export const ACTION = {
    CREATE_CUSTOM_TEXT_PAGE: "CREATE_CUSTOM_TEXT_PAGE",
    FETCH_ALL_TEXT_PAGE: "FETCH_ALL_TEXT_PAGE",
    FETCH_CONTENT_TEXT_PAGE: "FETCH_CONTENT_TEXT_PAGE",
    FETCH_CONTENT_FAQ_PAGE: "FETCH_CONTENT_FAQ_PAGE",
    FILTER_TEXT_PAGE: "FILTER_TEXT_PAGE",
    SOFT_DELETE_TEXT_PAGE: "SOFT_DELETE_TEXT_PAGE",
    CREATE_CUSTOM_FAQ: "CREATE_CUSTOM_FAQ",
    FETCH_FAQ_PAGE: "FETCH_FAQ_PAGE",
    SOFT_DELETE_FAQ_LIST: "SOFT_DELETE_FAQ_LIST",
    FETCH_FORM_FIELD: "FETCH_FORM_FIELD",
    CREATE_FORM_PAGE: "CREATE_FORM_PAGE",
    FETCH_CREATED_FORM: "FETCH_CREATED_FORM",
    UPDATE_CUSTOM_PAGE_BY_ID: "UPDATE_CUSTOM_PAGE_DATA_BY_ID"
}
export const CUSTOM_PAGE_TABLE_HEADING = [
    "Sr",
    "page name",
    "page type",
    "created date",
    "last updated",
    "action"
]

export const CUSTOM_PAGE_TABLE_ROWS = [
    {
        id: 1,
        page_name: "Admin",
        page_type: "Text page",
        created_date: " 01-01-2020",
        last_updated: "04-01-2020"
    },
    {
        id: 2,
        page_name: "Super Admin",
        page_type: "Text page",
        created_date: " 01-01-2020",
        last_updated: "04-01-2020"
    },
    {
        id: 3,
        page_name: "Author Movies",
        page_type: "Text page",
        created_date: " 01-01-2020",
        last_updated: "04-01-2020"
    },
    {
        id: 4,
        page_name: "Author Web Series",
        page_type: "Text page",
        created_date: " 01-01-2020",
        last_updated: "04-01-2020"
    }
]

export const CUSTOM_PAGE_API_PREFIX = "/editorial/v1/custom-pages"

export const TEXT_TYPE_CONST = [
    {
        name: "Terms and Conditions",
        id: "TERMS_AND_CONDITIONS"
    },
    {
        name: "Privacy Policy",
        id: "PRIVACY_POLICY"
    },
    {
        name: "Others",
        id: "OTHERS"
    }
]
