import ApiService from "../../../utils/apiService"
import { API_ROOT_PATH } from "../../../config/apiPath" 

class AddTranslationServices {
    fetchWebDictionaryIds() {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/editorial/v1/dictionaries/list`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    addDictionaryStrings(id,body){        
        const options = {
            method: "PUT",
            url: `${API_ROOT_PATH}/editorial/v1/dictionaries/${id}`,
            isAuthRequired: true,
            data: body
        }
        return ApiService(options)
    }
} 
const AddTranslationServicesInstance = new AddTranslationServices()
export default AddTranslationServicesInstance