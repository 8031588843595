import React from "react"
import PropTypes from "prop-types"
import infoIcon from "../../../assets/images/info.svg"
import "./style.scss"
import { isEmpty } from "lodash"
import { REGEX } from "../../../utils/constants"

const Input = (props) => {
    const {
        type,
        name,
        id,
        placeholder,
        value,
        cName,
        cFormName,
        labelText,
        required,
        errMsg,
        listRef,
        onClick,
        title,
        disabled,
        maxLength,
        inputType,
        minValue,
        isInfo,
        infoCname,
        infoText,
        step,
        maxValue,
        pattern,
        isOnBlur = true
    } = props
    const onChange = (event) => {
        let { name, changeHandler, inputType, isUrl, validateUrl } = props
        let { value } = event.target
        if (isEmpty(value.split(" ").join(""))) {
            value = ""
        }
        if (inputType === "Integer") {
            value = value.replace(/\D/g, "")
        }
        if (isUrl) {
            let regex = REGEX.VIDEO_URL
            if (!regex.test(value)) {
                validateUrl(true, name)
            } else {
                validateUrl(false, name)
            }
        }
        changeHandler && changeHandler(name, value)
    }

    const onBlur = (event) => {
        let { name, changeHandler } = props
        let { value } = event.target
        if (value && isOnBlur) {
            value = value.replace(REGEX.REMOVE_SPACES_START_END, "")
            changeHandler && changeHandler(name, value)
        }
    }
    return (
        <div className={`form-input ${cFormName || ""}`}>
            {labelText && (
                <label htmlFor={id}>
                    {labelText}
                    <span
                        className="required"
                        style={{
                            display: required ? "inline" : "none",
                            // color: "#1FB1FF",
                            marginLeft: "5px"
                        }}>
                        *
                    </span>
                </label>
            )}
            <input
                required={required}
                type={type}
                inputType={inputType}
                onChange={onChange}
                onClick={onClick}
                data-toggle="tooltip"
                data-placement="top"
                maxLength={maxLength}
                title={title}
                name={name}
                className={errMsg ? `${cName} inputErr` : cName}
                id={id}
                placeholder={placeholder}
                value={value}
                autoComplete="off"
                ref={listRef}
                min={minValue}
                max={maxValue}
                disabled={disabled}
                step={step}
                pattern={pattern}
                onBlur={onBlur}
                {...props}
            />
            {isInfo && (
                <p className={`info-container ${infoCname || ""}`}>
                    <img className="info-icon" src={infoIcon} alt="info-icon" />
                    <span className="tooltiptext">{infoText}</span>
                </p>
            )}
            {errMsg && <span className="err">{errMsg}</span>}
        </div>
    )
}

Input.propTypes = {
    type: PropTypes.string,
    changeHandler: PropTypes.func,
    name: PropTypes.string,
    id: PropTypes.string,
    placeholder: PropTypes.any,
    value: PropTypes.any,
    cName: PropTypes.string,
    labelText: PropTypes.string
}

export default Input
