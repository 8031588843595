import React, { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { fetchPlatformList } from "../Category/api/action"
import { createEditSplashScreen, getSplashScreen, uploadSplashImage } from "./APIs/action"
import CustomRadioButton from "../common/customRadioBtn"
import Input from "../common/Input"
import Button from "../common/Button"
import { SplashScreen } from "./SplashScreen"
import {
    accpetedFiles,
    backgroundOption,
    logoOptions,
    OTHER_DEVICE_LIST,
    TV_DEVICES
} from "./APIs/constant"
import { BUTTON_CONSTANTS, REGEX } from "../../utils/constants"
import mobileImage from "../../assets/images/mobile.png"
import laptopImage from "../../assets/images/laptop.png"
import tvImage from "../../assets/images/TV.png"

const INITIAL_STATE_LOGO = {
    logo: "",
    name: ""
}
const INITIAL_STATE_BACKGROUND = {
    background: "",
    name: ""
}

const AppSplash = () => {
    const [currentPlatform, setCurrentPlatForm] = useState("")
    const [currentTabID, setCurrentTabID] = useState(0)
    const [platformList, setPlatFormList] = useState([])

    const [options, setOptions] = useState({
        backgroundOption: "color",
        logoOption: "png"
    })

    const [background, setBackground] = useState(INITIAL_STATE_BACKGROUND)

    const [logo, setLogo] = useState(INITIAL_STATE_LOGO)

    const handleRadio = useCallback(
        (event) => {
            const { name, value } = event.target
            setOptions({ ...options, [name]: value })
            name === "backgroundOption"
                ? setBackground(INITIAL_STATE_BACKGROUND)
                : setLogo(INITIAL_STATE_LOGO)
        },
        [options]
    )

    const handlePlatformChange = (item) => {
        setCurrentPlatForm(item.platformEnum)
    }

    const dispatch = useDispatch()

    const loadPlatFormList = async () => {
        let list = []
        if (!platformList.length) {
            list = await dispatch(fetchPlatformList())
            setPlatFormList(list?.data)
            setCurrentPlatForm(list?.data[0]?.platformEnum)
        }
        return list
    }

    const getScreenData = async () => {
        const resp = await dispatch(getSplashScreen(currentPlatform))
        if (resp.status) {
            const { backgroundColorCode, backgroundImageUrl, logoUrl, lottieJson, id } = resp.data
            const logo = logoUrl || lottieJson
            setCurrentTabID(id)
            setLogo({ logo: logo, name: logo?.split("/")[1] })
            setBackground({
                background: backgroundColorCode || backgroundImageUrl,
                name: backgroundImageUrl ? backgroundImageUrl.split("/")[1] : backgroundColorCode
            })
            setOptions({
                backgroundOption: backgroundColorCode ? "color" : "image",
                logoOption: logoUrl ? "png" : "json"
            })
        } else {
            setCurrentTabID(0)
            setLogo(INITIAL_STATE_LOGO)
            setBackground(INITIAL_STATE_BACKGROUND)
            setOptions({
                backgroundOption: "color",
                logoOption: "png"
            })
        }
    }

    useEffect(() => {
        loadPlatFormList()
    }, [])

    useEffect(() => {
        getScreenData()
    }, [currentPlatform])

    const handleBackground = useCallback((name, value) => {
        setBackground({ [name]: value, name: value })
    }, [])

    const imageChangeHandler = useCallback(async (e) => {
        const { files, name } = e.target
        if (!files.length) {
            return
        }
        const modifiedName = files?.[0]?.name?.replace(REGEX.FILE_NAME, "")
        const modifiedFile = new File([files[0]], modifiedName, {
            type: files[0] ? files[0].type : null
        })
        if (!accpetedFiles.includes(modifiedFile.type)) {
            toast.error("Image type is not valid")
            return
        }
        let data = new FormData()
        data.append("multipartFile", modifiedFile)
        const response = await dispatch(uploadSplashImage(data))
        if (response?.status) {
            if (name === "background") {
                setBackground({ name: modifiedName, [name]: response.data })
            } else {
                setLogo({ [name]: response.data, name: modifiedName })
            }
        }
    }, [])

    const handleSubmit = useCallback(() => {
        const payload = {
            backgroundColorCode: background.background.includes("splash")
                ? null
                : background.background,
            backgroundImageUrl: background.background.includes("splash")
                ? background.background
                : null,
            logoUrl: logo.logo.includes(".png") ? logo.logo : null,
            lottieJson: logo.logo.includes(".png") ? null : logo.logo,
            platform: currentPlatform
        }
        if (currentTabID) {
            dispatch(createEditSplashScreen(payload, currentTabID))
        } else {
            dispatch(createEditSplashScreen(payload))
        }
    }, [background, logo, currentTabID, currentPlatform])

    return (
        <div className="app-splash">
            <div className="vd-category">
                <div className="platform">
                    {platformList?.map((item, index) => {
                        return (
                            <i
                                className={`icon-${item.name} ${
                                    currentPlatform === item.platformEnum && "activeIcon"
                                }`}
                                id={item.id}
                                onClick={() => handlePlatformChange(item)}
                            >
                                <span className="platform-name">
                                    {item.name === "dongle" ? "Android B" : item.name}
                                </span>
                            </i>
                        )
                    })}
                </div>
            </div>
            <div className="container splash-container">
                <div>
                    <div className="form-data">
                        <p className="scte-marker-radio-sub-heading">Select Background Option</p>
                        <CustomRadioButton
                            key={"backgroundOption"}
                            id={"backgroundOption"}
                            name={"backgroundOption"}
                            handleRadioChange={handleRadio}
                            options={backgroundOption}
                            value={options.backgroundOption}
                        />
                        {options.backgroundOption === "color" ? (
                            <Input
                                type="text"
                                name="background"
                                placeholder="Enter Color Code"
                                changeHandler={handleBackground}
                                value={background.background}
                                cName="color-code"
                                required="required"
                            />
                        ) : (
                            <div className={"image-input-container"}>
                                <span>{background.name}</span>
                                <div>
                                    <input
                                        type="file"
                                        name="background"
                                        id="bg-btn"
                                        onChange={imageChangeHandler}
                                    />
                                    <label htmlFor="bg-btn">Upload</label>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="form-data">
                        <p className="scte-marker-radio-sub-heading">Select Logo Option</p>
                        <CustomRadioButton
                            key={"logoOption"}
                            id={"logoOption"}
                            name={"logoOption"}
                            handleRadioChange={handleRadio}
                            options={logoOptions}
                            value={options.logoOption}
                        />
                        <div className={"image-input-container"}>
                            <span>{logo.name}</span>
                            <div>
                                <input
                                    type="file"
                                    name="logo"
                                    id="logo-btn"
                                    accept={`${
                                        options.logoOption === "png"
                                            ? "image/png"
                                            : "application/json"
                                    }`}
                                    onChange={imageChangeHandler}
                                />
                                <label htmlFor="logo-btn">Upload</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="preview-container">
                    <span>Preview</span>
                    <div className="screens">
                        {currentPlatform === OTHER_DEVICE_LIST.WEB && (
                            <SplashScreen
                                classes={{ bg: "laptop-bg", content: "laptop-content-bg" }}
                                image={background.background}
                                logo={logo.logo}
                                screen={laptopImage}
                            />
                        )}
                        {(currentPlatform === OTHER_DEVICE_LIST.ANDROID ||
                            currentPlatform === OTHER_DEVICE_LIST.IOS) && (
                            <SplashScreen
                                classes={{ bg: "mobile-bg", content: "mobile-content-bg" }}
                                image={background.background}
                                logo={logo.logo}
                                screen={mobileImage}
                            />
                        )}
                        {TV_DEVICES.includes(currentPlatform) && (
                            <SplashScreen
                                classes={{ bg: "tv-bg", content: "tv-content-bg" }}
                                image={background.background}
                                logo={logo.logo}
                                screen={tvImage}
                            />
                        )}
                    </div>
                </div>
            </div>
            <Button
                cName={"btn secondary-btn"}
                bValue={BUTTON_CONSTANTS.SUBMIT}
                clickHandler={handleSubmit}
                disabled={!background.background || !logo.logo}
            />
        </div>
    )
}

export default AppSplash
