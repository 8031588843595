import React, { Component } from "react"
import BuilderCanvas from "./builderCanvas"
import Button from "../common/Button"
import BuilderTable from "./builderTable"

import "./style.scss"
import Select from "../common/SelectBox"
import { bindActionCreators } from "redux"
import { fetchContentList, fetchContentEntityAll, saveForm, deleteForm } from "./api/action"
import { connect } from "react-redux"
import Input from "../common/Input"
import { scrollPage } from "../../utils/common"
import { toast } from "react-toastify"
import { BUTTON_CONSTANTS, PERMISSIONS, TEXT_CONSTANTS } from "../../utils/constants"
import { checkIfUserHasAccess } from "../../utils/users"

class FormBuilder extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isCreateForm: false,
            isLoading: true,
            elementsArr: [],
            isLinkForm: false,
            options: [],
            formatType: 0,
            contentEntityAll: "",
            apiPayload: {}
        }
    }
    componentDidMount() {
        scrollPage()
        this.fetchContentList()
        this.resetApiPayload()
    }
    resetApiPayload = () => {
        this.setState({
            apiPayload: {
                name: "",
                formTitle: "",
                dataFormat: {
                    type: "Object",
                    properties: {}
                },
                searchKeys: [],
                nameKey: "",
                imageKey: ""
            }
        })
        this.canUpdateStatus = checkIfUserHasAccess([PERMISSIONS.SETTING_FORM_BUILDER_EDIT])
    }
    fetchContentList = async () => {
        const response = await this.props.fetchContentList()
        const arr = []
        response &&
            response.data &&
            response.data.length &&
            response.data.forEach((data, index) => {
                arr.push({ name: data, id: index })
            })
        this.setState({
            options: arr,
            isLoading: false,
            contentType: arr[0] && arr[0].name
        })
        const data = await this.props.fetchContentEntityAll()
        if (data && data.data && data.data.length) {
            this.setState({ contentEntityAll: [...data.data] })
        }
    }
    setElementArray = (value) => {
        this.setState({ elementsArr: value })
    }
    setPayloadName = (name) => {
        const { apiPayload } = this.state
        const data = name ? name : "Form Title"
        this.setState({
            apiPayload: {
                ...apiPayload,
                formTitle: data
            }
        })
    }
    setPayload = (value) => {
        this.setState((prevState) => ({
            apiPayload: {
                ...prevState.apiPayload,
                dataFormat: {
                    ...prevState.apiPayload.dataFormat,
                    properties: {
                        ...prevState.apiPayload.dataFormat.properties,
                        ...value
                    }
                }
            }
        }))
    }
    setStepperProperties = (data, properties) => {
        this.setState((prevState) => {
            if (
                prevState.apiPayload.dataFormat.properties[
                    data && data.groupTitle && data.groupTitle.toLowerCase().split(" ").join("_")
                ]
            ) {
                return {
                    apiPayload: {
                        ...prevState.apiPayload,
                        dataFormat: {
                            ...prevState.apiPayload.dataFormat,
                            properties: {
                                ...prevState.apiPayload.dataFormat.properties,
                                [data &&
                                data.groupTitle &&
                                data.groupTitle.toLowerCase().split(" ").join("_")]: {
                                    ...prevState.apiPayload.dataFormat.properties[
                                        data &&
                                            data.groupTitle &&
                                            data.groupTitle.toLowerCase().split(" ").join("_")
                                    ],
                                    properties: {
                                        ...prevState.apiPayload.dataFormat.properties[
                                            data &&
                                                data.groupTitle &&
                                                data.groupTitle.toLowerCase().split(" ").join("_")
                                        ].properties,
                                        ...properties
                                    }
                                }
                            }
                        }
                    }
                }
            }
        })
    }
    handleAddLinkClick = () => {
        this.setState({
            isLinkForm: !this.state.isLinkForm
        })
        this.state.elementsArr.map((data) => {
            let properties = {}
            //const title =
            if ((data.htmlElement === "group" && data.isStepper) || data.stepperId) {
                if (
                    data.title !== "group-title" &&
                    !(
                        data &&
                        data.groupTitle &&
                        data.groupTitle.toLowerCase().split(" ").join("_") in properties
                    )
                ) {
                    properties[
                        data &&
                            data.groupTitle &&
                            data.groupTitle.toLowerCase().split(" ").join("_")
                    ] = {}
                    this.setPayload(properties)
                }
                properties[
                    data && data.groupTitle && data.groupTitle.toLowerCase().split(" ").join("_")
                ].type = "object"
                properties[
                    data && data.groupTitle && data.groupTitle.toLowerCase().split(" ").join("_")
                ].properties = {}
                this.state.elementsArr
                    .filter((p) => p.stepperId === data.id)
                    .map(async (ele) => {
                        properties = this.getProperties(ele, {})
                        await this.setStepperProperties(data, properties)
                        if (ele.children && ele.children.length) {
                            ele.children.map(async (i) => {
                                properties = this.getProperties(i, properties)
                                Object.keys(properties).map(async (item, index) => {
                                    await this.setStepperProperties(i, {
                                        [item]: properties[item]
                                    })
                                })
                            })
                        }
                    })
            } else if (data.children && data.children.length) {
                properties = this.getProperties(data, properties)
                this.setPayload(properties)
                data.children.map((i) => {
                    properties = this.getProperties(i, properties)
                    this.setPayload(properties)
                    return i
                })
            } else {
                properties = this.getProperties(data, properties)
                this.setPayload(properties)
            }
            return data
        })
    }
    getProperties = (data, properties) => {
        const title = data && data.title && data.title.toLowerCase().split(" ").join("_")
        if (data.htmlElement !== "group") {
            if (title === "title" || !(data.title in properties)) {
                properties[title] = {
                    uniqueItems: data.required || false
                }
            }
            if (data.options && data.options.length && properties[title]) {
                properties[title].type = "string"
                properties[title].items = { enum: [] }
                data.options.length &&
                    data.options.map((d) => {
                        if (properties[title] && properties[title].items) {
                            properties[title].items.type = "array"
                            properties[title].items.enum.push(d.name)
                        }
                        return d
                    })
            } else {
                if (data && title && typeof properties[title] === "object") {
                    properties[title].type = "string"
                }
            }
        }
        return properties
    }
    handleAddClick = () => {
        this.setState({
            isCreateForm: !this.state.isCreateForm,
            isLinkForm: false,
            elementsArr: []
        })
        this.resetApiPayload()
    }
    handleTextChange = (name, value) => {
        this.setState({
            apiPayload: { ...this.state.apiPayload, name: value }
        })
    }
    findDefaultOption = (item, list) => {
        let defaultOption = list.find((ele) => {
            if (ele.id === item) {
                return ele
            }
            return false
        })
        return defaultOption
    }
    getId = () => {
        return Math.random().toString(36).substring(2) + Date.now().toString(36)
    }
    saveForm = async () => {
        const payload = this.state.apiPayload
        payload.htmlElements = { content: this.state.elementsArr }
        if (payload.searchKeys && payload.searchKeys.length) {
            const response = await this.props.saveForm(payload)
            if (response && response.data && response.message) {
                this.handleAddClick()
                const data = await this.props.fetchContentEntityAll()
                if (data && data.data && data.data.length) {
                    this.setState({
                        contentEntityAll: [...data.data],
                        isLinkForm: false
                    })
                }
            }
        } else {
            toast.error("Please select at least  one key to include in search")
            this.setState({
                isLinkForm: false
            })
        }
    }
    handleEditForm = (data, index) => {
        if (data && data.htmlElements) {
            this.handleAddClick()
            this.setElementArray(data.htmlElements.content)
            let apiPayload = { ...this.state.apiPayload }
            apiPayload.name = data.name
            apiPayload.formTitle = data.formTitle
            apiPayload.nameKey = data.nameKey
            apiPayload.imageKey = data.imageKey
            apiPayload.id = data.id
            apiPayload.searchKeys = data.searchKeys
            this.setState({
                apiPayload
            })
        }
    }
    tableLoader = () => {
        let listLoader = [1, 2, 3, 4, 5, 6]
        return (
            <div className="rowContainer-loader">
                {listLoader.map(() => {
                    return (
                        <div className="row_loader">
                            <div className="block_loader" />
                            <div className="block_loader" />
                            <div className="block_loader" />
                            <div className="action_loader">
                                <span className="circle_loader" />
                                <span className="circle_loader" />
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    addSearch = (key) => {
        const { apiPayload } = this.state
        apiPayload.searchKeys.push(key)
        this.setState({
            apiPayload
        })
    }
    findChecked = (item, key) => {
        const { apiPayload } = this.state
        let list = apiPayload[key]
        let isFound = false
        list.forEach((i) => {
            if (i === item) {
                isFound = true
            }
        })
        return isFound
    }

    getNameKeyOptions = (type) => {
        const { elementsArr } = this.state
        let options = []
        elementsArr.map((item) => {
            if (item.elementsArr && item.elementsArr.length) {
                item.elementsArr.forEach((i) => {
                    if (i.htmlInputType === type) {
                        options.push({ id: i.title, name: i.title })
                    }
                })
            } else if (item.children && item.children.length) {
                options.push({ id: item.title, name: item.title })
                item.children.forEach((i) => {
                    if (i.htmlInputType === type) {
                        options.push({ id: i.title, name: i.title })
                    }
                })
            } else {
                if (item.htmlInputType === type) {
                    options.push({ id: item.title, name: item.title })
                }
            }
            return item
        })
        return options
    }
    handleSelectChange = (name, value) => {
        const { apiPayload } = this.state
        apiPayload[name] = value
        this.setState({
            apiPayload
        })
    }
    validateLinkForm = () => {
        const { name, nameKey, imageKey } = this.state.apiPayload
        if (name && nameKey && imageKey) {
            return false
        } else {
            return true
        }
    }

    renderLinkFormPopup = () => {
        const { isLinkForm, apiPayload, elementsArr } = this.state

        return (
            <React.Fragment>
                <div className="selectionButtons">
                    <div className={isLinkForm ? "toolsBtn  show" : "toolsBtn"}>
                        {this.canUpdateStatus && (
                            <Button
                                cName={"btn border-btn linkBtn"}
                                bValue={BUTTON_CONSTANTS?.LINK_FORM}
                                bType="button"
                                leftIcon={"icon-settings"}
                                disabled={elementsArr && elementsArr.length ? false : true}
                                clickHandler={this.handleAddLinkClick}
                            />
                        )}
                        <div className="confirmation-popup">
                            <div className="message">
                                <Input
                                    name={"form-name"}
                                    cName={"form-name"}
                                    value={apiPayload.name}
                                    labelText={"Apply On"}
                                    changeHandler={this.handleTextChange}
                                />
                                <Select
                                    options={this.getNameKeyOptions("text")}
                                    cName={"nameKey"}
                                    name={"nameKey"}
                                    value={this.findDefaultOption(
                                        apiPayload.nameKey,
                                        this.getNameKeyOptions("text")
                                    )}
                                    placeholder={"select"}
                                    label={"Name Key"}
                                    changeHandler={this.handleSelectChange}
                                />
                                <Select
                                    options={this.getNameKeyOptions("file")}
                                    cName={"imageKey"}
                                    name={"imageKey"}
                                    value={this.findDefaultOption(
                                        apiPayload.imageKey,
                                        this.getNameKeyOptions("file")
                                    )}
                                    placeholder={"select"}
                                    label={"Image Key"}
                                    changeHandler={this.handleSelectChange}
                                />
                            </div>
                            <Button
                                cName={`btn primary-btn`}
                                bValue={BUTTON_CONSTANTS?.LINK}
                                bType="button"
                                disabled={this.validateLinkForm() ? true : false}
                                clickHandler={this.saveForm}
                            />
                            <button
                                type="button"
                                className="btn cancel-btn"
                                onClick={this.handleAddLinkClick}
                            >
                                {BUTTON_CONSTANTS?.CANCEL}
                            </button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    render() {
        const { isCreateForm, contentEntityAll, apiPayload, elementsArr, isLoading } = this.state
        const {
            setPayload,
            setPayloadName,
            setElementArray,
            handleEditForm,
            addSearch,
            fetchContentList,
            findChecked
        } = this
        const { deleteForm } = this.props
        return (
            <div className="builder-table vd-form-builder">
                <div className="vr-cont-head mb">
                    {/* TODO: Replace below with the reusable pageTitleComponent */}
                    <div className="vr-cont-title">
                        <span className="vr-breadcrum">
                            {TEXT_CONSTANTS?.SETTINGS} <i className="icon-arrow-right1" />
                            <span>{TEXT_CONSTANTS?.CREATE_FORM}</span>
                        </span>
                        <h2 className="breadcrum-header">{TEXT_CONSTANTS?.CREATE_FORM}</h2>
                    </div>
                    <div className="vr-cont-action header-filter-btn">
                        {isCreateForm && this.renderLinkFormPopup()}
                        {(this.canUpdateStatus || isCreateForm) && (
                            <Button
                                cName={isCreateForm ? "btn border-btn" : "btn primary-btn"}
                                bValue={
                                    isCreateForm
                                        ? BUTTON_CONSTANTS?.BACK
                                        : BUTTON_CONSTANTS?.ADD_NEW
                                }
                                bType="button"
                                leftIcon={isCreateForm && "icon-arrow-left1"}
                                clickHandler={this.handleAddClick}
                            />
                        )}
                    </div>
                </div>
                <div className="vr-content-holder">
                    {isCreateForm ? (
                        <BuilderCanvas
                            setPayload={setPayload}
                            apiPayload={apiPayload}
                            setPayloadName={setPayloadName}
                            setElementArray={setElementArray}
                            elementsArr={elementsArr}
                            addSearch={addSearch}
                            findChecked={findChecked}
                            handleAddClick={this.handleAddClick}
                        />
                    ) : isLoading ? (
                        this.tableLoader()
                    ) : (
                        <BuilderTable
                            contentEntityAll={contentEntityAll}
                            deleteForm={deleteForm}
                            handleEdit={handleEditForm}
                            fetchContentList={fetchContentList}
                        />
                    )}
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                fetchContentList,
                fetchContentEntityAll,
                saveForm,
                deleteForm
            },
            dispatch
        )
    }
}

export default connect(null, mapDispatchToProps)(FormBuilder)
