import { ACTION } from "./constants"

let initialState = {}

export default function addUserReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION.FETCH_FORM_ELEMENT:
            return { ...state, formElementJson: action.apiResponse.data }
        default:
            return state
    }
}
