const Options = [
    {
        name: "Input Fields",
        id: 1,
        icon: "input",
        fields: [
            {
                id: 1,
                name: "Text Field",
                htmlElement: "input",
                htmlInputType: "text",
                htmlPlaceholder: "Text Field",
                size: "L",
                required: "",
                sideMenuType: "option-1",
                isSearchableKey: true
            },
            {
                id: 2,
                name: "Text Area",
                htmlElement: "input",
                htmlInputType: "textarea",
                htmlPlaceholder: "Text Area",
                size: "L",
                required: "",
                sideMenuType: "option-1",
                isSearchableKey: true
            },
            {
                id: 3,
                name: "Number Field",
                htmlElement: "input",
                htmlInputType: "number",
                htmlPlaceholder: "Number Field",
                size: "L",
                required: "",
                sideMenuType: "option-1",
                isSearchableKey: true
            }
        ]
    },
    {
        name: "Image Upload",
        id: 2,
        icon: "image",
        fields: [
            /*{
                id: 1,
                name: 'Browse Button Upload',
                htmlElement: "dgfd",
                htmlInputType: "file",
                htmlPlaceholder: '',
                size: 'L',
                required: '',
                sideMenuType: "option-1"

            }, */ {
                id: 1,
                name: "Placeholder Upload",
                htmlElement: "file",
                htmlInputType: "file",
                htmlPlaceholder: "",
                size: "L",
                required: "",
                sideMenuType: "option-1"
            }
        ]
    },
    {
        name: "Selection",
        id: 3,
        icon: "selection",
        fields: [
            {
                id: 1,
                name: "Single Select DropDown",
                htmlElement: "select",
                htmlInputType: "select",
                htmlPlaceholder: "Single Select DropDown",
                size: "L",
                required: "",
                options: [{ id: 1, name: "Option-1" }],
                sideMenuType: "option-2"
            },
            {
                id: 2,
                name: "Multi Select DropDown",
                htmlElement: "select",
                htmlInputType: "multiSelect",
                htmlPlaceholder: "Multi Select DropDown",
                size: "L",
                required: "",
                options: [
                    { id: 1, name: "Option-1" },
                    { id: 2, name: "Option-2" }
                ],
                sideMenuType: "option-2"
            },
            {
                id: 3,
                name: "Multi Select DropDown with photo",
                htmlElement: "select",
                htmlInputType: "multiSelect",
                htmlPlaceholder: "Multi Select DropDown with photo",
                size: "L",
                required: "",
                options: [
                    { id: 1, name: "Option-1" },
                    { id: 2, name: "Option-2" },
                    { id: 3, name: "Option-3" }
                ],
                sideMenuType: "option-2"
            },
            {
                id: 4,
                name: "Multi Select DropDown without photo",
                htmlElement: "select",
                htmlInputType: "multiSelect",
                htmlPlaceholder: "Multi Select DropDown without photo",
                size: "L",
                required: "",
                options: [{ id: 1, name: "Option-1" }],
                sideMenuType: "option-2"
            }
        ]
    },
    {
        name: "Date Time",
        id: 4,
        icon: "date-time",
        fields: [
            {
                id: 1,
                name: "Date(DD/MM/YYYY)",
                htmlElement: "dateAndTime",
                htmlInputType: "date",
                htmlPlaceholder: "Date(DD/MM/YYYY)",
                size: "L",
                required: "",
                sideMenuType: "option-1"
            },
            {
                id: 2,
                name: "Time",
                htmlElement: "dateAndTime",
                htmlInputType: "time",
                htmlPlaceholder: "HH/MM/SS",
                size: "",
                required: "",
                sideMenuType: "option-1"
            }
        ]
    },
    {
        name: "Checkbox",
        id: 5,
        icon: "checkbox",
        fields: [
            {
                id: 1,
                name: "CheckBox",
                htmlElement: "checkbox",
                htmlInputType: "checkbox",
                htmlPlaceholder: "CheckBox",
                size: "L",
                required: "",
                sideMenuType: "option-2",
                options: [
                    { id: 1, name: "yes" },
                    { id: 2, name: "no" }
                ]
            }
        ]
    },
    {
        name: "Radio Button",
        id: 6,
        icon: "radio-buitton",
        fields: [
            {
                id: 1,
                name: "Radio Button",
                htmlElement: "radio",
                htmlInputType: "radio",
                htmlPlaceholder: "Radio Button",
                size: "L",
                required: "",
                sideMenuType: "option-2",
                options: [
                    { id: 1, name: "yes" },
                    { id: 2, name: "no" }
                ]
            }
        ]
    },
    // {
    //     name: 'Group',
    //     id: 6,
    //     icon: 'group',
    //     fields: [
    //         {
    //             id: 1,
    //             name: 'Radio Button',
    //             htmlElement: "radio",
    //             htmlInputType: "radio",
    //             htmlPlaceholder: '',
    //             size: 'L',
    //             required: '',
    //             sideMenuType: "option-1",
    //         }
    //     ]
    // },
    {
        name: "Action Button",
        id: 7,
        icon: "action",
        fields: [
            {
                id: 1,
                name: "Action Button",
                htmlElement: "button",
                htmlInputType: "button",
                htmlPlaceholder: "",
                size: "L",
                required: "",
                sideMenuType: "option-1"
            }
        ]
    },
    {
        name: "Group",
        id: 8,
        icon: "group",
        fields: [
            {
                id: 1,
                name: "Group Section",
                htmlElement: "group",
                htmlInputType: "group",
                htmlPlaceholder: "",
                size: "L",
                required: ""
            }
        ]
    }
]
export default Options
