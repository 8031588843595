import React, { useMemo } from "react"
import { imagePathPrefix } from "../../../config/apiPath"
import NoImgAvailable from "../../../assets/images/no_img_available.png"
import { TEXT_CONSTANTS } from "../../../utils/constants"
import Checkbox from "../../common/Checkbox"
import DisplayCard from "../DisplayCard"
import { CONTENT_TAB_TYPES, FORM_DATA_CONSTANTS } from "../API/constants"
import useInfiniteScroll from "../useInfiniteScroll"

import "./RenderWebSeriesContent.scss"
import Slider from "react-slick"
import { DEFAULT_SELECTED_LANGUAGE_CODE } from "../../ContentNav/api/constants"

const RenderWebSeriesContent = ({
    webseriesDataList = [],
    selectedWebSeriesId,
    webSeriesCheckboxHandler,
    fetchNextBatchData,
    plansList
}) => {
    const joinUrl = (string) => {
        return imagePathPrefix + string
    }

    const [, setIsFetching] = useInfiniteScroll(fetchMoreListItems, "webseries-grid-view-container")

    function fetchMoreListItems() {
        setTimeout(() => {
            // Make API call
            fetchNextBatchData(null, true)
            setIsFetching(false)
        }, 700)
    }

    return (
        <div className="vr-webseries-content-container" id="webseries-grid-view-container">
            {webseriesDataList?.map((webSeries) => {
                const { id: webSeriesId } = webSeries
                const { brand_poster_image, season } = webSeries?.content
                const thumbnail = brand_poster_image?.values?.[DEFAULT_SELECTED_LANGUAGE_CODE]
                return (
                    <div className="single-series-container" key={webSeriesId}>
                        <div className="single-series-container-img">
                            {/* Dislay season poster */}
                            <img
                                src={
                                    thumbnail && thumbnail !== null
                                        ? joinUrl(thumbnail)
                                        : `${NoImgAvailable}`
                                }
                                alt="thumbnail"
                                className={!thumbnail ? "no-img-available" : "movie-Thumbnail"}
                                onError={(e) => {
                                    e.target.onerror = null
                                    e.target.src = `${NoImgAvailable}`
                                    e.target.className = `no-img-available`
                                }}
                            />
                        </div>
                        {/* Display rest of the episodes */}
                        <RenderWebSeriesEpisodeList
                            season={season}
                            webSeriesCheckboxHandler={webSeriesCheckboxHandler}
                            selectedWebSeriesId={selectedWebSeriesId}
                            webSeriesId={webSeriesId}
                            plansList={plansList}
                        />
                    </div>
                )
            })}
            {/* {isFetching && <h2>Fetching more results</h2>} */}
        </div>
    )
}

const RenderWebSeriesEpisodeList = ({
    season,
    webSeriesCheckboxHandler,
    selectedWebSeriesId,
    webSeriesId,
    plansList
}) => {
    const currentSelectedWebSeries = selectedWebSeriesId?.find(
        (item) => item?.seriesId === webSeriesId
    )

    const selectAllCheckBoxHandler = (name, checked, id) => {
        webSeriesCheckboxHandler(checked, name, webSeriesId, false)
    }

    const setAllFirstFreeCheckBoxHandler = (name, checked, id, seasonId) => {
        webSeriesCheckboxHandler(checked, name, webSeriesId, true)
    }

    const webSeriesCheckboxHandler1 = (name, checked, id, seasonId) => {
        webSeriesCheckboxHandler(checked, name, webSeriesId, false)
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1360,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1840,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            }
        ]
    }

    const seasonEpisodeLength = useMemo(() => {
        let length = 0
        season &&
            season.forEach((item) => {
                length += 1
            })
        return length
    }, [season])

    // Calculate selected episode length and compare it with total episode length
    const isAllCheckboxesChecked = (selectedWebSeries, firstFree = false) => {
        // {
        //   seriesId: '',
        //   selectedSeasonID: [],
        //   selectedFreeSeasonId:[]
        // }

        if (firstFree) {
            return seasonEpisodeLength === selectedWebSeries?.selectedFreeSeasonId?.length
        }

        // Calculate episode length of each season
        if (selectedWebSeries && selectedWebSeries?.selectedSeasonID) {
            return seasonEpisodeLength === selectedWebSeries?.selectedSeasonID?.length
        }
        return false
    }

    return (
        <div className="webseries-episode-list">
            <div className="webseries-episode-selection-box">
                {/* Select all and episode buttons */}
                <Checkbox
                    cName="check-box"
                    id={FORM_DATA_CONSTANTS?.selectAllCheckbox}
                    name={FORM_DATA_CONSTANTS?.selectAllCheckbox}
                    labelText={TEXT_CONSTANTS?.SELECT_ALL}
                    isChecked={isAllCheckboxesChecked(currentSelectedWebSeries)}
                    changeHandler={selectAllCheckBoxHandler}
                />
                <Checkbox
                    cName="check-box"
                    id={FORM_DATA_CONSTANTS?.freeForAllSeason}
                    name={FORM_DATA_CONSTANTS?.freeForAllSeason}
                    labelText={TEXT_CONSTANTS?.FIRST_FREE_EPISODE}
                    isChecked={isAllCheckboxesChecked(currentSelectedWebSeries, true)}
                    changeHandler={setAllFirstFreeCheckBoxHandler}
                />
            </div>
            <div>
                <Slider {...settings}>
                    {/* //  Display episode list  */}
                    {season?.map((item, index) => {
                        const isFreeEpisodeInSeason =
                            item?.episode[0]?.subscription_plan?.includes("FREE")
                        let currentSeasonPlans = item?.subscription_plan
                                ? [...item?.subscription_plan]
                                : [],
                            masterPlanList = [...plansList]
                        if (isFreeEpisodeInSeason) {
                            currentSeasonPlans.push("FREE")
                            masterPlanList.push({
                                code: "FREE",
                                colorCode: "#00AF6C",
                                name: "First Free",
                                description: "First Episode Free"
                            })
                        }
                        return (
                            <>
                                <DisplayCard
                                    key={item?.season_id}
                                    id={item?.season_id}
                                    thumbnail={
                                        item?.season_poster_image?.values?.[
                                            DEFAULT_SELECTED_LANGUAGE_CODE
                                        ]
                                    }
                                    selectedMoviesId={currentSelectedWebSeries?.selectedSeasonID}
                                    moviesCheckBoxHandler={(name, checked, id) =>
                                        webSeriesCheckboxHandler1(
                                            name,
                                            checked,
                                            id,
                                            item?.season_id
                                        )
                                    }
                                    title={`S-${index + 1}/${item?.episode?.length} ${
                                        item?.episode?.length > 1 ? "EPS" : "EP"
                                    }`}
                                    showFirstFreeCheckbox={true}
                                    firstFreeCheckboxHandler={(name, checked, id) =>
                                        setAllFirstFreeCheckBoxHandler(
                                            name,
                                            checked,
                                            id,
                                            item?.season_id
                                        )
                                    }
                                    selectedTab={CONTENT_TAB_TYPES?.WEBSERIES}
                                    selectedFirstFreeWebSeriesId={
                                        currentSelectedWebSeries?.selectedFreeSeasonId
                                    }
                                    plansList={masterPlanList}
                                    plans={currentSeasonPlans}
                                    count={{ season: index + 1, episode: item?.episode?.length }}
                                ></DisplayCard>
                            </>
                        )
                    })}
                </Slider>
            </div>
        </div>
    )
}

export default RenderWebSeriesContent
