import { ACTION } from "./constant"

let initialState = {}

export default function formBuilderReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION.FETCH_CONTENT:
            return { ...state, contentType: action.apiResponse.data }
        default:
            return state
    }
}
