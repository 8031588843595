import React, { useState } from "react"
import { AuthWrapper } from "../../common/AuthWrapper"
import { BUTTON_CONSTANTS, PERMISSIONS, TEXT_CONSTANTS } from "../../../utils/constants"
import "./style.scss"
import Button from "../../common/Button"
import { VOD_SPORTS_TAB } from "../api/constant"
import ImageDropdown from "../../common/ImageDropdown"
import tripleDot from "../../../assets/images/tripleDot.svg"
import DeleteModal from "../../common/DeleteModal"


export default function CategoryList({
    categoryList,
    handleListClick,
    selectedTab,
    addMenuBtn,
    showAddMenuBtn = false,
    parent,
    publishUnpublishRail
}) {

    const [showUnpublishModal, setShowUnpublishModal] = useState(false)
    const [selectedUnpublishItem, setSelectedUnpublishItem] = useState(null)
    const isCreateManagePage = parent === VOD_SPORTS_TAB.CREATE_MANAGE_PAGE

    const publishUnpublishHandler = (listItem) => {
        // Publish the rail if previously unpublished
        if (listItem?.value) {
            publishUnpublishRail(listItem)
        } else {
            setShowUnpublishModal(true)
            setSelectedUnpublishItem(listItem)
        }
    }

    const deleteModalSubmitHandler = () => {
        setShowUnpublishModal(false)
        selectedUnpublishItem && publishUnpublishRail(selectedUnpublishItem)
    }

    const cancelModalHandler = () => {
        setShowUnpublishModal(false)
    }

    return (
        <div className="category-list-headers">
            <ul className="category-list">
                {categoryList?.map((item) => {
                    const isActive = item?.active
                    return (
                        <div className="list-item" key={item?.id}>
                            <li
                                className={`category-list-items ${selectedTab === item?.id ? "active" : null}`}
                                onClick={() => handleListClick(item)}
                            >
                                <p className={isActive ? 'active-font' : ''}>{item?.name}</p>
                                {isCreateManagePage && (
                                    <p className={`active-symbol `}>
                                        <span
                                            className={`base-style ${isActive ? "published" : "unpublished"
                                                }`}
                                        >
                                        </span>
                                    </p>
                                )}
                            </li>
                            {isCreateManagePage && <ImageDropdown
                                dropdownImage={tripleDot}
                                dropdownItemList={[
                                    {
                                        id: item?.id,
                                        label: isActive ? TEXT_CONSTANTS?.UNPUBLISH : TEXT_CONSTANTS?.PUBLISH,
                                        value: !isActive,
                                    }
                                ]}
                                dropdownClickHandler={(listItem) => { publishUnpublishHandler(listItem) }}
                            ></ImageDropdown>}
                        </div>
                    )
                })}
            </ul>
            {isCreateManagePage && (
                <>
                    <div className="publish-unpublish-info">
                        <p className="info-text">
                            <span className={`base-style published`}></span>
                            {'Published'}
                        </p>
                        <p className="info-text">
                            <span className={`base-style unpublished`}></span>
                            {'Unpublished'}
                        </p>
                    </div>
                    <div className="sports-info">
                        <p className="info">{TEXT_CONSTANTS?.ADD_NEW_CATEGORY_LABEL}</p>
                        <h4>{TEXT_CONSTANTS?.SETUP_SPORTS_LABEL}</h4>
                    </div>
                </>
            )}
            {showAddMenuBtn && (
                <AuthWrapper permissions={[PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_EDIT]}>
                    <Button
                        leftIcon= {"icon-plus"}
                        cName="btn border-btn add-new-btn"
                        clickHandler={addMenuBtn}
                        bValue={TEXT_CONSTANTS.ADD_NEW_TAB}
                    />
                </AuthWrapper>
            )}
            <DeleteModal
                deleteModalHeader={TEXT_CONSTANTS?.UNPUBLISH_CATEGORY}
                deleteModalDescription={TEXT_CONSTANTS?.UNPUBLISH_CATEGORY_DESC}
                confirmationText={TEXT_CONSTANTS?.UNPUBLISH_CATEGORY_CONFIRMATION}
                showCheckBox={true}
                initialShowModal={showUnpublishModal}
                submitHandler={deleteModalSubmitHandler}
                cancelHandler={cancelModalHandler}
            >
            </DeleteModal>
        </div>
    )
}
