import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import Select from "react-select"
import coordinatesJSON from "./coordinates.json"

import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from "react-simple-maps"

import WorldJSON from "./world.json"
import "./styles.scss"

/* admin work */
class MapChart extends Component {
    constructor(props) {
        super(props)
        this.state = {
            position: { coordinates: [0, 0], zoom: 1 },
            selectedCountry: "India",
            coordinates: {},
            selectedCoordinate: 0
        }
    }
    /* https://codesandbox.io/s/zoom-controls-iwo3f?from-embed=&file=/src/MapChart.js:764-777 */
    componentDidMount() {
        const { countriesArray, data } = this.props
        let coordinates = coordinatesJSON.filter((value, index) => {
            if (countriesArray.includes(value.country)) {
                return value
            }
            return false
        })
        this.setState({
            coordinates,
            selectedCountry: data && data[0]?.label
        })
    }

    handleZoomIn = () => {
        const { position } = this.state
        if (position.zoom >= 4) return
        this.setState({
            position: { coordinates: [0, 0], zoom: position.zoom * 2 }
        })
        //setPosition((pos) => ({ ...pos, zoom: pos.zoom * 2 }));
    }

    handleZoomOut = () => {
        const { position } = this.state
        if (position.zoom <= 1) return
        this.setState({
            position: { coordinates: [0, 0], zoom: position.zoom / 2 }
        })
        //   setPosition((pos) => ({ ...pos, zoom: pos.zoom / 2 }));
    }

    handleMoveEnd = (position) => {
        // setPosition(position);
    }
    call = (countryInfo, index) => {
        this.setState(
            {
                selectedCountry: countryInfo.country,
                selectedCoordinate: index
            },
            () => {
                this.handleChange({ index, label: countryInfo.country })
            }
        )
    }

    handleChange = (value) => {
        const { setSelectedCountry } = this.props
        setSelectedCountry(value.index)
        this.setState({
            selectedCountry: value.label,
            selectedCoordinate: Number(value.index),
            selectedMenuCountry: { value: value.label, label: value.label }
        })
    }

    render() {
        const { position, selectedCountry, coordinates, selectedCoordinate } = this.state
        const { data } = this.props
        const geoUrl = WorldJSON
        /*   let colourStyles = {
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma(data.color);
        return {
          ...styles,
          backgroundColor: "#241d3b",
          color: "#FFFFFF",
        };
      },
    }; */

        //  "https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json";
        //https://codesandbox.io/s/react-select-selected-option-background-color-6re4j?file=/src/Select.js:198-515
        const options = data
        /*   { value: "India", label: "India" },
      { value: "China", label: "China" },
      { value: "Pakistan", label: "Pakistan" },
      { value: "Germany", label: "Germany" }, */
        let screenHeight = window.screen.availWidth <= 1440 ? 465 : 500
        let label = options && options[0].label
        return (
            <>
                <div className="dropdown-container">
                    {options && (
                        <Select
                            options={options}
                            defaultValue={{
                                value: options?.[0].value,
                                label: label
                            }}
                            value={this.state.selectedMenuCountry}
                            /*      menuIsOpen={false} */
                            cName={"selected-country"}
                            name={"selectedCountry"}
                            classNamePrefix={"multiSelect"}
                            onChange={this.handleChange}
                            //styles={colourStyles}
                            menuPlacement="auto" // Dropdown going out of display in small screen
                        />
                    )}
                </div>
                <ComposableMap
                    height={screenHeight}
                    projectionConfig={{
                        scale: 180,
                        rotate: [0, 10.0, 0],
                        center: [0, 20]
                    }}
                    style={{ width: "100%", height: "100%", overflow: "hidden" }}
                >
                    <ZoomableGroup
                        zoom={position.zoom}
                        center={position.coordinates}
                        onMoveEnd={this.handleMoveEnd}
                    >
                        <Geographies geography={geoUrl}>
                            {({ geographies }) =>
                                geographies?.map((geo) => (
                                    <Geography
                                        key={geo.rsmKey}
                                        geography={geo}
                                        /*    style={{
                      hover: {
                        fill: "#F53",
                        outline: "none",
                      },
                      pressed: {
                        fill: "#E42",
                        outline: "none",
                      },
                    }} */
                                        fill={
                                            geo.properties.name === selectedCountry
                                                ? "#00A3FA"
                                                : "#D5DBE5"
                                        }
                                        stroke="#FFF"
                                    />
                                ))
                            }
                        </Geographies>

                        {!isEmpty(coordinates) &&
                            coordinates?.map((value, index) => {
                                return (
                                    <Marker coordinates={[value.longitude, value.latitude]}>
                                        <circle
                                            r={5}
                                            fill="#FFFFFF"
                                            stroke={"black"}
                                            stroke-width={
                                                selectedCoordinate === index ? "5px" : "3px"
                                            }
                                            onClick={() => this.call(value, index)}
                                        />
                                    </Marker>
                                )
                            })}
                    </ZoomableGroup>
                </ComposableMap>
                <div className="country-selected">
                    <p title={selectedCountry && selectedCountry.toUpperCase()}>
                        {selectedCountry
                            ? selectedCountry.length > 12
                                ? selectedCountry.substring(0, 12).toUpperCase() + "..."
                                : selectedCountry
                            : ""}
                    </p>
                </div>
                <button className="zoom-in" onClick={this.handleZoomIn}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="21"
                        color="#000000"
                        viewBox="0 0 24 24"
                    >
                        <line
                            x1="12"
                            y1="5"
                            x2="12"
                            y2="19"
                            stroke="#000000"
                            fill="#000000"
                            stroke-width="2"
                            stroke-linecap="butt"
                        />
                        <line
                            x1="5"
                            y1="12"
                            x2="19"
                            y2="12"
                            fill="#000000"
                            stroke="#000000"
                            stroke-width="2"
                            stroke-linecap="butt"
                        />
                    </svg>
                </button>
                <button className="zoom-out" onClick={this.handleZoomOut}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="21"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="3"
                    >
                        <line
                            x1="5"
                            y1="12"
                            x2="19"
                            y2="12"
                            stroke="#000"
                            fill="#000000"
                            strokeWidth="3"
                        />
                    </svg>
                </button>
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({}, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MapChart)
