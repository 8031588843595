import React from "react"

import "./style.scss"

function ToolTopDropdown(props) {
    const { tooltiptext, innerText, handleReorder } = props
    let list = []
    for (let i = 1; i <= innerText; i++) {
        list.push(i)
    }
    return (
        <div className="tooltipDropdown">
            {tooltiptext}
            <div className="tooltip-wrapper">
                <span
                    className={
                        list.length > 5 ? "tooltiptext scroll" : "tooltiptext"
                    }>
                    {list.map((item) => {
                        return (
                            <p
                                className="innerText"
                                onClick={() =>
                                    handleReorder(tooltiptext - 1, item - 1)
                                }>
                                {item}
                            </p>
                        )
                    })}
                </span>
            </div>
        </div>
    )
}

export default ToolTopDropdown
