import React, { Component } from "react"
import { fetchProductList } from "../../APIs/action"
import get from "lodash/get"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import ProductCard from "./productCardList"
import Button from "../../../common/Button"
import "./style.scss"
import Search from "../../../common/Search"
import Filter from "../../../common/Filter"
import { BUTTON_CONSTANTS, TEXT_CONSTANTS } from "../../../../utils/constants"

class ProductList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            productList: []
        }
    }
    componentDidMount() {
        this.props.fetchProductList()
    }
    static getDerivedStateFromProps(nextProps, state) {
        let obj = {}
        if (nextProps.productList && state.productList !== nextProps.productList) {
            obj = {
                ...obj,
                productList: nextProps.productList
            }
        }
        return obj
    }
    loader = () => {
        let cardLoader = [1, 2, 3, 4]
        return cardLoader.map((item, index) => {
            return (
                <div key={`loader_${index}`} className="productCard-loader productCard">
                    <span className="imgLoader" />
                    <span className="cardNameLoader" />
                </div>
            )
        })
    }
    render() {
        const { productList } = this.state
        const { onClose, selectedProductId, handleCheckProduct, handleProceed } = this.props
        return (
            <div className="vr-popup-container" onClick={onClose}>
                <div className="vr-content-holder productList" onClick={(e) => e.stopPropagation()}>
                    <div className="vr-cont-head mb">
                        {/* TODO: Replace below with the reusable pageTitleComponent */}
                        <div className="vr-cont-title">
                            <h2 className="breadcrum-header">{TEXT_CONSTANTS?.PRODUCT_LIST}</h2>
                        </div>
                        <div className="vr-cont-action">
                            <Search
                                name="search"
                                changeHandler={() => {}}
                                placeholder="Search Content"
                            />
                            <Filter
                                cName="product_filter"
                                text="Select Genre"
                                id="editorialFilter"
                                defaultValue={""}
                                filterList={[{ name: "Genre", id: "1" }]}
                                changeHandler={() => {}}
                            />
                        </div>
                    </div>

                    <ul className="vr-product-listing">
                        {productList.length
                            ? productList.map(
                                  (product, index) =>
                                      product.active &&
                                      product.content.contentType === "MOVIES" && (
                                          <ProductCard
                                              item={product}
                                              index={index}
                                              selectedProductId={selectedProductId}
                                              handleCheckProduct={handleCheckProduct}
                                          />
                                      )
                              )
                            : this.loader()}
                    </ul>
                    <div className="button-grp">
                        <Button
                            cName={`btn primary-btn`}
                            disabled={selectedProductId === null}
                            bValue={BUTTON_CONSTANTS?.PROCEED}
                            bType="button"
                            clickHandler={() => handleProceed(productList[selectedProductId])}
                        />
                        <Button
                            cName="btn cancel-btn"
                            bValue={BUTTON_CONSTANTS?.CLOSE}
                            bType="button"
                            clickHandler={onClose}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        productList: get(state.productPlacement, "productList", [])
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                fetchProductList
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductList)
