import React, { useMemo, useState } from "react"
import {
    FORM_FIELDS_NAME,
    IS_MULTILINGUAL_FIELD,
    MULTILINGUAL_KEY,
    SELECTED_LANGUAGE,
    SETUP_SPROTS_CONSTANTS,
    SPORTS_TAB
} from "../../../APIs/constant"
import ImageUplode from "../../../../Movies/addMovie/imageUplode"
import {
    fetchFilterDropdownDataList,
    fetchSelectedSportsDataList,
    getSportsCategory,
    saveSelectedSportsData,
    uploadImage
} from "../../../APIs/action"
import { useDispatch, useSelector } from "react-redux"
import { formatValueAccToMutlilingualAPI, getMultiLingualValue, joinUrl } from "../../../../../utils/common"
import Input from "../../../../common/Input"
import CustomModalFooter from "../../../../common/Modal/CustomModalFooter"
import { BUTTON_CONSTANTS, ERROR_MESSAGES } from "../../../../../utils/constants"
import { isEmpty } from "lodash"
import { toast } from "react-toastify"
import { imagePathPrefix } from "../../../../../config/apiPath"
import TextArea from "../../../../common/TextArea"
import {
    categoryLeagueDropdownData,
    createFilterDataListPayload,
    getFilterId,
    getFormData,
    getSelectedIdName
} from "../../../APIs/helper"
import "./style.scss"
import Select1 from "../../../../common/SelectBox"

export default function AddNewCategoryForm({ prefillData, cancelHandler }) {
    const code = SELECTED_LANGUAGE?.code
    const { meta, id: editedElementId, parent, published } = prefillData ?? {}
    const [formData, setFormData] = useState({
        posterImage: meta?.posterImage ?? "",
        bannerImage: meta?.coverImage ?? "",
        name: meta?.name ?? "",
        category: parent
            ? formatValueAccToMutlilingualAPI(
                  getSelectedIdName(parent?.["0"], code),
                  code,
                  MULTILINGUAL_KEY
              )
            : "",
        shortDescription: meta?.shortDescription ?? "",
        description: meta?.description ?? "",
        league: parent
            ? formatValueAccToMutlilingualAPI(
                  getSelectedIdName(parent?.["1"], code),
                  code,
                  MULTILINGUAL_KEY
              )
            : "",
        published: published ?? false      
    })

    const dispatch = useDispatch()

    const sportsCategory = useSelector((state) => state?.sportsReducer?.sportsCategory)
    const sportsSetup = useSelector((state) => state.sportsReducer?.sportsSetup)
    const { paginationData, selectedTab, filterData, sportsCategoryId, sportsDropdownFilterList } =
        sportsSetup

    const formBasicDetails = useMemo(() => {
        return getFormData(selectedTab?.id)
    }, [selectedTab?.id])

    const imgUpload = async (data, id, name) => {
        const response = await dispatch(uploadImage(data))
        if (response && response?.status) {
            updateFormData(name, response.data.path)
        }
    }

    const removeUploadedImage = (name) => {
        updateFormData(name, "")
    }

    const dropDownDataHandler = (name, value, selectedOption) => {
        updateFormData(name, selectedOption)
    }

    const updateFormData = (name, value) => {
        let updatedMultilingualValue = { ...formData?.[name] }
        if (IS_MULTILINGUAL_FIELD) {
            updatedMultilingualValue[code] = value
        }
        setFormData((prevState) => {
            return {
                ...prevState,
                [name]: IS_MULTILINGUAL_FIELD
                    ? formatValueAccToMutlilingualAPI(
                          updatedMultilingualValue,
                          code,
                          MULTILINGUAL_KEY
                      )
                    : value
            }
        })
    }

    // Return true if any field value is empty
    const validatePayloadData = () => {
        let isInvalid = false
        if (formBasicDetails?.requriedFields) {
            formBasicDetails.requriedFields.forEach((item) => {
                if (
                    IS_MULTILINGUAL_FIELD &&
                    isEmpty(formData[item]?.values?.[SELECTED_LANGUAGE?.code])
                ) {
                    isInvalid = true
                }
            })
        }
        return isInvalid
    }

    const getFieldData = (data) => {
        return getMultiLingualValue(
            IS_MULTILINGUAL_FIELD,
            data,
            SELECTED_LANGUAGE?.code,
            MULTILINGUAL_KEY
        )
    }

    const formSubmitHandler = async (e) => {
        e.preventDefault()

        if (validatePayloadData()) {
            return toast.error(ERROR_MESSAGES.REQUIRED_FIELD_ERROR)
        }

        // API call to send the data
        let payload = {
            meta: {
                posterImage: formData?.posterImage,
                shortDescription: formData?.shortDescription,
                description: formData?.description,
                name: formData?.name
            },
            pageEntityId: selectedTab?.pageEntityId,
            categoryId: sportsCategoryId?.id,
            published: formData?.published
        }
        if (formData?.bannerImage) {
            payload.meta.coverImage = formData?.bannerImage
        }
        if (formData?.category) {
            payload.parent = {
                0: getFieldData(formData?.category)?.id,
                ...(formData?.league && { 1: getFieldData(formData?.league)?.id })
            }
        }
        // Add edit during editing
        if (editedElementId) {
            payload.id = editedElementId
        }
        const res = await dispatch(saveSelectedSportsData(payload))
        if (res?.status) {
            toast.success(res?.message)
            cancelHandler()
            let filterPayload = createFilterDataListPayload(paginationData, selectedTab, filterData)
            // Updating sports Tab count
            !editedElementId && dispatch(getSportsCategory())
            // Fetching latest records
            dispatch(fetchSelectedSportsDataList(filterPayload))
            // Updating filter dropdown data
            if (
                selectedTab?.id === SPORTS_TAB?.LEAGUES ||
                selectedTab?.id === SPORTS_TAB.SPORTS_CATEGORY
            ) {
                const filterDataId = getFilterId(sportsCategory)
                dispatch(fetchFilterDropdownDataList(filterDataId))
            }
        } else {
            toast.error(res?.message ?? ERROR_MESSAGES.SOMETHING_WENT_WRONG)
        }
    }

    const posterImageUploadPath = useMemo(() => {
        return getMultiLingualValue(
            IS_MULTILINGUAL_FIELD,
            formData.posterImage,
            SELECTED_LANGUAGE?.code,
            MULTILINGUAL_KEY
        )
    }, [formData.posterImage])
    const bannerImageUploadPath = useMemo(() => {
        return getMultiLingualValue(
            IS_MULTILINGUAL_FIELD,
            formData.bannerImage,
            SELECTED_LANGUAGE?.code,
            MULTILINGUAL_KEY
        )
    }, [formData.bannerImage])

    const dropdownFilterData = useMemo(() => {
        return categoryLeagueDropdownData(sportsDropdownFilterList, sportsCategory)
    }, [sportsDropdownFilterList, sportsCategory])

    return (
        <div className="sport-category-form">
            <h2>{formBasicDetails?.formTitle}</h2>
            <form className="category-input-form">
                <div className="input-form-image-container">
                    <ImageUplode
                        name={FORM_FIELDS_NAME.posterImage}
                        required
                        id={"posterImage"}
                        placeholder={`Upload ${selectedTab?.id !== SPORTS_TAB.SPORTS_CATEGORY ? 'Image' : 'Icon'}`}
                        value={
                            posterImageUploadPath
                                ? `${joinUrl(posterImageUploadPath)}`
                                : null
                        }
                        uploadImage={imgUpload}
                        removeImg={removeUploadedImage}
                        showRecommededSize={true}
                        recommendedName={'icon'}
                        className={'sports-poster-icon'}
                    />
                    {selectedTab?.id !== SPORTS_TAB.SPORTS_CATEGORY && (
                        <ImageUplode
                            name={FORM_FIELDS_NAME.bannerImage}
                            required
                            id={"bannerImage"}
                            placeholder="Upload Banner Image"
                            value={
                                bannerImageUploadPath
                                    ? `${joinUrl(bannerImageUploadPath)}`
                                    : null
                            }
                            uploadImage={imgUpload}
                            removeImg={removeUploadedImage}
                            showRecommededSize={true}
                        />
                    )}
                </div>
                <div className="form-input-fields">
                    <Input
                        type={"text"}
                        labelText={formBasicDetails?.inputFieldTitle}
                        name={FORM_FIELDS_NAME.name}
                        required={true}
                        value={getMultiLingualValue(
                            IS_MULTILINGUAL_FIELD,
                            formData.name,
                            SELECTED_LANGUAGE?.code,
                            MULTILINGUAL_KEY
                        )}
                        placeholder={formBasicDetails?.placeholderTitle}
                        changeHandler={updateFormData}
                    />
                    {selectedTab?.id !== SPORTS_TAB.SPORTS_CATEGORY && (
                        <>
                            <Select1
                                key={"player-select-dropdown"}
                                placeholder="-select-"
                                options={dropdownFilterData?.sportsCategoryList}
                                label={SETUP_SPROTS_CONSTANTS?.selectSportCategoryLabel}
                                required={true}
                                name={FORM_FIELDS_NAME.category}
                                changeHandler={dropDownDataHandler}
                                value={getMultiLingualValue(
                                    IS_MULTILINGUAL_FIELD,
                                    formData.category,
                                    SELECTED_LANGUAGE?.code,
                                    MULTILINGUAL_KEY
                                )}
                                isAutoFocus={false}
                            />
                            {selectedTab?.id === SPORTS_TAB.MATCHES && (
                                <Select1
                                    key={"player-select-dropdown1"}
                                    placeholder="-select-"
                                    options={dropdownFilterData?.leaguesList}
                                    label={SETUP_SPROTS_CONSTANTS?.selectLeague}
                                    required={true}
                                    name={FORM_FIELDS_NAME.league}
                                    changeHandler={dropDownDataHandler}
                                    value={getMultiLingualValue(
                                        IS_MULTILINGUAL_FIELD,
                                        formData.league,
                                        SELECTED_LANGUAGE?.code,
                                        MULTILINGUAL_KEY
                                    )}
                                    isAutoFocus={false}
                                />
                            )}
                        </>
                    )}
                    <Input
                        type={"text"}
                        labelText={SETUP_SPROTS_CONSTANTS?.shortDetail}
                        name={FORM_FIELDS_NAME.shortDescription}
                        required={true}
                        value={getMultiLingualValue(
                            IS_MULTILINGUAL_FIELD,
                            formData.shortDescription,
                            SELECTED_LANGUAGE?.code,
                            MULTILINGUAL_KEY
                        )}
                        placeholder={SETUP_SPROTS_CONSTANTS?.sportShortDetailLabel}
                        changeHandler={updateFormData}
                        maxlength={50}
                    />
                    <TextArea
                        row={5}
                        col={5}
                        labelText={SETUP_SPROTS_CONSTANTS?.description}
                        placeholder={SETUP_SPROTS_CONSTANTS?.description}
                        required={true}
                        name={FORM_FIELDS_NAME.description}
                        value={getMultiLingualValue(
                            IS_MULTILINGUAL_FIELD,
                            formData.description,
                            SELECTED_LANGUAGE?.code,
                            MULTILINGUAL_KEY
                        )}
                        changeHandler={updateFormData}
                    />
                </div>
            </form>
            <footer className="modal_footer">
                <CustomModalFooter
                    cancelBtnHandler={cancelHandler}
                    submitBtnHandler={formSubmitHandler}
                    showCancelButton={true}
                    submitButtonLabel={BUTTON_CONSTANTS?.SAVE}
                    isSubmitDisabled={validatePayloadData()}
                />
            </footer>
        </div>
    )
}
