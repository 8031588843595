export const ACTION = {
    GET_ALL_PLATFORM: "GET_ALL_PLATFORM:",
    ADD_NEW_PLATFORM: "ADD_NEW_PLATFORM:",
    DELETE_PLATFORM: "DELETE_PLATFORM:",
    GET_ALL_PLATFORM_TYPE: "GET_ALL_PLATFORM_TYPE"
}

export const PLATFORM_TABLE_HEADING = [
    "S.no.",
    "Id",
    "icon",
    "name",
    "Type",
    "created",
    "updated",
    "action"
]
