import MultilingualService from "./service"
import { ACTION } from "./constant"

export const getLanguageListData = () => {
    return async (dispatch) => {
        return MultilingualService.getAllLanguage()
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_LANGUAGE_DATA,
                    apiResponse: response.data
                })
                return response?.data
            })
            .catch((error) => {
                console.log("Got error in getting form json ", error)
            })
    }
}

export const saveLanguageData = (params) => {
    return async () => {
        return MultilingualService.saveLanguageList(params)
            .then((response) => {
                return response
            })
            .catch((error) => {
                console.log("Got error in getting form json ", error)
            })
    }
}

export const setLanguagePrimaryStatus = (params) => {
    return async () => {
        return MultilingualService.languagePrimaryStatus(params)
            .then((response) => {
                return response
            })
            .catch((error) => {
                console.log("Got error in getting form json ", error)
            })
    }
}
export const setLanguageEnableStatus = (params) => {
    return async () => {
        return MultilingualService.languageEnableStatus(params)
            .then((response) => {
                return response
            })
            .catch((error) => {
                console.log("Got error in getting form json ", error)
            })
    }
}
