import React, { useEffect, useState } from "react"
import { debounce } from "../../../utils/common"
import Input from "../Input"
import Checkbox from "../Checkbox"

import "./style.scss"

export const SearchableList = ({
    searchableListHeading,
    searchableListItems = [],
    checkedListItems,
    checkboxHandleChange,
    isCheckbox = false,
    selectedItemChangeHandler,
    defaultSelectedItem,
    isSearchableListDisabled = false
}) => {
    const [searchedListItems, setSearchedListItems] = useState(searchableListItems)
    const [searchedString, setSearchedString] = useState("")
    const [selectedItem, setSelectedItem] = useState(defaultSelectedItem)

    useEffect(() => {
        setSearchedListItems(searchableListItems)
        setSelectedItem(defaultSelectedItem)
    }, [searchableListItems, defaultSelectedItem])

    const setSearchQuery = debounce((value) => {
        handleSearch(value)
    }, 400)

    const searchChangeHandler = (name, value) => {
        setSearchedString(value)
        setSearchQuery(value)
    }

    const handleSearch = (value) => {
        let searchList = []

        if (value === "" || value?.length === 0) {
            searchList = searchableListItems
        } else {
            searchList = searchableListItems?.filter((item) =>
                item?.name?.toLowerCase().includes(value?.toLowerCase())
            )
        }

        setSearchedListItems(searchList)
    }

    const findChecked = (name) => {
        let isChecked = false
        checkedListItems.map((item) => {
            if (item.name === name) {
                isChecked = true
                return isChecked
            }
            return item
        })
        return isChecked
    }

    const inputChangedHandler = (e, selectedItem) => {
        if (!isSearchableListDisabled) {
            setSelectedItem(selectedItem?.id)
            setSearchedString("")
            selectedItemChangeHandler(selectedItem)
        }
    }

    const getTrimmedString = (inputString) => {
        return inputString?.replace(/\s/g, "")?.replace(":", "")
    }

    const renderListItems = () => {
        if (isCheckbox) {
            return searchedListItems?.map((item, index) => {
                return (
                    <li key={index}>
                        <Checkbox
                            cName="checklist"
                            labelText={item.name}
                            id={item.id}
                            name={item.name}
                            isChecked={findChecked(item.name)}
                            changeHandler={checkboxHandleChange}
                        />
                    </li>
                )
            })
        }

        return searchedListItems?.map((item) => {
            return (
                <li
                    key={item?.id}
                    className={`searchable-options-list-item ${
                        isSearchableListDisabled ? "list-disabled" : ""
                    }`}
                    onClick={(e) => inputChangedHandler(e, item)}
                >
                    <p>{item?.label}</p>
                    {getTrimmedString(selectedItem) === getTrimmedString(item?.id) && (
                        <p>&#10003;</p>
                    )}
                </li>
            )
        })
    }

    return (
        <div className="searchable-list-container">
            <label htmlFor="pl-searchBox" className="label-text">
                {searchableListHeading}
            </label>
            <div className="pl-search">
                <span className="icon-search"></span>
                <Input
                    type="text"
                    changeHandler={searchChangeHandler}
                    name="searchPermission"
                    cName="searchInput"
                    value={searchedString}
                />
            </div>
            <div className="searchable-options">
                <ul>{renderListItems()}</ul>
            </div>
        </div>
    )
}
