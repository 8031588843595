import ApiService from "../../../utils/apiService"
import { API_ROOT_PATH } from "../../../config/apiPath"

class MetadataIngestionSerivce {
    submitContentType(data, timezone) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content-ingestion/content-ingestion/ingestion/uploadFile`,
            isAuthRequired: true,
            data: data,
            headers: { "X-TIMEZONE": timezone }
        }
        return ApiService(options)
    }

    downloadTemplateFile(queryParams) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/upload/getTemplateFile?${queryParams}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    getMetadataIngestionRecords(data) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/content-summary/list`,
            isAuthRequired: true,
            data: data
        }
        return ApiService(options)
    }
    getSuccessFailureRecords(status, summaryId) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/content-detail/fetchDetails?status=${status}&summaryId=${summaryId}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
    downloadFailureRecords(summaryId) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/content-detail/downloadIngestionSummary?status=false&summaryId=${summaryId}`,
            isAuthRequired: true,
            responseType: "blob"
        }
        return ApiService(options)
    }
    getAllTimezone() {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/timezone/getAll`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
}
const MetadataIngestionSerivceInstance = new MetadataIngestionSerivce()
export default MetadataIngestionSerivceInstance
