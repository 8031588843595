import React from "react"
import Select from "react-select"
import "./style.scss"

// const ValueContainer = (props, classProps) => {
//   const selected = props.getValue();
//   const content = ((length) => {
//     switch (length) {
//       case 0:
//         return props.children;
//       case 1:
//         return classProps.optionName
//           ? selected[0][classProps.optionName]
//           : selected[0].name;
//       default:
//         return `${selected.length} Selected`;
//     }
//   })(selected.length);

//   return (
//     <components.ValueContainer {...props}>{content}</components.ValueContainer>
//   );
// };

// const Option = (props) => {
//   return (
//     <components.Option {...props}>
//       <span className="checkboxContainer">
//         <span
//           className={
//             props.isSelected ? "checkboxStyleActive" : "checkboxStyleDisable"
//           }
//           id={`${props.isSelected ? "tue" : "fal"}`}
//         />{" "}
//       </span>
//       <label
//         className={`option-label ${props.isSelected && "selectedOptionLabel"}`}
//       >
//         {props.label}
//       </label>
//     </components.Option>
//   );
// };

export default class CustomSelect extends React.Component {
    handleChange = (selectedOptions, action) => {
        const { changeHandler, id } = this.props
        if (selectedOptions) {
            changeHandler(id, selectedOptions)
        } else {
            changeHandler(id, [])
        }
    }

    render() {
        const {
            options,
            label,
            required,
            id,
            placeholder,
            selectedOptions,
            selectedValues,
            errMsg,
            isDisabled,
            optionName,
            optionId
        } = this.props

        return (
            <div className={`multi-select-container ${errMsg ? `inputErr` : ""}`}>
                {label && (
                    <label htmlFor={id}>
                        {label}
                        <span className={required ? "required" : "notRequired"}>*</span>
                    </label>
                )}
                <Select
                    options={options}
                    placeholder={placeholder}
                    defaultValue={selectedOptions}
                    value={selectedValues && selectedValues.length ? selectedValues : []}
                    onChange={this.handleChange}
                    isMulti
                    isDisabled={isDisabled}
                    id={id}
                    openMenuOnClick={true}
                    hideSelectedOptions={false}
                    isSearchable={true}
                    className={"multiSelect-container"}
                    classNamePrefix={"multiSelect"}
                    getOptionLabel={(options) => (optionName ? options[optionName] : options.name)}
                    getOptionValue={(options) => (optionId ? options[optionId] : options.id)}
                />
                {errMsg && <span className="err">{errMsg}</span>}
            </div>
        )
    }
}
