import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import { MenuTokenFun } from "./MenuTokenConstant"
import Loader from "../../assets/images/loader.gif"
import { sendMagnaRequest } from "../Monetize/APIs/action"

import "./style.scss"
import { BUTTON_CONSTANTS, MASTER_TENANT_IDENTIFIER_NAME } from "../../utils/constants"
import Button from "../common/Button"

class MagnaQuest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            isFormVisible: true,
            isMasterTenant: props?.tenantIdentifier === MASTER_TENANT_IDENTIFIER_NAME,
            menuToken: MenuTokenFun()
        }
        this.formRef = React.createRef()
        let location = this.props?.location?.pathname?.split("/")
        this.pageName = props.path
            ? props.path
            : location
            ? location[this.props?.location?.pathname?.split("/").length - 1]
            : ""
    }

    submitForm() {
        document.getElementById("login") && document.getElementById("login").submit()
        this.setState({
            isFormVisible: false
        })
    }

    componentDidMount() {
        // let bodyFormData = new FormData();
        // bodyFormData.append("KEY", "TTNADMIN");
        // bodyFormData.append("TOKEN", "882");
        // bodyFormData.append("MENUID", "1008");
        //  this.getMagna(bodyFormData);

        this.submitForm()
    }

    getMagna = async (bodyFormData) => {
        await this.props.sendMagnaRequest(bodyFormData)
    }
    iframeloaded = () => {
        this.setState({
            isLoading: false
        })
    }

    navigateToBack = () => {
        const { navigateToBack } = this.props
        navigateToBack()
    }

    handleBackBtn = () => {
        this.setState(
            {
                isLoading: true,
                menuToken: MenuTokenFun(),
                isFormVisible: true
            },
            () => {
                this.submitForm()
            }
        )
    }

    showBackButton = () => {
        if (this.pageName === "product" || this.pageName === "coupons") {
            return true
        }
        return false
    }

    render() {
        const { isLoading, isFormVisible, menuToken } = this.state
        const showBackButton = this.showBackButton()
        return (
            <>
                <div className={`magnaquest-container ${showBackButton ? "back-btn-style" : ""}`}>
                    {isLoading && (
                        <div className="loader">
                            <img src={Loader} width={"100px"} alt="Loader" />
                        </div>
                    )}
                    <>
                        {isFormVisible && (
                            <form
                                id="login"
                                action="https://demo-ttnsandbox.magnaquest.com/portalIntegration.aspx"
                                //   action="http://demo-ttnsandbox.magnaquest.com/portalIntegration.aspx"
                                method="POST"
                                name="myForm"
                                target="my_frame"
                                ref={this.formRef}
                            >
                                <input
                                    type="hidden"
                                    name="KEY"
                                    id="KEY"
                                    value={menuToken[this.pageName]?.KEY}
                                />
                                {/* <input type="hidden" name="THEME" id="THEME" value="dark" /> */}
                                <input
                                    type="hidden"
                                    name="TOKEN"
                                    value={menuToken[this.pageName]?.TOKEN}
                                />
                                <input type="hidden" name="SHOWMENU" value="0" />
                                <input
                                    type="hidden"
                                    name="MENUID"
                                    value={menuToken[this.pageName]?.MENUID}
                                />

                                <input type="hidden" name="SHOWTITLE" value="true" />
                                <input type="hidden" name="ISPOPUP" value="N" />
                                <input
                                    type="hidden"
                                    name="CALLBACK_URL"
                                    id="CALLBACK_URL"
                                    value="https://www.google.com"
                                />
                            </form>
                        )}
                        {showBackButton && (
                            <Button
                                cName="btn border-btn magnaquest-back-btn"
                                bValue={BUTTON_CONSTANTS?.BACK}
                                clickHandler={this.handleBackBtn}
                                leftIcon="icon-arrow-left"
                            />
                        )}

                        <iframe
                            name="my_frame"
                            id="iframe_1"
                            width={"100%"}
                            height={"100%"}
                            sandbox="allow-scripts allow-forms allow-same-origin allow-popups"
                            allowFullScreen="allowfullscreen"
                            onLoad={this.iframeloaded}
                            title="magnaquest-iframe"
                        ></iframe>
                    </>
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        tenantIdentifier: state?.AdminHomeReducer?.tenantInfo?.data?.tenantIdentifier
    }
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(
            {
                sendMagnaRequest: sendMagnaRequest
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MagnaQuest)
