import ApiService from "../../../utils/apiService"
import { API_ROOT_PATH } from "../../../config/apiPath"

class UserManagementService {
    fetchPermissionsList(prams) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/permission/`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    fetchAllRoles() {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/role/`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    fetchRolesList(params) {
        const { length, start, searchTag } = params
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/role/listRoles?length=${length}&searchTag=${searchTag}&start=${start}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    AddRole(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/role/create`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }

    addUserDetails(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/user/create`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }

    softDeleteRole(id) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/role/softDelete/${id}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    fetchUsersList(params) {
        const { length, start, userRole, userType, searchTag } = params
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/user/listUsers?length=${length}&searchTag=${searchTag}&start=${start}&userRole=${userRole}&userType=${userType}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    // fetchAllUsersList(params){
    //   const options = {
    //     method: 'GET',
    //     url: `${API_ROOT_PATH}/user/`,
    //     isAuthRequired: true,
    // }
    // return ApiService(options);
    // }

    changeUserState(id) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/user/deactivate/${id}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    softDeleteUser(id) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/user/softDelete/${id}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
}

const UserManagementServiceInstance = new UserManagementService()
export default UserManagementServiceInstance
