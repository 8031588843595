import ApiService from "../../../utils/apiService"
import { API_ROOT_PATH } from "../../../config/apiPath"

class ContentService {
    fetchFormElement(id) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/entityAttribute/${id}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    createContent(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/entityValue/insertData`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }

    fetchContent(id) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/entityValue/preview/${id}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
    updateContent(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/entityValue/update`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }
    fetchEntityValueMasterData(params) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/entityValue/master-data`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
    fetchContentListing(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/core/data/content`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }

    uploadImage(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/core/document/upload`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }

    deleteImage(id) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/core/document/delete/${id}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    deleteContent(ids) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/entityValue/delete`,
            isAuthRequired: true,
            data: ids
        }
        return ApiService(options)
    }

    fetchTaggedContentInRail(ids) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/editorial/rail/content`,
            isAuthRequired: true,
            data: ids
        }
        return ApiService(options)
    }

    togglePublish(ids) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/entityValue/publish`,
            isAuthRequired: true,
            data: ids
        }
        return ApiService(options)
    }
}

const ContentServiceInstance = new ContentService()
export default ContentServiceInstance
