export const profileResolution = [
    {
        name: "720",
        label: "720"
    },
    {
        name: "980",
        label: "980"
    },
    {
        name: "1280",
        label: "1280"
    },
    {
        name: "1440",
        label: "1440"
    },
    {
        name: "1920",
        label: "1920"
    }
]

// export const profileResolutionaArray = [160, 240, 360, 480, 720, 1080];
export const profileResolutionaArray = ["720", "540", "360", "270"]

export const HLS_DASH_RENDITION = ["720", "540", "360", "270"]

export const VOD_ERROR_MESSAGES = {
    cancelled: "Cancelled",
    preSigned: "Something went wrong!",
    initialized: "Something went wrong!",
    complete: "Something went wrong!"
}

export const DRM_VALUES = [
    { id: 0, name: "Yes" },
    { id: 1, name: "No" }
]

export const TRANSCODING_CONTENT_TYPE_OPTIONS = [
    { id: "10", name: "Movie", value: "Movie" },
    { id: "11", name: "Series", value: "Series" },
    { id: "12", name: "Trailer", value: "Trailer" }
]

export const TRANSCODING_PROTOCOL_VALUES = [
    {
        id: 110,
        name: "Choose Protocol"
    },
    {
        id: 111,
        name: "Custom Profile"
    }
]
