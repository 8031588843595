import { removeLoader, setLoader } from "../../common/Loader/api/action"
import { ACTION } from "./constant"
import EpgServicesInstance from "./service"

export const fetchEpgContentLiveTV = (params) => {
    return (dispatch) => {
        dispatch(setLoader())
        return EpgServicesInstance.fetchEpgContentLiveTV(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_EPGCONTENT_LIVE_TV,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in creating text page ", error)
            })
            .finally(() => {
                dispatch(removeLoader())
            })
    }
}

export const addEpgShow = (params, channelId, TIMEZONE) => {
    return (dispatch) => {
        dispatch(setLoader())
        return EpgServicesInstance.addEpgShow(params, channelId, TIMEZONE)
            .then((response) => {
                dispatch({
                    type: ACTION.ADD_EPG_SHOW,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error, response) => {
                console.error(error)
                console.log("Got error in adding epg show ", error, error.response)
                if (error) {
                    return error?.message
                }
            })
            .finally(() => {
                dispatch(removeLoader())
            })
    }
}

export const uploadImage = (params) => {
    return (dispatch) => {
        dispatch(setLoader())
        return EpgServicesInstance.uploadImage(params)
            .then((response) => {
                dispatch({
                    type: ACTION.UPLOAD_IMAGE,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in upload image ", error)
            })
    }
}

export const fetchAddEpgOptionData = (params) => {
    return (dispatch) => {
        dispatch(setLoader())
        return EpgServicesInstance.fetchAddEpgOptionData(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_ADD_EPG_OPTION_DATA,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in fetching data ", error)
            })
            .finally(() => {
                dispatch(removeLoader())
            })
    }
}

export const fetchEpgTvShow = (channelId, date, timezone) => {
    return (dispatch) => {
        dispatch(setLoader())
        return EpgServicesInstance.fetchEpgTvShow(channelId, date, timezone)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_EPG_TV_SHOW,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in fetching epg tv show ", error)
            })
            .finally(() => {
                dispatch(removeLoader())
            })
    }
}

export const softDeleteEpgShow = (params) => {
    return (dispatch) => {
        return EpgServicesInstance.softDeleteEpgShow(params)
            .then((response) => {
                dispatch({
                    type: ACTION.SOFT_DELETE_EPG_SHOW,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in deleting epg show ", error)
            })
    }
}
