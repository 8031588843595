import ApiService from "../../../../utils/apiService"
import { API_TENANT_ROOT_PATH } from "../../../../config/apiPath"

class AdminComponentService {
    //--text pages services---
    createTenant(params) {
        const options = {
            method: "POST",
            url: `${API_TENANT_ROOT_PATH}/multitenancy/v1/tenant/create`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }

    getTenants(params) {
        const options = {
            method: "GET",
            url: `${API_TENANT_ROOT_PATH}/multitenancy/v1/tenant/`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    getTenantsById(params) {
        const options = {
            method: "GET",
            url: `${API_TENANT_ROOT_PATH}/multitenancy/v1/tenant/${params ? params : ""}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
    getPaginatedTenants(params) {
        const options = {
            method: "POST",
            url: `${API_TENANT_ROOT_PATH}/multitenancy/v1/tenant/filteredListCMS`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }

    isExists(tenantCode) {
        const options = {
            method: "GET",
            url: `${API_TENANT_ROOT_PATH}/multitenancy/v1/tenant/isExist/${tenantCode}`,
            isAuthRequired: true
        }

        return ApiService(options)
    }

    uploadLogo(params) {
        const options = {
            method: "POST",
            url: `${API_TENANT_ROOT_PATH}/multitenancy/v1/tenant/uploadLogo`,
            isAuthRequired: true,
            data: params
        }

        return ApiService(options)
    }

    deactivateTenant(params) {
        const options = {
            method: "POST",
            url: `${API_TENANT_ROOT_PATH}/multitenancy/v1/deactivate/${params}`,
            isAuthRequired: true,
            data: params
        }

        return ApiService(options)
    }

    isExistSubdomain(params) {
        const options = {
            method: "GET",
            url: `${API_TENANT_ROOT_PATH}/multitenancy/v1/tenant/isExistBySubDomain/${params}`,
            isAuthRequired: true
        }

        return ApiService(options)
    }

    isExistEmailMobile(params) {
        const options = {
            method: "GET",
            url: `${API_TENANT_ROOT_PATH}/multitenancy/v1/tenant/isExistByEmailOrPhone?email=${params?.email}&phone=${params?.mobile}`,
            isAuthRequired: true
        }

        return ApiService(options)
    }
}

const AdminComponentInstance = new AdminComponentService()
export default AdminComponentInstance
