import { isEmpty } from "lodash"
import React, { useEffect, useState } from "react"

import "./style.scss"

const FileUpload = (props) => {
    const { cName, name, id, value, label, size, disabled, fileUpload, required, image } = props
    const [file, setFile] = useState("Choose file")

    useEffect(() => {
        !isEmpty(value) && setFile("auth.der")
    }, [value])

    function uploadFile(data) {
        fileUpload && fileUpload(data)
    }

    function deleteFile(data) {
        setFile("Choose file")
        if (document.getElementById(name)) {
            document.getElementById(name).value = null
        }
        fileUpload && fileUpload()
    }

    return (
        <div className={`file-upload ${cName ? cName : ""}`}>
            <div className="form-input">
                <label>
                    {`${label}`}
                    <span
                        className="required"
                        style={{
                            display: required ? "inline" : "none",
                            // color: "#1FB1FF",
                            marginLeft: "5px"
                        }}
                    >
                        *
                    </span>
                </label>
            </div>
            <label className="file-input">
                <label for={name}>
                    {image && <img src={image} alt="file-icon"></img>}
                    {file}
                </label>
                <i onClick={() => deleteFile()} className="icon-del"></i>
            </label>
            <input
                type={"file"}
                data-id={id}
                id={name}
                name={name}
                onChange={(e) => {
                    const { files } = e.target
                    let data = new FormData()
                    setFile(files[0]?.name)
                    data.append("der", files[0])
                    uploadFile(data)
                }}
                disabled={disabled}
            />
            {size && <span className="file-size">{size}</span>}
        </div>
    )
}

export default FileUpload
