import React, { Component } from "react"
import ContentEntity from "../ContentEntity"
import EpisodeList from "./EpisodeList"
import AddEpisode from "./AddEpisode"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { uploadImage } from "../api/action"
import { formatAndAssignValue, isFieldMultilingual } from "../../../utils/common"
import { isEmpty } from "lodash"
import Input from "../../common/Input"
class AddSeason extends Component {
    constructor(props) {
        super(props)
        this.newEpisodeData = {
            id: "",
            contentType: "",
            active: true,
            episodeTitle: "",
            playUrl: "",
            playDuration: "",
            description: "",
            videoFormat: [],
            episodeAirDate: "",
            rating: "",
            hlsPlayUrl: "",
            drm: "",
            dashPlayUrl: "",
            // certification: "",
            episode_id: "",
            drm_asset_id: ""
        }
        this.state = {
            currentDataSource: "web_series",
            episodeFormData: null,
            expanded: true,
            episodes: props.preFillData ? props?.preFillData?.["addEpisode"]?.["episode"] : [],
            isNewEpisode: !props.preFillData?.["addEpisode"]?.["episode"]?.length,
            isEditEpisode: false,
            currentEditEpIndex: 0,
            data: props?.preFillData,
            isAllFilled: false,
            episodeContainerNumber: 0,
            newEpisodeCreated: false,
            episodeEditedId: null
        }
        this.checkRequiredObj = {}
        this.screenName = "addSeason"
    }
    loadEpisodeForm = (episodeFormData) => {
        if (this.state?.episodeFormData) {
            return false
        }
        this.setState({
            episodeFormData: episodeFormData
        })
    }
    updateRequiredFields = (name, value) => {
        this.checkRequiredObj[name] = value
    }

    checkValues = (name, obj) => {
        if (
            name === "id" ||
            name === "url" ||
            name === "name" ||
            name === "season" ||
            name === "published"
        ) {
            return true
        }
        for (let key in obj) {
            if (name === key) {
                if (Array.isArray(obj[key])) {
                    return obj[key].length
                } else if (typeof obj[key] === "object" && obj[key] !== null) {
                    if (Object.keys(obj).length === 0 && obj.constructor === Object) {
                        return false
                    } else {
                        return true
                    }
                } else {
                    return obj[key].length
                }
            } else if (typeof obj[key] === "object" && obj[key] !== null) {
                if (this.checkValues(name, obj[key]) !== undefined) {
                    return this.checkValues(name, obj[key])
                }
            }
        }
    }
    componentDidUpdate(prevprops) {
        let ele = document.getElementById(this.state?.episodeEditedId)
        if (ele) {
            this.setState({
                episodeEditedId: null
            })
            setTimeout(() => {
                ele.scrollIntoView({
                    block: "center"
                })
            }, 0)
        }
        if (prevprops !== this.props) {
            this.setState({
                episodes: this.props.preFillData
                    ? this.props.preFillData["addEpisode"]["episode"]
                    : [],
                data: this.props.preFillData
                // To make content enrichment and IMDB modal visible in episodes
                // isNewEpisode: !this.props.preFillData["addEpisode"]["episode"].length,
                // expanded: false,
            })
        }
    }

    checkRequiredFields = (obj) => {
        let allFilled = true
        for (let key in this.checkRequiredObj) {
            if (this.checkRequiredObj[key]) {
                allFilled = this.checkValues(key, obj)
                if (!allFilled) {
                    return false
                }
            }
        }
        return allFilled
    }
    handleChange = (name, value, id) => {
        let stateData = this.state.data
        const isMulti = isFieldMultilingual(name, "season", this.props?.multilingualKeys)
        const { code } = this.props.selectedLanguage

        if (name === "genre" || name === "language") {
            let newOptions = value.map((item) => {
                return { id: item.id }
            })
            stateData[name] = newOptions
        } else if (name === "rating") {
            stateData[name] = { id: value, name: value }
        } else if (name === "age_rating") {
            stateData[name] = { id: value, name: value }
        } else if (name === "seasonYear") {
            stateData[name] = value
        } else if (name === "videoFormat") {
            if (value) {
                stateData[name].push({
                    id: id,
                    name: id
                })
            } else {
                let newVideoFormats = stateData[name].filter((element) => {
                    return element.id !== id
                })
                stateData[name] = newVideoFormats
            }
        } else {
            // stateData[name] = value;
            stateData[name] = formatAndAssignValue(isMulti, code, stateData[name], value)
        }
        this.setState({
            data: stateData
        })
        this.props.handleSave(this.props.index, this.state.data)
    }
    imgUpload = async (data, id, name) => {
        const response = await this.props.uploadImage(data)
        if (response && response.status) {
            let imgUrl = response.data.path
            this.handleChange(name, imgUrl, id)
        }
    }
    imgRemove = (name) => {
        let stateData = this.state.data
        stateData[name] = ""
        this.setState({
            data: stateData
        })
    }
    toggelExpand = () => {
        this.setState({
            expanded: !this.state.expanded
        })
    }
    addEpisodes = (currentEdit, index, obj) => {
        let episodes = []
        if (this.state?.episodes) {
            episodes = JSON.parse(JSON.stringify(this.state?.episodes))
        }
        if (this.state.isNewEpisode && !obj?.scrollId && !currentEdit) {
            obj.scrollId = Date.now()
            this.setState({ episodeEditedId: obj.scrollId })
        }
        if (currentEdit) {
            episodes[index] = obj
        } else {
            delete obj.id
            episodes.push(obj)
        }
        let stateData = this.state.data
        stateData.addEpisode.episode = episodes
        const activeElement = document.activeElement
        const isInsideEpisodeContainer = activeElement.closest(".episode-form-container")

        const episodeContainer = isInsideEpisodeContainer.getAttribute(
            "data-episode-container-number"
        )

        this.setState(
            {
                episodes: episodes,
                isNewEpisode: false,
                data: stateData,
                episodeContainerNumber: episodeContainer,
                newEpisodeCreated: false
            },
            () => {
                // document.getElementsByClassName("episode-form-container") &&
                //   !isEmpty(this.state?.episodeContainerNumber) &&
                //   document
                //     .getElementsByClassName("episode-form-container")
                //     [this.state.episodeContainerNumber].scrollTo(0, 0);
                this.props.handleSave(this.props.index, this.state.data)
            }
        )
        this.props.handleSave(this.props.index, this.state.data)
    }

    handleEpisodeCancel = () => {
        const activeElement = document.activeElement
        const isInsideEpisodeContainer = activeElement.closest(".episode-form-container")

        if (isInsideEpisodeContainer) {
            const episodeContainer = isInsideEpisodeContainer.getAttribute(
                "data-episode-container-number"
            )
            let episodeEditedId = this.state.episodeEditedId
            if (this.state.newEpisodeCreated) {
                episodeEditedId = `season-add-new-${this.props?.index}`
                // this.setState({episodeEditedId: `season-add-new-${this.props?.index}`})
            }

            this.setState(
                {
                    episodeContainerNumber: episodeContainer,
                    episodeEditedId,
                    newEpisodeCreated: false
                },
                () => {
                    // document.getElementsByClassName("episode-form-container") &&
                    //   !isEmpty(this.state?.episodeContainerNumber) &&
                    //   document
                    //     .getElementsByClassName("episode-form-container")
                    //     [this.state.episodeContainerNumber].scrollTo(0, 0);
                    this.setState({
                        isNewEpisode: false
                    })
                }
            )
        }
    }
    handleAddNewEpisode = () => {
        this.setState({
            isNewEpisode: true,
            isEditEpisode: false,
            newEpisodeCreated: true
        })
    }
    eidtEpisode = (index, episodeId) => {
        this.setState({
            isNewEpisode: true,
            isEditEpisode: true,
            currentEditEpIndex: index,
            episodeEditedId: episodeId
        })
    }
    deleteEpisode = (index) => {
        let episodes = JSON.parse(JSON.stringify(this.state.episodes))
        episodes.splice(index, 1)
        let stateData = this.state.data
        stateData.addEpisode.episode = episodes
        this.setState({
            episodes: episodes,
            data: stateData
        })
    }

    handleSeasonChange = (e) => {
        const { data } = this.state
        if (Number(e.target.value) > 100) {
            return
        }
        data.seasonNumber = e.target.value
        this.setState({
            data
        })
        //  this.setState({ [e?.target?.name]: e?.target?.value });
    }

    editSeasonName = (index) => {
        this.setState({
            [`enable_editindex${index}`]: !this.state[`enable_editindex${index}`]
        })
    }

    onBlur = (index) => {
        this.editSeasonName(index)
        console.log("on blur")
    }

    render() {
        let className = this.state.expanded
            ? "season-content-form-container show"
            : "season-content-form-container hide"
        let addText = this.state.expanded ? "SEASON" : "SEASON"
        let getExpandIcon = this.state.expanded ? "- " : "+ "
        let isEditable =
            typeof this.state?.[`enable_editindex${this.props?.index}`] == "boolean" ? true : false

        const commonPropsForEpisode = {
            isModalOpen: this.props.isModalOpen,
            ceDataLoadingState: this.props.ceDataLoadingState,
            isModalViewOpen: this.props.isModalViewOpen,
            episodeIndex: this.props.episodeIndex,
            isImdbSuccessModal: this.props.isImdbSuccessModal,
            imdbLoadingState: this.props.imdbLoadingState,
            imdbData: this.props.imdbData,
            contentEnrichmentBtnHandler: this.props.contentEnrichmentBtnHandler,
            submitModalHandler: this.props.submitModalHandler,
            fetchVideoMarkers: this.props.fetchVideoMarkers,
            contentEnrichmentViewBtnHandler: this.props.contentEnrichmentViewBtnHandler,
            contentMarkers: this.props.contentMarkers,
            openImdbModal: this.props.openImdbModal,
            getContentEnrichment: this.props.getContentEnrichment,
            viewData: this.props.viewData,
            seasonIndex: this.props.index,
            selectedLanguage: this.props?.selectedLanguage,
            multilingualKeys: this.props?.multilingualKeys,
            drmResponse: this.props?.drmResponse,
            isEdit: this.state?.isEditEpisode
        }
        return (
            <>
                <div className="add-season-text">
                    {
                        <span className="plus-minus" onClick={this.toggelExpand}>
                            {getExpandIcon}{" "}
                        </span>
                    }
                    {addText}
                    <p>
                        {!isEmpty(this.state?.data.seasonNumber) && !isEditable && (
                            <span className="season-number">{this.state?.data.seasonNumber}</span>
                        )}
                        {(isEmpty(this.state?.data.seasonNumber) || isEditable) &&
                            (this.state?.[`enable_editindex${this.props?.index}`] === true ||
                            this.state?.[`enable_editindex${this.props?.index}`] == null ? (
                                <Input
                                    inputType="Integer"
                                    type="number"
                                    maxLength={2}
                                    maxValue={50}
                                    id={"season" + this.props.index}
                                    minValue={1}
                                    onBlur={() => this.onBlur(this.props.index)}
                                    disabled={!this.state[`enable_editindex${this.props?.index}`]}
                                    value={this.state?.data.seasonNumber}
                                    name={`season_${this.state?.data.seasonNumber}`}
                                    onChange={this.handleSeasonChange}
                                    cName={
                                        this.state[`enable_editindex${this.props?.index}`] &&
                                        "enable"
                                    }
                                />
                            ) : (
                                <span className="season-number">
                                    {this.state?.data.seasonNumber}
                                </span>
                            ))}
                    </p>
                    {(isEmpty(this.state?.data.seasonNumber) || isEditable) && (
                        <span
                            className="icon-edit"
                            id={this.state?.data.seasonNumber}
                            style={{ backgroundColor: "transparent" }}
                            onClick={() => this.editSeasonName(this.props.index)}
                        ></span>
                    )}
                    <span
                        className="icon-del"
                        style={{ backgroundColor: "transparent" }}
                        onClick={() => this.props.deleteSeason(this.props.index)}
                    ></span>
                </div>
                <div className={className}>
                    <div className="col-half season-form-container">
                        <ContentEntity
                            loadEpisodeForm={this.loadEpisodeForm}
                            data={this.props.data}
                            formMetaData={this.props.formMetaData}
                            handleChange={this.handleChange}
                            uploadImage={this.imgUpload}
                            removeImg={this.imgRemove}
                            preFillData={this.state.data}
                            updateRequiredFields={this.updateRequiredFields}
                            selectedLanguage={this.props?.selectedLanguage}
                            certifications={this.props.certifications}
                            multilingualKeys={this.props?.multilingualKeys}
                            selectedData={this?.props?.selectedData}
                        ></ContentEntity>
                    </div>
                    <div className="col-half episode-form-container">
                        {this.state?.isNewEpisode || !this.state?.episodes?.length ? (
                            this.state?.isEditEpisode ? (
                                <AddEpisode
                                    handleEpisodeCancel={this.handleEpisodeCancel}
                                    handleSaveEpisode={this.addEpisodes}
                                    data={this.state.episodeFormData}
                                    index={this.state.currentEditEpIndex}
                                    currentEdit={true}
                                    formMetaData={this.props.formMetaData}
                                    preFillData={this.state.episodes[this.state.currentEditEpIndex]}
                                    webseriesData={this.props.webseriesData}
                                    contentType={this.props.contentType}
                                    certifications={this.props.certifications}
                                    selectedData={
                                        this?.props?.selectedData?.episode?.[
                                            this.state.currentEditEpIndex
                                        ]
                                    }
                                    {...commonPropsForEpisode}
                                    seasonNumber={this.state.data.seasonNumber}
                                />
                            ) : (
                                <AddEpisode
                                    handleEpisodeCancel={this.handleEpisodeCancel}
                                    handleSaveEpisode={this.addEpisodes}
                                    currentEdit={false}
                                    formMetaData={this.props.formMetaData}
                                    data={this.state.episodeFormData}
                                    preFillData={JSON.parse(JSON.stringify(this.newEpisodeData))}
                                    webseriesData={this.props.webseriesData}
                                    contentType={this.props.contentType}
                                    certifications={this.props.certifications}
                                    selectedData={JSON.parse(JSON.stringify(this.newEpisodeData))}
                                    {...commonPropsForEpisode}
                                    seasonNumber={this.state.data.seasonNumber}
                                />
                            )
                        ) : (
                            <EpisodeList
                                episodeList={this.state.episodes}
                                seasonIndex={this.props.index}
                                handleAddNewEpisode={this.handleAddNewEpisode}
                                deleteEpisode={this.deleteEpisode}
                                eidtEpisode={this.eidtEpisode}
                            />
                        )}
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                uploadImage
            },
            dispatch
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddSeason)
