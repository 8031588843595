import { imagePathPrefix } from "../../../config/apiPath"

export const ACTION = {
    FETCH_EPGCONTENT_LIVE_TV: "FETCH_EPGCONTENT_LIVE_TV",
    ADD_EPG_SHOW: "ADD_EPG_SHOW",
    UPLOAD_IMAGE: "UPLOAD_IMAGE",
    FETCH_ADD_EPG_OPTION_DATA: "FETCH_ADD_EPG_OPTION_DATA",
    FETCH_EPG_TV_SHOW: "FETCH_EPG_TV_SHOW",
    SOFT_DELETE_EPG_SHOW: "SOFT_DELETE_EPG_SHOW"
}

export const imgPathPrefix = imagePathPrefix

export const showList = [
    {
        startTime: "00:00",
        endTime: "00:00",
        title: "Today",
        liveShow: "Live"
    },
    {
        startTime: "00:00",
        endTime: "00:00",
        title: "Today",
        liveShow: "Live"
    },
    {
        startTime: "00:00",
        endTime: "00:00",
        title: "Today",
        liveShow: "Live"
    },
    {
        startTime: "00:00",
        endTime: "00:00",
        title: "Today",
        liveShow: "Live"
    }
]

export const generList = ["COMEDY", "ROMANCE", "THRILLER"]
export const languageList1 = ["HINDI", "ENGLISH"]
export const catchUpList = [
    { id: 1, name: "Yes" },
    { id: 0, name: "No" }
]
export const actorsList = ["HUGH JACKMAN", "CHRIS EVANS"]
export const directorList = ["STEVEN", "STANLEY"]
export const producerList = ["MARVELS"]
export const ratingList = ["1", "2", "3"]

// export const epgList=
