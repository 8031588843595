import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import get from "lodash/get"
import { toast } from "react-toastify"
import Button from "../../common/Button"
import AddNewPlan from "../CreatePlan/addNewPlan"
import {
    createPlans,
    fetchCreatedPlans,
    softDeleteCreatePLan,
    activateDeactivatePlans
} from "../APIs/action"
import { CREATE_PLAN_TABLE_HEADING } from "../APIs/constants"
import { scrollPage } from "../../../utils/common"

import "./style.scss"
import { BUTTON_CONSTANTS } from "../../../utils/constants"

class CreatePlan extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isAddNewPlan: false,
            isEdit: true,
            isDelete: false,
            idToDelete: -1,
            createPlan: [],
            editableData: [],
            currency: null,
            addPlanMsg: true,
            addSavePlan: false,
            create_Plan: [], //fetching plans
            fetchTable: false
        }
    }

    componentDidMount() {
        this.loadHandler()
    }

    loadHandler = async () => {
        const { fetchCreatedPlans } = this.props
        const response = await fetchCreatedPlans()
        if (response && response.status) {
            this.setState({
                create_Plan: response.data
            })
        }
    }

    pageCountSetup = () => {
        const { customTextCount } = this.props
        const { limit } = this.state.apiOptions
        let pageCount = Math.ceil(customTextCount / limit)
        this.setState({
            pageCount
        })
    }

    onPageChange = async (e) => {
        const { apiOptions } = this.state
        const selectedPage = e.selected
        apiOptions.offset = selectedPage * apiOptions.limit
        this.setState(
            {
                currentPage: selectedPage,
                apiOptions
            },
            () => scrollPage()
        )
        this.loadHandler()
    }

    showPerPageChange = async (name, value) => {
        let { apiOptions } = this.state
        apiOptions.limit = value
        this.setState({
            apiOptions
        })
        this.loadHandler()
    }

    handleSearch = async (name, value) => {
        const { apiOptions } = this.state
        apiOptions["searchTag"] = value

        this.setState(
            {
                apiOptions
            },
            () => {
                this.onPageChange({ selected: 0 })
            }
        )
        this.loadHandler()
    }

    handleIsAddNewPlan = () => {
        this.setState(
            {
                isAddNewPlan: !this.state.isAddNewPlan,
                isEdit: false
            },
            () => scrollPage()
        )
        this.loadHandler()
    }

    handleEditCreatePlan = (item) => {
        this.setState({
            isEdit: true,
            editableData: item,
            isAddNewPlan: !this.state.isAddNewPlan
        })
    }

    handleDeleteCreatePlan = async (id) => {
        const { softDeleteCreatePLan } = this.props
        const response = await softDeleteCreatePLan(id)
        if (get(response, "status")) {
            toast.success(get(response, "message"))
        } else {
            toast.error(get(response, "message"))
        }
        this.setState({
            isDelete: !this.state.isDelete
        })
        this.loadHandler()
    }

    handleActivateDeActivatePlan = async (id) => {
        const { activateDeactivatePlans } = this.props
        const response = await activateDeactivatePlans(id)

        if (get(response, "status")) {
            toast.success(get(response, "message"))
        } else {
            toast.error(get(response, "message"))
        }
        this.loadHandler()
    }

    handleDeleteClick = (id) => {
        const { isDelete, idToDelete } = this.state
        if (!isDelete) {
            this.setState({
                idToDelete: id,
                isDelete: !this.state.isDelete
            })
        } else if (id === idToDelete) {
            this.setState({
                idToDelete: -1,
                isDelete: !this.state.isDelete
            })
        }
    }

    render() {
        const { isAddNewPlan, create_Plan, isEdit, editableData } = this.state

        const tableHeading = CREATE_PLAN_TABLE_HEADING.map((item, index) => {
            return (
                <th key={index} className="custom_thead">
                    {item}
                </th>
            )
        })
        const tableRows = create_Plan.map((row, index) => {
            return (
                <tr id={row.id} key={index} className="custom_tr">
                    <td>{index + 1}</td>
                    <td>{row.productName}</td>
                    <td>{row.planName}</td>
                    <td>{row.amount}</td>
                    <td>{row.billingPeriod}</td>
                    <td>{row.trialLength}</td>
                    <td>{row.trialTimeUnit}</td>
                    {/* <td>
                        {row.description}
                    </td> */}

                    <td className="btn-popupContainer">
                        <i
                            className="icon-edit"
                            onClick={(item) => this.handleEditCreatePlan(row)}
                            title="Edit"
                        />
                        <i
                            className={row.active ? "icon-published" : "icon-unpublish"}
                            onClick={() => this.handleActivateDeActivatePlan(row.id)}
                            title={row.active ? "Deactivate" : "Activate"}
                        />
                    </td>
                </tr>
            )
        })
        return (
            <div className="vd-custom-management">
                <div className="vr-cont-head mb">
                    {/* TODO: Replace below with the reusable pageTitleComponent */}
                    <div className="vr-cont-title">
                        <span className="vr-breadcrum">
                            DESIGN
                            <i className="icon-arrow-right1" />
                        </span>
                        <h2 className="breadcrum-header">CREATE PLAN</h2>
                    </div>

                    {isAddNewPlan && (
                        <button className="btn border-btn" onClick={this.handleIsAddNewPlan}>
                            {" "}
                            <i className="btn-icon-left icon-arrow-left1" />
                            <span className="button-text">Back</span>
                        </button>
                    )}
                </div>

                <div className="vr-content-holder">
                    {!isAddNewPlan && (
                        <>
                            <div className="vr-cont-head create-plan">
                                <div className="vr-cont-title">
                                    <h2 className="inner-count-title">
                                        {" "}
                                        <span>{create_Plan.length}</span> PLAN
                                    </h2>

                                    <Button
                                        cName="btn primary-btn"
                                        bValue={BUTTON_CONSTANTS?.ADD_PLAN?.toUpperCase()}
                                        bType="button"
                                        clickHandler={this.handleIsAddNewPlan}
                                    />
                                </div>
                            </div>

                            <table className="table user-role-table">
                                <thead>
                                    <tr>{tableHeading}</tr>
                                </thead>
                                <tbody>{tableRows}</tbody>
                            </table>
                        </>
                    )}
                    {isAddNewPlan && (
                        <AddNewPlan
                            handleClose={this.handleIsAddNewPlan}
                            editableData={editableData}
                            isEdit={isEdit}
                        />
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        create_Plan: get(state.create_Plan, "create_Plan", [])
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                createPlans,
                fetchCreatedPlans,
                softDeleteCreatePLan,
                activateDeactivatePlans
            },
            dispatch
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreatePlan)
