import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import get from "lodash/get"
import AddNewEpg from "./AddEpg/AddNewEpg"
import {
    fetchEpgTvShow,
    fetchEpgContentLiveTV,
    softDeleteEpgShow,
    fetchAddEpgOptionData
} from "./api/action"
import EpgSlider from "./EpgSlider"
import ChannelDetails from "./ChannelDetails"
import EpgTimeline from "./EpgTimeline"
import ShowDetails from "./ShowDetails"
import "./style.scss"
import { debounce, isEmpty } from "lodash"
import { BUTTON_CONSTANTS, TEXT_CONSTANTS } from "../../utils/constants"
import CustomModal from "../common/Modal"
import Loader from "../common/Loader"
import { getGMTTimezone } from "../../utils/common"

class EpgUpdate extends Component {
    constructor(props) {
        super(props)
        /* eslint-disable */
        this.state = {
            addNewEpg: false,
            isEdit: false,
            editableData: [],
            idToEdit: null,
            epgLiveTV: [],
            epgLiveTVdata: [],
            epgTVshow: [],
            showMsg: false,
            TVshow: false,
            TVshowData: {},
            addShow: false,
            ShowList: false,
            showTVdata: false,
            todaysDate: "",
            activeChannel: "",
            searchValue: "",
            // timezone: new Date().toString().match(/([A-Z]+[\+-][0-9]+)/)?.[1],
            currentlyActiveChannel: 0,
            isDeleteModalVisible: false,
            itemToBeDeleted: null
        }
    }

    componentDidMount() {
        this.loadHandler()
    }

    loadHandler = async () => {
        const {
            fetchEpgContentLiveTV,
            fetchEpgTvShow,
            fetchAddEpgOptionData,
            selectedTimezoneLabel
        } = this.props
        const { todaysDate } = this.state
        const globalCurrentTimezone = getGMTTimezone(selectedTimezoneLabel?.timeZone)
        this.dateFormat()
        const response = await fetchEpgContentLiveTV()
        if (response && !isEmpty(response.data) && response.status) {
            const res = await fetchEpgTvShow(
                response.data[this.state?.currentlyActiveChannel].id,
                this.newDateFormat(todaysDate),
                globalCurrentTimezone
            )
            this.setState({
                epgTVshow: res && res.data && res.data.epgShowVOS,
                epgLiveTV: response.data,
                epgLiveTVdata: response.data[this.state?.currentlyActiveChannel],
                addShow: true,
                activeChannel: response.data[this.state?.currentlyActiveChannel].id
            })
        }
        const response1 = await fetchAddEpgOptionData()
        if (response1 && response1.status) {
            this.setState({
                epgOption: response1.data
            })
        }
        this.selectOptions()
    }

    selectOptions = () => {
        const { epgOption } = this.state
        let genreList = [],
            languageList1 = [],
            directorList = [],
            producerList = [],
            actorsList = []
        epgOption &&
            epgOption.forEach((item, index) => {
                if (item.type === "GENRE") {
                    genreList.push({
                        id: item.id,
                        name: item.name
                    })

                    this.setState({
                        genreList
                    })
                } else if (item.type === "LANGUAGE") {
                    languageList1.push({
                        id: item.id,
                        name: item.name
                    })

                    this.setState({
                        languageList1
                    })
                } else if (item.type === "DIRECTOR") {
                    directorList.push({
                        id: item.id,
                        name: item.name
                    })

                    this.setState({
                        directorList
                    })
                } else if (item.type === "PRODUCER") {
                    producerList.push({
                        id: item.id,
                        name: item.name
                    })

                    this.setState({
                        producerList
                    })
                } else if (item.type === "ACTOR") {
                    actorsList.push({
                        id: item.id,
                        name: item.name
                    })

                    this.setState({
                        actorsList
                    })
                }
            })
    }

    newDateFormat = (date) => {
        let updatedDate = date ? new Date(date) : new Date()
        updatedDate.setHours(5)
        updatedDate.setMinutes(30)
        updatedDate.setSeconds(0)
        const rounOffTimestamp = ((updatedDate.getTime() / 1000) | 0) * 1000
        return rounOffTimestamp
    }

    dateFormat = () => {
        const { todaysDate } = this.state
        let d = todaysDate ? new Date(todaysDate) : new Date(),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear()

        if (month.length < 2) month = "0" + month
        if (day.length < 2) day = "0" + day

        let formatedDate = [year, month, day].join("-")
        this.setState({
            todaysDate: formatedDate
        })
    }

    epgLiveTVdesc = async (item, index) => {
        const { todaysDate } = this.state
        this.setState({
            activeChannel: item.id,
            selectedChannelName: item.name,
            currentlyActiveChannel: index
        })
        const { fetchEpgTvShow, selectedTimezoneLabel } = this.props
        const epgChannelId = item.id
        let sendDate = new Date(todaysDate).getTime()
        const globalCurrentTimezone = getGMTTimezone(selectedTimezoneLabel?.timeZone)
        const response = await fetchEpgTvShow(epgChannelId, sendDate, globalCurrentTimezone)

        if (response && response.status && response.data) {
            this.setState({
                epgTVshow: response.data.epgShowVOS,
                ShowList: true,
                TVshow: false
            })
        } else {
            this.setState({
                epgTVshow: [],
                ShowList: false,
                TVshow: false
            })
        }

        this.setState({
            epgLiveTVdata: item,
            addShow: true
        })
    }

    handleDateFieldList = (name, value) => {
        this.setState({
            todaysDate: value,
            ShowList: true,
            TVshow: false
        })
        this.timeline(value)
    }

    timeline = async (value) => {
        let date = value
        const { epgLiveTVdata } = this.state
        const { fetchEpgTvShow, selectedTimezoneLabel } = this.props
        const epgChannelId = epgLiveTVdata.id
        let sendDate = new Date(date).getTime()
        const globalCurrentTimezone = getGMTTimezone(selectedTimezoneLabel?.timeZone)

        const response = await fetchEpgTvShow(epgChannelId, sendDate, globalCurrentTimezone)

        if (response && response.data && response.status) {
            this.setState({
                epgTVshow: response.data.epgShowVOS
                // ShowList: true
            })
        } else {
            this.setState({
                epgTVshow: [],
                ShowList: false
            })
        }
    }

    handleAddClick = (epgLiveTVdata, date) => {
        this.setState({
            addNewEpg: true,
            epgLiveTVdata,
            date,
            editableData: epgLiveTVdata
        })
    }

    TVlistdata = (item) => {
        this.setState({
            TVshowData: item,
            TVshow: true,
            showTVdata: false
        })
    }

    handleBack = () => {
        this.setState({
            addNewEpg: false,
            TVshow: false,
            ShowList: false
        })
        this.loadHandler()
    }
    handleEditClick = (editTvshowData, date) => {
        this.setState({
            addNewEpg: true,
            date,
            editableData: editTvshowData,
            isEdit: true
        })
    }

    handleDeleteEpgShow = async (id) => {
        const { softDeleteEpgShow } = this.props
        const response = await softDeleteEpgShow(id)
        if (get(response, "status")) {
            toast.success(get(response, "message"))
            this.setState({
                showTVdata: true,
                TVshow: false
            })
            this.reset()
        } else {
            toast.error(get(response, "message"))
        }
    }

    onDeleteShow = async () => {
        const { activeChannel, date } = this.state
        const { fetchEpgTvShow, selectedTimezoneLabel } = this.props
        const globalCurrentTimezone = getGMTTimezone(selectedTimezoneLabel?.timeZone)
        const response = await fetchEpgTvShow(
            activeChannel,
            this.newDateFormat(date),
            globalCurrentTimezone
        )
        if (response && response.data && response.status) {
            this.setState({
                epgTVshow: response.data.epgShowVOS
            })
        } else {
            this.setState({
                epgTVshow: [],
                ShowList: false
            })
        }
    }

    reset = () => {
        this.setState({
            TVshowData: {}
        })
        this.loadHandler()
    }

    setSearchQuery = debounce((name, value, selectedOptions) => {
        this.setState(
            {
                [name]: value,
                selectedTimezone: selectedOptions
            },
            () => {
                this.loadHandler()
            }
        )
    }, 300)

    epgDeleteHandler = (deletedItem) => {
        this.setState({
            itemToBeDeleted: deletedItem,
            isDeleteModalVisible: true
        })
    }

    setModalStateHandler = (value) => {
        this.setState({
            isDeleteModalVisible: value
        })
    }

    modalSubmitHandler = async () => {
        // const { handleDeleteEpgShow, onDeleteShow } = this.props;
        await this.handleDeleteEpgShow(this.state.itemToBeDeleted.id)
        await this.onDeleteShow()
        this.setModalStateHandler(false)
    }

    modalCancelHandler = () => {
        this.setModalStateHandler(false)
    }

    render() {
        const {
            todaysDate,
            epgLiveTV,
            epgLiveTVdata,
            epgTVshow,
            TVshowData,
            date,
            addNewEpg,
            ShowList,
            editableData,
            isEdit,
            addShow,
            TVshow,
            activeChannel,
            actorsList,
            directorList,
            producerList,
            languageList1,
            genreList,
            // timezone,
            currentlyActiveChannel,
            isDeleteModalVisible
        } = this.state
        return (
            <div>
                {!addNewEpg ? (
                    <div className="vd-epg">
                        <div className="vr-cont-head mb">
                            {/* TODO: Replace below with the reusable pageTitleComponent */}
                            <div className="vr-cont-title">
                                <span className="vr-breadcrum">
                                    {TEXT_CONSTANTS?.STREAM?.toLowerCase()}{" "}
                                    <i className="icon-arrow-right1" />
                                    <span>{TEXT_CONSTANTS?.EPG}</span>
                                </span>
                                <h2 className="breadcrum-header">{TEXT_CONSTANTS?.EPG}</h2>
                            </div>
                        </div>
                        <div className="vd-epgMain">
                            <div className="vr-content-holder">
                                {this.props?.isLoading ? (
                                    <Loader></Loader>
                                ) : (
                                    <>
                                        <div className="epg-header">
                                            <EpgSlider
                                                epgLiveTV={epgLiveTV}
                                                epgLiveTVdesc={this.epgLiveTVdesc}
                                                currentlyActiveChannel={currentlyActiveChannel}
                                            />
                                            {/*    <Search
                    name="search"
                    changeHandler={this.setSearchQuery}
                    placeholder="Search by Timezone"
                    value={search}
                  /> */}
                                            {/*  <Select
                    options={TIMEZONE_OPTIONS}
                    cName={"filterDropdown timezone-select"}
                    name={"timezone"}
                    value={{ id: timezone, name: timezone }}
                    changeHandler={this.setSearchQuery}
                    placeholder={"Search by timezone"}
                    isSearchable={false}
                    label={"Search by timezone"}
                  /> */}
                                        </div>
                                        <div className="epg-content">
                                            <ChannelDetails
                                                epgLiveTV={epgLiveTV}
                                                handleAddClick={this.handleAddClick}
                                                addShow={addShow}
                                                date={date}
                                                handleDateFieldList={this.handleDateFieldList}
                                                epgLiveTVdata={epgLiveTVdata}
                                                todaysDate={todaysDate}
                                                selectedChannelName={
                                                    this.state?.selectedChannelName
                                                }
                                            />

                                            <div className="epgTimeline">
                                                <EpgTimeline
                                                    ShowList={ShowList}
                                                    epgTVList={epgTVshow}
                                                    date={todaysDate}
                                                    TVlistdata={this.TVlistdata}
                                                />
                                            </div>
                                            <div className="showDetails">
                                                <ShowDetails
                                                    TVshowData={TVshowData}
                                                    handleEditClick={this.handleEditClick}
                                                    date={todaysDate}
                                                    TVshow={TVshow}
                                                    epgDeleteHandler={this.epgDeleteHandler}
                                                    onDeleteShow={() => this.onDeleteShow()}
                                                    directorList={directorList}
                                                    actorsList={actorsList}
                                                />
                                            </div>
                                        </div>
                                        <CustomModal
                                            showModal={isDeleteModalVisible}
                                            submitHandler={this.modalSubmitHandler}
                                            cancelHandler={this.modalCancelHandler}
                                            showCancelButton={true}
                                            showCloseIcon={false}
                                            submitButtonLabel={BUTTON_CONSTANTS?.CONFIRM}>
                                            <p>{TEXT_CONSTANTS?.DELETE_EPG_MSG}</p>
                                        </CustomModal>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <AddNewEpg
                            handleBack={this.handleBack}
                            epgLiveTVs={epgLiveTV}
                            epgTVList={epgTVshow}
                            epgLiveTVdata={epgLiveTVdata}
                            editableData={editableData}
                            dateSelected={todaysDate}
                            isEdit={isEdit}
                            handleDeleteEpgShow={this.handleDeleteEpgShow}
                            onDeleteShow={() => this.onDeleteShow()}
                            channelId={activeChannel}
                            directorList={directorList}
                            actorsList={actorsList}
                            genreList={genreList}
                            languageList1={languageList1}
                            producerList={producerList}
                            // timezone={timezone}
                            // selectedTimezone={this.state.selectedTimezone}
                        />
                    </>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        epgTVshow: get(state.epgTVshow, "epgTVshow", []),
        isLoading: state?.loader?.isLoading,
        selectedTimezoneLabel: state?.timezone?.selectedTimezone
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                fetchEpgTvShow,
                fetchEpgContentLiveTV,
                softDeleteEpgShow,
                fetchAddEpgOptionData
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EpgUpdate)
