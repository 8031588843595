import React, { Component } from "react"
import plusIcon from "../Assets/plus_icon.svg"
import minusIcon from "../Assets/minus_icon.svg"
import { getBrowserType, getMultiLingualValue, isMultiLingual } from "../../../utils/common"
import { DEFAULT_SELECTED_LANGUAGE_CODE } from "../../ContentNav/api/constants"
import { AuthWrapper } from "../../common/AuthWrapper"
import { ERROR_MESSAGES, PERMISSIONS } from "../../../utils/constants"
import CustomModal from "../../common/Modal"
import DRMTrancodedVideo from "../../ContentPipeline/ListingVideoPipeline/TranscodedContent/DRMTrancodedVideo"
import playIcon from "../../../assets/images/play_url.svg"
import { isEmpty } from "lodash"
import { toast } from "react-toastify"

export default class EpisodeContent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            expanded: false,
            showModal: false
        }
        this.error = ""
    }

    toggelExpand = () => {
        this.setState({
            expanded: !this.state.expanded
        })
    }

    play = () => {
        this.setState({
            showModal: !this.state.showModal
        })
    }

    render() {
        const VideoModal = ({ modalProperties }) => {
            const { data } = this.props
            let play_url = data?.playUrl

            if (getBrowserType() === "Apple Safari") {
                if (!isEmpty(data?.hlsPlayUrl)) play_url = data?.hlsPlayUrl
                else {
                    this.error = ERROR_MESSAGES.HLS_ERROR
                }
            } else {
                if (!isEmpty(data?.dashPlayUrl)) play_url = data?.dashPlayUrl
                else {
                    this.error = ERROR_MESSAGES.DASH_ERROR_SAFARI
                }
            }

            if (isEmpty(play_url)) {
                toast.error(this.error)
                return
            }

            return (
                <DRMTrancodedVideo
                    src={play_url}
                    selectedObj={{ isDRMEnabled: data?.drm, playURL: play_url }}
                ></DRMTrancodedVideo>
            )
        }
        let className = this.state.expanded
            ? "episode-content-container"
            : "episode-content-container closed"
        let addImg = this.state.expanded ? (
            <img className="minus-icon" style={{ marginTop: 25 }} alt="expanded" src={minusIcon} />
        ) : (
            <img className="plus-icon" style={{ marginTop: 20 }} alt="collapsed" src={plusIcon} />
        )
        const { data } = this.props
        const { showModal } = this.state
        const isMulti = isMultiLingual()
        return (
            <>
                {showModal && (
                    <CustomModal
                        showModal={showModal}
                        showCloseIcon={true}
                        showCancelButton={false}
                        showSubmitButton={false}
                        closeOnSubmit={true}
                        cancelHandler={this.play}
                        cancelOnOutsideClick={true}
                        modalContainerClass={"transcoded-modal-container"}
                    >
                        {<VideoModal modalProperties="" />}
                    </CustomModal>
                )}
                <div className="ep-content-header" id={this.props.episodeId}>
                    <div className="ep-content-header-left" onClick={this.toggelExpand}>
                        <div className="ep-contnet-head">{addImg}</div>

                        <div className="ep-count-text" style={{ margin: 10, padding: 10 }}>
                            EPISODE {this.props.index + 1}
                        </div>
                    </div>
                    <div className="ep-content-header-right">
                        <div
                            className="ep-contnet-edit icon-edit toolBtn"
                            onClick={() => {
                                this.props.eidtEpisode(this.props.index, this.props.episodeId)
                            }}
                        >
                            {" "}
                            <span className="tooltiptext">Edit</span>
                        </div>
                        <AuthWrapper permissions={[PERMISSIONS?.CONTENT_EDIT]}>
                            <div
                                className="ep-contnet-delete icon-del toolBtn"
                                onClick={() => {
                                    this.props.deleteEpisode(this.props.index)
                                }}
                            >
                                {" "}
                                <span className="tooltiptext">Delete</span>
                            </div>
                        </AuthWrapper>
                        <div className="play-icon toolBtn">
                            <img onClick={this.play} src={playIcon} alt="play" />
                            <span className="tooltiptext">Play</span>
                        </div>
                    </div>
                </div>
                <div className={className}>
                    <div className="episode-content-title">
                        {getMultiLingualValue(
                            isMulti,
                            data.episodeTitle,
                            DEFAULT_SELECTED_LANGUAGE_CODE
                        )}
                    </div>
                    <div className="episode-content-description">
                        {getMultiLingualValue(
                            isMulti,
                            data.description,
                            DEFAULT_SELECTED_LANGUAGE_CODE
                        )}
                    </div>
                    <div className="ep-content-play">
                        <span>Play URL : </span>
                        {getMultiLingualValue(
                            isMulti,
                            data.playUrl,
                            DEFAULT_SELECTED_LANGUAGE_CODE
                        )}
                    </div>
                    <div className="ep-content-duration">
                        <span>Duration : </span>
                        {getMultiLingualValue(
                            isMulti,
                            data.playDuration,
                            DEFAULT_SELECTED_LANGUAGE_CODE
                        )}
                    </div>
                </div>
                <div className="ep-content-hr"></div>
            </>
        )
    }
}
