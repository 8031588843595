import React, { useEffect, useState } from 'react'
import CustomModal from '../Modal'
import Checkbox from '../Checkbox'
import './style.scss'

/**
 * For implementation refer Create Manage Page Unpublish confirmation Modal
 */
export default function DeleteModal({
  deleteModalHeader,
  deleteModalDescription,
  confirmationText,
  showCheckBox = false,
  initialShowModal,
  cancelButtonLabel,
  submitButtonLabel,
  submitHandler,
  cancelHandler
}) {

  const [showModal, setShowModal] = useState(false)
  const [deleteModalStatus, setDeleteModalStatus] = useState(false)

  useEffect(() => {
    setShowModal(initialShowModal)
  }, [initialShowModal])

  const confirmationCheckboxHandler = (name, checked) => {
    setDeleteModalStatus(checked)
  }

  const resetModalHandler = () => {
    setShowModal(false)
    setDeleteModalStatus(false)
    cancelHandler && cancelHandler()
  }

  const submitModalHandler = async () => {
    submitHandler && submitHandler()
    setShowModal(false)
    setDeleteModalStatus(false)
  }

  return (
    <CustomModal
      showModal={showModal}
      cancelHandler={resetModalHandler}
      submitHandler={submitModalHandler}
      onClickCancelBtn={resetModalHandler}
      showCloseIcon={true}
      submitButtonLabel={submitButtonLabel}
      cancelButtonLabel={cancelButtonLabel}
      showCancelButton={true}
      closeOnSubmit={true}
      cancelOnOutsideClick={true}
      modalContainerClass={""}
      isSubmitDisabled={!deleteModalStatus}
    >
      <div className="delete-modal--wrapper">
        {deleteModalHeader && <h2 className="delete-modal-header">{deleteModalHeader}</h2>}
        {deleteModalDescription && <p className="delete-modal-description">{deleteModalDescription}</p>}
        {showCheckBox && <Checkbox
          cName="check-box"
          id={"checkBox"}
          name={"delete-all"}
          isChecked={deleteModalStatus}
          changeHandler={confirmationCheckboxHandler}
          labelText={confirmationText}
        />}
      </div>
    </CustomModal>
  )
}


