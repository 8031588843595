import React, { Component } from "react"
import { bindActionCreators } from "redux"
import User from "../../UserPanel"
import ThemeMode from "../ThemeMode"
import { Link } from "react-router-dom"

import logo from "../../../assets/images/VideoReady_Black.svg"
import dmLogo from "../../../assets/images/VideoReady_White.svg"
import Notification from "../Notification"
import { connect } from "react-redux"

import { URL } from "../../../utils/routeConstants"

import "./style.scss"
import { checkIfUserHasAccess } from "../../../utils/users"
import { PERMISSIONS } from "../../../utils/constants"

class Header extends Component {
    themeChangeHandler = (event) => {}

    render() {
        const { additionalDetails, isDarkMode, handleMenuOpen, selectedTimezoneLabel } = this.props

        const canUpdateStatus = checkIfUserHasAccess([
            PERMISSIONS.TIMEZONE_VIEW,
            PERMISSIONS?.TIMEZONE_EDIT
        ])
        return (
            <header className="vr-header">
                <div className="header-lt">
                    <i className="icon-menu" onClick={handleMenuOpen} />
                    <div className="header-logo">
                        <Link to="/">
                            <img src={isDarkMode ? dmLogo : logo} alt="logo" />
                        </Link>
                    </div>
                </div>
                <div className="header-rt">
                    <div className="logged-in-user">
                        <User details={additionalDetails} history={this.props.history} />
                    </div>
                    <div className="header-tools">
                        {canUpdateStatus && (
                            <div className="timezone-label">
                                <Link
                                    to={`/${URL.SETTINGS}/${URL.CONFIG}/${URL.TIMEZONE}`}
                                    className="timezone-text"
                                >
                                    <p>{selectedTimezoneLabel?.timeZone}</p>
                                </Link>
                            </div>
                        )}
                        <i className="icon-search">
                            <span className="iconTooltiptext">Under Development</span>
                        </i>
                        <Notification />
                        <ThemeMode />
                    </div>
                </div>
            </header>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        selectedTimezoneLabel: state?.timezone?.selectedTimezone
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({}, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
