import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import get from "lodash/get"

import Button from "../../common/Button"
import Input from "../../common/Input"
import TextArea from "../../common/TextArea"
import TaggedPermissions from "./TaggedPermissions"
import PermissionList from "./PermissionList"
import { AddRole } from "../APIs/action"
import { BUTTON_CONSTANTS, TEXT_CONSTANTS } from "../../../utils/constants"

class AddRoles extends Component {
    constructor(props) {
        super(props)

        this.state = {
            searchValues: [],
            taggedPermissions: [],
            name: "",
            description: "",
            isReset: false,
            isDisable: true,
            err: {
                roleErr: "",
                descriptionErr: "",
                tagPermissionErr: ""
            }
        }
    }

    componentDidMount() {
        this.loadHandler()
    }

    loadHandler = () => {
        const {
            editableData: { id, name, description, permissions },
            isEdit
        } = this.props
        if (isEdit) {
            this.setState(
                {
                    id,
                    name,
                    description,
                    taggedPermissions: permissions
                },
                () => {
                    this.checkForEmpty()
                }
            )
        }
    }

    handleRemovePermission = (permission) => {
        let updatedList = [...this.state.taggedPermissions]
        updatedList = updatedList.filter((item) => item.id !== permission.id)
        this.setState(
            {
                taggedPermissions: updatedList
            },
            () => this.checkForEmpty()
        )
    }

    handleChange = (name, value) => {
        this.validate(name, value)
        this.setState(
            {
                [name]: value
            },
            () => this.checkForEmpty()
        )
    }

    checkboxHandleChange = (name, value, id) => {
        let updatedList = [...this.state.taggedPermissions]
        if (value) {
            updatedList.push({ name, id })
        } else {
            updatedList = updatedList.filter((e) => e.id !== id)
        }
        this.setState(
            {
                taggedPermissions: [...updatedList]
            },
            () => this.checkForEmpty()
        )
    }

    checkForEmpty = () => {
        const { name, description, taggedPermissions } = this.state
        let count = 3
        if (name.trim().length) {
            count = count - 1
        }
        if (description.trim().length) {
            count = count - 1
        }
        if (taggedPermissions.length) {
            count = count - 1
        }
        if (!count) {
            this.setState({
                isDisable: false
            })
        } else {
            this.setState({
                isDisable: true
            })
        }
    }

    validate = (name, value) => {
        let flag = 1
        const err = {}
        if (value.length === 0) {
            err[name] = `${name} cannot be empty`
            flag = 0
        } else {
            delete err["descriptionErr"]
        }
        this.setState({ err: { ...err } })
        return flag
    }

    handleAddRole = async () => {
        const { AddRole, handleBack } = this.props
        const { id = null, name, description, taggedPermissions } = this.state
        const payload = {
            id,
            name,
            description,
            permissions: taggedPermissions
        }
        this.setState({
            err: {
                roleErr: "",
                descriptionErr: "",
                tagPermissionErr: ""
            }
        })
        const response = await AddRole(payload)
        if (get(response, "status") === true) {
            toast.success(get(response, "message"))
            this.resetForm()
            handleBack()
        } else {
            toast.error(get(response, "message"))
        }
    }

    resetForm = () => {
        this.setState({
            name: "",
            description: "",
            taggedPermissions: [],
            isReset: true
        })
    }
    render() {
        let { taggedPermissions, name, description, err, isReset, isDisable } = this.state
        const { handleBack, isEdit } = this.props
        return (
            <div className="addRole-container">
                <div className="addRole-header">
                    <i className="icon-arrow-left1" onClick={handleBack} />
                    <h3>{TEXT_CONSTANTS?.ADD_NEW_ROLE}</h3>
                </div>
                <div className="addRole-content">
                    <div className="addRole-permission">
                        <PermissionList
                            checkboxHandleChange={this.checkboxHandleChange}
                            taggedPermissions={taggedPermissions}
                            isReset={isReset}
                        />
                    </div>
                    <div className="addRole-form">
                        <div>
                            <Input
                                type="text"
                                changeHandler={this.handleChange}
                                name="name"
                                id="roleName"
                                labelText="Role Name"
                                value={name}
                                maxLength={40}
                                errMsg={err.roleErr}
                            />
                            <TextArea
                                rows={4}
                                cols={5}
                                changeHandler={this.handleChange}
                                name="description"
                                labelText="Description"
                                value={description}
                                maxLength={100}
                                errMsg={err.descriptionErr}
                            />
                            <TaggedPermissions
                                taggedPermissions={taggedPermissions}
                                label={`TAGGED PERMISSION${
                                    taggedPermissions.length > 1 ? "S" : ""
                                }`}
                                changeHandler={this.handleRemovePermission}
                                errMsg={err.tagPermissionErr}
                            />
                            <div className="buttons">
                                <Button
                                    bValue={
                                        isEdit
                                            ? BUTTON_CONSTANTS?.UPDATE?.toUpperCase()
                                            : BUTTON_CONSTANTS?.ADD_ROLE?.toUpperCase()
                                    }
                                    cName="btn primary-btn"
                                    clickHandler={this.handleAddRole}
                                    disabled={isDisable}
                                />
                                <Button
                                    bValue={BUTTON_CONSTANTS?.CANCEL?.toUpperCase()}
                                    cName="btn cancel-btn"
                                    clickHandler={handleBack}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                AddRole
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddRoles)
