import { API_ROOT_PATH, API_TENANT_ROOT_PATH } from "../../../config/apiPath"
import ApiService from "../../../utils/apiService"
import { FOOTER_BASE_URL } from "./constant"

export const FooterServices = {
    getAllFooterItems(platformId) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}${FOOTER_BASE_URL}/platformList/${platformId}`,
            isAuthRequired: true
        }
        return ApiService(options)
    },
    createFooterItem(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}${FOOTER_BASE_URL}`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    },

    updateFooterItem(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}${FOOTER_BASE_URL}/update`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    },

    bulkUpdateFooter(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}${FOOTER_BASE_URL}/bulk`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    },

    deleteFooterItem(selectedId) {
        const options = {
            method: "DELETE",
            url: `${API_ROOT_PATH}${FOOTER_BASE_URL}/${selectedId}`,
            isAuthRequired: true
        }
        return ApiService(options)
    },

    publishFooterItem(selectedId, active) {
        const options = {
            method: "PUT",
            url: `${API_ROOT_PATH}${FOOTER_BASE_URL}/publish/${selectedId}?publish=${active}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
}
