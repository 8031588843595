import React, { Component } from "react"
import get from "lodash/get"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import {
    fetchFormElement,
    fetchEntityValueMasterData,
    fetchContent,
    createContent
} from "../api/action"
import { saveContentNav } from "../../ContentNav/api/action"

import { createWebSeries } from "../helper"

import AddBrand from "./AddBrand"
import AddSeasonWrapper from "../AddSeasonWrapper"
import "./style.scss"
import Button from "../../common/Button"
import ImdbModal from "../../ContentNav/contentAddForm/ImdbModal"
import LanguageCardList, { getLanguageObject } from "../../common/LanguageCardList"
import {
    checkAllLanguageHasValue,
    isFieldMultilingual,
    isMultiLingual
} from "../../../utils/common"
import { DEFAULT_SELECTED_LANGUAGE_CODE } from "../../ContentNav/api/constants"
import LanguageDataConfirmationModal from "../../common/LanguageCardList/languageDataConfirmationModal"
import { BUTTON_CONSTANTS, PERMISSIONS, TEXT_CONSTANTS } from "../../../utils/constants"
import { toast } from "react-toastify"
import { AuthWrapper } from "../../common/AuthWrapper"
import { timeToSeconds } from "../../../utils/common"
import { REGEX } from "../../../utils/constants"
import { isEmpty } from "lodash"

class AddWebSeries extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentScreen: "addBrand",
            formElementData: {},
            isLoading: true,
            save: false,
            webSeriesCreateData: this.getUpdatedWebSeriesData(this.props.selectedData),
            selectedLanguage: {
                name: "English",
                code: "en"
            },
            multilingualKeys: []
        }
    }

    updateLanguageObject = async () => {
        this.languageObject = await getLanguageObject()
    }

    getStringConvertedValue = (value) => {
        if (Array.isArray(value)) {
            return value?.join(",")
        }
        return value
    }

    getUpdatedWebSeriesData = (selectedData) => {
        let webSeriesCreateData
        if (this.props.isEdit) {
            webSeriesCreateData = {
                addBrand: {
                    posterImage: selectedData?.brand_poster_image,
                    coverImage: selectedData?.brand_cover_image,
                    brandTitle: selectedData?.brand_title,
                    trailerUrl: selectedData?.brand_trailer_url,
                    playDuration: selectedData?.brand_trailer_duration_hhmmss,
                    assetId: selectedData?.asset_id,
                    webBannerImage: selectedData?.web_banner_image,
                    mobileBannerImage: selectedData?.mobile_banner_image,
                    brandDescription: {
                        description: selectedData?.brand_description,
                        genre: selectedData?.brand_genre,
                        language: selectedData?.brand_language,
                        // genre: selectedData.brand_genre.map((item) => {
                        //   return { id: item, name: item };
                        // }),
                        // language: selectedData.brand_language.map((item) => {
                        //   return { id: item, name: item };
                        // }),
                        year: selectedData?.brand_year,
                        // rating: selectedData.brand_rating
                        //   ? {
                        //     id: selectedData.brand_rating,
                        //     name: selectedData.brand_rating,
                        //   }
                        //   : "",
                        rating: `${selectedData?.brand_rating}`,
                        // ? {
                        //   id: selectedData.brand_rating,
                        //   name: selectedData.brand_rating,
                        // }
                        // : "",

                        videoFormat: selectedData?.brand_video_format?.map((item) => {
                            return { id: item, name: item }
                        }),
                        content_id: selectedData?.content_id,
                        imdb_id: selectedData?.imdb_id,
                        age_rating: selectedData?.age_rating,
                        // certification: this.getStringConvertedValue(
                        //   selectedData?.certification
                        // ),
                        brand_production_house: selectedData?.brand_production_house
                    },
                    credit: {
                        director: selectedData?.brand_director,
                        producer: selectedData?.brand_producer,
                        actor: selectedData?.brand_actor
                        // director: selectedData.brand_director.map((item) => {
                        //   return { id: item, name: item };
                        // }),
                        // producer: selectedData.brand_producer.map((item) => {
                        //   return { id: item, name: item };
                        // }),
                        // actor: selectedData.brand_actor.map((item) => {
                        //   return { id: item, name: item };
                        // }),
                    }
                },
                addSeason: {
                    season: selectedData?.season?.map((data) => {
                        return {
                            seasonPosterImage: data?.season_poster_image,
                            coverImage: data?.season_cover_image,
                            seasonNumber: data?.season_number,
                            seasonTitle: data?.season_title,
                            description: data?.season_description,
                            trailerUrl: data?.season_trailer_url,
                            playDuration: data?.season_trailer_duration_hhmmss,
                            seasonYear: data?.season_year,
                            rating: data?.season_rating
                                ? { id: data?.season_rating, name: data?.season_rating }
                                : "",
                            videoFormat: data?.season_video_format?.map((item) => {
                                return { id: item, name: item }
                            }),
                            assetId: data?.asset_id,
                            // certification: this.getStringConvertedValue(data?.certification),
                            age_rating: data?.age_rating,
                            seasonId: data?.season_id,
                            addEpisode: {
                                episode: data?.episode?.map((ele) => {
                                    return {
                                        active: true,
                                        episodeId: ele?.episode_id,
                                        episodePosterImage: ele?.episode_poster_image,
                                        episodeCoverImage: ele?.episode_cover_image,
                                        episodeNumber: ele?.episode_number,
                                        episodeTitle: ele?.episode_title,
                                        playUrl: ele?.episode_play_url,
                                        playDuration: ele?.duration_hhmmss,
                                        description: ele?.episode_description,
                                        assetId: ele?.asset_id,
                                        sub_titles: ele?.sub_titles,
                                        hlsPlayUrl: ele?.hls_play_url,
                                        drm: ele?.drm,
                                        mediatailorHlsSuffix: ele?.mediatailor_hls_suffix,
                                        offlineDownload: ele?.offlineDownload,
                                        dashPlayUrl: ele?.dash_play_url,
                                        videoFormat: ele?.episode_video_format?.map((item) => {
                                            return { id: item, name: item }
                                        }),
                                        episodeAirDate: ele?.episode_air_date,
                                        rating: ele?.episode_rating
                                            ? { id: ele?.episode_rating, name: ele?.episode_rating }
                                            : "",
                                        // certification: this.getStringConvertedValue(
                                        //   ele?.certification,
                                        //   ),
                                        age_rating: ele?.age_rating,
                                        drmAssetId: ele?.drm_asset_id,
                                        internationalContent: ele?.international_content,
                                        assetCategory: ele?.asset_category,
                                        episode_rating: {
                                            values: {
                                                [DEFAULT_SELECTED_LANGUAGE_CODE]:
                                                    ele?.episode_rating
                                            }
                                        },
                                        isPublish: ele?.isPublish
                                    }
                                })
                            }
                        }
                    })
                }
            }
        } else {
            webSeriesCreateData = {
                addBrand: {
                    posterImage: "",
                    coverImage: "",
                    brandTitle: "",
                    trailerUrl: "",
                    playDuration: "",
                    // age_rating: "",
                    asset_id: "",
                    webBannerImage: "",
                    mobileBannerImage: "",
                    brandDescription: {
                        description: "",
                        genre: [],
                        language: [],
                        year: "",
                        rating: "",
                        videoFormat: [],
                        // certification: "",
                        brand_production_house: "",
                        age_rating: []
                    },
                    credit: {
                        director: [],
                        producer: [],
                        actor: []
                    }
                },
                addSeason: {
                    season: [
                        {
                            seasonPosterImage: "",
                            coverImage: "",
                            age_rating: "",
                            seasonNumber: "",
                            seasonTitle: "",
                            description: "",
                            trailerUrl: "",
                            playDuration: "",
                            seasonYear: "",
                            rating: "",
                            videoFormat: [],
                            // certification: "",
                            assetId: "",
                            seasonId: "",
                            addEpisode: {
                                episode: []
                            }
                        }
                    ]
                }
            }
        }
        webSeriesCreateData.contentEntityId = selectedData.contentEntityId
        return webSeriesCreateData
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedData !== prevProps.selectedData) {
            this.setState({
                webSeriesCreateData: this.getUpdatedWebSeriesData(this.props.selectedData)
            })
        }
    }

    componentDidMount() {
        this.updateLanguageObject()
        this.loadHandler()
    }

    loadHandler = async () => {
        const { formData } = this.props
        let data = formData && formData.htmlElements
        let responseMetaData = formData && formData.dataFormat && formData.dataFormat.properties
        if (data && responseMetaData) {
            this.setState(
                {
                    formElementData: data,
                    formMetaData: responseMetaData,
                    isLoading: false,
                    multilingualKeys: formData?.multilingualKeys
                },
                () => window.scrollTo(0, 0)
            )
        }
    }
    assignUpdateValues = (obj, atrrName, data) => {
        if (Array.isArray(obj)) {
            obj.forEach((item, index) => {
                this.assignUpdateValues(item, atrrName, data)
            })
        } else if (typeof obj === "object" && obj !== null) {
            for (let key in obj) {
                if (key === atrrName) {
                    obj[key] = data
                } else {
                    this.assignUpdateValues(obj[key], atrrName, data)
                }
            }
        }
    }

    assignSeasonEpisodeNumber = (lastSeasonEpisodeIndex, curSeasonNumber) => {
        if (!curSeasonNumber) {
            curSeasonNumber = lastSeasonEpisodeIndex + 1
            lastSeasonEpisodeIndex++
        }
        return { curSeasonNumber, lastSeasonEpisodeIndex }
    }

    calculateLastIndex = (data, searchString) => {
        let lastIndex = null
        for (let i = 0; i < data?.length; i++) {
            if (!data[i]?.[searchString]) {
                lastIndex = i
                break
            }
        }
        return lastIndex
    }

    formatDataForAPI = (value) => {
        if (isMultiLingual()) {
            const val = value?.values
            let modObj = { ...value }
            for (const key in val) {
                const currVal = val[key]
                modObj.values[key] = Array.isArray(currVal) ? currVal : currVal?.split(",")
            }
            return modObj
        }
        return value
    }

    checkobjValue = (value, isMultilingual) => {
        if (isMultilingual) {
            if (Array.isArray(value)) {
                return value?.length > 0 ? true : false
            } else {
                return value?.values?.[DEFAULT_SELECTED_LANGUAGE_CODE]?.length > 0 ? true : false
            }
        } else {
            return Object.keys(value)?.length > 0 ? true : false
        }
    }

    isInvalidPayload = (payload) => {
        const dataFormat = this.props?.formData?.dataFormat?.required
        let data = dataFormat?.map((ele) => {
            return ele in payload ? true : false
        })
        if (data?.includes(false)) {
            const position = data.indexOf(false)
            const value = dataFormat[position]
            return {
                error: String(value).toUpperCase().replace("_", " ") + " IS REQUIRED"
            }
        }
        let status = this.checkIfLanguageDataIsFilled(payload, {
            [DEFAULT_SELECTED_LANGUAGE_CODE]: ""
        })
        if (!status?.isAllLangDataFilled) {
            return {
                error:
                    String(status?.emptyKey).toUpperCase().replace("_", " ") +
                    " IS INVALID/EMPTY IN ENGLISH LANGUAGE"
            }
        }
        return null
    }

    checkIfLanguageDataIsFilled = (obj, languageObject) => {
        // Multilingual fields which can be empty
        // const nonRequiredMultilingualFields = [
        //   "brand_poster_image",
        //   "brand_cover_image",
        //   "$season.season_cover_image",
        //   "$season.season_poster_image",
        //   "$season.$episode.episode_poster_image",
        // ];
        // const multiKeys = this.props?.formData?.multilingualKeys?.filter(
        //   (item) => !nonRequiredMultilingualFields.includes(item)
        // );
        const multiKeys = this.props?.formData?.multilingualKeys
        let isAllLangDataFilled = true
        let emptyKey
        // TO check data is filled in all multilingual fields or not
        this.checkAllLangDataFill = (obj, languageObject) => {
            for (let key in obj) {
                if (key === "season" || key === "episode") {
                    const val = obj[key]
                    for (let i = 0; i < val?.length; i++) {
                        if (!this.checkAllLangDataFill(val[i], languageObject)) {
                            break
                        }
                    }
                } else if (isFieldMultilingual(key, "", multiKeys)) {
                    if (
                        !checkAllLanguageHasValue(obj[key]?.values, languageObject) &&
                        key !== "age_rating"
                    ) {
                        isAllLangDataFilled = false
                        emptyKey = key
                        break
                    }
                }
            }
            return { isAllLangDataFilled, emptyKey }
        }
        return this.checkAllLangDataFill(obj, languageObject)
    }

    setContentDurationTime = (durationKey, durationHHKey, playDuration) => {
        let obj = {}
        if (!REGEX.DURATION_REGEX.test(playDuration)) {
            obj[durationHHKey] = null
            obj[durationKey] = null
            return obj
        }
        let numericDuration = timeToSeconds(playDuration)
        obj[durationHHKey] = playDuration
        obj[durationKey] = numericDuration
        return obj
    }

    sortSeasonArray = (inputArray) => {
        const modifiedArray = [...inputArray]
        modifiedArray.sort((a, b) => {
            if (Number(a?.season_number) < Number(b?.season_number)) {
                return -1
            } else if (Number(a?.season_number) > Number(b?.season_number)) {
                return 1
            }
            return 0
        })
        return modifiedArray
    }

    handleSave = async (currentScreen, atrrName, data, isSaveAndFinish, isPublish) => {
        window.scrollTo(0, 0)
        for (let key in data) {
            this.assignUpdateValues(this.state.webSeriesCreateData[atrrName], key, data[key])
        }

        // var checkForValidDuration = [];
        // if (data?.season && isSaveAndFinish) {
        //   data.season.forEach((season) => {
        //     season?.addEpisode.episode &&
        //       season.addEpisode.episode.forEach((episode) => {
        //         if (episode?.playDuration) {
        //           if (!REGEX.DURATION_REGEX.test(episode?.playDuration)) {
        //             toast.error(
        //               `${ERROR_MESSAGES.TIME_FORMAT1_ERROR} for ${episode.episodeTitle.values?.en}. ${ERROR_MESSAGES.TIME_FORMAT2_ERROR}`
        //             );
        //             checkForValidDuration.push(true);
        //             return;
        //           } else {
        //             let numericDuration = timeToSeconds(episode.playDuration);
        //             episode["duration_hhmmss"] = episode.playDuration;
        //             episode["episode_duration"] = numericDuration;
        //             return episode;
        //           }
        //         } else return episode;
        //       });
        //   });
        // }

        // if (checkForValidDuration.includes(true)) {
        //   return;
        // }

        this.setState({
            currentScreen: currentScreen
        })
        if (isSaveAndFinish) {
            delete this.state?.webSeriesCreateData?.addBrand?.brandDescription?.["age_rating"]
                ?.search_key
            delete this.state?.webSeriesCreateData?.addBrand?.brandDescription?.["age_rating"]?.rank
            let finalObject = {
                brand_poster_image: this.state.webSeriesCreateData?.addBrand?.posterImage,
                // brand_trailer_duration: Number(
                //   this.state.webSeriesCreateData?.addBrand?.playDuration
                // ),
                ...this.setContentDurationTime(
                    "brand_trailer_duration",
                    "brand_trailer_duration_hhmmss",
                    this.state.webSeriesCreateData?.addBrand?.playDuration
                ),
                brand_director: this.formatDataForAPI(
                    this.state.webSeriesCreateData?.addBrand?.credit?.director
                ),
                brand_producer: this.formatDataForAPI(
                    this.state.webSeriesCreateData?.addBrand?.credit?.producer
                ),
                brand_actor: this.formatDataForAPI(
                    this.state.webSeriesCreateData?.addBrand?.credit?.actor
                ),
                mobile_banner_image:
                    this.state.webSeriesCreateData?.addBrand?.mobileBannerImage || "",
                web_banner_image: this.state.webSeriesCreateData?.addBrand?.webBannerImage || "",
                // brand_director: this.state.webSeriesCreateData.addBrand.credit.director.map(
                //   (item) => {
                //     return item.name;
                //   }
                // ),
                // brand_producer: this.state.webSeriesCreateData.addBrand.credit.producer.map(
                //   (item) => {
                //     return item.name;
                //   }
                // ),
                // brand_actor: this.state.webSeriesCreateData.addBrand.credit.actor.map(
                //   (item) => {
                //     return item.name;
                //   }
                // ),
                // brand_language: this.state.webSeriesCreateData.addBrand.brandDescription.language.map(
                //   (item) => {
                //     return item.id;
                //   }
                // ),
                brand_language: this.formatDataForAPI(
                    this.state.webSeriesCreateData?.addBrand?.brandDescription?.language
                ),
                brand_cover_image: this.state.webSeriesCreateData?.addBrand?.coverImage,
                brand_year: Number(
                    this.state.webSeriesCreateData?.addBrand?.brandDescription?.year
                ),
                brand_rating: String(
                    this.state.webSeriesCreateData?.addBrand?.brandDescription?.rating
                ),
                // brand_rating: this.state.webSeriesCreateData.addBrand.brandDescription.rating
                // .name,
                brand_video_format:
                    this.state.webSeriesCreateData?.addBrand?.brandDescription?.videoFormat?.map(
                        (item) => {
                            return item.name
                        }
                    ),
                brand_title: this.state.webSeriesCreateData?.addBrand?.brandTitle,
                brand_trailer_url: this.state.webSeriesCreateData?.addBrand?.trailerUrl,
                brand_description:
                    this.state.webSeriesCreateData?.addBrand?.brandDescription?.description,
                content_id: this.state.webSeriesCreateData?.addBrand?.brandDescription?.content_id,
                imdb_id: this.state.webSeriesCreateData?.addBrand?.brandDescription?.imdb_id,
                // certification:
                //   this.state.webSeriesCreateData.addBrand.brandDescription.certification?.split(
                //     ","
                //   ),
                brand_production_house:
                    this.state.webSeriesCreateData?.addBrand?.brandDescription
                        ?.brand_production_house,
                age_rating: {
                    certification:
                        this.state?.webSeriesCreateData?.addBrand?.brandDescription?.["age_rating"]
                },
                asset_id: this.state.webSeriesCreateData?.addBrand?.assetId,
                season: this.state.webSeriesCreateData?.addSeason?.season?.map((data) => {
                    let lastEpisodeNumber = this.calculateLastIndex(
                        data.addEpisode.episode,
                        "episodeNumber"
                    )

                    return {
                        // season_number: String(data.seasonNumber),assignSeasonEpisodeNumber
                        // season_number: String(
                        //   this.assignSeasonEpisodeNumber(
                        //     lastSeasonNumber,
                        //     data.seasonNumber
                        //   )
                        // ),
                        season_number: data.seasonNumber,
                        season_title: data?.seasonTitle,
                        season_poster_image: data?.seasonPosterImage,
                        season_cover_image: data?.coverImage,
                        season_trailer_url: data?.trailerUrl,
                        // season_trailer_duration: Number(data?.playDuration),
                        season_description: data?.description,
                        season_year: Number(data?.seasonYear),
                        season_rating: data?.rating?.name,
                        asset_id: data?.assetId,
                        season_video_format: data?.videoFormat?.map((item) => {
                            return item?.name
                        }),
                        season_id: data?.seasonId,
                        // certification: data.certification?.split(","),
                        age_rating: data?.ageRating,
                        ...this.setContentDurationTime(
                            "season_trailer_duration",
                            "season_trailer_duration_hhmmss",
                            data?.playDuration
                        ),
                        episode: data?.addEpisode?.episode?.map((item) => {
                            const episodeInfo = this.assignSeasonEpisodeNumber(
                                lastEpisodeNumber,
                                item?.episodeNumber
                            )
                            lastEpisodeNumber = episodeInfo?.lastSeasonEpisodeIndex
                            return {
                                episode_poster_image: item?.episodePosterImage,
                                episode_cover_image: item?.episodeCoverImage,
                                // episode_number: String(item.episodeNumber),
                                episode_number: String(episodeInfo?.curSeasonNumber),
                                // episode_number: String(
                                //   this.assignSeasonEpisodeNumber(
                                //     lastEpisodeNumber,
                                //     item?.episodeNumber
                                //   )
                                // ),
                                episode_title: item?.episodeTitle,
                                episode_play_url: item?.playUrl,
                                // episode_duration: item?.episode_duration,
                                episode_description: item?.description,
                                sub_titles: item?.sub_titles,
                                episode_video_format: item?.videoFormat?.map((ele) => {
                                    return ele?.name
                                }),
                                episode_air_date: item?.episodeAirDate,
                                episode_rating:
                                    item?.episode_rating?.values?.[DEFAULT_SELECTED_LANGUAGE_CODE],
                                asset_id: item?.assetId,
                                hls_play_url: item?.hlsPlayUrl,
                                drm: !!item?.drm,
                                offlineDownload: !!item?.offlineDownload,
                                dash_play_url: item?.dashPlayUrl,
                                // certification: item.certification?.split(","),
                                age_rating: item?.ageRating,
                                asset_category: item?.assetCategory,
                                mediatailor_hls_suffix: item?.mediatailorHlsSuffix,
                                // duration_hhmmss: item?.duration_hhmmss,
                                drm_asset_id: item?.drmAssetId,
                                // international_content: !!item?.internationalContent,
                                episode_id: item?.episodeId,
                                isPublish: item?.isPublish || false,
                                ...this.setContentDurationTime(
                                    "episode_duration",
                                    "duration_hhmmss",
                                    item?.playDuration
                                )
                            }
                        })
                    }
                }),
                brand_genre: this.formatDataForAPI(
                    this.state.webSeriesCreateData?.addBrand?.brandDescription?.genre
                )
                // brand_genre: this.state.webSeriesCreateData.addBrand.brandDescription.genre.map(
                //   (item) => {
                //     return item.id;
                //   }
                // ),
            }
            if (!isEmpty(finalObject?.season)) {
                let sortedArray = this.sortSeasonArray(finalObject?.season || [])
                finalObject.season = sortedArray
            }
            const errors = this.isInvalidPayload(finalObject)
            if (errors) {
                toast.error(errors?.error)
                return
            }

            if (
                !this.checkIfLanguageDataIsFilled(finalObject, this.languageObject)
                    ?.isAllLangDataFilled
            ) {
                this.setState({
                    emptyLangDataModal: true,
                    finalPayload: finalObject
                })
            } else {
                await this.submitData(finalObject)
            }
        }
    }

    modalCancelHandler = () => {
        this.setState({
            emptyLangDataModal: false
        })
    }

    submitData = async (finalObject) => {
        this.modalCancelHandler()
        if (this.props.isEdit) {
            finalObject["id"] = this.props.editId
        }
        this.setState({
            save: true
        })

        const response = await this.props.saveContentNav(finalObject, this.props.formData.id)
        if (get(response, "status")) {
            this.props.handleAddClick()
        } else {
            this.setState({
                save: false
            })
        }
    }

    loader = () => {
        return (
            <div className="addMovie-form-loader">
                <div className="content-header"></div>
                <div className="content">
                    <div className="posterImgUpload small"></div>
                    <div className="coverImgUpload large"></div>
                    <div className="col-medium"></div>
                    <div className="col-medium"></div>
                    <div className="col-small"></div>
                </div>
                <div className="content-header"></div>
                <div className="content">
                    <div className="col-medium"></div>
                    <div className="col-medium"></div>
                    <div className="col-small"></div>
                </div>
            </div>
        )
    }

    onLanguageChangeListener = (value) => {
        this.setState({
            selectedLanguage: value
        })
    }

    render() {
        const {
            formElementData,
            formMetaData,
            currentScreen,
            isLoading,
            emptyLangDataModal,
            finalPayload
        } = this.state
        const { isUpdate, isEdit, genres } = this.props
        let showIMDBButton = currentScreen === "addBrand" && isEdit
        showIMDBButton = false
        return (
            <React.Fragment>
                <div className="vd-addWebSeries">
                    <div className="container">
                        <div className="main-content">
                            <div className="form-content">
                                {isLoading ? (
                                    this.loader()
                                ) : (
                                    <>
                                        <div
                                            className={`${
                                                currentScreen === "addSeason"
                                                    ? "add-series-progress-season"
                                                    : "add-series-progress"
                                            } 
                        ${isUpdate ? "allFilled" : ""}
                          `}
                                        >
                                            <div className="series-step-container">
                                                <div className="series-step add-series-steps">
                                                    <div
                                                        className={
                                                            currentScreen === "addSeason" ||
                                                            isUpdate
                                                                ? "step-circle-success"
                                                                : "step-circle"
                                                        }
                                                    ></div>
                                                    <div
                                                        className={
                                                            currentScreen === "addSeason" ||
                                                            isUpdate
                                                                ? "step-text-success"
                                                                : "step-text"
                                                        }
                                                    >
                                                        <div>{TEXT_CONSTANTS?.STEP_1}</div>
                                                        <div className="screenName">
                                                            {TEXT_CONSTANTS?.BRAND}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="season-step add-series-steps step-second">
                                                    <div
                                                        className={
                                                            isUpdate
                                                                ? "step-circle-success"
                                                                : "step-circle"
                                                        }
                                                    ></div>
                                                    <div
                                                        className={
                                                            isUpdate
                                                                ? "step-text-success"
                                                                : "step-text"
                                                        }
                                                    >
                                                        <div>{TEXT_CONSTANTS?.STEP_2}</div>
                                                        <div className="screenName">
                                                            {TEXT_CONSTANTS?.SEASON}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="imdb-multilingual-container">
                                                    {showIMDBButton && (
                                                        <AuthWrapper
                                                            permissions={[
                                                                PERMISSIONS?.CONTENT_EDIT
                                                            ]}
                                                        >
                                                            <div className="season-step imdb-btn">
                                                                <Button
                                                                    cName={"btn border-btn"}
                                                                    bType={"submit"}
                                                                    bValue={
                                                                        BUTTON_CONSTANTS?.METADATA_UPDATE
                                                                    }
                                                                    bIcon={"icon-left-arrow"}
                                                                    clickHandler={() => {
                                                                        this.props.getContentEnrichment()
                                                                    }}
                                                                />
                                                            </div>
                                                        </AuthWrapper>
                                                    )}
                                                    <div
                                                        className={`content-multilingual ${
                                                            showIMDBButton
                                                                ? ""
                                                                : "content-multilingual-end"
                                                        }`}
                                                    >
                                                        {isMultiLingual() && (
                                                            <LanguageCardList
                                                                handleChange={
                                                                    this.onLanguageChangeListener
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <AddBrand
                                                data={
                                                    isMultiLingual()
                                                        ? formElementData?.attributes?.[
                                                              DEFAULT_SELECTED_LANGUAGE_CODE
                                                          ]?.[0]
                                                        : formElementData.attributes[0]
                                                }
                                                formMetaData={formMetaData}
                                                currentScreen={this.state.currentScreen}
                                                handleSave={this.handleSave}
                                                createWebSeries={createWebSeries}
                                                preFillData={
                                                    this.state.webSeriesCreateData.addBrand
                                                }
                                                selectedLanguage={this.state.selectedLanguage}
                                                multilingualKeys={this.state.multilingualKeys}
                                                genres={genres}
                                                certifications={this.props?.certifications}
                                                selectedData={this?.props?.selectedData}
                                            />
                                            <AddSeasonWrapper
                                                data={
                                                    isMultiLingual()
                                                        ? formElementData?.attributes?.[
                                                              DEFAULT_SELECTED_LANGUAGE_CODE
                                                          ]?.[1]
                                                        : formElementData.attributes[1]
                                                }
                                                currentScreen={this.state.currentScreen}
                                                handleSave={this.handleSave}
                                                genres={genres}
                                                formMetaData={formMetaData}
                                                preFillData={
                                                    this.state.webSeriesCreateData.addSeason
                                                }
                                                isEdit={this.props.isEdit}
                                                save={this.state.save}
                                                isModalOpen={this.props.isModalOpen}
                                                ceDataLoadingState={this.props.ceDataLoadingState}
                                                isModalViewOpen={this.props.isModalViewOpen}
                                                episodeIndex={this.props.episodeIndex}
                                                isImdbSuccessModal={this.props.isImdbSuccessModal}
                                                imdbLoadingState={this.props.imdbLoadingState}
                                                imdbData={this.props.imdbData}
                                                contentEnrichmentBtnHandler={
                                                    this.props.contentEnrichmentBtnHandler
                                                }
                                                submitModalHandler={this.props.submitModalHandler}
                                                fetchVideoMarkers={this.props.fetchVideoMarkers}
                                                contentEnrichmentViewBtnHandler={
                                                    this.props.contentEnrichmentViewBtnHandler
                                                }
                                                contentMarkers={this.props.contentMarkers}
                                                openImdbModal={this.props.openImdbModal}
                                                getContentEnrichment={
                                                    this.props.getContentEnrichment
                                                }
                                                viewData={this.props.viewData}
                                                webseriesData={this.state.webSeriesCreateData}
                                                contentType={this.props.contentType}
                                                selectedLanguage={this.state.selectedLanguage}
                                                multilingualKeys={this.state.multilingualKeys}
                                                certifications={this.props?.certifications}
                                                selectedData={this?.props?.selectedData}
                                                drmResponse={this.props.drmResponse}
                                            />
                                            <AuthWrapper permissions={[PERMISSIONS?.CONTENT_EDIT]}>
                                                <ImdbModal
                                                    isModalOpen={this.props.isImdbSuccessModal}
                                                    setModalOpen={this.props?.openImdbModal}
                                                    loadingState={this.props.imdbLoadingState}
                                                    imdbData={this.props.imdbData}
                                                ></ImdbModal>
                                            </AuthWrapper>
                                            {emptyLangDataModal && (
                                                <LanguageDataConfirmationModal
                                                    isModalOpen={emptyLangDataModal}
                                                    submitHandler={() =>
                                                        this.submitData(finalPayload)
                                                    }
                                                    cancelHandler={this.modalCancelHandler}
                                                ></LanguageDataConfirmationModal>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        darkMode: state.theme.darkMode
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                fetchFormElement,
                fetchEntityValueMasterData,
                fetchContent,
                createContent,
                saveContentNav
            },
            dispatch
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddWebSeries)
