import React, { Component } from "react"
import Button from "../../common/Button"
import "./style.scss"
import ProductList from "./ProductList"
import AdsClips from "./AdsClips"
import { BUTTON_CONSTANTS, TEXT_CONSTANTS } from "../../../utils/constants"
class ProductPlacement extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showProductList: false,
            selectedProductId: null,
            selectedProduct: {},
            showDetailPage: false
        }
    }
    handleSelectVideo = () => {
        this.setState({
            selectedProduct: !this.state.showProductList ? {} : this.state.selectedProduct,
            selectedProductId: !this.state.showProductList ? null : this.state.selectedProductId,
            showProductList: !this.state.showProductList
        })
    }
    handleProceed = (item) => {
        if (this.state.selectedProductId !== null) {
            this.setState(
                {
                    showDetailPage: true,
                    selectedProduct: item
                },
                () => {
                    this.handleSelectVideo()
                }
            )
        }
    }

    handleCheckProduct = (id) => {
        this.setState({
            selectedProductId: id
        })
    }
    handleCancelBtn = (id) => {
        this.setState({
            showDetailPage: false,
            showProductList: true,
            selectedProduct: {},
            selectedProductId: null
        })
    }
    render() {
        const { showProductList, selectedProductId, showDetailPage, selectedProduct } = this.state
        return (
            <>
                <div className="vr-cont-head mb">
                    {/* TODO: Replace below with the reusable pageTitleComponent */}
                    <div className="vr-cont-title">
                        <span className="vr-breadcrum">
                            {TEXT_CONSTANTS?.MONETIZE} <i className="icon-arrow-right1" />
                            <span>{TEXT_CONSTANTS?.PRODUCT_PLACEMENT?.toLowerCase()} </span>
                        </span>
                        <h2 className="breadcrum-header">
                            {TEXT_CONSTANTS?.PRODUCT_PLACEMENT?.toLowerCase()}
                        </h2>
                    </div>
                </div>
                {!showDetailPage ? (
                    <div className="vr-content-holder new-task">
                        <div className="vr-new-task-container">
                            <p>{TEXT_CONSTANTS?.SELECT_CONTENT_TO_ADD}</p>
                            <Button
                                cName="btn primary-btn"
                                bValue={BUTTON_CONSTANTS?.SELECT_VIDEO}
                                bType="button"
                                clickHandler={this.handleSelectVideo}
                            />
                        </div>
                    </div>
                ) : (
                    <AdsClips
                        selectedProduct={selectedProduct}
                        handleCancelBtn={this.handleCancelBtn}
                    />
                )}
                {showProductList && (
                    <ProductList
                        onClose={this.handleSelectVideo}
                        handleCheckProduct={this.handleCheckProduct}
                        selectedProductId={selectedProductId}
                        handleProceed={this.handleProceed}
                    />
                )}
            </>
        )
    }
}
export default ProductPlacement
