import React, { Component } from "react"
import Checkbox from "../../../common/Checkbox"
import Button from "../../../common/Button"
import SelectTarget from "./SelectTarget"
import VideoPlayerJS from "../../../VideoJsPlayer"
import { imgPathPrefix } from "../../APIs/constant"
import check_icon from "../../../../assets/images/check_icon.svg"
import { getContentData } from "../../../../utils/common"
import { BUTTON_CONSTANTS, PERMISSIONS, TEXT_CONSTANTS } from "../../../../utils/constants"
import { AuthWrapper } from "../../../common/AuthWrapper"

class ClipAccordion extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showModal: false,
            selectedTargets: {}
        }
    }

    handleSelectTarget = (id, data = null) => {
        if (data) {
            this.setState({
                selectedTargets: {
                    ...this.state.selectedTargets,
                    [id]: data
                },
                showModal: null
            })
        } else {
            this.setState({
                showModal: this.state.showModal ? null : id
            })
        }
    }
    render() {
        const { timeFrame, data, selectedClip, handleSelectClip, selectedAcc, handleAccordion } =
            this.props
        const { showModal, selectedTargets } = this.state
        return (
            <li className="accordion-list">
                <div className="accordion-header" onClick={() => handleAccordion(timeFrame)}>
                    <span className="accordion-title">
                        {TEXT_CONSTANTS?.TIME_FRAMES} <span className="bold">{timeFrame}</span>
                    </span>
                    <span>
                        <i className={selectedAcc !== timeFrame ? "icon-plus" : "icon-minus"}></i>
                    </span>
                </div>
                <div className={`accordion-content ${selectedAcc !== timeFrame && "hide"}`}>
                    <ul>
                        {data.map((clip, index) => {
                            return (
                                <li key={`clip_${index}`} className="clip-container">
                                    <div className="left-container">
                                        <div className="clip-title">
                                            <span className="bold">
                                                {getContentData(clip?.adName)}
                                            </span>
                                            <span className="timespan">
                                                {clip.timespan} <span>sec</span>
                                            </span>
                                        </div>
                                        <div className="clip-player">
                                            <VideoPlayerJS
                                                src={{
                                                    videoUrl: clip.playUrl,
                                                    videoPoster: `${imgPathPrefix}${clip.coverImage}`
                                                }}
                                                id={`clip_${clip.id}`}
                                            />
                                        </div>
                                        <div className="clip-checkbox">
                                            <Checkbox
                                                cName="select-checkBox"
                                                name={"clip-checkbox"}
                                                id={clip.id}
                                                changeHandler={(e) =>
                                                    handleSelectClip(clip.id, timeFrame)
                                                }
                                                isChecked={
                                                    selectedClip !== null &&
                                                    selectedClip === clip.id
                                                }
                                            />
                                            <span>
                                                {selectedClip !== null && selectedClip === clip.id
                                                    ? "Uncheck to remove"
                                                    : "Check to apply"}
                                            </span>
                                        </div>
                                        <AuthWrapper
                                            permissions={[
                                                PERMISSIONS?.MONETIZE_PRODUCT_PLACEMENT_EDIT
                                            ]}
                                        >
                                            <Button
                                                cName="btn border-btn"
                                                bValue={BUTTON_CONSTANTS?.SELECTED_TARGETS}
                                                bType="button"
                                                clickHandler={() =>
                                                    this.handleSelectTarget(clip.id)
                                                }
                                            />
                                        </AuthWrapper>
                                    </div>
                                    <div className="right-container">
                                        <div className="clip-title">
                                            <span className="bold">{TEXT_CONSTANTS?.TAGS} </span>
                                            <span>({TEXT_CONSTANTS?.OBJECT_PLACES})</span>
                                        </div>
                                        <ul className="clips-tag">
                                            {console.log(clip, "clip")}
                                            {clip?.tag.map((item, index) => {
                                                return <li key={`tags_${index}`}>{item}</li>
                                            })}
                                        </ul>
                                        {selectedTargets[clip.id] != null && (
                                            <>
                                                <hr />
                                                <div className="clip-title">
                                                    <span className="bold">
                                                        {TEXT_CONSTANTS?.SELECTED_TARGETS}{" "}
                                                    </span>
                                                    <span>
                                                        ({TEXT_CONSTANTS?.WHERE_WILL_AIRED})
                                                    </span>
                                                </div>
                                                <ul className="clips-tag">
                                                    {selectedTargets[clip.id].location.map(
                                                        (item, index) => {
                                                            return (
                                                                <li
                                                                    key={`tags_${index}`}
                                                                    className="selected"
                                                                >
                                                                    <span>{item}</span>
                                                                    <span className="icon">
                                                                        <img
                                                                            src={check_icon}
                                                                            alt="check icon"
                                                                        />
                                                                    </span>
                                                                </li>
                                                            )
                                                        }
                                                    )}
                                                    {selectedTargets[clip.id].age.map(
                                                        (item, index) => {
                                                            return (
                                                                <li
                                                                    key={`age_${index}`}
                                                                    className="selected"
                                                                >
                                                                    <span>{item}</span>
                                                                    <span className="icon">
                                                                        <img
                                                                            src={check_icon}
                                                                            alt="check icon"
                                                                        />
                                                                    </span>
                                                                </li>
                                                            )
                                                        }
                                                    )}
                                                    {selectedTargets[clip.id].gender.map(
                                                        (item, index) => {
                                                            return (
                                                                <li
                                                                    key={`gender_${index}`}
                                                                    className="selected"
                                                                >
                                                                    <span>{item}</span>
                                                                    <span className="icon">
                                                                        <img
                                                                            src={check_icon}
                                                                            alt="check icon"
                                                                        />
                                                                    </span>
                                                                </li>
                                                            )
                                                        }
                                                    )}
                                                </ul>
                                            </>
                                        )}
                                    </div>
                                    {showModal === clip.id && (
                                        <SelectTarget
                                            selectedTarget={selectedTargets[clip.id]}
                                            id={clip.id}
                                            onProceed={this.handleSelectTarget}
                                            onClose={this.handleSelectTarget}
                                        />
                                    )}
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </li>
        )
    }
}

export default ClipAccordion
