import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import get from "lodash/get"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { fetchContentTextPage, softDeleteFaqList } from "../APIs/action"
import Listcomponent from "./listcomponent"

class FaqList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isDelete: false,
            faqListData: []
        }
    }
    componentDidMount() {
        this.loadHandler()
    }

    loadHandler = async () => {
        const { editableData, fetchContentTextPage, getFaqsList } = this.props

        const response = await fetchContentTextPage(editableData.id)
        if (response && response.status) {
            this.setState(
                {
                    faqListData: response.data
                },
                () => {
                    getFaqsList(this.state.faqListData)
                }
            )
        }
    }

    deletefaqQA = async (id) => {
        const { softDeleteFaqList } = this.props
        const response = await softDeleteFaqList(id)
        if (response && response.status) {
            toast.success(get(response, "message"))
        } else {
            toast.error(get(response, "message"))
        }

        this.loadHandler()
    }

    getContent = (content) => {
        this.setState({
            content: content
        })
    }
    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    }

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return
        }

        const listdrop = this.reorder(
            this.state.faqListData.questionAnswers,
            result.source.index,
            result.destination.index
        )
        let temp = this.state.faqListData
        temp.faqList = listdrop
        this.setState({
            faqListData: temp
        })
    }

    render() {
        const { faqListData } = this.state
        const { selectedLanguage } = this.props
        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <ul
                            className="faqlist"
                            {...provided.droppableProps}
                            ref={provided.innerRef}>
                            {Object.keys(faqListData).length > 0 &&
                                faqListData.questionAnswers.map((element, index) => {
                                    return (
                                        <Draggable
                                            key={element.id}
                                            draggableId={`item-${element.id}`}
                                            index={index}>
                                            {(provided, snapshot) => (
                                                <Listcomponent
                                                    provided={provided}
                                                    id={index + 1}
                                                    question={element.question}
                                                    answer={element.answer}
                                                    key={index}
                                                    index={index}
                                                    selectedLanguage={selectedLanguage}
                                                    deletefaqQA={this.deletefaqQA}
                                                    handleChange={this.handleChange}
                                                    pageType={faqListData.type}
                                                    name={faqListData.name}
                                                    faqId={faqListData.id}
                                                    loadHandler={this.loadHandler}
                                                />
                                            )}
                                        </Draggable>
                                    )
                                })}
                        </ul>
                    )}
                </Droppable>
            </DragDropContext>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        faqList: get(state.customPage, "faqList", [])
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                // fetchFaqPage,
                softDeleteFaqList,
                fetchContentTextPage
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FaqList)
