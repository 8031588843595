export const configFields = [
    {
        name: "tenantName",
        label: "Tenant Name"
    },
    {
        name: "tenantAddress1",
        label: "Tenant Address1"
    },
    {
        name: "tenantAddress2",
        label: "Tenant Address2"
    },
    {
        name: "websiteUrl",
        label: "Website Url"
    },
    {
        name: "fbUrl",
        label: "Facebook Url"
    },
    {
        name: "yturl",
        label: "Youtube Url"
    },
    {
        name: "twitterUrl",
        label: "Twitter Url"
    },
    {
        name: "contactNo",
        label: "Contact No."
    },
    {
        name: "email",
        label: "Email"
    }
]
