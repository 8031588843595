import React, { Component } from "react"
import MultiSelect from "../../../common/CustomMultiselect"
import { toast } from "react-toastify"
import { isSuperAdminUser } from "../../../../utils/common"

class EditableMultiSelect extends Component {
    state = {
        value: this.props.value,
        isInEditMode: false,
        editableRoleValue: this.props?.selectedValues
    }

    canUserEditRole = () => {
        const { userInfo, selectedValues } = this.props
        if (isSuperAdminUser(userInfo?.roles)) {
            return true
        }
        // If user is not superAdmin, user cannot modify superAdmin roles rest they can modify
        return isSuperAdminUser(selectedValues) ? false : true
    }

    changeEditMode = () => {
        // only superAdmin can modify superAdmin roles preventing all other roles to change superAdmin role
        const isUserRoleEditable = this.canUserEditRole()
        this.setState({
            isInEditMode: !this.state.isInEditMode && !this.props.disabled && isUserRoleEditable
        })
        if (!isUserRoleEditable) {
            toast.error("Access denied!")
        }
    }

    selectedRoles = (roles) => {
        let roleName = []
        if (roles.length > 2) {
            roleName = roles.map((item, index) => {
                if (index < 2) return item.name
                else return null
            })
        } else {
            roleName = roles.map((item, index) => {
                return item.name
            })
        }
        let newRole = roleName.slice(0, 2).join(" , ")
        if (roles.length > 2) {
            newRole = (
                <p>
                    {newRole} <span id="indicator"> + {roles.length - 2}</span>
                </p>
            )
        }
        return newRole
    }
    updateComponentValue = () => {
        this.setState({
            isInEditMode: false
        })

        this.props.updateRole()
    }

    multiSelectChangeHandler = (user, value, id) => {
        const { handleOnSelect } = this.props
        // Selection of atleast one role is necessary
        if (value?.length > 0) {
            this.setState({
                editableRoleValue: [...value]
            })
            handleOnSelect(user, value, id)
        } else {
            toast.error("Atleast one role is required!")
        }
    }

    renderEditView = () => {
        const { userId, disabled, removeSuperAdminRole } = this.props
        const { editableRoleValue } = this.state
        const modifiedRoleList = removeSuperAdminRole()
        return (
            <div onBlur={this.updateComponentValue}>
                <MultiSelect
                    options={modifiedRoleList}
                    placeholder={"-select-"}
                    id={userId}
                    isAutoFocus={editableRoleValue?.length ? true : false}
                    selectedOptions={editableRoleValue}
                    changeHandler={this.multiSelectChangeHandler}
                    isDisabled={disabled}
                />
            </div>
        )
    }

    renderDefaultView = () => {
        const { selectedValues } = this.props
        return (
            <div onDoubleClick={this.changeEditMode} className="default-view-role">
                {this.selectedRoles(selectedValues)}
            </div>
        )
    }

    render() {
        const { selectedValues } = this.props
        return selectedValues.length
            ? this.state.isInEditMode
                ? this.renderEditView()
                : this.renderDefaultView()
            : this.renderEditView()
    }
}

export default EditableMultiSelect
