import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import Filter from "../../common/Filter"
import { filterList, dropdown } from "../api/constant"
import { fetchCategory, fetchCategoryById, updateCategory } from "../api/action"

import "./style.scss"

class CategoryHeader extends Component {
    constructor(props) {
        super(props)
        this.drowdownClick = {}
        this.state = {
            tab: [],
            platform: this.props.platform,
            activePageVisibility: dropdown[0],
            isClick: false
        }
    }
    componentDidMount() {
        this.loadHandler()
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.platform !== this.props.platform) {
            this.setState(
                {
                    platform: nextProps.platform
                },
                () => this.loadHandler()
            )
        }
        if (nextProps.selectedTab !== this.props.selectedTab) {
            let { platform, selectedTab, categoryList } = this.props
            categoryList.map((tab) => {
                if (tab.id === selectedTab && tab?.categoryPlatforms) {
                    tab.categoryPlatforms.map((ele) => {
                        if (ele.platformId === platform) {
                            if (ele.active) {
                                this.setState({
                                    activePageVisibility: dropdown[0]
                                })
                            }
                        }
                        return ele
                    })
                } else {
                    this.setState({
                        activePageVisibility: dropdown[1]
                    })
                }
                return tab
            })
        }
    }
    handleOutsideClick = (e) => {
        // ignore clicks on the component itself
        if (this.drowdownClick && this.drowdownClick.contains(e.target)) {
            return
        }
        this.toggleIsClick()
    }

    toggleIsClick = () => {
        if (!this.state.isClick) {
            document.addEventListener("click", this.handleOutsideClick, false)
        } else {
            document.removeEventListener("click", this.handleOutsideClick, false)
        }
        this.setState({ isClick: !this.state.isClick })
    }

    loadHandler = async () => {
        let { platform, selectedTab, categoryList } = this.props
        categoryList.map((tab) => {
            if (tab?.id === selectedTab && tab?.categoryPlatforms) {
                tab.categoryPlatforms.map((ele) => {
                    if (ele.platformId === platform) {
                        if (ele.active) {
                            this.setState({
                                activePageVisibility: dropdown[0]
                            })
                        } else {
                            this.setState({
                                activePageVisibility: dropdown[1]
                            })
                        }
                    }
                    return ele
                })
            }
            return tab
        })
    }

    render() {
        const { handleChangeView, isListView, selectedTabName, handlePublishFilter, published } =
            this.props
        return (
            <div className="category-headers">
                <div className="category-header">
                    <div className="category-view">
                        <Filter
                            cName="cs_filter"
                            text="All"
                            id="editorialFilter"
                            defaultValue={published}
                            filterList={filterList}
                            changeHandler={handlePublishFilter}
                        />
                        <div className="view">
                            <i
                                className={`icon-list ${isListView && "activeView"}`}
                                onClick={() => handleChangeView("list")}
                            ></i>
                            <i
                                className={`icon-grid ${!isListView && "activeView"}`}
                                onClick={() => handleChangeView("grid")}
                            ></i>
                        </div>
                    </div>
                </div>
                <div className="category-subHeader">
                    <div className="title">
                        <h2 className="subHeader-title">{selectedTabName}</h2>
                    </div>
                    {/* <div className="pageVisibility">
            <i className={activePageVisibility.icon}></i>
            <div className="rightNav">
              <span className="contentHead">{TEXT_CONSTANTS?.PAGE_VISIBILITY}</span>
              <div className="pageDropdown">
                <span onClick={this.toggleIsClick}>
                  {activePageVisibility.name}{" "}
                  <i className="icon-arrow-down1"></i>
                </span>
                <ul
                  className={isClick && "showdropdown"}
                  ref={(drowdownClick) => {
                    this.drowdownClick = drowdownClick;
                  }}
                >
                  {dropdown.map((item, index) => (
                    <li key={index} onClick={() => this.handleDropdown(item)}>
                      {item.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div> */}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                fetchCategory,
                fetchCategoryById,
                updateCategory
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryHeader)
