import MagnaQuestInstance from "./service"

// export const sendMagnaRequest = (params) => {
//   return (dispatch) => {
//     return MagnaQuestInstance.sendMagnaRequest(params)
//       .then((response) => {
//         dispatch({
//           type: ACTION.SAVE_ADD_URL,
//           apiResponse: response.data,
//         });
//         return response.data;
//       })
//       .catch((error) => {
//         console.log("Got error in ads preview URL list ", error);
//       });
//   };
// };

export const getPlansList = (params) => {
    return (dispatch) => {
        return MagnaQuestInstance.getPlansList(params)
            .then((response) => {
                // dispatch({
                //   type: ACTION.SAVE_ADD_URL,
                //   apiResponse: response.data,
                // });
                return response.data
            })
            .catch((error) => {
                console.log("Got error in plans URL list ", error)
            })
    }
}

export const getCouponsList = (params) => {
    return (dispatch) => {
        return MagnaQuestInstance.getCouponsList(params)
            .then((response) => {
                // dispatch({
                //   type: ACTION.SAVE_ADD_URL,
                //   apiResponse: response.data,
                // });
                return response.data
            })
            .catch((error) => {
                console.log("Got error in coupons URL list ", error)
            })
    }
}
