import React from "react"
import LoaderImg from "../../../assets/images/loader.gif"

import "./style.scss"

export default function Loader() {
    return (
        <div className="overlay">
            <div className="loader">
                <img src={LoaderImg} alt="loader" width={"100px"} />
            </div>
        </div>
    )
}
