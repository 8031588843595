import { ACTION } from "./constant"

let initialState = {}

export default function addUserReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION.FETCH_PERMISSIONS_LIST:
            return { ...state, permission: action.apiResponse.data }
        case ACTION.FETCH_ALL_ROLES:
            return { ...state, allRoles: action.apiResponse.data }
        case ACTION.FETCH_USERS_LIST:
            return { ...state, users: action.apiResponse }
        case ACTION.FETCH_ROLES_LIST:
            return { ...state, roles: action.apiResponse }
        // case ACTION.FETCH_ALL_USERS_LIST :
        //   return {...state, allUsers: action.apiResponse.data, userCount: action.apiResponse.data.length }
        default:
            return state
    }
}
