import React from "react"
// import "./style.scss";
import noContent from "../../../assets/images/noContent.svg"

import { useSelector } from "react-redux"
import Button from "../Button"
import { BUTTON_CONSTANTS } from "../../../utils/constants"

export default function EmptyContent({
    errorMsg,
    addNewButtonHandler,
    isButtonRequired = true,
    isErrorDesc = true
}) {
    const TEXT_LABEL = {
        errorDesc1: "“Add New” to upload"
    }

    const isDarkMode = useSelector((state) => state?.theme?.darkMode)

    return (
        <div className="vr-content-holder not-found-conatiner">
            <img
                src={isDarkMode ? noContent : noContent}
                alt="No Content Available"
                className="no-content-image"
            ></img>

            <h3 className="not-found-access-heading-color">{errorMsg}</h3>
            {isErrorDesc && <p className="desc-color">{TEXT_LABEL?.errorDesc1}</p>}
            {isButtonRequired && (
                <Button
                    bValue={BUTTON_CONSTANTS?.ADD_NEW}
                    clickHandler={addNewButtonHandler}
                    cName="btn primary-btn"
                />
            )}
        </div>
    )
}
