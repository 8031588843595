import React from "react"
import NoImgAvailable from "../../../assets/images/no_img_available.png"
import { imagePathPrefix } from "../../../config/apiPath"
import Checkbox from "../../common/Checkbox"
import "./DisplayCard.scss"
import Tooltip from "../../common/Tooltip"
import { CONTENT_TAB_TYPES } from "../API/constants"

export default function DisplayCard({
    id,
    thumbnail,
    selectedMoviesId,
    moviesCheckBoxHandler,
    title,
    selectedTab = CONTENT_TAB_TYPES?.MOVIES,
    showFirstFreeCheckbox,
    firstFreeCheckboxHandler,
    selectedFirstFreeWebSeriesId,
    isUnpublished = false,
    plans,
    plansList,
    count
}) {
    const joinUrl = (string) => {
        return imagePathPrefix + string
    }

    const isWebSeriesData = selectedTab === CONTENT_TAB_TYPES?.WEBSERIES

    const getPlanDetailsFromPlanList = (planCode) => {
        const currPlan = plansList?.find((plan) => plan?.code === planCode)
        return currPlan
    }

    const onMouseOverHandler = (title, right) => {
        if (isWebSeriesData) {
            const element = document.getElementById(`tooltip-${id}`)
            element.textContent = title
            element.style.display = "block"
            element.style.right = "unset"
            if (right) {
                element.style.right = right
            }
        }
    }

    const onMouseOutHandler = () => {
        if (isWebSeriesData) {
            const element = document.getElementById(`tooltip-${id}`)
            element.style.display = "none"
        }
    }

    return (
        <div className={` single-card-view ${isUnpublished ? "single-unpublished-card" : ""}`}>
            <div className={`single-cardImage `}>
                {isUnpublished && (
                    <div className="unpublished-card-container">
                        <p> Unpublished</p>
                    </div>
                )}
                <div className="single-card-no-img">
                    <img
                        src={
                            thumbnail && thumbnail !== null
                                ? joinUrl(thumbnail)
                                : `${NoImgAvailable}`
                        }
                        alt="thumbnail"
                        className={!thumbnail ? "no-img-available" : "movie-Thumbnail"}
                        onError={(e) => {
                            e.target.onerror = null
                            e.target.src = `${NoImgAvailable}`
                            e.target.className = `no-img-available`
                        }}
                    />
                </div>
                <div className="selected-plans-icon">
                    {plans?.map((plan) => {
                        const currentPlan = getPlanDetailsFromPlanList(plan)
                        return (
                            <Tooltip
                                tooltiptext={currentPlan?.name
                                    ?.split(" ")
                                    ?.map((i) => i[0])
                                    ?.join("")}
                                innerText={[{ name: currentPlan?.description?.toLowerCase() }]}
                                backgroundColorName={currentPlan?.colorCode}
                            />
                        )
                    })}
                </div>
            </div>
            <div className="selected-plan-heading">
                {count && <p className="selected-season-tooltip" id={`tooltip-${id}`}></p>}
                <Checkbox
                    cName="check-box"
                    id={id}
                    name={id}
                    isChecked={selectedMoviesId?.includes(id)}
                    changeHandler={moviesCheckBoxHandler}
                    labelText={title}
                    onMouseOver={() => {
                        onMouseOverHandler(
                            `Season-${count?.season} / ${count?.episode} ${
                                count?.episode === 1 ? "Episode" : "Episodes"
                            }`
                        )
                    }}
                    onMouseOut={onMouseOutHandler}
                />
                {isWebSeriesData && showFirstFreeCheckbox && (
                    <Checkbox
                        cName="check-box"
                        id={`firstFreeCheckboxHandler-${id}`}
                        name={id}
                        isChecked={selectedFirstFreeWebSeriesId?.includes(id)}
                        changeHandler={firstFreeCheckboxHandler}
                        labelText={"FF"}
                        onMouseOver={() => {
                            onMouseOverHandler(`First Episode Free`, "20px")
                        }}
                        onMouseOut={onMouseOutHandler}
                    />
                )}
            </div>
        </div>
    )
}
