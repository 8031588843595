import React, { useEffect } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Link } from "react-router-dom"
import NoImgAvailable from "../../assets/images/no_image.jpg"

import { logoutUser, sendLogoutToken } from "../LoginPanel/APIs/action"
import { viewProfile } from "./api/action"
import "./style.scss"
import { imagePathPrefix } from "../../config/apiPath"
import { TEXT_CONSTANTS } from "../../utils/constants"

const User = (props) => {
    const { viewProfile } = props
    useEffect(() => {
        viewProfile()
    }, [viewProfile])

    const onLogout = async () => {
        const { logoutUser, history, sendLogoutToken } = props
        await sendLogoutToken() // vapt logout api implementation requirement
        await logoutUser()

        const logoutUrl = "/"
        !localStorage.getItem("accessToken") && history.push(logoutUrl)
    }

    const { userProfile } = props

    let backgroundValue
    if (userProfile && userProfile.profilePicture) {
        backgroundValue = `${imagePathPrefix}${userProfile.profilePicture.path}`
    } else {
        backgroundValue = NoImgAvailable
    }

    let profilePic = {
        backgroundImage: `url(${backgroundValue})`
    }
    return (
        <div className="vr-user-panel">
            <span className="user-icon" style={profilePic}>
                <span className="profile-image">
                    <i className="icon-profile" />
                </span>
            </span>
            <ul className="menu-dropdown">
                <li>
                    <div className="user">
                        <span className="user-name">{userProfile && userProfile.name}</span>
                        <i className="up-down-arrow">
                            <svg width="20" height="13">
                                <path
                                    fill="none"
                                    fillRule="evenodd"
                                    stroke="#5B539A"
                                    opacity=".6"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M5.063 2.102L9.942 7.45l3.904-5.348"
                                />
                            </svg>
                        </i>
                    </div>
                    <ul className="menu-dropdown-list">
                        <li>
                            <Link to={"/myprofile"}>
                                {TEXT_CONSTANTS?.MY_PROFILE?.toUpperCase()}
                            </Link>
                        </li>
                        <li onClick={onLogout}>{TEXT_CONSTANTS?.SIGN_OUT?.toUpperCase()}</li>
                    </ul>
                </li>
            </ul>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        userProfile: state.userProfile.userDetails
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                logoutUser,
                viewProfile,
                sendLogoutToken
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(User)
