import React from "react"

import "./style.scss"
import { BUTTON_CONSTANTS } from "../../../../utils/constants"

function ImageUplode(props) {
    const { id, value, isSubMenu, errMsg } = props

    const imageChangeHandler = (e) => {
        const { files } = e.target

        const modifiedName = files?.[0]?.name?.replace(/[^a-zA-Z.0-9_-]/g, "")
        const modifiedFile = new File([files[0]], modifiedName, {
            type: files[0].type
        })

        let data = new FormData()
        data.append("image", modifiedFile)
        props.uploadImage(data, id, "menuIcon", isSubMenu)
    }

    return (
        <div>
            <span className="img-header-label"></span>
            <label for={"MenuIcon" + id}>
                <div class="addIconImage">
                    {value ? (
                        <>
                            <img src={`${value}`} alt="" id="showImg" />
                            <div className="overlay-btn">
                                <label htmlFor={"MenuIcon" + id} className="btn">
                                    {BUTTON_CONSTANTS?.CHANGE}
                                </label>
                                <button
                                    className="btn"
                                    onClick={(e) => {
                                        props.removeImg("menuIcon", id, isSubMenu)
                                        e.stopPropagation()
                                        e.preventDefault()
                                    }}
                                >
                                    {BUTTON_CONSTANTS?.REMOVE}
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <i className="icon-plus"></i>
                            <span>{"Menu Icon"}</span>
                        </>
                    )}
                </div>
            </label>
            <input
                type={"file"}
                data-id={"MenuIcon" + id}
                accept="image/svg+xml"
                id={"MenuIcon" + id}
                name={"menuIcon"}
                onClick={(e) => {
                    e.currentTarget.value = null
                }}
                onChange={imageChangeHandler}
            />
            {errMsg && <span className="err">{errMsg}</span>}
        </div>
    )
}

export default ImageUplode
