import React from "react"

import Accordion from "./Accordion"
import NoImgAvailable from "../../../assets/images/no_img_available.png"
import { imagePathPrefix } from "../../../config/apiPath"

import "./style.scss"
import { joinUrl } from "../../../utils/common"

export const WebSeriesContentContainer = (props) => {
    const { seasonPosterImage, seasonReleaseYear, title, seasonId } = props.data
    const { contentType } = props
    const { meta } = props
    const getContent = () => {
        return (
            <div className="content-list">
                {props?.data?.episodes?.map((value, index) => {
                    return (
                        <div className="sub-container">
                            <p>{`Ep.${value?.episodeNumber}`}</p>
                            <img
                                className="episode-icon"
                                src={joinUrl(value?.episodePosterImage)}
                                onError={(e) => {
                                    e.target.onerror = null
                                    e.target.src = `${NoImgAvailable}`
                                }}
                                alt="episode-icon-"></img>
                            {!props?.checkIfSelected(value?.episodeId) ? (
                                <div
                                    className="expand-collapse-icon icon-plus"
                                    onClick={() =>
                                        props?.addMovie({
                                            episodeId: value?.episodeId,
                                            contentId: meta.id,
                                            id: value?.episodeId,
                                            title: meta.title,
                                            seasonId,
                                            seasonNo: value?.seasonNo,
                                            episodeNumber: value?.episodeNumber,
                                            subTitle: value?.seasonTitle,
                                            portraitImage: meta.posterImage,
                                            landscapeImage: meta.coverImage,
                                            posterImage: value?.episodePosterImage,
                                            logo: value?.episodePosterImage,
                                            yearOfRelease: value?.seasonReleaseYear,
                                            contentType
                                        })
                                    }></div>
                            ) : (
                                <div
                                    className="expand-collapse-icon icon-minus"
                                    onClick={() =>
                                        props?.removeMovie({
                                            contentId: meta.id,
                                            id: value?.episodeId,
                                            title: meta.title,
                                            episodeNumber: value?.episodeNumber,
                                            subTitle: title,
                                            portraitImage: meta.posterImage,
                                            landscapeImage: meta.coverImage,
                                            posterImage: value?.episodePosterImage,
                                            logo: value?.episodePosterImage,
                                            yearOfRelease: value?.seasonReleaseYear,
                                            contentType
                                        })
                                    }></div>
                            )}
                        </div>
                    )
                })}
            </div>
        )
    }
    const accordionItems = [
        {
            title: (
                <div className="header">
                    <img
                        className="header-img"
                        src={
                            seasonPosterImage.includes("https")
                                ? seasonPosterImage
                                : `${imagePathPrefix}${seasonPosterImage}`
                        }
                        alt=""></img>
                    <p className="content-container">
                        <p className="title">
                            <span className="sub-title">
                                {`${props?.meta?.title} | `}
                                <span className="season-no">{title}</span>
                            </span>
                        </p>
                        <p className="year">{seasonReleaseYear}</p>
                    </p>
                </div>
            ),
            content: getContent /*  (
        <div className="content-list">
          {props?.data?.episodes?.map((value, index) => {
            return (
              <div className="sub-container">
                <p>{`Ep.${value?.episodeNumber}`}</p>
                <img
                  className="episode-icon"
                  src={`${imagePathPrefix}${value?.episodePosterImage}`}
                ></img>
                <div
                  className="expand-collapse-icon icon-plus"
                  onClick={() => props?.add()}
                ></div>
              </div>
            );
          })}
        </div>
      ), */
        }
    ]
    return (
        <div className="web-series-content-container">
            <Accordion
                items={accordionItems}
                accordionContainerClass="acc-container"
                showSideArrows={true}
            />
        </div>
    )
}
