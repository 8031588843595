import { isEmpty } from "lodash"
import React from "react"
import { useState } from "react"
import { BUTTON_CONSTANTS, TEXT_CONSTANTS } from "../../../utils/constants"
import Input from "../../common/Input"
import Button from "../../common/Button"
import CustomModal from "../../common/Modal"

import "./RenderSaveFilter.scss"

export default function RenderSaveFilter({
    saveCurrentFilterDataAPI,
    isSaveFilterDisabled,
    savedFilterList
}) {
    const [filterName, setFilterName] = useState("")
    const [error, setError] = useState("")
    const [saveFilterModal, setSaveFilterModal] = useState(false)

    const saveFilterBtnHandler = () => {
        setSaveFilterModal(true)
    }

    const filterNameChangeHandler = (name, value) => {
        setFilterName(value)
    }

    const submitFilterHandler = () => {
        if (isEmpty(filterName)) {
            setError("Filter name is required!")
            return
        }
        let nameAlreadyExists = false
        savedFilterList &&
            savedFilterList.forEach((item) => {
                if (item?.name?.trim()?.toLowerCase() === filterName?.trim()?.toLowerCase()) {
                    nameAlreadyExists = true
                }
            })
        if (nameAlreadyExists) {
            setError("Please enter unique filter name!")
            return
        }

        saveCurrentFilterDataAPI(filterName)
        setError("")
        setFilterName("")
        setSaveFilterModal(false)
    }

    const renderFilterJSX = () => {
        return (
            <div className="save-filter-modal-container">
                <h3>{TEXT_CONSTANTS?.ADD_FILTER}</h3>
                <p>{TEXT_CONSTANTS?.FILTER_DES}</p>
                <Input
                    type={"text"}
                    labelText={TEXT_CONSTANTS?.FILTER_NAME?.toUpperCase()}
                    name={"filterName"}
                    required={true}
                    value={filterName}
                    changeHandler={(name, value) => filterNameChangeHandler(name, value)}
                    errMsg={error}
                />
            </div>
        )
    }

    return (
        <>
            <Button
                cName={"save-filter-btn"}
                bType={"btn"}
                bValue={BUTTON_CONSTANTS?.SAVE_FILTER?.toUpperCase()}
                clickHandler={saveFilterBtnHandler}
                disabled={isSaveFilterDisabled}
            ></Button>
            <CustomModal
                showModal={saveFilterModal}
                submitHandler={submitFilterHandler}
                cancelHandler={() => {
                    setSaveFilterModal(false)
                    setFilterName("")
                }}
                closeOnSubmit={false}
                submitButtonLabel={BUTTON_CONSTANTS?.SAVE?.toUpperCase()}
                showCancelButton={true}
                cancelOnOutsideClick={false}
                showCloseIcon={false}
                submitBtnClass={"confirmation-submit-btn"}
                cancelBtnClass={"confirmation-cancel-btn"}
            >
                {renderFilterJSX()}
            </CustomModal>
        </>
    )
}
