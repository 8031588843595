import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import moment from "moment"

import Button from "../../common/Button"
import { ProductMenu } from "./ProductConstants"
import Loader from "../../../assets/images/loader.gif"
import { MASTER_TENANT_IDENTIFIER_NAME, TEXT_CONSTANTS } from "../../../utils/constants"
import { getCouponsList } from "../APIs/action"
import MagnaQuestIframe from "../MagnaQuestIframe"
import { MenuTokenFun } from "../MenuTokenConstant"
import { toast } from "react-toastify"

import "./style.scss"
import MagnaQuest from ".."
/* admin work */

class COUPONS extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedOptionURL: "",
            couponsListData: [],
            showIFrame: false,
            searchValue: "",
            isMasterTenant: props?.tenantIdentifier === MASTER_TENANT_IDENTIFIER_NAME
        }
    }

    addNewCouponHandler = () => {
        const MenuToken = MenuTokenFun()
        this.setState({
            showIFrame: true,
            selectedOptionURL: `https://demo-ttnsandbox.magnaquest.com//OrderManagement/NewCoupon/NewCoupon?MenuID=${MenuToken.coupons.MENUID}`
        })
    }

    editCoupons = (copounCode) => {
        const MenuToken = MenuTokenFun()
        this.setState({
            showIFrame: true,
            selectedOptionURL: `https://demo-ttnsandbox.magnaquest.com//OrderManagement/NewCoupon/NewCoupon?MenuID=${MenuToken.coupons.MENUID}&Couponcode=${copounCode}`
        })
    }

    componentDidMount() {
        !this.state.isMasterTenant && this.loadHandler()
    }

    loadHandler = async () => {
        const { getCouponsList } = this.props
        this.setState({
            isLoading: true
        })
        const res = await getCouponsList()
        if (res?.status) {
            this.setState({
                couponsListData: res?.data?.coupons,
                isLoading: false
            })
            return
        }
        this.setState({
            isLoading: false
        })
        toast.error(res?.message)
    }

    handleBackBtn = () => {
        this.setState({
            showIFrame: false
        })
        this.loadHandler()
    }

    renderCouponsData = () => {
        const { isLoading, showIFrame, selectedOptionURL } = this.state
        const tableHeading = ProductMenu.map((item, index) => {
            return (
                <th key={index}>
                    <p>
                        <span>{item.key}</span>{" "}
                    </p>
                </th>
            )
        })
        const couponsData = this.state?.couponsListData
        const tableRows =
            couponsData &&
            Array.isArray(couponsData) &&
            couponsData
                .filter((item) => item?.status?.toLowerCase() === "a")
                .map((row, i) => {
                    return (
                        <tr id={row.couponCode} key={row.couponCode}>
                            <td>{i + 1}</td>

                            <td>{row?.couponCode}</td>
                            <td>{row?.status}</td>
                            <td>{moment(row?.validFrom)?.format(" DD MMMM, YYYY")}</td>
                            <td>{moment(row?.validTo)?.format(" DD MMMM, YYYY")}</td>
                            <td>{row?.redeemLimit}</td>

                            <td className="btn-popupContainer">
                                <p onClick={() => this.editCoupons(row.couponCode)}>
                                    <i className="action-btn icon-edit"></i>
                                </p>
                            </td>
                        </tr>
                    )
                })

        return this.state?.isMasterTenant ? (
            <MagnaQuest path={"coupons"}></MagnaQuest>
        ) : (
            <div>
                {!showIFrame ? (
                    <div className="vr-content-holder">
                        {
                            <div className="tenant-list-container magna-tenant-container">
                                <p className="add-new-tenant">
                                    <p className="tenant-heading">
                                        {TEXT_CONSTANTS?.MANAGE_COUPONS}
                                    </p>
                                    <div className="search-container">
                                        <Button
                                            type="button"
                                            clickHandler={() => this.addNewCouponHandler()}
                                            bValue={TEXT_CONSTANTS?.ADD_NEW_COUPON}
                                            cName="btn primary-btn"
                                        ></Button>
                                    </div>
                                </p>
                                <table className="table">
                                    <thead>
                                        <tr>{tableHeading}</tr>
                                    </thead>
                                    {!isLoading ? (
                                        <tbody>{tableRows}</tbody>
                                    ) : (
                                        <tbody>
                                            <img className="loader" src={Loader} alt="loader" />
                                        </tbody>
                                    )}
                                </table>
                            </div>
                        }
                    </div>
                ) : (
                    <MagnaQuestIframe
                        iframeURL={selectedOptionURL}
                        handleBackBtn={this.handleBackBtn}
                        location={this.props?.location}
                    ></MagnaQuestIframe>
                )}
            </div>
        )
    }

    render() {
        return this.renderCouponsData()
    }
}

const mapStateToProps = (state) => {
    return {
        tenantIdentifier: state?.AdminHomeReducer?.tenantInfo?.data?.tenantIdentifier
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                getCouponsList
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(COUPONS)
