import ApiService from "../../../utils/apiService"
import { API_ROOT_PATH } from "../../../config/apiPath"
import { CUSTOM_PAGE_API_PREFIX } from "./constants"

class CustomPageService {
    //--text pages services---

    createTextPage(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}${CUSTOM_PAGE_API_PREFIX}`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }

    fetchContentTextPage(id) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}${CUSTOM_PAGE_API_PREFIX}/${id}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    //---custom main pages services---

    fetchAlltextpage(params) {
        const { limit, offset } = params
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}${CUSTOM_PAGE_API_PREFIX}?offset=${offset}&limit=${limit}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    softDeleteTextpage(id) {
        const options = {
            method: "DELETE",
            url: `${API_ROOT_PATH}${CUSTOM_PAGE_API_PREFIX}/${id}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    // ---faq services---

    createFaq(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/core/faq-page/create`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }

    fetchFaqPage() {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/core/faq-page/`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    fetchContentFaqPage(id) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/core/faq-page/${id}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    softDeleteFaqList(id) {
        // const {id} = params
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/core/page/delete/question/${id}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    // ---create form ---

    fetchFormField() {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/core/form-field/`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    createFormPage(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/core/form-page/create`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }

    fetchCreatedForm(id) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/core/form-page/${id}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    updateCustomPageById(params) {
        const options = {
            method: "PUT",
            url: `${API_ROOT_PATH}${CUSTOM_PAGE_API_PREFIX}/${params.id}`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }
}

const CustomPageServiceInstance = new CustomPageService()
export default CustomPageServiceInstance
