import { ACTION } from "./constants"
import CustomPageService from "./service"

export const createTextPage = (params) => {
    return (dispatch) => {
        return CustomPageService.createTextPage(params)
            .then((response) => {
                dispatch({
                    type: ACTION.CREATE_CUSTOM_TEXT_PAGE,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in creating text page ", error)
            })
    }
}

// ---custom main page---

export const fetchContentTextPage = (params) => {
    return (dispatch) => {
        return CustomPageService.fetchContentTextPage(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_CONTENT_TEXT_PAGE,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in fetching list ", error)
            })
    }
}

export const fetchAlltextpage = (params) => {
    return (dispatch) => {
        return CustomPageService.fetchAlltextpage(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_ALL_TEXT_PAGE,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting text list ", error)
            })
    }
}

export const softDeleteTextpage = (params) => {
    return (dispatch) => {
        return CustomPageService.softDeleteTextpage(params)
            .then((response) => {
                dispatch({
                    type: ACTION.SOFT_DELETE_TEXT_PAGE,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in deleting list", error)
            })
    }
}

export const filterTextpage = (params) => {
    return (dispatch) => {
        return CustomPageService.filterTextpage(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FILTER_TEXT_PAGE,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in filter text list ", error)
            })
    }
}

// ---Faq action---

export const createFaq = (params) => {
    return (dispatch) => {
        return CustomPageService.createFaq(params)
            .then((response) => {
                dispatch({
                    type: ACTION.CREATE_CUSTOM_FAQ,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in creating Faq ", error)
            })
    }
}

export const fetchFaqPage = (params) => {
    return (dispatch) => {
        return CustomPageService.fetchFaqPage(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_FAQ_PAGE,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in  Faq page ", error)
            })
    }
}

export const fetchContentFaqPage = (params) => {
    return (dispatch) => {
        return CustomPageService.fetchContentFaqPage(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_CONTENT_FAQ_PAGE,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in editing faq page ", error)
            })
    }
}

export const softDeleteFaqList = (params) => {
    return (dispatch) => {
        return CustomPageService.softDeleteFaqList(params)
            .then((response) => {
                dispatch({
                    type: ACTION.SOFT_DELETE_FAQ_LIST,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in deleting list ", error)
            })
    }
}

//---create form page---

export const fetchFormField = (params) => {
    return (dispatch) => {
        return CustomPageService.fetchFormField(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_FORM_FIELD,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in fetching form field ", error)
            })
    }
}

export const createFormPage = (params) => {
    return (dispatch) => {
        return CustomPageService.createFormPage(params)
            .then((response) => {
                dispatch({
                    type: ACTION.CREATE_FORM_PAGE,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in creating form page ", error)
                return error
            })
    }
}

export const fetchCreatedForm = (params) => {
    return (dispatch) => {
        return CustomPageService.fetchCreatedForm(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_CREATED_FORM,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in editing form ", error)
            })
    }
}

export const updateCustomPageById = (params) => {
    return (dispatch) => {
        return CustomPageService.updateCustomPageById(params)
            .then((response) => {
                dispatch({
                    type: ACTION.UPDATE_CUSTOM_PAGE_BY_ID,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in editing form ", error)
            })
    }
}
