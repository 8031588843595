import DrmServiceInstance from "./service"
import { toast } from "react-toastify"

export const getBasicConfig = () => {
    return (dispatch) => {
        return DrmServiceInstance.getBasicConfig()
            .then(async (response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Get Basic config error " + error?.message)
                return error
            })
    }
}

export const saveBasicConfig = (params) => {
    return (dispatch) => {
        return DrmServiceInstance.saveBasicConfig(params)
            .then(async (response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Save Basic config error " + error?.message)
                return error
            })
    }
}

export const createEditSplashScreen = (params, id = "") => {
    return (dispatch) => {
        return DrmServiceInstance.createEditSplashScreen(params, id)
            .then(async (response) => {
                toast.success(response.data.message)
                return response.data
            })
            .catch((error) => {
                console.log("Create Splash Screen error " + error?.message)
                toast.error(error?.message)
                return error
            })
    }
}

export const getSplashScreen = (params) => {
    return (dispatch) => {
        return DrmServiceInstance.getSplashScreen(params)
            .then(async (response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Get Splash Screen error " + error?.message)
                return error
            })
    }
}

export const uploadSplashImage = (params) => {
    return (dispatch) => {
        return DrmServiceInstance.uploadSplashImage(params)
            .then(async (response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Upload Splash Image API error " + error?.message)
                toast.error("Upload image failed")
                return error
            })
    }
}
