import React, { Component } from "react"
import get from "lodash/get"
// import ViewRightsDM from "../../../../assets/images/view-dark-mode.svg";
// import ViewRightsLM from "../../../../assets/images/view-light-mode.svg";
import { TABLE_HEADING, imgPathPrefix } from "../../api/constants"
import "./style.scss"
import Checkbox from "../../../common/Checkbox"
import Button from "../../../common/Button"
import NoImgAvailable from "../../../../assets/images/no_img_available.png"
import { dateTime, getContentData } from "../../../../utils/common"
import { BUTTON_CONSTANTS, PERMISSIONS, TEXT_CONSTANTS } from "../../../../utils/constants"
import { AuthWrapper } from "../../../common/AuthWrapper"
import { getRightsManagement } from "../../api/action"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

class MovieCardList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isShowIcons: false,
            idToDelete: null,
            isDelete: false,
            highResImageLoaded: false
        }
    }
    // function to show icons menu
    showIconsMenu = () => {
        const { isShowIcons } = this.state
        this.setState({
            isShowIcons: !isShowIcons
        })
    }

    getGenreList = (data) => {
        let list = []
        data &&
            data.forEach((item) => {
                let genreName = item.name
                list.push(`#${genreName}`)
            })
        return list.join(" ")
    }

    handleDelete = (id) => {
        if (id) {
            this.setState({
                idToDelete: id,
                isDelete: true
            })
        } else {
            this.setState({
                idToDelete: null,
                isDelete: false
            })
        }
    }

    handleUnpublish = (id) => {
        if (id) {
            this.setState({
                idToUnpublish: id,
                isUnpublish: true
            })
        } else {
            this.setState({
                idToUnpublish: null,
                isUnpublish: false
            })
        }
    }

    handleViewRights = async (val) => {
        // const { getRightsManagement } = this.props;
        // let response = await getRightsManagement(val?.content?.asset_id);
        // if (response && response?.status) {
        //   this.props.setViewRightsId(response?.data?.rights);
        //   this.props.manageModal();
        // } else {
        //   toast.error("No rights found");
        // }
        this.props.manageModal()
    }
    render() {
        const {
            movieList,
            markSelected,
            handleEdit,
            selectedCards,
            handleUnpublishClick,
            handleDeleteContent,
            imageKey,
            activeCardId
        } = this.props
        const { idToDelete, isDelete, idToUnpublish, isUnpublish, highResImageLoaded } = this.state
        const tableHeadings =
            movieList[0].content?.contentType === "MOVIES"
                ? TABLE_HEADING.map((item, index) => {
                      return <th key={index}>{item.value}</th>
                  })
                : movieList[0].content?.brand_genre || movieList[0]?.content?.genre
                ? TABLE_HEADING.filter((item) => item.value !== "title").map((item, index) => {
                      return <th key={index}>{item.value}</th>
                  })
                : TABLE_HEADING.filter(
                      (item) => item.value !== "genre" && item.value !== "title"
                  ).map((item, index) => <th key={index}>{item.value}</th>)

        const tableRows = movieList.map((row, index) => {
            const { date, time } = dateTime(row.publishedDate)
            return (
                <tr
                    id={row.id}
                    key={row.id}
                    className={`movie-list-item ${row.active ? "" : "greyScale"}`}>
                    <td className="title-detail">
                        {console.log(selectedCards.includes(row.id), row, selectedCards, "check")}
                        <div
                            className={`selectCheckBox ${
                                selectedCards && selectedCards.length ? "showMe" : ""
                            } ${
                                isDelete || isUnpublish || selectedCards.includes(row.id)
                                    ? "selected"
                                    : ""
                            }`}>
                            <Checkbox
                                cName="select-checkBox"
                                name={row.content?.title}
                                id={row.id}
                                changeHandler={markSelected}
                                activeCardId={activeCardId}
                                isChecked={selectedCards && selectedCards.includes(row.id) && true}
                                activeIndex={row.active}
                            />
                        </div>
                        <div className={`movieBanner logo`}>
                            <img
                                // src={get(row, 'posterImage')}
                                onLoad={() => {
                                    this.setState({ highResImageLoaded: true })
                                }}
                                src={
                                    row && row.content && row.content[imageKey]
                                        ? `${imgPathPrefix}${getContentData(row.content[imageKey])}`
                                        : `${NoImgAvailable}`
                                }
                                alt="banner"
                                onError={(e) => {
                                    e.target.onerror = null
                                    e.target.src = `${NoImgAvailable}`
                                    e.target.className = `no-img-available`
                                }}
                            />
                            <img
                                {...(highResImageLoaded && { style: { opacity: "0" } })}
                                alt=""
                                className="overlayStyles"
                                src=""
                            />
                        </div>
                        <span>{get(row, "moviesName")}</span>
                    </td>
                    {row?.content?.contentType === "MOVIES" ? (
                        <td className="genre-detail">
                            <span>{row?.content?.movie_title?.values?.en}</span>
                        </td>
                    ) : (
                        ""
                    )}
                    {row?.content?.brand_genre || row?.content?.genre ? (
                        <td className="genre-detail">
                            <span>
                                {
                                    (row.content,
                                    row.content.contentType === "WEB SERIES" &&
                                    row?.content?.brand_genre
                                        ? getContentData(row.content?.brand_genre)
                                        : getContentData(row?.content?.genre))
                                }
                            </span>
                        </td>
                    ) : (
                        ""
                    )}
                    <td className="published-detail">
                        {row.active ? (
                            <>
                                <span>{date}</span>
                                <span> &middot; </span>
                                <span>{time}</span>
                            </>
                        ) : (
                            <span> — </span>
                        )}
                    </td>
                    <td className="btn-popupContainer">
                        <div className="icons-menu">
                            <div className={"action-icons-list"}>
                                <AuthWrapper permissions={[PERMISSIONS.CONTENT_EDIT]}>
                                    <div
                                        className={`toolsBtn ${
                                            row.active ? "icon-unpublish" : "icon-published"
                                        }`}
                                        onClick={() => this.handleUnpublish(row.id)}>
                                        <span class="tooltiptext">
                                            {row.active ? "Unpublish" : "Publish"}
                                        </span>
                                    </div>
                                </AuthWrapper>
                                {/* <div
                  className="toolsBtn"
                  onClick={() => this.handleViewRights(row)}
                >
                  <img
                    src={this.props.darkMode ? ViewRightsDM : ViewRightsLM}
                    alt="view rights"
                  ></img>
                  <span class="tooltiptext">
                    {BUTTON_CONSTANTS?.VIEW_RIGHTS}
                  </span>
                </div> */}
                                <div
                                    className="toolsBtn icon-edit"
                                    onClick={() => handleEdit(row.id)}>
                                    <span class="tooltiptext">{BUTTON_CONSTANTS?.EDIT}</span>
                                </div>
                                <AuthWrapper permissions={[PERMISSIONS.CONTENT_EDIT]}>
                                    <div
                                        className="toolsBtn icon-del"
                                        onClick={() => this.handleDelete(row.id)}>
                                        <span class="tooltiptext">{BUTTON_CONSTANTS?.DELETE}</span>
                                    </div>
                                </AuthWrapper>
                            </div>
                            <i className={"icon-dot-3"}></i>
                        </div>
                        {idToDelete === row.id && (
                            <div className={`deletePopup ${isDelete ? "showPopUp" : "hidePopUp"}`}>
                                <p>{TEXT_CONSTANTS?.DELETE_MOVIE}</p>
                                <Button
                                    cName="btn popup-del-btn"
                                    bValue={BUTTON_CONSTANTS?.YES_DELETE}
                                    bType="button"
                                    clickHandler={() => handleDeleteContent(row.id)}
                                />
                                <Button
                                    cName="btn cancel-btn"
                                    bValue={BUTTON_CONSTANTS?.CANCEL}
                                    bType="button"
                                    clickHandler={() => this.handleDelete()}
                                />
                            </div>
                        )}
                        {idToUnpublish === row.id && (
                            <div
                                className={`unpublishPopup ${
                                    isUnpublish ? "showPopUp" : "hidePopUp"
                                }`}>
                                <p>{row.active ? "Unpublish" : "Publish"} this content?</p>
                                <Button
                                    cName="btn popup-del-btn"
                                    bValue={BUTTON_CONSTANTS?.YES}
                                    bType="button"
                                    clickHandler={() => {
                                        handleUnpublishClick(row.id, row.active)
                                    }}
                                />
                                <Button
                                    cName="btn cancel-btn"
                                    bValue={BUTTON_CONSTANTS?.CANCEL}
                                    bType="button"
                                    clickHandler={() => this.handleUnpublish()}
                                />
                            </div>
                        )}
                    </td>
                </tr>
            )
        })
        return (
            <div className="movie-list-view">
                {movieList.length ? (
                    <table className="table movie-list-table">
                        <thead>
                            <tr>{tableHeadings}</tr>
                        </thead>
                        <tbody>{tableRows}</tbody>
                    </table>
                ) : (
                    <div>{TEXT_CONSTANTS?.NO_RESULT_FOUND}</div>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        darkMode: state?.theme?.darkMode
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                getRightsManagement
            },
            dispatch
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MovieCardList)
