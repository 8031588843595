import React, { Component } from "react"
import "./style.scss"

class RoiMaximiser extends Component {
    componentDidMount() {
        window.open("https://portal.vionlabs.com")
    }

    render() {
        return <></>
        // return (
        //   <>
        //     <div className="vr-cont-head mb">
        //       <div className="vr-cont-title">
        //         <h2 className="breadcrum-header">
        //           ROI MAXIMISER - Content Acquisition
        //         </h2>
        //       </div>
        //     </div>
        //     <iframe
        //       className={"roi-iframe"}
        //       src="https://legacy.portal.vionlabs.com"
        //       frameborder="0"
        //     ></iframe>
        //   </>
        // );
    }
}

export default RoiMaximiser
