import React from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

import Input from "../../../common/Input"
import Button from "../../../common/Button"
import Checkbox from "../../../common/Checkbox"

import "./style.scss"
import { TEXT_CONSTANTS } from "../../../../utils/constants"

export default function SideMenuFieldsTemplate(props) {
    const [placeholder, setPlaceHolder] = React.useState("true")
    const [selectedMenuTab, setMenuTab] = React.useState("settings")
    const [idToEdit, setIdToEdit] = React.useState(null)
    const [changedValue, setChangeValue] = React.useState()
    const [group, setGroup] = React.useState(props.selectedElement.isStepper || false)

    const handleChange = (e, key) => {
        const { selectedElement, selectedIndex, isChild } = props
        const newArray = [...props.elementsArr]
        if (selectedElement.isGroupElement) {
            newArray.map((i) => {
                if (i.isGroup) {
                    if (
                        i.elementsArr[selectedIndex] &&
                        i.elementsArr[selectedIndex].id === selectedElement.id
                    ) {
                        i.elementsArr[selectedIndex][key] = e.target.value
                    }
                }
                return i
            })
        } else if (isChild !== "" && isChild !== undefined && isChild !== "undefined") {
            if (!("children" in newArray[selectedIndex])) {
                newArray[selectedIndex].children = [{}]
            }
            newArray[selectedIndex].children[isChild][key] = e.target.value
        } else {
            newArray[selectedIndex][key] = e.target.value
        }
        props.setElementArray(newArray)
    }
    const handleRadio = (e) => {
        setPlaceHolder(e.target.value)
        const { selectedElement, selectedIndex, isChild } = props
        if (e.target.value === "false") {
            const newArray = [...props.elementsArr]
            if (selectedElement.isGroupElement) {
                newArray.map((i) => {
                    if (i.isGroup) {
                        if (
                            i.elementsArr[selectedIndex] &&
                            i.elementsArr[selectedIndex].id === selectedElement.id
                        ) {
                            i.elementsArr[selectedIndex].htmlPlaceholder = "None"
                        }
                    }
                    return i
                })
            } else if (e.target.value === "default") {
                const newArray = [...props.elementsArr]
                props.setElementArray(newArray)
                if (isChild !== "" && isChild !== undefined && isChild !== "undefined") {
                    newArray[selectedIndex].children[isChild].htmlPlaceholder =
                        newArray[props.selectedIndex].options[0].name
                } else {
                    newArray[props.selectedIndex].htmlPlaceholder =
                        newArray[props.selectedIndex].options[0].name
                }
            } else {
                if (isChild !== "" && isChild !== undefined && isChild !== "undefined") {
                    newArray[selectedIndex].children[isChild].htmlPlaceholder = "None"
                } else {
                    newArray[selectedIndex].htmlPlaceholder = "None"
                }
            }

            props.setElementArray(newArray)
        } else if (e.target.value === "default") {
            const newArray = [...props.elementsArr]
            newArray[props.selectedIndex].htmlPlaceholder =
                newArray[props.selectedIndex] &&
                newArray[props.selectedIndex].options &&
                newArray[props.selectedIndex].options[0] &&
                newArray[props.selectedIndex].options[0].name
            props.setElementArray(newArray)
        }
        setPlaceHolder(e.target.value)
    }
    const selectSize = (size) => {
        const { selectedElement, selectedIndex, isChild } = props
        // setSelectedSize(size);
        const newArray = [...props.elementsArr]
        if (selectedElement.isGroupElement) {
            newArray.map((i) => {
                if (i.isGroup) {
                    if (
                        i.elementsArr[selectedIndex] &&
                        i.elementsArr[selectedIndex].id === selectedElement.id
                    ) {
                        i.elementsArr[selectedIndex].size = size
                    }
                }
                return i
            })
        } else if (isChild !== "" && isChild !== undefined && isChild !== "undefined") {
            newArray[selectedIndex].children[isChild].size = size
        } else {
            newArray[selectedIndex].size = size
        }
        props.setElementArray(newArray)
    }
    const CheckMandatory = (e, checked) => {
        const { selectedElement, selectedIndex, isChild } = props
        const newArray = [...props.elementsArr]
        if (selectedElement.isGroupElement) {
            newArray.map((i) => {
                if (i.isGroup) {
                    if (
                        i.elementsArr[selectedIndex] &&
                        i.elementsArr[selectedIndex].id === selectedElement.id
                    ) {
                        i.elementsArr[selectedIndex].required = checked
                    }
                }
                return i
            })
        } else if (isChild !== "" && isChild !== undefined && isChild !== "undefined") {
            newArray[selectedIndex].children[isChild].required = checked
        } else {
            newArray[selectedIndex].required = checked
        }
        props.setElementArray(newArray)
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    }
    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return
        }

        const items = reorder(
            props.selectedElement.options,
            result.source.index,
            result.destination.index
        )
        const newArray = [...props.elementsArr]
        newArray[props.selectedIndex].options = items
        props.setElementArray(newArray)
    }
    // const getId = () => {
    //   return Math.random().toString(36).substring(2) + Date.now().toString(36);
    // };
    const handleAddOption = () => {
        const { isChild, selectedIndex } = props
        const newArray = [...props.elementsArr]
        if (
            isChild !== "" &&
            isChild !== undefined &&
            isChild !== "undefined" &&
            newArray[selectedIndex].children
        ) {
            let obj = {
                id: newArray[selectedIndex].children[isChild].options
                    ? newArray[selectedIndex].children[isChild].options.length + 1
                    : null,
                name: `New Option ${
                    newArray[selectedIndex].children[isChild].options &&
                    newArray[selectedIndex].children[isChild].options.length
                        ? newArray[selectedIndex].children[isChild].options.length + 1
                        : 1
                }`
            }
            newArray[selectedIndex].children[isChild].options = [
                newArray[selectedIndex].children[isChild].options,
                obj
            ]
        } else {
            let obj = {
                id: newArray[selectedIndex].options
                    ? newArray[selectedIndex].options.length + 1
                    : 1,
                name: `New Option ${
                    newArray[selectedIndex].options && newArray[selectedIndex].options.length
                        ? newArray[props.selectedIndex].options.length + 1
                        : 1
                }`
            }
            if (newArray[selectedIndex]) {
                if (!("options" in newArray[selectedIndex])) {
                    newArray[selectedIndex].options = [{}]
                }
                newArray[selectedIndex].options = [...newArray[selectedIndex].options, obj]
            }
        }
        props.setElementArray(newArray)
    }

    const handleEditChange = (e, id) => {
        setChangeValue(e.target.value)
        //handleSave(id)
    }

    const handleEditClick = (item) => {
        setIdToEdit(item.id)
        const newArray = [...props.elementsArr]

        newArray[props.selectedIndex] &&
            newArray[props.selectedIndex].options &&
            newArray[props.selectedIndex].options.forEach((ele) => {
                if (ele.id === item.id) {
                    setChangeValue(item.name)
                }
            })
    }

    const handleSave = (id) => {
        const newArray = [...props.elementsArr]

        newArray[props.selectedIndex].options &&
            newArray[props.selectedIndex].options.map((item) => {
                if (item.id === id) {
                    item.name = changedValue
                }
                return false
            })
        props.setElementArray(newArray)
        //setIdToEdit(null)
    }
    const deleteOption = (id) => {
        const newArray = [...props.elementsArr]

        newArray[props.selectedIndex].options = newArray[props.selectedIndex].options.filter(
            (item) => {
                if (item.id !== id) {
                    return item
                }
                return false
            }
        )
        props.setElementArray(newArray)
    }

    const getPlaceholder = (item) => {
        switch (item.htmlElement) {
            case "input":
                return (
                    <React.Fragment>
                        <div className={"editor-list-section"}>
                            <span>{TEXT_CONSTANTS?.TEXT_TO_LOAD}</span>
                            <div className="radio-btns">
                                <div className="sideMenu-checkbox">
                                    <label className="container-checkBox">
                                        {TEXT_CONSTANTS?.NONE}
                                        <input
                                            type="radio"
                                            name="radio"
                                            value={false}
                                            onChange={handleRadio}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                <div className="sideMenu-checkbox">
                                    <label className="container-checkBox">
                                        {TEXT_CONSTANTS?.PLACEHOLDER}
                                        <input
                                            type="radio"
                                            name="radio"
                                            value={true}
                                            onChange={handleRadio}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        {placeholder === "true" ? (
                            <div className={"editor-list-section nn"}>
                                <Input
                                    type="text"
                                    labelText={"Placeholder Text"}
                                    placeholder={"Add answer here"}
                                    value={props.selectedElement.htmlPlaceholder}
                                    onChange={(e) => handleChange(e, "htmlPlaceholder")}
                                />
                            </div>
                        ) : null}
                    </React.Fragment>
                )

            case "select":
                return (
                    <React.Fragment>
                        <div className={"editor-list-section"}>
                            <span>{TEXT_CONSTANTS?.TEXT_TO_LOAD}</span>
                            <div className="radio-btns">
                                <div className="sideMenu-checkbox">
                                    <label className="container-checkBox">
                                        {TEXT_CONSTANTS?.NONE}
                                        <input
                                            type="radio"
                                            name="radio"
                                            value={false}
                                            onChange={handleRadio}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                <div className="sideMenu-checkbox">
                                    <label className="container-checkBox">
                                        {TEXT_CONSTANTS?.PLACEHOLDER}
                                        <input
                                            type="radio"
                                            name="radio"
                                            value={true}
                                            onChange={handleRadio}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                <div className="sideMenu-checkbox">
                                    <label className="container-checkBox">
                                        {TEXT_CONSTANTS?.ITEM_FROM_DROPDOWN}
                                        <input
                                            type="radio"
                                            name="radio"
                                            value={"default"}
                                            onChange={handleRadio}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        {placeholder === "true" ? (
                            <div className={"editor-list-section"}>
                                <Input
                                    type="text"
                                    labelText={"Placeholder Text"}
                                    placeholder={"Add answer here"}
                                    value={props.selectedElement.htmlPlaceholder}
                                    onChange={(e) => handleChange(e, "htmlPlaceholder")}
                                />
                            </div>
                        ) : null}
                    </React.Fragment>
                )
            default:
                return null
        }
    }
    const handleGroup = (e) => {
        const newArray = [...props.elementsArr]
        newArray[props.selectedIndex].isStepper = e.target.value === "true" ? true : false
        setGroup(e.target.value === "true" ? true : false)
        props.setElementArray(newArray)
    }
    return (
        <React.Fragment>
            <div className="formTitle">
                <i className="icon-arrow-left1" onClick={props.handleBack}></i>
                <div className="formTitle-right">
                    <span>{TEXT_CONSTANTS?.SETTINGS}</span>
                    <p>{props.selectedElement.name}</p>
                </div>
                <i
                    className="icon-del"
                    onClick={() => props.deleteElement(props.selectedElement, props.selectedIndex)}
                ></i>
            </div>
            {props.selectedElement && props.selectedElement.htmlElement === "group" ? (
                <div className="editor-list sideMenu-select">
                    <div className="radio-btns">
                        <div className="sideMenu-checkbox">
                            <label className="container-checkBox">
                                Group
                                <input
                                    type="radio"
                                    name="radio"
                                    value={false}
                                    checked={group === false ? true : false}
                                    onChange={handleGroup}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        <div className="sideMenu-checkbox">
                            <label className="container-checkBox">
                                Stepper
                                <input
                                    type="radio"
                                    name="radio"
                                    value={true}
                                    checked={group === true ? true : false}
                                    onChange={handleGroup}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="editor-list sideMenu-select" style={{ marginTop: 21 }}>
                    <div className="menu-tab-container">
                        <div
                            className={`menu-tab-header ${
                                selectedMenuTab === "settings" ? "selectedMenu" : ""
                            }`}
                            onClick={() => setMenuTab("settings")}
                        >
                            <span>{TEXT_CONSTANTS?.SETTINGS}</span>
                        </div>
                        <div
                            className={`menu-tab-header ${
                                selectedMenuTab === "value" ? "selectedMenu" : ""
                            }`}
                            onClick={() => setMenuTab("value")}
                        >
                            <span>{TEXT_CONSTANTS?.VLAUE}</span>
                        </div>
                    </div>
                    {selectedMenuTab === "settings" ? (
                        <div className="settings-tab-cont">
                            <div className={"editor-list-section"}>
                                <Input
                                    type="text"
                                    cname={"sideMenu-input"}
                                    labelText={"Add answer here"}
                                    value={props.selectedElement.title}
                                    onChange={(e) => handleChange(e, "title")}
                                />
                            </div>
                            <hr />

                            {getPlaceholder(props.selectedElement)}
                            <div className={"editor-list-section"}>
                                <span>{TEXT_CONSTANTS?.SIZE}</span>
                                <div className="size-btns">
                                    <Button
                                        cName={`btn popup-del-btn ${
                                            props.selectedElement.size === "L" ? "active" : null
                                        }`}
                                        bValue="L"
                                        bType="button"
                                        clickHandler={() => selectSize("L")}
                                        disabled={false}
                                    />
                                    <Button
                                        cName={`btn popup-del-btn ${
                                            props.selectedElement.size === "M" ? "active" : null
                                        }`}
                                        bValue="M"
                                        bType="button"
                                        clickHandler={() => selectSize("M")}
                                    />
                                    <Button
                                        cName={`btn popup-del-btn ${
                                            props.selectedElement.size === "S" ? "active" : null
                                        }`}
                                        bValue="S"
                                        bType="button"
                                        clickHandler={() => selectSize("S")}
                                    />
                                </div>
                            </div>
                            <div className={"editor-list-section"}>
                                <span>{TEXT_CONSTANTS?.GENERAL_SETTINGS}</span>
                                <Checkbox
                                    cName="check-box"
                                    id={"checkBox"}
                                    labelText={"Mandatory"}
                                    changeHandler={CheckMandatory}
                                    isChecked={props.selectedElement.required}
                                />
                            </div>
                            <hr />
                            <div className={"editor-list-section"}>
                                <Input
                                    type="text"
                                    labelText={"Regular Expresion"}
                                    placeholder={"Add answer here"}
                                    value={props.selectedElement.regularExpresion}
                                    onChange={(e) => handleChange(e, "regularExpresion")}
                                />
                            </div>
                            <hr />
                        </div>
                    ) : (
                        <div className="value-tab-cont">
                            <div className="option-list-cont">
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {props.selectedElement &&
                                                props.selectedElement.options
                                                    ? props.selectedElement.options.map(
                                                          (item, index) => (
                                                              <Draggable
                                                                  key={item.name}
                                                                  draggableId={item.name}
                                                                  index={index}
                                                              >
                                                                  {(provided, snapshot) => (
                                                                      <div
                                                                          ref={provided.innerRef}
                                                                          {...provided.draggableProps}
                                                                          {...provided.dragHandleProps}
                                                                          className="option-cont"
                                                                      >
                                                                          <div className="drag">
                                                                              <span className="menu-icon"></span>
                                                                              <span className="menu-icon"></span>
                                                                              <span className="menu-icon"></span>
                                                                          </div>
                                                                          <span className="option-name">
                                                                              {idToEdit ===
                                                                              item.id ? (
                                                                                  <Input
                                                                                      type="text"
                                                                                      key={item.id}
                                                                                      cname={
                                                                                          "sideMenu-input"
                                                                                      }
                                                                                      value={
                                                                                          idToEdit ===
                                                                                          item.id
                                                                                              ? changedValue
                                                                                              : item.name
                                                                                      }
                                                                                      onChange={(
                                                                                          e
                                                                                      ) =>
                                                                                          handleEditChange(
                                                                                              e,
                                                                                              item.id
                                                                                          )
                                                                                      }
                                                                                      onBlur={(e) =>
                                                                                          handleSave(
                                                                                              item.id
                                                                                          )
                                                                                      }
                                                                                  />
                                                                              ) : (
                                                                                  item.name
                                                                              )}
                                                                          </span>
                                                                          <span
                                                                              className={`${
                                                                                  idToEdit ===
                                                                                  item.id
                                                                                      ? "icon-menu"
                                                                                      : "icon-checkbox"
                                                                              }`}
                                                                              onClick={() => {
                                                                                  idToEdit ===
                                                                                  item.id
                                                                                      ? handleSave(
                                                                                            item.id
                                                                                        )
                                                                                      : handleEditClick(
                                                                                            item,
                                                                                            item.id
                                                                                        )
                                                                              }}
                                                                          ></span>
                                                                          <span
                                                                              className="icon-del"
                                                                              onClick={() =>
                                                                                  deleteOption(
                                                                                      item.id
                                                                                  )
                                                                              }
                                                                          ></span>
                                                                      </div>
                                                                  )}
                                                              </Draggable>
                                                          )
                                                      )
                                                    : null}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                            <Button
                                cName="btn primary-btn addNewBtn"
                                bType="button"
                                bValue="Add New Option"
                                clickHandler={handleAddOption}
                            />
                        </div>
                    )}
                </div>
            )}
        </React.Fragment>
    )
}
