import React, { useState } from "react"
import { BUTTON_CONSTANTS } from "../../utils/constants"
import Button from "../common/Button"

import ForceUpgradeTable from "./ForceUpgradeTable"
import { checkIfUserHasAccess } from "../../utils/users"
import ForceUpgradeForm from "./ForceUpgradeForm"
import Select1 from "../common/SelectBox"

import "./style.scss"

export default function ForceUpgrade(props) {
    const [isAddingNewData, setIsAddingNewData] = useState(false)
    const [filters, setFilters] = useState({})
    const [cloneData, setCloneData] = useState({})
    const [viewMode, setViewMode] = useState(false)

    // const canUpdateStatus = checkIfUserHasAccess([PERMISSIONS.BULK_UPLOAD_EDIT]);
    let canUpdateStatus = true
    const addNewBackHandler = () => {
        setIsAddingNewData(!isAddingNewData)
        setCloneData({})
        setViewMode(false)
    }

    const setFilterData = (name, value, selectedOptions) => {
        filters[name] = Array.isArray(selectedOptions)
            ? selectedOptions.map((value) => {
                  return value.name
              })
            : selectedOptions?.name
        filters.isUpdated = !filters.isUpdated
        setFilters(filters)
    }

    return (
        <div className="force-update-container">
            {renderPageTitle({
                isAddingNewData,
                addNewBackHandler,
                canUpdateStatus,
                setFilterData
            })}
            <>
                {isAddingNewData ? (
                    <div>
                        <ForceUpgradeForm
                            addNewBackHandler={addNewBackHandler}
                            cloneData={cloneData}
                            viewMode={viewMode}
                        ></ForceUpgradeForm>
                    </div>
                ) : (
                    <ForceUpgradeTable
                        addNewBackHandler={addNewBackHandler}
                        canUpdateStatus={canUpdateStatus}
                        filters={filters}
                        setCloneData={setCloneData}
                        setIsAddingNewData={setIsAddingNewData}
                        setViewMode={setViewMode}
                    ></ForceUpgradeTable>
                )}
            </>
        </div>
    )
}

const renderPageTitle = ({
    isAddingNewData,
    addNewBackHandler,
    canUpdateStatus,
    setFilterData
}) => {
    return (
        <div className="vr-cont-head heading">
            {canUpdateStatus && (
                <div className="vr-cont-action header-filter-btn">
                    <Button
                        cName={isAddingNewData ? "btn border-btn" : "btn primary-btn"}
                        bValue={
                            isAddingNewData ? BUTTON_CONSTANTS?.BACK : BUTTON_CONSTANTS?.ADD_NEW
                        }
                        bType="button"
                        leftIcon={isAddingNewData && "icon-arrow-left1"}
                        clickHandler={addNewBackHandler}
                    />
                </div>
            )}
        </div>
    )
}
