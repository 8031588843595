import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import {
    fetchFormElement,
    fetchEntityValueMasterData,
    fetchContent,
    createContent
} from "../api/action"
import "./style.scss"
import ADDWEBSERIES from "../../WebSeries/addWebSeries/AddWebSeries"
import { DEFAULT_SELECTED_LANGUAGE_CODE } from "../api/constants"

class AddWebSeries extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentScreen: "addBrand",
            formData: [],
            formElementData: {},
            isLoading: true,
            steppersData: [],
            currentStep: 0,
            updatedData: this.props.selectedData
        }
    }

    async componentDidMount() {
        const { contentType, formBuilderData } = this.props
        const filter =
            formBuilderData &&
            formBuilderData.length &&
            formBuilderData.filter(
                (item) => item.name.toLowerCase().split(" ").join("") === contentType.toLowerCase()
            )
        this.setState({ formData: filter && filter.length && filter[0] }, () => {
            const { formData } = this.state
            const arr = []
            formData &&
                formData.htmlElements &&
                formData.htmlElements.content &&
                formData.htmlElements.content[DEFAULT_SELECTED_LANGUAGE_CODE].forEach((item) => {
                    if (item.htmlElement === "group" && item.isStepper) {
                        arr.push(item)
                    }
                })
            this.setState({ steppersData: arr }, () => {
                this.setState({ isLoading: false })
            })
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedData !== prevProps.selectedData) {
            this.setState({
                updatedData: this.props.selectedData
            })
        }
    }

    assignUpdateValues = (obj, atrrName, data) => {
        if (Array.isArray(obj)) {
            obj.forEach((item, index) => {
                this.assignUpdateValues(item, atrrName, data)
            })
        } else if (typeof obj === "object" && obj !== null) {
            for (let key in obj) {
                if (key === atrrName) {
                    obj[key] = data
                } else {
                    this.assignUpdateValues(obj[key], atrrName, data)
                }
            }
        }
    }

    loader = () => {
        return (
            <div className="addMovie-form-loader">
                <div className="content-header"></div>
                <div className="content">
                    <div className="posterImgUpload small"></div>
                    <div className="coverImgUpload large"></div>
                    <div className="col-medium"></div>
                    <div className="col-medium"></div>
                    <div className="col-small"></div>
                </div>
                <div className="content-header"></div>
                <div className="content">
                    <div className="col-medium"></div>
                    <div className="col-medium"></div>
                    <div className="col-small"></div>
                </div>
            </div>
        )
    }
    setCurrentStep = (value) => {
        this.setState({ currentStep: value })
    }

    render() {
        const { isLoading, formData, steppersData, currentStep } = this.state
        const { genres } = this.props

        return (
            <React.Fragment>
                {isLoading ? (
                    this.loader()
                ) : (
                    <ADDWEBSERIES
                        isLoading={isLoading}
                        handleSearch={this.handleSearch}
                        handleViewClick={() => this.props.handleViewClick()}
                        handleAddClick={() => this.props.handleAddClick()}
                        formData={formData}
                        isWebSeries={true}
                        certifications={this.props.certifications}
                        steppersData={steppersData}
                        currentStep={currentStep}
                        setCurrentStep={this.setCurrentStep}
                        isEdit={this.props.isEdit}
                        genres={genres}
                        editId={this.props.editId}
                        {...this.props}
                        selectedData={this.state.updatedData}
                    />
                )}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        darkMode: state.theme.darkMode
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                fetchFormElement,
                fetchEntityValueMasterData,
                fetchContent,
                createContent
            },
            dispatch
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddWebSeries)
