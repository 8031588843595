import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import get from "lodash/get"
import { toast } from "react-toastify"
import { Link } from "react-router-dom"

import Search from "../../common/Search/index"
import Pagination from "../../common/Pagination/index"
import Tooltip from "../../common/Tooltip/index"
import Button from "../../common/Button"
import AddRoles from "./addRoles"
import { ROLES_TABLE_HEADING } from "../APIs/constant"
import { fetchRolesList, softDeleteRole } from "../APIs/action"
import { scrollPage, debounce, isSuperAdminUser } from "../../../utils/common"

import "./style.scss"
import "../../common/Table/style.scss"
import "../style.scss"
import { BUTTON_CONSTANTS, PERMISSIONS, TEXT_CONSTANTS } from "../../../utils/constants"
import { checkIfUserHasAccess } from "../../../utils/users"
import { AuthWrapper } from "../../common/AuthWrapper"

class Roles extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            roles: [],
            isAddRole: false,
            isEdit: true,
            isDelete: false,
            idToDelete: -1,
            editableData: [],
            apiOptions: {
                start: 0,
                length: 10,
                searchTag: ""
            },
            pageCount: 0,
            currentPage: 0
        }
        this.canUpdateStatus = checkIfUserHasAccess([
            PERMISSIONS.SETTING_USER_MANAGEMENT_ROLES_EDIT
        ])
    }
    options = [
        { id: 10, name: "10" },
        { id: 20, name: "20" },
        { id: 30, name: "30" },
        { id: 40, name: "40" },
        { id: 50, name: "50" }
    ]

    componentDidMount() {
        scrollPage()
        this.loadHandler()
    }

    loadHandler = async () => {
        const response = await this.props.fetchRolesList(this.state.apiOptions)
        if (response && response.status) {
            this.setState({
                roles: response.data,
                isLoading: false
            })
        }
        this.pageCountSetup()
    }
    pageCountSetup = () => {
        const { rolesCount } = this.props
        const { length } = this.state.apiOptions
        let pageCount = Math.ceil(rolesCount / length)
        this.setState({
            pageCount
        })
    }

    onPageChange = async (e) => {
        const { apiOptions } = this.state
        const selectedPage = e.selected
        apiOptions.start = selectedPage * apiOptions.length
        this.setState(
            {
                currentPage: selectedPage,
                apiOptions
            },
            () => scrollPage()
        )
        this.loadHandler()
    }

    showPerPageChange = async (name, value) => {
        let { apiOptions } = this.state
        apiOptions.length = value
        this.setState({
            apiOptions
        })
        this.loadHandler()
    }
    handleSearch = async (name, value) => {
        const { apiOptions } = this.state
        apiOptions["searchTag"] = value
        this.setState(
            {
                apiOptions
            },
            () => {
                this.onPageChange({ selected: 0 })
            }
        )
        // this.loadHandler();
    }

    setSearchQuery = debounce((name, value) => {
        this.handleSearch(name, value)
    }, 300)

    handleIsAddRole = () => {
        const { apiOptions } = this.state
        this.setState(
            {
                isAddRole: !this.state.isAddRole,
                isEdit: false,
                apiOptions: { ...apiOptions, searchTag: "" }
            },
            () => scrollPage()
        )
        this.loadHandler()
    }

    handleDeleteRole = async (id) => {
        const { softDeleteRole } = this.props
        const response = await softDeleteRole(id)
        if (get(response, "status")) {
            toast.success(get(response, "message"))
        } else {
            toast.error(get(response, "message"))
        }
        this.setState({
            isDelete: !this.state.isDelete
        })
        this.loadHandler()
    }

    handleEditRole = (item) => {
        this.setState({
            isEdit: true,
            editableData: item,
            isAddRole: !this.state.isAddRole
        })
    }

    handleDeleteClick = (id) => {
        const { isDelete, idToDelete } = this.state
        if (!isDelete) {
            this.setState({
                idToDelete: id,
                isDelete: !this.state.isDelete
            })
        } else if (id === idToDelete) {
            this.setState({
                idToDelete: -1,
                isDelete: !this.state.isDelete
            })
        }
    }

    tableloader = () => {
        let listLoader = [1, 2, 3, 4, 5, 6]
        return (
            <div className="rowContainer-loader">
                {listLoader.map(() => {
                    return (
                        <div className="row_loader">
                            <div className="block_loader" />
                            <div className="block_loader" />
                            <div className="block_loader" />
                            <div className="action_loader">
                                <span className="circle_loader" />
                                <span className="circle_loader" />
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    render() {
        const {
            isAddRole,
            roles,
            isDelete,
            idToDelete,
            editableData,
            isEdit,
            pageCount,
            isLoading
        } = this.state
        const { rolesCount, currentUserRole } = this.props
        const tableHeading = ROLES_TABLE_HEADING.map((item, index) => {
            return (
                <AuthWrapper permissions={item?.permissions}>
                    <th key={index} className="custom_thead">
                        {item?.name}
                    </th>
                </AuthWrapper>
            )
        })
        const tableRows = roles.map((row, index) => {
            const usersViewEditPermission = checkIfUserHasAccess([
                PERMISSIONS.SETTING_USER_MANAGEMENT_USERS_EDIT,
                PERMISSIONS?.SETTING_USER_MANAGEMENT_USERS_VIEW
            ])
            return (
                <tr
                    id={row.id}
                    key={index}
                    className={`custom_tr ${this.canUpdateStatus ? "" : "table-row-style"}`}
                >
                    <td>{index + 1}</td>
                    <td>{row.name}</td>
                    <td>
                        <Tooltip tooltiptext={row.permissions.length} innerText={row.permissions} />
                    </td>
                    <td>
                        <Link
                            to={{ pathname: "users", value: row }}
                            className={`link-tag ${usersViewEditPermission ? "" : "disable-link"}`}
                        >
                            {row.taggedUsers}
                        </Link>
                    </td>

                    {this.canUpdateStatus && (
                        <td className="btn-popupContainer">
                            <i className="icon-edit" onClick={(item) => this.handleEditRole(row)} />
                            <i
                                className={`icon-del ${
                                    row?.name.replaceAll(" ", "")?.toLowerCase() ===
                                        PERMISSIONS?.SUPER_ADMIN ||
                                    (row?.name.replaceAll(" ", "")?.toLowerCase() ===
                                        PERMISSIONS?.ADMIN &&
                                        !isSuperAdminUser(currentUserRole))
                                        ? "disabled"
                                        : ""
                                }`}
                                onClick={() =>
                                    row?.name.replaceAll(" ", "")?.toLowerCase() ===
                                        PERMISSIONS?.SUPER_ADMIN ||
                                    (row?.name.replaceAll(" ", "")?.toLowerCase() ===
                                        PERMISSIONS?.ADMIN &&
                                        !isSuperAdminUser(currentUserRole))
                                        ? ""
                                        : this.handleDeleteClick(row.id)
                                }
                            />
                            {idToDelete === row.id && (
                                <div
                                    className={`deletePopup ${
                                        isDelete ? "showPopUp slide-right" : "hidePopUp slide-left"
                                    }`}
                                >
                                    <p>Delete this Role?</p>
                                    <Button
                                        cName="btn popup-del-btn"
                                        bValue={BUTTON_CONSTANTS?.YES_DELETE}
                                        bType="button"
                                        clickHandler={() => this.handleDeleteRole(row.id)}
                                    />
                                    <Button
                                        cName="btn cancel-btn"
                                        bValue={BUTTON_CONSTANTS?.CANCEL}
                                        bType="button"
                                        clickHandler={() => this.handleDeleteClick(row.id)}
                                    />
                                </div>
                            )}
                        </td>
                    )}
                </tr>
            )
        })
        return (
            <div className="vd-user-management">
                <div className="vr-cont-head mb">
                    {/* TODO: Replace below with the reusable pageTitleComponent */}
                    <div className="vr-cont-title">
                        <span className="vr-breadcrum">
                            {TEXT_CONSTANTS?.USER_MANAGEMENT} <i className="icon-arrow-right1" />
                            <span>{TEXT_CONSTANTS?.ROLES?.toLowerCase()}</span>
                        </span>
                        <h2 className="breadcrum-header">{TEXT_CONSTANTS?.ROLES?.toUpperCase()}</h2>
                    </div>
                    <div className="vr-cont-action">
                        {this.canUpdateStatus && (
                            <Button
                                cName={`btn ${!isAddRole ? "primary-btn" : "border-btn"}`}
                                bValue={`${
                                    !isAddRole ? BUTTON_CONSTANTS?.ADD_NEW : BUTTON_CONSTANTS?.BACK
                                }`}
                                clickHandler={this.handleIsAddRole}
                                leftIcon={isAddRole && "icon-arrow-left1"}
                            />
                        )}
                    </div>
                </div>
                {!isAddRole ? (
                    <div className="vr-content-holder">
                        <div className="vr-cont-head">
                            <div className="vr-cont-title">
                                <h2 className="inner-count-title">
                                    {rolesCount < 10 ? `0${rolesCount}` : rolesCount}{" "}
                                    {TEXT_CONSTANTS?.ROLES?.toUpperCase()}
                                </h2>
                            </div>
                            <div className="vr-cont-action">
                                <Search
                                    name="search"
                                    changeHandler={this.setSearchQuery}
                                    placeholder="Search Here"
                                />
                            </div>
                        </div>
                        {isLoading ? (
                            this.tableloader()
                        ) : rolesCount > 0 ? (
                            <table className="table user-role-table">
                                <thead>
                                    <tr>{tableHeading}</tr>
                                </thead>
                                <tbody>{tableRows}</tbody>
                            </table>
                        ) : (
                            <div style={{ paddingLeft: "20px" }}>
                                {TEXT_CONSTANTS?.NO_RESULT_FOUND}
                            </div>
                        )}
                    </div>
                ) : (
                    <AddRoles
                        handleBack={this.handleIsAddRole}
                        editableData={editableData}
                        isEdit={isEdit}
                    />
                )}
                {!isAddRole && (
                    <Pagination
                        changeHandler={this.showPerPageChange}
                        options={this.options}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.onPageChange}
                    />
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        roles: get(state.userManagement, "roles", []),
        rolesCount: get(state.userManagement, "roles.count", []),
        currentUserRole: state.auth?.user?.roles
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                fetchRolesList,
                softDeleteRole
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Roles)
