import React from "react"
import { useDropzone } from "react-dropzone"
import uploadFile from "../../../assets/images/uploadFile.svg"
import { TEXT_CONSTANTS } from "../../../utils/constants"
import deleteUploadedFile from "../../../assets/images/deleteUploadedFile.svg"

import "./style.scss"

const DropzoneFileComponent = ({
    selectedFile,
    acceptedFilesType,
    onDropHandler,
    onFileRemoveHandler
}) => {
    const onDrop = (acceptedFiles) => {
        // Do something with the files
        onDropHandler(acceptedFiles)
    }

    const removeFile = (event) => {
        onFileRemoveHandler(event)
    }

    const onDropError = (error) => {
        console.log("error")
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: acceptedFilesType ? acceptedFilesType : "",
        onError: onDropError,
        multiple: false
    })

    return (
        <div className="uploading-csv-container">
            <div {...getRootProps()} className="dropzone-area">
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p className="drop-text">{TEXT_CONSTANTS?.DROP_FILES_HERE}</p>
                ) : selectedFile ? (
                    <div className="selected-file-text">
                        <p className="selected-file-name">{selectedFile.name}</p>
                        <div className="remove-btn" onClick={removeFile}>
                            <img src={deleteUploadedFile} alt="deleted file icon"></img>
                        </div>
                    </div>
                ) : (
                    <div className="empty-file-text">
                        <div className="remove-btn">
                            <img src={uploadFile} alt="upload file icon"></img>
                        </div>
                        <p>
                            {`${TEXT_CONSTANTS?.DRAG_DROP_FILES} `}
                            <span className="browse-text">{TEXT_CONSTANTS?.BROWSE}</span>
                        </p>
                    </div>
                )}
            </div>
        </div>
    )
}

export default DropzoneFileComponent
