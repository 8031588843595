import { ACTION } from "./constants"
import UserServiceInstance from "./service"

export const uploadImage = (params, type) => {
    return (dispatch) => {
        return UserServiceInstance.uploadImage({ params, type })
            .then((response) => {
                dispatch({
                    type: ACTION.UPLOAD_IMAGE,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in upload image ", error)
            })
    }
}

export const deleteImage = (id) => {
    return (dispatch) => {
        return UserServiceInstance.deleteImage(id)
            .then((response) => {
                dispatch({
                    type: ACTION.DELETE_IMAGE,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in delete image ", error)
            })
    }
}

export const saveUserPreference = (params) => {
    return (dispatch) => {
        return UserServiceInstance.saveUserPreference(params)
            .then((response) => {
                dispatch({
                    type: ACTION.SAVE_USER_PREFERENCE,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in updating user profile ", error)
            })
    }
}
