import React, { Component } from "react"
import { Switch, withRouter } from "react-router-dom"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { ToastContainer, Flip } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import SideNavAdmin from "../../components/common/AdminSideNav"
import SideNav from "../../components/common/SideNav"
import createRoute from "../../utils/createRoutes"
import { adminRoutes } from "../../utils/routeConstants"
import { mainRoutes } from "../../utils/routeConstants"
import Header from "../../components/common/Header"
import { updateTheme } from "../../components/common/ThemeMode/APIs/action"
import LoginPanel from "../../components/LoginPanel"
import { fetchContentList } from "../../components/formBuilder/api/action"
import { updateHeaderLogo, showNavBar, findTenantByDomain } from "../AdminHome/APIs/action"
import { logoutUser } from "../../components/LoginPanel/APIs/action"
import { isEmpty } from "lodash"
import Loader from "../../components/common/Loader"
import { getSavedTimezone, saveTimezone } from "../../components/Timezone/API/action"
import { getCurrentUserTimezone } from "../../utils/common"
import { encryptData } from "../../utils/EncryptDecrypt"

class AdminHome extends Component {
    constructor(props) {
        super(props)
        let { auth } = props

        this.state = {
            isAuth: auth != null && !isEmpty(auth),
            isNavbarOpen: true,
            showNavBar: true,
            contentList: [],
            isSuperAdmin: null,
            tenantData: {}
        }
    }

    toggleNavBar = () => {
        this.setState({
            showNavBar: !this.state.showNavBar
        })
    }

    async componentDidMount() {
        this.loadHandler()
    }

    componentWillReceiveProps(nextProps) {
        /* todo remove this */
        /*    const { history } = this.props;
    if (
      history &&
      history.location &&
      !history?.location?.pathname.includes("tenant") &&
      history?.location?.pathname !== "/"
    ) {
      this.setState({
        showNavBar: true,
      });
    } else {
      this.setState({
        showNavBar: false,
      });
    } */
    }

    storeTimezoneInformation = async () => {
        const { getSavedTimezone, saveTimezone } = this.props
        if (this.state?.isAuth && localStorage.getItem("accessToken")) {
            const timezoneResponse = await getSavedTimezone()
            if (timezoneResponse?.status && !timezoneResponse?.data) {
                // If there is no Timezone data saved -> save the user current timezone
                const userCurrentTimezone = getCurrentUserTimezone()
                await saveTimezone(userCurrentTimezone)
            }
        }
    }

    loadHandler = async () => {
        const { updateTheme, findTenantByDomain } = this.props
        await this.storeTimezoneInformation()
        let response = await findTenantByDomain()
        if (response && response.data) {
            sessionStorage.setItem(
                "tenantInfo",
                encryptData(response, process.env.REACT_APP_LOCAL_STORAGE_SECRETE_KEY)
            )
            this.setState(
                {
                    isSuperAdmin: response?.data?.tenantIdentifier === "master",
                    tenantData: response?.data,
                    isLoginPanel: true
                },
                () => {
                    //updateHeaderLogo(dmLogo);
                    // isSuperAdmin && this.toggleNavBar();
                }
            )
        } else {
            this.setState({
                isSuperAdmin: false,
                isLoginPanel: true
            })
        }
        const tenant_deleted = response?.data?.tenant_deleted
        const isTenantExpired = response?.status
            ? response?.data?.auxData?.isTenantExpired
            : response?.data?.isTenantExpired
        if (tenant_deleted || isTenantExpired) {
            const { logoutUser } = this.props
            await logoutUser(false)
        } else if (response && response.data) {
            const contentListData = await this.props.fetchContentList()
            this.setState({ contentList: contentListData && contentListData.data })
        }

        // } else if (tenantInfo && tenantInfo.isSuperAdmin) {
        //   this.setState({
        //     isSuperAdmin: true,
        //   });
        // }

        // const timezoneResponse = await getSavedTimezone();
        // if (timezoneResponse?.status && !timezoneResponse?.data) {
        //   // If there is no Timezone data saved -> save the user current timezone
        //   const userCurrentTimezone = getCurrentUserTimezone();
        //   await saveTimezone(userCurrentTimezone);
        // }
        updateTheme()
    }

    handleAfterLogin = async () => {
        let { auth } = this.props
        this.setState(
            {
                isAuth: auth && auth.user && auth.user.active
            },
            async () => {
                await this.storeTimezoneInformation()
            }
        )
        this.props.history.push("/dashboard")
    }

    handleMenuOpen = () => {
        this.setState({
            isNavbarOpen: !this.state.isNavbarOpen,
            showNavBar: !this.state.showNavBar
        })
    }

    renderNavMenu = () => {
        const isDarkMode = this.props.darkMode
        const { history, auth, logo } = this.props
        const { isNavbarOpen, contentList, showNavBar, isSuperAdmin, tenantData } = this.state

        if (isSuperAdmin === null) {
            return <Loader></Loader>
        }
        return (
            <React.Fragment>
                <Header
                    logo={logo}
                    history={history}
                    additionalDetails={auth.user}
                    isDarkMode={isDarkMode}
                    handleMenuOpen={this.handleMenuOpen}
                />
                {
                    <div className="vr-container">
                        {showNavBar && (
                            <div
                                className={`${
                                    isNavbarOpen ? "vr-navigation" : "vr-navigation navCollapse"
                                }`}
                            >
                                {isSuperAdmin ? (
                                    <SideNavAdmin
                                        history={history}
                                        contentList={contentList}
                                        isDarkMode={isDarkMode}
                                        isSubscriptionEnabled={
                                            tenantData?.auxData?.subscriptionEnabled
                                        }
                                    />
                                ) : (
                                    <SideNav
                                        history={history}
                                        contentList={contentList}
                                        isDarkMode={isDarkMode}
                                        isSubscriptionEnabled={
                                            tenantData?.auxData?.subscriptionEnabled
                                        }
                                    />
                                )}
                            </div>
                        )}
                        <div
                            className={`${
                                isNavbarOpen && showNavBar
                                    ? "vr-content"
                                    : "vr-content content-fluid"
                            }`}
                        >
                            {isSuperAdmin !== null && (
                                <Switch>
                                    {createRoute(isSuperAdmin ? adminRoutes : mainRoutes)}
                                </Switch>
                            )}
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }

    render() {
        const { history } = this.props
        return (
            <div
                className={`vr-main-container ${
                    localStorage.getItem("darkMode") === "true" ? "vr-dark-mode" : "vr-light-mode"
                }`}
            >
                <ToastContainer autoClose={5000} closeOnClick transition={Flip} />
                <>
                    {" "}
                    {this.state.isAuth && localStorage.getItem("accessToken") ? (
                        this.renderNavMenu()
                    ) : this.state?.isLoginPanel ? (
                        <LoginPanel history={history} handleAfterLogin={this.handleAfterLogin} />
                    ) : null}
                </>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        darkMode: state.theme.darkMode,
        logo: state?.AdminHomeReducer?.logo,
        auth: state.auth,
        showNavbar: state?.AdminHomeReducer.showNavbar,
        tenantData: state?.AdminHomeReducer.tenantInfo
    }
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(
            {
                updateTheme,
                fetchContentList,
                updateHeaderLogo,
                showNavBar,
                findTenantByDomain,
                logoutUser,
                getSavedTimezone,
                saveTimezone
            },
            dispatch
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminHome))
