
export const CONTENT_TYPE_CONSTANT = {
    movie: "movies",
    series: "series",
    tv_show: "tv_show",
    season: "season",
}


export const convertContentTypeName = (contentType, isSave, updateId) => {
    let type = contentType?.toLowerCase()   
    if(type === CONTENT_TYPE_CONSTANT?.movie || type === CONTENT_TYPE_CONSTANT?.tv_show){
        if(isSave){
            return `metadata-ingestor/v1/metadata/ingest/vod/save?contentType=${type}`
        }
        return `metadata-ingestor/v1/metadata/ingest/vod/update/${updateId}?contentType=${type}`
    }else if(type === CONTENT_TYPE_CONSTANT?.series){
         if(isSave){
            return `metadata-ingestor/v1/metadata/ingest/series/save?contentType=${type}`
        }
        return `metadata-ingestor/v1/metadata/ingest/series/update/${updateId}?contentType=${type}`
    }else if (type ===CONTENT_TYPE_CONSTANT?.season){
        if(isSave){
            return `metadata-ingestor/v1/metadata/ingest/season/save?contentType=${type}`
        }
        return `metadata-ingestor/v1/metadata/ingest/season/update/${updateId}?contentType=${type}`
    }
    return type
}

export const v3DeleteURL = (contentType, deletedId) => {
    const type = contentType?.toLowerCase()   
    let purgeType = type;
    if(type === CONTENT_TYPE_CONSTANT?.movie || type === CONTENT_TYPE_CONSTANT?.tv_show){
        purgeType = 'vod'
    }
    return `metadata-ingestor/v1/metadata/ingest/${purgeType}/purge/${deletedId}?contentType=${type}`

}

export const hideHeader = (contentType) => {
    if(contentType === CONTENT_TYPE_CONSTANT?.movie || contentType === CONTENT_TYPE_CONSTANT?.series 
        || contentType === CONTENT_TYPE_CONSTANT?.season || contentType === CONTENT_TYPE_CONSTANT?.tv_show
    ){
        return false
    }

    return true
}

export const createAddNewStructure = (contentType) => {
    switch(contentType){
        case CONTENT_TYPE_CONSTANT?.movie:
        case 'movies': {
            return NEW_MOVIE_CONTENT_STRUCTURE
        };
        case CONTENT_TYPE_CONSTANT?.series: {
            return {
                ...NEW_SERIES_CONTENT_STRUCTURE,
                isOpenSeries: "false"
            }
        }
        case CONTENT_TYPE_CONSTANT?.season: {
            return {
                ...NEW_SERIES_CONTENT_STRUCTURE,
                "seriesId": "",
                "seasonNumber": ""
            }
        }
        default: {
            // TV show strucutre
            return { ...NEW_MOVIE_CONTENT_STRUCTURE,
                "seriesId": "",
                "seasonId": "",
                "episodeNumber": "",
                "seasonNumber": "",
            }
        }
    }
    
}

const CONTENT_COMMON_PROPERTIES = {
      "title": {
        "short": {
            "eng": "",
            "may": "",
            "tam": "",
            "chi": "",
            "tha": "",
            "ind": ""
        },
        "long": {
            "eng": "",
            "may": "",
            "tam": "",
            "chi": "",
            "tha": "",
            "ind": ""
        }
    },
    "description": {
        "short": {
            "eng": "",
            "may": "",
            "tam": "",
            "chi": "",
            "tha": "",
            "ind": ""
        },
        "long": {
            "eng": "",
            "may": "",
            "tam": "",
            "chi": "",
            "tha": "",
            "ind": ""
        }
    },
    "offers": [
                    {
                        "contractType": "",
                        "offerStartDate": "",
                        "offerEndDate": "",
                        "offerRegion": "",
                        "rentalExpiry": "",
                        "entitlements": [],
                        "adsEnabled": false,
                        "adsType": {
                            "adsPreRoll": null,
                            "adsMidRoll": null,
                            "adsPostRoll": null
                        },
                        "adsSupplier": null,
                        "allowedOnApplicationDevices": {
                            "VideoReady": [
                                "Mobile",
                                "Web",
                                "SmartTV",
                                "Dongle"
                            ],
                        }
                    }
                ],
    "playUrls": [
        {
            "contentType": "Full Asset",
            "duration": "",
            "hls": {
                "fairplay": ""
            },
            "dash": {
                "playready": "",
                "widevine": ""
            },
            "licenseAcquisitionUrl": {
                "playready": "",
                "widevine": ""
            }
        }
    ],
    "actor": {
        "eng": [
        ],
        "may": [
           
        ],
        "tam": [
        ],
        "chi": [
        ],
        "tha": [
        ],
        "ind": [
        ],
        "image": ""
    },
    "director": {
        "eng": [],
        "may": [],
        "tam": [],
        "chi": [],
        "tha": [],
        "ind": [],
        "image": ""
    },
    "producer": {
        "eng": [],
        "may": [],
        "tam": [],
        "chi": [],
        "tha": [],
        "ind": [],
        "image": ""
    },
    "showType": "",
    "keywords": [],
    "audioLanguages": {
        "eng": [],
        "may": [],
        "tam": [],
        "chi": [],
        "tha": [],
        "ind": []
    },
    "genre": {
        "primary": [
            {
                "name": {
                    "eng": "",
                    "may": "",
                    "tam": "",
                    "chi": "",
                    "tha": "",
                    "ind": ""
                },
                "image": ""
            }
        ],
        "secondary": [
            {
                "name": {
                    "eng": "",
                    "may": "",
                    "tam": "",
                    "chi": "",
                    "tha": "",
                    "ind": ""
                },
                "image": ""
            }
        ]
    },
    "images": [
        {
            "type": "boxcover",
            "url": ""
        },
        {
            "type": "poster",
            "url": ""
        },
        {
            "type": "thumbnail",
            "url": ""
        }
    ],
     "boxsetId": [],
    "yearOfRelease": "",
    "rating": "",
    "isEnabled": true,
    "operatorLabels": [],
    "maxConcurrentStreams": "0",
    
}

export const NEW_MOVIE_CONTENT_STRUCTURE = {
    ...CONTENT_COMMON_PROPERTIES,
    "isAutoplayEnabled": false,
    "isWMAuthEnabled": false,
    "isCDNAuthEnabled": false,
    "skipIntroStart": "",
    "skipIntroEnd": "",
    "skipCreditStart": "",
    "skipCreditEnd": "",
    "skipRecapStart": "",
    "skipRecapEnd": "",
    "contentCreateDate": "0",
    "contentUpdateDate": "0",
    "operatorLabels": [],
    "maxConcurrentStreams": "0"

}


export const NEW_SERIES_CONTENT_STRUCTURE ={
    ...CONTENT_COMMON_PROPERTIES,
    "playUrls": [],
}


export const getContentEditableFields = (contentType) => {
    return {
        'title.short.eng': {
            isEditable: true,
            isRequired: true,
            isSingleInput: false,
            isArrayPayload: false
        },
        'title.short.may': {
            isEditable: true,
            isRequired: false
        },
        'title.short.tam': {
            isEditable: true,
            isRequired: false
        },
        'title.short.chi': {
            isEditable: true,
            isRequired: false
        },
        'title.short.tha': {
            isEditable: true,
            isRequired: false
        },
        'title.short.ind': {
            isEditable: true,
            isRequired: false
        },
        'title.long.eng': {
            isEditable: true,
            isRequired: true
        },
        'title.long.may': {
            isEditable: true,
            isRequired: false
        },
        'title.long.tam': {
            isEditable: true,
            isRequired: false
        },
        'title.long.chi': {
            isEditable: true,
            isRequired: false
        },
        'title.long.tha': {
            isEditable: true,
            isRequired: false
        },
        'title.long.ind': {
            isEditable: true,
            isRequired: false
        },
        'description.short.eng': {
            isEditable: true,
            isRequired: true
        },
        'description.short.may': {
            isEditable: true,
            isRequired: false
        },
        'description.short.tam': {
            isEditable: true,
            isRequired: false
        },
        'description.short.chi': {
            isEditable: true,
            isRequired: false
        },
        'description.short.tha': {
            isEditable: true,
            isRequired: false
        },
        'description.short.ind': {
            isEditable: true,
            isRequired: false
        },
        'description.long.eng': {
            isEditable: true,
            isRequired: true
        },
        'description.long.may': {
            isEditable: true,
            isRequired: false
        },
        'description.long.tam': {
            isEditable: true,
            isRequired: false
        },
        'description.long.chi': {
            isEditable: true,
            isRequired: false
        },
        'description.long.tha': {
            isEditable: true,
            isRequired: false
        },
        'description.long.ind': {
            isEditable: true,
            isRequired: false
        },
        'offers.contractType': {
            isEditable: true,
            isRequired: false
        },
        'offers.offerStartDate': {
            isEditable: true,
            isRequired: false
        },
        'offers.offerEndDate': {
            isEditable: true,
            isRequired: false
        },
        'offers.offerRegion': {
            isEditable: true,
            isRequired: false
        },
        'offers.rentalExpiry': {
            isEditable: true,
            isRequired: false
        },
        'offers.entitlements': {
            isEditable: true,
            isRequired: false,
            isSingleInput: true
        },
        'offers.adsEnabled': {
            isEditable: true,
            isRequired: false
        },
        'offers.adsType.adsPreRoll': {
            isEditable: true,
            isRequired: false
        },
        'offers.adsType.adsMidRoll': {
            isEditable: true,
            isRequired: false
        },
        'offers.adsSupplier': {
            isEditable: true,
            isRequired: false
        },
        'offers.allowedOnApplicationDevices.videoReady': {
            isEditable: true,
            isRequired: false
        },
        'playUrls.contentType': {
            isEditable: true,
            isRequired: true
        },
        'playUrls.duration': {
            isEditable: true,
            isRequired: true
        },
        'playUrls.hls.fairplay': {
            isEditable: true,
            isRequired: true
        },
        'playUrls.dash.playready': {
            isEditable: true,
            isRequired: true
        },
        'playUrls.dash.widevine': {
            isEditable: true,
            isRequired: true
        },
        'playUrls.licenseAcquisitionUrl.playready': {
            isEditable: true,
            isRequired: false
        },
        'playUrls.licenseAcquisitionUrl.widevine': {
            isEditable: true,
            isRequired: false
        },
        'actor.eng': {
            isEditable: true,
            isRequired: true,
            isSingleInput: true,
            isArrayPayload: true
        },
        'actor.may': {
            isEditable: true,
            isRequired: false,
            isSingleInput: true,
            isArrayPayload: true
        },
        'actor.tam': {
            isEditable: true,
            isRequired: false,
            isSingleInput: true,
            isArrayPayload: true
        },
        'actor.chi': {
            isEditable: true,
            isRequired: false,
            isSingleInput: true,
            isArrayPayload: true
        },
        'actor.tha': {
            isEditable: true,
            isRequired: false,
            isSingleInput: true,
            isArrayPayload: true

        },
        'actor.ind': {
            isEditable: true,
            isRequired: false,
            isSingleInput: true,
            isArrayPayload: true
        },
        'director.eng': {
            isEditable: true,
            isRequired: true,
            isSingleInput: true,
            isArrayPayload: true
        },
        'director.may': {
            isEditable: true,
            isRequired: false,
            isSingleInput: true,
            isArrayPayload: true
        },
        'director.tam': {
            isEditable: true,
            isRequired: false,
            isSingleInput: true,
            isArrayPayload: true
        },
        'director.chi': {
            isEditable: true,
            isRequired: false,
            isSingleInput: true,
            isArrayPayload: true
        },
        'director.tha': {
            isEditable: true,
            isRequired: false,
            isSingleInput: true,
            isArrayPayload: true
        },
        'director.ind': {
            isEditable: true,
            isRequired: false,
            isSingleInput: true,
            isArrayPayload: true
        },
        'producer.eng': {
            isEditable: true,
            isRequired: true,
        isSingleInput: true,
            isArrayPayload: true
        },
        'producer.may': {
            isEditable: true,
            isRequired: false,
            isSingleInput: true,
            isArrayPayload: true
        },
        'producer.tam': {
            isEditable: true,
            isRequired: false,
            isSingleInput: true,
            isArrayPayload: true
        },
        'producer.chi': {
            isEditable: true,
            isRequired: false,
            isSingleInput: true,
            isArrayPayload: true
        },
        'producer.tha': {
            isEditable: true,
            isRequired: false,
            isSingleInput: true,
            isArrayPayload: true
        },
        'producer.ind': {
            isEditable: true,
            isRequired: false,
            isSingleInput: true,
            isArrayPayload: true
        },
        'showType': {
            isEditable: true,
            isRequired: true 
        },
        'keywords': {
            isEditable: true,
            isRequired: false,
            isSingleInput: true,
            isArrayPayload: true
        },
        'audioLanguages.eng': {
            isEditable: true,
            isRequired: true,
            isSingleInput: true,
            isArrayPayload: true
        },
        'audioLanguages.may': {
            isEditable: true,
            isRequired: false,
            isSingleInput: true,
            isArrayPayload: true
        },
        'audioLanguages.tam': {
            isEditable: true,
            isRequired: false,
            isSingleInput: true,
            isArrayPayload: true
        },
        'audioLanguages.chi': {
            isEditable: true,
            isRequired: false,
            isSingleInput: true,
            isArrayPayload: true
        },
        'audioLanguages.tha': {
            isEditable: true,
            isRequired: false,
            isSingleInput: true,
            isArrayPayload: true
        },
        'audioLanguages.ind': {
            isEditable: true,
            isRequired: false,
            isSingleInput: true,
            isArrayPayload: true
        },
        'genre.primary.name.eng': {
            isEditable: true,
            isRequired: true,
            isSingleInput: true
        },
        'genre.primary.name.may': {
            isEditable: true,
            isRequired: false,
            isSingleInput: true
        },
        'genre.primary.name.tam': {
            isEditable: true,
            isRequired: false,
            isSingleInput: true
        },
        'genre.primary.name.chi': {
            isEditable: true,
            isRequired: false,
            isSingleInput: true
        },
        'genre.primary.name.tha': {
            isEditable: true,
            isRequired: false,
            isSingleInput: true
        }, 
        'genre.primary.name.ind': {
            isEditable: true,
            isRequired: false,
            isSingleInput: true
        },'genre.secondary.name.eng': {
            isEditable: true,
            isRequired: true,
            isSingleInput: true
        },
        'genre.secondary.name.may': {
            isEditable: true,
            isRequired: false,
            isSingleInput: true
        },
        'genre.secondary.name.tam': {
            isEditable: true,
            isRequired: false,
            isSingleInput: true
        },
        'genre.secondary.name.chi': {
            isEditable: true,
            isRequired: false,
            isSingleInput: true
        },
        'genre.secondary.name.tha': {
            isEditable: true,
            isRequired: false,
            isSingleInput: true
        }, 
        'genre.secondary.name.ind': {
            isEditable: true,
            isRequired: false,
            isSingleInput: true
        },
        'images.url': {
            isEditable: true,
            isRequired: true,
            isImage: true
        },
        'seriesId': {
            isEditable: true,
            isRequired: contentType === CONTENT_TYPE_CONSTANT?.movie ? false : true
        },
        'seasonId': {
            isEditable: true,
            isRequired: contentType === CONTENT_TYPE_CONSTANT?.movie ? false : true
        },
        'boxsetId': {
            isEditable: true,
            isRequired: false
        },
        'episodeNumber': {
            isEditable: true,
            isRequired: contentType === CONTENT_TYPE_CONSTANT?.movie ? false : true
        },
        'seasonNumber': {
            isEditable: true,
            isRequired: contentType === CONTENT_TYPE_CONSTANT?.movie ? false : true
        },
        'yearOfRelease': {
            isEditable: true,
            isRequired: false
        },
        'isEnabled': {
            isEditable: true,
            isRequired: false
        },
        'isAutoplayEnabled': {
            isEditable: true,
            isRequired: false
        },
        'isWMAuthEnabled': {
            isEditable: true,
            isRequired: false
        },
        'isCDNAuthEnabled': {
            isEditable: true,
            isRequired: false
        },
    'skipIntroStart': {
            isEditable: true,
            isRequired: false
        }, 'skipIntroEnd': {
            isEditable: true,
            isRequired: false
        }, 'skipCreditStart': {
            isEditable: true,
            isRequired: false
        }, 'skipCreditEnd': {
            isEditable: true,
            isRequired: false
        }, 'skipRecapStart': {
            isEditable: true,
            isRequired: false
        }, 'skipRecapEnd': {
            isEditable: true,
            isRequired: false
        }, 'contentCreateDate': {
            isEditable: true,
            isRequired: false
        },'contentUpdateDate': {
            isEditable: true,
            isRequired: false
        },'operatorLabels': {
            isEditable: true,
            isRequired: false,
            isSingleInput: true

        },'maxConcurrentStreams': {
            isEditable: true,
            isRequired: false
        },'rating': {
            isEditable: true,
            isRequired: false
        },
        "boxsetIds": {
            isEditable: true,
            isRequired: false,
            isSingleInput: true
        },
        // Series Data:
        "boxsetId": {
            isEditable: true,
            isRequired: false,
            isSingleInput: true
        },
        "isOpenSeries": {
            isEditable: true,
            isRequired: false,
        },
        'title.shortTitle.eng': {
            isEditable: true,
            isRequired: true,
            isSingleInput: false,
            isArrayPayload: false
        },
        'title.shortTitle.may': {
            isEditable: true,
            isRequired: false
        },
        'title.shortTitle.tam': {
            isEditable: true,
            isRequired: false
        },
        'title.shortTitle.chi': {
            isEditable: true,
            isRequired: false
        },
        'title.shortTitle.tha': {
            isEditable: true,
            isRequired: false
        },
        'title.shortTitle.ind': {
            isEditable: true,
            isRequired: false
        },
        'title.longTitle.eng': {
            isEditable: true,
            isRequired: true
        },
        'title.longTitle.may': {
            isEditable: true,
            isRequired: false
        },
        'title.longTitle.tam': {
            isEditable: true,
            isRequired: false
        },
        'title.longTitle.chi': {
            isEditable: true,
            isRequired: false
        },
        'title.longTitle.tha': {
            isEditable: true,
            isRequired: false
        },
        'title.longTitle.ind': {
            isEditable: true,
            isRequired: false
        },
        'description.shortDescription.eng': {
            isEditable: true,
            isRequired: true
        },
        'description.shortDescription.may': {
            isEditable: true,
            isRequired: false
        },
        'description.shortDescription.tam': {
            isEditable: true,
            isRequired: false
        },
        'description.shortDescription.chi': {
            isEditable: true,
            isRequired: false
        },
        'description.shortDescription.tha': {
            isEditable: true,
            isRequired: false
        },
        'description.shortDescription.ind': {
            isEditable: true,
            isRequired: false
        },
        'description.longDescription.eng': {
            isEditable: true,
            isRequired: true
        },
        'description.longDescription.may': {
            isEditable: true,
            isRequired: false
        },
        'description.longDescription.tam': {
            isEditable: true,
            isRequired: false
        },
        'description.longDescription.chi': {
            isEditable: true,
            isRequired: false
        },
        'description.longDescription.tha': {
            isEditable: true,
            isRequired: false
        },
        'description.longDescription.ind': {
            isEditable: true,
            isRequired: false
        },
        "playUrls": {
            isEditable: true,
            isRequired: false
        },
        

    }

}
