import React, { Component } from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import StartCase from "lodash/startCase"
import CamelCase from "lodash/camelCase"

import Button from "../../../common/Button/index"
import SubMenuList from "../subMenuList"
import MainMenuList from "../mainMenuList/index."
import {
    fetchMenuOptions,
    createMenu,
    fetchMenuList,
    deleteMenu,
    uploadImage,
    deleteTab
} from "../../api/action"
import { fetchCategory } from "../../../Category/api/action"
import { fetchAlltextpage } from "../../../CustomPages/APIs/action"
import { getLanguageObject } from "../../../common/LanguageCardList"
import "./style.scss"
import { checkAllLanguageHasValue, isMultiLingual, sortArray } from "../../../../utils/common"
import { isEmpty } from "lodash"
import { DEFAULT_SELECTED_LANGUAGE_CODE } from "../../../ContentNav/api/constants"
import LanguageDataConfirmationModal from "../../../common/LanguageCardList/languageDataConfirmationModal"
import { BUTTON_CONSTANTS, ERROR_MESSAGES, TEXT_CONSTANTS } from "../../../../utils/constants"
import { getDefaultMultilingualValue } from "../../../../utils/common"
import ManageSEO from "../../../ManageSEO"

class EditableMenuDesign extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            isDelete: false,
            isDeleteSubMenu: false,
            idToDeleteSubMenu: -1,
            idToDelete: -1,
            isUnpublish: false,
            idToUnpublish: -1,
            tab: [],
            subMenu: [],
            placementList: [],
            cmsOptions: [],
            editorialOptions: [],
            menuId: null,
            error: [],
            isError: false,
            isAddClick: false,
            emptyLangDataModal: false,
            openMetaPage: false
        }
    }

    componentDidMount() {
        this.updateLanguageObject()
        this.loadHandler()
    }

    updateLanguageObject = async () => {
        this.languageObject = await getLanguageObject()
    }

    loadHandler = async () => {
        const {
            fetchMenuOptions,
            fetchCategory,
            currentPlatform,
            fetchAlltextpage,
            fetchMenuList
        } = this.props
        try {
            let [
                { data: menuOptions, status: status1 },
                { data: editorialOptions, status: status2 },
                { data: cmsOptions, status: status3 },
                { data: menuList, status: status4 }
            ] = await Promise.all([
                fetchMenuOptions(),
                fetchCategory(currentPlatform),
                fetchAlltextpage({ limit: 40, offset: 0 }),
                fetchMenuList(currentPlatform)
            ])
            if (status1 && status2 && status3 && status4) {
                if (menuList && menuList.menuDataList) {
                    menuList.menuDataList.forEach((item) => {
                        item.type =
                            item.menuType === "CMS"
                                ? item.menuType
                                : item.menuType
                                      ?.split("_")
                                      .map((i) => StartCase(CamelCase(i)))
                                      .join("-")
                        item.placement = item.placementType.map((i) => ({
                            id: StartCase(CamelCase(i)),
                            name: StartCase(CamelCase(i))
                        }))
                        item.innerDataList.map((ele) => {
                            ele.type =
                                ele.menuType === "CMS"
                                    ? ele.menuType
                                    : StartCase(CamelCase(ele.menuType))
                            return ele
                        })
                    })
                }
                cmsOptions.forEach((item) => {
                    item.id = String(item.id)
                })
                const sortedMenuList = menuList?.length ? sortArray(menuList) : []
                this.setState({
                    subMenu: menuOptions.menuTypes,
                    placementList: menuOptions.placementList.map((item) => item.toUpperCase()),
                    cmsOptions: !isMultiLingual()
                        ? cmsOptions
                        : this.getDefaultLangugageCmsOptions(cmsOptions),
                    editorialOptions,
                    menuId: menuList ? menuList.id : null,
                    tab: sortedMenuList,
                    isLoading: false
                })
            } else {
                console.log("something went wrong!!!")
                this.setState({
                    isLoading: false
                })
            }
        } catch (err) {
            console.log("something went wrong!!!", err)
            this.setState({
                isLoading: false
            })
        }
    }

    getId = () => {
        return Math.random().toString(36).substring(2) + Date.now().toString(36)
    }

    imgUpload = async (data, id, name, isSubMenu) => {
        data.append("type", "MENU_ICON")
        const response = await this.props.uploadImage(data)
        if (response && response.status) {
            let imgUrl = response.data.path
            let { tab } = this.state
            tab.map((item) => {
                if (isSubMenu) {
                    item.innerDataList.map((ele) => {
                        if (ele.id === id || ele.uniqueId === id) {
                            if (name === "menuIcon") {
                                ele[name] = imgUrl
                            }
                        }
                        return ele
                    })
                } else if (item.id === id || item.uniqueId === id) {
                    if (name === "menuIcon") {
                        item[name] = imgUrl
                    }
                }
                return item
            })
            this.setState({
                tab
            })
        }
    }

    imgRemove = (name, id, isSubMenu) => {
        let { tab } = this.state
        tab.map((item) => {
            if (isSubMenu) {
                item.innerDataList.map((ele) => {
                    if (ele.id === id || ele.uniqueId === id) {
                        if (name === "menuIcon") {
                            ele[name] = ""
                        }
                    }
                    return ele
                })
            } else if (item.id === id || item.uniqueId === id) {
                if (name === "menuIcon") {
                    item[name] = ""
                }
            }
            return item
        })
        this.setState({
            tab
        })
    }

    addNewTabClick = () => {
        const { tab } = this.state
        let obj = {
            uniqueId: this.getId(),
            position: tab?.length
                ? Math.max.apply(
                      Math,
                      tab.map((o) => o.position)
                  ) + 1
                : 1,
            tabName: !isMultiLingual() ? "" : JSON.parse(JSON.stringify(this.languageObject)),
            innerDataList: [],
            placement: [],
            linkToPage: null,
            type: null,
            menuIcon: null,
            active: false
        }
        if (tab.length) {
            tab[tab.length - 1].isTouched = { name: true, platformType: true }
        }
        this.setState({
            tab: [...this.state.tab, obj]
        })
    }
    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)
        return result
    }

    onDragEnd = (result) => {
        if (!result.destination) {
            return
        }
        const tab = this.reorder(this.state.tab, result.source.index, result.destination.index)
        let newTab = tab.map((item, index) => {
            item.position = index + 1
            return item
        })

        this.setState(
            {
                tab: newTab
            },
            this.validate
        )
    }

    onDragEndSubMenu = (result, id) => {
        // dropped outside the list
        if (!result.destination) {
            return
        }
        let { tab } = this.state
        let subMenu = []
        tab.map((item) => {
            if (item.id === id || item.uniqueId === id) {
                subMenu = this.reorder(
                    item.innerDataList,
                    result.source.index,
                    result.destination.index
                )
                item.innerDataList = subMenu.map((ele, index) => {
                    ele.position = index + 1
                    return ele
                })
            }
            return item
        })

        this.setState(
            {
                tab
            }
            // this.validate
        )
    }

    handleSelectChange = (id, name, value) => {
        let { tab } = this.state
        let selectedID = null
        tab.map((item) => {
            selectedID = item?.id ? item?.id : item?.uniqueId
            if (selectedID === id) {
                if (name === "type") {
                    if (value === "Sub-Menu") {
                        item.innerDataList.push({
                            uniqueId: this.getId(),
                            position: item.innerDataList.length + 1,
                            tabName: !isMultiLingual()
                                ? ""
                                : JSON.parse(JSON.stringify(this.languageObject)),
                            linkToPage: {},
                            type: null
                        })
                    } else {
                        item.innerDataList = []
                        item.menuType = value
                    }
                    item[name] = value
                } else {
                    item[name] = value
                    if (name === "linkToPage") {
                        item[name] = String(value)
                    }

                    if (name === "linkToPage" && item.type === "CMS") {
                        item.pageType = this.findPageType(String(value))
                    }
                }
            }
            return item
        })
        this.setState({
            tab,
            ...(name === "seoTags" && { openMetaPage: false })
        })
    }

    findPageType = (id) => {
        let { cmsOptions } = this.state
        let pageType = null
        cmsOptions.forEach((item) => {
            if (item.id === id) {
                return (pageType = item.pageType)
            }
        })
        return pageType
    }

    handleDeleteClick = (id) => {
        const { isDelete, idToDelete } = this.state
        if (!isDelete) {
            this.setState({
                idToDelete: id,
                isDelete: !isDelete
            })
        } else if (id === idToDelete) {
            this.setState({
                idToDelete: -1,
                isDelete: !isDelete
            })
        }
    }

    handleDeleteTab = async (ele) => {
        const { tab, isDelete } = this.state
        if (ele.uniqueId) {
            ele.id = ele.uniqueId
        }
        let newTabData = tab.filter((item) => {
            if (item.id ? item.id !== ele.id : item.uniqueId !== ele.id) {
                return item
            }
            return false
        })
        this.props.deleteTab(ele.id)
        this.resetPosition(newTabData)
        this.setState({
            isDelete: !isDelete,
            tab: [...newTabData],
            error: {}
        })
    }

    resetPosition = (newTabData) => {
        newTabData &&
            newTabData.map((value, index) => {
                value.position = index + 1
            })
    }

    handleDeleteClickSubMenu = (id) => {
        const { idToDeleteSubMenu, isDeleteSubMenu } = this.state
        if (!isDeleteSubMenu) {
            this.setState({
                idToDeleteSubMenu: id,
                isDeleteSubMenu: !isDeleteSubMenu
            })
        } else if (id === idToDeleteSubMenu) {
            this.setState({
                idToDeleteSubMenu: -1,
                isDeleteSubMenu: !isDeleteSubMenu
            })
        }
    }

    handleDeleteSubMenu = async (sub, id) => {
        const { tab, isDeleteSubMenu } = this.state
        if (sub.uniqueId) {
            sub.id = sub.uniqueId
        }
        let newTabData = tab.map((item) => {
            if (item.id ? item.id === id : item.uniqueId === id) {
                item.innerDataList = item.innerDataList.filter((ele) => {
                    if (ele.id ? ele.id !== sub.id : ele.uniqueId !== sub.id) {
                        return ele
                    }
                    return false
                })
                return item
            }
            return item
        })
        // if (!sub.uniqueId) {
        //   let response = await this.props.deleteMenu(sub.id);
        //   if (response && response.status) {
        //     this.setState(
        //       {
        //         isDeleteSubMenu: !isDeleteSubMenu,
        //         tab: [...newTabData],
        //       },
        //       () => toast.success(response.message)
        //     );
        //   }
        // } else {
        this.setState({
            isDeleteSubMenu: !isDeleteSubMenu,
            tab: [...newTabData],
            error: {}
        })
        // }
    }

    findLinkTo = (id, list) => {
        if (Array.isArray(list)) {
            let obj = null
            list.forEach((item) => {
                if (item.id === id) {
                    return (obj = item)
                }
            })
            return obj
        }
    }
    handleSubMenuChange = (id, subId, name, value) => {
        let { tab } = this.state
        tab.forEach((item) => {
            if (item.id === id || item.uniqueId === id) {
                item.innerDataList.map((ele) => {
                    if (ele.id === subId || ele.uniqueId === subId) {
                        ele[name] = value
                    }
                    return ele
                })
            }
        })
        this.setState({
            tab
        })
    }

    addNewSubMenu = (id) => {
        const { tab } = this.state
        tab.forEach((item) => {
            if (item.id === id || item.uniqueId === id) {
                item.innerDataList.push({
                    uniqueId: this.getId(),
                    position: item.innerDataList.length + 1,
                    tabName: !isMultiLingual()
                        ? ""
                        : JSON.parse(JSON.stringify(this.languageObject)),
                    linkToPage: null,
                    type: null
                })
            }
        })
        this.setState({
            tab
        })
    }
    getDefaultLangugageCmsOptions = (list) => {
        if (Array.isArray(list)) {
            let tempList = []
            list.map((item) => {
                item.name = item.name["en"] ? item.name["en"] : item.name
                tempList.push(item)
                return item
            })
            return tempList
        }
    }

    createUpdatedPayload = () => {
        const { tab, menuId } = this.state
        const { currentPlatform } = this.props

        tab.map((item) => {
            delete item.uniqueId

            let p
            if (item.placement) {
                p = item.placement.map((ele) => {
                    if (ele.name) {
                        return ele.name
                    } else {
                        return ele
                    }
                })
                item.placementType = p
            }
            item.innerDataList?.length &&
                item.innerDataList.map((ele) => {
                    delete ele.uniqueId
                    return ele
                })
            item.platformId = currentPlatform
            return item
        })
        return tab
    }

    handleSave = async () => {
        const validation = this.validate()
        if (validation?.isValid) {
            if (validation?.emptyLangDataModal) {
                this.setState({
                    emptyLangDataModal: true
                })
            } else {
                await this.submitData()
            }
        }
    }

    modalCancelHandler = () => {
        this.setState({
            emptyLangDataModal: false
        })
    }

    submitData = async () => {
        this.modalCancelHandler()
        const { createMenu, handleEditClick } = this.props
        const payLoad = this.createUpdatedPayload()
        const response = await createMenu(payLoad)
        if (response && response.status) {
            toast.success("Menu created successfully")
            handleEditClick()
        } else {
            toast.error(ERROR_MESSAGES.ENTER_VALID_DATA)
        }
    }

    validate = () => {
        const { tab } = this.state
        // const { selectedLanguage } = this.props;
        const selectedCode = DEFAULT_SELECTED_LANGUAGE_CODE
        let isError = false
        let isDataFilledInAllLang = true
        let error = tab.map((i) => {
            let err = {}
            if (i.menuType == null) {
                err.menuType = "Please Select Menu Type"
            }
            if (!i.placement?.length && !i.placementType?.length) {
                err.placement = "Please Select One Placement Position"
            }
            if (!isMultiLingual() && i?.tabName?.trim() === "") {
                err.name = "Please Enter Name"
            }
            if (
                isMultiLingual() &&
                (isEmpty(i.tabName) ||
                    (!isEmpty(i.tabName) && i.tabName[selectedCode]?.trim() === ""))
            ) {
                err.englishName = "Please Enter Name in English Language"
                toast.error(ERROR_MESSAGES.ENTER_DATA_IN_ENGLISH)
            }
            if (isMultiLingual() && !checkAllLanguageHasValue(i.tabName, this.languageObject)) {
                isDataFilledInAllLang = false
            }
            if (i.menuIcon == null || i.menuIcon === "") {
                err.menuIcon = "Please select Menu Icon"
            }
            if (i.innerDataList?.length) {
                err.subErr = i.innerDataList?.map((ele) => {
                    let subErr = {}
                    if (ele.type == null) {
                        subErr.type = "Please Select Menu Type"
                    }
                    if (ele.linkToPage == null) {
                        subErr.linkToPage = "Please Select Link to Page"
                    }
                    if (getDefaultMultilingualValue(ele?.tabName)?.trim() === "") {
                        subErr.name = "Please Enter Name"
                    }
                    if (ele.menuIcon == null || ele.menuIcon === "") {
                        subErr.menuIcon = "Please Select Menu Icon"
                    }
                    return subErr
                })
                if (!err.subErr.length) {
                    delete err.subErr
                }
            } else {
                if (i.linkToPage == null && (i.type === "CMS" || i.type === "Editorial")) {
                    err.linkToPage = "Please Select Link to Page"
                }
            }
            return err
        })
        let isErrorExist = false // If data is not present in all language set iserrorExist to true
        for (let i = 0; i < error.length; i++) {
            if (
                error[i].name ||
                error[i].type ||
                error[i].placement ||
                error[i].linkToPage ||
                error[i].menuIcon ||
                error[i].englishName
            ) {
                isErrorExist = true
            } else if (error[i].subErr && error[i].subErr.length) {
                if (error[i].subErr.filter((value) => Object.keys(value).length).length) {
                    isErrorExist = true
                    break
                }
            }
        }

        this.setData = (isValid, emptyLangDataModal) => {
            this.setState({
                error,
                isError
            })
            return { isValid, emptyLangDataModal }
        }

        if (isErrorExist) {
            return this.setData(false, false)
        } else if (!isDataFilledInAllLang) {
            // toast.error("Please enter data in all languages");
            return this.setData(true, true)
        } else {
            return { isValid: true, emptyLangDataModal: false }
        }
    }

    loader = () => {
        return (
            <ul className="loader">
                <li className="placeholder">
                    <p className="handler"></p>
                    <p className="tab-placeholder"></p>
                </li>
                <li className="placeholder">
                    <p className="handler"></p>
                    <p className="tab-placeholder"></p>
                </li>
                <li className="placeholder">
                    <p className="handler"></p>
                    <p className="tab-placeholder"></p>
                </li>
            </ul>
        )
    }

    handlePublishUnpublishTab = (ele) => {
        const { tab } = this.state
        const updatedTabItem = tab.map((tabItem) => {
            const currentElement = tabItem?.id ? tabItem?.id : tabItem?.uniqueId
            const selectedElement = ele?.id ? ele?.id : ele?.uniqueId
            if (currentElement === selectedElement) {
                tabItem.active = !tabItem.active
            }
            return tabItem
        })
        this.setState({
            tab: [...updatedTabItem]
        })
    }

    openMetaEdit = (data) => {
        this.setState({
            openMetaPage: true,
            metaData: data
        })
    }

    render() {
        const {
            tab,
            isDelete,
            subMenu,
            cmsOptions,
            editorialOptions,
            placementList,
            idToDeleteSubMenu,
            isDeleteSubMenu,
            idToDelete,
            error,
            isLoading,
            emptyLangDataModal,
            metaData,
            openMetaPage
        } = this.state

        const { handleEditClick, selectedLanguage } = this.props
        const tabItem = tab
        return (
            <div className="menu-container">
                {isLoading ? (
                    this.loader()
                ) : openMetaPage ? (
                    <ManageSEO
                        data={metaData}
                        updateData={this.handleSelectChange}
                        onCancel={() => this.setState({ openMetaPage: false })}
                    />
                ) : (
                    <div>
                        {tabItem.length > 0 && (
                            <section className="listing">
                                <div className="listing-head">
                                    <span className="place"></span>
                                    <span className="head">{TEXT_CONSTANTS?.MENU_ICON}</span>
                                    <span className="head">{TEXT_CONSTANTS?.TAB_NAME}</span>
                                    <span className="head">{TEXT_CONSTANTS?.TYPE}</span>
                                    <span className="head">{TEXT_CONSTANTS?.LINK_TO_PAGE}</span>
                                    <span className="head">{TEXT_CONSTANTS?.PLACEMENT}</span>
                                    <span className="place"></span>
                                </div>
                                <DragDropContext onDragEnd={this.onDragEnd}>
                                    <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <ul
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                className="menuTab">
                                                {tabItem.map((item, index) => (
                                                    <Draggable
                                                        key={item.id ? item.id : item.uniqueId}
                                                        draggableId={`item-${
                                                            item.id ? item.id : item.uniqueId
                                                        }`}
                                                        index={index}>
                                                        {(provided, snapshot) => (
                                                            <li
                                                                id={
                                                                    item.id
                                                                        ? item.id
                                                                        : item.uniqueId
                                                                }
                                                                key={
                                                                    item.id
                                                                        ? item.id
                                                                        : item.uniqueId
                                                                }
                                                                className={`tabList`}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}>
                                                                <MainMenuList
                                                                    item={item}
                                                                    idToDelete={idToDelete}
                                                                    isDelete={isDelete}
                                                                    handleSelectChange={
                                                                        this.handleSelectChange
                                                                    }
                                                                    handleDeleteClick={
                                                                        this.handleDeleteClick
                                                                    }
                                                                    handleDeleteTab={
                                                                        this.handleDeleteTab
                                                                    }
                                                                    delBtn={
                                                                        item.length - 1 === index
                                                                            ? false
                                                                            : true
                                                                    }
                                                                    index={index}
                                                                    subMenu={subMenu}
                                                                    placementList={placementList}
                                                                    cmsOptions={cmsOptions}
                                                                    editorialOptions={
                                                                        editorialOptions
                                                                    }
                                                                    selectedLanguage={
                                                                        selectedLanguage
                                                                    }
                                                                    findLinkTo={this.findLinkTo}
                                                                    provided={provided}
                                                                    error={error[index]}
                                                                    isAddClick={(value) => {
                                                                        this.setState({
                                                                            isAddClick: value
                                                                        })
                                                                    }}
                                                                    imageUpload={this.imgUpload}
                                                                    imageRemove={this.imgRemove}
                                                                    handlePublishUnpublishTab={
                                                                        this
                                                                            .handlePublishUnpublishTab
                                                                    }
                                                                    openMetaEdit={this.openMetaEdit}
                                                                />
                                                                {
                                                                    <DragDropContext
                                                                        onDragEnd={(result) =>
                                                                            this.onDragEndSubMenu(
                                                                                result,
                                                                                item.id
                                                                                    ? item.id
                                                                                    : item.uniqueId
                                                                            )
                                                                        }>
                                                                        <Droppable droppableId="droppable">
                                                                            {(provided, snapshot) =>
                                                                                item.innerDataList
                                                                                    ?.length ? (
                                                                                    <ul
                                                                                        {...provided.droppableProps}
                                                                                        ref={
                                                                                            provided.innerRef
                                                                                        }
                                                                                        className="subMenu">
                                                                                        {item.innerDataList
                                                                                            .sort(
                                                                                                (
                                                                                                    a,
                                                                                                    b
                                                                                                ) =>
                                                                                                    a.position >
                                                                                                    b.position
                                                                                                        ? 1
                                                                                                        : -1
                                                                                            )
                                                                                            .map(
                                                                                                (
                                                                                                    element,
                                                                                                    i
                                                                                                ) => (
                                                                                                    <Draggable
                                                                                                        key={
                                                                                                            element.id
                                                                                                                ? element.id
                                                                                                                : element.uniqueId
                                                                                                        }
                                                                                                        draggableId={`item-${
                                                                                                            element.id
                                                                                                                ? element.id
                                                                                                                : element.uniqueId
                                                                                                        }`}
                                                                                                        index={
                                                                                                            i
                                                                                                        }>
                                                                                                        {(
                                                                                                            provided,
                                                                                                            snapshot
                                                                                                        ) => (
                                                                                                            <SubMenuList
                                                                                                                element={
                                                                                                                    element
                                                                                                                }
                                                                                                                provided={
                                                                                                                    provided
                                                                                                                }
                                                                                                                imageUpload={
                                                                                                                    this
                                                                                                                        .imgUpload
                                                                                                                }
                                                                                                                selectedLanguage={
                                                                                                                    selectedLanguage
                                                                                                                }
                                                                                                                imageRemove={
                                                                                                                    this
                                                                                                                        .imgRemove
                                                                                                                }
                                                                                                                handleDeleteClickSubMenu={
                                                                                                                    this
                                                                                                                        .handleDeleteClickSubMenu
                                                                                                                }
                                                                                                                handleDeleteSubMenu={(
                                                                                                                    id
                                                                                                                ) =>
                                                                                                                    this.handleDeleteSubMenu(
                                                                                                                        id,
                                                                                                                        item.id
                                                                                                                            ? item.id
                                                                                                                            : item.uniqueId
                                                                                                                    )
                                                                                                                }
                                                                                                                isDeleteSubMenu={
                                                                                                                    isDeleteSubMenu
                                                                                                                }
                                                                                                                idToDeleteSubMenu={
                                                                                                                    idToDeleteSubMenu
                                                                                                                }
                                                                                                                addNewSubMenu={() =>
                                                                                                                    this.addNewSubMenu(
                                                                                                                        item.id
                                                                                                                            ? item.id
                                                                                                                            : item.uniqueId
                                                                                                                    )
                                                                                                                }
                                                                                                                handleSubMenuChange={(
                                                                                                                    name,
                                                                                                                    value
                                                                                                                ) => {
                                                                                                                    if (
                                                                                                                        isMultiLingual() &&
                                                                                                                        name ===
                                                                                                                            "tabName"
                                                                                                                    ) {
                                                                                                                        element.tabName[
                                                                                                                            selectedLanguage.code
                                                                                                                        ] =
                                                                                                                            value
                                                                                                                    }

                                                                                                                    this.handleSubMenuChange(
                                                                                                                        item.id
                                                                                                                            ? item.id
                                                                                                                            : item.uniqueId,
                                                                                                                        element.id
                                                                                                                            ? element.id
                                                                                                                            : element.uniqueId,
                                                                                                                        name,
                                                                                                                        isMultiLingual() &&
                                                                                                                            name ===
                                                                                                                                "tabName"
                                                                                                                            ? element.tabName
                                                                                                                            : value
                                                                                                                    )
                                                                                                                }}
                                                                                                                delBtn={
                                                                                                                    item
                                                                                                                        .innerDataList
                                                                                                                        .length -
                                                                                                                        1 ===
                                                                                                                    i
                                                                                                                        ? false
                                                                                                                        : true
                                                                                                                }
                                                                                                                subMenu={
                                                                                                                    subMenu
                                                                                                                }
                                                                                                                placementList={
                                                                                                                    placementList
                                                                                                                }
                                                                                                                cmsOptions={
                                                                                                                    cmsOptions
                                                                                                                }
                                                                                                                editorialOptions={
                                                                                                                    editorialOptions
                                                                                                                }
                                                                                                                findLinkTo={
                                                                                                                    this
                                                                                                                        .findLinkTo
                                                                                                                }
                                                                                                                error={
                                                                                                                    error[
                                                                                                                        index
                                                                                                                    ] &&
                                                                                                                    error[
                                                                                                                        index
                                                                                                                    ]
                                                                                                                        .subErr &&
                                                                                                                    error[
                                                                                                                        index
                                                                                                                    ]
                                                                                                                        .subErr[
                                                                                                                        i
                                                                                                                    ]
                                                                                                                        ? error[
                                                                                                                              index
                                                                                                                          ]
                                                                                                                              .subErr[
                                                                                                                              i
                                                                                                                          ]
                                                                                                                        : {}
                                                                                                                }
                                                                                                            />
                                                                                                        )}
                                                                                                    </Draggable>
                                                                                                )
                                                                                            )}
                                                                                        {
                                                                                            provided.placeholder
                                                                                        }
                                                                                    </ul>
                                                                                ) : null
                                                                            }
                                                                        </Droppable>
                                                                    </DragDropContext>
                                                                }
                                                            </li>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </ul>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </section>
                        )}
                        <div className="addnewTab-Btn">
                            <span className="icon-container" onClick={this.addNewTabClick}>
                                <i className="close icon-plus"></i>
                            </span>
                            <span className="icon-text" onClick={this.addNewTabClick}>
                                {TEXT_CONSTANTS?.ADD_NEW_TAB}
                            </span>
                        </div>
                        <div className="addTabBtn">
                            <Button
                                bValue={BUTTON_CONSTANTS?.SUBMIT?.toUpperCase()}
                                cName="btn primary-btn"
                                clickHandler={this.handleSave}
                            />
                            <Button
                                bValue={BUTTON_CONSTANTS?.CANCEL?.toUpperCase()}
                                cName="btn cancel-btn"
                                clickHandler={handleEditClick}
                            />
                        </div>
                    </div>
                )}

                {emptyLangDataModal && (
                    <LanguageDataConfirmationModal
                        isModalOpen={emptyLangDataModal}
                        submitHandler={() => this.submitData()}
                        cancelHandler={this.modalCancelHandler}></LanguageDataConfirmationModal>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                fetchMenuOptions,
                fetchCategory,
                fetchAlltextpage,
                createMenu,
                fetchMenuList,
                deleteMenu,
                uploadImage,
                deleteTab
            },
            dispatch
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditableMenuDesign)
