import React, { useCallback } from "react"
import { TEXT_CONSTANTS } from "../../../utils/constants"
import ImageUpload from "../../common/imageUpload"

const CustomHeroBanner = (props) => {
    const {
        closeScheduleModal,
        uploadImage,
        removeImage,
        customHeroBanner,
        cancelHandler,
        saveContent
    } = props

    const closeModal = useCallback(() => {
        closeScheduleModal(-1)
        cancelHandler()
    }, [])

    return (
        <div className="railSchedulePreview ">
            <div className="empty-wrapper" />
            <div className="scheduleRail-container customHeroBanner">
                <header className="">
                    CHANGE IMAGE
                    <i className="icon-close" onClick={closeModal} />
                </header>
                <div className="d-flex">
                    <div className="scheduleRail-contents">
                        {" "}
                        <ImageUpload
                            key={"banner"}
                            name={"Mobile banner image"}
                            id={"heroBannerSmallScreenImage"}
                            label={"Mobile banner image"}
                            value={
                                customHeroBanner?.heroBannerSmallScreenImage
                                    ? customHeroBanner.heroBannerSmallScreenImage
                                    : null
                            }
                            cName={"landscape"}
                            uploadImage={uploadImage}
                            removeImage={removeImage}
                        />
                        <span className="note">Recommended Size (420 x 560 px)</span>
                        <ImageUpload
                            key={"banner"}
                            name={"Web banner image"}
                            id={"heroBannerLargeScreenImage"}
                            label={"Web banner image"}
                            value={
                                customHeroBanner?.heroBannerLargeScreenImage
                                    ? customHeroBanner.heroBannerLargeScreenImage
                                    : null
                            }
                            cName={"landscape"}
                            uploadImage={uploadImage}
                            removeImage={removeImage}
                        />
                        <span className="note">Recommended Size (1920 x 1080 px)</span>
                    </div>
                    <div>
                        <button className="btn border-btn">
                            <span
                                className="button-text"
                                onClick={() => {
                                    saveContent()
                                    closeScheduleModal(-1)
                                }}>
                                Save
                            </span>
                        </button>
                        <button className="btn cancel-btn">
                            <span className="button-text" onClick={closeModal}>
                                Cancel
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CustomHeroBanner
