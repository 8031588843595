import { API_ROOT_PATH } from "../../../config/apiPath"
import ApiService from "../../../utils/apiService"

class MultilingualService {
    getAllLanguage() {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/language/allLanguage`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
    saveLanguageList(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/language/update`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }
    languagePrimaryStatus(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/language/save`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }
    languageEnableStatus(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/language/enableLanguage`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }
}
const MultilingualServiceInstance = new MultilingualService()
export default MultilingualServiceInstance
