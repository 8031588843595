import React, { Component } from "react"
import Input from "../Input/index"
import "./style.scss"

class Search extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isClick: false
        }
    }

    render() {
        const { changeHandler, name, cName, placeholder, value } = this.props
        return (
            <div className={"search-box"}>
                <Input
                    id="search"
                    type="text"
                    changeHandler={changeHandler}
                    name={name}
                    cName={cName}
                    placeholder={placeholder}
                    value={value}
                />
            </div>
        )
    }
}

export default Search
