import ApiService from "../../../utils/apiService"
import { API_ROOT_PATH, API_TENANT_ROOT_PATH } from "../../../config/apiPath"

class ContentService {
    fetchFormElement(id) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/entityAttribute/${id}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
    getRightsManagement(params) {
        const TIMEZONE = new Date().toString().match(/([A-Z]+[\\+-][0-9]+)/)?.[1]
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/content/right/asset/${params}`,
            isAuthRequired: true,
            headers: { "X-TIMEZONE": TIMEZONE }
        }
        return ApiService(options)
    }

    createContent(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/entityValue/insertData`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }

    //fetch content list by content type
    fetchContent(params) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/content/${params}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    updateContent(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/entityValue/update`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }
    fetchEntityValueMasterData(params) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/entityValue/master-data`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
    fetchContentListing(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/core/data/content`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }

    uploadImage(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/core/document/upload?type=CONTENT`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }

    deleteImage(id) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/core/document/delete/${id}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    // deleteContent(ids) {
    //   const options = {
    //     method: "POST",
    //     url: `${API_ROOT_PATH}/content/entityValue/delete`,
    //     isAuthRequired: true,
    //     data: ids,
    //   };
    //   return ApiService(options);
    // }

    fetchTaggedContentInRail(ids) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/editorial/rail/content`,
            isAuthRequired: true,
            data: ids
        }
        return ApiService(options)
    }

    togglePublish(ids) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/content/publish`,
            isAuthRequired: true,
            data: ids
        }
        return ApiService(options)
    }
    saveContentNav(data, id) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/content?entityId=${id}`,
            isAuthRequired: true,
            data: data
        }
        return ApiService(options)
    }
    fetchContentList(data, id) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/content/v2/list?contentEntityId=${id}`,
            isAuthRequired: true,
            data: data
        }
        return ApiService(options)
    }
    deleteContent(data) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/content/delete-all`,
            isAuthRequired: true,
            data: data
        }
        return ApiService(options)
    }

    restartStream() {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/content/restartStreams`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    saveReels(data, id) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/reels/insertReel?entityId=${id}`,
            isAuthRequired: true,
            data: data
        }
        return ApiService(options)
    }

    searchReels(value) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/reels/search`,
            data: value,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    getEnrichmentData(data, id, contentType) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/api/v1/vionlabs/save/response/${id}?contentType=${contentType}`,
            isAuthRequired: true,
            data: data
        }
        return ApiService(options)
    }

    getContentEnrichment(data) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/api/v1/contentUtility/imdb/updateMetaData`,
            isAuthRequired: true,
            // data: { imdbId: "tt9779516", contentId: "movie_1020" },
            data: data
        }
        return ApiService(options)
    }

    fetchAllVideoMarkerData(id, contentType) {
        const accessToken = JSON.parse(localStorage.getItem("accessToken"))
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/api/v1/vionlabs/fetch/data/${id}?authorizationToken=${accessToken}&contentType=${contentType}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    fetchGenre() {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/genre/getGenreMap`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    fetchCertification() {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/ageGrading/fetchCertification`,
            isAuthRequired: true
            // data: {},
        }
        return ApiService(options)
    }

    getCredits() {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/master-metaData/getCredits`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    getCategory() {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/master-metaData/getMasterData`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
    saveV3NewContentNav(data, url) {
        const options = {
            method: "POST",
            url: `${API_TENANT_ROOT_PATH}/${url}`,
            isAuthRequired: true,
            data: data
        }
        return ApiService(options)
    }
    editV3NewContentNav(data, url) {
        const options = {
            method: "PUT",
            url: `${API_TENANT_ROOT_PATH}/${url}`,
            isAuthRequired: true,
            data: data
        }
        return ApiService(options)
    }
    deleteV3Content(url) {
        const options = {
            method: "DELETE",
            url: `${API_TENANT_ROOT_PATH}/${url}`,
            isAuthRequired: true,
            // data: data
        }
        return ApiService(options)
    }
     toggleV3Publish(ids) {
        const options = {
            method: "POST",
            url: `${API_TENANT_ROOT_PATH}/metadata-ingestor/v1/metadata/ingest/publish`,
            isAuthRequired: true,
            data: ids
        }
        return ApiService(options)
    }
    
}

const ContentServiceInstance = new ContentService()
export default ContentServiceInstance
