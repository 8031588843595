import LanguageServiceInstance from "./service"

export const fetchLanguageList = (callback) => {
    return LanguageServiceInstance.fetchLanguageList()
        .then((response) => {
            callback(response.data)
            return response.data
        })
        .catch((error) => {
            console.log("Some error in language list api")
        })
}
