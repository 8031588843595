import { API_ROOT_PATH } from "../../../../config/apiPath"
import ApiService from "../../../../utils/apiService"

export const PlatformService = {
    getAllPlatform() {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/editorial/v2/platforms/`,
            isAuthRequired: true
        }
        return ApiService(options)
    },
    addNewPlatform(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/editorial/v2/platforms`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    },
    deletePlatform(id) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/editorial/v2/platforms/delete/${id}`,
            isAuthRequired: true
        }
        return ApiService(options)
    },
    getAllPlatformType(id) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/editorial/v2/platforms/platform-types`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
}
