import ApiService from "../../../utils/apiService"
import { API_TENANT_ROOT_PATH } from "../../../config/apiPath"

class AuditTrailService {
    getAuditDropdowns(params) {
        const options = {
            method: "GET",
            url: `${API_TENANT_ROOT_PATH}/audit-trail-consumer/v1/dropdown/${params}/detail`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    fetchDetails(recordId) {
        const options = {
            method: "GET",
            url: `${API_TENANT_ROOT_PATH}/audit-trail-consumer/v1/search/${recordId}/detail`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    searchAuditList(params) {
        const options = {
            method: "POST",
            url: `${API_TENANT_ROOT_PATH}/audit-trail-consumer/v1/search`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }
}

const AuditTrailInstance = new AuditTrailService()
export default AuditTrailInstance
