import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import Button from "../../common/Button/index"
import { toast } from "react-toastify"
import get from "lodash/get"
import TextArea from "../../common/TextArea"
import Input from "../../common/Input/index"
import {
    createPlans,
    fetchCreatedPlans,
    softDeleteCreatePLan,
    editCreatePlan,
    updatePlans
} from "../APIs/action"
import { billingPeriodlists, timeUnitlist, currencylist } from "../APIs/constants"
import Select from "../../common/SelectBox"

import "./style.scss"
import { BUTTON_CONSTANTS } from "../../../utils/constants"

class AddNewPlan extends Component {
    constructor(props) {
        super(props)
        this.state = {
            createPlan: [],
            currency: null,
            addPlanMsg: true,
            addSavePlan: false,
            create_Plan: [], //fetching plans
            isDelete: false,
            fetchTable: false,
            isDisable: true
        }
    }

    componentDidMount() {
        if (!this.props.isEdit) {
            const planItem = {
                currency: null,
                active: false,
                amount: "",
                billingPeriod: null,
                planName: "",
                productName: "",
                trialLength: "",
                trialTimeUnit: null,
                description: ""
            }
            let { createPlan } = this.state
            createPlan.push(planItem)
            this.setState({
                createPlan,
                addPlanMsg: false,
                addSavePlan: true,
                fetchTable: true
            })
        }
        this.loadHandler()
    }

    loadHandler = async () => {
        const { isEdit, editableData, editCreatePlan } = this.props
        if (isEdit) {
            const resp = await editCreatePlan(editableData.id)
            if (resp && resp.status) {
                this.setState({
                    createPlan: [resp.data],
                    currency: resp.data.currency
                })
            }
        }
    }

    handleDeleteCreatePlan = async (id) => {
        const response = await this.props.softDeleteCreatePLan(id)
        if (get(response, "status")) {
            toast.success(get(response, "message"))
        } else {
            toast.error(get(response, "message"))
        }
        this.setState({
            isDelete: !this.state.isDelete
        })
        this.loadHandler()
    }
    checkProperties = (obj) => {
        let flag = 0
        obj.forEach((e) => {
            for (let key in e) {
                let value = e[key]
                if (value === null || value === "") flag = 1
            }
        })
        if (flag === 0) return true
        else return false
    }

    handleAddNewPlan = () => {
        const planItem = {
            currency: null,
            amount: "",
            billingPeriod: null,
            active: false,
            planName: "",
            productName: "",
            trialLength: "",
            trialTimeUnit: null,
            description: ""
        }
        let { createPlan } = this.state
        createPlan.push(planItem)
        this.setState({
            createPlan,
            addPlanMsg: false,
            addSavePlan: true,
            fetchTable: true
        })
    }

    deletePlan = (idx) => {
        const { createPlan } = this.state

        if (idx >= 0) {
            createPlan.splice(idx, 1)
        }

        this.setState({
            createPlan
        })
        if (createPlan.length === 0) {
            this.setState({
                addSavePlan: false,
                addPlanMsg: true
            })
        }
    }

    handleChange = (name, value, id) => {
        const { createPlan } = this.state
        createPlan.map((item, index) => {
            if (index === id) {
                item[name] = value
                // return item
            }
            return item
        })
        this.setState({
            createPlan
        })
    }

    handleSelectCurrencyChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    handleSelectChange = (name, value, index) => {
        const { createPlan } = this.state
        createPlan[index][name] = value
        this.setState({
            createPlan
        })
    }

    handlePlanCreate = async () => {
        const { createPlan, currency } = this.state

        const { isEdit, editableData, handleClose } = this.props
        let payload = createPlan
        payload.map((i) => {
            i.currency = currency
            return i
        })

        if (isEdit) {
            payload.id = editableData.id
        }

        if (this.checkProperties(payload)) {
            const response = await this.props.createPlans(payload)
            if (get(response, "status")) {
                toast.success(get(response, "message"))
                this.resetForm()
                handleClose()
            } else {
                toast.error(get(response, "message"))
            }
        } else {
            toast.error("Form incomplete")
        }
    }

    handleEditPlan = async () => {
        const { createPlan, currency } = this.state

        const { isEdit, editableData, handleClose } = this.props
        let payload = createPlan
        payload.map((i) => {
            i.currency = currency
            return i
        })

        let description
        payload.map((i) => {
            description = i.description
            return i
        })

        if (isEdit) {
            payload.id = editableData.id
        }

        const response = await this.props.updatePlans(editableData.id, description)
        if (get(response, "status")) {
            toast.success(get(response, "message"))
            this.resetForm()
            handleClose()
        } else {
            toast.error(get(response, "message"))
        }
    }

    resetForm = () => {
        this.setState({
            createPlan: [],
            addPlanMsg: true,
            addSavePlan: false,
            fetchTable: false
        })
    }

    findDefaultOption = (list, item) => {
        var defaultOption = list.find((ele) => {
            if (ele.id === item) {
                return ele
            }
            return false
        })

        return defaultOption
    }

    render() {
        const { createPlan, addSavePlan } = this.state
        const { isEdit, handleClose } = this.props
        return (
            <div className="vd-custom-management">
                <div className="vr-content-holder plan-container">
                    <div className="vr-cont-head">
                        <div className="vr-cont-title plan-header">
                            <div className="currency">
                                <label>
                                    CURRENCY <span>*</span>
                                </label>
                                <Select
                                    name={"currency"}
                                    // value={timeUnit}
                                    placeholder={"Currency"}
                                    options={currencylist.map((i) => {
                                        return { id: i.id, name: i.name }
                                    })}
                                    value={this.findDefaultOption(
                                        currencylist,
                                        this.state.currency
                                    )}
                                    changeHandler={this.handleSelectCurrencyChange}
                                    required={true}
                                    isDisabled={isEdit ? true : false}
                                    cName={isEdit ? "textDisable" : null}
                                />
                            </div>

                            {isEdit ? null : (
                                <Button
                                    cName="btn primary-btn"
                                    bValue={BUTTON_CONSTANTS?.ADD_NEW_PLAN}
                                    bType="button"
                                    clickHandler={this.handleAddNewPlan}
                                    disabled={!(createPlan.length < 3)}
                                />
                            )}
                        </div>
                    </div>

                    <table className="table plan-table">
                        <tbody className="parameter-tbody">
                            <tr>
                                <th>PARAMETER</th>
                            </tr>

                            <tr>
                                <th>
                                    PRODUCT NAME<span>*</span>
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    PLAN NAME<span>*</span>
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    AMOUNT<span>*</span>
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    <p>
                                        {" "}
                                        BILLING PERIOD <span>*</span>
                                    </p>
                                </th>
                            </tr>

                            <tr>
                                <th>
                                    FREE TRIAL<span>*</span>
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    TRIAL TIME UNIT<span>*</span>
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    DESCRIPTION<span>*</span>
                                </th>
                            </tr>
                        </tbody>

                        {createPlan.map((item, index) => (
                            <tbody className="plan-list" id={item}>
                                <tr>
                                    <th>
                                        <div className="plan-name">
                                            <p>PLAN {index + 1} </p>
                                            <p>
                                                <i
                                                    className="icon-del"
                                                    onClick={() => this.deletePlan(index)}
                                                ></i>
                                            </p>
                                        </div>
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        <Input
                                            type="text"
                                            changeHandler={(name, value) =>
                                                this.handleChange(name, value, index)
                                            }
                                            name={`productName`}
                                            id="productName"
                                            value={item.productName}
                                            placeholder="Product Name"
                                            disabled={isEdit ? true : false}
                                            cName={isEdit ? "textDisable" : null}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Input
                                            type="text"
                                            changeHandler={(name, value) =>
                                                this.handleChange(name, value, index)
                                            }
                                            name={`planName`}
                                            id="planName"
                                            value={item.planName}
                                            placeholder="Plan Name"
                                            disabled={isEdit ? true : false}
                                            cName={isEdit ? "textDisable" : null}
                                            title="No special symbol allowed"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Input
                                            type="number"
                                            changeHandler={(name, value) =>
                                                this.handleChange(name, value, index)
                                            }
                                            name={`amount`}
                                            id="amount"
                                            value={item.amount}
                                            placeholder="Amount"
                                            disabled={isEdit ? true : false}
                                            cName={isEdit ? "textDisable" : null}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <td className="timeSelect">
                                        <Select
                                            name={`billingPeriod`}
                                            placeholder={"Billing Period"}
                                            changeHandler={(name, value) =>
                                                this.handleSelectChange(name, value, index)
                                            }
                                            options={billingPeriodlists.map((i) => {
                                                return { id: i.id, name: i.name }
                                            })}
                                            value={this.findDefaultOption(
                                                billingPeriodlists,
                                                item.billingPeriod
                                            )}
                                            isDisabled={isEdit ? true : false}
                                            cName={isEdit ? "textDisable" : null}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <Input
                                            type="number"
                                            changeHandler={(name, value) =>
                                                this.handleChange(name, value, index)
                                            }
                                            name={`trialLength`}
                                            id="trialLength"
                                            value={item.trialLength}
                                            placeholder="Free Trail"
                                            disabled={isEdit ? true : false}
                                            cName={isEdit ? "textDisable" : null}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="timeSelect">
                                        <Select
                                            disabled={true}
                                            name={`trialTimeUnit`}
                                            placeholder={"Trail Time"}
                                            options={timeUnitlist.map((i) => {
                                                return { id: i.id, name: i.name }
                                            })}
                                            changeHandler={(name, value) =>
                                                this.handleSelectChange(name, value, index)
                                            }
                                            value={this.findDefaultOption(
                                                timeUnitlist,
                                                item.trialTimeUnit
                                            )}
                                            isDisabled={isEdit ? true : false}
                                            cName={isEdit ? "textDisable" : null}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <TextArea
                                            rows={4}
                                            changeHandler={(name, value) =>
                                                this.handleChange(name, value, index)
                                            }
                                            name={`description`}
                                            id="description"
                                            value={item.description}
                                            placeholder="Description"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        ))}
                    </table>

                    {/* {addPlanMsg && <p className="plan-icon">Add Plans<i className="icon-texonomy"></i></p>} */}

                    <div className="btn-footer">
                        <Button
                            bValue={
                                isEdit
                                    ? BUTTON_CONSTANTS?.UPDATE?.toUpperCase()
                                    : BUTTON_CONSTANTS?.SAVE?.toUpperCase()
                            }
                            disabled={isEdit ? addSavePlan : !addSavePlan}
                            clickHandler={!isEdit ? this.handlePlanCreate : this.handleEditPlan}
                            cName="btn primary-btn"
                        />
                        <Button
                            bValue={BUTTON_CONSTANTS?.CANCEL?.toUpperCase()}
                            clickHandler={handleClose}
                            cName="btn cancel-btn"
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        create_Plan: get(state.create_Plan, "create_Plan", [])
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                createPlans,
                fetchCreatedPlans,
                softDeleteCreatePLan,
                editCreatePlan,
                updatePlans
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewPlan)
