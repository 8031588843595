import React, { useEffect, useRef } from "react"

import Pagination from "../../../common/Pagination"
import {
    MODAL_ACTION,
    PROTOCOL,
    PaginationOptions,
    TRANSCODED_CONTENT_HEADER,
    TRANSCODING_TAB
} from "../../api/constant"
import Loader from "../../../../assets/images/loader.gif"
import tripleDot from "../../../../assets/images/tripleDot.svg"
import GreenTick from "../../../../assets/images/greenTick.svg"
import deleteIcon from "../../../../assets/images/delete.svg"
import fileIcon from "../../../../assets/images/fileIcon.svg"
import playURL from "../../../../assets/images/play_url.svg"

import EmptyContent from "../EmptyContent"
import { BUTTON_CONSTANTS, ERROR_MESSAGES, TEXT_CONSTANTS } from "../../../../utils/constants"
import { useHistory } from "react-router-dom"

import "./style.scss"
import { ProgressBar } from "../../../common/StripedProgressBar"
import { useState } from "react"
import CustomModal from "../../../common/Modal"
import DRMTrancodedVideo from "./DRMTrancodedVideo"
import { toast } from "react-toastify"
import PipelineModal from "../PipelineModal"
import { getBrowserType, isClickedOutside } from "../../../../utils/common"

export default function TranscodedContent({
    isLoading,
    transcodedContentData,
    paginationData,
    filterOptions,
    fetchData,
    updateFilterOptions,
    updatePaginationData,
    deleteSelectedHandler,
    cancelSelectedTranscodingHandler
}) {
    const [showActionItem, setShowActionItem] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [modalProperties, setModalProperties] = useState({
        currentActiveModal: null,
        selectedObj: {}
    })

    const history = useHistory()

    const actionItemRef = useRef(null)

    useEffect(() => {
        const handleClickOutside = (event) => {
            showActionItem &&
                !showModal &&
                isClickedOutside(event?.target, actionItemRef?.current) &&
                setShowActionItem("")
        }
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [showActionItem, showModal])

    useEffect(() => {
        fetchData(filterOptions, TRANSCODING_TAB?.Transcoded_TAB)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const intervalCall = setInterval(() => {
            fetchData(filterOptions, TRANSCODING_TAB?.Transcoded_TAB)
        }, 30000)
        return () => {
            // clean up
            clearInterval(intervalCall)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterOptions])

    const onPageChange = (e) => {
        const filterOptionsCopy = { ...filterOptions }
        const selectedPage = e.selected
        filterOptionsCopy.start = selectedPage + 1
        let startValue = (filterOptionsCopy["start"] - 1) * filterOptionsCopy["length"]

        let paginationDataCopy = { ...paginationData }
        paginationDataCopy.currentPage = selectedPage
        paginationDataCopy.start = startValue
        updatePaginationData(paginationDataCopy)

        filterOptionsCopy.start = startValue
        fetchData(filterOptionsCopy, TRANSCODING_TAB?.Transcoded_TAB)
        updateFilterOptions(filterOptionsCopy)
    }
    const showPerPageChange = (name, value) => {
        const filterOptionsCopy = { ...filterOptions }
        filterOptionsCopy["length"] = value
        filterOptionsCopy["start"] = 0

        let defaultOption = PaginationOptions.filter((item) => {
            if (value === item.id) {
                return item
            }
            return false
        })

        let paginationDataCopy = { ...paginationData }
        paginationDataCopy.selectedOption = defaultOption
        paginationDataCopy.start = 0
        paginationDataCopy.pageCount = 0
        paginationDataCopy.currentPage = 0
        updatePaginationData(paginationDataCopy)

        filterOptionsCopy.length = defaultOption[0]?.id
        updateFilterOptions(filterOptionsCopy)
        fetchData(filterOptionsCopy, TRANSCODING_TAB?.Transcoded_TAB)
    }

    const sortBy = (item) => {
        let sortBy = item
        let filterOptionsCopy = { ...filterOptions }
        filterOptionsCopy["sortBy"] = sortBy
        filterOptionsCopy["sortOrder"] = filterOptions["sortOrder"] === "desc" ? "asc" : "desc"
        fetchData(filterOptionsCopy, TRANSCODING_TAB?.Transcoded_TAB)
        updateFilterOptions(filterOptionsCopy)
    }

    const initiateModal = (val, selectedModal) => {
        setShowModal(true)
        setModalProperties({
            currentActiveModal: selectedModal,
            selectedObj: val
        })
    }

    const actionItemHandler = (selectedItem) => {
        if (selectedItem === showActionItem) {
            setShowActionItem("")
            return
        }
        setShowActionItem(selectedItem)
    }

    const copyToCliboard = (value) => {
        if (value) {
            navigator.clipboard.writeText(value)
            toast.success("Text copied!")
        }
    }

    const isURLPlayable = (protocol, isDRMEnabled) => {
        // DRM -> Yes HLS url only playable in safari
        if (getBrowserType() === "Apple Safari") {
            if (protocol === PROTOCOL.DASH) {
                return { status: false, errorMsg: ERROR_MESSAGES?.DASH_ERROR_SAFARI }
            }
            return { status: true, errorMsg: "" }
        } else {
            if (protocol === PROTOCOL.HLS && isDRMEnabled) {
                return { status: false, errorMsg: ERROR_MESSAGES?.HLS_ERROR }
            }
            return { status: true, errorMsg: "" }
        }
    }

    const showPlayURLStatus = (row, isValidUrl, isUrlPlayerInBrowser) => {
        if (isValidUrl) {
            if (isUrlPlayerInBrowser?.status) {
                return initiateModal(row, MODAL_ACTION?.PLAY_URL)
            } else {
                toast.error(isUrlPlayerInBrowser?.errorMsg)
            }
        }
        return false
    }

    const renderTableData = () => {
        const renderTableHeader = (item) => {
            return (
                <p>
                    <span>{item.header}</span>{" "}
                    {item?.isSortEnabled && (
                        <span onClick={() => sortBy(item?.value)} className="triangle"></span>
                    )}
                </p>
            )
        }

        const tableHeading = TRANSCODED_CONTENT_HEADER.map((item, index) => {
            return <th key={index}>{renderTableHeader(item)}</th>
        })

        const renderStatusBar = (status, progress, statusForUI) => {
            switch (status) {
                case "MEDIA_CONVERT_JOB_COMPLETED_EVENT":
                    return (
                        <div className="transcoding-completed">
                            <img src={GreenTick} alt={"successIcon"}></img>
                            <p>Completed</p>
                        </div>
                    )
                case "MEDIA_CONVERT_JOB_IN_PROGRESS_EVENT":
                    return <ProgressBar progress={progress} id={`transcoded-bar`} failed={false} />
                default:
                    return <p>{statusForUI}</p>
            }
        }

        const tableRows = transcodedContentData.map((row) => {
            const isUrlPlayerInBrowser = isURLPlayable(row?.protocol, row?.isDRMEnabled)
            return (
                <tr id={row?.jobId} key={row?.jobId} className="">
                    <td>
                        <div className="tooltip-container">
                            <p className="tooltip-container-show hide-show">{row?.fileName}</p>
                            <div className="tooltip-container-innerText">
                                <p className="transcoding-play-url">
                                    {row?.fileName ? row?.fileName : "-"}
                                </p>
                            </div>
                        </div>
                    </td>
                    <td>{row?.contentType ? row?.contentType : "-"}</td>
                    <td>{row?.protocol}</td>
                    <td>{row?.isDRMEnabled ? "Yes" : "No"}</td>
                    <td>{row?.totalSize}</td>
                    <td>
                        {renderStatusBar(row?.status, row?.progressPercentage, row?.statusForUI)}
                    </td>
                    <td>{row?.uploadedDate}</td>
                    <td>{row?.uploadedBy ? row?.uploadedBy : "-"}</td>
                    <td>
                        <div className="tooltip-container">
                            <p
                                className="tooltip-container-show pointerCursor"
                                onClick={() => {
                                    copyToCliboard(row?.assetId)
                                }}
                            >
                                {TEXT_CONSTANTS?.COPY_TO_CLIPBOARD}{" "}
                                <i className="icon-duplicate"></i>
                            </p>
                            <div className="tooltip-container-innerText">
                                <p className="transcoding-play-url">
                                    {row?.assetId ? row?.assetId : "-"}
                                </p>
                            </div>
                        </div>
                    </td>

                    <td>
                        <div className="tooltip-container">
                            {row?.playURL && (
                                <p
                                    className="tooltip-container-show pointerCursor"
                                    onClick={() => {
                                        copyToCliboard(row?.playURL)
                                    }}
                                >
                                    {TEXT_CONSTANTS?.COPY_TO_CLIPBOARD}{" "}
                                    <i className="icon-duplicate"></i>
                                </p>
                            )}
                            <div className="tooltip-container-innerText">
                                <p className="transcoding-play-url transcoding-height">
                                    {row?.playURL ? row?.playURL : "-"}
                                </p>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className="tooltip-container">
                            {row?.mediaTailorSuffix && (
                                <p
                                    className="tooltip-container-show pointerCursor"
                                    onClick={() => {
                                        copyToCliboard(row?.mediaTailorSuffix)
                                    }}
                                >
                                    {TEXT_CONSTANTS?.COPY_TO_CLIPBOARD}{" "}
                                    <i className="icon-duplicate"></i>
                                </p>
                            )}
                            <div className="tooltip-container-innerText">
                                <p className="transcoding-play-url transcoding-height">
                                    {row?.mediaTailorSuffix ? row?.mediaTailorSuffix : "N/A"}
                                </p>
                            </div>
                        </div>
                    </td>
                    <td className="btn-popupContainer">
                        <div className="popup-items" ref={actionItemRef}>
                            <img
                                src={tripleDot}
                                alt="additional-item-icon"
                                onClick={() => {
                                    actionItemHandler(row?.jobId)
                                }}
                            />
                            {showActionItem === row?.jobId && (
                                <div className="additional-items">
                                    <div
                                        className={`items-container ${
                                            row?.playURL ? "" : "disabled"
                                        }`}
                                        onClick={() => {
                                            showPlayURLStatus(
                                                row,
                                                row?.playURL,
                                                isUrlPlayerInBrowser
                                            )
                                        }}
                                    >
                                        <div className="image-container">
                                            <img src={playURL} alt="play-url-icon" />
                                        </div>
                                        <p>{BUTTON_CONSTANTS?.PLAY_URL}</p>
                                    </div>
                                    <div
                                        className="items-container"
                                        onClick={() => {
                                            initiateModal(row, MODAL_ACTION?.DELETE_MODAL)
                                        }}
                                    >
                                        <div className="image-container">
                                            <img src={deleteIcon} alt="deleteIcon-icon" />
                                        </div>
                                        <p>{BUTTON_CONSTANTS?.DELETE}</p>
                                    </div>
                                    <div
                                        className="items-container"
                                        onClick={() => {
                                            initiateModal(row, MODAL_ACTION?.VIEW)
                                        }}
                                    >
                                        <div className="image-container">
                                            <img src={fileIcon} alt="deleteIcon-icon" />
                                        </div>
                                        <p>{BUTTON_CONSTANTS?.VIEW_SETTINGS}</p>
                                    </div>
                                    {row?.status === "MEDIA_CONVERT_JOB_IN_PROGRESS_EVENT" && (
                                        <div
                                            className="items-container"
                                            onClick={() => {
                                                initiateModal(row, MODAL_ACTION?.CANCEL_MODAL)
                                            }}
                                        >
                                            <div className="image-container">
                                                <img src={fileIcon} alt="Cancel-icon" />
                                            </div>
                                            <p>{BUTTON_CONSTANTS?.CANCEL}</p>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </td>
                </tr>
            )
        })

        return (
            <table className={`table`}>
                <thead>
                    <tr>{tableHeading}</tr>
                </thead>
                <tbody>{tableRows}</tbody>
            </table>
        )
    }

    const displayLoader = () => {
        return (
            <div className="loader">
                <img src={Loader} alt="loader" width={"100px"} />
            </div>
        )
    }

    const resetOnModalClose = () => {
        setModalProperties({
            currentActiveModal: null,
            selectedObj: {}
        })
        setShowModal(false)
    }

    const submitModalHandler = () => {
        if (modalProperties?.currentActiveModal === MODAL_ACTION?.DELETE_MODAL) {
            deleteSelectedHandler(modalProperties?.selectedObj, TRANSCODING_TAB?.Transcoded_TAB)
        } else {
            cancelSelectedTranscodingHandler(
                modalProperties?.selectedObj,
                TRANSCODING_TAB?.Transcoded_TAB
            )
        }
        resetOnModalClose()
    }

    const cancelBtnHandler = () => {
        resetOnModalClose()
    }

    const showSubmitButton = () => {
        if (
            modalProperties?.currentActiveModal === MODAL_ACTION?.DELETE_MODAL ||
            modalProperties?.currentActiveModal === MODAL_ACTION?.CANCEL_MODAL
        ) {
            return true
        }
        return false
    }

    const addNewButtonHandler = () => {
        history.push("/add-video-content")
    }

    return (
        <div className="vr-transcode-content-wrapper vr-upload-content-wrapper">
            {isLoading && displayLoader()}
            <div className="tenant-list-container vr-content-holder transcoded-list-container">
                {!isLoading ? (
                    transcodedContentData?.length > 0 ? (
                        renderTableData()
                    ) : (
                        <EmptyContent
                            errorMsg={ERROR_MESSAGES?.TRANSCODING_ERROR}
                            addNewButtonHandler={addNewButtonHandler}
                        ></EmptyContent>
                    )
                ) : (
                    <></>
                )}
            </div>
            <Pagination
                changeHandler={showPerPageChange}
                options={PaginationOptions}
                pageCount={paginationData?.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={onPageChange}
                selectedOption={paginationData?.selectedOption}
                currentPage={paginationData?.currentPage}
            />
            <CustomModal
                showModal={showModal}
                cancelHandler={cancelBtnHandler}
                submitHandler={submitModalHandler}
                onClickCancelBtn={cancelBtnHandler}
                showCloseIcon={true}
                submitButtonLabel={BUTTON_CONSTANTS?.YES}
                showCancelButton={showSubmitButton()}
                showSubmitButton={showSubmitButton()}
                closeOnSubmit={false}
                cancelOnOutsideClick={false}
                modalContainerClass={
                    modalProperties?.currentActiveModal === MODAL_ACTION?.PLAY_URL
                        ? "transcoded-modal-container"
                        : modalProperties?.currentActiveModal === MODAL_ACTION?.VIEW
                        ? "view-modal-container"
                        : ""
                }
            >
                {DisplayModalContent({ modalProperties })}
            </CustomModal>
        </div>
    )
}

const DisplayModalContent = ({ modalProperties }) => {
    switch (modalProperties?.currentActiveModal) {
        case MODAL_ACTION?.DELETE_MODAL:
            return <DeleteModalContent modalProperties={modalProperties} />
        case MODAL_ACTION?.PLAY_URL:
            return <VideoModal modalProperties={modalProperties} />
        case MODAL_ACTION?.VIEW:
            return <PipelineModal modalProperties={modalProperties} />
        case MODAL_ACTION?.CANCEL_MODAL:
            return <CancelModalContent modalProperties={modalProperties} />
        default:
            return null
    }
}

const DeleteModalContent = ({ modalProperties }) => {
    return (
        <div className="delete-modal-container">
            <h3>
                {`${TEXT_CONSTANTS?.DELETE_TRANSCODED_CONTENT}: `}
                <b>{modalProperties?.selectedObj?.fileName}</b>
            </h3>
        </div>
    )
}

const CancelModalContent = ({ modalProperties }) => {
    return (
        <div className="delete-modal-container">
            <h3>
                {`${TEXT_CONSTANTS?.CANCEL_TRANSCODED_CONTENT}: `}
                <b>{modalProperties?.selectedObj?.fileName}</b>
            </h3>
        </div>
    )
}

const VideoModal = ({ modalProperties }) => {
    const selectedObj = modalProperties?.selectedObj

    return (
        <DRMTrancodedVideo
            selectedObj={selectedObj}
            src={selectedObj?.playURL ? selectedObj?.playURL : ""}
        ></DRMTrancodedVideo>
    )
}
