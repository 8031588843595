import React, { Component } from "react"
import { bindActionCreators } from "redux"
import Button from "../../common/Button"
import { connect } from "react-redux"
import { imgPathPrefix } from "../api/constant"
import { softDeleteEpgShow } from "../api/action"
import {
    getDefaultMultilingualValue,
    getGMTTimezone,
    getTimeBasedOnTimezoneString
} from "../../../utils/common"
import { PERMISSIONS, TEXT_CONSTANTS } from "../../../utils/constants"
import { AuthWrapper } from "../../common/AuthWrapper"
import "./style.scss"

class ShowDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            toShow: false
        }
    }

    showMoretoggle = () => {
        this.setState({ isOpen: !this.state.isOpen })
    }

    showMore = () => {
        let { isOpen } = this.state
        let { showDesc } = this.props
        if (showDesc === null) {
            showDesc = ""
        }

        if (isOpen) {
            return showDesc
        }
        if (showDesc.length > 153) {
            return showDesc.slice(0, 153) + "..."
        } else {
            return showDesc.slice(0, 153)
        }
    }

    getCommaSeperatedList = (data) => {
        let list = []
        data && data.map((item) => list.push(item.name))
        return list.join(", ")
    }

    handleDeleteEpgShow = async (itemToBeDeleted) => {
        // const { handleDeleteEpgShow, onDeleteShow } = this.props;
        // await handleDeleteEpgShow(itemToBeDeleted.id);
        // onDeleteShow();
        const { epgDeleteHandler } = this.props
        epgDeleteHandler(itemToBeDeleted)
    }

    getCastInformation = (optionsList, selectedOptions) => {
        return optionsList
            ?.filter((item) => selectedOptions?.includes(item?.id))
            ?.map((i) => <span>{i.name} </span>)
    }

    render() {
        const {
            TVshowData,
            date,
            handleEditClick,
            TVshow,
            showTVdata,
            directorList,
            actorsList,
            selectedTimezoneLabel
        } = this.props

        // let beforeDate = moment(date, "YYYY/MM/DD").isBefore(
        //   moment(new Date()).format("YYYY/MM/DD")
        // );
        const globalCurrentTimezoneString = getGMTTimezone(selectedTimezoneLabel?.timeZone, 0)
        let startTime = TVshowData?.startTime
            ? getTimeBasedOnTimezoneString(TVshowData?.startTime, globalCurrentTimezoneString)
            : ""
        let endTime = TVshowData?.endTime
            ? getTimeBasedOnTimezoneString(TVshowData?.endTime, globalCurrentTimezoneString)
            : ""

        return (
            <>
                {TVshow && (
                    <>
                        {" "}
                        <div className="showImg">
                            <img src={`${TVshowData.coverImage}`} alt="" />
                            {/* <img src={`${imgPathPrefix}${TVshowData.coverImage}`} alt="" /> */}
                        </div>
                        <h3>{TVshowData?.title}</h3>
                        {/* <h3>{getDefaultMultilingualValue(TVshowData?.title)}</h3> */}
                        <>
                            <div className="details-wrapper">
                                <span className="time">
                                    {startTime} - {endTime}
                                </span>
                                <p className="desc">
                                    {TVshowData?.description}
                                    {/* {getDefaultMultilingualValue(TVshowData?.description)} */}
                                </p>
                                <table className="subDetails">
                                    <tbody>
                                        <tr>
                                            <td>{TEXT_CONSTANTS?.CAST?.toLowerCase()}</td>

                                            {TVshowData &&
                                                TVshowData.actors &&
                                                this.getCastInformation(
                                                    actorsList,
                                                    TVshowData?.actors
                                                )}
                                        </tr>
                                        <tr>
                                            <td>{TEXT_CONSTANTS?.DIRECTOR?.toLowerCase()}</td>
                                            <td>
                                                {TVshowData &&
                                                    TVshowData?.directors &&
                                                    this.getCastInformation(
                                                        directorList,
                                                        TVshowData?.directors
                                                    )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{TEXT_CONSTANTS?.CATCH_UP?.toLowerCase()}</td>
                                            <td>{TVshowData.catchupAvailable ? "Yes" : "No"}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="btn-popupContainer">
                                <Button
                                    cName="icon-edit"
                                    bType="button"
                                    clickHandler={() => handleEditClick(TVshowData, date)}
                                    // disabled={moment(TVshowData?.startTime).isBefore(+new Date())}
                                />

                                <AuthWrapper permissions={[PERMISSIONS?.EPG_EDIT]}>
                                    <Button
                                        cName="icon-del"
                                        bType="button"
                                        clickHandler={() => this.handleDeleteEpgShow(TVshowData)}
                                        // disabled={moment(TVshowData?.startTime).isBefore(
                                        //   +new Date()
                                        // )}
                                    />
                                </AuthWrapper>
                            </div>
                            {showTVdata && (
                                <div className="details-wrapper">
                                    <span className="time">
                                        {TEXT_CONSTANTS?.TIME?.toLowerCase()}
                                    </span>
                                    <p className="desc">
                                        {TEXT_CONSTANTS?.DESCRIPTION?.toLowerCase()}
                                    </p>
                                    <table className="subDetails">
                                        <tbody>
                                            <tr>
                                                <td>{TEXT_CONSTANTS?.CAST?.toLowerCase()}</td>
                                            </tr>
                                            <tr>
                                                <td>{TEXT_CONSTANTS?.DIRECTOR?.toLowerCase()}</td>
                                            </tr>
                                            <tr>
                                                <td>{TEXT_CONSTANTS?.CATCH_UP?.toLowerCase()}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </>
                    </>
                )}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        selectedTimezoneLabel: state?.timezone?.selectedTimezone
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                softDeleteEpgShow
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowDetails)
