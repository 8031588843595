export const ACTION = {
    FETCH_ALL_DATA: "FETCH_ALL_DATA",
    SAVE_SELECTED_DATA: "SAVE_SELECTED_DATA"
}

export const CURRENT_SCREEN_INDEX = {
    emptyScreenIndex: 1,
    moviesScreenIndex: 2,
    successScreenIndex: 3
}

export const FORM_DATA_CONSTANTS = {
    selectAllCheckbox: "selectAllCheckbox",
    selectPlanError: "Please select atleast one plan",
    selectFilterOption: "Please select atleast one filter",
    unpublishedContent: "+ Unpublished Content",
    freeForAllSeason: "freeForAllSeason"
}

export const CONTENT_TAB_TYPES = {
    MOVIES: "Movies_1",
    WEBSERIES: "Webseries_1"
}

// Payload structure for getting the movies data
export const initialStateForContent = {
    filters: {
        active: [true] //  True = For published data;  False = For unpublished data; Remove active key for both data
    },
    rangeFilter: {},
    sortBy: "title",
    sortOrder: "ASC",
    start: 0,
    length: 20
}

export const SORT_LIST = [
    { id: 1, title: "Alphabetical (A-Z)", name: "title", order: "ASC" },
    { id: 2, title: "Alphabetical (Z-A)", name: "title", order: "DESC" },
    {
        id: 3,
        title: "Content Added (Recent)",
        name: "dateCreated ",
        order: "ASC"
    },
    {
        id: 4,
        title: "Content Added (Oldest)",
        name: "dateCreated ",
        order: "DESC"
    },
    {
        id: 5,
        title: "Release Date (Descending)",
        name: "releaseDate",
        order: "DESC"
    },
    {
        id: 6,
        title: "Release Date (Ascending) ",
        name: "releaseDate",
        order: "ASC"
    }
]
