export const ProductMenu = [
    { key: "SR", value: "serialNumber" },
    { key: "Plan", value: "planName" },
    // { key: "Template", value: "template" },
    // { key: "Category", value: "category" },
    { key: "price", value: "price" },
    { key: "Plan Description", value: "plandescription" },
    // { key: "status", value: "status" },
    // { key: "Effective From - To", value: "effectiveDate" },
    { key: "Action", value: "actions" }
    /*   { key: "Payment Due Date" }, */
]
