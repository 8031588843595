import ApiService from "../../../utils/apiService"
import { API_ROOT_PATH, API_TENANT_ROOT_PATH } from "../../../config/apiPath"

class LoginService {
    login(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/signIn`,
            data: params,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    logout() {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/logout`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    forgotPassword(params) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/forgetPassword?email=${params}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    resetPassword(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/resetPassword`,
            data: params
        }
        return ApiService(options)
    }

    validateToken(token) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/resetToken/validate?resetToken=${token}`
        }
        return ApiService(options)
    }

    extendTrail(params) {
        const options = {
            method: "POST",
            url: `${API_TENANT_ROOT_PATH}/multitenancy/diy/updateSubscription`,
            data: params
        }
        return ApiService(options)
    }

    sendLogoutToken() {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/logout`,
            isAuthRequired: true
        }
        return ApiService(options)
    } // vapt logout api implementation requirement
}

const LoginServiceInstance = new LoginService()
export default LoginServiceInstance
