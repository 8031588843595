import React from "react"

import "./style.scss"

const Notification = () => {
    const isNotification = true
    return (
        <div className="vr-notification">
            {isNotification && <span className="new-notification" />}
            <i className="icon-notification">
                <span className="iconTooltiptext">Under Development</span>
            </i>
        </div>
    )
}

export default Notification
