import Dashboard from "../components/Dashboard"
import StatsDashboard from "../components/StatsDashboard"
import Tenant from "../components/AdminComponent/Tenant/TenantList"
import AddEditTenant from "../components/AdminComponent/Tenant/AddEditTenant"
import SuperAdminManagement from "../components/AdminComponent/SuperAdminManagement"
// import Monetize from "../components/Monetize";
import ManagementUsers from "../components/UserManagement/Users"
import ManagementRoles from "../components/UserManagement/Roles"
import ContentNav from "../components/ContentNav"
import Category from "../components/Category"
import MyProfile from "../components/UserPanel/MyProfile"
// import WebSeries from "../components/WebSeries";
import CustomerBranding from "../components/CustomerBranding"
import CustomPages from "../components/CustomPages"
import MenuDesign from "../components/MenuDesign"
import EpgUpdate from "../components/EPGUpdate/index"
import FormBuilder from "../components/formBuilder"
import CreatePlan from "../components/Subscription/CreatePlan"
import Invoice from "../components/Subscription/SubscriptionDetail"
import TotalSubscriber from "../components/Subscription/Subscriber"
import ContentEnrichment from "../components/Content Enrichment"
import Multilingual from "../components/Mulltilingual"
import { PERMISSIONS } from "./constants"
import ProductPlacement from "../components/Monetize/ProductPlacement"
import RoiMaximiser from "../components/Monetize/RoiMaximiser"
import ContentPlan from "../components/ContentPlan"
import NotFound404 from "../components/common/NotFound"
import LoginPanel from "../components/LoginPanel"
import DrmConfiguration from "../components/DrmConfiguration"
import MagnaQuest from "../components/MagnaQuest"
import AddCoupon from "../components/MagnaQuest/AddCoupon"
import PlansList from "../components/MagnaQuest/PlansList"
import BasicConfiguration from "../components/BasicConfiguration"
import AddContentPipeline from "../components/ContentPipeline/AddContentPipeline"
import Transcoding from "../components/ContentPipeline/AddContentPipeline/Transcoding"
import VideoPipeline from "../components/ContentPipeline/ListingVideoPipeline"
import { WebSeriesContentContainer } from "../components/Category/WebSeriesContentContainer"
import MetadataIngestion from "../components/MetadataIngestion"
import ForceUpgrade from "../components/ForceUpgradeApplication"
import { isMultiTenant } from "./common"
import Timezone from "../components/Timezone"
import SportsSetup from "../components/Sports/SportsSetup"
import CreateManagePage from "../components/Sports/CreateManagePage"
import SportsHome from "../components/Sports/SportsHome"
import VODCategory from "../components/Category/VODCategory"
import AuditTrail from "../components/AuditTrail"
import Footer from "../components/Footer"
import Environments from "../components/common/Environments"
import Platforms from "../components/common/Platforms"
import PlatformConfig from "../components/PlatformConfig"
import AddTranslation from "../components/AddTranslation"

export const URL = {
    DASHBOARD: "dashboard",
    TENANT: "tenant",
    MONETIZE: "monetize",
    PRODUCT_PLACEMENT: "placement",
    MANAGEMENT: "management",
    CONTENT: "content",
    UX_MANGER: "ux-manager",
    STREAM: "stream",
    ENGAGE: "engage",
    TEXONOMY: "texonomy",
    SETTINGS: "settings",
    MOVIES: "movies",
    WEBSERIES: "webseries",
    LIVETV: "livetv",
    CATEGORY: "category",
    MENU_DESIGN: "menudesign",
    MANAGEMENT_USERS: "users",
    MANAGEMENT_ROLES: "roles",
    MYPROFILE: "myprofile",
    CUSTOMER_BRANDING: "customerBranding",
    CUSTOM_PAGES: "custompages",
    EPG: "epg",
    FORM_BUILDER: "formBuilder",
    SUBSCRIPTION: "subscription",
    CREATE_PLAN: "createPlan",
    INVOICE: "invoice",
    SUBSCRIBERS: "subscribers",
    ADD_TENANT: "add_tenant",
    ADMIN_MANAGEMENT: "admin-management",
    CONTENTENRICHMENT: "contentenrichment",
    MULTILINGUAL: "multilingual",
    ADDTRANSLATION: "add-translation",
    MAXIMISER: "maximiser",
    MAGNA_QUEST: "magnaquest",
    PRODUCT: "product",
    CONTENT_PRODUCT: "contentproduct",
    COUPONS: "coupons",
    REPORTS: "reports",
    PROMOTION: "promotion",
    ACTIVECUSTOMERS: "active-customers",
    NOTIFICATION_TEMPLATE: "notification-template",
    WORKFLOW: "workflow",
    DESIGNER_DASHBOARD: "dashboard-designer",
    NOTIFICATION: "notifications",
    DRM_CONFIG: "drm-config",
    BASIC_CONFIG: "basic-config",
    VIDEO_PIPELINE: "videoPipeline",
    METADATA_INGESTION: "metadata-ingestion",
    FORCE_UPGRADE: "force-upgrade",
    CONFIG: "config",
    TIMEZONE: "timezone",
    SPORTS: "sports",
    SETUP_SPORTS: "setup-sports",
    CREATE_PAGES: "create-pages",
    SPORTS_HOME: "sports-home",
    AUDIT_TRAIL: "audit-trail",
    MISC_LINKS: "misc-links",
    PLATFORMS: "platforms",
    ENVIRONMENTS: "environments",
    PLATFORM_CONFIG: "platform-config"
}

export const mainRoutes = [
    {
        path: `/`,
        component: Dashboard,
        exact: true,
        permissions: [PERMISSIONS?.DASHBOARD_VIEW, PERMISSIONS?.DASHBOARD_EDIT]
    },
    {
        path: `/dashboard`,
        component: Dashboard,
        exact: true,
        permissions: [PERMISSIONS?.DASHBOARD_VIEW, PERMISSIONS?.DASHBOARD_EDIT]
    },
    {
        path: `/${URL.DASHBOARD}`,
        component: Dashboard,
        permissions: [PERMISSIONS?.DASHBOARD_VIEW, PERMISSIONS?.DASHBOARD_EDIT]
    },
    {
        path: `/${URL.SETTINGS}/${URL.MANAGEMENT}/${URL.MANAGEMENT_USERS}`,
        component: ManagementUsers,
        permissions: [
            PERMISSIONS?.SETTING_USER_MANAGEMENT_USERS_EDIT,
            PERMISSIONS?.SETTING_USER_MANAGEMENT_USERS_VIEW
        ]
    },
    {
        path: `/${URL.SETTINGS}/${URL.MANAGEMENT}/${URL.MANAGEMENT_ROLES}`,
        component: ManagementRoles,
        permissions: [
            PERMISSIONS?.SETTING_USER_MANAGEMENT_ROLES_EDIT,
            PERMISSIONS?.SETTING_USER_MANAGEMENT_ROLES_VIEW
        ]
    },
    {
        path: `/${URL.STREAM}/${URL.CONTENT}/${URL.METADATA_INGESTION}`,
        component: MetadataIngestion,
        permissions: []
    },
    {
        path: `/${URL.STREAM}/${URL.CONTENT}`,
        component: ContentNav,
        permissions: [PERMISSIONS?.CONTENT_EDIT, PERMISSIONS?.CONTENT_VIEW]
    },
    {
        path: `/${URL.ENGAGE}/${URL.UX_MANGER}/${URL.CATEGORY}`,
        component: VODCategory,
        permissions: [
            PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_VIEW,
            PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_EDIT
        ]
    },
    {
        path: `/${URL.MYPROFILE}`,
        component: MyProfile,
        permissions: []
    },
    {
        path: `/${URL.SETTINGS}/${URL.CUSTOMER_BRANDING}`,
        component: CustomerBranding,
        permissions: [PERMISSIONS?.SETTING_CUSTOMER_EDIT, PERMISSIONS?.SETTING_CUSTOMER_VIEW]
    },
    {
        path: `/${URL.ENGAGE}/${URL.CUSTOM_PAGES}`,
        component: CustomPages,
        permissions: [
            PERMISSIONS?.ENGAGE_UX_MANAGER_CUSTOM_PAGES_VIEW,
            PERMISSIONS?.ENGAGE_UX_MANAGER_CUSTOM_PAGES_EDIT
        ]
    },
    {
        path: `/${URL.ENGAGE}/${URL.MENU_DESIGN}`,
        component: MenuDesign,
        permissions: [
            PERMISSIONS?.ENGAGE_UX_MANAGER_MENU_DESIGN_VIEW,
            PERMISSIONS?.ENGAGE_UX_MANAGER_MENU_DESIGN_EDIT
        ]
    },

    {
        path: `/${URL.SETTINGS}/${URL.FORM_BUILDER}`,
        component: FormBuilder,
        permissions: [
            PERMISSIONS?.SETTING_FORM_BUILDER_EDIT,
            PERMISSIONS?.SETTING_FORM_BUILDER_VIEW
        ]
    },
    {
        path: `/${URL.STREAM}/${URL.EPG}`,
        component: EpgUpdate,
        permissions: [PERMISSIONS?.EPG_EDIT, PERMISSIONS?.EPG_VIEW]
    },
    {
        path: `/${URL.SUBSCRIPTION}/${URL.CREATE_PLAN}`,
        component: CreatePlan
    },
    {
        path: `/${URL.SUBSCRIPTION}/${URL.INVOICE}`,
        component: Invoice
    },
    {
        path: `/${URL.SUBSCRIPTION}/${URL.SUBSCRIBERS}`,
        component: TotalSubscriber
    },
    {
        path: `/${URL.SUBSCRIPTION}/${URL.PRODUCT}`,
        component: PlansList
    },
    {
        path: `/${URL.SUBSCRIPTION}/${URL.COUPONS}`,
        component: AddCoupon
    },
    // {
    //   path: `/${URL.SUBSCRIPTION}/${URL.PROMOTION}`,
    //   component: MagnaQuest,
    // },
    {
        path: `/${URL.SUBSCRIPTION}/${URL.DASHBOARD}`,
        component: MagnaQuest
    },

    {
        path: `/${URL.SUBSCRIPTION}/${URL.ACTIVECUSTOMERS}`,
        component: MagnaQuest
    },

    {
        path: `/${URL.SUBSCRIPTION}/${URL.NOTIFICATION_TEMPLATE}`,
        component: MagnaQuest
    },
    {
        path: `/${URL.SUBSCRIPTION}/${URL.NOTIFICATION}`,
        component: MagnaQuest
    },
    {
        path: `/${URL.SUBSCRIPTION}/${URL.REPORTS}`,
        component: MagnaQuest
    },
    {
        path: `/${URL.ENGAGE}/${URL.CONTENTENRICHMENT}`,
        component: ContentEnrichment,
        permissions: [PERMISSIONS?.CONTENT_ENRICHMENT_EDIT, PERMISSIONS?.CONTENT_ENRICHMENT_VIEW]
    },
    {
        path: `/${URL.MONETIZE}/${URL.PRODUCT_PLACEMENT}`,
        component: ProductPlacement,
        permissions: [
            PERMISSIONS?.MONETIZE_PRODUCT_PLACEMENT_EDIT,
            PERMISSIONS?.MONETIZE_PRODUCT_PLACEMENT_VIEW
        ]
    },
    // {
    //   path: `/${URL.ENGAGE}/${URL.CONTENTENRICHMENT}`,
    //   component: ContentEnrichment,
    //   permissions: [
    //     PERMISSIONS?.CONTENT_ENRICHMENT_EDIT,
    //     PERMISSIONS?.CONTENT_ENRICHMENT_VIEW,
    //   ],
    // },
    {
        path: `/${URL.SETTINGS}/${URL.MULTILINGUAL}`,
        component: Multilingual,
        permissions: [
            PERMISSIONS?.SETTING_MULTILINGUAL_EDIT,
            PERMISSIONS?.SETTING_MULTILINGUAL_VIEW
        ]
    },
    {
        path: `/${URL.MONETIZE}/${URL.SUBSCRIPTION}/${URL.PRODUCT}`,
        component: MagnaQuest
    },
    {
        path: `/${URL.SUBSCRIPTION}/${URL.CONTENT_PRODUCT}`,
        component: ContentPlan
    },
    {
        path: `/${URL.SETTINGS}/${URL.DRM_CONFIG}`,
        component: DrmConfiguration,
        permissions: [PERMISSIONS?.DRM_EDIT, PERMISSIONS?.DRM_VIEW]
    },
    {
        path: `/${URL.SETTINGS}/${URL.CONFIG}/${URL.BASIC_CONFIG}`,
        component: BasicConfiguration,
        permissions: [PERMISSIONS?.BASIC_CONFIGURATION_VIEW, PERMISSIONS?.BASIC_CONFIGURATION_EDIT]
    },
    {
        path: `/${URL.STREAM}/${URL.VIDEO_PIPELINE}`,
        component: VideoPipeline,
        permissions: [PERMISSIONS?.VIDEO_PIPELINE_EDIT, PERMISSIONS?.VIDEO_PIPELINE_VIEW]
    },
    {
        path: `/add-video-content`,
        component: AddContentPipeline,
        permissions: [PERMISSIONS?.VIDEO_PIPELINE_EDIT]
    },
    {
        path: `/${URL.SETTINGS}/${URL.CONFIG}/${URL.TIMEZONE}`,
        component: Timezone,
        permissions: [PERMISSIONS?.TIMEZONE_VIEW, PERMISSIONS?.TIMEZONE_EDIT]
    },
    {
        path: `/${URL.ENGAGE}/${URL.SPORTS}/${URL.SETUP_SPORTS}`,
        component: SportsSetup,
        permissions: [
            PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_VIEW,
            PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_EDIT
        ]
    },
    {
        path: `/${URL.ENGAGE}/${URL.SPORTS}/${URL.CREATE_PAGES}`,
        component: CreateManagePage,
        permissions: [
            PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_VIEW,
            PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_EDIT
        ]
    },
    {
        path: `/${URL.ENGAGE}/${URL.SPORTS}/${URL.SPORTS_HOME}`,
        component: SportsHome,
        permissions: [
            PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_VIEW,
            PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_EDIT
        ]
    },
    {
        path: "*",
        component: NotFound404,
        permissions: []
    }
]
const DashboardComponent = isMultiTenant() ? StatsDashboard : Dashboard
export const adminRoutes = [
    {
        path: `/`,
        component: DashboardComponent,
        exact: true,
        permissions: [PERMISSIONS?.DASHBOARD_VIEW, PERMISSIONS?.DASHBOARD_EDIT]
    },
    {
        path: `/webcontent`,
        component: WebSeriesContentContainer,
        exact: true,
        permissions: []
    },
    /*  {
    path: `/magnaquest`,
    component: MagnaQuest,
    exact: true,
  }, */
    {
        path: `/changePassword`,
        component: LoginPanel,
        permissions: [PERMISSIONS?.DASHBOARD_VIEW, PERMISSIONS?.DASHBOARD_EDIT]
    },

    {
        path: `/${URL.DASHBOARD}`,
        component: DashboardComponent,
        permissions: [PERMISSIONS?.DASHBOARD_VIEW, PERMISSIONS?.DASHBOARD_EDIT]
    },
    {
        path: `/${URL.TENANT}`,
        component: Tenant,
        exact: true,
        permissions: [PERMISSIONS?.TENANT_LIST_EDIT, PERMISSIONS?.TENANT_LIST_VIEW]
    },
    {
        path: `/${URL.ADMIN_MANAGEMENT}`,
        component: SuperAdminManagement,
        exact: true,
        permissions: []
    },

    {
        path: `/${URL.TENANT}/${URL.ADD_TENANT}`,
        component: AddEditTenant,
        permissions: [PERMISSIONS?.ADD_TENANT_EDIT, PERMISSIONS?.ADD_TENANT_VIEW]
    },
    {
        path: `/${URL.DASHBOARD}`,
        component: Dashboard,
        permissions: [PERMISSIONS?.DASHBOARD_VIEW, PERMISSIONS?.DASHBOARD_EDIT]
    },
    {
        path: `/${URL.SETTINGS}/${URL.MANAGEMENT}/${URL.MANAGEMENT_USERS}`,
        component: ManagementUsers,
        permissions: [
            PERMISSIONS?.SETTING_USER_MANAGEMENT_USERS_EDIT,
            PERMISSIONS?.SETTING_USER_MANAGEMENT_USERS_VIEW
        ]
    },
    {
        path: `/${URL.SETTINGS}/${URL.MANAGEMENT}/${URL.MANAGEMENT_ROLES}`,
        component: ManagementRoles,
        permissions: [
            PERMISSIONS?.SETTING_USER_MANAGEMENT_ROLES_EDIT,
            PERMISSIONS?.SETTING_USER_MANAGEMENT_ROLES_VIEW
        ]
    },
    {
        path: `/${URL.STREAM}/${URL.CONTENT}/${URL.METADATA_INGESTION}`,
        component: MetadataIngestion,
        permissions: []
    },
    {
        path: `/${URL.STREAM}/${URL.CONTENT}`,
        component: ContentNav,
        permissions: [PERMISSIONS?.CONTENT_EDIT, PERMISSIONS?.CONTENT_VIEW]
    },
    // {
    //   path: `/test/${URL.WEBSERIES}`,
    //   component: WebSeries,
    // },
    // {
    //   path: `/${URL.CONTENT}/${URL.LIVETV}`,
    //   component: LiveTv,
    // },

    {
        path: `/${URL.ENGAGE}/${URL.UX_MANGER}/${URL.CATEGORY}`,
        component: VODCategory,
        permissions: [
            PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_VIEW,
            PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_EDIT
        ]
    },
    {
        path: `/${URL.MYPROFILE}`,
        component: MyProfile,
        permissions: []
    },
    {
        path: `/${URL.SETTINGS}/${URL.CUSTOMER_BRANDING}`,
        component: CustomerBranding,
        permissions: [PERMISSIONS?.SETTING_CUSTOMER_EDIT, PERMISSIONS?.SETTING_CUSTOMER_VIEW]
    },
    {
        path: `/${URL.ENGAGE}/${URL.CUSTOM_PAGES}`,
        component: CustomPages,
        permissions: [
            PERMISSIONS?.ENGAGE_UX_MANAGER_CUSTOM_PAGES_VIEW,
            PERMISSIONS?.ENGAGE_UX_MANAGER_CUSTOM_PAGES_EDIT
        ]
    },
    {
        path: `/${URL.ENGAGE}/${URL.MENU_DESIGN}`,
        component: MenuDesign,
        permissions: [
            PERMISSIONS?.ENGAGE_UX_MANAGER_MENU_DESIGN_VIEW,
            PERMISSIONS?.ENGAGE_UX_MANAGER_MENU_DESIGN_EDIT
        ]
    },

    {
        path: `/${URL.SETTINGS}/${URL.FORM_BUILDER}`,
        component: FormBuilder,
        permissions: [
            PERMISSIONS?.SETTING_FORM_BUILDER_EDIT,
            PERMISSIONS?.SETTING_FORM_BUILDER_VIEW
        ]
    },
    {
        path: `/${URL.STREAM}/${URL.EPG}`,
        component: EpgUpdate,
        permissions: [PERMISSIONS?.EPG_EDIT, PERMISSIONS?.EPG_VIEW]
    },
    {
        path: `/${URL.ENGAGE}/${URL.CONTENTENRICHMENT}`,
        component: ContentEnrichment,
        permissions: [
            PERMISSIONS?.ENGAGE_UX_MANAGER_CONTENT_ENRICHMENT_EDIT,
            PERMISSIONS?.ENGAGE_UX_MANAGER_CONTENT_ENRICHMENT_VIEW
        ]
    },
    {
        path: `/${URL.SUBSCRIPTION}/${URL.CREATE_PLAN}`,
        component: CreatePlan
    },
    /*   {
    path: `/${URL.SUBSCRIPTION}/${URL.TENANT}`,
    component: MagnaQuest,
  }, */
    {
        path: `/${URL.SUBSCRIPTION}/${URL.INVOICE}`,
        component: Invoice
    },
    {
        path: `/${URL.SUBSCRIPTION}/${URL.SUBSCRIBERS}`,
        component: TotalSubscriber
    },
    {
        path: `/${URL.MONETIZE}/${URL.PRODUCT_PLACEMENT}`,
        component: ProductPlacement,
        permissions: [
            PERMISSIONS?.MONETIZE_PRODUCT_PLACEMENT_EDIT,
            PERMISSIONS?.MONETIZE_PRODUCT_PLACEMENT_VIEW
        ]
    },
    {
        path: `/${URL.MONETIZE}/${URL.MAXIMISER}`,
        component: RoiMaximiser,
        permissions: [
            PERMISSIONS?.MONETIZE_ROI_MAXIMISER_EDIT,
            PERMISSIONS?.MONETIZE_ROI_MAXIMISER_VIEW
        ]
    },
    {
        path: `/${URL.SUBSCRIPTION}/${URL.COUPONS}`,
        component: AddCoupon
    },
    // {
    //   path: `/${URL.SUBSCRIPTION}/${URL.PROMOTION}`,
    //   component: MagnaQuest,
    // },
    {
        path: `/${URL.SUBSCRIPTION}/${URL.DASHBOARD}`,
        component: MagnaQuest
    },
    {
        path: `/${URL.SUBSCRIPTION}/${URL.DESIGNER_DASHBOARD}`,
        component: MagnaQuest
    },
    {
        path: `/${URL.SUBSCRIPTION}/${URL.ACTIVECUSTOMERS}`,
        component: MagnaQuest
    },
    {
        path: `/${URL.SUBSCRIPTION}/${URL.NOTIFICATION_TEMPLATE}`,
        component: MagnaQuest
    },
    {
        path: `/${URL.SUBSCRIPTION}/${URL.WORKFLOW}`,
        component: MagnaQuest
    },
    {
        path: `/${URL.SUBSCRIPTION}/${URL.REPORTS}`,
        component: MagnaQuest
    },
    {
        path: `/${URL.SUBSCRIPTION}/${URL.PRODUCT}`,
        component: PlansList
    },
    {
        path: `/${URL.SUBSCRIPTION}/${URL.NOTIFICATION}`,
        component: MagnaQuest
    },
    // {
    //   path: `/${URL.MONETIZE}/:name`,
    //   component: Monetize,
    // },
    // {
    //   path: `/${URL.MONETIZE}/:name`,
    //   component: Monetize,
    //   path: `/${URL.MONETIZE}/${URL.PRODUCT_PLACEMENT}`,
    //   component: ProductPlacement,
    //   permissions: [
    //     PERMISSIONS?.MONETIZE_PRODUCT_PLACEMENT_EDIT,
    //     PERMISSIONS?.MONETIZE_PRODUCT_PLACEMENT_VIEW,
    //   ],
    // },
    {
        path: `/${URL.MONETIZE}/${URL.MAXIMISER}`,
        component: RoiMaximiser,
        permissions: [
            PERMISSIONS?.MONETIZE_ROI_MAXIMISER_EDIT,
            PERMISSIONS?.MONETIZE_ROI_MAXIMISER_VIEW
        ]
    },
    {
        path: `/${URL.SETTINGS}/${URL.MULTILINGUAL}`,
        component: Multilingual,
        permissions: [
            PERMISSIONS?.SETTING_MULTILINGUAL_EDIT,
            PERMISSIONS?.SETTING_MULTILINGUAL_VIEW
        ]
    },
    {
        path: `/${URL.SETTINGS}/${URL.ADDTRANSLATION}`,
        component: AddTranslation,
        permissions:[PERMISSIONS?.TRANSLATION_EDIT, PERMISSIONS?.TRANSLATION_VIEW, PERMISSIONS?.SETTING_MULTILINGUAL_VIEW]
    },
    // {
    //   path: `/${URL.CONTENT_PLAN}`,
    //   component: ContentPlan,
    // },
    {
        path: `/${URL.SUBSCRIPTION}/${URL.CONTENT_PRODUCT}`,
        component: ContentPlan
    },
    {
        path: `/${URL.SETTINGS}/${URL.DRM_CONFIG}`,
        component: DrmConfiguration,
        permissions: [PERMISSIONS?.DRM_VIEW, PERMISSIONS?.DRM_EDIT]
    },
    {
        path: `/${URL.SETTINGS}/${URL.CONFIG}/${URL.BASIC_CONFIG}`,
        component: BasicConfiguration,
        exact: true,
        permissions: [PERMISSIONS?.BASIC_CONFIGURATION_VIEW, PERMISSIONS?.BASIC_CONFIGURATION_EDIT]
    },
    {
        path: `/add-video-content`,
        component: AddContentPipeline,
        permissions: [PERMISSIONS?.VIDEO_PIPELINE_EDIT]
    },
    {
        path: `/transcoding`,
        component: Transcoding
    },
    {
        path: `/${URL.STREAM}/${URL.VIDEO_PIPELINE}`,
        component: VideoPipeline,
        permissions: [PERMISSIONS?.VIDEO_PIPELINE_EDIT, PERMISSIONS?.VIDEO_PIPELINE_VIEW]
    },
    // {
    //   path: `/${URL.SETTINGS}/${URL.BASIC_CONFIG}/${URL.FORCE_UPGRADE}`,
    //   component: ForceUpgrade,
    //   exact: true,
    //   permissions: [
    //     PERMISSIONS?.VIDEO_PIPELINE_EDIT,
    //     PERMISSIONS?.VIDEO_PIPELINE_VIEW,
    //   ],
    // },
    {
        path: `/${URL.SETTINGS}/${URL.CONFIG}/${URL.TIMEZONE}`,
        component: Timezone,
        exact: true,
        permissions: [PERMISSIONS?.BASIC_CONFIGURATION_VIEW, PERMISSIONS?.TIMEZONE_EDIT]
    },
    {
        path: `/${URL.ENGAGE}/${URL.SPORTS}/${URL.SETUP_SPORTS}`,
        component: SportsSetup,
        permissions: [
            PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_VIEW,
            PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_EDIT
        ]
    },
    {
        path: `/${URL.ENGAGE}/${URL.SPORTS}/${URL.CREATE_PAGES}`,
        component: CreateManagePage,
        permissions: [
            PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_VIEW,
            PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_EDIT
        ]
    },
    {
        path: `/${URL.ENGAGE}/${URL.SPORTS}/${URL.SPORTS_HOME}`,
        component: SportsHome,
        permissions: [
            PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_VIEW,
            PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_EDIT
        ]
    },
    {
        path: `/${URL.AUDIT_TRAIL}`,
        component: AuditTrail,
        exact: true,
        // todo: update permissions
        permissions: [
            PERMISSIONS?.SETTING_USER_MANAGEMENT_USERS_EDIT,
            PERMISSIONS?.SETTING_USER_MANAGEMENT_USERS_VIEW
        ]
    },
    {
        path: `/${URL.ENGAGE}/${URL.MISC_LINKS}`,
        component: Footer,
        permissions: [
            PERMISSIONS?.ENGAGE_UX_MANAGER_MENU_DESIGN_VIEW,
            PERMISSIONS?.ENGAGE_UX_MANAGER_MENU_DESIGN_EDIT
        ]
    },
    // {
    //     path: `/${URL.SETTINGS}/${URL.ENVIRONMENTS}`,
    //     component: Environments,
    //     permissions: []
    // },
    // {
    //     path: `/${URL.SETTINGS}/${URL.PLATFORMS}`,
    //     component: Platforms,
    //     permissions: []
    // },
    {
        path: `/${URL.SETTINGS}/${URL.PLATFORM_CONFIG}`,
        component: PlatformConfig,
        permissions: []
    },
    {
        path: "*",
        component: NotFound404,
        permissions: []
    }
]
