import { PlatformConfigService } from "./service"
import { ACTION } from "./constant"

export const getPlatformConfig = (platformName) => {
    return (dispatch) => {
        return PlatformConfigService.getPlatformConfig(platformName)
            .then((response) => {
                dispatch({
                    type: ACTION.GET_PLATFORM_CONFIG,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting platformConfig ", error)
            })
    }
}

export const submitConfigPayload = (params) => {
    return (dispatch) => {
        return PlatformConfigService.submitConfigPayload(params)
            .then((response) => {
                dispatch({
                    type: ACTION.GET_PLATFORM_CONFIG,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting platformConfig ", error)
            })
    }
}
