import React from "react"
import PropTypes from "prop-types"
import "./style.scss"
import Select from "react-select"
import { useSelector } from "react-redux"
import { isEmpty } from "lodash"

const Select1 = (props) => {
    let {
        label,
        options,
        value,
        required,
        defaultOption,
        cName,
        errMsg,
        isDisabled,
        placeholder,
        menuOpenStyle,
        isOptionDisabled,
        isSearchable,
        styles,
        isMulti = false,
        name,
        changeHandler,
        isAutoFocus = true
    } = props
    const handleChange = (selectedOptions, action) => {
        changeHandler(name, selectedOptions?.id, selectedOptions)
    }

    const isDarkMode = useSelector((state) => state?.theme?.darkMode)
    const customDropdownStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            let bgColorObj = {
                bg: "#241d3b",
                bgHover: "#322c5a",
                selected: "#322c5a"
            }
            if (!isDarkMode) {
                bgColorObj = {
                    bg: "#FFF",
                    bgHover: "#DEEBFF",
                    selected: "#8cb9f3"
                }
            }
            return {
                ...styles,
                "&:hover": {
                    backgroundColor: `${bgColorObj.bgHover} !important`
                },
                backgroundColor: isSelected
                    ? `${bgColorObj.selected} !important`
                    : `${bgColorObj.bg} !important`
            }
        }
    }
    return (
        <div
            className={`select-box ${errMsg ? `${cName} inputErr` : cName}  ${
                isDisabled ? `${cName} disable` : cName
            }`}
        >
            {label && (
                <label htmlFor="selectBox">
                    {label}
                    <span
                        className="required"
                        style={{
                            display: required ? "inline" : "none",
                            // color: "#1FB1FF",
                            marginLeft: "5px"
                        }}
                    >
                        *
                    </span>
                </label>
            )}
            {/* <select name={name} id="selectBox" value={value} onChange={handleChange} required={required} ref={listRef}>
                {
                    defaultOption && <option disabled={true} selected={!selectedVal&&true} value="">{defaultText? defaultText : '- Select -'}</option>
                }
                {
                    options && options.map((item, index) => (
                        <option key={index} value={item.id}>{item.name}</option>
                    ))
                }
            </select> */}

            <Select
                placeholder={placeholder ? placeholder : null}
                defaultValue={defaultOption}
                onChange={handleChange}
                options={options}
                isSearchable={isSearchable ? isSearchable : false}
                value={isEmpty(value) ? "" : value}
                isDisabled={isDisabled}
                // menuIsOpen={true}
                isMulti={isMulti}
                menuPlacement={menuOpenStyle ? menuOpenStyle : "auto"}
                // id={id}
                autoFocus={isAutoFocus}
                className={"multiSelect-container disable"}
                classNamePrefix={"multiSelect"}
                getOptionLabel={(options) => options.name}
                getOptionValue={(options) => options.id}
                isOptionDisabled={isOptionDisabled}
                styles={styles || customDropdownStyles}
            />
            {errMsg && <span className="err">{errMsg}</span>}
        </div>
    )
}

Select.propTypes = {
    changeHandler: PropTypes.func,
    name: PropTypes.string,
    placeholder: PropTypes.any,
    value: PropTypes.any,
    label: PropTypes.string
}

export default Select1
