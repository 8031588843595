import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import { MenuTokenFun } from "./MenuTokenConstant"
import Loader from "../../assets/images/loader.gif"
import { sendMagnaRequest } from "../Monetize/APIs/action"

import "./magnaQuestIframe.scss"
import Button from "../common/Button"
import { BUTTON_CONSTANTS } from "../../utils/constants"

class MagnaQuestIframe extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            isFormVisible: true,
            url: this.props?.iframeURL
        }
        this.formRef = React.createRef()
        let location = this.props?.location?.pathname?.split("/")
        this.pageName = location
            ? location[this.props?.location?.pathname?.split("/").length - 1]
            : ""
    }
    componentDidMount() {
        document.getElementById("login") && document.getElementById("login").submit()
        this.setState({
            isFormVisible: false
        })
    }
    changeSrc = () => {
        document.getElementById("iframe_2").src = this.state?.url
        // "https://demo-ttnsandbox.magnaquest.com//OrderManagement/NewCoupon/NewCoupon?MenuID=1054";
    }
    iframeloaded = () => {
        this.setState({
            isLoading: false
        })
        this.changeSrc()
    }

    render() {
        const { isLoading, isFormVisible } = this.state
        const { handleBackBtn } = this.props
        const MenuToken = MenuTokenFun()

        return (
            <>
                {isLoading && (
                    <div className="loader">
                        <img src={Loader} width={"100px"} alt="Loader" />
                    </div>
                )}
                <div className="magnaquest-tenant-container">
                    <>
                        {isFormVisible && (
                            <form
                                id="login"
                                action="https://demo-ttnsandbox.magnaquest.com/portalIntegration.aspx"
                                //   action="http://demo-ttnsandbox.magnaquest.com/portalIntegration.aspx"
                                method="POST"
                                name="myForm"
                                target="my_frame"
                                ref={this.formRef}
                            >
                                <input
                                    type="hidden"
                                    name="KEY"
                                    id="KEY"
                                    value={MenuToken[this.pageName]?.KEY}
                                />
                                <input
                                    type="hidden"
                                    name="TOKEN"
                                    value={MenuToken[this.pageName]?.TOKEN}
                                />
                                {/* <input type="hidden" name="THEME" id="THEME" value="dark" /> */}
                                <input type="hidden" name="SHOWMENU" value="0" />
                                <input
                                    type="hidden"
                                    name="MENUID"
                                    value={MenuToken[this.pageName]?.MENUID}
                                />

                                <input type="hidden" name="SHOWTITLE" value="true" />
                                <input type="hidden" name="ISPOPUP" value="N" />
                                <input
                                    type="hidden"
                                    name="CALLBACK_URL"
                                    id="CALLBACK_URL"
                                    value="https://www.google.com"
                                />
                            </form>
                        )}
                        <Button
                            cName="btn border-btn magnaquest-back-btn"
                            bValue={BUTTON_CONSTANTS?.BACK}
                            clickHandler={handleBackBtn}
                            leftIcon="icon-arrow-left"
                        />
                        <iframe
                            name="my_frame2"
                            id="iframe_2"
                            width={"100%"}
                            height={"800px"}
                            sandbox="allow-scripts allow-forms allow-same-origin allow-popups"
                            allowFullScreen="allowfullscreen"
                            // onLoad={this.iframeloaded}
                            title="magnaquest-iframe2"
                        ></iframe>
                        <iframe
                            name="my_frame"
                            id="iframe_1"
                            width={"100%"}
                            height={"0px"}
                            sandbox="allow-scripts allow-forms allow-same-origin allow-popups"
                            allowFullScreen="allowfullscreen"
                            onLoad={this.iframeloaded}
                            title="magnaquest-iframe1"
                        ></iframe>
                    </>
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(
            {
                sendMagnaRequest: sendMagnaRequest
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MagnaQuestIframe)
