import React from "react"
import Select from "react-select"
import "./style.scss"

// const ValueContainer = (props) => {
//   const selected = props.getValue();
//   const content = ((length) => {
//     switch (length) {
//       case 0:
//         return props.children;
//       case 1:
//         return selected[0].name;
//       default:
//         return `${selected.length} Selected`;
//     }
//   })(selected.length);

//   return (
//     <components.ValueContainer {...props}>{content}</components.ValueContainer>
//   );
// };

export default class CustomSelect extends React.Component {
    handleChange = (selectedOptions, action) => {
        const { changeHandler, id } = this.props
        if (selectedOptions) {
            changeHandler(id, selectedOptions)
        } else {
            changeHandler(id, [])
        }
    }

    render() {
        const { options, label, required, id, placeholder, selectedOptions, errMsg, isDisabled } =
            this.props
        return (
            <div className={`multi-select-container ${errMsg ? `inputErr` : ""}`}>
                {label && (
                    <label htmlFor={id}>
                        {label}
                        <span className={required ? "required" : "notRequired"}>*</span>
                    </label>
                )}
                <Select
                    options={options}
                    placeholder={placeholder}
                    value={selectedOptions}
                    //  value={selectedValues && selectedValues.length ? selectedValues : []}
                    onChange={this.handleChange}
                    isMulti={true}
                    // components={{
                    //   ValueContainer: ValueContainer,
                    //   // DropdownIndicator: () => null,
                    //   // IndicatorSeparator: () => null,
                    // }}
                    id={id}
                    openMenuOnClick={true}
                    isSearchable={true}
                    className={"multiSelect-container"}
                    classNamePrefix={"multiSelect"}
                    getOptionLabel={(options) => options.title}
                    getOptionValue={(options) => options.id}
                    isDisabled={isDisabled}
                />
                {errMsg && <span className="err">{errMsg}</span>}
            </div>
        )
    }
}
