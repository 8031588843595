import React, { useState, useEffect } from "react"
import ContentPlanFilterOptions from "../ContentPlanFilterOptions"
import DisplayContentList from "../DisplayContentList"
import { useDispatch } from "react-redux"
import {
    deleteSavedFilterAPI,
    fetchContentData,
    getSavedFilterDataListAPI,
    saveFilterData,
    saveMoviesSelectedPlan,
    saveWebSeriesSelectedPlan
} from "../API/action"
import RenderSelectedContentPlan from "../RenderSelectedPlans"
import { CONTENT_TAB_TYPES, FORM_DATA_CONSTANTS, initialStateForContent } from "../API/constants"
import { toast } from "react-toastify"
import { isEmpty } from "lodash"
import { fetchContentEntityAll } from "../../formBuilder/api/action"
import Loader from "../../common/Loader"
import { getPlansList } from "../../MagnaQuest/APIs/action"

import "./DisplayContentPlan.scss"
import { scrollPage } from "../../../utils/common"
import { useRef } from "react"

export default function DisplayContentPlan({ contentTabType }) {
    const [contentPayload, setContentPayload] = useState(initialStateForContent)
    const [contentEntityId, setContentEntityId] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [moviesDataList, setMoviesDataList] = useState(null)
    const [selectedMoviesId, setSelectedMoviesId] = useState([])
    const [allFiltersData, setAllFiltersData] = useState({
        customisedFilterDataList: null,
        savedFilterDataList: null,
        selectedFilterOptionsDataList: null
    })

    const [plansList, setPlansList] = useState([])
    const [isSaveFilterDisabled, setIsSaveFilterDisabled] = useState(true)

    const [webSeriesDataList, setWebSeriesDataList] = useState([])
    const [selectedWebSeriesId, setSelectedWebSeriesId] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const [unplishedCheckbox, setUnpublishedCheckbox] = useState(false)

    const dispatch = useDispatch()

    const contentContainerWidthRef = useRef(null)
    const movieCardWidth = 170 //Display card for movies and webseries is 170px

    const isWebSeriesTab = CONTENT_TAB_TYPES?.WEBSERIES === contentTabType

    useEffect(() => {
        fetchAllContentAPI()
        getContentPlansList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // API Calls Start
    useEffect(() => {
        if (contentContainerWidthRef) {
            initialStateForContent.length =
                Math.floor(contentContainerWidthRef.current.offsetWidth / movieCardWidth) * 3 //
        }
        // Clear data on tab change
        setContentPayload(initialStateForContent)
        setAllFiltersData((prevState) => {
            return {
                ...prevState,
                selectedFilterOptionsDataList: null
            }
        })
        setSearchValueHandler("")
        setUnpublishedCheckbox(false)
        setWebSeriesDataList([])
        setMoviesDataList([])
        setSelectedMoviesId([])
        setSelectedWebSeriesId([])
        setIsSaveFilterDisabled(true)
        // API Calls to get the data
        getSavedFilterDataList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentTabType, contentEntityId])

    const fetchAllContentAPI = async () => {
        setIsLoading(true)
        const response = await dispatch(fetchContentEntityAll())
        setIsLoading(false)
        if (response?.status) {
            const data = response?.data
            setContentEntityId({
                [CONTENT_TAB_TYPES.WEBSERIES]: data?.find(
                    (item) => item?.name?.toLowerCase()?.split(" ")?.join("") === "webseries"
                )?.id,
                [CONTENT_TAB_TYPES.MOVIES]: data?.find(
                    (item) => item?.name?.toLowerCase() === "movies"
                )?.id
            })
        }
    }

    const getContentPlansList = async () => {
        const res = await dispatch(getPlansList())
        if (res?.status) {
            setPlansList(res?.data?.plans)
        }
    }

    // Saved Filter API Call
    const saveCurrentFilterDataAPI = async (savedFilterName) => {
        // contentPayload create data accoring to API
        const res = await dispatch(
            saveFilterData(contentPayload, savedFilterName, contentEntityId?.[contentTabType])
        )
        if (res?.status) {
            toast.success(res?.message)
            getSavedFilterDataList()
            return
        }
        toast.error("Something went wrong. Please try again!!!")
    }

    const getSavedFilterDataList = async () => {
        if (contentEntityId?.[contentTabType]) {
            const res = await dispatch(getSavedFilterDataListAPI(contentEntityId?.[contentTabType]))
            if (res?.status) {
                setAllFiltersData((prevState) => {
                    return {
                        ...prevState,
                        savedFilterDataList: res?.data,
                        customisedFilterDataList: res?.data?.filters?.filters
                    }
                })
            }
        }
    }

    const getSelectedSavedFilterData = async (selectedFilterData) => {
        setAllFiltersData((prevState) => {
            return {
                ...prevState,
                selectedFilterOptionsDataList: selectedFilterData
            }
        })
    }

    const deleteSavedFilter = async (deletedFilterId) => {
        const res = await dispatch(deleteSavedFilterAPI(deletedFilterId))
        if (res?.status) {
            toast.success(res?.message)
            return true
        }
        toast.error(res?.message)
        return false
    }

    const fetchAPIData = async (data, appendData = false) => {
        setIsLoading(true)
        const res = await dispatch(fetchContentData(data, contentEntityId?.[contentTabType]))
        if (isWebSeriesTab) {
            if (res?.status) {
                setWebSeriesDataList(res)
            }
        } else {
            if (res?.status) {
                setMoviesDataList(res)
            }
        }
        setIsLoading(false)
    }

    const scrollContainerToTop = () => {
        if (isWebSeriesTab) {
            const webSeriesContainer = document.getElementById("webseries-grid-view-container")
            if (webSeriesContainer) {
                webSeriesContainer.scrollTop = 0
            }
        } else {
            const movieContainer = document.getElementById("content-grid-view-container")
            if (movieContainer) {
                movieContainer.scrollTop = 0
            }
        }
    }

    const setSearchValueHandler = (val) => {
        setSearchValue(val)
    }

    const filterSubmitHandler = (filterOptions) => {
        let modifiedPayload = JSON.parse(JSON.stringify(contentPayload))
        let newFilters = {}
        let rangeFilter = {}
        if (modifiedPayload.filters.hasOwnProperty("active")) {
            newFilters = {
                ...newFilters,
                active: modifiedPayload.filters?.active
            }
        } else {
            newFilters = {
                ...newFilters,
                active: [true]
            }
        }
        for (let key in filterOptions) {
            if (!isEmpty(filterOptions?.[key])) {
                if (key === "date") {
                    rangeFilter = {
                        lastUpdated: {
                            greaterThan: filterOptions[key]?.startDate,
                            lessThan: filterOptions[key]?.endDate
                        }
                    }
                } else {
                    newFilters = {
                        ...newFilters,
                        [key]: filterOptions[key]?.map((item) => item?.name)
                    }
                }
            }
        }
        modifiedPayload.filters = newFilters
        modifiedPayload.rangeFilter = rangeFilter
        modifiedPayload.length = contentPayload?.length
        modifiedPayload.searchTag = ""
        setContentPayload(modifiedPayload)
        fetchAPIData(modifiedPayload)
        setIsSaveFilterDisabled(false)
        setSearchValueHandler("")
        setSelectedMoviesId([])
        setSelectedWebSeriesId([])
        setUnpublishedCheckbox(false)
        scrollPage()
        scrollContainerToTop()
        return
    }

    const fetchNextBatchData = (param, isWebSeriesData = false) => {
        let modifiedPayload = JSON.parse(JSON.stringify(contentPayload))
        let isFetchingData = false
        if (isWebSeriesData) {
            const intialRecordCount = 10
            if (
                webSeriesDataList?.count > modifiedPayload?.length &&
                webSeriesDataList?.count > intialRecordCount
            ) {
                modifiedPayload.length += intialRecordCount
                isFetchingData = true
            }
        } else {
            const intialRecordCount = contentPayload?.length
            if (
                moviesDataList?.count > modifiedPayload?.length &&
                moviesDataList?.count > intialRecordCount
            ) {
                modifiedPayload.length += intialRecordCount
                isFetchingData = true
            }
        }

        if (isFetchingData) {
            setContentPayload(modifiedPayload)
            fetchAPIData(modifiedPayload, true)
        }
        return
    }

    // Unpublished API call
    const showUnplishedRecordHandler = (name, checked, id) => {
        let modifiedPayload = JSON.parse(JSON.stringify(contentPayload))
        //unpbulised true show both records
        if (checked) {
            if (modifiedPayload.filters.hasOwnProperty("active")) {
                delete modifiedPayload.filters.active
            }
        } else {
            modifiedPayload.filters.active = [true] //show only publish result
        }
        setUnpublishedCheckbox(checked)
        setContentPayload(modifiedPayload)
        fetchAPIData(modifiedPayload)
        return
    }

    // Sorting API call
    const sortRecordsHandler = (value) => {
        //Call api to send the vlaue
        let modifiedPayload = JSON.parse(JSON.stringify(contentPayload))
        //unpbulised true show both records
        modifiedPayload.sortBy = value?.name
        modifiedPayload.sortOrder = value?.order
        setContentPayload(modifiedPayload)
        fetchAPIData(modifiedPayload)
        return
    }

    // Searching API call
    const inputSearchHandler = async (searchValue) => {
        // API call
        let modifiedPayload = JSON.parse(JSON.stringify(contentPayload))
        //unpbulised true show both records
        modifiedPayload.searchTag = searchValue
        setContentPayload(modifiedPayload)
        fetchAPIData(modifiedPayload)
        return
    }

    const findSeasonListFromWebSeries = (webSeriesId, firstFree = false) => {
        const selectedSeries = webSeriesDataList?.data?.find((item) => item?.id === webSeriesId)
        let seasonsList = []
        if (selectedSeries?.content?.season) {
            seasonsList = selectedSeries?.content?.season?.map((season) => season?.season_id)
        }
        return seasonsList
    }

    const moviesCheckBoxHandler = (name, checked, id) => {
        if (id === FORM_DATA_CONSTANTS?.selectAllCheckbox) {
            checked
                ? setSelectedMoviesId(moviesDataList?.data?.map((item) => item?.id))
                : setSelectedMoviesId([])
        } else {
            if (checked) {
                // Add in the selectedMovies
                setSelectedMoviesId((prevState) => {
                    return [...prevState, id]
                })
            } else {
                // Remove from the selectedMovies
                const remainingMoviesId = selectedMoviesId?.filter((movieId) => movieId !== id)
                setSelectedMoviesId(remainingMoviesId)
            }
        }
    }

    const addIdsForSelectAll = (
        selectedSeasonObj,
        checked,
        id,
        webSeriesId,
        isWebSeriesObjPresent
    ) => {
        let tempObj
        if (isWebSeriesObjPresent) {
            let selectedWebSeriesIdCopy = JSON.parse(JSON.stringify(selectedWebSeriesId))
            selectedWebSeriesIdCopy.forEach((series) => {
                if (series?.seriesId === webSeriesId) {
                    if (checked) {
                        series[selectedSeasonObj] = findSeasonListFromWebSeries(webSeriesId)
                    } else {
                        series[selectedSeasonObj] = []
                    }
                }
            })
            setSelectedWebSeriesId(selectedWebSeriesIdCopy)
        } else {
            tempObj = {
                seriesId: webSeriesId,
                selectedSeasonID: [],
                selectedFreeSeasonId: []
            }
            tempObj[selectedSeasonObj] = findSeasonListFromWebSeries(webSeriesId)
            if (checked) {
                setSelectedWebSeriesId((prevState) => {
                    return [...prevState, tempObj]
                })
            }
        }
    }

    const addIdsForSingleSelect = (
        selectedSeasonObj,
        checked,
        id,
        webSeriesId,
        isWebSeriesObjPresent
    ) => {
        let modifiedWebSeriesIds = JSON.parse(JSON.stringify(selectedWebSeriesId))
        if (isWebSeriesObjPresent) {
            modifiedWebSeriesIds.forEach((series) => {
                if (series?.seriesId === webSeriesId) {
                    if (checked) {
                        series[selectedSeasonObj].push(id)
                    } else {
                        series[selectedSeasonObj] = series?.[selectedSeasonObj]?.filter(
                            (item) => item !== id
                        )
                    }
                }
            })
            setSelectedWebSeriesId(modifiedWebSeriesIds)
        } else {
            let newSeriesObj = {
                seriesId: webSeriesId,
                selectedSeasonID: [],
                selectedFreeSeasonId: []
            }
            newSeriesObj[selectedSeasonObj] = [id]
            modifiedWebSeriesIds.push(newSeriesObj)
            setSelectedWebSeriesId(modifiedWebSeriesIds)
        }
    }

    const webSeriesCheckboxHandler = (checked, id, webSeriesId, isFirstFree) => {
        // {
        //   seriesId: '',
        //   selectedSeasonID: [],
        //   selectedFreeSeasonId:[]
        // }
        const isWebSeriesObjPresent =
            selectedWebSeriesId?.findIndex((item) => item?.seriesId === webSeriesId) !== -1

        if (isFirstFree) {
            id === FORM_DATA_CONSTANTS?.freeForAllSeason
                ? addIdsForSelectAll(
                      "selectedFreeSeasonId",
                      checked,
                      id,
                      webSeriesId,
                      isWebSeriesObjPresent
                  )
                : addIdsForSingleSelect(
                      "selectedFreeSeasonId",
                      checked,
                      id,
                      webSeriesId,
                      isWebSeriesObjPresent
                  )
        } else {
            id === FORM_DATA_CONSTANTS?.selectAllCheckbox
                ? addIdsForSelectAll(
                      "selectedSeasonID",
                      checked,
                      id,
                      webSeriesId,
                      isWebSeriesObjPresent
                  )
                : addIdsForSingleSelect(
                      "selectedSeasonID",
                      checked,
                      id,
                      webSeriesId,
                      isWebSeriesObjPresent
                  )
        }
    }

    const selectedPlanApplyHandler = async (selectedMoviePlansIds, deselectAllIds) => {
        if (deselectAllIds) {
            setSelectedMoviesId([])
            setSelectedWebSeriesId([])
        } else {
            if (selectedMoviePlansIds?.length > 0) {
                let planPayload, res
                if (isWebSeriesTab) {
                    let webseriesReq = []
                    selectedWebSeriesId.forEach((series) => {
                        const seasons = series?.selectedSeasonID
                        if (seasons?.length > 0) {
                            let tempObj = {
                                brandId: series?.seriesId,
                                seasonData: []
                            }
                            tempObj.seasonData = seasons?.map((item) => {
                                return {
                                    seasonId: item,
                                    isFirstEpisodeFree: series?.selectedFreeSeasonId?.includes(item)
                                }
                            })
                            webseriesReq.push(tempObj)
                        }
                    })

                    planPayload = {
                        webSeriesRequest: webseriesReq,
                        contentType: "WEB SERIES",
                        planList: [...selectedMoviePlansIds]
                    }
                    res = await dispatch(
                        saveWebSeriesSelectedPlan(planPayload, contentEntityId?.[contentTabType])
                    )
                } else {
                    // Make an API call based on selected plan and movies
                    planPayload = {
                        contentIds: [...selectedMoviesId],
                        contentType: "MOVIES",
                        planList: [...selectedMoviePlansIds]
                    }
                    res = await dispatch(
                        saveMoviesSelectedPlan(planPayload, contentEntityId?.[contentTabType])
                    )
                }
                if (res?.status) {
                    fetchAPIData(contentPayload)
                }
                return res
            }
            toast.error(FORM_DATA_CONSTANTS?.selectPlanError)
        }
    }

    const isRenderSelectedPlanVisible = () => {
        let isVisible = false
        if (contentTabType === CONTENT_TAB_TYPES?.MOVIES) {
            isVisible = selectedMoviesId?.length > 0
        } else {
            selectedWebSeriesId.forEach((item) => {
                if (item?.selectedSeasonID?.length > 0) {
                    isVisible = true
                }
            })
        }
        return isVisible
    }

    return (
        <>
            {isLoading && <Loader></Loader>}
            <div className="vr-content-holder vr-movies-content-contanier">
                <ContentPlanFilterOptions
                    filterSubmitHandler={filterSubmitHandler}
                    allFiltersData={allFiltersData}
                    getSelectedSavedFilterData={getSelectedSavedFilterData}
                    saveCurrentFilterDataAPI={saveCurrentFilterDataAPI}
                    contentTabType={contentTabType}
                    deleteSavedFilter={deleteSavedFilter}
                    isSaveFilterDisabled={isSaveFilterDisabled}
                ></ContentPlanFilterOptions>
                <DisplayContentList
                    moviesDataList={moviesDataList?.data}
                    moviesCheckBoxHandler={moviesCheckBoxHandler}
                    selectedMoviesId={selectedMoviesId}
                    inputSearchHandler={inputSearchHandler}
                    showUnplishedRecordHandler={showUnplishedRecordHandler}
                    sortRecordsHandler={sortRecordsHandler}
                    fetchNextBatchData={fetchNextBatchData}
                    contentTabType={contentTabType}
                    webseriesDataList={webSeriesDataList?.data}
                    selectedWebSeriesId={selectedWebSeriesId}
                    webSeriesCheckboxHandler={webSeriesCheckboxHandler}
                    plansList={plansList}
                    totalContentCount={
                        isWebSeriesTab ? webSeriesDataList?.count : moviesDataList?.count
                    }
                    contentEntityId={contentEntityId}
                    contentContainerWidthRef={contentContainerWidthRef}
                    contentPayload={contentPayload}
                    searchValue={searchValue}
                    setSearchValueHandler={setSearchValueHandler}
                    unplishedCheckbox={unplishedCheckbox}
                ></DisplayContentList>
            </div>
            {isRenderSelectedPlanVisible() && (
                <RenderSelectedContentPlan
                    selectedMoviesId={selectedMoviesId}
                    selectedPlanApplyHandler={selectedPlanApplyHandler}
                    contentTabType={contentTabType}
                    selectedWebSeriesId={selectedWebSeriesId}
                    moviesDataList={moviesDataList?.data}
                    webseriesDataList={webSeriesDataList?.data}
                    plansList={plansList}
                ></RenderSelectedContentPlan>
            )}
        </>
    )
}
