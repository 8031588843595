import React, { useEffect, useState } from "react"
import Category from "../../Category"
import { CREATE_PAGE_DETAILS, VOD_SPORTS_TAB } from "../../Category/api/constant"
import { useDispatch, useSelector } from "react-redux"
import { getSportsCategory } from "../APIs/action"
import { createSportsTab } from "../APIs/helper"

export default function CreateManagePage() {
    const [tabs, setTabs] = useState([])
    const dispatch = useDispatch()

    const sportsCategoryList = useSelector((state) => state.sportsReducer?.sportsCategory)

    useEffect(() => {
        if (!sportsCategoryList || sportsCategoryList?.length < 1) {
            dispatch(getSportsCategory())
        }
    }, [])

    useEffect(() => {
        let tabs = createSportsTab(sportsCategoryList)
        setTabs(tabs)
    }, [sportsCategoryList])

    return (
        <Category
            tabs={tabs}
            pageDetails={CREATE_PAGE_DETAILS}
            parent={VOD_SPORTS_TAB.CREATE_MANAGE_PAGE}
        ></Category>
    )
}
