import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import DateRangeCalendar, { showCalendarDate } from "../../common/DateRangePicker"
import Accordion from "../../common/Accordion"
import { dateTime } from "../../../utils/common"

import "./DateCalendar.scss"

const DateCalendar = ({ startDate, endDate, dateSubmitHandler, name }) => {
    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: startDate ? dateTime(new Date(startDate))?.date : "",
        endDate: endDate ? dateTime(new Date(endDate))?.date : ""
    })
    const [isOpen, setIsOpen] = useState(false)

    const submitHandler = (ranges) => {
        let obj = {
            startDate: new Date(ranges?.startDate).getTime(),
            endDate: new Date(ranges?.endDate).getTime()
        }
        setSelectedDateRange({
            startDate: dateTime(new Date(ranges?.startDate))?.date,
            endDate: dateTime(new Date(ranges?.endDate))?.date
        })
        dateSubmitHandler(name, obj)
        setIsOpen(false)
    }

    const accordionExpandHandler = (val) => {
        setIsOpen(val)
    }

    const showCalendar = () => {
        return (
            <>
                <DateRangeCalendar
                    showSelectionButtons={true}
                    cancelButtonHandler={() => {
                        setIsOpen(false)
                    }}
                    submitBtnHandler={submitHandler}
                    startDate={selectedDateRange?.startDate}
                    endDate={selectedDateRange?.endDate}
                ></DateRangeCalendar>
            </>
        )
    }

    const accordionItems = [
        {
            title: showCalendarDate(selectedDateRange),
            content: showCalendar()
        }
    ]

    useEffect(() => {
        setSelectedDateRange({
            startDate: startDate ? dateTime(startDate)?.date : "",
            endDate: endDate ? dateTime(endDate)?.date : ""
        })
    }, [startDate, endDate])

    return (
        <>
            <Accordion
                items={accordionItems}
                accordionContainerClass={"vr-date-calendar-accordion"}
                isAccordionOpen={isOpen}
                accordionExpandHandler={accordionExpandHandler}
                reCenterAccordionPosition={true}
            />
        </>
    )
}

DateCalendar.propTypes = {
    onChange: PropTypes.func
}

export default DateCalendar
