import React, { Component } from "react"
import Button from "../../../common/Button"
import Calendar from "react-calendar"
import MaskedInput from "react-maskedinput"

import "react-calendar/dist/Calendar.css"
import "./style.scss"
import { errorMsg } from "../../api/constant"
import isEmpty from "lodash/isEmpty"
import isDate from "lodash/isDate"
import { BUTTON_CONSTANTS, TEXT_CONSTANTS } from "../../../../utils/constants"
import { connect } from "react-redux"
// import { getTimezoneBasedDate, getTimezoneBasedDateAndTime } from "../../../../utils/common";

class ScheduleRail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            finalStart: "",
            finalEnd: "",
            startDate: props?.startDate || "",
            endDate: props?.endDate || "",
            inputStartDate: props?.inputStartDate || "",
            inputEndDate: props?.inputEndDate || "",
            inputStartTime: props?.inputStartTime || "",
            inputEndTime: props?.inputEndTime || "",
            disable: true,
            err: {
                startDateErr: "",
                endDateErr: "",
                startTimeErr: "",
                endTimeErr: "",
                publishErr: ""
            }
        }
        // this.getTimezoneBasedDate = this.getMinimumDate()
    }

    validEndTime = (inputEndtime) => {
        const { startDate, endDate, inputStartTime } = this.state
        let sDate = new Date(startDate).setHours(0, 0, 0, 0)
        let eDate = new Date(endDate).setHours(0, 0, 0, 0)
        if (sDate === eDate) {
            if (inputStartTime < inputEndtime) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }
    inputTime = (e, type) => {
        let err = this.state.err
        let pattern = /^([01]\d|2[0-3]):?([0-5]\d)$/

        if (e.target.value.includes("_")) {
            if (type === "inputStartTime") {
                err.startTimeErr = errorMsg.Start_TIME_TEXT
            } else {
                err.endTimeErr = errorMsg.End_TIME_TEXT
            }
            this.setState(
                {
                    err
                },
                () => {
                    if (!this.isValidateAll()) {
                        this.setState({
                            disable: true
                        })
                    } else {
                        this.setState({
                            disable: false
                        })
                    }
                }
            )
        }
        if (!e.target.value.includes("_")) {
            if (pattern.test(e.target.value)) {
                if (type === "inputStartTime") {
                    err.startTimeErr = ""
                    this.setState(
                        {
                            inputStartTime: e.target.value,
                            err
                        },
                        () => {
                            if (!this.isValidateAll()) {
                                this.setState({
                                    disable: true
                                })
                            } else {
                                this.setState({
                                    disable: false
                                })
                            }
                        }
                    )
                } else {
                    let endTimeCheck = this.validEndTime(e.target.value)
                    if (endTimeCheck) {
                        err.endTimeErr = ""
                        this.setState(
                            {
                                inputEndTime: e.target.value,
                                err
                            },
                            () => {
                                if (!this.isValidateAll()) {
                                    this.setState({
                                        disable: true
                                    })
                                } else {
                                    this.setState({
                                        disable: false
                                    })
                                }
                            }
                        )
                    } else {
                        err.endTimeErr = errorMsg.END_GREATER_TO_START
                        this.setState(
                            {
                                err
                            },
                            () => {
                                if (!this.isValidateAll()) {
                                    this.setState({
                                        disable: true
                                    })
                                } else {
                                    this.setState({
                                        disable: false
                                    })
                                }
                            }
                        )
                    }
                }
            }
        }
    }
    getCalenderDetail = (detail) => {
        let date = new Date(detail).getDate()
        let month = new Date(detail).getMonth() + 1
        let year = new Date(detail).getFullYear()

        if (month < 10) {
            month = `0${month}`
        }
        if (date < 10) {
            date = `0${date}`
        }

        return { date, month, year }
    }

    onChange = (date) => {
        let startDate = this.getCalenderDetail(date[0])
        let endDate = this.getCalenderDetail(date[1])
        let err = this.state.err
        err.startDateErr = ""
        err.endDateErr = ""
        this.setState(
            {
                startDate: date[0],
                endDate: date[1],
                inputStartDate: `${startDate.date}/${startDate.month}/${startDate.year}`,
                inputEndDate: `${endDate.date}/${endDate.month}/${endDate.year}`,
                err
            },
            () => {
                if (!this.isValidateAll()) {
                    this.setState({
                        disable: true
                    })
                } else {
                    this.setState({
                        disable: false
                    })
                }
            }
        )
    }

    getMinimumDate = () => {
        // const { selectedTimezoneLabel } = this.props
        // return new Date(getTimezoneBasedDate(selectedTimezoneLabel?.timeZone));
        return new Date(new Date().toLocaleString("en-US", { timeZone: "GMT" }))
    }

    isTodayCheck = (date1, date2) => {
        // format: mm/dd/yyyy
        return date1.setHours(0, 0, 0, 0) >= date2.setHours(0, 0, 0, 0)
    }

    inputDate = (e, type) => {
        let pattern = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/
        let err = this.state.err
        if (pattern.test(e.target.value)) {
            let dt = e.target.value.split("/")
            let checkIfToday = this.isTodayCheck(
                new Date(`${dt[1]}/${dt[0]}/${dt[2]}`),
                // new Date(this.getTimezoneBasedDate)
                new Date()
            )
            if (checkIfToday) {
                if (type === "inputStartDate") {
                    err.startDateErr = ""
                    this.setState(
                        {
                            startDate: new Date(+dt[2], +dt[1] - 1, +dt[0]),
                            err
                        },
                        () => {
                            if (!this.isValidateAll()) {
                                this.setState({
                                    disable: true
                                })
                            } else {
                                this.setState({
                                    disable: false
                                })
                            }
                        }
                    )
                } else {
                    let isValidEndDate = this.isTodayCheck(
                        new Date(`${dt[1]}/${dt[0]}/${dt[2]}`),
                        this.state.startDate
                    )
                    if (isValidEndDate) {
                        err.endDateErr = ""
                        this.setState(
                            {
                                endDate: new Date(+dt[2], +dt[1] - 1, +dt[0]),
                                err
                            },
                            () => {
                                if (!this.isValidateAll()) {
                                    this.setState({
                                        disable: true
                                    })
                                } else {
                                    this.setState({
                                        disable: false
                                    })
                                }
                            }
                        )
                    } else {
                        err.endDateErr = errorMsg.End_START_DATE
                        this.setState(
                            {
                                endDate: "",
                                err
                            },
                            () => {
                                if (!this.isValidateAll()) {
                                    this.setState({
                                        disable: true
                                    })
                                } else {
                                    this.setState({
                                        disable: false
                                    })
                                }
                            }
                        )
                    }
                }
            } else {
                if (type === "inputStartDate") {
                    err.startDateErr = errorMsg.START_TODAY_OR_AFTER
                } else {
                    err.endDateErr = errorMsg.END_TODAY_OR_AFETR
                }
                this.setState(
                    {
                        err
                    },
                    () => {
                        if (!this.isValidateAll()) {
                            this.setState({
                                disable: true
                            })
                        } else {
                            this.setState({
                                disable: false
                            })
                        }
                    }
                )
            }
        } else {
            if (type === "inputStartDate") {
                err.startDateErr = errorMsg.VALID_START_DATE
            } else {
                err.endDateErr = errorMsg.VALID_END_DATE
            }
            this.setState(
                {
                    err
                },
                () => {
                    if (!this.isValidateAll()) {
                        this.setState({
                            disable: true
                        })
                    } else {
                        this.setState({
                            disable: false
                        })
                    }
                }
            )
        }
    }
    formateDateAndTime = () => {
        let { finalStart, finalEnd } = this.state
        let { handleSchedulePublish } = this.props
        this.setState({ disable: true })
        handleSchedulePublish(finalStart, finalEnd)
    }

    isValidateAll = () => {
        let err = this.state.err
        let { inputStartDate, inputStartTime, inputEndDate, inputEndTime, startDate, endDate } =
            this.state
        // const { selectedTimezoneLabel } = this.props
        if (
            !isEmpty(err.endDateErr) ||
            !isEmpty(err.startDateErr) ||
            !isEmpty(err.startTimeErr) ||
            !isEmpty(err.endTimeErr) ||
            isEmpty(inputStartDate) ||
            isEmpty(inputStartTime) ||
            isEmpty(inputEndDate) ||
            isEmpty(inputEndTime)
        ) {
            return false
        }
        // let currentTime = new Date(getTimezoneBasedDateAndTime(selectedTimezoneLabel?.timeZone));
        // const timezoneDate = getTimezoneBasedDate(selectedTimezoneLabel?.timeZone)
        let currentTime = new Date()
        let exactTime = currentTime.getTime()
        let startTimestamp
        let endTimestamp
        let finalStartValue
        let finalEndValue
        let isValidStartTime =
            !isEmpty(inputStartDate) && !isEmpty(inputStartTime) && isDate(startDate)
        let isValidEndTime = !isEmpty(inputEndDate) && !isEmpty(inputEndTime) && isDate(endDate)
        if (isValidStartTime) {
            let startTimeArr = inputStartTime.split(":")
            let startTime = startTimeArr[0]
            let startSec = startTimeArr[1]
            // finalStartValue = new Date(
            //   `${timezoneDate} ${startTime}:${startSec}`
            // ).toString();
            finalStartValue = new Date(
                Date.UTC(
                    startDate.getFullYear(),
                    startDate.getMonth(),
                    startDate.getDate(),
                    startTime,
                    startSec,
                    0
                )
            ).toUTCString()
            startTimestamp = new Date(finalStartValue).getTime()
            this.setState({
                finalStart: finalStartValue
            })
            if (exactTime <= startTimestamp) {
                err.startTimeErr = ""
            } else {
                err.startTimeErr = errorMsg.GMT_START_TIME
            }
        }
        if (isValidEndTime) {
            let endTimeArr = inputEndTime.split(":")
            let endTime = endTimeArr[0]
            let endSec = endTimeArr[1]
            // finalEndValue = new Date(
            //   `${timezoneDate} ${endTime}:${endSec}`
            // ).toString();
            finalEndValue = new Date(
                Date.UTC(
                    endDate.getFullYear(),
                    endDate.getMonth(),
                    endDate.getDate(),
                    endTime,
                    endSec,
                    0
                )
            ).toUTCString()
            endTimestamp = new Date(finalEndValue).getTime()
            this.setState({
                finalEnd: finalEndValue
            })
            if (exactTime <= endTimestamp && startTimestamp < endTimestamp) {
                err.endTimeErr = ""
            } else {
                err.endTimeErr = errorMsg.GMT_END_TIME
            }
        }
        this.setState({
            err,
            finalStart: finalStartValue,
            finalEnd: finalEndValue
        })
        if (err.startTimeErr || err.endTimeErr) {
            return false
        } else {
            return true
        }
    }

    render() {
        const { handleOnClose } = this.props
        const { disable } = this.state
        return (
            <div className="scheduleRail-container">
                <header className="">
                    {TEXT_CONSTANTS?.SCHEDULE_RAIL_PUBLISH}
                    <i className="icon-close" onClick={handleOnClose}></i>
                </header>

                <div className="scheduleRail-contents">
                    <section className="calender-container">
                        <span className="calender-head">{TEXT_CONSTANTS?.PICK_DATE}</span>
                        <Calendar
                            onChange={this.onChange}
                            value={[this.state.startDate, this.state.endDate]}
                            minDate={this?.getMinimumDate()}
                            selectRange={true}
                        />
                    </section>
                    <p className="note">
                        <span>*</span>
                        {errorMsg.GMT_TIME_PUBLISH}
                    </p>
                    <br></br>
                    <section className="DateTime">
                        <span>{TEXT_CONSTANTS?.START_DATE_TIME}</span>
                        <MaskedInput
                            mask="11/11/1111"
                            name="startDate"
                            placeholder="DD/MM/YYYY"
                            onChange={(e) => this.inputDate(e, "inputStartDate")}
                            value={this.state.inputStartDate}
                        />
                        <MaskedInput
                            className="maskTime"
                            mask="11:11"
                            name="startTime"
                            placeholder="HH:MM"
                            onChange={(e) => this.inputTime(e, "inputStartTime")}
                            disabled={false}
                            value={this.state?.inputStartTime}
                        />
                        <span className="scheduleErr">{this.state.err.startDateErr}</span>
                        <span className="scheduleErr">{this.state.err.startTimeErr}</span>
                    </section>
                    <section className="DateTime cs-dateTime">
                        <span>{TEXT_CONSTANTS?.END_DATE_TIME}</span>
                        <MaskedInput
                            mask="11/11/1111"
                            name="endDate"
                            placeholder="DD/MM/YYYY"
                            onChange={(e) => this.inputDate(e, "inputEndDate")}
                            value={this.state.inputEndDate}
                        />
                        <MaskedInput
                            className="maskTime"
                            mask="11:11"
                            name="endTime"
                            placeholder="HH:MM"
                            onChange={(e) => this.inputTime(e, "inputEndTime")}
                            disabled={false}
                            value={this.state?.inputEndTime}
                        />
                        <span className="scheduleErr">{this.state.err.endDateErr}</span>
                        <span className="scheduleErr">{this.state.err.endTimeErr}</span>
                        <span className="scheduleErr">{this.state.err.publishErr}</span>
                    </section>
                    <section className="btnContainer">
                        <Button
                            cName="btn primary-btn"
                            bValue={BUTTON_CONSTANTS?.SCHEDULE_NOW}
                            clickHandler={this.formateDateAndTime}
                            disabled={disable}
                        />
                        <Button
                            cName="btn cancel-btn"
                            bValue={BUTTON_CONSTANTS?.CANCEL}
                            clickHandler={handleOnClose}
                        />
                    </section>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        selectedTimezoneLabel: state?.timezone?.selectedTimezone
    }
}

export default connect(mapStateToProps)(ScheduleRail)
