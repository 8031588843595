export const drmConfigFields = {
    TOGGLE: {
        name: "Enable Drm"
    },
    DRM_TYPES: [
        {
            label: "HLS + FairPlay",
            name: "hlsFairPlay"
        },
        {
            label: "DASH + Widevine",
            name: "dashWidevine"
        },
        {
            label: "DASH + PlayReady",
            name: "dashPlayReady"
        }
    ],
    CONFIG_KEYS: [
        {
            name: "keyOsUrl",
            label: "Keyos URL",
            info: "Play Url"
        },
        {
            name: "keyOsUserKey",
            label: "Keyos - User Key",
            info: "User Key"
        },
        {
            name: "wideVineLicenseServerUrl",
            label: "Widevine License Server URL",
            info: "Widevine License Server URL"
        },
        {
            name: "playReadyLicenseServerUrl",
            label: "PlayReady License Server URL",
            info: "PlayReady License Server URL"
        },
        {
            name: "fairPlayLicenseServerUrl",
            label: "FairPlay License Server URL",
            info: "FairPlay License Server URL"
        },
        {
            name: "fairPlayCertificateUrl",
            label: "FairPlay Certificate URL",
            info: "FairPlay Certificate URL"
        }
    ],
    OTHER_SETTINGS: [
        {
            name: "hdcp",
            label: "Enable HDCP",
            children: [
                {
                    type: "radio-btn",
                    name: "anyHdcp",
                    label: "Any HDCP"
                },
                {
                    type: "radio-btn",
                    name: "hdcp2Dot2",
                    label: "HDCP 2.2"
                }
            ]
        },
        {
            name: "geoBlocking",
            label: "Geo Blocking",
            children: [
                {
                    type: "select",
                    name: "SELECT_COUNTRIES",
                    label: "Select countries"
                }
            ]
        },
        {
            name: "waterMarking",
            label: "Water Marking"
        },
        {
            name: "offlineDownloading",
            label: "Offline Download",
            children: [
                {
                    type: "inputText",
                    name: "offlineDownloadingExpire",
                    label: "Expire Days"
                }
            ]
        },
        {
            name: "streamingConcurrency",
            label: "Streaming Concurrency"
        }
    ],
    MAIN_LABELS: {
        DRM_TYPE_LABEL: "Select DRM Type/s",
        LICENCE_LABEL: "License Setting",
        OTHER_SETTING: "Other Setting"
    }
}
