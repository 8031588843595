import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import NoImage from "../../../assets/images/no_image.jpg"
import { imagePathPrefix } from "../../../config/apiPath"

import "./style.scss"
import Button from "../../common/Button"
import { BUTTON_CONSTANTS, PERMISSIONS } from "../../../utils/constants"
import { AuthWrapper } from "../../common/AuthWrapper"

/* admin work */
class TotalTenants extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isSuperAdmin: true
        }
    }

    viewTenant = () => {
        const { history } = this.props
        history.push("/tenant")
    }

    addTenant = () => {
        const { history } = this.props
        history.push("/tenant/add_tenant")
    }

    render() {
        const { data } = this.props
        return (
            <div className="total-tenant">
                <div className="tenant-detail-container">
                    <p> TOTAL TENANTS</p>
                    <div>
                        <AuthWrapper
                            permissions={[
                                PERMISSIONS?.TENANT_LIST_EDIT,
                                PERMISSIONS?.TENANT_LIST_VIEW
                            ]}
                        >
                            <Button
                                cName={`btn view-btn`}
                                clickHandler={() => this.viewTenant()}
                                bValue={BUTTON_CONSTANTS?.VIEW_ALL}
                            />
                        </AuthWrapper>
                        <AuthWrapper permissions={[PERMISSIONS?.ADD_TENANT_EDIT]}>
                            <Button
                                cName={`btn primary-btn`}
                                bValue={BUTTON_CONSTANTS?.ADD_NEW}
                                clickHandler={() => this.addTenant()}
                            />
                        </AuthWrapper>
                    </div>
                </div>
                <p className="tenant-count">{data?.total_count?.totalCount}</p>
                <div className="tenant-added-container">
                    <p className="sub-container">
                        <p className="heading">
                            {" "}
                            NEWLY ADDED +
                            <span className="newly-added-count">{data?.newly_added.length}</span>
                        </p>
                        {data?.newly_added &&
                            data?.newly_added.slice(0, 3).map((value, index) => {
                                return (
                                    <img
                                        className="tenant-image"
                                        src={
                                            !isEmpty(value?.logoPath)
                                                ? imagePathPrefix + value?.logoPath
                                                : NoImage
                                        }
                                        alt="tenant logo"
                                        onError={(e) => {
                                            e.target.onerror = null
                                            e.target.src = `${NoImage}`
                                        }}
                                    />
                                )
                            })}
                    </p>
                    <p className="sub-container">
                        <p className="heading">ACTIVE TENANT</p>
                        <p className="count">
                            <span className="status-dot"></span>
                            {data?.total_count?.activeCount}
                        </p>
                    </p>
                    <p className="sub-container">
                        <p className="heading">INACTIVE TENANT</p>
                        <p className="count">
                            <span className="status-dot"></span>
                            {data?.total_count?.inactiveCount}
                        </p>
                    </p>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({}, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TotalTenants)
