import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import isEmpty from "lodash/isEmpty"

import Input from "../common/Input"
import Button from "../common/Button"

import { BUTTON_CONSTANTS, PERMISSIONS, TEXT_CONSTANTS } from "../../utils/constants"

import { drmConfigFields } from "./drmJson"
import Checkbox from "../common/Checkbox"
import FileUpload from "../common/FileUpload"
import MultiSelect from "./multiselect"
import {
    saveDrmConfig,
    getCountries,
    getLicenseDer,
    getDrmConfig,
    getIsDrmConfig
} from "./APIs/action"

import "./style.scss"
import { checkIfUserHasAccess } from "../../utils/users"
import DerImage from "../../assets/images/der.svg"
import Unauthorized from "../common/Unauthorized"
import Loader from "../common/Loader"
import PageNotFound from "../common/PageNotFound"

class DrmConfiguration extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hdcpBtnState: {},
            isDrm: false,
            payload: { drm: false, otherSettings: {}, licenceSetting: {} },
            isLoading: false,
            isValidated: false
        }
        this.validated = false
        this.canUpdateStatus = null
        this.error = {}
    }

    componentDidMount() {
        this.canUpdateStatus = checkIfUserHasAccess([PERMISSIONS?.DRM_EDIT])
        this.loadHandler()
    }

    loadHandler = async () => {
        const { getCountries, getDrmConfig, getIsDrmConfig } = this.props
        const { payload } = this.state
        this.setState({
            isLoading: true
        })
        let response = await getDrmConfig()
        let isDrmResponse = await getIsDrmConfig()
        if (isDrmResponse) {
            payload.drm = isDrmResponse?.data
            this.setState({
                isDrm: isDrmResponse?.data,
                payload
            })
        }
        if (!isEmpty(response) && response?.data) {
            if (
                !response.data?.["otherSettings"]?.["countryCode"]?.[0]?.name ||
                !response.data?.["otherSettings"]?.["countryCode"]?.[0]?.code ||
                isEmpty(response.data?.["otherSettings"]?.["countryCode"])
            ) {
                if (response.data["otherSettings"]) {
                    response.data["otherSettings"]["countryCode"] = []
                } else {
                    response.data["otherSettings"] = {
                        countryCode: []
                    }
                }
            }
            this.setState(
                {
                    payload: response?.data,
                    isEdit: true
                },
                () => {
                    const isValidated = this.validatePayload(payload)
                    this.setState({ isValidated })
                }
            )
        } else {
            toast.error("Some error in get drm response")
        }
        let countriesList = await getCountries()
        if (countriesList?.data && countriesList?.status) {
            this.setState({
                countries: countriesList?.data
            })
        }
        this.setState({
            isLoading: false
        })
    }

    validatePayload = (payload) => {
        if (isEmpty(payload)) {
            return false
        }
        if (payload) {
            let isValidated = true
            console.log(
                "validation check",
                !isEmpty(payload?.keyOsUrl),
                !isEmpty(payload?.keyOsUserKey),
                !isEmpty(payload["licenceSetting"]?.["authDerFile"]),
                payload["licenceSetting"]?.["licenceDuration"]
            )
            if (
                !isEmpty(payload?.keyOsUrl) &&
                !isEmpty(payload?.keyOsUserKey) &&
                !isEmpty(payload["licenceSetting"]?.["authDerFile"]) &&
                payload["licenceSetting"]?.["licenceDuration"]
            ) {
                isValidated = this.validateDrmTypeFields(payload)
                return isValidated
            } else {
                return false
            }
        }
    }

    validateDrmTypeFields = (payload) => {
        let isDrmTypeValidation = true
        if (!payload?.hlsFairPlay && !payload?.dashWidevine && !payload?.dashPlayReady) {
            return false
        }
        if (
            payload?.hlsFairPlay &&
            (isEmpty(payload?.fairPlayLicenseServerUrl) || isEmpty(payload?.fairPlayCertificateUrl))
        ) {
            isDrmTypeValidation = false
        }
        if (payload?.dashWidevine && isEmpty(payload?.wideVineLicenseServerUrl)) {
            isDrmTypeValidation = false
        }
        if (payload?.dashPlayReady && isEmpty(payload?.playReadyLicenseServerUrl)) {
            isDrmTypeValidation = false
        }
        return isDrmTypeValidation
    }

    saveDrmConfig = async () => {
        const { payload } = this.state
        const isValidated = this.validatePayload(payload)
        const { saveDrmConfig } = this.props
        if (isValidated) {
            this.setState({
                isSaveLoading: true
            })
            const response = await saveDrmConfig(payload)
            this.setState({
                isSaveLoading: false
            })
            if (!isEmpty(response) && response?.status) {
                toast.success("Drm configuration updated!")
                this.setState({
                    payload
                })
            } else {
                toast.error("Some error in save drm response")
            }
        } else {
            toast.error("Please fill all the required fields")
        }
    }

    handleDrmToggle = () => {
        const { payload, isDrm } = this.state
        payload.drm = !isDrm?.isDRMEnabled
        const updatedDRM = { ...isDrm }
        updatedDRM.isDRMEnabled = !isDrm?.isDRMEnabled
        this.setState({
            isDrm: updatedDRM,
            payload
        })
    }

    handleChange = (name, value) => {
        const { payload } = this.state
        payload[name] = value
        this.setState({
            payload
        })
    }

    handleChangeLicense = (name, value) => {
        const { payload } = this.state
        payload["licenceSetting"] = payload["licenceSetting"] ? payload["licenceSetting"] : {}
        payload["licenceSetting"][name] = value
        this.setState({
            payload
        })
    }

    handleDrmChange = (name, checked) => {
        this.handleChange(name, checked)
    }

    handleOtherSettingChange = (name, checked, type) => {
        const { payload } = this.state
        payload["otherSettings"] = payload["otherSettings"] ? payload["otherSettings"] : {}
        if (name === "geoBlocking") {
            if (!checked) {
                payload["otherSettings"]["countryCode"] = []
            }
            // payload["otherSettings"][name] = {
            //   geoBlocking: checked,
            //   country: [],
            // };
            payload["otherSettings"][name] = checked
        } else if (name === "offlineDownloading") {
            if (!checked) {
                payload["otherSettings"]["offlineDownloadingExpire"] = null
            }
            payload["otherSettings"][name] = checked
        } else if (name === "hdcp") {
            if (!checked) {
                payload["otherSettings"]["hdcp"] = null
            }
        } else payload["otherSettings"][name] = checked
        this.setState({
            payload
        })
    }

    handleSelectChange = (name, value) => {
        const { payload } = this.state
        this.setState(
            {
                selectedCountry: value
            },
            () => {
                payload["otherSettings"]["countryCode"] = this.state?.selectedCountry
                this.setState({
                    payload
                })
            }
        )
    }

    fileUpload = async (data) => {
        if (!data) {
            this.handleChangeLicense("authDerFile", "")
            return
        }
        const { getLicenseDer } = this.props
        let response = await getLicenseDer(data)
        if (response?.data && response.status)
            this.handleChangeLicense("authDerFile", response?.data)
        else {
            this.handleChangeLicense("authDerFile", "")
        }
    }

    handleRadioBtn = (e) => {
        const { payload } = this.state
        payload["otherSettings"]["hdcp"] = {
            [e.target.name]: e.target.checked
        }
        this.setState({ payload })
    }

    handleInputChange = (name, value) => {
        const { payload } = this.state
        payload["otherSettings"]["offlineDownloadingExpire"] = value
        this.setState({ payload })
    }

    getToggleBtn = () => {
        const { isDrm } = this.state
        return (
            <label className={`switch-button`}>
                <input
                    type="checkbox"
                    name="switch"
                    onChange={(e) => this.handleDrmToggle()}
                    checked={!isDrm?.isDRMEnabled ? true : false}
                    disabled={true}
                />
                <div
                    className={`switch-slider switch-round ${
                        isDrm?.isDRMEnabled ? "icon-tick" : "icon-close"
                    }`}
                >
                    <span className="isChecked">{TEXT_CONSTANTS?.NO?.toUpperCase()}</span>
                    <span className="isUnChecked">{TEXT_CONSTANTS?.YES?.toUpperCase()}</span>
                </div>
            </label>
        )
    }

    renderChild = (ele, value) => {
        const { payload, countries } = this.state
        switch (ele.type) {
            case "radio-btn":
                return (
                    <div className="radio-btn-input">
                        <input
                            type="radio"
                            className="radio"
                            id={ele?.name}
                            name={ele?.name}
                            checked={
                                payload?.["otherSettings"]?.["hdcp"]?.[ele?.name]
                                    ? payload?.["otherSettings"]?.["hdcp"][ele?.name]
                                    : false
                            }
                            onChange={this.handleRadioBtn}
                        />
                        <span>{ele?.label}</span>
                    </div>
                )

            case "select":
                return (
                    <MultiSelect
                        key={"country"}
                        options={
                            countries &&
                            countries?.map((item) => ({
                                name: item.name,
                                code: item.code
                            }))
                        }
                        label="Select countries to allow"
                        required
                        placeholder={"Select"}
                        id={"country"}
                        optionName={"name"}
                        optionId={"code"}
                        isDisabled={!payload?.["otherSettings"]?.[value.name]}
                        name={"country"}
                        isOptionCheckbox={false}
                        selectedValues={payload["otherSettings"]?.["countryCode"]}
                        changeHandler={this.handleSelectChange}
                        errMsg={null}
                    />
                )

            case "inputText":
                return payload?.["otherSettings"]?.["offlineDownloading"] ? (
                    <div className="form-field">
                        <Input
                            type="text"
                            maxLength={2}
                            name={ele?.name}
                            inputType="Integer"
                            labelText={ele?.label}
                            required
                            disabled={!payload["otherSettings"]?.["offlineDownloading"]}
                            changeHandler={this.handleInputChange}
                            value={payload["otherSettings"]?.["offlineDownloadingExpire"]}
                            placeholder=""
                        />
                    </div>
                ) : null
            default:
                return null
        }
    }

    renderDRMFields = () => {
        const { payload, isEdit } = this.state

        return (
            <>
                <p className="label">{drmConfigFields?.MAIN_LABELS.DRM_TYPE_LABEL}</p>
                <div className="drm-checkbox-container">
                    {drmConfigFields?.DRM_TYPES.map((value, index) => {
                        return (
                            <Checkbox
                                cName="drm-checkbox"
                                labelText={value.label}
                                id={index}
                                isChecked={payload[value.name]}
                                name={value.name}
                                changeHandler={this.handleDrmChange}
                            />
                        )
                    })}
                </div>
                <div className="config-fields-container">
                    {drmConfigFields?.CONFIG_KEYS.map((value, index) => {
                        return (
                            <div className="form-field">
                                <Input
                                    type="text"
                                    maxLength={130}
                                    name={value.name}
                                    labelText={value.label}
                                    infoText={value?.info}
                                    id={index}
                                    isInfo
                                    required
                                    changeHandler={this.handleChange}
                                    value={payload[value.name]}
                                    placeholder=""
                                />
                            </div>
                        )
                    })}
                </div>
                <div className="licence-setting-container">
                    <p className="label">{drmConfigFields?.MAIN_LABELS.LICENCE_LABEL}</p>
                    <div className="sub-container">
                        <FileUpload
                            fileUpload={this.fileUpload}
                            cName="file"
                            name="authDerFile"
                            label="Auth DER File"
                            image={DerImage}
                            required
                            value={payload?.["licenceSetting"]?.["authDerFile"]}
                        />
                        <div className="form-field">
                            <Input
                                type="text"
                                name={"licenceDuration"}
                                labelText={"Duration in seconds"}
                                required
                                changeHandler={this.handleChangeLicense}
                                value={payload?.["licenceSetting"]?.["licenceDuration"]}
                                cName="duration"
                                inputType="Integer"
                                maxLength={30}
                            />
                        </div>
                    </div>
                </div>
                <div className="other-setting-container">
                    <p className="label setting-label">
                        {drmConfigFields?.MAIN_LABELS.OTHER_SETTING}
                    </p>
                    <div className="drm-checkbox-container">
                        {drmConfigFields?.OTHER_SETTINGS.map((value, index) => {
                            return (
                                <>
                                    <Checkbox
                                        cName="drm-checkbox"
                                        labelText={value.label}
                                        id={index}
                                        name={value.name}
                                        isChecked={payload?.["otherSettings"]?.[value.name]}
                                        changeHandler={this.handleOtherSettingChange}
                                    />
                                    <div className="child-container">
                                        {value?.children?.map((ele, index) => {
                                            return this.renderChild(ele, value)
                                        })}
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
                <Button
                    bValue={isEdit ? BUTTON_CONSTANTS.UPDATE : BUTTON_CONSTANTS.SAVE}
                    clickHandler={this.saveDrmConfig}
                    disabled={!this.validated}
                    cName={`btn primary-btn`}
                />
            </>
        )
    }

    displayLoader = () => {
        return <Loader />
    }

    renderDRMComponent = () => {
        const { isDrm, isSaveLoading } = this.state

        return isDrm?.isDRMEnabled ? (
            <div className="vr-content-holder ">
                {isDrm?.isDRMConfigured ? (
                    <div className="drm-container">
                        {isSaveLoading && this.displayLoader()}

                        <div className="toggle-container">
                            <p className="label">{drmConfigFields?.TOGGLE.name}</p>
                            {this.getToggleBtn()}
                        </div>
                        {this.renderDRMFields()}
                    </div>
                ) : (
                    <PageNotFound heading={TEXT_CONSTANTS?.DRM_NOT_CONFIGURED}></PageNotFound>
                )}
            </div>
        ) : (
            <Unauthorized isErrorCodeRequired={false} isErrorMsgRequired={false}></Unauthorized>
        )
    }

    render() {
        const { isLoading, payload } = this.state
        this.validated = this.validatePayload(payload)
        return <>{isLoading ? <Loader /> : this.renderDRMComponent()}</>
    }
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(
            {
                saveDrmConfig,
                getCountries,
                getLicenseDer,
                getDrmConfig,
                getIsDrmConfig
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DrmConfiguration)
