import { PERMISSIONS } from "../../../../utils/constants"

export const ACTION = {
    CREATE_TENANT: "CREATE_TENANT",
    FETCH_TENANT: "FETCH_TENANT",
    IS_EXISTS: "IS_EXISTS",
    UPLOAD_LOGO: "UPLOAD_LOGO",
    DEACTIVATE_TENANT: "DEACTIVATE_TENANT",
    EXIST_DOMAIN: "EXIST_DOMAIN",
    EXIST_EMAIL_MOBILE: "EXIST_EMAIL_MOBILE"
}

export const MenuDesignHeading = [
    { key: "", permissions: [] },
    { key: "Name", value: "appName", permissions: [] },
    { key: "Client Name", value: "tenantAdminName", permissions: [] },
    { key: "Email", value: "tenantAdminEmail", permissions: [] },
    { key: "Number", value: "tenantAdminContact", permissions: [] },
    /*   { key: "Payment Due Date" }, */
    { key: "Onboard Date", value: "createdDate", permissions: [] },
    { key: "Status", value: "currentStatus", permissions: [] },
    {
        key: "Actions",
        value: "",
        permissions: [PERMISSIONS?.ADD_TENANT_EDIT, PERMISSIONS?.ADD_TENANT_VIEW]
    }
]

export const ON_BORDED_STATE = {
    INPROGRESS: "progress-color",
    FAILED: "failed-color",
    COMPLETED: "done-color"
}

export const CURRENT_STATE = {
    ACTIVE: "active-color",
    INACTIVE: "done-color"
}
