import DrmServiceInstance from "./service"
import { toast } from "react-toastify"
import AuditTrailInstance from "./service"

export const getAuditDropdowns = (params) => {
    return (dispatch) => {
        return AuditTrailInstance.getAuditDropdowns(params)
            .then(async (response) => {
                return response.data?.data
            })
            .catch((error) => {
                console.log("getAuditDropdowns error " + error?.message)
                return error
            })
    }
}

export const searchAuditList = (params) => {
    return (dispatch) => {
        return AuditTrailInstance.searchAuditList(params)
            .then(async (response) => {
                return response.data?.data
            })
            .catch((error) => {
                console.log("searchAuditList error " + error?.message)
                return error
            })
    }
}

export const fetchDetails = (params) => {
    return (dispatch) => {
        return AuditTrailInstance.fetchDetails(params)
            .then(async (response) => {
                return response.data?.data
            })
            .catch((error) => {
                console.log("fetchDetails error " + error?.message)
                return error
            })
    }
}
