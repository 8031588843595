import React from "react"
import Select, { components } from "react-select"
import "./style.scss"
import { connect } from "react-redux"

const MultiValueContainer = (props) => {
    const selected = props?.selectProps?.value
    let content='';
    if(props?.index === 0 ){
        content = ((length) => {
           switch (length) {
               case 0:
                   return props?.selectProps?.placeholder
               case 1:
                   return selected?.[0]?.name
               default:
                   return `${selected?.length ?? 0} Selected`
           }
       })(selected.length)
    }

    return <components.MultiValueContainer {...props}>{content}</components.MultiValueContainer>
}

const Option = (props) => {
    return (
        <components.Option {...props}>
            <span className="checkboxContainer">
                <span
                    className={props.isSelected ? "checkboxStyleActive" : "checkboxStyleDisable"}
                    id={`${props.isSelected ? "tue" : "fal"}`}
                />{" "}
            </span>
            <label className={`option-label ${props.isSelected && "selectedOptionLabel"}`}>
                {props.label}
            </label>
        </components.Option>
    )
}

class CustomSelect extends React.Component {

     customDropdownStyles = {
        option: (styles) => {
            const bgColorObj = this.props?.isDarkMode ? "#322c5a" : "#DEEBFF"
            return {
                ...styles,
                "&:hover": {
                    backgroundColor: bgColorObj
                },
                backgroundColor: 'transparent',
            }
        },
        
  }

    handleChange = (selectedOptions, action) => {
        const { changeHandler, id } = this.props
        if (selectedOptions) {
            changeHandler(id, selectedOptions)
        } else {
            changeHandler(id, [])
        }
    }

    render() {
        const {
            options,
            label,
            required,
            id,
            placeholder,
            selectedOptions,
            selectedValues,
            errMsg,
            isOptionCheckbox,
            showValueOnSelect,
            isDisabled,
            optionName,
            optionId
        } = this.props

        return (
            <div className={`multi-select-container ${errMsg ? `inputErr` : ""}`}>
                {label && (
                    <label htmlFor={id}>
                        {label}
                        <span className={required ? "required" : "notRequired"}>*</span>
                    </label>
                )}
                <Select
                    options={options}
                    placeholder={placeholder}
                    defaultValue={selectedOptions}
                    value={selectedValues && selectedValues.length ? selectedValues : []}
                    onChange={this.handleChange}
                    isMulti
                    isDisabled={isDisabled}
                    components={
                        isOptionCheckbox
                            ? showValueOnSelect
                                ? {
                                      Option: isOptionCheckbox ? Option : null
                                      // DropdownIndicator: () => null,
                                      // IndicatorSeparator: () => null,
                                  }
                                : {
                                      MultiValue: MultiValueContainer,
                                      Option: isOptionCheckbox ? Option : null
                                      // DropdownIndicator: () => null,
                                      // IndicatorSeparator: () => null,
                                  }
                            : {
                                  MultiValue: MultiValueContainer
                              }
                    }
                    id={id}
                    closeMenuOnSelect={false}
                    // openMenuOnClick={true}
                    hideSelectedOptions={false}
                    isSearchable={true}
                    className={"multiSelect-container"}
                    classNamePrefix={"multiSelect"}
                    getOptionLabel={(options) => (optionName ? options[optionName] : options.name)}
                    getOptionValue={(options) => (optionId ? options[optionId] : options.id)}
                    styles={this.customDropdownStyles}

               />
                {errMsg && <span className="err">{errMsg}</span>}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isDarkMode: state?.theme?.darkMode
    }
}


export default connect(mapStateToProps)(CustomSelect)