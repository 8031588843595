import { removeLoader, setLoader } from "../../common/Loader/api/action"
import MetadataIngestionSerivceInstance from "./service"

export const submitContentType = (data, timezone) => {
    return (dispatch) => {
        dispatch(setLoader())
        return MetadataIngestionSerivceInstance.submitContentType(
            data,
            timezone
        )
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in submitContentType ", error)
            })
            .finally(() => {
                dispatch(removeLoader())
            })
    }
}

export const downloadTemplateFile = (queryParams) => {
    return () => {
        return MetadataIngestionSerivceInstance.downloadTemplateFile(
            queryParams
        )
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in downloadTemplateFile ", error)
            })
    }
}

export const getMetadataIngestionRecords = (payloadData) => {
    return () => {
        return MetadataIngestionSerivceInstance.getMetadataIngestionRecords(
            payloadData
        )
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in downloadTemplateFile ", error)
            })
    }
}

export const getSuccessFailureRecords = (status, summaryId) => {
    return () => {
        return MetadataIngestionSerivceInstance.getSuccessFailureRecords(
            status,
            summaryId
        )
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in downloadTemplateFile ", error)
            })
    }
}

export const downloadFailureRecords = (summaryId) => {
    return () => {
        return MetadataIngestionSerivceInstance.downloadFailureRecords(
            summaryId
        )
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in downloadTemplateFile ", error)
            })
    }
}

export const getAllTimezone = () => {
    return (dispatch) => {
        dispatch(setLoader())
        return MetadataIngestionSerivceInstance.getAllTimezone()
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in submitContentType ", error)
            })
            .finally(() => {
                dispatch(removeLoader())
            })
    }
}
