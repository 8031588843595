import ApiService from "../../../utils/apiService"
import { API_ROOT_PATH, FAST_CHANNEL_API_PATH } from "../../../config/apiPath"

class CategoryManagementService {
    fetchPlatformList() {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/editorial/v2/platforms/`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
    createCategory(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/editorial/category/create/bulk`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }
    updateCategory(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/editorial/category/create`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }
    fetchAllCategory(params) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/editorial/category/`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
    fetchCategory(params) {
        const options = {
            method: "GET",
            // url: `${API_ROOT_PATH}/editorial/category/listByPlatformId?platformId=${params}`,
            url: `${API_ROOT_PATH}/editorial/category/listByPlatformId?platformId=${params}&published=true`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
    fetchCategoryById(params) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/editorial/category/${params}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
    deleteCategory(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/editorial/category/softDelete/${params}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
    unpublishCategory(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/editorial/category/deactivate/${params}`,
            data: params
        }
        return ApiService(options)
    }
    createRail(params, TIMEZONE_VAL) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/editorial/rail/v2/create`,
            isAuthRequired: true,
            data: params,
            headers: { "X-TIMEZONE": TIMEZONE_VAL }
        }
        return ApiService(options)
    }
    autoShuffleCreateRail(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/editorial/rail/validate-auto-shuffle`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }
    fetchFilteredRailById(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/core/v3/data/railList`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }

    fetchRailById(params) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/core/v3/data/fetchRail?id=${params}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
    fetchContentByID(params) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/entityValue/fetch/${params}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
    deactivateRailById(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/editorial/rail/v2/publish`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }
    softDeleteRail(id) {
        const options = {
            method: "DELETE",
            url: `${API_ROOT_PATH}/editorial/rail/v2/delete`,
            isAuthRequired: true,
            data: id
        }
        return ApiService(options)
    }

    fetchContentTypeList(params) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/content-entity/names`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
    fetchRailTypeList(params) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/editorial/rail/v2/rail-type`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
    fetchFilters(params) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/entityValue/categoryType?categoryType=${params}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
    searchContent(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/core/data/v2/content`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }
    railReposition(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/editorial/rail/v2/reposition`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }
    getLatestPosition(params) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/editorial/rail/v2/last-position`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }
    uploadImage(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/core/document/upload`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }
    fetchDummyRail(params) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/editorial/rail/systemGenerated`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }

    changeUploadImage(params) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/editorial/rail/getContentImageHistory?contentId=${params}`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }

    refreshRails() {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/editorial/rail/v2/refresh-rails`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    deleteContentImageHistory(historyId) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/editorial/rail/deleteContentImageHistory?historyId=${historyId}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    fetchFastChannel() {
        const options = {
            method: "GET",
            url: `${FAST_CHANNEL_API_PATH}/api/program/channel/listing`,
            isAuthRequired: true
            // data: {},
        }
        return ApiService(options)
    }

    fetchCertification() {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/ageGrading/fetchCertification`,
            isAuthRequired: true
            // data: {},
        }
        return ApiService(options)
    }
    fetchAgeGrading() {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/ageGrading/getAgeGrading`,
            isAuthRequired: true
            // data: {},
        }
        return ApiService(options)
    }
    fetchSystemGenerateFav() {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/editorial/v1/content-types/favourites`,
            isAuthRequired: true
            // data: {},
        }
        return ApiService(options)
    }
    publishUnpublishPage(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/editorial/v1/page-categories/publish`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }
}

const CategoryManagementServiceInstance = new CategoryManagementService()
export default CategoryManagementServiceInstance
