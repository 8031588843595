import { ACTION } from "./constants"
import StatsServiceInstance from "./service"

//action to fetch content
export const fetchAdminDashboard = (params) => {
    return (dispatch) => {
        return StatsServiceInstance.fetchAdminDashboard(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_DASHBOARD,
                    apiResponse: response.data
                })
                /*       response.data.data["user_by_device"] = {
          android_app: {
            inputValues: ["2.2M", "100"],
            name: "Android App",
          },
          IOS_app: {
            inputValues: ["2.1M", "1"],
            name: "IOS App",
          },
          Web: {
            inputValues: ["2.4M", "120"],
            name: "web App",
          },
        }; */
                return response.data
            })
            .catch((error) => {
                let response = {
                    data: {
                        allTenant: {
                            totalCount: 18,
                            total_count: {
                                activeCount: 10,
                                inactiveCount: 3
                            },
                            newly_added: [
                                {
                                    logoPath: ""
                                },
                                {
                                    logoPath: ""
                                },
                                {
                                    logoPath: ""
                                }
                            ]
                        },
                        all_tenant_subsciber: {
                            totalCount: "27K",
                            totalCountValue: 270000,
                            last_month_data: {
                                new_user: "20K",
                                drop_user: "5K"
                            },
                            pie: [
                                {
                                    tenantName: "A",
                                    count: "10K",
                                    countValue: 10000
                                },
                                {
                                    tenantName: "B",
                                    count: "5K",
                                    countValue: 5000
                                },
                                {
                                    tenantName: "C",
                                    count: "10K",
                                    countValue: 4000
                                },
                                {
                                    tenantName: "D",
                                    count: "10K",
                                    countValue: 3000
                                }
                            ]
                        },
                        session_by_tenant: {
                            today: {
                                GOLD_GYM: 100,
                                STRENGTHA_GYM: 200,
                                GOLDB_GYM: 500,
                                STRENGTHB_GYM: 600,
                                GOLDC_GYM: 300,
                                STRENGTHC_GYM: 100
                            },

                            last_7_days: {
                                GOLD_GYM: 500,
                                STRENGTHA_GYM: 600,
                                GOLDB_GYM: 400,
                                STRENGTHB_GYM: 100,
                                GOLDC_GYM: 200,
                                STRENGTHC_GYM: 100
                            },

                            last_30_days: {
                                GOLD_GYM: 400,
                                STRENGTHA_GYM: 100,
                                GOLDB_GYM: 300,
                                STRENGTHB_GYM: 100,
                                GOLDC_GYM: 600,
                                STRENGTHC_GYM: 100
                            }
                        },
                        tenant_across_world: {
                            by_country: {
                                summary: [
                                    {
                                        country: "India",
                                        count: "10"
                                    },
                                    {
                                        country: "Afghanistan",
                                        count: "10"
                                    }
                                ],
                                details: {
                                    India: [
                                        {
                                            country: "India",
                                            tenant_name: "gold",
                                            logPath: "",
                                            userCount: "20K",
                                            userCountValue: "20000",
                                            branchCount: "10"
                                        },
                                        {
                                            country: "India",
                                            tenant_name: "gold",
                                            logPath: "",
                                            userCount: "10K",
                                            userCountValue: "10000",
                                            branchCount: "10"
                                        },
                                        {
                                            country: "India",
                                            tenant_name: "gold",
                                            logPath: "",
                                            userCount: "10K",
                                            userCountValue: "10000",
                                            branchCount: "10"
                                        }
                                    ],
                                    Afghanistan: [
                                        {
                                            country: "Afghanistan",
                                            tenant_name: "Gold gym",
                                            logPath: "",
                                            userCount: "10K",
                                            userCountValue: "10000",
                                            branchCount: "10"
                                        }
                                    ]
                                }
                            },
                            by_tenant: {}
                        },
                        user_by_device: {
                            android_app: {
                                inputValues: ["2.2M", "100"],
                                name: "Android App"
                            },
                            IOS_app: {
                                inputValues: ["2.1M", "1"],
                                name: "IOS App"
                            },
                            Web: {
                                inputValues: ["2.4M", "120"],
                                name: "web App"
                            }
                        },

                        upcomming_activity: [
                            {
                                day: "05 MON",
                                start_time: "06:00AM",
                                end_time: "08:30AM",
                                activity:
                                    "Special Activity Session For Fitness",
                                tenant_logo: "",
                                instructor: ""
                            },
                            {
                                day: "06 MON",
                                start_time: "06:00AM",
                                end_time: "08:30AM",
                                activity:
                                    "Special Activity Session For Fitness",
                                tenant_logo: "",
                                instructor: ""
                            },
                            {
                                day: "07 MON",
                                start_time: "06:00AM",
                                end_time: "08:30AM",
                                activity:
                                    "Special Activity Session For Fitness",
                                tenant_logo: "",
                                instructor: ""
                            },
                            {
                                day: "09 MON",
                                start_time: "06:00AM",
                                end_time: "08:30AM",
                                activity:
                                    "Special Activity Session For Fitness",
                                tenant_logo: "",
                                instructor: ""
                            }
                        ]
                    }
                }
                dispatch({
                    type: ACTION.FETCH_DASHBOARD,
                    apiResponse: response
                })
                console.log("Got error in getting form json ", error)
                return response
            })
    }
}
