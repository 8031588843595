import { imagePathPrefix } from "../../../config/apiPath"

export const ACTION = {
    FETCH_MENU_OPTIONS: "FETCH_MENU_OPTIONS",
    CREATE_MENU: "CREATE_MENU",
    FETCH_MENU: "FETCH_MENU",
    FETCH_PLATFORM: "FETCH_PLATFORM",
    DELETE_MENU: "DELETE_MENU",
    DELETE_CONTENT: "DELETE_CONTENT",
    UPLOAD_IMAGE: "UPLOAD_IMAGE",
    DELETE_TAB: "DELETE_TAB"
}
export const imgPathPrefix = imagePathPrefix
export const subMenu = [
    {
        id: "CMS",
        name: "CMS"
    },
    {
        id: "editorial",
        name: "editorial"
    },
    {
        id: "sub_menu",
        name: "Sub Menu"
    }
]

export const MenuDesignHeading = [
    { key: "Serial No." },
    { key: "Menu Icon" },
    { key: "Tab Name" },
    { key: "Type" },
    { key: "Link TO Page" },
    { key: "Placement" }
]

export const menuList = [
    {
        tabName: "Home",
        type: "Editorial",
        linkToPage: "Home",
        placement: ["Hamburger", "Tab"]
    },
    {
        tabName: "Movies",
        type: "Editorial",
        linkToPage: "Movies",
        placement: ["Hamburger", "Tab"]
    },
    {
        tabName: "Others",
        type: "Editorial",
        linkToPage: "Others",
        placement: ["Hamburger", "Tab"]
    }
]
