import React from "react"
import Input from "../../../common/Input"
import "./style.scss"
import Checkbox from "./Checkbox"

function SearchBox(props) {
    const {
        label,
        options,
        required,
        id,
        selectedValues,
        searchedText,
        handleSearchBox,
        handleCheckBoxChange,
        isChecked,
        handleDeleteChange
    } = props
    const optionsList = options
        ? options.map((option) => {
              return (
                  <li className="actor-list" key={option.id}>
                      <Checkbox
                          image={option.url}
                          id={option.id}
                          labelText={option.name}
                          checked={isChecked(option.id, id) && true}
                          name={option.name}
                          changeHandler={(e) =>
                              handleCheckBoxChange(e, option.id)
                          }
                      />
                  </li>
              )
          })
        : null
    return (
        <div className="searchBox-container">
            {label && (
                <label htmlFor={id}>
                    {label}
                    <span
                        className="required"
                        style={{ display: required ? "inline" : "none" }}>
                        *
                    </span>
                </label>
            )}
            <div className="searchBox-content">
                <div className="search-section">
                    <div className="searchBar">
                        <span className="icon-search" />
                        <Input
                            type="text"
                            changeHandler={handleSearchBox}
                            name="searchedText"
                            cName="searchInput"
                            value={searchedText}
                        />
                    </div>
                    <div className="options-list">
                        <ul>
                            {
                                // (!searchValues.length)?
                                //     actors.map((item,index)=>{
                                //     return <li className='actor-list' key={index}>
                                //                 <Checkbox
                                //                     image={item.image}
                                //                     labelText={item.name}
                                //                     checked={isChecked(item.name)&&true}
                                //                     name={item.name}
                                //                     changeHandler={handleCheckBoxChange}
                                //                 />
                                //             </li>
                                //     })
                                // :
                                // searchValues.map((item,index)=>{
                                //     return <li className='actor-list' key={index}>
                                //                 <Checkbox
                                //                     image={item.image}
                                //                     labelText={item.name}
                                //                     checked={isChecked(item.name)&&true}
                                //                     name={item.name}
                                //                     changeHandler={handleCheckBoxChange}
                                //                 />
                                //             </li>
                                // })
                                optionsList
                            }
                        </ul>
                    </div>
                </div>
                <div className="tag-section">
                    <label>Selected ({selectedValues.length})</label>
                    <div className="tag-list">
                        {selectedValues.map((item, index) => {
                            return (
                                <div key={index} className="label">
                                    <div className="label-left">
                                        <div className="actor-img">
                                            <img
                                                src={item.url}
                                                alt={item.name}
                                            />
                                        </div>
                                        {item.name}
                                    </div>
                                    <div
                                        className="label-right"
                                        onClick={(e) =>
                                            handleDeleteChange(e, item, id)
                                        }>
                                        x
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchBox
