import React from "react"
import Loader from "../../assets/images/loader.gif"
import { configFields } from "./config"
import Input from "../common/Input"
import Button from "../common/Button"
import { BUTTON_CONSTANTS } from "../../utils/constants"

const BasicConfig = (props) => {
    const { handleChange, handleSubmit, isLoading, getToggleBtn, payload } = props
    return (
        <div className="basic-config-container">
            {isLoading && <img id={"loader"} src={Loader} alt="loader" />}
            <div className="toggle-container">
                <p className="label">{"Subheading enable"}</p>
                {getToggleBtn()}
            </div>
            <div className="config-fields">
                {configFields.map((value, index) => {
                    return (
                        <div className="form-field">
                            <Input
                                className="inputField"
                                type="text"
                                changeHandler={handleChange}
                                name={value?.name}
                                maxLength={200}
                                errMsg={null}
                                value={payload[value?.name]}
                                labelText={value.label}
                            />
                        </div>
                    )
                })}
                <div className="form-field">
                    <Button
                        bValue={BUTTON_CONSTANTS?.SUBMIT}
                        clickHandler={handleSubmit}
                        cName={`btn primary-btn`}
                    />
                </div>
            </div>
        </div>
    )
}

export default BasicConfig
