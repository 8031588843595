import ApiService from "../../../utils/apiService"
import { API_ROOT_PATH } from "../../../config/apiPath"
import moment from "moment-timezone"
/* eslint-disable */
class EpgServices {
    fetchEpgContentLiveTV() {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/content/fetch-live-tv`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    addEpgShow(payload, channelId, TIMEZONE_VAL) {
        // let TIMEZONE = new Date().toString().match(/([A-Z]+[\+-][0-9]+)/)?.[1];
        // let OFFSET = 330;
        // if (TIMEZONE_VAL) {
        //   TIMEZONE = TIMEZONE_VAL;
        //   OFFSET = TIMEZONE_VAL?.offset;
        // }
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/epg/v3/shows?channelId=${channelId}`,
            isAuthRequired: true,
            data: payload,
            headers: { "X-TIMEZONE": TIMEZONE_VAL }
        }
        return ApiService(options)
    }

    uploadImage(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/core/document/upload`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }

    fetchAddEpgOptionData() {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/epg-masterData/`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    fetchEpgTvShow(channelId, date, TIMEZONE) {
        if (!TIMEZONE) {
            TIMEZONE = new Date().toString().match(/([A-Z]+[\+-][0-9]+)/)?.[1]
        }
        date = moment(date).format("DD-MM-yyyy")

        console.log("timezone", TIMEZONE, date)
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/epg/v2/shows?channelId=${channelId}&currentDate=${date}`,
            isAuthRequired: true,
            headers: { "X-TIMEZONE": TIMEZONE }
        }
        return ApiService(options)
    }

    softDeleteEpgShow(id) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/epg-show/delete/${id}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
}

const EpgServicesInstance = new EpgServices()
export default EpgServicesInstance
