import ApiService from "../../../utils/apiService"
import { API_ROOT_PATH } from "../../../config/apiPath"

class ProductPlacementService {
    fetchProductList() {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/content/`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
    fetchAdsClipList() {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/content/groupByTimeFrame/Ads`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
    fetchAdsPreviewUrl(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/content/fetchAdUrl/`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }
    savePreviewUrl(data, id) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/content?entityId=${id}`,
            isAuthRequired: true,
            data: data
        }
        return ApiService(options)
    }
    sendMagnaRequest(data) {
        const options = {
            method: "POST",
            url: `https://demo-ottsandbox.magnaquest.com/Deploy/PortalIntegration.aspx`,
            // isAuthRequired: true,
            data: data
        }
        return ApiService(options)
    }
}

const ProductPlacementServiceInstance = new ProductPlacementService()
export default ProductPlacementServiceInstance
