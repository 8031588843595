export const ACTION = {
    FETCH_LANGUAGE_DATA: "FETCH_LANGUAGE_DATA"
}

export const multilingualLabels = {
    heading: "Select language/s",
    subheading:
        "in which you prefer your content to be presented to the end users.",
    preferedLanguageTxt: "Your preferred language is not in the list?",
    addNowBtnTxt: "+ ADD NOW",
    saveTxt: "Save",
    confirmTxt: "Confirm",
    cancelTxt: "Cancel",
    newLanguageTxt: "Enter the language name",
    placeholderTxt: "Enter here",
    confirmationTxtHeading: "Deselecting the language?",
    confirmationTxtDes:
        "Please confirm whether you want to deselect this language.",
    prefillDataConfirmation:
        "You have not entered data in all the language fields. So, the English language data will be prefilled into blank language fields. Do you want to continue?"
    // prefillDataConfirmation:
    //   "You have not entered data in all the languages. The English language data will be prefilled into blank fields. Do you want to continue?",
}
