import ApiService from "../../../utils/apiService"
import { API_ROOT_PATH } from "../../../config/apiPath"

class PipelineService {
    initializeMultipartUpload(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/pvt/v1/transcoding/s3/multipart-upload/initialize?fileName=${params}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    getMultipartPreSignedUrls(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/pvt/v1/transcoding/s3/multipart-upload/presigned-url`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }
    complete(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/pvt/v1/transcoding/s3/multipart-upload/complete`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }
    abort(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/pvt/v1/transcoding/s3/multipart-upload/abort`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }
    saveCustomProfile(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/pvt/v1/transcoding/customProfile/upload`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }
    saveChooseProtocol(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/pvt/v1/transcoding/customProfile/upload`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }

    getUploadedContent(params) {
        let TIMEZONE = new Date().toString().match(/([A-Z]+[\\+-][0-9]+)/)?.[1]
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/pvt/v1/transcoding/s3/listAll`,
            isAuthRequired: true,
            data: params,
            headers: { "X-TIMEZONE": TIMEZONE }
        }
        return ApiService(options)
    }
    getTranscodedContent(params) {
        let TIMEZONE = new Date().toString().match(/([A-Z]+[\\+-][0-9]+)/)?.[1]
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/pvt/v1/transcoding/job/all`,
            isAuthRequired: true,
            data: params,
            headers: { "X-TIMEZONE": TIMEZONE }
        }
        return ApiService(options)
    }
    deleteUploadedContent(id) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/pvt/v1/transcoding/s3/delete/${id}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
    deleteTranscodedContent(id) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/pvt/v1/transcoding/job/delete/${id}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    startTranscoding(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/pvt/v1/transcoding/start`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }
    fitlerData() {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/pvt/v1/transcoding/state/all`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    getDRMToken(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/v1/drm`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }

    getPipelineSettings(params) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/pvt/v1/transcoding/view/settings/${params}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    cancelTranscodingContent(id) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/pvt/v1/transcoding/cancel-transcoding`,
            isAuthRequired: true,
            data: id
        }
        return ApiService(options)
    }

    fetchLicenseDetails(serverLicense, body, headers) {
        const options = {
            method: "POST",
            url: `${serverLicense}`,
            isAuthRequired: false,
            body: body,
            headers: {
                "Content-type": "application/x-www-form-urlencoded",
                ...headers
            }
        }
        return ApiService(options)
    }
}

const PipelineServiceInstance = new PipelineService()
export default PipelineServiceInstance
