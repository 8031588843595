import React, { Component } from "react"
import ReactPlayer from "react-player"

import NoImgAvailable from "../../../assets/images/no_img_available.png"
import "./style.scss"
import { AuthWrapper } from "../../common/AuthWrapper"
import { PERMISSIONS } from "../../../utils/constants"

class Preview extends Component {
    constructor(props) {
        super(props)
        this.myRef = React.createRef()
        this.state = {
            videoPlay: false,
            isOpen: false,
            unpublishPopup: false,
            deletePopup: false
        }
    }
    showMoretoggle = () => {
        this.setState({ isOpen: !this.state.isOpen })
    }

    showMore = () => {
        let { isOpen } = this.state
        let movieDesc = this.props.movieDesc
        if (movieDesc === null) {
            movieDesc = ""
        }

        if (isOpen) {
            return movieDesc
        }
        if (movieDesc.length > 145) {
            return movieDesc.slice(0, 145) + "..."
        } else {
            return movieDesc.slice(0, 145)
        }
    }

    playVideo = () => {
        const videoTag = this.myRef.current
        videoTag.play()
        this.setState({ videoPlay: !this.state.videoPlay })
    }

    componentDidMount() {
        const { isMovieView } = this.props
        if (isMovieView) {
            document.body.style.overflow = "hidden"
        }
    }

    componentWillUnmount() {
        document.body.style.overflow = "unset"
    }

    toggleConfirmationPopup = (e, popupKey) => {
        e.stopPropagation()
        this.setState((prevState) => {
            return {
                unpublishPopup: false,
                deletePopup: false,
                [popupKey]: !prevState[popupKey]
            }
        })
    }

    render() {
        const {
            posterImg,
            movieTitle,
            rating,
            genre,
            year,
            movieDesc,
            language,
            director,
            producer,
            starringData,
            isMovieView,
            handleOnClose,
            videoSrc,
            handleEdit,
            published,
            selectedData,
            handleUnpublishClick,
            handleDeleteContent,
            handleDeleteView
        } = this.props
        const { isOpen, unpublishPopup, deletePopup } = this.state
        const { toggleConfirmationPopup } = this
        return (
            <div className={isMovieView ? "preview movieView" : "preview"}>
                <div className="prev-container">
                    <h4 className="movieViewHead">
                        Preview{" "}
                        {isMovieView && (
                            <i title="Close" className="icon-close" onClick={handleOnClose} />
                        )}{" "}
                    </h4>
                    <div className="inner-content">
                        {!isMovieView && (
                            <h5 className={videoSrc ? "" : "visibility"}>
                                Trailer
                                <i className="icon-arrow-right1"></i>
                            </h5>
                        )}
                        <div className="video-frame-wrapper">
                            <ReactPlayer url={videoSrc} controls width={"100%"} height={"232px"} />
                        </div>
                        <div className="details-wrapper">
                            <div className={`img-preview ${!posterImg ? "no-img-available" : ""}`}>
                                <img
                                    src={posterImg ? posterImg : `${NoImgAvailable}`}
                                    alt=""
                                    onError={(e) => {
                                        e.target.onerror = null
                                        e.target.src = `${NoImgAvailable}`
                                        e.target.className = `no-img-available`
                                    }}
                                />
                            </div>
                            <div className="movie-desc">
                                <h4 className="movie-Title">
                                    {movieTitle}
                                    {movieTitle ? <span>({rating})</span> : ""}
                                </h4>
                                {genre && (
                                    <p className="sub-detail">
                                        genre : <span>{genre}</span>
                                    </p>
                                )}
                                {year && (
                                    <p className="sub-detail">
                                        year : <span>{year}</span>
                                    </p>
                                )}
                                <p className="sub-detail desc">
                                    {movieDesc && movieDesc.length ? this.showMore() : null}
                                    {movieDesc && movieDesc !== null && movieDesc.length > 145 && (
                                        <span
                                            className={isOpen ? "showLess" : "showMore"}
                                            onClick={this.showMoretoggle}></span>
                                    )}
                                </p>
                                {language && (
                                    <p className="sub-detail">
                                        Language : <span>{language}</span>
                                    </p>
                                )}
                                <div className="starring">
                                    {/* <h5 className={starringData.length ? 'star-heading' : 'empty star-head'}>
                        {
                            starringData.length ?
                                <Fragment>
                                    <span>starring</span>
                                    <i className="icon-arrow-right1"></i>
                                </Fragment>
                                : ''
                        }
                    </h5> */}

                                    {starringData && (
                                        <p className="prev-lastSection">
                                            Cast : <span>{starringData}</span>
                                        </p>
                                    )}
                                </div>
                                {director && (
                                    <p className="prev-lastSection">
                                        director : <span>{director}</span>
                                    </p>
                                )}
                                {producer && (
                                    <p className="prev-lastSection">
                                        producer : <span>{producer}</span>
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <AuthWrapper permissions={[PERMISSIONS.CONTENT_EDIT]}>
                    {isMovieView && (
                        <div className="movieListPreview">
                            {/* <AuthWrapper permissions={[PERMISSIONS.CONTENT_EDIT]}> */}
                            <div
                                className={unpublishPopup ? "movieView-lf show" : "movieView-lf"}
                                onClick={(e) => toggleConfirmationPopup(e, "unpublishPopup")}>
                                <i className={published ? "icon-unpublish" : "icon-published"}></i>
                                <span>{published ? "unpublish" : "publish"}</span>
                                <div className="confirmation-popup">
                                    <div className="message">
                                        Are you sure you wish to
                                        {published ? (
                                            <span className="type">unpublish</span>
                                        ) : (
                                            <span className="type">publish</span>
                                        )}
                                        this content?
                                    </div>
                                    <button
                                        type="button"
                                        className="btn primary-btn"
                                        onClick={() =>
                                            handleUnpublishClick(selectedData.id, published)
                                        }>
                                        {published ? <>unpublish</> : <>publish</>}
                                    </button>
                                    <button
                                        type="button"
                                        className="btn cancel-btn"
                                        onClick={(e) =>
                                            toggleConfirmationPopup(e, "unpublishPopup")
                                        }>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                            {/* </AuthWrapper> */}
                            <div className={deletePopup ? "movieView-rt show" : "movieView-rt"}>
                                <div className="edit" onClick={() => handleEdit(selectedData.id)}>
                                    <i className="icon-edit"></i>
                                    <span className="editIcon">edit</span>
                                </div>
                                {/* <AuthWrapper permissions={[PERMISSIONS.CONTENT_EDIT]}> */}
                                <div
                                    className={deletePopup ? "delete show" : "delete"}
                                    onClick={(e) => toggleConfirmationPopup(e, "deletePopup")}>
                                    <i className="icon-del"></i>
                                    <span>delete</span>
                                    <div className="confirmation-popup">
                                        <div className="message">
                                            Are you sure you wish to delete this movie?
                                        </div>
                                        <button
                                            type="button"
                                            className="btn primary-btn"
                                            onClick={() => {
                                                handleDeleteContent(selectedData.id)
                                                handleDeleteView()
                                            }}>
                                            Delete
                                        </button>
                                        <button
                                            type="button"
                                            className="btn cancel-btn"
                                            onClick={(e) =>
                                                toggleConfirmationPopup(e, "deletePopup")
                                            }>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                                {/* </AuthWrapper> */}
                            </div>
                            {/* <span>View Details</span> */}
                        </div>
                    )}
                </AuthWrapper>
            </div>
        )
    }
}

export default Preview
