import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import get from "lodash/get"
import Button from "../common/Button"
import Pagination from "../common/Pagination"
import { options } from "./api/constants"
import Reels from "./Reels"
import AddContentForm from "./contentAddForm"
import { fetchContentEntityAll } from "../formBuilder/api/action"
import {
    fetchContentList,
    deleteContentList,
    deleteContent,
    togglePublish,
    restartStreaming,
    getEnrichmentData,
    getContentEnrichment,
    fetchAllVideoMarkerData,
    fetchGenre,
    fetchCertification,
    deleteV3Content,
    toggleV3Publish
} from "./api/action"
import ContentListing from "./contentListing"
import { toast } from "react-toastify"
import "./style.scss"
import { scrollPage } from "../../utils/common"
import AddWebSeries from "./addWebSeries/AddWebSeries"
import { BUTTON_CONSTANTS, PERMISSIONS, TEXT_CONSTANTS } from "../../utils/constants"
import { isEmpty } from "lodash"
import { checkIfUserHasAccess } from "../../utils/users"
import { getIsDrmConfig } from "../DrmConfiguration/APIs/action"
import CustomModal from "../common/Modal"
import ViewRights from "./contentListing/ViewRights"
import { createAddNewStructure, hideHeader,  v3DeleteURL } from "./contentNavCommon"

class Content extends Component {
    constructor(props) {
        super(props)

        this.state = {
            searchValue: "",
            editId: "",
            isEdit: false,
            isHide: false,
            isAddContent: false,
            contentType: null,
            contentEntityId: null,
            formData: [],
            webSeriesCount: 0,
            genres: [],
            contentList: [],
            formBuilderData: [],
            selectedData: {},
            nameKey: "",
            imageKey: "",
            isLoading: true,
            contentPreview: false,
            pageCount: 0,
            currentPage: 0,
            selectedOption: options[0],
            deleteIndexId: [],
            filterOptions: {
                pageSize: 10,
                pageNo: 1,
                published: null,
                searchQuery: ""
            },
            // Content Enrichment, IMDB changes
            isModalOpen: false,
            isModalViewOpen: false,
            isImdbSuccessModal: false,
            imdbLoadingState: "",
            ceDataLoadingState: false,
            currentSelectedEpisode: 0,
            episodeIndex: 0,
            canUpdateStatus: checkIfUserHasAccess([PERMISSIONS.CONTENT_EDIT]),
            showModal: false
        }
    }
    setLoader = (val) => {
        this.setState({ isLoading: val })
    }

    async loadHandler(type, id) {
        const { location, fetchGenre, fetchCertification, getIsDrmConfig } = this.props
        const { filterOptions } = this.state
        if (location.contentType) {
            filterOptions["searchQuery"] = ""
            this.setState({
                contentType: location.contentType.linkName,
                filterOptions
            })
        } else {
            this.setState({
                contentType: location.pathname.split("/").pop(),
                filterOptions
            })
        }
        this.setLoader(true)
        const response = await this.props.fetchContentEntityAll()
        const certifications = await fetchCertification()
        // const drmResponse = await getIsDrmConfig()
        if (isEmpty(this.state.genres)) {
            const genres = await fetchGenre()
            this.setState({
                genres: genres
            })
        }
        this.setState({ formBuilderData: response && response.data }, async () => {
            this.fetchContentList(type, id)
            const { contentType, formBuilderData } = this.state
            const filter =
                formBuilderData &&
                formBuilderData.length &&
                formBuilderData.filter((item) => {
                    if (item.name === null || item.name === undefined) {
                        return false
                    }
                    return item.name.toLowerCase().split(" ").join("") === contentType.toLowerCase()
                })

            this.setState({
                formData: filter && filter.length && filter[0],
                certifications: certifications?.data,
                drmResponse: {
                    isDRMEnabled: true
                }
            })
        })
    }

    async componentDidMount() {
        scrollPage()
        this.loadHandler()
    }

    manageModal = () => {
        this.setState({
            showModal: !this.state.showModal
        })
    }

    handleDeleteContent = async (contentId) => {
        const { deleteContent, deleteV3Content } = this.props
        let payload = contentId
        if (!Array.isArray(contentId)) {
            payload = [contentId]
        }
        // const response = await deleteContent(payload)
        
        const response = await deleteV3Content(v3DeleteURL(this.state.contentType, contentId))
        if (response && response.status) {
            this.setState({
                selectedCards: [],
                isHide: false,
                deleteIndexId: contentId,
                currentPage: 0
            })
            toast.success(response?.message)
            this.loadHandler()
        }
    }

    pageCountSetup = (userCount) => {
        const { pageSize } = this.state.filterOptions
        let pageCount = Math.ceil(userCount / pageSize)
        this.setState({
            pageCount
        })
    }
    prefillSelectedData = (contentId) => {
        const { contentList, contentType } = this.state
        const filter =
            contentList &&
            contentList.data.length &&
            contentList.data.filter((i) => i.id === contentId)[0]
        /* agerating remove */
        if (filter.content && filter.content.age_rating) {
            filter.content.age_rating.values = filter?.content?.age_rating?.certification?.values
        }
        // filter.content && filter.content.age_rating
        //   ? (filter.content.age_rating.values =
        //     filter?.content?.age_rating?.certification?.values)
        //   : null;
        if (filter.content && filter.content.age_rating) {
            filter.content.age_rating.is_multilingual =
                filter?.content?.age_rating?.certification?.is_multilingual
        }
        // filter.content && filter.content.age_rating
        //   ? (filter.content.age_rating.is_multilingual =
        //     filter?.content?.age_rating?.certification?.is_multilingual)
        //   : null;
        if (
            !filter.content?.age_rating?.values &&
            (contentType?.toLowerCase() === "movies" || contentType?.toLowerCase() === "webseries")
        ) {
            // Commented out code for UAT relase
            // filter.content.age_rating.values = [];
        }
        delete filter["content"]?.["age_rating"]?.["certification"]
        this.setState({
            selectedData: filter && filter.content,
            editId: contentId,
            isEdit: true
        })
    }
    fetchContentList = async (type, contentId, successCallback) => {
        const { formBuilderData, contentType } = this.state
        if (formBuilderData && formBuilderData.length) {
            let defaultSplit = " "
            const filter = formBuilderData.find((item) => {
                if (item.name === null || item.name === undefined) {
                    return false
                }
                // TODO: check wheter following condition is still required
                else if (item.name.includes("_")) {
                    defaultSplit = "_"
                }
                return (
                    item.name.toLowerCase().split(defaultSplit).join("") ===
                    contentType.toLowerCase().split(defaultSplit).join("")
                )
            })
            if (filter && filter.id) {
                const res = await this.props.fetchContentList(this.state.filterOptions, filter.id)

                this.setLoader(false)
                this.pageCountSetup(res && res.data && res.data.count)
                let { filterOptions } = this.state
                filterOptions.pageNo = 1
                this.setState(
                    {
                        contentList: res && res.data,
                        nameKey: filter.nameKey,
                        imageKey:
                            contentType === "REELS"
                                ? filter.imageKey
                                : filter.imageKey.toLowerCase().split(" ").join("_"),
                        count: res && res.data && res.data.count,
                        filterOptions,

                        contentEntityId: filter.id
                    },
                    () => {
                        if (type === "content_enrichment") {
                            this.prefillSelectedData(contentId)
                            successCallback && successCallback(this.state.isLoading)
                        } else if (type === "publish") {
                            const { contentList } = this.state
                            const filter =
                                contentList &&
                                contentList.data.length &&
                                contentList.data.filter((i) => i.id === contentId)[0]
                            if (filter && filter?.content) {
                                this.setState({
                                    selectedData: filter
                                })
                            }
                        }
                    }
                )
            }
        }
    }
    cancelBtnHandler = () => {
        this.setState({
            showModal: false
        })
    }
    componentDidUpdate(prevProps, prevState) {
        if (
            this.state.contentType &&
            this.props.location.contentType &&
            this.state.contentType !== this.props.location.contentType.linkName
        ) {
            let { filterOptions } = this.state
            filterOptions.pageNo = 1
            this.setState(
                {
                    contentType: this.props.location.contentType.linkName,
                    isLoading: true,
                    isAddContent: false,
                    currentPage: 0,
                    filterOptions,
                    contentPreview: false,
                },
                () => {
                    this.loadHandler()
                }
            )
        } else if (
            this.state.contentType &&
            this.state.contentType !== this.props.location.pathname.split("/").pop()
        ) {
            this.setState(
                {
                    contentType: this.props.location.pathname.split("/").pop(),
                    isLoading: true,
                    isAddContent: false,
                    currentPage: 0,
                    contentPreview: false,
                },
                () => {
                    this.loadHandler()
                }
            )
        }
    }

    toCamelCase = (sentenceCase) => {
        var out = ""
        sentenceCase.split(" ").forEach(function (el, idx) {
            var add = el.toLowerCase()
            out += idx === 0 ? add : add[0].toUpperCase() + add.slice(1)
        })
        return out
    }

    provideAddNewData = () => {
        return createAddNewStructure(this.state?.contentType?.toLowerCase())
    }

    handleAddClick = (type, contentId, successCallback) => {
        let { filterOptions } = this.state
        if (type !== "content_enrichment") filterOptions.pageNo = this.state.currentPage + 1
        if (type === "content_enrichment") {
            var isContentEnrichment = true
        }
        this.setState(
            {
                isAddContent: !isContentEnrichment ? !this.state.isAddContent : true,
                selectedData: this.provideAddNewData(),
                contentPreview: false,
                isEdit: false,
                currentPage: this.state.currentPage,
                filterOptions
            },
            () => {
                if (!this.state.isAddContent || isContentEnrichment) {
                    this.setLoader(true)
                    this.fetchContentList(type, contentId, successCallback)
                }
                scrollPage()
            }
        )
    }
    handleViewClick = (item, isEdit) => {
        const { contentPreview } = this.state
        this.setState({
            contentPreview: !contentPreview,
            isAddNew: false,
            isAddContent: isEdit
        })
        if (item) {
            this.setState({ selectedData: item, isEdit })
        }
    }

     addNewHandler = (item, isEdit) => {
        const { contentPreview } = this.state
        this.setState({
            contentPreview: !contentPreview,
            selectedData: this.provideAddNewData(),
            isEdit: false, 
            isAddNew: true,
            isAddContent: true
        })
    }

    backButtonHandler = () => {
         this.setState({
            isAddContent: false,
            contentPreview: false,
            isEdit: false, 
            isAddNew: false
        })
    }

    handleUnpublishClick = async (id, publish) => {
        let payloadID = id
        if (!Array.isArray(id)) {
            payloadID = [id]
        }
        let payLoad = { ids: payloadID, publish: !publish, "contentType": this.state.contentType?.toLowerCase() }
        // let response = await this.props.togglePublish(payLoad)
         let response = await this.props.toggleV3Publish(payLoad)
        if (response && response.status) {
            toast.success(response?.message)

            const { filterOptions, currentPage } = this.state
            const UpdatedFilterOptions = { ...filterOptions }
            UpdatedFilterOptions["pageNo"] = currentPage + 1

            this.setState({
                isHide: false,
                deleteIndexId: id,
                filterOptions: UpdatedFilterOptions
            })
            await this.loadHandler("publish", id)
        } else {
            toast.error(get(response, "message"))
        }
    }

    onPageChange = async (e) => {
        const { filterOptions } = this.state
        let updatedFilterOption = JSON.parse(JSON.stringify(filterOptions))
        const selectedPage = e.selected
        updatedFilterOption.pageNo = selectedPage + 1
        const res = await this.props.fetchContentList(
            updatedFilterOption,
            this.state.contentEntityId
        )
       
        const result = res && res?.data
        this.setState(
            { contentList: result,  currentPage: selectedPage,
                filterOptions: updatedFilterOption 
            },
            () => {
            scrollPage()
            this.pageCountSetup(res && res?.data && res?.data?.count)
            }
        )
    }
    showPerPageChange = async (name, value) => {
        let { filterOptions } = this.state
        let updatedFilterOption = JSON.parse(JSON.stringify(filterOptions))
        updatedFilterOption["pageSize"] = value
        updatedFilterOption.pageNo = 1
        let defaultOption = options.filter((item) => {
            if (value === item.id) {
                return item
            }
            return false
        })

        const res = await this.props.fetchContentList(
            updatedFilterOption,
            this.state.contentEntityId
        )
        const result = res && res?.data

        this.setState({
            contentList: result,
            filterOptions: updatedFilterOption,
            selectedOption: defaultOption,
            currentPage: 0
        }, () => this.pageCountSetup(res && res?.data && res?.data?.count)
        )

    }

    handleSearch = async (name, value) => {
        const { filterOptions } = this.state
        const UpdatedFilterOptions = JSON.parse(JSON.stringify(filterOptions))
        UpdatedFilterOptions["searchQuery"] = value
        const res = await this.props.fetchContentList(
            UpdatedFilterOptions,
            this.state.contentEntityId
        )
        const result = res && res.data
        this.setState({
            filterOptions: UpdatedFilterOptions,
            currentPage: 0,
            contentList: result
        }, () => {
            this.pageCountSetup(res && res.data && res.data.count)
        })
        
    }


    handleLoadhandler = () => {
        this.loadHandler()
    }

    handleLiveStreaming = () => {
        let url = "https://3.80.173.161:8443/"
        window.open(url)
    }

    handleRestartStreaming = async () => {
        const { restartStreaming } = this.props
        const response = await restartStreaming()
        let responseMsg = response && response[0].message
        if (responseMsg && response[0].status) {
            toast.success(responseMsg)
        }
    }

    // IMDB and COntent Enrichment changes

    contentEnrichmentBtnHandler = (val, index, seasonIndex) => {
        Number(index) >= 0
            ? this.setState(
                  {
                      episodeIndex: index,
                      seasonIndex: seasonIndex
                  },
                  () => {
                      this.setState({
                          isModalOpen: val
                      })
                  }
              )
            : this.setState({
                  isModalOpen: val
              })
    }

    submitModalHandler = async (val) => {
        const data = {
            fingerPrinting: val?.includes("fingerPrinting"),
            bingeMarkerIdentification: val?.includes("bingeMarkerIdentification"),
            adBreaksIdentification: val?.includes("adBreaksIdentification")
        }
        let id = this.state.selectedData["content_id"]
        if (this.state?.contentType === "WEBSERIES") {
            id =
                this.state?.selectedData?.season?.[this.state?.seasonIndex].episode?.[
                    this.state?.episodeIndex
                ]?.["episode_id"]
        }

        this.setState({
            ceDataLoadingState: true
        })
        await this.props.getEnrichmentData(data, id, this.state.selectedData?.contentType)
        this.setState({
            ceDataLoadingState: false
        })
    }

    contentEnrichmentViewBtnHandler = (val) => {
        this.setState({
            isModalViewOpen: val
        })
    }

    setImdbModalState = (loadingState) => {
        this.setState({
            isImdbSuccessModal: true,
            imdbLoadingState: loadingState
        })
    }

    getContentEnrichment = async (index, seasonIndex) => {
        let { imdb_id } = this.state.selectedData
        let contentId = this.state.selectedData["content_id"]
        let data = {
            contentEntityId: this.state.contentEntityId,
            contentId: contentId,
            contentType: this.state.selectedData?.contentType,
            imdbId: imdb_id
        }
        this.setImdbModalState(true)
        let response = await this.props.getContentEnrichment(data)
        if (response?.status) {
            this.setState({
                imdbData: response?.data?.data?.content?.IMDB
            })
            setTimeout(async () => {
                this.handleAddClick("content_enrichment", contentId, this.setImdbModalState)
                //prefillSelectedData();
            }, 500)
        } else {
            this.setImdbModalState(false)
        }
    }

    viewData = (index, seasonIndex) => {
        this.setState(
            {
                episodeIndex: index,
                seasonIndex: seasonIndex
            },
            () => {
                this.fetchVideoMarkers()
            }
        )
    }

    openImdbModal = (val) => {
        this.setState({
            isImdbSuccessModal: val
        })
    }

    fetchVideoMarkers = async () => {
        let id =
            this.state.contentType === "WEBSERIES"
                ? this.state.selectedData?.season?.[this.state?.seasonIndex]?.episode?.[
                      this.state?.episodeIndex
                  ]?.["episode_id"]
                : this.state?.selectedData?.["content_id"]
        let response = await this.props.fetchAllVideoMarkerData(
            id,
            this.state?.selectedData?.["contentType"]
        )

        this.setState({
            isModalViewOpen: true,
            contentMarkers: response
        })
    }

    render() {
        const {
            deleteIndexId,
            isHide,
            editId,
            isEdit,
            isAddContent,
            contentType,
            formBuilderData,
            contentList,
            imageKey,
            nameKey,
            selectedData,
            contentPreview,
            isLoading,
            formData,
            selectedOption,
            isModalOpen,
            ceDataLoadingState,
            isModalViewOpen,
            episodeIndex,
            isImdbSuccessModal,
            imdbLoadingState,
            imdbData,
            genres,
            canUpdateStatus,
            drmResponse,
            isAddNew,
        } = this.state

        const commonMovieAndWebSeriesProps = {
            isModalOpen: isModalOpen,
            ceDataLoadingState: ceDataLoadingState,
            isModalViewOpen: isModalViewOpen,
            episodeIndex: episodeIndex,
            isImdbSuccessModal: isImdbSuccessModal,
            imdbLoadingState: imdbLoadingState,
            imdbData: imdbData,
            contentEnrichmentBtnHandler: this.contentEnrichmentBtnHandler,
            submitModalHandler: this.submitModalHandler,
            fetchVideoMarkers: this.fetchVideoMarkers,
            contentEnrichmentViewBtnHandler: this.contentEnrichmentViewBtnHandler,
            contentMarkers: this.state.contentMarkers,
            openImdbModal: this.openImdbModal,
            getContentEnrichment: this.getContentEnrichment,
            viewData: this.viewData,
            drmResponse: drmResponse,
            isAddNew
        }

        return (
            <div className="vd-movie live-movie">
                <div className="vr-cont-head mb">
                    {/* TODO: Replace below with the reusable pageTitleComponent */}
                    <div className="vr-cont-title">
                        <span className="vr-breadcrum">
                            {TEXT_CONSTANTS?.CONTENT?.toLowerCase()}{" "}
                            <i className="icon-arrow-right1" />
                            {contentType === "LIVETV" ? (
                                <span>{TEXT_CONSTANTS?.LIVE_TV?.toUpperCase()}</span>
                            ) : (
                                <span>{contentType}</span>
                            )}
                        </span>
                        {contentType === "LIVETV" ? (
                            <h2 className="breadcrum-header">
                                {TEXT_CONSTANTS?.LIVE_TV?.toUpperCase()}
                            </h2>
                        ) : (
                            <h2 className="breadcrum-header">
                                {contentType?.includes("_")
                                    ? contentType.replace("_", " ")
                                    : contentType}
                            </h2>
                        )}
                    </div>
                    <div className="vr-cont-action header-filter-btn">
                        {!isAddContent && contentType === "LIVETV" ? (
                            <>
                                <Button
                                    cName={`btn secondary-btn m-5 disabled`}
                                    bValue={BUTTON_CONSTANTS?.START_LIVE_STREAMING}
                                    bType="button"
                                    disabled
                                    clickHandler={this.handleLiveStreaming}
                                />
                                <Button
                                    cName={"btn secondary-btn m-5 disabled"}
                                    bValue={BUTTON_CONSTANTS?.RESTART_STREAMING}
                                    bType="button"
                                    disabled
                                    clickHandler={this.handleRestartStreaming}
                                />
                            </>
                        ) : null}

                        {(canUpdateStatus || contentPreview) && (
                            <>
                            {contentPreview ? <Button
                                cName={contentPreview ? "btn border-btn" : "btn primary-btn"}
                                bValue={contentPreview ? BUTTON_CONSTANTS?.BACK : BUTTON_CONSTANTS?.ADD_NEW}

                                bType="button"
                                leftIcon={contentPreview && "icon-arrow-left1"}
                                clickHandler={this.backButtonHandler}
                            />: !hideHeader(contentType?.toLowerCase())  &&
                                <Button
                                cName={"btn primary-btn"}
                                bValue={BUTTON_CONSTANTS?.ADD_NEW}
                                bType="button"
                                // leftIcon={contentPreview && "icon-arrow-left1"}
                                clickHandler={this.addNewHandler}
                            />}
                          
                            </>
                        )}
                    </div>
                </div>
                {contentPreview ? (
                    contentType.toLowerCase() === "webseries" ? (
                        <div>
                            <AddWebSeries
                                formBuilderData={formBuilderData}
                                contentType={contentType}
                                handleViewClick={this.handleViewClick}
                                handleAddClick={this.handleAddClick}
                                isEdit={isEdit}
                                genres={genres}
                                selectedData={selectedData}
                                editId={editId}
                                certifications={this.state?.certifications}
                                {...commonMovieAndWebSeriesProps}
                            />
                        </div>
                    ) : contentType === "REELS" ? (
                        <Reels
                            formData={formData}
                            editId={editId}
                            contentType={contentType}
                            isEdit={isEdit}
                            isLoading={isLoading}
                            handleAddClick={this.handleAddClick}
                            selectedData={selectedData}
                        />
                    ) : (
                        <AddContentForm
                            handleAddClick={this.handleAddClick}
                            selectedData={selectedData}
                            isLoading={isLoading}
                            genres={genres}
                            handleSearch={this.handleSearch}
                            handleViewClick={this.handleViewClick}
                            formData={formData}
                            contentType={contentType}
                            editId={editId}
                            isEdit={isEdit}
                            certifications={this.state?.certifications}
                            {...commonMovieAndWebSeriesProps}
                        />
                    )
                ) : (
                    <ContentListing
                        contentType={contentType}
                        contentList={contentList}
                        imageKey={imageKey}
                        nameKey={nameKey}
                        handleAddClick={this.handleAddClick}
                        prefillSelectedData={this.prefillSelectedData}
                        contentPreview={contentPreview}
                        handleViewClick={this.handleViewClick}
                        selectedData={selectedData}
                        isLoading={isLoading}
                        handleSearch={this.handleSearch}
                        handleDeleteContent={this.handleDeleteContent}
                        deleteContent={this.props.deleteContent}
                        handleUnpublishClick={this.handleUnpublishClick}
                        handleLoadhandler={this.handleLoadhandler}
                        isHide={isHide}
                        deleteIndexId={deleteIndexId}
                        searchValue={this.state.filterOptions["searchQuery"]}
                        showModal={this.state.showModal}
                        setViewRightsId={this.setViewRightsId}
                    />
                )}
                {!contentPreview && (
                    <>
                        <Pagination
                            options={options}
                            pageCount={this.state.pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            selectedOption={selectedOption}
                            onPageChange={this.onPageChange}
                            changeHandler={this.showPerPageChange}
                            currentPage={this.state.currentPage}
                        />
                    </>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                fetchContentEntityAll,
                fetchContentList,
                deleteContentList,
                deleteContent,
                togglePublish,
                restartStreaming,
                getEnrichmentData,
                getContentEnrichment,
                fetchAllVideoMarkerData,
                fetchGenre,
                fetchCertification,
                getIsDrmConfig,
                deleteV3Content,
                toggleV3Publish
            },
            dispatch
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Content)
