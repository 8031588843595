import { TEXT_CONSTANTS } from "../../../utils/constants"
import Manual from "../../../assets/images/manual.png"
import { RAIL_CATEGORY } from "../api/constant"
import System from "../../../assets/images/system.png"
import React from "react"

const RailCategory = (props) => {
    const { railCategory, handleRailCategory } = props

    return (
        <div className="rail-category-group">
            <label htmlFor="rail-type" className="label">
                {TEXT_CONSTANTS?.RAIL_CATEGORY}
            </label>
            <div className="rail-type-button">
                <div className="radio-btn">
                    <input
                        type="radio"
                        id="manual-create-rail"
                        name="rail-type"
                        value="NEW"
                        checked={railCategory === "NEW"}
                        onChange={handleRailCategory}
                    />
                    <label className="labelText" htmlFor="manual-create-rail">
                        <span>
                            <div className="railTypeIcon">
                                <img src={Manual} alt="manual" />
                            </div>
                            {TEXT_CONSTANTS?.CREATE_NEW_RAIL}
                        </span>
                    </label>
                </div>
                <div className="radio-btn">
                    <input
                        type="radio"
                        id="auto-create-rail"
                        name="rail-type"
                        value={RAIL_CATEGORY.SYSTEM_GENERATED}
                        checked={railCategory === RAIL_CATEGORY.SYSTEM_GENERATED}
                        onChange={handleRailCategory}
                    />
                    <label className="labelText" htmlFor="auto-create-rail">
                        <span>
                            <div className="railTypeIcon">
                                <img src={System} alt="system" />
                            </div>
                            {TEXT_CONSTANTS?.SYSTEM_GENERATED}
                        </span>
                    </label>
                </div>
            </div>
        </div>
    )
}

export default RailCategory
