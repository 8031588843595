import { API_ROOT_PATH } from "../../../config/apiPath"
import ApiService from "../../../utils/apiService"

class TimezoneSerivce {
    getAllTimezone() {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/timezone/getAll`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
    saveTimezoneData(timezone) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/timezone`,
            isAuthRequired: true,
            data: timezone
        }
        return ApiService(options)
    }

    getSavedTimezoneData() {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/timezone`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
}
const TimezoneSerivceInstance = new TimezoneSerivce()
export default TimezoneSerivceInstance
