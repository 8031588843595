import React, { Component } from "react"

import InputSource from "./InputSource"
import "./style.scss"
import { ERROR_MESSAGES } from "../../../utils/constants"

class AddContentPipeline extends Component {
    constructor(props) {
        super(props)
        this.state = {
            files: [],
            activeDots: { 1: "grey-marker" }
        }
    }

    componentDidMount() {
        window.addEventListener("beforeunload", this.beforeLoad)
        window.history.pushState(null, null, window.location.href)
        window.onpopstate = function () {
            window.history.go(1)
        }
    }

    beforeLoad = (event) => {
        event.preventDefault()
        return (event.returnValue = ERROR_MESSAGES?.UPLOADING_NAVIGATION_ERROR)
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.beforeLoad)
        window.onpopstate = null
    }

    render() {
        const { history } = this.props
        return (
            <>
                <div className="vr-content-holder ">
                    <div className="main-pipeline-container">
                        <InputSource history={history} />
                    </div>
                </div>
            </>
        )
    }
}

export default AddContentPipeline
