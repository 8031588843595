import React, { useEffect, useState } from "react"
import LanguageCardList from "../common/LanguageCardList"
import "./style.scss";
import Input from "../common/Input";
import { fetchWebDictionaryIds, addDictionaryStrings } from "./api/action";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Button from "../common/Button";
import { BUTTON_CONSTANTS, ERROR_MESSAGES, TEXT_CONSTANTS } from "../../utils/constants";
import { getAllPlatform } from "../common/Platforms/api/action";


export default function AddTranslation() {
    const [selectedLanguage, setSelectedLanguage] = useState({ name: "English", code: "EN" });
    const [dictionaryIds, setdictionaryIds] = useState([]);
    const [rows, setRows] = useState([{ input1: '', input2: '' }]);
    const [platform, setPlatform] = useState([])
    const dispatch = useDispatch();

    useEffect(() => {
       fetchData()
    }, []);

    const fetchData = async () => {
         const resp = await dispatch(getAllPlatform());
        let reqPlatform = {};
        reqPlatform = resp?.data?.filter(r => r?.name=='web');
        setPlatform(reqPlatform[0]);
        reqPlatform.length && dispatch(fetchWebDictionaryIds(reqPlatform?.[0]?.id,
            (res) => {
                if (res) {
                    let ids = [];
                    res.map(r => ids.push({ id: r.id, languageCode: r.languageCode }))
                    setdictionaryIds(ids);
                }
            }
        ));
    }

    const onLanguageChangeListener = (value) => {
        setSelectedLanguage(value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        let result = {};
        rows.forEach(row => {
            let obj = { [row.input1]: row?.input2 };
            Object.assign(result, obj);
        });


        let reqDictionaryIdDetail = dictionaryIds.filter(d => d?.languageCode == selectedLanguage?.code?.toUpperCase()) || '';
        dispatch(addDictionaryStrings(platform?.id,reqDictionaryIdDetail?.[0], result,
            (res) => {
                if (res) {
                    toast.success(TEXT_CONSTANTS.ADDED_SUCCESSFULLY);
                    setRows([{ input1: '', input2: '' }]);
                }
                else{
                    toast.error(ERROR_MESSAGES.SOMETHING_WENT_WRONG);
                    setRows([{ input1: '', input2: '' }]);
                }
            }
        )
        )
    }

    const addRow = () => {
        setRows([...rows, { input1: '', input2: '' }]);
    };

    const handleInputChange = (rowIndex, inputName, event) => {
        const newRows = rows.map((row, index) => {
            if (index === rowIndex) {
                return {
                    ...row,
                    [inputName]: event?.target?.value
                };
            }
            return row;
        });
        setRows(newRows);
    };

    return (
        <div className="vr-add-string-component">
            <div className="vr-cont-head mb">
                <div className="vr-cont-title">
                    <span className="vr-breadcrum">
                        Settings <i className="icon-arrow-right1"></i>
                        <span>Add Translation</span>
                    </span>
                    <h2 className="breadcrum-header">Add Translation</h2>
                </div>
            </div>
            <div className="vr-content-holder basic-container">
                <div className="vr-translation">
                    <LanguageCardList handleChange={onLanguageChangeListener} />
                    <div className="value-containers">
                        <h2>{`${TEXT_CONSTANTS?.ADD_TRANSLATION} ${selectedLanguage?.name}`}</h2>
                        <form onSubmit={handleSubmit} className="add-multilingual-form menu-container">
                            {rows.map((row, rowIndex) => (
                                <div key={rowIndex} className="translation-input-row">
                                    <Input
                                        labelText="Keyname"
                                        placeholder="Enter Keyname"
                                        cName={"keynameClass"}
                                        name={"key"}
                                        value={row.input1 || ''}
                                        onChange={(event) => handleInputChange(rowIndex, 'input1', event)}
                                        required={true}
                                    />
                                    <Input
                                        labelText="Value"
                                        placeholder="Enter Value"
                                        cName={"ValueClass"}
                                        name={"value"}
                                        value={row.input2 || ''}
                                        onChange={(event) => handleInputChange(rowIndex, 'input2', event)}
                                        required={true}
                                    />
                                </div>
                            ))}
                            <div className="addnewTab-Btn">
                                <span className="icon-container" onClick={addRow}>
                                    <i className="close icon-plus"></i>
                                </span>
                                <span className="icon-text" onClick={addRow}>
                                    {TEXT_CONSTANTS?.ADD_NEW_TAB}
                                </span>
                            </div>
                            <Button
                                bType="submit"
                                bValue={BUTTON_CONSTANTS.SUBMIT}
                                cName="btn primary-btn" />
                        </form>

                    </div>
                </div>
            </div>
        </div>
    )
}