import React from "react"
import Slider from "react-slick"

import Filter from "../../common/Filter"
import MovieCardGrid from "../../common/MovieCardGrid"
import { wordFormat } from "../helper"

import "./style.scss"
import { TEXT_CONSTANTS } from "../../../utils/constants"

function Timeline(props) {
    const { recentContentData, isLoading } = props
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1920,
                settings: {
                    slidesToShow: 7,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1
                    // initialSlide: 0
                }
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            }
        ]
    }

    const cardLoader = () => {
        return (
            <div className="movieCard-loader">
                <span></span>
                <span className="imgLoader" />
                <span className="tagLoader" />
                <span className="cardNameLoader" />
            </div>
        )
    }
    let timeLineFilter = props.filterList.map((item) => {
        return { id: item.id, name: `${wordFormat(item.name)}` }
    })

    const onChangeContentFilter = (name, value) => {
        let formatedValue = `${wordFormat(value)}`
        props.recentContentFilterChangeHandler(formatedValue, "selectedContentType")
    }
    let cardData = !!recentContentData.length ? recentContentData : [1, 2, 3, 4, 5, 6]
    return (
        <div className="timeline-container">
            <div className="header">
                <div className="heading">
                    <h3>{TEXT_CONSTANTS?.RECENTLY_ADDED_CONTENT}</h3>
                    <Filter
                        cName="cs_filter"
                        text="All content"
                        id="timelineFilter"
                        defaultValue=""
                        filterList={timeLineFilter}
                        changeHandler={onChangeContentFilter}
                    />
                </div>
                <div className="nav-buttons"></div>
            </div>
            <section className="timelineList">
                <div className="timeline">
                    <div className="start" />
                </div>
                <div className="recent-add">
                    <Slider {...settings} key={props.recentDatakey}>
                        {cardData &&
                            !!cardData.length &&
                            cardData.map((item) => {
                                return (
                                    <div className="project">
                                        <div className="entry">
                                            <div className="dot" />
                                            {isLoading ? (
                                                cardLoader()
                                            ) : (
                                                <MovieCardGrid
                                                    title={item.title}
                                                    thumbnail={
                                                        item.banner ? item.banner : item.logo
                                                    }
                                                    category={
                                                        item.category
                                                            ? wordFormat(item.category)
                                                            : null
                                                    }
                                                    date={item.date}
                                                    time={item.time}
                                                    published={item.published}
                                                    cardType={
                                                        item.category === "#LIVE_TV" ? "small" : ""
                                                    }
                                                />
                                            )}
                                        </div>
                                    </div>
                                )
                            })}
                    </Slider>
                </div>
            </section>
        </div>
    )
}

export default Timeline
