import axios from "axios"

import { API_ROOT_PATH } from "../config/apiPath"
import { saveUserCookie, setErrorMessage } from "./common"
import { logoutUser, sendLogoutToken } from "../components/LoginPanel/APIs/action"
import { toast } from "react-toastify"
import { decryptData } from "./EncryptDecrypt"

export default function ApiService(config) {
    try {
        let sessionStorageTenant = sessionStorage.getItem("tenantInfo")
        let tenantInfo
        if (sessionStorageTenant) {
            tenantInfo = decryptData(
                sessionStorage.getItem("tenantInfo"),
                process.env.REACT_APP_LOCAL_STORAGE_SECRETE_KEY
            )
        }

        // if (tenantInfo) {
        //   tenantInfo = JSON.parse(tenantInfo);
        // }
        let axiosInstance = axios.create()

        if (config.isAuthRequired) {
            if (config.headers) {
                config.headers["Authorization"] =
                    "Bearer " + JSON.parse(localStorage.getItem("accessToken"))
                config.headers["Content-Type"] = "application/json"
                config.headers["Cache-Control"] = "no-cache"
            } else {
                config.headers = {
                    // 'Authorization':  'Bearer '+ (auth.details.data.jwtToken.accessToken ? auth.details.data.jwtToken.accessToken : cookie.load('accessToken')),
                    Authorization: "Bearer " + JSON.parse(localStorage.getItem("accessToken")),
                    "Cache-Control": "no-cache",
                    "Content-Type": "application/json"
                }
            }
            if (tenantInfo) {
                config.headers["TENANT_IDENTIFIER"] = tenantInfo?.data?.tenantIdentifier
                    ? tenantInfo?.data?.tenantIdentifier
                    : "master"
            }
            config.headers["environmentCode"] = localStorage.getItem("environmentCode")
        } else {
            config.headers = config.headers ? config.headers : {}
            config.headers["TENANT_IDENTIFIER"] = tenantInfo?.data?.tenantIdentifier
                ? tenantInfo?.data?.tenantIdentifier
                : "master"
        }

        return axiosInstance(config)
            .then((_response) => {
                return new Promise((resolve) => {
                    return resolve(_response)
                })
            })
            .catch((err) => {
                const statusCode = err?.response?.status
                const error = err?.response?.data?.error || err?.response?.data?.message
                return new Promise(async (resolve, reject) => {
                    if (
                        err &&
                        err?.response &&
                        err?.response?.status >= 400 &&
                        err?.response?.status < 500
                    ) {
                        if (
                            err?.response?.status === 401 &&
                            config.isAuthRequired &&
                            localStorage.getItem("refreshToken")
                        ) {
                            let originalConfig = config
                            try {
                                const rs = await axiosInstance({
                                    method: "POST",
                                    url: `${API_ROOT_PATH}/refreshment/${JSON.parse(
                                        localStorage.getItem("refreshToken")
                                    )}`,
                                    headers: {
                                        TENANT_IDENTIFIER: tenantInfo?.data?.tenantIdentifier
                                            ? tenantInfo?.data?.tenantIdentifier
                                            : "master",
                                        "Cache-Control": "no-cache",
                                        "Content-Type": "application/json"
                                    }
                                })
                                saveUserCookie(rs.data, "/")
                                if (originalConfig?.isAuthRequired) {
                                    originalConfig.headers.Authorization =
                                        "Bearer " + rs?.data?.data?.jwtToken?.accessToken
                                }
                                return axiosInstance(originalConfig)
                                    .then((res) => {
                                        return resolve(res)
                                    })
                                    .catch((err) => {
                                        console.log("error:", err)
                                        return reject(err.response?.data)
                                    })
                            } catch (_error) {
                                await sendLogoutToken()
                                await logoutUser()
                                toast.error("Refresh token error", _error)
                                window.location.assign("/")
                                return Promise.reject(_error)
                            }
                        }
                        return reject(err.response.data)
                    }

                    // setErrorMessage(error, statusCode);
                    console.log("error:", error, "statusCode:", statusCode)

                    if (err && err?.response?.status) {
                        return reject(err)
                    } else {
                        return reject(null)
                    }
                    // else {
                    // store.dispatch({type:'SHOW_ERROR',
                    //     data: {"message":  err.response && err.response.data.message ? err.response.data.message :
                    //         err.response && err.response.statusText ? err.response.statusText: 'Something went wrong'}});

                    // }
                })
            })
    } catch (err) {
        const error = err?.response?.data?.error || err?.response?.data?.message
        const statusCode = err?.response?.status
        setErrorMessage(error, statusCode)
    }
}
