import React from "react"
import { FILE_STATUS_TAB, SUCCESS_FAILURE_CONTENT_HEADER } from "../../api/constants"
import { dateTime } from "../../../../utils/common"
import loaderImg from "../../../../assets/images/loader.gif"
import "./style.scss"

export default function SuccessFailureTable({
    tableData = [],
    currentActiveTab,
    isLoading,
    selectedRowData
}) {
    const isSuccessTab = currentActiveTab === FILE_STATUS_TAB?.SUCCESS_TAB

    const sortBy = (item) => {}

    const renderTableData = () => {
        const renderTableHeader = (item) => {
            return (
                <p>
                    <span>{item.header}</span>{" "}
                    {item?.isSortEnabled && (
                        <span onClick={() => sortBy(item?.value)} className="triangle"></span>
                    )}
                </p>
            )
        }

        const tableHeading = SUCCESS_FAILURE_CONTENT_HEADER.filter(
            (item) => !(item?.accessor === "failureStatus" && isSuccessTab)
        ).map((item, index) => {
            return (
                <div
                    className={`${isSuccessTab ? "success-header-info" : "header-info"} `}
                    key={index}
                >
                    {renderTableHeader(item)}
                </div>
            )
        })

        const tableRows = tableData?.map((row) => {
            const uploadedOn = dateTime(selectedRowData?.uploadDate)
            return (
                <div id={row?.id} key={row?.id} className="row-data">
                    <div
                        className={`tooltip-container ${
                            isSuccessTab ? "success-header-info" : "header-info"
                        }`}
                    >
                        <p className="tooltip-container-show">{row?.display_name}</p>
                        <div className="tooltip-container-innerText">
                            <p className="transcoding-play-url">
                                {row?.display_name ? row?.display_name : "N/A"}
                            </p>
                        </div>
                    </div>
                    <p className={`${isSuccessTab ? "success-header-info" : "header-info"}`}>
                        {selectedRowData?.uploadDate
                            ? `${uploadedOn?.date} ${uploadedOn?.time}`
                            : "-"}
                    </p>
                    <p className={`${isSuccessTab ? "success-header-info" : "header-info"}`}>
                        {selectedRowData?.userId ? selectedRowData?.userId : "-"}
                    </p>
                    {!isSuccessTab && (
                        <p className={`${isSuccessTab ? "success-header-info" : "header-info"}`}>
                            {row?.errors ? row?.errors?.join() : "-"}
                        </p>
                    )}
                </div>
            )
        })

        const displayLoader = () => {
            return (
                <div className="loader">
                    <img src={loaderImg} alt="loader" width={"100px"} />
                </div>
            )
        }

        return (
            <>
                <div className="table-header-data">{tableHeading}</div>
                {isLoading ? (
                    displayLoader()
                ) : (
                    <div className="table-row-data">
                        {tableData?.length > 0 ? (
                            tableRows
                        ) : (
                            <h3 className="no-record-found">No Record Found!</h3>
                        )}
                    </div>
                )}
            </>
        )
    }

    return <div className="success-failure-wrapper">{renderTableData()}</div>
}
