import React from "react"

import "./style.scss"
import { getImageRecommendedText } from "../../../../utils/common"

function ImageUplode(props) {
    const {
        name,
        id,
        listRef,
        value,
        label,
        placeholder,
        required,
        disabled,
        showRecommededSize = true,
        recommendedName = null,
    } = props

    const imageChangeHandler = (e) => {
        const { files } = e.target

        const modifiedName = files?.[0]?.name?.replace(/[^a-zA-Z.0-9_-]/g, "")
        const modifiedFile = new File([files[0]], modifiedName, {
            type: files[0].type
        })

        let data = new FormData()
        data.append("image", modifiedFile)
        data.append("type", "CONTENT")
        props.uploadImage(data, id, name)
    }

    return (
        <div>
            <span className="img-header-label">
                {placeholder}
                {required && (
                    <span className="required">
                        {" "}
                        {""}
                        {"*"}
                    </span>
                )}
            </span>
            <label for={name}>
                <div class={`addPoster ${disabled ? "no-cursor-allowed" : "show-pointer-cursor"}`}>
                    {value ? (
                        <>
                            <img src={`${value}`} alt="" id="showImg" />
                            {!disabled && (
                                <div className="overlay-btn">
                                    {/* btn primary-btn */}
                                    <label htmlFor={name} className="btn ">
                                        Change
                                    </label>
                                    <button
                                        className="btn"
                                        onClick={(e) => {
                                            props.removeImg(name)
                                            e.stopPropagation()
                                            e.preventDefault()
                                        }}
                                    >
                                        Remove
                                    </button>
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            <i className="icon-plus"></i>
                            <span>{label}</span>
                        </>
                    )}
                </div>
            </label>
            <input
                type={"file"}
                data-id={id}
                accept="image/png, image/jpeg"
                id={name}
                name={name}
                ref={listRef}
                onClick={(e) => {
                    e.currentTarget.value = null
                }}
                onChange={imageChangeHandler}
                disabled={disabled}
            />
            {showRecommededSize && (
                <span className="img-size">{getImageRecommendedText(recommendedName || name)}</span>
            )}
        </div>
    )
}

export default ImageUplode
