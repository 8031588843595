import { ACTION } from "./constant"
import CategoryManagementServiceInstance from "./service"
import { removeLoader, setLoader } from "../../common/Loader/api/action"

export const fetchPlatformList = (params) => {
    return (dispatch) => {
        return CategoryManagementServiceInstance.fetchPlatformList(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_PLATFORM,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting user list ", error)
            })
    }
}

export const createCategory = (params) => {
    return (dispatch) => {
        return CategoryManagementServiceInstance.createCategory(params)
            .then((response) => {
                dispatch({
                    type: ACTION.CREATE_CATEGORY,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in creating category ", error)
            })
    }
}

export const updateCategory = (params) => {
    return (dispatch) => {
        return CategoryManagementServiceInstance.updateCategory(params)
            .then((response) => {
                dispatch({
                    type: ACTION.UPDATE_CATEGORY,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in creating category ", error)
            })
    }
}

export const fetchAllCategory = (params) => {
    return (dispatch) => {
        return CategoryManagementServiceInstance.fetchAllCategory(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_ALL_CATEGORY,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting category list", error)
            })
    }
}

export const fetchCategory = (params) => {
    return (dispatch) => {
        return CategoryManagementServiceInstance.fetchCategory(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_CATEGORY,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting category list", error)
            })
    }
}

export const fetchCategoryById = (params) => {
    return (dispatch) => {
        return CategoryManagementServiceInstance.fetchCategoryById(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_CATEGORY_BY_ID,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting category list", error)
            })
    }
}

export const deleteCategory = (params) => {
    return (dispatch) => {
        return CategoryManagementServiceInstance.deleteCategory(params)
            .then((response) => {
                dispatch({
                    type: ACTION.DELETE_CATEGORY,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in deleting category list", error)
                console.log("Got error in creating rail ", error)
            })
    }
}

export const createRail = (params, TIMEZONE_VAL) => {
    return (dispatch) => {
        dispatch(setLoader())
        return CategoryManagementServiceInstance.createRail(
            params,
            TIMEZONE_VAL
        )
            .then((response) => {
                dispatch({
                    type: ACTION.CREATE_RAIL,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in creating rail ", error)
                return error
            })
            .finally(() => {
                dispatch(removeLoader())
            })
    }
}

export const autoShuffleCreateRail = (params) => {
    return (dispatch) => {
        return CategoryManagementServiceInstance.autoShuffleCreateRail(params)
            .then((response) => {
                dispatch({
                    type: ACTION.AUTO_SHUFFLE_CREATE_RAIL,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in auto shuffle create rail ", error)
                return error
            })
    }
}

export const unpublishCategory = (params) => {
    return (dispatch) => {
        return CategoryManagementServiceInstance.unpublishCategory(params)
            .then((response) => {
                dispatch({
                    type: ACTION.UNPUBLISH_CATEGORY,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in unpublish category list", error)
            })
    }
}

export const fetchFilteredRailById = (params) => {
    return (dispatch) => {
        return CategoryManagementServiceInstance.fetchFilteredRailById(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_FILTERED_RAIL_BY_ID,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in unpublish category list", error)
            })
    }
}

export const fetchRailById = (params) => {
    return (dispatch) => {
        return CategoryManagementServiceInstance.fetchRailById(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_RAIL_BY_ID,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in unpublish category list", error)
            })
    }
}

export const fetchContentByID = (params) => {
    return (dispatch) => {
        return CategoryManagementServiceInstance.fetchContentByID(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_CONTENT_BY_ID,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in fetching all rails", error)
            })
    }
}

export const deactivateRailById = (params) => {
    return (dispatch) => {
        return CategoryManagementServiceInstance.deactivateRailById(params)
            .then((response) => {
                dispatch({
                    type: ACTION.DEACTIVATE_RAIL_BY_ID,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in deactivating rail by id", error)
            })
    }
}

export const softDeleteRail = (params) => {
    return (dispatch) => {
        return CategoryManagementServiceInstance.softDeleteRail(params)
            .then((response) => {
                dispatch({
                    type: ACTION.SOFT_DELETE_RAIL,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in soft delete rail by id", error)
            })
    }
}

export const fetchContentTypeList = (params) => {
    return (dispatch) => {
        return CategoryManagementServiceInstance.fetchContentTypeList(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_CONTENT_TYPE,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting user list ", error)
            })
    }
}

export const fetchRailTypeList = (params) => {
    return (dispatch) => {
        return CategoryManagementServiceInstance.fetchRailTypeList(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_RAIL_TYPE,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting user list ", error)
            })
    }
}

export const fetchFilters = (params) => {
    return (dispatch) => {
        return CategoryManagementServiceInstance.fetchFilters(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_FILTERS,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting user list ", error)
                return error
            })
    }
}

export const searchContent = (params) => {
    return (dispatch) => {
        return CategoryManagementServiceInstance.searchContent(params)
            .then((response) => {
                dispatch({
                    type: ACTION.CONTENT_SEARCH,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting user list ", error)
            })
    }
}

export const railReposition = (params) => {
    return (dispatch) => {
        return CategoryManagementServiceInstance.railReposition(params)
            .then((response) => {
                dispatch({
                    type: ACTION.CONTENT_SEARCH,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting user list ", error)
            })
    }
}

export const getLatestPosition = (params) => {
    return (dispatch) => {
        return CategoryManagementServiceInstance.getLatestPosition(params)
            .then((response) => {
                dispatch({
                    type: ACTION.RAIL_LAST_POSITION,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting user list ", error)
            })
    }
}

export const uploadImage = (params) => {
    return (dispatch) => {
        return CategoryManagementServiceInstance.uploadImage(params)
            .then((response) => {
                dispatch({
                    type: ACTION.UPLOAD_IMAGE,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in upload image ", error)
            })
    }
}

export const fetchDummyRail = (params) => {
    return (dispatch) => {
        return CategoryManagementServiceInstance.fetchDummyRail(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_DUMMY_RAIL,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in upload image ", error)
            })
    }
}

export const changeUploadImage = (params) => {
    return (dispatch) => {
        return CategoryManagementServiceInstance.changeUploadImage(params)
            .then((response) => {
                dispatch({
                    type: ACTION.CHANGE_UPLOAD_IMAGE,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in upload image ", error)
            })
    }
}

export const deleteContentImageHistory = (params) => {
    return (dispatch) => {
        return CategoryManagementServiceInstance.deleteContentImageHistory(
            params
        )
            .then((response) => {
                dispatch({
                    type: ACTION.DELETE_CONTENT_IMAGE,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in deleting category list", error)
                console.log("Got error in creating rail ", error)
            })
    }
}

export const refreshRails = () => {
    console.log("refresh rail action")

    return (dispatch) => {
        return CategoryManagementServiceInstance.refreshRails()
            .then((response) => {
                dispatch({
                    type: ACTION.REFRESH_RAILS,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in refreshing rails", error)
            })
    }
}
export const fetchFastChannel = () => {
    console.log("refresh rail action")

    return (dispatch) => {
        return CategoryManagementServiceInstance.fetchFastChannel()
            .then((response) => {
                dispatch({
                    type: ACTION.FAST_CHANNEL,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in refreshing rails", error)
                return error
                // return error;
            })
    }
}

export const fetchCertification = () => {
    console.log("fetch certification")

    return (dispatch) => {
        return CategoryManagementServiceInstance.fetchCertification()
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_CERTIFICATION,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in refreshing rails", error)
                return error
                // return error;
            })
    }
}

export const fetchAgeGrading = () => {
    console.log("fetch certification")

    return (dispatch) => {
        return CategoryManagementServiceInstance.fetchAgeGrading()
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_GRADING,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in refreshing rails", error)
                return error
                // return error;
            })
    }
}

export const fetchSystemGenerateFav = (params) => {
    return (dispatch) => {
        return CategoryManagementServiceInstance.fetchSystemGenerateFav(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_CONTENT_TYPE,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting user list ", error)
            })
    }
}

export const publishUnpublishRailAPI = (params) => {
    return (dispatch) => {
        return CategoryManagementServiceInstance.publishUnpublishPage(params)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in publish Unpublish Rail API ", error)
            })
    }
}
