import React, { useState } from "react"
import PropTypes from "prop-types"
import CustomModal from "../../common/Modal"
import Checkbox from "../../common/Checkbox"
import Button from "../../common/Button"
import checkList from "../../../assets/images/checkList.svg"
import Loader from "../../../assets/images/loader.gif"
import "./contentEnrichment.scss"
import { TEXT_CONSTANTS } from "../../../utils/constants"

const ContentEnrichment = (props) => {
    const { isModalOpen, setModalOpen, submitModalHandler, fetchVideoMarkers, loadingState } = props

    const [isSaved, setIsSaved] = useState(false)
    const [selectedChargesCheckbox, setSelectedChargesCheckbox] = useState([])

    const setModalHandler = (val) => {
        setModalOpen(val)
    }

    const checkNowHandler = () => {
        setModalHandler(false)
        fetchVideoMarkers()
        setIsSaved(false)
    }

    const modalSubmitHandler = () => {
        setIsSaved(true)
        submitModalHandler(selectedChargesCheckbox)
    }

    const modalCancelHandler = () => {
        setModalHandler(false)
        setIsSaved(false)
        setSelectedChargesCheckbox([])
    }

    const checkboxHandler = (name, checked, id) => {
        let selectedChargesCheckboxCpy = [...selectedChargesCheckbox]
        if (checked) {
            selectedChargesCheckboxCpy.push(name)
        } else {
            selectedChargesCheckboxCpy.splice(selectedChargesCheckboxCpy.indexOf(name), 1)
        }
        setSelectedChargesCheckbox(selectedChargesCheckboxCpy)
    }

    return (
        <div className="content-enrichment-section">
            <CustomModal
                showModal={isModalOpen}
                submitHandler={modalSubmitHandler}
                cancelHandler={modalCancelHandler}
                closeOnSubmit={false}
                submitButtonLabel={"Save"}
                showCancelButton={false}
                showSubmitButton={!isSaved}
                isSubmitDisabled={selectedChargesCheckbox?.length <= 0}
            >
                {displayModalContent({
                    isSaved,
                    checkboxHandler,
                    selectedChargesCheckbox,
                    checkNowHandler,
                    loadingState
                })}
            </CustomModal>
        </div>
    )
}

const displayModalContent = ({
    isSaved,
    checkboxHandler,
    selectedChargesCheckbox,
    checkNowHandler,
    loadingState
}) => {
    if (loadingState) {
        return (
            <div className="loading-state-container">
                <img src={Loader} width={"100px"} alt="Loader" />
                <p className="main-text">{TEXT_CONSTANTS?.PLEASE_WAIT_MOMENT}</p>{" "}
            </div>
        )
    }

    if (isSaved) {
        return (
            <div className="ce-saved-modal-content">
                <div className="ce-modal-heading">
                    <img src={checkList} alt="logo" />
                </div>
                <h3 className="ce-modal-heading">{ModalData?.savedDataHeader}</h3>
                <Button
                    cName={"ce-check-now"}
                    bType={"submit"}
                    bValue={ModalData?.checkNowLabel}
                    clickHandler={checkNowHandler}
                ></Button>
            </div>
        )
    }
    return (
        <>
            <h3 className="ce-modal-heading">{ModalData?.modalHeader}</h3>
            {ModalData?.chargesOptions?.map((item) => {
                return (
                    <div className="ce-checkbox">
                        <Checkbox
                            cName=""
                            labelText={item?.heading}
                            id={item?.name}
                            name={item?.name}
                            changeHandler={checkboxHandler}
                            isChecked={selectedChargesCheckbox.indexOf(item?.name) > -1}
                        />
                        {item?.description && (
                            <span className="ce-checkbox-description">{item?.description}</span>
                        )}
                    </div>
                )
            })}
        </>
    )
}

// Keeping static values for now.
const ModalData = {
    checkNowLabel: "Check Now",
    savedDataHeader: "Content data is available now. You can check",
    modalHeader: "Additional usage base charges applicable",
    chargesOptions: [
        {
            id: "1",
            heading: "Finger Printing",
            description: "(Advanced metadata generation)",
            name: "fingerPrinting"
        },
        {
            heading: "Binge Marker identification",
            id: "2",
            name: "bingeMarkerIdentification"
        },
        {
            heading: "Ad Breaks Identification",
            id: "3",
            name: "adBreaksIdentification"
        }
    ]
}

ContentEnrichment.propTypes = {
    isModalOpen: PropTypes.bool,
    setModalOpen: PropTypes.func,
    submitModalHandler: PropTypes.func
}

export default ContentEnrichment
