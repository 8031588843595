import CryptoJS from "crypto-js"

export const encryptData = (data, key) =>
    CryptoJS.AES.encrypt(JSON.stringify(data), key).toString()

export const decryptData = (ciphertext, key) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, key)
    try {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    } catch (err) {
        return null
    }
}

// To generate secretKey for encryption
export const secretKey = () => CryptoJS.lib.WordArray.random(256 / 8).toString()
