import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import Input from "../../common/Input/index"
import Button from "../../common/Button/index"

import "./style.scss"
import { BUTTON_CONSTANTS, TEXT_CONSTANTS } from "../../../utils/constants"

/* admin work */
class SuperAdminManagement extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            email_id: "",
            phone_number: ""
        }
    }
    handleSubmit = () => {}
    handleClose = () => {
        this.setState({
            name: "",
            email_id: "",
            phone_number: ""
        })
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    render() {
        const { name, email_id, phone_number } = this.state
        return (
            <div className="admin-main-container">
                <h1 className="heading">{TEXT_CONSTANTS.CREATE_SUPERADMIN}</h1>
                <p className="sub-heading">{TEXT_CONSTANTS.FILL_BELOW_DETAILS}</p>
                <Input
                    className="inputField"
                    type="text"
                    changeHandler={this.handleChange}
                    name="name"
                    id="customName"
                    maxLength={25}
                    value={name}
                    placeholder="Super Admin Name*"
                />
                <Input
                    className="inputField"
                    type="text"
                    changeHandler={this.handleChange}
                    name="email_id"
                    id="customName"
                    maxLength={25}
                    value={email_id}
                    placeholder="Email ID*"
                />
                <Input
                    className="inputField"
                    type="text"
                    changeHandler={this.handleChange}
                    name="phone_number"
                    id="customName"
                    value={phone_number}
                    maxLength={25}
                    placeholder="Phone Number *"
                />
                <div className="user-btn">
                    <Button
                        bValue={BUTTON_CONSTANTS?.CREATE}
                        clickHandler={this.handleSubmit}
                        cName="btn primary-btn"
                    />
                    <Button
                        bValue={BUTTON_CONSTANTS?.RESET}
                        clickHandler={this.handleClose}
                        cName="btn cancel-btn"
                    />
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({}, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SuperAdminManagement)
