import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { JobJSON, configJSON } from "./constants"
import { getPipelineSettings } from "../../api/action"

import "./style.scss"
import moment from "moment"
import Button from "../../../common/Button"
import CustomModal from "../../../common/Modal"
import { isEmpty } from "lodash"
import { BUTTON_CONSTANTS } from "../../../../utils/constants"
import { toast } from "react-toastify"

export default function PipelineModal(props) {
    const dispatch = useDispatch()
    const [settings, setSettings] = useState({})
    const [selectedTab, setSelectedTab] = useState(0)
    const [selectedEvent, setEvent] = useState(0)
    const [isOpenDropdown, setDropdownToggle] = useState(false)
    const [showModal, setModal] = useState(false)
    // const [inputProperties, setInputProperties] = useState({});
    const handleTabClick = (tab, e) => {
        setSelectedTab(tab)
        if (tab === 1) {
            setDropdownToggle(!isOpenDropdown)
        }
    }

    useEffect(() => {
        loadHandler()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const loadHandler = async () => {
        let response = await dispatch(
            getPipelineSettings(props?.modalProperties?.selectedObj?.jobId)
        )
        if (response && response.status) {
            setSettings(response?.data)
        }
        console.log("resp", response?.data)
    }

    const setSelectedEvent = (eventNo, e) => {
        e && e.stopPropagation()
        setEvent(eventNo)
    }

    // const ViewInput = (properties) => {
    //   setModal(!showModal);
    //   properties = {
    //     ...properties,
    //     ...properties.detail,
    //     ...properties.detail?.userMetadata,
    //   };
    //   delete properties["detail"];
    //   delete properties["userMetadata"];
    //   setInputProperties(properties);
    // };

    const cancelBtnHandler = () => {
        setModal(!showModal)
    }

    // const DisplayModalContent = (properties) => {
    //   return Object.keys(properties)?.map((value, index) => {
    //     return (
    //       <p className="content-details-items">
    //         <span className="content-details-items-title">{value}</span>{" "}
    //         {value.name !== "inputSource" && (
    //           <span className="content-details-items-details">
    //             {" : "} &nbsp;
    //             {JSON.stringify(properties[value])}
    //           </span>
    //         )}
    //       </p>
    //     );
    //   });
    // };

    const downloadInputJsonHandler = (downloadLink) => {
        if (downloadLink) {
            // Create an anchor element to trigger the download
            const downloadAnchor = document.createElement("a")
            downloadAnchor.href = downloadLink
            downloadAnchor.download = "downloaded-json" // specify the download file name
            downloadAnchor.click()
        } else {
            toast.error("File not present!")
        }
    }

    return (
        <div className="pipeline-modal">
            <CustomModal
                showModal={showModal}
                cancelHandler={cancelBtnHandler}
                onClickCancelBtn={cancelBtnHandler}
                showCloseIcon={true}
                cancelOnOutsideClick={false}
                modalContainerClass={"view-modal"}
                showSubmitButton={false}
            >
                <div className="view-display-modal">
                    {/* {DisplayModalContent(inputProperties)} */}
                </div>
            </CustomModal>
            <h2 className="not-found-access-heading-color">{"View Settings"}</h2>
            <p className="horizontal-line"></p>
            <div className="section">
                <div className="left-section">
                    <div className="left-tabs">
                        <p
                            className={`tab1 ${selectedTab === 0 ? "active" : ""}`}
                            onClick={(e) => handleTabClick(0, e)}
                        >
                            <span className="job-title">Config </span>
                        </p>
                        <p className={`tab2`} onClick={() => handleTabClick(1)}>
                            <p className="job-title">
                                <span>Jobs</span>{" "}
                                <i
                                    className={
                                        !isOpenDropdown ? "icon-arrow-down1" : "icon-arrow-up"
                                    }
                                ></i>
                            </p>
                            {selectedTab === 1 &&
                                isOpenDropdown &&
                                settings?.jobs?.map((value, index) => {
                                    return (
                                        <p
                                            className={`event ${
                                                selectedEvent === index ? "active" : ""
                                            }`}
                                            onClick={(e) => setSelectedEvent(index, e)}
                                        >{`Event ${index + 1}`}</p>
                                    )
                                })}
                        </p>
                    </div>
                </div>
                <div className="right-section">
                    {selectedTab === 0
                        ? configJSON.map((value, index) => {
                              return (
                                  <p className="content-details-items">
                                      <span className="content-details-items-title">
                                          {value?.label}
                                      </span>{" "}
                                      {
                                          <span className="content-details-items-details">
                                              {" : "} &nbsp;
                                              {!isEmpty(
                                                  JSON.stringify(settings?.config?.[value.name])
                                              )
                                                  ? JSON.stringify(settings?.config?.[value.name])
                                                  : "NA"}
                                          </span>
                                      }
                                      {/* {value.name == "inputSource" &&
                      !isEmpty(settings?.config?.[value.name]) && (
                        <span className="content-details-items-details">
                          {" "}
                          <Button
                            cName="btn border-btn"
                            bValue={BUTTON_CONSTANTS?.VIEW}
                            clickHandler={() =>
                              ViewInput(settings?.config?.[value.name])
                            }
                          ></Button>
                        </span>
                      )} */}
                                  </p>
                              )
                          })
                        : JobJSON.map((value, index) => {
                              if (
                                  settings?.jobs?.[selectedEvent]?.[value.name] &&
                                  (value?.name === "startedAt" || value?.name === "endAt")
                              ) {
                                  settings.jobs[selectedEvent][value.name] = moment(
                                      settings?.jobs?.[selectedEvent]?.[value.name]
                                  ).format(" DD MMMM, YYYY")
                              }
                              return (
                                  <p className="content-details-items">
                                      <span className="content-details-items-title">
                                          {value?.label}
                                      </span>{" "}
                                      {value.name !== "input" && (
                                          <span className="content-details-items-details">
                                              {" : "} &nbsp;
                                              {!isEmpty(
                                                  JSON.stringify(
                                                      settings?.jobs?.[selectedEvent]?.[value.name]
                                                  )
                                              )
                                                  ? JSON.stringify(
                                                        settings?.jobs?.[selectedEvent]?.[
                                                            value.name
                                                        ]
                                                    )
                                                  : "NA"}
                                          </span>
                                      )}
                                      {value.name === "input" &&
                                          !isEmpty(
                                              settings?.jobs?.[selectedEvent]?.[value.name]
                                          ) && (
                                              <span className="content-details-items-details">
                                                  {" "}
                                                  <Button
                                                      cName="btn border-btn"
                                                      bValue={BUTTON_CONSTANTS?.DOWNLOAD_EVENT}
                                                      clickHandler={() => {
                                                          downloadInputJsonHandler(
                                                              settings?.jobs?.[selectedEvent]
                                                                  ?.inputJsonLink
                                                          )
                                                          // ViewInput(
                                                          //   settings?.jobs?.[selectedEvent]?.[value.name]
                                                          // )
                                                      }}
                                                  ></Button>
                                              </span>
                                          )}
                                  </p>
                              )
                          })}
                </div>
            </div>
        </div>
    )
}
