export const SPORTS_ACTION = {
    SET_SPORTS_CATEGORY: "SET_SPORTS_CATEGORY",
    SPORTS_SETUP_SELECTED_TAB: "SPORTS_SETUP_SELECTED_TAB",
    SPORTS_SETUP_SET_FILTER_DATA: "SPORTS_SETUP_SET_FILTER_DATA",
    SPORTS_SETUP_CLEAR_FILTER_DATA: "SPORTS_SETUP_CLEAR_FILTER_DATA",
    SPORTS_SETUP_SHOWHIDE_MODAL: "SPORTS_SETUP_SHOWHIDE_MODAL",
    SAVE_SELECTED_SPORTS_DATA: "SAVE_SELECTED_SPORTS_DATA",
    FETCH_SELECTED_SPORTS_LIST: "FETCH_SELECTED_SPORTS_LIST",
    FETCH_SELECTED_SPORTS_FILTER: "FETCH_SELECTED_SPORTS_FILTER",
    FETCH_ALL_CATEGORY: "FETCH_ALL_CATEGORY",
    SPORTS_SETUP_CATEGORY_ID: "SPORTS_SETUP_CATEGORY_ID",
    SPORTS_SETUP_SET_PAGINATION_DATA: "SPORTS_SETUP_SET_PAGINATION_DATA"
}

export const SPORTS_TAB = {
    SPORTS_CATEGORY: "sports_category",
    PLAYERS: "players",
    LEAGUES: "leagues",
    MATCHES: "matches"
}

export const SETUP_SPROTS_CONSTANTS = {
    searchSports: "Search Sports",
    searchPlayer: "Search Player",
    searchLeague: "Search League",
    searchTeam: "Search Teams",
    selectCategory: "Select Category",
    selectLegaueTour: "Select League/Tournament",
    pageStatus: "Page Status",
    sportCategoryFormHeader: "Create New Sport Category",
    sportCategoryNameLabel: "Sport Category",
    sportCategoryPlaceholder: "Enter Sport Category",
    sportShortDetailLabel: "Enter Short Detail",
    shortDetail: "Short Detail",
    description: "Description",
    deleteModalLabel:
        "If you delete the category, all saved rails and data associated with it will be lost, and users will no longer be able to access this category. Please ensure you've made your final decision before proceeding with deletion",
    checkboxLabel: "I Agree",
    createNewPlayer: "Create New Player",
    playerNameLabel: "Player Name",
    playerNamePlaceholder: "Enter Player Name",
    selectSportCategoryLabel: "Select Sports Category",
    teamNameLabel: "Team Name",
    teamNamePlaceholder: "Enter Team Name",
    selectLeague: "Select League",
    createNewMatch: "Create New Match",
    createNewLeague: "Create New League",
    leagueNameLabel: "League Name",
    leagueNamePlaceholder: "Enter League Name",
    informationText: `Create/Manage page from 'Create/Manage Pages' menu`
}

export const SELECT_ALL_OPTION = {
    id: "all",
    name: "all",
    value: "ALL"
}


export const PAGE_STATUS_OPTIONS = [
    SELECT_ALL_OPTION,
    {
        id: "true",
        name: "Published",
        value: "true",
        label: "Published"
    },
    {
        id: "false",
        name: "Unpublished",
        value: "false",
        label: "Unpublished"
    }
]

export const SELECTED_LANGUAGE = {
    code: "en",
    name: "English"
}

export const IS_MULTILINGUAL_FIELD = true
export const MULTILINGUAL_KEY = "multilingual"

export const SPORTS_SETUP_FILTER_KEYS = {
    SPORT_SEARCH: "sportSearch",
    SPORTS_CATEGORY: "sportCategory",
    PAGE_STATUS: "pageStatus",
    LEAGUE: "league"
}

export const SPORTS_TABLE_HEADING = [
    {
        header: "Sr",
        accessor: "sr",
        type: "text",
        isSortEnabled: false
    },
    {
        header: "Icon",
        accessor: "icon",
        type: "text",
        isSortEnabled: false
    },
    {
        header: "Match Name",
        accessor: "name",
        type: "text",
        isSortEnabled: false
    },
    {
        header: "Sport Category",
        accessor: "sportCategory",
        type: "text",
        isSortEnabled: false
    },
    {
        header: "League/Tournament",
        accessor: "league-tournament",
        type: "text",
        isSortEnabled: false
    },
    {
        header: "Page Status",
        accessor: "pageStatus",
        type: "text",
        isSortEnabled: false,
        isInformationTooltip: true
    },
    {
        header: "Short Detail",
        accessor: "shortDetail",
        type: "text",
        isSortEnabled: false,
        isInformationTooltip: false
    },
    {
        header: "Description",
        accessor: "description",
        type: "text",
        isSortEnabled: false,
        isInformationTooltip: false
    },
    {
        header: "Action",
        accessor: "action",
        type: "text",
        isSortEnabled: false
    }
]

export const ACTION_TABLE_MODAL = {
    EDIT_MODAL: "1",
    DELETE_MODAL: "2"
}

export const PaginationOptions = [
    { id: 10, name: "10" },
    { id: 20, name: "20" },
    { id: 30, name: "30" },
    { id: 40, name: "40" },
    { id: 50, name: "50" }
]

export const FORM_FIELDS_NAME = {
    posterImage: "posterImage",
    bannerImage: "bannerImage",
    name: "name",
    category: "category",
    shortDescription: "shortDescription",
    description: "description",
    league: "league"
}

export const FORM_FIELDS_DATA = [
    FORM_FIELDS_NAME.posterImage,
    FORM_FIELDS_NAME.bannerImage,
    FORM_FIELDS_NAME.name,
    FORM_FIELDS_NAME.category,
    FORM_FIELDS_NAME.shortDescription,
    FORM_FIELDS_NAME.description,
    FORM_FIELDS_NAME.league
]
