import React, { useCallback } from "react"
import PropTypes from "prop-types"

import CustomModalFooter from "../Modal/CustomModalFooter"
import close from "../../../assets/images/close.svg"

import "./style.scss"

const RightModal = (props) => {
    const {
        showModal,
        children,
        cancelHandler = () => {},
        submitHandler = () => {},
        submitButtonLabel,
        cancelButtonLabel,
        showCancelButton = false,
        showSubmitButton = true,
        isSubmitDisabled = false,
        cancelOnOutsideClick = true,
        showCloseIcon = true,
        submitBtnClass = "",
        cancelBtnClass = ""
    } = props

    const onClickCancelBtn = useCallback(() => {
        if (cancelHandler) {
            cancelHandler()
        }
    }, [cancelHandler])

    const onClickSubmitBtn = useCallback(() => {
        if (submitHandler) {
            submitHandler()
        }
    }, [submitHandler])

    return (
        <>
            {showModal ? (
                <div
                    className="right-modalContainer"
                    onClick={cancelOnOutsideClick && onClickCancelBtn}>
                    <div className="modal" onClick={(e) => e.stopPropagation()}>
                        <div className="close">
                            {showCloseIcon && (
                                <button onClick={onClickCancelBtn}>
                                    <img src={close} alt="close" />
                                </button>
                            )}
                        </div>
                        {children}
                        <footer className="modal_footer">
                            <CustomModalFooter
                                cancelBtnHandler={onClickCancelBtn}
                                submitBtnHandler={onClickSubmitBtn}
                                showCancelButton={showCancelButton}
                                cancelButtonLabel={cancelButtonLabel}
                                submitButtonLabel={submitButtonLabel}
                                showSubmitButton={showSubmitButton}
                                isSubmitDisabled={isSubmitDisabled}
                                submitBtnClass={submitBtnClass}
                                cancelBtnClass={cancelBtnClass}
                            />
                        </footer>
                    </div>
                </div>
            ) : null}
        </>
    )
}

RightModal.propTypes = {
    submitHandler: PropTypes.func,
    cancelHandler: PropTypes.func,
    submitButtonLabel: PropTypes.string,
    cancelButtonLabel: PropTypes.string,
    showCancelButton: PropTypes.bool
}

export default RightModal
