import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import moment from "moment"
import "./style.scss"

class InvoiceDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        // this.loadHandler()
    }

    render() {
        const { handleclose, invoiceDetail } = this.props
        return (
            <div className="preview movieView">
                <div className="prev-container">
                    <h4 className="movieViewHead">
                        {" "}
                        Invoice Details
                        <i title="Close" className="icon-close" onClick={handleclose} />
                    </h4>
                </div>
                <div className="inner-content">
                    <div className="invoice-detail">
                        <div className="detail">
                            <p>INVOICE ID</p>
                            <p>{invoiceDetail.invoiceId}</p>
                        </div>
                        {/* <div className="icons"> 
                            <i
                                className="icon-published"

                            />
                            <i
                                className="icon-selection"

                            />
                        </div> */}
                    </div>

                    <ul className="invoice-detail-table">
                        <li>
                            <p>Invoice Date</p>
                            <p>{moment(invoiceDetail.invoiceDate).format(" DD MMMM, YYYY")}</p>
                        </li>
                        <li>
                            <p>Service Period</p>
                            <p>
                                {moment(invoiceDetail.invoiceDate).format(" DD MMMM, YYYY")} -{" "}
                                {moment(invoiceDetail.targetDate).format(" DD MMMM, YYYY")}
                            </p>
                        </li>
                        <li>
                            <p>Charge Summary</p>
                            <p>Monthly Charge</p>
                        </li>
                    </ul>

                    {/* <div className="transaction-heading">
                        transaction details
                    </div>

                    <ul className="transaction-list">
                        <li>
                            <div className="trans-info">
                                <div className="trans-code">
                                    <p>Transaction ID</p>
                                    <p>P-00158510</p>
                                </div>
                                <div className="trans-status">
                                    <span className="Failed">FAILED</span>
                                </div>
                            </div>
                            <div className="trans-detail">
                                <div className="trans-date">
                                    <span>Date - 25 Aug 2020, 2:30 am</span>
                                    <span>Amount</span>

                                </div>
                                <div className="trans-type">
                                    <span>Type - Credit Card</span>
                                    <span>$120</span>
                                </div>
                            </div>
                        </li>

                        <li>
                            <div className="trans-info">
                                <div className="trans-code">
                                    <p>Transaction ID</p>
                                    <p>P-00158510</p>
                                </div>
                                <div className="trans-status">
                                    <span className="Successful">SUCCESSFUL</span>
                                </div>
                            </div>
                            <div className="trans-detail">
                                <div className="trans-date">
                                    <span>Date - 25 Aug 2020, 2:30 am</span>
                                    <span>Amount</span>

                                </div>
                                <div className="trans-type">
                                    <span>Type - Credit Card</span>
                                    <span>$120</span>
                                </div>
                            </div>
                        </li>

                    </ul> */}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                // changeUploadImage,
                // uploadImage,
                // deleteContentImageHistory
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetail)
