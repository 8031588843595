import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom"

import { URL } from "../../../utils/routeConstants"
import MultilingualDarkIcon from "../../../assets/images/multilingual-dark.svg"
import MultilingualLightIcon from "../../../assets/images/multilingual-light-icon.svg"
import MultilingualActiveIcon from "../../../assets/images/multilingual-active-icon.svg"
import { checkIfUserHasAccess } from "../../../utils/users"
import { PERMISSIONS } from "../../../utils/constants"

import "./style.scss"

const adminMenuItems = [
    {
        labelName: "dashboard",
        linkName: "dashboard",
        iconName: "icon-dashboard-3",
        permissions: [PERMISSIONS?.DASHBOARD_VIEW, PERMISSIONS?.DASHBOARD_EDIT]
    },
    {
        labelName: "stream",
        linkName: "stream",
        iconName: "icon-stream",
        permissions: [PERMISSIONS?.STREAM_VIEW],
        children: [
            {
                labelName: "content",
                linkName: "content",
                iconName: "icon-content",
                children: [],
                permissions: [PERMISSIONS?.CONTENT_EDIT, PERMISSIONS?.CONTENT_VIEW]
            },
            {
                labelName: "Epg",
                linkName: "epg",
                iconName: "icon-epg",
                permissions: [PERMISSIONS?.EPG_EDIT, PERMISSIONS?.EPG_VIEW]
            },
            {
                labelName: "Video Pipeline",
                linkName: "videoPipeline",
                iconName: "icon-dashboard-3",
                permissions: [PERMISSIONS?.VIDEO_PIPELINE_EDIT, PERMISSIONS?.VIDEO_PIPELINE_VIEW]
            }
        ]
    },
    {
        labelName: "engage",
        linkName: "engage",
        iconName: "icon-experience",
        permissions: [PERMISSIONS?.ENGAGE_EDIT, PERMISSIONS?.ENGAGE_VIEW],
        children: [
            {
                labelName: "UX Manager",
                linkName: "ux-manager",
                iconName: "icon-editorial",
                permissions: [
                    PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_VIEW,
                    PERMISSIONS?.UXMANAGER_VIEW
                ],
                children: [
                    {
                        labelName: "category",
                        linkName: "category",
                        permissions: [
                            PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_VIEW,
                            PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_EDIT
                        ]
                    }
                ]
            },
            {
                labelName: "Sports",
                linkName: "sports",
                iconName: "icon-editorial",
                permissions: [
                    PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_VIEW,
                    PERMISSIONS?.UXMANAGER_VIEW
                ],
                children: [
                    {
                        labelName: "setup sports",
                        linkName: "setup-sports",
                        permissions: [
                            PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_VIEW,
                            PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_EDIT
                        ]
                    },
                    {
                        labelName: "create/manage pages",
                        linkName: "create-pages",
                        permissions: [
                            PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_VIEW,
                            PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_EDIT
                        ]
                    },
                    {
                        labelName: "sports home page",
                        linkName: "sports-home",
                        permissions: [
                            PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_VIEW,
                            PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_EDIT
                        ]
                    }
                ]
            },
            {
                labelName: "Custom Pages",
                linkName: "custompages",
                iconName: "icon-customepage",
                permissions: [
                    PERMISSIONS?.ENGAGE_UX_MANAGER_CUSTOM_PAGES_VIEW,
                    PERMISSIONS?.ENGAGE_UX_MANAGER_CUSTOM_PAGES_EDIT
                ]
            },
            {
                labelName: "menu design",
                linkName: "menudesign",
                iconName: "icon-menudesign",
                permissions: [
                    PERMISSIONS?.ENGAGE_UX_MANAGER_MENU_DESIGN_EDIT,
                    PERMISSIONS?.ENGAGE_UX_MANAGER_MENU_DESIGN_VIEW
                ]
            },
            {
                labelName: "content enrichment",
                linkName: "contentenrichment",
                disable: true,
                permissions: [
                    PERMISSIONS?.ENGAGE_UX_MANAGER_CONTENT_ENRICHMENT_EDIT,
                    PERMISSIONS?.ENGAGE_UX_MANAGER_CONTENT_ENRICHMENT_VIEW
                ]
            },
            {
                labelName: "misc links",
                linkName: "misc-links",
                iconName: "icon-menudesign",
                permissions: [
                    PERMISSIONS?.ENGAGE_UX_MANAGER_MENU_DESIGN_EDIT,
                    PERMISSIONS?.ENGAGE_UX_MANAGER_MENU_DESIGN_VIEW
                ]
            }
        ]
    },

    {
        labelName: "Monetize",
        linkName: "monetize",
        iconName: "icon-monetize",
        disable: true,
        permissions: [PERMISSIONS?.MONETIZE_EDIT, PERMISSIONS?.MONITIZE_VIEW],
        children: [
            {
                labelName: "product placement",
                linkName: "placement",
                iconName: "icon-productplacement",
                permissions: [
                    PERMISSIONS?.MONETIZE_PRODUCT_PLACEMENT_EDIT,
                    PERMISSIONS?.MONETIZE_PRODUCT_PLACEMENT_VIEW
                ]
            }
            /* {
        labelName: "roi maximiser",
        linkName: "maximiser",
        iconName: "icon-roimaximiser",
        permissions: [PERMISSIONS?.MONETIZE_ROI_MAXIMISER_EDIT, PERMISSIONS?.MONETIZE_ROI_MAXIMISER_VIEW],
      }, */
        ]
    },
    {
        labelName: "Tenant List",
        linkName: "tenant",
        iconName: "icon-dashboard-3",
        disable: false,
        permissions: [PERMISSIONS?.TENANT_LIST_EDIT, PERMISSIONS?.TENANT_LIST_VIEW]
    },
    {
        labelName: "Add Tenant",
        linkName: "tenant/add_tenant",
        iconName: "icon-dashboard-3",
        disable: false,
        permissions: [PERMISSIONS?.ADD_TENANT_EDIT, PERMISSIONS?.ADD_TENANT_VIEW]
    },
    {
        labelName: "settings",
        linkName: "settings",
        iconName: "icon-settings",
        permissions: [PERMISSIONS?.SETTING_VIEW, PERMISSIONS?.SETTING_EDIT],
        children: [
            {
                labelName: "Configuration",
                linkName: "config",
                // assetsIcon: {
                //   darkMode: MultilingualDarkIcon,
                //   lightMode: MultilingualLightIcon,
                //   activeIcon: MultilingualActiveIcon,
                // },
                iconName: "icon-settings",
                // permissions: [
                //   PERMISSIONS?.BASIC_CONFIGURATION_VIEW,
                //   PERMISSIONS?.BASIC_CONFIGURATION_EDIT,
                // ],
                children: [
                    {
                        labelName: "Basic Configuration",
                        linkName: "basic-config",
                        assetsIcon: {
                            darkMode: MultilingualDarkIcon,
                            lightMode: MultilingualLightIcon,
                            activeIcon: MultilingualActiveIcon
                        },
                        permissions: [
                            PERMISSIONS?.BASIC_CONFIGURATION_VIEW,
                            PERMISSIONS?.BASIC_CONFIGURATION_EDIT
                        ]
                    },
                    {
                        labelName: "Timezone",
                        linkName: "timezone",
                        assetsIcon: {
                            darkMode: MultilingualDarkIcon,
                            lightMode: MultilingualLightIcon,
                            activeIcon: MultilingualActiveIcon
                        },
                        permissions: [PERMISSIONS?.TIMEZONE_VIEW, PERMISSIONS?.TIMEZONE_EDIT]
                    }
                ]
            },
            {
                labelName: "user management",
                linkName: "management",
                iconName: "icon-user-management",
                permissions: [
                    PERMISSIONS?.SETTING_USER_MANAGEMENT_EDIT,
                    PERMISSIONS?.SETTING_USER_MANAGEMENT_VIEW
                ],
                children: [
                    {
                        labelName: "users",
                        linkName: "users",
                        permissions: [
                            PERMISSIONS?.SETTING_USER_MANAGEMENT_USERS_EDIT,
                            PERMISSIONS?.SETTING_USER_MANAGEMENT_USERS_VIEW
                        ]
                    },
                    {
                        labelName: "roles",
                        linkName: "roles",
                        permissions: [
                            PERMISSIONS?.SETTING_USER_MANAGEMENT_ROLES_EDIT,
                            PERMISSIONS?.SETTING_USER_MANAGEMENT_ROLES_VIEW
                        ]
                    }
                ]
            },
            {
                labelName: "Customer",
                linkName: "customerbranding",
                iconName: "icon-customepage",
                permissions: [
                    PERMISSIONS?.CUSTOMER_BRANDING_EDIT,
                    PERMISSIONS?.CUSTOMER_BRANDING_VIEW
                ]
            },

            {
                labelName: "multilingual",
                linkName: "multilingual",
                assetsIcon: {
                    darkMode: MultilingualDarkIcon,
                    lightMode: MultilingualLightIcon,
                    activeIcon: MultilingualActiveIcon
                },
                // disable: true,
                permissions: [
                    PERMISSIONS?.SETTING_MULTILINGUAL_EDIT,
                    PERMISSIONS?.SETTING_MULTILINGUAL_VIEW
                ]
            },
            {
                labelName: "Add Translation",
                linkName: "add-translation",
                permissions: [PERMISSIONS?.TRANSLATION_EDIT, PERMISSIONS?.TRANSLATION_VIEW]
            },
            {
                labelName: "Drm Configuration",
                linkName: "drm-config",
                assetsIcon: {
                    darkMode: MultilingualDarkIcon,
                    lightMode: MultilingualLightIcon,
                    activeIcon: MultilingualActiveIcon
                },
                disable: true,
                permissions: [PERMISSIONS?.DRM_EDIT, PERMISSIONS?.DRM_VIEW]
            },
            // {
            //     labelName: "platforms",
            //     linkName: "platforms",
            //     iconName: "icon-menudesign",
            //     permissions: []
            // },
            // {
            //     labelName: "environments",
            //     linkName: "environments",
            //     iconName: "icon-menudesign",
            //     permissions: []
            // },
            {
                labelName: "platform config",
                linkName: "platform-config",
                iconName: "icon-menudesign",
                permissions: []
            }
        ]
    },
    {
        labelName: "audit trail",
        linkName: "audit-trail",
        iconName: "icon-dashboard-3",
        permissions: [
            PERMISSIONS?.SETTING_USER_MANAGEMENT_USERS_EDIT,
            PERMISSIONS?.SETTING_USER_MANAGEMENT_USERS_VIEW
        ]
    }
]

var navMenuItems = adminMenuItems

// Temp changes: VRPR:303 Focus not shifting to clicked element
const availableRoute = [
    {
        pathname: `/`,
        tabName: "dashboard"
    },
    {
        pathname: `/dashboard`,
        tabName: "dashboard"
    },
    {
        pathname: `/${URL.TENANT}`,
        tabName: "tenantList"
    },
    {
        pathname: `/${URL.TENANT}/${URL.ADD_TENANT}`,
        tabName: "addTenant"
    }
]

class SideNave extends Component {
    state = {
        activeTab: "dashboard",
        activeTabIndex: 0
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let element = navMenuItems.findIndex(
            (value) => "/" + value.linkName === nextProps.history.location.pathname
        )
        availableRoute.map((route) => {
            if (route.pathname === nextProps.history.location.pathname) {
                if (this.state.activeTab !== route.tabName) {
                    this.setState({
                        activeTab: route.tabName,
                        activeTabIndex: element
                    })
                }
            }
            return route
        })
    }

    handleToggleTab = (name, index) => {
        this.setState((prevState) => ({
            activeTab: prevState.activeTab !== name ? name : "",
            activeTabIndex: index
        }))
    }

    handleTenantToggleTab = (name, index) => {
        this.setState((prevState) => ({
            activeTab: name,
            activeTabIndex: index
        }))
    }
    handleSubmenu = (name) => {
        this.setState({
            activeSubMenu: name
        })
    }
    openLink = (url) => {
        window.open(url)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.contentList !== "" &&
            this.props.contentList !== "" &&
            this.props.contentList !== undefined &&
            this.props.contentList.length > 0
        ) {
            const parentIndex =
                navMenuItems && navMenuItems.findIndex((item) => item.labelName === "stream")
            const childIndex =
                parentIndex > -1 &&
                navMenuItems &&
                navMenuItems[parentIndex] &&
                navMenuItems[parentIndex].children.findIndex((item) => item.labelName === "content")

            const additionalContentTypeItems = [
                {
                    labelName: "Metadata Ingestion",
                    linkName: "metadata-ingestion",
                    id: `metadata-ingestion-1`,
                    name: "metadata-ingestion",
                    permissions: [PERMISSIONS.BULK_UPLOAD_EDIT, PERMISSIONS.BULK_UPLOAD_VIEW]
                }
            ]

            if (this.props.contentList && this.props.contentList.length) {
                if (
                    navMenuItems[parentIndex] &&
                    navMenuItems[parentIndex].children &&
                    navMenuItems[parentIndex].children[childIndex] &&
                    navMenuItems[parentIndex].children[childIndex].children.length !==
                        this.props.contentList.length + additionalContentTypeItems?.length
                ) {
                    this.props.contentList.map((content, i) => {
                        if (content) {
                            navMenuItems &&
                                navMenuItems?.[parentIndex] &&
                                navMenuItems?.[parentIndex].children &&
                                navMenuItems[parentIndex].children[childIndex] &&
                                navMenuItems[parentIndex].children[childIndex].children.push({
                                    labelName: content?.includes("_")
                                        ? content.replace("_", " ")
                                        : content,
                                    linkName: content.split(" ").join(""),
                                    id: i,
                                    name: content.split(" ").join("")
                                })
                        }
                        return content
                    })
                    // Add metadata ingestion tab for the first time
                    navMenuItems &&
                        navMenuItems?.[parentIndex] &&
                        navMenuItems?.[parentIndex]?.children &&
                        navMenuItems[parentIndex].children[childIndex] &&
                        navMenuItems[parentIndex].children[childIndex].children.push(
                            ...additionalContentTypeItems
                        )
                }
            }
        }
    }

    componentDidMount() {
        const {
            history: {
                location: { pathname }
            }
        } = this.props
        navMenuItems &&
            navMenuItems.forEach((nav, index) => {
                if (pathname === nav.linkName || pathname.indexOf(nav.linkName) > -1) {
                    this.setState({
                        activeTab: nav.linkName,
                        activeTabIndex: index
                    })
                }
            })
    }

    handleClick = (e, array) => {
        if (Array.isArray(array) && array.length) {
            e.preventDefault()
        }
    }

    goToTenantList = () => {
        const { history } = this.props
        history.push(`/${URL.TENANT}`)
    }

    addNewTenant = () => {
        const { history } = this.props
        history.push(`/${URL.TENANT}/${URL.ADD_TENANT}`)
    }

    goToTenantPage = () => {
        const { history } = this.props
        history.push("/tenant")
    }

    renderIcon = (child, isSubActive) => {
        const { isDarkMode } = this.props
        const { activeSubMenu } = this.state

        let imageIcon
        if (activeSubMenu === child?.linkName || isSubActive) {
            imageIcon = child?.assetsIcon?.activeIcon
        } else {
            imageIcon = isDarkMode ? child?.assetsIcon?.darkMode : child?.assetsIcon?.lightMode
        }

        return (
            <div className="iconimg-container">
                <img className="icon-img" src={imageIcon} alt="icon" />
                {child?.labelName}{" "}
            </div>
        )
    }

    checkMenuItemPermission = (menuItem) => {
        return checkIfUserHasAccess(menuItem?.permissions)
    }

    // To render Menu Items which do not have any children
    renderSingleMenuItem = ({ menuItem, index }) => {
        const { activeTabIndex } = this.state

        if (!this.checkMenuItemPermission(menuItem) || menuItem.disable) {
            return null
        }

        return (
            <li
                key={menuItem.linkName}
                className={`dropdown-menu ${activeTabIndex === index ? "active" : ""}`}
                onClick={() => this.handleToggleTab(menuItem.linkName, index)}>
                {menuItem.type !== "webUrl" ? (
                    <Link to={`/${menuItem.linkName}`}>
                        <i className={`${menuItem.iconName}`}></i>
                        {menuItem.labelName}
                    </Link>
                ) : (
                    <a
                        href="https://console.firebase.google.com/?pli=1"
                        target="_blank"
                        rel="noopener noreferrer">
                        <i className={`${menuItem.iconName}`}></i>
                        {menuItem.labelName}
                    </a>
                )}
            </li>
        )
    }

    renderMenuItemsWithChildren = ({ menuItem, index }) => {
        const {
            history: { location }
        } = this.props
        const { activeTab, activeTabIndex } = this.state

        let pathname = location.pathname

        if (!this.checkMenuItemPermission(menuItem) || menuItem.disable) {
            return null
        }

        return (
            <Fragment key={menuItem.linkName}>
                <li
                    className={`dropdown-menu ${index === activeTabIndex ? "active" : ""}`}
                    key={index}
                    onClick={() => this.handleToggleTab(menuItem.linkName, index)}
                    on={() => {
                        this.setState({ activeTab: null })
                    }}
                    onMouseLeave={() => {
                        this.setState({ activeSubMenu: null })
                    }}>
                    <Link
                        to={{
                            pathname: ``,
                            contentId: {
                                id: menuItem.children[0] ? menuItem.children[0].id : 0
                            }
                        }}>
                        <i className={`${menuItem.iconName}`}></i>
                        {menuItem.labelName}
                        {menuItem.children && (
                            <i
                                className={
                                    activeTab === menuItem.linkName
                                        ? "icon-arrow-up"
                                        : "icon-arrow-down1"
                                }
                                style={{ float: "right" }}
                            />
                        )}
                    </Link>
                    <ul
                        className="menu-dropdown-list"
                        onClick={(event) => {
                            event.stopPropagation()
                        }}>
                        {activeTab === menuItem.linkName &&
                            menuItem.children &&
                            menuItem.children.map((child, index) => {
                                return this.renderSubMenuItems({ menuItem, child, pathname })
                            })}
                    </ul>
                </li>
            </Fragment>
        )
    }

    // To render menuItems which contains sub routes
    renderSubMenuItems = ({ menuItem, child, pathname }) => {
        const { activeSubMenu } = this.state

        if (!this.checkMenuItemPermission(child) || child.disable) {
            return null
        }
        let isSubActive =
            child.linkName.replace(/\s+/g, "-").toLowerCase() ===
            (pathname.split("/")[2] && pathname.split("/")[2].toLowerCase())

        return (
            <li
                key={`${menuItem.linkName}-${child.linkName}`}
                className={isSubActive ? "active" : ""}
                onMouseEnter={(e) => this.handleSubmenu(child.linkName)}>
                {child.labelName === "roi maximiser" ? (
                    <a target="_blank" href="https://portal.vionlabs.com" rel="noopener noreferrer">
                        <i className={`${menuItem.iconName}`}></i>
                        {child.labelName}
                    </a>
                ) : (
                    <Link
                        to={{
                            pathname: child.children
                                ? ""
                                : `/${menuItem.linkName}/${child.linkName}`,
                            contentType: { linkName: child.linkName }
                        }}
                        onClick={(e) => this.handleClick(e, child.children)}>
                        {child?.assetsIcon ? (
                            this.renderIcon(child, isSubActive)
                        ) : (
                            <>
                                <i className={child.iconName ? child.iconName : "vr-icon-circle"} />
                                {child.labelName}
                            </>
                        )}
                        {child.children && (
                            <i className={"icon-arrow-right1"} style={{ float: "right" }} />
                        )}
                    </Link>
                )}
                {child.children && (
                    <ul
                        className={`submenu-dropdown-list ${
                            activeSubMenu === child.linkName && "show"
                        }`}
                        onClick={(event) => {
                            event.stopPropagation()
                        }}>
                        {child.children.map((child1, index) => {
                            return this.renderMenuItemsGrandChild({
                                menuItem,
                                child1,
                                child,
                                pathname
                            })
                        })}
                    </ul>
                )}
            </li>
        )
    }

    renderMenuItemsGrandChild = ({ menuItem, child1, child, pathname }) => {
        let isSubActive =
            child1.linkName.replace(/\s+/g, "-").toLowerCase() ===
            (pathname.split("/")[3] && pathname.split("/")[3].toLowerCase())

        if (!this.checkMenuItemPermission(child1) || child1.disable) {
            return null
        }

        return (
            <li
                key={`${menuItem.linkName}-${child1.linkName}`}
                className={`${isSubActive ? "active" : ""} ${
                    child1.linkName === "metadata-ingestion" ? "metadata-ingestion" : ""
                }`}>
                <Link
                    to={{
                        pathname: `/${menuItem.linkName}/${child.linkName}/${child1.linkName}`,
                        contentType: {
                            linkName: child1.linkName
                        }
                    }}>
                    <i className={"vr-icon-circle"} /> {child1.labelName}
                </Link>
            </li>
        )
    }

    renderMenuItems = () => {
        if (!this.props?.isSubscriptionEnabled) {
            const index =
                navMenuItems && navMenuItems.findIndex((item) => item?.labelName === "subscription")
            if (index !== -1) {
                navMenuItems.splice(index, 1)
            }
        }
        const menuItems =
            navMenuItems &&
            navMenuItems.map((menuItem, index) => {
                // let pathname = location.pathname;
                return menuItem?.children
                    ? this.renderMenuItemsWithChildren({ menuItem, index })
                    : this.renderSingleMenuItem({ menuItem, index })
            })

        return menuItems
    }

    render() {
        return (
            <nav>
                <ul className="vr-nav">{this.renderMenuItems()}</ul>
            </nav>
        )
    }
}

export default SideNave
