import React, { useState } from "react"
import './style.scss'

const HoverOverTooltip = (props) => {
    const {
        children,
        tooltipContent,
        direction,
    } = props

    const [isHovered, setIsHovered] = useState(false);
    return (
        <div className={"hover-over-tooltip-wrapper"}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {children}
            {isHovered && (
                <div
                    className={`hover-over-tooltip hover-over-tooltip-${direction}`}
                >
                    <span className={"hover-over-tooltip-content"}>{tooltipContent}</span>
                </div>
            )}
        </div>
    )
}

export default HoverOverTooltip