import React, { Component } from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import isEqual from "lodash/isEqual"

import Button from "../../common/Button"

import "./style.scss"
import { BUTTON_CONSTANTS, PERMISSIONS, TEXT_CONSTANTS } from "../../../utils/constants"
import { checkIfUserHasAccess } from "../../../utils/users"

class RailsListing extends Component {
    constructor(props) {
        super(props)
        this.state = {
            newIndex: -1,
            railListing: [],
            railList: [],
            isOrderChange: false,
            isShowPopup: false
        }
        this.canUpdateStatus = checkIfUserHasAccess([PERMISSIONS.ENGAGE_UX_MANAGER_CATEGORY_EDIT])
    }
    componentDidMount() {
        this.setState({
            railListing: this.props.rails,
            railList: this.props.rails,
            fastChannel: this.props.fastChannel
        })
    }
    static getDerivedStateFromProps(props, state) {
        if (!isEqual(props.rails, state.railListing)) {
            return {
                railListing: props.rails,
                railList: props.rails,
                isShowPopup: false,
                isOrderChange: false
            }
        }

        return null
    }
    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    }

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return
        }

        const railList = this.reorder(
            this.state.railList,
            result.source.index,
            result.destination.index
        )

        this.setState({
            railList,
            isOrderChange: true
        })
    }

    handleOrderSave = () => {
        this.setState({
            isShowPopup: !this.state.isShowPopup
        })
    }

    handleOrderSaveCancel = () => {
        const { rails } = this.props
        this.setState({
            railList: rails,
            isOrderChange: false
        })
    }

    handlePopUpClose = () => {
        this.setState({
            isShowPopup: !this.state.isShowPopup,
            isOrderChange: false,
            railListing: this.props.rails
        })
        this.handleOrderSaveCancel()
    }

    handlePopUpSave = () => {
        const { railList } = this.state
        this.props.changeRailsSequence(railList)
    }

    render() {
        const { handleClick, rails } = this.props

        const { isShowPopup, isOrderChange, railList } = this.state
        return (
            <div className="railMenu">
                {isShowPopup ? (
                    <div className="popUp">
                        <div className="popup-text">
                            <p>{TEXT_CONSTANTS?.SAVE_CHANGES}</p>
                        </div>
                        <div className="popup-btns">
                            {this.canUpdateStatus && (
                                <Button
                                    clickHandler={this.handlePopUpSave}
                                    cName="btn popup-btn"
                                    bValue={BUTTON_CONSTANTS?.YES_SAVE}
                                    bType="button"
                                />
                            )}
                            <Button
                                clickHandler={this.handlePopUpClose}
                                cName="btn cancel-btn"
                                bValue={BUTTON_CONSTANTS?.CANCEL}
                                bType="button"
                            />
                        </div>
                    </div>
                ) : (
                    <div className="main-content">
                        {rails.length > 1 && (
                            <span className="railList-head">{TEXT_CONSTANTS?.DRAG_TO_CHANGE}</span>
                        )}
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <ul {...provided.droppableProps} ref={provided.innerRef}>
                                        {railList.map((item, index) => (
                                            <Draggable
                                                key={item.id}
                                                draggableId={`item-${item.id}`}
                                                index={index}
                                                isDragDisabled={!this.canUpdateStatus}>
                                                {(provided, snapshot) => (
                                                    <li
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}>
                                                        <div className="drag">
                                                            <span className="menu-icon"></span>
                                                            <span className="menu-icon"></span>
                                                            <span className="menu-icon"></span>
                                                        </div>
                                                        <span>{item.title}</span>
                                                    </li>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </ul>
                                )}
                            </Droppable>
                        </DragDropContext>
                        {isOrderChange ? (
                            <div className="orderChangeBtn">
                                {this.canUpdateStatus && (
                                    <Button
                                        cName="btn primary-btn"
                                        bValue={BUTTON_CONSTANTS?.PUBLISH_CHANGES}
                                        clickHandler={this.handleOrderSave}
                                    />
                                )}
                                <Button
                                    cName="btn cancel-btn"
                                    bValue={BUTTON_CONSTANTS?.CANCEL}
                                    clickHandler={this.handleOrderSaveCancel}
                                />
                            </div>
                        ) : (
                            this.canUpdateStatus && (
                                <Button
                                    leftIcon="icon-plus"
                                    cName="btn border-btn"
                                    bValue={TEXT_CONSTANTS?.ADD_NEW_RAIL?.toLowerCase()}
                                    clickHandler={handleClick}
                                />
                            )
                        )}
                    </div>
                )}
            </div>
        )
    }
}

export default RailsListing
