import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import get from "lodash/get"
import { toast } from "react-toastify"
import Button from "../common/Button"
import CreateTextPage from "../CustomPages/createTextPage"
import FaqPage from "../CustomPages/faq/faq"
import CreateForm from "../CustomPages/createForm"
import { CUSTOM_PAGE_TABLE_HEADING } from "./APIs/constants"
import { fetchAlltextpage, softDeleteTextpage, filterTextpage } from "./APIs/action"
import { isMultiLingual, scrollPage } from "../../utils/common"
import moment from "moment"

import "./style.scss"
import { BUTTON_CONSTANTS, PERMISSIONS, TEXT_CONSTANTS } from "../../utils/constants"
import { AuthWrapper } from "../common/AuthWrapper"

class CustomPages extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isCreateTextPage: false,
            isFaqPage: false,
            isCreateForm: false,
            pageType: "",
            customText: [],
            isEdit: true,
            isDelete: false,
            idToDelete: -1,
            editableData: [],
            apiOptions: {
                offset: 0,
                limit: 40,
                searchTag: ""
            },
            pageCount: 0,
            currentPage: 0
        }
    }

    componentDidMount() {
        scrollPage()
        this.loadHandler()
    }

    loadHandler = async () => {
        const { fetchAlltextpage } = this.props
        const response = await fetchAlltextpage(this.state.apiOptions)
        if (response && response.status) {
            this.setState({
                customText: response.data
            })
        }

        this.pageCountSetup()
    }

    pageCountSetup = () => {
        const { customTextCount } = this.props
        const { limit } = this.state.apiOptions
        let pageCount = Math.ceil(customTextCount / limit)
        this.setState({
            pageCount
        })
    }

    onPageChange = async (e) => {
        const { apiOptions } = this.state
        const selectedPage = e.selected
        apiOptions.offset = selectedPage * apiOptions.limit
        this.setState(
            {
                currentPage: selectedPage,
                apiOptions
            },
            () => scrollPage()
        )
        this.loadHandler()
    }

    showPerPageChange = async (name, value) => {
        let { apiOptions } = this.state
        apiOptions.limit = value
        this.setState({
            apiOptions
        })
        this.loadHandler()
    }

    handleSearch = async (name, value) => {
        const { apiOptions } = this.state
        apiOptions["searchTag"] = value

        this.setState(
            {
                apiOptions
            },
            () => {
                this.onPageChange({ selected: 0 })
            }
        )
        this.loadHandler()
    }

    handleIsAddCustomText = (isDataRefreshRequired = false) => {
        this.setState(
            {
                isCreateTextPage: !this.state.isCreateTextPage,
                isEdit: false
            },
            () => scrollPage()
        )
        isDataRefreshRequired && this.loadHandler()
    }

    handleDeleteCustomText = async (id) => {
        const { softDeleteTextpage } = this.props
        const response = await softDeleteTextpage(id)
        if (get(response, "status")) {
            toast.success(get(response, "message"))
        } else {
            toast.error(get(response, "message"))
        }
        this.setState({
            isDelete: !this.state.isDelete
        })
        this.loadHandler()
    }
    handleEditCustomText = (item) => {
        if (item.pageTypes === "TEXT") {
            this.setState({
                isEdit: true,
                editableData: item,
                isCreateTextPage: !this.state.isCreateTextPage
            })
        } else if (item.pageTypes === "FAQ") {
            this.setState({
                isEdit: true,
                editableData: item,
                isFaqPage: !this.state.isFaqPage
            })
            // } else if (item.pageType === "FORM") {
            //     this.setState({
            //         isEdit: true,
            //         editableData: item,
            //         isCreateForm: !this.state.isCreateForm
            //     })
        }
    }

    handleDeleteClick = (id, pageType) => {
        const { isDelete, idToDelete } = this.state
        if (!isDelete) {
            this.setState({
                idToDelete: id,
                pageTypeToDelete: pageType,
                isDelete: !this.state.isDelete
            })
        } else if (id === idToDelete) {
            this.setState({
                idToDelete: -1,
                pageTypeToDelete: "",
                isDelete: !this.state.isDelete
            })
        }
    }

    faqPage = () => {
        this.setState(
            {
                isFaqPage: !this.state.isFaqPage,
                isEdit: false
            },
            () => scrollPage()
        )
        this.loadHandler()
    }

    CreateForm = () => {
        this.setState(
            {
                isCreateForm: !this.state.isCreateForm,
                isEdit: false
            },
            () => scrollPage()
        )
        this.loadHandler()
    }

    render() {
        const {
            isCreateTextPage,
            isFaqPage,
            isCreateForm,
            pageTypeToDelete,
            customText,
            isDelete,
            idToDelete,
            editableData,
            isEdit
        } = this.state

        const tableHeading = CUSTOM_PAGE_TABLE_HEADING.map((item, index) => {
            return (
                <th key={index} className="custom_thead">
                    {item}
                </th>
            )
        })
        const tableRows = customText.map((row, index) => {
            return (
                <tr id={row.id} key={index} className="custom_tr">
                    <td>{index + 1}</td>
                    <td>{isMultiLingual() ? row.name["en"] : row.name}</td>
                    <td>{row.pageTypes}</td>
                    <td> {moment(row.createdDate).format(" DD MMMM, YYYY")}</td>

                    <td>{moment(row.modifiedDate).format(" DD MMMM, YYYY")}</td>

                    <td className="btn-popupContainer">
                        <i
                            className="icon-edit"
                            onClick={(item) => this.handleEditCustomText(row)}
                        />
                        <AuthWrapper
                            permissions={[PERMISSIONS?.ENGAGE_UX_MANAGER_CUSTOM_PAGES_EDIT]}>
                            <i
                                className="icon-del"
                                onClick={() => this.handleDeleteClick(row.id, row.pageTypes)}
                            />
                        </AuthWrapper>
                        {idToDelete === row.id && pageTypeToDelete === row.pageTypes && (
                            <div
                                className={`deletePopup ${
                                    isDelete ? "showPopUp slide-right" : "hidePopUp slide-left"
                                }`}>
                                <p>Delete this field?</p>
                                <Button
                                    cName="btn popup-del-btn"
                                    bValue={BUTTON_CONSTANTS?.YES_DELETE?.toUpperCase()}
                                    bType="button"
                                    clickHandler={() =>
                                        this.handleDeleteCustomText(row.id, row.pageTypes)
                                    }
                                />
                                <Button
                                    cName="btn cancel-btn"
                                    bValue={BUTTON_CONSTANTS?.CANCEL?.toUpperCase()}
                                    bType="button"
                                    clickHandler={() =>
                                        this.handleDeleteClick(row.id, row.pageTypes)
                                    }
                                />
                            </div>
                        )}
                    </td>
                </tr>
            )
        })
        return (
            <div className="vd-custom-management">
                <div className="vr-cont-head mb">
                    {/* TODO: Replace below with the reusable pageTitleComponent */}
                    <div className="vr-cont-title">
                        <span className="vr-breadcrum">
                            {TEXT_CONSTANTS?.SETTINGS}
                            <i className="icon-arrow-right1" />
                            <span>{TEXT_CONSTANTS?.CUSTOM_PAGES}</span>
                        </span>
                        <h2 className="breadcrum-header">
                            {TEXT_CONSTANTS?.CUSTOM_PAGES?.toUpperCase()}
                        </h2>
                    </div>
                    {isCreateTextPage && (
                        <button
                            className="btn border-btn"
                            onClick={() => this.handleIsAddCustomText()}>
                            {" "}
                            <i className="btn-icon-left icon-arrow-left1" />
                            <span className="button-text">{BUTTON_CONSTANTS?.BACK}</span>
                        </button>
                    )}
                    {isFaqPage && (
                        <button className="btn border-btn" onClick={this.faqPage}>
                            {" "}
                            <i className="btn-icon-left icon-arrow-left1" />
                            <span className="button-text">{BUTTON_CONSTANTS?.BACK}</span>
                        </button>
                    )}
                    {isCreateForm && (
                        <button className="btn border-btn" onClick={this.CreateForm}>
                            {" "}
                            <i className="btn-icon-left icon-arrow-left1" />
                            <span className="button-text">{BUTTON_CONSTANTS?.BACK}</span>
                        </button>
                    )}
                </div>

                <div className="vr-content-holder custom-page-container">
                    {!isCreateTextPage && !isFaqPage && !isCreateForm && (
                        <>
                            <div className="vr-cont-head cp-first-row">
                                <div className="vr-cont-title">
                                    <h2 className="inner-count-title">
                                        {TEXT_CONSTANTS?.CREATE_NEW_PAGES}
                                    </h2>
                                    <p>{TEXT_CONSTANTS?.SELECT_PAGE_TYPE}</p>
                                </div>
                                <AuthWrapper
                                    permissions={[
                                        PERMISSIONS?.ENGAGE_UX_MANAGER_CUSTOM_PAGES_EDIT
                                    ]}>
                                    <div className="vr-cont-action">
                                        <button
                                            className="btn custom-page-btn"
                                            onClick={() => this.handleIsAddCustomText()}>
                                            <i className="icon-plus"></i>
                                            <p>
                                                <span>{TEXT_CONSTANTS?.TEXT_PAGE}</span>
                                                <span>{TEXT_CONSTANTS?.ABOUT_US_PRIVACY}</span>
                                            </p>
                                        </button>
                                        <button
                                            className="btn custom-page-btn"
                                            onClick={this.faqPage}>
                                            <i className="icon-plus"></i>
                                            <p>
                                                <span>{TEXT_CONSTANTS?.FAQ}</span>
                                                <span>
                                                    {TEXT_CONSTANTS?.QUESTION} &{" "}
                                                    {TEXT_CONSTANTS?.ANSWER}
                                                </span>
                                            </p>
                                        </button>
                                        {/*<button*/}
                                        {/*    className="btn custom-page-btn"*/}
                                        {/*    onClick={this.CreateForm}*/}
                                        {/*>*/}
                                        {/*    <i className="icon-plus"></i>*/}
                                        {/*    <p>*/}
                                        {/*        <span>{TEXT_CONSTANTS?.FORM}</span>*/}
                                        {/*        <span>{TEXT_CONSTANTS?.CONTACT_FEEDBACK}</span>*/}
                                        {/*    </p>*/}
                                        {/*</button>*/}
                                    </div>
                                </AuthWrapper>
                            </div>
                            <div className="vr-cont-head">
                                <div className="vr-cont-title">
                                    <h2 className="inner-count-title">
                                        {TEXT_CONSTANTS?.PAGES?.toUpperCase()}
                                    </h2>
                                </div>
                            </div>
                            <table className="table user-role-table">
                                <thead>
                                    <tr>{tableHeading}</tr>
                                </thead>
                                <tbody>{tableRows}</tbody>
                            </table>
                        </>
                    )}
                    {isCreateTextPage && (
                        <CreateTextPage
                            handleClose={this.handleIsAddCustomText}
                            editableData={editableData}
                            isEdit={isEdit}
                            customPages={customText}
                        />
                    )}
                    {isFaqPage && (
                        <FaqPage
                            handleClose={this.faqPage}
                            editableData={editableData}
                            isEdit={isEdit}
                            customPages={customText}
                        />
                    )}
                    {/*{isCreateForm && (*/}
                    {/*    <CreateForm*/}
                    {/*        handleClose={this.CreateForm}*/}
                    {/*        editableData={editableData}*/}
                    {/*        isEdit={isEdit}*/}
                    {/*    />*/}
                    {/*)}*/}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        customText: get(state.customPage, "customText", []),
        customTextCount: get(state.customPage, "customText.count", [])
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                fetchAlltextpage,
                filterTextpage,
                softDeleteTextpage
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomPages)
