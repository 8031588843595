import React, { useEffect, useState } from "react"
import Pagination from "../../../common/Pagination"
import { PaginationOptions, TRANSCODING_TAB, UPLOADED_CONTENT_HEADER } from "../../api/constant"
import Button from "../../../common/Button"
import { BUTTON_CONSTANTS, ERROR_MESSAGES, TEXT_CONSTANTS } from "../../../../utils/constants"
import Loader from "../../../../assets/images/loader.gif"
import deleteIcon from "../../../../assets/images/delete.svg"
import EmptyContent from "../EmptyContent/index"
import CustomModal from "../../../common/Modal"
import Checkbox from "../../../common/Checkbox"
import { useHistory } from "react-router-dom"

import "./style.scss"
import RightModal from "../../../common/RightModal"
import Transcoding from "../../AddContentPipeline/Transcoding"
import { toast } from "react-toastify"

export default function UploadedContent({
    isLoading,
    uploadedContentData,
    paginationData,
    filterOptions,
    fetchData,
    updateFilterOptions,
    updatePaginationData,
    deleteSelectedHandler,
    startTranscoding
}) {
    const [selectedCheckBoxId, setSelectedCheckBoxId] = useState([])
    const [isDeleteModal, setIsDeleteModal] = useState({
        isDeleteModalOpen: false,
        deletedObj: {}
    })
    const [isRightModalOpen, setRightModalOpen] = useState(false)

    const history = useHistory()

    useEffect(() => {
        fetchData(filterOptions, TRANSCODING_TAB?.UPLOADED_TAB)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onPageChange = (e) => {
        const filterOptionsCopy = { ...filterOptions }
        const selectedPage = e.selected
        filterOptionsCopy.start = selectedPage + 1
        let startValue = (filterOptionsCopy["start"] - 1) * filterOptionsCopy["length"]

        let paginationDataCopy = { ...paginationData }
        paginationDataCopy.currentPage = selectedPage
        paginationDataCopy.start = startValue
        updatePaginationData(paginationDataCopy)

        filterOptionsCopy.start = startValue
        fetchData(filterOptionsCopy, TRANSCODING_TAB?.UPLOADED_TAB)
        updateFilterOptions(filterOptionsCopy)
        setSelectedCheckBoxId([])
    }
    const showPerPageChange = (name, value) => {
        const filterOptionsCopy = { ...filterOptions }
        filterOptionsCopy["length"] = value
        filterOptionsCopy["start"] = 0

        let defaultOption = PaginationOptions.filter((item) => {
            if (value === item.id) {
                return item
            }
            return false
        })

        let paginationDataCopy = { ...paginationData }
        paginationDataCopy.selectedOption = defaultOption
        paginationDataCopy.start = 0
        paginationDataCopy.pageCount = 0
        paginationDataCopy.currentPage = 0
        updatePaginationData(paginationDataCopy)

        filterOptionsCopy.length = defaultOption[0]?.id
        updateFilterOptions(filterOptionsCopy)
        fetchData(filterOptionsCopy, TRANSCODING_TAB?.UPLOADED_TAB)
        setSelectedCheckBoxId([])
    }

    const checkboxChangeHandler = (checked, isSelectAll, id) => {
        if (isSelectAll) {
            checked
                ? setSelectedCheckBoxId(uploadedContentData?.map((item) => item?.id))
                : setSelectedCheckBoxId([])
        } else {
            if (checked) {
                setSelectedCheckBoxId((prevState) => {
                    return [...prevState, id]
                })
            } else {
                // remove the id from the checkbox list
                const selectedCheckBoxIdCpy = selectedCheckBoxId.filter((item) => item !== id)
                setSelectedCheckBoxId(selectedCheckBoxIdCpy)
            }
        }
    }

    const showDeleteModal = (val) => {
        setIsDeleteModal({
            isDeleteModalOpen: true,
            deletedObj: val
        })
    }

    const sortBy = (item) => {
        let sortBy = item
        let filterOptionsCopy = { ...filterOptions }
        filterOptionsCopy["sortBy"] = sortBy
        filterOptionsCopy["sortOrder"] = filterOptions["sortOrder"] === "desc" ? "asc" : "desc"
        fetchData(filterOptionsCopy, TRANSCODING_TAB?.UPLOADED_TAB)
        updateFilterOptions(filterOptionsCopy)
    }

    const renderTableData = () => {
        const renderTableHeader = (item) => {
            if (item?.isCheckbox) {
                return (
                    <Checkbox
                        cName="drm-checkbox uploaded-content-checkbox-header"
                        id={"upload-content-select-all"}
                        isChecked={selectedCheckBoxId?.length === uploadedContentData?.length}
                        name={"upload-content-select-all"}
                        changeHandler={(name, checked, id) => checkboxChangeHandler(checked, true)}
                    />
                )
            } else {
                return (
                    <p>
                        <span>{item.header}</span>{" "}
                        {item?.isSortEnabled && (
                            <span onClick={() => sortBy(item?.value)} className="triangle"></span>
                        )}
                    </p>
                )
            }
        }

        const tableHeading = UPLOADED_CONTENT_HEADER.map((item, index) => {
            return <th key={index}>{renderTableHeader(item)}</th>
        })

        const tableRows = uploadedContentData.map((row) => {
            return (
                <tr id={row?.id} key={row?.id} className="">
                    <td className="uploaded-content-checkbox">
                        <Checkbox
                            cName="drm-checkbox"
                            id={row?.id}
                            isChecked={selectedCheckBoxId?.includes(row?.id)}
                            name={row?.id}
                            changeHandler={(name, checked, id) =>
                                checkboxChangeHandler(checked, false, row?.id)
                            }
                        />
                    </td>
                    <td>
                        {" "}
                        <div className="tooltip-container">
                            <p className="tooltip-container-show hide-show">{row?.fileName}</p>
                            <div className="tooltip-container-innerText">
                                <p className="transcoding-play-url">
                                    {row?.fileName ? row?.fileName : "-"}
                                </p>
                            </div>
                        </div>
                    </td>

                    <td>{row?.uploadedDate}</td>
                    <td>{row?.uploadedBy ? row?.uploadedBy : "-"}</td>
                    <td>{row?.totalSize}</td>
                    <td className="btn-popupContainer">
                        <img
                            src={deleteIcon}
                            alt="deleteIcon-icon"
                            onClick={() => {
                                showDeleteModal(row)
                            }}
                        />
                    </td>
                </tr>
            )
        })

        return (
            <table className={`table`}>
                <thead>
                    <tr>{tableHeading}</tr>
                </thead>
                <tbody>{tableRows}</tbody>
            </table>
        )
    }

    const deleteSelectedId = () => {
        // if deleted remove the id from the checkbox list
        const selectedCheckBoxIdCpy = selectedCheckBoxId?.filter(
            (item) => item !== isDeleteModal?.deletedObj?.id
        )
        setSelectedCheckBoxId(selectedCheckBoxIdCpy)
    }

    const submitDeleteModalHandler = () => {
        deleteSelectedHandler(
            isDeleteModal?.deletedObj,
            TRANSCODING_TAB?.UPLOADED_TAB,
            deleteSelectedId
        )
        setSelectedCheckBoxId([])
        setIsDeleteModal({
            isDeleteModalOpen: false,
            deletedObj: {}
        })
    }

    const transcodingCallback = (response) => {
        if (response && response.data && response?.status) {
            // setRightModalOpen(false);
        } else {
            toast.error(response?.message)
        }
    }

    const rightModalSubmitHandler = (params) => {
        setRightModalOpen(false)
        startTranscoding(params, transcodingCallback)
    }

    const proceedToTranscodeHandler = () => {
        setRightModalOpen(true)
    }

    const displayLoader = () => {
        return (
            <div className="loader">
                <img src={Loader} alt="loader" width={"100px"} />
            </div>
        )
    }

    const addNewButtonHandler = () => {
        history.push("/add-video-content")
    }

    return (
        <div className="vr-upload-content-wrapper">
            {isLoading && displayLoader()}
            <div className="tenant-list-container vr-content-holder">
                {!isLoading ? (
                    uploadedContentData?.length > 0 ? (
                        renderTableData()
                    ) : (
                        <EmptyContent
                            errorMsg={ERROR_MESSAGES?.UPLOADED_CONTENT_ERROR}
                            addNewButtonHandler={addNewButtonHandler}
                        ></EmptyContent>
                    )
                ) : (
                    <></>
                )}
            </div>

            <Pagination
                changeHandler={showPerPageChange}
                options={PaginationOptions}
                pageCount={paginationData?.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={onPageChange}
                selectedOption={paginationData?.selectedOption}
                currentPage={paginationData?.currentPage}
            />
            <RenderSelectedContentInfo
                count={selectedCheckBoxId?.length}
                proceedToTranscodeHandler={proceedToTranscodeHandler}
            ></RenderSelectedContentInfo>

            <CustomModal
                showModal={isDeleteModal?.isDeleteModalOpen}
                submitHandler={submitDeleteModalHandler}
                cancelHandler={() => {
                    setIsDeleteModal({
                        isDeleteModalOpen: false
                    })
                }}
                closeOnSubmit={false}
                submitButtonLabel={BUTTON_CONSTANTS?.YES}
                showCancelButton={true}
                cancelOnOutsideClick={false}
                showCloseIcon={true}
                submitBtnClass={"confirmation-submit-btn"}
                cancelBtnClass={"confirmation-cancel-btn"}
            >
                <div className="delete-modal-container">
                    <h3>
                        {`${TEXT_CONSTANTS?.DELETE_TRANSCODED_CONTENT}: `}
                        <b>{isDeleteModal?.deletedObj?.fileName}</b>
                    </h3>
                </div>
            </CustomModal>

            <RightModal
                showModal={isRightModalOpen}
                submitHandler={rightModalSubmitHandler}
                cancelHandler={() => {
                    setRightModalOpen(false)
                }}
                closeOnSubmit={false}
                cancelOnOutsideClick={false}
                showCloseIcon={true}
                showSubmitButton={false}
                submitBtnClass={"confirmation-submit-btn"}
            >
                <Transcoding
                    rightModalSubmitHandler={rightModalSubmitHandler}
                    selectedContentId={selectedCheckBoxId}
                />
            </RightModal>
        </div>
    )
}

const RenderSelectedContentInfo = ({ count, proceedToTranscodeHandler }) => {
    return (
        <div className="selected-content-info-wrapper">
            <div className="content-info-container">
                <div className="count-wrapper">
                    <h3 className="selected-txt">{count}</h3>
                </div>
                <div className="content-info-msg">
                    <p className="selected-txt">{TEXT_CONSTANTS?.CONTENTS_SELECTED}</p>
                    <p>{TEXT_CONSTANTS?.TRANSCODING_MSG}</p>
                </div>
            </div>
            <div className="content-info-button">
                <Button
                    bValue={BUTTON_CONSTANTS?.PROCEED_TO_TRANSCODE}
                    clickHandler={proceedToTranscodeHandler}
                    cName="btn primary-btn"
                    disabled={!count}
                />
            </div>
        </div>
    )
}
