import React from "react"
import Checkbox from "../../../common/Checkbox"
import "./style.scss"

function VideoSelector(props) {
    const {
        label,
        options,
        required,
        changeHandler,
        isChecked,
        name,
        disabled
    } = props
    // const handleChange = ()=>{

    // }
    return (
        <div className="video-format-container-web">
            {label && (
                <label className="labelText" htmlFor={label}>
                    {label}
                    <span
                        className="required"
                        style={{
                            display: required ? "inline" : "none",
                            marginLeft: "5px"
                        }}>
                        *
                    </span>
                </label>
            )}
            <div className="checkbox-container">
                <ul className="checkbox-list">
                    {options &&
                        options.map((item, index) => {
                            return (
                                <li>
                                    <Checkbox
                                        cName="checklist"
                                        labelText={item.name}
                                        id={item.id}
                                        name={item.name}
                                        changeHandler={(
                                            optionName,
                                            checked,
                                            id
                                        ) => {
                                            changeHandler(name, checked, id)
                                        }}
                                        isChecked={
                                            isChecked(item.id, name) && true
                                        }
                                        disabled={disabled}
                                    />
                                </li>
                            )
                        })}
                </ul>
            </div>
        </div>
    )
}

export default VideoSelector
