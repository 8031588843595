import React, { useCallback, useState } from "react"
import { CHECK_MARK, TEXT_CONSTANTS } from "../../../utils/constants"
import Checkbox from "../../common/Checkbox"
import DisplayCard from "../DisplayCard"
import { CONTENT_TAB_TYPES, FORM_DATA_CONSTANTS, SORT_LIST } from "../API/constants"

import "./DisplayContentList.scss"
import Input from "../../common/Input"
import { debounce } from "../../../utils/common"
import Accordion from "../../common/Accordion"
import useInfiniteScroll from "../useInfiniteScroll"
import RenderWebSeriesContent from "../RenderWebSeriesContent"
import sortIcon from "../../../assets/images/sort.svg"
import { DEFAULT_SELECTED_LANGUAGE_CODE } from "../../ContentNav/api/constants"
import { useEffect } from "react"

export default function DisplayContentList({
    moviesDataList,
    moviesCheckBoxHandler,
    selectedMoviesId,
    inputSearchHandler,
    sortRecordsHandler,
    showUnplishedRecordHandler,
    fetchNextBatchData,
    contentTabType,
    webseriesDataList,
    selectedWebSeriesId,
    webSeriesCheckboxHandler,
    plansList,
    totalContentCount,
    contentEntityId,
    contentContainerWidthRef,
    contentPayload,
    searchValue,
    setSearchValueHandler,
    unplishedCheckbox
}) {
    const [selectedSortFilter, setSelectedSortFilter] = useState({
        id: 1,
        name: ""
    })

    const isMoviesTab = contentTabType === CONTENT_TAB_TYPES?.MOVIES

    // Clear out values on tab change
    useEffect(() => {
        setSelectedSortFilter({ id: 1, name: "" })
    }, [contentTabType])

    const setSearchQuery = useCallback(
        debounce((value) => {
            inputSearchHandler(value)
        }, 400),
        [contentTabType, contentEntityId, contentPayload]
    )
    const searchChangeHandler = (name, value) => {
        setSearchValueHandler(value)
        setSearchQuery(value)
    }

    const unpublishedContentHandler = (name, checked, id) => {
        showUnplishedRecordHandler(name, checked, id)
    }

    const changeSelectedSortHandler = (item) => {
        setSelectedSortFilter(item)
        sortRecordsHandler(item)
    }

    const accordionItems = [
        {
            title: (
                <div className="sorting-filter-header">
                    <img src={sortIcon} alt="sorting-icon"></img>
                    <p>{TEXT_CONSTANTS?.SORT}</p>
                </div>
            ),
            content: (
                <div className="sorting-filter-content-list">
                    {SORT_LIST?.map((item) => {
                        const selected = item?.id === selectedSortFilter?.id
                        return (
                            <div className="single-list-item" key={item?.id}>
                                <p className="check-sign">{selected && CHECK_MARK}</p>
                                <p
                                    className={`filter-name ${selected ? "selected-color" : ""}`}
                                    type="button"
                                    onClick={() => changeSelectedSortHandler(item)}
                                >
                                    {item?.title}
                                </p>
                            </div>
                        )
                    })}
                </div>
            )
        }
    ]

    const renderAdditionalFilters = () => {
        const isUnpublishedAllowed = isMoviesTab ? moviesDataList : webseriesDataList
        const isSelectAllAllowed = isMoviesTab
            ? moviesDataList?.length > 0
            : webseriesDataList?.length > 0
        return (
            <div className="additional-filter-container">
                {isUnpublishedAllowed && (
                    <div className="selectAllCheckBox">
                        <Checkbox
                            cName="check-box"
                            id={"unpublishedContent"}
                            name={"unpublishedContent"}
                            labelText={FORM_DATA_CONSTANTS?.unpublishedContent}
                            isChecked={unplishedCheckbox}
                            changeHandler={unpublishedContentHandler}
                        />
                    </div>
                )}
                {isSelectAllAllowed && (
                    <div className="selectAll-sorting-container">
                        <div>
                            {isMoviesTab && (
                                <Checkbox
                                    cName="check-box"
                                    id={FORM_DATA_CONSTANTS?.selectAllCheckbox}
                                    name={"selectAll"}
                                    labelText={"Select All"}
                                    isChecked={
                                        selectedMoviesId?.length === moviesDataList?.length
                                            ? true
                                            : false
                                    }
                                    changeHandler={moviesCheckBoxHandler}
                                />
                            )}
                        </div>
                        <Accordion
                            items={accordionItems}
                            accordionContainerClass="sorting-filter-container"
                            showSideArrows={false}
                        />
                    </div>
                )}
            </div>
        )
    }

    const renderMoviesHeader = () => {
        return (
            <div className="movies-header-container" ref={contentContainerWidthRef}>
                <p className="search-result-text">
                    {TEXT_CONSTANTS?.SEARCH_RESULTS} ({totalContentCount || 0})
                </p>
                {renderAdditionalFilters()}
                <div className="search-box-container">
                    <div className="searchBar">
                        <div className="icon-search"></div>
                        <Input
                            id="search"
                            type="text"
                            cName={"movie-search-bar"}
                            placeholder="Search Here"
                            changeHandler={searchChangeHandler}
                            value={searchValue}
                        />
                    </div>
                </div>
            </div>
        )
    }

    const displayContentData = () => {
        return isMoviesTab ? (
            <RenderMoviesContentGridView
                moviesDataList={moviesDataList}
                selectedMoviesId={selectedMoviesId}
                moviesCheckBoxHandler={moviesCheckBoxHandler}
                fetchNextBatchData={fetchNextBatchData}
                plansList={plansList}
            ></RenderMoviesContentGridView>
        ) : (
            <RenderWebSeriesContent
                webseriesDataList={webseriesDataList}
                moviesCheckBoxHandler={moviesCheckBoxHandler}
                selectedWebSeriesId={selectedWebSeriesId}
                webSeriesCheckboxHandler={webSeriesCheckboxHandler}
                fetchNextBatchData={fetchNextBatchData}
                plansList={plansList}
            ></RenderWebSeriesContent>
        )
    }

    const isCountGreater = () => {
        return isMoviesTab ? moviesDataList?.length > 0 : webseriesDataList?.length > 0
    }

    return (
        <div className="vr-render-movies-container">
            {renderMoviesHeader()}
            {isCountGreater() ? (
                displayContentData()
            ) : (
                <EmptyContainerMessage
                    moviesDataList={moviesDataList}
                    isMoviesTab={isMoviesTab}
                    webseriesDataList={webseriesDataList}
                ></EmptyContainerMessage>
            )}
        </div>
    )
}

const EmptyContainerMessage = ({ moviesDataList, isMoviesTab, webseriesDataList }) => {
    let title
    if (isMoviesTab) {
        title =
            moviesDataList?.length === 0
                ? TEXT_CONSTANTS?.NO_RECORDS_FOUND
                : TEXT_CONSTANTS?.EMPTY_CONTAINER_MESSAGE
    } else {
        title =
            webseriesDataList?.length === 0
                ? TEXT_CONSTANTS?.NO_RECORDS_FOUND
                : TEXT_CONSTANTS?.EMPTY_CONTAINER_MESSAGE
    }

    return (
        <div className="vr-display-empty-container-msg">
            <h3>{title}</h3>
        </div>
    )
}

const RenderMoviesContentGridView = ({
    moviesDataList,
    selectedMoviesId,
    moviesCheckBoxHandler,
    fetchNextBatchData,
    plansList
}) => {
    const [, setIsFetching] = useInfiniteScroll(fetchMoreListItems, "content-grid-view-container")

    function fetchMoreListItems() {
        setTimeout(() => {
            // Make API call
            fetchNextBatchData()
            setIsFetching(false)
        }, 700)
    }

    return (
        <>
            <div className="movies-grid-view-container" id="content-grid-view-container">
                {moviesDataList?.map((item) => {
                    const { movie_title, poster_image, subscription_plan } = item?.content

                    return (
                        <DisplayCard
                            key={item?.id}
                            id={item?.id}
                            thumbnail={poster_image?.values?.[DEFAULT_SELECTED_LANGUAGE_CODE]}
                            selectedMoviesId={selectedMoviesId}
                            moviesCheckBoxHandler={moviesCheckBoxHandler}
                            title={movie_title?.values?.[DEFAULT_SELECTED_LANGUAGE_CODE]}
                            plans={subscription_plan}
                            isUnpublished={!item?.active}
                            plansList={plansList}
                        ></DisplayCard>
                    )
                })}
            </div>
            {/* {isFetching && <h2>Fetching more results</h2>} */}
        </>
    )
}
