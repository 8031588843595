import React, { Component } from "react"

import Select from "../../../common/SelectBox"
import Input from "../../../common/Input"
import Button from "../../../common/Button"

import "./style.scss"
import { BUTTON_CONSTANTS, TEXT_CONSTANTS } from "../../../../utils/constants"
import { isEmpty } from "lodash"
import { toast } from "react-toastify"

const optionForApply = [
    {
        id: "All",
        name: "Full Rail"
    },
    {
        id: "custom",
        name: "Custom Range"
    }
]
class CustomFilter extends Component {
    constructor(props) {
        super(props)
        this.node = {}
        this.state = {
            showItem: "",
            noOfItems: "All",
            frequency: "",
            autoShuffle: false,
            isCustomSelected: false,
            offset: "",
            limit: ""
        }
    }

    componentDidMount() {
        this.loadHandler()
        const { autoShuffle, selectedMovieListLength } = this.props
        if (isEmpty(autoShuffle)) {
            return
        }
        if (autoShuffle.offset === 0 && autoShuffle.limit === selectedMovieListLength) {
            this.setState({
                isCustomSelected: false,
                noOfItems: "All"
            })
        } else if (autoShuffle.offset || autoShuffle.limit) {
            this.setState({
                isCustomSelected: true,
                noOfItems: "custom"
            })
        }
    }

    loadHandler = () => {
        if (!isEmpty(this.props.autoShuffle)) {
            const { frequency, autoShuffle, offset, limit } = this.props.autoShuffle
            this.setState({
                frequency,
                autoShuffle,
                offset,
                limit
            })
        }
    }

    findDefaultOption = (item, list) => {
        let defaultOption = list.find((ele) => {
            if (ele.id === item) {
                return ele
            }
            return false
        })
        return defaultOption
    }

    handleRadioBtn = (e) => {
        this.setState({ autoShuffle: JSON.parse(e.target.value) })
    }

    handleSelectChange = (name, value) => {
        this.setState({
            [name]: value
        })
        if (value === "custom") {
            this.setState({
                isCustomSelected: true,
                offset: "",
                limit: ""
            })
        } else if (value === "All") {
            this.setState({
                isCustomSelected: false
            })
        }
    }

    validateAutoShuffleValues = (autoShuffleObj) => {
        const offset = Number(autoShuffleObj?.offset)
        const limit = Number(autoShuffleObj?.limit)
        if (
            isEmpty(autoShuffleObj?.offset) ||
            isNaN(offset) ||
            offset < 0 ||
            offset > this.props.selectedMovieListLength
        ) {
            toast.error("Please enter valid offset value")
            return false
        } else if (
            isEmpty(autoShuffleObj?.limit) ||
            isNaN(limit) ||
            limit < 0 ||
            limit > this.props.selectedMovieListLength ||
            limit === offset
        ) {
            toast.error("Please enter valid limit value")
            return false
        }
        return true
    }

    handleApplyCLick = () => {
        let { autoShuffle, offset, limit, noOfItems, isCustomSelected } = this.state
        if (!autoShuffle) {
            offset = ""
            limit = ""
        }
        if (autoShuffle && noOfItems === "All") {
            offset = "0"
            limit = this.props.selectedMovieListLength
        }
        let autoShuffleObj = {
            autoShuffle,
            offset,
            limit
        }
        if (!autoShuffle || !isCustomSelected || this.validateAutoShuffleValues(autoShuffleObj)) {
            this.props.handleAutoUpdateFilter(autoShuffleObj)
        }
    }

    checkIsButtonDisabled = () => {
        let { offset, limit, isCustomSelected, autoShuffle } = this.state
        if (autoShuffle && isCustomSelected && (isEmpty(offset) || isEmpty(limit))) {
            return true
        }
        return false
    }

    handleCloseIcon = () => {
        const { frequency, autoShuffle, offset, limit } = this.props.autoShuffle
        this.setState({
            frequency,
            autoShuffle,
            offset,
            limit
        })
        this.props.toggleIsClick()
    }
    render() {
        const { noOfItems, autoShuffle } = this.state
        const isApplyDisabled = this.checkIsButtonDisabled()
        return (
            <div className="auto-shuffle-dropdown">
                <label
                    htmlFor="userFilter"
                    className="main-label"
                    onClick={this.props.toggleIsClick}>
                    <span className="filter-title">
                        {TEXT_CONSTANTS?.AUTO_SHUFFLE}({" "}
                        {autoShuffle ? <b>{TEXT_CONSTANTS?.ON}</b> : "OFF"} )
                    </span>
                    <i className="up-down-arrow">
                        <svg viewBox="0 0 20.5 13.5" width="20" height="13">
                            <path
                                fill="none"
                                fillRule="evenodd"
                                stroke="#5B539A"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="3"
                                d="M5.063 2.102L9.942 7.45l3.904-5.348"
                            />
                        </svg>
                    </i>
                </label>
                <div
                    id="userFilter"
                    className={`menu ${this.props.isClick ? "showMenu" : "hideMenu"}`}>
                    <ul className="dropdown-items">
                        <li className="list-option headingList">
                            <span>{TEXT_CONSTANTS?.AUTO_UPDATE}</span>
                            <i className="icon-close" onClick={this.handleCloseIcon}></i>
                        </li>
                        <li className="list-option">
                            <div class="checkbox">
                                <input
                                    type="radio"
                                    name="radio"
                                    value={false}
                                    onChange={this.handleRadioBtn}
                                    checked={!autoShuffle}
                                />
                                <label></label>
                                <span class="checkbox-text">{TEXT_CONSTANTS?.OFF}</span>
                            </div>
                        </li>
                        <li className="list-option">
                            <div class="checkbox">
                                <input
                                    type="radio"
                                    name="radio"
                                    value={true}
                                    onChange={this.handleRadioBtn}
                                    checked={autoShuffle}
                                />
                                <label></label>
                                <span class="checkbox-text">
                                    {TEXT_CONSTANTS?.AUTO_SHUFFLE?.replace("-", "")?.toUpperCase()}
                                </span>
                            </div>
                        </li>
                        {autoShuffle ? (
                            <li className="list-option">
                                <Select
                                    options={optionForApply}
                                    cName={"applyFilter"}
                                    name="noOfItems"
                                    value={this.findDefaultOption(noOfItems, optionForApply)}
                                    label={"Apply On"}
                                    changeHandler={this.handleSelectChange}
                                />
                            </li>
                        ) : (
                            ""
                        )}
                        {this.state.isCustomSelected && autoShuffle ? (
                            <React.Fragment>
                                <li className="list-option">
                                    <Input
                                        id="offset"
                                        name="offset"
                                        type={"number"}
                                        cName={"offset"}
                                        placeholder="Enter offset"
                                        labelText={"offset"}
                                        changeHandler={this.handleSelectChange}
                                        value={this.state.offset}
                                    />
                                </li>
                                <li className="list-option">
                                    <Input
                                        id="limit"
                                        name="limit"
                                        type={"number"}
                                        cName={"limit"}
                                        placeholder="Enter limit"
                                        labelText={"limit"}
                                        changeHandler={this.handleSelectChange}
                                        value={this.state.limit}
                                    />
                                </li>
                            </React.Fragment>
                        ) : (
                            ""
                        )}

                        <li className="list-option">
                            <Button
                                cName={`btn ${isApplyDisabled ? "" : " secondary-btn"}`}
                                bValue={BUTTON_CONSTANTS?.APPLY}
                                clickHandler={this.handleApplyCLick}
                                disabled={isApplyDisabled}
                            />
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default CustomFilter
