import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import LanguageCardList from "../../common/LanguageCardList"
import "../style.scss"
import FaqForm from "./faqForm"
import { isMultiLingual } from "../../../utils/common"
import { TEXT_CONSTANTS } from "../../../utils/constants"

class FaqPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedLanguage: {
                name: "English",
                code: "en"
            }
        }
    }

    onClickListener = (value) => {
        this.setState({
            selectedLanguage: value
        })
    }
    render() {
        const { handleClose } = this.props
        const { selectedLanguage } = this.state

        return (
            <div className="vd-addCustomPages faqContainer">
                <header>
                    <div>
                        <i className="icon-arrow-left1" onClick={handleClose} />
                        <span>{TEXT_CONSTANTS?.FAQ}</span>
                    </div>
                    {isMultiLingual() && (
                        <div className="language-container">
                            <LanguageCardList handleChange={this.onClickListener} />
                        </div>
                    )}
                </header>

                <FaqForm
                    selectedLanguage={selectedLanguage}
                    handleClose={handleClose}
                    {...this.props}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({}, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FaqPage)
