import React, { Component } from "react"

import EditableMenuDesign from "./EditableMenuDesign"
import PlatformTabHeader from "../platformTab"
import LanguageListing from "../../../components/common/LanguageCardList"
import "./style.scss"
import { isMultiLingual } from "../../../utils/common"

class AddEditMenu extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedLanguage: {
                name: "English",
                code: "en"
            }
        }
    }

    onClickListener = (value) => {
        this.setState({
            selectedLanguage: value
        })
    }

    render() {
        const { platFormList, currentPlatform, handlePlatformChange, handleEditClick, isEdit } =
            this.props
        const { selectedLanguage } = this.state
        return (
            <div>
                <div className="vr-cont-head">
                    <div className="language-sub-container">
                        <PlatformTabHeader
                            currentPlatform={currentPlatform}
                            platFormList={platFormList}
                            handlePlatformChange={handlePlatformChange}
                            isEdit={isEdit}
                        />
                        {isMultiLingual() && (
                            <LanguageListing handleChange={this.onClickListener} />
                        )}
                    </div>
                </div>
                <div className="menuDesignList">
                    <EditableMenuDesign
                        platFormList={platFormList}
                        currentPlatform={currentPlatform}
                        selectedLanguage={selectedLanguage}
                        handleEditClick={handleEditClick}
                    />
                </div>
            </div>
        )
    }
}

export default AddEditMenu
