import React, { Component } from "react"
import { isEmpty } from "lodash"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import Button from "../../../common/Button"
import CustomRadioButton from "../../../common/customRadioBtn"
import { BUTTON_CONSTANTS, TEXT_CONSTANTS } from "../../../../utils/constants"
import {
    DRM_VALUES,
    HLS_DASH_RENDITION,
    TRANSCODING_CONTENT_TYPE_OPTIONS,
    TRANSCODING_PROTOCOL_VALUES,
    profileResolutionaArray
} from "./constants"

import "./style.scss"
import Checkbox from "../../../common/Checkbox"
import { Dropzone } from "../../../common/Dropzone"
import { saveCustomProfile } from "../../api/action"
import alertIcon from "../.././../../assets/images/alert.svg"
import { getIsDrmConfig } from "../../../DrmConfiguration/APIs/action"
import { toast } from "react-toastify"
import Input from "../../../common/Input"

class Transcoding extends Component {
    constructor(props) {
        super(props)
        this.state = {
            files: [],
            activeDots: { 1: "grey-marker" },
            isCustomProfile: TRANSCODING_PROTOCOL_VALUES[0]?.id,
            togglePopup: false,
            profileFields: [],
            isDrmEnabledGlobal: true,
            isDRMEnabled: DRM_VALUES[1]?.id,
            payload: {},
            hlsRendition: HLS_DASH_RENDITION,
            dashRendition: HLS_DASH_RENDITION,
            isSubmitDisabled: true,
            hls_scketeMarker: true,
            hls_markerFrequency: 900,
            hls_adDuration: -1,
            dash_scketeMarker: false,
            dash_markerFrequency: 900,
            dash_adDuration: -1,
            contentType: TRANSCODING_CONTENT_TYPE_OPTIONS[0]?.id,
            HLS: true
        }
    }

    componentDidMount = () => {
        this.loadHandler()
    }

    loadHandler = async () => {
        const { getIsDrmConfig } = this.props
        let isDrmResponse = await getIsDrmConfig()
        let { isDrmEnabledGlobal } = this.state
        if (isDrmResponse) {
            isDrmEnabledGlobal =
                isDrmResponse?.data?.isDRMEnabled &&
                isDrmResponse?.data?.isDRMConfigured &&
                isDrmResponse?.data?.isStackCreated
            this.setState({
                isDRMEnabled: isDrmEnabledGlobal ? DRM_VALUES[1]?.id : DRM_VALUES[1]?.id,
                isDrmEnabledGlobal
            })
        }
    }

    handleChange = (name, value) => {
        this.setState({ [name]: value })
    }

    handleDashHlsChange = (name, value) => {
        // let renditionType = name === "HLS" ? "hlsRendition" : "dashRendition";
        this.setState({
            [name]: value
            // [renditionType]: value ? this.state[renditionType] : [],
        })
    }

    renditions = (name, value, id, cName) => {
        let renditionType = cName === "HLS" ? "hlsRendition" : "dashRendition"
        let renditionArray = this.state[renditionType]

        if (value) {
            renditionArray.push(String(name))
        } else {
            renditionArray = renditionArray.filter(
                (filterValue) => filterValue?.toString() !== name?.toString()
            )
        }
        this.setState({
            [renditionType]: renditionArray
        })
    }

    getProfileContainer = (id) => {
        const renditionType = id === "HLS" ? "hlsRendition" : "dashRendition"
        return profileResolutionaArray.map((item, index) => {
            return (
                <Checkbox
                    cName={id}
                    labelText={item}
                    id={index}
                    name={item}
                    changeHandler={this.renditions}
                    // disabled={true}
                    isChecked={this.state?.[renditionType]?.includes(item)}
                />
            )
        })
    }
    togglePopup = () => {
        this.setState({
            togglePopup: !this.state.togglePopup
        })
    }

    validateScketeMarker = (name) => {
        const scketeMarkerName = `${name}_scketeMarker`,
            markerFrequencyName = `${name}_markerFrequency`,
            adDurationName = `${name}_adDuration`
        if (this.state?.[scketeMarkerName]) {
            if (this.state?.[markerFrequencyName] === 0 || this.state?.[adDurationName] === 0) {
                return true
            }
        }
        return false
    }

    validate = () => {
        const { isCustomProfile, customProfileFilePath, hlsRendition, dashRendition, HLS, DASH } =
            this.state

        if (isCustomProfile === TRANSCODING_PROTOCOL_VALUES[0]?.id) {
            if (HLS) {
                if (isEmpty(hlsRendition)) {
                    toast.error("Please select atleast one profile resolution")
                    return false
                } else {
                    if (this.validateScketeMarker("hls")) {
                        toast.error("Please fill required fields!")
                        return false
                    }
                    return true
                }
                // return isEmpty(hlsRendition) || this.validateScketeMarker('hls') ? false : true;
            }
            if (DASH) {
                if (isEmpty(dashRendition)) {
                    toast.error("Please select atleast one profile resolution")
                    return false
                } else {
                    if (this.validateScketeMarker("dash")) {
                        toast.error("Please fill required fields!")
                        return false
                    }
                    return true
                }

                // return isEmpty(dashRendition) || this.validateScketeMarker('dash') ? false : true;
            }
            toast.error("Please select atleast one transcoding protocol")
            return false
        } else {
            if (isEmpty(customProfileFilePath)) {
                toast.error("Please upload custom profile!")
                return false
            }
            return true
            // return isEmpty(customProfileFilePath) ? false : true;
        }
        // return false;
    }

    setPayload = () => {
        const {
            isDRMEnabled,
            isCustomProfile,
            customProfileFilePath,
            hlsRendition,
            dashRendition,
            HLS,
            DASH,
            hls_markerFrequency,
            hls_adDuration,
            hls_scketeMarker,
            dash_markerFrequency,
            dash_adDuration,
            contentType
        } = this.state

        let payload = { settings: [] }
        let settingObj = {}

        if (HLS) {
            settingObj = {
                protocol: "HLS",
                renditions: [...new Set(hlsRendition)],
                adDurationInSeconds: Number(hls_adDuration),
                adMarkerFrequencyInSeconds: Number(hls_markerFrequency),
                scteMarkersEnabled: hls_scketeMarker
            }

            payload.settings.push(settingObj)
        }
        if (DASH) {
            settingObj = {
                protocol: "DASH",
                renditions: [...new Set(dashRendition)],
                adDurationInSeconds: Number(dash_adDuration),
                adMarkerFrequencyInSeconds: Number(dash_markerFrequency),
                scteMarkersEnabled: false
            }
            payload.settings.push(settingObj)
        }

        if (isCustomProfile === TRANSCODING_PROTOCOL_VALUES[1]?.id) {
            payload.settings = []
        }
        payload.customProfileFilePath = customProfileFilePath
        payload.isCustomProfile =
            isCustomProfile === TRANSCODING_PROTOCOL_VALUES[1]?.id ? true : false
        payload.isDRMEnabled = isDRMEnabled === DRM_VALUES[0]?.id ? true : false
        payload.vodS3ObjectIds = this.props?.selectedContentId
        payload.contentType = TRANSCODING_CONTENT_TYPE_OPTIONS?.find(
            (item) => item?.id?.toString() === contentType?.toString()
        )?.value
        return payload
    }

    submit = () => {
        const { rightModalSubmitHandler } = this.props
        let payload = this.setPayload()
        let isValidated = this.validate()
        if (!isValidated) {
            // toast.error("Please fill required fields");
        } else {
            rightModalSubmitHandler(payload)
        }
    }

    saveCustomProfile = async () => {
        const { profileFields } = this.state
        const { saveCustomProfile } = this.props
        let response = await saveCustomProfile(profileFields["file"])
        if (response) {
            this.setState({
                customProfileFilePath: response
            })
        }
    }

    setFiles = (files) => {
        const { profileFields } = this.state
        let data = new FormData()
        data.append("file", files[0])
        profileFields["file"] = data
        this.setState(
            {
                profileFields
            },
            () => {
                this.saveCustomProfile()
            }
        )
    }

    getCustomProfile = () => {
        return (
            <div className="profile-modal">
                <h3> Add Custom Profile</h3>
                {/*    <div className="form-input">
          <Input
            className="inputField"
            type="text"
            changeHandler={this.handleChange}
            name="profileName"
            errMsg={null}
            maxLength={25}
            labelText="Profile Name"
            value={profileFields["profileName"]}
            required
          />
        </div> */}
                <div className="drop-container">
                    <div className="dropzone-container">
                        <Dropzone
                            isMultipleUpload={"false"}
                            setFiles={this.setFiles}
                            acceptFiles={{
                                "application/json": [".json", ".JSON"]
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }

    handleRadioChange = (e) => {
        let additionalProperty = {
            hlsRendition: HLS_DASH_RENDITION,
            dashRendition: HLS_DASH_RENDITION
        }
        if (Number(e?.target?.value) === DRM_VALUES[1]?.id) {
            additionalProperty = {
                ...additionalProperty,
                HLS: true,
                DASH: false
            }
        } else if (Number(e?.target?.value) === DRM_VALUES[0]?.id) {
            additionalProperty = {
                ...additionalProperty,
                HLS: true,
                DASH: true
            }
        } else if (e?.target?.value === TRANSCODING_CONTENT_TYPE_OPTIONS[0].id) {
            additionalProperty = {
                ...additionalProperty,
                hls_markerFrequency: 900,
                dash_markerFrequency: 900,
                hls_scketeMarker: true,
                dash_scketeMarker: false
            }
        } else if (e?.target?.value === TRANSCODING_CONTENT_TYPE_OPTIONS[1].id) {
            additionalProperty = {
                ...additionalProperty,
                hls_markerFrequency: 420,
                dash_markerFrequency: 420,
                hls_scketeMarker: true,
                dash_scketeMarker: false
            }
        } else if (e?.target?.value === TRANSCODING_CONTENT_TYPE_OPTIONS[2].id) {
            additionalProperty = {
                ...additionalProperty,
                hls_scketeMarker: false,
                dash_scketeMarker: false
            }
        }

        this.setState({
            ...additionalProperty,
            [e.target?.name]: Number(e?.target?.value)
        })
    }

    getScteMarker = (name) => {
        const radioBtnName = `${name}_scketeMarker`,
            markerFrequencyName = `${name}_markerFrequency`,
            adDurationName = `${name}_adDuration`
        return (
            <div className="scte-marker">
                <p className="scte-marker-radio-sub-heading">Ad Marker</p>
                <div className="radio-btn-container">
                    <CustomRadioButton
                        key={radioBtnName}
                        id={radioBtnName}
                        name={radioBtnName}
                        handleRadioChange={(e) =>
                            this.handleChange(radioBtnName, e?.target?.value === "1" ? true : false)
                        }
                        options={[
                            { id: 1, name: "Yes" },
                            { id: 2, name: "No" }
                        ]}
                        value={this.state?.[radioBtnName] === true ? 1 : 2}
                    />
                </div>
                {this.state?.[radioBtnName] && (
                    <>
                        <Input
                            type={"Number"}
                            labelText={"Marker Frequency (Seconds)"}
                            name={markerFrequencyName}
                            required={true}
                            value={this.state?.[markerFrequencyName]}
                            placeholder={0}
                            changeHandler={(name, value) => this.handleChange(name, value)}
                            // disabled={true}
                        />
                        <Input
                            type={"Number"}
                            labelText={"Ad Duration (Seconds)"}
                            name={adDurationName}
                            required={true}
                            value={this.state?.[adDurationName]}
                            placeholder={0}
                            changeHandler={(name, value) => this.handleChange(name, value)}
                            // disabled={true}
                        />
                        <p className="ad-duration-note">
                            <b>Note:</b> {TEXT_CONSTANTS?.AD_DURATION_MESSAGE}
                        </p>
                    </>
                )}
            </div>
        )
    }

    render() {
        const { isCustomProfile, HLS, DASH, isDrmEnabledGlobal, isDRMEnabled, contentType } =
            this.state
        const { selectedContentId } = this.props

        return (
            <>
                <div className="transcoding-main-container">
                    <div className="transcoding-container">
                        <h2>Transcoding Settings</h2>
                        <p className="count-text">
                            Contents to be transcoded:{" "}
                            <span className="bold-text">{selectedContentId.length}</span>
                        </p>
                        <p className="horizontal-line"></p>
                        {/*        <CustomModal
              showModal={togglePopup}
              children={this.getCustomProfile()}
              submitButtonLabel={BUTTON_CONSTANTS.SAVE}
              submitHandler={this.submit}
              cancelHandler={this.togglePopup}
            /> */}
                        <p className="radio-sub-heading">DRM</p>
                        <div className="radio-btn-container">
                            <CustomRadioButton
                                key={"DRM"}
                                id={"isDRMEnabled"}
                                name={"isDRMEnabled"}
                                handleRadioChange={
                                    isDrmEnabledGlobal ? this.handleRadioChange : null
                                }
                                cName={!isDrmEnabledGlobal ? "disabled" : ""}
                                options={DRM_VALUES}
                                disabled={!isDrmEnabledGlobal}
                                value={isDRMEnabled}
                            />
                        </div>
                        <p className="horizontal-line"></p>
                        <p className="radio-sub-heading">Content Type</p>
                        <div className="radio-btn-container">
                            <CustomRadioButton
                                key={"contentType"}
                                id={"contentType"}
                                name={"contentType"}
                                handleRadioChange={this.handleRadioChange}
                                // cName={!isDrmEnabledGlobal ? "disabled" : ""}
                                options={TRANSCODING_CONTENT_TYPE_OPTIONS}
                                // disabled={!isDrmEnabledGlobal}
                                value={contentType}
                            />
                        </div>
                        {!isDrmEnabledGlobal && (
                            <p className="non-drm-alert">
                                <img src={alertIcon} alt="alert-icon"></img>
                                <span className="alert-text">
                                    Your DRM configuration is incomplete. Please configure it and
                                    finalize the transcoding process with encrypted content.
                                </span>
                            </p>
                        )}
                        <p className="horizontal-line"></p>
                        <p className="radio-sub-heading">Transcoding</p>
                        <div className="radio-btn-container">
                            <CustomRadioButton
                                key={"Choose Protocol"}
                                id={"protocol"}
                                name={"isCustomProfile"}
                                handleRadioChange={this.handleRadioChange}
                                options={TRANSCODING_PROTOCOL_VALUES}
                                value={isCustomProfile}
                            />
                        </div>
                        <div className="profile-container">
                            {isCustomProfile === TRANSCODING_PROTOCOL_VALUES[0]?.id && (
                                <>
                                    <div className="sub-container">
                                        <p>
                                            <Checkbox
                                                cName="checklist"
                                                labelText={"HLS"}
                                                id={"HLS"}
                                                name={"HLS"}
                                                changeHandler={this.handleDashHlsChange}
                                                isChecked={this.state?.HLS}
                                            />
                                        </p>
                                        {HLS && (
                                            <div className="resolution-container">
                                                <p>Choose Profile Resolution</p>
                                                <div className="checkbox-container">
                                                    {this.getProfileContainer("HLS")}
                                                </div>
                                                {this.getScteMarker("hls")}
                                            </div>
                                        )}
                                    </div>
                                    <div className="sub-container">
                                        <p>
                                            <Checkbox
                                                cName="checklist"
                                                labelText={"DASH"}
                                                id={"DASH"}
                                                name={"DASH"}
                                                changeHandler={this.handleDashHlsChange}
                                                isChecked={this.state?.DASH}
                                            />
                                        </p>
                                        {DASH && (
                                            <div className="resolution-container">
                                                <p>Choose Profile Resolution</p>
                                                <div className="checkbox-container">
                                                    {this.getProfileContainer("DASH")}
                                                </div>
                                                {/* {this.getScteMarker('dash')} */}
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                            {isCustomProfile === TRANSCODING_PROTOCOL_VALUES[1]?.id && (
                                <div className="d">
                                    {this.getCustomProfile()}
                                    <p className="file-format-text">File format : JSON</p>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="footer">
                        <p className="horizontal-line"></p>
                        <Button
                            type="button"
                            clickHandler={() => this.submit()}
                            bValue={BUTTON_CONSTANTS.START_NOW}
                            cName="transcodingSubmit btn primary-btn"
                        ></Button>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                saveCustomProfile,
                getIsDrmConfig
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Transcoding)
