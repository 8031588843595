import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import get from "lodash/get"

import Chart from "./Chart"
import MostViewedContent from "./MostViewContent"
import Timeline from "./timeline"
import { VOD, userCountColor } from "./api/constant"
import { toLocaleString, parseNumber } from "../../utils/common"
import CountUp from "react-countup"

import "./style.scss"
import {
    fetchContentTypeWise,
    fetchRecentContent,
    fetchUserCount,
    fetchContentSliderData,
    fetchGenreContentTypeWise,
    getContentNames
} from "./api/action"
import {
    formatChartData,
    formatRecentContent,
    formatUserCountData,
    formatSliderContentData,
    formatRecentContentFilterData,
    formatGenreContentWiseData,
    wordFormat
} from "./helper"
import { TEXT_CONSTANTS } from "../../utils/constants"

class Dashboard extends Component {
    state = {
        chartData: [],
        recentContentData: [],
        userCountData: [],
        sliderContentData: [],
        genreContentWiseData: [],
        selectedContentType: "GENRE",
        selectedPublishFilter: "published",
        selectedRecentContentFilter: "",
        recentContentFilterData: [],
        isLoading: true,
        recentDatakey: Date.now(),
        selectedNavbarType: "",
        getContentEntityNames: [],
        genre: "genre",
        language: "language",
        selectedTab: 0
    }
    onChangeFilter = (name, value) => {
        this.initialLoadHandler()
    }

    formatNumber = (value) => {
        return toLocaleString(value)
    }

    componentDidMount() {
        this.getcontent()
        this.initialLoadHandler()
    }
    getcontent = async () => {
        const { getContentNames } = this.props
        const response = await getContentNames()
        if (response && response.status) {
            this.setState(
                {
                    getContentEntityNames: response.data.data
                },
                () => this.getContentNamewise()
            )
        }
    }

    initialLoadHandler = async () => {
        const { fetchContentTypeWise, fetchRecentContent, fetchUserCount, fetchContentSliderData } =
            this.props
        const { selectedRecentContentFilter } = this.state
        const recentFilterParam = selectedRecentContentFilter
            ? { contentType: selectedRecentContentFilter }
            : {}
        try {
            const [
                {
                    data: { data: chartData, status: status1 }
                },
                {
                    data: { data: recentContentData, status: status2 }
                },
                {
                    data: { data: userCountData, status: status3 }
                },
                {
                    data: { data: sliderContentData, status: status4 }
                }
            ] = await Promise.all([
                fetchContentTypeWise(),
                fetchRecentContent(recentFilterParam),
                fetchUserCount(),
                fetchContentSliderData()
            ])

            if (status1 && status2 && status3 && status4) {
                const formattedChartData = formatChartData(chartData)
                const formattedRecentContent = formatRecentContent(
                    get(recentContentData, "results", [])
                )
                const formattedUserCountData = formatUserCountData(userCountData)
                const formattedSliderContentData = formatSliderContentData(sliderContentData)
                const formaattedRecentContentFilterData =
                    formatRecentContentFilterData(sliderContentData)
                this.setState(
                    {
                        chartData: formattedChartData,
                        recentContentData: formattedRecentContent,
                        userCountData: formattedUserCountData,
                        sliderContentData: formattedSliderContentData,
                        recentContentFilterData: formaattedRecentContentFilterData,
                        selectedNavbarType: formattedSliderContentData[0]?.labelName?.replace(
                            "_",
                            " "
                        ),
                        isLoading: false
                    },
                    () => {
                        this.getGenreContentTypeWise()
                    }
                )
            } else {
            }
        } catch (err) {
            console.log("error", err)
        }
    }

    getContentNamewise = () => {
        const { getContentEntityNames, selectedNavbarType } = this.state
        let name = getContentEntityNames.find((elm) => elm.name === selectedNavbarType)
        if (name) {
            this.setState(
                {
                    genre: name.genreKey,
                    language: name.languageKey
                },
                () => this.getGenreContentTypeWise()
            )
        }
    }

    getGenreContentTypeWise = async () => {
        const { selectedPublishFilter, selectedContentType, selectedNavbarType, genre, language } =
            this.state

        const { fetchGenreContentTypeWise } = this.props
        const params = {
            contentType: selectedNavbarType,
            dashBoardFilterCategory: selectedContentType,
            published: selectedPublishFilter === "published",
            genre: genre,
            language: language
        }
        try {
            const {
                data: { data: genreContentWiseData, status }
            } = await fetchGenreContentTypeWise(params)
            if (status) {
                const formattedGenreContentWiseData =
                    formatGenreContentWiseData(genreContentWiseData)
                this.setState({ genreContentWiseData: formattedGenreContentWiseData })
            }
        } catch (err) {
            console.log("error", err)
        }
    }

    getRecentContent = async () => {
        const { selectedRecentContentFilter } = this.state
        const params = selectedRecentContentFilter
            ? { contentType: selectedRecentContentFilter }
            : {}
        const { fetchRecentContent } = this.props
        try {
            const {
                data: { data: recentContentData, status }
            } = await fetchRecentContent(params)
            if (status) {
                const formattedRecentContent = formatRecentContent(
                    get(recentContentData, "results", [])
                )
                this.setState(
                    {
                        recentContentData: formattedRecentContent
                    },
                    () => {
                        this.setState({ recentDatakey: Date.now() })
                    }
                )
            }
        } catch (err) {
            console.log("error", err)
        }
    }

    mostViewedFilterChangeHandler = (selectedValue, identifier) => {
        const prevSelectedValue = this.state[identifier]
        if (prevSelectedValue !== selectedValue) {
            this.setState({ [identifier]: selectedValue }, () => {
                this.getContentNamewise()
            })
        }
    }

    recentContentFilterChangeHandler = (value) => {
        const { selectedRecentContentFilter } = this.state
        if (selectedRecentContentFilter !== value) {
            this.setState({ selectedRecentContentFilter: value }, () => {
                this.getRecentContent()
            })
        }
    }
    mainLoader = () => {
        return (
            <div className="dashboardLoader">
                <div className="content-loader">
                    <div className="chartLoader">
                        <div className="pieLoader"></div>
                        <ul className="listLoader">
                            <li className="bar-loader"></li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                    <div className="mvcLoader">
                        <ul>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                </div>
                <div className="userloader">
                    <span className="userInfo-loader"></span>
                </div>
            </div>
        )
    }

    render() {
        const {
            chartData,
            recentContentData,
            userCountData,
            sliderContentData,
            genreContentWiseData,
            selectedContentType,
            recentContentFilterData,
            isLoading,
            selectedTab
        } = this.state

        let dashboardContentClass
        if (chartData.length === 1) {
            dashboardContentClass = "oneChartContent"
        } else if (chartData.length > 2) {
            dashboardContentClass = "multipleContent"
        }

        return (
            <div className="dashboard">
                <div className="dashboard-header">
                    <h2
                        onClick={() => this.setState({ selectedTab: 0 })}
                        className={selectedTab === 0 && "active"}
                    >
                        {TEXT_CONSTANTS?.ANALYTICS_DASHBOARD}
                    </h2>
                    <h2
                        onClick={() => this.setState({ selectedTab: 1 })}
                        className={selectedTab === 1 && "active"}
                    >
                        {TEXT_CONSTANTS?.CMS_DASHBOARD}
                    </h2>
                </div>
                <div className="dashboard-container">
                    {selectedTab === 1 ? (
                        <>
                            {isLoading ? (
                                this.mainLoader()
                            ) : (
                                <>
                                    <div
                                        className={
                                            dashboardContentClass
                                                ? `dashboard-content ${dashboardContentClass}`
                                                : `dashboard-content`
                                        }
                                    >
                                        <div className="chart-wrapper">
                                            {chartData.map((item, index) => (
                                                <Chart
                                                    key={index}
                                                    live_content={item.live_content}
                                                    contentTitle={wordFormat(item.contentTitle)}
                                                    contentType={item.contentType}
                                                />
                                            ))}
                                        </div>
                                        <MostViewedContent
                                            contentList={genreContentWiseData}
                                            navMenuItems={sliderContentData}
                                            VOD={VOD}
                                            selectedContentType={selectedContentType}
                                            filterChangeHandler={this.mostViewedFilterChangeHandler}
                                        />
                                    </div>
                                    <div className="user-info">
                                        <div className="groupImage" />
                                        <ul className="detail">
                                            {userCountData.map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <span>{item.title}</span>
                                                        {parseNumber(item.count) ? (
                                                            <CountUp
                                                                style={{
                                                                    color: userCountColor[index]
                                                                }}
                                                                end={parseNumber(item.count)}
                                                                duration={2}
                                                                formattingFn={this.formatNumber}
                                                            />
                                                        ) : (
                                                            <span
                                                                style={{
                                                                    color: userCountColor[index]
                                                                }}
                                                            >
                                                                0
                                                            </span>
                                                        )}
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </>
                            )}
                            <Timeline
                                recentDatakey={this.state.recentDatakey}
                                isLoading={isLoading}
                                recentContentData={recentContentData}
                                filterList={recentContentFilterData}
                                recentContentFilterChangeHandler={
                                    this.recentContentFilterChangeHandler
                                }
                            />
                        </>
                    ) : (
                        <div className={"iframe-container"}>
                            <iframe
                                src="https://suite.npaw.com/"
                                frameborder="0"
                                title="npaw iframe"
                            ></iframe>
                            <a
                                href={"https://suite.npaw.com/"}
                                target={"_blank"}
                                className="iframe-overlay"
                                rel="noopener noreferrer"
                            >
                                {" "}
                            </a>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                fetchContentTypeWise,
                fetchRecentContent,
                fetchUserCount,
                fetchContentSliderData,
                fetchGenreContentTypeWise,
                getContentNames
            },
            dispatch
        )
    }
}

export default connect(null, mapDispatchToProps)(Dashboard)
