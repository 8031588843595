import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import get from "lodash/get"
import Input from "../common/Input/index"
import Button from "../common/Button/index"
import EditorComponent from "../common/Editor"
import { createTextPage, fetchContentTextPage, updateCustomPageById } from "./APIs/action"
import LanguageCardList from "../common/LanguageCardList"
import { getLanguageObject } from "../common/LanguageCardList"

import { isEmpty } from "lodash"
import "./style.scss"
import { checkAllLanguageHasValue, isMultiLingual } from "../../utils/common"
import { DEFAULT_SELECTED_LANGUAGE_CODE } from "../ContentNav/api/constants"
import LanguageDataConfirmationModal from "../common/LanguageCardList/languageDataConfirmationModal"
import { BUTTON_CONSTANTS, PERMISSIONS, TEXT_CONSTANTS } from "../../utils/constants"
import { checkIfUserHasAccess } from "../../utils/users"
import Select from "../common/SelectBox"
import { TEXT_TYPE_CONST } from "./APIs/constants"

class CreateTextPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: !isMultiLingual() ? "" : {},
            content: !isMultiLingual() ? "" : {},
            formattedContent: !isMultiLingual() ? "" : {},
            isDisable: true,
            selectedLanguage: {
                name: "English",
                code: "en"
            },
            err: {
                nameErr: "",
                contentErr: ""
            },
            emptyLangDataModal: false,
            textTypes: ""
        }
        this.canUpdateStatus = checkIfUserHasAccess([
            PERMISSIONS?.ENGAGE_UX_MANAGER_CUSTOM_PAGES_EDIT
        ])
    }

    componentDidMount() {
        this.updateLanguageObject()
        this.loadHandler()
    }

    updateLanguageObject = async () => {
        this.languageObject = await getLanguageObject()
    }

    loadHandler = async () => {
        const { isEdit, editableData, fetchContentTextPage } = this.props
        if (isEdit) {
            const resp = await fetchContentTextPage(editableData.id)
            if (resp && resp.status) {
                const find = resp.data.textTypes || "OTHERS"
                this.setState(
                    {
                        name: resp.data.name,
                        formattedContent: resp.data.formattedContent,
                        isDisable: false,
                        textTypes: TEXT_TYPE_CONST.find((item) => item.id === find)
                    },
                    () => {
                        this.checkForEmpty()
                    }
                )
            }
        }
    }

    handleChange = (name, value) => {
        if (isMultiLingual()) {
            const { selectedLanguage } = this.state
            var updatedMultilingualValue = this.state[name]
            updatedMultilingualValue[selectedLanguage.code] = value
        }
        this.setState({
            [name]: !isMultiLingual() ? value : updatedMultilingualValue,
            isDisable: false
        })
    }
    checkForEmpty = () => {
        const { name, content } = this.state
        let count = 3

        if (isMultiLingual()) {
            if (name["en"]?.trim().length) {
                count = count - 1
            }
            if (content["en"]?.trim().length) {
                count = count - 1
            }
        } else {
            if (name.trim().length) {
                count = count - 1
            }
            if (content.trim().length) {
                count = count - 1
            }
        }
        if (!count) {
            this.setState({
                isDisable: false
            })
        }
    }

    checkEmptyDescription = (content) => {
        return (
            content?.replaceAll("<p>", "")?.replaceAll("</p>", "")?.replaceAll("\n", "")?.length ===
            0
        )
    }

    validate = (name, content) => {
        let flag = 1
        const err = {}
        if (isMultiLingual()) {
            const selectedCode = DEFAULT_SELECTED_LANGUAGE_CODE //this.state.selectedLanguage?.code
            if (
                isEmpty(content[selectedCode]) ||
                !content[selectedCode]?.trim() ||
                this.checkEmptyDescription(content[selectedCode])
            ) {
                err[selectedCode] = { descriptionErr: "Description cannot be empty" }
                flag = 0
            }
            if (isEmpty(name[selectedCode]) || !name[selectedCode]?.trim()) {
                err[selectedCode] = { nameErr: "Name cannot be empty" }
                flag = 0
            }
            if (!flag) {
                toast.error("Please enter data in english language")
            }
        } else {
            if (isEmpty(content)) {
                err.descriptionErr = "Description cannot be empty"
                flag = 0
            }
            if (name.length === 0) {
                err.roleErr = "Name cannot be empty"
                flag = 0
            }
        }
        this.setState({ err: { ...err } })
        return flag
    }

    handleCustomTextPage = async () => {
        const { createTextPage, handleClose, isEdit, editableData, customPages } = this.props
        let content = ""
        if (isEdit && !isEmpty(this.state.formattedContent) && isEmpty(this.state.content)) {
            let value = this.state.formattedContent
            value = value.split("<pre>").join("")
            value = value.split("</pre>").join("")
            content = value
        } else {
            content = this.state.content
        }

        const { type = "TEXT", name, formattedContent, textTypes } = this.state
        this.setState({
            isDisable: true
        })
        let payload = {
            position: customPages?.length ? customPages.length + 1 : 0,
            pageTypes: type,
            name,
            content,
            formattedContent,
            textTypes: textTypes.id === "OTHERS" ? null : textTypes.id
        }
        if (isEdit) {
            payload.id = editableData.id
        }
        let validated = this.validate(name, content)
        if (!validated) {
            return null
        }
        this.setState({
            err: {
                nameErr: "",
                contentErr: ""
            }
        })

        const response = await createTextPage(payload)
        if (get(response, "status")) {
            toast.success(get(response, "message"))
            this.resetForm()
            handleClose(true)
        } else {
            toast.error(get(response, "message"))
        }
    }

    modalCancelHandler = () => {
        this.setState({
            emptyLangDataModal: false
        })
    }

    submitData = async (payload, handleClose, createTextPage) => {
        const { updateCustomPageById } = this.props
        this.modalCancelHandler()
        const response = payload.id
            ? await updateCustomPageById(payload)
            : await createTextPage(payload)
        if (get(response, "status")) {
            toast.success(get(response, "message"))
            this.resetForm()
            handleClose(true)
        } else {
            toast.error(get(response, "message"))
        }
    }

    handleMultilingualCustomTextPage = async () => {
        const { createTextPage, handleClose, isEdit, editableData, customPages } = this.props
        let content = {}
        if (isEdit && !isEmpty(this.state.formattedContent) && isEmpty(this.state.content)) {
            this.state.formattedContent &&
                Object.keys(this.state.formattedContent).forEach((language, index) => {
                    let value = this.state.formattedContent[language]
                    value = value.split("<pre>").join("")
                    value = value.split("</pre>").join("")
                    content[language] = value
                })
        } else {
            this.state.formattedContent &&
                Object.keys(this.state.formattedContent).forEach((language, index) => {
                    let value = this.state.formattedContent[language]
                    value =
                        value && value.split("<pre>") && value.split("<pre>").length > 1
                            ? value.split("<pre>").join("")
                            : value
                    value =
                        value && value.split("</pre>") && value.split("<pre>").length > 1
                            ? value.split("</pre>").join("")
                            : value
                    content[language] = value
                })
        }

        const { type = "TEXT", name, formattedContent, textTypes } = this.state
        this.setState({
            isDisable: true
        })
        let payload = {
            position: customPages?.length ? customPages.length + 1 : 0,
            pageTypes: type,
            name,
            content,
            formattedContent,
            textTypes: textTypes.id === "OTHERS" ? null : textTypes.id
        }
        if (isEdit) {
            payload.id = editableData.id
        }
        let validated = this.validate(name, content, true)
        if (!validated) {
            return null
        }
        this.setState({
            err: {
                nameErr: "",
                contentErr: ""
            }
        })

        if (
            !checkAllLanguageHasValue(content, this.languageObject) ||
            !checkAllLanguageHasValue(name, this.languageObject)
        ) {
            this.setState({
                emptyLangDataModal: true,
                finalPayload: payload
            })
        } else {
            await this.submitData(payload, handleClose, createTextPage)
        }
    }
    resetForm = () => {
        this.setState({
            name: "",
            content: "",
            taggedPermissions: [],
            isReset: true
        })
    }

    getContent = (contentValue, formattedTextValue) => {
        if (isMultiLingual()) {
            const { formattedContent, content, selectedLanguage } = this.state
            let updatedFormattedText = formattedContent
            updatedFormattedText[selectedLanguage.code] = formattedTextValue
            let updatedContentText = content
            updatedContentText[selectedLanguage.code] = contentValue
            this.setState({
                content: updatedContentText,
                formattedContent: updatedFormattedText
            })
        } else
            this.setState({
                content: contentValue,
                formattedContent: formattedTextValue
            })
    }

    onClickListener = (value) => {
        this.setState({
            selectedLanguage: value
        })
    }

    handleSelect = (name, value, selectedOption) => {
        this.setState({
            [name]: selectedOption
        })
    }

    render() {
        const { handleClose, isEdit, createTextPage } = this.props

        let {
            name,
            formattedContent,
            err,
            selectedLanguage,
            emptyLangDataModal,
            finalPayload,
            textTypes
        } = this.state
        return (
            <div className="vd-addCustomPages textPageContainer">
                <div className="heading-container">
                    <header>
                        <i className="icon-arrow-left1" onClick={handleClose} />
                        <span>{TEXT_CONSTANTS?.CREATE_TEXT_PAGE}</span>
                    </header>
                    {isMultiLingual() && <LanguageCardList handleChange={this.onClickListener} />}
                </div>
                <div className="add-user-form">
                    <label className="editor-description">
                        {TEXT_CONSTANTS?.PAGE_NAME?.toUpperCase()} <span>*</span>
                    </label>

                    <Input
                        className="inputField"
                        type="text"
                        changeHandler={this.handleChange}
                        name="name"
                        id="customName"
                        value={
                            !isMultiLingual()
                                ? name
                                : name && name[selectedLanguage.code]
                                ? name[selectedLanguage.code]
                                : ""
                        }
                        errMsg={err[this.state.selectedLanguage?.code]?.nameErr}
                        maxLength={25}
                        disabled={!this.canUpdateStatus}
                    />

                    <label className="editor-description">
                        TEXT TYPE<span>*</span>
                    </label>
                    <Select
                        value={textTypes}
                        options={TEXT_TYPE_CONST}
                        cName={"form-input"}
                        name={"textTypes"}
                        required
                        placeholder={"Select"}
                        changeHandler={this.handleSelect}
                    />
                    <label className="editor-description">
                        {TEXT_CONSTANTS?.DESCRIPTION} <span>*</span>
                    </label>
                    <EditorComponent
                        className="inputField"
                        placeholder="— Enter Name —"
                        editorstate={this.props.editorstate}
                        getContent={this.getContent}
                        selectedLanguage={this.state.selectedLanguage}
                        content={
                            !isMultiLingual()
                                ? isEdit
                                    ? formattedContent
                                    : ""
                                : formattedContent[selectedLanguage.code]
                                ? formattedContent[selectedLanguage.code]
                                : " "
                        }
                        isDisabled={!this.canUpdateStatus}
                    />
                    <span className="err">
                        {err[this.state.selectedLanguage?.code]?.descriptionErr}
                    </span>

                    <div className="user-btn">
                        {this.canUpdateStatus && (
                            <Button
                                bValue={
                                    isEdit
                                        ? BUTTON_CONSTANTS?.UPDATE?.toUpperCase()
                                        : BUTTON_CONSTANTS?.SAVE?.toUpperCase()
                                }
                                disabled={this.state.name.length < 1}
                                clickHandler={
                                    !isMultiLingual()
                                        ? this.handleCustomTextPage
                                        : this.handleMultilingualCustomTextPage
                                }
                                cName="btn primary-btn"
                            />
                        )}
                        <Button
                            bValue={BUTTON_CONSTANTS?.CANCEL?.toUpperCase()}
                            clickHandler={() => handleClose()}
                            cName="btn cancel-btn"
                        />
                    </div>
                    {emptyLangDataModal && (
                        <LanguageDataConfirmationModal
                            isModalOpen={emptyLangDataModal}
                            submitHandler={() =>
                                this.submitData(finalPayload, handleClose, createTextPage)
                            }
                            cancelHandler={this.modalCancelHandler}></LanguageDataConfirmationModal>
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        customText: get(state.customPage, "customText", [])
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                createTextPage,
                fetchContentTextPage,
                updateCustomPageById
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTextPage)
