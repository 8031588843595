import React, { useRef, useState } from "react"

import { BUTTON_CONSTANTS, ERROR_MESSAGES, TEXT_CONSTANTS } from "../../../utils/constants"
import UploadedContent from "./UploadedContent/index.js"
import TranscodedContent from "./TranscodedContent/index.js"
import PlusIcon from "../../../assets/images/plusIcon.svg"
import FilterIcon from "../../../assets/images/FilterIcon.svg"
import { debounce, scrollPage } from "../../../utils/common"
import Button from "../../common/Button"
import Input from "../../common/Input"
import { useCallback } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import {
    cancelTranscoding,
    deleteTranscodedContent,
    deleteUploadedContent,
    getStatusFilterData,
    getTranscodedContentList,
    getUploadedContentList,
    startTranscoding
} from "../api/action"
import { getIsDrmConfig } from "../../DrmConfiguration/APIs/action"
import {
    CHECKBOX_FILTER,
    FILTER_INITIAL_DATA,
    PAGINATION_INITIAL_DATA,
    TRANSCODING_TAB
} from "../api/constant"
import Calendar from "react-calendar"
import { toast } from "react-toastify"
import { useEffect } from "react"
import "react-calendar/dist/Calendar.css"
import "./style.scss"
import Checkbox from "../../common/Checkbox"
import { isEmpty } from "lodash"
import NotFound from "../../common/PageNotFound"
import useOutsideClickAlert from "../../../utils/useOutsideClickAlert"

const Tab = ({ label, isActive, onClick }) => {
    return (
        <div onClick={onClick} className={isActive ? "tab currently-active-tab" : "tab"}>
            {label}
        </div>
    )
}

const MultipleTab = ({
    tabs,
    className,
    searchChangeHandler,
    searchValue,
    handleTabClick,
    activeTab,
    filterOptions,
    updateFilterOptions,
    fetchData,
    filterData,
    updatePaginationData,
    paginationData
}) => {
    const initialData = {
        filters: { ...filterOptions?.filters },
        rangeFilter: {
            startDate: formatDate(filterOptions?.rangeFilter?.startDate) || "",
            endDate: formatDate(filterOptions?.rangeFilter?.endDate) || ""
        }
    }

    const [openFilter, setOpenFilter] = useState(false)
    const [addFilterData, setAddFilterData] = useState(initialData)

    const openFilterRef = useRef(null)

    useOutsideClickAlert(openFilterRef, () => {
        setOpenFilter(false)
    })

    function formatDate(dateString) {
        if (dateString) {
            let [day, month, year] = dateString?.split("-")
            const dateObj = new Date(`${month}/${day}/${year}`)
            return dateObj
        }
        return null
    }

    const getCalenderDetail = (detail) => {
        let date = new Date(detail).getDate()
        let month = new Date(detail).getMonth() + 1
        let year = new Date(detail).getFullYear()

        if (month < 10) {
            month = `0${month}`
        }
        if (date < 10) {
            date = `0${date}`
        }

        return { date, month, year }
    }

    const onChange = (date) => {
        let startDate = getCalenderDetail(date[0])
        let endDate = getCalenderDetail(date[1])
        setAddFilterData((prevState) => {
            return {
                ...prevState,
                rangeFilter: {
                    startDate: date[0],
                    endDate: date[1],
                    inputStartDate: `${startDate.date}-${startDate.month}-${startDate.year}`,
                    inputEndDate: `${endDate.date}-${endDate.month}-${endDate.year}`
                }
            }
        })
        // let err = this.state.err;
        // err.startDateErr = "";
        // err.endDateErr = "";
        // this.setState(
        //   {
        //     startDate: date[0],
        //     endDate: date[1],
        //     inputStartDate: `${startDate.date}/${startDate.month}/${startDate.year}`,
        //     inputEndDate: `${endDate.date}/${endDate.month}/${endDate.year}`,
        //     err,
        //   },
        // () => {
        //   if (!this.isValidateAll()) {
        //     this.setState({
        //       disable: true,
        //     });
        //   } else {
        //     this.setState({
        //       disable: false,
        //     });
        //   }
        // }
        // );
    }

    const checkboxChangeHandler = (name, checked, label) => {
        let val = addFilterData?.filters?.[label] || []
        if (checked) {
            setAddFilterData((prevState) => {
                return {
                    ...prevState,
                    filters: {
                        ...prevState?.filters,
                        [label]: [...val, name]
                    }
                }
            })
        } else {
            val = val?.filter((item) => item !== name)
            setAddFilterData((prevState) => {
                return {
                    ...prevState,
                    filters: {
                        ...prevState?.filters,
                        [label]: [...val]
                    }
                }
            })
        }
    }

    const applyBtnHandler = () => {
        setOpenFilter(!openFilter)
        if (checkHasValidData(addFilterData)) {
            let filterOptionsCopy = { ...filterOptions }
            filterOptionsCopy.start = 0
            filterOptionsCopy.filters = addFilterData?.filters
            if (addFilterData?.rangeFilter?.inputStartDate) {
                filterOptionsCopy.rangeFilter = {
                    startDate: addFilterData?.rangeFilter?.inputStartDate,
                    endDate: addFilterData?.rangeFilter?.inputEndDate
                }
            }
            const updatedPaginationData = {
                ...paginationData,
                pageCount: 1,
                currentPage: 0,
                start: 0
            }
            updatePaginationData(updatedPaginationData)
            updateFilterOptions(filterOptionsCopy)
            fetchData(filterOptionsCopy, activeTab)
        }
    }

    const clearSelectionHandler = () => {
        setOpenFilter(!openFilter)
        let filterOptionsCopy = { ...filterOptions }
        filterOptionsCopy.filters = {}
        filterOptionsCopy.rangeFilter = {}
        updateFilterOptions(filterOptionsCopy)
        fetchData(filterOptionsCopy, activeTab)
    }

    const checkHasValidData = (filterObj) => {
        if (isEmpty(filterObj?.rangeFilter) || filterObj?.rangeFilter?.startDate === "") {
            const filter = filterObj?.filters
            if (isEmpty(filter)) {
                return false
            } else {
                let hasData = false
                for (let key in filter) {
                    if (filter[key]?.length > 0) {
                        hasData = true
                    }
                }
                return hasData
            }
        }
        return true
    }

    return (
        <>
            <div className={`multiple-tabs-container-wrapper`}>
                <div className={`multiple-tabs-container ${className}`}>
                    {tabs.map((tab, index) => (
                        <Tab
                            key={index}
                            label={tab.label}
                            isActive={activeTab === index}
                            onClick={() => handleTabClick(index)}
                        />
                    ))}
                </div>
                <div className="filters">
                    <div className="search-box-container">
                        <div className="searchBar">
                            <div className="icon-search"></div>
                            <Input
                                id="search"
                                type="text"
                                cName={"movie-search-bar"}
                                placeholder="Search Here"
                                changeHandler={searchChangeHandler}
                                value={searchValue}
                            />
                        </div>
                    </div>
                    <div className="filters-box" ref={openFilterRef}>
                        <Button
                            bValue={
                                <p className="txt">
                                    <img src={FilterIcon} alt="Plus"></img>{" "}
                                    {checkHasValidData(filterOptions) && <span class="dot"></span>}
                                    {BUTTON_CONSTANTS?.FILTERS}
                                </p>
                            }
                            clickHandler={() => {
                                setAddFilterData(initialData)
                                setOpenFilter(!openFilter)
                            }}
                            cName="btn filters-cta"
                        />
                        {openFilter && (
                            <div className="filters-option-container">
                                <div className="filters-option">
                                    {/* Left Section */}
                                    {activeTab === TRANSCODING_TAB.Transcoded_TAB && (
                                        <div className="left-section">
                                            {filterData?.map((filter) => {
                                                return (
                                                    <div className="filter-wrapper">
                                                        <p className="filter-wrapper-heading">
                                                            {filter?.filterHeading?.labelText}
                                                        </p>
                                                        <div className="filter-wrapper-listing">
                                                            {filter?.filterData?.map((item) => {
                                                                return (
                                                                    <Checkbox
                                                                        cName="drm-checkbox"
                                                                        id={item?.id}
                                                                        isChecked={addFilterData?.filters?.[
                                                                            filter?.filterHeading
                                                                                ?.name
                                                                        ]?.includes(item?.name)}
                                                                        name={item?.name}
                                                                        changeHandler={(
                                                                            name,
                                                                            checked,
                                                                            id
                                                                        ) =>
                                                                            checkboxChangeHandler(
                                                                                name,
                                                                                checked,
                                                                                filter
                                                                                    ?.filterHeading
                                                                                    ?.name
                                                                            )
                                                                        }
                                                                        labelText={item?.labelText}
                                                                    />
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )}
                                    {/* Right Calendar */}
                                    <div className="calender-container">
                                        <p className="calender-head">
                                            {TEXT_CONSTANTS?.DATE_RANGE}
                                        </p>
                                        <Calendar
                                            onChange={onChange}
                                            value={[
                                                addFilterData?.rangeFilter?.startDate,
                                                addFilterData?.rangeFilter?.endDate
                                            ]}
                                            // minDate={new Date()}
                                            selectRange={true}
                                        />
                                    </div>
                                </div>
                                {/* Btn container */}
                                <div className="btn-container">
                                    <Button
                                        bValue={BUTTON_CONSTANTS?.APPLY}
                                        clickHandler={applyBtnHandler}
                                        cName="btn primary-btn "
                                    />
                                    <Button
                                        bValue={BUTTON_CONSTANTS?.CLEAR_ALL}
                                        clickHandler={clearSelectionHandler}
                                        cName="btn clear-all-btn"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div>{tabs[activeTab].component}</div>
        </>
    )
}

export default function VideoPipeline() {
    const [searchValue, setSearchValue] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [transcodedContentData, setTranscodedContentData] = useState([])
    const [uploadedContentData, setUploadedContentData] = useState([])
    const [paginationData, setPaginationData] = useState(PAGINATION_INITIAL_DATA)
    const [filterOptions, setFilterOptions] = useState(FILTER_INITIAL_DATA)
    const [activeTab, setActiveTab] = useState(0)
    const [filterData, setFilterData] = useState({})
    const [drmStatus, setDrmStatus] = useState(undefined)
    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        /* eslint-disable */
        fetchData(filterOptions, TRANSCODING_TAB?.UPLOADED_TAB, true)
        fetchStatusFilter()
        if (history.location?.state?.params === 1) {
            setActiveTab(1)
            fetchData(filterOptions, TRANSCODING_TAB?.Transcoded_TAB, true)
            window.history.replaceState({}, document.title)
        }
        fetchDrmStatus()
    }, [])

    useEffect(() => {
        scrollPage()
    }, [transcodedContentData, uploadedContentData])

    const fetchDrmStatus = async () => {
        let isDrmResponse = await dispatch(getIsDrmConfig())
        if (isDrmResponse && isDrmResponse?.data) {
            setDrmStatus(String(isDrmResponse?.data?.isStackCreated))
        }
    }
    const updateFilterOptions = (updatedFilterOptions) => {
        setFilterOptions(updatedFilterOptions)
    }

    const updatePaginationData = (updatedPaginationData) => {
        setPaginationData(updatedPaginationData)
    }

    const pageCountSetup = (userCount, payloadObj) => {
        const { length } = payloadObj
        let pageCount = Math.ceil(userCount / length)
        setPaginationData((prevState) => {
            return {
                ...prevState,
                pageCount: pageCount
            }
        })
    }

    const startTranscodingApi = async (params, callback) => {
        setIsLoading(true)
        await dispatch(startTranscoding(params, callback))
        setActiveTab(0)
        setIsLoading(false)
    }

    const convertPayloadObj = (payloadObj) => {
        const modifiedPayload = JSON.parse(JSON.stringify(payloadObj))
        let startDate = payloadObj?.rangeFilter?.startDate
        let endDate = payloadObj?.rangeFilter?.endDate
        // modifying Payload according API
        if (startDate && endDate) {
            startDate = startDate.split("-")
            endDate = endDate.split("-")
            modifiedPayload.rangeFilter = {
                date: {
                    greaterThan: new Date(
                        +startDate[2],
                        startDate[1] - 1,
                        +startDate[0]
                    )?.getTime(),
                    lessThan: new Date(+endDate[2], endDate[1] - 1, +endDate[0])?.getTime()
                }
            }
        }
        return modifiedPayload
    }

    const fetchData = async (payloadObj, currentTab, intialDataFetching = false) => {
        const isTranscodingTab = currentTab === TRANSCODING_TAB?.Transcoded_TAB
        setIsLoading(true)
        // Structure changed for sending date payload
        const modifiedPayload = convertPayloadObj(payloadObj)
        let response
        if (isTranscodingTab) {
            response = await dispatch(getTranscodedContentList(modifiedPayload))
        } else {
            response = await dispatch(getUploadedContentList(modifiedPayload))
        }
        setIsLoading(false)
        if (response?.status) {
            isTranscodingTab ? setTranscodedContentData(response) : setUploadedContentData(response)
        } else {
            isTranscodingTab ? setTranscodedContentData([]) : setUploadedContentData([])
        }
        !intialDataFetching && pageCountSetup(response?.count, payloadObj)
    }

    const fetchStatusFilter = async () => {
        setIsLoading(true)
        let response = await dispatch(getStatusFilterData())
        setIsLoading(false)
        let filterData = [...CHECKBOX_FILTER]
        if (response) {
            let val = 1,
                resData = []
            for (let key in response) {
                let tempObj = {
                    id: `status-${val}`,
                    name: response[key],
                    labelText: key
                }
                resData.push(tempObj)
                val++
            }
            filterData.unshift({
                filterHeading: { name: "status", labelText: "Status" },
                filterData: resData
            })
        }
        setFilterData(filterData)
    }

    const handleTabClick = (index) => {
        setActiveTab(index)
        resetSearchValue()
        setFilterOptions(FILTER_INITIAL_DATA)
        setPaginationData(PAGINATION_INITIAL_DATA)
    }

    const deleteSelectedHandler = async (item, currentTab, callback) => {
        let response
        if (currentTab === TRANSCODING_TAB?.UPLOADED_TAB) {
            response = await dispatch(deleteUploadedContent(item?.id))
        } else {
            response = await dispatch(deleteTranscodedContent(item?.jobId))
        }
        if (response?.status) {
            toast.success(response?.message)
            fetchData(filterOptions, currentTab)
            callback && callback()
        } else {
            toast.error(response?.message || ERROR_MESSAGES?.SOMETHING_WENT_WRONG)
        }
    }

    const cancelSelectedTranscodingHandler = async (item, currentTab) => {
        let response = await dispatch(cancelTranscoding([item?.jobId]))
        if (response?.status) {
            toast.success(response?.message)
            fetchData(filterOptions, currentTab)
        } else {
            toast.error(response?.message || ERROR_MESSAGES?.SOMETHING_WENT_WRONG)
        }
    }

    const tabs = [
        {
            label: `${TEXT_CONSTANTS?.TRANSCODED_CONTENT}(${transcodedContentData?.count || 0})`,
            component: (
                <TranscodedContent
                    isLoading={isLoading}
                    transcodedContentData={transcodedContentData?.data}
                    paginationData={paginationData}
                    fetchData={fetchData}
                    updateFilterOptions={updateFilterOptions}
                    updatePaginationData={updatePaginationData}
                    filterOptions={filterOptions}
                    deleteSelectedHandler={deleteSelectedHandler}
                    cancelSelectedTranscodingHandler={cancelSelectedTranscodingHandler}
                ></TranscodedContent>
            )
        },
        {
            label: `${TEXT_CONSTANTS?.UPLOADED_CONTENT} (${uploadedContentData?.count || 0})`,
            component: (
                <UploadedContent
                    isLoading={isLoading}
                    uploadedContentData={uploadedContentData?.data}
                    paginationData={paginationData}
                    fetchData={fetchData}
                    startTranscoding={startTranscodingApi}
                    updateFilterOptions={updateFilterOptions}
                    updatePaginationData={updatePaginationData}
                    filterOptions={filterOptions}
                    deleteSelectedHandler={deleteSelectedHandler}
                ></UploadedContent>
            )
        }
    ]

    const handleSearch = async (value) => {
        let payload = { ...filterOptions }
        payload.searchTag = value?.trim()
        payload.start = 0
        const updatedPaginationData = {
            ...paginationData,
            pageCount: 1,
            currentPage: 0,
            start: 0
        }
        setPaginationData(updatedPaginationData)
        updateFilterOptions(payload)
        if (activeTab === TRANSCODING_TAB?.UPLOADED_TAB) {
            fetchData(payload, TRANSCODING_TAB?.UPLOADED_TAB)
        } else {
            fetchData(payload, TRANSCODING_TAB?.Transcoded_TAB)
        }
    }

    const setSearchQuery = useCallback(
        debounce((value) => {
            handleSearch(value)
        }, 400),
        [activeTab, filterOptions]
    )

    const searchChangeHandler = (name, value) => {
        setSearchValue(value)
        setSearchQuery(value)
    }

    const resetSearchValue = () => {
        setSearchValue("")
    }

    const addNewHandler = () => {
        history.push("/add-video-content")
    }

    return (
        <div className="vr-video-pipeline-listing">
            <div className="vr-cont-head mb">
                {/* TODO: Replace below with the reusable pageTitleComponent */}
                <div className="vr-cont-title">
                    <h2 className="breadcrum-header">
                        {TEXT_CONSTANTS?.VIDEO_PIPELINE?.toUpperCase()}
                    </h2>
                    <span className="vr-breadcrum">{TEXT_CONSTANTS?.VIDEO_PIPELINE_MSG}</span>
                </div>
                <Button
                    bValue={
                        <p>
                            <img src={PlusIcon} alt="Plus" width={"12px"}></img>{" "}
                            {BUTTON_CONSTANTS?.ADD_NEW}
                        </p>
                    }
                    clickHandler={addNewHandler}
                    cName="btn primary-btn add-new-cta"
                    disabled={!(drmStatus === "true")}
                />
            </div>
            {drmStatus === "true" ? (
                <MultipleTab
                    tabs={tabs}
                    className={""}
                    searchChangeHandler={searchChangeHandler}
                    searchValue={searchValue}
                    resetSearchValue={resetSearchValue}
                    handleTabClick={handleTabClick}
                    activeTab={activeTab}
                    filterOptions={filterOptions}
                    fetchData={fetchData}
                    updateFilterOptions={updateFilterOptions}
                    filterData={filterData}
                    updatePaginationData={updatePaginationData}
                    paginationData={paginationData}
                ></MultipleTab>
            ) : (
                ""
            )}
            {drmStatus === "false" && !isLoading && (
                <NotFound heading={TEXT_CONSTANTS?.DRM_UNDER_PROCESS} />
            )}
        </div>
    )
}
