import React from "react"
import Input from "../../../common/Input"
import Button from "../../../common/Button"
import Checkbox from "../../../common/Checkbox"
import { TEXT_CONSTANTS } from "../../../../utils/constants"

export default function SideMenuFields(props) {
    const [placeholder, setPlaceHolder] = React.useState("true")

    const handleChange = (e, key) => {
        const { selectedElement, selectedIndex, setElementArray, isChild } = props
        let newArray = [...props.elementsArr]
        if (selectedElement.isGroupElement) {
            newArray.map((i) => {
                if (i.isGroup) {
                    if (
                        i.elementsArr[selectedIndex] &&
                        i.elementsArr[selectedIndex].id === selectedElement.id
                    ) {
                        i.elementsArr[selectedIndex][key] = e.target.value
                    }
                }
                return i
            })
        } else if (
            isChild !== "" &&
            isChild !== undefined &&
            isChild !== "undefined" &&
            newArray[selectedIndex]
        ) {
            newArray[selectedIndex].children[isChild][key] = e.target.value
        } else {
            if (newArray[selectedIndex]) {
                newArray[selectedIndex][key] = e.target.value
            }
        }
        setElementArray(newArray)
    }
    const handleRadio = (e) => {
        setPlaceHolder(e.target.value)
        const { selectedElement, selectedIndex, setElementArray, isChild } = props
        if (e.target.value === "false") {
            const newArray = [...props.elementsArr]
            if (selectedElement.isGroupElement) {
                newArray.map((i) => {
                    if (i.isGroup) {
                        if (
                            i.elementsArr[selectedIndex] &&
                            i.elementsArr[selectedIndex].id === selectedElement.id
                        ) {
                            i.elementsArr[selectedIndex].htmlPlaceholder = "None"
                        }
                    }
                    return i
                })
            } else if (isChild !== "" && isChild !== undefined && isChild !== "undefined") {
                newArray[selectedIndex].children[isChild].htmlPlaceholder = e.target.value
            } else {
                newArray[selectedIndex].htmlPlaceholder = "None"
            }
            setElementArray(newArray)
        }
    }
    const selectSize = (size) => {
        const { selectedElement, selectedIndex, setElementArray, isChild } = props
        const newArray = [...props.elementsArr]
        if (selectedElement.isGroupElement) {
            newArray.map((i) => {
                if (i && i.isGroup) {
                    if (
                        i.elementsArr[selectedIndex] &&
                        i.elementsArr[selectedIndex].id === selectedElement.id
                    ) {
                        i.elementsArr[selectedIndex].size = size
                    }
                }
                return i
            })
        } else if (
            isChild !== "" &&
            isChild !== undefined &&
            isChild !== "undefined" &&
            newArray[selectedIndex]
        ) {
            newArray[selectedIndex].children[isChild].size = size
        } else {
            if (newArray[selectedIndex]) {
                newArray[selectedIndex].size = size
            }
        }
        setElementArray(newArray)
    }
    const CheckMandatory = (e, checked) => {
        const { selectedElement, selectedIndex, setElementArray, isChild } = props
        const newArray = [...props.elementsArr]
        if (selectedElement.isGroupElement) {
            newArray.map((i) => {
                if (i.isGroup) {
                    if (
                        i.elementsArr[selectedIndex] &&
                        i.elementsArr[selectedIndex].id === selectedElement.id
                    ) {
                        i.elementsArr[selectedIndex].required = checked
                    }
                }
                return i
            })
        } else if (isChild !== "" && isChild !== undefined && isChild !== "undefined") {
            newArray[selectedIndex].children[isChild].required = checked
        } else {
            newArray[selectedIndex].required = checked
        }
        setElementArray(newArray)
    }

    const getPlaceholder = (item) => {
        switch (item.htmlElement) {
            case "file":
            case "input":
                return (
                    <React.Fragment>
                        <div className={"editor-list-section"}>
                            <span>{TEXT_CONSTANTS?.TEXT_TO_LOAD}</span>
                            <div className="radio-btns">
                                <div className="sideMenu-checkbox">
                                    <label className="container-checkBox">
                                        {TEXT_CONSTANTS?.NONE}
                                        <input
                                            type="radio"
                                            name="radio"
                                            value={false}
                                            onChange={handleRadio}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                <div className="sideMenu-checkbox">
                                    <label className="container-checkBox">
                                        {TEXT_CONSTANTS?.PLACEHOLDER}
                                        <input
                                            type="radio"
                                            name="radio"
                                            value={true}
                                            onChange={handleRadio}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        {placeholder === "true" ? (
                            <div className={"editor-list-section nn"}>
                                <Input
                                    type="text"
                                    labelText={"Placeholder Text"}
                                    placeholder={"Add answer here"}
                                    value={props.selectedElement.htmlPlaceholder}
                                    onChange={(e) => handleChange(e, "htmlPlaceholder")}
                                />
                            </div>
                        ) : null}
                    </React.Fragment>
                )
            case "dateAndTime":
                return (
                    <React.Fragment>
                        <div className={"editor-list-section"}>
                            <span>{TEXT_CONSTANTS?.TEXT_TO_LOAD}</span>
                            <div className="radio-btns">
                                <div className="sideMenu-checkbox">
                                    <label className="container-checkBox">
                                        {TEXT_CONSTANTS?.NONE}
                                        <input
                                            type="radio"
                                            name="radio"
                                            value={false}
                                            onChange={handleRadio}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                <div className="sideMenu-checkbox">
                                    <label className="container-checkBox">
                                        {TEXT_CONSTANTS?.PLACEHOLDER}
                                        <input
                                            type="radio"
                                            name="radio"
                                            value={true}
                                            onChange={handleRadio}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        {placeholder === "true" ? (
                            <div className={"editor-list-section nn"}>
                                <Input
                                    type="text"
                                    labelText={"Placeholder Text"}
                                    placeholder={"Add answer here"}
                                    value={props.selectedElement.htmlPlaceholder}
                                    onChange={(e) => handleChange(e, "htmlPlaceholder")}
                                />
                            </div>
                        ) : null}
                    </React.Fragment>
                )
            default:
                return null
        }
    }
    return (
        <React.Fragment>
            <div className="formTitle">
                <i className="icon-arrow-left1" onClick={props.handleBack}></i>
                <div className="formTitle-right">
                    <span>{TEXT_CONSTANTS?.SETTINGS}</span>
                    <p>{props.selectedElement.name}</p>
                </div>
                <i
                    className="icon-del"
                    onClick={() => props.deleteElement(props.selectedElement, props.selectedIndex)}
                ></i>
            </div>
            <div className="editor-list">
                <div className={"editor-list-section"}>
                    <Input
                        type="text"
                        cname={"sideMenu-input"}
                        labelText={"Add answer here"}
                        value={props.selectedElement.title}
                        onChange={(e) => handleChange(e, "title")}
                    />
                </div>
                <hr />
                {getPlaceholder(props.selectedElement)}
                <div className={"editor-list-section"}>
                    <span>{TEXT_CONSTANTS?.SIZE}</span>
                    <div className="size-btns">
                        <Button
                            cName={`btn popup-del-btn ${
                                props.selectedElement.size === "L" ? "active" : null
                            }`}
                            bValue="L"
                            bType="button"
                            clickHandler={() => selectSize("L")}
                            disabled={false}
                        />
                        <Button
                            cName={`btn popup-del-btn ${
                                props.selectedElement.size === "M" ? "active" : null
                            }`}
                            bValue="M"
                            bType="button"
                            clickHandler={() => selectSize("M")}
                        />
                        <Button
                            cName={`btn popup-del-btn ${
                                props.selectedElement.size === "S" ? "active" : null
                            }`}
                            bValue="S"
                            bType="button"
                            clickHandler={() => selectSize("S")}
                        />
                    </div>
                </div>
                <div className={"editor-list-section"}>
                    <span>{TEXT_CONSTANTS?.GENERAL_SETTINGS}</span>
                    {props.actionButton && (
                        <Checkbox
                            cName="check-box"
                            id={"checkBox"}
                            labelText={"Mandatory"}
                            changeHandler={CheckMandatory}
                            isChecked={props.selectedElement.required}
                        />
                    )}
                    {props.selectedElement.isSearchableKey ? (
                        <Checkbox
                            cName="check-box"
                            id={"checkBox"}
                            labelText={"Include in Search"}
                            changeHandler={() => props.addSearch(props.selectedElement.title)}
                        />
                    ) : null}
                </div>
                <hr />
                <div className={"editor-list-section"}>
                    <Input
                        type="text"
                        labelText={"Regular Expresion"}
                        placeholder={"Add answer here"}
                        value={props.selectedElement.regularExpresion}
                        onChange={(e) => handleChange(e, "regularExpresion")}
                    />
                </div>
                <hr />
            </div>
        </React.Fragment>
    )
}
