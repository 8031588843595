import React, { useState } from "react"
import { BUTTON_CONSTANTS, PERMISSIONS, TEXT_CONSTANTS } from "../../utils/constants"
import Button from "../common/Button"
import UploadMetaDataContent from "./uploadMetaDataContent"

import "./style.scss"
import MetadataIngestionTable from "./metaDataIngestionTable"
import { checkIfUserHasAccess } from "../../utils/users"

export default function MetadataIngestion() {
    const [isAddingNewData, setIsAddingNewData] = useState(false)

    const canUpdateStatus = checkIfUserHasAccess([PERMISSIONS.BULK_UPLOAD_EDIT])

    const addNewBackHandler = () => {
        setIsAddingNewData(!isAddingNewData)
    }

    return (
        <div className="vd-metadata-ingestion-container">
            {renderPageTitle({ isAddingNewData, addNewBackHandler, canUpdateStatus })}
            <>
                {isAddingNewData ? (
                    <div className="vr-content-holder">
                        <UploadMetaDataContent
                            addNewBackHandler={addNewBackHandler}
                        ></UploadMetaDataContent>
                    </div>
                ) : (
                    <MetadataIngestionTable
                        addNewBackHandler={addNewBackHandler}
                        canUpdateStatus={canUpdateStatus}
                    ></MetadataIngestionTable>
                )}
            </>
        </div>
    )
}

const renderPageTitle = ({ isAddingNewData, addNewBackHandler, canUpdateStatus }) => {
    return (
        <div className="vr-cont-head mb">
            {/* TODO: Replace below with the reusable pageTitleComponent */}
            <div className="vr-cont-title">
                <span className="vr-breadcrum">
                    {TEXT_CONSTANTS?.CONTENT} <i className="icon-arrow-right1" />
                    <span> {TEXT_CONSTANTS?.BULK_UPLOAD?.toLowerCase()}</span>
                </span>
                <h2 className="breadcrum-header">{TEXT_CONSTANTS?.BULK_UPLOAD}</h2>
            </div>

            {canUpdateStatus && (
                <div className="vr-cont-action header-filter-btn">
                    <Button
                        cName={isAddingNewData ? "btn border-btn" : "btn primary-btn"}
                        bValue={
                            isAddingNewData ? BUTTON_CONSTANTS?.BACK : BUTTON_CONSTANTS?.ADD_NEW
                        }
                        bType="button"
                        leftIcon={isAddingNewData && "icon-arrow-left1"}
                        clickHandler={addNewBackHandler}
                    />
                </div>
            )}
        </div>
    )
}
