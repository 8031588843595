import React, { useEffect } from "react"
import { sportsSetupSelectedTab } from "../APIs/action"
import SportsCategoryHeader from "../SportsCategoryHeader"
import SportsCategoryManager from "./SportsCategoryManager"
import SportsDataTable from "./SportsDataTable"
import { useDispatch, useSelector } from "react-redux"
import { TEXT_CONSTANTS } from "../../../utils/constants"
import PageTitleComponent from "../../common/PageTitleComponent"

export default function SportsSetup() {
    const selectedTab = useSelector((state) => state.sportsReducer?.sportsSetup?.selectedTab)
    const dispatch = useDispatch()

    useEffect(() => {
        // Cleanup selected Tab
        return () => {
            dispatch(sportsSetupSelectedTab(null))
        }
    }, [])

    return (
        <div className="sports-setup-wrapper">
            <div className="vr-cont-head mb">
                <PageTitleComponent menuTitle={TEXT_CONSTANTS?.SPORTS} pageTitle={TEXT_CONSTANTS?.SETUP_SPORTS}></PageTitleComponent>
            </div>
            <SportsCategoryHeader
                selectedTabAction={sportsSetupSelectedTab}
                selectedTab={selectedTab}
            ></SportsCategoryHeader>
            <SportsCategoryManager></SportsCategoryManager>
            <SportsDataTable></SportsDataTable>
        </div>
    )
}
