import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import get from "lodash/get"
import "./style.scss"
import { fetchEpgTvShow } from "../api/action"
import moment from "moment"
import {
    getDefaultMultilingualValue,
    getGMTTimezone,
    getTimeBasedOnTimezoneString,
    getUTCOffset
} from "../../../utils/common"
import { BUTTON_CONSTANTS, PERMISSIONS, TEXT_CONSTANTS } from "../../../utils/constants"
import { AuthWrapper } from "../../common/AuthWrapper"
import CustomModal from "../../common/Modal"

class TvShowEpg extends Component {
    constructor(props) {
        super(props)
        this.state = {
            epgTVshow: [],
            showMsg: false,
            epgTVList: [],
            epgID: null
        }
        this.getUTCOffset = getUTCOffset()
    }

    componentDidMount() {
        this.loadHandler()
    }

    loadHandler = async () => {
        const { fetchEpgTvShow, activeChannelId, dateSelected, selectedTimezoneLabel } = this.props
        const globalCurrentTimezone = getGMTTimezone(selectedTimezoneLabel?.timeZone)
        const response = await fetchEpgTvShow(
            activeChannelId,
            this.newDateFormat(dateSelected),
            globalCurrentTimezone
        )
        if (response && response.data) {
            //   toast.success(get(response, "message"));
            this.setState({
                epgTVList: response.data.epgShowVOS
            })
        } else {
            // toast.error(get(response, "message"));
        }
    }

    newDateFormat = (date) => {
        let updatedDate = new Date(date)
        updatedDate.setHours(5)
        updatedDate.setMinutes(30)
        updatedDate.setSeconds(0)
        const rounOffTimestamp = ((updatedDate.getTime() / 1000) | 0) * 1000
        return rounOffTimestamp
    }

    openPopup = (idToBeDeleted) => {
        this.setState({
            epgID: idToBeDeleted
        })
    }

    closeModal = () => {
        this.setState({
            epgID: null
        })
    }

    handleDeleteEpgShow = async (idToBeDeleted) => {
        const { handleDeleteEpgShow, dateSelected } = this.props
        const { epgID } = this.state
        await handleDeleteEpgShow(epgID)
        this.closeModal()
        this.loadHandler()
    }

    render() {
        const { showList, epgTVList } = this.state
        const { dateSelected, selectedTimezoneLabel } = this.props
        const globalCurrentTimezoneString = getGMTTimezone(selectedTimezoneLabel?.timeZone, 0)
        // let startTime =
        //   epgTVList &&
        //   epgTVList.length &&
        //   epgTVList?.map((i) => new Date(i.startTime).toUTCString().slice(-12, -7));
        // let endTime =
        //   epgTVList &&
        //   epgTVList?.length &&
        //   epgTVList?.map((i) => new Date(i.endTime).toUTCString().slice(-12, -7));
        // let todayTime = moment().format("HH:mm");
        // console.log("here");
        return (
            <>
                <div className="showHeader">
                    <span>{TEXT_CONSTANTS?.TV_SHOWS}</span>
                    <span>{moment(dateSelected).format(" DD MMMM, YYYY")}</span>
                </div>
                {!showList ? (
                    <ul className="showList">
                        {epgTVList?.map((item, idx) => (
                            <li>
                                <div className="left-list">
                                    <div className="showTime">
                                        <p>
                                            {getTimeBasedOnTimezoneString(
                                                item?.startTime,
                                                globalCurrentTimezoneString
                                            )}
                                        </p>
                                        <p>
                                            {getTimeBasedOnTimezoneString(
                                                item?.endTime,
                                                globalCurrentTimezoneString
                                            )}
                                        </p>
                                    </div>
                                    <div className="showName">
                                        <p>{item?.title}</p>
                                        {/* <p>{getDefaultMultilingualValue(item?.title)}</p> */}
                                    </div>
                                </div>
                                <AuthWrapper permissions={[PERMISSIONS?.EPG_EDIT]}>
                                    {!moment(item?.startTime).isBefore(+new Date()) && (
                                        <div className=" list-btn">
                                            <i
                                                className="icon-del"
                                                onClick={() => this.openPopup(item.id)}></i>
                                        </div>
                                    )}
                                </AuthWrapper>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>{TEXT_CONSTANTS?.SHOW_NOT_AVAILABLE} !</p>
                )}
                {this.state.epgID && (
                    <CustomModal
                        showModal={this.state.epgID}
                        submitHandler={this.handleDeleteEpgShow}
                        cancelHandler={this.closeModal}
                        closeOnSubmit={true}
                        submitButtonLabel={BUTTON_CONSTANTS?.CONFIRM}
                        showCancelButton={true}
                        cancelOnOutsideClick={false}
                        showCloseIcon={false}
                        submitBtnClass={"confirmation-submit-btn"}
                        cancelBtnClass={"confirmation-cancel-btn"}>
                        <div className="confirmation-modal-container">
                            <h3>{`${TEXT_CONSTANTS?.DELETE_EPG_MSG}: `}</h3>
                        </div>
                    </CustomModal>
                )}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        epgTVshow: get(state.epgTVshow, "epgTVshow", []),
        selectedTimezoneLabel: state?.timezone?.selectedTimezone
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                fetchEpgTvShow
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TvShowEpg)
