export const countries = [
    {
        id: "63b6478b1c3aa33a5c4a7a1a",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "AE",
        latitude: "23.424076",
        longitude: "53.847818",
        name: "United Arab Emirates"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a1b",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "BH",
        latitude: "25.930414",
        longitude: "50.637772",
        name: "Bahrain"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a1c",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "CI",
        latitude: "7.539989",
        longitude: "-5.54708",
        name: "Côte d'Ivoire"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a1d",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "SE",
        latitude: "60.128161",
        longitude: "18.643501",
        name: "Sweden"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a1e",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "NO",
        latitude: "60.472024",
        longitude: "8.468946",
        name: "Norway"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a1f",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "MX",
        latitude: "23.634501",
        longitude: "-102.552784",
        name: "Mexico"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a20",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "LS",
        latitude: "-29.609988",
        longitude: "28.233608",
        name: "Lesotho"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a21",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "SN",
        latitude: "14.497401",
        longitude: "-14.452362",
        name: "Senegal"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a22",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "NI",
        latitude: "12.865416",
        longitude: "-85.207229",
        name: "Nicaragua"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a23",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "FR",
        latitude: "46.227638",
        longitude: "2.213749",
        name: "France"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a24",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "AO",
        latitude: "-11.202692",
        longitude: "17.873887",
        name: "Angola"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a25",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "GE",
        latitude: "42.315407",
        longitude: "43.356892",
        name: "Georgia"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a26",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "ID",
        latitude: "-0.789275",
        longitude: "113.921327",
        name: "Indonesia"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a27",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "CD",
        latitude: "-4.038333",
        longitude: "21.758664",
        name: "Congo [DRC]"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a28",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "AR",
        latitude: "-38.416097",
        longitude: "-63.616672",
        name: "Argentina"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a29",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "TC",
        latitude: "21.694025",
        longitude: "-71.797928",
        name: "Turks and Caicos Islands"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a2a",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "MN",
        latitude: "46.862496",
        longitude: "103.846656",
        name: "Mongolia"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a2b",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "BN",
        latitude: "4.535277",
        longitude: "114.727669",
        name: "Brunei"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a2c",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "BM",
        latitude: "32.321384",
        longitude: "-64.75737",
        name: "Bermuda"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a2d",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "HT",
        latitude: "18.971187",
        longitude: "-72.285215",
        name: "Haiti"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a2e",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "ET",
        latitude: "9.145",
        longitude: "40.489673",
        name: "Ethiopia"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a2f",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "RU",
        latitude: "61.52401",
        longitude: "105.318756",
        name: "Russia"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a30",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "DO",
        latitude: "18.735693",
        longitude: "-70.162651",
        name: "Dominican Republic"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a31",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "MZ",
        latitude: "-18.665695",
        longitude: "35.529562",
        name: "Mozambique"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a32",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "LY",
        latitude: "26.3351",
        longitude: "17.228331",
        name: "Libya"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a33",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "GP",
        latitude: "16.995971",
        longitude: "-62.067641",
        name: "Guadeloupe"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a34",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "AD",
        latitude: "42.546245",
        longitude: "1.601554",
        name: "Andorra"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a35",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "XK",
        latitude: "42.602636",
        longitude: "20.902977",
        name: "Kosovo"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a36",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "BS",
        latitude: "25.03428",
        longitude: "-77.39628",
        name: "Bahamas"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a37",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "LV",
        latitude: "56.879635",
        longitude: "24.603189",
        name: "Latvia"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a38",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "SJ",
        latitude: "77.553604",
        longitude: "23.670272",
        name: "Svalbard and Jan Mayen"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a39",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "CU",
        latitude: "21.521757",
        longitude: "-77.781167",
        name: "Cuba"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a3a",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "WS",
        latitude: "-13.759029",
        longitude: "-172.104629",
        name: "Samoa"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a3b",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "UZ",
        latitude: "41.377491",
        longitude: "64.585262",
        name: "Uzbekistan"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a3c",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "MS",
        latitude: "16.742498",
        longitude: "-62.187366",
        name: "Montserrat"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a3d",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "KZ",
        latitude: "48.019573",
        longitude: "66.923684",
        name: "Kazakhstan"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a3e",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "GY",
        latitude: "4.860416",
        longitude: "-58.93018",
        name: "Guyana"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a3f",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "LA",
        latitude: "19.85627",
        longitude: "102.495496",
        name: "Laos"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a40",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "GI",
        latitude: "36.137741",
        longitude: "-5.345374",
        name: "Gibraltar"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a41",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "MA",
        latitude: "31.791702",
        longitude: "-7.09262",
        name: "Morocco"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a42",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "BR",
        latitude: "-14.235004",
        longitude: "-51.92528",
        name: "Brazil"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a43",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "AZ",
        latitude: "40.143105",
        longitude: "47.576927",
        name: "Azerbaijan"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a44",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "UG",
        latitude: "1.373333",
        longitude: "32.290275",
        name: "Uganda"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a45",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "EG",
        latitude: "26.820553",
        longitude: "30.802498",
        name: "Egypt"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a46",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "ZW",
        latitude: "-19.015438",
        longitude: "29.154857",
        name: "Zimbabwe"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a47",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "TT",
        latitude: "10.691803",
        longitude: "-61.222503",
        name: "Trinidad and Tobago"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a48",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "TG",
        latitude: "8.619543",
        longitude: "0.824782",
        name: "Togo"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a49",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "DJ",
        latitude: "11.825138",
        longitude: "42.590275",
        name: "Djibouti"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a4a",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "ME",
        latitude: "42.708678",
        longitude: "19.37439",
        name: "Montenegro"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a4b",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "TO",
        latitude: "-21.178986",
        longitude: "-175.198242",
        name: "Tonga"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a4c",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "AM",
        latitude: "40.069099",
        longitude: "45.038189",
        name: "Armenia"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a4d",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "LU",
        latitude: "49.815273",
        longitude: "6.129583",
        name: "Luxembourg"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a4e",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "PS",
        latitude: "31.952162",
        longitude: "35.233154",
        name: "Palestinian Territories"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a4f",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "HK",
        latitude: "22.396428",
        longitude: "114.109497",
        name: "Hong Kong"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a50",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "HM",
        latitude: "-53.08181",
        longitude: "73.504158",
        name: "Heard Island and McDonald Islands"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a51",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "SY",
        latitude: "34.802075",
        longitude: "38.996815",
        name: "Syria"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a52",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "CL",
        latitude: "-35.675147",
        longitude: "-71.542969",
        name: "Chile"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a53",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "KE",
        latitude: "-0.023559",
        longitude: "37.906193",
        name: "Kenya"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a54",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "RS",
        latitude: "44.016521",
        longitude: "21.005859",
        name: "Serbia"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a55",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "LB",
        latitude: "33.854721",
        longitude: "35.862285",
        name: "Lebanon"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a56",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "MK",
        latitude: "41.608635",
        longitude: "21.745275",
        name: "Macedonia [FYROM]"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a57",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "TW",
        latitude: "23.69781",
        longitude: "120.960515",
        name: "Taiwan"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a58",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "GD",
        latitude: "12.262776",
        longitude: "-61.604171",
        name: "Grenada"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a59",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "IT",
        latitude: "41.87194",
        longitude: "12.56738",
        name: "Italy"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a5a",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "JM",
        latitude: "18.109581",
        longitude: "-77.297508",
        name: "Jamaica"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a5b",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "MG",
        latitude: "-18.766947",
        longitude: "46.869107",
        name: "Madagascar"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a5c",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "CA",
        latitude: "56.130366",
        longitude: "-106.346771",
        name: "Canada"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a5d",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "JP",
        latitude: "36.204824",
        longitude: "138.252924",
        name: "Japan"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a5e",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "MM",
        latitude: "21.913965",
        longitude: "95.956223",
        name: "Myanmar [Burma]"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a5f",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "NP",
        latitude: "28.394857",
        longitude: "84.124008",
        name: "Nepal"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a60",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "BO",
        latitude: "-16.290154",
        longitude: "-63.588653",
        name: "Bolivia"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a61",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "FI",
        latitude: "61.92411",
        longitude: "25.748151",
        name: "Finland"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a62",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "UA",
        latitude: "48.379433",
        longitude: "31.16558",
        name: "Ukraine"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a63",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "UM",
        latitude: null,
        longitude: null,
        name: "U.S. Minor Outlying Islands"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a64",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "SI",
        latitude: "46.151241",
        longitude: "14.995463",
        name: "Slovenia"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a65",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "TL",
        latitude: "-8.874217",
        longitude: "125.727539",
        name: "Timor-Leste"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a66",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "IM",
        latitude: "54.236107",
        longitude: "-4.548056",
        name: "Isle of Man"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a67",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "VC",
        latitude: "12.984305",
        longitude: "-61.287228",
        name: "Saint Vincent and the Grenadines"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a68",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "CF",
        latitude: "6.611111",
        longitude: "20.939444",
        name: "Central African Republic"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a69",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "VE",
        latitude: "6.42375",
        longitude: "-66.58973",
        name: "Venezuela"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a6a",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "YE",
        latitude: "15.552727",
        longitude: "48.516388",
        name: "Yemen"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a6b",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "VN",
        latitude: "14.058324",
        longitude: "108.277199",
        name: "Vietnam"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a6c",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "ST",
        latitude: "0.18636",
        longitude: "6.613081",
        name: "São Tomé and Príncipe"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a6d",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "AI",
        latitude: "18.220554",
        longitude: "-63.068615",
        name: "Anguilla"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a6e",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "CG",
        latitude: "-0.228021",
        longitude: "15.827659",
        name: "Congo [Republic]"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a6f",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "FM",
        latitude: "7.425554",
        longitude: "150.550812",
        name: "Micronesia"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a70",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "TV",
        latitude: "-7.109535",
        longitude: "177.64933",
        name: "Tuvalu"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a71",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "PF",
        latitude: "-17.679742",
        longitude: "-149.406843",
        name: "French Polynesia"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a72",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "GM",
        latitude: "13.443182",
        longitude: "-15.310139",
        name: "Gambia"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a73",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "PE",
        latitude: "-9.189967",
        longitude: "-75.015152",
        name: "Peru"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a74",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "BV",
        latitude: "-54.423199",
        longitude: "3.413194",
        name: "Bouvet Island"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a75",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "HU",
        latitude: "47.162494",
        longitude: "19.503304",
        name: "Hungary"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a76",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "BB",
        latitude: "13.193887",
        longitude: "-59.543198",
        name: "Barbados"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a77",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "NU",
        latitude: "-19.054445",
        longitude: "-169.867233",
        name: "Niue"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a78",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "PM",
        latitude: "46.941936",
        longitude: "-56.27111",
        name: "Saint Pierre and Miquelon"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a79",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "PT",
        latitude: "39.399872",
        longitude: "-8.224454",
        name: "Portugal"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a7a",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "MT",
        latitude: "35.937496",
        longitude: "14.375416",
        name: "Malta"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a7b",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "AN",
        latitude: "12.226079",
        longitude: "-69.060087",
        name: "Netherlands Antilles"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a7c",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "NE",
        latitude: "17.607789",
        longitude: "8.081666",
        name: "Niger"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a7d",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "IN",
        latitude: "20.593684",
        longitude: "78.96288",
        name: "India"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a7e",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "KG",
        latitude: "41.20438",
        longitude: "74.766098",
        name: "Kyrgyzstan"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a7f",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "FK",
        latitude: "-51.796253",
        longitude: "-59.523613",
        name: "Falkland Islands [Islas Malvinas]"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a80",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "IM",
        latitude: "54.236107",
        longitude: "-4.548056",
        name: "Isle of Man"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a81",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "LT",
        latitude: "55.169438",
        longitude: "23.881275",
        name: "Lithuania"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a82",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "SA",
        latitude: "23.885942",
        longitude: "45.079162",
        name: "Saudi Arabia"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a83",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "EH",
        latitude: "24.215527",
        longitude: "-12.885834",
        name: "Western Sahara"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a84",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "GF",
        latitude: "3.933889",
        longitude: "-53.125782",
        name: "French Guiana"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a85",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "GG",
        latitude: "49.465691",
        longitude: "-2.585278",
        name: "Guernsey"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a86",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "LI",
        latitude: "47.166",
        longitude: "9.555373",
        name: "Liechtenstein"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a87",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "NF",
        latitude: "-29.040835",
        longitude: "167.954712",
        name: "Norfolk Island"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a88",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "SR",
        latitude: "3.919305",
        longitude: "-56.027783",
        name: "Suriname"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a89",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "WF",
        latitude: "-13.768752",
        longitude: "-177.156097",
        name: "Wallis and Futuna"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a8a",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "MU",
        latitude: "-20.348404",
        longitude: "57.552152",
        name: "Mauritius"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a8b",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "PG",
        latitude: "-6.314993",
        longitude: "143.95555",
        name: "Papua New Guinea"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a8c",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "KH",
        latitude: "12.565679",
        longitude: "104.990963",
        name: "Cambodia"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a8d",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "BJ",
        latitude: "9.30769",
        longitude: "2.315834",
        name: "Benin"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a8e",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "NL",
        latitude: "52.132633",
        longitude: "5.291266",
        name: "Netherlands"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a8f",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "EC",
        latitude: "-1.831239",
        longitude: "-78.183406",
        name: "Ecuador"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a90",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "MY",
        latitude: "4.210484",
        longitude: "101.975766",
        name: "Malaysia"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a91",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "GW",
        latitude: "11.803749",
        longitude: "-15.180413",
        name: "Guinea-Bissau"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a92",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "BE",
        latitude: "50.503887",
        longitude: "4.469936",
        name: "Belgium"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a93",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "CZ",
        latitude: "49.817492",
        longitude: "15.472962",
        name: "Czech Republic"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a94",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "PK",
        latitude: "30.375321",
        longitude: "69.345116",
        name: "Pakistan"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a95",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "IL",
        latitude: "31.046051",
        longitude: "34.851612",
        name: "Israel"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a96",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "CK",
        latitude: "-21.236736",
        longitude: "-159.777671",
        name: "Cook Islands"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a97",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "ML",
        latitude: "17.570692",
        longitude: "-3.996166",
        name: "Mali"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a98",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "GH",
        latitude: "7.946527",
        longitude: "-1.023194",
        name: "Ghana"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a99",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "NC",
        latitude: "-20.904305",
        longitude: "165.618042",
        name: "New Caledonia"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a9a",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "LR",
        latitude: "6.428055",
        longitude: "-9.429499",
        name: "Liberia"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a9b",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "CC",
        latitude: "-12.164165",
        longitude: "96.870956",
        name: "Cocos [Keeling] Islands"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a9c",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "BA",
        latitude: "43.915886",
        longitude: "17.679076",
        name: "Bosnia and Herzegovina"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a9d",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "TH",
        latitude: "15.870032",
        longitude: "100.992541",
        name: "Thailand"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a9e",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "TZ",
        latitude: "-6.369028",
        longitude: "34.888822",
        name: "Tanzania"
    },
    {
        id: "63b6478b1c3aa33a5c4a7a9f",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "MW",
        latitude: "-13.254308",
        longitude: "34.301525",
        name: "Malawi"
    },
    {
        id: "63b6478b1c3aa33a5c4a7aa0",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "PA",
        latitude: "8.537981",
        longitude: "-80.782127",
        name: "Panama"
    },
    {
        id: "63b6478b1c3aa33a5c4a7aa1",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "ES",
        latitude: "40.463667",
        longitude: "-3.74922",
        name: "Spain"
    },
    {
        id: "63b6478b1c3aa33a5c4a7aa2",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "PW",
        latitude: "7.51498",
        longitude: "134.58252",
        name: "Palau"
    },
    {
        id: "63b6478b1c3aa33a5c4a7aa3",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "BI",
        latitude: "-3.373056",
        longitude: "29.918886",
        name: "Burundi"
    },
    {
        id: "63b6478b1c3aa33a5c4a7aa4",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "JO",
        latitude: "30.585164",
        longitude: "36.238414",
        name: "Jordan"
    },
    {
        id: "63b6478b1c3aa33a5c4a7aa5",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "GN",
        latitude: "9.945587",
        longitude: "-9.696645",
        name: "Guinea"
    },
    {
        id: "63b6478b1c3aa33a5c4a7aa6",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "BZ",
        latitude: "17.189877",
        longitude: "-88.49765",
        name: "Belize"
    },
    {
        id: "63b6478b1c3aa33a5c4a7aa7",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "JE",
        latitude: "49.214439",
        longitude: "-2.13125",
        name: "Jersey"
    },
    {
        id: "63b6478b1c3aa33a5c4a7aa8",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "SV",
        latitude: "13.794185",
        longitude: "-88.89653",
        name: "El Salvador"
    },
    {
        id: "63b6478b1c3aa33a5c4a7aa9",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "SG",
        latitude: "1.352083",
        longitude: "103.819836",
        name: "Singapore"
    },
    {
        id: "63b6478b1c3aa33a5c4a7aaa",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "AU",
        latitude: "-25.274398",
        longitude: "133.775136",
        name: "Australia"
    },
    {
        id: "63b6478b1c3aa33a5c4a7aab",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "RE",
        latitude: "-21.115141",
        longitude: "55.536384",
        name: "Réunion"
    },
    {
        id: "63b6478b1c3aa33a5c4a7aac",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "SO",
        latitude: "5.152149",
        longitude: "46.199616",
        name: "Somalia"
    },
    {
        id: "63b6478b1c3aa33a5c4a7aad",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "VG",
        latitude: "18.420695",
        longitude: "-64.639968",
        name: "British Virgin Islands"
    },
    {
        id: "63b6478b1c3aa33a5c4a7aae",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "AW",
        latitude: "12.52111",
        longitude: "-69.968338",
        name: "Aruba"
    },
    {
        id: "63b6478b1c3aa33a5c4a7aaf",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "BY",
        latitude: "53.709807",
        longitude: "27.953389",
        name: "Belarus"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ab0",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "YT",
        latitude: "-12.8275",
        longitude: "45.166244",
        name: "Mayotte"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ab1",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "HN",
        latitude: "15.199999",
        longitude: "-86.241905",
        name: "Honduras"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ab2",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "PN",
        latitude: "-24.703615",
        longitude: "-127.439308",
        name: "Pitcairn Islands"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ab4",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "CY",
        latitude: "35.126413",
        longitude: "33.429859",
        name: "Cyprus"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ab5",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "SC",
        latitude: "-4.679574",
        longitude: "55.491977",
        name: "Seychelles"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ab6",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "IS",
        latitude: "64.963051",
        longitude: "-19.020835",
        name: "Iceland"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ab7",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "IR",
        latitude: "32.427908",
        longitude: "53.688046",
        name: "Iran"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ab8",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "SM",
        latitude: "43.94236",
        longitude: "12.457777",
        name: "San Marino"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ab9",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "CN",
        latitude: "35.86166",
        longitude: "104.195397",
        name: "China"
    },
    {
        id: "63b6478b1c3aa33a5c4a7aba",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "RO",
        latitude: "45.943161",
        longitude: "24.96676",
        name: "Romania"
    },
    {
        id: "63b6478b1c3aa33a5c4a7abb",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "BF",
        latitude: "12.238333",
        longitude: "-1.561593",
        name: "Burkina Faso"
    },
    {
        id: "63b6478b1c3aa33a5c4a7abc",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "CV",
        latitude: "16.002082",
        longitude: "-24.013197",
        name: "Cape Verde"
    },
    {
        id: "63b6478b1c3aa33a5c4a7abd",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "GB",
        latitude: "55.378051",
        longitude: "-3.435973",
        name: "United Kingdom"
    },
    {
        id: "63b6478b1c3aa33a5c4a7abe",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "GS",
        latitude: "-54.429579",
        longitude: "-36.587909",
        name: "South Georgia and the South Sandwich Islands"
    },
    {
        id: "63b6478b1c3aa33a5c4a7abf",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "KN",
        latitude: "17.357822",
        longitude: "-62.782998",
        name: "Saint Kitts and Nevis"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ac0",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "ZA",
        latitude: "-30.559482",
        longitude: "22.937506",
        name: "South Africa"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ac1",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "PL",
        latitude: "51.919438",
        longitude: "19.145136",
        name: "Poland"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ac2",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "NG",
        latitude: "9.081999",
        longitude: "8.675277",
        name: "Nigeria"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ac3",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "AT",
        latitude: "47.516231",
        longitude: "14.550072",
        name: "Austria"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ac4",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "GR",
        latitude: "39.074208",
        longitude: "21.824312",
        name: "Greece"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ac5",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "MO",
        latitude: "22.198745",
        longitude: "113.543873",
        name: "Macau"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ac6",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "HR",
        latitude: "45.1",
        longitude: "15.2",
        name: "Croatia"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ac7",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "SD",
        latitude: "12.862807",
        longitude: "30.217636",
        name: "Sudan"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ac8",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "PR",
        latitude: "18.220833",
        longitude: "-66.590149",
        name: "Puerto Rico"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ac9",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "AQ",
        latitude: "-75.250973",
        longitude: "-0.071389",
        name: "Antarctica"
    },
    {
        id: "63b6478b1c3aa33a5c4a7aca",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "VU",
        latitude: "-15.376706",
        longitude: "166.959158",
        name: "Vanuatu"
    },
    {
        id: "63b6478b1c3aa33a5c4a7acb",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "AD",
        latitude: "42.546245",
        longitude: "1.601554",
        name: "Andorra"
    },
    {
        id: "63b6478b1c3aa33a5c4a7acc",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "SK",
        latitude: "48.669026",
        longitude: "19.699024",
        name: "Slovakia"
    },
    {
        id: "63b6478b1c3aa33a5c4a7acd",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "KY",
        latitude: "19.513469",
        longitude: "-80.566956",
        name: "Cayman Islands"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ace",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "PY",
        latitude: "-23.442503",
        longitude: "-58.443832",
        name: "Paraguay"
    },
    {
        id: "63b6478b1c3aa33a5c4a7acf",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "MD",
        latitude: "47.411631",
        longitude: "28.369885",
        name: "Moldova"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ad0",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "CR",
        latitude: "9.748917",
        longitude: "-83.753428",
        name: "Costa Rica"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ad1",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "LK",
        latitude: "7.873054",
        longitude: "80.771797",
        name: "Sri Lanka"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ad2",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "GL",
        latitude: "71.706936",
        longitude: "-42.604303",
        name: "Greenland"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ad3",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "EE",
        latitude: "58.595272",
        longitude: "25.013607",
        name: "Estonia"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ad4",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "BD",
        latitude: "23.684994",
        longitude: "90.356331",
        name: "Bangladesh"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ad5",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "BG",
        latitude: "42.733883",
        longitude: "25.48583",
        name: "Bulgaria"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ad6",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "TD",
        latitude: "15.454166",
        longitude: "18.732207",
        name: "Chad"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ad7",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "KM",
        latitude: "-11.875001",
        longitude: "43.872219",
        name: "Comoros"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ad8",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "IQ",
        latitude: "33.223191",
        longitude: "43.679291",
        name: "Iraq"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ad9",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "NR",
        latitude: "-0.522778",
        longitude: "166.931503",
        name: "Nauru"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ada",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "NA",
        latitude: "-22.95764",
        longitude: "18.49041",
        name: "Namibia"
    },
    {
        id: "63b6478b1c3aa33a5c4a7adb",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "TR",
        latitude: "38.963745",
        longitude: "35.243322",
        name: "Turkey"
    },
    {
        id: "63b6478b1c3aa33a5c4a7adc",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "GU",
        latitude: "13.444304",
        longitude: "144.793731",
        name: "Guam"
    },
    {
        id: "63b6478b1c3aa33a5c4a7add",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "VI",
        latitude: "18.335765",
        longitude: "-64.896335",
        name: "U.S. Virgin Islands"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ade",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "GT",
        latitude: "15.783471",
        longitude: "-90.230759",
        name: "Guatemala"
    },
    {
        id: "63b6478b1c3aa33a5c4a7adf",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "RW",
        latitude: "-1.940278",
        longitude: "29.873888",
        name: "Rwanda"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ae0",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "NZ",
        latitude: "-40.900557",
        longitude: "174.885971",
        name: "New Zealand"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ae1",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "MQ",
        latitude: "14.641528",
        longitude: "-61.024174",
        name: "Martinique"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ae2",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "UY",
        latitude: "-32.522779",
        longitude: "-55.765835",
        name: "Uruguay"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ae3",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "ER",
        latitude: "15.179384",
        longitude: "39.782334",
        name: "Eritrea"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ae4",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "KW",
        latitude: "29.31166",
        longitude: "47.481766",
        name: "Kuwait"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ae5",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "QA",
        latitude: "25.354826",
        longitude: "51.183884",
        name: "Qatar"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ae6",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "TM",
        latitude: "38.969719",
        longitude: "59.556278",
        name: "Turkmenistan"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ae7",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "GZ",
        latitude: "31.354676",
        longitude: "34.308825",
        name: "Gaza Strip"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ae8",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "SZ",
        latitude: "-26.522503",
        longitude: "31.465866",
        name: "Swaziland"
    },
    {
        id: "63b6478b1c3aa33a5c4a7ae9",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "DE",
        latitude: "51.165691",
        longitude: "10.451526",
        name: "Germany"
    },
    {
        id: "63b6478b1c3aa33a5c4a7aea",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "DZ",
        latitude: "28.033886",
        longitude: "1.659626",
        name: "Algeria"
    },
    {
        id: "63b6478b1c3aa33a5c4a7aeb",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "FO",
        latitude: "61.892635",
        longitude: "-6.911806",
        name: "Faroe Islands"
    },
    {
        id: "63b6478b1c3aa33a5c4a7aec",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "FJ",
        latitude: "-16.578193",
        longitude: "179.414413",
        name: "Fiji"
    },
    {
        id: "63b6478b1c3aa33a5c4a7aed",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "TJ",
        latitude: "38.861034",
        longitude: "71.276093",
        name: "Tajikistan"
    },
    {
        id: "63b6478b1c3aa33a5c4a7aee",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "SB",
        latitude: "-9.64571",
        longitude: "160.156194",
        name: "Solomon Islands"
    },
    {
        id: "63b6478b1c3aa33a5c4a7aef",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "KR",
        latitude: "35.907757",
        longitude: "127.766922",
        name: "South Korea"
    },
    {
        id: "63b6478b1c3aa33a5c4a7af0",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "OM",
        latitude: "21.512583",
        longitude: "55.923255",
        name: "Oman"
    },
    {
        id: "63b6478b1c3aa33a5c4a7af1",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "MC",
        latitude: "43.750298",
        longitude: "7.412841",
        name: "Monaco"
    },
    {
        id: "63b6478b1c3aa33a5c4a7af2",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "BT",
        latitude: "27.514162",
        longitude: "90.433601",
        name: "Bhutan"
    },
    {
        id: "63b6478b1c3aa33a5c4a7af3",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "SH",
        latitude: "-24.143474",
        longitude: "-10.030696",
        name: "Saint Helena"
    },
    {
        id: "63b6478b1c3aa33a5c4a7af4",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "KI",
        latitude: "-3.370417",
        longitude: "-168.734039",
        name: "Kiribati"
    },
    {
        id: "63b6478b1c3aa33a5c4a7af5",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "SL",
        latitude: "8.460555",
        longitude: "-11.779889",
        name: "Sierra Leone"
    },
    {
        id: "63b6478b1c3aa33a5c4a7af6",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "IE",
        latitude: "53.41291",
        longitude: "-8.24389",
        name: "Ireland"
    },
    {
        id: "63b6478b1c3aa33a5c4a7af7",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "CO",
        latitude: "4.570868",
        longitude: "-74.297333",
        name: "Colombia"
    },
    {
        id: "63b6478b1c3aa33a5c4a7af8",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "PH",
        latitude: "12.879721",
        longitude: "121.774017",
        name: "Philippines"
    },
    {
        id: "63b6478b1c3aa33a5c4a7af9",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "MP",
        latitude: "17.33083",
        longitude: "145.38469",
        name: "Northern Mariana Islands"
    },
    {
        id: "63b6478b1c3aa33a5c4a7afa",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "AL",
        latitude: "41.153332",
        longitude: "20.168331",
        name: "Albania"
    },
    {
        id: "63b6478b1c3aa33a5c4a7afb",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "TF",
        latitude: "-49.280366",
        longitude: "69.348557",
        name: "French Southern Territories"
    },
    {
        id: "63b6478b1c3aa33a5c4a7afc",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "CM",
        latitude: "7.369722",
        longitude: "12.354722",
        name: "Cameroon"
    },
    {
        id: "63b6478b1c3aa33a5c4a7afd",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "DK",
        latitude: "56.26392",
        longitude: "9.501785",
        name: "Denmark"
    },
    {
        id: "63b6478b1c3aa33a5c4a7afe",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "DM",
        latitude: "15.414999",
        longitude: "-61.370976",
        name: "Dominica"
    },
    {
        id: "63b6478b1c3aa33a5c4a7aff",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "AF",
        latitude: "33.93911",
        longitude: "67.709953",
        name: "Afghanistan"
    },
    {
        id: "63b6478b1c3aa33a5c4a7b00",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "CX",
        latitude: "-10.447525",
        longitude: "105.690449",
        name: "Christmas Island"
    },
    {
        id: "63b6478b1c3aa33a5c4a7b01",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "VA",
        latitude: "41.902916",
        longitude: "12.453389",
        name: "Vatican City"
    },
    {
        id: "63b6478b1c3aa33a5c4a7b02",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "BW",
        latitude: "-22.328474",
        longitude: "24.684866",
        name: "Botswana"
    },
    {
        id: "63b6478b1c3aa33a5c4a7b03",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "GQ",
        latitude: "1.650801",
        longitude: "10.267895",
        name: "Equatorial Guinea"
    },
    {
        id: "63b6478b1c3aa33a5c4a7b04",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "GA",
        latitude: "-0.803689",
        longitude: "11.609444",
        name: "Gabon"
    },
    {
        id: "63b6478b1c3aa33a5c4a7b05",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "TK",
        latitude: "-8.967363",
        longitude: "-171.855881",
        name: "Tokelau"
    },
    {
        id: "63b6478b1c3aa33a5c4a7b06",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "US",
        latitude: "37.09024",
        longitude: "-95.712891",
        name: "United States"
    },
    {
        id: "63b6478b1c3aa33a5c4a7b07",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "CH",
        latitude: "46.818188",
        longitude: "8.227512",
        name: "Switzerland"
    },
    {
        id: "63b6478b1c3aa33a5c4a7b08",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "IO",
        latitude: "-6.343194",
        longitude: "71.876519",
        name: "British Indian Ocean Territory"
    },
    {
        id: "63b6478b1c3aa33a5c4a7b09",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "MH",
        latitude: "7.131474",
        longitude: "171.184478",
        name: "Marshall Islands"
    },
    {
        id: "63b6478b1c3aa33a5c4a7b0a",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "MR",
        latitude: "21.00789",
        longitude: "-10.940835",
        name: "Mauritania"
    },
    {
        id: "63b6478b1c3aa33a5c4a7b0b",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "AS",
        latitude: "-14.270972",
        longitude: "-170.132217",
        name: "American Samoa"
    },
    {
        id: "63b6478b1c3aa33a5c4a7b0c",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "TN",
        latitude: "33.886917",
        longitude: "9.537499",
        name: "Tunisia"
    },
    {
        id: "63b6478b1c3aa33a5c4a7b0d",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "MV",
        latitude: "3.202778",
        longitude: "73.22068",
        name: "Maldives"
    },
    {
        id: "63b6478b1c3aa33a5c4a7b0e",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "KP",
        latitude: "40.339852",
        longitude: "127.510093",
        name: "North Korea"
    },
    {
        id: "63b6478b1c3aa33a5c4a7b0f",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "AG",
        latitude: "17.060816",
        longitude: "-61.796428",
        name: "Antigua and Barbuda"
    },
    {
        id: "63b6478b1c3aa33a5c4a7b10",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "ZM",
        latitude: "-13.133897",
        longitude: "27.849332",
        name: "Zambia"
    },
    {
        id: "63b6478b1c3aa33a5c4a7b11",
        dateCreated: null,
        lastUpdated: null,
        active: true,
        code: "LC",
        latitude: "13.909444",
        longitude: "-60.978893",
        name: "Saint Lucia"
    }
]
