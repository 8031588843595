import React, { Component } from "react"
import Input from "../common/Input/index"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { toast } from "react-toastify"

import { uploadImage, deleteImage, saveUserPreference } from "./api/action"
import NoImgAvailable from "../../assets/images/no_image.jpg"
import Button from "../common/Button"
import { scrollPage } from "../../utils/common"

import "./style.scss"
import { imagePathPrefix } from "../../config/apiPath"
import { BUTTON_CONSTANTS, PERMISSIONS, TEXT_CONSTANTS } from "../../utils/constants"
import { checkIfUserHasAccess } from "../../utils/users"

class CustomerBranding extends Component {
    constructor(props) {
        super(props)
        this.state = {
            companyName: "",
            logo: {
                id: null,
                path: ""
            },
            defaultMode: "Light",
            isLoading: true
        }
        this.canUpdateStatus = checkIfUserHasAccess([PERMISSIONS?.CUSTOMER_BRANDING_EDIT])
    }

    componentDidMount() {
        scrollPage() // To scroll page up while rendering data
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    posterImgUpload = async (e) => {
        const { files } = e.target
        let data = new FormData()
        data.append("image", files[0])
        const response = await this.props.uploadImage(data, "CONTENT")
        if (response && response.status) {
            this.setState({
                logo: response.data
            })
        }
    }

    handleModeChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        const { companyName, defaultMode, logo } = this.state
        let payload = {
            darkTheme: defaultMode === "Dark" && true,
            logo: logo,
            name: companyName
        }
        const response = await this.props.saveUserPreference(payload)
        if (response && response.status) {
            toast.success("Data saved successfully")
        }
    }

    joinUrl = (string) => {
        return imagePathPrefix + string
    }

    render() {
        const { companyName, logo, defaultMode } = this.state
        return (
            <div className="vd-customer-branding">
                <div className="vr-cont-head mb">
                    {/* TODO: Replace below with the reusable pageTitleComponent */}
                    <div className="vr-cont-title">
                        <span className="vr-breadcrum">
                            {TEXT_CONSTANTS?.SETTINGS}
                            <i className="icon-arrow-right1" />
                            <span>{TEXT_CONSTANTS?.CUSTOMER_BRANDING}</span>
                        </span>
                        <h2 className="breadcrum-header">{TEXT_CONSTANTS?.CUSTOMER_BRANDING}</h2>
                    </div>
                </div>
                <div className="vr-content-holder">
                    <div className="customer-branding-container">
                        <div className="profile">
                            <img
                                className="profileImg"
                                src={`${imagePathPrefix}${logo.path}`}
                                alt=""
                                onError={(e) => {
                                    e.target.onerror = null
                                    e.target.src = `${NoImgAvailable}`
                                }}
                            />
                            <div className="profile-rt">
                                <span>{TEXT_CONSTANTS?.YOUR_LOGO?.toUpperCase()}</span>
                                {this.canUpdateStatus && (
                                    <label className="profileBtn" htmlFor="imgUpload">
                                        {BUTTON_CONSTANTS?.CHANGE?.toUpperCase()}
                                        <input
                                            type="file"
                                            id="imgUpload"
                                            name="image"
                                            onChange={this.posterImgUpload}
                                            accept="image/png, image/jpeg"
                                        />
                                    </label>
                                )}
                            </div>
                        </div>

                        <Input
                            type="text"
                            name="companyName"
                            id="companyName"
                            labelText="Company Name"
                            value={companyName}
                            changeHandler={this.handleChange}
                            disabled={!this.canUpdateStatus}
                        />
                        <div className="mode-selection">
                            <label htmlFor="mode" className="label">
                                {TEXT_CONSTANTS?.DEFAULT_MODE}
                            </label>
                            <div className="mode-selection-content">
                                <div className="radio-group">
                                    <input
                                        type="radio"
                                        id="publish"
                                        name="defaultMode"
                                        value="Light"
                                        onChange={this.handleModeChange}
                                        checked={defaultMode === "Light"}
                                        disabled={!this.canUpdateStatus}
                                    />
                                    <label></label>
                                    <span className="radio-text">{TEXT_CONSTANTS?.LIGHT}</span>
                                </div>
                                <div className="radio-group">
                                    <input
                                        type="radio"
                                        id="unpublish"
                                        name="defaultMode"
                                        value="Dark"
                                        onChange={this.handleModeChange}
                                        checked={defaultMode === "Dark"}
                                        disabled={!this.canUpdateStatus}
                                    />
                                    <label></label>
                                    <span className="radio-text">{TEXT_CONSTANTS?.DARK}</span>
                                </div>
                            </div>
                        </div>
                        {this.canUpdateStatus && (
                            <Button
                                cName="btn primary-btn submitBtn"
                                bValue={BUTTON_CONSTANTS?.SUBMIT}
                                clickHandler={this.handleSubmit}
                            />
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                uploadImage,
                deleteImage,
                saveUserPreference
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerBranding)
