import { intersection, isEmpty } from "lodash"
import store from "../store"

export const checkIfUserHasAccess = (permissions = []) => {
    if (isEmpty(permissions)) {
        return true
    }
    // console.log('store', store.getState())
    const { auth } = store.getState()
    // console.log('userDetails', auth state?.)
    // const user = ['admin', 'developer', 'superAdmin',]
    const userPermission = getUserPermissionsList(auth?.user?.roles)
    // return (
    //     !isEmpty(intersection(permissions, user.permissions)) ||
    //     // user.permissions?.includes(PERMISSIONS.superAdmin)
    // )
    return !isEmpty(intersection(permissions, userPermission))
}

//   export const checkIfUserHasAccess = (permissions = []) => {
//     if (isEmpty(permissions)) {
//       return true
//     }
//     // const { user } = store.getState()
//     const user = ['admin', 'developer', 'superAdmin',]
//     // return (
//     //     !isEmpty(intersection(permissions, user.permissions)) ||
//     //     // user.permissions?.includes(PERMISSIONS.superAdmin)
//     // )
//     return (!isEmpty(intersection(permissions, user)))
//   }

export const getUserPermissionsList = (userRoles) => {
    let permissionsList = []
    if (userRoles) {
        userRoles.forEach((role) => {
            const permissions = role?.permissions
            if (permissions) {
                permissions.forEach((item) => {
                    if (!permissionsList?.find((permissionItem) => permissionItem === item?.name)) {
                        permissionsList.push(item?.name)
                    }
                })
            }
            // permissionsList = [...permissionsList, ...role?.permissions]
        })
    }
    return permissionsList
}
