import { ACTION } from "./constant"

let initialState = {}

export default function productPlacementReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION.FETCH_PRODUCT_LIST:
            return { ...state, productList: action.apiResponse.data }
        case ACTION.FETCH_ADD_CLIPS_LIST:
            return { ...state, addClipList: action.apiResponse.data }
        case ACTION.FETCH_ADS_PREVIEW_URL:
            return { ...state, adsPreviewUrl: action.apiResponse.data }
        case ACTION.SAVE_ADD_URL:
            return { ...state, updatedData: action.apiResponse.data }
        default:
            return state
    }
}
