import React from "react"
import "./style.scss"

const TaggedPermissions = (props) => {
    const { taggedPermissions, label, changeHandler, errMsg } = props
    return (
        <>
            <div className="tagged-container">
                <label htmlFor="tagged-permission" className="labelText">
                    {`${label} ${
                        taggedPermissions.length > 0
                            ? `(${taggedPermissions.length})`
                            : ""
                    }`}
                </label>
                <div
                    className={
                        errMsg
                            ? "tagged-permission tagInputErr"
                            : "tagged-permission"
                    }>
                    {taggedPermissions.map((permission, index) => {
                        return (
                            <div key={index} className="label">
                                {permission.name}
                                <span
                                    onClick={(e) => changeHandler(permission)}>
                                    x
                                </span>
                            </div>
                        )
                    })}
                </div>
            </div>
            {errMsg && <span className="err">{errMsg}</span>}
        </>
    )
}

export default TaggedPermissions
