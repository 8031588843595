import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import { MenuDesignHeading } from "../api/constant"
import { fetchMenuList } from "../api/action"
import { fetchCategory } from "../../Category/api/action"
import { fetchAlltextpage } from "../../CustomPages/APIs/action"
import PlatformTabHeader from "../platformTab"
import { imgPathPrefix } from "../api/constant"
import { isMultiLingual, sortArray } from "../../../utils/common"

import "./style.scss"
import { DEFAULT_SELECTED_LANGUAGE_CODE } from "../../ContentNav/api/constants"

class MenuList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            menuList: [],
            isLoading: true,
            currentPlatform: this.props.currentPlatform
        }
    }
    componentDidMount() {
        this.loadHandler()
    }

    componentDidUpdate() {
        if (this.props.currentPlatform !== this.state.currentPlatform) {
            this.setState(
                {
                    currentPlatform: this.props.currentPlatform
                },
                () => this.loadHandler()
            )
        }
    }

    getDefaultLangugageCmsOptions = (list) => {
        if (Array.isArray(list)) {
            let tempList = []
            list.map((item) => {
                item.name = item.name["en"]
                tempList.push(item)
                return tempList
            })
            return tempList
        }
    }

    loadHandler = async () => {
        const { fetchMenuList, fetchCategory, fetchAlltextpage } = this.props
        const { currentPlatform } = this.state
        try {
            const response = await fetchMenuList(currentPlatform)
            let responseMenu = {
                isLoading: false,
                menuList: []
            }
            if (response?.status) {
                responseMenu.menuList = response.data || []
                const res = await fetchCategory(currentPlatform)
                const resp = await fetchAlltextpage({ limit: 40, offset: 0 })
                if (res?.status && resp?.status) {
                    responseMenu = {
                        ...responseMenu,
                        editorialOptions: res.data,
                        cmsOptions: isMultiLingual()
                            ? this.getDefaultLangugageCmsOptions(resp.data)
                            : resp.data
                    }
                }
            }
            this.setState(responseMenu)
        } catch (err) {
            console.log("Something went wrong!", err)
            this.setState({
                isLoading: false
            })
        }
    }
    tableloader = () => {
        let listLoader = [1, 2, 3, 4, 5, 6]
        return (
            <div className="rowContainer-loader">
                {listLoader.map(() => {
                    return (
                        <div className="row_loader">
                            <div className="block_loader" />
                            <div className="block_loader" />
                            <div className="block_loader" />
                            <div className="switch_loader" />
                        </div>
                    )
                })}
            </div>
        )
    }

    findLinkTo = (id, list) => {
        if (Array.isArray(list)) {
            let obj = null
            list.forEach((item) => {
                if (item.id === id) {
                    return (obj = item.name)
                }
            })
            return obj
        }
    }

    render() {
        const { platFormList, handlePlatformChange } = this.props
        const { menuList, isLoading, editorialOptions, cmsOptions, currentPlatform } = this.state
        const tableHeading = MenuDesignHeading.map((item, index) => {
            return <th key={index}>{item.key}</th>
        })
        const sortedData = sortArray(menuList)
        const tableRows =
            sortedData &&
            sortedData.map((row, i) => {
                const tabName = isMultiLingual()
                    ? row.tabName[DEFAULT_SELECTED_LANGUAGE_CODE]
                    : row.tabName
                return (
                    <tr id={row.id} key={row.id}>
                        <td>{i + 1}</td>
                        <td className="menuIconTD">
                            {row.menuIcon ? (
                                <img
                                    src={`${imgPathPrefix}${row.menuIcon}`}
                                    alt={tabName}
                                    className="menuIconImg"></img>
                            ) : (
                                "Not Available"
                            )}
                        </td>
                        <td>{tabName}</td>
                        <td>{row.menuType}</td>
                        <td>
                            {row.linkToPage
                                ? this.findLinkTo(
                                      row.menuType === "CMS"
                                          ? parseInt(row.linkToPage)
                                          : row.linkToPage,
                                      row.menuType === "TAB"
                                          ? editorialOptions
                                          : row.menuType === "CMS"
                                          ? cmsOptions
                                          : []
                                  )
                                : "-"}
                        </td>
                        <td>{row.placementType.join(" , ")}</td>
                    </tr>
                )
            })
        return (
            <div className="menuList-container">
                <PlatformTabHeader
                    platFormList={platFormList}
                    currentPlatform={currentPlatform}
                    handlePlatformChange={handlePlatformChange}
                />
                {isLoading ? (
                    this.tableloader()
                ) : (
                    <table className="table">
                        <thead>
                            <tr>{tableHeading}</tr>
                        </thead>
                        <tbody>{tableRows}</tbody>
                    </table>
                )}
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                fetchMenuList,
                fetchCategory,
                fetchAlltextpage
            },
            dispatch
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MenuList)
