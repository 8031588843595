import { PERMISSIONS } from "../../../utils/constants"

export const ACTION = {
    FETCH_ALL_ROLES: "FETCH_ALL_ROLES",
    ADD_ROLE: "ADD_ROLE",
    SOFT_DELETE_ROLE: "SOFT_DELETE_ROLE",
    FETCH_PERMISSIONS_LIST: "FETCH_PERMISSIONS_LIST",
    FETCH_USERS_LIST: "FETCH_USERS_LIST",
    FETCH_ALL_USERS_LIST: "FETCH_ALL_USERS_LIST",
    ADD_USER_DETAILS: "ADD_USER_DETAILS",
    CHANGE_USER_STATE: "CHANGE_USER_STATE",
    SOFT_DELETE_USER: "SOFT_DELETE_USER",
    FETCH_ROLES_LIST: "FETCH_ROLES_LIST"
}

export const USER_TABLE_HEADING = [
    { key: "sr", value: "sr" },
    { key: "name", value: "name" },
    { key: "email", value: "email" },
    { key: "role", value: "role" },
    { key: "active", value: "active" }
]

export const TABLE_DATA = [
    {
        id: 1,
        name: "Amit Singh",
        email: "amitguidesigner@gmail.com",
        role: ["Read only user", "Read only user", "Read only user", "Read only user"],
        isActive: true
    },
    {
        id: 2,
        name: "Raghuveer Printos",
        email: "raghuveer.printos@gmail.com",
        role: ["Read only user", "Read only user", "Read only user", "Read only user"],
        isActive: true
    },
    {
        id: 3,
        name: "Ryu Andrewson",
        email: "andrewson.ryu@gmail.com",
        role: ["Read only user", "Read only user", "Read only user", "Read only user"],
        isActive: true
    },
    {
        id: 4,
        name: "Mike Donal Beer",
        email: "donabeer.mike@gmail.com",
        role: ["Read only user", "Read only user", "Read only user", "Read only user"],
        isActive: false
    }
]

export const ROLES_TABLE_HEADING = [
    { name: "Sr", permissions: [] },
    { name: "roles name", permissions: [] },
    { name: "permissions", permissions: [] },
    { name: "tagged user", permissions: [] },
    {
        name: "action",
        permissions: [PERMISSIONS?.SETTING_USER_MANAGEMENT_ROLES_EDIT]
    }
]
export const ROLES_TABLE_ROWS = [
    {
        id: 1,
        name: "Admin",
        permissions: 12,
        taggedUser: 4
    },
    {
        id: 2,
        name: "Super Admin",
        permissions: 12,
        taggedUser: 4
    },
    {
        id: 3,
        name: "Author Movies",
        permissions: 12,
        taggedUser: 4
    },
    {
        id: 4,
        name: "Author Web Series",
        permissions: 12,
        taggedUser: 4
    }
]
