import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import get from "lodash/get"
import {
    fetchContent,
    deleteContentList,
    togglePublish,
    fetchTaggedContentInRail
} from "../api/action"
import Search from "../../common/Search"
import MovieCardGrid from "../../common/MovieCardGrid"
import MovieCardList from "./MovieCardListView"
import { dateTime, debounce, getContentData, isMultiLingual } from "../../../utils/common"
import MovieAlert from ".//MovieAlert/index"
import "./style.scss"
import Preview from "../Preview"
import { imgPathPrefix } from "../../Movies/api/constants"
import Checkbox from "../../common/Checkbox"
import {
    DEFAULT_SELECTED_LANGUAGE_CODE,
    DEFAULT_SELECTED_LANGUAGE_CODE_UPDATED
} from "../api/constants"
import { BUTTON_CONSTANTS, PERMISSIONS, TEXT_CONSTANTS } from "../../../utils/constants"
import { AuthWrapper } from "../../common/AuthWrapper"
import WebSeriesList from "./WebSeriesListView"
import { hideHeader } from "../contentNavCommon"

class ContentListing extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectId: [],
            isHidecard: "",
            isListView: false,
            contentType: null,
            item: null,
            isMovieAlert: false,
            selectedCards: [],
            massPublish: false,
            unpublishPopup: false,
            deletePopup: false,
            bothOptionSelected: true,
            isUnpublish: false,
            idToUnpublish: -1,
            isActive: "",
            selectedData: this.props?.selectedData
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps?.contentType !== this.props.contentType) {
            this.setState({ selectedCards: [] })
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { selectedCards } = this.state
        let UpdatedselectedCards = selectedCards.filter((item) => {
            if (item !== nextProps.deleteIndexId) {
                return item
            }
            return false
        })
        let updatedObj = {
            selectedCards: UpdatedselectedCards
        }
        if (nextProps.selectedData !== this.state.selectedData) {
            updatedObj["selectedData"] = nextProps.selectedData
        }

        this.setState({
            ...updatedObj
        })
    }

    // function to handle view change
    handleChangeView = (type) => {
        const { isListView } = this.state
        if (type === "list" && !isListView) {
            this.setState({
                isListView: !isListView
            })
        }
        if (type === "grid" && isListView) {
            this.setState({
                isListView: !isListView
            })
        }
    }

    loader = () => {
        let cardLoader = [1, 2, 3, 4, 5]
        return (
            <div className="cardContainer-loader">
                {cardLoader.map(() => {
                    return (
                        <div className="movieCard-loader movieCard">
                            <span className="imgLoader" />
                            <span className="tagLoader" />
                            <span className="cardNameLoader" />
                        </div>
                    )
                })}
            </div>
        )
    }

    closeAlertModal = () => {
        const { movieAlert } = this.state
        this.setState({
            movieAlert: !movieAlert
        })
    }
    handleEditClick = (contentId) => {
        this.props.handleAddClick()
        this.props.prefillSelectedData(contentId)
    }

    setSearchQuery = debounce((name, value) => {
        this.props.handleSearch(name, value)
    })

    handleMultiSelect = (name, checked, id, active) => {
        let selectedCards = this.state.selectedCards
        if (checked) {
            selectedCards.push(id)
        } else {
            selectedCards = selectedCards.filter((item) => {
                if (item !== id) {
                    return item
                }
                return false
            })
        }
        let count = 0
        let count1 = 0
        const { contentList } = this.props
        const content =
            contentList && contentList.data && contentList.data && contentList.data.length
                ? contentList.data
                : []
        selectedCards.length &&
            selectedCards.forEach((item) => {
                content.forEach((ele) => {
                    if (ele.active === item && !ele.active) {
                        count = count + 1
                    }
                    if (ele.active === item && ele.active) {
                        count1 = count1 + 1
                    }
                })
            })

        let tempSelctedcards = []
        contentList.data.forEach((item) => {
            selectedCards.forEach((ele) => {
                if (ele === item.id) {
                    tempSelctedcards.push(item)
                }
            })
        })
        let tempActive = tempSelctedcards.every((item) => item.active === true)
        let tempNonactive = tempSelctedcards.every((item) => item.active === false)
        this.setState({
            selectsetSearchQueryedCards: selectedCards,
            selectedCards: selectedCards,
            massPublish: !tempActive ? true : false,
            bothOptionSelected: !(tempActive || tempNonactive) ? false : true
        })
    }

    activeCardId = (a, b, c, d) => {}

    toCamelCase = (sentenceCase) => {
        var out = ""
        sentenceCase.split(" ").forEach(function (el, idx) {
            var add = el.toLowerCase()
            out += idx === 0 ? add : add[0].toUpperCase() + add.slice(1)
        })
        return out
    }
    toggleConfirmationPopup = (e, popupKey) => {
        e.stopPropagation()
        this.setState((prevState) => {
            return {
                unpublishPopup: false,
                deletePopup: false,
                [popupKey]: !prevState[popupKey]
            }
        })
    }

    handleMassClose = () => {
        this.setState({
            selectedCards: []
        })
        // this.props.handleLoadhandler()
    }

    selectAllCard = (name, checked, id) => {
        let { contentList } = this.props
        if (checked) {
            let list = contentList.data.map((item) => item.id)
            this.setState({
                selectedCards: list,
                bothOptionSelected: false
            })
        } else {
            this.setState({
                selectedCards: []
            })
        }
    }

    handlepublishItem = (selectedCards, massPublish) => {
        this.props.handleUnpublishClick(selectedCards, massPublish)
        this.handleMassClose()
    }

    handleDelete = (selectedCards) => {
        this.props.handleDeleteContent(selectedCards)
        this.handleMassClose()
    }

    getContentTitle = (contentType, ele) => {
        switch (contentType) {
            case "LIVETV":
                return isMultiLingual()
                    ? ele?.content?.title?.values[DEFAULT_SELECTED_LANGUAGE_CODE]
                    : ele?.content?.title
            case "Ads":
                return isMultiLingual()
                    ? ele?.content?.ads_title?.values[DEFAULT_SELECTED_LANGUAGE_CODE]
                    : ele?.content?.ads_title
            case "REELS":
                return isMultiLingual()
                    ? ele.content.reel_title?.values[DEFAULT_SELECTED_LANGUAGE_CODE]
                    : ele.content.title
            // case "MOVIES": {
            //     if (isMultiLingual()) {
            //         return ele?.content?.movie_title?.values[DEFAULT_SELECTED_LANGUAGE_CODE]
            //     }
            //     return ele?.content?.movie_title
            // }
            case "WEB SERIES":
                return ""
            default: {
                const shortTitle = ele.title.short || ele.title.shortTitle
                const longTitle = ele.title.long || ele.title.longTitle
                return isMultiLingual()
                    ? shortTitle[DEFAULT_SELECTED_LANGUAGE_CODE_UPDATED]
                    : longTitle[DEFAULT_SELECTED_LANGUAGE_CODE_UPDATED]
            }
        }
    }

    renderContentHeader = () => {
        const { searchValue, contentType, contentList } = this.props
        const { isListView } = this.state
        return (
            <div className="vr-cont-head">
                <div className="vr-cont-title">
                    <h2 className="inner-count-title">
                        {contentList && contentList.count}
                        <span> </span>
                        {contentType === "LIVETV" ? (
                            <span>{TEXT_CONSTANTS?.LIVE_TV?.toUpperCase()}</span>
                        ) : contentType?.includes("_") ? (
                            contentType.replace("_", " ")
                        ) : (
                            contentType
                        )}
                    </h2>
                </div>
                <div className="vr-cont-action">
                    <Search
                        name="search"
                        changeHandler={this.setSearchQuery}
                        placeholder="Search with title, keywords or contentID"
                        value={searchValue}
                    />
                    <div
                        className={`icons icon-list ${isListView ? "active" : ""}`}
                        onClick={() => this.handleChangeView("list")}></div>
                    <div
                        className={`icons icon-grid ${!isListView ? "active" : ""}`}
                        onClick={() => this.handleChangeView("grid")}></div>
                </div>
            </div>
        )
    }

    renderListOrGridView = () => {
        const {
            contentType,
            contentList,
            imageKey,
            handleViewClick,
            isLoading,
            handleDeleteContent,
            handleUnpublishClick
        } = this.props
        const { isListView, selectedCards, massPublish } = this.state
        const content = contentList?.data?.length ? contentList.data : []
        if (!content.length || contentType === "CHANNELDAY") {
            return <div>{TEXT_CONSTANTS?.NO_RESULT_FOUND}</div>
        }
        return isListView ? (
            <WebSeriesList
                movieList={content}
                selectedCards={selectedCards}
                imageKey={imageKey}
                activeCardId={(a, b, c, d) => this.handleMultiSelect(a, b, c, d)}
                showEditOptions={!hideHeader(contentType?.toLowerCase())}
                handleViewClick={handleViewClick}
                handleDeleteContent={handleDeleteContent}
                handleEdit= {(ele) =>  handleViewClick(ele, true)}
                contentType={contentType}
                handleUnpublishClick={handleUnpublishClick}
            />
        ) : (
            content.map((ele, index) => {
                const { date, time } = dateTime(ele.lastUpdated)
                return (
                    <MovieCardGrid
                        published={ele.active}
                        key={ele.id}
                        date={date}
                        time={time}
                        contentId={ele.id}
                        id={ele.id}
                        cardType={
                            contentType === "LIVETV"
                                ? "livetvcard"
                                : contentType === "reels"
                                ? "promotionalBannerCard"
                                : ""
                        }
                        thumbnail={
                            ele.images &&
                            getContentData(ele.images.find((item) => item.type === "thumbnail").url)
                        }
                        title={ele.title ? this.getContentTitle(contentType, ele) : ""}
                        isButton={true}
                        handleViewClick={() => handleViewClick(ele)}
                        handleEdit= {() => handleViewClick(ele, true)}
                        hideOverlayHeader={hideHeader(contentType?.toLowerCase())}
                        handleDeleteContent={handleDeleteContent}
                        handleUnpublishClick={() =>
                            handleUnpublishClick(ele.id, ele.active)
                        }
                    />
                )
            })
        )
    }

    renderContentPreview = () => {
        const {
            contentType,
            contentList,
            handleViewClick,
            contentPreview,
            handleDeleteContent,
            handleUnpublishClick
        } = this.props
        const { selectedData } = this.state
        const content =
            contentList && contentList.data && contentList.data && contentList.data.length
                ? contentList.data
                : []

        return (
            contentPreview && (
                <div className="moviePreview">
                    <div className="empty-wrapper" onClick={handleViewClick} />
                    {contentType.toLowerCase() === "movies" ? (
                        <Preview
                            contentType={contentType}
                            isMovieView={true}
                            item={selectedData.content}
                            selectedData={selectedData}
                            handleOnClose={handleViewClick}
                            posterImg={`${imgPathPrefix}${getContentData(
                                get(selectedData, "content.poster_image")
                            )}`}
                            videoSrc={get(selectedData.content, "play_url")}
                            movieTitle={getContentData(get(selectedData, "content.movie_title"))}
                            year={get(selectedData, "content.movie_year")}
                            genre={getContentData(selectedData.content?.genre)}
                            rating={"U/A"}
                            movieDesc={getContentData(get(selectedData, "content.description"))}
                            contentId={content.id}
                            language={getContentData(selectedData.content?.language)}
                            handleEdit={this.handleEditClick}
                            handleDeleteContent={handleDeleteContent}
                            handleDeleteView={handleViewClick}
                            handleUnpublishClick={handleUnpublishClick}
                            published={selectedData.active}
                            director={getContentData(get(selectedData, "content.director"))}
                            producer={getContentData(get(selectedData, "content.producer"))}
                            starringData={getContentData(get(selectedData, "content.actor"))}
                        />
                    ) : contentType.toLowerCase().split(" ").join("") === "livetv" ? (
                        <Preview
                            isMovieView={true}
                            selectedData={selectedData}
                            item={selectedData.content}
                            handleOnClose={handleViewClick}
                            posterImg={`${imgPathPrefix}${getContentData(
                                get(selectedData, "content.channel_logo")
                            )}`}
                            videoSrc={get(selectedData, "content.play_url")}
                            movieTitle={getContentData(get(selectedData, "content.title"))}
                            rating={"U/A"}
                            genre={getContentData(selectedData.content?.genre)}
                            language={getContentData(selectedData.content?.language)}
                            movieDesc={
                                getContentData(get(selectedData, "content.description")) || ""
                            }
                            contentId={content.id}
                            handleEdit={this.handleEditClick}
                            handleTogglePublish={this.handleTogglePublish}
                            handleDeleteContent={handleDeleteContent}
                            handleDeleteView={handleViewClick}
                            handleUnpublishClick={handleUnpublishClick}
                            published={selectedData.active}
                        />
                    ) : contentType.toLowerCase().split(" ").join("") === "webseries" ? (
                        <Preview
                            selectedData={selectedData}
                            isMovieView={true}
                            item={selectedData.content}
                            handleOnClose={handleViewClick}
                            posterImg={`${imgPathPrefix}${getContentData(
                                get(selectedData, "content.brand_poster_image")
                            )}`}
                            videoSrc={get(selectedData, "content.brand_trailer_url")}
                            movieTitle={getContentData(get(selectedData, "content.brand_title"))}
                            rating={"U/A"}
                            movieDesc={
                                getContentData(get(selectedData.content, "brand_description")) || ""
                            }
                            genre={getContentData(selectedData.content?.brand_genre)}
                            language={getContentData(selectedData.content?.brand_language)}
                            director={getContentData(selectedData.content?.brand_director)}
                            producer={getContentData(selectedData.content?.brand_producer)}
                            starringData={getContentData(selectedData.content?.brand_actor)}
                            contentId={content.id}
                            handleEdit={this.handleEditClick}
                            handleTogglePublish={this.handleTogglePublish}
                            handleDeleteContent={handleDeleteContent}
                            handleDeleteView={handleViewClick}
                            handleUnpublishClick={handleUnpublishClick}
                            published={selectedData.active}
                        />
                    ) : contentType.toLowerCase().split(" ").join("") === "ads" ? (
                        <Preview
                            isMovieView={true}
                            selectedData={selectedData}
                            item={selectedData.content}
                            handleOnClose={handleViewClick}
                            posterImg={`${imgPathPrefix}${getContentData(
                                get(selectedData, "content.cover_image")
                            )}`}
                            videoSrc={get(selectedData, "content.play_url")}
                            movieTitle={getContentData(get(selectedData, "content.ads_title"))}
                            rating={"U/A"}
                            genre={getContentData(selectedData.content?.genre)}
                            language={getContentData(selectedData.content?.language)}
                            movieDesc={
                                getContentData(get(selectedData, "content.description")) || ""
                            }
                            contentId={content.id}
                            handleEdit={this.handleEditClick}
                            handleTogglePublish={this.handleTogglePublish}
                            handleDeleteContent={handleDeleteContent}
                            handleDeleteView={handleViewClick}
                            handleUnpublishClick={handleUnpublishClick}
                            published={selectedData.active}
                        />
                    ) : contentType.toLowerCase().split(" ").join("") === "reels" ? (
                        <Preview
                            isMovieView={true}
                            selectedData={selectedData}
                            item={selectedData.content}
                            handleOnClose={handleViewClick}
                            posterImg={`${imgPathPrefix}${getContentData(
                                get(selectedData, "content.reel_poster_image")
                            )}`}
                            videoSrc={get(selectedData, "content.play_url")}
                            movieTitle={getContentData(get(selectedData, "content.reel_title"))}
                            language={getContentData(selectedData.content?.language)}
                            movieDesc={
                                getContentData(get(selectedData, "content.description")) || ""
                            }
                            contentId={content?.id}
                            handleEdit={this.handleEditClick}
                            handleTogglePublish={this.handleTogglePublish}
                            handleDeleteContent={handleDeleteContent}
                            handleDeleteView={handleViewClick}
                            handleUnpublishClick={handleUnpublishClick}
                            published={selectedData?.active}
                            rating={"U/A"}
                        />
                    ) : null}
                </div>
            )
        )
    }

    renderMovieAlert = () => {
        const { item, isMovieAlert } = this.state

        return (
            isMovieAlert && (
                <div className="moviePreview">
                    <div className="empty-wrapper" onClick={this.closeAlertModal} />
                    <MovieAlert
                        handleOnClose={this.closeAlertModal}
                        hashTag={this.getGenreList(get(item, "genre"))}
                        title={get(item, "title")}
                        created_At=""
                        created_Time=""
                        handleTogglePublish={this.handleTogglePublish}
                        contentId={item.id}
                        published={item.published}
                    />
                </div>
            )
        )
    }

    renderBulkSelectedPanel = () => {
        const { contentType, contentList } = this.props
        const { selectedCards, unpublishPopup, massPublish, deletePopup, bothOptionSelected } =
            this.state
        let contentLength = contentList && contentList.data && contentList.data.length
        return (
            <div
                className={`menuComponent ${selectedCards.length ? "showMe" : ""}`}
                ref={this.userActionContainer}>
                <div className="selectionValue">
                    <div className="selectAllCheckBox">
                        <Checkbox
                            cName="check-box"
                            id={"checkBox"}
                            name={"select all"}
                            isChecked={selectedCards.length === contentLength ? true : false}
                            changeHandler={this.selectAllCard}
                        />
                    </div>
                    <div>
                        <span className="selected-count">{selectedCards.length}</span>
                        {contentType} Selected
                    </div>
                </div>
                <div className="selectionButtons">
                    <AuthWrapper permissions={[PERMISSIONS.CONTENT_EDIT]}>
                        <div
                            className={unpublishPopup ? "toolsBtn mrl0 show" : "toolsBtn mrl0"}
                            onClick={(e) => this.toggleConfirmationPopup(e, "unpublishPopup")}>
                            {bothOptionSelected && (
                                <div>
                                    <i
                                        className={
                                            massPublish
                                                ? "toolsBtn icon-published"
                                                : "toolsBtn icon-unpublish"
                                        }>
                                        <span className="tooltiptext">
                                            {massPublish ? "Publish" : "Unpublish"}
                                        </span>
                                    </i>
                                </div>
                            )}
                            <div className="confirmation-popup">
                                <div className="message">
                                    {TEXT_CONSTANTS?.WISH_TO}
                                    {massPublish ? (
                                        <span className="type">
                                            {" "}
                                            {TEXT_CONSTANTS?.PUBLISH?.toLowerCase()}
                                        </span>
                                    ) : (
                                        <span className="type">
                                            {" "}
                                            {TEXT_CONSTANTS?.UNPUBLISH?.toLowerCase()}
                                        </span>
                                    )}
                                    this movie?
                                </div>
                                <button
                                    type="button"
                                    className="btn primary-btn"
                                    onClick={() =>
                                        this.handlepublishItem(selectedCards, !massPublish)
                                    }>
                                    {massPublish ? (
                                        <> {TEXT_CONSTANTS?.PUBLISH?.toLowerCase()}</>
                                    ) : (
                                        <> {TEXT_CONSTANTS?.UNPUBLISH?.toLowerCase()}</>
                                    )}
                                </button>
                                <button
                                    type="button"
                                    className="btn cancel-btn"
                                    onClick={(e) =>
                                        this.toggleConfirmationPopup(e, "unpublishPopup")
                                    }>
                                    {BUTTON_CONSTANTS?.CANCEL}
                                </button>
                            </div>
                        </div>

                        <div
                            className={deletePopup ? "toolsBtn icon-del show" : "toolsBtn icon-del"}
                            onClick={(e) => this.toggleConfirmationPopup(e, "deletePopup")}>
                            <span className="tooltiptext"> {BUTTON_CONSTANTS?.DELETE}</span>
                            <div className="confirmation-popup">
                                <div className="message">
                                    {TEXT_CONSTANTS?.WISH_TO_DELETE_MOVIE}
                                </div>
                                <button
                                    type="button"
                                    className="btn primary-btn"
                                    onClick={() => this.handleDelete(selectedCards)}>
                                    {BUTTON_CONSTANTS?.DELETE}
                                </button>
                                <button
                                    type="button"
                                    className="btn cancel-btn"
                                    onClick={(e) => this.toggleConfirmationPopup(e, "deletePopup")}>
                                    {BUTTON_CONSTANTS?.CANCEL}
                                </button>
                            </div>
                        </div>
                    </AuthWrapper>

                    <div className="toolsBtn icon-close" onClick={() => this.handleMassClose()}>
                        <span className="tooltiptext"> {BUTTON_CONSTANTS?.CLOSE}</span>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { isLoading } = this.props
        return (
            <>
                <div className="vr-content-holder">
                    {this.renderContentHeader()}
                    <div className="movieList-container">
                        {isLoading ? this.loader() : this.renderListOrGridView()}
                    </div>
                    {/*{this.renderContentPreview()}*/}
                    {this.renderMovieAlert()}
                </div>
                {this.renderBulkSelectedPanel()}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                fetchContent,
                deleteContentList,
                togglePublish,
                fetchTaggedContentInRail
            },
            dispatch
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ContentListing)
