import React from "react"
import MaskedInput from "react-maskedinput"
import "./style.scss"

const CustomInput = (props) => {
    const {
        mask,
        placeholder,
        size,
        name,
        changeHandler,
        labelText,
        required,
        id,
        cName,
        disabled
    } = props
    return (
        <div className={`customInput ${cName}`}>
            {labelText && (
                <label htmlFor={id}>
                    {labelText}
                    <span
                        style={{
                            display: required ? "inline" : "none",
                            color: "#1FB1FF"
                        }}>
                        *
                    </span>
                </label>
            )}
            <MaskedInput
                id={id}
                mask={mask}
                placeholder={placeholder}
                size={size}
                name={name}
                onChange={changeHandler}
                disabled={disabled}
                // formatCharacters={{
                //     'W': {
                //         validate(char) { return /\w/.test(char) },
                //         transform(char) { return char.toUpperCase() }
                //     }
                // }}
            />
        </div>
    )
}

export default CustomInput
