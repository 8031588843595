import React, { Component } from "react"
import Input from "../../common/Input"
import Button from "../../common/Button"
import Select from "../../common/SelectBox"
import MultiSelect from "../../common/CustomMultiselect"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import get from "lodash/get"
import ImageUplode from "../../common/imageUpload"
import { ratingList, catchUpList } from "../api/constant"
import {
    checkAllLanguageHasValue,
    getGMTTimezone,
    getTimeBasedOnTimezoneString,
    getUTCOffset,
    isMultiLingual,
    scrollPage
} from "../../../utils/common"
import {
    uploadImage,
    addEpgShow,
    fetchAddEpgOptionData,
    fetchEpgContentLiveTV,
    fetchEpgTvShow
} from "../api/action"
import "./style.scss"
import TvShowEpg from "./TvShow"
import TextArea from "../../common/TextArea/index"
import LanguageCardList from "../../common/LanguageCardList"
import LanguageDataConfirmationModal from "../../common/LanguageCardList/languageDataConfirmationModal"
import { BUTTON_CONSTANTS, PERMISSIONS, TEXT_CONSTANTS } from "../../../utils/constants"
import { checkIfUserHasAccess } from "../../../utils/users"
import moment from "moment"
import { isEmpty } from "lodash"

class AddNewEpg extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isDisable: true,
            epgTVshow: [],
            title: "",
            // timezone: "",
            selectChannel: "",
            description: "",
            genre: "",
            language: "",
            dates: [{}],
            expandDesc: false,
            expandCredit: false,
            epgOption: [],
            genreList: [],
            languageList1: [],
            directorList: [],
            producerList: [],
            actorsList: [],
            coverImage: "",
            catchupAvailable: "",
            rating: "",
            actors: "",
            directors: "",
            producers: "",
            selectedLanguage: {
                name: "English",
                code: "en"
            },
            emptyLangDataModal: false,
            finalPayload: {}
        }
        this.isMultiLingualAllowed = isMultiLingual()
        this.canUpdateStauts = checkIfUserHasAccess([PERMISSIONS.EPG_EDIT])
        this.globalCurrentTimezoneString = getGMTTimezone(
            this.props?.selectedTimezoneLabel?.timeZone,
            0
        )
    }

    componentDidMount() {
        scrollPage()
        this.loadHandler()
    }

    loadHandler = async () => {
        const {
            dateSelected,
            isEdit,
            editableData: {
                title,
                startTime,
                endTime,
                description,
                catchupAvailable,
                rating,
                actors,
                directors,
                producers,
                genre,
                language,
                coverImage
            },
            epgLiveTVdata
        } = this.props

        this.selectOptions()
        if (dateSelected) {
            this.setState({
                dates: [
                    {
                        date: dateSelected
                    }
                ]
            })
        }
        if (isEdit) {
            /* moment utcOffset is used to convert time to IST (local time of the country) */
            this.setState({
                title,
                dates: [
                    {
                        date: dateSelected,
                        startTime: getTimeBasedOnTimezoneString(
                            startTime,
                            this.globalCurrentTimezoneString
                        ),
                        endTime: getTimeBasedOnTimezoneString(
                            endTime,
                            this.globalCurrentTimezoneString
                        )
                    }
                ],
                description,
                catchupAvailable: catchupAvailable ? { id: 1, name: "Yes" } : { id: 0, name: "No" },
                rating: rating,
                actors,
                directors,
                producers,
                genre,
                selectChannel: epgLiveTVdata.name,
                language,
                coverImage
            })
        }
    }

    requiredFormat = (data) => {
        let updatedData =
            !isEmpty(data) &&
            data?.map((item, index) => {
                return item.id ? item.id : item
            })
        return !isEmpty(updatedData) ? updatedData : []
    }

    selectOptions = () => {
        const { genreList, languageList1, directorList, producerList, actorsList } = this.props

        this.setState({
            genreList: genreList,
            languageList1: languageList1,
            directorList: directorList,
            producerList: producerList,
            actorsList: actorsList
        })
    }

    handleChangeinput = (name, value, index) => {
        let updatedMultilingualValue = value
        if ((this.isMultiLingualAllowed && name === "title") || name === "description") {
            const { selectedLanguage } = this.state
            updatedMultilingualValue = { ...this.state?.[name] }
            updatedMultilingualValue[selectedLanguage.code] = value
        }

        this.setState(
            {
                [name]: updatedMultilingualValue
            },
            () => {
                this.validatePayload()
            }
        )
    }

    validatePayload = () => {
        const { title, description, dates, coverImage } = this.state
        if (
            !isEmpty(title) &&
            !isEmpty(description) &&
            !!dates[0].startTime &&
            !!dates[0].endTime &&
            !isEmpty(coverImage)
        ) {
            /*  if (!this.handleTimestampValidaiton()) {
        this.setState({
          isDisable: false,
        });
      } */
            this.setState({
                isDisable: false
            })
        } else {
            this.setState({
                isDisable: true
            })
        }
    }

    handleChange = (name, value, index) => {
        let value1 = value.map((item, i) => {
            return item.id
        })
        this.setState(
            {
                [name]: value1
            },
            () => {
                this.validatePayload()
            }
        )
    }

    handleCatchupAvailable = (name, value) => {
        if (Array.isArray(value)) {
            value = value.map((item) => item.name)
        } else {
            value = JSON.parse(value)
            if (name === "catchupAvailable") {
                value = !!value ? { id: 1, name: "Yes" } : { id: 0, name: "No" }
            }
        }
        this.setState(
            {
                [name]: value
            },
            () => {
                this.validatePayload()
            }
        )
    }

    handleDateFieldList = (name, value, id) => {
        let { dates } = this.state
        const { epgTVList } = this.props
        dates.map((item, index) => {
            if (index === id) {
                item[name] = value
            }
            return item
        })
        let flag = 0
        dates.forEach((item, index) => {
            epgTVList &&
                epgTVList.forEach((element, idx) => {
                    if (item[name] != null) {
                        let timestamp
                        if (typeof item.date !== "number" || typeof (item[name] !== "number")) {
                            timestamp = this.getTimestamp(item.date, item[name])
                        }
                        if (timestamp >= element.startTime && timestamp < element.endTime) {
                            this.setState({
                                isDisable: true
                            })
                            flag = 1
                        } else if (timestamp === element.endTime) {
                            this.setState({
                                isDisable: false
                            })
                            flag = 0
                        }
                    }
                })
        })
        if (flag === 0) {
            this.setState({
                isDisable: false
            })
        }
        this.setState(
            {
                dates
            },
            () => this.validatePayload()
        )
    }

    handleTimestampValidaiton = () => {
        let { dates } = this.state
        const { epgTVList } = this.props
        let flag = 0
        dates.forEach((item, index) => {
            epgTVList &&
                epgTVList.forEach((element, idx) => {
                    if (item["startTime"] != null) {
                        let timestamp
                        if (typeof item["startTime"] !== "number") {
                            timestamp = this.getTimestamp(item.date, item["startTime"])
                        } else {
                            timestamp = item["startTime"]
                        }
                        if (timestamp >= element.startTime && timestamp <= element.endTime) {
                            flag = 1
                        }
                    }
                })
        })
        dates.forEach((item, index) => {
            epgTVList &&
                epgTVList.forEach((element, idx) => {
                    if (item["endTime"] != null) {
                        let timestamp
                        if (typeof item["endTime"] !== "number") {
                            timestamp = this.getTimestamp(item.date, item["endTime"])
                        } else {
                            timestamp = item["endTime"]
                        }
                        if (timestamp >= element.startTime && timestamp <= element.endTime) {
                            flag = 1
                        }
                    }
                })
        })

        dates.forEach((item, index) => {
            if (item.startTime >= item.endTime) {
                flag = 1
            }
        })

        return flag
    }

    addDateField = () => {
        this.setState((prevState) => ({
            dates: [...prevState.dates, { date: "", startTime: "", endTime: "" }]
        }))
    }

    removeDateField = (idx) => () => {
        const { dates } = this.state
        this.setState({
            dates: this.state.dates.filter((s, sidx) => idx !== sidx)
        })
        if (dates.length >= 2) {
            this.setState({
                addDate: true
            })
        }
    }

    handleExpand = () => {
        this.setState({
            expandDesc: !this.state.expandDesc
        })
    }

    handleSelectChannel = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    handleExpandCredit = () => {
        this.setState({
            expandCredit: !this.state.expandCredit
        })
    }

    imgUpload = async (data, id, name) => {
        data.append('type', 'EPG')
        const response = await this.props.uploadImage(data)
        if (response && response.status) {
            let imgUrl = response.data.path
            this.setState(
                {
                    coverImage: imgUrl
                },
                () => {
                    this.validatePayload()
                }
            )
        }
    }

    imgRemove = (name) => {
        let stateData = this.state.data
        stateData.addNewMovie[name] = ""
        this.setState({
            data: stateData
        })
    }

    getTimestamp = (date, time) => {
        /* to convert date to utc string */
        let convertedDateString = `${date} ${time}`
        // return new Date(convertedDateString).getTime();
        return moment
            .tz(convertedDateString, `${this.globalCurrentTimezoneString?.trim()}`)
            ?.utc()
            ?.valueOf()

        // if (typeof date === "number" || typeof time === "number") {
        //   var newDate = new Date(date);
        //   date =
        //     newDate.getFullYear() +
        //     "-" +
        //     (newDate.getMonth() + 1) +
        //     "-" +
        //     newDate.getDate();
        // }
        // const abd = date && date.split("-");
        // let day = abd && parseInt(abd[2]);
        // let month = abd && parseInt(abd[1]);
        // let year = abd && parseInt(abd[0]);
        // let newTime = time && time.split(":");
        // let hour = newTime && newTime[0];
        // let minute = newTime && newTime[1];
        // const utcDate =
        //   year &&
        //   Date.UTC(
        //     year,
        //     month - 1,
        //     day,
        //     hour,
        //     minute
        //   ); /* this converts into milliseconds only */
        // console.log("utcDate", utcDate, date, time);
        // return utcDate;
    }

    convertIntoTimestamp = (date) => {
        const updatedDate = date
        updatedDate.map((item, idx) => {
            //  this.getDateByTimezone(item);
            const actualDate = item.date
            item.date = new Date(actualDate).getTime()
            if (typeof item.startTime !== "number") {
                item.startTime = this.getTimestamp(actualDate, item.startTime)
            }
            if (typeof item.endTime !== "number") {
                item.endTime = this.getTimestamp(actualDate, item.endTime)
            }
            //  this.getDateByTimezone(item);
            return updatedDate
        })
        return updatedDate
    }

    handleOnSubmit = async () => {
        const {
            title,
            catchupAvailable,
            rating,
            actors,
            directors,
            producers,
            dates,
            coverImage,
            description,
            genre,
            language
        } = this.state
        const {
            isEdit,
            editableData: { uuid }
        } = this.props
        let dateObj = JSON.parse(JSON.stringify(dates))
        let payload = {
            actors: this.requiredFormat(actors),
            genre: this.requiredFormat(genre),
            language: this.requiredFormat(language),
            directors: this.requiredFormat(directors),
            producers: this.requiredFormat(producers),
            catchupAvailable: !!catchupAvailable.id,
            coverImage,
            dates: this.convertIntoTimestamp(dateObj),
            description,
            rating,
            title,
            ...(isEdit && { uuid })
        }
        if (
            !checkAllLanguageHasValue(title, this.languageObject) ||
            !checkAllLanguageHasValue(description, this.languageObject)
        ) {
            this.setState({
                emptyLangDataModal: true,
                finalPayload: payload
            })
        } else {
            await this.submitData(payload)
        }
    }

    modalCancelHandler = () => {
        this.setState({
            emptyLangDataModal: false
        })
    }

    submitData = async (payload) => {
        this.modalCancelHandler()
        const {
            addEpgShow,
            epgLiveTVdata,
            handleBack,
            isEdit,
            editableData,
            onDeleteShow,
            selectedTimezoneLabel
        } = this.props
        let epgLiveTVId = epgLiveTVdata.id
        const editableShowId = isEdit ? editableData.id : epgLiveTVId
        const globalCurrentTimezone = getGMTTimezone(selectedTimezoneLabel?.timeZone)

        let response = ""
        if (isEdit) {
            payload.id = editableShowId
            response = await addEpgShow(payload, epgLiveTVId, globalCurrentTimezone)
        } else {
            response = await addEpgShow(payload, epgLiveTVId, globalCurrentTimezone)
        }
        if (get(response, "status")) {
            toast.success(get(response, "message"))
            this.resetForm()
            handleBack()
            onDeleteShow()
        } else {
            toast.error(response)
        }
    }

    resetForm = () => {
        this.setState({
            title: "",
            selectChannel: "",
            startTime: "",
            endTime: "",
            date: "",
            description: "",
            catchupAvailable: "",
            rating: "",
            actors: "",
            directors: "",
            producers: ""
        })
    }

    onLanguageChangeListener = (value) => {
        this.setState({
            selectedLanguage: value
        })
    }

    getMultilingualValue = (value) => {
        const val = isMultiLingual() ? value?.[this.state?.selectedLanguage?.code] : value
        return val || ""
    }

    getMultiSelectDropdownValue = (optionsList, selectedOptions) => {
        return optionsList?.filter((item) => selectedOptions?.includes(item?.id))
    }

    getDateByTimezone(item) {
        let utcOffset = getUTCOffset(this.props?.selectedTimezoneLabel)
        let updatedDate = item
        updatedDate.startTime = this.getTimestamp(updatedDate.date, item.startTime)
        updatedDate.endTime = this.getTimestamp(updatedDate.date, item.endTime)
        updatedDate.date = new Date(updatedDate.date).getTime()
        let newDate = ""
        if (utcOffset > 0) {
            updatedDate.startTime = moment
                .utc(updatedDate?.startTime)
                .subtract(utcOffset, "minutes")
                .format("HH:mm")
            updatedDate.endTime = moment
                .utc(updatedDate?.endTime)
                .subtract(utcOffset, "minutes")
                .format("HH:mm")
            newDate = moment
                .utc(updatedDate?.date)
                .subtract(utcOffset, "minutes")
                .format("YYYY-MM-DD")
        } else if (utcOffset < 0) {
            updatedDate.startTime = moment
                .utc(updatedDate?.startTime)
                .add(utcOffset, "minutes")
                .format("HH:mm")
            updatedDate.endTime = moment
                .utc(updatedDate?.endTime)
                .add(utcOffset, "minutes")
                .format("HH:mm")
            newDate = moment.utc(updatedDate?.date).add(utcOffset, "minutes").format("YYYY-MM-DD")
        }
        updatedDate.date = newDate
        return updatedDate
    }

    coverImgRemove = (name) => {
        this.setState({
            coverImage: ""
        })
    }

    render() {
        const {
            dates,
            expandDesc,
            expandCredit,
            title,
            description,
            genreList,
            languageList1,
            directorList,
            producerList,
            actorsList,
            isDisable,
            rating,
            catchupAvailable,
            emptyLangDataModal,
            finalPayload
        } = this.state

        const { epgTVList, dateSelected, handleBack, isEdit, handleDeleteEpgShow } = this.props
        return (
            <div className="vd-epgaddMain">
                <div className="vr-content-holder">
                    <div className="add-epg-header-container">
                        <div className="add-epg-header">
                            <i className="icon-arrow-left1" onClick={() => handleBack()}></i>{" "}
                            <span>{TEXT_CONSTANTS?.ADD_SHOW?.toUpperCase()}</span>
                        </div>
                        <div className="content-multilingual">
                            {this.isMultiLingualAllowed && (
                                <LanguageCardList handleChange={this.onLanguageChangeListener} />
                            )}
                        </div>
                    </div>
                    <div className="add-epg-content">
                        <div className="add-form-field">
                            <ImageUplode
                                name={`cover_image`}
                                required
                                label={"Cover Image"}
                                id={"cover_image"}
                                placeholder="Add Cover Image "
                                value={`${this.state.coverImage}`}
                                uploadImage={this.imgUpload}
                                removeImage={(name) => this.coverImgRemove(name)}
                                disabled={!this.canUpdateStauts}
                                showPathPrefix={false}
                            />
                            <label>
                                {TEXT_CONSTANTS?.SHOW_TITLE} <span>*</span>
                            </label>
                            <Input
                                type="text"
                                changeHandler={this.handleChangeinput}
                                name="title"
                                id="title"
                                value={title}
                                // value={this.getMultilingualValue(title)}
                                disabled={!this.canUpdateStauts}
                            />
                            {/*   <label>
                {TEXT_CONSTANTS?.TIMEZONE} <span>*</span>
              </label>
              <Input
                type="text"
                changeHandler={this.handleChangeinput}
                name="timezone"
                id="timezone"
                value={timezone}
              /> */}
                            {/*       <label>
                {TEXT_CONSTANTS?.SELECT_CHANNEL} <span>*</span>
              </label>

              <Select
                options={epgLiveTVs.map((i) => {
                  return { id: i.name, name: i.name };
                })}
                placeholder={"-Select Channel-"}
                name="selectChannel"
                changeHandler={(name, value) =>
                  this.handleSelectChannel(name, value)
                }
                value={{ name: selectChannel }}
                isDisabled={!this.canUpdateStauts}
              /> */}
                            <ul className="add-newepg-date">
                                <li>
                                    <label className="epg-label">
                                        {TEXT_CONSTANTS?.AIRED_DATE}
                                        <span>*</span>
                                    </label>
                                    <label className="epg-label">
                                        {TEXT_CONSTANTS?.START_TIME}
                                        <span>*</span>
                                    </label>
                                    <label className="epg-label">
                                        {TEXT_CONSTANTS?.END_TIME} <span>*</span>
                                    </label>

                                    {this.canUpdateStauts && (
                                        <Button
                                            cName="btn-add"
                                            bValue={BUTTON_CONSTANTS?.PLUS}
                                            clickHandler={this.addDateField}
                                            disabled={dates.length > 2}
                                        />
                                    )}
                                </li>

                                {dates?.map((item, index) => (
                                    <li>
                                        <Input
                                            type="date"
                                            changeHandler={(name, value) =>
                                                this.handleDateFieldList(name, value, index)
                                            }
                                            name={`date`}
                                            id={index}
                                            value={dates[index].date}
                                            disabled={!this.canUpdateStauts}
                                        />
                                        <Input
                                            type="time"
                                            changeHandler={(name, value) =>
                                                this.handleDateFieldList(name, value, index)
                                            }
                                            name={`startTime`}
                                            id={index}
                                            value={dates[index].startTime}
                                            disabled={!this.canUpdateStauts}
                                            step={3600}
                                            minValue={"00:00"}
                                            maxValue={"23:59"}
                                            pattern={"[0-2][0-9]:[0-5][0-9]"}
                                        />
                                        <Input
                                            type="time"
                                            changeHandler={(name, value) =>
                                                this.handleDateFieldList(name, value, index)
                                            }
                                            name={`endTime`}
                                            id={index}
                                            value={dates[index].endTime}
                                            disabled={!this.canUpdateStauts}
                                            step={3600}
                                            minValue={"00:00"}
                                            maxValue={"23:59"}
                                            pattern={"[0-2][0-9]:[0-5][0-9]"}
                                        />

                                        {this.canUpdateStauts && (
                                            <Button
                                                cName="btn-remove"
                                                bValue="-"
                                                clickHandler={this.removeDateField(index)}
                                                disabled={dates.length < 2}
                                            />
                                        )}
                                    </li>
                                ))}
                            </ul>
                            <div className="add-epg-description">
                                <div className="epg-description">
                                    {!expandDesc ? (
                                        <span onClick={this.handleExpand}>+</span>
                                    ) : (
                                        <span onClick={this.handleExpand}>-</span>
                                    )}
                                    <span>{TEXT_CONSTANTS?.DESCRIPTION?.toUpperCase()}</span>
                                </div>
                                {expandDesc && (
                                    <>
                                        {" "}
                                        <label>
                                            {TEXT_CONSTANTS?.DESCRIPTION?.toUpperCase()}
                                            <span>*</span>
                                        </label>
                                        <TextArea
                                            row={100}
                                            col={10}
                                            changeHandler={this.handleChangeinput}
                                            name="description"
                                            value={description}
                                            // value={this.getMultilingualValue(description)}
                                            disabled={!this.canUpdateStauts}
                                        />
                                        <div className="add-genre-epg">
                                            <div className="add-genre">
                                                <label>
                                                    {TEXT_CONSTANTS?.GENRE?.toUpperCase()}
                                                </label>
                                                <div className="add-multiselect">
                                                    <MultiSelect
                                                        options={
                                                            genreList &&
                                                            Array.isArray(genreList) &&
                                                            genreList.map((i, idx) => {
                                                                return i
                                                            })
                                                        }
                                                        selectedOptions={this.getMultiSelectDropdownValue(
                                                            genreList,
                                                            this.state.genre
                                                        )}
                                                        placeholder={"-genre-"}
                                                        name="genre"
                                                        changeHandler={(name, value) =>
                                                            this.handleChange(name, value)
                                                        }
                                                        isDisabled={!this.canUpdateStauts}
                                                    />
                                                </div>
                                            </div>
                                            <div className="add-genre">
                                                <label>
                                                    {TEXT_CONSTANTS?.LANGUAGE?.toUpperCase()}
                                                </label>
                                                <div className="add-multiselect">
                                                    <MultiSelect
                                                        options={
                                                            languageList1 &&
                                                            Array.isArray(languageList1) &&
                                                            languageList1.map((i) => {
                                                                return i
                                                            })
                                                        }
                                                        selectedOptions={this.getMultiSelectDropdownValue(
                                                            languageList1,
                                                            this.state.language
                                                        )}
                                                        placeholder={"-language-"}
                                                        name="language"
                                                        changeHandler={(name, value) =>
                                                            this.handleChange(name, value)
                                                        }
                                                        isDisabled={!this.canUpdateStauts}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="add-rating">
                                            <div className="add-available">
                                                <label>{TEXT_CONSTANTS?.CATCHUP_AVAILABLE}</label>

                                                <Select
                                                    options={catchUpList}
                                                    placeholder={"-catchupAvailable-"}
                                                    name="catchupAvailable"
                                                    changeHandler={(name, value) =>
                                                        this.handleCatchupAvailable(name, value)
                                                    }
                                                    value={catchupAvailable}
                                                    isDisabled={!this.canUpdateStauts}
                                                />
                                            </div>
                                            <div className="add-available">
                                                <label>
                                                    {TEXT_CONSTANTS?.RATING?.toUpperCase()}
                                                </label>
                                                <Select
                                                    options={ratingList.map((i) => {
                                                        return { id: i, name: i }
                                                    })}
                                                    placeholder={"-rating-"}
                                                    name="rating"
                                                    changeHandler={(name, value) =>
                                                        this.handleCatchupAvailable(name, value)
                                                    }
                                                    value={{ name: rating }}
                                                    isDisabled={!this.canUpdateStauts}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>

                            <div className="add-epg-description">
                                <div className="epg-description">
                                    {!expandCredit ? (
                                        <span onClick={this.handleExpandCredit}>+</span>
                                    ) : (
                                        <span onClick={this.handleExpandCredit}>-</span>
                                    )}
                                    <span>{TEXT_CONSTANTS?.CREDIT?.toUpperCase()}</span>
                                </div>
                                {expandCredit && (
                                    <>
                                        <div className="add-starring">
                                            <div>
                                                <label>
                                                    {TEXT_CONSTANTS?.STARRING?.toUpperCase()}
                                                </label>
                                                <div className="add-multiselect">
                                                    <MultiSelect
                                                        options={
                                                            actorsList &&
                                                            Array.isArray(actorsList) &&
                                                            actorsList.map((i) => {
                                                                return i
                                                            })
                                                        }
                                                        selectedOptions={this.getMultiSelectDropdownValue(
                                                            actorsList,
                                                            this.state.actors
                                                        )}
                                                        placeholder={"-actors-"}
                                                        name="actors"
                                                        changeHandler={(name, value) =>
                                                            this.handleChange(name, value)
                                                        }
                                                        isDisabled={!this.canUpdateStauts}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="add-rating">
                                            <div className="add-available">
                                                <label>
                                                    {TEXT_CONSTANTS?.DIRECTOR?.toUpperCase()}
                                                </label>
                                                <div className="add-multiselect">
                                                    <MultiSelect
                                                        options={
                                                            directorList &&
                                                            Array.isArray(directorList) &&
                                                            directorList.map((i) => {
                                                                return i
                                                            })
                                                        }
                                                        selectedOptions={this.getMultiSelectDropdownValue(
                                                            directorList,
                                                            this.state.directors
                                                        )}
                                                        placeholder={"-directors-"}
                                                        name="directors"
                                                        changeHandler={(name, value) =>
                                                            this.handleChange(name, value)
                                                        }
                                                        isDisabled={!this.canUpdateStauts}
                                                    />
                                                </div>
                                            </div>
                                            <div className="add-available">
                                                <label>
                                                    {TEXT_CONSTANTS?.PRODUCER?.toUpperCase()}
                                                </label>
                                                <div className="add-multiselect">
                                                    <MultiSelect
                                                        options={
                                                            producerList &&
                                                            Array.isArray(producerList) &&
                                                            producerList.map((i) => {
                                                                return i
                                                            })
                                                        }
                                                        selectedOptions={this.getMultiSelectDropdownValue(
                                                            producerList,
                                                            this.state.producers
                                                        )}
                                                        placeholder={"-producers-"}
                                                        name="producers"
                                                        changeHandler={(name, value) =>
                                                            this.handleChange(name, value)
                                                        }
                                                        isDisabled={!this.canUpdateStauts}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>

                            <div className="add-btn-footer">
                                {this.canUpdateStauts && (
                                    <Button
                                        bValue={
                                            isEdit
                                                ? BUTTON_CONSTANTS?.UPDATE?.toUpperCase()
                                                : BUTTON_CONSTANTS?.SAVE?.toUpperCase()
                                        }
                                        disabled={isEdit ? "" : isDisable}
                                        clickHandler={this.handleOnSubmit}
                                        cName="btn primary-btn"
                                    />
                                )}
                                <Button
                                    bValue={BUTTON_CONSTANTS?.CANCEL?.toUpperCase()}
                                    clickHandler={() => handleBack()}
                                    cName="btn cancel-btn"
                                />
                            </div>
                        </div>
                        <div className="add-list-field">
                            <TvShowEpg
                                dateSelected={dateSelected}
                                epgTVList={epgTVList}
                                handleDeleteEpgShow={handleDeleteEpgShow}
                                activeChannelId={this.props.channelId}
                            />
                        </div>
                    </div>
                </div>
                {emptyLangDataModal && (
                    <LanguageDataConfirmationModal
                        isModalOpen={emptyLangDataModal}
                        submitHandler={() => this.submitData(finalPayload)}
                        cancelHandler={this.modalCancelHandler}
                    ></LanguageDataConfirmationModal>
                )}
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        epgLiveTV: get(state.epgLiveTV, "epgLiveTV", []),
        epgOption: get(state.epgOption, "epgOption", []),
        epgTVshow: get(state.epgTVshow, "epgTVshow", []),
        selectedTimezoneLabel: state?.timezone?.selectedTimezone
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                uploadImage,
                addEpgShow,
                fetchAddEpgOptionData,
                fetchEpgContentLiveTV,
                fetchEpgTvShow
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewEpg)
