import React, { useMemo } from "react"
import { checkIfUserHasAccess, getUserPermissionsList } from "./users"
import Unauthorized from "../components/common/Unauthorized"
import { shallowEqual, useSelector } from "react-redux"

export function AuthourizedRoute(props) {
    const Component = props?.routes.component,
        permissions = props?.routes?.permissions
    const userRoles = useSelector((state) => state?.auth?.user?.roles, shallowEqual)
    const userPermissions = getUserPermissionsList(userRoles || [])

    /** Check if user is authorized to access the page */
    const isUserAuthorized = useMemo(() => {
        return checkIfUserHasAccess(permissions)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permissions, userPermissions])

    /** Block Page Access for unAuthorized User */
    if (!isUserAuthorized) {
        return <Unauthorized />
    }

    return <Component {...props} routes={props?.routes} />
}
