import React from "react"
import "./style.scss"
import accessDeniedDMImg from "../../../assets/images/accessDenied-DM.svg"
import accessDeniedLMImg from "../../../assets/images/accessDenied-LM.svg"

import { useSelector } from "react-redux"

export default function PageNotFound(props) {
    const isDarkMode = useSelector((state) => state?.theme?.darkMode)

    return (
        <div className="vr-content-holder page-not-found-conatiner">
            <img
                src={isDarkMode ? accessDeniedDMImg : accessDeniedLMImg}
                alt="Access Denied"
                className="access-denied-image"
            ></img>
            <h3 className="not-found-access-heading not-found-access-heading-color">
                {props.heading}
            </h3>
            {/*   <h3 className="not-found-access-heading-color">{TEXT_LABEL?.errorMsg}</h3>
      <p className="desc-color">{TEXT_LABEL?.errorDesc1}</p>
      <p className="desc-color">{TEXT_LABEL?.errorDesc2}</p> */}
        </div>
    )
}
