import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import Chart from "react-apexcharts"
import "./styles.scss"

/* admin work */
class BarChart extends Component {
    constructor(props) {
        super(props)
        this.state = {
            series: props.plotValues,
            options: {
                // stroke: {
                //   show: true,
                //   curve: "smooth",
                //   lineCap: "butt",
                //   colors: undefined,
                //   width: "2",
                //   dashArray: 0,
                // },
                chart: {
                    height: 260,
                    width: 260,
                    type: "radialBar"
                },
                colors: ["#F0878A", "#2887F2", "#5449d4", "#17d0fc", "#f2dd96", "#4ed964"],
                plotOptions: {
                    radialBar: {
                        // startAngle: -360,
                        borderRadius: 20,
                        width: 20,
                        //  endAngle: 0,
                        dataLabels: {
                            name: {
                                fontSize: "22px"
                            },
                            value: {
                                fontSize: "16px"
                            },
                            total: {
                                show: false,
                                label: "Total"
                                // formatter: function (w) {
                                //   // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                //   return 340000;
                                // },
                            }
                        },
                        track: {
                            show: false,
                            startAngle: undefined,
                            endAngle: undefined,
                            background: "#f2f2f2",
                            strokeWidth: "100%",
                            opacity: 1,
                            margin: 5,
                            dropShadow: {
                                enabled: false,
                                top: 0,
                                left: 0,
                                blur: 3,
                                opacity: 0.5
                            }
                        },
                        hollow: {
                            margin: 5,
                            size: "30%"
                        }
                    }
                },
                labels: props.labels,
                stroke: {
                    lineCap: "round"
                }
            }
        }
    }

    componentDidMount() {}
    render() {
        return (
            <Chart
                options={this.state.options}
                series={this.state.series}
                type="radialBar"
                width={260}
                height={260}
            />
        )
    }
}
const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({}, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BarChart)
