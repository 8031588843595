import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import { isEmpty } from "lodash"

import Input from "../../../common/Input/index"
import Button from "../../../common/Button/index"
import { showNavBar } from "../../../../containers/AdminHome/APIs/action"
import TenantPersonalDetails from "../TenantPersonalDetails"
import {
    createTenant,
    getTenantsById,
    isExists,
    isExistSubdomain,
    isExistEmailMobile
} from "../APIs/action"
import BrandTheme from "../BrandTheme"
import ArrowLeft from "../../../../assets/images/arrow-alt-circle-left.svg"

import "./style.scss"
import {
    BUTTON_CONSTANTS,
    ERROR_MESSAGES,
    PERMISSIONS,
    REGEX,
    TEXT_CONSTANTS
} from "../../../../utils/constants"
import { debounce } from "../../../../utils/common"
import { AuthWrapper } from "../../../common/AuthWrapper"
import { checkIfUserHasAccess } from "../../../../utils/users"

/* admin work */
class AddEditTenant extends Component {
    constructor(props) {
        super(props)
        this.state = {
            stepCount: 1,
            tenantFields: {
                domain: "videoready.tv"
            },
            activeDots: { 1: "black-marker" },
            isPreviousDisable: true,
            isNextDisable: true,
            isCreateDisable: true,
            isEdit: false,
            isTenantExist: false,
            isExistingSubdomain: false,
            isExistingEmail: false,
            isExistingMobile: false,
            error: {}
        }
        this.canUpdateStatus = checkIfUserHasAccess([PERMISSIONS.ADD_TENANT_EDIT])
    }

    async componentDidMount() {
        const { getTenantsById, history } = this.props
        if (history?.location?.search) {
            let tenantId = history?.location?.search.split("=")[1]
            let response = await getTenantsById(tenantId)
            if (response && response.status && response?.data) {
                this.setState({
                    tenantFields: response?.data,
                    isNextDisable: false,
                    isEdit: true,
                    existingTenant: response?.data["tenantCode"],
                    existingSubdomain: response?.data["subDomain"],
                    existingEmail: response?.data["tenantAdminEmail"],
                    existingMobile: response?.data["tenantAdminContact"]
                })
            }
        }
    }

    handleSubmit = () => {
        const { stepCount, isPreviousDisable } = this.state

        if (stepCount !== 3) {
            this.changeStep()

            if (isPreviousDisable) {
                this.setState({
                    isPreviousDisable: false
                })
            }
        }
        // history.push("/dashboard");
        // showNavBar(false);
    }
    handleClose = () => {
        const { history } = this.props
        history.goBack()
    }
    handleChange = async (name, value) => {
        let { tenantFields, existingTenant } = this.state
        if (name === "tenantCode" || name === "subDomain") {
            value = value.toLowerCase()
        }
        let inputFields = tenantFields
        inputFields[name] = value

        this.setState(
            {
                tenantFields: inputFields
            },
            async () => {
                if (name === "tenantCode" && !isEmpty(value)) {
                    if (existingTenant && existingTenant === value) {
                        return
                    }
                    const { isExists } = this.props
                    let response = await isExists(value)
                    if (response && response.status && !response.data) {
                        this.setState(
                            {
                                isTenantExist: false
                            },
                            () => {
                                this.checkValidation()
                            }
                        )
                    } else {
                        this.setState({
                            isNextDisable: true,
                            isTenantExist: true
                        })
                        toast.error(ERROR_MESSAGES.TENANT_NICKNAME_EXISTS)
                    }
                } else if (name === "subDomain" && !isEmpty(value)) {
                    this.checkSubdomainValue(value)
                } else if (name === "tenantAdminEmail") {
                    this.validateEmail(tenantFields["tenantAdminEmail"])
                } else if (name === "tenantAdminContact" && !isEmpty(value)) {
                    this.checkMobile(value)
                } else this.checkValidation()
            }
        )
    }

    checkEmail = async (value) => {
        const { isExistEmailMobile } = this.props
        const { existingEmail } = this.state
        if (existingEmail && existingEmail === value) {
            this.setState({
                isExistingEmail: false,
                isCreateDisable: false
            })
            return
        }
        let response = await isExistEmailMobile({ email: value })
        if (response && !response.data) {
            this.setState(
                {
                    isExistingEmail: false
                },
                () => {
                    this.checkValidation()
                }
            )
        } else {
            this.setState({
                isCreateDisable: true,
                isExistingEmail: true
            })
            toast.error(ERROR_MESSAGES.TENANT_EMAIL_EXISTS)
        }
    }

    checkMobile = async (value) => {
        const { isExistEmailMobile } = this.props
        const { existingMobile } = this.state
        if (existingMobile && existingMobile === value) {
            this.setState({
                isExistingMobile: false,
                isCreateDisable: false
            })
            return
        }

        if (value?.trim()?.length < 10) {
            this.setState({
                isCreateDisable: true,
                error: {
                    ...this.state.error,
                    invalidMobile: ERROR_MESSAGES.ENTER_VALID_MOBILE
                }
            })
            return
        } else {
            let errorObj = { ...this.state.error }
            delete errorObj?.invalidMobile
            this.setState({
                error: errorObj
            })
        }

        let response = await isExistEmailMobile({ mobile: value })
        if (response && !response.data) {
            this.setState(
                {
                    isExistingMobile: false
                },
                () => {
                    this.checkValidation()
                }
            )
        } else {
            this.setState({
                isCreateDisable: true,
                isExistingMobile: true
            })
            toast.error(ERROR_MESSAGES.TENANT_MOBILE_EXISTS)
        }
    }

    checkSubdomainValue = debounce((value) => {
        this.checkForSubdomain(value)
    }, 500)

    checkForSubdomain = async (value) => {
        const { isExistSubdomain } = this.props
        const { existingSubdomain } = this.state
        if (existingSubdomain && existingSubdomain === value) {
            return
        }
        if (!REGEX?.SUBDOMAIN_REGEX.test(value)) {
            this.setState({
                error: {
                    ...this.state.error,
                    isSubdomain: ERROR_MESSAGES?.ENTER_VALID_SUBDOMAIN
                },
                isNextDisable: true
            })
            return
        } else {
            let errorObj = { ...this.state.error }
            delete errorObj?.isSubdomain
            this.setState({
                error: errorObj
            })
        }
        let response = await isExistSubdomain(value)
        if (response && !response.data) {
            this.setState(
                {
                    isExistingSubdomain: false
                },
                () => {
                    this.checkValidation()
                }
            )
        } else {
            this.setState({
                isNextDisable: true,
                isExistingSubdomain: true
            })
            toast.error(ERROR_MESSAGES.TENANT_SUBDOMAIN_EXISTS)
        }
    }

    changeStep = (count) => {
        /*   this.setState({
      stepCount: count,
    }); */
        let { stepCount, activeDots } = this.state
        let activeMarkers = activeDots
        this.setState(
            {
                stepCount: stepCount + 1
            },
            () => {
                let { stepCount } = this.state
                activeMarkers[stepCount - 1] = "green-marker"
                activeMarkers[stepCount] = "black-marker"
                this.checkValidation()
                this.setState({
                    activeDots: activeMarkers
                })
            }
        )
    }

    validateEmail = (value) => {
        if (isEmpty(value)) {
            this.setState({
                isCreateDisable: true
            })
            return
        }
        if (!isEmpty(value)) {
            let email = value ? value : ""
            const emailRegex = REGEX.EMAIL_REGEX
            if (!emailRegex.test(email)) {
                this.setState({
                    error: {
                        ...this.state.error,
                        isEmail: ERROR_MESSAGES?.ENTER_VALID_EMAIL
                    },
                    isCreateDisable: true
                })
            } else {
                let errorObj = { ...this.state.error }
                delete errorObj?.isEmail
                this.setState(
                    {
                        error: errorObj
                    },
                    () => {
                        this.checkEmail(value)
                    }
                )
            }
        }
    }

    checkValidation = (name, value) => {
        const {
            stepCount,
            tenantFields,
            isTenantExist,
            isExistingSubdomain,
            isExistingEmail,
            isExistingMobile
        } = this.state
        if (isTenantExist || isExistingSubdomain) {
            this.setState({
                isNextDisable: true
            })
            return
        }

        if (isExistingEmail || isExistingMobile) {
            this.setState({
                isCreateDisable: true
            })
            return
        }
        if (stepCount === 1) {
            if (
                isEmpty(tenantFields["appName"]) ||
                isEmpty(tenantFields["domain"]) ||
                isEmpty(tenantFields["subDomain"]) ||
                isEmpty(tenantFields["tenantCode"])
            ) {
                this.setState({
                    isNextDisable: true
                })
            } else {
                this.setState({
                    isNextDisable: false
                })
            }
        } else if (stepCount === 2) {
            if (isEmpty(tenantFields["logoFilePath"])) {
                this.setState({
                    isNextDisable: true
                })
            } else {
                this.setState({
                    isNextDisable: false
                })
            }
        } else if (stepCount === 3) {
            if (
                isEmpty(tenantFields["tenantAdminName"]) ||
                isEmpty(tenantFields["tenantAdminContact"]) ||
                isEmpty(tenantFields["tenantAdminEmail"]) ||
                isEmpty(tenantFields["countries"])
            ) {
                this.setState({
                    isCreateDisable: true
                })
            } else {
                this.setState({
                    isCreateDisable: false
                })
            }
        }
    }

    goBackToStep = () => {
        const { stepCount, activeDots } = this.state
        let activeMarkers = activeDots
        if (stepCount === 2) {
            this.setState({
                isPreviousDisable: true,
                isNextDisable: false
            })
        }
        this.setState(
            {
                stepCount: stepCount - 1
            },
            () => {
                let { stepCount } = this.state
                activeMarkers[stepCount] = "black-marker"
                activeMarkers[stepCount + 1] = ""
                this.setState({
                    activeDots: activeMarkers
                })
            }
        )
    }

    goToDashboard = () => {
        const { history } = this.props
        history.push("/dashboard")
    }

    createTenant = async () => {
        const { tenantFields, isEdit, error } = this.state
        const { createTenant, history } = this.props
        if (!isEmpty(error)) {
            return
        }
        let response = await createTenant(tenantFields)
        if (response && response.status) {
            isEdit
                ? toast.success(TEXT_CONSTANTS?.TENANT_UPADTED_SUCCESS)
                : toast.success(TEXT_CONSTANTS?.TENANT_ADDED_SUCCESS)
            history.push("/tenant")
        } else {
            toast.error(response?.message)
        }
    }

    imgRemove = (name, item) => {
        this.changeHandler(name, "", false, item)
    }

    render() {
        const {
            stepCount,
            tenantFields,
            activeDots,
            isPreviousDisable,
            isNextDisable,
            isCreateDisable,
            isEdit,
            isExistingSubdomain,
            isTenantExist,
            isExistingEmail,
            isExistingMobile,
            error
        } = this.state

        return (
            <div className="vr-content-holder">
                <div className="tenant-edit-container">
                    <AuthWrapper
                        permissions={[PERMISSIONS?.DASHBOARD_EDIT, PERMISSIONS?.DASHBOARD_VIEW]}
                    >
                        <p onClick={() => this.goToDashboard()} className="back-btn">
                            <img src={ArrowLeft} alt="left arrow"></img>
                            <span className="button-text">{TEXT_CONSTANTS?.BACK_TO_DASHBOARD}</span>
                        </p>
                    </AuthWrapper>
                    <p className="heading">{TEXT_CONSTANTS?.ADD_NEW_TENANT?.toUpperCase()}</p>
                    <p className="sub-heading">{TEXT_CONSTANTS?.FILL_TENANT_DETAILS}</p>
                    <div className="timeline">
                        <div className={`marker marker1 ${activeDots[1] ? activeDots[1] : ""}`}>
                            <p className={` ${activeDots[1] ? "active-text" : ""}`}>
                                {TEXT_CONSTANTS?.TENANT_BRAND_DETAILS}
                            </p>
                        </div>
                        <div className={`marker marker2 ${activeDots[2] ? activeDots[2] : ""}`}>
                            <p className={` ${activeDots[2] ? "active-text" : ""}`}>
                                {TEXT_CONSTANTS?.BRAND_THEME}
                            </p>
                        </div>
                        <div className={`marker marker3 ${activeDots[3] ? activeDots[3] : ""}`}>
                            <p className={` ${activeDots[3] ? "active-text" : ""}`}>
                                {TEXT_CONSTANTS?.TENANT_PERSONAL_DETAILS}
                            </p>
                        </div>
                    </div>
                    {stepCount === 1 && (
                        <div className="step1-container">
                            <Input
                                className="inputField"
                                type="text"
                                changeHandler={this.handleChange}
                                name="appName"
                                disabled={isEdit || !this.canUpdateStatus}
                                id="customName"
                                maxLength={25}
                                labelText="APP NAME"
                                value={tenantFields["appName"]}
                                required
                            />
                            <Input
                                className="inputField"
                                type="text"
                                changeHandler={this.handleChange}
                                name="domain"
                                disabled={true}
                                id="customName"
                                maxLength={25}
                                labelText="DOMAIN"
                                value={tenantFields["domain"]}
                                required
                            />
                            <Input
                                className="inputField"
                                type="text"
                                changeHandler={this.handleChange}
                                name="subDomain"
                                errMsg={
                                    error?.isSubdomain
                                        ? error?.isSubdomain
                                        : isExistingSubdomain && !isEmpty(tenantFields["subDomain"])
                                        ? "Please enter unique subdomain."
                                        : null
                                }
                                disabled={isEdit || !this.canUpdateStatus}
                                id="customName"
                                maxLength={25}
                                labelText="SUBDOMAIN"
                                value={tenantFields["subDomain"]}
                                required
                            />
                            <Input
                                className="inputField"
                                type="text"
                                changeHandler={this.handleChange}
                                name="tenantCode"
                                id="customName"
                                maxLength={25}
                                errMsg={
                                    isTenantExist && !isEmpty(tenantFields["tenantCode"])
                                        ? ERROR_MESSAGES?.UNIQUE_TENANT_NAME
                                        : null
                                }
                                disabled={isEdit || !this.canUpdateStatus}
                                labelText="NICK NAME OF TENANT"
                                value={tenantFields["tenantCode"]}
                                required
                            />
                        </div>
                    )}
                    {stepCount === 2 && (
                        <BrandTheme tenantFields={tenantFields} handleChange={this.handleChange} />
                    )}
                    {stepCount === 3 && (
                        <TenantPersonalDetails
                            tenantFields={tenantFields}
                            error={this.state.error}
                            isEdit={isEdit}
                            isExistingEmail={isExistingEmail}
                            isExistingMobile={isExistingMobile}
                            handleChange={this.handleChange}
                        />
                    )}
                    <div className="user-btn">
                        <Button
                            bValue={BUTTON_CONSTANTS?.PREVIOUS}
                            disabled={isPreviousDisable}
                            clickHandler={this.goBackToStep}
                            cName={`btn primary-btn ${
                                isPreviousDisable ? "prev-disabled" : "prev-btn"
                            }`}
                        />
                        {stepCount !== 3 && (
                            <Button
                                bValue={BUTTON_CONSTANTS?.NEXT}
                                disabled={isNextDisable || error?.isSubdomain}
                                clickHandler={this.handleSubmit}
                                cName={`btn primary-btn ${
                                    isNextDisable || error?.isSubdomain ? "prev-disabled" : ""
                                }`}
                            />
                        )}
                        {stepCount === 3 && this.canUpdateStatus && (
                            <Button
                                bValue={
                                    isEdit
                                        ? BUTTON_CONSTANTS?.UPDATE?.toUpperCase()
                                        : BUTTON_CONSTANTS?.CREATE?.toUpperCase()
                                }
                                clickHandler={this.createTenant}
                                disabled={isCreateDisable}
                                cName={`btn primary-btn ${
                                    isCreateDisable || !isEmpty(this.state.error)
                                        ? "prev-disabled"
                                        : ""
                                }`}
                            />
                        )}
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                showNavBar,
                createTenant,
                getTenantsById,
                isExists,
                isExistSubdomain,
                isExistEmailMobile
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEditTenant)
