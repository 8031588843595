import React from "react"
import Checkbox from "../Checkbox"
import "./style.scss"

function VideoSelector(props) {
    const {
        label,
        options,
        required,
        changeHandler,
        isChecked,
        cName,
        disabled
    } = props

    return (
        <div className={`video-format-container ${cName}`}>
            {label && (
                <label className="labelText" htmlFor={label}>
                    {label}
                    <span
                        className="required"
                        style={{
                            display: required ? "inline" : "none",
                            // color: "#1FB1FF",
                            marginLeft: "5px"
                        }}>
                        *
                    </span>
                </label>
            )}
            <div className="checkbox-container">
                <ul className="checkbox-list">
                    {options &&
                        options.map((item, index) => {
                            return (
                                <li>
                                    <Checkbox
                                        cName="checklist"
                                        labelText={item.name}
                                        id={item.id}
                                        name={item.name}
                                        changeHandler={(id) => {
                                            changeHandler(id)
                                        }}
                                        isChecked={
                                            isChecked &&
                                            isChecked.includes(item.id)
                                        }
                                        disabled={disabled}
                                    />
                                </li>
                            )
                        })}
                </ul>
            </div>
        </div>
    )
}

export default VideoSelector
