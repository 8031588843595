import React, { Component } from "react"
import ContentEntity from "../ContentEntity"
import Button from "../../common/Button"

import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { uploadImage } from "../api/action"
import ContentEnrichment from "../../ContentNav/contentAddForm/ContentEnrichment"
import viewIcon from "../../../assets/images/eyeIcon.svg"
import ContentEnrichmentView from "../../ContentNav/contentAddForm/ContentEnrichmentView"
import { formatAndAssignValue, isFieldMultilingual, getLanguages } from "../../../utils/common"
import { BUTTON_CONSTANTS, PERMISSIONS, TEXT_CONSTANTS } from "../../../utils/constants"
import { AuthWrapper } from "../../common/AuthWrapper"
import Search from "../../common/Search"
import CustomModal from "../../common/Modal"
import Checkbox from "../../common/Checkbox"
import SelectedButtons from "../../common/SelectedButtons"
import { isEmpty } from "lodash"
import Input from "../../common/Input"
import { toast } from "react-toastify"
import { DEFAULT_SELECTED_LANGUAGE_CODE } from "../../ContentNav/api/constants"

// export default class AddEpisode extends Component {
class AddEpisode extends Component {
    constructor(props) {
        super(props)
        this.state = {
            episodeFormData: null,
            data: props.preFillData,
            selectedSubtitles: [],
            finalSelectedSubtitles: [],
            showSubtitlePopup: false,
            isSubtitleExpand: true,
            subTitleArray: []
        }
    }

    componentDidMount() {
        setTimeout(() => {
            document.getElementsByClassName("episode-form-container") &&
                document
                    .getElementsByClassName("episode-form-container")
                    [this.props.seasonIndex].scrollTo(0, 0)
        }, 0)

        const { sub_titles } = this.state?.data
        if (!isEmpty(sub_titles)) {
            this.setState({
                selectedSubtitles: sub_titles,
                finalSelectedSubtitles: sub_titles
            })
        }
        setTimeout(() => {
            document.getElementsByClassName("episode-form-container") &&
                document
                    .getElementsByClassName("episode-form-container")
                    [this.props.seasonIndex].scrollTo(0, 0)
        }, 0)
    }

    validateSubtitles = () => {
        const { selectedSubtitles } = this.state
        let isValidated = true
        if (!isEmpty(selectedSubtitles)) {
            selectedSubtitles.forEach((value, index) => {
                if (isEmpty(value?.uri)) {
                    isValidated = false
                }
            })
        }
        return isValidated
    }

    handleChange = (name, value, id) => {
        let stateData = this.state.data
        const isMulti = isFieldMultilingual(name, "episode", this.props.multilingualKeys)

        const { code } = this.props.selectedLanguage
        if (name === "genre" || name === "language") {
            let newOptions = value.map((item) => {
                return { id: item.id }
            })
            stateData[name] = newOptions
        } else if (name === "rating") {
            stateData[name] = { id: value, name: value }
        } else if (name === "age_rating" || name === "episode_rating") {
            stateData[name] = formatAndAssignValue(
                true,
                DEFAULT_SELECTED_LANGUAGE_CODE,
                stateData[name],
                value
            )
        } else if (name === "year" || name === "episodeAirDate") {
            stateData[name] = value
        } else if (name === "videoFormat") {
            if (value) {
                if (!stateData[name]) {
                    stateData[name] = []
                }
                stateData[name].push({
                    id: id,
                    name: id
                })
            } else {
                let newVideoFormats = stateData[name].filter((element) => {
                    return element.id !== id
                })
                stateData[name] = newVideoFormats
            }
        } else if (
            name?.toLowerCase() === "drm" ||
            name === "offlineDownload" ||
            name === "internationalContent" ||
            name === "isPublish"
            // ||
            // name === "rating"
        ) {
            stateData[name] = value === "1" ? true : false
        } else {
            // stateData[name] = value;
            stateData[name] = formatAndAssignValue(isMulti, code, stateData[name], value)
        }
        this.setState({
            data: stateData
        })
    }

    handleSaveEpisode = () => {
        const { selectedSubtitles, data } = this.state
        data["sub_titles"] = !isEmpty(selectedSubtitles) ? selectedSubtitles : []
        this.setState(
            {
                data
            },
            () => {
                let isValidated = this.validateSubtitles()
                if (!isValidated) {
                    toast.error("Invalid/Empty subtitles")
                    return
                }
                this.props.handleSaveEpisode(
                    this.props.currentEdit,
                    this.props.index,
                    this.state.data
                )
            }
        )
    }

    handleSave = () => {}
    imgUpload = async (data, id, name) => {
        const response = await this.props.uploadImage(data)
        if (response && response.status) {
            let imgUrl = response.data.path
            this.handleChange(name, imgUrl, id)
        }
    }

    imgRemove = (name) => {
        let stateData = this.state.data
        stateData[name] = ""
        this.setState({
            data: stateData
        })
    }

    renderEnrichmentAndMetaDataBtn = () => {
        const { currentEdit, index: episodeIndex, seasonIndex } = this.props
        if (currentEdit) {
            return (
                <AuthWrapper permissions={[PERMISSIONS?.CONTENT_EDIT]}>
                    <Button
                        cName={"btn border-btn"}
                        bType={"submit"}
                        bValue={BUTTON_CONSTANTS?.CONTENT_ENRICHMENT}
                        bIcon={"icon-left-arrow"}
                        clickHandler={(e) => {
                            this.props.contentEnrichmentBtnHandler(true, episodeIndex, seasonIndex)
                        }}
                    />
                    <button
                        onClick={() => this.props.viewData(episodeIndex, seasonIndex)}
                        className="view-icon"
                    >
                        <img alt="view-icon" src={viewIcon} />
                    </button>
                </AuthWrapper>
            )
        }
        return null
    }
    addSubtitle = () => {
        this.setState({
            showSubtitlePopup: true,
            searchSubtitleList: this.state?.subTitleArray
        })
    }

    handleSearch = (name, value) => {
        const { subTitleArray } = this.state
        let searchSubtitleList = []

        subTitleArray.map((item) => {
            if (value === "" || value.length === 0) {
                searchSubtitleList = subTitleArray
            } else if (item.title.toLowerCase().includes(value.toLowerCase())) {
                searchSubtitleList.push(item)
            }
            return item
        })

        this.setState({
            searchSubtitleList
        })
    }

    getSubtitlePopup = () => {
        let languages = getLanguages()
        let { searchSubtitleList, subTitleArray } = this.state
        let subTitle = {}
        let { selectedSubtitles } = this.state
        let selectedSubtitleLanguages = selectedSubtitles.map((value, index) => {
            return value.language
        })

        if (isEmpty(searchSubtitleList) && isEmpty(subTitleArray)) {
            languages.forEach((value, index) => {
                subTitle = {
                    title: value?.name,
                    language: value?.code,
                    type: "application/x-subrip"
                }
                subTitleArray.push(subTitle)
            })
            this.setState({
                subTitleArray
            })
        } else {
            subTitleArray = !isEmpty(searchSubtitleList) ? searchSubtitleList : subTitleArray
        }

        return (
            <div className="subtitle-popup-container">
                <p className="title">{TEXT_CONSTANTS?.SELECT_SUBTITLE_LANGUAGE}</p>
                <div className="pl-search">
                    {/*       <span className="icon-search"></span>
          <Input
            type="text"
            changeHandler={this.handleSearch}
            name="searchPermission"
            cName="searchInput"
          /> */}
                    <Search
                        name="search"
                        changeHandler={this.handleSearch}
                        placeholder="Search Language"
                        // value={searchValue}
                    />
                </div>
                <div className="language-list">
                    {subTitleArray.map((item, index) => {
                        return (
                            <Checkbox
                                cName="featured-checkBox"
                                labelText={item.title} //language name
                                id={item.language} //lanuage code
                                name={item.title}
                                isChecked={selectedSubtitleLanguages.includes(item.language)}
                                changeHandler={this.subtitleLanguageChange}
                            />
                        )
                    })}
                </div>
                <p className="horizontal-line"></p>
                <SelectedButtons
                    list={selectedSubtitles}
                    changeHandler={this.subtitleLanguageChange}
                />
                <p className="horizontal-line"></p>
            </div>
        )
    }

    subtitleLanguageChange = (name, checked, id) => {
        console.log("name", name, checked)
        let { selectedSubtitles } = this.state
        if (checked) {
            selectedSubtitles.push({
                title: name,
                language: id, //:"en"
                type: "application/x-subrip",
                uri: ""
            })
        } else {
            selectedSubtitles = selectedSubtitles.filter((value, index) => {
                return value.language !== id
            })
        }
        this.setState({
            selectedSubtitles
        })
    }

    handleSubtitleChange = (name, value, index) => {
        console.log("n", name, value, index)
        let { selectedSubtitles } = this.state
        selectedSubtitles[index].uri = value
        this.setState({
            selectedSubtitles
        })
    }

    submitModalHandler = () => {
        this.setState({
            showSubtitlePopup: !this.state?.showSubtitlePopup,
            finalSelectedSubtitles: this.state?.selectedSubtitles
        })
    }

    handleSubtitleExpand = () => {
        this.setState({
            isSubtitleExpand: !this.state?.isSubtitleExpand
        })
    }

    renderSubtitle = () => {
        const { finalSelectedSubtitles, isSubtitleExpand } = this.state
        return (
            <div className="subtitle-listing-container">
                <div className="group-header">
                    <div className="group-title">
                        <div className="col-large content-form-title">
                            {/*  <i
                className={!isSubtitleExpand ? "icon-plus" : "icon-minus"}
                onClick={() => {
                  this.handleSubtitleExpand();
                }}
              ></i> */}
                            <label className="content-title">{"SUBTITLE"}</label>
                        </div>
                    </div>
                </div>
                {isSubtitleExpand && (
                    <>
                        {finalSelectedSubtitles.map((value, index) => {
                            return (
                                <div className="margin">
                                    <Input
                                        labelText={`SUBTITLE - ${value.title}`}
                                        cName={"banner-title"}
                                        name={value.title}
                                        value={finalSelectedSubtitles[index]?.uri}
                                        changeHandler={(name, value) =>
                                            this.handleSubtitleChange(name, value, index)
                                        }
                                    />
                                </div>
                            )
                        })}
                        <Button
                            leftIcon="icon-plus"
                            cName="btn primary-btn add-subtitle-btn"
                            bValue={TEXT_CONSTANTS?.ADD_SUBTITLE}
                            clickHandler={() => this.addSubtitle()}
                        />
                    </>
                )}
            </div>
        )
    }
    render() {
        const {
            isModalOpen,
            ceDataLoadingState,
            isModalViewOpen,
            contentMarkers,
            index: episodeIndex,
            seasonIndex
        } = this.props
        const { showSubtitlePopup } = this.state
        return (
            <>
                {showSubtitlePopup && (
                    <CustomModal
                        showModal={showSubtitlePopup}
                        submitHandler={this.submitModalHandler}
                        cancelHandler={this.submitModalHandler}
                        closeOnSubmit={false}
                        submitButtonLabel={BUTTON_CONSTANTS?.ADD}
                        showCancelButton={true}
                        cancelOnOutsideClick={false}
                        showCloseIcon={false}
                        submitBtnClass={"confirmation-submit-btn"}
                        cancelBtnClass={"confirmation-cancel-btn"}
                    >
                        {this.getSubtitlePopup()}
                    </CustomModal>
                )}
                <AuthWrapper permissions={[PERMISSIONS?.CONTENT_EDIT]}>
                    <ContentEnrichment
                        isModalOpen={isModalOpen}
                        setModalOpen={this.props?.contentEnrichmentBtnHandler}
                        submitModalHandler={this.props?.submitModalHandler}
                        fetchVideoMarkers={this.props?.fetchVideoMarkers}
                        loadingState={ceDataLoadingState}
                    ></ContentEnrichment>

                    <ContentEnrichmentView
                        isModalOpen={isModalViewOpen}
                        setModalOpen={this.props?.contentEnrichmentViewBtnHandler}
                        payload={this.state?.data}
                        contentMarkers={contentMarkers}
                        webseriesData={this.props.webseriesData}
                        episodeIndex={episodeIndex}
                        seasonIndex={seasonIndex}
                        contentType={this.props.contentType}
                        selectedLanguage={this.props.selectedLanguage}
                    ></ContentEnrichmentView>
                </AuthWrapper>
                {this.renderEnrichmentAndMetaDataBtn()}
                {this.props.data ? (
                    <ContentEntity
                        data={this.props.data}
                        preFillData={this.state.data}
                        formMetaData={this.props.formMetaData}
                        handleChange={this.handleChange}
                        uploadImage={this.imgUpload}
                        removeImg={this.imgRemove}
                        selectedLanguage={this.props.selectedLanguage}
                        certifications={this.props.certifications}
                        drmResponse={this.props.drmResponse}
                        isEdit={this.props?.isEdit}
                        selectedData={this?.props?.selectedData}
                    ></ContentEntity>
                ) : (
                    ""
                )}
                {this.renderSubtitle()}
                <div className="episode-btn">
                    <AuthWrapper permissions={[PERMISSIONS?.CONTENT_EDIT]}>
                        <Button
                            cName={"btn secondary-btn"}
                            bValue={BUTTON_CONSTANTS?.SAVE}
                            clickHandler={this.handleSaveEpisode}
                            disabled={this.props?.isUploadingImage}
                        />
                    </AuthWrapper>
                    <Button
                        cName={"btn cancel-btn"}
                        bValue={BUTTON_CONSTANTS?.CANCEL}
                        clickHandler={this.props.handleEpisodeCancel}
                    />
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isUploadingImage: state?.loader?.isLoading //Disable the submit button whenever user is uploading images
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                uploadImage
            },
            dispatch
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddEpisode)
