import React from "react"
import { getImageRecommendedText } from "../../../utils/common"

function ImageUplode(props) {
    const { name, id, listRef, value, label, placeholder, required, disabled } = props

    const imageChangeHandler = (e) => {
        const { files } = e.target

        const modifiedName = files?.[0]?.name?.replace(/[^a-zA-Z.0-9_-]/g, "")
        const modifiedFile = new File([files[0]], modifiedName, {
            type: files[0].type
        })

        let data = new FormData()
        data.append("image", modifiedFile)
        data.append("type", "CONTENT")
        props.uploadImage(data, id, name)
    }

    if (value) {
        return (
            <div>
                <span className="img-header-label">
                    {placeholder}
                    {required && (
                        <span className="required">
                            {" "}
                            {""}
                            {"*"}
                        </span>
                    )}
                </span>
                <div htmlFor={name}>
                    <div className="addPoster">
                        <img src={`${value}`} alt="" id="showImg" />
                        {!disabled && (
                            <div className="overlay-btn">
                                <button className="btn" onClick={() => props.removeImg(name)}>
                                    Remove
                                </button>
                            </div>
                        )}
                    </div>
                </div>

                <span className="img-size">{getImageRecommendedText(id)}</span>
            </div>
        )
    } else {
        return (
            <div>
                <span className="img-header-label">
                    {placeholder}
                    {required && (
                        <span className="required">
                            {" "}
                            {""}
                            {"*"}
                        </span>
                    )}
                </span>
                <label htmlFor={name}>
                    <div className="addPoster">
                        <i className="icon-plus"></i>
                        <span>{label}</span>
                    </div>
                </label>

                <input
                    type={"file"}
                    data-id={id}
                    accept="image/png, image/jpeg"
                    id={name}
                    name={name}
                    ref={listRef}
                    onClick={(e) => {
                        e.currentTarget.value = null
                    }}
                    onChange={imageChangeHandler}
                    disabled={disabled}
                />
                <span className="img-size">{getImageRecommendedText(name)}</span>
            </div>
        )
    }
}

export default ImageUplode
