import React, { useEffect, useMemo, useRef, useState } from "react"
import { imagePathPrefix } from "../../../../config/apiPath"
import NoImgAvailable from "../../../../assets/images/no_img_available.png"
import tripleDot from "../../../../assets/images/tripleDot.svg"
import deleteIcon from "../../../../assets/images/delete.svg"
import GreenTick from "../../../../assets/images/greenTick.svg"
import infoIcon from "../../../../assets/images/info.svg"
import { BUTTON_CONSTANTS } from "../../../../utils/constants"
import {
    ACTION_TABLE_MODAL,
    IS_MULTILINGUAL_FIELD,
    MULTILINGUAL_KEY,
    SELECTED_LANGUAGE,
    SETUP_SPROTS_CONSTANTS,
    SPORTS_TAB
} from "../../APIs/constant"
import { getMultiLingualValue, isClickedOutside, joinUrl } from "../../../../utils/common"
import EmptyContent from "../../../common/EmptyContent"
import {
    disableTableFields,
    getDataBasedOnSelectedTab,
    getEmptyErrorMessage
} from "../../APIs/helper"
import { useSelector } from "react-redux"
import HoverOverTooltip from "../../../common/HoverOverTooltip"

export default function RenderSportsTableData({ sportsData = [], initiateModal }) {
    const actionItemRef = useRef(null)
    const [showActionItem, setShowActionItem] = useState("")
    const sportsSetupState = useSelector((state) => state.sportsReducer?.sportsSetup)
    const { selectedTab } = sportsSetupState

    useEffect(() => {
        // Closing the table action item (edit, delete) dropdown on outside click
        const handleClickOutside = (event) => {
            showActionItem &&
                // !showModal &&
                isClickedOutside(event?.target, actionItemRef?.current) &&
                setShowActionItem("")
        }
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [showActionItem])

    const actionItemHandler = (selectedItem) => {
        if (selectedItem === showActionItem) {
            setShowActionItem("")
            return
        }
        setShowActionItem(selectedItem)
    }

    const renderActionIconItem = ({ currentRowData }) => {
        return (
            <div className="popup-items" ref={actionItemRef}>
                <img
                    src={tripleDot}
                    alt="additional-item-icon"
                    onClick={() => {
                        actionItemHandler(currentRowData?.id)
                    }}
                />
                {showActionItem === currentRowData?.id && (
                    <div className="additional-items">
                        <div
                            className="items-container"
                            onClick={() => {
                                initiateModal(currentRowData, ACTION_TABLE_MODAL?.EDIT_MODAL)
                            }}
                        >
                            <div className="image-container">
                                <img src={GreenTick} alt="deleteIcon-icon" />
                            </div>
                            <p>{BUTTON_CONSTANTS?.EDIT}</p>
                        </div>
                        <div
                            className="items-container"
                            onClick={() => {
                                initiateModal(currentRowData, ACTION_TABLE_MODAL?.DELETE_MODAL)
                            }}
                        >
                            <div className="image-container">
                                <img src={deleteIcon} alt="deleteIcon-icon" />
                            </div>
                            <p>{BUTTON_CONSTANTS?.DELETE}</p>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    const tableHeading = useMemo(() => {
        const heading = getDataBasedOnSelectedTab({
            selectedTab: selectedTab?.id,
            sportCategoryData: disableTableFields(
                ["sportCategory", "league-tournament"],
                "Sport Name"
            ),
            playersData: disableTableFields(["league-tournament"], "Player Name"),
            leaguesData: disableTableFields(["league-tournament"], "League Name"),
            matchData: disableTableFields([], "Match Name")
        })
        return renderTableHeadingData(heading)
    }, [selectedTab?.id])

    const tableRows = sportsData?.map((item, index) => {
        const sportDescription = getColumnData(item?.meta?.description)
        return (
            <tr id={item?.id} key={item?.id} className="">
                <td>{index + 1}</td>
                <td>{renderIcon(getColumnData(item?.meta?.posterImage))}</td>
                <td>{getColumnData(item?.meta?.name)}</td>
                {selectedTab?.id !== SPORTS_TAB?.SPORTS_CATEGORY && (
                    <td>{getMetaDataFromParent(item?.parent?.["0"], "name")}</td>
                )}
                {selectedTab?.id === SPORTS_TAB?.MATCHES && (
                    <td>{getMetaDataFromParent(item?.parent?.["1"], "name")}</td>
                )}
                <td>{item?.published ? "Published" : "Unpublished"}</td>
                <td>{getColumnData(item?.meta?.shortDescription)}</td>
                <td>
                    <HoverOverTooltip
                        tooltipContent={sportDescription}
                        contentType="text"
                        direction="top"
                        delay={200}
                    >
                        <p>{sportDescription}</p>
                    </HoverOverTooltip>
                </td>
                <td className="btn-popupContainer">
                    {renderActionIconItem({ currentRowData: item })}
                </td>
            </tr>
        )
    })

    const tableErrorMessage = useMemo(() => {
        return getEmptyErrorMessage(selectedTab?.id)
    }, [selectedTab?.id])

    return sportsData?.length < 1 ? (
        <EmptyContent errorMsg={tableErrorMessage} isButtonRequired={false}></EmptyContent>
    ) : (
        <table className={`table sports-table`}>
            <thead>
                <tr>{tableHeading}</tr>
            </thead>
            <tbody>{tableRows}</tbody>
        </table>
    )
}

const renderTableHeadingData = (headingData) => {
    return headingData.map((item) => {
        return (
            <th key={item?.accessor}>
                <div className="table-header-wrapper">
                    <span>{item?.header}</span>
                    {item?.isInformationTooltip && (
                        <HoverOverTooltip
                            tooltipContent={SETUP_SPROTS_CONSTANTS?.informationText}
                            contentType="text"
                            direction="bottom"
                            delay={200}
                        >
                            <img src={infoIcon} alt="information" className="info-icon"></img>
                        </HoverOverTooltip>
                    )}
                </div>
            </th>
        )
    })
}

const renderIcon = (imgSrc) => {
    return (
        <div className="image-icon-container">
            <img
                src={imgSrc && joinUrl(imgSrc)}
                alt="thumbnail"
                className="image-icon"
                onError={(e) => {
                    e.target.onerror = null
                    e.target.src = `${NoImgAvailable}`
                }}
            />
        </div>
    )
}

const getMetaDataFromParent = (parent, metaDataKey) => {
    return getMultiLingualValue(
        IS_MULTILINGUAL_FIELD,
        parent?.meta?.[metaDataKey],
        SELECTED_LANGUAGE?.code,
        MULTILINGUAL_KEY
    )
}

const getColumnData = (value) => {
    return getMultiLingualValue(
        IS_MULTILINGUAL_FIELD,
        value,
        SELECTED_LANGUAGE?.code,
        MULTILINGUAL_KEY
    )
}
