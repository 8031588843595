import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import Input from "../../../common/Input/index"
import { showNavBar } from "../../../../containers/AdminHome/APIs/action"
import ImageUplode from "../imageUplode"
import { getTenants, uploadLogo } from "../APIs/action"

import "./style.scss"
import { imagePathPrefix } from "../../../../config/apiPath"
import { checkIfUserHasAccess } from "../../../../utils/users"
import { PERMISSIONS } from "../../../../utils/constants"

/* admin work */
class AddEditTenant extends Component {
    constructor(props) {
        super(props)
        this.state = {
            logoFilePath: "",
            tenantFields: {}
        }
        this.canUpdateStatus = checkIfUserHasAccess([PERMISSIONS.ADD_TENANT_EDIT])
    }
    handleSubmit = () => {
        const { showNavBar, history } = this.props
        history.push("/dashboard")
        showNavBar(false)
    }
    handleClose = () => {
        const { history } = this.props
        history.goBack()
    }

    imgUpload = async (data, id, name) => {
        const { handleChange, uploadLogo } = this.props
        const response = await uploadLogo(data)
        if (response && response.data) {
            let imgUrl = response.data
            this.setState({
                logoFilePath: imgUrl
            })
            handleChange("logoFilePath", imgUrl)
        }
    }

    handleChange = async (name, value) => {
        let { tenantFields } = this.state

        let inputFields = tenantFields
        inputFields[name] = value
        this.setState(
            {
                tenantFields: inputFields
            },
            () => {
                this.props.handleChange(name, value)
            }
        )
    }

    async componentDidMount() {
        this.setState({
            tenantFields: this.props.tenantFields,
            logoFilePath: this.props.tenantFields["logoFilePath"]
        })
    }

    imgRemove = (name, item) => {
        this.setState({
            [name]: ""
        })
    }

    render() {
        const { tenantFields, logoFilePath } = this.state
        return (
            <div className="">
                <div className="step2-container">
                    <Input
                        className="inputField"
                        type="text"
                        changeHandler={this.handleChange}
                        name="primaryColor"
                        id="customName"
                        maxLength={25}
                        labelText="PRIMARY COLOR"
                        value={tenantFields["primaryColor"]}
                        disabled={!this.canUpdateStatus}
                    />
                    <Input
                        className="inputField"
                        type="text"
                        changeHandler={this.handleChange}
                        name="secondaryColor"
                        id="customName"
                        maxLength={25}
                        labelText="SECONDARY COLOR"
                        value={tenantFields["secondaryColor"]}
                        disabled={!this.canUpdateStatus}
                    />
                    <ImageUplode
                        key={"UPLOAD_LOGO"}
                        name={"logoFilePath"}
                        id={"logoFilePath"}
                        required
                        placeholder={"UPLOAD LOGO"}
                        label={"UPLOAD LOGO"}
                        value={logoFilePath ? `${imagePathPrefix}${logoFilePath}` : null}
                        uploadImage={this.imgUpload}
                        removeImg={(name) => this.imgRemove(name, "")}
                        disabled={!this.canUpdateStatus}
                    />
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                showNavBar,
                getTenants,
                uploadLogo
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEditTenant)
