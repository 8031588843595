import React from "react"
import RightModal from "../../../common/RightModal"
import AddNewCategoryForm from "./AddNewCategoryForm"

export default function AddEditModal({
    showAddEditModal,
    setAddEditModalHandler,
    prefillData = null
}) {
    const rightModalSubmitHandler = () => {}

    const modalCancelHandler = () => {
        setAddEditModalHandler(false)
    }

    return (
        <RightModal
            showModal={showAddEditModal}
            submitHandler={rightModalSubmitHandler}
            cancelHandler={modalCancelHandler}
            closeOnSubmit={false}
            cancelOnOutsideClick={false}
            showCloseIcon={true}
            showSubmitButton={false}
            submitBtnClass={"confirmation-submit-btn"}>
            <AddNewCategoryForm
                prefillData={prefillData}
                cancelHandler={modalCancelHandler}
            />
        </RightModal>
    )
}
