import ApiService from "../../../utils/apiService"
import { API_ROOT_PATH, API_TENANT_ROOT_PATH } from "../../../config/apiPath"

class DrmConfigService {
    saveDrmConfig(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/drm/config/saveAndUpdate`,
            data: params,
            isAuthRequired: true
        }
        return ApiService(options)
    }
    getDrmConfig(params) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/drm/config/fetch`,
            data: params,
            isAuthRequired: true
        }
        return ApiService(options)
    }
    getIsDrmConfig(params) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/drm/config/stack/status`,
            data: params,
            isAuthRequired: true
        }
        return ApiService(options)
    }
    getCountries(params) {
        const options = {
            method: "GET",
            url: `${API_TENANT_ROOT_PATH}/multitenancy/v1/country/`,
            data: params,
            isAuthRequired: true
        }
        return ApiService(options)
    }
    getLicenseDer(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/drm/config/upload/der`,
            data: params,
            isAuthRequired: true
        }
        return ApiService(options)
    }
}

const DrmConfigInstance = new DrmConfigService()
export default DrmConfigInstance
