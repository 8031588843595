import React, { Component } from "react"
import { toast } from "react-toastify"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import Checkbox from "../../common/Checkbox"
import Button from "../../common/Button"
import { createRail, getLatestPosition, fetchCategory } from "../api/action"
import { dateTime, getGMTTimezone } from "../../../utils/common"

import "./style.scss"
import {
    BUTTON_CONSTANTS,
    ERROR_MESSAGES,
    PERMISSIONS,
    TEXT_CONSTANTS
} from "../../../utils/constants"
import { AuthWrapper } from "../../common/AuthWrapper"
import DuplicateRailModal from "../CategoryDetails/DuplicateRailModal"
import { DEFAULT_SELECTED_LANGUAGE_CODE } from "../../ContentNav/api/constants"
import { PUBLISH_UNPULISH_GROUP, SPORTS_HOME_PAGE_TAB } from "../api/constant"
import { isEmpty } from "lodash"

class RailListView extends Component {
    constructor(props) {
        super(props)

        this.state = {
            railToDuplicate: null,
            platformList: [],
            categoryList: [],
            platform: null,
            category: null,
            railStage: null,
            idToDelete: null,
            isDelete: false,
            idToUnpublish: null,
            isUnpublish: false
        }
    }

    handleDeletePopup = (id) => {
        if (id) {
            this.setState({
                idToDelete: id,
                isDelete: true
            })
        } else {
            this.setState({
                idToDelete: null,
                isDelete: false
            })
        }
    }

    handleUnpublishPopup = (id) => {
        if (id) {
            this.setState({
                idToUnpublish: id,
                isUnpublish: true
            })
        } else {
            this.setState({
                idToUnpublish: null,
                isUnpublish: false
            })
        }
    }

    componentDidMount() {
        this.loadHandler()
    }

    loadHandler = async () => {
        const { categoryList, platformList } = this.props
        this.setState({
            categoryList,
            platformList
        })
    }

    handleDuplicate = (item) => {
        if (item) {
            this.setState({
                railToDuplicate: { ...item }
            })
        } else {
            this.setState({
                railToDuplicate: null
            })
        }
    }

    handleRailNameChange = (name, value) => {
        let { railToDuplicate } = this.state
        railToDuplicate.name = value
        this.setState({
            railToDuplicate
        })
    }

    handleSelectChange = async (name, value) => {
        let categoryList = [...this.state.categoryList]
        if (name === "platform") {
            let resp = await this.props.fetchCategory(value)
            if (resp && resp.status) {
                categoryList = resp.data
            }
        }
        this.setState({
            [name]: value,
            categoryList
        })
    }

    handleRailCategory = (e) => {
        this.setState({ railStage: e.target.value })
    }

    showPlatform = (platform = []) => {
        let platformName = []
        if (platform.length > 2) {
            platformName = platform.map((item, index) => {
                if (index < 2) return item?.name
                else return null
            })
        } else {
            platformName = platform.map((item, index) => {
                return item?.name
            })
        }
        let newRole = platformName?.slice(0, 2).join(" , ")
        if (platform.length > 2) {
            newRole = (
                <p>
                    {newRole} <span id="indicator"> + {platform?.length - 2}</span>
                </p>
            )
        }
        return newRole
    }

    handleDuplicateSubmit = async (duplicateRailData) => {
        let { railToDuplicate } = this.state

        const { getLatestPosition, createRail, selectedTimezoneLabel } = this.props

        const { railName, platform, category, stage, sportsCategory, sportsSubType } =
            duplicateRailData

        let newRailToDuplicate = railToDuplicate
        newRailToDuplicate.title = railName

        // const translatedNames = newRailToDuplicate?.translatedNames
        // for (let key in translatedNames) {
        //     translatedNames[key] = ""
        // }
        // translatedNames[DEFAULT_SELECTED_LANGUAGE_CODE] = railName

        newRailToDuplicate.platform = [platform.id]
        newRailToDuplicate.category = category

        newRailToDuplicate.active = stage === PUBLISH_UNPULISH_GROUP?.[0]?.id ? true : false
        let contentEditorial = newRailToDuplicate?.contentList
        contentEditorial = contentEditorial?.map((item) => {
            delete item.content
            delete item.id
            return {
                ...item
            }
        })
        newRailToDuplicate.contentList = contentEditorial
        delete newRailToDuplicate.id

        if (category?.hasPageCategory) {
            newRailToDuplicate.pageCategoryId = sportsSubType?.id // page Category ID ex cricket, tennis or virat
            newRailToDuplicate.pageCategoryRail =
                sportsCategory?.id !== SPORTS_HOME_PAGE_TAB?.id ? true : false // False for sports home page
        } else {
            delete newRailToDuplicate?.pageCategoryId
            delete newRailToDuplicate?.pageCategoryRail
        }

        let resp = await getLatestPosition()
        if (resp && resp?.status) {
            newRailToDuplicate.position = resp?.data + 1
        }
        let response = await createRail(
            newRailToDuplicate,
            getGMTTimezone(selectedTimezoneLabel?.timeZone)
        )
        if (response && response.status) {
            toast.success("Duplicate rail created")
            this.handleDuplicate()
            this.props.refresh()
        } else {
            toast.error(response?.message || ERROR_MESSAGES.SOMETHING_WENT_WRONG)
        }
    }

    render() {
        const {
            rails,
            handleClick,
            handleDelete,
            togglePublishRail,
            selectRail,
            selectAllRail,
            isSelected,
            platformList
        } = this.props
        const { railToDuplicate, idToDelete, isDelete, idToUnpublish, isUnpublish, categoryList } =
            this.state
        return (
            <div className="railListViewContainer">
                <table className="table">
                    <thead>
                        <tr>
                            <th className="firstChild">
                                <div className="tickMark">
                                    <Checkbox
                                        cName="check-box"
                                        id={"checkBox"}
                                        name={"select all"}
                                        changeHandler={selectAllRail}
                                    />
                                </div>
                                {TEXT_CONSTANTS?.RAIL_NAME_PLATFORM}
                            </th>
                            <th>{TEXT_CONSTANTS?.STATUS?.toLowerCase()}</th>
                            <th>
                                {TEXT_CONSTANTS?.SCHEDULE} {TEXT_CONSTANTS?.PUBLISH?.toLowerCase()}{" "}
                                {TEXT_CONSTANTS?.FROM?.toLowerCase()}
                            </th>
                            <th>
                                {TEXT_CONSTANTS?.SCHEDULE} {TEXT_CONSTANTS?.PUBLISH?.toLowerCase()}{" "}
                                {TEXT_CONSTANTS?.TO?.toLowerCase()}
                            </th>
                            <th>{TEXT_CONSTANTS?.ACTION?.toLowerCase()}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rails.map((item, index) => {
                            const { date: startDate, time: startTime } = dateTime(item.start)
                            const { date: endDate, time: endTime } = dateTime(item.end)
                            return (
                                <tr className="railListRow" key={index} id={index}>
                                    <td className="firstChild">
                                        <div className="tickMark">
                                            {isSelected(item.id) ? (
                                                <Checkbox
                                                    cName="select-checkBox"
                                                    name={item.name}
                                                    id={item.id}
                                                    isChecked={true}
                                                    changeHandler={selectRail}
                                                />
                                            ) : (
                                                <Checkbox
                                                    cName="select-checkBox"
                                                    name={item.name}
                                                    id={item.id}
                                                    changeHandler={selectRail}
                                                />
                                            )}
                                        </div>
                                        <div>
                                            <p className="platformName">
                                                {this.showPlatform(item?.platform)}
                                            </p>
                                            <p className="railName">
                                                {item.title}
                                                <span>
                                                    ({" "}
                                                    {item.contentList.length < 10
                                                        ? `0${item.contentList.length}`
                                                        : item.contentList.length}{" "}
                                                    )
                                                </span>
                                            </p>
                                        </div>
                                    </td>
                                    <td className="status">
                                        <i
                                            className={
                                                item.active ? "published" : "unpublished"
                                            }></i>
                                    </td>
                                    <td className="dateTime">
                                        {item.start ? (
                                            <>
                                                <span>{startDate}</span>
                                                <span>{startTime}</span>
                                            </>
                                        ) : (
                                            <span className="dash"> - </span>
                                        )}
                                    </td>
                                    <td className="dateTime">
                                        {item.end ? (
                                            <>
                                                <span>{endDate}</span>
                                                <span>{endTime}</span>
                                            </>
                                        ) : (
                                            <span className="dash"> - </span>
                                        )}
                                    </td>
                                    <td className="icons-container">
                                        <div className="action-icons-list">
                                            <AuthWrapper
                                                permissions={[
                                                    PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_EDIT
                                                ]}>
                                                <i
                                                    className={`toolsBtn ${
                                                        !item.active
                                                            ? "icon-published"
                                                            : "icon-unpublish"
                                                    }`}
                                                    onClick={() =>
                                                        this.handleUnpublishPopup(item.id)
                                                    }>
                                                    <span class="tooltiptext">
                                                        {!item.active ? "Publish" : "Unpublish"}
                                                    </span>
                                                </i>
                                                <i
                                                    className="toolsBtn icon-del"
                                                    onClick={() => this.handleDeletePopup(item.id)}>
                                                    <span class="tooltiptext">
                                                        {BUTTON_CONSTANTS?.DELETE}
                                                    </span>
                                                </i>
                                                <div
                                                    className="toolsBtn icon-duplicate"
                                                    onClick={() => this.handleDuplicate(item)}>
                                                    <span class="tooltiptext">
                                                        {TEXT_CONSTANTS?.DUPLICATE_RAIL}
                                                    </span>
                                                </div>
                                            </AuthWrapper>
                                            <div
                                                onClick={() => handleClick(item.id)}
                                                className="toolsBtn icon-edit">
                                                <span className="tooltiptext">
                                                    {BUTTON_CONSTANTS?.EDIT}
                                                </span>
                                            </div>
                                        </div>
                                        <i className="icon-dot-3"></i>
                                        {idToDelete === item.id && (
                                            <div
                                                className={`deletePopup ${
                                                    isDelete ? "showPopUp" : "hidePopUp"
                                                }`}>
                                                <p>{TEXT_CONSTANTS?.DELETE_RAIL}</p>
                                                <Button
                                                    cName="btn popup-del-btn"
                                                    bValue={BUTTON_CONSTANTS?.YES_DELETE}
                                                    bType="button"
                                                    clickHandler={() => handleDelete(item.id)}
                                                />
                                                <Button
                                                    cName="btn cancel-btn"
                                                    bValue={BUTTON_CONSTANTS?.CANCEL}
                                                    bType="button"
                                                    clickHandler={() => this.handleDeletePopup()}
                                                />
                                            </div>
                                        )}
                                        {idToUnpublish === item.id && (
                                            <div
                                                className={`unpublishPopup ${
                                                    isUnpublish ? "showPopUp" : "hidePopUp"
                                                }`}>
                                                <p>
                                                    {!item.active ? "Publish" : "Unpublish"}{" "}
                                                    {TEXT_CONSTANTS?.THIS_RAIL}
                                                </p>
                                                <Button
                                                    cName="btn popup-del-btn"
                                                    bValue={BUTTON_CONSTANTS?.YES}
                                                    bType="button"
                                                    clickHandler={
                                                        item.active
                                                            ? () =>
                                                                  togglePublishRail(item.id, false)
                                                            : () => togglePublishRail(item.id, true)
                                                    }
                                                />
                                                <Button
                                                    cName="btn cancel-btn"
                                                    bValue={BUTTON_CONSTANTS?.CANCEL}
                                                    bType="button"
                                                    clickHandler={() => this.handleUnpublishPopup()}
                                                />
                                            </div>
                                        )}
                                        <AuthWrapper
                                            permissions={[
                                                PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_EDIT
                                            ]}>
                                            {railToDuplicate?.id === item?.id && (
                                                <DuplicateRailModal
                                                    handleDuplicate={this.handleDuplicate}
                                                    handleDuplicateSubmit={
                                                        this.handleDuplicateSubmit
                                                    }
                                                    platformList={platformList}
                                                    railToDuplicate={railToDuplicate}
                                                    item={item}
                                                    category={this.state.category}
                                                    categoryList={
                                                        categoryList
                                                    }></DuplicateRailModal>
                                            )}
                                        </AuthWrapper>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        selectedTimezoneLabel: state?.timezone?.selectedTimezone
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                createRail,
                getLatestPosition,
                fetchCategory
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RailListView)
