import React from "react"

import "./style.scss"
function CustomRadioButton(props) {
    const { id, cName, options, label, required, disabled, cRadioBtn } = props
    return (
        <div className={`radio-btn-cont ${cName} ${disabled ? "disabled" : ""}`}>
            {label && (
                <label htmlFor={id} className="labelText">
                    {label}
                    <span
                        style={{
                            display: required ? "inline" : "none",
                            color: "#1FB1FF",
                            marginLeft: "5px"
                        }}
                    >
                        *
                    </span>
                </label>
            )}

            <div className={`radio-btns ${cRadioBtn ? cRadioBtn : ""} `}>
                {options
                    ? options.map((item) => {
                          return (
                              <div class="checkbox">
                                  <input
                                      type="radio"
                                      id={item.id}
                                      name={props.name}
                                      onChange={props.handleRadioChange}
                                      value={item.id}
                                      disabled={disabled}
                                      checked={props?.value?.toString() === item.id?.toString()}
                                  />
                                  <label></label>
                                  <span class="checkbox-text">{item.name}</span>
                              </div>
                          )
                      })
                    : null}
            </div>
        </div>
    )
}

export default CustomRadioButton
