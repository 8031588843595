import ApiService from "../../../utils/apiService"
import { API_ROOT_PATH } from "../../../config/apiPath"

class DrmConfigService {
    getBasicConfig() {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/config/getTenantBasicConfig`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    saveBasicConfig(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/config/saveTenantBasicConfig`,
            data: params,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    createEditSplashScreen(params, id) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/core/v1/splash-screens/${id}`,
            data: params,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    getSplashScreen(params) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/core/v1/splash-screens/${params}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    uploadSplashImage(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/core/document/upload/splash-screens`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }
}

const DrmConfigInstance = new DrmConfigService()
export default DrmConfigInstance
