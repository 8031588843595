import React, { useMemo, useState } from "react"
import {
    BUTTON_CONSTANTS,
    EURO_SYMBOL_UNICODE,
    RUPEES_SYMBOL_UNICODE,
    TEXT_CONSTANTS
} from "../../../utils/constants"
import Checkbox from "../../common/Checkbox"
import Button from "../../common/Button"
import ImageSlider from "../ImageSlider"
import "./RenderSelectedPlans.scss"
import CustomModal from "../../common/Modal"
import SuccessIcon from "../../../assets/images/successIcon.svg"
import { CONTENT_TAB_TYPES } from "../API/constants"
import { DEFAULT_SELECTED_LANGUAGE_CODE } from "../../ContentNav/api/constants"
import { toast } from "react-toastify"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { getContentCountLinkToPlan } from "../API/action"

export default function RenderSelectedContentPlan({
    selectedMoviesId,
    selectedPlanApplyHandler,
    contentTabType,
    selectedWebSeriesId,
    moviesDataList,
    webseriesDataList,
    plansList
}) {
    const [selectedMoviePlansIds, setSelectedMoviePlansIds] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const isWebSeriesTab = CONTENT_TAB_TYPES?.WEBSERIES === contentTabType
    const [defaultSelectedPlan, setDefaultSelectedPlan] = useState(null)
    const [contentCountMappedToPlan, setContentCountMappedToPlan] = useState({})
    const dispatch = useDispatch()

    const getContentCountMappedToPlan = async () => {
        const response = await dispatch(getContentCountLinkToPlan())
        if (response.status) {
            setContentCountMappedToPlan(response?.data)
        }
    }

    useEffect(() => {
        getContentCountMappedToPlan()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const plansSorting = () => {
        let sortedPlans = JSON.parse(JSON.stringify(plansList)) || []
        sortedPlans = sortedPlans?.filter((plan) => plan.isPrimary)
        sortedPlans.sort(function (a, b) {
            const p1 = Number(a?.rank || 99999),
                p2 = Number(b?.rank || 99999)
            if (p1 < p2) return 1
            if (p1 > p2) return -1
            return 0
        })
        // Select the highest plan
        const premiumPlanCode = sortedPlans[sortedPlans?.length - 1]?.code
        setSelectedMoviePlansIds([premiumPlanCode])
        setDefaultSelectedPlan(premiumPlanCode)
        return sortedPlans
    }

    const sortedPlanList = useMemo(() => {
        plansSorting()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plansList])

    const checkBoxChangeHandler = (name, value, id) => {
        if (value) {
            setSelectedMoviePlansIds((prevState) => {
                return [...prevState, id]
            })
        } else {
            const remainingSelectedPlans = selectedMoviePlansIds?.filter(
                (plansId) => plansId !== id
            )
            setSelectedMoviePlansIds(remainingSelectedPlans)
        }
    }

    const submitFilterHandler = async () => {
        if (showSuccessModal) {
            setIsModalOpen(false)
            selectedPlanApplyHandler(selectedMoviePlansIds, true)
        } else {
            if (selectedMoviePlansIds?.length > 0) {
                const res = await selectedPlanApplyHandler(selectedMoviePlansIds, false)
                if (res?.status) {
                    setShowSuccessModal(true)
                }
            } else {
                toast.error("Please select atleast one plan!")
            }
        }
    }

    const selectedContentImages = () => {
        let imagesArr = []
        if (isWebSeriesTab) {
            selectedWebSeriesId &&
                selectedWebSeriesId.forEach((series) => {
                    if (series?.selectedSeasonID?.length > 0) {
                        const webSeries = webseriesDataList.find(
                            (item) => item?.id === series?.seriesId
                        )
                        if (webSeries) {
                            imagesArr.push({
                                thumbnail:
                                    webSeries?.content?.brand_poster_image?.values?.[
                                        DEFAULT_SELECTED_LANGUAGE_CODE
                                    ],
                                id: webSeries?.id,
                                seasonCount: series?.selectedSeasonID?.length
                            })
                        }
                    }
                })
        }

        selectedMoviesId &&
            selectedMoviesId.forEach((id) => {
                const movie = moviesDataList?.find((item) => item?.id === id)
                if (movie) {
                    imagesArr.push({
                        thumbnail:
                            movie?.content?.poster_image?.values?.[DEFAULT_SELECTED_LANGUAGE_CODE],
                        id: movie?.id
                    })
                }
            })
        return imagesArr
    }

    const renderSubscriptionPlan = () => {
        return (
            <div className="subscription-plan-wrapper">
                <header className="subscription-plans-header">
                    <h3>{TEXT_CONSTANTS?.SUBSCRIPTION_PLANS}</h3>
                    <p>{TEXT_CONSTANTS?.SUBSCRIPTION_PLANS_DESC}</p>
                </header>
                {/* <div> */}
                {/* Image slider code */}
                <ImageSlider images={selectedContentImages()} isWebSeriesTab={isWebSeriesTab} />
                {/* </div> */}
                <div>
                    {/* Plans */}
                    <div className="plans-container">
                        {sortedPlanList?.map((item) => {
                            return (
                                <ShowPlansContainer
                                    key={item?.id}
                                    plan={item}
                                    checkBoxChangeHandler={checkBoxChangeHandler}
                                    selectedMoviePlansIds={selectedMoviePlansIds}
                                    defaultSelectedPlan={defaultSelectedPlan}
                                    contentCountMappedToPlan={contentCountMappedToPlan}
                                ></ShowPlansContainer>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }

    const displayCount = () => {
        if (contentTabType === CONTENT_TAB_TYPES?.MOVIES) {
            return selectedMoviesId?.length
        } else {
            let count = 0
            selectedWebSeriesId.forEach((item) => {
                if (item?.selectedSeasonID?.length > 0) {
                    count++
                }
            })
            return count
        }
    }

    const calculateAddedContentCount = () => {
        if (isWebSeriesTab) {
            return selectedWebSeriesId?.length
        }
        return selectedMoviesId?.length
    }

    return (
        <div className="selected-plan-container">
            <div className="plans-button-wrapper">
                <div className="plans-text">
                    <p className="selected-movie-txt">
                        {`${TEXT_CONSTANTS?.CONTENT_SELECTED}`} <span>{displayCount()}</span>
                    </p>
                    <p className="selected-movie-txt-des">{`${TEXT_CONSTANTS?.MAP_MOVIES_TO_PLANS}`}</p>
                </div>
                <Button
                    bValue={BUTTON_CONSTANTS?.MAP_TO_PLAN}
                    clickHandler={() => {
                        setIsModalOpen(true)
                    }}
                    cName="btn primary-btn"
                />
                <CustomModal
                    showModal={isModalOpen}
                    submitHandler={submitFilterHandler}
                    cancelHandler={() => {
                        setIsModalOpen(false)
                    }}
                    closeOnSubmit={false}
                    submitButtonLabel={
                        showSuccessModal
                            ? BUTTON_CONSTANTS?.OK?.toUpperCase()
                            : BUTTON_CONSTANTS?.SAVE?.toUpperCase()
                    }
                    showCancelButton={showSuccessModal ? false : true}
                    cancelOnOutsideClick={false}
                    showCloseIcon={false}
                    submitBtnClass={"confirmation-submit-btn"}
                    cancelBtnClass={"confirmation-cancel-btn"}
                    modalContainerClass={showSuccessModal ? "content-success-modal-container" : ""}
                >
                    {showSuccessModal ? (
                        <ContentSuccessfullyAddedMessage
                            addedCount={calculateAddedContentCount()}
                            isWebSeriesTab={isWebSeriesTab}
                            selectedMoviePlansIds={selectedMoviePlansIds}
                            sortedPlanList={sortedPlanList}
                        ></ContentSuccessfullyAddedMessage>
                    ) : (
                        renderSubscriptionPlan()
                    )}
                </CustomModal>
            </div>
        </div>
    )
}

const getCurrencySymbol = (currencyCode) => {
    switch (currencyCode) {
        case currencyCode?.toLowerCase()?.includes("rupee"):
            return RUPEES_SYMBOL_UNICODE
        case currencyCode?.toLowerCase()?.includes("euro"):
            return EURO_SYMBOL_UNICODE
        default:
            return "$"
    }
}

const ShowPlansContainer = ({
    plan,
    checkBoxChangeHandler,
    selectedMoviePlansIds,
    defaultSelectedPlan,
    contentCountMappedToPlan
}) => {
    const isPremiumPlan = defaultSelectedPlan === plan?.code
    const currency = getCurrencySymbol(plan?.currencyCode)
    return (
        <div className={`plan-desc-wrapper ${isPremiumPlan ? "default-selected" : ""}`}>
            <div className="plan-details">
                <p className="plan-name">{plan?.name}</p>
                <p className="plan-price">{`${currency} ${plan?.price}`}</p>
                <p className="total-plan-added">{`${
                    contentCountMappedToPlan[plan?.code] || 0
                } already added`}</p>
            </div>
            <Checkbox
                cName="check-box"
                id={plan?.code}
                name={plan?.name}
                labelText={"Select"}
                isChecked={selectedMoviePlansIds?.includes(plan?.code)}
                changeHandler={checkBoxChangeHandler}
                // disabled={isPremiumPlan} // make highest rank plan disabled
            />
        </div>
    )
}

const ContentSuccessfullyAddedMessage = ({
    addedCount = 1,
    isWebSeriesTab,
    selectedMoviePlansIds,
    sortedPlanList
}) => {
    let planName = []
    sortedPlanList.forEach((plan) => {
        if (selectedMoviePlansIds.includes(plan.code)) {
            planName.push(plan.name)
        }
    })
    let message = TEXT_CONSTANTS?.MOVIES_SUCCESS_ADDED_MESSAGE
    if (isWebSeriesTab) {
        message = TEXT_CONSTANTS?.WEBSERIES_SUCCESS_ADDED_MESSAGE
    }
    if (addedCount < 2) {
        message = message.replace("movies", `movie`)
        message = message.replace("series", `serie`)
    }

    return (
        <div className="vr-successful-msg-container">
            <img src={SuccessIcon} alt="scuccess icon"></img>
            <h3>
                <b className="bold-text">{addedCount}</b>
                {message}
                <b className="bold-text">{planName?.join(", ")}</b>
                {` plan successfully.`}
            </h3>
        </div>
    )
}
