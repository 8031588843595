import React, { useEffect, useState } from "react"
import { BUTTON_CONSTANTS, TEXT_CONSTANTS } from "../../../../utils/constants"
import Input from "../../../common/Input"
import Select from "../../../common/SelectBox"
import Button from "../../../common/Button"
import { fetchCategory } from "../../api/action"
import CustomRadioButton from "../../../common/customRadioBtn"
import { useDispatch, useSelector } from "react-redux"
import { fetchSelectedSportsDataList, getSportsCategory } from "../../../Sports/APIs/action"
import { createSportsTab } from "../../../Sports/APIs/helper"
import { formatSportsData } from "../../api/helper"

import "./style.scss"
import { PUBLISH_UNPULISH_GROUP, SPORTS_HOME_PAGE_TAB, errorMsg } from "../../api/constant"
import { toast } from "react-toastify"
import { isEmpty } from "lodash"

export default function DuplicateRailModal({
    handleDuplicate,
    handleDuplicateSubmit,
    platformList,
    railToDuplicate,
    item,
    categoryList
}) {
    const [dulicateRailFormData, setDuplicateRailFormData] = useState({
        railName: railToDuplicate?.name,
        platform: "",
        category: "",
        stage: PUBLISH_UNPULISH_GROUP?.[1]?.id,
        sportsCategory: "",
        sportsSubType: ""
    })

    const [sportsCategoryTabList, setSportsCategoryTabList] = useState([])
    const [sportsSubTypeList, setSportsSubTypeList] = useState([])
    const [categoryListData, setCategoryListData] = useState(categoryList)
    const dispatch = useDispatch()

    const sportsCategoryList = useSelector((state) => state.sportsReducer?.sportsCategory)

    useEffect(() => {
        if (!sportsCategoryList || sportsCategoryList?.length < 1) {
            dispatch(getSportsCategory())
        } else {
            let tabs = createSportsTab(sportsCategoryList, false, "name")
            tabs.push(SPORTS_HOME_PAGE_TAB)
            setSportsCategoryTabList(tabs)
        }
    }, [sportsCategoryList])

    useEffect(() => {
        setDuplicateRailFormData((prevState) => {
            return {
                ...prevState,
                railName: railToDuplicate?.name
            }
        })
    }, [railToDuplicate?.name])

    useEffect(() => {
        dulicateRailFormData?.platform?.id &&
            updateCategoryListData(dulicateRailFormData?.platform?.id)
    }, [dulicateRailFormData?.platform?.id])

    useEffect(() => {
        dulicateRailFormData?.sportsCategory?.id &&
            updateSelectedSportsCategoryList(dulicateRailFormData?.sportsCategory?.pageEntityId)
    }, [dulicateRailFormData?.sportsCategory?.id])

    const setFormHandler = (name, value, options) => {
        let updatedObj = {}
        if (name === "sportsCategory") {
            updatedObj.sportsSubType = ""
        }
        setDuplicateRailFormData((prevState) => {
            return {
                ...prevState,
                ...updatedObj,
                [name]: value
            }
        })
    }

    const updateSelectedSportsCategoryList = async (pageEntityId) => {
        const categoryPayload = {
            length: -1,
            start: -1,
            pageEntityId: pageEntityId
        }
        const resp = await dispatch(fetchSelectedSportsDataList(categoryPayload))
        if (resp?.status) {
            const formatedData = formatSportsData(resp?.data, "name")
            setSportsSubTypeList(formatedData)
        }
    }

    const updateCategoryListData = async (platformId) => {
        const resp = await dispatch(fetchCategory(platformId))
        if (resp?.status) {
            setCategoryListData(resp?.data)
        }
    }

    const selectDropDownHandler = (name, value, options) => {
        if (dulicateRailFormData?.[name]?.id !== options?.id) {
            setFormHandler(name, options)
        }
    }

    const setRadioHandler = (event) => {
        setFormHandler("stage", event.target?.value)
    }

    const checkIsValidData = () => {
        let isValidData = {
            isValid: true,
            errorMsg: ""
        }
        const { sportsCategory, sportsSubType } = dulicateRailFormData
        if (
            !isEmpty(sportsCategory) &&
            sportsCategory?.id !== SPORTS_HOME_PAGE_TAB?.id &&
            isEmpty(sportsSubType)
        ) {
            isValidData.isValid = false
            isValidData.errorMsg = errorMsg?.SPORTS_SUBTYPE_SELECTION
        }
        return isValidData
    }

    const submitDuplicateRailHandler = () => {
        const validation = checkIsValidData()
        if (validation?.isValid) {
            return handleDuplicateSubmit(dulicateRailFormData)
        }
        toast.error(validation?.errorMsg)
    }

    return (
        <div
            className={`duplicateRailMenu ${
                railToDuplicate && railToDuplicate.id === item.id ? "show" : ""
            }`}
        >
            <div className="header">
                <div className="head-right">{TEXT_CONSTANTS?.DUPLICATE}</div>
                <i className="icon-close" onClick={handleDuplicate}></i>
            </div>
            <div className="duplicate-form">
                <Input
                    cName={`rail-name`}
                    placeholder={"Enter Rail Name"}
                    name={"railName"}
                    labelText={"Rail Title"}
                    required={true}
                    value={dulicateRailFormData?.railName}
                    changeHandler={setFormHandler}
                />
                <div className="copyToFormElement">
                    <label htmlFor="details" className="label">
                        Copy To
                    </label>
                    <Select
                        defaultOption="true"
                        placeholder={"Select Platform"}
                        options={platformList}
                        cName={"copyToOptions"}
                        name={"platform"}
                        changeHandler={selectDropDownHandler}
                        value={dulicateRailFormData?.platform}
                    />
                    <Select
                        defaultOption="true"
                        menuOpenStyle={"top"}
                        placeholder={"Select Page"}
                        options={categoryListData}
                        cName={"copyToOptions"}
                        name={"category"}
                        changeHandler={selectDropDownHandler}
                        value={dulicateRailFormData?.category}
                    />
                    {dulicateRailFormData?.category?.hasPageCategory && (
                        <Select
                            defaultOption="true"
                            menuOpenStyle={"top"}
                            placeholder={"Select Sports Type"}
                            options={sportsCategoryTabList}
                            cName={"copyToOptions"}
                            name={"sportsCategory"}
                            changeHandler={selectDropDownHandler}
                            value={dulicateRailFormData?.sportsCategory}
                        />
                    )}
                    {!isEmpty(dulicateRailFormData?.sportsCategory) &&
                        dulicateRailFormData?.sportsCategory?.id != SPORTS_HOME_PAGE_TAB?.id && (
                            <Select
                                defaultOption="true"
                                menuOpenStyle={"top"}
                                placeholder={"Select Sub Type"}
                                options={sportsSubTypeList}
                                cName={"copyToOptions"}
                                name={"sportsSubType"}
                                changeHandler={selectDropDownHandler}
                                value={dulicateRailFormData?.sportsSubType}
                                isSearchable={true}
                                isAutoFocus={false}
                            />
                        )}
                </div>
                <div className="stage-selection">
                    <label htmlFor="stage" className="label">
                        {TEXT_CONSTANTS?.STAGE}
                    </label>
                    <div className="stage-list">
                        <CustomRadioButton
                            name={"stage"}
                            handleRadioChange={setRadioHandler}
                            options={PUBLISH_UNPULISH_GROUP}
                            cRadioBtn={""}
                            value={dulicateRailFormData?.stage}
                        />
                    </div>
                </div>
                <Button
                    cName={"btn secondary-btn"}
                    bValue={BUTTON_CONSTANTS?.CREATE}
                    clickHandler={submitDuplicateRailHandler}
                />
            </div>
        </div>
    )
}
