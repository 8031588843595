import React, { useEffect, useState } from "react"
import MultipleTab from "../../common/MultipleTab/MultipleTab"
import { useDispatch, useSelector } from "react-redux"
import { getSportsCategory, saveSportsCategoryId } from "../APIs/action"
import { fetchAllCategory } from "../../Category/api/action"
import "./style.scss"
import { createSportsTab } from "../APIs/helper"

export default function SportsCategoryHeader({ selectedTabAction, selectedTab }) {
    const [sportsTab, setSportsTab] = useState([])

    const dispatch = useDispatch()

    const sportsCategoryList = useSelector((state) => state.sportsReducer.sportsCategory)
    const sportsCategoryId = useSelector(
        (state) => state.sportsReducer.sportsSetup.sportsCategoryId
    )

    useEffect(() => {
        fetchAPIData()
    }, [])

    const fetchAPIData = async () => {
        // Call sports category api
        if (!sportsCategoryList || sportsCategoryList?.length < 1) {
            dispatch(getSportsCategory())
        }
        if (!sportsCategoryId) {
            let categoryResponse = await dispatch(fetchAllCategory())
            let id = categoryResponse?.data?.find((item) => item?.hasPageCategory)
            dispatch(saveSportsCategoryId(id))
        }
    }

    useEffect(() => {
        // Create data for sports category
        let tabs = createSportsTab(sportsCategoryList)
        setSportsTab(tabs)
        !selectedTab && dispatch(selectedTabAction(tabs?.[0]))
    }, [sportsCategoryList])

    const activeTabHandler = (tab) => {
        dispatch(selectedTabAction(tab))
    }

    return (
        <MultipleTab
            tabs={sportsTab}
            callBack={activeTabHandler}
            className={"sport-multiple-tab"}
        ></MultipleTab>
    )
}
