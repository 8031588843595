import React from "react"
import PropTypes from "prop-types"
import CustomModal from "../../../common/ContentEnrichmentModal"
import Loader from "../../../../assets/images/loader.gif"
import CheckIcon from "../../../../assets/images/check_icon.svg"

import "./style.scss"
import { isEmpty } from "lodash"
import { TEXT_CONSTANTS } from "../../../../utils/constants"

function ImdbModal(props) {
    const { isModalOpen, setModalOpen } = props
    let loadingState = props.loadingState
    let imdbData = props.imdbData

    const onClickCancelBtn = () => {
        setModalOpen(false)
    }
    return (
        <div className="imdb-view-section">
            <CustomModal
                disableFooter={true}
                showModal={isModalOpen}
                modalStyle={"content-view-modal"}
                cancelHandler={onClickCancelBtn}
            >
                {loadingState ? (
                    <div className="loading-state-container">
                        <img src={Loader} alt="loader" />
                        <p className="main-text">{TEXT_CONSTANTS?.PLEASE_WAIT_MOMENT}</p>{" "}
                        <p className="sub-text">{TEXT_CONSTANTS?.FETCHING_IMDB_DATA}</p>
                    </div>
                ) : (
                    <div className="imdb-response-container">
                        <p className="main-heading">
                            {!isEmpty(imdbData)
                                ? "Data is successfully fetched from IMDB"
                                : "Something went wrong"}
                        </p>
                        <br />
                        {imdbData &&
                            Object.keys(imdbData) &&
                            Object.keys(imdbData).map((key, index) => {
                                return (
                                    <p>
                                        <p className="label-container">
                                            <img src={CheckIcon} alt="check icon"></img>
                                            <p className="main-text">{key?.replace("_", " ")}</p>
                                        </p>
                                        <p title={imdbData[key]} className="sub-text">
                                            {imdbData[key] && imdbData[key].length <= 50
                                                ? imdbData[key]
                                                      ?.replaceAll("[", "")
                                                      ?.replaceAll("]", "")
                                                : imdbData[key]
                                                      ?.replaceAll("[", "")
                                                      ?.replaceAll("]", "")
                                                      ?.substring(0, 100) + "..."}
                                        </p>
                                        <br />
                                    </p>
                                )
                            })}
                    </div>
                )}
            </CustomModal>
        </div>
    )
}

ImdbModal.propTypes = {
    isModalOpen: PropTypes.bool,
    setModalOpen: PropTypes.func
}

export default ImdbModal
