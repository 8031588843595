import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Button from "../../../common/Button"
import { BUTTON_CONSTANTS } from "../../../../utils/constants"
import { debounce } from "../../../../utils/common"
import Input from "../../../common/Input"
import { PAGE_STATUS_OPTIONS, SETUP_SPROTS_CONSTANTS, SPORTS_TAB } from "../../APIs/constant"
import AddEditModal from "../AddEditModal"
import {
    categoryLeagueDropdownData,
    createFilterDataListPayload,
    getDataBasedOnSelectedTab,
    getFilterId
} from "../../APIs/helper"
import {
    fetchFilterDropdownDataList,
    fetchSelectedSportsDataList,
    sportsSetupSetFilterData,
    sportsSetupSetPaginationData
} from "../../APIs/action"
import "./style.scss"
import Select1 from "../../../common/SelectBox"

export default function SportsCategoryManager() {
    const [addNewCategory, setAddNewCategory] = useState(false)

    const sportsCategory = useSelector((state) => state?.sportsReducer?.sportsCategory)
    const sportsSetupState = useSelector((state) => state.sportsReducer?.sportsSetup)
    const { selectedTab, filterData, paginationData, sportsDropdownFilterList } = sportsSetupState
    const dispatch = useDispatch()

    useEffect(() => {
        if (!sportsDropdownFilterList && sportsCategory) {
            const filterDataId = getFilterId(sportsCategory)
            dispatch(fetchFilterDropdownDataList(filterDataId))
        }
    }, [sportsCategory, sportsDropdownFilterList])

    const addNewButtonHandler = (value) => {
        setAddNewCategory(value)
    }

    const fetchUpdatedFilterData = (updatedFilterData) => {
        let paginationDataCopy = { ...paginationData }
        paginationDataCopy.start = 0
        paginationDataCopy.pageCount = 0
        paginationDataCopy.currentPage = 0
        let payload = createFilterDataListPayload(
            paginationDataCopy,
            selectedTab,
            updatedFilterData
        )
        dispatch(fetchSelectedSportsDataList(payload))
        dispatch(sportsSetupSetPaginationData(paginationDataCopy))
    }

    const onSearchChange = (name, value) => {
        updateFormData(name, value, true)
    }

    const updateSearchData = useCallback(
        debounce((updatedFilterData) => {
            // Fetching search results
            fetchUpdatedFilterData(updatedFilterData)
        }, 500),
        [selectedTab?.id, paginationData]
    )

    const updateFormData = (name, value, isSearchValue) => {
        const updatedFilterData = {
            ...filterData,
            [name]: value
        }
        // Make api call when previous and updated data is different
        if (filterData?.[name] !== updatedFilterData?.[name]) {
            dispatch(sportsSetupSetFilterData(updatedFilterData))
            if (isSearchValue) {
                updateSearchData(updatedFilterData)
            } else {
                // Fetching filter results
                fetchUpdatedFilterData(updatedFilterData)
            }
        }
    }

    const searchFilterData = useMemo(() => {
        return getDataBasedOnSelectedTab({
            selectedTab: selectedTab?.id,
            sportCategoryData: {
                searchPlacholder: SETUP_SPROTS_CONSTANTS.searchSports
            },
            playersData: {
                searchPlacholder: SETUP_SPROTS_CONSTANTS.searchPlayer,
                pageStatusOptions: PAGE_STATUS_OPTIONS
            },
            leaguesData: {
                searchPlacholder: SETUP_SPROTS_CONSTANTS.searchLeague,
                pageStatusOptions: PAGE_STATUS_OPTIONS
            },
            matchData: {
                searchPlacholder: SETUP_SPROTS_CONSTANTS.searchTeam,
                selectLeaguePlaceholder: SETUP_SPROTS_CONSTANTS.selectLegaueTour
            }
        })
    }, [selectedTab?.id])

    const dropdownFilterData = useMemo(() => {
        return categoryLeagueDropdownData(sportsDropdownFilterList, sportsCategory)
    }, [sportsDropdownFilterList, sportsCategory])

    return (
        <div className="sports-category-manager">
            <header className="navigation-filter">
                <div className="left-side-container">
                    <Button
                        leftIcon="icon-plus"
                        bValue={BUTTON_CONSTANTS?.ADD_NEW}
                        clickHandler={() => addNewButtonHandler(true)}
                        cName={`btn primary-btn`}
                    />
                </div>
                {renderRightSideData({
                    searchFilterData,
                    filterData,
                    onSearchChange,
                    sportsSetupState,
                    updateFormData,
                    dropdownFilterData
                })}
            </header>
            {addNewCategory && (
                <AddEditModal
                    showAddEditModal={addNewCategory}
                    setAddEditModalHandler={addNewButtonHandler}
                ></AddEditModal>
            )}
        </div>
    )
}

const renderRightSideData = ({
    searchFilterData,
    filterData,
    onSearchChange,
    sportsSetupState,
    updateFormData,
    dropdownFilterData
}) => {
    const { selectedTab } = sportsSetupState
    return (
        <div className="right-side-container">
            <Input
                id="search-box"
                type="text"
                changeHandler={onSearchChange}
                name={"sportSearch"}
                cName={""}
                placeholder={searchFilterData.searchPlacholder}
                value={filterData?.sportSearch}
                isOnBlur={false}
            />
            {/* {selectedTab?.id !== SPORTS_TAB?.SPORTS_CATEGORY && <>
            <MultiSelect
                options={dropdownFilterData?.sportsCategoryList}
                placeholder={SETUP_SPROTS_CONSTANTS.selectCategory}
                name={"sportCategory"}
                changeHandler={(name, value) => updateFormData(name, value)}
                cName={"sports-filter"}
                id={"sportCategory"}
                selectedOptions={filterData?.sportCategory}
            />
            {selectedTab?.id === SPORTS_TAB?.MATCHES ? <MultiSelect
                options={dropdownFilterData?.leaguesList}
                placeholder={SETUP_SPROTS_CONSTANTS.selectLegaueTour}
                name={"league"}
                changeHandler={(name, value) => updateFormData(name, value)}
                cName={"sports-filter"}
                id={"league"}
                selectedOptions={filterData?.league}
            />
                : <MultiSelect
                    options={searchFilterData.pageStatusOptions}
                    placeholder={SETUP_SPROTS_CONSTANTS.pageStatus}
                    name={"pageStatus"}
                    changeHandler={(name, value) => updateFormData(name, value)}
                    cName={"sports-filter"}
                    id={"pageStatus"}
                    selectedOptions={filterData?.pageStatus}
                />
            }
        </> */}
            {selectedTab?.id !== SPORTS_TAB?.SPORTS_CATEGORY && (
                <>
                    <Select1
                        options={dropdownFilterData?.sportsCategoryList}
                        placeholder={SETUP_SPROTS_CONSTANTS.selectCategory}
                        name={"sportCategory"}
                        changeHandler={(name, value, selectedOption) =>
                            updateFormData(name, selectedOption)
                        }
                        cName={"sports-filter"}
                        id={"sportCategory"}
                        value={filterData?.sportCategory}
                    />
                    {selectedTab?.id === SPORTS_TAB?.MATCHES ? (
                        <Select1
                            options={dropdownFilterData?.leaguesList}
                            placeholder={SETUP_SPROTS_CONSTANTS.selectLegaueTour}
                            name={"league"}
                            changeHandler={(name, value, selectedOption) =>
                                updateFormData(name, selectedOption)
                            }
                            cName={"sports-filter"}
                            id={"league"}
                            value={filterData?.league}
                        />
                    ) : (
                        <Select1
                            options={searchFilterData.pageStatusOptions}
                            placeholder={SETUP_SPROTS_CONSTANTS.pageStatus}
                            name={"pageStatus"}
                            changeHandler={(name, value, selectedOption) =>
                                updateFormData(name, selectedOption)
                            }
                            cName={"sports-filter"}
                            id={"pageStatus"}
                            value={filterData?.pageStatus}
                        />
                    )}
                </>
            )}
        </div>
    )
}
