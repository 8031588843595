import React from "react"
import { BUTTON_CONSTANTS, TEXT_CONSTANTS } from "../../../../utils/constants"
import NoImgAvailable from "../../../../assets/images/no_img_available.png"
import "./style.scss"

function ImageUplode(props) {
    const { name, id, listRef, value, label, placeholder, required } = props
    return (
        <div>
            <div className="label-container">
                <span className="img-header-label">
                    {`${placeholder}`} <span className="required">{`${required ? "*" : ""}`}</span>
                </span>
                <span className="img-size">{TEXT_CONSTANTS?.LOGO_DIMENSION}</span>
            </div>
            <label for={name}>
                <div class="addPoster">
                    {value ? (
                        <>
                            <img
                                src={`${value}`}
                                alt=""
                                id="showImg"
                                onError={(e) => {
                                    e.target.onerror = null
                                    e.target.src = `${NoImgAvailable}`
                                }}
                            />
                            <div className="overlay-btn">
                                <label htmlFor={name} className="btn ">
                                    {BUTTON_CONSTANTS?.CHANGE}
                                </label>
                                <button
                                    className="btn"
                                    onClick={(e) => {
                                        props.removeImg(name)
                                        e.stopPropagation()
                                        e.preventDefault()
                                    }}
                                >
                                    {BUTTON_CONSTANTS?.REMOVE}
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <i className="icon-plus"></i>
                            <span>{label}</span>
                        </>
                    )}
                </div>
            </label>
            <input
                type={"file"}
                data-id={id}
                accept="image/png, image/jpeg"
                id={name}
                name={name}
                ref={listRef}
                onClick={(e) => {
                    e.currentTarget.value = null
                }}
                onChange={(e) => {
                    const { files } = e.target
                    let data = new FormData()
                    data.append("image", files[0])
                    props.uploadImage(data, id, name)
                }}
            />
        </div>
    )
}

export default ImageUplode
