import React, { Component } from "react"
import { Draggable } from "react-beautiful-dnd"

import Input from "../../../common/Input"
import Select from "../../../common/SelectBox"
import CustomInput from "../../../common/MaskedInput"
import CustomRadioButton from "../../../common/customRadioBtn"
import CustomCheckBox from "../../../common/commonCheckbox"
import TextArea from "../../../common/TextArea"
import Button from "../../../common/Button"

import "./style.scss"
import { PERMISSIONS, TEXT_CONSTANTS } from "../../../../utils/constants"
import { checkIfUserHasAccess } from "../../../../utils/users"

class PreviewCanvas extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isTitleEdit: false,
            title:
                this.props.apiPayload && this.props.apiPayload.formTitle
                    ? this.props.apiPayload.formTitle
                    : "Form Title",
            groupTitle: "",
            groupIdToEdit: null
        }
        this.canUpdateStatus = checkIfUserHasAccess([PERMISSIONS?.SETTING_FORM_BUILDER_EDIT])
    }

    reorder = (startIndex, endIndex, arr) => {
        const result = [...arr]
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)
        return result
    }

    onDragEnd = (result, arr) => {
        const { source, destination } = result
        const { elementsArr } = this.props
        if (source && destination) {
            const items = this.reorder(
                source.index,
                destination.index,
                arr.elementsArr ? arr.elementsArr : []
            )

            elementsArr.map((i) => {
                if (i.id === arr.id) {
                    i.elementsArr = [...items]
                }
                return i
            })

            this.props.setElementArray(elementsArr)
        }
    }

    renderFields = (item, index, isChild, forSize) => {
        if (item) {
            const { onClick, selectedElement } = this.props
            let className
            if (forSize) {
                className = item && item.size === "S" ? "small" : "medium"
            } else {
                className =
                    item && item.size === "S"
                        ? "small"
                        : item && item.size === "M"
                        ? "medium"
                        : "large"
            }
            switch (item.htmlElement) {
                case "input":
                    return item.htmlInputType === "textarea" ? (
                        <div
                            className={`input-element ${
                                item.id === selectedElement.id ? "highlighted" : ""
                            } ${className}`}
                            onClick={(e) => onClick(e, item, index)}
                        >
                            <TextArea
                                row={5}
                                col={5}
                                labelText={item.title}
                                // cName={classes}
                                placeholder={item.htmlPlaceholder || item.name}
                                required={item.required}
                                name={item.name}
                                changeHandler={(e) => onClick(e, item, index, isChild)}
                                readonly={true}
                                disabled={!this.canUpdateStatus}
                            />
                        </div>
                    ) : (
                        <div
                            className={`input-element  ${
                                item.id === selectedElement.id ? "highlighted" : ""
                            } ${className}`}
                        >
                            <Input
                                type={item && item.htmlInputType}
                                // cName={classes}
                                labelText={item.title}
                                required={item.required}
                                placeholder={item.htmlPlaceholder || item.name}
                                onClick={(e) => onClick(e, item, index, isChild)}
                                readOnly={true}
                                disabled={!this.canUpdateStatus}
                            />
                        </div>
                    )

                case "file":
                    return (
                        <div
                            className={`input-element ${
                                item.id === selectedElement.id ? "highlighted" : ""
                            } ${className}`}
                        >
                            <div className={`imageUpload`}>
                                <span className="img-header-label">
                                    {item.required ? `${item.title}*` : item.title}
                                </span>
                                <label htmlFor={"Add Image"}>
                                    <div
                                        className={`addPoster ${
                                            this.canUpdateStatus
                                                ? "show-pointer-cursor"
                                                : "no-cursor-allowed"
                                        }`}
                                        onClick={(e) => onClick(e, item, index, isChild)}
                                    >
                                        <i className="icon-plus"></i>
                                        <span>{item.htmlPlaceholder || item.name}</span>
                                    </div>
                                </label>
                                <input
                                    type={"file"}
                                    data-id={"addImage"}
                                    id={"addImage"}
                                    name={item.htmlPlaceholder || item.name}
                                    onChange={(e) => {
                                        const { files } = e.target
                                        let data = new FormData()
                                        data.append("image", files[0])
                                        //this.handleImageUpload(data, "addImage", "Add Image");
                                    }}
                                    disabled={!this.canUpdateStatus}
                                />
                            </div>
                        </div>
                    )
                case "select":
                    return item.htmlInputType === "multiSelect" ? (
                        <div
                            className={`input-element ${
                                item.id === selectedElement.id ? "highlighted" : ""
                            } ${className}`}
                            onClick={(e) => onClick(e, item, index, isChild)}
                        >
                            {item.title && (
                                <label className="option-label">
                                    {item.title}
                                    <span
                                        style={{
                                            display: item.required ? "inline" : "none",
                                            color: "#1FB1FF",
                                            marginLeft: "5px"
                                        }}
                                    >
                                        *
                                    </span>
                                </label>
                            )}
                            <div className="options-container">
                                {item.options &&
                                    item.options.map((value, index) => {
                                        return (
                                            <span key={value.id} className="option">
                                                {value.name}
                                            </span>
                                        )
                                    })}
                            </div>
                        </div>
                    ) : (
                        <div
                            className={`input-element ${
                                item.id === selectedElement.id ? "highlighted" : ""
                            } ${className}`}
                            onClick={(e) => onClick(e, item, index, isChild)}
                        >
                            <Select
                                label={item.title}
                                placeholder={item.htmlPlaceholder || item.name}
                                defaultOption={{
                                    id: item.options[0].id,
                                    name: item.options[0].name
                                }}
                                options={item.options}
                                required={item.required}
                                name={item.title}
                                isDisabled={true}
                            />
                        </div>
                    )
                case "dateAndTime":
                    return item.htmlInputType === "date" ? (
                        <div
                            className={`input-element ${
                                item.id === selectedElement.id ? "highlighted" : ""
                            } ${className}`}
                            onClick={(e) => onClick(e, item, index, isChild)}
                        >
                            <CustomInput
                                type={item && item.htmlInputType}
                                labelText={item.title}
                                required={item.required}
                                placeholder={item.htmlPlaceholder || "DD/MM/YYYY"}
                                mask="11/11/1111"
                                name="duration"
                                disabled={true}
                                changeHandler={(e) => onClick(e, item, index, isChild)}
                            />
                        </div>
                    ) : (
                        <div
                            className={`input-element ${
                                item.id === selectedElement.id ? "highlighted" : ""
                            } ${className}`}
                            onClick={(e) => onClick(e, item, index, isChild)}
                        >
                            <CustomInput
                                type={item && item.htmlInputType}
                                labelText={item.title}
                                required={item.required}
                                placeholder={item.htmlPlaceholder || "HH/MM/SS"}
                                mask="11/11/11"
                                name="duration"
                                disabled={true}
                                changeHandler={(e) => onClick(e, item, index, isChild)}
                            />
                        </div>
                    )
                case "radio":
                    return (
                        <div
                            className={`input-element ${
                                item.id === selectedElement.id ? "highlighted" : ""
                            } ${className}`}
                            onClick={(e) => onClick(e, item, index, isChild)}
                        >
                            <CustomRadioButton
                                options={item.options ? item.options : []}
                                label={item.title}
                                changeHandler={(e) => onClick(e, item, index, isChild)}
                                required={item.required}
                            />
                        </div>
                    )
                case "checkbox":
                    return (
                        <div
                            className={`input-element ${
                                item.id === selectedElement.id ? "highlighted" : ""
                            } ${className}`}
                            onClick={(e) => onClick(e, item, index, isChild)}
                        >
                            <CustomCheckBox
                                options={item.options ? item.options : []}
                                label={item.title}
                                required={item.required}
                                name={item.title}
                                isChecked={this.isChecked}
                                changeHandler={(e) => onClick(e, item, index, isChild)}
                            />
                        </div>
                    )
                case "button":
                    return (
                        <div
                            className={`input-element ${
                                item.id === selectedElement.id ? "highlighted" : ""
                            } ${className}`}
                            onClick={(e) => onClick(e, item, index, isChild)}
                        >
                            <Button
                                cName="btn primary-btn"
                                bValue={item.title}
                                bType="button"
                                clickHandler={(e) => onClick(e, item, index, isChild)}
                            />
                        </div>
                    )

                case "group":
                    return (
                        <div
                            className={`input-element group ${
                                item.id === selectedElement.id ? "highlighted" : ""
                            }`}
                            onClick={(e) => onClick(e, item, index, isChild)}
                        >
                            <div className="group-header">
                                <i className="icon-plus"></i>
                                <div
                                    className="group-title"
                                    onDoubleClick={() =>
                                        this.handleGroupTitleEdit(item.id, item.groupTitle)
                                    }
                                >
                                    {this.state.groupIdToEdit === item.id ? (
                                        <Input
                                            cName={"title-edit"}
                                            value={this.state.groupTitle}
                                            changeHandler={this.handleGroupTitleEditChange}
                                            onBlur={() => this.handleGroupTitleSave(index)}
                                            autofocus={true}
                                        />
                                    ) : (
                                        <span>{item.groupTitle}</span>
                                    )}
                                </div>
                            </div>
                            <div className="group-body" style={{ height: "100%" }}>
                                {item.elementsArr && item.elementsArr.length
                                    ? item.elementsArr.map((ele, i) =>
                                          ele ? (
                                              <Draggable
                                                  key={`${ele.id}`}
                                                  draggableId={`${ele.id}`}
                                                  index={i + "" + index}
                                              >
                                                  {(provided, snapshot) => {
                                                      return (
                                                          <div
                                                              ref={provided.innerRef}
                                                              {...provided.draggableProps}
                                                              {...provided.dragHandleProps}
                                                              style={this.getItemStyle(
                                                                  snapshot.isDragging,
                                                                  provided.draggableProps.style
                                                              )}
                                                              className="element-cont"
                                                          >
                                                              <div className="drag">
                                                                  <span className="menu-icon"></span>
                                                                  <span className="menu-icon"></span>
                                                                  <span className="menu-icon"></span>
                                                              </div>
                                                              {this.renderFields(ele, i)}
                                                              {ele.children &&
                                                                  ele.children.length !== 0 &&
                                                                  ele.children.map((data, j) =>
                                                                      this.renderFields(data, i, j)
                                                                  )}
                                                          </div>
                                                      )
                                                  }}
                                              </Draggable>
                                          ) : null
                                      )
                                    : null}
                            </div>
                        </div>
                    )

                default:
                    return null
            }
        }
    }

    handleGroupTitleEdit = (id, title) => {
        this.setState({
            groupIdToEdit: id,
            groupTitle: title
        })
    }

    getItemStyle = (isDragging, draggableStyle) => ({
        ...draggableStyle,
        userSelect: "none",
        position: "static",
        zIndex: 99999,
        // padding: 8 * 2,
        //margin: `0 0 8px 0`,
        background: isDragging ? "#d3e7c9" : ""
    })

    handleTitleEditClick = () => {
        this.setState({ isTitleEdit: !this.state.isTitleEdit })
    }

    handleTitleEditChange = (name, value) => {
        this.props.setPayloadName(value)
        this.setState({
            title: value
        })
    }

    handleGroupTitleEditChange = (name, value) => {
        this.setState({
            groupTitle: value
        })
    }

    handleGroupTitleSave = (index) => {
        const { setElementArray, elementsArr } = this.props
        const { groupTitle } = this.state
        elementsArr[index].groupTitle = groupTitle
        setElementArray(elementsArr)
        this.setState({ groupIdToEdit: null })
    }

    render() {
        const { elementsArr, onClick, selectedElement, handleAddClick } = this.props
        let className = elementsArr && elementsArr.length ? "form-elements-list" : "form-elements"
        const { isTitleEdit, title } = this.state
        return (
            <div className="vd-preview">
                <div className="form-title-cont" onDoubleClick={this.handleTitleEditClick}>
                    <span className="icon-arrow-left1" onClick={handleAddClick}></span>
                    {isTitleEdit ? (
                        <Input
                            cName={"title-edit"}
                            value={title}
                            changeHandler={this.handleTitleEditChange}
                            onBlur={this.handleTitleEditClick}
                        />
                    ) : (
                        <h4>{title}</h4>
                    )}
                </div>
                <div className={className}>
                    {elementsArr && elementsArr.length ? (
                        elementsArr.map((item, index) =>
                            item ? (
                                !item.isStepper && !item.stepperId ? (
                                    <Draggable
                                        draggableId={item.id}
                                        key={item.id}
                                        index={index}
                                        isDragDisabled={!this.canUpdateStatus}
                                    >
                                        {(provided, snapshot) => {
                                            return (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={this.getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                    className="element-cont"
                                                >
                                                    <div className="drag">
                                                        <span className="menu-icon"></span>
                                                        <span className="menu-icon"></span>
                                                        <span className="menu-icon"></span>
                                                    </div>
                                                    {this.renderFields(item, index)}
                                                    {item.children &&
                                                        item.children.length !== 0 &&
                                                        item.children.map((data, i) =>
                                                            this.renderFields(data, index, i, true)
                                                        )}
                                                </div>
                                            )
                                        }}
                                    </Draggable>
                                ) : item.htmlElement === "group" ? (
                                    <div className="element-cont">
                                        <div className="drag">
                                            <span className="menu-icon"></span>
                                            <span className="menu-icon"></span>
                                            <span className="menu-icon"></span>
                                        </div>
                                        <div
                                            className={`input-element group ${
                                                item.id === selectedElement.id ? "highlighted" : ""
                                            }`}
                                            onClick={(e) => onClick(e, item, index, false)}
                                        >
                                            <div className="group-header">
                                                <i className="icon-plus"></i>
                                                <div
                                                    className="group-title"
                                                    onDoubleClick={() =>
                                                        this.handleGroupTitleEdit(
                                                            item.id,
                                                            item.groupTitle
                                                        )
                                                    }
                                                >
                                                    {this.state.groupIdToEdit === item.id ? (
                                                        <Input
                                                            cName={"title-edit"}
                                                            value={this.state.groupTitle}
                                                            changeHandler={
                                                                this.handleGroupTitleEditChange
                                                            }
                                                            onBlur={() =>
                                                                this.handleGroupTitleSave(index)
                                                            }
                                                            autofocus={true}
                                                        />
                                                    ) : (
                                                        <span>{item.groupTitle}</span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="group-body" style={{ height: "100%" }}>
                                                {elementsArr
                                                    .filter((p) => p.stepperId === item.id)
                                                    .map((ele) => (
                                                        <Draggable
                                                            key={`${ele.id}`}
                                                            draggableId={`${ele.id}`}
                                                            index={index + "" + index}
                                                        >
                                                            {(provided, snapshot) => {
                                                                return (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={this.getItemStyle(
                                                                            snapshot.isDragging,
                                                                            provided.draggableProps
                                                                                .style
                                                                        )}
                                                                        className="element-cont"
                                                                    >
                                                                        <div className="drag">
                                                                            <span className="menu-icon"></span>
                                                                            <span className="menu-icon"></span>
                                                                            <span className="menu-icon"></span>
                                                                        </div>
                                                                        {this.renderFields(
                                                                            ele,
                                                                            index
                                                                        )}
                                                                        {ele.children &&
                                                                            ele.children.length !==
                                                                                0 &&
                                                                            ele.children.map(
                                                                                (data, j) =>
                                                                                    this.renderFields(
                                                                                        data,
                                                                                        index,
                                                                                        j,
                                                                                        true
                                                                                    )
                                                                            )}
                                                                    </div>
                                                                )
                                                            }}
                                                        </Draggable>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                ) : null
                            ) : null
                        )
                    ) : (
                        <div className="preview-placeholder">
                            <span className="main-title">{TEXT_CONSTANTS?.DESIGN_YOUR_FORM}</span>
                            <span className="sub-title">{TEXT_CONSTANTS?.ADD_NEW_FORM_FIELDS}</span>
                            <div className="icons-container">
                                <i className="icon-input"></i>
                                <i className="icon-image"></i>
                                <i className="icon-checkbox"></i>
                                <i className="icon-selection"></i>
                                <i className="icon-date-time"></i>
                                <i className="icon-range"></i>
                                <i className="icon-radio-buitton"></i>
                                <i className="icon-url"></i>
                                <i className="icon-file-upload"></i>
                                <i className="icon-action"></i>
                                <i className="icon-captcha"></i>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default PreviewCanvas
