import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { isEmpty } from "lodash"
import { BUTTON_CONSTANTS, PERMISSIONS, TEXT_CONSTANTS } from "../../../utils/constants"
import Button from "../../common/Button"
import Input from "../../common/Input"
import Select from "../../common/SelectBox"
import MultipleTab from "../../common/MultipleTab/MultipleTab"
import { fetchPlatformList } from "../../Category/api/action"
import { setConfig, fetchLanguageList, validateForceUpgrade } from "../api/action"

import "./style.scss"
import { toast } from "react-toastify"
import { getLanguageListData } from "../../Mulltilingual/api/action"
import { setLanguageInLocalStorage } from "../../../utils/common"
import CustomModal from "../../common/Modal"

export default function ForceUpgradeForm(props) {
    const { cloneData, addNewBackHandler, viewMode } = props

    const [languages, setLanguages] = useState([])
    const [platforms, setPlatforms] = useState([])
    const [showConfirmPopup, setShowConfirmPopup] = useState(false)
    const upgradeOptions = ["FORCE_UPGRADE", "RECOMMENDED_UPGRADE"]
    const [params, setParams] = useState({
        matchCriteria: cloneData?.matchCriteria || "EQUAL",
        messageMap: cloneData?.messageMap || {},
        platform: cloneData?.platform || "IOS",
        updateType: cloneData?.updateType || upgradeOptions[0],
        targetAppVersion: cloneData?.targetAppVersion || "",
        message: cloneData?.message || "",
        title: cloneData?.title || ""
    })
    useEffect(() => {
        fetchPlatforms()
        let languages =
            localStorage.getItem("languages") && JSON.parse(localStorage.getItem("languages"))
        if (languages) setLanguageData(languages)
        else fetchLanguage()
    }, [])

    const setLanguageData = (languages) => {
        if (languages) {
            languages.map((value) => {
                value.label = value.name
                value.code = value.code.toUpperCase()
            })
            setLanguages(languages)
        }
    }
    const fetchLanguage = async () => {
        const res = await dispatch(fetchLanguageList())
        if (res && res?.data) {
            let languageObj = setLanguageInLocalStorage(res?.data?.languages)
            setLanguageData(languageObj)
        }
    }
    const [languageCode, setLanguageCode] = useState("EN")
    const changeHandler = (name, value, selectedOptions) => {
        let paramsState = JSON.parse(JSON.stringify(params))
        paramsState[name] =
            selectedOptions && selectedOptions[0] ? selectedOptions[0]["name"] : value
        setParams(paramsState)
    }

    const multiLingualChangeHandler = (name, value) => {
        let paramsState = JSON.parse(JSON.stringify(params))
        if (paramsState["messageMap"][languageCode])
            paramsState["messageMap"][languageCode][name] = value
        else {
            paramsState["messageMap"][languageCode] = {}
            paramsState["messageMap"][languageCode][name] = value
        }
        setParams(paramsState)
    }

    let dispatch = useDispatch()

    const fetchPlatforms = async () => {
        const response = await dispatch(fetchPlatformList())
        if (!isEmpty(response)) {
            let platforms = removePlatforms(response?.data)
            setPlatforms(platforms)
        }
    }

    const setLanguage = (tab) => {
        setLanguageCode(tab.code)
    }

    const removePlatforms = (platforms) => {
        let requiredPlatforms = platforms.filter((value) => {
            return value.name !== "web"
        })
        return requiredPlatforms
    }

    const setPlatformConfig = async () => {
        const payload = {
            platform: params.platform,
            targetAppVersion: params.targetAppVersion,
            updateType: params.updateType,
            messageMap: params.messageMap
        }

        const config = await dispatch(setConfig(payload))

        if (config?.data && config.status) {
            toast.success("Successfully updated")
            addNewBackHandler()
        } else {
            toast.error("Something went wrong")
        }
    }
    const submit = async () => {
        const validate = await dispatch(validateForceUpgrade(params))
        if (validate?.data) {
            setShowConfirmPopup(true)
        } else {
            setPlatformConfig()
        }
    }

    const handleConfirmModal = () => {
        setPlatformConfig()
        setShowConfirmPopup(false)
    }

    const validation = () => {
        return (
            !!(
                isEmpty(params?.platform) ||
                isEmpty(params?.targetAppVersion) ||
                isEmpty(params?.updateType) ||
                isEmpty(params.messageMap?.["EN"]?.title) ||
                isEmpty(params.messageMap?.["EN"]?.message)
            ) || viewMode
        )
    }

    const getTabContent = () => {
        return (
            <div className="sub-component">
                <div className="form-input">
                    <Input
                        type={"text"}
                        labelText={"Title of the update"}
                        name={"title"}
                        required={true}
                        placeholder={"Enter here"}
                        value={
                            params["messageMap"]?.[languageCode]
                                ? params["messageMap"]?.[languageCode]?.["title"]
                                : ""
                        }
                        changeHandler={(name, value) => multiLingualChangeHandler(name, value)}
                        disabled={viewMode}
                        maxLength={25}
                    />
                </div>
                <div className="form-input">
                    <Input
                        type={"text"}
                        labelText={"Description of the update"}
                        name={"message"}
                        required={true}
                        placeholder={"Enter here"}
                        value={
                            params["messageMap"]?.[languageCode]
                                ? params["messageMap"]?.[languageCode]?.["message"]
                                : ""
                        }
                        changeHandler={(name, value) => multiLingualChangeHandler(name, value)}
                        disabled={viewMode}
                        maxLength={500}
                    />
                </div>
            </div>
        )
    }

    return (
        <div className="force-upgrade-form-container">
            <div className="form-input">
                <Select
                    key="platform"
                    placeholder="Select Platform"
                    options={
                        platforms &&
                        platforms.map((ele) => {
                            return {
                                id: ele?.name?.toUpperCase(),
                                name: ele?.name?.toUpperCase(),
                                value: ele?.name?.toUpperCase()
                            }
                        })
                    }
                    label={"Select Platform"}
                    required={true}
                    name={"platform"}
                    changeHandler={(name, value, options) => changeHandler(name, value, options)}
                    value={{ id: params["platform"], name: params["platform"] }}
                    isDisabled={viewMode}
                />
            </div>
            <div className="row-container">
                <div className="form-input">
                    <Input
                        type={"text"}
                        labelText={"Target App version"}
                        name={"targetAppVersion"}
                        required={true}
                        placeholder={"Enter here"}
                        value={params["targetAppVersion"]}
                        changeHandler={(name, value) => changeHandler(name, value)}
                        disabled={viewMode}
                        maxLength={10}
                    />
                </div>
            </div>
            <p className="note">
                Note: Entered app version will be excluded, Ex-2.0 means it is applicable for
                version 1.9 and below.
            </p>
            <div className="form-input">
                <Select
                    key="updateType"
                    placeholder="-select-"
                    options={
                        upgradeOptions &&
                        upgradeOptions.map((ele) => {
                            return {
                                id: ele,
                                name: ele,
                                value: ele
                            }
                        })
                    }
                    label={"Type of update"}
                    required={true}
                    name={"updateType"}
                    value={{ id: params["updateType"], name: params["updateType"] }}
                    changeHandler={(name, value) => changeHandler(name, value)}
                    isDisabled={viewMode}
                />
            </div>
            <div className="multiple-tab-container">
                <MultipleTab tabs={languages} callBack={setLanguage} />
                {getTabContent(languageCode)}
            </div>
            <Button
                bValue={"submit"}
                clickHandler={submit}
                cName={`btn primary-btn`}
                disabled={validation()}
            />
            {showConfirmPopup && (
                <CustomModal
                    showModal={showConfirmPopup}
                    submitHandler={handleConfirmModal}
                    cancelHandler={() => setShowConfirmPopup(false)}
                    closeOnSubmit={true}
                    submitButtonLabel={BUTTON_CONSTANTS?.YES}
                    showCancelButton={true}
                    cancelOnOutsideClick={false}
                    showCloseIcon={false}
                    submitBtnClass={"confirmation-submit-btn"}
                    cancelBtnClass={"confirmation-cancel-btn"}>
                    <div className="confirmation-modal-container">
                        <h3>{`${TEXT_CONSTANTS?.CONFIG_UPDATE_MESSAGE}`}</h3>
                    </div>
                </CustomModal>
            )}
        </div>
    )
}
