import React, { useEffect, useState } from "react"
import Select from "../SelectBox"
import { useDispatch, useSelector } from "react-redux"
import { getAllEnv } from "../Environments/api/action"
import "./style.scss"

const EnvDropdown = (props) => {
    const { refreshCall } = props

    const dispatch = useDispatch()

    const [selectedEnv, setSelectedEnv] = useState({ name: "MAIN", id: 1 })

    const envList = useSelector(
        (state) =>
            state.categoryRail.envList?.map((item) => ({
                ...item,
                name: item.environmentName,
                id: item.id
            })) || []
    )

    const handleSelect = async (name, value, selectedOption) => {
        await window.localStorage.setItem("environmentCode", selectedOption.environmentName)
        setSelectedEnv(selectedOption)
        refreshCall && refreshCall()
    }

    useEffect(() => {
        dispatch(getAllEnv())
    }, [])

    return (
        <div className="env-container">
            <label>Environment:</label>
            <Select
                value={
                    window.localStorage.getItem("environmentCode")
                        ? {
                              name: window.localStorage.getItem("environmentCode"),
                              id: 2
                          }
                        : selectedEnv
                }
                options={envList}
                cName={"env-type"}
                name={"env"}
                required
                placeholder={"Select"}
                changeHandler={handleSelect}
            />
        </div>
    )
}

export default EnvDropdown
