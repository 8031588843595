import React, { Component } from "react"
import VideoPlayerJS from "../../../../common/VideoJsMarkerPlayer"

import "./style.scss"
import { TEXT_CONSTANTS } from "../../../../../utils/constants"

class BingeMarker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isChecked: null,
            selectedTooltipLabel: "Intro"
        }
    }

    setSelectedMarker = (markerName) => {
        this.setState({
            selectedTooltipLabel: markerName
        })
    }

    renderBingeMarkerInfo = (bingeMarkerData) => {
        let timeFrames = []
        let introData
        introData = {
            start: bingeMarkerData?.intro_start ? bingeMarkerData?.intro_start : "NA",
            end: bingeMarkerData?.intro_end ? bingeMarkerData?.intro_end : "NA",
            toolTip: "Intro"
        }
        timeFrames.push(introData)
        introData = {
            start: bingeMarkerData?.recap_start ? bingeMarkerData?.recap_start : "NA",
            end: bingeMarkerData?.recap_end ? bingeMarkerData?.recap_end : "NA",
            toolTip: "Recap"
        }
        timeFrames.push(introData)
        introData = {
            start: bingeMarkerData?.credit_start ? bingeMarkerData?.credit_start : "NA",
            toolTip: "Credit"
        }
        timeFrames.push(introData)
        return timeFrames
    }

    render() {
        const { selectedTooltipLabel } = this.state
        const { contentMarkers, src } = this.props

        let response = contentMarkers ? contentMarkers : {}
        let timeFrames = this.renderBingeMarkerInfo(response)

        return (
            <div className="binge-marker-container">
                <>
                    <div className="marker-left-container">
                        <p className="start-end-label">
                            <span className="field1"></span>
                            <span className="field2">
                                {TEXT_CONSTANTS?.START}
                                <span className="second-label">
                                    ({TEXT_CONSTANTS?.SECS?.toLowerCase()})
                                </span>
                            </span>
                            <span className="field3"></span>
                            <span className="field4">
                                {TEXT_CONSTANTS?.END}
                                <span className="second-label">
                                    ({TEXT_CONSTANTS?.SECS?.toLowerCase()})
                                </span>
                            </span>
                        </p>
                        {timeFrames.map((value, index) => {
                            return (
                                <p
                                    onClick={() => this.setSelectedMarker(value.toolTip)}
                                    className={`marker-type ${
                                        value.toolTip === selectedTooltipLabel ? "active" : ""
                                    }`}
                                >
                                    <span className="field1"> {value.toolTip}</span>
                                    <span className="field2"> {value.start}</span>
                                    {value.toolTip !== "Credit" && (
                                        <span className="field3"> -</span>
                                    )}
                                    {value.toolTip !== "Credit" && (
                                        <span className="field4">{value.end}</span>
                                    )}
                                </p>
                            )
                        })}
                    </div>
                    <div className="marker-right-container">
                        <VideoPlayerJS
                            timeFrames={timeFrames}
                            selectedTooltipLabel={selectedTooltipLabel}
                            src={src}
                        />
                    </div>
                </>
            </div>
        )
    }
}

export default BingeMarker
