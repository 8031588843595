import React, { useEffect, useRef, useState } from "react"
import { createFilterDataListPayload } from "../../APIs/helper"
import { ACTION_TABLE_MODAL, PaginationOptions, SETUP_SPROTS_CONSTANTS } from "../../APIs/constant"
import { isClickedOutside, scrollPage } from "../../../../utils/common"
import { BUTTON_CONSTANTS, ERROR_MESSAGES } from "../../../../utils/constants"
import AddEditModal from "../AddEditModal"
import CustomModal from "../../../common/Modal"
import { useDispatch, useSelector } from "react-redux"
import {
    fetchSelectedSportsDataList,
    setupSportsDeleteSelectedData,
    sportsSetupSetPaginationData
} from "../../APIs/action"
import Checkbox from "../../../common/Checkbox"
import { toast } from "react-toastify"
import Pagination from "../../../common/Pagination"
import Loader from "../../../common/Loader"
import RenderSportsTableData from "./RenderSportsTableData"
import "./style.scss"

export default function SportsDataTable() {
    const sportsSetupState = useSelector((state) => state.sportsReducer?.sportsSetup)
    const { selectedTab, sportsData: sportsDataObj, paginationData, filterData } = sportsSetupState
    const sportsData = sportsDataObj?.data
    const [showActionItem, setShowActionItem] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [modalProperties, setModalProperties] = useState({
        currentActiveModal: null,
        selectedObj: {}
    })
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)

    const [payload, setPayload] = useState(
        createFilterDataListPayload(paginationData, selectedTab, filterData)
    )

    const actionItemRef = useRef(null)
    const dispatch = useDispatch()
    const isLoading = useSelector((state) => state.loader.isLoading)

    useEffect(() => {
        // Fetching Table data whenever Selected id, pagination changes
        if (payload?.pageEntityId) {
            dispatch(fetchSelectedSportsDataList(payload))
            scrollPage()
        }
    }, [payload])

    useEffect(() => {
        setPayload(createFilterDataListPayload(paginationData, selectedTab, filterData))
    }, [selectedTab?.id])

    useEffect(() => {
        // Updating pagination whenever table data updated
        pageCountSetup(sportsDataObj?.count)
    }, [sportsData])

    useEffect(() => {
        // Closing the table action item (edit, delete) dropdown on outside click
        const handleClickOutside = (event) => {
            showActionItem &&
                // !showModal &&
                isClickedOutside(event?.target, actionItemRef?.current) &&
                setShowActionItem("")
        }
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [showActionItem, showModal])

    const initiateModal = (val, selectedModal) => {
        setShowModal(true)
        setModalProperties({
            selectedObj: val,
            currentActiveModal: selectedModal
        })
    }

    const resetAddEditModalHandler = () => {
        setModalProperties({
            currentActiveModal: null,
            selectedObj: {}
        })
        setShowModal(false)
        setModalPropertyHandler(false)
    }

    const setModalPropertyHandler = (value) => {
        setDeleteModalStatus(value)
    }

    const deleteModalCheckboxHandler = (name, checked, id, cName) => {
        setModalPropertyHandler(checked)
    }

    const submitModalHandler = async () => {
        // Delete the selected Id and refetch the results
        let res = await dispatch(setupSportsDeleteSelectedData(modalProperties?.selectedObj?.id))
        if (res?.status) {
            toast.success(res?.message)
            resetAddEditModalHandler()
            // Reset pagination to avoid blank page
            let paginationDataCopy = { ...paginationData }
            paginationDataCopy.start = 0
            paginationDataCopy.pageCount = 0
            paginationDataCopy.currentPage = 0
            dispatch(sportsSetupSetPaginationData(paginationDataCopy))
            setPayload(createFilterDataListPayload(paginationDataCopy, selectedTab, filterData))
        } else {
            toast.error(res?.message ?? ERROR_MESSAGES?.SOMETHING_WENT_WRONG)
        }
    }

    const pageCountSetup = (userCount) => {
        const paginationDataCopy = { ...paginationData }
        let pageCount = Math.ceil(userCount / paginationDataCopy?.length)
        paginationDataCopy.pageCount = pageCount
        dispatch(sportsSetupSetPaginationData(paginationDataCopy))
    }

    const onPageChange = (e) => {
        const paginationDataCopy = { ...paginationData }
        const selectedPage = e.selected
        paginationDataCopy.start = selectedPage + 1
        let startValue = (paginationDataCopy["start"] - 1) * paginationDataCopy["length"]

        paginationDataCopy.currentPage = selectedPage
        paginationDataCopy.start = startValue
        dispatch(sportsSetupSetPaginationData(paginationDataCopy))
        setPayload(createFilterDataListPayload(paginationDataCopy, selectedTab, filterData))
    }
    const showPerPageChange = (name, value) => {
        const defaultOption = PaginationOptions.filter((item) => {
            if (value === item.id) {
                return item
            }
            return false
        })
        let paginationDataCopy = { ...paginationData }
        paginationDataCopy.selectedOption = defaultOption
        paginationDataCopy.start = 0
        paginationDataCopy.pageCount = 0
        paginationDataCopy.currentPage = 0
        paginationDataCopy.length = value
        dispatch(sportsSetupSetPaginationData(paginationDataCopy))
        setPayload(createFilterDataListPayload(paginationDataCopy, selectedTab, filterData))
    }

    return (
        <div className="vr-transcode-content-wrapper vr-upload-content-wrapper sports-data-table">
            {isLoading && <Loader></Loader>}
            <div className="tenant-list-container vr-content-holder">
                <RenderSportsTableData
                    sportsData={sportsData}
                    initiateModal={initiateModal}
                ></RenderSportsTableData>
            </div>

            <Pagination
                changeHandler={showPerPageChange}
                options={PaginationOptions}
                pageCount={paginationData?.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={onPageChange}
                selectedOption={paginationData?.selectedOption}
                currentPage={paginationData?.currentPage}
            />
            {modalProperties?.currentActiveModal === ACTION_TABLE_MODAL?.EDIT_MODAL && (
                <AddEditModal
                    showAddEditModal={showModal}
                    setAddEditModalHandler={resetAddEditModalHandler}
                    prefillData={modalProperties.selectedObj}
                ></AddEditModal>
            )}
            {modalProperties?.currentActiveModal === ACTION_TABLE_MODAL?.DELETE_MODAL && (
                <CustomModal
                    showModal={showModal}
                    cancelHandler={resetAddEditModalHandler}
                    submitHandler={submitModalHandler}
                    onClickCancelBtn={resetAddEditModalHandler}
                    showCloseIcon={true}
                    submitButtonLabel={BUTTON_CONSTANTS?.DELETE}
                    cancelButtonLabel={BUTTON_CONSTANTS?.NOT_NOW}
                    showCancelButton={true}
                    closeOnSubmit={true}
                    cancelOnOutsideClick={true}
                    modalContainerClass={""}
                    isSubmitDisabled={!deleteModalStatus}
                >
                    {deleteModal(
                        modalProperties?.selectedObj,
                        deleteModalCheckboxHandler,
                        deleteModalStatus
                    )}
                </CustomModal>
            )}
        </div>
    )
}

const deleteModal = (selectedObj, deleteModalCheckboxHandler, deleteModalStatus) => {
    return (
        <div className="delete-modal">
            <h2 className="delete-modal-header">{`Do you want to delete ${selectedObj?.meta?.name?.values?.["en"]} Category?`}</h2>
            <p className="delete-modal-description">{SETUP_SPROTS_CONSTANTS?.deleteModalLabel}</p>
            <Checkbox
                cName="check-box"
                id={"checkBox"}
                name={"delete-all"}
                isChecked={deleteModalStatus}
                changeHandler={deleteModalCheckboxHandler}
                labelText={SETUP_SPROTS_CONSTANTS?.checkboxLabel}
            />
        </div>
    )
}
