import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import get from "lodash/get"
import Input from "../../common/Input/index"
import Button from "../../common/Button/index"
import TextArea from "../../common/TextArea"
import FaqList from "./faqList"
import { createTextPage, fetchContentTextPage, updateCustomPageById } from "../APIs/action"
import { getLanguageObject } from "../../common/LanguageCardList"

import "../style.scss"
import { checkAllLanguageHasValue, isMultiLingual } from "../../../utils/common"
import { isEmpty } from "lodash"
import { DEFAULT_SELECTED_LANGUAGE_CODE } from "../../ContentNav/api/constants"
import LanguageDataConfirmationModal from "../../common/LanguageCardList/languageDataConfirmationModal"
import {
    BUTTON_CONSTANTS,
    ERROR_MESSAGES,
    PERMISSIONS,
    TEXT_CONSTANTS
} from "../../../utils/constants"
import { checkIfUserHasAccess } from "../../../utils/users"

class FaqForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            faqList: [],
            name: !isMultiLingual() ? "" : {},
            totalFaqList: [],
            err: {
                nameErr: "",
                questionErr: "",
                answerErr: ""
            },
            isDisable: true,
            faqData: {},
            emptyLangDataModal: false
        }
        this.canUpdateStatus = checkIfUserHasAccess([
            PERMISSIONS?.ENGAGE_UX_MANAGER_CUSTOM_PAGES_EDIT
        ])
    }

    componentDidMount() {
        this.updateLanguageObject()
        this.loadHandler()
    }

    updateLanguageObject = async () => {
        this.languageObject = await getLanguageObject()
    }

    loadHandler = async () => {
        const { isEdit, editableData, fetchContentTextPage } = this.props
        if (isEdit) {
            const resp = await fetchContentTextPage(editableData.id)
            if (resp && resp.status) {
                this.setState({
                    name: resp.data.name,
                    faqData: resp.data
                })
                this.getFaqsList(resp.data)
            }
        }
    }

    handleChange = (name, value, id) => {
        if (isMultiLingual()) {
            this.handleMultiLingualChange(name, value, id)
            return
        }
        let { faqList } = this.state
        if (name === "name") {
            this.setState({
                name: value,
                isDisable: false
            })
        } else {
            faqList.map((item, index) => {
                if (index === id) {
                    item[name] = value
                    // return item
                }
                return item
            })
            this.setState({
                faqList,
                isDisable: false
            })
        }
    }

    handleMultiLingualChange = (pageName, value, id) => {
        const { selectedLanguage } = this.props
        let { faqList, name } = this.state

        if (pageName === "name") {
            let updatedName = name
            updatedName[selectedLanguage.code] = value
            this.setState({
                name: updatedName,
                isDisable: false
            })
        } else {
            faqList.map((item, index) => {
                if (index === id) {
                    if (isEmpty(item[pageName])) {
                        item[pageName] = {
                            [selectedLanguage.code]: value
                        }
                    } else item[pageName][selectedLanguage.code] = value
                }
                return item
            })
            this.setState({
                faqList,
                isDisable: false
            })
        }
    }
    checkForEmpty = () => {
        const { name, question, answer } = this.state
        let count = 3
        if (name.trim().length) {
            count = count - 1
        }
        if (question.trim().length) {
            count = count - 1
        }
        if (answer.trim().length) {
            count = count - 1
        }

        if (!count) {
            this.setState({
                isDisable: false
            })
        }
    }

    validate = (name, faqList) => {
        let flag = 1
        let showOtherLangugeEmptyModal = false
        const err = {}
        if (isMultiLingual()) {
            const selectedCode = DEFAULT_SELECTED_LANGUAGE_CODE
            if (isEmpty(name) || !name[selectedCode]?.trim()) {
                err["nameErr"] = { [selectedCode]: "Name cannot be empty" }
                flag = 0
            }
            if (faqList.length > 0) {
                let faqQesErr = [],
                    faqAnsErr = []
                faqList.forEach((ques) => {
                    if (isEmpty(ques?.question[selectedCode])) {
                        faqQesErr.push("Question cannot be empty")
                        flag = 0
                    } else {
                        faqQesErr.push("")
                    }
                    if (isEmpty(ques?.answer[selectedCode])) {
                        faqAnsErr.push("Answer cannot be empty")
                        flag = 0
                    } else {
                        faqAnsErr.push("")
                    }
                    if (
                        !checkAllLanguageHasValue(ques?.answer, this.languageObject) ||
                        !checkAllLanguageHasValue(ques?.question, this.languageObject)
                    ) {
                        showOtherLangugeEmptyModal = true
                        // flag = 0;
                    }
                })
                err["questionErr"] = { [selectedCode]: faqQesErr }
                err["answerErr"] = { [selectedCode]: faqAnsErr }
            }
            if (
                !showOtherLangugeEmptyModal &&
                !checkAllLanguageHasValue(name, this.languageObject)
            ) {
                showOtherLangugeEmptyModal = true
                // flag = 0;
            }

            if (!flag) {
                toast.error(ERROR_MESSAGES.ENTER_DATA_IN_ENGLISH)
            }
        } else {
            if (name.length === 0) {
                err.nameErr = "Name cannot be empty"
                flag = 0
            }
        }

        this.setState({ err: { ...err } })
        return { flag, showOtherLangugeEmptyModal }
    }

    handleCustomFaqPage = async () => {
        const { createTextPage, handleClose, isEdit, editableData, customPages } = this.props
        const { type = "FAQ", name, faqList, totalFaqList } = this.state
        this.setState({
            isDisable: true
        })
        let payload = {
            pageTypes: type,
            name,
            questionAnswers: faqList,
            position: customPages ? customPages.length + 1 : 1
        }

        let validated = this.validate(name, faqList)
        if (!validated?.flag) {
            return null
        }
        this.setState({
            err: {
                nameErr: "",
                questionErr: "",
                answerErr: ""
            }
        })

        if (isEdit) {
            payload.id = editableData.id
            payload.questionAnswers = [...totalFaqList.questionAnswers, ...faqList]
        }
        for (let i = 0; i < payload.questionAnswers.length; i++) {
            payload.questionAnswers[i].position = i + 1
        }

        if (validated?.showOtherLangugeEmptyModal) {
            this.setState({
                emptyLangDataModal: true,
                finalPayload: payload,
                isDisable: false
            })
        } else {
            await this.submitData(payload, handleClose, createTextPage)
        }
    }

    submitData = async (payload, handleClose, createTextPage) => {
        const { updateCustomPageById } = this.props
        this.modalCancelHandler()
        const response = payload.id
            ? await updateCustomPageById(payload)
            : await createTextPage(payload)
        if (get(response, "status")) {
            toast.success(get(response, "message"))
            this.resetForm()
            handleClose()
        } else {
            toast.error(get(response, "message"))
        }
    }

    modalCancelHandler = () => {
        this.setState({
            emptyLangDataModal: false
        })
    }

    resetForm = () => {
        this.setState({
            name: "",
            question: "",
            answer: "",
            isReset: true
        })
    }

    //------------------------------------------------------------

    addFaqForm = () => {
        this.setState((prevState) => ({
            faqList: [...prevState.faqList, { question: "", answer: "" }]
        }))
    }

    addMultilingualFaqForm = () => {
        this.setState((prevState) => ({
            faqList: [
                ...prevState.faqList,
                {
                    question: JSON.parse(JSON.stringify(this.languageObject)),
                    answer: JSON.parse(JSON.stringify(this.languageObject))
                }
            ]
        }))
    }

    removeFaqForm = (idx) => {
        let values = [...this.state.faqList]
        if (idx > 0) {
            values.splice(idx, 1)
        }

        this.setState({
            faqList: values
        })
    }

    getContent = (content) => {
        this.setState({
            content: content
        })
    }

    getFaqsList = (faqs) => {
        this.setState({
            totalFaqList: faqs
        })
    }

    render() {
        const { handleClose, isEdit, editableData, selectedLanguage, createTextPage } = this.props
        let { faqList, name, err, isDisable, faqData, emptyLangDataModal, finalPayload } =
            this.state
        return (
            <div className="vd-addCustomPages faqContainer">
                {isEdit ? (
                    <FaqList
                        selectedLanguage={selectedLanguage}
                        editableData={editableData}
                        getFaqsList={this.getFaqsList}
                        faqData={faqData}
                    />
                ) : (
                    ""
                )}
                <div className="add-user-form faq-form">
                    <label className="editor-description">
                        {TEXT_CONSTANTS?.PAGE_NAME?.toUpperCase()} <span>*</span>
                    </label>
                    <Input
                        type="text"
                        changeHandler={this.handleChange}
                        name="name"
                        id="faqName"
                        value={
                            !isMultiLingual()
                                ? name
                                : name[selectedLanguage.code]
                                ? name[selectedLanguage.code]
                                : ""
                        }
                        errMsg={err?.nameErr?.[selectedLanguage.code]}
                        maxLength={25}
                        disabled={!this.canUpdateStatus}
                    />
                    {faqList.map((val, idx) => {
                        return (
                            <div className="ques-ans-block" key={idx}>
                                <label className="editor-description">
                                    {TEXT_CONSTANTS?.QUESTION?.toUpperCase()} <span>*</span>
                                </label>
                                <Input
                                    type="text"
                                    changeHandler={(name, value) =>
                                        this.handleChange(name, value, idx)
                                    }
                                    name={`question`}
                                    id={idx}
                                    value={
                                        !isMultiLingual()
                                            ? this.state.faqList[idx].question
                                            : !isEmpty(
                                                  this.state.faqList[idx]?.question[
                                                      selectedLanguage.code
                                                  ]
                                              )
                                            ? this.state.faqList[idx]?.question[
                                                  selectedLanguage.code
                                              ]
                                            : " "
                                    }
                                    errMsg={err.questionErr?.[selectedLanguage.code]?.[idx]}
                                />
                                <label className="editor-description">
                                    {TEXT_CONSTANTS?.ANSWER?.toUpperCase()} <span>*</span>
                                </label>
                                <TextArea
                                    rows={4}
                                    cols={5}
                                    changeHandler={(name, value) =>
                                        this.handleChange(name, value, idx)
                                    }
                                    name={`answer`}
                                    id={idx}
                                    value={
                                        !isMultiLingual()
                                            ? this.state.faqList[idx].answer
                                            : !isEmpty(
                                                  this.state.faqList[idx]?.answer[
                                                      selectedLanguage.code
                                                  ]
                                              )
                                            ? this.state.faqList[idx]?.answer[selectedLanguage.code]
                                            : " "
                                    }
                                    errMsg={err.answerErr?.[selectedLanguage.code]?.[idx]}
                                />
                                {idx > 0 ? (
                                    <>
                                        {" "}
                                        <button
                                            className="btn addfaqBtn "
                                            onClick={() => this.removeFaqForm(idx)}>
                                            <i className="icon-minus"></i>
                                        </button>{" "}
                                        <span className="addbtn">
                                            {BUTTON_CONSTANTS?.DELETE?.toUpperCase()}
                                        </span>
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>
                        )
                    })}
                    {this.canUpdateStatus && (
                        <>
                            <button
                                className="btn addfaqBtn"
                                onClick={
                                    !isMultiLingual()
                                        ? this.addFaqForm
                                        : this.addMultilingualFaqForm
                                }>
                                <i className="icon-plus"></i>
                            </button>{" "}
                            <span className="addbtn">{BUTTON_CONSTANTS?.ADD?.toUpperCase()}</span>
                        </>
                    )}
                    <div className="user-btn">
                        {this.canUpdateStatus && (
                            <Button
                                bValue={BUTTON_CONSTANTS?.SAVE?.toUpperCase()}
                                cName="btn primary-btn"
                                clickHandler={this.handleCustomFaqPage}
                                disabled={isDisable}
                            />
                        )}
                        <Button
                            bValue={BUTTON_CONSTANTS?.CANCEL?.toUpperCase()}
                            clickHandler={handleClose}
                            cName="btn cancel-btn"
                        />
                    </div>
                    {emptyLangDataModal && (
                        <LanguageDataConfirmationModal
                            isModalOpen={emptyLangDataModal}
                            submitHandler={() =>
                                this.submitData(finalPayload, handleClose, createTextPage)
                            }
                            cancelHandler={this.modalCancelHandler}></LanguageDataConfirmationModal>
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                createTextPage,
                fetchContentTextPage,
                updateCustomPageById
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FaqForm)
