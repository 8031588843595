import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { toast } from "react-toastify"

import AddTab from "./AddTab/index"
import get from "lodash/get"
import CategoryHeader from "./CategoryHeader/index"
import CategoryDetails from "./CategoryDetails"
import RailListView from "./RailListView"
import Button from "../common/Button"
import Checkbox from "../common/Checkbox"
import RailsListing from "./RailsListing"
import {
    fetchPlatformList,
    fetchFilteredRailById,
    softDeleteRail,
    deactivateRailById,
    createRail,
    fetchCategory,
    railReposition,
    refreshRails,
    fetchAllCategory,
    fetchFastChannel,
    publishUnpublishRailAPI
} from "./api/action"
import { scrollPage } from "../../utils/common"
import { fetchSelectedSportsDataList } from "../Sports/APIs/action"

import "./style.scss"
import {
    BUTTON_CONSTANTS,
    ERROR_MESSAGES,
    PERMISSIONS,
    TEXT_CONSTANTS
} from "../../utils/constants"
import { AuthWrapper } from "../common/AuthWrapper"
import Pagination from "../common/Pagination"
import { VOD_SPORTS_TAB, options } from "./api/constant"
import AddNewRail from "./AddNewRail/AddNewRail"
import CategoryList from "./CategoryList"
import MultipleTab from "../common/MultipleTab/MultipleTab"
import { formatSportsData } from "./api/helper"
import EnvDropdown from "../common/EnvDropdown"

class Category extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menuTab: [],
            categoryList: [],
            allCategoryList: [],
            isListView: false,
            addMenuBtnClick: false,
            platformList: [],
            editRailBtnClick: false,
            published: null,
            idToEdit: null,
            addRailBtnClick: false,
            currentPlatform: 0,
            platformName: "",
            selectedTab: 0,
            selectedTabName: "",
            railsList: [],
            selectedRailsList: [],
            railTypeList: [],
            heroBannerTypeList: [],
            isShowRailPopup: false,
            isOrderChange: false,
            isLoading: false,
            isLoadingContent: false,
            unpublishPopup: false,
            deletePopup: false,
            bothOptionSelected: true,
            massPublish: false,
            pageCount: 0,
            currentPage: 0,
            selectedOption: options[2],
            filterOptions: {
                pageSize: options[2]?.id,
                pageNo: 0
            },
            selectedSportsTab: 0,
            scrollDirection: null
        }
        this.lastScrollY = 0
    }
    componentDidMount() {
        if (this.props.tabs?.length > 0) {
            this.fetchAllPlatformAndCategory()
        }
        this.props.parent === VOD_SPORTS_TAB.CREATE_MANAGE_PAGE &&
            window.addEventListener("scroll", this.updateScrollDirection)
    }

    componentWillUnmount() {
        this.props.parent === VOD_SPORTS_TAB.CREATE_MANAGE_PAGE &&
            window.removeEventListener("scroll", this.updateScrollDirection)
    }

    componentDidUpdate(prevProps) {
        if (this.props.tabs?.length > 0 && prevProps.tabs !== this.props.tabs) {
            this.fetchAllPlatformAndCategory()
        }
    }

    updateScrollDirection = () => {
        const scrollY = window.scrollY
        const direction = scrollY > this.lastScrollY ? "down" : "up"
        if (direction !== this.state.scrollDirection) {
            this.setState({ scrollDirection: direction })
        }
        this.lastScrollY = scrollY > 0 ? scrollY : 0
    }

    fetchAllPlatformAndCategory = async () => {
        let { currentPlatform, platformName } = this.state
        const { fetchPlatformList, fetchAllCategory, fetchFastChannel } = this.props
        this.setState({
            isLoading: true
        })
        let [{ value: resp }, { value: response }] = await Promise.allSettled([
            fetchAllCategory(),
            fetchPlatformList()
        ])

        let newPlatform = currentPlatform,
            newPlatformName = platformName

        let fastChannel =
            process.env.REACT_APP_ENABLE_FAST_CHANNEL === "true" ? await fetchFastChannel() : []
        fastChannel = fastChannel ? fastChannel : []
        if (resp && resp?.status) {
            const sportContentId = resp?.data?.find((item) => item?.hasPageCategory)
            this.setState({
                allCategoryList: resp?.data,
                sportContentId
            })
        }

        if (response && response?.status) {
            if (!newPlatform && response?.data?.length) {
                newPlatform = response?.data?.[0]?.id
                newPlatformName = response?.data?.[0]?.name
            }

            this.setState(
                {
                    platformList: response.data,
                    fastChannel: fastChannel,
                    currentPlatform: newPlatform,
                    platformName: newPlatformName
                },
                () => {
                    this.fetchCategoryAndRailList()
                }
            )
        }
    }

    fetchCategoryAndRailList = () => {
        scrollPage() // To scroll page up while rendering data
        // Added 4s loader as CMS is not getting updated railsRails from BE
        this.fetchCategoryListResponse()
    }

    fetchCategoryListResponse = async () => {
        let { selectedTab, selectedTabName, selectedSportsTab, currentPlatform } = this.state
        const { fetchCategory, parent, fetchSelectedSportsDataList } = this.props
        let newSelectedTab = selectedTab,
            newSelectedTabName = selectedTabName,
            newSelectedSportsTab = selectedSportsTab,
            newPlatform = currentPlatform

        let updatedStateData = {}
        if (parent !== VOD_SPORTS_TAB.VOD) {
            if (parent === VOD_SPORTS_TAB.CREATE_MANAGE_PAGE) {
                // API to fetch selected Sports TAB data
                if (!selectedSportsTab) {
                    newSelectedSportsTab = this.props?.tabs?.[0]
                }
                let categoryPayload = {
                    length: -1,
                    start: -1,
                    pageEntityId: newSelectedSportsTab?.pageEntityId
                }
                const sportsData = await fetchSelectedSportsDataList(categoryPayload)

                const formatedData = formatSportsData(sportsData?.data, "name")

                if (!selectedTab && formatedData?.length) {
                    newSelectedTab = formatedData?.[0]?.id
                    newSelectedTabName = formatedData?.[0]?.name
                }

                updatedStateData = {
                    categoryList: formatSportsData(sportsData?.data, "name"),
                    selectedTabName: newSelectedTabName,
                    selectedTab: newSelectedTab
                }
            } else {
                updatedStateData = {
                    selectedTabName: "HomePage"
                }
            }
        } else {
            const fetchCategoryResponse = await fetchCategory(newPlatform)

            if (fetchCategoryResponse && fetchCategoryResponse.status) {
                let tabList = fetchCategoryResponse.data.sort(
                    (a, b) => parseInt(a.position) - parseInt(b.position)
                )
                if (parent !== VOD_SPORTS_TAB.SPORTS_HOME) {
                    tabList = tabList?.filter((item) => !item?.hasPageCategory)
                }
                if (!selectedTab && fetchCategoryResponse.data.length) {
                    newSelectedTab = tabList[0].id
                    newSelectedTabName = tabList[0].name
                }
                updatedStateData = {
                    categoryList: tabList,
                    selectedTabName: newSelectedTabName,
                    selectedTab: newSelectedTab
                }
            }
        }

        this.setState(
            (prevState) => {
                return {
                    ...prevState,
                    ...updatedStateData
                }
            },
            () => {
                this.fetchRailListData()
            }
        )
    }

    fetchRailListData = () => {
        // refresh interval of elastic is 1 second and cannot be made less than 1 second
        setTimeout(() => {
            this.fetchUpdatedRailListData()
        }, 2000)
    }

    fetchUpdatedRailListData = async () => {
        const { parent, fetchFilteredRailById } = this.props
        const { published, filterOptions, sportContentId, selectedTab, currentPlatform } =
            this.state
        const vodTabSelected = parent === VOD_SPORTS_TAB.VOD

        const payLoad = {
            categoryId: vodTabSelected ? selectedTab : sportContentId?.id,
            ...(vodTabSelected && { categoryPublished: true }),
            platformId: currentPlatform,
            limit: filterOptions?.pageSize,
            offset: filterOptions?.pageNo,
            ...(!vodTabSelected && {
                pageCategoryId: selectedTab, // page Category ID ex cricket, tennis or virat
                pageCategoryRail: parent === VOD_SPORTS_TAB.CREATE_MANAGE_PAGE ? true : false // False for sports home page
            })
        }

        let updatedStateObj = {
            isLoading: false
        }
        let responseRailListApi = await fetchFilteredRailById(payLoad)
        if (responseRailListApi && responseRailListApi.status) {
            let tempRailsList = responseRailListApi.data.results
            tempRailsList.sort(function (a, b) {
                return a.position - b.position
            })
            let heroBannerTypeList = [],
                railTypeList = []
            tempRailsList.forEach((item) => {
                if (item.railType === "HERO_BANNER") {
                    heroBannerTypeList.push(item)
                } else {
                    railTypeList.push(item)
                }
            })
            this.pageCountSetup(responseRailListApi?.data?.totalResults)

            updatedStateObj = {
                ...updatedStateObj,
                railsList: tempRailsList,
                railTypeList,
                heroBannerTypeList
            }
        }
        this.setState({
            ...updatedStateObj
        })
        scrollPage() // To scroll page up while rendering data
    }

    onPageChange = (e) => {
        const { filterOptions } = this.state
        const selectedPage = e.selected
        filterOptions.pageNo = selectedPage * filterOptions?.pageSize
        this.setState(
            {
                currentPage: selectedPage,
                filterOptions,
                isLoading: true
            },
            () => {
                scrollPage()
                this.fetchRailListData()
            }
        ) // To scroll page up while rendering data
    }
    showPerPageChange = (name, value) => {
        let { filterOptions } = this.state
        filterOptions["pageSize"] = value
        filterOptions["pageNo"] = 0
        let defaultOption = options.filter((item) => {
            if (value === item.id) {
                return item
            }
            return false
        })

        this.setState(
            {
                filterOptions,
                selectedOption: defaultOption,
                currentPage: 0,
                isLoading: true
            },
            () => {
                this.fetchRailListData()
            }
        )
    }

    pageCountSetup = (userCount) => {
        const { pageSize } = this.state.filterOptions
        let pageCount = Math.ceil(userCount / pageSize)
        this.setState({
            pageCount
        })
    }

    handleChangeView = (viewType) => {
        const { isListView } = this.state
        if (viewType === "list" && !isListView) {
            this.setState({ isListView: !isListView })
        }
        if (viewType === "grid" && isListView) {
            this.setState({ isListView: !isListView })
        }
    }
    handleBackBtn = () => {
        const { addMenuBtnClick, addRailBtnClick } = this.state
        if (addMenuBtnClick) {
            this.addMenuBtn()
        } else if (addRailBtnClick) {
            this.addRailBtn()
        } else {
            this.editRailBtn()
        }
    }
    addMenuBtn = () => {
        const { addMenuBtnClick } = this.state
        this.setState(
            {
                addMenuBtnClick: !addMenuBtnClick,
                isLoading: true
            },
            () => {
                this.fetchCategoryAndRailList()
                this.fetchAllPlatformAndCategory()
            }
        )
    }
    editRailBtn = (id) => {
        const { editRailBtnClick } = this.state
        this.setState(
            {
                editRailBtnClick: !editRailBtnClick,
                idToEdit: id,
                isLoading: true
            },
            () => {
                this.fetchRailListData()
            }
        )
    }
    addRailBtn = () => {
        const { addRailBtnClick } = this.state
        this.setState(
            {
                addRailBtnClick: !addRailBtnClick,
                isLoading: true
            },
            () => {
                this.fetchRailListData()
            }
        )
    }
    handlePlatformChange = (item) => {
        this.setState(
            {
                currentPlatform: item.id,
                platformName: item.name,
                selectedTab: this.state.categoryList?.[0]?.id,
                selectedTabName: this.state.categoryList?.[0]?.name,
                filterOptions: {
                    ...this.state.filterOptions,
                    pageNo: 0
                },
                currentPage: 0,
                isLoading: true
            },
            () => {
                this.fetchCategoryAndRailList()
            }
        )
    }

    handleTabClick = (item) => {
        this.setState(
            {
                selectedTab: item.id,
                selectedTabName: item.name,
                published: null,
                filterOptions: {
                    ...this.state.filterOptions,
                    pageNo: 0
                },
                currentPage: 0,
                isLoading: true
            },
            () => {
                this.fetchRailListData()
            }
        )
    }

    handleRefreshRails = async () => {
        const { refreshRails } = this.props
        this.setState({
            isLoading: true
        })
        const response = await refreshRails()
        if (get(response, "status")) {
            toast.success(get(response, "message"))
        } else {
            toast.error(get(response, "message"))
        }

        this.fetchRailListData()
    }

    handleDeleteRail = async (id) => {
        if (!Array.isArray(id)) {
            id = [id]
        }
        let response = await this.props.softDeleteRail(id)
        if (response && response.status) {
            this.setState({
                selectedRailsList: [],
                isLoading: true
            })
            toast.success(response.message)
            this.fetchRailListData()
        }
    }

    togglePublishRail = async (id, publish) => {
        if (Array.isArray(id)) {
            let payLoad = { ids: id, publish: publish }
            let response = await this.props.deactivateRailById(payLoad)
            if (response && response.status) {
                this.setState({
                    selectedRailsList: [],
                    isLoading: true
                })
                this.fetchRailListData()
                toast.success(response.message)
            }
        } else {
            let payLoad = { ids: [id], publish: publish }
            let response = await this.props.deactivateRailById(payLoad)
            if (response && response.status) {
                toast.success(response.message)
                this.setState({
                    isLoading: true
                })
                this.fetchRailListData()
            }
        }
    }

    selectAllRail = (name, checked, id) => {
        let rails = this.state.railsList
        if (checked) {
            let list = rails.map((item) => item.id)
            this.setState({
                selectedRailsList: list,
                bothOptionSelected: false
            })
        } else {
            this.setState({
                selectedRailsList: []
            })
        }
    }

    selectRail = (name, checked, id) => {
        let selectedRailsList = this.state.selectedRailsList
        if (checked) {
            selectedRailsList = [...this.state.selectedRailsList, id]
        } else {
            selectedRailsList = selectedRailsList.filter((item) => {
                if (item !== id) {
                    return item
                }
                return false
            })
        }

        let count = 0
        let count1 = 0
        let publishUnpublishBothSelected = true
        selectedRailsList.forEach((item) => {
            this.state.railsList.forEach((ele) => {
                if (ele.id === item && !ele.active) {
                    count = count + 1
                }
                if (ele.id === item && ele.active) {
                    count1 = count1 + 1
                }
            })
        })
        if (count !== selectedRailsList.length && count1 !== selectedRailsList.length) {
            publishUnpublishBothSelected = false
        }

        this.setState({
            selectedRailsList: selectedRailsList,
            massPublish: count === selectedRailsList.length && true,
            bothOptionSelected: publishUnpublishBothSelected
        })
    }

    handlePublishFilter = (id, value) => {
        let publishedRail = null
        if (value === "publish") {
            publishedRail = true
        } else if (value === "unpublish") {
            publishedRail = false
        }
        this.setState(
            {
                published: publishedRail,
                filterOptions: {
                    ...this.state.filterOptions,
                    pageNo: 0
                },
                currentPage: 0,
                isLoading: true
            },
            () => this.fetchCategoryAndRailList()
        )
    }
    isSelected = (id) => {
        const { selectedRailsList } = this.state
        if (selectedRailsList.includes(id)) {
            return true
        } else {
            return false
        }
    }

    changeRailsSequence = async (rails) => {
        let updatedRails = rails.map((item, index) => {
            item.position = index
            return item
        })
        const response = await this.props.railReposition(updatedRails)
        if (response && response.status) {
            toast.success(response.message)
            this.setState({
                isLoading: true
            })
            this.fetchRailListData()
        }
    }

    handleMassClose = () => {
        this.setState({
            selectedRailsList: []
        })
    }
    mainLoader = () => {
        return (
            <div className="main-loader vr-categoryLoader">
                <div className="subHeader-loader">
                    <span></span>
                    <span></span>
                </div>
                <div className="container-loader">
                    <div className="list-loader"></div>
                    <div className="rail-loader">
                        <div className="rail-head"></div>
                        <div className="rail-block"></div>
                    </div>
                </div>
            </div>
        )
    }

    toggleConfirmationPopup = (e, popupKey) => {
        e.stopPropagation()
        this.setState((prevState) => {
            return {
                unpublishPopup: false,
                deletePopup: false,
                [popupKey]: !prevState[popupKey]
            }
        })
    }

    selectedTabHandler = (tab) => {
        // API call to fetch the updated data based on tab category changes
        this.setState(
            (prevState) => {
                return {
                    ...prevState,
                    selectedSportsTab: tab,
                    selectedTab: 0,
                    isLoading: true
                }
            },
            () => {
                this.fetchCategoryAndRailList()
            }
        )
    }

    publishUnpublishRail = async (selectedListItem) => {
        const { publishUnpublishRailAPI } = this.props
        // API calls to publish and upublish rail
        const payload = {
            ids: [selectedListItem?.id],
            toPublish: selectedListItem?.value
        }
        const response = await publishUnpublishRailAPI(payload)
        if (response && response?.status) {
            toast.success(response?.message)
            this.setState(
                (prevState) => {
                    return {
                        ...prevState,
                        isLoading: true
                    }
                },
                () => {
                    this.fetchCategoryAndRailList()
                }
            )
        } else {
            toast.error(response?.message || ERROR_MESSAGES.SOMETHING_WENT_WRONG)
        }
    }

    render() {
        const {
            categoryList,
            addMenuBtnClick,
            allCategoryList,
            addRailBtnClick,
            isListView,
            platformList,
            currentPlatform,
            selectedTab,
            selectedTabName,
            platformName,
            railsList,
            editRailBtnClick,
            idToEdit,
            selectedRailsList,
            published,
            heroBannerTypeList,
            railTypeList,
            isLoading,
            unpublishPopup,
            deletePopup,
            bothOptionSelected,
            massPublish,
            fastChannel,
            pageCount,
            selectedOption,
            currentPage,
            scrollDirection,
            selectedSportsTab
        } = this.state
        const { pageDetails, parent } = this.props
        const { toggleConfirmationPopup } = this
        return (
            <div className="vd-category">
                <EnvDropdown refreshCall={this.fetchAllPlatformAndCategory} />
                <div className="vr-cont-head mb">
                    {/* TODO: Replace below with the reusable pageTitleComponent */}
                    <div className="vr-cont-title">
                        <span className="vr-breadcrum">
                            {pageDetails?.menuTitle}
                            <i className="icon-arrow-right1" />
                            <span>{pageDetails?.pageTitle?.toLowerCase()}</span>
                        </span>
                        <h2 className="breadcrum-header">
                            {pageDetails?.pageTitle?.toUpperCase()}
                        </h2>
                        <Button
                            cName="btn border-btn refresh-btn"
                            bValue={BUTTON_CONSTANTS?.REFRESH_RAIL}
                            clickHandler={this.handleRefreshRails}
                        />
                    </div>
                    {!(addMenuBtnClick || editRailBtnClick || addRailBtnClick) && (
                        <div className="platform">
                            {platformList?.map((item, index) => {
                                return (
                                    <i
                                        className={`icon-${item.name.toLowerCase()} ${
                                            currentPlatform === item.id && "activeIcon"
                                        }`}
                                        id={item.id}
                                        onClick={() => this.handlePlatformChange(item)}>
                                        <span className="platform-name">{item.name}</span>
                                    </i>
                                )
                            })}
                        </div>
                    )}
                    {(addMenuBtnClick || editRailBtnClick || addRailBtnClick) && (
                        <div className="vr-cont-action">
                            <Button
                                cName="btn border-btn"
                                bValue={BUTTON_CONSTANTS?.BACK}
                                clickHandler={this.handleBackBtn}
                                leftIcon="icon-arrow-left1"
                            />
                        </div>
                    )}
                </div>
                {addMenuBtnClick || editRailBtnClick || addRailBtnClick || (
                    <MultipleTab
                        tabs={this.props?.tabs}
                        callBack={this.selectedTabHandler}
                        className={"category-tabs"}
                        selectedSportsTab={selectedSportsTab?.position}></MultipleTab>
                )}
                <div className="vr-content-holder">
                    {addMenuBtnClick || editRailBtnClick || addRailBtnClick || (
                        <>
                            <div className="header">
                                <CategoryHeader
                                    categoryList={categoryList}
                                    platform={currentPlatform}
                                    platformArr={platformList}
                                    handleClick={this.addMenuBtn}
                                    selectedTab={selectedTab}
                                    selectedTabName={selectedTabName}
                                    platformName={platformName}
                                    handleTabClick={this.handleTabClick}
                                    handlePublishFilter={this.handlePublishFilter}
                                    isListView={isListView}
                                    handleChangeView={this.handleChangeView}
                                    published={published}
                                    handlePlatformChange={this.handlePlatformChange}
                                />
                            </div>
                            {isLoading ? (
                                this.mainLoader()
                            ) : (
                                <div className="vd-categoryContainer">
                                    {parent !== VOD_SPORTS_TAB.SPORTS_HOME && (
                                        <CategoryList
                                            categoryList={categoryList}
                                            handleListClick={this.handleTabClick}
                                            selectedTab={selectedTab}
                                            addMenuBtn={this.addMenuBtn}
                                            showAddMenuBtn={parent === VOD_SPORTS_TAB.VOD}
                                            parent={parent}
                                            publishUnpublishRail={
                                                this.publishUnpublishRail
                                            }></CategoryList>
                                    )}
                                    <div className="rails-listing">
                                        <RailsListing
                                            rails={railsList}
                                            heroBannerTypeList={heroBannerTypeList}
                                            fastChannel={fastChannel}
                                            railTypeList={railTypeList}
                                            handleClick={this.addRailBtn}
                                            changeRailsSequence={this.changeRailsSequence}
                                        />
                                    </div>
                                    <div className="category-details">
                                        {isListView ? (
                                            <RailListView
                                                rails={railsList}
                                                handleDelete={this.handleDeleteRail}
                                                togglePublishRail={this.togglePublishRail}
                                                handleClick={this.editRailBtn}
                                                selectedRailsList={selectedRailsList}
                                                selectRail={this.selectRail}
                                                selectAllRail={this.selectAllRail}
                                                isSelected={this.isSelected}
                                                platformList={platformList}
                                                categoryList={allCategoryList}
                                            />
                                        ) : (
                                            <CategoryDetails
                                                rails={railsList}
                                                platformList={platformList}
                                                handleDelete={this.handleDeleteRail}
                                                togglePublishRail={this.togglePublishRail}
                                                fastChannel={fastChannel}
                                                refresh={this.fetchCategoryAndRailList}
                                                categoryList={allCategoryList}
                                                selectRail={this.selectRail}
                                                handleClick={this.editRailBtn}
                                                selectedRailsList={selectedRailsList}
                                                isSelected={this.isSelected}
                                            />
                                        )}
                                    </div>
                                </div>
                            )}
                        </>
                    )}

                    {addMenuBtnClick && (
                        <AddTab
                            handleBackClick={this.addMenuBtn}
                            categoryList={allCategoryList}
                            platformList={platformList}
                        />
                    )}
                    {(addRailBtnClick || editRailBtnClick) && (
                        <AddNewRail
                            id={idToEdit}
                            rails={railsList}
                            selectedPlatform={currentPlatform}
                            selectedTab={selectedTab}
                            selectedTabName={selectedTabName}
                            handleBackClick={this.handleBackBtn}
                            parent={parent}
                            allCategoryList={allCategoryList}
                        />
                    )}
                </div>
                {!addMenuBtnClick && !editRailBtnClick && !addRailBtnClick && (
                    <Pagination
                        options={options}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        selectedOption={selectedOption}
                        onPageChange={this.onPageChange}
                        changeHandler={this.showPerPageChange}
                        currentPage={currentPage}
                        isAutoFocus={false}
                    />
                )}

                <div className={`menuComponent ${selectedRailsList.length ? "showMe" : ""}`}>
                    <div className="selectionValue">
                        <div className="selectAllCheckBox">
                            <Checkbox
                                cName="check-box"
                                id={"checkBox"}
                                name={"select all"}
                                isChecked={
                                    selectedRailsList.length === railsList.length ? true : false
                                }
                                changeHandler={this.selectAllRail}
                            />
                        </div>
                        <div>
                            <span className="selected-count">{selectedRailsList.length}</span>
                            Rail{selectedRailsList.length > 1 ? "s" : ""} Selected
                        </div>
                    </div>
                    <div className="selectionButtons">
                        <AuthWrapper permissions={[PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_EDIT]}>
                            <div
                                className={unpublishPopup ? "toolsBtn mrl0 show" : "toolsBtn mrl0"}
                                onClick={(e) => toggleConfirmationPopup(e, "unpublishPopup")}>
                                {bothOptionSelected && (
                                    <div>
                                        <i
                                            className={
                                                massPublish
                                                    ? "toolsBtn icon-published"
                                                    : "toolsBtn icon-unpublish"
                                            }
                                        />
                                        <span className="tooltiptext">
                                            {massPublish ? "Publish" : "Unpublish"}
                                        </span>
                                        <div className="confirmation-popup">
                                            <div className="message">
                                                {massPublish ? (
                                                    <span className="type">
                                                        {TEXT_CONSTANTS?.PUBLISHING_RAIL_MESSAGE}
                                                    </span>
                                                ) : (
                                                    <span className="type">
                                                        {" "}
                                                        {TEXT_CONSTANTS?.UNPUBLISHING_RAIL_MESSAGE}
                                                    </span>
                                                )}
                                            </div>
                                            <button
                                                type="button"
                                                className="btn cancel-btn"
                                                onClick={() =>
                                                    this.togglePublishRail(
                                                        selectedRailsList,
                                                        massPublish
                                                    )
                                                }>
                                                {massPublish ? (
                                                    <>{TEXT_CONSTANTS?.PUBLISH}</>
                                                ) : (
                                                    <> {TEXT_CONSTANTS?.UNPUBLISH}</>
                                                )}
                                            </button>
                                            <button
                                                type="button"
                                                className="btn primary-btn"
                                                onClick={(e) =>
                                                    toggleConfirmationPopup(e, "unpublishPopup")
                                                }>
                                                {BUTTON_CONSTANTS?.CANCEL}
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div
                                className={
                                    deletePopup ? "toolsBtn icon-del show" : "toolsBtn icon-del"
                                }
                                onClick={(e) => toggleConfirmationPopup(e, "deletePopup")}>
                                <span className="tooltiptext">{BUTTON_CONSTANTS?.DELETE}</span>
                                <div className="confirmation-popup">
                                    <div className="message">{TEXT_CONSTANTS?.DELETE_RAIL_MSG}</div>
                                    <button
                                        type="button"
                                        className="btn cancel-btn"
                                        onClick={() => this.handleDeleteRail(selectedRailsList)}>
                                        {BUTTON_CONSTANTS?.DELETE}
                                    </button>
                                    <button
                                        type="button"
                                        className="btn primary-btn"
                                        onClick={(e) => toggleConfirmationPopup(e, "deletePopup")}>
                                        {BUTTON_CONSTANTS?.CANCEL}
                                    </button>
                                </div>
                            </div>
                        </AuthWrapper>
                        <div className="toolsBtn icon-close" onClick={this.handleMassClose}>
                            <span className="tooltiptext">{BUTTON_CONSTANTS?.CLOSE}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                fetchPlatformList,
                fetchFilteredRailById,
                deactivateRailById,
                softDeleteRail,
                createRail,
                fetchCategory,
                railReposition,
                refreshRails,
                fetchAllCategory,
                fetchFastChannel,
                fetchSelectedSportsDataList,
                publishUnpublishRailAPI
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Category)
