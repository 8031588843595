import React, { Component } from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { toast } from "react-toastify"

import Input from "../../common/Input/index"
import Button from "../../common/Button/index"
import {
    createCategory,
    fetchPlatformList,
    fetchCategoryById,
    deleteCategory,
    unpublishCategory
} from "../api/action"
import { optionsBannerType } from "../api/constant"

import "./style.scss"
import { BUTTON_CONSTANTS, TEXT_CONSTANTS } from "../../../utils/constants"

class AddTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tab: [],
            platformArr: [],
            isDelete: false,
            idToDelete: -1,
            isUnpublish: false,
            idToUnpublish: -1,
            isErr: true,
            isLoading: true,
            sportsCategoryItem: []
        }
    }

    componentDidMount() {
        this.loadHandler()
    }
    loadHandler = async () => {
        this.setState({
            isLoading: true
        })
        let { platformList, categoryList } = this.props
        let platformArr = platformList

        let tab = [],
            sportsCategoryItem = []

        categoryList.forEach((listItem) => {
            if (listItem?.hasPageCategory) {
                sportsCategoryItem.push(listItem)
            } else {
                let tempItem = { ...listItem }
                tempItem.isTouched = {}
                tempItem.inValid = {}
                tempItem.isChange = false
                tab.push(tempItem)
            }
        })

        let tabList = tab.sort((a, b) => parseInt(a.position) - parseInt(b.position))
        this.setState({
            tab: tabList,
            platformArr,
            isLoading: false,
            sportsCategoryItem
        })
    }

    handleDeleteClick = (id) => {
        const { isDelete, idToDelete } = this.state
        if (!isDelete) {
            this.setState({
                idToDelete: id,
                isDelete: !isDelete
            })
        } else if (id === idToDelete) {
            this.setState({
                idToDelete: -1,
                isDelete: !isDelete
            })
        }
    }
    handleUnpublishClick = (id) => {
        const { isUnpublish, idToUnpublish } = this.state
        if (!isUnpublish) {
            this.setState({
                idToUnpublish: id,
                isUnpublish: !isUnpublish
            })
        } else if (id === idToUnpublish) {
            this.setState({
                idToUnpublish: id,
                isUnpublish: !isUnpublish
            })
        }
    }

    handlePublish = async (ele) => {
        const { tab } = this.state

        if (ele.active) {
            if (!ele.uniqueId) {
                let response = await this.props.unpublishCategory(ele.id)
                if (response && response.status) {
                    toast.success(response.message)
                    this.loadHandler()
                }
            } else {
                let newTab = tab.map((item) => {
                    if (item.uniqueId === ele.id) {
                        item.active = !item.active
                        return item
                    } else {
                        return item
                    }
                })
                this.setState({
                    tab: newTab
                })
            }
        } else {
            let newTab = []
            tab.map((item) => {
                if (item.id === ele.id) {
                    item.active = !item.active
                    return newTab.push(item)
                }
                return item
            })
            const response = await this.props.createCategory(newTab)
            if (response && response.status) {
                toast.success("Tab is unpublished")
                this.loadHandler()
            }
        }
    }

    handleDeleteTab = async (ele) => {
        const { tab, isDelete } = this.state
        if (ele.uniqueId) {
            ele.id = ele.uniqueId
        }
        let newTabData = tab.filter((item) => {
            if (item.id ? item.id !== ele.id : item.uniqueId !== ele.id) {
                return item
            }
            return false
        })
        this.setState(
            {
                isDelete: !isDelete,
                tab: [...newTabData]
            },
            this.validate
        )
    }

    validate = () => {
        const { tab } = this.state
        let valid = true
        let tempArr = tab.map((item) => {
            const inValid = {}

            if (item.name === "") {
                inValid.isname = "Enter Tab Name"
                valid = false
            } else if (item.name.length < 3 || item.name.length > 25) {
                inValid.isname = "Name should be of between 3 to 25 characters"
                valid = false
            }

            item.inValid = inValid
            return item
        })
        if (!valid) {
            this.setState({
                tab: tempArr,
                isErr: true
            })
        } else {
            this.setState({ isErr: false })
        }
        return valid
    }

    onSubmitClick = async () => {
        const { tab, sportsCategoryItem } = this.state
        const { handleBackClick, createCategory } = this.props
        let singlePayload = {}
        let payload = []
        tab.forEach((item, index) => {
            if (!item.uniqueId) {
                singlePayload = {
                    name: item.name,
                    id: item.id,
                    categoryPlatforms: item.categoryPlatforms,
                    position: index,
                    rails: [],
                    hasPageCategory: item?.hasPageCategory
                }
            } else {
                singlePayload = {
                    name: item.name,
                    categoryPlatforms: item.categoryPlatforms,
                    position: index,
                    rails: [],
                    hasPageCategory: item?.hasPageCategory
                }
            }

            payload.push(singlePayload)
        })
        let modSportsItem = [...sportsCategoryItem]
        if (modSportsItem.length > 0) {
            modSportsItem[0].position = payload.length
        }
        payload = [...payload, ...modSportsItem]
        let response = await createCategory(payload)
        if (response && response.status) {
            toast.success(response.message)
            handleBackClick()
        }
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        if (startIndex < endIndex) {
            for (let i = startIndex; i <= endIndex; i++) {
                result[i].isChange = true
            }
        } else {
            for (let i = startIndex; i >= endIndex; i--) {
                result[i].isChange = true
            }
        }
        return result
    }
    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return
        }
        const tab = this.reorder(this.state.tab, result.source.index, result.destination.index)
        let newTab = tab.map((item, index) => {
            item.position = index + 1
            return item
        })

        this.setState(
            {
                tab: newTab
            },
            this.validate
        )
    }

    onChangetab = (name, value, index) => {
        if (name === "platform") {
            this.state.platformArr.forEach((item) => {
                if (item.id === value) {
                    value = item
                }
            })
        }
        let updatedTab = [...this.state.tab]
        let selectedTab = updatedTab[index]
        if (name === "name" || name === "platform") {
            selectedTab[name] = value
            selectedTab.isTouched[name] = true
            selectedTab.isChange = true
        }
        this.validate()
        this.setState({
            tab: updatedTab
        })
    }
    getId = () => {
        return Math.random().toString(36).substring(2) + Date.now().toString(36)
    }

    platformSelect = (id, values) => {
        let updatedTab = this.state.tab.map((item) => {
            if (item.id ? item.id === id : item.uniqueId === id) {
                item.platform = values
                item.isTouched.platformType = false
                item.isChange = true
                return item
            } else {
                return item
            }
        })
        this.setState({
            tab: updatedTab
        })
    }

    addNewTabClick = () => {
        const { tab } = this.state
        let obj = {
            uniqueId: this.getId(),
            position: tab.length + 1,
            name: "",
            categoryPlatforms: [],
            active: true,
            inValid: {},
            isTouched: {},
            isChange: true
        }
        if (tab.length) {
            tab[tab.length - 1].isTouched = { name: true, platformType: true }
        }
        this.setState({
            tab: [...this.state.tab, obj]
        })
    }

    handlePlatformChange = (id, subId) => {
        let platform = { platformId: subId }
        const { tab } = this.state
        let updatedTab = tab.map((item) => {
            if (item.id ? item.id === id : item.uniqueId === id) {
                let a = item.categoryPlatforms.filter((ele) => ele.platformId === subId)
                if (a.length > 0) {
                    item.categoryPlatforms = item.categoryPlatforms.filter(
                        (ele) => ele.platformId !== subId
                    )
                    item.isTouched.platformType = false
                    item.isChange = true
                } else {
                    item.categoryPlatforms.push(platform)
                    item.isTouched.platformType = false
                    item.isChange = true
                }
                return item
            } else {
                return item
            }
        })
        this.setState(
            {
                tab: updatedTab
            },
            this.validate
        )
    }

    checkForActive = (tab, subId) => {
        let flag = false
        if (!tab.id) {
            tab.id = tab.uniqueId
        }
        this.state.tab.map((item) => {
            if (item.id ? item.id === tab.id : item.uniqueId === tab.id) {
                if (item.categoryPlatforms) {
                    item.categoryPlatforms.forEach((ele) => {
                        if (ele.platformId === subId) {
                            return (flag = true)
                        }
                    })
                } else {
                    return null
                }
            }
            return null
        })
        return flag
    }
    render() {
        const { tab, isDelete, idToDelete, isErr, platformArr, isLoading } = this.state
        const { handleBackClick } = this.props
        return (
            <div className="addTab">
                <header className="addTabHeader">
                    <i className="icon-arrow-left1" onClick={handleBackClick}></i>
                    <span>{TEXT_CONSTANTS?.ADD_EDIT_TAB}</span>
                </header>
                {isLoading ? (
                    <ul className="loader">
                        {optionsBannerType.map((item) => {
                            return <li className="placeholder"></li>
                        })}
                    </ul>
                ) : (
                    tab.length > 0 && (
                        <section className="listing">
                            <div className="head">{TEXT_CONSTANTS?.SELECT_PLATFORM}</div>
                            <div className="listing-head">
                                <div className="left-container">
                                <div className="place"></div>

                                <div className="head2">
                                    {platformArr
                                        .sort((a, b) => a.name.localeCompare(b.name))
                                        .map((item) => (
                                            <span>{item.name}</span>
                                        ))}
                                </div>

                                </div>

                                <div className="place right-container"></div>
                            </div>
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <ul {...provided.droppableProps} ref={provided.innerRef}>
                                            {this.state.tab.map((item, index) => (
                                                <Draggable
                                                    key={item.id}
                                                    draggableId={`item-${item.id}`}
                                                    index={index}>
                                                    {(provided, snapshot) => (
                                                        <li
                                                            id={item.uniqueId}
                                                            className={`tabList ${
                                                                item.active ? "" : "unpublish"
                                                            }`}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}>
                                                            <div className="left-container">
                                                                <div className="drag">
                                                                <span className="menu-icon"></span>
                                                                <span className="menu-icon"></span>
                                                                <span className="menu-icon"></span>
                                                            </div>

                                                            <div className="platform-cont">
                                                                <div
                                                                    className={`platformSelect ${
                                                                        item.isTouched
                                                                            .platformType && "error"
                                                                    }`}>
                                                                    {platformArr.map(
                                                                        (ele, index) => {
                                                                            return (
                                                                                <i
                                                                                    className={`icon-${
                                                                                        ele.name
                                                                                    } toolsBtn ${
                                                                                        this.checkForActive(
                                                                                            item,
                                                                                            ele.id
                                                                                        )
                                                                                            ? "active"
                                                                                            : ""
                                                                                    }`}
                                                                                    id={ele.id}
                                                                                    onClick={() =>
                                                                                        item.id
                                                                                            ? this.handlePlatformChange(
                                                                                                  item.id,
                                                                                                  ele.id
                                                                                              )
                                                                                            : this.handlePlatformChange(
                                                                                                  item.uniqueId,
                                                                                                  ele.id
                                                                                              )
                                                                                    }></i>
                                                                            )
                                                                        }
                                                                    )}
                                                                </div>
                                                                {item.isTouched.platformType && (
                                                                    <span className="err">
                                                                        {
                                                                            item.inValid
                                                                                .isPlatformType
                                                                        }
                                                                    </span>
                                                                )}
                                                            </div>
                                                            </div>
                                                            <div className="right-container">
                                                            <Input
                                                                type="text"
                                                                placeholder="Tab Name"
                                                                cName={`name`}
                                                                name="name"
                                                                value={item.name}
                                                                changeHandler={(name, value) =>
                                                                    this.onChangetab(
                                                                        name,
                                                                        value,
                                                                        index
                                                                    )
                                                                }
                                                                errMsg={
                                                                    item.isTouched.name &&
                                                                    item.inValid.isname
                                                                }
                                                                disabled={
                                                                    item.categoryPlatforms.length
                                                                        ? false
                                                                        : true
                                                                }
                                                            />
                                                            <div className="actions">
                                                                <i
                                                                    className="icon-del"
                                                                    onClick={() =>
                                                                        this.handleDeleteClick(
                                                                            item.id
                                                                        )
                                                                    }></i>
                                                            </div>
                                                            {item.id === idToDelete && (
                                                                <div
                                                                    className={`deletePopup ${
                                                                        isDelete
                                                                            ? "showPopUp slide-right"
                                                                            : "hidePopUp slide-left"
                                                                    }`}>
                                                                    <p>
                                                                        {
                                                                            TEXT_CONSTANTS?.DO_YOU_WANT_TO_REMOVE
                                                                        }{" "}
                                                                        {item.name} Tab?
                                                                    </p>
                                                                    <Button
                                                                        cName="btn popup-del-btn"
                                                                        bValue={
                                                                            BUTTON_CONSTANTS?.YES_DELETE
                                                                        }
                                                                        bType="button"
                                                                        clickHandler={() =>
                                                                            this.handleDeleteTab(
                                                                                item
                                                                            )
                                                                        }
                                                                    />
                                                                    <Button
                                                                        cName="btn cancel-btn"
                                                                        bValue={
                                                                            BUTTON_CONSTANTS?.CANCEL
                                                                        }
                                                                        bType="button"
                                                                        clickHandler={() =>
                                                                            this.handleDeleteClick(
                                                                                item.id
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                           </div>
                                                        </li>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </ul>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </section>
                    )
                )}
                <div className="addnewTab-Btn">
                    <span className="icon-container" onClick={this.addNewTabClick}>
                        <i className="close icon-plus"></i>
                    </span>
                    <span className="icon-text" onClick={this.addNewTabClick}>
                        {TEXT_CONSTANTS?.ADD_NEW_TAB}
                    </span>
                </div>
                <div className="addTabBtn">
                    <Button
                        bValue={BUTTON_CONSTANTS?.SUBMIT?.toUpperCase()}
                        cName="btn primary-btn"
                        disabled={isErr}
                        clickHandler={this.onSubmitClick}
                    />
                    <Button
                        bValue={BUTTON_CONSTANTS?.CANCEL?.toUpperCase()}
                        cName="btn cancel-btn"
                        clickHandler={handleBackClick}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                createCategory,
                fetchPlatformList,
                fetchCategoryById,
                deleteCategory,
                unpublishCategory
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTab)
