import React from "react"
import { TEXT_CONSTANTS } from "../../utils/constants"
import MultipleTab from "../common/MultipleTab/MultipleTab"
import DisplayContentPlan from "./DisplayContentPlan"
import { CONTENT_TAB_TYPES } from "./API/constants"

export default function ContentPlan() {
    const tabs = [
        {
            label: "MOVIES",
            component: <DisplayContentPlan contentTabType={CONTENT_TAB_TYPES?.MOVIES} />
        },
        {
            label: "WEB SERIES",
            component: <DisplayContentPlan contentTabType={CONTENT_TAB_TYPES?.WEBSERIES} />
        }
    ]

    return (
        <div className="vd-custom-management">
            <div className="vr-cont-head mb">
                {/* TODO: Replace below with the reusable pageTitleComponent */}
                <div className="vr-cont-title">
                    <span className="vr-breadcrum">
                        {TEXT_CONSTANTS?.SETTINGS}
                        <i className="icon-arrow-right1" />
                        <span>{TEXT_CONSTANTS?.CONTEN_Product_MANAGEMENT}</span>
                    </span>
                    <h2 className="breadcrum-header">
                        {TEXT_CONSTANTS?.CONTEN_Product_MANAGEMENT?.toUpperCase()}
                    </h2>
                </div>
            </div>
            {/* <div className="vr-content-holder"> */}
            <MultipleTab tabs={tabs} className={""}></MultipleTab>
            {/* </div> */}
        </div>
    )
}
