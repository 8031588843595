import React, { useCallback } from "react"
import PropTypes from "prop-types"
import close from "../../../assets/images/close.svg"

import "./style.scss"

const CustomModal = (props) => {
    const { showModal, children, cancelHandler = () => {}, modalStyle } = props

    const onClickCancelBtn = useCallback(() => {
        if (cancelHandler) {
            cancelHandler()
        }
    }, [cancelHandler])

    return (
        <>
            {showModal ? (
                <div className="modalContainer" onClick={onClickCancelBtn}>
                    <div
                        className={`modal ${modalStyle || ""}`}
                        onClick={(e) => e.stopPropagation()}>
                        <div className="close">
                            <button onClick={onClickCancelBtn}>
                                <img src={close} alt="close" />
                            </button>
                        </div>
                        {children}
                    </div>
                </div>
            ) : null}
        </>
    )
}

CustomModal.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    submitHandler: PropTypes.func,
    cancelHandler: PropTypes.func,
    submitButtonLabel: PropTypes.string,
    cancelButtonLabel: PropTypes.string,
    showCancelButton: PropTypes.bool
}

export default CustomModal
