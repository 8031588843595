import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import Input from "../../../common/Input/index"
import { showNavBar } from "../../../../containers/AdminHome/APIs/action"
import MultiSelect from "./multiselect/index"
import { countries } from "../../../StatsDashboard/MapChart/countries"

import "./style.scss"
import { isEmpty } from "lodash"
import { checkIfUserHasAccess } from "../../../../utils/users"
import { PERMISSIONS } from "../../../../utils/constants"

/* admin work */
class AddEditTenant extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tenantFields: {}
        }
        this.canUpdateStatus = checkIfUserHasAccess([PERMISSIONS.ADD_TENANT_EDIT])
    }

    componentDidMount() {
        this.setState({
            tenantFields: this.props.tenantFields
        })
    }

    handleSubmit = () => {
        const { showNavBar, history } = this.props
        history.push("/dashboard")
        showNavBar(false)
    }
    handleClose = () => {
        const { history } = this.props
        history.goBack()
    }

    handleChange = async (name, value) => {
        let { tenantFields } = this.state

        let inputFields = tenantFields
        inputFields[name] = value
        this.setState(
            {
                tenantFields: inputFields
            },
            () => {
                this.props.handleChange(name, value)
            }
        )
    }

    handleCountryChange = (id, selectedOptions) => {
        this.setState({
            selectedCountries: selectedOptions
        })
        this.handleChange("countries", selectedOptions)
    }

    render() {
        const { tenantFields } = this.state
        const { error, isEdit, isExistingMobile, isExistingEmail } = this.props
        return (
            <div className="">
                <div className="step3-container">
                    <Input
                        className="inputField"
                        type="text"
                        changeHandler={this.handleChange}
                        name="tenantAdminName"
                        id="tenantAdminName"
                        required
                        maxLength={25}
                        labelText="CUSTOMER NAME"
                        value={tenantFields["tenantAdminName"]}
                        disabled={!this.canUpdateStatus}
                    />
                    <Input
                        className="inputField"
                        type="text"
                        changeHandler={this.handleChange}
                        name="tenantAdminEmail"
                        id="tenantAdminEmail"
                        errMsg={
                            error["isEmail"]
                                ? error["isEmail"]
                                : isExistingEmail && !isEmpty(tenantFields["tenantAdminEmail"])
                                ? "Please enter unique email."
                                : null
                        }
                        disabled={isEdit || !this.canUpdateStatus}
                        maxLength={50}
                        labelText="EMAIL ID"
                        value={tenantFields["tenantAdminEmail"]}
                        required
                    />
                    <Input
                        className="inputField"
                        type="text"
                        changeHandler={this.handleChange}
                        name="tenantAdminContact"
                        id="tenantAdminContact"
                        maxLength={10}
                        errMsg={
                            error["invalidMobile"]
                                ? error["invalidMobile"]
                                : isExistingMobile && !isEmpty(tenantFields["tenantAdminContact"])
                                ? "Please enter unique mobile no."
                                : null
                        }
                        labelText="CONTACT NO"
                        inputType="Integer"
                        value={tenantFields["tenantAdminContact"]}
                        required
                        disabled={isEdit || !this?.canUpdateStatus}
                    />
                    <div className="form-input">
                        <MultiSelect
                            key={"country"}
                            options={countries}
                            placeholder={"Select"}
                            id={"country"}
                            isOptionCheckbox={true}
                            showValueOnSelect
                            name={"country"}
                            label={"Country"}
                            required
                            selectedValues={tenantFields["countries"]}
                            changeHandler={this.handleCountryChange}
                            isDisabled={!this.canUpdateStatus}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                showNavBar
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEditTenant)
