import { API_ROOT_PATH } from "../../../config/apiPath"
import ApiService from "../../../utils/apiService"

export const PlatformConfigService = {
    getPlatformConfig(platformName) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/platform/config/getData`,
            isAuthRequired: true,
            headers: {
                PLATFORM: platformName
            }
        }
        return ApiService(options)
    },
    submitConfigPayload(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/platform/config/addData`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }
}
