import React from "react"
import "./style.scss"
import CreatableSelect from "react-select/creatable"

function CustomCreateAbleSelect(props) {
    const handleChange = (selectedOptions, action) => {
        const { changeHandler, id, name } = props
        if (selectedOptions) {
            changeHandler(name, selectedOptions, id)
        } else {
            changeHandler(id, [])
        }
    }
    const {
        options,
        label,
        required,
        id,
        placeholder,
        selectedOptions,
        isAutoFocus,
        errMsg,
        listRef,
        cName,
        isDisabled
    } = props

    return (
        <div className={`multi-select-container ${cName}`}>
            {label && (
                <label htmlFor={id}>
                    {label}
                    <span className={required ? "required" : "notRequired"}>
                        *
                    </span>
                </label>
            )}

            <CreatableSelect
                isMulti
                placeholder={placeholder}
                defaultValue={selectedOptions}
                onChange={handleChange}
                ref={listRef}
                options={options}
                id={id}
                autoFocus={isAutoFocus}
                className={"multiSelect-container"}
                classNamePrefix={"multiSelect"}
                isDisabled={isDisabled}
                value={selectedOptions}
                isCreatable
                onCreateOption={(inputValue) => {
                    const newOption = {
                        label: inputValue,
                        id: inputValue,
                        value: inputValue,
                        name: inputValue
                    }
                    handleChange(
                        selectedOptions
                            ? [...selectedOptions, newOption]
                            : [newOption]
                    )
                }}
            />
            {errMsg && <span className="err">{errMsg}</span>}
        </div>
    )
}

export default CustomCreateAbleSelect
