import { ACTION } from "./constants"

let initialState = {}

export default function adminPageReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION.CREATE_TENANT:
            return { ...state, customText: action.apiResponse.data }
        case ACTION.FETCH_TENANT:
            return { ...state, tenantList: action.apiResponse }
        case ACTION.UPLOAD_LOGO:
            return { ...state, customText: action.apiResponse.data }
        default:
            return state
    }
}
