import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import Button from "../../../common/Button"
import Pagination from "../../../common/Pagination"
import { scrollPage } from "../../../../utils/common"
import { MenuDesignHeading } from "../APIs/constants"
import { getTenants, deactivateTenant } from "../APIs/action"
import { URL } from "../../../../utils/routeConstants"
import { imagePathPrefix } from "../../../../config/apiPath"
import NoImage from "../../../../assets/images/no_image.jpg"
import Loader from "../../../../assets/images/loader.gif"

import "./style.scss"
import { debounce, isEmpty } from "lodash"
import moment from "moment"
import { options } from "../../../ContentNav/api/constants"
import Search from "../../../common/Search"
import { BUTTON_CONSTANTS, PERMISSIONS, TEXT_CONSTANTS } from "../../../../utils/constants"
import { AuthWrapper } from "../../../common/AuthWrapper"
import { checkIfUserHasAccess } from "../../../../utils/users"

/* admin work */
let response
class Tenant extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tenantIds: {},
            pageCount: 0,
            currentPage: 0,
            selectedOption: options[0],
            searchValue: "",
            isLoading: false,
            start: 0,
            filterOptions: {
                pageSize: 10,
                pageNo: 1,
                tenantStatus: null,
                sortOrder: false,
                sortBy: "tenantCode",
                filters: {}
            }
        }
    }

    tabChange = (activeIndex) => {
        this.setState({
            activeIndex
        })
    }

    onPageChange = (e) => {
        const { filterOptions } = this.state
        const selectedPage = e.selected
        filterOptions.pageNo = selectedPage + 1
        let startValue = (filterOptions["pageNo"] - 1) * filterOptions["pageSize"]

        this.setState(
            {
                currentPage: selectedPage,
                filterOptions,
                start: startValue
            },
            () => {
                scrollPage()
                this.loadHandler()
            }
        ) // To scroll page up while rendering data
    }
    showPerPageChange = (name, value) => {
        let { filterOptions } = this.state
        filterOptions["pageSize"] = value
        filterOptions["pageCount"] = 0
        filterOptions["pageNo"] = 1

        let defaultOption = options.filter((item) => {
            if (value === item.id) {
                return item
            }
            return false
        })

        this.setState(
            {
                filterOptions,
                selectedOption: defaultOption,
                start: 0,
                pageCount: 0,
                currentPage: 0
            },
            () => {
                this.loadHandler()
            }
        )
    }

    addNewTenant = () => {
        const { history } = this.props
        history.push(`/${URL.TENANT}/${URL.ADD_TENANT}`)
    }

    editTenant = (tenantId) => {
        const { history } = this.props
        history.push(`/${URL.TENANT}/${URL.ADD_TENANT}?id=${tenantId}`)
    }

    deactivateTenant = (tenantId) => {
        //todo call it
    }

    openDropDown = (id) => {
        let { tenantIds } = this.state
        let editTenantId = {}
        editTenantId[`tenant_${id}`] = !tenantIds[`tenant_${id}`]
        this.setState({
            [`tenant_${id}`]: !this.state[`tenant_${id}`],
            tenantIds: editTenantId
        })
    }

    componentWillUnmount() {}

    componentDidMount() {
        this.loadHandler()
    }

    loadHandler = async () => {
        const { getTenants } = this.props
        const { filterOptions, start } = this.state
        this.setState({
            isLoading: true
        })
        let params = {
            length: filterOptions["pageSize"],
            start: start,
            searchTag: filterOptions["searchTag"],
            sortBy: filterOptions.sortBy,
            sortOrder: filterOptions.sortOrder ? "asc" : "desc"
        }
        response = await getTenants(params)
        this.pageCountSetup(response.count)
        this.setState(
            {
                isLoading: false
            },
            () => {
                scrollPage()
            }
        )
    }

    pageCountSetup = (userCount) => {
        const { pageSize } = this.state.filterOptions
        let pageCount = Math.ceil(userCount / pageSize)
        this.setState({
            pageCount
        })
    }

    setSearchQuery = debounce((name, value) => {
        let { filterOptions } = this.state
        filterOptions["searchTag"] = value
        this.setState(
            {
                filterOptions,
                searchValue: value,
                start: 0
            },
            () => {
                this.loadSearchData()
            }
        )
    })

    loadSearchData = debounce((name, value) => {
        this.loadHandler()
    }, 400)

    sortBy = (item) => {
        let sortBy = item
        let { filterOptions } = this.state
        filterOptions["sortBy"] = sortBy
        filterOptions["sortOrder"] = !filterOptions["sortOrder"]
        this.setState(
            {
                filterOptions
            },
            () => {
                this.loadHandler()
            }
        )
    }

    render() {
        const { tenantList } = this.props
        const { selectedOption } = this.state

        const tableHeading = MenuDesignHeading?.filter((item) => {
            if (checkIfUserHasAccess(item?.permissions)) {
                return true
            }
            return false
        })?.map((item, index) => {
            return (
                <th key={index}>
                    <p>
                        <span>{item.key}</span>{" "}
                        {index !== 0 && index !== MenuDesignHeading.length - 1 && (
                            <span
                                onClick={() => this.sortBy(item.value)}
                                className="triangle"
                            ></span>
                        )}
                    </p>
                </th>
            )
        })
        const sortedData = tenantList?.data
        const tableRows =
            sortedData &&
            Array.isArray(sortedData) &&
            sortedData.map((row, i) => {
                return (
                    <tr id={row.id} key={row.id}>
                        <td>
                            <img
                                className="tenant-image"
                                src={
                                    isEmpty(row.logoFilePath)
                                        ? { NoImage }
                                        : `${imagePathPrefix}${row.logoFilePath}`
                                }
                                alt="tenant"
                                onError={(e) => {
                                    e.target.onerror = null
                                    e.target.src = `${NoImage}`
                                }}
                            />
                        </td>

                        <td>{row.appName}</td>
                        <td>{row.tenantAdminName}</td>
                        <td>{row.tenantAdminEmail}</td>
                        <td>{row.tenantAdminContact}</td>
                        {/* <td>{row.paymentDueDate ? row.paymentDueDate : "--"}</td> */}
                        <td>{moment(row.dateCreated)?.format(" DD MMMM, YYYY")}</td>
                        <td className="created-on">
                            {row.currentStatus !== "deactivated" && (
                                <span
                                    className={`status-dot ${
                                        row.currentStatus ? row.currentStatus : "ACTIVE"
                                    }`}
                                ></span>
                            )}{" "}
                            <span
                                className={`${
                                    row.currentStatus === "deactivated" ? "deactive-status" : ""
                                }`}
                            >
                                {row.currentStatus ? row.currentStatus : "ACTIVE"}
                            </span>
                        </td>
                        <AuthWrapper
                            permissions={[
                                PERMISSIONS?.ADD_TENANT_VIEW,
                                PERMISSIONS?.ADD_TENANT_EDIT
                            ]}
                        >
                            <td className="btn-popupContainer">
                                <p onClick={() => this.editTenant(row.id)}>
                                    <i className="action-btn icon-edit"></i>
                                </p>
                                {this.state.tenantIds[`tenant_${row.id}`] && (
                                    <ul>
                                        <li onClick={() => this.editTenant(row.id)}>
                                            {BUTTON_CONSTANTS?.EDIT}
                                        </li>
                                        <li>{BUTTON_CONSTANTS?.ACTIVATE}</li>
                                    </ul>
                                )}
                            </td>
                        </AuthWrapper>
                    </tr>
                )
            })

        const { searchValue, isLoading } = this.state
        return (
            <div>
                <div className="vr-content-holder">
                    {
                        <div className="tenant-list-container">
                            <p className="add-new-tenant">
                                <p className="tenant-heading">{TEXT_CONSTANTS?.TENANTS}</p>
                                <div className="search-container">
                                    <Search
                                        name="search"
                                        changeHandler={this.setSearchQuery}
                                        placeholder="Search Here"
                                        value={searchValue}
                                    />
                                    <AuthWrapper permissions={[PERMISSIONS?.ADD_TENANT_EDIT]}>
                                        <Button
                                            type="button"
                                            clickHandler={() => this.addNewTenant()}
                                            bValue={TEXT_CONSTANTS?.ADD_NEW_TENANT}
                                            cName="btn primary-btn"
                                        ></Button>
                                    </AuthWrapper>
                                </div>
                            </p>
                            <table className="table">
                                <thead>
                                    <tr>{tableHeading}</tr>
                                </thead>
                                {!isLoading ? (
                                    <tbody>{tableRows}</tbody>
                                ) : (
                                    <tbody>
                                        <img className="loader" src={Loader} alt="loader" />
                                    </tbody>
                                )}
                            </table>
                        </div>
                    }
                </div>

                <Pagination
                    options={options}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    selectedOption={selectedOption}
                    onPageChange={this.onPageChange}
                    changeHandler={this.showPerPageChange}
                    currentPage={this.state.currentPage}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        tenantList: state?.AdminPageReducer?.tenantList
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                getTenants,
                deactivateTenant
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tenant)
