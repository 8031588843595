import React, { useCallback, useEffect, useMemo, useState } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import Input from "../common/Input"
import { BUTTON_CONSTANTS, PERMISSIONS, TEXT_CONSTANTS } from "../../utils/constants"
import Button from "../common/Button"
import { useDispatch, useSelector } from "react-redux"
import {
    bulkUpdateFooter,
    createFooterItem,
    deleteFooterItem,
    getAllFooterItems,
    publishFooterItem,
    updateFooterItem
} from "./api/action"
import Select1 from "../common/SelectBox"
import CustomRadioButton from "../common/customRadioBtn"
import "./style.scss"
import { fetchAlltextpage } from "../CustomPages/APIs/action"
import { isEmpty, toNumber } from "lodash"
import PlatformTabHeader from "../MenuDesign/platformTab"
import { toast } from "react-toastify"
import { fetchPlatformList, uploadImage } from "../Category/api/action"
import { AuthWrapper } from "../common/AuthWrapper"
import moment from "moment-timezone"
import { APP_LINKS, FOOTER_TABS, FOOTER_TABS_TITLE, LINK_TYPES, SOCIAL_LINKS } from "./api/constant"
import ImageUpload from "../common/imageUpload"
import EnvDropdown from "../common/EnvDropdown"

const Footer = () => {
    const dispatch = useDispatch()

    const platformList = useSelector((state) => state.categoryRail?.platformList || [])

    const [availableFooterLink, setAvailabelFooterLink] = useState([])

    const [platform, setPlatform] = useState({})

    const [tabs, setTab] = useState({
        [FOOTER_TABS_TITLE.LINK]: [],
        [FOOTER_TABS_TITLE.SOCIAL]: [],
        [FOOTER_TABS_TITLE.APPS]: []
    })

    const [selectedTab, setSelectedTab] = useState({
        selectedTab: FOOTER_TABS[0].id,
        selectedTabName: FOOTER_TABS[0].name
    })

    const [titles, setTitles] = useState({})

    const [linkType, setLinkType] = useState(LINK_TYPES[0].id)

    const [url, setUrl] = useState("")

    const [urlError, setUrlError] = useState("")

    const [linkTitle, setLinkTitle] = useState("")

    const [showForm, setShowForm] = useState(false)

    const [position, setPosition] = useState(null)

    const [desc, setDesc] = useState("")

    const [iconImage, setIconImage] = useState(null)

    const [footerId, setFooterId] = useState(null)
    const [isActive, setIsActive] = useState(false)

    const onLoad = async () => {
        let footerPages = await dispatch(
            fetchAlltextpage({
                offset: 0,
                limit: 40
            })
        )
        footerPages = footerPages?.data?.map((item) => ({
            id: item.id,
            name: item.name.en,
            pageTypes: item.pageTypes
        }))
        setAvailabelFooterLink(footerPages)

        const response = await dispatch(fetchPlatformList())
        if (response?.status) {
            if (response.data) {
                setPlatform({
                    currentId: response.data[0].id,
                    currentName: response.data[0].name
                })
            }
        } else {
            toast.error("Opps!! Something went wrong!!")
        }
    }

    const handlePlatformChange = useCallback((item) => {
        setPlatform({
            currentId: item.id,
            currentName: item.name
        })

        setSelectedTab({
            selectedTab: FOOTER_TABS[0].id,
            selectedTabName: FOOTER_TABS[0].name
        })
    }, [])
    const getTableData = useCallback(async (platform) => {
        if (!platform?.currentId) {
            return
        }

        let footerItems = {
            [FOOTER_TABS_TITLE.LINK]: [],
            [FOOTER_TABS_TITLE.SOCIAL]: [],
            [FOOTER_TABS_TITLE.APPS]: []
        }
        const response = await dispatch(getAllFooterItems(platform.currentId))
        response.data.forEach((item) => {
            const tabName = item.footerTypes.replace("_", " ")
            if (item.footerTypes === FOOTER_TABS_TITLE.LINK) {
                const updatedObj = {
                    ...item,
                    name: item.data.PAGE_NAME
                }
                footerItems[FOOTER_TABS_TITLE.LINK].push(updatedObj)
                return
            }
            const keyName = Object.keys(item.data)[0].replace("_", " ")
            const value = Object.values(item.data)[0]
            footerItems[tabName] &&
                footerItems[tabName].push({ ...item, data: { [keyName]: value }, name: keyName })
        })
        setTab(footerItems)
    }, [])

    const handleTabClick = useCallback((item) => {
        setSelectedTab({
            selectedTab: item.id,
            selectedTabName: item.name
        })
    }, [])

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        if (startIndex < endIndex) {
            for (let i = startIndex; i <= endIndex; i++) {
                result[i].isChange = true
            }
        } else {
            for (let i = startIndex; i >= endIndex; i--) {
                result[i].isChange = true
            }
        }
        return result
    }

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return
        }
        let tabData = tabs[selectedTab.selectedTabName]
        const tab = reorder(tabData, result.source.index, result.destination.index)
        let newTabData = tab.map((item, index) => {
            item.position = index + 1
            return item
        })

        setTab({ ...tabs, [selectedTab.selectedTabName]: newTabData })
        dispatch(bulkUpdateFooter(newTabData))
    }

    const handleEdit = (row) => {
        setShowForm(true)
        setFooterId(row.id)
        setIsActive(row.active)
        if (selectedTab.selectedTabName === FOOTER_TABS_TITLE.LINK) {
            if (row.linkType === LINK_TYPES[1].name) {
                setLinkType(LINK_TYPES[1].id)
                setLinkTitle(row.data.PAGE_NAME)
                setUrl(row.data.URL)
            } else {
                setLinkType(LINK_TYPES[0].id)
                setTitles({ id: row.data.ID, name: row.data.PAGE_NAME })
            }
            setIconImage(row.data.ICON_SVG)
            setDesc(row.data.DESCRIPTION)
            return
        }

        const keyName = row.name
        const url = row.data[keyName]
        setUrl(url)

        const linkList =
            selectedTab.selectedTabName === FOOTER_TABS_TITLE.SOCIAL ? SOCIAL_LINKS : APP_LINKS
        const selectedOption = linkList.find((item) => item.name === keyName)
        setTitles(selectedOption)
        setPosition(row.position)
    }

    const handleDelete = async (id) => {
        const response = await dispatch(deleteFooterItem(id))
        if (response.status) {
            toast.success(response.message)
            getTableData(platform)
        } else {
            toast.error(response.message || "Something went wrong")
        }
    }

    const handlePublish = async (id, active) => {
        const response = await dispatch(publishFooterItem(id, !active))
        if (response.status) {
            toast.success(response.message)
            getTableData(platform)
        } else {
            toast.error(response.message || "Something went wrong")
        }
    }

    const onSelect = (name, value, selectedOption) => {
        setTitles(selectedOption)
    }

    const clearForm = useCallback(() => {
        setUrl("")
        setLinkTitle("")
        setUrlError("")
        setTitles({})
        setPosition(null)
        setDesc("")
        setIconImage(null)
    }, [])

    const handleRadioChange = useCallback((e) => {
        setLinkType(toNumber(e.target.value))
        clearForm()
    }, [])

    const addNewHandle = useCallback(() => {
        setShowForm(!showForm)
        clearForm()
        setFooterId(null)
        setLinkType(LINK_TYPES[0].id)
    }, [showForm])

    const handleSubmit = async () => {
        const totalItems = tabs[selectedTab.selectedTabName]
        let data = {}
        if (selectedTab.selectedTabName === FOOTER_TABS_TITLE.LINK) {
            data =
                linkType === LINK_TYPES[0].id
                    ? {
                          ID: titles.id.toString(),
                          PAGE_NAME: titles.name,
                          PAGE_TYPE: titles.pageTypes,
                          URL: `${process.env.REACT_APP_WEB_BASE_URL}/${titles.id}/${titles.name}`
                      }
                    : {
                          PAGE_NAME: linkTitle,
                          URL: url
                      }
            data = {
                ...data,
                ...(iconImage && { ICON_SVG: iconImage }),
                ...(desc && { DESCRIPTION: desc })
            }
        } else {
            const keyName = titles?.name?.replace(" ", "_")
            data = { [keyName]: url }
        }

        const payload = {
            data,
            footerTypes: selectedTab.selectedTabName.replace(" ", "_"),
            linkType:
                selectedTab.selectedTabName === FOOTER_TABS_TITLE.LINK
                    ? LINK_TYPES[linkType].name
                    : null,
            platformId: platform.currentId,
            position: position || totalItems.length + 1,
            id: footerId,
            active: isActive
        }
        let response
        if (footerId) {
            response = await dispatch(updateFooterItem(payload))
        } else {
            response = await dispatch(createFooterItem(payload))
        }
        response.status ? toast.success(response.message) : toast.error(response.message)
        setShowForm(false)
        setTitles({})
        getTableData(platform)
    }

    const validateUrl = useCallback((err, name) => {
        if (err) {
            setUrlError("Please enter valid url")
        } else {
            setUrlError("")
        }
    }, [])

    const dropdownOptions = useMemo(() => {
        let list = availableFooterLink
        if (selectedTab.selectedTabName === FOOTER_TABS_TITLE.APPS) {
            list = APP_LINKS
        } else if (selectedTab.selectedTabName === FOOTER_TABS_TITLE.SOCIAL) {
            list = SOCIAL_LINKS
        }
        return list
    }, [availableFooterLink, selectedTab])

    const isDisabled = useMemo(() => {
        let value = isEmpty(titles)
        const mustHave = isEmpty(url) || !isEmpty(urlError)

        if (selectedTab.selectedTabName === FOOTER_TABS_TITLE.LINK) {
            if (linkType === LINK_TYPES[1].id) {
                value = isEmpty(linkTitle) || mustHave
            }
        } else {
            value = mustHave || isEmpty(titles)
        }
        return value
    }, [titles, linkTitle, url, urlError])

    const data = useMemo(() => {
        return tabs[selectedTab.selectedTabName].sort((acc, cur) => acc.position - cur.position)
    }, [tabs, selectedTab])

    const addButtonLabel = useMemo(() => {
        let label = "ADD LINK"
        if (selectedTab.selectedTabName === FOOTER_TABS_TITLE.SOCIAL) {
            label = "ADD SOCIAL LINK"
        } else if (selectedTab.selectedTabName === FOOTER_TABS_TITLE.APPS) {
            label = "ADD APP LINK"
        }
        return label
    }, [selectedTab])

    const handleInput = useCallback((name, value) => {
        if (name === "url") {
            setUrl(value)
        } else if (name === "desc") {
            setDesc(value)
        } else {
            setLinkTitle(value)
        }
    }, [])

    const handleImageUpload = useCallback(async (data, id, name) => {
        data.append("type", "CONTENT_MAPPING")
        const response = await dispatch(uploadImage(data))
        if (response?.status) {
            setIconImage(response.data.path)
        }
    }, [])

    const handleRemoveImage = useCallback((id) => {
        setIconImage(null)
    }, [])

    useEffect(() => {
        if (platform.currentId) {
            getTableData(platform)
        }
    }, [platform])

    useEffect(() => {
        onLoad()
    }, [])

    return (
        <>
            <EnvDropdown refreshCall={onLoad} />
            <div className="vd-menuDesign footer-design">
                <div className="vr-cont-head mb">
                    <div className="vr-cont-title">
                        <span className="vr-breadcrum">
                            Engage <i className="icon-arrow-right1"></i>
                            <span>Footer Design</span>
                        </span>
                        <h2 className="breadcrum-header">Footer Design</h2>
                    </div>
                    <AuthWrapper permissions={[PERMISSIONS?.ENGAGE_UX_MANAGER_MENU_DESIGN_EDIT]}>
                        <div className="vr-cont-action">
                            <Button
                                cName={`btn ${!showForm ? "primary-btn" : "border-btn"}`}
                                bValue={`${!showForm ? addButtonLabel : BUTTON_CONSTANTS.BACK}`}
                                leftIcon={showForm && "icon-arrow-left1"}
                                clickHandler={addNewHandle}
                            />
                        </div>
                    </AuthWrapper>
                </div>

                <div className="vr-content-holder basic-container">
                    {!showForm ? (
                        <>
                            {platformList.length && (
                                <PlatformTabHeader
                                    platFormList={platformList}
                                    currentPlatform={platform?.currentId}
                                    handlePlatformChange={handlePlatformChange}
                                />
                            )}
                            <div className="category-header">
                                <div className="navMenu">
                                    <ul className="category-list">
                                        {FOOTER_TABS.map((item, index) => {
                                            return (
                                                <li
                                                    key={index}
                                                    className={
                                                        selectedTab.selectedTab === item.id
                                                            ? "active"
                                                            : null
                                                    }
                                                    onClick={() => handleTabClick(item)}>
                                                    {item.name}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                            {data.length ? (
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <ul
                                                className="footer-table"
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}>
                                                <li className="heading">
                                                    <div className="drag"></div>
                                                    <span>Page Name</span>
                                                    <span>Created Date</span>
                                                    <span>Last Updated</span>
                                                    <span>Actions</span>
                                                </li>
                                                {data.map((item, index) => (
                                                    <Draggable
                                                        key={item.id}
                                                        draggableId={`item-${item.id}`}
                                                        index={index}>
                                                        {(provided, snapshot) => (
                                                            <li
                                                                id={item.id}
                                                                className={`tabList ${
                                                                    item.active ? "" : "unpublish"
                                                                }`}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}>
                                                                <div className="drag">
                                                                    <span className="menu-icon"></span>
                                                                    <span className="menu-icon"></span>
                                                                    <span className="menu-icon"></span>
                                                                    <span className="menu-icon"></span>
                                                                </div>
                                                                <span>{item.name}</span>
                                                                <span>
                                                                    {moment(
                                                                        item.dateCreated
                                                                    ).format("DD MMMM, YY")}
                                                                </span>
                                                                <span>
                                                                    {moment(
                                                                        item.lastUpdated
                                                                    ).format("DD MMMM, YY")}
                                                                </span>
                                                                <span>
                                                                    <i
                                                                        className="toolsBtn icon-del"
                                                                        onClick={() =>
                                                                            handleDelete(item.id)
                                                                        }>
                                                                        <span className="tooltiptext">
                                                                            Delete
                                                                        </span>
                                                                    </i>
                                                                    <i
                                                                        className={`toolsBtn ${
                                                                            item.active
                                                                                ? "icon-unpublish"
                                                                                : "icon-published"
                                                                        }`}
                                                                        onClick={() =>
                                                                            handlePublish(
                                                                                item.id,
                                                                                item.active
                                                                            )
                                                                        }>
                                                                        <span className="tooltiptext">
                                                                            {item.active
                                                                                ? "Unpublish"
                                                                                : "Publish"}
                                                                        </span>
                                                                    </i>
                                                                    <i
                                                                        className="toolsBtn icon-edit"
                                                                        onClick={() =>
                                                                            handleEdit(item)
                                                                        }>
                                                                        <span className="tooltiptext">
                                                                            Edit
                                                                        </span>
                                                                    </i>
                                                                </span>
                                                            </li>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </ul>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            ) : (
                                <div className="no-data">No Data Available</div>
                            )}
                        </>
                    ) : (
                        <>
                            <div className="heading-container">
                                <header>
                                    <i className="icon-arrow-left1" onClick={addNewHandle} />
                                    <span className="add-new-label">ADD NEW</span>
                                </header>
                                <Input
                                    labelText="Position"
                                    cName={"position"}
                                    name={"position"}
                                    disabled
                                    value={position || data.length + 1}
                                />
                            </div>
                            <div className="add-new-links">
                                {selectedTab.selectedTabName === FOOTER_TABS_TITLE.LINK && (
                                    <div className="radio-btn-container">
                                        <div className="radio-btn">
                                            <CustomRadioButton
                                                key={"Brand"}
                                                id={"protocol"}
                                                name={"linkType"}
                                                handleRadioChange={handleRadioChange}
                                                options={LINK_TYPES}
                                                label={"Link Type"}
                                                value={linkType}
                                            />
                                        </div>
                                    </div>
                                )}
                                {linkType !== LINK_TYPES[1].id && (
                                    <Select1
                                        placeholder="Select"
                                        cName={"event"}
                                        options={dropdownOptions}
                                        label={"Available Titles"}
                                        required={true}
                                        name={"titles"}
                                        changeHandler={onSelect}
                                        value={titles}
                                    />
                                )}
                                {linkType === LINK_TYPES[1].id && (
                                    <Input
                                        labelText="Title"
                                        placeholder="Enter Link Title"
                                        cName={"banner-title"}
                                        name={"linkTitle"}
                                        value={linkTitle}
                                        changeHandler={handleInput}
                                        required
                                    />
                                )}
                                {(linkType === LINK_TYPES[1].id ||
                                    selectedTab.selectedTabName !== FOOTER_TABS_TITLE.LINK) && (
                                    <Input
                                        labelText="URL"
                                        placeholder="Enter URL"
                                        cName={"banner-title"}
                                        name={"url"}
                                        value={url}
                                        required
                                        changeHandler={handleInput}
                                        isUrl={true}
                                        errMsg={urlError || null}
                                        validateUrl={validateUrl}
                                    />
                                )}

                                {selectedTab.selectedTabName === FOOTER_TABS_TITLE.LINK && (
                                    <>
                                        <Input
                                            labelText="Description"
                                            placeholder="Enter Description"
                                            cName={"banner-title"}
                                            name={"desc"}
                                            value={desc}
                                            changeHandler={handleInput}
                                        />

                                        <ImageUpload
                                            key={"banner"}
                                            name={"Add Icon"}
                                            label={"Upload"}
                                            value={iconImage}
                                            cName={"landscape"}
                                            uploadImage={handleImageUpload}
                                            removeImage={handleRemoveImage}
                                        />
                                    </>
                                )}

                                <Button
                                    bValue="SUBMIT"
                                    disabled={isDisabled}
                                    clickHandler={handleSubmit}
                                    cName="btn primary-btn"
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default Footer
