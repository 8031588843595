import React, { Component } from "react"
import ContentEntity from "../ContentEntity"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { uploadImage } from "../api/action"

import Button from "../../common/Button"
import { formatAndAssignValue, isFieldMultilingual, isMultiLingual } from "../../../utils/common"
import { getCredits } from "../../ContentNav/api/action"
import { BUTTON_CONSTANTS } from "../../../utils/constants"
class AddBrand extends Component {
    constructor(props) {
        super(props)

        this.state = {
            currentDataSource: "web_series",
            data: props.preFillData,
            searchedText: "",
            newActorList: [],
            isAllFilled: false,
            directorOptions: [],
            actorOptions: [],
            producerOptions: []
        }
        this.checkRequiredObj = {}

        this.screenName = "addBrand"
        this.isMultiLingualAllowed = isMultiLingual()
    }

    componentDidMount() {
        this.fetchCreditsOptions()
    }

    fetchCreditsOptions = async () => {
        const { getCredits } = this.props
        const response = await getCredits()
        /*     let response = {
      status: true,
      message: "fetching actors, directors and producers",
      data: {
        actor: [
          "Luisa Donalisio",
          "Luca Ward",
          "Bob Ferrari",
          "Luigi Filippo D'Amico",
          "Giancarlo Giannini'",
          "Maria Bosco",
          "Gino Sinimberghi",
          "Maurizio Mattioli",
          "Luisa Ferida",
          "Alessandro Haber'",
          "Ross ",
          "Chiara Conti",
          "Miriam Angilè",
          "M.Teresa Ruta",
          "vibha",
        ],
        director: [
          "Giuseppe Ivan",
          "Emily Tribianni",
          "Carmine Gallone",
          "Gerardo Fontana",
          "Abhineet",
          "Bruno Gaburro",
          "Michele Di Rienzo",
          "Rachel Green",
          "R Robot",
          "Ryan",
          "Maurizio Targhetta",
          "David Di Giorgio",
        ],
        producer: [
          "Joe Kaufmann",
          "Rachel Green",
          "Tara Strong",
          "Ian Bryce",
          "Paul Greengrass",
          "Robert Chartoff",
          "diksha",
          "John Davis",
          "Tim Bevan",
          "Jon Landau",
        ],
      },
    }; */
        try {
            if (response && response.status) {
                response.data.actor = response.data?.actor.map((actor) => {
                    return { id: actor, name: actor, value: actor, label: actor }
                })
                response.data.director = response.data?.director.map((director) => {
                    return {
                        id: director,
                        name: director,
                        value: director,
                        label: director
                    }
                })
                response.data.producer = response.data?.producer.map((producer) => {
                    return {
                        id: producer,
                        name: producer,
                        value: producer,
                        label: producer
                    }
                })
                this.setState({
                    actorOptions: response?.data?.actor,
                    directorOptions: response?.data?.director,
                    producerOptions: response?.data?.producer
                })
            }
        } catch (e) {
            return e
        } finally {
            this.setState({ isLoading: false })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.preFillData !== prevProps.preFillData) {
            this.setState({
                data: this.props.preFillData
            })
        }
    }

    updateRequiredFields = (name, value) => {
        this.checkRequiredObj[name] = value
    }
    checkValues = (name, obj) => {
        if (name === "id" || name === "url" || name === "name") {
            return true
        }
        for (let key in obj) {
            if (name === key) {
                if (Array.isArray(obj[key])) {
                    return obj[key].length
                } else if (typeof obj[key] === "object" && obj[key] !== null) {
                    if (Object.keys(obj).length === 0 && obj.constructor === Object) {
                        return false
                    } else {
                        return true
                    }
                } else {
                    return obj[key].length
                }
            } else if (typeof obj[key] === "object" && obj[key] !== null) {
                if (this.checkValues(name, obj[key]) !== undefined) {
                    return this.checkValues(name, obj[key])
                }
            }
        }
    }
    checkRequiredFields = (obj) => {
        let allFilled = true
        for (let key in this.checkRequiredObj) {
            if (this.checkRequiredObj[key]) {
                allFilled = this.checkValues(key, obj)
                if (!allFilled) {
                    return false
                }
            }
        }
        return allFilled
    }
    handleChange = (name, value, id) => {
        let stateData = this.state.data
        const isMulti = isFieldMultilingual(name, "brand", this.props?.multilingualKeys)
        const { code } = this.props.selectedLanguage

        if (
            name?.toLowerCase() === "genre" ||
            name === "language"
            //  ||
            // name === "certification"
        ) {
            // stateData.brandDescription[name.toLowerCase()] = isMulti ? updatedMultilingualValue : value;
            stateData.brandDescription[name.toLowerCase()] = formatAndAssignValue(
                isMulti,
                code,
                stateData.brandDescription[name.toLowerCase()],
                value
            )
        } else if (name === "rating" || name === "brand_production_house") {
            // stateData.brandDescription[name] = { id: value, name: value };
            stateData.brandDescription[name] = formatAndAssignValue(
                isMulti,
                code,
                stateData.brandDescription[name],
                value
            )
        } else if (name === "year") {
            stateData.brandDescription[name] = formatAndAssignValue(
                isMulti,
                code,
                stateData.brandDescription[name],
                value
            )
        } else if (name === "description") {
            stateData.brandDescription.description = formatAndAssignValue(
                isMulti,
                code,
                stateData.brandDescription.description,
                value
            )
        } else if (name === "age_rating") {
            stateData.brandDescription["age_rating"] = formatAndAssignValue(
                true,
                code,
                stateData.brandDescription["age_rating"],
                value
            )
        } else if (name === "videoFormat") {
            if (value) {
                stateData.brandDescription[name].push({
                    id: id,
                    name: id
                })
            } else {
                let newVideoFormats = stateData.brandDescription[name].filter((element) => {
                    return element.id !== id
                })
                stateData.brandDescription[name] = newVideoFormats
            }
        } else if (
            name.toLowerCase() === "director" ||
            name.toLowerCase() === "producer" ||
            name.toLowerCase() === "actor"
        ) {
            stateData.credit[name.toLowerCase()] = formatAndAssignValue(
                isMulti,
                code,
                stateData.credit[name.toLowerCase()],
                value
            )
        } else {
            stateData[name] = formatAndAssignValue(isMulti, code, stateData[name], value)
        }

        this.setState({
            data: stateData
        })
    }

    handleSave = () => {
        this.props.handleSave("addSeason", "addBrand", this.state.data)
    }
    imgUpload = async (data, id, name) => {
        const response = await this.props.uploadImage(data)
        if (response && response.status) {
            let imgUrl = response.data.path
            this.handleChange(name, imgUrl, id)
        }
    }
    imgRemove = (name) => {
        let stateData = this.state.data
        stateData[name] = ""
        this.setState({
            data: stateData
        })
    }
    // handleSearchBox = (name, value) => {
    //   let { formMetaData } = this.props;
    //   let actor = [];
    //   let newActorList = actor.filter((item) => {
    //     if (item.name.toLowerCase().includes(value.toLowerCase())) {
    //       return item;
    //     }
    //   });
    //   this.setState({
    //     searchedText: value,
    //     newActorList: newActorList.length ? newActorList : actor,
    //   });
    // };
    handleCheckBoxChange = (e, entityId, name, actorId) => {
        let { formMetaData } = this.props
        let actorList = this.state.data.credit[name]

        formMetaData.forEach((item, index) => {
            if (item.entityId === entityId) {
                if (e.target.checked) {
                    item.values.forEach((elem) => {
                        if (elem.id === actorId) {
                            actorList.push({
                                id: elem.id,
                                name: elem.name,
                                url: elem.url
                            })
                        }
                    })
                } else {
                    actorList.splice(index, 1)
                }
            }
        })
        let stateData = this.state.data
        stateData.credit[name] = actorList
        this.setState({
            data: stateData
        })
    }
    handleDeleteChange = (e, item, id) => {
        let { data } = this.state
        let temp = this.state.data[id]
        let temp2 = temp.filter((val) => {
            if (val.id !== item.id) {
                return val
            }
            return false
        })
        data[id] = temp2
        this.setState({
            data
        })
    }

    render() {
        let className =
            this.props.currentScreen === this.screenName
                ? "add-step-container active"
                : "add-step-container"
        const { formMetaData, selectedLanguage, genres, isUploadingImage } = this.props
        const { searchedText, newActorList } = this.state
        return (
            <div className={className}>
                <div className="content-entity-wrapper">
                    {this.props.currentScreen === this.screenName ? (
                        <ContentEntity
                            updateRequiredFields={this.updateRequiredFields}
                            data={this.props.data.entity}
                            formMetaData={formMetaData}
                            handleChange={this.handleChange}
                            uploadImage={this.imgUpload}
                            removeImg={this.imgRemove}
                            preFillData={this.state.data}
                            genres={genres}
                            searchedText={searchedText}
                            handleSearchBox={this.handleSearchBox}
                            handleCheckBoxChange={this.handleCheckBoxChange}
                            handleDeleteChange={this.handleDeleteChange}
                            newActorList={newActorList}
                            selectedLanguage={selectedLanguage}
                            certifications={this.props?.certifications}
                            selectedData={this?.props?.selectedData}
                            actorOptions={this.state.actorOptions}
                            directorOptions={this.state.directorOptions}
                            producerOptions={this.state.producerOptions}
                        ></ContentEntity>
                    ) : null}
                </div>

                <div className="save-and-next">
                    <Button
                        cName={"btn primary-btn"}
                        bType={"submit"}
                        bValue={BUTTON_CONSTANTS?.SAVE_NEXT_STEP}
                        bIcon={"icon-left-arrow"}
                        clickHandler={this.handleSave}
                        disabled={isUploadingImage}
                        // disabled={!this.state.isAllFilled}
                    />
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isUploadingImage: state?.loader?.isLoading //Disable the submit button whenever user is uploading images
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                uploadImage,
                getCredits
            },
            dispatch
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddBrand)
