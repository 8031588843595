import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import get from "lodash/get"
import viewIcon from "../../../assets/images/eyeIcon.svg"
import { fetchContent } from "../api/action"
import Loader from "../../../assets/images/loader.gif"
import ImdbModal from "./ImdbModal"
import ContentEnrichmentView from "./ContentEnrichmentView"
import TextArea from "../../common/TextArea"
import Input from "../../common/Input"
import MultiSelect from "../../common/CustomMultiselect"
import Select from "../../common/SelectBox"
import CustomInput from "../../common/MaskedInput"
import CustomRadioButton from "../../common/customRadioBtn"
import SelectedButtons from "../../common/SelectedButtons"
import CustomCheckBox from "../../common/commonCheckbox"
import Button from "../../common/Button"
import ImageUplode from "../../Movies/addMovie/imageUplode"
import { uploadImage } from "../../Movies/api/action"
import { saveContentNav, getCredits, getCategory } from "../api/action"
import { DEFAULT_SELECTED_LANGUAGE_CODE, imgPathPrefix } from "../api/constants"
import {
    checkAllLanguageHasValue,
    formatValueAccToMutlilingualAPI,
    getMultiLingualValue,
    isFieldMultilingual,
    isMultiLingual,
    scrollPage,
    getLanguages,
    timeToSeconds
} from "../../../utils/common"
import ContentEnrichment from "./ContentEnrichment"

import "./style.scss"
import LanguageCardList, { getLanguageObject } from "../../common/LanguageCardList"
import LanguageDataConfirmationModal from "../../common/LanguageCardList/languageDataConfirmationModal"
import { BUTTON_CONSTANTS, PERMISSIONS, TEXT_CONSTANTS } from "../../../utils/constants"

import { isEmpty } from "lodash"
import { checkIfUserHasAccess } from "../../../utils/users"
import { AuthWrapper } from "../../common/AuthWrapper"
import CustomModal from "../../common/Modal"
import Checkbox from "../../common/Checkbox"
import Search from "../../common/Search"
import { REGEX, ERROR_MESSAGES } from "../../../utils/constants"
import CustomCreateAbleSelect from "../../common/CustomCreateAbleSelect"
import ContentData from "./ContentData"

class AddContentForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isFilled: false,
            payload: this.props.selectedData ? this.props.selectedData : {},
            showInsights: false,
            isDrm: this.props?.selectedData?.drm,
            selectedSubtitles: [],
            finalSelectedSubtitles: [],
            subTitleArray: [],
            selectedLanguage: {
                name: "English",
                code: "en"
            },
            directorOptions: [],
            actorOptions: [],
            producerOptions: [],
            categoryOptions: [],
            emptyLangDataModal: false,
            canUpdateStatus: checkIfUserHasAccess([PERMISSIONS.CONTENT_EDIT]),
            showSubtitlePopup: false,
            isSubtitleExpand: true
        }
        this.isMultiLingualAllowed = isMultiLingual()
    }

    componentDidMount() {
        const { sub_titles } = this.props?.selectedData
        if (!isEmpty(sub_titles)) {
            this.setState({
                selectedSubtitles: sub_titles,
                finalSelectedSubtitles: sub_titles
            })
        }
        this.updateLanguageObject()
        this.fetchCreditsOptions()
        this.fetchCategoryOptions()

        scrollPage()
    }
    fetchCategoryOptions = async () => {
        const { getCategory } = this.props
        const response = await getCategory()
        if (response && response.status) {
            response.data.channelCategory = response.data.channelCategory.map((category) => {
                return {
                    id: category,
                    name: category,
                    value: category,
                    label: category
                }
            })

            this.setState({
                categoryOptions: response?.data?.channelCategory
            })
        }
    }
    fetchCreditsOptions = async () => {
        const { getCredits } = this.props
        const response = await getCredits()
        /*  let response = {
      status: true,
      message: "fetching actors, directors and producers",
      data: {
        actor: [
          "Luisa Donalisio",
          "Luca Ward",
          "Bob Ferrari",
          "Luigi Filippo D'Amico",
          "Giancarlo Giannini'",
          "Maria Bosco",
          "Gino Sinimberghi",
          "Maurizio Mattioli",
          "Luisa Ferida",
          "Alessandro Haber'",
          "Ross ",
          "Chiara Conti",
          "Miriam Angilè",
          "M.Teresa Ruta",
          "vibha",
        ],
        director: [
          "Giuseppe Ivan",
          "Emily Tribianni",
          "Carmine Gallone",
          "Gerardo Fontana",
          "Abhineet",
          "Bruno Gaburro",
          "Michele Di Rienzo",
          "Rachel Green",
          "R Robot",
          "Ryan",
          "Maurizio Targhetta",
          "David Di Giorgio",
        ],
        producer: [
          "Joe Kaufmann",
          "Rachel Green",
          "Tara Strong",
          "Ian Bryce",
          "Paul Greengrass",
          "Robert Chartoff",
          "diksha",
          "John Davis",
          "Tim Bevan",
          "Jon Landau",
        ],
      },
    }; */
        if (response && response.status) {
            response.data.actor = response.data.actor.map((actor) => {
                return { id: actor, name: actor, value: actor, label: actor }
            })
            response.data.director = response.data.director.map((director) => {
                return {
                    id: director,
                    name: director,
                    value: director,
                    label: director
                }
            })
            response.data.producer = response.data.producer.map((producer) => {
                return {
                    id: producer,
                    name: producer,
                    value: producer,
                    label: producer
                }
            })
            this.setState({
                actorOptions: response?.data?.actor,
                directorOptions: response?.data?.director,
                producerOptions: response?.data?.producer
            })
        }
    }

    updateLanguageObject = async () => {
        this.languageObject = await getLanguageObject()
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedData !== prevProps.selectedData) {
            this.setState({
                payload: this.props.selectedData ? this.props.selectedData : {}
            })
        }
    }

    checkobjValue = (value) => {
        if (this.isMultiLingualAllowed) {
            if (Array.isArray(value)) {
                return value?.length > 0 ? true : false
            } else {
                return value?.values?.[DEFAULT_SELECTED_LANGUAGE_CODE]?.length > 0 ? true : false
            }
        } else {
            return Object.keys(value)?.length > 0 ? true : false
        }
    }

    isInvalidPayload = (payload, dataFormat) => {
        let data = dataFormat?.map((ele) => {
            return ele in payload ? true : false
        })
        if (data?.includes(false)) {
            const position = data.indexOf(false)
            const value = dataFormat[position]
            return {
                error: String(value).toUpperCase().replace("_", " ") + " IS REQUIRED"
            }
        }
        data = dataFormat?.map((dt) => {
            if (dt in payload) {
                let type = typeof payload[dt]
                switch (type) {
                    case "string":
                        return payload[dt] !== "" ? true : false
                    case "object":
                        return this.checkobjValue(payload[dt])
                    case "number":
                        return payload[dt] !== 0 ? true : false
                    default:
                        return null
                }
            }
            return null
        })

        if (data?.includes(false)) {
            const position = data.indexOf(false)
            const value = dataFormat[position]
            return {
                error:
                    String(value).toUpperCase().replace("_", " ") +
                    " IS INVALID/EMPTY IN ENGLISH LANGUAGE"
            }
        }
        return null
    }

    handleRadioChange = (e) => {
        const { payload } = this.state
        payload[e?.target.name] = e?.target.value === "1" ? true : false
        this.setState({
            payload
        })
    }

    changeHandler = (name, value, isGroup, item) => {
        const { formData } = this.props
        let isMulti = this.isMultiLingualAllowed && formData?.multilingualKeys?.includes(name)
        if (name === "age_rating") {
            isMulti = true
        }
        if (isMulti) {
            const { selectedLanguage } = this.state
            var updatedMultilingualValue = { ...this.state.payload?.[name] }
            updatedMultilingualValue[selectedLanguage.code] = value
        }
        const boxValue =
            item &&
            item.options &&
            item.options.length &&
            item.options[parseInt(value) - 1] &&
            item.options[parseInt(value) - 1].name
        this.setValues(
            isGroup,
            item,
            name,
            boxValue
                ? boxValue
                : isMulti
                ? updatedMultilingualValue
                : typeof value === "string"
                ? value
                : value,
            isMulti
        )
    }
    setValues = (isGroup, item, name, value, isMulti) => {
        const { payload } = this.state
        const { formData } = this.props

        if (item && item.htmlInputType === "multiSelect") {
            value = this.updateGenreLanguage(value, isMulti)
        }

        if (value === "YES") value = true
        if (value === "NO") value = false

        if (item && item.htmlInputType === "checkbox") {
            if (payload[name]) value = [value, ...payload[name]]
            else value = [value]
        }

        let dataFormat = formData && formData.dataFormat.properties

        let type

        if (name in dataFormat) {
            type = dataFormat[name].type
        }
        const { selectedLanguage } = this.state

        if (type) {
            if (type === "number") {
                value = isMulti
                    ? formatValueAccToMutlilingualAPI(value, selectedLanguage?.code)
                    : Number(value)
            } else if (type === "string") {
                value = isMulti
                    ? formatValueAccToMutlilingualAPI(value, selectedLanguage?.code)
                    : String(value)
            } else if (type === "object") {
                value = isMulti
                    ? formatValueAccToMutlilingualAPI(value, selectedLanguage?.code)
                    : value
            }
        }

        this.setState({
            payload: {
                ...this.state.payload,
                [name]: value
            }
        })
    }

    imgUpload = async (data, id, name) => {
        const response = await this.props.uploadImage(data)
        if (response && response.status) {
            let imgUrl = response.data.path
            this.changeHandler(name, imgUrl, false)
        }
    }
    imgRemove = (name, item) => {
        this.changeHandler(name, "", false, item)
    }

    handleCheckBox = (name, value, id, isGroup, item, options) => {
        const boxValue = id
        if (value && value.includes(id)) {
            let position = value.indexOf(id)
            value.splice(position, 1)
            this.setState({
                payload: {
                    ...this.state.payload,
                    [name]: value
                }
            })
        } else this.setValues(isGroup, item, name, boxValue)
    }

    handleExpand = (item) => {
        item.isGroup = !item.isGroup

        this.setState({
            ...this.state.payload
        })
    }

    renderFields = (item, index, isGroup, value, options, dataFormat, requiredFields, forSize) => {
        value = value === true || value === false ? (value === true ? "YES" : "NO") : value
        let required = false
        if (item?.title === "category") {
            options = this.state.categoryOptions
        }
        if (requiredFields && requiredFields.includes(item.title)) {
            required = true
        }
        const globalDRMResponse = this.props?.drmResponse?.isDRMEnabled
        let isDisableRadio = false
        // let isDisableRadio = item.title === "drm" ? true : false;
        if (!globalDRMResponse || this.props.isEdit) {
            if (item.title === "drm" || item?.title === "offlineDownload") {
                isDisableRadio = true
                // value = "No"
            }
        }
        // Disable asset_id and drm_asset_id once value is added
        if (
            (item?.title === "asset_id" &&
                this.props?.selectedData?.hasOwnProperty("asset_id") &&
                this.props.selectedData?.asset_id !== "") ||
            (item?.title === "drm_asset_id" &&
                this.props.selectedData?.hasOwnProperty("drm_asset_id") &&
                this.props.selectedData?.drm_asset_id !== "")
        ) {
            isDisableRadio = true
        }

        const isDRMEnabled = this.state?.payload?.drm && this.props?.drmResponse?.isDRMEnabled
        if (isDRMEnabled) {
            if (item?.title === "play_url") return
        } else {
            if (
                item?.title === "hls_play_url" ||
                item?.title === "dash_play_url" ||
                item?.title === "offlineDownload"
            )
                return
        }

        if (item) {
            const { selectedElement } = this.props
            let className
            if (forSize) {
                className = item && item.size === "S" ? "small" : "medium"
            } else {
                className =
                    item && item.size === "S"
                        ? "small"
                        : item && item.size === "M"
                        ? "medium"
                        : "large"
            }
            const languageCode = this.state?.selectedLanguage?.code
            if (item.title === "genre") {
                item.htmlElement = "select"
                item.htmlInputType = "multiSelect"
            }
            const { canUpdateStatus } = this.state

            if (item.title === "director" || item.title === "producer" || item.title === "actor") {
                item.htmlElement = "select"
                item.htmlInputType = "multiSelect"
            }
            let selectBoxValue = { name: this.state.payload[item.title], id: item.title }
            if (item.title === "category") {
                selectBoxValue = {
                    ...selectBoxValue,
                    name: this.state.payload.category?.values?.en
                }
            } else if (item.title === "catchupavailable") {
                selectBoxValue = {
                    ...selectBoxValue,
                    name: value
                }
            }
            switch (item.htmlElement) {
                case "input":
                    return item.htmlInputType === "textarea" ? (
                        <div
                            className={`input-element ${
                                item.id === selectedElement && selectedElement.id
                                    ? "highlighted"
                                    : ""
                            } ${className}`}>
                            <TextArea
                                row={5}
                                col={5}
                                labelText={item.title.toUpperCase().replace("_", " ")}
                                placeholder={item.htmlPlaceholder || item.name}
                                required={required}
                                name={item.title}
                                value={getMultiLingualValue(
                                    this.isMultiLingualAllowed,
                                    value,
                                    languageCode
                                )}
                                changeHandler={(name, value) =>
                                    this.changeHandler(name, value, isGroup, item)
                                }
                                disabled={!canUpdateStatus}
                            />
                        </div>
                    ) : (
                        <div
                            className={`input-element  ${
                                item.id === selectedElement && selectedElement.id
                                    ? "highlighted"
                                    : ""
                            } ${className}`}>
                            <Input
                                type={item && item.htmlInputType}
                                labelText={item.title.toUpperCase().replace("_", " ")}
                                name={item.title}
                                required={required}
                                value={getMultiLingualValue(
                                    this.isMultiLingualAllowed,
                                    value,
                                    languageCode
                                )}
                                placeholder={item.htmlPlaceholder || item.name}
                                changeHandler={(name, value) =>
                                    this.changeHandler(name, value, isGroup, item)
                                }
                                disabled={!canUpdateStatus}
                            />
                        </div>
                    )

                case "file":
                    const title = getMultiLingualValue(
                        this.isMultiLingualAllowed,
                        value,
                        languageCode
                    )
                    return (
                        <div className={`col-small`}>
                            <ImageUplode
                                key={item.title}
                                name={item.title}
                                id={item.name}
                                placeholder={item.title.toUpperCase().replace("_", " ")}
                                label={item.title.toUpperCase().replace("_", " ")}
                                required={required}
                                value={title ? `${imgPathPrefix}${title}` : null}
                                uploadImage={this.imgUpload}
                                removeImg={(name) => this.imgRemove(name, item)}
                                disabled={!canUpdateStatus}
                            />
                        </div>
                    )

                case "select":
                    // let newValue = value
                    let selectOptions = options
                        ? options.map((ele) => {
                              return { id: ele, name: ele, value: ele }
                          })
                        : []

                    let modValue = getMultiLingualValue(
                        this.isMultiLingualAllowed,
                        value,
                        languageCode
                    )
                    if (item.htmlInputType === "multiSelect") {
                        modValue = Array.isArray(modValue) ? modValue : []
                    }
                    return item.htmlInputType === "multiSelect" ? (
                        item.title === "actor" ||
                        item.title === "director" ||
                        item.title === "producer" ? (
                            <CustomCreateAbleSelect
                                key={item.name + "-" + item.id}
                                options={
                                    item.title === "actor"
                                        ? this.state.actorOptions
                                        : item.title === "director"
                                        ? this.state.directorOptions
                                        : item.title === "producer"
                                        ? this.state.producerOptions
                                        : ""
                                }
                                required={required}
                                placeholder={"-select-"}
                                label={item.title.toUpperCase().replace("_", " ")}
                                id={item.title}
                                name={item.title}
                                selectedOptions={
                                    modValue && !modValue?.[0]?.id
                                        ? modValue?.map((ele) => ({
                                              id: ele,
                                              name: ele,
                                              value: ele,
                                              label: ele
                                          }))
                                        : modValue
                                }
                                changeHandler={(name, value) =>
                                    this.changeHandler(name, value, isGroup, item, selectOptions)
                                }
                            />
                        ) : (
                            <div
                                className={`input-element ${
                                    item.id === selectedElement && selectedElement.id
                                        ? "highlighted"
                                        : ""
                                } ${className}`}>
                                <MultiSelect
                                    key={item.name + "-" + item.id}
                                    options={
                                        options
                                            ? options.map((ele) => {
                                                  return { id: ele, name: ele, value: ele }
                                              })
                                            : []
                                    }
                                    required={required}
                                    placeholder={"-select-"}
                                    label={item.title.toUpperCase().replace("_", " ")}
                                    id={item.title}
                                    name={item.title}
                                    selectedOptions={
                                        modValue && !modValue?.[0]?.id
                                            ? modValue?.map((ele) => ({
                                                  id: ele,
                                                  name: ele,
                                                  value: ele
                                              }))
                                            : modValue
                                    }
                                    changeHandler={(name, value) =>
                                        this.changeHandler(name, value, isGroup, item)
                                    }
                                    isDisabled={!canUpdateStatus}
                                />
                            </div>
                        )
                    ) : (
                        <div className={`col-large`}>
                            <Select
                                key={item.name + "-" + item.id}
                                placeholder="-select-"
                                options={
                                    item.title === "category"
                                        ? this.state.categoryOptions
                                        : options?.map((ele) => {
                                              return {
                                                  id: ele,
                                                  name: ele,
                                                  value: ele
                                              }
                                          })
                                }
                                defaultOption={
                                    modValue
                                        ? {
                                              id: modValue,
                                              name: modValue,
                                              value: modValue
                                          }
                                        : []
                                }
                                label={item.title.toUpperCase().replace("_", " ")}
                                required={required}
                                name={item.title}
                                changeHandler={(name, value) =>
                                    this.changeHandler(name, value, isGroup, item)
                                }
                                isDisabled={!canUpdateStatus}
                                value={selectBoxValue}
                            />
                        </div>
                    )
                case "dateAndTime":
                    return item.htmlInputType === "date" ? (
                        <div
                            className={`input-element ${
                                item.id === selectedElement && selectedElement.id
                                    ? "highlighted"
                                    : ""
                            } ${className}`}>
                            <Input
                                type={"text"}
                                labelText={item.title.toUpperCase().replace("_", " ")}
                                required={required}
                                name={item.title}
                                value={value}
                                placeholder={item.htmlPlaceholder || item.name}
                                changeHandler={(name, value) =>
                                    this.changeHandler(name, value, isGroup, item)
                                }
                                disabled={!canUpdateStatus}
                            />
                        </div>
                    ) : (
                        <div
                            className={`input-element ${
                                item.id === selectedElement && selectedElement.id
                                    ? "highlighted"
                                    : ""
                            } ${className}`}>
                            <CustomInput
                                type={item && item.htmlInputType}
                                labelText={item.title.toUpperCase().replace("_", " ")}
                                required={required}
                                placeholder={item.htmlPlaceholder || "HH/MM/SS"}
                                mask="11/11/11"
                                name="duration"
                                value={value}
                                changeHandler={(name, value) =>
                                    this.changeHandler(name, value, isGroup, item)
                                }
                                // placeholder="HH / MM / SS"
                                disabled={!canUpdateStatus}
                            />
                        </div>
                    )
                case "radio":
                    return (
                        <div
                            className={`input-element ${
                                item.id === selectedElement && selectedElement.id
                                    ? "highlighted"
                                    : ""
                            } ${className}`}>
                            <CustomRadioButton
                                options={item.options ? item.options : []}
                                label={item.title.toUpperCase().replace("_", " ")}
                                required={required}
                                disabled={isDisableRadio}
                                name={item.title}
                                value={value === "YES" ? "1" : "2"}
                                cRadioBtn={`radioBtnContainer ${
                                    isDisableRadio ? "disableRadio" : null
                                }`}
                                handleRadioChange={this.handleRadioChange}
                            />
                        </div>
                    )
                case "checkbox":
                    return (
                        <div
                            className={`input-element ${
                                item.id === selectedElement && selectedElement.id
                                    ? "highlighted"
                                    : ""
                            } ${className}`}>
                            <CustomCheckBox
                                options={
                                    options
                                        ? options.map((ele) => {
                                              return { id: ele, name: ele }
                                          })
                                        : []
                                }
                                label={item.title.toUpperCase().replace("_", " ")}
                                required={required}
                                name={item.title}
                                isChecked={value ? value : null}
                                changeHandler={(id) =>
                                    this.handleCheckBox(
                                        item.title,
                                        value,
                                        id,
                                        isGroup,
                                        item,
                                        options
                                    )
                                }
                                disabled={!canUpdateStatus}
                            />
                        </div>
                    )
                case "button":
                    return (
                        <div
                            className={`input-element ${
                                item.id === selectedElement && selectedElement.id
                                    ? "highlighted"
                                    : ""
                            } ${className}`}>
                            <Button cName="btn primary-btn" bValue={item.title} bType="button" />
                        </div>
                    )

                case "group":
                    return (
                        <div
                            className={`input-element group ${
                                item.id === selectedElement && selectedElement.id
                                    ? "highlighted"
                                    : ""
                            }`}>
                            <div className="group-header">
                                <div className="group-title">
                                    <div className="col-large content-form-title">
                                        <i
                                            className={!item.isGroup ? "icon-plus" : "icon-minus"}
                                            onClick={() => {
                                                this.handleExpand(item)
                                            }}></i>
                                        <div className="content-title">{item.groupTitle}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="group-body" style={{ height: "100%" }}>
                                {item.elementsArr && item.isGroup && item.elementsArr.length
                                    ? item.elementsArr.map((ele, i) => {
                                          const dt = ele && ele.title
                                          let options =
                                              ele.title in dataFormat &&
                                              dataFormat[ele.title] &&
                                              dataFormat[ele.title]?.items &&
                                              dataFormat[ele.title]?.items?.enum &&
                                              dataFormat[ele.title]?.items?.enum.length &&
                                              dataFormat[ele.title]?.items?.enum.length > 0
                                                  ? dataFormat[ele.title].items.enum
                                                  : null

                                          if (
                                              ele.title in dataFormat &&
                                              dataFormat[ele.title].type === "boolean"
                                          ) {
                                              options = ["TRUE", "FALSE"]
                                          }
                                          const value =
                                              this.state.payload &&
                                              ele &&
                                              dt &&
                                              dt in this.state.payload &&
                                              this.state.payload[dt]
                                                  ? this.state.payload[dt]
                                                  : null
                                          if (ele.title === "genre" && this.isMultiLingualAllowed)
                                              options = this.getOptions(
                                                  ele.title,
                                                  dataFormat,
                                                  this.props?.formData?.multilingualKeys
                                              )
                                          return (
                                              <div className="element-cont">
                                                  {this.renderFields(
                                                      ele,
                                                      i,
                                                      true,
                                                      value,
                                                      options,
                                                      dataFormat,
                                                      requiredFields
                                                  )}
                                              </div>
                                          )
                                      })
                                    : null}
                            </div>
                        </div>
                    )

                default:
                    return null
            }
        }
    }

    updatePayloadData = (updatePayload) => {
        // Updating payload for genre, languages, director, actor and producer as these fields now needs to be coverted to array of string
        const val = ["genre", "language", "actor", "director", "producer"]
        for (let key in updatePayload) {
            if (val?.includes(key)) {
                const tempValue = updatePayload[key]
                if (tempValue.hasOwnProperty("is_multilingual")) {
                    const values = tempValue?.values
                    for (let languageCode in values) {
                        const data = values?.[languageCode]
                        if (!Array.isArray(data)) {
                            values[languageCode] = data?.split(",")
                        }
                    }
                } else {
                    if (!Array.isArray(tempValue)) {
                        updatePayload[key] = tempValue?.split(",")
                    }
                }
            }
        }
    }

    handleSave = async () => {
        const { payload, selectedSubtitles } = this.state
        const { isEdit, editId, formData } = this.props

        if (payload.duration_hhmmss) {
            if (!REGEX?.DURATION_REGEX?.test(payload.duration_hhmmss)) {
                toast.error(
                    `${ERROR_MESSAGES.TIME_FORMAT1_ERROR}.${ERROR_MESSAGES.TIME_FORMAT2_ERROR}`
                )
                return
            } else {
                let numericDuration = timeToSeconds(payload.duration_hhmmss)
                payload.duration = numericDuration
            }
        }

        let updatePayload = JSON.parse(JSON.stringify(payload))

        updatePayload["sub_titles"] = !isEmpty(selectedSubtitles) ? selectedSubtitles : []
        let isValidated = this.validateSubtitles()
        if (!isValidated) {
            toast.error("Invalid/Empty subtitles")
            return
        }
        if (isEdit) {
            updatePayload["id"] = editId
        }
        if (!updatePayload.hasOwnProperty("drm")) {
            updatePayload["drm"] = !!this.state.isDrm
        }
        if (!updatePayload.hasOwnProperty("offlineDownload")) {
            updatePayload["offlineDownload"] = !!this.state.offlineDownload
        }

        // if (!updatePayload?.international_content) {
        //   updatePayload.international_content = false;
        // }

        let requiredFields = formData && formData.dataFormat?.required
        const errors = this.isInvalidPayload(updatePayload, requiredFields)
        if (!errors) {
            this.setState({
                isFilled: true
            })
            this.updatePayloadData(updatePayload)
            if (this.isMultiLingualAllowed) {
                const { formData } = this.props
                let data = requiredFields?.map((dt) => {
                    if (dt in updatePayload && formData?.multilingualKeys?.includes(dt)) {
                        let type = typeof updatePayload[dt]
                        switch (type) {
                            case "object": {
                                if (!Array.isArray(updatePayload[dt])) {
                                    return checkAllLanguageHasValue(
                                        updatePayload[dt]?.values,
                                        this.languageObject
                                    )
                                }
                                return true
                            }
                            default:
                                return true
                        }
                    }
                    return true
                })

                if (data?.includes(false)) {
                    this.setState({
                        emptyLangDataModal: true,
                        finalPayload: updatePayload,
                        isFilled: false
                    })
                } else {
                    await this.submitData(updatePayload)
                }
            }
        } else {
            toast.error(errors.error)
        }
    }

    modalCancelHandler = () => {
        this.setState({
            emptyLangDataModal: false
        })
    }

    submitData = async (updatePayload) => {
        delete updatePayload?.age_rating?.search_key
        delete updatePayload?.age_rating?.rank
        const contentType = this.props.contentType
        this.modalCancelHandler()
        if (contentType?.toLowerCase() === "movies" || contentType?.toLowerCase() === "webseries") {
            // updatePayload.age_rating = {
            //   certification: updatePayload?.age_rating,
            // };

            if (!updatePayload.age_rating?.hasOwnProperty("certification")) {
                updatePayload.age_rating = {
                    certification: updatePayload?.age_rating
                }
            }

            // To remove extra certification value
            if (updatePayload?.age_rating?.certification?.values?.hasOwnProperty("certification")) {
                delete updatePayload?.age_rating?.certification?.values?.certification
            }
        }

        const response = await this.props.saveContentNav(updatePayload, this.props.formData.id)

        if (get(response, "status")) {
            this.props.handleAddClick()
        } else {
            this.setState({
                isFilled: false
            })
        }
    }

    handleInsights = () => {
        // Movie insight not required in Iframe
        // this.setState(
        //   {
        //     showInsights: !this.state.showInsights,
        //   },
        //   () => {
        //     window.scrollTo(0, 0);
        //     if (this.state.showInsights && this.props.formData.name === "MOVIES") {
        //       let obj = {
        //         "62f499d3cb4172051f7ab386": "c1wkcu",
        //         "62f48b58cb4172051f7ab380": "acozhx",
        //         "62fb2d6ecb4172051f7ab388": "7sceku",
        //         "62fb3044cb4172051f7ab38b": "im7pio",
        //         "62f48d4ecb4172051f7ab381": "wfiyn0",
        //         "62fb311fcb4172051f7ab38c": "lplu9p",
        //       };
        //       let url = "https://legacy.portal.vionlabs.com/library/";
        //       if (obj[this.props.editId]) {
        //         url = `${url}${obj[this.props.editId]}`;
        //       } else {
        //         url = `${url}63s513`;
        //       }
        //       // let iframe = document.getElementById("insights-iframe");
        //       // iframe.src = url;
        //     }

        //   }
        //   );
        if (this.props.formData.name === "MOVIES") {
            let obj = {
                "62f499d3cb4172051f7ab386": "c1wkcu",
                "62f48b58cb4172051f7ab380": "acozhx",
                "62fb2d6ecb4172051f7ab388": "7sceku",
                "62fb3044cb4172051f7ab38b": "im7pio",
                "62f48d4ecb4172051f7ab381": "wfiyn0",
                "62fb311fcb4172051f7ab38c": "lplu9p"
            }
            var movieId = obj[this.props?.editId] ? obj[this.props?.editId] : "63s513"
        }
        window.open(`https://portal.vionlabs.com/library/${movieId}`, "_blank")
    }
    updateGenreLanguage = (value, isMulti) => {
        if (isMulti) {
            return value
        }
        if (value === undefined || value === null) {
            return
        }
        let list = []
        value.map((i) => list.push(i.name))
        return list
    }

    nextWebSeries = async () => {
        const { steppersData, currentStep, setCurrentStep } = this.props
        if (currentStep !== steppersData.length - 1) {
            setCurrentStep(currentStep + 1)
        } else {
            await this.props.saveContentNav(this.state.payload, this.props.formData.id)
            this.props.handleViewClick()
        }
    }
    previousWebSeries = () => {
        const { currentStep, setCurrentStep } = this.props
        setCurrentStep(currentStep - 1)
    }

    renderEnrichmentAndMetaDataButton = () => {
        if (this.props.isEdit) {
            return (
                <AuthWrapper permissions={[PERMISSIONS.CONTENT_EDIT]}>
                    <Button
                        cName={"btn border-btn"}
                        bType={"submit"}
                        bValue={BUTTON_CONSTANTS?.METADATA_UPDATE}
                        bIcon={"icon-left-arrow"}
                        clickHandler={() => {
                            this.props.getContentEnrichment()
                        }}
                    />
                    <Button
                        cName={"btn border-btn"}
                        bType={"submit"}
                        bValue={BUTTON_CONSTANTS?.CONTENT_ENRICHMENT}
                        bIcon={"icon-left-arrow"}
                        clickHandler={() => {
                            this.props.contentEnrichmentBtnHandler(true)
                        }}
                    />
                    <button onClick={this.props.viewData} className="view-icon">
                        <img alt="view-icon" src={viewIcon} />
                    </button>
                </AuthWrapper>
            )
        }
        return null
    }

    onLanguageChangeListener = (value) => {
        this.setState({
            selectedLanguage: value
        })
    }

    getGenreOptions = (value) => {
        const { genres } = this.props
        if (value === "genre" && !isEmpty(genres)) {
            let genre = genres[this.state.selectedLanguage?.code]
            return genre
        }
    }

    getOptions = (value, dataFormat, multilingualKeys) => {
        if (value === "age_rating") {
            return this?.props?.certifications
        }
        if (isFieldMultilingual(value, "", multilingualKeys)) {
            let genres = this.getGenreOptions(value)
            if (genres) {
                return genres
            }
            const val = dataFormat[value]?.items?.enum?.[this.state.selectedLanguage.code]
            return val?.length > 0 ? val : []
        }
        return dataFormat[value]?.items?.enum?.length > 0 ? dataFormat[value]?.items?.enum : []
    }

    renderContentHeader = () => {
        const { emptyLangDataModal, finalPayload } = this.state
        const {
            formData,
            isWebSeries,
            handleAddClick,
            isModalOpen,
            ceDataLoadingState,
            isModalViewOpen,
            isImdbSuccessModal,
            imdbLoadingState,
            imdbData,
            contentMarkers
        } = this.props
        return (
            <>
                <AuthWrapper permissions={[PERMISSIONS.CONTENT_EDIT]}>
                    <ContentEnrichment
                        isModalOpen={isModalOpen}
                        setModalOpen={this.props?.contentEnrichmentBtnHandler}
                        submitModalHandler={this.props?.submitModalHandler}
                        fetchVideoMarkers={this.props?.fetchVideoMarkers}
                        loadingState={ceDataLoadingState}></ContentEnrichment>

                    <ContentEnrichmentView
                        isModalOpen={isModalViewOpen}
                        setModalOpen={this.props?.contentEnrichmentViewBtnHandler}
                        payload={this.state?.payload}
                        contentMarkers={contentMarkers}
                        contentType={this.props.contentType}
                        selectedLanguage={this.state.selectedLanguage}></ContentEnrichmentView>

                    <ImdbModal
                        isModalOpen={isImdbSuccessModal}
                        setModalOpen={this.props?.openImdbModal}
                        loadingState={imdbLoadingState}
                        imdbData={imdbData}></ImdbModal>
                </AuthWrapper>
                {!isWebSeries ? (
                    <>
                        <div className="content-multilingual">
                            {this.isMultiLingualAllowed && (
                                <LanguageCardList handleChange={this.onLanguageChangeListener} />
                            )}
                        </div>
                        {emptyLangDataModal && (
                            <LanguageDataConfirmationModal
                                isModalOpen={emptyLangDataModal}
                                submitHandler={() => this.submitData(finalPayload)}
                                cancelHandler={
                                    this.modalCancelHandler
                                }></LanguageDataConfirmationModal>
                        )}
                        <div className="col-large content-form-title">
                            <div onClick={handleAddClick}>
                                <span className="icon-arrow-left1"></span>
                                {formData && formData.name}
                            </div>
                            {formData && formData.name === "MOVIES" && (
                                <div>
                                    {/*    {this.renderEnrichmentAndMetaDataButton()} */}
                                    {/*  <Button
                    cName={"btn border-btn"}
                    bType={"submit"}
                    bValue={BUTTON_CONSTANTS?.MOVIE_INSIGHTS}
                    bIcon={"icon-left-arrow"}
                    clickHandler={this.handleInsights}
                    // disabled={!this.state.isAllFilled}
                  /> */}
                                    {/*    <p>
                                Powered by{" "}
                                <span className={"vionlab-logo"}>
                                  <img src={vionLabs} alt="vionlab" />
                                </span>
                              </p> */}
                                </div>
                            )}
                        </div>
                    </>
                ) : null}
            </>
        )
    }

    renderContentInputFields = () => {
        const { payload } = this.state
        const { formData, isWebSeries, steppersData, currentStep } = this.props
        let data =
            (formData &&
                formData.htmlElements &&
                formData.htmlElements.content &&
                formData.htmlElements.content.length &&
                formData.htmlElements.content) ||
            formData.htmlElements.content[DEFAULT_SELECTED_LANGUAGE_CODE]
        let dataFormat = formData && formData.dataFormat.properties
        let requiredFields = formData && formData.dataFormat.required
        if (isWebSeries) {
            data = data && data.filter((item) => item.stepperId === steppersData[currentStep].id)
        }

        return (
            data &&
            data.map((item, i) => {
                let dt
                let options = this.getOptions(item.title, dataFormat, formData?.multilingualKeys)
                if (item.title in dataFormat && dataFormat[item.title].type === "boolean") {
                    options = ["YES", "NO"]
                }
                dt = item && item.title
                const value = payload && item && dt && dt in payload ? payload[dt] : null

                return (
                    <div className="margin">
                        {this.renderFields(
                            item,
                            i,
                            false,
                            value,
                            options,
                            dataFormat,
                            requiredFields
                        )}
                        {item.children &&
                            item.children.length !== 0 &&
                            item.children.map((data, j) => {
                                const dt = data && data.title
                                let options =
                                    data.title in dataFormat &&
                                    dataFormat[data.title].items &&
                                    dataFormat[data.title].items.enum.length > 0
                                        ? dataFormat[data.title].items.enum
                                        : null

                                if (
                                    data.title in dataFormat &&
                                    dataFormat[data.title].type === "boolean"
                                ) {
                                    options = ["YES", "NO"]
                                }
                                const v =
                                    payload && data && dt && dt in payload && payload[dt]
                                        ? payload[dt]
                                        : null
                                if (item.title === "genre" && this.isMultiLingualAllowed)
                                    options = this.getOptions(
                                        item.title,
                                        dataFormat,
                                        formData?.multilingualKeys
                                    )
                                return this.renderFields(
                                    data,
                                    j,
                                    true,
                                    v,
                                    options,
                                    dataFormat,
                                    requiredFields
                                )
                            })}
                    </div>
                )
            })
        )
    }

    renderNavigationButtons = () => {
        const { isFilled } = this.state
        const { isWebSeries, steppersData, currentStep, isUploadingImage } = this.props

        return isWebSeries ? (
            <div className="save-and-next">
                {currentStep > 0 ? (
                    <Button
                        cName={"btn cancel-btn"}
                        bType={"button"}
                        bValue={BUTTON_CONSTANTS?.PREVIOUS_STEP}
                        clickHandler={this.previousWebSeries}
                    />
                ) : null}
                <Button
                    cName={"btn primary-btn"}
                    bType={"submit"}
                    bValue={
                        currentStep === steppersData.length - 1
                            ? BUTTON_CONSTANTS?.SUBMIT?.toUpperCase()
                            : BUTTON_CONSTANTS?.SAVE_NEXT_STEP?.toUpperCase()
                    }
                    bIcon={"icon-left-arrow"}
                    clickHandler={this.nextWebSeries}
                    disabled={isUploadingImage}
                />
            </div>
        ) : (
            <div className="save-and-next">
                <AuthWrapper permissions={[PERMISSIONS.CONTENT_EDIT]}>
                    <Button
                        cName={"btn primary-btn"}
                        bType={"submit"}
                        bValue={BUTTON_CONSTANTS?.SUBMIT?.toUpperCase()}
                        bIcon={"icon-left-arrow"}
                        clickHandler={this.handleSave}
                        disabled={isFilled || isUploadingImage}
                    />
                </AuthWrapper>
                <Button
                    cName={"btn cancel-btn"}
                    bType={"button"}
                    bValue={BUTTON_CONSTANTS?.CANCEL?.toUpperCase()}
                    clickHandler={this.props.handleAddClick}
                />
            </div>
        )
    }

    renderMovieInsights = () => {
        return (
            <div className="vd-addMovie-insights">
                <div className="insights-header">
                    <div onClick={this.handleInsights}>
                        <span className="icon-arrow-left1"></span> {TEXT_CONSTANTS?.BACK_TO_MOVIE}
                    </div>
                    {/*   <p>
        Powered by{" "}
        <span className={"vionlab-logo"}>
          <img src={vionLabs} alt="vionlab" />
        </span>
      </p> */}
                </div>
                <iframe
                    src="https://legacy.portal.vionlabs.com/library/63s513"
                    id="insights-iframe"
                    frameborder="0"
                    title="vion labs portal"></iframe>
            </div>
        )
    }

    addSubtitle = () => {
        this.setState({
            showSubtitlePopup: true,
            searchSubtitleList: this.state?.subTitleArray
        })
    }

    handleSearch = (name, value) => {
        const { subTitleArray } = this.state
        let searchSubtitleList = []

        subTitleArray.map((item) => {
            if (value === "" || value.length === 0) {
                searchSubtitleList = subTitleArray
            } else if (item.title.toLowerCase().includes(value.toLowerCase())) {
                searchSubtitleList.push(item)
            }
            return item
        })

        this.setState({
            searchSubtitleList
        })
    }

    getSubtitlePopup = () => {
        let languages = getLanguages()
        let { searchSubtitleList, subTitleArray } = this.state
        let subTitle = {}
        let { selectedSubtitles } = this.state
        let selectedSubtitleLanguages = selectedSubtitles.map((value, index) => {
            return value.language
        })

        if (isEmpty(searchSubtitleList) && isEmpty(subTitleArray)) {
            languages.forEach((value, index) => {
                subTitle = {
                    title: value?.name,
                    language: value?.code,
                    type: "application/x-subrip"
                }
                subTitleArray.push(subTitle)
            })
            this.setState({
                subTitleArray
            })
        } else {
            subTitleArray = !isEmpty(searchSubtitleList) ? searchSubtitleList : subTitleArray
        }

        return (
            <div className="subtitle-popup-container">
                <p className="title">{TEXT_CONSTANTS?.SELECT_SUBTITLE_LANGUAGE}</p>
                <div className="pl-search">
                    {/*       <span className="icon-search"></span>
          <Input
            type="text"
            changeHandler={this.handleSearch}
            name="searchPermission"
            cName="searchInput"
          /> */}
                    <Search
                        name="search"
                        changeHandler={this.handleSearch}
                        placeholder="Search Language"
                        // value={searchValue}
                    />
                </div>
                <div className="language-list">
                    {subTitleArray.map((item, index) => {
                        return (
                            <Checkbox
                                cName="featured-checkBox"
                                labelText={item.title} //language name
                                id={item.language} //lanuage code
                                name={item.title}
                                isChecked={selectedSubtitleLanguages.includes(item.language)}
                                changeHandler={this.subtitleLanguageChange}
                            />
                        )
                    })}
                </div>
                <p className="horizontal-line"></p>
                <SelectedButtons
                    list={selectedSubtitles}
                    changeHandler={this.subtitleLanguageChange}
                />
                <p className="horizontal-line"></p>
            </div>
        )
    }

    validateSubtitles = () => {
        const { selectedSubtitles } = this.state
        let isValidated = true
        if (!isEmpty(selectedSubtitles)) {
            selectedSubtitles.forEach((value, index) => {
                if (isEmpty(value?.uri)) {
                    isValidated = false
                }
            })
        }
        return isValidated
    }

    subtitleLanguageChange = (name, checked, id) => {
        let { selectedSubtitles } = this.state
        if (checked) {
            selectedSubtitles.push({
                title: name,
                language: id, //:"en"
                type: "application/x-subrip",
                uri: ""
            })
        } else {
            selectedSubtitles = selectedSubtitles.filter((value, index) => {
                return value.language !== id
            })
        }
        this.setState({
            selectedSubtitles
        })
    }

    handleSubtitleChange = (name, value, index) => {
        console.log("n", name, value, index)
        let { selectedSubtitles } = this.state
        selectedSubtitles[index].uri = value
        this.setState({
            selectedSubtitles
        })
    }

    submitModalHandler = () => {
        this.setState({
            showSubtitlePopup: !this.state?.showSubtitlePopup,
            finalSelectedSubtitles: this.state?.selectedSubtitles
        })
    }

    handleSubtitleExpand = () => {
        this.setState({
            isSubtitleExpand: !this.state?.isSubtitleExpand
        })
    }

    renderSubtitle = () => {
        const { finalSelectedSubtitles, isSubtitleExpand } = this.state

        return (
            <div className="subtitle-listing-container">
                <div className="group-header">
                    <div className="group-title">
                        <div className="col-large content-form-title">
                            <i
                                className={!isSubtitleExpand ? "icon-plus" : "icon-minus"}
                                onClick={() => {
                                    this.handleSubtitleExpand()
                                }}></i>
                            <div className="content-title">{"SUBTITLE"}</div>
                        </div>
                    </div>
                </div>
                {isSubtitleExpand && (
                    <>
                        {finalSelectedSubtitles.map((value, index) => {
                            return (
                                <div className="margin">
                                    <Input
                                        labelText={`SUBTITLE - ${value.title}`}
                                        cName={"banner-title"}
                                        name={value.title}
                                        value={finalSelectedSubtitles[index]?.uri}
                                        changeHandler={(name, value) =>
                                            this.handleSubtitleChange(name, value, index)
                                        }
                                    />
                                </div>
                            )
                        })}
                        <Button
                            leftIcon="icon-plus"
                            cName="btn primary-btn add-subtitle-btn"
                            bValue={TEXT_CONSTANTS?.ADD_SUBTITLE}
                            clickHandler={() => this.addSubtitle()}
                        />
                    </>
                )}
            </div>
        )
    }

    //------------------------start of method-----------
    renderNestedData = (obj, indent = 0) => {
        return Object.keys(obj).map((key) => (
            <div key={key} className={`children nested-${indent}`}>
                <strong
                    className="row-data keys"
                    style={{
                        ...(!indent && { width: "200px" }),
                        ...(indent && { width: "75px" })
                    }}>
                    {key}:
                </strong>
                <div style={{ width: "100%" }}>
                    {typeof obj[key] === "object" && obj[key] !== null ? (
                        // for check if key is array then index will be +1
                        Array.isArray(obj[key]) ? (
                            obj[key].map((item, index) => (
                                <span key={index + 1}>
                                    {typeof item === "object" && item !== null ? (
                                        this.renderNestedData(item, indent + 2)
                                    ) : (
                                        <span className="row-data">{item?.toString()}</span>
                                    )}
                                </span>
                            ))
                        ) : (
                            //else render normal nesting
                            this.renderNestedData(obj[key], indent + 1)
                        )
                    ) : obj[key]?.toString().includes("s3") ? (
                        <a href={obj[key]} target="_blank">
                            {obj[key]?.toString()}
                        </a>
                    ) : (
                        <span className="row-data">{obj[key]?.toString()}</span>
                    )}
                </div>
            </div>
        ))
    }

    renderTable = (obj) => {
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th style={{ padding: "8px" }}>Field</th>
                        <th style={{ padding: "8px" }}>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(obj).map((key) => (
                        <tr key={key}>
                            <td className="field">{key}</td>
                            <td className="values">
                                {typeof obj[key] === "object" && obj[key] !== null ? (
                                    // for check if key is array then index will be +1
                                    Array.isArray(obj[key]) ? (
                                        obj[key].map((item, index) => (
                                            <span key={index + 1} className={`${key}`}>
                                                {typeof item === "object" && item !== null ? (
                                                    this.renderNestedData(item)
                                                ) : (
                                                    <span className="row-data">
                                                        {item?.toString()}
                                                    </span>
                                                )}
                                            </span>
                                        ))
                                    ) : (
                                        //else render normal nesting
                                        this.renderNestedData(obj[key])
                                    )
                                ) : (
                                    <span className="row-data">{obj[key]?.toString()}</span>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    }
    //-----------------------end of method-----------------

    render() {
        const { showInsights, showSubtitlePopup, canUpdateStatus } = this.state
        const { isWebSeries, isLoading, selectedData, isEdit, isAddNew, handleAddClick, contentType } = this.props

        return (
            // To show the data in table format
            // <div className="vd-addMovie 3">{this.renderTable(selectedData)}</div>
            <div className="vd-addMovie">
                <ContentData selectedData={selectedData} 
                    isEditable={isEdit || isAddNew} 
                    handleAddClick={handleAddClick} 
                    canUpdateStatus={canUpdateStatus}
                    isEdit={isEdit}
                    isAddNew={isAddNew}
                    contentType={contentType}
                    ></ContentData>
            </div>
            // <React.Fragment>
            //     <div className="vd-addMovie">
            //         {showSubtitlePopup && (
            //             <CustomModal
            //                 showModal={showSubtitlePopup}
            //                 submitHandler={this.submitModalHandler}
            //                 cancelHandler={this.submitModalHandler}
            //                 closeOnSubmit={false}
            //                 submitButtonLabel={BUTTON_CONSTANTS?.ADD}
            //                 showCancelButton={true}
            //                 cancelOnOutsideClick={false}
            //                 showCloseIcon={false}
            //                 submitBtnClass={"confirmation-submit-btn"}
            //                 cancelBtnClass={"confirmation-cancel-btn"}>
            //                 {this.getSubtitlePopup()}
            //             </CustomModal>
            //         )}
            //         {!showInsights ? (
            //             <>
            //                 {isLoading && (
            //                     <div className="loader">
            //                         <img src={Loader} alt={"loader"} />
            //                     </div>
            //                 )}
            //                 <div className={!isWebSeries ? "container" : null}>
            //                     <div className="main-content">
            //                         <div className="form-content">
            //                             <div className="content-entity-wrapper">
            //                                 {this.renderContentHeader()}
            //                                 {this.renderContentInputFields()}
            //                                 {this.renderSubtitle()}
            //                             </div>
            //                             {this.renderNavigationButtons()}
            //                         </div>
            //                     </div>
            //                 </div>
            //             </>
            //         ) : (
            //             this.renderMovieInsights()
            //         )}
            //     </div>
            // </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isUploadingImage: state?.loader?.isLoading //Disable the submit button whenever user is uploading images
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                fetchContent,
                uploadImage,
                saveContentNav,
                getCredits,
                getCategory
            },
            dispatch
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddContentForm)
