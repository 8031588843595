import React, { useState } from "react"
import PropTypes from "prop-types"
import CustomModal from "../../../common/ContentEnrichmentModal"
import "./style.scss"
import BingeMarker from "./BingeMarker"
import AdBreak from "./AdBreak"
import FingerPrinting from "./FingerPrinting"
import { imgPathPrefix } from "../../api/constants"
import { getContentData, isMultiLingual } from "../../../../utils/common"
import { TEXT_CONSTANTS } from "../../../../utils/constants"

function ContentEnrichmentView(props) {
    const {
        isModalOpen,
        setModalOpen,
        payload,
        contentMarkers,
        webseriesData,
        seasonIndex,
        episodeIndex,
        contentType
    } = props
    const [tabIndex, setTabIndex] = useState(1)
    const [tabHeading, setTabHeading] = useState("Binge Marker")

    const tabClick = (index, heading) => {
        setTabIndex(index)
        setTabHeading(heading)
    }

    const onClickCancelBtn = () => {
        setModalOpen(false)
    }

    const getVideoSrc = () => {
        if (payload) {
            return contentType === "MOVIES" ? payload?.trailer_url : payload?.playUrl
        }
        return ""
    }

    const getDefaultValues = (inputValue) => {
        let val
        if (Array.isArray(inputValue)) {
            val = Object.keys(inputValue) ? inputValue[0] : inputValue
        } else {
            val = inputValue?.length > 40 ? inputValue?.substring(0, 40) + "..." : inputValue
        }
        return val || ""
    }

    const displayLeftView = () => {
        const isMovies = contentType === "MOVIES" ? true : false

        let contentInfo = {}
        if (!isMovies) {
            contentInfo = {
                posterImage: getContentData(
                    webseriesData?.addSeason?.season?.[seasonIndex]?.seasonPosterImage
                ),
                movieTitle:
                    getContentData(
                        webseriesData?.addSeason?.season?.[seasonIndex]?.addEpisode?.episode?.[
                            episodeIndex
                        ]?.episodeTitle
                    )?.substring(0, 40) || "",
                genre:
                    getDefaultValues(
                        getContentData(webseriesData?.addBrand?.brandDescription?.genre)
                    ) || "-",
                movieYear: webseriesData?.addSeason?.season?.[seasonIndex]?.seasonYear,
                actor: getDefaultValues(getContentData(webseriesData?.addBrand?.credit?.actor)),
                director: getDefaultValues(
                    getContentData(webseriesData?.addBrand?.credit?.director)
                )
            }
        } else {
            const actor = getContentData(payload?.actor)
            const director = getContentData(payload?.director)
            const genre = isMultiLingual()
                ? getContentData(payload?.genre)
                : Array.isArray(payload?.genre) && Object.keys(payload?.genre)
                ? payload?.genre[0]
                : payload?.genre
                ? payload?.genre
                : "-"
            contentInfo = {
                posterImage: getContentData(payload?.poster_image),
                movieTitle: getContentData(payload?.movie_title)?.substring(0, 40) || "",
                genre: genre,
                movieYear: payload?.movie_year,
                actor: actor ? actor?.toString()?.substring(0, 40) + "..." : "",
                director: director ? director?.toString()?.substring(0, 40) + "..." : ""
            }
        }

        return (
            <div className="left-container">
                <img
                    className="background-image"
                    src={`${imgPathPrefix}${contentInfo?.posterImage}`}
                    alt="movie-img"
                ></img>
                <div className="left-content-container">
                    <img
                        className="portait-image"
                        src={`${imgPathPrefix}${contentInfo?.posterImage}`}
                        alt="movie-img"
                    ></img>
                    <div className="content-description">
                        <p className="content-heading">
                            {contentInfo?.movieTitle}
                            <span className="content-sub-text">({TEXT_CONSTANTS?.U_A})</span>
                        </p>
                        <div className="content-details">
                            <p className="content-details-items">
                                <span className="content-details-items-title">
                                    {TEXT_CONSTANTS?.GENRE}
                                </span>{" "}
                                <span className="content-details-items-details">
                                    {" : "} &nbsp;
                                    {contentInfo?.genre}
                                </span>
                            </p>
                            <p className="content-details-items">
                                <span className="content-details-items-title">
                                    {TEXT_CONSTANTS?.YEAR}
                                </span>{" "}
                                <span className="content-details-items-details">
                                    {" : "}&nbsp;
                                    {contentInfo?.movieYear}
                                </span>
                            </p>
                            <p className="content-details-items">
                                <span className="content-details-items-title">
                                    {TEXT_CONSTANTS?.CAST}
                                </span>{" "}
                                <span className="content-details-items-details">
                                    {" : "}&nbsp;
                                    {contentInfo?.actor}
                                </span>
                            </p>
                            <p className="content-details-items">
                                <span className="content-details-items-title">
                                    {TEXT_CONSTANTS?.DIRECTOR}
                                </span>{" "}
                                <span
                                    title={contentInfo?.director}
                                    className="content-details-items-details"
                                >
                                    {" : "}&nbsp;
                                    {contentInfo?.director}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="content-enrichment-view-section">
            <CustomModal
                disableFooter={true}
                showModal={isModalOpen}
                modalStyle={"content-view-modal"}
                cancelHandler={onClickCancelBtn}
            >
                <div className="content-container">
                    {displayLeftView()}
                    <div className="right-container">
                        <h2 className="heading">{tabHeading}</h2>
                        <div className="tabs">
                            <p
                                className={tabIndex === 1 ? `active` : ""}
                                onClick={() => tabClick(1, "Binge Marker")}
                            >
                                {TEXT_CONSTANTS?.BINGE_MARKER}
                            </p>
                            <p
                                className={tabIndex === 2 ? `active` : ""}
                                onClick={() => tabClick(2, "Ad Break")}
                            >
                                {TEXT_CONSTANTS?.AD_BREAK}
                            </p>
                            <p
                                className={tabIndex === 3 ? `active` : ""}
                                onClick={() => tabClick(3, "Finger Printing")}
                            >
                                {TEXT_CONSTANTS?.FIGER_PRINTING}
                            </p>
                        </div>
                        <p className="horizontal-line"></p>
                        <div className="tab-content">
                            {tabIndex === 1 && (
                                <BingeMarker src={getVideoSrc()} contentMarkers={contentMarkers} />
                            )}
                            {tabIndex === 2 && (
                                <AdBreak src={getVideoSrc()} contentMarkers={contentMarkers} />
                            )}
                            {tabIndex === 3 && <FingerPrinting contentMarkers={contentMarkers} />}
                        </div>
                    </div>
                </div>
            </CustomModal>
        </div>
    )
}

ContentEnrichmentView.propTypes = {
    isModalOpen: PropTypes.bool,
    setModalOpen: PropTypes.func
}

export default ContentEnrichmentView
