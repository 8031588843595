import React, { useEffect } from "react"
import Input from "../../common/Input"
import Button from "../../common/Button"
import { BUTTON_CONSTANTS } from "../../../utils/constants"
import { useState } from "react"
import MultiSelect from "../../Category/AddNewRail/multiselect"
import customiseIcon from "../../../assets/images/customise-option-DM.svg"
import Checkbox from "../../common/Checkbox"
import { isEmpty } from "lodash"
import SavedFilterOptions from "../SavedFilterOptions"
import RenderSaveFilter from "../RenderSaveFilter"
import DateCalendar from "../DateCalendar"
import { FORM_DATA_CONSTANTS } from "../API/constants"
import { toast } from "react-toastify"

import "./ContentPlanFilterOptions.scss"

const defaultCustomiseFilterOptionLength = 3
export default function ContentPlanFilterOptions({
    filterSubmitHandler,
    allFiltersData,
    getSelectedSavedFilterData,
    saveCurrentFilterDataAPI,
    contentTabType,
    deleteSavedFilter,
    isSaveFilterDisabled
}) {
    const [selectedFilterOptionsData, setSelectedFilterOptionsData] = useState({})
    const [showCustomFilterList, setShowCustomFilterList] = useState(false)

    const selectedFilterOptionsDataList = allFiltersData?.selectedFilterOptionsDataList
    const customisedFilterDataList = allFiltersData?.customisedFilterDataList

    useEffect(() => {
        setSelectedFilterOptionsData(setDefaultSelectedFilters())
        // eslint-disable-next-line
    }, [selectedFilterOptionsDataList, customisedFilterDataList, contentTabType])

    const setDefaultSelectedFilters = () => {
        let defaultValue = {}
        if (allFiltersData?.selectedFilterOptionsDataList) {
            const filterData = JSON.parse(allFiltersData.selectedFilterOptionsDataList?.listRequest)
            for (let key in filterData) {
                if (key === "filters") {
                    const allFiltersList = allFiltersData?.savedFilterDataList?.filters?.filters
                    const filtersValue = filterData[key]
                    for (let itemKey in filtersValue) {
                        if (itemKey !== "active") {
                            if (
                                allFiltersList
                                    ?.find((item) => item?.title === itemKey)
                                    ?.filterType?.toLowerCase() === "multiselect"
                            ) {
                                defaultValue[itemKey] = convertToMultiSelectOptions(
                                    filtersValue[itemKey]
                                )
                            } else {
                                defaultValue[itemKey] = filtersValue[itemKey]
                            }
                        }
                    }
                } else if (key === "rangeFilter") {
                    if (filterData[key]?.lastUpdated?.greaterThan) {
                        defaultValue["date"] = {
                            startDate: filterData[key]?.lastUpdated?.greaterThan,
                            endDate: filterData[key]?.lastUpdated?.lessThan
                        }
                    }
                }
            }
        } else {
            const filter = allFiltersData?.customisedFilterDataList
            const size =
                filter?.length > defaultCustomiseFilterOptionLength
                    ? defaultCustomiseFilterOptionLength
                    : filter?.length
            for (let i = 0; i < size; i++) {
                const item = filter[i]
                defaultValue[item?.title] = ""
            }
        }
        return defaultValue
    }

    const checkObjectContainsValue = (dataObj) => {
        let isObjContainsValue = false
        for (let key in dataObj) {
            if (!isEmpty(dataObj[key])) {
                isObjContainsValue = true
                break
            }
        }
        return isObjContainsValue
    }

    const filterSubmitBtnHandler = () => {
        if (
            Object.keys(selectedFilterOptionsData)?.length > 0 &&
            checkObjectContainsValue(selectedFilterOptionsData)
        ) {
            filterSubmitHandler(selectedFilterOptionsData)
            return
        }
        toast.error(FORM_DATA_CONSTANTS?.selectFilterOption)
    }

    const customiseFilterOptionsHandler = () => {
        setShowCustomFilterList(!showCustomFilterList)
    }

    const customisedCheckboxChangeHandler = (name, value, id) => {
        if (value) {
            setSelectedFilterOptionsData((prevState) => {
                return { ...prevState, [id]: "" }
            })
        } else {
            let remainingSelectedFilterOptions = { ...selectedFilterOptionsData }
            delete remainingSelectedFilterOptions?.[id]
            setSelectedFilterOptionsData(remainingSelectedFilterOptions)
        }
    }

    const changeHandler = (name, value) => {
        setSelectedFilterOptionsData((prevState) => {
            return {
                ...prevState,
                [name]: value
            }
        })
    }

    const renderFilterOptions = (item) => {
        // Only show selected filter options data
        if (!selectedFilterOptionsData?.hasOwnProperty(item?.title)) {
            return
        }
        switch (item?.filterType?.toLowerCase()) {
            case "input":
                return (
                    <div className={`input-element`} key={item?.title}>
                        <Input
                            type={item && item?.htmlInputType}
                            labelText={item?.title}
                            name={item?.title}
                            required={item?.required}
                            value={selectedFilterOptionsData?.[item?.title]}
                            changeHandler={(name, value) => changeHandler(name, value)}
                        />
                    </div>
                )
            case "multiselect":
                const currentValue = selectedFilterOptionsData?.[item?.title]
                return (
                    <div className={`vr-movies-select`} key={item?.title}>
                        <MultiSelect
                            key={"placement"}
                            options={item?.values && convertToMultiSelectOptions(item?.values)}
                            placeholder={"Select"}
                            label={item?.title}
                            required={item?.required}
                            name={item?.title}
                            id={item?.title}
                            isOptionCheckbox={true}
                            selectedValues={currentValue ? currentValue : []}
                            changeHandler={(name, value) => changeHandler(name, value)}
                        />
                    </div>
                )
            case "date":
                return (
                    <div className={`date-range-calendar`} key={item?.title}>
                        <p className="multi-select-container label">{item?.title}</p>
                        <DateCalendar
                            startDate={selectedFilterOptionsData?.[item?.title]?.startDate}
                            endDate={selectedFilterOptionsData?.[item?.title]?.endDate}
                            dateSubmitHandler={changeHandler}
                            name={item?.title}
                        ></DateCalendar>
                    </div>
                )

            default:
                return null
        }
    }

    const renderCustomisableFilterOptionsList = () => {
        return (
            <div className="custom-filter-option-container">
                {allFiltersData?.customisedFilterDataList?.map((item) => {
                    return (
                        <Checkbox
                            cName="check-box"
                            id={item?.title}
                            name={item?.title}
                            labelText={item?.title}
                            isChecked={selectedFilterOptionsData?.hasOwnProperty(item?.title)}
                            changeHandler={customisedCheckboxChangeHandler}
                        />
                    )
                })}
            </div>
        )
    }

    return (
        <div className="vr-movies-filter-container">
            {/* show saved filter options */}
            <SavedFilterOptions
                getSelectedSavedFilterData={getSelectedSavedFilterData}
                saveCurrentFilterDataAPI={saveCurrentFilterDataAPI}
                allFiltersData={allFiltersData}
                contentTabType={contentTabType}
                deleteSavedFilter={deleteSavedFilter}
            ></SavedFilterOptions>

            {/* Customize filter Options */}
            <div className="customise-filter-wrapper">
                <Button
                    cName={"customise-filter-btn"}
                    bType={"button"}
                    bValue={
                        <>
                            <div className="customise-filter-icon-container">
                                <img
                                    src={customiseIcon}
                                    alt="filter-options"
                                    className="customise-filter-icon"
                                ></img>
                            </div>
                            <div className="customise-filter-text-container">
                                <p className="customise-filter-text">
                                    {BUTTON_CONSTANTS?.CUSTOMISE}
                                </p>
                                <p className="customise-filter-text">
                                    {BUTTON_CONSTANTS?.FILTER_OPTIONS}
                                </p>
                            </div>
                        </>
                    }
                    clickHandler={customiseFilterOptionsHandler}
                ></Button>
                {/* Custom filter option list */}
                {showCustomFilterList && renderCustomisableFilterOptionsList()}
            </div>

            {/* Render filter Options */}
            {allFiltersData?.customisedFilterDataList?.map((item) => {
                return renderFilterOptions(item)
            })}
            <Button
                cName={"apply-btn"}
                bType={"submit"}
                bValue={BUTTON_CONSTANTS?.APPLY?.toUpperCase()}
                clickHandler={filterSubmitBtnHandler}
            ></Button>

            {/* Save Filter Modal */}
            {!isSaveFilterDisabled && (
                <RenderSaveFilter
                    saveCurrentFilterDataAPI={saveCurrentFilterDataAPI}
                    isSaveFilterDisabled={isSaveFilterDisabled}
                    savedFilterList={allFiltersData?.savedFilterDataList?.savedFilters}
                ></RenderSaveFilter>
            )}
        </div>
    )
}

// To convert array to multiselect
// Input: ['All']
// Output: [{id:'All', name:'All', value: 'All'}]
export const convertToMultiSelectOptions = (item = []) => {
    return item.map((ele) => {
        return {
            id: ele,
            name: ele,
            value: ele
        }
    })
}
