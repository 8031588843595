import React, { Component } from "react"
import { isEmpty } from "lodash"

import { BUTTON_CONSTANTS, ERROR_MESSAGES, TEXT_CONSTANTS } from "../../../utils/constants"
import { Dropzone } from "../../common/Dropzone"
import FileUploader from "../FileUploader"
import { ProgressBar } from "../../common/StripedProgressBar/"
import deleteIcon from "../../../assets/images/delete.svg"
import fileupload from "../../../assets/images/fileupload.svg"
import greenTick from "../../../assets/images/greenTick.svg"
import { abort } from "../api/action"
import Button from "../../common/Button"
import { Prompt } from "react-router-dom"
import { VOD_ERROR_MESSAGES } from "./Transcoding/constants"
import CustomModal from "../../common/Modal"
import { getUniqueId } from "../../../utils/common"

class InputSource extends Component {
    constructor(props) {
        super(props)
        this.state = {
            files: [],
            fileNumber: 0,
            maxUploadCount: 10,
            isUserOnline: navigator?.onLine,
            showConfirmPopup: false,
            deletedFileIndex: null
        }
        this.fileProgressLength = 0
        this.uploaderList = []
        this.aborted = this.aborted.bind(this)
        this.fileDetails = []
    }

    componentDidMount() {
        window.addEventListener("online", this.handleOnlineStatusChange)
        window.addEventListener("offline", this.handleOfflineStatusChange)
    }

    handleOnlineStatusChange = () => {
        this.setState({ isUserOnline: true })
        const failedFiles = this.fileDetails.filter((fileItem, index) => {
            if (fileItem.failed && !fileItem.isCancelled) {
                return true
            }
            return false
        })
        const { files } = this.state
        failedFiles.map((item) => {
            const itemIndex = files.findIndex((file) => file.id === item.id)
            this.fileDetails[itemIndex].failed = false
            // To resume the uploading when user comes back online
            return this.uploaderList[itemIndex].onResume()

            // To restart the uploading when user comes back online
            // return this.uploadFile(files[itemIndex], itemIndex);
        })
    }

    handleOfflineStatusChange = () => {
        this.setState({ isUserOnline: false })
    }

    componentWillUnmount() {
        window.removeEventListener("online", this.handleOnlineStatusChange)
        window.removeEventListener("offline", this.handleOfflineStatusChange)
    }

    //    files: [{name:'File1'}],
    setFiles = (newFiles) => {
        this.fileProgressLength = newFiles.length
        const { files } = this.state
        newFiles.forEach((value) => {
            value.id = getUniqueId()
        })
        this.setState(
            {
                files: [...this.state?.files, ...newFiles]
                //  fileNumber: !this.state?.files.length ? 0 : fileNumber + 1,
            },
            () => {
                let index = files.length
                newFiles.forEach((value) => {
                    this.uploadFile(value, index)
                    index = index + 1
                })
            }
        )
    }

    setFileDetails = (file, index) => {
        let fileDetails = {
            size: file?.size,
            fileName: file?.name,
            duration: "",
            startTime: new Date(),
            progress: 0,
            id: file?.id
        }
        // this.fileDetails.push(fileDetails);
        this.fileDetails[index] = fileDetails
    }

    uploadFile = (file, index) => {
        const videoUploaderOptions = {
            file
        }
        if (videoUploaderOptions.file) {
            var percentage = undefined
        }
        this.setFileDetails(file, index)
        let uploader = null
        uploader = new FileUploader(videoUploaderOptions)
        // this.uploaderList.push(uploader);
        this.uploaderList[index] = uploader
        this.uploaderList[index]
            .onProgress(({ percentage: newPercentage }) => {
                // to avoid the same percentage to be logged twice
                if (newPercentage !== percentage) {
                    percentage = newPercentage
                    console.log(`${percentage}%`)
                }

                this.fileDetails[index].progress = percentage
                this.setState({
                    percent: ""
                })
            })
            .onComplete(({ status }) => {
                console.log(status)
                this.fileDetails[index].endTime = new Date()
                this.fileDetails[index].enableDeleteBtn = true
                this.fileDetails[index].completed = true
                this.fileDetails[index].statusUpdated = true
                this.setState({
                    progress: true
                })
                console.log("file complete status", this.fileDetails[index])
            })
            .onInitialize(({ fileId, filePath }) => {
                this.fileDetails[index].fileId = fileId
                this.fileDetails[index].filePath = filePath
            })
            .onError((error, type) => {
                switch (type) {
                    case "preSigned":
                    case "initialized":
                    case "complete":
                    case "cancelled":
                        this.fileDetails[index].enableDeleteBtn = false
                        this.fileDetails[index].failed = true
                        this.fileDetails[index].progress = 0
                        this.fileDetails[index].statusUpdated = true
                        this.fileDetails[index].errorMessage = VOD_ERROR_MESSAGES[type]
                        if (type === "cancelled") {
                            this.fileDetails[index].isCancelled = true
                        }
                        break
                    default:
                        this.fileDetails[index].errorMessage = VOD_ERROR_MESSAGES["preSigned"]
                        break
                }
                this.setState({
                    progress: true
                })
                this.fileDetails[index].enableDeleteBtn = false
                this.fileDetails[index].failed = true
                // if (this.fileDetails.length !== this.state?.files.length) {
                // } else {
                //   this.setState({
                //     allFilesUpdated: true,
                //   });
                // }
            })
        this.uploaderList[index].start()
    }

    onCancel = (index) => {
        if (this.uploaderList[index]) {
            this.uploaderList[index].abort()
            this.fileDetails[index].cancelled = true
        }
    }

    deleteFile = (index) => {
        this.setState({
            showConfirmPopup: true,
            deletedFileIndex: index
        })
    }

    aborted = (response, index) => {
        this.fileDetails[index].deleted = true
        this.fileDetails[index].completed = false
        /*  this.fileDetails.splice(index, 1);
    this.state.files.splice(index, 1);
    this.uploaderList.splice(index, 1); */
        this.setState({ files: this.state.files })
    }

    getFileListContainer = () => {
        const { files } = this.state
        return files.map((value, index) => {
            return (
                <div
                    className={`file ${this.fileDetails[index]?.failed ? "error-file" : ""}${
                        this.fileDetails[index]?.deleted ? " deleted" : ""
                    }`}
                >
                    <p className="file-icon">
                        <img src={fileupload} alt="file-upload"></img>
                    </p>
                    <div className="file-info-container">
                        <p className="file-name">
                            <span className="text-name">
                                {value.name}{" "}
                                <span>
                                    {` ( ${(value?.size / (1024 * 1024 * 1024)).toFixed(3)} GB )`}
                                </span>
                            </span>
                            <span className="percent">
                                {`${
                                    this.fileDetails?.[index]?.progress
                                        ? this.fileDetails?.[index]?.progress + "%"
                                        : ""
                                }`}
                            </span>
                        </p>
                        <div className="progress-bar-container">
                            {this.fileDetails[index]?.failed ? (
                                <p className="error-text">{this.fileDetails[index].errorMessage}</p>
                            ) : (
                                <ProgressBar
                                    progress={this.fileDetails?.[index]?.progress}
                                    id={index}
                                    failed={this.fileDetails?.[index]?.failed}
                                    completed={this.fileDetails?.[index]?.completed}
                                />
                            )}
                        </div>
                    </div>
                    {this.fileDetails?.[index]?.enableDeleteBtn && (
                        <img className={"green-tick"} src={greenTick} alt="tick-icon"></img>
                    )}
                    {this.fileDetails?.[index]?.enableDeleteBtn && (
                        <img
                            className="delete-icon"
                            src={deleteIcon}
                            onClick={() => this.deleteFile(index)}
                            alt="delete-icon"
                        ></img>
                    )}
                    {this.fileDetails?.[index]?.progress < 100 &&
                        !this?.fileDetails?.[index]?.cancelled && (
                            <p className="cancel-button" onClick={() => this.onCancel(index)}>
                                Cancel
                            </p>
                        )}
                </div>
            )
        })
    }

    goToUploadedContent = (isParamsRequired = true) => {
        const { history } = this.props
        let params = {}
        if (isParamsRequired) {
            params = {
                params: 1
            }
        }
        history.push("stream/videoPipeline", { ...params })
    }

    isFileProgress = () => {
        if (this.fileDetails?.length) {
            let totalFileProgress = this.fileDetails.filter((value, index) => {
                return value.statusUpdated === false || value.statusUpdated == null
            })
            return totalFileProgress.length ? false : true
        }
        return false
    }
    modalSubmitHandler = () => {
        this.setState({ isUserOnline: true })
    }

    modalCancelHandler = () => {
        this.setState({ isUserOnline: true })
    }

    deleteContent = () => {
        const index = this.state.deletedFileIndex
        if (index != null) {
            let request = {
                uploadId: this.fileDetails[index]?.fileId,
                filePath: this.fileDetails[index]?.filePath,
                fileName: this.fileDetails[index]?.fileName
            }
            this.onCancel(index)
            abort(request, this.aborted, index)
            this.setState({
                showConfirmPopup: false
            })
        }
    }

    render() {
        let { files, maxUploadCount, showConfirmPopup } = this.state
        let allFilesUpdated = this.isFileProgress()
        return (
            <div className="input-source-container">
                {showConfirmPopup && (
                    <CustomModal
                        showModal={showConfirmPopup}
                        submitHandler={this.deleteContent}
                        cancelHandler={() => {
                            this.setState({
                                showConfirmPopup: false
                            })
                        }}
                        closeOnSubmit={true}
                        submitButtonLabel={BUTTON_CONSTANTS?.YES}
                        showCancelButton={true}
                        cancelOnOutsideClick={false}
                        showCloseIcon={false}
                        submitBtnClass={"confirmation-submit-btn"}
                        cancelBtnClass={"confirmation-cancel-btn"}
                    >
                        <div className="confirmation-modal-container">
                            <h3>{`${TEXT_CONSTANTS?.DELETE_EPG_MSG} `}</h3>
                        </div>
                    </CustomModal>
                )}
                <div className="input-source-heading-container">
                    <p className="input-heading">{TEXT_CONSTANTS.SELECT_INPUT_SOURCE}</p>
                    <Button
                        cName="btn border-btn refresh-btn"
                        bValue={BUTTON_CONSTANTS?.BACK}
                        clickHandler={() => {
                            this.goToUploadedContent(false)
                        }}
                    />
                </div>
                <div className="drop-container">
                    {" "}
                    <div className="dropzone-container">
                        <Dropzone
                            setFiles={this.setFiles}
                            acceptFiles={{
                                video: [".mp4", ".MP4", ".Mp4"]
                            }}
                            disabled={!allFilesUpdated && this.fileProgressLength >= maxUploadCount}
                            maxSize={maxUploadCount}
                        />
                    </div>
                    {!isEmpty(files) && (
                        <div className="file-list-container">
                            <p className="horizontal-line"></p>
                            {!allFilesUpdated && (
                                <p className="uploading-text">{TEXT_CONSTANTS.FILE_UPLOADING}</p>
                            )}
                            <div className="list-sub-container">{this.getFileListContainer()}</div>
                        </div>
                    )}
                </div>
                {allFilesUpdated && (
                    <div className="button-container">
                        <Button
                            cName="btn border-btn refresh-btn"
                            bValue={BUTTON_CONSTANTS?.DONE}
                            clickHandler={this.goToUploadedContent}
                        />
                    </div>
                )}
                <Prompt
                    when={files?.length > 0 && !allFilesUpdated}
                    message={ERROR_MESSAGES?.UPLOADING_NAVIGATION_ERROR}
                />
                <CustomModal
                    showModal={files?.length > 0 && !allFilesUpdated && !this.state.isUserOnline}
                    submitHandler={this.modalSubmitHandler}
                    cancelHandler={this.modalCancelHandler}
                    closeOnSubmit={false}
                    submitButtonLabel={BUTTON_CONSTANTS.OK}
                    showCancelButton={false}
                    cancelOnOutsideClick={false}
                    showCloseIcon={false}
                    showFooter={false}
                >
                    <div>
                        <p>{ERROR_MESSAGES?.OFFLINE_ERROR_MESSAGE}</p>
                    </div>
                </CustomModal>
            </div>
        )
    }
}

export default InputSource
