export const configJSON = [
    {
        label: "DRM",
        name: "isDrmEnabled"
    },
    {
        label: "Custom Profile",
        name: "isCustomProfile"
    },
    {
        label: "Protocol",
        children: "yes",
        name: "protocol"
    },
    {
        label: "Renditions",
        name: "renditions"
    },
    {
        label: "Input Source",
        name: "inputSource"
    },
    {
        label: "Output",
        name: "output"
    }
]

export const JobJSON = [
    {
        label: "Job ID",
        name: "jobId"
    },
    {
        label: "Job Name",
        name: "isCustomProfile"
    },
    {
        label: "Event",
        children: "yes",
        name: "eventName"
    },
    {
        label: "Status",
        name: "status"
    },
    {
        label: "Error",
        name: "error"
    },
    {
        label: "Input",
        name: "input"
    },
    {
        label: "Output",
        name: "output"
    },
    {
        label: "Start",
        name: "startedAt"
    },
    {
        label: "End",
        name: "endAt"
    }
]
