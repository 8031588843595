import React from "react"
import { FORM_BUILDER_HEADING } from "./constant"
import Button from "../../common/Button"
import moment from "moment"
import { BUTTON_CONSTANTS } from "../../../utils/constants"

const BuilderTable = (props) => {
    const tableHeading = FORM_BUILDER_HEADING.map((item, index) => {
        return <th key={index}>{item.key}</th>
    })
    const deleteForm = (data) => {
        props.deleteForm({ id: data.id })
        props.fetchContentList()
    }
    const [idToDelete, setIdToDelete] = React.useState(null)
    const isDelete = false

    const tableRows = (data, index) => {
        return (
            <tr id="" key="">
                <td>{index + 1}</td>
                <td className="editable-content">{data.formTitle}</td>
                <td className="email-field">{data.name}</td>
                <td className="email-field">
                    {data.dateCreated
                        ? moment(data.dateCreated).format("DD/MM/YYYY")
                        : data.lastUpdated
                        ? moment(data.lastUpdated).format("DD/MM/YYYY")
                        : "-"}
                </td>
                <td className="btns-cont btn-popupContainer">
                    <div
                        className="toolsBtn icon-edit"
                        onClick={() => props.handleEdit(data, index)}
                    >
                        <span className="tooltiptext">Edit</span>
                    </div>

                    {idToDelete === data.id && (
                        <div className={`deletePopup ${isDelete ? "showPopUp" : "hidePopUp"}`}>
                            <p>Delete this User?</p>
                            <Button
                                cName="btn popup-del-btn"
                                bValue={BUTTON_CONSTANTS?.YES_DELETE}
                                bType="button"
                                clickHandler={() => deleteForm(data)}
                            />
                            <Button
                                cName="btn cancel-btn"
                                bValue={BUTTON_CONSTANTS?.CANCEL}
                                bType="button"
                                clickHandler={() => setIdToDelete(null)}
                            />
                        </div>
                    )}
                </td>
            </tr>
        )
    }

    return (
        <div className="vr-content-holder">
            <div className="vr-cont-head">
                <div className="vr-cont-title">
                    <h2 className="inner-count-title">
                        {props.contentEntityAll && props.contentEntityAll.length > 9
                            ? `${props.contentEntityAll.length - 1} Forms`
                            : `0${props.contentEntityAll.length - 1} Forms`}
                    </h2>
                </div>
            </div>
            <table className="table user-role-table">
                <thead>
                    <tr>{tableHeading}</tr>
                </thead>
                <tbody>
                    {props.contentEntityAll &&
                        props.contentEntityAll.length &&
                        props.contentEntityAll.slice(0, -1).map((data, index) => {
                            return tableRows(data, index)
                        })}
                </tbody>
            </table>
        </div>
    )
}
export default BuilderTable
