import AdminServiceInstance from "./service"
import { setCloudFrontUrl } from "../../../config/apiPath"

export function updateHeaderLogo(logo) {
    return {
        type: "HEADER_LOGO_CHANGE",
        logo
    }
}

export function showNavBar(isShowNavbar) {
    return {
        type: "SHOW_NAVBAR",
        showNavbar: isShowNavbar
    }
}
export const findTenantByDomain = (params) => {
    return (dispatch) => {
        return AdminServiceInstance.findTenantByDomain(params)
            .then((response) => {
                if (response && response?.data?.data?.auxData?.cloudFront)
                    setCloudFrontUrl(response?.data?.data?.auxData?.cloudFront)
                dispatch({
                    type: "FETCH_TENANT_DOMAIN",
                    tenantInfo: response.data
                })
                return response.data
            })
            .catch((error) => {
                let response = {
                    data: {
                        tenantId: "1",
                        tenantIdentifier: "20",
                        isSuperAdmin: true
                    },
                    message: "added",
                    status: true
                }

                dispatch({
                    type: "FETCH_TENANT_DOMAIN",
                    apiResponse: response.data
                })
                console.log("Got error in getting form json ", error)
                return response.data
            })
    }
}
