import { ACTION } from "./constants"

let initialState = {}

export default function addCustomPageReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION.FETCH_ALL_TEXT_PAGE:
            return { ...state, customText: action.apiResponse.data }
        case ACTION.FILTER_TEXT_PAGE:
            return { ...state, customText: action.apiResponse.data }

        case ACTION.FETCH_FAQ_PAGE:
            return { ...state, faqList: action.apiResponse }

        default:
            return state
    }
}
