import React, { useState } from "react"
import PropTypes from "prop-types"

import "react-date-range/dist/styles.css" // main css file
import "react-date-range/dist/theme/default.css" // theme css file
import { DateRangePicker } from "react-date-range"
// import { addDays, subDays } from "date-fns";
import Button from "../Button"
import { BUTTON_CONSTANTS } from "../../../utils/constants"
import moment from "moment"

import "./style.scss"
import { dateTime } from "../../../utils/common"

const DateRangeCalendar = ({
    onChange,
    showSelectionButtons = false,
    submitBtnHandler,
    cancelButtonHandler,
    startDate,
    endDate
}) => {
    const [state, setState] = useState([
        {
            startDate:
                (startDate && new Date(moment(startDate, "DD/MM/YYYY").format("MMMM D, YYYY"))) ||
                new Date(),
            endDate:
                (endDate && new Date(moment(endDate, "DD/MM/YYYY").format("MMMM D, YYYY"))) ||
                new Date(),
            key: "selection"
        }
    ])

    const handleOnChange = (ranges) => {
        const { selection } = ranges
        onChange && onChange(selection)
        setState([selection])
    }

    return (
        <div className="date-range-picker-container">
            <DateRangePicker
                onChange={handleOnChange}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={state}
                direction="horizontal"
                showPreview={true}
            />
            {showSelectionButtons && (
                <div className="date-preview-conatiner">
                    {showCalendarDate(state[0], true)}
                    <Button
                        cName={"edit-save-filter  btn cancel-btn"}
                        bType={"button"}
                        bValue={BUTTON_CONSTANTS?.CANCEL}
                        clickHandler={cancelButtonHandler}
                    ></Button>
                    <Button
                        cName={"edit-save-filter btn primary-btn"}
                        bType={"submit"}
                        bValue={BUTTON_CONSTANTS?.APPLY}
                        clickHandler={() => {
                            console.log("cleicled")
                            submitBtnHandler(state[0])
                        }}
                    ></Button>
                </div>
            )}
        </div>
    )
}

DateRangeCalendar.propTypes = {
    onChange: PropTypes.func
}

export default DateRangeCalendar

export const showCalendarDate = (selectedDateRange, calendarDate) => {
    if (selectedDateRange?.startDate || selectedDateRange?.endDate) {
        if (calendarDate) {
            return (
                <p className="calendar-title selected-date-name">{`${
                    dateTime(new Date(selectedDateRange?.startDate))?.date
                } - ${dateTime(new Date(selectedDateRange?.endDate))?.date}`}</p>
            )
        }
        return (
            <p className="calendar-title selected-date-name">{`${selectedDateRange?.startDate} - ${selectedDateRange?.endDate}`}</p>
        )
    }
    return <p className="calendar-title">{"Select"}</p>
}
