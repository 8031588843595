import React, { useEffect, useState } from "react"

import { TEXT_CONSTANTS } from "../../../utils/constants"
import { useDispatch } from "react-redux"
import fileDownload from "../../../assets/images/file-download.svg"

import "./style.scss"
import SuccessFailureTable from "./successFailureTable"
import { FILE_STATUS_TAB } from "../api/constants"
import { downloadFailureRecords, getSuccessFailureRecords } from "../api/action"
import { toast } from "react-toastify"

const Tab = ({ label, isActive, onClick }) => {
    return (
        <div onClick={onClick} className={isActive ? "tab currently-active-tab" : "tab"}>
            {label}
        </div>
    )
}

const MultipleTab = ({ tabs, className, activeTab, handleTabClick }) => {
    return (
        <>
            <div className={`multiple-tabs-container-wrapper`}>
                <div className={`multiple-tabs-container ${className}`}>
                    {tabs.map((tab, index) => (
                        <Tab
                            key={tab?.label}
                            label={tab.label}
                            isActive={activeTab === index}
                            onClick={() => handleTabClick(index)}
                        />
                    ))}
                </div>
            </div>
            <div>{tabs[activeTab].component}</div>
        </>
    )
}

export default function MetadataIngestionNotificationPopup({ selectedRowData }) {
    const [isLoading, setIsLoading] = useState(false)
    const [activeTab, setActiveTab] = useState(0)
    const [successTableData, setSuccessTableData] = useState([])
    const [failureTableData, setFailureTableData] = useState([])

    const dispatch = useDispatch()

    const tabs = [
        {
            label: `${TEXT_CONSTANTS?.SUCCESS}(${successTableData?.count || 0})`,
            component: (
                <SuccessFailureTable
                    tableData={successTableData?.contentMap}
                    currentActiveTab={FILE_STATUS_TAB?.SUCCESS_TAB}
                    isLoading={isLoading}
                    selectedRowData={selectedRowData}
                ></SuccessFailureTable>
            )
        },
        {
            label: `${TEXT_CONSTANTS?.FAILURE} (${failureTableData?.count || 0})`,
            component: (
                <SuccessFailureTable
                    tableData={failureTableData?.contentMap}
                    currentActiveTab={FILE_STATUS_TAB?.FAILURE_TAB}
                    isLoading={isLoading}
                    selectedRowData={selectedRowData}
                ></SuccessFailureTable>
            )
        }
    ]

    useEffect(() => {
        fetchSuccessFailureTableData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchSuccessFailureTableData = async () => {
        setIsLoading(true)
        let response = await dispatch(getSuccessFailureRecords(true, selectedRowData?.id))
        if (response?.status) {
            setSuccessTableData(response?.data)
        }
        response = await dispatch(getSuccessFailureRecords(false, selectedRowData?.id))
        setIsLoading(false)
        if (response?.status) {
            setFailureTableData(response?.data)
        }
    }

    const handleTabClick = (index) => {
        setActiveTab(index)
    }

    const downloadTemplateHandler = async () => {
        if (failureTableData?.count > 0) {
            let response = await dispatch(downloadFailureRecords(selectedRowData?.id))

            var data = new Blob([response])
            var csvURL = window.URL.createObjectURL(data)
            let tempLink = document.createElement("a")
            tempLink.href = csvURL
            tempLink.setAttribute("download", "Failure.xlsx")
            tempLink.click()
        } else {
            toast.success("There are no errors in the files!")
        }
    }

    return (
        <div className="vr-metadata-notification-listing">
            <div className="vr-cont-head">
                <div className="vr-cont-title">
                    <h2 className="breadcrum-header">{TEXT_CONSTANTS?.FILE_STATUS_TEXT}</h2>
                </div>
            </div>
            <MultipleTab
                tabs={tabs}
                className={""}
                activeTab={activeTab}
                handleTabClick={handleTabClick}
            ></MultipleTab>

            {FILE_STATUS_TAB?.FAILURE_TAB === activeTab && (
                <div
                    className="download-btn-wrapper"
                    onClick={downloadTemplateHandler}
                    role="button"
                >
                    <img src={fileDownload} alt="file-download"></img>
                    <p className="text-color">{TEXT_CONSTANTS?.DOWNLOAD_TEMPLATE}</p>
                </div>
            )}
        </div>
    )
}
