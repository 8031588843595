import React, { useCallback, useEffect, useState } from "react"
import loaderImg from "../../../assets/images/loader.gif"
import {
    FILTER_INITIAL_DATA,
    METADATA_INGESTION_CONTENT_HEADER,
    PAGINATION_INITIAL_DATA,
    PaginationOptions
} from "../api/constants"
import CustomModal from "../../common/Modal"
import eyeIcon from "../../../assets/images/eyeIcon.svg"
import { ERROR_MESSAGES } from "../../../utils/constants"
import EmptyContent from "../../common/EmptyContent"
import Pagination from "../../common/Pagination"
import { dateTime, debounce } from "../../../utils/common"
import Input from "../../common/Input"

import "./style.scss"
import MetadataIngestionNotificationPopup from "../metadataIngestionNotificationPopup"
import { getMetadataIngestionRecords } from "../api/action"
import { useDispatch } from "react-redux"

export default function MetadataIngestionTable({ addNewBackHandler, canUpdateStatus }) {
    const [isLoading, setIsLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [metadataIngestionRecords, setMetadataIngestionRecords] = useState([])

    const [searchValue, setSearchValue] = useState("")
    const [paginationData, setPaginationData] = useState(PAGINATION_INITIAL_DATA)
    const [filterOptions, setFilterOptions] = useState(FILTER_INITIAL_DATA)
    const [selectedRowData, setSelectedRowData] = useState(null)

    const dispatch = useDispatch()

    useEffect(() => {
        fetchData(filterOptions)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const intervalCall = setInterval(() => {
            fetchData(filterOptions)
        }, 30000)
        return () => {
            // clean up
            clearInterval(intervalCall)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterOptions])

    const onPageChange = (e) => {
        const filterOptionsCopy = { ...filterOptions }
        const selectedPage = e.selected
        filterOptionsCopy.start = selectedPage + 1
        let startValue = filterOptionsCopy["start"] - 1

        let paginationDataCopy = { ...paginationData }
        paginationDataCopy.currentPage = selectedPage
        paginationDataCopy.start = startValue
        setPaginationData(paginationDataCopy)

        filterOptionsCopy.start = startValue
        setFilterOptions(filterOptionsCopy)
        fetchData(filterOptionsCopy)
    }
    const showPerPageChange = (name, value) => {
        const filterOptionsCopy = { ...filterOptions }
        filterOptionsCopy["length"] = value
        filterOptionsCopy["start"] = 0

        let defaultOption = PaginationOptions.filter((item) => {
            if (value === item.id) {
                return item
            }
            return false
        })

        let paginationDataCopy = { ...paginationData }
        paginationDataCopy.selectedOption = defaultOption
        paginationDataCopy.start = 0
        paginationDataCopy.pageCount = 0
        paginationDataCopy.currentPage = 0
        setPaginationData(paginationDataCopy)

        filterOptionsCopy.length = defaultOption[0]?.id
        setFilterOptions(filterOptionsCopy)
        fetchData(filterOptionsCopy)
    }

    const sortBy = (item) => {
        let sortBy = item
        let filterOptionsCopy = { ...filterOptions }
        filterOptionsCopy["sortBy"] = sortBy
        filterOptionsCopy["sortOrder"] = filterOptions["sortOrder"] === "desc" ? "asc" : "desc"
        setFilterOptions(filterOptionsCopy)
        fetchData(filterOptionsCopy)
    }

    const eyeIconHandler = (row) => {
        setShowModal(true)
        setSelectedRowData(row)
    }

    const renderTableData = () => {
        const renderTableHeader = (item) => {
            return (
                <p>
                    <span>{item.header}</span>{" "}
                    {item?.isSortEnabled && (
                        <span onClick={() => sortBy(item?.value)} className="triangle"></span>
                    )}
                </p>
            )
        }

        const tableHeading = METADATA_INGESTION_CONTENT_HEADER.map((item, index) => {
            return <th key={index}>{renderTableHeader(item)}</th>
        })

        // const renderStatusBar = (status, progress, statusForUI) => {
        //     switch (status) {
        //         case "MEDIA_CONVERT_JOB_COMPLETED_EVENT":
        //             return (
        //                 <div className="transcoding-completed">
        //                     <img src={GreenTick} alt={"successIcon"}></img>
        //                     <p>Completed</p>
        //                 </div>
        //             );
        //         case "MEDIA_CONVERT_JOB_IN_PROGRESS_EVENT":
        //             return (
        //                 <ProgressBar
        //                     progress={progress}
        //                     id={`transcoded-bar`}
        //                     failed={false}
        //                 />
        //             );
        //         default:
        //             return <p>{statusForUI}</p>;
        //     }
        // };

        const tableRows = metadataIngestionRecords.map((row) => {
            const uploadedOn = dateTime(row?.uploadDate)
            return (
                <tr id={row?.id} key={row?.id} className="">
                    <td>
                        <div className="tooltip-container">
                            <p className="tooltip-container-show">{row?.originalFileName}</p>
                            <div className="tooltip-container-innerText">
                                <p className="transcoding-play-url">
                                    {row?.originalFileName ? row?.originalFileName : "-"}
                                </p>
                            </div>
                        </div>
                    </td>
                    <td>{row?.uploadDate ? `${uploadedOn?.date} ${uploadedOn?.time}` : "-"}</td>
                    <td>{row?.userId ? row?.userId : "-"}</td>
                    <td>{row?.status ? row?.status : "-"}</td>
                    <td>{row?.totalRecords ? row?.totalRecords : "-"}</td>
                    <td>{row?.successCount ? row?.successCount : "-"}</td>
                    <td>{row?.failureCount ? row?.failureCount : "-"}</td>
                    <td className="btn-popupContainer">
                        {row?.status !== "IN_PROGRESS" && (
                            <img
                                src={eyeIcon}
                                alt="actionItem-icon"
                                onClick={() => {
                                    eyeIconHandler(row)
                                }}
                                className="actionItem"
                            />
                        )}
                    </td>
                </tr>
            )
        })

        return (
            <table className={`table metadata-ingestion-table`}>
                <thead>
                    <tr>{tableHeading}</tr>
                </thead>
                <tbody>{tableRows}</tbody>
            </table>
        )
    }

    const pageCountSetup = (userCount, payloadObj) => {
        const { length } = payloadObj
        let pageCount = Math.ceil(userCount / length)
        setPaginationData((prevState) => {
            return {
                ...prevState,
                pageCount: pageCount
            }
        })
    }

    const fetchData = async (payloadObj) => {
        setIsLoading(true)
        const payloadData = {
            pageNo: payloadObj?.start,
            pageSize: payloadObj?.length,
            searchQuery: payloadObj?.searchTag
        }
        const response = await dispatch(getMetadataIngestionRecords(payloadData))
        setIsLoading(false)
        if (response?.status) {
            setMetadataIngestionRecords(response?.data?.contentSummaryList)
        }
        pageCountSetup(response?.data?.totalCounts, payloadObj)
    }

    const displayLoader = () => {
        return (
            <div className="loader">
                <img src={loaderImg} alt="loader" width={"100px"} />
            </div>
        )
    }

    const submitModalHandler = () => {}

    const handleSearch = async (value) => {
        let payload = { ...filterOptions }
        payload.searchTag = value?.trim()
        payload.start = 0
        const updatedPaginationData = {
            ...paginationData,
            pageCount: 1,
            currentPage: 0,
            start: 0
        }
        setPaginationData(updatedPaginationData)
        setFilterOptions(payload)
        fetchData(payload)
    }

    const uploadMetadataHandler = () => {
        addNewBackHandler()
    }

    const setSearchQuery = useCallback(
        debounce((value) => {
            handleSearch(value)
        }, 400),
        [filterOptions]
    )

    const searchChangeHandler = (name, value) => {
        setSearchValue(value)
        setSearchQuery(value)
    }

    return (
        <div className="vr-metadata-ingestion-table-wrapper">
            {isLoading && displayLoader()}
            <div className="details-header">
                <h3>All Records</h3>
                <div className="search-box-container">
                    <div className="searchBar">
                        <div className="icon-search"></div>
                        <Input
                            id="search"
                            type="text"
                            cName={"movie-search-bar"}
                            placeholder="Search Here"
                            changeHandler={searchChangeHandler}
                            value={searchValue}
                        />
                    </div>
                </div>
            </div>
            <div className="tenant-list-container vr-content-holder metadata-ingestion-table-container">
                {!isLoading ? (
                    metadataIngestionRecords?.length > 0 ? (
                        renderTableData()
                    ) : (
                        <EmptyContent
                            errorMsg={ERROR_MESSAGES?.EMPTY_METADATA_INGESTION}
                            addNewButtonHandler={uploadMetadataHandler}
                            isButtonRequired={canUpdateStatus}
                            isErrorDesc={canUpdateStatus}
                        ></EmptyContent>
                    )
                ) : (
                    <></>
                )}
            </div>
            <Pagination
                changeHandler={showPerPageChange}
                options={PaginationOptions}
                pageCount={paginationData?.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={onPageChange}
                selectedOption={paginationData?.selectedOption}
                currentPage={paginationData?.currentPage}
            />
            <CustomModal
                showModal={showModal}
                cancelHandler={() => {
                    setShowModal(false)
                }}
                onClickCancelBtn={submitModalHandler}
                showCloseIcon={true}
                showSubmitButton={false}
                closeOnSubmit={false}
                cancelOnOutsideClick={false}
                modalContainerClass={""}
                showFooter={false}
            >
                <MetadataIngestionNotificationPopup
                    selectedRowData={selectedRowData}
                ></MetadataIngestionNotificationPopup>
            </CustomModal>
        </div>
    )
}
