import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import RadialChart from "../RadialChart"

import "./style.scss"

/* admin work */
class UsersByDevices extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isSuperAdmin: true
        }
        this.hashColor = []
        this.colorCodes = {
            android: "android-color",
            ios: "ios-color",
            web: "web-color",
            smart_tv: "smarttv-color",
            set_top_box: "mobile-web-color",
            dongle: "dongle-color"
        }
    }

    componentDidMount() {
        const { data } = this.props
        let plotValues = []
        let plotPercent = []
        let labels = []
        let totalUsers = 0

        for (let value in data) {
            labels.push(data[value]?.name)
            totalUsers = totalUsers + Number(data[value]?.inputValues[1])
            plotValues.push(data[value]?.inputValues[1])
        }
        if (totalUsers > 0) {
            for (let index in plotValues) {
                plotPercent.push(Math.round((plotValues[index] / totalUsers) * 100))
            }
        } else {
            plotPercent = plotValues
        }

        this.setState({
            plotValues: plotPercent,
            labels,
            totalUsers
        })
    }

    getCountColor = (name) => {
        let value = name && name.split(" ")[0].toLowerCase()
        return this.colorCodes[value]
    }

    render() {
        const { data } = this.props
        const { plotValues, labels, totalUsers } = this.state
        return (
            <div className="user-tenant-container">
                <div className="tenant-detail-container">
                    <p> USERS BY DEVICES</p>
                    <div>
                        {/* VRPR: 2339 Setting functionality not working */}
                        {/* <img
              src={!isDarkMode ? SettingIcon : SettingIconWhite}
              alt="setting icon"
            /> */}
                    </div>
                </div>
                <p className="horizontal-line"></p>
                <div className="tenant-added-container">
                    <div className="radial-chart">
                        {!isEmpty(plotValues) && !isEmpty(labels) && (
                            <RadialChart
                                plotValues={plotValues}
                                labels={labels}
                                totalUsers={totalUsers}
                            />
                        )}
                    </div>
                    {
                        <div className="app-list-container">
                            {data &&
                                Object.keys(data)?.map((value, index) => {
                                    return (
                                        <p className="app-name-container">
                                            <span
                                                className={`count-box ${this.getCountColor(
                                                    data[value]?.name
                                                )}`}
                                            >
                                                {data && data[value]?.inputValues[0]}
                                            </span>
                                            <span className="app-text">{data?.[value]?.name?.replaceAll('_', " ")}</span>
                                        </p>
                                    )
                                })}
                        </div>
                    }
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({}, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersByDevices)
