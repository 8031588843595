import ApiService from "../../../utils/apiService"
import { API_ROOT_PATH } from "../../../config/apiPath"

class Subscription {
    //--create plan services---

    createPlans(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/subscription/plan/create/bulk`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }

    updatePlans(id, params) {
        const options = {
            method: "PUT",
            url: `${API_ROOT_PATH}/subscription/plan/update/description/${id}`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }

    fetchCreatedPlans() {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/subscription/plan/`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    editCreatePlan(id) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/subscription/plan/${id}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    softDeleteCreatePLan(id) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/subscription/plan/softDelete/${id}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    activateDeactivatePlans(id) {
        const options = {
            method: "PUT",
            url: `${API_ROOT_PATH}/subscription/plan/active/${id}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    //--customer--

    fetchSubscriptionCustomer() {
        // const {limit, offset} = params
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/subscription/customer/`,
            isAuthRequired: true
            // data:params
        }
        return ApiService(options)
    }

    //--invoice detail --

    fetchInvoiceDetail(accountId) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/subscription/invoice/account/${accountId}?unpaidInvoicesOnly=false`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
}

const SubscriptionServices = new Subscription()
export default SubscriptionServices
