import React, { useMemo, useState } from 'react'
import Input from '../../common/Input'
import './ContentData.scss'
import { cloneDeep, set } from 'lodash';
import { convertContentTypeName, getContentEditableFields } from '../contentNavCommon';
import { AuthWrapper } from '../../common/AuthWrapper';
import { BUTTON_CONSTANTS, ERROR_MESSAGES, PERMISSIONS } from '../../../utils/constants';
import Button from '../../common/Button';
import { useDispatch } from 'react-redux';
import { editV3NewContentNav, saveV3NewContentNav } from '../api/action';
import { toast } from 'react-toastify';
import ImageUplode from '../../Movies/addMovie/imageUplode';
import { uploadImage } from '../../Movies/api/action';
import { imgPathPrefix } from '../api/constants';


export default function ContentData({
    selectedData,
    isEditable = false,
    handleAddClick,
    canUpdateStatus,
    isEdit,
    isAddNew,
    contentType
}) {

    const [selectedContentData, setSelectedContentData] = useState(selectedData)
    const dispatch = useDispatch()

    const CONTENT_EDITABLE_FIELDS = useMemo(() => {
        return getContentEditableFields(contentType?.toLowerCase())
    }, [contentType])

    const renderData = ({obj, key, indent, fullkey}) => {
        let updatedkey = `${fullkey}.${key}`
        // To print actor, director, producer value in single input box
        const isSingleValue =  isSingleEditableValue(updatedkey)

        return <div style={{ width: "100%" }}>
                    {typeof obj[key] === "object" && obj[key] !== null ? (
                        // for check if key is array then index will be +1
                        Array.isArray(obj[key]) ? (
                            isSingleValue ? 
                                renderFields(obj[key]?.toString(), updatedkey) 
                                :  
                                obj[key].map((item, index) => (
                                    <span key={index + 1}>
                                        {typeof item === "object" && item !== null ? (
                                        renderNestedData({obj: item, indent: indent + 2, fullkey: `${updatedkey}.${index}`})
                                        ) : (
                                        renderFields(item?.toString(), `${updatedkey}.${index}`)
                                        )}
                                    </span>
                                ))
                        ) : (
                            //else render normal nesting 
                           renderNestedData({obj: obj[key], indent: indent + 1, fullkey: updatedkey})
                        )
                    )
                     : (
                        renderFields(obj[key]?.toString(), updatedkey)
                    )}
                </div>
    }


    const renderRequiredKey = (fullkey) => {
          const field =  CONTENT_EDITABLE_FIELDS[fullkey?.replace(/\.\d+\./g, '.')]
        return field?.isRequired && isEditable && <span className='required'>*</span>
    }

    const isSingleEditableValue = (key) => {
        // Array of string values
        return CONTENT_EDITABLE_FIELDS[key?.replace(/\.\d+\./g, '.')]?.isSingleInput && isEditable

    }

        //------------------------start of method-----------
    const renderNestedData = ({obj, indent = 0, fullkey}) => {

        return Object.keys(obj).map((key) => (
            <div key={key} className={`children nested-${indent}`}>
                <strong
                    className="row-data keys"
                    style={{
                        ...(!indent && { width: "200px" }),
                        ...(indent && { width: "75px" })
                    }}>
                    {key}:
                    {renderRequiredKey(`${fullkey}.${key}`)}
                </strong>
                {renderData({obj, key, indent, fullkey})}
            </div>
        ))
    }


    const renderkeys = (obj, key) => {
         const isSingleValue = isSingleEditableValue(key)
        return typeof obj[key] === "object" && obj[key] !== null ? (
            // for check if key is array then index will be +1
            Array.isArray(obj[key]) ? 
            isSingleValue ? 
                renderFields(obj[key]?.toString(), key) :
            (
                obj[key].map((item, index) => (
                    <span key={index + 1} className={`${key}`}>
                        {typeof item === "object" && item !== null ? (
                            renderNestedData({obj: item, fullkey: `${key}.${index}`})
                        ) : (
                            renderFields(item?.toString(), `${key}.${index}`)
                        )}
                    </span>
                ))
            ) : (
                //else render normal nesting
                renderNestedData({obj: obj[key], fullkey: key})
            )
        ) : (
            renderFields(obj[key]?.toString(), key)
        )
    }
    

    const renderTable = (obj) => {
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th style={{ padding: "8px" }}>Field</th>
                        <th style={{ padding: "8px" }}>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(obj).map((key) => (
                        
                        <tr key={key}>
                            <td className="field">
                                {key}
                                {renderRequiredKey(`${key}`)}
                            </td>
                            <td className="values">
                                {renderkeys(obj, key)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    }

    const changeHandler = (name, value) => {
        const copyObj = cloneDeep(selectedContentData)
        const field =  CONTENT_EDITABLE_FIELDS[name?.replace(/\.\d+\./g, '.')]
        const updatedValue = field?.isArrayPayload ? [value] : value
        set(copyObj, name, updatedValue)
        setSelectedContentData(copyObj)
    }


    const imgUpload = async (data, id, name) => {
        const response = await dispatch(uploadImage(data))
        if (response && response.status) {
            let imgUrl = response.data.path
            changeHandler(name, `${imgPathPrefix}${imgUrl}`)
        }
    }
    const imgRemove = (name, item) => {
       changeHandler(name, "", false, item)
    }


    const renderFields = (displayValue, title) => {
    const field =  CONTENT_EDITABLE_FIELDS[title?.replace(/\.\d+\./g, '.')]
    if(field?.isEditable && isEditable){
        if(field?.isImage){
            return  <div>
                <ImageUplode
                    key={title}
                    name={title}
                    id={title}
                    value={displayValue ?? null}
                    uploadImage={imgUpload}
                    removeImg={(name) => imgRemove(name)}
                    disabled={!canUpdateStatus}
                    showRecommededSize={false}
                />
                <span className="row-data">{displayValue}</span>
            </div>
        }
        return  <Input
            cName={"row-data"}
            name={title}
            value={displayValue}
            changeHandler={(name, value) => {
                changeHandler(name, value)
                }
            }
            />
    }else {
       return <span className="row-data">{displayValue}</span>
    }
    }


    const handleSave = async () => {
        let response;
        if(isAddNew){
            response = await dispatch(saveV3NewContentNav(selectedContentData, convertContentTypeName(contentType, true)))
        }else{
            response = await dispatch(editV3NewContentNav(selectedContentData, convertContentTypeName(contentType, false,  selectedData?.id)))
        }
        if (response && response?.status){
            toast.success(response?.message)
            handleAddClick()
        }else {
            toast.error(response?.message ?? ERROR_MESSAGES.SOMETHING_WENT_WRONG)
        }
    }

  return (
    <div className="vd-addMovie 3">
        {renderTable(selectedContentData)}
        <div className="save-and-next">
            {isEditable && <AuthWrapper permissions={[PERMISSIONS.CONTENT_EDIT]}>
                <Button
                    cName={"btn primary-btn"}
                    bType={"submit"}
                    bValue={BUTTON_CONSTANTS?.SUBMIT?.toUpperCase()}
                    bIcon={"icon-left-arrow"}
                    clickHandler={handleSave}
                />
            </AuthWrapper>}
            <Button
                cName={"btn cancel-btn"}
                bType={"button"}
                bValue={BUTTON_CONSTANTS?.CANCEL?.toUpperCase()}
                clickHandler={handleAddClick}
            />
        </div>
    </div>
  )
}



