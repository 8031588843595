import { ACTION } from "./constant"
import FormBuilderMangementService from "./service"
import { toast } from "react-toastify"

export const fetchContentList = (params) => {
    return (dispatch) => {
        return FormBuilderMangementService.fetchContentList(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_CONTENT,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting user list ", error)
            })
    }
}
export const fetchContentEntityAll = (params) => {
    return (dispatch) => {
        return FormBuilderMangementService.fetchContentEntityAll(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_CONTENT_ENTITY_ALL,
                    apiResponse: response.data
                })
                return response.data
                // return dataJson;
            })
            .catch((error) => {
                console.log("Got error in getting user list ", error)
            })
    }
}
export const saveForm = (params) => {
    /*else if(params)*/
    return (dispatch) => {
        return FormBuilderMangementService.saveForm(params)
            .then((response) => {
                if (!params.formTitle) {
                    toast.error("Please enter form title")
                } else if (!params.searchKeys.length) {
                    toast.error("Please select search keys")
                } else if (!params.name) {
                    toast.error("Please enter apply on")
                } else if (!params.imageKey) {
                    toast.error("Please select image key")
                } else if (!params.nameKey) {
                    toast.error("Please select name key")
                } else if (response && response.status) {
                    toast.success(response.data.message)
                    return response.data
                } else {
                    toast.error(response.data.message)
                }
            })
            .catch((error) => {
                toast.error("Something went wrong")
                console.log("Got error in creating category ", error)
            })
    }
}
export const deleteForm = (params) => {
    return (dispatch) => {
        return FormBuilderMangementService.deleteForm(params)
            .then((response) => {
                toast.success(response.data.message)
                return response.data
            })
            .catch((error) => {
                toast.error(error || "Something went wrong")
                console.log("Got error in creating category", error)
            })
    }
}
