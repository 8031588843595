import React from "react"
import { imagePathPrefix } from "../../../config/apiPath"
import NoImgAvailable from "../../../assets/images/no_img_available.png"
import Slider from "react-slick"

import "./ImageSlider.scss"

const ImageSlider = ({ images, isWebSeriesTab }) => {
    const joinUrl = (string) => {
        return imagePathPrefix + string
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 12,
        slidesToScroll: 1
    }

    const renderImages = () => {
        return images.map((image, index) => (
            <div className="image-row-container" key={image?.id}>
                <img
                    src={image?.thumbnail != null ? joinUrl(image?.thumbnail) : `${NoImgAvailable}`}
                    alt={`content ${index}`}
                />
                {image?.seasonCount && <p className="season-text">{`${image?.seasonCount}S`}</p>}
            </div>
        ))
    }

    return (
        <>
            <Slider {...settings}>{renderImages()}</Slider>
            {!isWebSeriesTab && (
                <p className="selected-content-count">{images?.length || 0} movies selected</p>
            )}
        </>
    )
}

export default ImageSlider
