import { TEXT_CONSTANTS } from "../../../utils/constants"

export const ACTION = {
    FETCH_PLATFORM: "FETCH_PLATFORM",
    CREATE_CATEGORY: "CREATE_CATEGORY",
    UPDATE_CATEGORY: "UPDATE_CATEGORY",
    FETCH_CATEGORY: "FETCH_CATEGORY",
    FETCH_ALL_CATEGORY: "FETCH_ALL_CATEGORY",
    FETCH_CATEGORY_BY_ID: "FETCH_CATEGORY_BY_ID",
    DELETE_CATEGORY: "DELETE_CATEGORY",
    UNPUBLISH_CATEGORY: "UNPUBLISH_CATEGORY",
    CREATE_RAIL: "CREATE_RAIL",
    AUTO_SHUFFLE_CREATE_RAIL: "AUTO_SHUFFLE_CREATE_RAIL",
    FETCH_RAIL_BY_ID: "FETCH_RAIL_BY_ID",
    FETCH_FILTERED_RAIL_BY_ID: "FETCH_FILTERED_RAIL_BY_ID",
    FETCH_ALL_RAIL: "FETCH_ALL_RAIL",
    DEACTIVATE_RAIL_BY_ID: "DEACTIVATE_RAIL_BY_ID",
    SOFT_DELETE_RAIL: "SOFT_DELETE_RAIL",
    FETCH_FILTERED_LIST: "FETCH_FILTERED_LIST",
    FETCH_CONTENT_TYPE: "FETCH_CONTENT_TYPE",
    FETCH_RAIL_TYPE: "FETCH_RAIL_TYPE",
    CONTENT_SEARCH: "CONTENT_SEARCH",
    FETCH_FILTERS: "FETCH_FILTERS",
    FETCH_CONTENT_BY_ID: "FETCH_CONTENT_BY_ID",
    RAIL_REPOSITION: "RAIL_REPOSITION",
    RAIL_LAST_POSITION: "RAIL_LAST_POSITION",
    UPLOAD_IMAGE: "UPLOAD_IMAGE",
    FETCH_DUMMY_RAIL: "FETCH_DUMMY_RAIL",
    CHANGE_UPLOAD_IMAGE: "CHANGE_UPLOAD_IMAGE",
    DELETE_CONTENT_IMAGE: "DELETE_CONTENT_IMAGE",
    REFRESH_RAILS: "REFRESH_RAILS",
    FAST_CHANNEL: "FAST_CHANNEL",
    FETCH_CERTIFICATION: "FETCH_CERTIFICATION",
    FETCH_GRADING: "FETCH_GRADING",
    GET_ALL_ENV: "GET_ALL_ENV"
}

export const errorMsg = {
    END_GREATER_TO_START: "End Time Must Greater Than Start Time",
    Start_TIME_TEXT: "Start Time Must Be 24 HRS Format",
    End_TIME_TEXT: "End Time Must Be 24 HRS Format",
    End_START_DATE: "End Date Must Be After Start Date",
    START_TODAY_OR_AFTER: "Start Date Must Be Today Or After Today",
    END_TODAY_OR_AFETR: "End Date Must Be Today Or After Today",
    VALID_START_DATE: "Please Fill Valid Start Date",
    VALID_END_DATE: "Please Fill Valid End Date",
    GMT_START_TIME: "Please Fill Valid GMT Start Time",
    GMT_END_TIME: "Please Fill Valid GMT End Time",
    GMT_TIME_PUBLISH: "Please Fill GMT Time For Schedule Publish.",
    SPORTS_SUBTYPE_SELECTION: "Please select sports sub type!"
}

export const RAIL_TYPE_CONSTANT = {
    LANGUAGE: "LANGUAGE",
    GENRE: "GENRE",
    CONTINUE_WATCHING: "CONTINUE_WATCHING",
    ACTOR: "ACTOR",
    RAIL: "RAIL",
    LATEST: "LATEST",
    PROMOTION_BANNER: "PROMOTIONAL_BANNER",
    CHANNEL_RAIL: "CHANNEL_RAIL",
    FAVOURITE: "FAVOURITE",
    HERO_BANNER: "HERO_BANNER",
    ADS: "ADS",
    LANGUAGE_BASED: "LANGUAGE_BASED",
    GENRE_BASED: "GENRE_BASED",
    GENERIC_BASED: "GENERIC_BASED"
}

export const CONTENT_TYPE_CONST = {
    LIVE_TV: "LIVE TV",
    WEB_SERIES: "WEB SERIES",
    SPORTS: "SPORTS",
    MOVIES: "MOVIES",
    CHANNEL: "CHANNEL"
}

export const contentType = [
    { id: "All", name: "All" },
    { id: "Movies", name: "Movies" },
    { id: "Live Tv", name: "Live Tv" }
]

export const platformFilterList = [
    { id: 1, name: "Android" },
    { id: 2, name: "ios" },
    { id: 3, name: "desktop web" },
    { id: 3, name: "smart tv" },
    { id: 4, name: "dongle" },
    { id: 5, name: "set-top box" }
]

export const year = [
    {
        id: 1990,
        name: 1990
    },
    {
        id: 1991,
        name: 1991
    },
    {
        id: 1992,
        name: 1992
    },
    {
        id: 1993,
        name: 1993
    },
    {
        id: 1994,
        name: 1994
    },
    {
        id: 1995,
        name: 1995
    },
    {
        id: 1996,
        name: 1996
    },
    {
        id: 1997,
        name: 1997
    },
    {
        id: 1998,
        name: 1998
    },
    {
        id: 1999,
        name: 1999
    },
    {
        id: 2000,
        name: 2000
    },
    {
        id: 2001,
        name: 2001
    },
    {
        id: 2002,
        name: 2002
    },
    {
        id: 2003,
        name: 2003
    },
    {
        id: 2004,
        name: 2004
    },
    {
        id: 2005,
        name: 2005
    },
    {
        id: 2006,
        name: 2006
    },
    {
        id: 2007,
        name: 2007
    },
    {
        id: 2008,
        name: 2008
    },
    {
        id: 2009,
        name: 2009
    },
    {
        id: 2010,
        name: 2010
    },
    {
        id: 2011,
        name: 2011
    },
    {
        id: 2012,
        name: 2012
    },
    {
        id: 2013,
        name: 2013
    },
    {
        id: 2014,
        name: 2014
    },
    {
        id: 2015,
        name: 2015
    },
    {
        id: 2016,
        name: 2016
    },
    {
        id: 2017,
        name: 2017
    },
    {
        id: 2018,
        name: 2018
    },
    {
        id: 2019,
        name: 2019
    },
    {
        id: 2020,
        name: 2020
    }
]

export const menuTab = [
    {
        order: "",
        tabName: "Home",
        contentType: "",
        platformType: "",
        isPublished: false
    },
    {
        order: "",
        tabName: "Movies",
        contentType: "",
        platformType: "",
        isPublished: false
    },
    {
        order: "",
        tabName: "live series",
        contentType: "",
        platformType: "",
        isPublished: false
    },
    {
        order: "",
        tabName: "web tv",
        contentType: "",
        platformType: "",
        isPublished: false
    }
]

export const optionsBannerType = [
    {
        id: 0,
        name: "select"
    },
    {
        id: 1,
        name: "Web Series"
    },
    {
        id: 2,
        name: "Movies"
    },
    {
        id: 3,
        name: "Live Tv"
    }
]
export const optionsContentType = [
    {
        id: "select",
        name: "select"
    },
    {
        id: "Live Channel",
        name: "Live Channel"
    },
    {
        id: "Forward EPG",
        name: "Forward EPG"
    },
    {
        id: "Catch-up",
        name: "Catch-up"
    },
    {
        id: "Brands",
        name: "Brands"
    },
    {
        id: "Series",
        name: "Series"
    },
    {
        id: "Movies",
        name: "Movies"
    },
    {
        id: "TV Shows",
        name: "TV Shows"
    },
    {
        id: "Customs",
        name: "Customs"
    }
]
export const genreOptions = [
    {
        id: 0,
        name: "select"
    },
    {
        id: 1,
        name: "Action"
    },
    {
        id: 2,
        name: "Comedy"
    },
    {
        id: 3,
        name: "Thriller"
    }
]

export const optionsRailType = [
    {
        id: "RAIL",
        name: "RAIL"
    },
    {
        id: "HERO_BANNER",
        name: "HERO BANNER"
    }
]

export const ORIENTATION_MENU = {
    PORTRAIT: 0,
    LANDSCAPE: 1,
    CIRCULAR: 2
}

export const orientationsOptions = [
    {
        id: ORIENTATION_MENU.PORTRAIT,
        name: "PORTRAIT"
    },
    {
        id: ORIENTATION_MENU.LANDSCAPE,
        name: "LANDSCAPE"
    },
    {
        id: ORIENTATION_MENU.CIRCULAR,
        name: "CIRCULAR"
    }
]

export const formatTypeOptions = [
    {
        id: "WEB_URL",
        name: "WEB URL"
    }
]

export const shuffleOption = [
    {
        id: 1,
        name: "no"
    }
]

export const filterList = [
    { id: "publish", name: "publish" },
    { id: "unpublish", name: "unpublish" }
    // , { id: 'scheduled', name: 'scheduled' }
]

export const dropdown = [
    { id: "0", name: "public", icon: "icon-globe", published: true },
    { id: "1", name: "our team", icon: "icon-lock", published: false }
]

export const options = [
    { id: 10, name: "10" },
    { id: 20, name: "20" },
    { id: 30, name: "30" },
    { id: 50, name: "50" }
]

export const REDIRECTION_OPTION = {
    EXTERNAL: "EXTERNAL_URL",
    IN_APP: "IN_APP"
}

export const RAIL_CATEGORY = {
    SYSTEM_GENERATED: "SYSTEM_GENERATED",
    NEW: "NEW"
}

export const REDIRECTION_LIST = [
    {
        name: "Content Type",
        id: REDIRECTION_OPTION.IN_APP
    },
    {
        name: "External",
        id: REDIRECTION_OPTION.EXTERNAL
    }
]

export const CARD_SIZE_LIST = [
    {
        id: "REGULAR",
        name: "REGULAR CARD"
    },
    {
        id: "LARGE",
        name: "LARGE CARD"
    }
]

export const CONTENT_LEVEL_OPTION = {
    BRAND: 0,
    SEASON: 1,
    EPISODE: 2
}

export const VOD_TABS = [
    {
        id: "GEC/VOD",
        label: `GEC/VOD`
    }
]

export const SPORTS_HOME_TABS = [
    {
        id: "Sports-home",
        label: `Sports`
    }
]

export const SPORTS_CONTENT_TYPE = {
    id: "Sports",
    label: `SPORTS`,
    value: "Sports"
}

export const VOD_SPORTS_TAB = {
    SPORTS_HOME: "Sports_Home",
    VOD: "VOD",
    CREATE_MANAGE_PAGE: "Create_Manage_Page"
}

export const SPORTS_ID = "-sports"

export const CREATE_PAGE_DETAILS = {
    menuTitle: TEXT_CONSTANTS?.SPORTS,
    pageTitle: TEXT_CONSTANTS?.CREATE_MANAGE_PAGE
}

export const VOD_PAGE_DETAILS = {
    menuTitle: TEXT_CONSTANTS?.UX_MANAGER,
    pageTitle: TEXT_CONSTANTS?.CATEGORY
}

export const SPORTS_HOME_PAGE_DETAILS = {
    menuTitle: TEXT_CONSTANTS?.SPORTS,
    pageTitle: TEXT_CONSTANTS?.SPORTS_HOME
}

export const RESPONSIVE_1360_BREAKPOINT = {
    breakpoint: 1360,
    settings: {
        slidesToShow: 2,
        slidesToScroll: 1
    }
}

export const RESPONSIVE_1680_BREAKPOINT = {
    breakpoint: 1680,
    settings: {
        slidesToShow: 3,
        slidesToScroll: 2
    }
}

export const PUBLISH_UNPULISH_GROUP = [
    {
        id: "publish",
        name: "Publish"
    },
    {
        id: "unpublish",
        name: "UnPublish"
    }
]

export const SPORTS_HOME_PAGE_TAB = {
    id: "sportsHomePage",
    name: "Sports Home Page"
}
