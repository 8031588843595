import React, { useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"

import "./style.scss"
import { toast } from "react-toastify"
import { isEmpty } from "lodash"
import uploadFile from "../../../assets/images/uploadFile.svg"

export const Dropzone = (props) => {
    const [dropped, setDropped] = useState(false)
    const [selectedFiles, setFiles] = useState([])

    const onDrop = useCallback((acceptedFiles) => {
        /* eslint-disable */
        // Do something with the files
        if (acceptedFiles.length === 0) {
            toast.error("Some issue in file")
        }
        if (acceptedFiles.length > props?.maxSize) {
            return
        }

        acceptedFiles.forEach((file) => {
            const reader = new FileReader()
            reader.onabort = () => console.log("file reading was aborted")
            reader.onerror = () => console.log("file reading has failed")
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result
                console.log(binaryStr)
            }
            reader.readAsArrayBuffer(file)
        })
        if (acceptedFiles.length) {
            setDropped(true)
            setFiles(acceptedFiles)
            props.setFiles(acceptedFiles)
        }
    }, [])

    const onDropError = (error) => {
        console.log("error")
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: props.acceptFiles ? props.acceptFiles : "",
        onError: onDropError,
        multiple: props.isMultipleUpload === "false" ? false : true
    })
    return (
        <div className={`dropzone-area ${props.disabled ? "disabled" : ""}`} {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
                <p className="drop-text">Drop the files here ...</p>
            ) : (
                <p className="drop-text">
                    <img src={uploadFile} alt="uploadedFile"></img>
                    {dropped && !isEmpty(selectedFiles) && props.isMultipleUpload === "false" ? (
                        <p>{selectedFiles?.[0].name}</p>
                    ) : (
                        <p>
                            {" "}
                            Drag and Drop files to upload OR <span className="browse">
                                Browse
                            </span>{" "}
                            <span>{`(max files at a time ${props?.maxSize})`}</span>
                        </p>
                    )}
                </p>
            )}
        </div>
    )
}
