import React, { useEffect, useState } from "react"
import { API_ROOT_PATH } from "../../../config/apiPath"
import LANGUAGE_IMAGE from "../../../assets/images/language.svg"
import MultiSelect from "./multiselect"
import { fetchLanguageList } from "./api/action"

import "./index.scss"

export const setLanguageInLocalStorage = (languages) => {
    let languageObj = {}
    // Only add enabled languages in the language object
    localStorage.setItem("languages", JSON.stringify(languages))
    languages &&
        languages
            .filter((item) => item?.enable)
            .map((value) => {
                languageObj[value.code] = ""
                return value
            })
    localStorage.setItem("languageObj", JSON.stringify(languageObj))
    return languageObj
}

export const getLanguageObject = async () => {
    if (localStorage.getItem("languageObj")) {
        return JSON.parse(localStorage.getItem("languageObj"))
    } else {
        const url = `${API_ROOT_PATH}/content/language/getLanguageList`
        try {
            const response = await fetch(url)
            const languages = await response.json()
            return setLanguageInLocalStorage(languages?.data?.languages)
        } catch (error) {
            console.log("error", error)
        }
    }
}

const LanguageCardList = (props) => {
    const [languageData, setLanguageData] = useState(0)
    const [selectedLanguage, setLanguage] = useState({
        name: "English",
        code: "en",
        id: "en"
    })

    const setLanguageValue = (response) => {
        const languages = response?.data?.languages
        setLanguageData(languages?.filter((item) => item?.enable))
        setLanguageInLocalStorage(languages)
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                fetchLanguageList(setLanguageValue)
            } catch (error) {
                console.log("error", error)
            }
        }

        fetchData()
    }, [])

    const handleSelectChange = (id, selectedLanguage) => {
        const { handleChange } = props
        setLanguage(selectedLanguage)
        handleChange({ name: selectedLanguage.name, code: selectedLanguage.id })
    }
    const selectStyles = { menu: (styles) => ({ ...styles, zIndex: 999 }) }

    return (
        <div className="multilingual-container">
            <div className="sub-container">
                <img src={LANGUAGE_IMAGE} alt="globe" />
                <MultiSelect
                    key={"placement"}
                    options={
                        languageData &&
                        languageData?.map((item) => ({
                            id: item.code,
                            name: item.name
                        }))
                    }
                    placeholder={"Select"}
                    label="SELECT INPUT LANGUAGE"
                    id={"language"}
                    name={"language"}
                    styles={selectStyles}
                    selectedValues={selectedLanguage}
                    changeHandler={handleSelectChange}
                />
            </div>
        </div>
    )
}
export default LanguageCardList
