import React, { Component } from "react"
import videojs from "video.js"
import "video.js/dist/video-js.css"
import "./style.scss"
import { calcSliderPos, getSeconds, toHHMMSS } from "./util"
import playIcon from "../../assets/images/play.svg"
import pauseIcon from "../../assets/images/pause.svg"
import muteIcon from "../../assets/images/mute.svg"
import unmuteIcon from "../../assets/images/unmute.svg"
export default class VideoPlayerJS extends Component {
    constructor(props) {
        super(props)

        this.state = {
            src: props.src,
            timeFrames: props.timeFrames,
            isMuted: true,
            isPaused: true,
            contentId: props.id,
            selectedAcc: props.selectedAcc || null,
            selectedAds: props.selectedAds
        }
        this.valueHover = 0
        this.videoRef = React.createRef()
        this.updateVideoSrc = this.updateVideoSrc.bind(this)
        this.isPaused = this.isPaused.bind(this)
        this.play = this.play.bind(this)
        this.pause = this.pause.bind(this)
        this.mute = this.mute.bind(this)
        this.unMute = this.unMute.bind(this)
        this.onPlay = this.onPlay.bind(this)
        this.onPause = this.onPause.bind(this)
        this.onVolume = this.onVolume.bind(this)
        this.onError = this.onError.bind(this)
        this.onTimeupdate = this.onTimeupdate.bind(this)
        this.onReady = this.onReady.bind(this)
    }
    componentDidMount() {
        const self = this
        const { src, timeFrames } = this.state
        const params = {
            controls: true,
            autoplay: false,
            preload: "auto",
            volume: 1,
            poster: src.videoPoster,
            sources: [
                {
                    src: src.videoUrl
                }
            ],
            inactivityTimeout: 0, // show controls always
            errorDisplay: false,
            liveui: true,
            fluid: true
        }
        this.videoRef.current.onerror = this.onError
        this.player = videojs(this.videoRef.current, params, function onPlayerReady() {
            const player = this
            // Button State
            this.setState({ isPaused: player.paused() })
            this.setState({ isMuted: player.muted() })

            // Events
            player.muted(true)
            player.on("play", self.onPlay)
            player.on("pause", self.onPause)
            player.on("volumechange", self.onVolume)
            player.on("error", self.onError)
            player.on("timeupdate", self.onTimeupdate)
            player.on("ready", self.onReady)

            setTimeout(() => {
                // self.autoplay();
            }, 500)
        })
        if (timeFrames && timeFrames != null) {
            this.addTimeSlot()
        }
        const seekBall = document.getElementById(`custom-seekbar_${this.state.contentId}`)
        if (this.state.contentId === "preview") {
            const playerEle = document.querySelectorAll(`#videojs_${this.state.contentId} video`)
            const controlBar = document.getElementById(`custom-controlBar_${this.state.contentId}`)
            let timeOut = null
            playerEle[0].addEventListener("mousemove", () => {
                clearTimeout(timeOut)
                if (!controlBar.classList.contains("show")) {
                    controlBar.classList.add("show")
                }
                timeOut = setTimeout(() => {
                    controlBar.classList.remove("show")
                }, 50000)
            })
        }
        seekBall.value = 0
        seekBall.addEventListener("click", (e) => {
            e.stopPropagation()
            let seekTime = this.player.duration() * (seekBall.value / 1000)
            this.player.currentTime(seekTime)
        })

        seekBall.addEventListener("mousemove", (e) => {
            this.valueHover = calcSliderPos(e).toFixed(2)
            let tooltip = document.getElementById(`durtimeText_${this.state.contentId}`)
            tooltip.style.left = this.valueHover + "%"
            tooltip.innerText = toHHMMSS((this.player.duration() * this.valueHover) / 100)
        })
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.src.videoUrl !== prevState.src.videoUrl) {
            this.updateVideoSrc()
        }
        if (this.state.timeFrames !== prevState.timeFrames) {
            this.addTimeSlot()
        }
        if (this.state.selectedAcc !== prevState.selectedAcc) {
            this.addTimeSlot()
        }
    }
    static getDerivedStateFromProps(nextProps, state) {
        let obj = {}
        if (nextProps.timeFrames && state.timeFrames !== nextProps.timeFrames) {
            obj = {
                ...obj,
                timeFrames: nextProps.timeFrames
            }
        }
        if (nextProps.src && state.src !== nextProps.src) {
            obj = {
                ...obj,
                src: nextProps.src
            }
        }

        if (state.selectedAcc !== nextProps.selectedAcc) {
            obj = {
                ...obj,
                selectedAcc: nextProps.selectedAcc
            }
        }
        if (state.selectedAds !== nextProps.selectedAds) {
            obj = {
                ...obj,
                selectedAds: nextProps.selectedAds
            }
        }
        return obj
    }

    componentWillUnmount() {
        if (this.intId) {
            clearInterval(this.intId)
        }
        if (this.videoRef.current) {
            this.videoRef.current.onerror = undefined
        }
        if (this.player) {
            this.player.dispose()
            this.player = undefined
        }
    }

    updateVideoSrc() {
        const { src } = this.state
        if (this.player) {
            const controlBar = document.getElementById(`custom-controlBar_${this.state.contentId}`)
            if (controlBar.classList.contains("show") && this.state.contentId === "preview") {
                controlBar.classList.remove("show")
            }
            this.player.src(src.videoUrl)
            this.player.play()
            this.addTimeSlot()
            if (this.state.selectedAds && Object.keys(this.state.selectedAds).length) {
                let timeframeArray = Object.keys(this.state.selectedAds)
                let arr = timeframeArray[timeframeArray.length - 1].split("-")
                let startSec = getSeconds(arr[0])
                this.player.currentTime(startSec)
            }
        }
    }

    addTimeSlot() {
        if (this.player.duration()) {
            const { timeFrames, selectedAcc, selectedAds } = this.state
            let selectedTimeFrames = Object.keys(selectedAds)
            if (selectedTimeFrames.indexOf(selectedAcc) === -1) {
                selectedTimeFrames.push(selectedAcc)
            }
            const seekBar = document.getElementById(`custom-seekbar_${this.state.contentId}`)
            for (const [key] of Object.entries(timeFrames)) {
                let eleTemp = document.getElementById(`timeslot_${key}`)
                eleTemp && eleTemp.remove()
                if (selectedTimeFrames.indexOf(key) > -1) {
                    let property = this.updateSlotPosition(key)
                    let ele = document.createElement("div")
                    ele.id = `timeslot_${key}`
                    ele.className = "timeSlot"
                    ele.style.left = property.left
                    ele.style.width = property.width
                    seekBar.parentNode.insertBefore(ele, seekBar)
                    let toolTip = document.createElement("span")
                    toolTip.className = "toottip"
                    toolTip.style.left = "50%"
                    toolTip.innerHTML = `<div class="bold">Ad Opportunity</div> <div>${key}</div>`
                    ele.appendChild(toolTip)
                } else {
                    let ele = document.getElementById(`timeslot_${key}`)
                    ele && ele.remove()
                }
            }
        } else {
            setTimeout(() => {
                this.addTimeSlot()
            }, 1000)
        }
    }
    updateSlotPosition = (timeframe) => {
        let arr = timeframe.split("-")

        let startSec = getSeconds(arr[0])
        let endSec = getSeconds(arr[1])
        return {
            left: startSec * (100 / this.player.duration()) + "%",
            width: (endSec - startSec) * (100 / this.player.duration()) + "%"
        }
    }

    isPaused() {
        if (this.player) {
            return this.player.paused()
        }
        return false
    }

    play() {
        if (this.player) {
            this.player.play()
        }
    }

    pause() {
        if (this.player) {
            this.player.pause()
        }
    }

    mute() {
        if (this.player) {
            let muteBtn = document.getElementById(`custom-mute_${this.state.contentId}`)
            muteBtn.firstChild.src = muteIcon
            this.player.muted(true)
        }
    }

    unMute() {
        if (this.player) {
            let muteBtn = document.getElementById(`custom-mute_${this.state.contentId}`)
            muteBtn.firstChild.src = unmuteIcon
            this.player.muted(false)
        }
    }

    onPlay() {
        this.setState({
            error: undefined,
            isPaused: this.player.paused(),
            isMuted: this.player.muted()
        })
        const controlBar = document.getElementById(`custom-controlBar_${this.state.contentId}`)
        if (!controlBar.classList.contains("show") && this.state.contentId === "preview") {
            controlBar.classList.add("show")
        }
        let playBtn = document.getElementById(`play-btn_${this.state.contentId}`)
        playBtn.firstChild.src = pauseIcon
    }
    onReady(e) {
        let playBtn = document.getElementById(`play-btn_${this.state.contentId}`)
        let muteBtn = document.getElementById(`custom-mute_${this.state.contentId}`)
        playBtn.addEventListener("click", (e) => {
            e.stopPropagation()
            if (this.player.paused()) {
                this.player.play()
            } else {
                this.player.pause()
            }
        })
        muteBtn.addEventListener("click", (e) => {
            e.stopPropagation()
            if (this.player.muted()) {
                this.unMute(false)
            } else {
                this.mute()
            }
        })
    }
    onPause() {
        this.setState({
            isPaused: this.player.paused(),
            isMuted: this.player.muted()
        })
        let playBtn = document.getElementById(`play-btn_${this.state.contentId}`)
        playBtn.firstChild.src = playIcon
    }

    onVolume() {
        this.setState({ isMuted: this.player.muted() })
    }
    onMute() {}
    onError(e) {
        this.player.pause()
        const controlBar = document.getElementById(`custom-controlBar_${this.state.contentId}`)
        if (controlBar.classList.contains("show")) {
            controlBar.classList.remove("show")
        }
    }
    onTimeupdate() {
        if (this.player) {
            let seekBall = document.getElementById(`custom-seekbar_${this.state.contentId}`)
            let progressBar = document.getElementById(`progressBar_${this.state.contentId}`)
            let seekPos = this.player.currentTime() * (100 / this.player.duration())
            progressBar.style.width = seekPos + "%"
            seekBall.value = seekPos * 10
            document.getElementById(`custom-timer_${this.state.contentId}`).innerText = toHHMMSS(
                this.player.duration() - this.player.currentTime()
            )
        }
    }

    render() {
        const { contentId } = this.state
        return (
            <div
                data-vjs-player
                style={{
                    backgroundColor: "#000",
                    border: "solid 1px #000",
                    position: "relative"
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        top: 8,
                        left: "25%",
                        width: "50%",
                        zIndex: 1
                    }}
                >
                    {/*{this.renderAlert()}*/}
                </div>
                <video
                    ref={this.videoRef}
                    id={`videojs_${contentId}`}
                    className="video-js vjs-default-skin vjs-big-play-centered"
                ></video>
                <div id={`custom-controlBar_${contentId}`} className="custom-controlBar">
                    <div id={`play-btn_${contentId}`} className="play-btn">
                        <img src={playIcon} alt="" />
                    </div>
                    <div className="custom-seekbar-container">
                        <span
                            id={`durtimeText_${contentId}`}
                            className="toottip durtimeText"
                        ></span>
                        <div id={`progressBar_${contentId}`} className="progressBar"></div>
                        <input
                            type="range"
                            className="custom-seekbar"
                            id={`custom-seekbar_${contentId}`}
                            name="points"
                            min="0"
                            max="1000"
                        />
                    </div>
                    <div id={`custom-timer_${contentId}`} className="custom-timer"></div>
                    <div id={`custom-mute_${contentId}`} className="custom-mute">
                        <img src={muteIcon} alt="" />
                    </div>
                </div>
            </div>
        )
    }
}
