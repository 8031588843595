import React, { Component } from "react"
import Select from "../SelectBox/index"
import ReactPaginate from "react-paginate"
import "./style.scss"

class Pagination extends Component {
    state = {
        currentPage: 0
    }

    componentDidUpdate() {
        if (this.props.currentPage !== this.state.currentPage)
            this.changeCurrentpage(this.props.currentPage)
    }
    changeCurrentpage = (page) => {
        this.setState({
            currentPage: page
        })
    }

    render() {
        const {
            changeHandler,
            pageCount,
            marginPagesDisplayed,
            pageRangeDisplayed,
            onPageChange,
            options,
            selectedOption,
            isAutoFocus = true
        } = this.props
        return (
            <div className="pagination-footer">
                <div className="ftr-left">
                    <span>showing per page</span>
                    <Select
                        name="pageItem"
                        defaultOption={selectedOption ? selectedOption : { name: "10", id: 10 }}
                        value={selectedOption}
                        options={options}
                        changeHandler={changeHandler}
                        cName="menuOptions"
                        isAutoFocus={isAutoFocus}
                    />
                </div>
                <div className="ftr-right">
                    <ReactPaginate
                        previousClassName={
                            pageCount > 5 && this.state.currentPage !== 0
                                ? "showlabel"
                                : "hidelabel"
                        }
                        nextClassName={
                            pageCount > 5 && this.state.currentPage !== pageCount - 1
                                ? "showlabel"
                                : "hidelabel"
                        }
                        previousLabel={"<"}
                        nextLabel={">"}
                        pageCount={pageCount}
                        marginPagesDisplayed={marginPagesDisplayed}
                        pageRangeDisplayed={pageRangeDisplayed}
                        onPageChange={onPageChange}
                        pageClassName={"list-nmbr"}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        activeLinkClassName={"activeLink"}
                        forcePage={this.state.currentPage} // to focus on particular page
                    />
                </div>
            </div>
        )
    }
}

export default Pagination
