import React, { useEffect, useState } from "react"
import CustomModal from "../../Modal"
import { multilingualLabels } from "../../../Mulltilingual/api/constant"

export default function LanguageDataConfirmationModal({
    isModalOpen = false,
    submitHandler,
    cancelHandler
}) {
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        setShowModal(isModalOpen)
    }, [isModalOpen])

    const modalSubmitHandler = () => {
        submitHandler()
        setModalState(false)
    }

    const modalCancelHandler = () => {
        cancelHandler()
        setModalState(false)
    }

    const setModalState = (val) => {
        setShowModal(val)
    }

    return (
        <div className="vr-multilingual-language-container">
            <CustomModal
                showModal={showModal}
                submitHandler={modalSubmitHandler}
                cancelHandler={modalCancelHandler}
                closeOnSubmit={false}
                submitButtonLabel={multilingualLabels?.confirmTxt}
                showCancelButton={true}
                cancelOnOutsideClick={false}
                showCloseIcon={false}
            >
                {showConfirmationModal()}
            </CustomModal>
        </div>
    )
}

const showConfirmationModal = () => {
    return (
        <div className="deselect-confirmation-language-modal">
            <p>{multilingualLabels?.prefillDataConfirmation}</p>
        </div>
    )
}
