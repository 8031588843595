import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import get from "lodash/get"
import { createFaq } from "./../APIs/action"
import TextArea from "../../common/TextArea/index"
import Input from "../../common/Input/index"
import Button from "../../common/Button/index"
import { checkAllLanguageHasValue, isMultiLingual } from "../../../utils/common"
import { getLanguageObject } from "../../common/LanguageCardList"
import { DEFAULT_SELECTED_LANGUAGE_CODE } from "../../ContentNav/api/constants"
import { isEmpty } from "lodash"
import LanguageDataConfirmationModal from "../../common/LanguageCardList/languageDataConfirmationModal"
import {
    BUTTON_CONSTANTS,
    ERROR_MESSAGES,
    PERMISSIONS,
    TEXT_CONSTANTS
} from "../../../utils/constants"
import { AuthWrapper } from "../../common/AuthWrapper"

class Listcomponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isEditfaq: false,
            FaqQuestion: "",
            FaqAnswer: "",
            isshowfaqQA: false,
            isShowfaqId: null,
            isDisable: true,
            emptyLangDataModal: false
        }
    }

    componentDidMount() {
        this.updateLanguageObject()
        this.setState({
            FaqQuestion: this.props.question,
            FaqAnswer: this.props.answer
        })
    }

    updateLanguageObject = async () => {
        this.languageObject = await getLanguageObject()
    }

    validate = () => {
        let flag = 1
        let showEmptyLangData = false
        if (isMultiLingual()) {
            const selectedCode = DEFAULT_SELECTED_LANGUAGE_CODE
            const { FaqQuestion, FaqAnswer } = this.state
            if (isEmpty(FaqQuestion[selectedCode]) || isEmpty(FaqAnswer[selectedCode])) {
                flag = 0
                toast.error(ERROR_MESSAGES.ENTER_DATA_IN_ENGLISH)
            }
            if (
                (flag && !checkAllLanguageHasValue(FaqQuestion, this.languageObject)) ||
                !checkAllLanguageHasValue(FaqAnswer, this.languageObject)
            ) {
                showEmptyLangData = true
                flag = 0
            }
        }
        return { flag, showEmptyLangData }
    }

    handlelistfaq = async (id) => {
        const { FaqQuestion, FaqAnswer } = this.state
        const { pageType, createFaq, name, faqId } = this.props
        this.setState({
            isDisable: true
        })
        let payload = {
            id: faqId,
            type: pageType,
            name: name,
            faqList: [
                {
                    id: id,
                    question: FaqQuestion,
                    answer: FaqAnswer
                }
            ]
        }

        const validate = this.validate()
        if (validate?.flag) {
            this.submitData(payload, createFaq)
        } else if (validate?.showEmptyLangData) {
            this.setState({
                emptyLangDataModal: true,
                finalPayload: payload
            })
        }
    }

    modalCancelHandler = () => {
        this.setState({
            emptyLangDataModal: false
        })
    }

    submitData = async (payload, createFaq) => {
        this.modalCancelHandler()
        const response = await createFaq(payload)
        if (response && response.status) {
            toast.success(get(response, "message"))
            this.setState(
                {
                    isEditfaq: false,
                    isshowfaqQA: false
                },
                () => this.props.loadHandler()
            )
        }
    }

    cancelFaqQA = () => {
        this.setState({
            isEditfaq: false,
            isshowfaqQA: false,
            FaqQuestion: this.props.question,
            FaqAnswer: this.props.answer,
            isDisable: true
        })
    }

    editfaqQA = (id) => {
        this.showfaqQA(id)
        this.setState({
            isEditfaq: true
        })
    }

    showfaqQA = (id) => {
        id
            ? this.setState({
                  isshowfaqQA: true,

                  isShowfaqId: id
              })
            : this.setState({
                  isshowfaqQA: false,
                  isEditfaq: false,
                  isShowfaqId: null
              })
    }

    handleChange = (name, value, id) => {
        if (isMultiLingual()) {
            const { selectedLanguage } = this.props
            var multilingualValue = this.state[name]
            multilingualValue[selectedLanguage.code] = value
        }
        this.setState({
            [name]: !isMultiLingual() ? value : multilingualValue,
            isDisable: false
        })
    }

    render() {
        const { id, deletefaqQA, provided, selectedLanguage, createFaq } = this.props
        const {
            isEditfaq,
            isShowfaqId,
            isshowfaqQA,
            FaqQuestion,
            FaqAnswer,
            isDisable,
            emptyLangDataModal,
            finalPayload
        } = this.state

        return (
            <li
                className="faqlist-item"
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}>
                <div className="faqline">
                    <span className="menu-icon"></span>
                    <span className="menu-icon"></span>
                    <span className="menu-icon"></span>
                </div>

                <div className="box-shadow">
                    <div className="faq-header ">
                        <p className="faqElement">
                            <p className="faqQuestionHeading">
                                {TEXT_CONSTANTS?.QUESTION}{" "}
                                {isEditfaq ? <span className="listMandatory">*</span> : ""}
                            </p>
                            {!isEditfaq ? (
                                <p className="faqQuestion">
                                    {!isMultiLingual()
                                        ? FaqQuestion
                                        : FaqQuestion[selectedLanguage.code]
                                        ? FaqQuestion[selectedLanguage.code]
                                        : ""}
                                </p>
                            ) : (
                                <Input
                                    type="text"
                                    name="FaqQuestion"
                                    changeHandler={(name, value) =>
                                        this.handleChange(name, value, id)
                                    }
                                    value={
                                        !isMultiLingual()
                                            ? FaqQuestion
                                            : FaqQuestion[selectedLanguage.code]
                                            ? FaqQuestion[selectedLanguage.code]
                                            : ""
                                    }
                                />
                            )}
                        </p>
                        <p className="faq-icons">
                            {isEditfaq === true ? (
                                ""
                            ) : (
                                <>
                                    {" "}
                                    <AuthWrapper
                                        permissions={[
                                            PERMISSIONS?.ENGAGE_UX_MANAGER_CUSTOM_PAGES_EDIT
                                        ]}>
                                        <i
                                            className=" toolsBtn icon-edit"
                                            onClick={() => this.editfaqQA(id)}></i>
                                        <i
                                            className=" toolsBtn icon-del"
                                            onClick={() => deletefaqQA(id)}></i>
                                    </AuthWrapper>
                                    {isshowfaqQA && isShowfaqId === id ? (
                                        <i
                                            className="icon-arrow-up"
                                            onClick={() => this.showfaqQA(null)}></i>
                                    ) : (
                                        <i
                                            className="icon-arrow-down1"
                                            onClick={() => this.showfaqQA(id)}></i>
                                    )}
                                </>
                            )}
                        </p>
                    </div>

                    {isshowfaqQA && isShowfaqId === id && (
                        <div className="faqans">
                            {!isEditfaq ? (
                                <p>
                                    {" "}
                                    {!isMultiLingual()
                                        ? FaqAnswer
                                        : FaqAnswer[selectedLanguage.code]
                                        ? FaqAnswer[selectedLanguage.code]
                                        : ""}
                                </p>
                            ) : (
                                <>
                                    <p className="faqAnsHeading">
                                        {TEXT_CONSTANTS?.ANSWER}{" "}
                                        <span className="listMandatory">*</span>
                                    </p>
                                    <TextArea
                                        rows={4}
                                        cols={5}
                                        value={
                                            !isMultiLingual()
                                                ? FaqAnswer
                                                : FaqAnswer[selectedLanguage.code]
                                                ? FaqAnswer[selectedLanguage.code]
                                                : ""
                                        }
                                        name="FaqAnswer"
                                        changeHandler={(name, value) =>
                                            this.handleChange(name, value, id)
                                        }
                                    />
                                </>
                            )}
                        </div>
                    )}

                    {isshowfaqQA && isShowfaqId === id && (
                        <div className="listing-btn">
                            {!isEditfaq ? (
                                ""
                            ) : (
                                <>
                                    <Button
                                        bValue={BUTTON_CONSTANTS?.SAVE_CHANGES?.toUpperCase()}
                                        cName="btn primary-btn"
                                        clickHandler={() => this.handlelistfaq(id)}
                                        disabled={isDisable}
                                    />

                                    <Button
                                        bValue={BUTTON_CONSTANTS?.CANCEL?.toUpperCase()}
                                        clickHandler={() => this.cancelFaqQA(id)}
                                        cName="btn cancel-btn"
                                    />
                                </>
                            )}
                        </div>
                    )}
                </div>
                {emptyLangDataModal && (
                    <LanguageDataConfirmationModal
                        isModalOpen={emptyLangDataModal}
                        submitHandler={() => this.submitData(finalPayload, createFaq)}
                        cancelHandler={this.modalCancelHandler}></LanguageDataConfirmationModal>
                )}
            </li>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                createFaq
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Listcomponent)
