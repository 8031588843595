import { shallowEqual, useSelector } from "react-redux"
import { checkIfUserHasAccess, getUserPermissionsList } from "../../../utils/users"
import { useMemo } from "react"

// Wrapper component which show and hides based on permissions
export function AuthWrapper({ permissions, children, showErrorMessage = false }) {
    const userRoles = useSelector((state) => state?.auth?.user?.roles, shallowEqual)
    const userPermissions = getUserPermissionsList(userRoles || [])

    const isUserAuthorized = useMemo(
        () => checkIfUserHasAccess(permissions),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [userPermissions]
    )

    if (!isUserAuthorized) {
        return showErrorMessage ? "UnAuthorized Access" : null
    }

    return children
}
