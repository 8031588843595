import { ACTION } from "./constants"
import SubscriptionServices from "./service"
import { toast } from "react-toastify"

export const createPlans = (params) => {
    return (dispatch) => {
        return SubscriptionServices.createPlans(params)
            .then((response) => {
                dispatch({
                    type: ACTION.CREATE_PLAN,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                toast.error(error.message)
            })
    }
}

export const updatePlans = (id, params) => {
    return (dispatch) => {
        return SubscriptionServices.updatePlans(id, params)
            .then((response) => {
                dispatch({
                    type: ACTION.UPDATE_PLAN,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in updating plans ", error)
            })
    }
}

export const editPlans = (params) => {
    return (dispatch) => {
        return SubscriptionServices.createPlans(params)
            .then((response) => {
                dispatch({
                    type: ACTION.CREATE_PLAN,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in creating plans ", error)
            })
    }
}

export const fetchCreatedPlans = (params) => {
    return (dispatch) => {
        return SubscriptionServices.fetchCreatedPlans(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_CREATED_PLAN,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in fetching created plans ", error)
            })
    }
}

export const editCreatePlan = (params) => {
    return (dispatch) => {
        return SubscriptionServices.editCreatePlan(params)
            .then((response) => {
                dispatch({
                    type: ACTION.EDIT_CREATE_PLAN,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in fetching created plans ", error)
            })
    }
}

export const softDeleteCreatePLan = (params) => {
    return (dispatch) => {
        return SubscriptionServices.softDeleteCreatePLan(params)
            .then((response) => {
                dispatch({
                    type: ACTION.SOFTDELETE_CREATE_PLAN,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in delete created plans ", error)
            })
    }
}

export const activateDeactivatePlans = (params) => {
    console.log("IN AcTION")
    return (dispatch) => {
        return SubscriptionServices.activateDeactivatePlans(params)
            .then((response) => {
                dispatch({
                    type: ACTION.ACTIVATE_DEACTIVATE_PLAN,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log(
                    "Got error in activate/deactivate created plans ",
                    error
                )
            })
    }
}

export const fetchSubscriptionCustomer = (params) => {
    return (dispatch) => {
        return SubscriptionServices.fetchSubscriptionCustomer(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_SUBSCRIPTION_CUSTOMER,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in fetching customer ", error)
            })
    }
}

export const fetchInvoiceDetail = (params) => {
    return (dispatch) => {
        return SubscriptionServices.fetchInvoiceDetail(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_INVOICE_DETAIL,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in fetching invoice detail ", error)
            })
    }
}
