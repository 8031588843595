import { TEXT_CONSTANTS } from "../../../utils/constants"
import Checkbox from "../../common/Checkbox"
import React from "react"

const FeaturedIn = (props) => {
    const { platformList, checkboxHandleChange, selectedPlatform } = props
    return (
        <div className="featured-container">
            <label htmlFor="featuredIn">
                {TEXT_CONSTANTS?.FEATURED_IN} <span className="requiredIndicator">*</span>
            </label>
            <div className={`featuredIn`} id="featuredIn">
                {platformList?.map((item) => (
                    <Checkbox
                        cName="featured-checkBox"
                        labelText={item.name?.replace(/[_-]/g, " ")}
                        id={item.id}
                        name={item.name}
                        isChecked={selectedPlatform.includes(item.id)}
                        changeHandler={checkboxHandleChange}
                        // disabled={!this.canUpdateStatus}
                    />
                ))}
            </div>
        </div>
    )
}
export default FeaturedIn
