import React, { Component } from "react"
import get from "lodash/get"
import Checkbox from "../../../common/Checkbox"
import NoImgAvailable from "../../../../assets/images/no_image_placeholder.svg"
import { imgPathPrefix } from "../../APIs/constant"
import { getContentData } from "../../../../utils/common"

class ProductCard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            highResImageLoaded: false
        }
    }
    render() {
        const { item, index, selectedProductId, handleCheckProduct } = this.props
        const { highResImageLoaded } = this.state
        const coverImage = getContentData(get(item?.content, "cover_image"))
        return (
            <li
                key={item.id}
                className={`productCard ${selectedProductId === index && "selected"}`}
            >
                <div className="product-image">
                    <img
                        onLoad={() => {
                            this.setState({ highResImageLoaded: true })
                        }}
                        src={coverImage ? `${imgPathPrefix}${coverImage}` : `${NoImgAvailable}`}
                        className={coverImage ? `` : `placeholder`}
                        alt="banner"
                        onError={(e) => {
                            e.target.onerror = null
                            e.target.src = `${NoImgAvailable}`
                            e.target.className = `no-img-available`
                        }}
                    />
                    <img
                        {...(highResImageLoaded && { style: { opacity: "0" } })}
                        alt=""
                        className="overlayStyles"
                        src=""
                    />
                    <div className="overlay" onClick={(e) => handleCheckProduct(index)}>
                        <Checkbox
                            cName="select-checkBox"
                            name={item.content.movie_title}
                            id={item.id}
                            changeHandler={(e) => handleCheckProduct(index)}
                            isChecked={selectedProductId !== null && selectedProductId === index}
                        />
                    </div>
                </div>
                <div className="product-detail">
                    <span>{getContentData(get(item?.content, "movie_title"))}</span>
                </div>
            </li>
        )
    }
}

export default ProductCard
