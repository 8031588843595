export const ACTION = {
    CREATE_PLAN: "CREATE_PLAN",
    UPDATE_PLAN: "UPDATE_PLAN",
    FETCH_CREATED_PLAN: "FETCH_CREATED_PLAN",
    FETCH_SUBSCRIPTION_CUSTOMER: "FETCH_SUBSCRIPTION_CUSTOMER",
    FETCH_INVOICE_DETAIL: "FETCH_INVOICE_DETAIL",
    SOFTDELETE_CREATE_PLAN: "SOFTDELETE_CREATE_PLAN",
    EDIT_CREATE_PLAN: "EDIT_CREATE_PLAN",
    ACTIVATE_DEACTIVATE_PLAN: "ACTIVATE_DEACTIVATE_PLAN"
}

export const billingPeriodlist = [
    "DAILY",
    "WEEKLY",
    "BIWEEKLY",
    "THIRTY_DAYS",
    "SIXTY_DAYS",
    "NINETY_DAYS",
    "MONTHLY",
    "BIMESTRIAL",
    "QUARTERLY",
    "TRIANNUAL",
    " BIANNUAL",
    "ANNUAL",
    "BIENNIAL",
    "NO_BILLING_PERIOD"
]

export const billingPeriodlists = [
    { id: "DAILY", name: "DAILY" },
    { id: "WEEKLY", name: "WEEKLY" },
    { id: "THIRTY_DAYS", name: "MONTHLY" },
    { id: "QUARTERLY", name: "QUARTERLY" },
    { id: "ANNUAL", name: "ANNUAL" },
    { id: "NO_BILLING_PERIOD", name: "NO BILLING PERIOD" }
]

export const timeUnitlist = [
    { id: "DAYS", name: "DAYS" },
    { id: "WEEKS", name: "WEEKS" },
    { id: "MONTHS", name: "MONTHS" },
    { id: "YEARS", name: "YEARS" },
    { id: "UNLIMITED", name: "UNLIMITED" }
]

export const billingPeriodlistd = [
    { name: "DAILY", value: "DAILY" },
    { name: "WEEKLY", value: "WEEKLY" },
    { name: "NO BILLING PERIOD", value: "NO_BILLING_PERIOD" }
]

//export const timeUnitlist = ["DAYS", "WEEKS","MONTHS", "YEARS","UNLIMITED"]
export const currencylist = [
    { id: "INR", name: "INR" },
    { id: "USD", name: "USD" }
]

export const SUBSCRIPTION_PAGE_TABLE_HEADING = [
    "Sr",
    "invoice id ",
    "invoice date",
    "plan",
    "amount",
    "status",
    "invoice"
]
export const CREATE_PLAN_TABLE_HEADING = [
    "Sr",
    "product name ",
    "plan name",
    "amount",
    "billing period",
    "free trial",
    "trial time unit",
    "action"
]

export const SUBSCRIPTION_TABLE_ROWS = [
    {
        id: 1,
        invoice_id: "VR2345678",
        invoice_date: " 01-01-2020",
        plan: "Bronze(Yearly)",
        amount: "$100",
        status: "Successful"
    },
    {
        id: 2,
        invoice_id: "VR2345678",
        invoice_date: " 01-01-2020",
        plan: "Bronze(Yearly)",
        amount: "$100",
        status: "Failed"
    },
    {
        id: 3,
        invoice_id: "VR2345678",
        invoice_date: " 01-01-2020",
        plan: "Bronze(Yearly)",
        amount: "$100",
        status: "Successful"
    },
    {
        id: 4,
        invoice_id: "VR2345678",
        invoice_date: " 01-01-2020",
        plan: "Bronze(Yearly)",
        amount: "$100",
        status: "Failed"
    }
]

// ***************

export const SUBSCRIBER_PAGE_TABLE_HEADING = [
    "Sr",
    "name ",
    "email",
    "phone",
    "expired date",
    "currency",
    "action"
]

export const SUBSCRIBER_TABLE_ROWS = [
    {
        id: 1,
        name: "John",
        email: " john@gmail.com",
        plan: "Bronze(Yearly)",
        expired: "01-01-2020",
        status: "successful"
    },
    {
        id: 2,
        name: "John",
        email: " john@gmail.com",
        plan: "Bronze(Yearly)",
        expired: "01-01-2020",
        status: "failed"
    },
    {
        id: 3,
        name: "John",
        email: " john@gmail.com",
        plan: "Bronze(Yearly)",
        expired: "01-01-2020",
        status: "successful"
    },
    {
        id: 4,
        name: "John",
        email: " john@gmail.com",
        plan: "Bronze(Yearly)",
        expired: "01-01-2020",
        status: "failed"
    }
]
