export const ACTION = {}

export const TV_DEVICES = [
    "APPLE_TV",
    "LG_HTML_TV",
    "SMART_TV",
    "ANDROID_TV",
    "DONGLE",
    "SET_TOP_BOX",
    "SAMSUNG_HTML_TV"
]
export const OTHER_DEVICE_LIST = {
    ANDROID: "ANDROID",
    IOS: "IOS",
    WEB: "WEB"
}

export const backgroundOption = [
    { id: "color", name: "Color" },
    { id: "image", name: "Image" }
]
export const logoOptions = [
    { id: "png", name: "png" },
    { id: "json", name: "lottie/json" }
]

export const tabNames = [
    {
        id: "1",
        name: "Basic"
    },
    // {
    //     id: "2",
    //     name: "App Splash",
    // },
    {
        id: "3",
        name: "Force/Recommended"
    }
]

export const accpetedFiles = ["image/png", "image/jpeg", "application/json"]
