import React, { useEffect } from "react"
import Accordion from "../../common/Accordion"
import { BUTTON_CONSTANTS, CHECK_MARK, TEXT_CONSTANTS } from "../../../utils/constants"
import Button from "../../common/Button"
import { useState } from "react"
import deleteIcon from "../../../assets/images/deleteIcon-DM.svg"

import "./SavedFilterOptions.scss"
import CustomModal from "../../common/Modal"

export default function SavedFilterOptions({
    getSelectedSavedFilterData,
    allFiltersData,
    contentTabType,
    deleteSavedFilter
}) {
    const [editMode, setEditMode] = useState(false)
    const [filterData, setFilterData] = useState({
        savedFilterDataList: allFiltersData?.savedFilterDataList,
        filterName: TEXT_CONSTANTS?.SELECT_SAVED_FILTERS,
        selectedData: null,
        deletedItem: null
    })
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

    // Reset filter name on tab change
    useEffect(() => {
        setFilterData((prevState) => {
            return {
                ...prevState,
                filterName: TEXT_CONSTANTS?.SELECT_SAVED_FILTERS,
                selectedData: null
            }
        })
    }, [contentTabType])

    useEffect(() => {
        setFilterData((prevState) => {
            return {
                ...prevState,
                savedFilterDataList: allFiltersData?.savedFilterDataList
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allFiltersData?.savedFilterDataList])

    const changeFilterStateHandler = (val) => {
        setEditMode(val)
    }

    const changeSelectedFilterHandler = (item) => {
        setFilterData((prevState) => {
            return {
                ...prevState,
                filterName: item?.name,
                selectedData: item
            }
        })
        getSelectedSavedFilterData(item)
    }

    const delteSelectedSavedFilter = (deletedItem) => {
        setIsDeleteModalOpen(true)

        setFilterData((prevState) => {
            return {
                ...prevState,
                deletedItem: deletedItem
            }
        })
    }

    const submitDeleteModalHandler = async () => {
        const updatedList = filterData?.savedFilterDataList?.savedFilters?.filter(
            (item) => item?.id !== filterData?.deletedItem?.id
        )
        setIsDeleteModalOpen(false)
        const res = await deleteSavedFilter(filterData?.deletedItem?.id)
        res &&
            setFilterData((prevState) => {
                return {
                    ...prevState,
                    savedFilterDataList: {
                        ...prevState.savedFilterDataList,
                        savedFilters: updatedList
                    },
                    filterName: TEXT_CONSTANTS?.SELECT_SAVED_FILTERS,
                    selectedData: null,
                    deletedItem: null
                }
            })
    }

    const ContentList = () => {
        return (
            <div className="saved-filter-content">
                <div className="saved-filter-list">
                    {filterData?.savedFilterDataList?.savedFilters?.map((item) => {
                        const selected = item?.id === filterData?.selectedData?.id
                        return (
                            <div className="single-list-item" key={item?.id}>
                                {!editMode && (
                                    <span className="check-sign">{selected && CHECK_MARK}</span>
                                )}
                                <span
                                    className={`filter-name ${selected ? "selected-color" : ""}`}
                                    type="button"
                                    onClick={() => changeSelectedFilterHandler(item)}
                                >
                                    {item?.name}
                                </span>
                                {editMode && selected ? (
                                    <img
                                        src={deleteIcon}
                                        className={`delete-icon ${
                                            selected ? "selected-color" : "selected-color"
                                        }`}
                                        alt="delete-logo"
                                        onClick={() => delteSelectedSavedFilter(item)}
                                    />
                                ) : (
                                    <span></span>
                                )}
                            </div>
                        )
                    })}
                </div>
                {!editMode && (
                    <Button
                        cName={"edit-save-filter"}
                        bType={"submit"}
                        bValue={BUTTON_CONSTANTS?.EDIT_SAVED_FILTER}
                        clickHandler={() => {
                            changeFilterStateHandler(true)
                        }}
                    ></Button>
                )}
                {editMode && (
                    <Button
                        cName={"edit-save-filter"}
                        bType={"submit"}
                        bValue={BUTTON_CONSTANTS?.CANCEL}
                        clickHandler={() => {
                            changeFilterStateHandler(false)
                        }}
                    ></Button>
                )}
            </div>
        )
    }

    const accordionItems = [
        {
            title: <p className="saved-filter-name-styling">{filterData?.filterName}</p>,
            content: ContentList()
        }
    ]

    return (
        <>
            <Accordion items={accordionItems} accordionContainerClass="saved-filter-container" />
            <CustomModal
                showModal={isDeleteModalOpen}
                submitHandler={submitDeleteModalHandler}
                cancelHandler={() => {
                    setIsDeleteModalOpen(false)
                }}
                closeOnSubmit={false}
                submitButtonLabel={BUTTON_CONSTANTS?.YES}
                showCancelButton={true}
                cancelOnOutsideClick={false}
                showCloseIcon={false}
                submitBtnClass={"confirmation-submit-btn"}
                cancelBtnClass={"confirmation-cancel-btn"}
            >
                <div className="delete-modal-container">
                    <h3>{TEXT_CONSTANTS?.DELETE_SAVE_FILTER}</h3>
                    <h4>{filterData?.deletedItem?.name}</h4>
                    <p>{TEXT_CONSTANTS?.DELETE_CONFIRMATION_MSG}</p>
                </div>
            </CustomModal>
        </>
    )
}
