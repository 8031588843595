import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import get from "lodash/get"
import { toast } from "react-toastify"
import MultiSelect from "./multiselect"

import { fetchContent, saveReels, searchEventReels } from "../api/action"
import "./style.scss"
import Input from "../../common/Input"
import Button from "../../common/Button"
import VideoModal from "../../common/VideoModal"
import ImageUplode from "../../Movies/addMovie/imageUplode"
import { uploadImage } from "../../Movies/api/action"
import { saveContentNav } from "../api/action"
import { DEFAULT_SELECTED_LANGUAGE_CODE, imgPathPrefix } from "../api/constants"
import {
    checkAllLanguageHasValue,
    formatValueAccToMutlilingualAPI,
    getMultiLingualValue,
    isMultiLingual
} from "../../../utils/common"
import { isEmpty } from "lodash"
import LanguageCardList, { getLanguageObject } from "../../common/LanguageCardList"
import LanguageDataConfirmationModal from "../../common/LanguageCardList/languageDataConfirmationModal"
import { BUTTON_CONSTANTS, PERMISSIONS } from "../../../utils/constants"
import { checkIfUserHasAccess } from "../../../utils/users"

const SelectContainer = (props) => {
    return <div onKeyUp={props.inputChange}>{props.children}</div>
}

class Reels extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isFilled: false,
            value: null,
            payload: {},
            description: "",
            reel_title: "",
            playUrl: "",
            showModal: false,
            isLoading: false,
            emptyLangDataModal: false,
            selectedLanguage: {
                name: "English",
                code: "en"
            },
            canUpdateStatus: checkIfUserHasAccess([PERMISSIONS.CONTENT_EDIT])
        }
        this.isMultiLingualAllowed = isMultiLingual()
    }

    componentDidMount() {
        const { selectedData } = this.props
        if (!isEmpty(selectedData)) {
            let selectedOptionArr = []
            if (selectedData?.content) selectedOptionArr.push(selectedData?.content)

            this.setState({
                description: selectedData?.description,
                reel_title: selectedData?.reel_title,
                playUrl: selectedData?.play_url,
                uploadReel: selectedData?.reel_poster_image,
                selectedOptions: selectedOptionArr,
                startTime: selectedData?.start_time
            })
        }
        this.updateLanguageObject()
    }

    updateLanguageObject = async () => {
        this.languageObject = await getLanguageObject()
    }

    handleChange = (id, selectedOptions) => {
        if (selectedOptions && selectedOptions.length <= 1) {
            this.setState({
                selectedOptions: selectedOptions
            })
        }
    }

    inputChange = async (event) => {
        const { searchEventReels } = this.props
        let request = {
            search: event.target.value,
            limit: 100,
            offset: 0,
            published: true,
            contentType: "MOVIES"
        }
        let response = await searchEventReels(request)

        let data = response?.data
        this.setState({
            filterOptions: data
        })
    }

    validateFields = (updatePayload) => {
        const { formData } = this.props
        let error = false,
            data = []

        // Required fields must not be empty
        const requiredFields = [
            { key: "reel_title", title: "Reel Title" },
            { key: "description", title: "Description" },
            { key: "play_url", title: "Play URL" },
            { key: "reel_poster_image", title: "Poster Image" },
            { key: "start_time", title: "Start Time" }
        ]
        for (const item of requiredFields) {
            const { key, title } = item
            let value, errorMsg
            if (formData?.multilingualKeys?.includes(key)) {
                value = updatePayload[key]?.values?.[DEFAULT_SELECTED_LANGUAGE_CODE]
                errorMsg = `${title} is required in english language.`
            } else {
                value = updatePayload[key]
                errorMsg = `${title} is required.`
            }
            if (isEmpty(value)) {
                error = true
                toast.error(errorMsg)
                break
            }
        }

        if (!error) {
            data = formData?.multilingualKeys?.map((dt) => {
                let type = typeof updatePayload[dt]
                switch (type) {
                    case "object": {
                        if (!Array.isArray(updatePayload[dt])) {
                            return checkAllLanguageHasValue(
                                updatePayload[dt]?.values,
                                this.languageObject
                            )
                        }
                        return true
                    }
                    default:
                        return true
                }
            })
        }

        return {
            error: error,
            isFieldsEmpty: data
        }
    }

    handleSave = async () => {
        const { description, reel_title, playUrl, uploadReel, startTime, selectedOptions } =
            this.state
        const { isEdit, editId } = this.props
        let updatePayload = {
            description,
            reel_title: reel_title,
            play_url: playUrl,
            reel_poster_image: uploadReel,
            start_time: startTime
        }
        if (isEdit) {
            updatePayload["id"] = editId
        }
        updatePayload["content"] = selectedOptions?.[0]

        // let requiredFields = formData && formData.dataFormat.required;

        this.setState({
            isFilled: true
        })

        const data = this.validateFields(updatePayload)

        if (data?.error) {
            return
        }

        if (data?.isFieldsEmpty?.includes(false)) {
            this.setState({
                emptyLangDataModal: true,
                finalPayload: updatePayload,
                isFilled: false
            })
        } else {
            await this.submitData(updatePayload)
        }
    }

    modalCancelHandler = () => {
        this.setState({
            emptyLangDataModal: false
        })
    }

    submitData = async (updatePayload) => {
        const { formData } = this.props
        this.modalCancelHandler()
        const response = await this.props.saveReels(updatePayload, formData.id)

        if (get(response, "status")) {
            toast.success(response?.message)
            this.props.handleAddClick()
            this.clearFields()
        } else {
            this.setState({
                isFilled: false
            })
            toast.error(response?.message)
        }
    }

    changeHandler = (name, value) => {
        let updatedMultilingualValue = value
        const { formData } = this.props
        const isMulti = this.isMultiLingualAllowed && formData?.multilingualKeys?.includes(name)
        if (isMulti) {
            const { selectedLanguage } = this.state
            updatedMultilingualValue = { ...this.state?.[name] }
            updatedMultilingualValue[selectedLanguage.code] = value
            updatedMultilingualValue = formatValueAccToMutlilingualAPI(
                updatedMultilingualValue,
                selectedLanguage?.code
            )
        }
        this.setState(
            {
                [name]: updatedMultilingualValue
            },
            () => {}
        )
    }

    imgUpload = async (data, id, name) => {
        this.setState({
            isLoading: true
        })
        const response = await this.props.uploadImage(data)
        if (response && response.status) {
            let imgUrl = response.data.path
            this.changeHandler(name, imgUrl)
            this.setState({
                isLoading: false
            })
        } else {
        }
        this.setState({
            isLoading: false
        })
    }

    imgRemove = (name, item) => {
        this.changeHandler(name, "", false, item)
    }

    getTitle = (title) => {
        return title.replace("_", " ")
    }

    clearFields = () => {
        this.setState({
            description: "",
            reel_title: "",
            playUrl: "",
            uploadReel: ""
        })
    }

    playContent = () => {
        this.setState({
            showModal: true
        })
    }
    onCloseClick = () => {
        this.setState({
            showModal: false
        })
    }

    onLanguageChangeListener = (value) => {
        this.setState({
            selectedLanguage: value
        })
    }

    render() {
        const { item, handleAddClick, formData, isUploadingImage } = this.props
        const {
            description,
            reel_title,
            playUrl,
            uploadReel,
            showModal,
            startTime,
            isLoading,
            selectedLanguage,
            finalPayload,
            emptyLangDataModal,
            canUpdateStatus
        } = this.state
        let data =
            formData &&
            formData.htmlElements &&
            formData.htmlElements.content &&
            formData.htmlElements.content.length &&
            formData.htmlElements.content
        const languageCode = selectedLanguage?.code

        return (
            <div className="reels-main-content vd-addMovie">
                <div className="reels-multilingual">
                    {this.isMultiLingualAllowed && (
                        <LanguageCardList handleChange={this.onLanguageChangeListener} />
                    )}
                </div>
                <div className="form-content">
                    {false && <div className="loader">{/*   <img src={Loader}></img> */}</div>}
                    {showModal && (
                        <VideoModal
                            onCloseClick={this.onCloseClick}
                            url={`${imgPathPrefix}${uploadReel}`}
                        />
                    )}
                    {emptyLangDataModal && (
                        <LanguageDataConfirmationModal
                            isModalOpen={emptyLangDataModal}
                            submitHandler={() => this.submitData(finalPayload)}
                            cancelHandler={this.modalCancelHandler}
                        ></LanguageDataConfirmationModal>
                    )}
                    <div className="banner-image-container">
                        <div className={"image-container"}>
                            <ImageUplode
                                key={"Mobile"}
                                name={"uploadReel"}
                                id={"Mobile"}
                                isLoading={isLoading}
                                placeholderStyle="placeholderStyle"
                                imageSizeStyle="imageSizeStyle"
                                label={"UPLOAD REEL"}
                                isPlay={true}
                                value={uploadReel ? `${imgPathPrefix}${uploadReel}` : null}
                                uploadImage={this.imgUpload}
                                removeImg={(name) => this.imgRemove(name, item)}
                                imageSizeText=" "
                                disabled={!canUpdateStatus}
                            />
                        </div>
                        <div className="input-fields">
                            <SelectContainer inputChange={this.inputChange}>
                                <MultiSelect
                                    options={this.state.filterOptions}
                                    selectedOptions={this.state.selectedOptions}
                                    placeholder={"Search Content"}
                                    name="genre"
                                    optionName={"title"}
                                    label="Content"
                                    changeHandler={(name, value) => this.handleChange(name, value)}
                                    isDisabled={!canUpdateStatus}
                                />
                            </SelectContainer>
                            <Input
                                type={"text"}
                                labelText={this.getTitle(data[1]?.title)}
                                name={"reel_title"}
                                required={true}
                                value={getMultiLingualValue(
                                    this.isMultiLingualAllowed,
                                    reel_title,
                                    languageCode
                                )}
                                changeHandler={(name, value) => this.changeHandler(name, value)}
                                disabled={!canUpdateStatus}
                            />

                            <Input
                                type={"text"}
                                labelText={this.getTitle(data[2]?.title)}
                                name={"description"}
                                value={getMultiLingualValue(
                                    this.isMultiLingualAllowed,
                                    description,
                                    languageCode
                                )}
                                required={true}
                                changeHandler={(name, value) => this.changeHandler(name, value)}
                                disabled={!canUpdateStatus}
                            />

                            <Input
                                type={"text"}
                                labelText={this.getTitle(data[3]?.title)}
                                name={"playUrl"}
                                value={playUrl}
                                required={true}
                                changeHandler={(name, value) => this.changeHandler(name, value)}
                                disabled={!canUpdateStatus}
                            />
                            <Input
                                type={"text"}
                                labelText={"Start Time"}
                                name={"startTime"}
                                value={startTime}
                                required={true}
                                changeHandler={(name, value) => this.changeHandler(name, value)}
                                inputType="Integer"
                                maxLength={5}
                                disabled={!canUpdateStatus}
                            />
                        </div>
                    </div>

                    <div className="save-and-next">
                        {canUpdateStatus && (
                            <Button
                                cName={"btn primary-btn"}
                                bType={"submit"}
                                bValue={BUTTON_CONSTANTS?.SUBMIT?.toUpperCase()}
                                bIcon={"icon-left-arrow"}
                                clickHandler={this.handleSave}
                                disabled={isUploadingImage}
                            />
                        )}
                        <Button
                            cName={"btn cancel-btn"}
                            bType={"button"}
                            bValue={BUTTON_CONSTANTS?.CANCEL?.toUpperCase()}
                            clickHandler={handleAddClick}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isUploadingImage: state?.loader?.isLoading //Disable the submit button whenever user is uploading images
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                fetchContent,
                uploadImage,
                saveContentNav,
                searchEventReels,
                saveReels
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Reels)
