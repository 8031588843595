import React, { useMemo, useState } from "react"
import {
    CARD_SIZE_LIST,
    ORIENTATION_MENU,
    orientationsOptions,
    RAIL_CATEGORY,
    RAIL_TYPE_CONSTANT,
    shuffleOption
} from "../api/constant"
import { TEXT_CONSTANTS } from "../../../utils/constants"
import Select from "../../common/SelectBox"
import CustomFilter from "./Filter"
import PreviewSelected from "./previewSelectedManul"
import "./previewSelectedSystem/style.scss"
import CustomHeroBanner from "./CustomHeroBanner"
import SchedulePublishRail from "./SchedulePublishRail"

const ContentPreview = (props) => {
    const {
        orientations,
        selectedMovieList,
        autoShuffle,
        handleOnSave,
        toggleIsClick,
        isClick,
        handleAutoUpdateFilter,
        onChangeFilter,
        findDefaultOption,
        // real CTA props for preview begins here
        changePosition,
        handleCustomBannerEdit,
        removeMovie,
        onDragEnd,
        handleMovieUnpublishFromRail,
        isContentPublished,
        handleReorder,
        handleUpdateImage,
        handleChangeImageUpload,
        cardSize,
        railCategory,
        railType,
        railName,
        toggleCustomBannerModal,
        customHeroBanner,
        openDeleteModal
    } = props

    const renderPreviewOptions = useMemo(() => {
        let showPreviewOption = !!railType.id
        if (
            [
                RAIL_TYPE_CONSTANT.PROMOTION_BANNER,
                RAIL_TYPE_CONSTANT.HERO_BANNER,
                RAIL_TYPE_CONSTANT.CONTINUE_WATCHING,
                RAIL_TYPE_CONSTANT.ADS
            ].includes(railType.id)
        ) {
            showPreviewOption = false
        }
        return showPreviewOption
    }, [railType, railCategory])

    return (
        <div className="content-preview">
            {railCategory === RAIL_CATEGORY.NEW && (
                <label htmlFor="preview" className="label">
                    {TEXT_CONSTANTS?.CONTENT_ADDED} ( {selectedMovieList?.length} )
                </label>
            )}
            {renderPreviewOptions ? (
                <div className="content-preview-header">
                    <div className="content-preview-filter">
                        <Select
                            options={
                                railType.id === RAIL_TYPE_CONSTANT.FAVOURITE
                                    ? orientationsOptions.filter(
                                          (item) => item.id !== ORIENTATION_MENU.CIRCULAR
                                      )
                                    : orientationsOptions
                            }
                            cName={"filterDropdown orientation-select cs-Orientation"}
                            name={"orientations"}
                            value={findDefaultOption(orientations, orientationsOptions)}
                            changeHandler={onChangeFilter}
                            // isDisabled={!selectedMovieList.length}
                        />
                        <Select
                            options={CARD_SIZE_LIST}
                            cName={"filterDropdown orientation-select cs-Orientation"}
                            name={"cardSize"}
                            value={findDefaultOption(cardSize, CARD_SIZE_LIST)}
                            changeHandler={onChangeFilter}
                        />

                        {railCategory === RAIL_CATEGORY.NEW && (
                            <CustomFilter
                                id="shuffle-filter"
                                defaultValue="0"
                                filterList={shuffleOption}
                                autoShuffle={autoShuffle}
                                selectedMovieListLength={selectedMovieList.length}
                                handleAutoUpdateFilter={handleAutoUpdateFilter}
                                handleApplyApiCall={handleOnSave}
                                toggleIsClick={toggleIsClick}
                                isClick={isClick}
                            />
                        )}
                    </div>
                </div>
            ) : (
                <></>
            )}
            {railCategory === RAIL_CATEGORY.SYSTEM_GENERATED && (
                <div className="selected-container">
                    <div className="railName">
                        <h4>{railName}</h4>
                    </div>
                </div>
            )}
            <PreviewSelected
                movieList={selectedMovieList}
                changePosition={changePosition}
                handleCustomBannerEdit={handleCustomBannerEdit}
                orientations={orientations}
                onRemove={removeMovie}
                onDragEnd={onDragEnd}
                handleMovieUnpublishFromRail={handleMovieUnpublishFromRail}
                isContentPublished={isContentPublished}
                handleReorder={handleReorder}
                handleUpdateImage={handleUpdateImage}
                handleChangeImageUpload={handleChangeImageUpload}
                cardSize={cardSize}
                toggleCustomBannerModal={toggleCustomBannerModal}
                customHeroBanner={customHeroBanner}
                railType={railType}
                openDeleteModal={openDeleteModal}
            />
        </div>
    )
}

export default ContentPreview
