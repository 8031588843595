import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import get from "lodash/get"
import { isEmpty } from "lodash"
import Checkbox from "../common/Checkbox/index"
import Input from "../common/Input/index"
import Button from "../common/Button/index"
import TextArea from "../common/TextArea"
import LanguageCardList from "../common/LanguageCardList"
import { getLanguageObject } from "../common/LanguageCardList"

import { checkAllLanguageHasValue, isMultiLingual } from "../../utils/common"

import { fetchFormField, createFormPage, fetchCreatedForm } from "./APIs/action"

import "./style.scss"
import { DEFAULT_SELECTED_LANGUAGE_CODE } from "../ContentNav/api/constants"
import LanguageDataConfirmationModal from "../common/LanguageCardList/languageDataConfirmationModal"
import { BUTTON_CONSTANTS, PERMISSIONS, REGEX, TEXT_CONSTANTS } from "../../utils/constants"
import { checkIfUserHasAccess } from "../../utils/users"

class CreateForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isReset: false,
            ListField: [],
            formFieldList: [], // form create
            formFieldMaster: [], // fields
            name: "",
            emailId: "",
            selectedLanguage: {
                name: "English",
                code: "en"
            },
            isDisable: true,
            err: {
                nameErr: "",
                emailIdErr: ""
            },
            emptyLangDataModal: false
        }
        this.canUpdateStatus = checkIfUserHasAccess([
            PERMISSIONS.ENGAGE_UX_MANAGER_CUSTOM_PAGES_EDIT
        ])
    }

    componentDidMount() {
        this.updateLanguageObject()
        this.loadHandler()
        this.formLoadHandler()
    }

    updateLanguageObject = async () => {
        this.languageObject = await getLanguageObject()
    }

    loadHandler = async () => {
        const { fetchFormField } = this.props
        const resp = await fetchFormField()
        if (resp && resp.status) {
            this.setState({
                ListField: resp.data
            })
        }
    }

    formLoadHandler = async () => {
        const { isEdit, editableData, fetchCreatedForm } = this.props
        if (isEdit) {
            const response = await fetchCreatedForm(editableData.id)
            if (response && response.status) {
                this.setState({
                    name: response.data.name,
                    emailId: response.data.emailId,
                    formFieldList: response.data.formFieldList
                })
            }
            this.setState({
                isDisable: false
            })
        }
    }

    handleChange = (name, value) => {
        var updatedMultilingualValue = value
        if (isMultiLingual() && name === "name") {
            const { selectedLanguage } = this.state
            updatedMultilingualValue = !isEmpty(this.state[name]) ? this.state[name] : {}
            updatedMultilingualValue[selectedLanguage.code] = value
        }
        this.setState({
            [name]: !isMultiLingual() ? value : updatedMultilingualValue,
            isDisable: false
        })
    }
    checkForEmpty = () => {
        const { name, emailId } = this.state
        let count = 3
        if (name.trim().length) {
            count = count - 1
        }
        if (emailId.trim().length) {
            count = count - 1
        }

        if (!count) {
            this.setState({
                isDisable: false
            })
        }
    }
    validate = (name, emailId) => {
        let flag = 1
        const err = {}

        if (
            isEmpty(name) ||
            isEmpty(name?.[DEFAULT_SELECTED_LANGUAGE_CODE]) ||
            !name?.hasOwnProperty([DEFAULT_SELECTED_LANGUAGE_CODE]) ||
            name?.[DEFAULT_SELECTED_LANGUAGE_CODE] === null
        ) {
            err.nameErr = "Name cannot be empty in English language"
            flag = 0
            toast.error("Please enter data in english language")
        }
        if (emailId.length === 0) {
            err.emailIdErr = "Email id cannot be empty"
            flag = 0
        } else {
            const emailRegex = REGEX.EMAIL_REGEX
            if (!emailRegex.test(emailId)) {
                err.emailIdErr = "Invalid email id"
                flag = 0
            }
        }

        this.setState({ err: { ...err } })
        return flag
    }
    handleOnSubmit = async () => {
        const { createFormPage, isEdit, editableData, handleClose } = this.props
        const { type = "FORM", name, emailId, formFieldList } = this.state

        let payload = {
            name: name,
            type: type,
            emailId: emailId,
            formFieldList
        }

        if (isEdit) {
            payload.id = editableData.id
        }
        let validated = this.validate(name, emailId)
        if (!validated) {
            return null
        }
        this.setState({
            err: {
                nameErr: "",
                emailIdErr: ""
            }
        })

        if (!checkAllLanguageHasValue(name, this.languageObject)) {
            this.setState({
                emptyLangDataModal: true,
                finalPayload: payload
            })
        } else {
            await this.submitData(payload, handleClose, createFormPage)
        }
    }

    modalCancelHandler = () => {
        this.setState({
            emptyLangDataModal: false
        })
    }

    submitData = async (payload, handleClose, createFormPage) => {
        this.modalCancelHandler()
        const response = await createFormPage(payload)
        if (get(response, "status")) {
            toast.success(get(response, "message"))
            this.resetForm()
            handleClose()
        } else {
            toast.error(get(response, "message"))
        }
    }

    resetForm = () => {
        this.setState({
            name: "",
            emailId: "",
            formFieldList: [],
            formFieldMaster: [],
            isReset: true
        })
    }

    checkboxHandleChange = (name, value, id, inputType) => {
        const { formFieldList, ListField } = this.state
        let updatedList = [...formFieldList]
        if (value) {
            ListField.forEach((item, index) => {
                if (item.id === id) {
                    updatedList.push({
                        formFieldMaster: item,
                        inputLength: 0,
                        placeholder: item?.fieldName[DEFAULT_SELECTED_LANGUAGE_CODE],
                        position: 0,
                        required: true
                    })
                }
            })
        } else {
            updatedList = updatedList.filter((e) => e.formFieldMaster.id !== id)
        }
        this.setState({
            formFieldList: [...updatedList]
        })
    }
    onClickListener = (value) => {
        this.setState({
            selectedLanguage: value
        })
    }

    findChecked = (id, list) => {
        let isChecked = false
        list.forEach((item) => {
            if (item.formFieldMaster.id === id) {
                isChecked = true
                return isChecked
            }
        })
        return isChecked
    }

    checkboxRequired = (name, value, id) => {
        const { formFieldList } = this.state
        formFieldList.map((item, index) => {
            if (item.formFieldMaster.id === id) {
                item.required = value
            }
            return item
        })
        this.setState({
            formFieldList
        })
    }

    getMultilingualName = (value) => {
        if (isMultiLingual()) {
            return value[DEFAULT_SELECTED_LANGUAGE_CODE]
        }
        return value
    }

    renderInputFields = (inputType, itemName, placeholder) => {
        itemName = this.getMultilingualName(itemName)
        switch (inputType) {
            case "TEXT":
                return (
                    <Input
                        type={inputType}
                        changeHandler={this.handleChange}
                        name={itemName}
                        placeholder={placeholder}
                        disabled={!this.canUpdateStatus}
                    />
                )
            case "EMAIL":
                return (
                    <Input
                        type={inputType}
                        changeHandler={this.handleChange}
                        name={itemName}
                        placeholder={placeholder}
                        disabled={!this.canUpdateStatus}
                    />
                )
            case "NUMBER":
                return (
                    <div className="mobile">
                        <div className="mobile-number">+ 91 </div>
                        <Input
                            type={inputType}
                            name={itemName}
                            changeHandler={this.handleChange}
                            placeholder={placeholder}
                            disabled={!this.canUpdateStatus}
                        />
                    </div>
                )

            case "TEXTAREA":
                return (
                    <TextArea
                        row={100}
                        col={10}
                        changeHandler={this.handleChange}
                        name={itemName}
                        placeholder={placeholder}
                        disabled={!this.canUpdateStatus}
                    />
                )

            case "RADIO":
                if (itemName === "Rating") {
                    return (
                        <div class="rate">
                            <input type="radio" id="star5" name="rate" value="5" />
                            <label for="star5" title="text">
                                5 stars
                            </label>
                            <input type="radio" id="star4" name="rate" value="4" />
                            <label for="star4" title="text">
                                4 stars
                            </label>
                            <input type="radio" id="star3" name="rate" value="3" />
                            <label for="star3" title="text">
                                3 stars
                            </label>
                            <input type="radio" id="star2" name="rate" value="2" />
                            <label for="star2" title="text">
                                2 stars
                            </label>
                            <input type="radio" id="star1" name="rate" value="1" />
                            <label for="star1" title="text">
                                1 star
                            </label>
                        </div>
                    )
                } else {
                    return (
                        <div className="gender">
                            <label for="male">
                                <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    value="male"
                                    disabled={!this.canUpdateStatus}
                                ></input>
                                Male
                            </label>
                            <label for="female">
                                <input
                                    type="radio"
                                    id="female"
                                    name="gender"
                                    value="female"
                                    disabled={!this.canUpdateStatus}
                                ></input>
                                Female
                            </label>
                        </div>
                    )
                }
            default:
        }
    }

    renderFormCreationSection = () => {
        const { formFieldList, name, emailId, ListField, err, selectedLanguage } = this.state
        return (
            <div className="leftside-createForm">
                <label className="editor-description" htmlFor="pagename">
                    {TEXT_CONSTANTS?.PAGE_NAME?.toUpperCase()}
                    <span>*</span>
                    <Input
                        type="text"
                        changeHandler={this.handleChange}
                        name="name"
                        id="pagename"
                        value={
                            !isMultiLingual()
                                ? name
                                : name && name[selectedLanguage.code]
                                ? name[selectedLanguage.code]
                                : ""
                        }
                        errMsg={err.nameErr}
                        maxLength={25}
                        disabled={!this.canUpdateStatus}
                    />
                </label>
                <label className="editor-description" htmlFor="formdetail">
                    {TEXT_CONSTANTS?.FORM_DETAILS_SENDS} <span>*</span>
                    <Input
                        type="email"
                        changeHandler={this.handleChange}
                        name="emailId"
                        id="emailId"
                        value={emailId}
                        errMsg={err.emailIdErr}
                        disabled={!this.canUpdateStatus}
                    />
                </label>

                <label className="editor-description">
                    {TEXT_CONSTANTS?.CREATE_FORM?.toUpperCase()}
                </label>
                <div className="ls-create-form">
                    <ul>
                        {ListField.map((item, index) => {
                            return (
                                <li key={index}>
                                    {/* {item.inputType} */}
                                    <Checkbox
                                        cName="checklist"
                                        labelText={
                                            !isMultiLingual()
                                                ? item.fieldName
                                                : item.fieldName &&
                                                  item.fieldName[DEFAULT_SELECTED_LANGUAGE_CODE]
                                                ? item.fieldName[DEFAULT_SELECTED_LANGUAGE_CODE]
                                                : ""
                                        }
                                        id={item.id}
                                        name={
                                            !isMultiLingual()
                                                ? item.fieldName
                                                : item.fieldName &&
                                                  item.fieldName[DEFAULT_SELECTED_LANGUAGE_CODE]
                                                ? item.fieldName[DEFAULT_SELECTED_LANGUAGE_CODE]
                                                : ""
                                        }
                                        isChecked={this.findChecked(item.id, formFieldList)}
                                        changeHandler={(name, value, id) =>
                                            this.checkboxHandleChange(
                                                name,
                                                value,
                                                id,
                                                item.inputType
                                            )
                                        }
                                        disabled={!this.canUpdateStatus}
                                    />
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        )
    }

    renderFormPreviewSection = () => {
        const { handleClose, isEdit } = this.props
        const { formFieldList, isDisable } = this.state
        return (
            <div className="rightside-createForm">
                <p className="heading">{TEXT_CONSTANTS?.FORM_PREVIEW}</p>
                {formFieldList.map((item, index) => {
                    return (
                        <>
                            <div className="rightside-fields">
                                <label className="editor-description" htmlFor="fullname">
                                    {!isMultiLingual()
                                        ? item.formFieldMaster?.fieldName
                                        : item.formFieldMaster?.fieldName &&
                                          item.formFieldMaster?.fieldName[
                                              DEFAULT_SELECTED_LANGUAGE_CODE
                                          ]
                                        ? item.formFieldMaster?.fieldName[
                                              DEFAULT_SELECTED_LANGUAGE_CODE
                                          ]
                                        : ""}
                                    {item.required === true ? <span>*</span> : ""}
                                </label>
                                <Checkbox
                                    cName="checklist"
                                    labelText="Required"
                                    id={item.formFieldMaster.id}
                                    name={
                                        !isMultiLingual()
                                            ? item.formFieldMaster?.fieldName
                                            : item.formFieldMaster?.fieldName &&
                                              item.formFieldMaster?.fieldName[
                                                  DEFAULT_SELECTED_LANGUAGE_CODE
                                              ]
                                            ? item.formFieldMaster?.fieldName[
                                                  DEFAULT_SELECTED_LANGUAGE_CODE
                                              ]
                                            : ""
                                    }
                                    isChecked={item.required}
                                    changeHandler={(name, value, id) =>
                                        this.checkboxRequired(name, value, id)
                                    }
                                    disabled={!this.canUpdateStatus}
                                />
                            </div>

                            {this.renderInputFields(
                                item.formFieldMaster.inputType,
                                item.formFieldMaster.fieldName,
                                item.placeholder
                            )}
                        </>
                    )
                })}

                {formFieldList.length > 0 ? (
                    <div className="rightside-formBtns">
                        {this.canUpdateStatus && (
                            <Button
                                bValue={
                                    isEdit
                                        ? BUTTON_CONSTANTS?.UPDATE?.toUpperCase()
                                        : BUTTON_CONSTANTS?.SAVE?.toUpperCase()
                                }
                                disabled={isDisable}
                                clickHandler={this.handleOnSubmit}
                                cName="btn primary-btn"
                            />
                        )}
                        <Button
                            bValue={BUTTON_CONSTANTS?.CANCEL?.toUpperCase()}
                            clickHandler={handleClose}
                            cName="btn cancel-btn"
                        />
                    </div>
                ) : (
                    ""
                )}
            </div>
        )
    }

    render() {
        const { handleClose, createFormPage } = this.props
        const { emptyLangDataModal, finalPayload } = this.state
        return (
            <div className="vd-addCustomPages createFormContainer">
                <div className="heading-container">
                    <header>
                        <i className="icon-arrow-left1" onClick={handleClose} />
                        <span>{TEXT_CONSTANTS?.CREATE_FORM?.toUpperCase()}</span>
                    </header>
                    {isMultiLingual() && <LanguageCardList handleChange={this.onClickListener} />}
                </div>

                <div className="custom-createForm">
                    {this.renderFormCreationSection()}

                    {emptyLangDataModal && (
                        <LanguageDataConfirmationModal
                            isModalOpen={emptyLangDataModal}
                            submitHandler={() =>
                                this.submitData(finalPayload, handleClose, createFormPage)
                            }
                            cancelHandler={this.modalCancelHandler}
                        ></LanguageDataConfirmationModal>
                    )}

                    {this.renderFormPreviewSection()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        // roleList: get(state.userManagement, "allRoles", []),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                fetchFormField,
                createFormPage,
                fetchCreatedForm
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateForm)
