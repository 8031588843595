import React, { Component } from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

import NoImgAvailable from "../../../../assets/images/no_img_available.png"
import ToolTipList from "./Tooltip"

import "./style.scss"
import isEmpty from "lodash/isEmpty"
import { imagePathPrefix } from "../../../../config/apiPath"
import { checkIfUserHasAccess } from "../../../../utils/users"
import { BUTTON_CONSTANTS, PERMISSIONS, TEXT_CONSTANTS } from "../../../../utils/constants"
import { ORIENTATION_MENU, RAIL_TYPE_CONSTANT } from "../../api/constant"
import { toLower } from "lodash"
import { joinUrl } from "../../../../utils/common"

class PreviewSelected extends Component {
    constructor(props) {
        super(props)

        this.state = {
            movieList: []
        }
        this.canUpdateStatus = checkIfUserHasAccess([PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_EDIT])
    }

    getLayoutClass = () => {
        let className = "banner",
            { orientations, cardSize } = this.props
        if (orientations === ORIENTATION_MENU.LANDSCAPE) {
            className = "poster"
        } else if (orientations === ORIENTATION_MENU.CIRCULAR) {
            className = "stamp"
        }
        return `imgContainer ${toLower(cardSize)} ${className}`
    }

    getImage = (item) => {
        const { orientations } = this.props
        let image
        const isNewRail = !!item.images
        if (isNewRail) {
            image = orientations === ORIENTATION_MENU.PORTRAIT ? "poster" : "boxcover"
            image = item.images?.find((item) => item.type === image)?.url
            if (!image) {
                return NoImgAvailable
            } else if (image.includes("http")) {
                return image
            } else {
                return joinUrl(image)
            }
        } else {
            if (orientations === ORIENTATION_MENU.PORTRAIT) {
                image = item.portraitImage ? item.portraitImage : item.thumbnailImage
            } else {
                image = item.landscapeImage ? item.landscapeImage : item.thumbnailImage
            }
            image = image ? joinUrl(image) : `${NoImgAvailable}`
            return image
        }
    }

    renderCustomImagePreview = (index) => {
        const { railType, customHeroBanner, toggleCustomBannerModal, openDeleteModal } = this.props
        if (railType.id === RAIL_TYPE_CONSTANT.HERO_BANNER) {
            return isEmpty(
                customHeroBanner[index]?.heroBannerSmallScreenImage ||
                    customHeroBanner[index]?.heroBannerLargeScreenImage
            ) ? (
                <button
                    className="btn border-btn cta-customBanner"
                    onClick={() => toggleCustomBannerModal(index)}>
                    Custom Images
                </button>
            ) : (
                <div className="custom-image-container">
                    <div className="custom-preview">
                        <div
                            style={{
                                backgroundImage: `url(${imagePathPrefix}${customHeroBanner[index]?.heroBannerSmallScreenImage})`
                            }}
                        />
                        <span className="label">Mobile</span>
                    </div>

                    <div className="custom-preview">
                        <div
                            style={{
                                backgroundImage: `url(${imagePathPrefix}${customHeroBanner[index]?.heroBannerLargeScreenImage})`
                            }}
                        />
                        <span className="label">Web</span>
                    </div>
                    <div className="overlay-custom">
                        <button>
                            <i
                                className="icon-edit"
                                onClick={() => toggleCustomBannerModal(index)}
                            />
                        </button>
                        <button>
                            <i className="icon-del" onClick={() => openDeleteModal(index)} />
                        </button>
                    </div>
                </div>
            )
        }
    }

    render() {
        const {
            orientations,
            onRemove,
            movieList,
            handleChangeImageUpload,
            isContentPublished,
            handleCustomBannerEdit
        } = this.props
        return (
            <DragDropContext onDragEnd={this.props.onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <ul
                            className="content-preview-container"
                            {...provided.droppableProps}
                            ref={provided.innerRef}>
                            {movieList.map((item, index) => {
                                let {
                                    title,
                                    yearOfRelease,
                                    genres,
                                    details,
                                    id,
                                    tvGenre,
                                    logo,
                                    contentType,
                                    subTitle,
                                    episodeNumber
                                } = item
                                let directors
                                if (details) {
                                    directors = details.director
                                }
                                return (
                                    <Draggable
                                        key={item.id}
                                        draggableId={`item-${item.id}`}
                                        index={index}
                                        isDragDisabled={!this.canUpdateStatus}>
                                        {(provided, snapshot) => (
                                            <li
                                                className={`list-items ${
                                                    !isContentPublished(id) ? "" : "unpublished"
                                                }`}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                                <div className="dragHandler">
                                                    <div className="drag">
                                                        <span className="menu-icon"></span>
                                                        <span className="menu-icon"></span>
                                                        <span className="menu-icon"></span>
                                                    </div>
                                                    {this.canUpdateStatus && (
                                                        <div className="drag-input">
                                                            <span className="icon-arrow-up"></span>
                                                            <span className="position-placeholder">
                                                                <ToolTipList
                                                                    tooltiptext={index + 1}
                                                                    innerText={movieList.length}
                                                                    handleReorder={
                                                                        this.props.handleReorder
                                                                    }
                                                                />
                                                            </span>
                                                            <span className="icon-arrow-down1"></span>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="list-details">
                                                    {contentType !== "LIVE TV" && (
                                                        <div className={this.getLayoutClass()}>
                                                            <img
                                                                src={this.getImage(item)}
                                                                alt="title"
                                                                onError={(e) => {
                                                                    e.target.onerror = null
                                                                    e.target.src = `${NoImgAvailable}`
                                                                }}
                                                                onClick={() =>
                                                                    orientations ===
                                                                    ORIENTATION_MENU.PORTRAIT
                                                                        ? handleChangeImageUpload(
                                                                              item
                                                                          )
                                                                        : {}
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                    {contentType === "LIVE TV" && (
                                                        <div className={this.getLayoutClass()}>
                                                            <img
                                                                src={
                                                                    logo
                                                                        ? joinUrl(logo)
                                                                        : `${NoImgAvailable}`
                                                                }
                                                                alt="title"
                                                                onError={(e) => {
                                                                    e.target.onerror = null
                                                                    e.target.src = `${NoImgAvailable}`
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                    <div className="movieDetails">
                                                        <div className="text-container">
                                                            <h6>{title}</h6>
                                                            <p>
                                                                {yearOfRelease && (
                                                                    <span>{yearOfRelease} </span>
                                                                )}
                                                                {(!isEmpty(genres) ||
                                                                    !isEmpty(tvGenre)) && (
                                                                    <span> |</span>
                                                                )}
                                                                {genres &&
                                                                    Array.isArray(genres) &&
                                                                    genres?.map((item) => (
                                                                        <span>{item}</span>
                                                                    ))}
                                                                {tvGenre &&
                                                                    Array.isArray(tvGenre) &&
                                                                    tvGenre?.map((item) => (
                                                                        <span>{item.name}</span>
                                                                    ))}
                                                                {subTitle && (
                                                                    <span>{subTitle}</span>
                                                                )}
                                                                {episodeNumber && (
                                                                    <span>{` | Ep.${episodeNumber}`}</span>
                                                                )}
                                                            </p>
                                                            <p>
                                                                {directors &&
                                                                    directors.map((item) => (
                                                                        <span>{item.name}</span>
                                                                    ))}
                                                            </p>
                                                        </div>
                                                        {this.renderCustomImagePreview(index)}
                                                    </div>
                                                    {this.canUpdateStatus && (
                                                        <div className="action-btns">
                                                            {contentType === "CUSTOM_BANNER" && (
                                                                <div
                                                                    className="icon-edit"
                                                                    onClick={() =>
                                                                        handleCustomBannerEdit(item)
                                                                    }></div>
                                                            )}
                                                            <div
                                                                className={
                                                                    contentType === "CUSTOM_BANNER"
                                                                        ? "icon-del"
                                                                        : "icon-minus"
                                                                }
                                                                onClick={() => {
                                                                    onRemove(item)
                                                                }}></div>
                                                        </div>
                                                    )}
                                                </div>
                                            </li>
                                        )}
                                    </Draggable>
                                )
                            })}
                            {provided.placeholder}
                        </ul>
                    )}
                </Droppable>
            </DragDropContext>
        )
    }
}

export default PreviewSelected
