import { PlatformService } from "./service"
import { ACTION } from "./constants"

export const getAllPlatform = (platformId) => {
    return (dispatch) => {
        return PlatformService.getAllPlatform(platformId)
            .then((response) => {
                dispatch({
                    type: ACTION.GET_ALL_PLATFORM,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting user list ", error)
            })
    }
}

export const addNewPlatform = (params) => {
    return (dispatch) => {
        return PlatformService.addNewPlatform(params)
            .then((response) => {
                dispatch({
                    type: ACTION.ADD_NEW_PLATFORM,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting user list ", error)
            })
    }
}

export const deletePlatform = (id) => {
    return (dispatch) => {
        return PlatformService.deletePlatform(id)
            .then((response) => {
                dispatch({
                    type: ACTION.DELETE_PLATFORM,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting user list ", error)
            })
    }
}

export const getAllPlatformType = (id) => {
    return (dispatch) => {
        return PlatformService.getAllPlatformType(id)
            .then((response) => {
                dispatch({
                    type: ACTION.DELETE_PLATFORM,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting user list ", error)
            })
    }
}
