import AddTranslationServicesInstance from "./services"
import { removeLoader, setLoader } from "../../common/Loader/api/action"

export const fetchWebDictionaryIds = (platformId='', callback = () => { }) => {
    return (dispatch) => {
        dispatch(setLoader())
        return AddTranslationServicesInstance.fetchWebDictionaryIds()
            .then((response) => {
                let responseArray = response.data.data;
                let filteredResponseArray = responseArray.filter(arr => arr.platformId == platformId)
                callback(filteredResponseArray);
                return response.data.data;
            })
            .catch((error) => {
                console.log("Got error in fetching dictionary ids", error)
                return error;
            })
            .finally(() => {
                dispatch(removeLoader())
            })
    }
}

export const addDictionaryStrings = (platformId='', details={}, body={}, callback = () => { }) => {
    const newBodyData = {
        "platformId": platformId,
        "languageCode": details?.languageCode || '',
        "data": body
    }
    return (dispatch) => {
        return AddTranslationServicesInstance.addDictionaryStrings(details.id, newBodyData)
        .then((response) => {
            if(response.data.status){
                callback(true);
            }
            else { callback(false); }
        })
        .catch((error) => {
            console.log("Got error in adding Dictionary Strings", error)
            callback(false);
        })
    }
}