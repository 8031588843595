import { removeLoader, setLoader } from "../../common/Loader/api/action"
import ContentEnrichmentInstance from "./service"
import { toast } from "react-toastify"

export const getMoviesAndEpisodeData = (contentType, enrichmentType) => {
    return (dispatch) => {
        dispatch(setLoader())
        return ContentEnrichmentInstance.getMoviesAndEpisodeData(contentType, enrichmentType)
            .then((response) => {
                return response?.data
            })
            .catch((error) => {
                toast.error(error?.message)
                console.log("Got error in enrichment data ", error)
            })
            .finally(() => {
                dispatch(removeLoader())
            })
    }
}

export const getMoviesAndEpisodeCount = () => {
    return (dispatch) => {
        dispatch(setLoader())
        return ContentEnrichmentInstance.getMoviesAndEpisodeCount()
            .then((response) => {
                return response?.data
            })
            .catch((error) => {
                toast.error(error?.message)
                console.log("Got error in enrichment data ", error)
            })
            .finally(() => {
                dispatch(removeLoader())
            })
    }
}
