import React, { useCallback, useEffect, useRef, useState } from "react"
import Button from "../common/Button"
import { useDispatch } from "react-redux"
import { getPlatformConfig, submitConfigPayload } from "./api/action"
import Select from "../common/SelectBox"
import { getAllPlatform } from "../common/Platforms/api/action"
import { isEmpty } from "lodash"
import "./style.scss"

const PlatformConfig = () => {
    const dispatch = useDispatch()

    const [platform, setPlatform] = useState({})
    const [platformList, setPlatformList] = useState([])
    const [data, setData] = useState([])
    const [editValues, setEditValues] = useState({})
    const [errors, setErrors] = useState({})
    const inputRefs = useRef({})
    const textareaRefs = useRef({})

    const onLoad = async () => {
        const resp = await dispatch(getAllPlatform())
        setPlatformList(resp.data)
    }

    const handleFetch = async () => {
        const resp = await dispatch(getPlatformConfig(platform.platformEnum))
        setData(resp.data[0].data)
        setEditValues(
            Object.fromEntries(
                Object.entries(resp.data[0].data).map(([key, value]) => [
                    key,
                    JSON.stringify(value, null, 2)
                ])
            )
        )
    }

    const handleKeyChange = (oldKey, newKey) => {
        if (newKey.trim() === "") {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [oldKey]: "Key cannot be empty"
            }))
            return
        }

        // if (newKey in data) {
        //     setErrors((prevErrors) => ({
        //         ...prevErrors,
        //         [oldKey]: "Key already exists"
        //     }))
        //     return
        // }

        setData((prevData) => {
            const newData = { ...prevData }
            newData[newKey] = newData[oldKey]
            delete newData[oldKey]
            return newData
        })

        setEditValues((prevValues) => {
            const newValues = { ...prevValues }
            newValues[newKey] = newValues[oldKey]
            delete newValues[oldKey]
            return newValues
        })

        // setErrors((prevErrors) => {
        //     const newErrors = { ...prevErrors }
        //     newErrors[newKey] = newErrors[oldKey]
        //     delete newErrors[oldKey]
        //     return newErrors
        // })

        // Preserve focus
        // if (inputRefs.current[oldKey]) {
        //     inputRefs.current[newKey] = inputRefs.current[oldKey]
        //     delete inputRefs.current[oldKey]
        //     inputRefs.current[newKey].focus()
        // }
    }

    const handleValueChange = (key, value) => {
        setEditValues((prevValues) => ({
            ...prevValues,
            [key]: value
        }))
    }

    const handleBlur = (key, value) => {
        try {
            const parsedValue = JSON.parse(value)
            setData((prevData) => ({
                ...prevData,
                [key]: parsedValue
            }))
            setErrors((prevErrors) => ({ ...prevErrors, [key]: "" }))
        } catch (error) {
            const position = error.message.match(/position (\d+)/)
            const pos = position ? parseInt(position[1], 10) : 0
            const lines = value.substring(0, pos).split("\n")
            const lineNumber = lines.length
            setErrors((prevErrors) => ({
                ...prevErrors,
                [key]: `Invalid JSON format: Error at line ${lineNumber}.`
            }))
        }
    }

    const handleSubmit = useCallback(() => {
        const payload = {
            id: platform.platformEnum,
            platform: platform.platformEnum,
            data
        }
        dispatch(submitConfigPayload(payload))
    }, [data])

    useEffect(() => {
        onLoad()
    }, [])

    return (
        <div className="vd-menuDesign platform-config">
            <div className="vr-cont-head mb">
                <div className="vr-cont-title">
                    <span className="vr-breadcrum">
                        Settings <i className="icon-arrow-right1"></i>
                        <span>Platform Config</span>
                    </span>
                    <h2 className="breadcrum-header">Platform Config</h2>
                </div>
            </div>
            <div className="vr-content-holder basic-container">
                <div className="add-section">
                    <Select
                        value={platform}
                        options={platformList}
                        cName={"platform-type"}
                        name={"platformType"}
                        label={"Platform"}
                        required
                        placeholder={"Select"}
                        changeHandler={(name, value, selected) => {
                            setPlatform(selected)
                        }}
                    />
                    <Button
                        cName="btn primary-btn"
                        bType="button"
                        clickHandler={handleFetch}
                        bValue="Fetch"
                        disabled={!platform}
                    />
                </div>
                {!isEmpty(data) && (
                    <>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Keys</th>
                                    <th>Values</th>
                                    <th>Message</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(data)
                                    .sort((a, b) => a.localeCompare(b))
                                    .map((key) => (
                                        <tr key={key}>
                                            <td>
                                                <input
                                                    type="text"
                                                    value={key}
                                                    onChange={(e) =>
                                                        handleKeyChange(key, e.target.value)
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <textarea
                                                    rows="5"
                                                    cols="50"
                                                    value={editValues[key]}
                                                    onChange={(e) =>
                                                        handleValueChange(key, e.target.value)
                                                    }
                                                    onBlur={(e) => handleBlur(key, e.target.value)}
                                                    style={{
                                                        borderColor: errors[key] ? "red" : ""
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                {errors[key] && (
                                                    <span style={{ color: "red" }}>
                                                        {errors[key]}
                                                    </span>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                        <Button
                            bValue="Submit Config File"
                            cName="btn primary-btn"
                            clickHandler={handleSubmit}
                        />
                    </>
                )}
            </div>
        </div>
    )
}

export default PlatformConfig
