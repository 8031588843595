//JAN39@yopmail.com@123
import store from "../../store"

export const MenuTokenFun = () => {
    const storeData = store.getState()
    const userName =
        storeData?.AdminHomeReducer?.tenantInfo?.data?.auxData?.providerUsername || "TTNADMIN"
    const tenantIdentifier = storeData?.AdminHomeReducer?.tenantInfo?.data?.tenantIdentifier
    return {
        product: {
            MENUID: "1008",
            TOKEN: "882",
            KEY: userName
        },
        reports: {
            MENUID: "869",
            TOKEN: "755",
            KEY: userName
        },
        tenant: {
            MENUID: "28",
            TOKEN: "117",
            KEY: userName
        },
        coupons: {
            MENUID: "1054",
            TOKEN: "949",
            KEY: userName
        },
        promotion: {
            MENUID: "1019",
            TOKEN: "518",
            KEY: userName
        },

        dashboard:
            tenantIdentifier === "master"
                ? {
                      MENUID: "6094",
                      TOKEN: "6013",
                      KEY: userName
                  }
                : {
                      MENUID: "6093",
                      TOKEN: "6012",
                      KEY: userName
                  },
        "active-customers": {
            MENUID: "6082",
            TOKEN: "6001",
            KEY: userName
        },
        notifications: {
            MENUID: "1207",
            TOKEN: "969",
            KEY: userName
        },
        "notification-template": {
            MENUID: "906",
            TOKEN: "783",
            KEY: userName
        },
        workflow: {
            MENUID: "686",
            TOKEN: "614",
            KEY: userName
        }
    }
}
