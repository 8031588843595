import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { toast } from "react-toastify"

import Input from "../../common/Input"
import Button from "../../common/Button"
import { changePassword } from "../api/action"

import "./style.scss"
import { BUTTON_CONSTANTS, TEXT_CONSTANTS } from "../../../utils/constants"
import { isEmpty } from "lodash"

class ForgotPassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            error: {}
        }
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    handlePasswordUpdate = async () => {
        const { oldPassword, newPassword, confirmPassword } = this.state
        const payload = {
            confirmPassword: confirmPassword,
            newPassword: newPassword,
            oldPassword: oldPassword
        }
        let errStatus = this.checkForEmpty()
        if (!errStatus) {
            const response = await this.props.changePassword(payload)
            if (response && response.status && !response.error) {
                toast.success(response.message)
            } else {
                toast.error(response.message)
            }
            this.setState({
                oldPassword: "",
                newPassword: "",
                confirmPassword: "",
                errStatus: {}
            })
        } else {
            toast.error("Invalid password")
        }
    }

    checkForEmpty = () => {
        const { newPassword, confirmPassword, oldPassword } = this.state
        let error = {}
        let errStatus = false
        if (!oldPassword) {
            error.isOldPassword = "Please enter old password"
            errStatus = true
        }
        if (!newPassword) {
            error.isPassValid = "Please enter new password"
            errStatus = true
        }
        if (!confirmPassword) {
            error.isPasswordMatch = "please enter confirm password"
            errStatus = true
        }
        if (newPassword !== confirmPassword) {
            errStatus = true
        }
        //this.setState({error});
        return errStatus
    }

    validatePassword = () => {
        const { newPassword, confirmPassword, oldPassword } = this.state
        let error = {}
        let passRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$_!@])[A-Za-z\d$@!_]{8,16}$/

        if (!oldPassword) {
            error.isOldPassword = "Please enter old password"
        }
        if (!passRegex.test(newPassword) && newPassword) {
            error.isPassValid =
                "Password should be mix of cap letters, numbers & symbols of min 8 characters"
        }
        if (!newPassword) {
            error.isPassValid = "Please enter a New password"
        }
        if (
            newPassword &&
            confirmPassword &&
            !isEmpty(confirmPassword) &&
            !isEmpty(newPassword) &&
            newPassword !== confirmPassword
        ) {
            error.isPasswordMatch = "Password does not match"
        } else {
            error.isPasswordMatch = ""
        }
        this.setState({ error })
    }

    render() {
        const { error, newPassword, confirmPassword, oldPassword } = this.state
        return (
            <div className="forgotPassword innerContainer">
                <div className="password-header">
                    <i>
                        <svg viewBox="0 0 34 43" width="17" height="21">
                            <path
                                fill="#6E688F"
                                fillRule="evenodd"
                                d="M29 43H5c-2.757 0-5-2.261-5-5.039V20.828c0-2.778 2.243-5.039 5-5.039h2.997V8.883c0-4.898 4.037-8.883 9-8.883 4.962 0 9 3.985 9 8.883v6.906H29c2.757 0 5 2.261 5 5.039v17.133C34 40.739 31.757 43 29 43zM22.664 8.883c0-3.046-2.543-5.524-5.667-5.524-3.125 0-5.667 2.478-5.667 5.524v6.906h11.334V8.883zm8.003 11.945c0-.926-.748-1.68-1.667-1.68H5c-.919 0-1.667.754-1.667 1.68v17.133c0 .926.748 1.68 1.667 1.68h24c.919 0 1.667-.754 1.667-1.68V20.828zm-12.003 8.915v3.683c0 .927-.747 1.679-1.667 1.679-.921 0-1.667-.752-1.667-1.679v-3.687c-.85-.554-1.413-1.516-1.413-2.612 0-1.716 1.38-3.107 3.083-3.107 1.703 0 3.083 1.391 3.083 3.107 0 1.098-.566 2.063-1.419 2.616z"
                            />
                        </svg>
                    </i>
                    <span>{TEXT_CONSTANTS?.CHANGE_PASSWORD?.toUpperCase()}</span>
                </div>
                <div className="pass-container">
                    <Input
                        type="password"
                        changeHandler={this.handleChange}
                        labelText="OLD PASSWORD"
                        name="oldPassword"
                        id="oldPassword"
                        onBlur={this.validatePassword}
                        errMsg={error.isOldPassword}
                        value={oldPassword}
                    />
                    <Input
                        type="password"
                        changeHandler={this.handleChange}
                        labelText="NEW PASSWORD"
                        name="newPassword"
                        id="newPassword"
                        onBlur={this.validatePassword}
                        errMsg={error.isPassValid}
                        value={newPassword}
                    />
                    <Input
                        type="password"
                        changeHandler={this.handleChange}
                        labelText="CONFIRM PASSWORD"
                        name="confirmPassword"
                        id="confirmPassword"
                        onBlur={this.validatePassword}
                        errMsg={error.isPasswordMatch}
                        value={confirmPassword}
                    />
                    <Button
                        cName="btn primary-btn submitBtn"
                        bValue={BUTTON_CONSTANTS?.UPDATE?.toUpperCase()}
                        clickHandler={this.handlePasswordUpdate}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                changePassword
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
