import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import "./style.scss"
import { getEPGImagePath } from "../../../utils/common"

class EpgSlider extends Component {
    constructor(props) {
        super(props)
        this.state = {
            epgLiveTV: [],
            epgLiveTVdata: [],
            selectedIndex: 0,
            activeIndex: props.currentlyActiveChannel
        }
    }

    handleEpg = (index, item) => {
        const { epgLiveTVdesc } = this.props
        this.setState({
            activeIndex: index
        })
        epgLiveTVdesc(item, index)
    }

    render() {
        const { activeIndex } = this.state
        const { epgLiveTV } = this.props
        return (
            <div className="epgHeader">
                <div className="upperHeader">
                    <div className="itemDate">
                        <span className="item-Count">
                            {epgLiveTV?.length ? `${epgLiveTV.length} Channels` : ""}
                        </span>
                    </div>
                    <div className="epgSearch"></div>
                </div>
                <div className="lowerHeader">
                    <ul className="slider">
                        {epgLiveTV.map((item, index) => {
                            return (
                                <li
                                    onClick={() => this.handleEpg(index, item)}
                                    className={!!(index === activeIndex) ? "activeclass" : ""}
                                >
                                    <div className="img-container">
                                        <img
                                            src={`${getEPGImagePath(item?.image)}`}
                                            alt={item.name}
                                        />
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        // epgLiveTV: get(state.epgLiveTV, 'epgLiveTV', []),
        // customTextCount: get(state.customPage, 'customText.count', []),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                // fetchEpgContentLiveTV
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EpgSlider)
