import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import Invoice from "../SubscriptionDetail/index"
import get from "lodash/get"
import moment from "moment"
import { SUBSCRIBER_PAGE_TABLE_HEADING } from "../APIs/constants"
import { fetchSubscriptionCustomer } from "../APIs/action"

import "./style.scss"

class TotalSubscriber extends Component {
    constructor(props) {
        super(props)
        this.state = {
            subscribersDetail: true,
            customerList: [],
            invoicePage: false,
            viewData: []
        }
    }

    componentDidMount() {
        this.loadHandler()
    }

    loadHandler = async () => {
        const { fetchSubscriptionCustomer } = this.props
        const response = await fetchSubscriptionCustomer()
        if (response && response.status) {
            this.setState({
                customerList: response.data
            })
        }
    }

    handleSubcriberDetail = (item) => {
        this.setState({
            invoicePage: true,
            subscribersDetail: false,
            viewData: item
        })
    }
    handleClose = () => {
        this.setState({
            invoicePage: false,
            subscribersDetail: true
        })
        this.loadHandler()
    }

    render() {
        const { subscribersDetail, customerList, invoicePage, viewData } = this.state
        const tableHeading = SUBSCRIBER_PAGE_TABLE_HEADING.map((item, index) => {
            return <th key={index}>{item}</th>
        })

        const SubscriberList = customerList.filter(
            (v, i, a) => a.findIndex((t) => t.email === v.email && t.name === v.name) === i
        )

        const tableRows = SubscriberList.map((row, index) => {
            return (
                <tr id={row.id} key={index} className="custom_tr">
                    <td>{index + 1}</td>
                    <td>{row.name}</td>
                    <td>{row.email}</td>
                    <td>{row.phone}</td>
                    <td>{moment(row.expired).format(" DD MMMM, YYYY")}</td>
                    <td>{row.currency}</td>
                    <td className="btn-popupContainer">
                        <i
                            className="icon-published"
                            onClick={(item) => this.handleSubcriberDetail(row)}
                        />
                        {/* <i
                            className="icon-selection"
                        onClick={this.ShowInvoiceDetail}
                        /> */}
                    </td>
                </tr>
            )
        })

        return (
            <div className="vd-custom-management">
                {invoicePage && <Invoice handleClose={this.handleClose} viewData={viewData} />}
                {subscribersDetail && (
                    <>
                        <div className="vr-cont-head mb">
                            <div className="vr-cont-title">
                                <span className="vr-breadcrum">
                                    user-management <i className="icon-arrow-right1" />
                                    <span>users</span>
                                </span>
                                <h2 className="breadcrum-header">Subscriber Details</h2>
                            </div>
                            {/* <button className="btn border-btn"  >
                        <i className="btn-icon-left icon-arrow-left1" />
                        <span className="button-text" >Back</span></button> */}
                        </div>

                        <div className="vr-content-holder">
                            <div className="vr-cont-head">
                                <div className="vr-cont-title">
                                    <h2 className="inner-count-title">
                                        {tableRows.count} Subscribers
                                    </h2>
                                </div>
                                {/* <div className="vr-cont-action">
                            <Search
                                name="search"
                                // changeHandler={this.handleSearch}
                                placeholder="Search Here"
                            />
                        </div> */}
                            </div>
                            <div>
                                <table className="table invoice-table">
                                    <thead>
                                        <tr>{tableHeading}</tr>
                                    </thead>
                                    <tbody>{tableRows}</tbody>
                                </table>
                            </div>
                        </div>
                    </>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        customerList: get(state.customerList, "customerList", [])
        // customTextCount: get(state.customPage, 'customText.count', []),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                fetchSubscriptionCustomer
                // filterTextpage,
                // softDeleteTextpage,
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TotalSubscriber)
