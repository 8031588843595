import ApiService from "../../../../utils/apiService"
import { API_ROOT_PATH } from "../../../../config/apiPath"

class LanguageService {
    fetchLanguageList() {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/language/getLanguageList`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
}

const LanguageServiceInstance = new LanguageService()
export default LanguageServiceInstance
