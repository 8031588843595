import React, { Component } from "react"
import EpisodeContent from "./EpisodeContent"
import Button from "../../common/Button"
import { BUTTON_CONSTANTS, PERMISSIONS, TEXT_CONSTANTS } from "../../../utils/constants"
import { AuthWrapper } from "../../common/AuthWrapper"
export default class EpisodeList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            expanded: true
        }
    }

    toggelExpand = () => {
        this.setState({
            expanded: !this.state.expanded
        })
    }
    renderEpisodeList = () => {
        return this.props.episodeList.map((item, index) => {
            return (
                <EpisodeContent
                    eidtEpisode={this.props.eidtEpisode}
                    deleteEpisode={this.props.deleteEpisode}
                    data={item}
                    index={index}
                    seasonIndex={this.props.seasonIndex}
                    episodeId={item?.episodeId || item?.scrollId}
                />
            )
        })
    }
    render() {
        return (
            <>
                <div className="add-episode-head" id={`season-add-new-${this.props?.seasonIndex}`}>
                    <div className="episode-count">
                        {TEXT_CONSTANTS?.EPISODES?.toUpperCase()} ({this.props.episodeList.length})
                    </div>
                    <AuthWrapper permissions={[PERMISSIONS?.CONTENT_EDIT]}>
                        <Button
                            cName={"btn border-btn"}
                            bValue={BUTTON_CONSTANTS?.ADD_EPISODE}
                            leftIcon={"icon-plus"}
                            clickHandler={this.props.handleAddNewEpisode}
                        />
                    </AuthWrapper>
                </div>
                {this.renderEpisodeList()}
            </>
        )
    }
}
