import React, { Component } from "react"
import Button from "../../../common/Button"
import check_icon from "../../../../assets/images/check_icon.svg"
import uncheck_icon from "../../../../assets/images/uncheck_icon.svg"
import { BUTTON_CONSTANTS, TEXT_CONSTANTS } from "../../../../utils/constants"

class SelectTarget extends Component {
    constructor(props) {
        super(props)
        this.location = [
            "Any",
            "Amsterdam",
            "Rotterdam",
            "The Hague",
            "Groningen",
            "Utrecht",
            "Breda"
        ]
        this.age = ["Any", "13+", "16+", "18+", "40+", "55+"]
        this.gender = ["Any", "Male", "Female"]
        this.state = {
            selectedTarget:
                props.selectedTarget != null
                    ? props.selectedTarget
                    : {
                          location: [],
                          gender: [],
                          age: []
                      }
        }
    }

    onTargetSelect = (type, target) => {
        let obj = this.state.selectedTarget
        if (this.state.selectedTarget[type].includes(target)) {
            obj[type] = obj[type].filter((item) => item !== target)
        } else {
            obj[type].push(target)
        }
        this.setState({
            selectedTarget: obj
        })
    }
    render() {
        const { onClose, id, onProceed } = this.props
        const { selectedTarget } = this.state
        return (
            <div className="vr-popup-container" onClick={onClose}>
                <div
                    className="vr-content-holder select-target"
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className="vr-cont-head mb">
                        {/* TODO: Replace below with the reusable pageTitleComponent */}
                        <div className="vr-cont-title">
                            <h2 className="breadcrum-header">{TEXT_CONSTANTS?.SELECTED_TARGETS}</h2>
                        </div>
                    </div>
                    <div className="vr-content-select-target">
                        <h2>{TEXT_CONSTANTS?.LOCATION} :</h2>
                        <ul>
                            {this.location.map((item, index) => {
                                return (
                                    <li
                                        onClick={() => this.onTargetSelect("location", item)}
                                        className={
                                            selectedTarget.location.includes(item) && "selected"
                                        }
                                    >
                                        <span>{item}</span>
                                        <span className="icon">
                                            <img
                                                src={
                                                    !selectedTarget.location.includes(item)
                                                        ? uncheck_icon
                                                        : check_icon
                                                }
                                                alt={
                                                    !selectedTarget.location.includes(item)
                                                        ? "uncheck_icon"
                                                        : "check_icon"
                                                }
                                            />
                                        </span>
                                    </li>
                                )
                            })}
                        </ul>
                        <h2>{TEXT_CONSTANTS?.AGE_GROUP} :</h2>
                        <ul>
                            {this.age.map((item, index) => {
                                return (
                                    <li
                                        onClick={() => this.onTargetSelect("age", item)}
                                        className={selectedTarget.age.includes(item) && "selected"}
                                    >
                                        <span>{item}</span>
                                        <span className="icon">
                                            <img
                                                src={
                                                    !selectedTarget.age.includes(item)
                                                        ? uncheck_icon
                                                        : check_icon
                                                }
                                                alt={
                                                    !selectedTarget.age.includes(item)
                                                        ? "uncheck_icon"
                                                        : "check_icon"
                                                }
                                            />
                                        </span>
                                    </li>
                                )
                            })}
                        </ul>
                        <h2>{TEXT_CONSTANTS?.GENDER} :</h2>
                        <ul>
                            {this.gender.map((item, index) => {
                                return (
                                    <li
                                        onClick={() => this.onTargetSelect("gender", item)}
                                        className={
                                            selectedTarget.gender.includes(item) && "selected"
                                        }
                                    >
                                        <span>{item}</span>
                                        <span className="icon">
                                            <img
                                                src={
                                                    !selectedTarget.gender.includes(item)
                                                        ? uncheck_icon
                                                        : check_icon
                                                }
                                                alt={
                                                    !selectedTarget.gender.includes(item)
                                                        ? "uncheck_icon"
                                                        : "check_icon"
                                                }
                                            />
                                        </span>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <hr />
                    <div className="button-grp">
                        <Button
                            cName="btn primary-btn"
                            bValue={BUTTON_CONSTANTS?.PROCEED}
                            bType="button"
                            clickHandler={() => {
                                onProceed(id, selectedTarget)
                            }}
                        />
                        <Button
                            cName="btn cancel-btn"
                            bValue={BUTTON_CONSTANTS?.CLOSE}
                            bType="button"
                            clickHandler={onClose}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default SelectTarget
