import React, { useRef, useState } from 'react'
import './style.scss'
import useOutsideClickAlert from '../../../utils/useOutsideClickAlert'

export default function ImageDropdown({
    dropdownImage,
    dropdownItemList = [],
    dropdownClickHandler
}) {

    const [showModal, setShowModal] = useState(false)
    const actionItemRef = useRef(null)
    useOutsideClickAlert(actionItemRef, () => {
        if(showModal){
            setShowModal(false)
        }
    })

    const dropDownHandler = (listItem) => {
        dropdownClickHandler(listItem)
        setShowModal(false)
    }

    const toggleImageDropdown = () => {
        setShowModal(!showModal)
    }

    return (
        <div className="image-dropdown-container" ref={actionItemRef}>
            <img
                src={dropdownImage}
                alt="additional-item-icon"
                onClick={toggleImageDropdown}
                className='dropdown-image'
            />
            {showModal &&
                <ul className="list-items-container">
                    {dropdownItemList.map(listItem => {
                        return <li
                            key={listItem?.id}
                            className="list-items"
                            onClick={() => {
                                dropDownHandler(listItem)
                            }}
                        >
                            {listItem?.imageUrl && <div className="image-container">
                                <img src={listItem?.imageUrl} alt={`${listItem?.label}`} />
                            </div>}
                            <p>{listItem?.label}</p>
                        </li>

                    })}
                </ul>

            }
        </div>
    )
}
