import React from "react"
import { VOD_PAGE_DETAILS, VOD_SPORTS_TAB, VOD_TABS } from "../api/constant"
import Category from "../index"

export default function VODCategory() {
    return (
        <Category
            tabs={VOD_TABS}
            pageDetails={VOD_PAGE_DETAILS}
            parent={VOD_SPORTS_TAB.VOD}
        />
    )
}
