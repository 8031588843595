import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import get from "lodash/get"
import "./style.scss"
import { fetchEpgTvShow } from "../api/action"
import {
    getDefaultMultilingualValue,
    getUTCOffset,
    getTimeBasedOnTimezoneString,
    getGMTTimezone
} from "../../../utils/common"
import { TEXT_CONSTANTS } from "../../../utils/constants"
class EpgTimeline extends Component {
    constructor(props) {
        super(props)
        this.state = {}
        this.utcOffset = getUTCOffset()
    }

    render() {
        const { TVlistdata, epgTVList, selectedTimezoneLabel } = this.props
        // let startTime =
        //   epgTVList &&
        //   epgTVList.length &&
        //   epgTVList.map((i) => new Date(i.startTime).toUTCString().slice(-12, -7));
        // let endTime =
        //   epgTVList &&
        //   epgTVList.length &&
        //   epgTVList.map((i) => new Date(i.endTime).toUTCString().slice(-12, -7));
        const globalCurrentTimezone = getGMTTimezone(selectedTimezoneLabel?.timeZone, 0)
        return (
            <>
                {epgTVList == null || epgTVList.length === 0 ? (
                    <>
                        <p className="text-centered-no-show--avaiable">
                            {TEXT_CONSTANTS?.NO_SHOW_AVAIALBLE} !
                        </p>
                        {/* <ul className="staticshowList"> 
              {showList.map((item, idx) => (
                <li>
                  <div className="left-list">
                    <div className="showTime">
                      <p>{item?.startTime}</p>
                      <p>{item?.endTime}</p>
                    </div>
                    <div className="showName">
                      <p>{item.title}</p>
                    </div>
                  </div>
                  <div className="list-btn"></div>
                </li>
              ))}
            </ul> */}
                    </>
                ) : (
                    <ul className="showList">
                        {epgTVList &&
                            epgTVList.length &&
                            epgTVList.map((item, idx) => (
                                <li onClick={() => TVlistdata(item)}>
                                    <div className="left-list">
                                        <div className="showTime">
                                            <p>
                                                {getTimeBasedOnTimezoneString(
                                                    item?.startTime,
                                                    globalCurrentTimezone
                                                )}
                                            </p>
                                            <p>
                                                {getTimeBasedOnTimezoneString(
                                                    item?.endTime,
                                                    globalCurrentTimezone
                                                )}
                                            </p>
                                        </div>
                                        <div className="showName">
                                            <p>{item?.title}</p>
                                            {/* <p>{getDefaultMultilingualValue(item?.title)}</p> */}
                                        </div>
                                    </div>
                                    <div className=" list-btn"></div>
                                </li>
                            ))}
                    </ul>
                )}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        epgTVshow: get(state.epgTVshow, "epgTVshow", []),
        selectedTimezoneLabel: state?.timezone?.selectedTimezone
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                fetchEpgTvShow
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EpgTimeline)
