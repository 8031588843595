import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import PieChart from "../PieChart"
import "./style.scss"

/* admin work */
class TenantSubscribers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isSuperAdmin: true
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            let pieData = nextProps?.data?.pie
            let plotValues = []
            let xAxisData = []
            for (let i = 0; i < pieData?.length; i++) {
                xAxisData.push(pieData[i]?.tenantName)
                plotValues.push(pieData[i]?.countValue)
            }
            this.setState({
                plotValues,
                xAxisData
            })
        }
    }

    render() {
        const { data } = this.props
        const { plotValues, xAxisData } = this.state

        return (
            <>
                <div className="tenant-subscriber">
                    <div>
                        <div className="tenant-detail-container">
                            <p> ALL TENANT'S SUBSCRIBERS</p>
                            <div></div>
                        </div>
                        <p className="tenant-count">{data?.totalCount}</p>
                        <div className="tenant-subscriber-container">
                            <p className="sub-container">
                                <p className="heading">LAST MONTH'S DATA </p>
                                <p className="count-container">
                                    <p className="count">
                                        <span className="status-dot"></span>
                                        {data?.last_month_data && data?.last_month_data["new_user"]}
                                    </p>
                                    <p className="count">
                                        <span className="status-dot"></span>
                                        {data?.last_month_data &&
                                            data?.last_month_data["drop_user"]}
                                    </p>
                                </p>
                                <p className="count-container user-label">
                                    <span>New Users</span>
                                    <span>Drop Users</span>
                                </p>
                            </p>
                        </div>
                    </div>
                    <div className="pie-chart">
                        {!isEmpty(plotValues) && !isEmpty(xAxisData) && (
                            <PieChart plotValues={plotValues} xAxisData={xAxisData} />
                        )}
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({}, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TenantSubscribers)
