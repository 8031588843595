import React from "react"

import Input from "../../../common/Input/index"
import Select from "../../../common/SelectBox/index"
import Button from "../../../common/Button/index"
import MultiSelect from "../../../Category/AddNewRail/multiselect"
import ImageUpload from "../../../MenuDesign/addEditMenu/imageUplode"
import { imgPathPrefix } from "../../api/constant"
import { isMultiLingual } from "../../../../utils/common"
import { isEmpty } from "lodash"
import { BUTTON_CONSTANTS, TEXT_CONSTANTS } from "../../../../utils/constants"

function MainMenuList(props) {
    const {
        provided,
        item,
        findLinkTo,
        delBtn,
        subMenu,
        cmsOptions,
        editorialOptions,
        placementList,
        idToDelete,
        isDelete,
        handleSelectChange,
        handleDeleteClick,
        handleDeleteTab,
        error,
        imageUpload,
        imageRemove,
        selectedLanguage,
        handlePublishUnpublishTab,
        openMetaEdit
    } = props
    const changeTabName = (name, value) => {
        if (isMultiLingual()) item.tabName[selectedLanguage.code] = value
        handleSelectChange(
            item.id ? item.id : item.uniqueId,
            name,
            isMultiLingual() ? item.tabName : value
        )
    }
    return (
        <div className="mainMenu">
            <div className="drag" {...provided.dragHandleProps}>
                <span className="menu-icon"></span>
                <span className="menu-icon"></span>
                <span className="menu-icon"></span>
            </div>
            <div className="form-group">
                <ImageUpload
                    key={item.id ? item.id : item.uniqueId}
                    id={item.id ? item.id : item.uniqueId}
                    tabName={item.tabName}
                    cName={"menuIcon"}
                    value={item.menuIcon ? `${imgPathPrefix}${item.menuIcon}` : null}
                    uploadImage={imageUpload}
                    removeImg={(name, id) => imageRemove(name, id)}
                    errMsg={error && error.menuIcon ? error.menuIcon : null}
                />
            </div>
            <div className="form-group">
                <Input
                    type="text"
                    placeholder="Enter Tab Name"
                    maxLength={25}
                    cName={`name`}
                    name="tabName"
                    value={
                        !isMultiLingual()
                            ? item?.tabName
                            : !isEmpty(item?.tabName) && item?.tabName[selectedLanguage?.code]
                            ? item?.tabName[selectedLanguage?.code]
                            : ""
                    }
                    changeHandler={(name, value) => changeTabName(name, value)}
                    errMsg={error && error.name ? error.name : null}
                />
            </div>
            <div className="form-group">
                <Select
                    value={
                        item.menuType
                            ? { id: item.menuType, name: item.menuType }
                            : item.menuType
                            ? { id: item.menuType, name: item.menuType }
                            : null
                    }
                    placeholder={"Select"}
                    options={subMenu.map((item) => ({ id: item, name: item }))}
                    cName={"type"}
                    name={"type"}
                    changeHandler={(name, value) =>
                        handleSelectChange(item.id ? item.id : item.uniqueId, name, value)
                    }
                    errMsg={error && error.type ? error.type : null}
                />
            </div>
            <div className="form-group">
                <Select
                    // defaultOption="true"
                    value={
                        item.linkToPage
                            ? findLinkTo(
                                  item.linkToPage,
                                  item.menuType === "TAB"
                                      ? editorialOptions
                                      : item.menuType === "CMS"
                                      ? cmsOptions
                                      : []
                              )
                            : null
                    }
                    isDisabled={item.menuType && item.menuType === "Sub-Menu"}
                    placeholder={"Select"}
                    options={
                        item.menuType === "TAB"
                            ? editorialOptions
                            : item.menuType === "CMS"
                            ? cmsOptions
                            : []
                    }
                    cName={"linkToPage"}
                    name={"linkToPage"}
                    changeHandler={(name, value) =>
                        handleSelectChange(item.id ? item.id : item.uniqueId, name, value)
                    }
                    errMsg={error && error.linkToPage ? error.linkToPage : null}
                />
            </div>
            <div className="form-group">
                <MultiSelect
                    key={"placement"}
                    options={placementList.map((item) => ({ id: item, name: item }))}
                    // required={element.required}
                    placeholder={"Select"}
                    id={"placement"}
                    name={"placement"}
                    isOptionCheckbox={true}
                    selectedValues={
                        item.placement
                            ? item.placement
                            : item.placementType.map((item) => ({ id: item, name: item }))
                    }
                    changeHandler={(name, value) =>
                        handleSelectChange(item.id ? item.id : item.uniqueId, name, value)
                    }
                    errMsg={error && error.placement ? error.placement : null}
                />
            </div>
            {delBtn && (
                <div className="actions">
                    <i
                        className=" toolsBtn icon-del"
                        onClick={() => handleDeleteClick(item.id ? item.id : item.uniqueId)}>
                        <span className="tooltiptext">{"Delete"}</span>
                    </i>
                    <i
                        className={`${
                            item?.active
                                ? "toolsBtn icon-unpublish"
                                : "toolsBtn icon-published publish-icon"
                        }
                publish-unpublish-icon
                `}
                        onClick={() => handlePublishUnpublishTab(item)}>
                        <span className="tooltiptext">
                            {item?.active ? "Unpublish" : "Publish"}
                        </span>
                    </i>
                    <i className=" toolsBtn icon-edit" onClick={() => openMetaEdit(item)}>
                        <span className="tooltiptext">{"Edit Meta"}</span>
                    </i>
                </div>
            )}

            {(item.id === idToDelete || item.uniqueId === idToDelete) && (
                <div
                    className={`deletePopup ${
                        isDelete ? "showPopUp slide-right" : "hidePopUp slide-left"
                    }`}>
                    <p>
                        {TEXT_CONSTANTS?.DO_YOU_WANT_TO_REMOVE} {item.name} {TEXT_CONSTANTS?.TAB}?
                    </p>
                    <Button
                        cName="btn popup-del-btn"
                        bValue={BUTTON_CONSTANTS?.YES_DELETE}
                        bType="button"
                        clickHandler={() => handleDeleteTab(item)}
                    />
                    <Button
                        cName="btn cancel-btn"
                        bValue={BUTTON_CONSTANTS?.CANCEL}
                        bType="button"
                        clickHandler={() => handleDeleteClick(item.id ? item.id : item.uniqueId)}
                    />
                </div>
            )}
        </div>
    )
}

export default MainMenuList
