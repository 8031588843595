import { ACTION } from "./constant"

let initialState = {
    selectedTimezone: ""
}

export default function TimezoneReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION.SAVE_TIMEZONE:
            return {
                ...state,
                selectedTimezone: action?.data
            }

        default:
            return state
    }
}
