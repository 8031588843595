import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import "./style.scss"
import { BUTTON_CONSTANTS, PERMISSIONS, TEXT_CONSTANTS } from "../../../utils/constants"
import Select from "../../common/SelectBox"
import Checkbox from "../../common/Checkbox"
import { useDispatch, useSelector } from "react-redux"
import {
    autoShuffleCreateRail,
    createRail,
    fetchContentTypeList,
    fetchFilters,
    fetchPlatformList,
    fetchRailById,
    fetchRailTypeList,
    fetchSystemGenerateFav,
    getLatestPosition,
    searchContent,
    uploadImage
} from "../api/action"
import Input from "../../common/Input"
import { isEmpty, toNumber } from "lodash"
import { debounce, joinUrl } from "../../../utils/common"
import { WebSeriesContentContainer } from "../WebSeriesContentContainer"
import {
    CARD_SIZE_LIST,
    CONTENT_LEVEL_OPTION,
    CONTENT_TYPE_CONST,
    ORIENTATION_MENU,
    RAIL_CATEGORY,
    RAIL_TYPE_CONSTANT,
    REDIRECTION_LIST,
    REDIRECTION_OPTION,
    SPORTS_CONTENT_TYPE,
    SPORTS_ID,
    VOD_SPORTS_TAB
} from "../api/constant"
import NoImgAvailable from "../../../assets/images/no_img_available.png"
import { AuthWrapper } from "../../common/AuthWrapper"
import Button from "../../common/Button"
import { imagePathPrefix } from "../../../config/apiPath"
import ContentPreview from "./ContentPreview"
import ContentType from "./ContentType"
import ImageUpload from "../../common/imageUpload"
import { toast } from "react-toastify"
import SchedulePublishRail from "./SchedulePublishRail"
import FeaturedIn from "./FeaturedIn"
import RailCategory from "./RailCategory"
import CustomModal from "../../common/Modal"
import { fetchLanguageList } from "../../ForceUpgradeApplication/api/action"
import { fetchSelectedSportsDataList, getSportsCategory } from "../../Sports/APIs/action"
import {
    appendSportsContentType,
    formatContentType,
    formatSportsData,
    getSportsCategoryList
} from "../api/helper"
import { setLoader } from "../../common/Loader/api/action"
import { SPORTS_TAB } from "../../Sports/APIs/constant"
import CustomHeroBanner from "./CustomHeroBanner"

const SUB_TYPE_LIST = [
    {
        name: "CHANNEL",
        id: "Channel"
    },
    {
        name: "EPG",
        id: "EPG"
    }
]

const AddNewRail = (props) => {
    const { selectedTabName, selectedTab, handleBackClick, id, rails, parent, allCategoryList } =
        props
    const dispatch = useDispatch()
    const state = useSelector((state) => state.categoryRail || {})
    const isLoadingAPI = useSelector((state) => state.loader.isLoading)

    const { platformList, contentTypeList, railTypeList, categoryTabList } = state
    const [railCategory, setRailCategory] = useState(RAIL_CATEGORY.NEW)
    const [railName, setRailName] = useState("")
    const [orientations, setOrientations] = useState(0)
    const [redirectionType, setRedirectionType] = useState(REDIRECTION_OPTION.IN_APP)
    const [subType, setSubType] = useState("")
    const [mediaContentList, setMediaContentList] = useState([])
    const [redirectionURL, setRedirectionURL] = useState("")
    const [languageList, setLanguageList] = useState([])
    const [genreList, setGenreList] = useState([])
    const [artistList, setArtistList] = useState([])
    const [inputLang, setInputLang] = useState("")
    const [mediaContentSearch, setMediaContentSearch] = useState("")
    const [selectedMovies, setSelectedMovies] = useState([])
    const [genre, setGenre] = useState([])
    const [language, setLanguage] = useState([])
    const [isRailPublished, setRailPublished] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const [webSeriesToAdd, setWebSeriesToAdd] = useState([])
    const [contentLevel, setContentLevel] = useState(0)
    const [position, setPosition] = useState(-1)
    const [itemToChangeImg, setItemToChangeImg] = useState({})
    const [imageUpload, setImageUpload] = useState({})
    const [imageActive, setImageActive] = useState(false)
    const [isClick, setIsClick] = useState(false)
    const [unpublishedContent, setUnpublishedContent] = useState([])
    const [cardSize, setCardSize] = useState(CARD_SIZE_LIST[0].id)
    const [autoShuffle, setautoShuffle] = useState({})
    const [saveByApply, setSaveByApply] = useState(false)
    const [scheduleModal, setScheduleModal] = useState(false)
    const [selectedPlatform, setSelectedPlatform] = useState([])
    const [themeRailCheck, setThemeRailCheck] = useState(false)
    const [railType, setRailType] = useState({})
    const [contentType, setContentType] = useState({})
    const [favContent, setFavContent] = useState([])
    const [urlError, setUrlError] = useState("")
    const [time, setTime] = useState({
        start: null,
        end: null
    })
    const [showPreviewPopup, setShowPreviewPopup] = useState(false)
    const [tempContentLevel, setTempContentLevel] = useState(CONTENT_LEVEL_OPTION.BRAND)
    const [isShuffleFailed, setIsShuffleFailed] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [selectedSportsSubType, setSelectedSportsSubType] = useState(null)
    const [sportsSubTypeList, setSportsSubTypeList] = useState([])
    const [tempSportsSubType, setTempSportsSubType] = useState(null)
    const [customHeroBanner, setCustomHeroBanner] = useState({})
    const [showForContentID, setShowForContentID] = useState(-1) // default -1 set for hiding
    const [deleteContent, setDeleteContent] = useState(-1) // default -1 set for hiding
    const [customBannerIds, setCustomBannerIds] = useState([])
    const [autoRotation, setAutoRotation] = useState(false)
    const [rotationTime, setRotationTime] = useState(null)

    const sportsCategoryList = useSelector((state) => state.sportsReducer.sportsCategory)
    const availableContentType = useRef({})

    useEffect(() => {
        availableContentType.current =
            contentTypeList?.length &&
            contentTypeList.reduce((acc, obj) => {
                acc[obj.name] = obj.id
                return acc
            }, {})
    }, [contentTypeList])

    const railTypeOptions = useMemo(() => {
        return railTypeList?.filter((item) => item.railCategory === railCategory)
    }, [railTypeList, railCategory])

    const updatedContentTypeList = useMemo(() => {
        if (contentTypeList && parent !== VOD_SPORTS_TAB.VOD) {
            return appendSportsContentType(contentTypeList)
        }
        return contentTypeList
    }, [contentTypeList])

    const filterEnabledLanguage = (languageList = []) => {
        const updatedLanguageList = []
        languageList.forEach(language => {
            if(language?.enable === "true"){
                let tempLang = { ...language }
                tempLang.id = language.code;
                updatedLanguageList.push(tempLang)
            }
        })
        return updatedLanguageList;
    }

    const onLoad = async () => {
        let platforms = await dispatch(fetchPlatformList())
        let contentTypes = await dispatch(fetchContentTypeList())
        let railTypes = await dispatch(fetchRailTypeList())
        let languageList = await dispatch(fetchLanguageList())
        let genreApiResponse = await dispatch(fetchFilters(RAIL_TYPE_CONSTANT.GENRE))
        let artistApiResponse = await dispatch(fetchFilters(RAIL_TYPE_CONSTANT.ACTOR))

        languageList = filterEnabledLanguage(languageList?.data?.languages)
        setLanguageList(languageList)

        genreApiResponse = genreApiResponse?.data
        setGenreList(genreApiResponse)

        artistApiResponse = formateOptions(artistApiResponse?.data)
        setArtistList(artistApiResponse)
        fetchSportsSubType()
        if (id) {
            const initialize = await dispatch(fetchRailById(id))
            if (initialize.status) {
                const { data } = initialize
                let contentTypes = []
                if (data.railCategory === RAIL_CATEGORY.SYSTEM_GENERATED) {
                    const favContentList = await dispatch(fetchSystemGenerateFav())
                    favContentList.data &&
                        favContentList.data.forEach((item) => {
                            if (data.contentType?.includes(item)) {
                                contentTypes.push({
                                    id: item,
                                    name: item
                                })
                            }
                        })
                } else {
                    let contentTypesListData = await dispatch(fetchContentTypeList())
                    contentTypesListData = contentTypesListData?.data
                     // Find the sub type from the api
                    if (parent !== VOD_SPORTS_TAB.VOD) {
                        // Set content Type as Sports
                        contentTypesListData = appendSportsContentType(contentTypesListData)
                        // Setting content sub type
                        const subType = data?.contentList?.[0]?.contentSubType // All sports rails have a same content type
                        const subTypeId = SPORTS_TAB?.[subType?.replaceAll(" ", "_")?.toUpperCase()]
                        setSelectedSportsSubType({
                            id: subTypeId,
                            name: subType
                        })
                    }
                    contentTypes = contentTypesListData?.filter((obj1) =>
                        data.contentList.some(
                            (obj2) =>
                                obj1.localeContentEntityName === obj2.contentType.toUpperCase()
                        )
                    )
                }
                setRedirectionType(data?.redirectionType ?? REDIRECTION_OPTION.IN_APP)
                setRedirectionURL(data?.redirectionUrl ?? "")
                setRailPublished(data?.active)
                setRailCategory(data.railCategory)
                railTypes = railTypes.data.find((item) => item.name === data.railType)
                railTypes = {
                    id: railTypes.name,
                    name: railTypes.name.replace("_", " ")
                }
                setRailType(railTypes)
                setRailName(data.title)

                platforms = platforms.data
                    .filter((item) => data.platform.includes(item.id))
                    .map((item) => item.id)
                setSelectedPlatform(platforms)

                data.railCategory === RAIL_CATEGORY.SYSTEM_GENERATED && data.contentType?.length
                    ? setFavContent(contentTypes)
                    : setContentType(contentTypes?.[contentTypes.length - 1])

                setSelectedMovies(
                    data.contentList.map((item) => ({
                        ...item,
                        contentId: item.id,
                        heroBannerSmallScreenImage: item.heroBannerSmallScreenImage,
                        heroBannerLargeScreenImage: item.heroBannerLargeScreenImage
                    }))
                )
                if (data.smallScreenImageUrl && data.largeScreenImageUrl) {
                    setImageUpload({
                        regularThemeImage: { path: data.smallScreenImageUrl },
                        largeThemeImage: { path: data.largeScreenImageUrl }
                    })
                    data.railType === RAIL_TYPE_CONSTANT.RAIL && setThemeRailCheck(true)
                }
                setautoShuffle(data.autoShuffle)
                setSubType(data.liveType)
                setOrientations(ORIENTATION_MENU[data.orientation])

                let webSeriesType = CONTENT_LEVEL_OPTION.EPISODE
                setContentLevel(webSeriesType)
                setPosition(data.position)
                setCardSize(data.cardSize)
                setTime({ start: data.start, end: data.end })
                setAutoRotation(data.autoScroll)
                setRotationTime(data.scrollingTime)

                const customBannerIds = data.contentList.map((item, index) => {
                    if (item.heroBannerLargeScreenImage || item.heroBannerSmallScreenImage) {
                        return index
                    }
                })
                setCustomBannerIds(customBannerIds)
            }
        }
        setIsLoading(false)
    }

    useEffect(() => {
        onLoad()
    }, [])

    const clearFormData = (value = "") => {
        value === RAIL_TYPE_CONSTANT.CHANNEL_RAIL && getMediaContentList(CONTENT_TYPE_CONST.CHANNEL)
        setRailName("")
        setInputLang("")
        setSelectedPlatform([])
        setThemeRailCheck(false)
        setContentType({})
        setFavContent([])
        setImageUpload({})
        setRedirectionType(REDIRECTION_OPTION.IN_APP)
        setSubType("")
        setSelectedMovies([])
        setRedirectionURL("")
        setContentLevel(CONTENT_LEVEL_OPTION.BRAND)
        setCardSize(CARD_SIZE_LIST[0].id)
        setSelectedSportsSubType(null)
        value === RAIL_TYPE_CONSTANT.CONTINUE_WATCHING || value === RAIL_TYPE_CONSTANT.ADS
            ? setOrientations(1)
            : setOrientations(0)
        setAutoRotation(false)
        setRotationTime(null)
    }

    const handleRailCategory = useCallback((e) => {
        const value = e.target.value
        setRailCategory(value)
        setRailType({})
        clearFormData()
        value === RAIL_CATEGORY.SYSTEM_GENERATED && dispatch(fetchSystemGenerateFav())
        value === RAIL_CATEGORY.NEW && dispatch(fetchContentTypeList())
    }, [])

    const handleSelectChange = useCallback(
        (name, value, selectedOptions) => {
            switch (name) {
                case "railType":
                    const rail = railTypeList.find((item) => item.id === value)
                    setRailType({ name: rail.name, id: value })
                    clearFormData(value)
                    break
                case "contentType":
                    const content = updatedContentTypeList.find((item) => item.id === value)
                    if (selectedOptions?.length || !content) {
                        setFavContent(selectedOptions)
                        break
                    }
                    setMediaContentSearch("")
                    setSubType("")
                    setSelectedSportsSubType(null)
                    setContentType({ name: content?.name, id: value })
                    break
                case "inputLanguage":
                    setInputLang(value)
                    break
                case "subType":
                    setSubType(value)
                    break
                case "redirectionType":
                    setRedirectionType(value)
                    setContentType({})
                    break
                case "sports-subType": {
                    if (selectedMovies?.length > 0) {
                        setShowPreviewPopup(true)
                        setTempSportsSubType(selectedOptions)
                    } else {
                        setSelectedSportsSubType(selectedOptions)
                    }
                }
                default:
                    return
            }
        },
        [railTypeList, updatedContentTypeList, selectedMovies]
    )

    const findDefaultOption = useCallback((item, list) => {
        return list?.find((ele) => {
            if (ele.id === item) {
                return ele
            }
        })
    }, [])

    const formateOptions = (ele) => {
        ele = ele?.map((item) => {
            if (item.name === null || item.name === undefined) {
                return false
            }
            item.name = item.name.replace(/_/g, " ")
            item.name = item.name.toUpperCase()
            return item
        })
        return ele
    }

    const getSportsResponse = async () => {
        const item = findDefaultOption(selectedSportsSubType?.id, sportsSubTypeList)
        const payload = {
            length: -1, // -1 to get all the records
            start: -1,
            pageEntityId: item?.pageEntityId,
            restFilters: "&published=true"
        }
        const res = await dispatch(fetchSelectedSportsDataList(payload))
        const data = formatSportsData(res?.data)
        setMediaContentList(data)
    }

    const fetchSportsSubType = () => {
        if (!sportsCategoryList) {
            dispatch(getSportsCategory())
        } else if (!sportsSubTypeList) {
            const list = getSportsCategoryList(sportsCategoryList)
            setSportsSubTypeList(list)
        }
    }

    const getMediaContentList = async (type) => {
        if (railCategory === RAIL_CATEGORY.SYSTEM_GENERATED) {
            return
        }
        let contentTypeName = type || contentType.name
        if (contentTypeName === CONTENT_TYPE_CONST?.SPORTS) {
            contentTypeName = CONTENT_TYPE_CONST?.MOVIES
            if (selectedSportsSubType !== null) {
                return getSportsResponse()
            }
            fetchSportsSubType()
        }
        let payload = {
            contentType: contentTypeName,
            limit: 100,
            offset: 0,
            search: mediaContentSearch
        }
        let response = await dispatch(searchContent(payload))
        if (response?.status) {
            if (response.data?.customFormat === true) {
                setMediaContentList(response.data.results)
            } else {
                // let temp = response.data.results.map((item) => item.meta)
                setMediaContentList(response.data.results)
            }
        } else {
            setMediaContentList([])
        }
    }

    const setSearchQuery = debounce((name, value) => {
        getMediaContentList()
    }, 300)

    const handleSearch = useCallback(
        (name, value) => {
            setSearchQuery(name, value)
        },
        [mediaContentSearch, language, genre, contentType]
    )

    const addMovie = useCallback(
        (movie) => {
            setSelectedMovies([...selectedMovies, movie])
        },
        [selectedMovies]
    )

    const removeMovie = useCallback(
        (movie) => {
            const movieId = movie.id || movie.seasonId || movie.episodeId
            const newList = selectedMovies.filter((item) => {
                const itemId = item.id || item.seasonId || item.episodeId
                if (itemId !== movieId) {
                    return item
                }
                return false
            })
            setSelectedMovies(newList)
        },
        [selectedMovies]
    )

    const checkIfSelected = useCallback(
        (id) => {
            let found = false
            if (selectedMovies.length) {
                selectedMovies.forEach((item) => {
                    if (item.id === id) {
                        found = true
                    }
                })
            }
            return found
        },
        [selectedMovies]
    )

    const handleSeasonPopupClose = useCallback(() => {
        setShowPopup(false)
    }, [])

    const addSeasonCheckBox = useCallback(
        (name, checked, id) => {
            if (checked) {
                setWebSeriesToAdd([...webSeriesToAdd, id])
            }
        },
        [webSeriesToAdd]
    )

    const handleAddSeries = useCallback(
        (series) => {
            let value = [...selectedMovies, series]
            if (!webSeriesToAdd.includes(0)) {
                webSeriesToAdd.forEach((ele) => {
                    if (ele) {
                        value = [...selectedMovies, { ...series, subContentId: ele }]
                    }
                })
            }
            setSelectedMovies(value)
            handleSeasonPopupClose()
        },
        [selectedMovies, webSeriesToAdd]
    )

    const searchListOptionBySeason = (data) => {
        if (data) {
            const { logo, title, contentType, seasonList } = data
            return seasonList?.map((value, index) => {
                const payloadObj = {
                    ...value,
                    ...{
                        contentId: data.id,
                        id: value?.seasonId,
                        title,
                        subTitle: value?.title,
                        portraitImage: data.posterImage,
                        posterImage: value?.seasonPosterImage,
                        coverImage: data.coverImage,
                        logo: value?.seasonPosterImage,
                        yearOfRelease: value?.seasonReleaseYear
                    }
                }
                return (
                    <li
                        className={`searchList-item ${
                            checkIfSelected(value?.seasonId) ? "selected " : ""
                        }`}>
                        <div className="imgContainer">
                            <img
                                src={
                                    value?.seasonPosterImage
                                        ? joinUrl(value?.seasonPosterImage)
                                        : logo
                                        ? joinUrl(logo)
                                        : `${NoImgAvailable}`
                                }
                                alt="title"
                                onError={(e) => {
                                    e.target.onerror = null
                                    e.target.src = `${NoImgAvailable}`
                                }}
                            />
                        </div>
                        <div className="movieDetails">
                            <h6>{title && title}</h6> {/* brand title */}
                            <p>
                                {value?.seasonReleaseYear && (
                                    <span>{value?.seasonReleaseYear} | </span> /* season year */
                                )}
                                {<span>{value?.title}</span>} {/* season no */}
                            </p>
                        </div>
                        <AuthWrapper permissions={[PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_EDIT]}>
                            <div
                                className={
                                    !checkIfSelected(value?.seasonId)
                                        ? "expand-collapse-icon icon-plus"
                                        : "expand-collapse-icon icon-minus"
                                }
                                onClick={() =>
                                    !checkIfSelected(value?.seasonId)
                                        ? addMovie(payloadObj)
                                        : removeMovie(payloadObj)
                                }
                            />
                        </AuthWrapper>
                    </li>
                )
            })
        } else {
            return <span>{TEXT_CONSTANTS?.NO_RECORDS_FOUND}</span>
        }
    }

    const searchListOption = (data) => {
        if (data) {
            const { logo, title, yearOfRelease, genres, details, id, seasonList } = data
            let directors
            if (details) {
                directors = details.director
            }
            const posterImage = data?.images?.find((item) => item?.type === "poster")
            return (
                <li
                    className={`searchList-item ${checkIfSelected(id) ? "selected " : ""}${
                        contentType?.name === "LIVE_TV" ? "liveTv" : ""
                    }`}>
                    <div className="imgContainer">
                        <img
                            src={posterImage ? joinUrl(posterImage.url) : `${NoImgAvailable}`}
                            alt="title"
                            onError={(e) => {
                                e.target.onerror = null
                                e.target.src = `${NoImgAvailable}`
                            }}
                        />
                    </div>
                    <div className="movieDetails">
                        <h6>{title}</h6>
                        <p>
                            {yearOfRelease && <span>{yearOfRelease}</span>}
                            {(!isEmpty(genres) || !isEmpty(directors)) && <span> |</span>}
                            {genres && genres.map((item) => <span>{item}</span>)}
                        </p>
                        <p>{directors && directors.map((item) => <span>{item.name}</span>)}</p>
                    </div>
                    <AuthWrapper permissions={[PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_EDIT]}>
                        {!checkIfSelected(id) ? (
                            <div
                                className={`expand-collapse-icon icon-plus ${
                                    [RAIL_TYPE_CONSTANT.CHANNEL_RAIL].includes(railType.id) &&
                                    selectedMovies.length
                                        ? "no-cursor-allowed"
                                        : ""
                                }
                                    `}
                                onClick={() =>
                                    addMovie({ ...data, contentType: contentType.name })
                                }></div>
                        ) : (
                            <div
                                className="expand-collapse-icon icon-minus"
                                onClick={() => {
                                    removeMovie(data)
                                }}></div>
                        )}
                    </AuthWrapper>
                    {contentType?.name === CONTENT_TYPE_CONST.WEB_SERIES && (
                        <div className={`seasonFilter`}>
                            <div className="header">
                                <div className="infoLabel">
                                    <p>{TEXT_CONSTANTS?.SELECT_SEASON}</p>
                                    <i className="icon-close" onClick={handleSeasonPopupClose}></i>
                                </div>
                                <p className="seasonTitle">{data?.title}</p>
                            </div>
                            <div className="seasonList">
                                <Checkbox
                                    cName="featured-checkBox"
                                    labelText={"all season (group)"}
                                    id={0}
                                    name={"all-season"}
                                    changeHandler={addSeasonCheckBox}
                                />
                                {seasonList &&
                                    seasonList.map((item) => {
                                        return (
                                            <Checkbox
                                                cName="featured-checkBox"
                                                labelText={`${item.title} , ${item.episodes.length} episodes`}
                                                id={item.id}
                                                name={item.title}
                                                changeHandler={addSeasonCheckBox}
                                            />
                                        )
                                    })}
                            </div>

                            <Button
                                cName={"btn secondary-btn"}
                                bValue={BUTTON_CONSTANTS?.ADD}
                                clickHandler={() => handleAddSeries(data)}
                            />
                        </div>
                    )}
                </li>
            )
        } else {
            return <span>{TEXT_CONSTANTS?.NO_RECORDS_FOUND}</span>
        }
    }

    const webSeriesListOption = (data) => {
        const { posterImage, title, id, coverImage, seasonList } = data
        return seasonList?.map((value, index) => {
            return (
                <WebSeriesContentContainer
                    data={value}
                    meta={{ title, posterImage, id, coverImage }}
                    addMovie={addMovie}
                    removeMovie={removeMovie}
                    checkIfSelected={checkIfSelected}
                />
            )
        })
    }

    const contentLevelOption = useCallback(
        (item) => {
            if (contentType.name !== CONTENT_TYPE_CONST.WEB_SERIES) {
                return searchListOption(item)
            } else {
                if (contentLevel === CONTENT_LEVEL_OPTION.SEASON) {
                    return searchListOptionBySeason(item)
                } else if (contentLevel === CONTENT_LEVEL_OPTION.EPISODE) {
                    return webSeriesListOption(item)
                } else {
                    return searchListOption(item)
                }
            }
        },
        [contentType, contentLevel, selectedMovies]
    )

    const handleRadioChange = useCallback((e) => {
        setShowPreviewPopup(true)
        setTempContentLevel(toNumber(e.target.value))
    }, [])

    const handlePreviewModal = useCallback(() => {
        setShowPreviewPopup(false)
        setSelectedMovies([])
        setContentLevel(tempContentLevel)
        setSelectedSportsSubType(tempSportsSubType)
    }, [tempContentLevel, tempSportsSubType])

    const closeScheduleModal = () => {
        setScheduleModal(!scheduleModal)
    }

    const checkboxHandleChange = useCallback(
        (name, checked, id) => {
            if (name === "themeRailCheck") {
                setThemeRailCheck(checked)
            } else if (name === "autoRotation") {
                setAutoRotation(checked)
            } else {
                let value
                if (checked) value = [...selectedPlatform, id]
                else value = selectedPlatform.filter((item) => item !== id)
                setSelectedPlatform(value)
            }
        },
        [selectedPlatform]
    )

    const handleAutoUpdateFilter = useCallback(
        (obj) => {
            setSaveByApply(true)
            setautoShuffle(obj)
            setIsClick(!isClick)
        },
        [isClick]
    )

    const onChangeFilter = useCallback((id, value) => {
        if (id === "orientations") {
            setOrientations(value)
        } else if (id === "cardSize") {
            setCardSize(value)
        }
    }, [])

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    }

    const changePosition = useCallback((movies) => {
        setSelectedMovies(movies)
    }, [])

    const onDragEnd = useCallback(
        (result) => {
            // dropped outside the list
            if (!result.destination) {
                return
            }

            const selection = reorder(selectedMovies, result.source.index, result.destination.index)

            setSelectedMovies(selection)
        },
        [selectedMovies]
    )

    const handleMovieUnpublishFromRail = useCallback(
        (contentId) => {
            let unpublished = unpublishedContent
            if (unpublished.includes(contentId)) {
                unpublished = unpublished.filter((item) => item !== contentId)
            } else {
                unpublished.push(contentId)
            }
            setUnpublishedContent(unpublished)
        },
        [unpublishedContent]
    )

    const isContentPublished = useCallback(
        (id) => {
            return !unpublishedContent.includes(id)
        },
        [unpublishedContent]
    )

    const toggleIsClick = useCallback(() => {
        if (isShuffleFailed) {
            return
        }
        setIsClick(!isClick)
    }, [isClick, isShuffleFailed])

    const handleReorder = useCallback(
        (start, end) => {
            const selection = reorder(selectedMovies, start, end)
            setSelectedMovies(selection)
        },
        [selectedMovies]
    )

    const handleUpdateImage = useCallback(
        (portraitImage, landscapeImage, id) => {
            let updatedMovieList
            updatedMovieList = selectedMovies.map((item) => {
                if (item.id === id) {
                    if (orientations === ORIENTATION_MENU.PORTRAIT) {
                        item.regularThemeImage = portraitImage
                        item.largeThemeImage = null
                        return item
                    } else {
                        item.regularThemeImage = null
                        item.largeThemeImage = landscapeImage
                        return item
                    }
                } else {
                    return item
                }
            })
            setSelectedMovies(updatedMovieList)
            setImageActive(false)
        },
        [selectedMovies]
    )

    const handlePromoImageUpload = useCallback(
        async (data, id, name) => {
            data.append("type", "CONTENT_MAPPING")
            const response = await dispatch(uploadImage(data))
            if (response?.status) {
                setImageUpload({ ...imageUpload, [id]: response.data })
                setOrientations(1)
            }
        },
        [imageUpload, orientations]
    )

    const handleChangeImageUpload = useCallback((item) => {
        setImageActive(true)
        setItemToChangeImg(item)
    }, [])

    const handleRemoveImage = useCallback(
        (id) => {
            setImageUpload({ ...imageUpload, [id]: null })
        },
        [imageUpload]
    )

    const transformAgeRating = useCallback((ageRating) => {
        let ageRatingArray = ageRating?.map((value, index) => {
            return value.name ? value.name : { name: value, id: value }
        })
        return ageRatingArray
    }, [])

    const submitData = useCallback(
        async (payloadObj) => {
            let response
            let shuffleFailed = false
            if (saveByApply) {
                response = await dispatch(autoShuffleCreateRail(payloadObj))
                if (!response?.status) {
                    toast.error(response.message)
                    shuffleFailed = true
                }
                setIsShuffleFailed(shuffleFailed)
                setSaveByApply(false)
                toggleIsClick()
            }
            response = shuffleFailed ? response : await dispatch(createRail(payloadObj))
            if (response?.status) {
                toast.success(response.message)
                handleBackClick()
            } else {
                toast.error(response.message)
            }
        },
        [isClick, isShuffleFailed, saveByApply]
    )
    const contentTypeValue = useMemo(() => {
        let value = {
            id: [],
            name: []
        }
        selectedMovies.length &&
            selectedMovies.forEach((item) => {
                if (!value.name.includes(item.contentType)) {
                    value.name.push(item.contentType)
                    value.id.push(availableContentType.current?.[item.contentType])
                }
            })
        if (railType.id === RAIL_TYPE_CONSTANT.FAVOURITE) {
            favContent.forEach((item) => {
                value.id.push(item.name)
                value.name.push(item.name)
            })
        }
        return value
    }, [railType, favContent, selectedMovies, availableContentType])

    const validateUrl = useCallback((err, name) => {
        if (err) {
            setUrlError("Please enter valid url")
        } else {
            setUrlError("")
        }
    }, [])

    const getContentTypeName = (item) => {
        if(railType.id === RAIL_TYPE_CONSTANT.CHANNEL_RAIL){
            return CONTENT_TYPE_CONST.CHANNEL
        }
        if(item?.contentType?.toLowerCase() === CONTENT_TYPE_CONST?.SPORTS?.toLowerCase() && !selectedSportsSubType){
            return CONTENT_TYPE_CONST?.MOVIES?.toLowerCase()
        }
        return item.contentType
    }

    const handleOnSave = async (scheduledTime = {}) => {
        dispatch(setLoader())
        let newPosition = {}
        if (!position || position === -1) {
            newPosition = await dispatch(getLatestPosition())
        }

        const movieList = selectedMovies.map((item, index) => {
            return {
                id:
                    contentType.name === CONTENT_TYPE_CONST.WEB_SERIES && contentLevel !== 0
                        ? item.contentId
                        : item.id,
                contentType: getContentTypeName(item),
                position: index,
                heroBannerLargeScreenImage: item.heroBannerLargeScreenImage,
                heroBannerSmallScreenImage: item.heroBannerSmallScreenImage,
                ...(parent !== VOD_SPORTS_TAB.VOD && {
                  contentSubType: selectedSportsSubType?.name
            }),
            }
        })

        let scheduled,
            layout = "PORTRAIT"
        scheduled = !(time.end === null && time.start === null)

        if (orientations === ORIENTATION_MENU.LANDSCAPE) layout = "LANDSCAPE"
        else if (orientations === ORIENTATION_MENU.CIRCULAR) layout = "CIRCULAR"

        const sportCategoryItem = allCategoryList?.find((item) => item?.hasPageCategory)
        const payloadObj = {
            active: isRailPublished,
            title: railName,
            platform: selectedPlatform,
            page: parent !== VOD_SPORTS_TAB.VOD ? sportCategoryItem?.id : selectedTab, // TODO: SPORTS ki category Id,
            railCategory: railCategory,
            railType: railType.id,
            orientation: layout,
            contentList: movieList,
            cardSize,
            end: scheduledTime?.end,
            start: scheduledTime?.start,
            position: id ? position : newPosition.data + 1,
            autoShuffle: { autoShuffle: !!autoShuffle?.autoShuffle },
            autoScroll: autoRotation,
            scrollingTime: rotationTime,
            smallScreenImageUrl: imageUpload.regularThemeImage
                ? imageUpload.regularThemeImage.path
                : null,
            largeScreenImageUrl: imageUpload.largeThemeImage
                ? imageUpload.largeThemeImage.path
                : null,
            ...(contentType?.name === CONTENT_TYPE_CONST.LIVE_TV && { liveType: subType }),
            seeAllFlag: false,
            useCaseId: "",
            redirectionType: redirectionType,
            redirectionUrl: redirectionURL,
            ...(parent !== VOD_SPORTS_TAB.VOD && {
                pageCategoryId: selectedTab, // page Category ID ex cricket, tennis or virat
                pageCategoryRail: parent === VOD_SPORTS_TAB.CREATE_MANAGE_PAGE, // False for sports home page
                contentSubType: selectedSportsSubType?.name
            }),
            ...(railCategory === RAIL_CATEGORY.SYSTEM_GENERATED && {
                contentType: contentTypeValue.id
            })
        }
        if (id) {
            payloadObj.id = id
        }
        submitData(payloadObj)
    }

    const handleSchedulePublish = (start, end) => {
        const startTimestamp = new Date(start).getTime()
        const endTimestamp = new Date(end).getTime()
        const timeObj = { start: startTimestamp, end: endTimestamp }
        setTime(timeObj)
        closeScheduleModal()
        handleOnSave(timeObj)
    }

    const checkRail = useCallback(
        (value) => {
            let mustHave = value && selectedMovies.length
            if (themeRailCheck) {
                mustHave = mustHave && imageUpload.largeThemeImage && imageUpload.regularThemeImage
            }
            if (contentType?.name === CONTENT_TYPE_CONST.LIVE_TV) {
                mustHave = mustHave && subType
            }
            return !mustHave
        },
        [selectedMovies, imageUpload, subType, contentType, themeRailCheck]
    )

    const checkPromoBanner = useCallback(
        (value) => {
            let mustHave = value && imageUpload?.regularThemeImage && imageUpload.largeThemeImage
            if (contentType?.name === CONTENT_TYPE_CONST.LIVE_TV) {
                mustHave = mustHave && selectedMovies.length && subType
            } else if (redirectionType === REDIRECTION_OPTION.EXTERNAL) {
                mustHave = mustHave && redirectionURL && !urlError
            } else {
                mustHave = mustHave && selectedMovies.length
            }
            return !mustHave
        },
        [
            contentType,
            imageUpload,
            selectedMovies,
            redirectionURL,
            redirectionType,
            subType,
            urlError
        ]
    )

    const checkHeroBanner = useCallback(
        (value) => {
            let mustHave = value && contentType.name && selectedMovies.length
            if (contentType.name === CONTENT_TYPE_CONST.LIVE_TV) {
                mustHave = mustHave && subType
            } else if (autoRotation) {
                mustHave = mustHave && rotationTime && rotationTime > 0
            }
            return !mustHave
        },
        [subType, contentType, selectedMovies, autoRotation, rotationTime]
    )

    const validateData = useMemo(() => {
        const mustHave = selectedPlatform.length && railName
        switch (railType.id) {
            case RAIL_TYPE_CONSTANT.CONTINUE_WATCHING:
                return !mustHave
            case RAIL_TYPE_CONSTANT.ADS:
                return !mustHave
            case RAIL_TYPE_CONSTANT.FAVOURITE:
                return !(mustHave)
            case RAIL_TYPE_CONSTANT.RAIL:
                return checkRail(mustHave)
            case RAIL_TYPE_CONSTANT.PROMOTION_BANNER:
                return checkPromoBanner(mustHave)
            case RAIL_TYPE_CONSTANT.CHANNEL_RAIL:
                return !(mustHave && selectedMovies.length)
            case RAIL_TYPE_CONSTANT.HERO_BANNER:
                return checkHeroBanner(mustHave)
            case RAIL_TYPE_CONSTANT.LANGUAGE_BASED:
            case RAIL_TYPE_CONSTANT.GENERIC_BASED:
            case RAIL_TYPE_CONSTANT.GENRE_BASED:
                return !(mustHave && contentType.name)
            default:
                return !(mustHave && contentType.name && selectedMovies.length)
        }
    }, [
        contentType,
        selectedMovies,
        selectedPlatform,
        railName,
        favContent,
        imageUpload,
        subType,
        themeRailCheck,
        redirectionURL,
        inputLang,
        autoRotation,
        rotationTime
    ])

    const filteredPlatforms = useMemo(() => {
        if (parent !== VOD_SPORTS_TAB.VOD) {
            return platformList // For sports all platforms are applicable as they are not plaform specific
        } else if (categoryTabList?.length && platformList?.length) {
            const selectedCategoryTab = categoryTabList.find(
                (item) => item.name === selectedTabName
            )
            return platformList.filter((item) =>
                selectedCategoryTab?.categoryPlatforms?.find((obj) => obj.platformId === item.id)
            )
        }
    }, [platformList, categoryTabList, selectedTabName])

    const handleDeleteModal = useCallback(() => {
        let selectedContent = [...selectedMovies]
        selectedContent[deleteContent] = {
            ...selectedContent[deleteContent],
            heroBannerLargeScreenImage: null,
            heroBannerSmallScreenImage: null
        }
        setSelectedMovies(selectedContent)
        setDeleteContent(-1)
    }, [selectedMovies, deleteContent])

    const toggleCustomBannerModal = (index) => {
        setShowForContentID(index)
    }

    const uploadCustomHeroBanner = useCallback(
        async (data, id) => {
            const response = await dispatch(uploadImage(data))
            if (response?.status) {
                let selectedContent = [...selectedMovies]
                selectedContent[showForContentID] = {
                    ...selectedContent[showForContentID],
                    [id]: response.data?.path
                }
                setSelectedMovies(selectedContent)
            }
        },
        [showForContentID, selectedMovies]
    )

    const removeCustomBannerImage = useCallback(
        (id) => {
            let selectedContent = [...selectedMovies]
            selectedContent[showForContentID] = {
                ...selectedContent[showForContentID],
                [id]: null
            }
            setSelectedMovies(selectedContent)
        },
        [selectedMovies, showForContentID]
    )

    const cancelCustomHeroBanner = useCallback(() => {
        if (customBannerIds.includes(showForContentID)) {
            return
        }
        let selectedContent = [...selectedMovies]
        selectedContent[showForContentID] = {
            ...selectedContent[showForContentID],
            heroBannerLargeScreenImage: null,
            heroBannerSmallScreenImage: null
        }
        setSelectedMovies(selectedContent)
    }, [selectedMovies, showForContentID, customBannerIds])

    const saveCustomContent = useCallback(() => {
        setCustomBannerIds([...customBannerIds, showForContentID])
    }, [showForContentID, customBannerIds])

    useEffect(() => {
        if (railType.id === RAIL_TYPE_CONSTANT.CHANNEL_RAIL) {
            getMediaContentList(CONTENT_TYPE_CONST.CHANNEL)
        } else {
            contentType?.name && handleSearch()
        }
    }, [mediaContentSearch, contentType, railType])

    useEffect(() => {
        if (isEmpty(contentType)) {
            setMediaContentList([])
        } else {
            getMediaContentList()
        }
    }, [contentType, selectedSportsSubType])

    useEffect(() => {
        setSelectedMovies([])
        setMediaContentList([])
    }, [redirectionType])

    useEffect(() => {
        if (sportsCategoryList) {
            const list = getSportsCategoryList(sportsCategoryList)
            setSportsSubTypeList(list)
        }
    }, [sportsCategoryList])

    return (
        <div className="vr-addRail">
            <header className="vr-cont-head">
                <div className="head-left">
                    <i className="icon-arrow-left1" onClick={handleBackClick} />
                    <h4>
                        {id ? TEXT_CONSTANTS?.EDIT_RAIL : TEXT_CONSTANTS.ADD_NEW_RAIL}
                        <span>
                            {TEXT_CONSTANTS?.UNDER} {selectedTabName} {TEXT_CONSTANTS?.TAB}
                        </span>
                    </h4>
                </div>
            </header>
            {showPreviewPopup && (
                <CustomModal
                    showModal={showPreviewPopup}
                    submitHandler={handlePreviewModal}
                    cancelHandler={() => setShowPreviewPopup(false)}
                    closeOnSubmit={true}
                    submitButtonLabel={BUTTON_CONSTANTS?.YES}
                    showCancelButton={true}
                    cancelOnOutsideClick={false}
                    showCloseIcon={false}
                    submitBtnClass={"confirmation-submit-btn"}
                    cancelBtnClass={"confirmation-cancel-btn"}>
                    <div className="confirmation-modal-container">
                        <h3>{`${TEXT_CONSTANTS?.CONFIRM_POPUP_CONTENT_LEVEL}: `}</h3>
                    </div>
                </CustomModal>
            )}
            <div className="cont-container">
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <div className="content-holder">
                            <div className="content-form">
                                <RailCategory
                                    handleRailCategory={handleRailCategory}
                                    railCategory={railCategory}
                                />
                                <div className="rail-type-container">
                                    <Select
                                        value={railType}
                                        options={railTypeOptions}
                                        isOptionDisabled={(option) => option.isdisabled}
                                        cName={"rail-type"}
                                        name={"railType"}
                                        label={"RAIL TYPE"}
                                        required
                                        placeholder={"Select"}
                                        changeHandler={handleSelectChange}
                                    />
                                    <Select
                                        value={findDefaultOption(inputLang, languageList)}
                                        options={languageList}
                                        cName={"rail-type"}
                                        placeholder={"Select"}
                                        name={"inputLanguage"}
                                        label={"SELECT INPUT LANGUAGE"}
                                        changeHandler={handleSelectChange}
                                    />
                                </div>
                                <Input
                                    id="searchBar"
                                    cName={"movie-search-bar"}
                                    placeholder="Rail Name"
                                    value={railName}
                                    labelText={"Enter Rail Name"}
                                    changeHandler={(name, value) => setRailName(value)}
                                    required
                                    maxLength={40}
                                />

                                <FeaturedIn
                                    platformList={filteredPlatforms}
                                    checkboxHandleChange={checkboxHandleChange}
                                    selectedPlatform={selectedPlatform}
                                />
                                {railType.id === RAIL_TYPE_CONSTANT.HERO_BANNER && (
                                    <div className="auto-rotation-container">
                                        <Checkbox
                                            cName="featured-checkBox"
                                            labelText={"auto rotation"}
                                            name={"autoRotation"}
                                            changeHandler={checkboxHandleChange}
                                            isChecked={autoRotation}
                                        />
                                        <Input
                                            id="autoRotation"
                                            cName={""}
                                            placeholder="Enter in seconds"
                                            labelText={"Rotation Time"}
                                            value={rotationTime}
                                            changeHandler={(name, value) =>
                                                setRotationTime(parseInt(value))
                                            }
                                            type="number"
                                            disabled={!autoRotation}
                                        />
                                    </div>
                                )}
                                {railType.id === RAIL_TYPE_CONSTANT.PROMOTION_BANNER && (
                                    <div className="image-container">
                                        <ImageUpload
                                            key={"banner"}
                                            name={"Small Screen"}
                                            id={"regularThemeImage"}
                                            label={"Add Image for Small Device"}
                                            required={true}
                                            value={
                                                imageUpload.regularThemeImage
                                                    ? imageUpload.regularThemeImage.path
                                                    : null
                                            }
                                            cName={"landscape"}
                                            uploadImage={handlePromoImageUpload}
                                            removeImage={handleRemoveImage}
                                        />
                                        <ImageUpload
                                            key={"banner2"}
                                            name={"Large Screen"}
                                            id={"largeThemeImage"}
                                            label={"Add Image for Large Screen"}
                                            required={true}
                                            value={
                                                imageUpload.largeThemeImage
                                                    ? imageUpload.largeThemeImage.path
                                                    : null
                                            }
                                            cName={"landscape"}
                                            uploadImage={handlePromoImageUpload}
                                            removeImage={handleRemoveImage}
                                        />
                                    </div>
                                )}
                                {railType.id === RAIL_TYPE_CONSTANT.PROMOTION_BANNER && (
                                    <Select
                                        value={findDefaultOption(redirectionType, REDIRECTION_LIST)}
                                        options={REDIRECTION_LIST}
                                        cName={"rail-type"}
                                        placeholder={"Select"}
                                        name={"redirectionType"}
                                        label={"Redirection Type"}
                                        required
                                        changeHandler={handleSelectChange}
                                        isAutoFocus={false}
                                    />
                                )}

                                {redirectionType === REDIRECTION_OPTION.EXTERNAL && (
                                    <div className="content-type">
                                        <Input
                                            id="searchBar"
                                            cName={"movie-search-bar"}
                                            placeholder="Enter URL"
                                            value={redirectionURL}
                                            labelText={"Enter Redirection Url"}
                                            changeHandler={(name, value) =>
                                                setRedirectionURL(value)
                                            }
                                            name="redirectionUrl"
                                            required
                                            isUrl={true}
                                            errMsg={urlError || null}
                                            validateUrl={validateUrl}
                                        />
                                    </div>
                                )}

                                {!isEmpty(railType) &&
                                    ![
                                        RAIL_TYPE_CONSTANT.CONTINUE_WATCHING,
                                        RAIL_TYPE_CONSTANT.ADS,
                                        RAIL_TYPE_CONSTANT.LANGUAGE_BASED,
                                        RAIL_TYPE_CONSTANT.GENERIC_BASED,
                                        RAIL_TYPE_CONSTANT.GENRE_BASED,
                                        RAIL_TYPE_CONSTANT.ADS,
                                        RAIL_TYPE_CONSTANT.FAVOURITE
                                    ].includes(railType.id) &&
                                    redirectionType !== REDIRECTION_OPTION.EXTERNAL && (
                                        <ContentType
                                            handleSelectChange={handleSelectChange}
                                            mediaContentSearch={mediaContentSearch}
                                            setMediaContentSearch={setMediaContentSearch}
                                            findDefaultOption={findDefaultOption}
                                            contentLevelOption={contentLevelOption}
                                            contentType={contentType}
                                            subType={subType}
                                            subTypeList={SUB_TYPE_LIST}
                                            contentTypeList={updatedContentTypeList}
                                            mediaContentList={mediaContentList}
                                            genreList={genreList}
                                            languageList={languageList}
                                            contentLevel={contentLevel}
                                            handleRadioChange={handleRadioChange}
                                            railType={railType}
                                            checkboxHandleChange={checkboxHandleChange}
                                            themeRailCheck={themeRailCheck}
                                            handlePromoImageUpload={handlePromoImageUpload}
                                            imageUpload={imageUpload}
                                            language={language}
                                            genre={genre}
                                            setGenre={setGenre}
                                            setLanguage={setLanguage}
                                            railCategory={railCategory}
                                            favContent={favContent}
                                            handleRemoveImage={handleRemoveImage}
                                            sportsSubTypeList={sportsSubTypeList}
                                            selectedSportsSubType={selectedSportsSubType}
                                        />
                                    )}
                            </div>

                            <ContentPreview
                                orientations={orientations}
                                selectedMovieList={selectedMovies}
                                contentType={contentType}
                                autoShuffle={autoShuffle}
                                railType={railType}
                                handleOnSave={handleOnSave}
                                toggleIsClick={toggleIsClick}
                                isClick={isClick}
                                handleAutoUpdateFilter={handleAutoUpdateFilter}
                                onChangeFilter={onChangeFilter}
                                findDefaultOption={findDefaultOption}
                                changePosition={changePosition}
                                removeMovie={removeMovie}
                                onDragEnd={onDragEnd}
                                handleMovieUnpublishFromRail={handleMovieUnpublishFromRail}
                                isContentPublished={isContentPublished}
                                handleReorder={handleReorder}
                                handleUpdateImage={handleUpdateImage}
                                handleChangeImageUpload={handleChangeImageUpload}
                                cardSize={cardSize}
                                railCategory={railCategory}
                                railName={railName}
                                toggleCustomBannerModal={toggleCustomBannerModal}
                                customHeroBanner={selectedMovies}
                                openDeleteModal={setDeleteContent}
                            />
                        </div>
                        <div className="buttons">
                            <AuthWrapper
                                permissions={[PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_EDIT]}>
                                {(!id || !isRailPublished) && (
                                    <Button
                                        cName={"btn primary-btn scheduleBtn"}
                                        bValue={
                                            time.start
                                                ? BUTTON_CONSTANTS.PUBLISH_SCHEDULED
                                                : BUTTON_CONSTANTS.SCHEDULE_PUBLISH
                                        }
                                        clickHandler={closeScheduleModal}
                                        disabled={validateData}
                                    />
                                )}
                                <Button
                                    cName={"btn secondary-btn"}
                                    bValue={id ? BUTTON_CONSTANTS.UPDATE : BUTTON_CONSTANTS.SAVE}
                                    clickHandler={handleOnSave}
                                    disabled={validateData || isLoadingAPI}
                                />
                            </AuthWrapper>
                            <Button
                                cName={"btn cancel-btn"}
                                bValue={BUTTON_CONSTANTS?.CANCEL}
                                clickHandler={handleBackClick}
                            />
                        </div>
                    </>
                )}
            </div>
            {scheduleModal && (
                <SchedulePublishRail
                    start={time.start}
                    end={time.end}
                    closeScheduleModal={closeScheduleModal}
                    handleSchedulePublish={handleSchedulePublish}
                />
            )}
            {!!(showForContentID + 1) ? (
                <CustomHeroBanner
                    customHeroBanner={selectedMovies[showForContentID]}
                    closeScheduleModal={toggleCustomBannerModal}
                    uploadImage={uploadCustomHeroBanner}
                    removeImage={removeCustomBannerImage}
                    cancelHandler={cancelCustomHeroBanner}
                    saveContent={saveCustomContent}
                />
            ) : null}

            {!!(deleteContent + 1) ? (
                <CustomModal
                    showModal={deleteContent + 1}
                    submitHandler={handleDeleteModal}
                    cancelHandler={() => setDeleteContent(-1)}
                    closeOnSubmit={true}
                    submitButtonLabel={BUTTON_CONSTANTS?.YES}
                    showCancelButton={true}
                    cancelOnOutsideClick={false}
                    showCloseIcon={false}
                    submitBtnClass={"confirmation-submit-btn"}
                    cancelBtnClass={"confirmation-cancel-btn"}>
                    <div className="confirmation-modal-container">
                        <h3>{`${TEXT_CONSTANTS?.CONFIRM_POPUP_CONTENT_LEVEL}: `}</h3>
                    </div>
                </CustomModal>
            ) : null}
        </div>
    )
}

export default AddNewRail

export const Loader = () => {
    return (
        <div className="loader-container container">
            <div className="loader-form">
                <div className="sub">
                    <div className="subItem"></div>
                </div>
                <div className="placeholder-items"></div>
                <div className="sub">
                    <div className="subItem"></div>
                    <div className="subItem"></div>
                </div>
                <div className="placeholder-items"></div>
                <div className="sub">
                    <div className="subItem"></div>
                </div>
                <div className="placeholder-items"></div>
            </div>
            <div className="loader-preview"></div>
        </div>
    )
}
