import ApiService from "../../../utils/apiService"
import { API_ROOT_PATH, API_TENANT_ROOT_PATH } from "../../../config/apiPath"

class ForceUpgradeService {
    setConfig(data) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/v1/app-upgrades`,
            isAuthRequired: true,
            data: data
        }
        return ApiService(options)
    }

    getConfig(data, status) {
        let platforms = data.platforms ? data.platforms?.map((item) => `${item}`) : []
        const options = {
            method: "GET",
            url: `${API_TENANT_ROOT_PATH}/gateway/content/v1/app-upgrades?active=${status}&pageNo=${data.pageNo}&platforms=${platforms}&size=${data.size}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    toggleState(data, id) {
        const options = {
            method: "POST",
            url: `${API_TENANT_ROOT_PATH}/gateway/content/v1/app-upgrades/${id}/change-status`,
            isAuthRequired: true,
            data: data
        }
        return ApiService(options)
    }

    fetchLanguageList() {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/language/getLanguageList`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
    validateForceUpgrade(data) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/v1/app-upgrades/validate?platforms=${data.platform}&updateType=${data.updateType}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
}
const ForceUpgradeServiceInstance = new ForceUpgradeService()
export default ForceUpgradeServiceInstance
