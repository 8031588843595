import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { isEmpty } from "lodash"

import NoImage from "../../../assets/images/no_image.jpg"
import MapChart from "../MapChart"
import { imagePathPrefix } from "../../../config/apiPath"
import "./style.scss"

/* admin work */
class TenantAcrossWorld extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isSuperAdmin: true,
            selectedTab: "by_country",
            selectedCountry: 0
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            this.loadHandler(nextProps)
        }
    }

    componentDidMount() {
        this.loadHandler(this.props)
    }

    loadHandler = (props) => {
        const { data } = props
        const { selectedTab } = this.state
        let countryData = data?.[selectedTab]?.summary && Object.keys(data?.[selectedTab]?.summary)
        let details = data?.[selectedTab]?.details
        let country = {}
        let countriesInfo = []
        let countriesArray = []
        for (let index in countryData) {
            country.value = countryData[Number(index)]
            country.label = countryData[Number(index)]
            country.index = index
            country.details = details[countryData[Number(index)]]
            countriesInfo.push(country)
            countriesArray.push(country.label)
            country = {}
        }
        this.setState({
            countries: !isEmpty(countriesInfo) ? countriesInfo : [],
            countriesArray
        })
    }

    setSelectedCountry = (index) => {
        this.setState({
            selectedCountry: index
        })
    }

    render() {
        const { countries, selectedCountry, selectedTab, countriesArray } = this.state
        return (
            <div className="tenant-world-container">
                <div className="tenant-detail-container">
                    <p>TENANTS ACROSS THE WORLD</p>
                    <div className="tab-container">
                        <span
                            className={`tab ${selectedTab === "by_country" ? "active-color" : ""}`}
                        >
                            Country Tenants
                        </span>
                        <span className="tab">Tenant Countries</span>
                        {/* VRPR: 2339 Setting functionality not working */}
                        {/* <img
              src={isDarkMode ? SettingIconWhite : SettingIcon}
              alt="setting icon"
            /> */}
                    </div>
                </div>
                <p className="horizontal-line"></p>
                <div className="container">
                    <div className="left-container">
                        <div className="map-chart-container">
                            {!isEmpty(countries) && !isEmpty(countriesArray) && (
                                <MapChart
                                    setSelectedCountry={this.setSelectedCountry}
                                    data={countries}
                                    countriesArray={countriesArray}
                                />
                            )}
                        </div>
                    </div>
                    <div className="right-container">
                        {countries?.[selectedCountry] &&
                            countries[selectedCountry] &&
                            countries[selectedCountry]?.details.map((value, index) => {
                                return (
                                    <p className="sub-container">
                                        <p className="info-container">
                                            <span className=""> {value.appName}</span>
                                        </p>
                                        <p className="userContainer">
                                            <img
                                                src={
                                                    value.logoFilePath
                                                        ? `${imagePathPrefix}${value.logoFilePath}`
                                                        : NoImage
                                                }
                                                alt="file logo"
                                            ></img>
                                            <p>
                                                {" "}
                                                <p className="label">Total Users</p>
                                                <p className="info">{value.userCount}</p>
                                            </p>
                                            {/*  <p>
                        {" "}
                        <p className="label">Branches</p>
                        <p className="info">-</p>
                      </p> */}
                                        </p>
                                    </p>
                                )
                            })}
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({}, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TenantAcrossWorld)
