import { isEmpty } from "lodash"
import { useState, useEffect } from "react"

const useInfiniteScroll = (callback, containerElement = "") => {
    const [isFetching, setIsFetching] = useState(false)
    /* eslint-disable */
    let container
    useEffect(() => {
        if (isEmpty(containerElement)) {
            container = window
        } else {
            container = document.getElementById(containerElement)
        }

        container.addEventListener("scroll", handleScroll)
        return () => container.removeEventListener("scroll", handleScroll)
    }, [])

    useEffect(() => {
        if (!isFetching) return
        callback()
    }, [isFetching])

    function handleScroll() {
        if (isEmpty(containerElement)) {
            if (
                window.innerHeight + document.documentElement.scrollTop !==
                    document.documentElement.offsetHeight ||
                isFetching
            )
                return
            setIsFetching(true)
            return
        }

        const position = container.scrollTop
        const containerHeight = container.clientHeight
        const contentHeight = container.scrollHeight
        const distanceFromBottom = contentHeight - containerHeight - position
        const loadThreshold = 10 // Distance from the bottom to trigger loading more data

        if (distanceFromBottom < loadThreshold) {
            // Load more data here
            setIsFetching(true)
        }
    }

    return [isFetching, setIsFetching]
}

export default useInfiniteScroll
