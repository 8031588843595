import { toast } from "react-toastify"
import ContentPlan from "./service"

export const fetchContentData = (params, id) => {
    return (dispatch) => {
        return ContentPlan.fetchContentData(params, id)
            .then((response) => {
                // dispatch({
                //   type: ACTION.FETCH_ALL_DATA,
                //   apiResponse: response.data,
                // });
                return response.data
            })
            .catch((error) => {
                toast.error(error?.message)
                console.log("Got error in creating text page ", error)
            })
    }
}

export const saveMoviesSelectedPlan = (params, id) => {
    return () => {
        return ContentPlan.saveMoviesSelectedPlans(params, id)
            .then((response) => {
                // dispatch({
                //   type: ACTION.SAVE_SELECTED_DATA,
                //   apiResponse: response.data,
                // });
                return response.data
            })
            .catch((error) => {
                toast.error(error?.message)
                console.log("Got error in saving Selected Plan ", error)
            })
    }
}

export const saveWebSeriesSelectedPlan = (params, id) => {
    return () => {
        return ContentPlan.saveWebSeriesSelectedPlans(params, id)
            .then((response) => {
                // dispatch({
                //   type: ACTION.SAVE_SELECTED_DATA,
                //   apiResponse: response.data,
                // });
                return response.data
            })
            .catch((error) => {
                toast.error(error?.message)
                console.log("Got error in saving Selected Plan ", error)
            })
    }
}

export const saveFilterData = (params, filterName, id) => {
    return (dispatch) => {
        return ContentPlan.saveFilterData(params, filterName, id)
            .then((response) => {
                // dispatch({
                //   type: ACTION.SAVE_SELECTED_DATA,
                //   apiResponse: response.data,
                // });
                return response.data
            })
            .catch((error) => {
                console.log("Got error in saving filter data ", error)
            })
    }
}

export const getSavedFilterDataListAPI = (id) => {
    return (dispatch) => {
        return ContentPlan.saveFilterDataListAPI(id)
            .then((response) => {
                // dispatch({
                //   type: ACTION.SAVE_SELECTED_DATA,
                //   apiResponse: response.data,
                // });
                return response.data
            })
            .catch((error) => {
                console.log("Got error in saving filter data ", error)
            })

        // return {
        //   data: [{ name: 'saad', id: 1 }, { name: 'asdasd', id: 3 }],
        //   status: true
        // }

        // return {
        //   "status": true,
        //   "message": "Saved Successfully!",
        //   "data": [
        //     {
        //       "name": "Saved Filter 1",
        //       "id": 1,
        //       "active": true,
        //       "userId": 19,
        //       "listRequest": "{  filters: {    genre: [      Drama,Action    ],    language: [      English    ]  },  rangeFilter: {    date: {      greaterThan: 1687403563406,      lessThan: 1687403621366    }  },  searchTag: a,  sortBy: title,  sortOrder: desc}"
        //     }
        //   ]
        // }
    }
}

export const deleteSavedFilterAPI = (deletedFilterId) => {
    return (dispatch) => {
        return ContentPlan.deleteSaveFilterAPI(deletedFilterId)
            .then((response) => {
                // dispatch({
                //   type: ACTION.SAVE_SELECTED_DATA,
                //   apiResponse: response.data,
                // });
                return response.data
            })
            .catch((error) => {
                console.log("Got error in saving filter data ", error)
            })
    }
}

export const getContentCountLinkToPlan = (deletedFilterId) => {
    return (dispatch) => {
        return ContentPlan.contentCountLinkToPlan(deletedFilterId)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in saving filter data ", error)
            })
    }
}
