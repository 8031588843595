export const ACTION = {
    INITIALIZE_MULTIPART: "INITIALIZE_MULTIPART"
}

export const UPLOADED_CONTENT_HEADER = [
    {
        header: "",
        accessor: "",
        type: "text",
        isSortEnabled: false,
        isCheckbox: true
    },
    {
        header: "File Name",
        accessor: "fileName",
        type: "text",
        isSortEnabled: false
    },
    {
        header: "Uploaded On",
        accessor: "uploadedOn",
        type: "text",
        isSortEnabled: true,
        value: "uploadedDate"
    },
    {
        header: "Uploaded By",
        accessor: "uploadedBy",
        type: "text",
        isSortEnabled: false,
        value: "uploadedBy"
    },
    {
        header: "Total Size",
        accessor: "size",
        type: "text",
        isSortEnabled: true,
        value: "totalSize"
    },
    // {
    //   header: "Duration",
    //   accessor: "duration",
    //   type: "text",
    //   isSortEnabled: true,
    //   value: "duration",
    // },
    // {
    //   header: "Transcode",
    //   accessor: "transcode",
    //   type: "text",
    //   isSortEnabled: false,
    // },
    { header: "action", accessor: "action", type: "text", isSortEnabled: false }
]

export const PaginationOptions = [
    { id: 10, name: "10" },
    { id: 20, name: "20" },
    { id: 30, name: "30" },
    { id: 40, name: "40" },
    { id: 50, name: "50" }
]

export const TRANSCODED_CONTENT_HEADER = [
    {
        header: "File Name",
        accessor: "fileName",
        type: "text",
        isSortEnabled: false
    },
    {
        header: "Content Type",
        accessor: "contentType",
        type: "text",
        isSortEnabled: false
    },
    {
        header: "Protocol",
        accessor: "protocol",
        type: "text",
        isSortEnabled: false,
        value: "protocol"
    },
    {
        header: "DRM",
        accessor: "drm",
        type: "text",
        isSortEnabled: true,
        value: "drm"
    },
    {
        header: "Total Size",
        accessor: "size",
        type: "text",
        isSortEnabled: true,
        value: "totalSize"
    },
    // {
    //   header: "Duration",
    //   accessor: "duration",
    //   type: "text",
    //   isSortEnabled: true,
    //   value: "duration",
    // },
    {
        header: "Status",
        accessor: "status",
        type: "text",
        isSortEnabled: true,
        value: "status"
    },

    {
        header: "Uploaded On",
        accessor: "uploadedOn",
        type: "text",
        isSortEnabled: true,
        value: "uploadedDate"
    },
    {
        header: "Uploaded By",
        accessor: "uploadedBy",
        type: "text",
        isSortEnabled: false,
        value: "uploadedBy"
    },
    {
        header: "AssetId",
        accessor: "assetId",
        type: "text",
        isSortEnabled: false,
        value: "assetId"
    },
    {
        header: "URL",
        accessor: "url",
        type: "text",
        isSortEnabled: true,
        value: "url"
    },
    {
        header: "SUFFIX",
        accessor: "mediaTailorSuffix",
        type: "text",
        isSortEnabled: true,
        value: "mediaTailorSuffix"
    },
    { header: "action", accessor: "action", type: "text", isSortEnabled: false }
]

export const TRANSCODING_TAB = {
    Transcoded_TAB: 0,
    UPLOADED_TAB: 1
}

export const FILTER_INITIAL_DATA = {
    length: 10,
    start: 0,
    searchTag: null,
    sortBy: "",
    sortOrder: "desc",
    filters: {},
    rangeFilter: {},
    existKeyFilter: null
}

export const PAGINATION_INITIAL_DATA = {
    pageCount: 1,
    currentPage: 0,
    selectedOption: PaginationOptions[0],
    start: 0
}

export const CHECKBOX_FILTER = [
    {
        filterHeading: { name: "DRM", labelText: "DRM" },
        filterData: [
            { id: "1211", name: true, labelText: "Yes" },
            { id: "1222", name: false, labelText: "No" }
        ]
    }
]

export const MODAL_ACTION = {
    DELETE_MODAL: "1",
    PLAY_URL: "2",
    VIEW: "3",
    CANCEL_MODAL: "4",
    RIGHTS_MODAL: "5"
}

export const PROTOCOL = {
    DASH: "DASH",
    HLS: "HLS"
}
