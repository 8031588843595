import { SPORTS_ACTION } from "./constant"
import SportsService from "./service"
import { removeLoader, setLoader } from "../../common/Loader/api/action"

export const getSportsCategory = () => {
    return async (dispatch) => {
        dispatch(setLoader())
        return SportsService.getSportsCategory()
            .then((response) => {
                dispatch({
                    type: SPORTS_ACTION.SET_SPORTS_CATEGORY,
                    payload: response?.data?.data
                })
            })
            .catch((error) => {
                console.error("Got Error in getSportsCategory: " + error)
                return error
            })
            .finally(() => {
                dispatch(removeLoader())
            })
    }
}

/* Setup Sports related Action */

export const sportsSetupSelectedTab = (selectedTab) => ({
    type: SPORTS_ACTION.SPORTS_SETUP_SELECTED_TAB,
    payload: selectedTab
})

export const sportsSetupSetPaginationData = (paginationData) => ({
    type: SPORTS_ACTION.SPORTS_SETUP_SET_PAGINATION_DATA,
    payload: paginationData
})

export const saveSportsCategoryId = (selectedId) => ({
    type: SPORTS_ACTION.SPORTS_SETUP_CATEGORY_ID,
    payload: selectedId
})

export const sportsSetupSetFilterData = (filterData) => ({
    type: SPORTS_ACTION.SPORTS_SETUP_SET_FILTER_DATA,
    payload: filterData
})

export const uploadImage = (params) => {
    return async (dispatch) => {
        return SportsService.uploadImage(params)
            .then((response) => {
                return response?.data
            })
            .catch((error) => {
                console.error("Got error in upload image ", error)
            })
    }
}

export const saveSelectedSportsData = (params) => {
    return async (dispatch) => {
        return SportsService.saveSelectedSportsCategory(params)
            .then((response) => {
                return response?.data
            })
            .catch((error) => {
                console.error("Got Error in saveSelectedSportsData: " + error)
                return error
            })
    }
}

export const fetchSelectedSportsDataList = (params) => {
    return async (dispatch) => {
        dispatch(setLoader())
        return SportsService.getSelectedSportsCategoryData(params)
            .then((response) => {
                dispatch({
                    type: SPORTS_ACTION.FETCH_SELECTED_SPORTS_LIST,
                    payload: response?.data
                })
                return response?.data
            })
            .catch((error) => {
                console.error("Got Error in fetchSelectedSportsDataList: " + error)
                return error
            })
            .finally(() => {
                dispatch(removeLoader())
            })
    }
}

export const setupSportsDeleteSelectedData = (selectedId) => {
    return async (dispatch) => {
        dispatch(setLoader())
        return SportsService.deleteSelectedData(selectedId)
            .then((response) => {
                return response?.data
            })
            .catch((error) => {
                console.error("Got Error in setupSportsDeleteSelectedData: " + error)
                return error
            })
            .finally(() => {
                dispatch(removeLoader())
            })
    }
}

export const fetchFilterDropdownDataList = (params) => {
    return async (dispatch) => {
        dispatch(setLoader())
        return SportsService.fetchSportsFilterData(params)
            .then((response) => {
                dispatch({
                    type: SPORTS_ACTION.FETCH_SELECTED_SPORTS_FILTER,
                    payload: response?.data?.data
                })
            })
            .catch((error) => {
                console.error("Got Error in fetchFilterDropdownDataList: " + error)
                return error
            })
            .finally(() => {
                dispatch(removeLoader())
            })
    }
}
