import ApiService from "../../../utils/apiService"
import { API_ROOT_PATH } from "../../../config/apiPath"

class ContentEnrichmentSerivce {
    getMoviesAndEpisodeData(contentType, enrichmentType) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/api/v1/vionlabs/view/screen/${contentType}/${enrichmentType}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    getMoviesAndEpisodeCount() {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/api/v1/vionlabs/fetch/count`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
}

const ContentEnrichmentInstance = new ContentEnrichmentSerivce()
export default ContentEnrichmentInstance
