import { ACTION } from "./constant"
import ProductPlacementServiceInstance from "./service"

export const fetchProductList = () => {
    return (dispatch) => {
        return ProductPlacementServiceInstance.fetchProductList()
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_PRODUCT_LIST,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in product list ", error)
            })
    }
}
export const fetchAddClipList = () => {
    return (dispatch) => {
        return ProductPlacementServiceInstance.fetchAdsClipList()
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_ADD_CLIPS_LIST,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in add clip list ", error)
            })
    }
}
export const fetchAdsPreviewUrl = (prams = {}) => {
    return (dispatch) => {
        return ProductPlacementServiceInstance.fetchAdsPreviewUrl(prams)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_ADS_PREVIEW_URL,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in ads preview URL list ", error)
            })
    }
}

export const savePreviewUrl = (prams = {}, id) => {
    return (dispatch) => {
        return ProductPlacementServiceInstance.savePreviewUrl(prams, id)
            .then((response) => {
                dispatch({
                    type: ACTION.SAVE_ADD_URL,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in ads preview URL list ", error)
            })
    }
}

export const sendMagnaRequest = (params) => {
    return (dispatch) => {
        return ProductPlacementServiceInstance.sendMagnaRequest(params)
            .then((response) => {
                dispatch({
                    type: ACTION.SAVE_ADD_URL,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in ads preview URL list ", error)
            })
    }
}
