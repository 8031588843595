import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import get from "lodash/get"

import Input from "../../common/Input/index"
import Button from "../../common/Button/index"
import { addUserDetails, fetchAllRoles } from "../APIs/action"
import MultiSelect from "../../common/CustomMultiselect"

import "./style.scss"
import { BUTTON_CONSTANTS, ERROR_MESSAGES, REGEX, TEXT_CONSTANTS } from "../../../utils/constants"

class AddUser extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formData: this.defaultForm,
            isDisable: true,
            errorsList: {}
        }
    }

    defaultForm = {
        name: "",
        email: "",
        password: "",
        roles: []
    }

    componentDidMount() {
        this.props.fetchAllRoles()
    }

    handleChange = (name, value) => {
        const updatedFormData = { ...this.state.formData }
        updatedFormData[name] = value
        this.setState(
            {
                formData: updatedFormData
            },
            () => this.checkForEmpty()
        )
    }
    resetForm = () => {
        this.setState({
            formData: this.defaultForm
        })
    }
    formateData = (value) => {
        return value.map((item) => {
            return { id: item.id }
        })
    }

    handleOnSelect = (name, selectedValues) => {
        const { formData } = this.state
        formData[name] = selectedValues
        this.setState(
            {
                formData
            },
            () => this.checkForEmpty()
        )
    }
    handleOnSubmit = async () => {
        const { formData } = this.state
        const { handleBack, addUserDetails } = this.props
        let validated = this.validatorFunction(formData)
        if (!validated) {
            return null
        }
        const response = await addUserDetails(formData)
        if (response != null) {
            if (get(response, "status")) {
                toast.success(get(response, "message"))
                this.resetForm()
                handleBack()
            } else {
                toast.error(get(response, "message"))
            }
        } else {
            toast.error(ERROR_MESSAGES.SOMETHING_WENT_WRONG)
            this.setState({
                isDisable: true
            })
        }
    }
    checkForEmpty = () => {
        const { name, password, email, roles } = this.state.formData
        let count = 4
        if (name.trim().length) {
            count = count - 1
        }
        if (email.trim().length) {
            count = count - 1
        }
        if (password.trim().length) {
            count = count - 1
        }
        if (roles.length) {
            count = count - 1
        }
        if (!count) {
            this.setState({
                isDisable: false
            })
        }
    }
    validatorFunction = (data) => {
        let regex = {
            email: REGEX.EMAIL_REGEX,
            password: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
            passwordLength: /^.{8,}$/
        }
        let errorsList = {}
        let flag = 1
        const { name, roles, email, password } = data
        // check for empty
        if (name.trim() === "") {
            errorsList.name = "Name is mandatory"
            flag = 0
        }
        if (email === "") {
            errorsList.email = "Email is required"
            flag = 0
        } else if (!regex.email.test(email)) {
            errorsList.email = "Email is not valid"
            flag = 0
        }
        if (password === "") {
            errorsList.password = "Password is required"
            flag = 0
        } else if (!regex.passwordLength.test(password)) {
            errorsList.password = "Password length should be minimum 8 characters."
            flag = 0
        } else if (!regex.password.test(password)) {
            errorsList.password =
                "Password should contain one special char one uppercase one lower case and number"
            flag = 0
        }
        if (!roles.length) {
            errorsList.roles = "minimum one role should be selected"
            flag = 0
        }
        if (Object.keys(errorsList).length) {
            this.setState({
                errorsList: errorsList
            })
        } else {
            this.setState({
                errorsList: errorsList
            })
        }
        return flag
    }

    render() {
        const { handleBack, removeSuperAdminRole } = this.props
        const { errorsList, formData, isDisable } = this.state

        const modifiedRoleList = removeSuperAdminRole()

        return (
            <div className="vd-addUser">
                <header>
                    <i className="icon-arrow-left1" onClick={handleBack} />
                    <span>{TEXT_CONSTANTS?.ADD_NEW_USER}</span>
                </header>
                <div className="add-user-form">
                    <Input
                        type="text"
                        changeHandler={this.handleChange}
                        name="name"
                        id="username"
                        labelText="Name"
                        value={formData.name}
                        errMsg={errorsList.name}
                        maxLength={50}
                    />
                    <Input
                        type="email"
                        changeHandler={this.handleChange}
                        name="email"
                        id="email"
                        labelText="Email"
                        value={formData.email}
                        maxLength={80}
                        errMsg={errorsList.email}
                    />
                    <Input
                        type="password"
                        changeHandler={this.handleChange}
                        labelText="Password"
                        name="password"
                        id="password"
                        value={formData.password}
                        maxLength={30}
                        errMsg={errorsList.password}
                    />
                    <MultiSelect
                        options={modifiedRoleList}
                        placeholder={"-select-"}
                        label={"Roles"}
                        name={"roles"}
                        id={"roles"}
                        selectedOptions={formData.roles}
                        changeHandler={this.handleOnSelect}
                        errMsg={errorsList.roles}
                    />
                    <div className="user-btn">
                        <Button
                            bValue={BUTTON_CONSTANTS?.ADD_USER?.toUpperCase()}
                            disabled={isDisable}
                            clickHandler={this.handleOnSubmit}
                            cName="btn primary-btn"
                        />
                        <Button
                            bValue={BUTTON_CONSTANTS?.CANCEL?.toUpperCase()}
                            clickHandler={handleBack}
                            cName="btn cancel-btn"
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        roleList: get(state.userManagement, "allRoles", [])
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                addUserDetails,
                fetchAllRoles
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddUser)
