import { ACTION } from "./constant"

let initialState = {
    isLoading: false
}

export default function LoaderReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION.SET_LOADER:
            return {
                ...state,
                isLoading: true
            }

        case ACTION.REMOVE_LOADER:
            return {
                ...state,
                isLoading: false
            }

        default:
            return state
    }
}
