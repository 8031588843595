import ApiService from "../../../utils/apiService"
import { API_ROOT_PATH } from "../../../config/apiPath"

class ContentPlanService {
    fetchContentData(params, id) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/content/searchContent?contentEntityId=${id}`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }

    saveMoviesSelectedPlans(params, id) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/subscription-content/mapPlanToContent?contentEntityId=${id}`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }
    saveWebSeriesSelectedPlans(params, id) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/subscription-content/mapPlanToContent?contentEntityId=${id}`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }

    saveFilterData(params, filterName, id) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/userFilter/saveFilters?contentEntityId=${id}&name=${filterName}`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }

    saveFilterDataListAPI(id) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/userFilter/getFilterDetails?contentEntityId=${id}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    deleteSaveFilterAPI(deletedFilterId) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/userFilter/delete/${deletedFilterId}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    contentCountLinkToPlan(deletedFilterId) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/subscription-content/fetchContentCount?currencyCode=USD`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
}

const ContentPlanServiceInstance = new ContentPlanService()
export default ContentPlanServiceInstance
