import { ACTION } from "./constants"

let initialState = {}

export default function UserProfileReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION.VIEW_PROFILE:
            return { ...state, userDetails: action.apiResponse.data }
        default:
            return state
    }
}
