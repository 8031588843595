import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect, useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { ERROR_MESSAGES, TEXT_CONSTANTS } from "../../utils/constants"

import { getBasicConfig, saveBasicConfig } from "./APIs/action"

import "./style.scss"
import BasicConfig from "./BasicConfig"
import AppSplash from "./AppSplash"
import ForceUpgrade from "../ForceUpgradeApplication"
import { tabNames } from "./APIs/constant"

class BasicConfiguration extends Component {
    constructor(props) {
        super(props)
        this.state = {
            payload: { sub_title_visible: true },
            isLoading: true,
            selectedTab: "1",
            selectedTabName: "Basic"
        }
    }

    componentDidMount() {
        this.loadHandler()
    }

    loadHandler = async () => {
        const { getBasicConfig } = this.props
        let response = await getBasicConfig()
        if (response && response?.data) {
            this.setState(
                {
                    payload: response?.data,
                    isLoading: false
                },
                () => {
                    this.transformResponse()
                }
            )
        }
    }

    saveBasicConfig = async () => {
        const { saveBasicConfig } = this.props
        let request = this.setRequest()
        this.setState({
            isLoading: true
        })
        let response = await saveBasicConfig(request)
        if (response && response?.status) {
            toast.success(TEXT_CONSTANTS.BASIC_SETTINGS_SUCCESS)
        } else {
            toast.error(ERROR_MESSAGES.SOME_ISSUE_API)
        }
        this.setState({
            isLoading: false
        })
    }

    setRequest = () => {
        const { payload } = this.state
        let request = {
            id: "tenant_basic_config",
            configName: "tenant_basic_config",
            // tenantConfigMap: {
            //   sub_title_visible: payload?.sub_title_visible,
            // },
            tenantConfigMap: payload
        }
        return request
    }

    transformResponse = () => {
        let { payload } = this.state
        payload = payload?.tenantConfigMap
        // payload?.tenantConfigMap["sub_title_visible"];
        this.setState({
            payload
        })
    }

    handleToggle = (e) => {
        const { payload } = this.state
        this.setPayload(e?.target?.name, !payload[e?.target?.name])
    }

    setPayload = (name, value) => {
        const { payload } = this.state
        payload[name] = value
        this.setState(
            {
                payload
            },
            () => {
                //   this.saveBasicConfig();
            }
        )
    }

    getToggleBtn = () => {
        const { payload, isLoading } = this.state
        return (
            !isLoading && (
                <label className={`switch-button`}>
                    <input
                        type="checkbox"
                        name="sub_title_visible"
                        onChange={(e) => this.handleToggle(e)}
                        checked={!payload["sub_title_visible"] ? true : false}
                    />
                    <div
                        className={`switch-slider switch-round ${
                            payload["sub_title_visible"] ? "icon-tick" : "icon-close"
                        }`}
                    >
                        <span className="isChecked">{TEXT_CONSTANTS?.NO?.toUpperCase()}</span>
                        <span className="isUnChecked">{TEXT_CONSTANTS?.YES?.toUpperCase()}</span>
                    </div>
                </label>
            )
        )
    }

    handleSubmit = () => {
        this.saveBasicConfig()
    }

    handleChange = (name, value) => {
        this.setPayload(name, value)
    }

    handleTabClick = (item) => {
        this.setState({
            selectedTab: item.id,
            selectedTabName: item.name
        })
    }

    handlePlatformChange = (item) => {
        this.setState({
            currentPlatform: item.id,
            platformName: item.name,
            selectedTab: this.state.categoryList?.[0]?.id,
            selectedTabName: this.state.categoryList?.[0]?.name,
            filterOptions: {
                ...this.state.filterOptions,
                pageNo: 0
            },
            currentPage: 0
        })
    }

    getComponent = () => {
        const { isLoading, payload, currentPlatform, selectedTabName } = this.state
        switch (selectedTabName) {
            case "Basic":
                return (
                    <BasicConfig
                        handleChange={this.handleChange}
                        handleSubmit={this.handleSubmit}
                        isLoading={isLoading}
                        getToggleBtn={this.getToggleBtn}
                        payload={payload}
                    />
                )
            case "App Splash":
                return <AppSplash />
            case "Force/Recommended":
                return (
                    <ForceUpgrade
                        handlePlatformChange={this.handlePlatformChange}
                        currentPlatform={currentPlatform}
                    />
                )
        }
    }

    render() {
        const { isLoading, payload, selectedTab, currentPlatform, selectedTabName } = this.state
        return (
            <div className="vr-content-holder basic-container">
                <div className="category-header">
                    <div className="navMenu">
                        <ul className="category-list">
                            {tabNames.map((item, index) => {
                                return (
                                    <li
                                        key={index}
                                        className={selectedTab === item.id ? "active" : null}
                                        onClick={() => this.handleTabClick(item)}
                                    >
                                        {item.name}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
                {this.getComponent(selectedTabName)}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(
            {
                getBasicConfig,
                saveBasicConfig
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BasicConfiguration)
