import React, { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import loaderImg from "../../../assets/images/loader.gif"
import {
    FILTER_INITIAL_DATA,
    METADATA_INGESTION_CONTENT_HEADER,
    PAGINATION_INITIAL_DATA,
    PaginationOptions
} from "../api/constants"
import CustomModal from "../../common/Modal"
import eyeIcon from "../../../assets/images/eyeIcon.svg"
import { ERROR_MESSAGES, TEXT_CONSTANTS } from "../../../utils/constants"
import EmptyContent from "../../common/EmptyContent"
import Pagination from "../../common/Pagination"
import { dateTime, debounce } from "../../../utils/common"
import Input from "../../common/Input"
import { getConfig, toggleState } from "../api/action"

import "./style.scss"
import moment from "moment"
import Select1 from "../../common/SelectBox"
import { fetchPlatformList } from "../../Category/api/action"
import { isEmpty } from "lodash"
// import MetadataIngestionNotificationPopup from "../metadataIngestionNotificationPopup";

export default function MetadataIngestionTable({
    addNewBackHandler,
    canUpdateStatus,
    setCloneData,
    setIsAddingNewData,
    setViewMode
}) {
    const [isLoading, setIsLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [metadataIngestionRecords, setMetadataIngestionRecords] = useState([])
    const [platforms, setPlatforms] = useState([])

    const [paginationData, setPaginationData] = useState(PAGINATION_INITIAL_DATA)
    const [filterOptions, setFilterOptions] = useState(FILTER_INITIAL_DATA)
    const [selectedPlatforms, setSelectedPlatforms] = useState([])
    const [filters, setFilters] = useState({})
    const dispatch = useDispatch()

    useEffect(() => {
        if (platforms.length) {
            fetchData(filterOptions)
        } else {
            fetchPlatforms()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterOptions, platforms])

    const fetchPlatforms = async () => {
        const response = await dispatch(fetchPlatformList())
        if (!isEmpty(response)) {
            let platforms = removePlatforms(response?.data)
            setPlatforms(platforms)
        }
    }

    const removePlatforms = (platforms) => {
        let requiredPlatforms = platforms.filter((value) => {
            return value.name !== "web"
        })
        return requiredPlatforms
    }

    const onPageChange = (e) => {
        const filterOptionsCopy = { ...filterOptions }
        const selectedPage = e.selected
        filterOptionsCopy.start = selectedPage + 1
        let startValue = filterOptionsCopy["start"] - 1

        let paginationDataCopy = { ...paginationData }
        paginationDataCopy.currentPage = selectedPage
        paginationDataCopy.start = startValue
        setPaginationData(paginationDataCopy)

        filterOptionsCopy.start = startValue
        setFilterOptions(filterOptionsCopy)
        fetchData(filterOptionsCopy)
    }
    const showPerPageChange = (name, value) => {
        const filterOptionsCopy = { ...filterOptions }
        filterOptionsCopy["length"] = value
        filterOptionsCopy["start"] = 0

        let defaultOption = PaginationOptions.filter((item) => {
            if (value === item.id) {
                return item
            }
            return false
        })

        let paginationDataCopy = { ...paginationData }
        paginationDataCopy.selectedOption = defaultOption
        paginationDataCopy.start = 0
        paginationDataCopy.pageCount = 0
        paginationDataCopy.currentPage = 0
        setPaginationData(paginationDataCopy)

        filterOptionsCopy.length = defaultOption[0]?.id
        setFilterOptions(filterOptionsCopy)
        fetchData(filterOptionsCopy)
    }

    const renderTableData = () => {
        const renderTableHeader = (item) => {
            return (
                <p>
                    <span>{item.header}</span>{" "}
                </p>
            )
        }

        const tableHeading = METADATA_INGESTION_CONTENT_HEADER.map((item, index) => {
            return <th key={index}>{renderTableHeader(item)}</th>
        })

        const handleToggleChange = async (row) => {
            let params = {
                platform: row?.platform,
                status: !row.active,
                updateType: row.updateType
            }
            await dispatch(toggleState(params, row.id))
            fetchData(filterOptions)
        }

        const handleClone = (row, mode) => {
            if (mode === "view") {
                setViewMode(true)
            }
            setCloneData(row)
            setIsAddingNewData(true)
        }

        const tableRows = metadataIngestionRecords.map((row) => {
            const createdOn = row?.dateCreated ? dateTime(row.dateCreated) : "--"
            return (
                <tr id={row?.id} key={row?.id} className="">
                    <td>
                        <div className="tooltip-container">
                            <p className="tooltip-container-show">{row?.id}</p>
                            <div className="tooltip-container-innerText">
                                <p className="transcoding-play-url">{row?.id ? row?.id : "-"}</p>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className="tooltip-container">
                            <p className="tooltip-container-show">{row?.emailId}</p>
                            <div className="tooltip-container-innerText">
                                <p className="transcoding-play-url">{row?.emailId || "-"}</p>
                            </div>
                        </div>
                    </td>
                    <td>{createdOn.date}</td>
                    <td>{row?.active ? "ACTIVE" : "INACTIVE"}</td>
                    <td>{row?.platform ? row?.platform : "-"}</td>
                    <td>{row?.targetAppVersion ? row?.targetAppVersion : "-"}</td>
                    <td>{row?.updateType ? row?.updateType : "-"}</td>
                    <td>
                        {" "}
                        <label className={`switch-button`}>
                            <input
                                type="checkbox"
                                name="switch"
                                id={row.id}
                                onChange={(id) => handleToggleChange(row)}
                                checked={!row.active ? true : false}
                                // disabled={!canUpdateStatus}
                            />
                            <div
                                className={`switch-slider switch-round ${
                                    row.active ? "icon-tick" : "icon-close"
                                }`}>
                                <span className="isChecked">
                                    {TEXT_CONSTANTS?.NO?.toUpperCase()}
                                </span>
                                <span className="isUnChecked">
                                    {TEXT_CONSTANTS?.YES?.toUpperCase()}
                                </span>
                            </div>
                        </label>
                    </td>
                    <td>
                        <div
                            className={`icon-duplicate copy-action`}
                            onClick={() => handleClone(row, "")}></div>
                    </td>
                    <td>
                        <div
                            className={`icon-published`}
                            onClick={() => handleClone(row, "view")}></div>
                    </td>
                </tr>
            )
        })

        return (
            <table className={`table metadata-ingestion-table`}>
                <thead>
                    <tr>{tableHeading}</tr>
                </thead>
                <tbody>{tableRows}</tbody>
            </table>
        )
    }

    const pageCountSetup = (userCount, payloadObj) => {
        let pageCount = payloadObj.length ? Math.ceil(userCount / payloadObj.length) : 0
        setPaginationData((prevState) => {
            return {
                ...prevState,
                pageCount: pageCount
            }
        })
    }

    const fetchData = async (payloadObj) => {
        setIsLoading(true)
        const platformNames = platforms?.map((item) => item.platformEnum)
        const payloadData = {
            pageNo: payloadObj?.start + 1,
            size: payloadObj?.length,
            platforms: filters.platforms || platformNames
        }
        let status = filters.status === "Active" ? "true" : "false"
        let response = await dispatch(getConfig(payloadData, status))

        setIsLoading(false)
        if (response?.status) {
            setMetadataIngestionRecords(response?.data)
        }
        pageCountSetup(response?.data?.totalCounts, payloadObj)
    }

    const displayLoader = () => {
        return (
            <div className="loader">
                <img src={loaderImg} alt="loader" width={"100px"} />
            </div>
        )
    }

    const submitModalHandler = () => {}

    const uploadMetadataHandler = () => {
        addNewBackHandler()
    }

    // let platforms = ["IOS", "ANDROID", "WEB", "ANDROID_TV"];
    let status = ["Active", "InActive"]
    const changeHandler = (name, value, selectedOptions) => {
        setFilterData(name, value, selectedOptions)
    }
    const setFilterData = (name, value, selectedOptions) => {
        filters[name] = Array.isArray(selectedOptions)
            ? selectedOptions.map((value) => {
                  return value.name
              })
            : selectedOptions?.name
        filters.isUpdated = !filters.isUpdated

        let optionsArray = []
        if (name === "platforms") {
            filters[name] &&
                filters[name].forEach((item, index) => {
                    optionsArray.push({
                        id: item,
                        name: item
                    })
                })
            setSelectedPlatforms(optionsArray)
        }
        setFilters(filters)
        fetchData(filterOptions)
    }

    return (
        <div className="vr-force-upgrade-table-wrapper">
            {isLoading && displayLoader()}
            <div className="filter-container">
                <div className="form-input">
                    <Select1
                        key="platform"
                        placeholder="Select Platform"
                        cName={"platformSelect"}
                        options={
                            platforms &&
                            platforms.map((ele) => {
                                return {
                                    id: ele.name.toUpperCase(),
                                    name: ele.name.toUpperCase(),
                                    value: ele.name.toUpperCase()
                                }
                            })
                        }
                        isMulti={true}
                        label={"Select Platform"}
                        required={true}
                        name={"platforms"}
                        changeHandler={(name, value, options) =>
                            changeHandler(name, value, options)
                        }
                        value={selectedPlatforms}
                        isDisabled={false}
                    />
                </div>
                {/*  <div className="form-input">
          <Select1
            key="2"
            placeholder="Status"
            options={
              status &&
              status.map((ele) => {
                return {
                  id: ele,
                  name: ele,
                  value: ele?.name,
                };
              })
            }
            label={"Status"}
            required={true}
            name={"status"}
            changeHandler={(name, value, options) =>
              changeHandler(name, value, options)
            }
            isDisabled={false}
          />
        </div> */}
            </div>
            <div className="details-header">
                <h3>All Records</h3>
                <div className="search-box-container">
                    {/*    <div className="searchBar">
            <div className="icon-search"></div>
            <Input
              id="search"
              type="text"
              cName={"movie-search-bar"}
              placeholder="Search Here"
              changeHandler={searchChangeHandler}
              value={searchValue}
            />
          </div> */}
                </div>
            </div>
            <div className="force-upgrade-list-container vr-content-holder metadata-ingestion-table-container">
                {!isLoading ? (
                    metadataIngestionRecords?.length > 0 ? (
                        renderTableData()
                    ) : (
                        <EmptyContent
                            errorMsg={ERROR_MESSAGES?.EMPTY_METADATA_INGESTION}
                            addNewButtonHandler={uploadMetadataHandler}
                            isButtonRequired={canUpdateStatus}
                            isErrorDesc={canUpdateStatus}></EmptyContent>
                    )
                ) : (
                    <></>
                )}
            </div>
            <Pagination
                changeHandler={showPerPageChange}
                options={PaginationOptions}
                pageCount={paginationData?.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={onPageChange}
                selectedOption={paginationData?.selectedOption}
                currentPage={paginationData?.currentPage}
            />
            <CustomModal
                showModal={showModal}
                cancelHandler={() => {
                    setShowModal(false)
                }}
                onClickCancelBtn={submitModalHandler}
                showCloseIcon={true}
                showSubmitButton={false}
                closeOnSubmit={false}
                cancelOnOutsideClick={false}
                modalContainerClass={""}
                showFooter={false}>
                {
                    {
                        /* <MetadataIngestionNotificationPopup
          selectedRowData={selectedRowData}
        ></MetadataIngestionNotificationPopup> */
                    }
                }
            </CustomModal>
        </div>
    )
}
