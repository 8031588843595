import React, { Component } from "react"
import Options from "../../options"
import "./style.scss"
import SideMenuFields from "../sideMenuFields"
import SideMenuList from "./side-menu"
import SideMenuFieldsTemplate from "../sideMenuFieldsTemplate"
import { TEXT_CONSTANTS } from "../../../../utils/constants"

class SideMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isSelected: ""
        }
    }

    handleActiveClick = (type) => {
        this.setState({
            isSelected: type
        })
    }
    render() {
        const {
            elementsArr,
            selectedElement,
            setElementArray,
            selectedIndex,
            handleBack,
            deleteElement,
            actionButton,
            isChild,
            addSearch,
            findChecked
        } = this.props
        return (
            <div className="vd-sideMenu">
                {selectedElement && !selectedElement.id ? (
                    <React.Fragment>
                        <div className="formTitle">
                            <div className="formTitle-right">
                                <span>{TEXT_CONSTANTS?.FORM_ELEMENTS}</span>
                                <p>{TEXT_CONSTANTS?.ADD_NEW_FIELDS}</p>
                            </div>
                        </div>
                        <div className="element-list">
                            {Options.length &&
                                Options.map((field, i) => <MemoItem field={field} index={i} />)}
                        </div>
                    </React.Fragment>
                ) : selectedElement && selectedElement.sideMenuType === "option-1" ? (
                    <SideMenuFields
                        selectedElement={selectedElement}
                        setElementArray={setElementArray}
                        elementsArr={elementsArr}
                        selectedIndex={selectedIndex}
                        handleBack={handleBack}
                        deleteElement={deleteElement}
                        isChild={isChild}
                        addSearch={addSearch}
                        findChecked={findChecked}
                        actionButton={actionButton}
                    />
                ) : (
                    <SideMenuFieldsTemplate
                        selectedElement={selectedElement}
                        setElementArray={setElementArray}
                        elementsArr={elementsArr}
                        selectedIndex={selectedIndex}
                        handleBack={handleBack}
                        deleteElement={deleteElement}
                        isChild={isChild}
                        findChecked={findChecked}
                        actionButton={actionButton}
                    />
                )}
            </div>
        )
    }
}
const MemoItem = React.memo(SideMenuList, (prevProps, nextProps) => {
    //console.info('prevProps',prevProps);
    //console.info('nextProps', nextProps);
    if (prevProps.index === nextProps.index) {
        return true
    }
    return false
})
export default SideMenu
