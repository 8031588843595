import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import Button from "../../common/Button"
import { ProductMenu } from "./PlanConstant"
import Loader from "../../../assets/images/loader.gif"
import {
    BUTTON_CONSTANTS,
    MASTER_TENANT_IDENTIFIER_NAME,
    TEXT_CONSTANTS
} from "../../../utils/constants"

import { getPlansList } from "../APIs/action"
import MagnaQuestIframe from "../MagnaQuestIframe"
import { MenuTokenFun } from "../MenuTokenConstant"
import { toast } from "react-toastify"
import MagnaQuest from ".."

/* admin work */

class PlansList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedOptionURL: "",
            plansListData: [],
            isMasterTenant: props?.tenantIdentifier === MASTER_TENANT_IDENTIFIER_NAME
        }
    }

    addNewPlanHandler = () => {
        const MenuToken = MenuTokenFun()

        this.setState({
            showIFrame: true,
            selectedOptionURL: `https://demo-ttnsandbox.magnaquest.com//OrderManagement/NewPlan/NewPlan?MenuID=${MenuToken.product.MENUID}`
        })
    }

    editPlan = (planCode) => {
        const MenuToken = MenuTokenFun()

        this.setState({
            showIFrame: true,
            selectedOptionURL: `https://demo-ttnsandbox.magnaquest.com//OrderManagement/NewPlan/NewPlan?MenuID=${MenuToken.product.MENUID}&Productcode=${planCode}`
        })
    }

    componentDidMount() {
        this.loadHandler()
    }

    loadHandler = async () => {
        const { getPlansList } = this.props
        this.setState({
            isLoading: true
        })
        const res = await getPlansList()
        if (res?.status) {
            this.setState({
                plansListData: res?.data?.plans,
                isLoading: false
            })
            return
        }
        this.setState({
            isLoading: false
        })
        toast.error(res?.message)
    }
    handleBackBtn = () => {
        this.setState({
            showIFrame: false
        })
        this.loadHandler()
    }

    renderPlansData = () => {
        const { isLoading, showIFrame, selectedOptionURL } = this.state
        const tableHeading = ProductMenu.map((item, index) => {
            return (
                <th key={index}>
                    <p>
                        <span>{item.key}</span>{" "}
                    </p>
                </th>
            )
        })
        const rowsData = this.state?.plansListData?.filter((plan) => plan?.isPrimary)
        const tableRows =
            rowsData &&
            Array.isArray(rowsData) &&
            rowsData.map((row, i) => {
                return (
                    <tr id={row.id} key={row.id}>
                        <td>{i + 1}</td>
                        <td>{row?.name}</td>
                        <td>{row?.price}</td>
                        <td>{row?.description}</td>
                        <td className="btn-popupContainer">
                            <p onClick={() => this.editPlan(row.code)}>
                                <i className="action-btn icon-edit"></i>
                            </p>
                        </td>
                    </tr>
                )
            })

        return this.state?.isMasterTenant ? (
            <MagnaQuest path={"product"}></MagnaQuest>
        ) : (
            <div>
                {!showIFrame ? (
                    <div className="vr-content-holder">
                        {
                            <div className="tenant-list-container magna-tenant-container">
                                <p className="add-new-tenant">
                                    <p className="tenant-heading">{TEXT_CONSTANTS?.MANAGE_PLANS}</p>
                                    <div className="search-container">
                                        <Button
                                            type="button"
                                            clickHandler={() => this.addNewPlanHandler()}
                                            bValue={BUTTON_CONSTANTS?.ADD_NEW_PLAN}
                                            cName="btn primary-btn"
                                        ></Button>
                                    </div>
                                </p>
                                <table className="table">
                                    <thead>
                                        <tr>{tableHeading}</tr>
                                    </thead>
                                    {!isLoading ? (
                                        <tbody>{tableRows}</tbody>
                                    ) : (
                                        <tbody>
                                            <img className="loader" src={Loader} alt="loader" />
                                        </tbody>
                                    )}
                                </table>
                            </div>
                        }
                    </div>
                ) : (
                    <MagnaQuestIframe
                        iframeURL={selectedOptionURL}
                        handleBackBtn={this.handleBackBtn}
                        location={this.props?.location}
                    ></MagnaQuestIframe>
                )}
            </div>
        )
    }

    render() {
        return this.renderPlansData()
    }
}

const mapStateToProps = (state) => {
    return {
        tenantIdentifier: state?.AdminHomeReducer?.tenantInfo?.data?.tenantIdentifier
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                getPlansList
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlansList)
