import React, { useEffect, useState } from "react"
import Lottie from "react-lottie"
import { imagePathPrefix } from "../../config/apiPath"

export const SplashScreen = ({ image, logo, classes, screen }) => {
    const [animation, setAnimation] = useState("")
    const fetchFile = async () => {
        const response = await fetch(`${imagePathPrefix}${logo}`)
        const data = await response.json()
        setAnimation(data)
    }

    useEffect(() => {
        logo?.includes(".json") && fetchFile()
    }, [logo])

    return (
        <div className={classes.bg}>
            <div
                className={classes.content}
                style={{
                    background: `${
                        image?.includes("splash") ? `url(${imagePathPrefix}${image})` : image
                    }`
                }}
            />
            <img src={screen} alt="screen" />
            {logo ? (
                logo.includes(".png") ? (
                    <img src={`${imagePathPrefix}${logo}`} alt="logo" className="logo" />
                ) : (
                    <div className="logo">
                        <Lottie
                            width={300}
                            eventListeners={[
                                {
                                    eventName: "loopComplete",
                                    callback: () => console.log("a loop complete")
                                }
                            ]}
                            options={{
                                autoplay: true,
                                loop: true,
                                animationData: animation
                            }}
                        />
                    </div>
                )
            ) : (
                <></>
            )}
        </div>
    )
}
