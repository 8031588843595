import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import get from "lodash/get"

import Pagination from "../../common/Pagination/index"
import Search from "../../common/Search/index"
import Filter from "../../common/Filter/index"
import Button from "../../common/Button"
import { USER_TABLE_HEADING } from "../APIs/constant"
import AddUser from "./addUser"
import Editable from "../../common/Editable"
import {
    fetchUsersList,
    fetchAllRoles,
    changeUserState,
    softDeleteUser,
    addUserDetails
} from "../APIs/action"
import EditableMultiSelect from "./editableMultiSelect"
import { scrollPage, debounce, isSuperAdminUser } from "../../../utils/common"

import "../../common/Table/style.scss"
import "../style.scss"
import { BUTTON_CONSTANTS, PERMISSIONS, TEXT_CONSTANTS } from "../../../utils/constants"
import { checkIfUserHasAccess } from "../../../utils/users"

class Users extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            isAddUser: false,
            isDelete: false,
            idToDelete: -1,
            users: [],
            editedItemList: [],
            updatedRoles: {},
            filterOptions: {
                userRole: 0,
                userType: "ALL",
                searchTag: "",
                length: 10,
                start: 0
            },
            pageCount: 0,
            currentPage: 0,
            selectedRoleFilter: "",
            editError: ""
        }
        this.canUpdateStatus = checkIfUserHasAccess([
            PERMISSIONS?.SETTING_USER_MANAGEMENT_USERS_EDIT
        ])
    }
    options = [
        { id: 10, name: "10" },
        { id: 20, name: "20" },
        { id: 30, name: "30" },
        { id: 40, name: "40" },
        { id: 50, name: "50" }
    ]

    componentDidMount() {
        const { location } = this.props
        location.value && this.settingFilter(location.value)
        scrollPage() // scroll page up on first page load

        this.loadHandler()
    }

    loadHandler = async () => {
        let { filterOptions } = this.state
        const { fetchAllRoles } = this.props
        // const authUserData = localStorage.getItem("auth");
        // if (authUserData && filterOptions?.userRole === 0) {
        //   const res = decryptData(
        //     authUserData,
        //     process?.env.REACT_APP_LOCAL_STORAGE_SECRETE_KEY
        //   );
        // var defaultUserRoleId = res?.data?.user?.roles?.[0]?.id;
        // filterOptions.userRole = defaultUserRoleId ? defaultUserRoleId : 0;
        // }
        // let isSuperAdminOrAdminUser = this.isSuperAdminOrAdminUser();
        // if (
        //   !isEmpty(isSuperAdminOrAdminUser) &&
        //   (filterOptions?.userRole === 3 || filterOptions?.userRole === 2)
        // ) {
        //   filterOptions.userRole = 0;
        // }
        this.setState({
            isLoading: true,
            filterOptions
        })
        await fetchAllRoles()
        const response = await this.props.fetchUsersList(filterOptions)
        if (response && response.status) {
            this.setState(
                {
                    users: response.data,
                    isLoading: false
                },
                () => {
                    this.pageCountSetup()
                }
            )
        }
    }

    pageCountSetup = () => {
        const { userCount } = this.props
        const { length } = this.state.filterOptions
        let pageCount = Math.ceil(userCount / length)
        this.setState({
            pageCount
        })
    }

    settingFilter = (value) => {
        if (value) {
            let { filterOptions } = this.state
            filterOptions["userRole"] = value.id
            this.setState({
                filterOptions,
                selectedRoleFilter: value.name
            })
        }
    }

    showPerPageChange = (name, value) => {
        let { filterOptions } = this.state
        filterOptions["length"] = value
        filterOptions["start"] = 0
        this.setState(
            {
                filterOptions,
                currentPage: 0
            },
            () => {
                this.loadHandler()
            }
        )
    }

    handleChange = async (id, isActive, selectedRowRole) => {
        const { changeUserState, usersList, addUserDetails, userInfo } = this.props
        if (userInfo.id !== id && !this.disableSuperAdminBasedOnUserRole(selectedRowRole)) {
            if (isActive) {
                let response = await changeUserState(id)
                if (response && response.status) {
                    this.loadHandler()
                }
            } else {
                let tempId = {}
                usersList.map((item) => {
                    if (item.id === id) {
                        item.active = !item.active
                        delete item.password
                        return (tempId = item)
                    }
                    return item
                })
                let response = await addUserDetails(tempId)
                if (response && response.status) {
                    this.loadHandler()
                }
            }
        }
    }

    handleIsAddClick = () => {
        let { filterOptions, isAddUser } = this.state
        this.setState(
            {
                isAddUser: !isAddUser,
                filterOptions: { ...filterOptions, searchTag: "", userRole: 0, userType: "ALL" }
            },
            () => {
                scrollPage()
                this.loadHandler()
            }
        )
        // this.loadHandler();
    }

    handleSearch = async (name, value) => {
        const { filterOptions } = this.state
        filterOptions["searchTag"] = value
        this.setState(
            {
                filterOptions
            },
            () => {
                this.onPageChange({ selected: 0 })
            }
        )
        // this.loadHandler();
    }

    handleOnSelect = (user, value, id) => {
        this.setState({
            updatedRoles: {
                id: id,
                value: value
            }
        })
    }
    handleUpdateRole = async () => {
        const { usersList, addUserDetails } = this.props
        let { value, id } = this.state.updatedRoles
        let tempItem = {}
        usersList.map((item) => {
            if (item.id === id) {
                delete item.password
                item.roles = value
                return (tempItem = item)
            }
            return item
        })
        if (Object.keys(tempItem)?.length > 0) {
            let response = await addUserDetails(tempItem)
            if (response && response.status) {
                this.loadHandler()
            }
        }
        this.setState({
            updatedRoles: {}
        })
    }

    handleDelete = async (id) => {
        let response = await this.props.softDeleteUser(id)
        if (response && response.status) {
            this.setState(
                {
                    isDelete: !this.state.isDelete
                },
                () => this.loadHandler()
            )
        }
    }

    handleFilterChange = async (filter) => {
        this.loadHandler()
    }

    handleUpdate = async (value, row) => {
        row.name = value
        delete row.password
        let response = await this.props.addUserDetails(row)
        if (response && response.status) {
            this.loadHandler()
        }
        this.setState({
            editError: ""
        })
    }

    onChangeFilter = (name, value) => {
        let { filterOptions } = this.state
        filterOptions[name] = value
        filterOptions.start = 0
        this.setState({
            currentPage: 0,
            filterOptions
        })
        this.loadHandler()
    }

    onPageChange = async (e) => {
        const { filterOptions } = this.state
        const selectedPage = e.selected
        filterOptions.start = selectedPage * filterOptions.length
        this.setState(
            {
                currentPage: selectedPage,
                filterOptions
            },
            () => scrollPage()
        )
        this.loadHandler()
    }

    handleDeleteClick = (id) => {
        const { isDelete, idToDelete } = this.state
        if (!isDelete) {
            this.setState({
                idToDelete: id,
                isDelete: !this.state.isDelete
            })
        } else if (id === idToDelete) {
            this.setState({
                idToDelete: -1,
                isDelete: !this.state.isDelete
            })
        }
    }

    tableloader = () => {
        let listLoader = [1, 2, 3, 4, 5, 6]
        return (
            <div className="rowContainer-loader">
                {listLoader.map(() => {
                    return (
                        <div className="row_loader">
                            <div className="block_loader" />
                            <div className="block_loader" />
                            <div className="block_loader" />
                            <div className="switch_loader" />
                        </div>
                    )
                })}
            </div>
        )
    }

    setSearchQuery = debounce((name, value) => {
        this.handleSearch(name, value)
    }, 300)

    // Remove SuperAdmin Role if user is not superAdmin
    removeSuperAdminRole = () => {
        const { roles } = this.props
        // if (!isSuperAdminUser(userInfo?.roles)) {
        return roles?.filter(
            (item) => item?.name?.replaceAll(" ", "")?.toLowerCase() !== PERMISSIONS?.SUPER_ADMIN
        )
        // }
        // return roles;
    }

    isSuperAdminOrAdminUser = () => {
        const { userInfo } = this.props
        // if (!isSuperAdminUser(userInfo?.roles)) {
        return userInfo?.roles?.filter(
            (item) =>
                item?.name?.replaceAll(" ", "")?.toLowerCase() === PERMISSIONS?.SUPER_ADMIN ||
                item?.name?.replaceAll(" ", "")?.toLowerCase() === PERMISSIONS?.ADMIN
        )
    }

    disableSuperAdminBasedOnUserRole = (selectedRowRoles) => {
        const { userInfo } = this.props
        if (isSuperAdminUser(userInfo?.roles)) {
            return false
        }
        // If the selected row contain super Admin role -> disable that row
        return isSuperAdminUser(selectedRowRoles)
    }

    render() {
        const {
            isAddUser,
            users,
            isDelete,
            idToDelete,
            editError,
            selectedRoleFilter,
            pageCount,
            isLoading
        } = this.state
        const { roles, userCount, userInfo } = this.props
        const userType = [
            { id: "ACTIVE", name: "active" },
            { id: "INACTIVE", name: "inactive" }
        ]
        const tableHeading = USER_TABLE_HEADING.map((item, index) => {
            return <th key={index}>{item.key}</th>
        })
        const tableRows =
            users &&
            users.map((row, index) => {
                return (
                    <tr id={row.id} key={row.id}>
                        <td>{index + 1}</td>
                        <td className="editable-content">
                            <Editable
                                type="text"
                                id={row.id}
                                name={row.name}
                                value={row.name}
                                row={row}
                                dValue={row.name}
                                editError={editError}
                                updateHandler={this.handleUpdate}
                                disabled={!this.canUpdateStatus}
                            />
                        </td>
                        <td className="email-field">{row.email}</td>
                        <td
                            onDoubleClick={this.changeEditableState}
                            onBlur={this.handleEdit}
                            className="rolesCol"
                        >
                            <EditableMultiSelect
                                roles={roles}
                                userId={row.id}
                                selectedValues={row.roles}
                                handleOnSelect={this.handleOnSelect}
                                dvalue={row}
                                updateRole={this.handleUpdateRole}
                                disabled={!this.canUpdateStatus || isSuperAdminUser(row?.roles)}
                                userInfo={userInfo}
                                removeSuperAdminRole={this.removeSuperAdminRole}
                            />
                        </td>
                        <td className="btn-popupContainer">
                            <label
                                className={`switch-button ${
                                    row.id === userInfo.id ||
                                    this.disableSuperAdminBasedOnUserRole(row?.roles)
                                        ? "disable-btn"
                                        : ""
                                }`}
                            >
                                <input
                                    type="checkbox"
                                    name="switch"
                                    id={row.id}
                                    onChange={(id) =>
                                        this.handleChange(row.id, row.active, row?.roles)
                                    }
                                    checked={!row.active ? true : false}
                                    disabled={!this.canUpdateStatus}
                                />
                                <div
                                    className={`switch-slider switch-round ${
                                        row.active ? "icon-tick" : "icon-close"
                                    }`}
                                >
                                    <span className="isChecked">
                                        {TEXT_CONSTANTS?.NO?.toUpperCase()}
                                    </span>
                                    <span className="isUnChecked">
                                        {TEXT_CONSTANTS?.YES?.toUpperCase()}
                                    </span>
                                </div>
                            </label>
                            {!row.active ? (
                                <i
                                    className="icon-del"
                                    onClick={() => this.handleDeleteClick(row.id)}
                                />
                            ) : (
                                <i></i>
                            )}
                            {idToDelete === row.id && (
                                <div
                                    className={`deletePopup ${
                                        isDelete ? "showPopUp" : "hidePopUp"
                                    }`}
                                >
                                    {this.canUpdateStatus && (
                                        <>
                                            <p>{TEXT_CONSTANTS?.DELETE_THIS_USER}</p>
                                            <Button
                                                cName="btn popup-del-btn"
                                                bValue={BUTTON_CONSTANTS?.YES_DELETE}
                                                bType="button"
                                                clickHandler={() => this.handleDelete(row.id)}
                                            />
                                        </>
                                    )}
                                    <Button
                                        cName="btn cancel-btn"
                                        bValue={BUTTON_CONSTANTS?.CANCEL}
                                        bType="button"
                                        clickHandler={() => this.handleDeleteClick(row.id)}
                                    />
                                </div>
                            )}
                        </td>
                    </tr>
                )
            })
        return (
            <div className="vd-user-management">
                <div className="vr-cont-head mb">
                    {/* TODO: Replace below with the reusable pageTitleComponent */}
                    <div className="vr-cont-title">
                        <span className="vr-breadcrum">
                            {TEXT_CONSTANTS?.USER_MANAGEMENT} <i className="icon-arrow-right1" />
                            <span>{TEXT_CONSTANTS?.USERS?.toLowerCase()}</span>
                        </span>
                        <h2 className="breadcrum-header">{TEXT_CONSTANTS?.USERS?.toUpperCase()}</h2>
                    </div>
                    <div className="vr-cont-action header-filter-btn">
                        {!isAddUser && (
                            <React.Fragment>
                                <Filter
                                    text="all users roles"
                                    selectedValue={selectedRoleFilter}
                                    id="userRole"
                                    defaultValue="0"
                                    filterList={roles}
                                    changeHandler={this.onChangeFilter}
                                />
                                <Filter
                                    cName="cs_filter"
                                    text="all users type"
                                    id="userType"
                                    defaultValue="ALL"
                                    filterList={userType}
                                    changeHandler={this.onChangeFilter}
                                />
                            </React.Fragment>
                        )}
                        {this.canUpdateStatus && (
                            <Button
                                cName={`btn ${!isAddUser ? "primary-btn" : "border-btn"}`}
                                bValue={`${
                                    !isAddUser ? BUTTON_CONSTANTS?.ADD_NEW : BUTTON_CONSTANTS?.BACK
                                }`}
                                leftIcon={isAddUser && "icon-arrow-left1"}
                                clickHandler={this.handleIsAddClick}
                            />
                        )}
                    </div>
                </div>
                {!isAddUser ? (
                    <div className="vr-content-holder">
                        <div className="vr-cont-head">
                            <div className="vr-cont-title">
                                <h2 className="inner-count-title">
                                    {userCount && userCount < 10
                                        ? `0${userCount && userCount}`
                                        : userCount && userCount}{" "}
                                    {TEXT_CONSTANTS?.USERS?.toUpperCase()}
                                </h2>
                            </div>
                            <div className="vr-cont-action">
                                <Search
                                    name="search"
                                    changeHandler={this.setSearchQuery}
                                    placeholder="Search Here"
                                />
                            </div>
                        </div>
                        {isLoading ? (
                            this.tableloader()
                        ) : userCount && userCount > 0 ? (
                            <table className="table user-role-table">
                                <thead>
                                    <tr>{tableHeading}</tr>
                                </thead>
                                <tbody>{tableRows}</tbody>
                            </table>
                        ) : (
                            <div style={{ paddingLeft: "20px" }}>
                                {TEXT_CONSTANTS?.NO_RESULT_FOUND}
                            </div>
                        )}
                    </div>
                ) : (
                    <AddUser
                        handleBack={this.handleIsAddClick}
                        removeSuperAdminRole={this.removeSuperAdminRole}
                    />
                )}
                {!isAddUser && (
                    <Pagination
                        changeHandler={this.showPerPageChange}
                        options={this.options}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.onPageChange}
                        currentPage={this.state.currentPage}
                        selectedOption={this.options.find(
                            (item) => item?.id === this.state.filterOptions?.length
                        )}
                    />
                )}
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        usersList: get(state.userManagement, "users.data", []),
        roles: get(state.userManagement, "allRoles", []),
        allUser: get(state.userManagement, "allUsers", []),
        userCount: get(state.userManagement, "users.count", []),
        authDetails: get(state.auth, "user", []),
        userInfo: get(state.userProfile, "userDetails", [])
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                fetchUsersList,
                fetchAllRoles,
                changeUserState,
                softDeleteUser,
                addUserDetails
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)
