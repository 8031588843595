import store from "../store"
import moment from "moment-timezone"

import { API_ROOT_PATH, imagePathPrefix } from "../config/apiPath"
import { isEmpty } from "lodash"
import { DEFAULT_SELECTED_LANGUAGE_CODE } from "../components/ContentNav/api/constants"
import { toast } from "react-toastify"
import { PERMISSIONS } from "./constants"
import { encryptData } from "./EncryptDecrypt"

export const serialize = (obj) => {
    var str = []
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]))
        }
    return str.join("&")
}

export const setLanguageInLocalStorage = (languages) => {
    let languageObj = {}
    // Only add enabled languages in the language object

    languageObj = languages && languages.filter((item) => item?.enable)
    localStorage.setItem("languages", JSON.stringify(languageObj))
    return languageObj
}

export const replaceStringWithParams = (str, obj) => {
    for (var j in obj) {
        var regExp = new RegExp(j, "g")
        str = str.replace(regExp, obj[j])
    }
    return str
}

export const isSubtitleEnabled = () => {
    return true
}

export const isUserloggedIn = () => {
    const state = store.getState()
    let userInfo = state && state.auth

    if (userInfo && (!userInfo.details || (userInfo.details && !userInfo.details.access_token))) {
        userInfo = false
    }
    return userInfo
}

export const isMultiLingual = () => {
    return true
}

export const isMultiTenant = () => {
    return true
}

export const isSubscriptionAllowed = () => {
    return true
}

export const getAdditionalDetails = () => {
    // return cookie.load("additionalDetails");
    return JSON.parse(localStorage.getItem("additionalDetails"))
}

export const removeUserCookie = () => {
    // cookie.remove("auth", { path: "/" });
    // cookie.remove("accessToken", { path: "/" });
    // cookie.remove("refreshToken", { path: "/" });
    localStorage.removeItem("auth")
    localStorage.removeItem("accessToken")
    localStorage.removeItem("refreshToken")
}

export const saveUserCookie = (tokenDetails, path) => {
    // cookie.save("auth", tokenDetails, { path: path });
    // cookie.save("accessToken", tokenDetails.data.jwtToken.accessToken, {
    //   path: path,
    // });
    // cookie.save("refreshToken", tokenDetails.data?.refreshToken, {
    //   path: path,
    // });

    localStorage.setItem(
        "auth",
        encryptData(tokenDetails, process.env.REACT_APP_LOCAL_STORAGE_SECRETE_KEY)
    )
    localStorage.setItem("accessToken", JSON.stringify(tokenDetails.data.jwtToken.accessToken))
    localStorage.setItem("refreshToken", JSON.stringify(tokenDetails.data?.refreshToken))
}

export const additionalDetailsCookie = (details, appType, path) => {
    const newDetails = { ...details }
    delete newDetails.url
    if (typeof window != "undefined") {
        window.localStorage.setItem("url", details.url)
    }
    // cookie.save("additionalDetails", newDetails, { path: path });
    localStorage.setItem("additionalDetails", JSON.stringify(newDetails))
}

export const catchError = (dispatch, type, e) => {
    return dispatch({
        type: type,
        apiResponse: e,
        isLoading: false
    })
}

export const getBaseUrl = () => {
    return API_ROOT_PATH
}

export const toLocaleString = (str) => {
    return str ? Number(str).toLocaleString("en-IN") : ""
}

export const parseNumber = (value, defaultValue = 0) => {
    return parseInt(value) || defaultValue
}

export const scrollPage = () => {
    return window.scrollTo(0, 0)
}

export const dateTime = (dateValue) => {
    let dt = new Date(dateValue)
    let date = moment(dt).format("DD/MM/YYYY")
    let time = moment(dt).format("hh:mm A")
    return { date, time }
}

export const currentFormattedDate = (elementId) => {
    var today = new Date()
    var dd = today.getDate()
    var mm = today.getMonth() + 1 //January is 0!
    var yyyy = today.getFullYear()
    if (dd < 10) {
        dd = "0" + dd
    }
    if (mm < 10) {
        mm = "0" + mm
    }
    today = yyyy + "-" + mm + "-" + dd
    return today
    // document.getElementById(elementId).setAttribute("min", today);
}

export const debounce = (fn, delay) => {
    let timeoutId
    return function (...args) {
        if (timeoutId) {
            clearTimeout(timeoutId)
        }
        timeoutId = setTimeout(() => {
            clearTimeout(timeoutId)
            timeoutId = null
            fn(...args)
        }, delay)
    }
}

export const isSuperAdmin = true

export const ERROR_MESSAGES_CONST = {
    DATA_NOT_FOUND: "Data not found",
    UNAUTHORISED_ACCESS: "Unauthorized access",
    ERROR_OCCURED: "An error occurred"
}

export const checkAllLanguageHasValue = (langData, languageObject) => {
    let isDataFilledInAllLang = true
    for (let key in languageObject) {
        let val = langData?.[key]
        if (Array.isArray(val)) {
            val = val?.join(",")
        }
        if (isEmpty(val?.trim())) {
            isDataFilledInAllLang = false
        }
    }
    return isDataFilledInAllLang
}

export const getContentData = (value, multilingualKey = "is_multilingual") => {
    let val
    if (isMultiLingual() && typeof value === "object" && value?.hasOwnProperty(multilingualKey)) {
        val = value?.values[DEFAULT_SELECTED_LANGUAGE_CODE]
    } else {
        val = value
    }
    if (Array.isArray(val)) {
        val = val?.join(", ")
    }
    return val
}

export const getMultiLingualValue = (
    isMultiLingualAllowed,
    value,
    selectedLanguageCode,
    multilingualKey = "is_multilingual"
) => {
    return isMultiLingualAllowed && value?.hasOwnProperty(multilingualKey)
        ? value && value?.values[selectedLanguageCode]
            ? value?.values[selectedLanguageCode]
            : ""
        : value
}

export const formatValueAccToMutlilingualAPI = (
    value,
    selectedLangCode,
    multilingualKey = "is_multilingual"
) => {
    if (value?.hasOwnProperty(multilingualKey)) {
        let tempObj = { [multilingualKey]: true, values: { ...value?.values } }

        if (Array.isArray(tempObj.values[selectedLangCode])) {
            const tempList = []
            if (Array.isArray(value[selectedLangCode])) {
                value[selectedLangCode]?.length > 0 &&
                    value[selectedLangCode].map((i) => tempList.push(i?.name))
                tempObj.values[selectedLangCode] = [...tempList]
            } else {
                tempObj.values[selectedLangCode] = [...value[selectedLangCode]?.split(",")]
            }
        } else {
            if (Array.isArray(value?.[selectedLangCode])) {
                const tempList = []
                value[selectedLangCode]?.length > 0 &&
                    value[selectedLangCode].map((i) => tempList.push(i?.name))
                tempObj.values[selectedLangCode] = [...tempList]
            } else {
                tempObj.values[selectedLangCode] = value[selectedLangCode]
            }
            if (
                typeof tempObj?.values[selectedLangCode] === "string" &&
                !tempObj.values[selectedLangCode]?.trim()
            ) {
                delete tempObj.values[selectedLangCode]
            }
        }

        return tempObj
    } else {
        if (Array.isArray(value[selectedLangCode])) {
            let tempObj = { ...value }
            const tempList = []
            if (Array.isArray(value[selectedLangCode])) {
                value[selectedLangCode]?.length > 0 &&
                    value[selectedLangCode].map((i) => tempList.push(i?.name))
                tempObj[selectedLangCode] = [...tempList]
                return { [multilingualKey]: true, values: { ...tempObj } }
            }
        }
        return { [multilingualKey]: true, values: { ...value } }
    }
}

export const isFieldMultilingual = (name, connectingString = "brand", multilingualKeys) => {
    const modName = name?.includes(connectingString) ? name : `${connectingString}${name}`
    const isMultiKeyPresent = multilingualKeys?.find((item) => {
        if (
            item
                ?.replaceAll("_", "")
                ?.toLowerCase()
                .includes(modName?.replaceAll("_", "")?.toLowerCase())
        )
            return true

        return false
    })
    return isMultiLingual() && !!isMultiKeyPresent
}
export const EpochToDateTime = (dateValue) => {
    let dt = new Date(dateValue)
    let date = moment.unix(dt).format("DD/MM/YYYY")
    let time = moment.unix(dt).format("hh:mm A")
    return { date, time }
}
export const formatAndAssignValue = (isMulti, languageCode, prevValue, updatedValue) => {
    let updatedMultilingualValue
    if (isMulti) {
        updatedMultilingualValue = { ...prevValue }
        updatedMultilingualValue[languageCode] = updatedValue
        updatedMultilingualValue = formatValueAccToMutlilingualAPI(
            updatedMultilingualValue,
            languageCode
        )
        return updatedMultilingualValue
    }
    return updatedValue
}

export const getDefaultMultilingualValue = (value, langCode = DEFAULT_SELECTED_LANGUAGE_CODE) => {
    if (isMultiLingual()) {
        return value[langCode]
    }
    return value
}

const setErrorText = (errMessage) => {
    toast.error(errMessage)
    console.log("Error Exception : ", errMessage)
}

export const setErrorMessage = (errMessage, statusCode) => {
    if (!statusCode) setErrorText(errMessage)
    else {
        if (statusCode === 404) {
            setErrorText(ERROR_MESSAGES_CONST.DATA_NOT_FOUND)
        } else if (statusCode === 401) {
            setErrorText(ERROR_MESSAGES_CONST.UNAUTHORISED_ACCESS)
        } else {
            setErrorText(!isEmpty(errMessage) || ERROR_MESSAGES_CONST.ERROR_OCCURED)
        }
    }
}

export const isSuperAdminUser = (roles) => {
    const isSuperAdmin = roles?.find(
        (item) => item?.name?.replaceAll(" ", "")?.toLowerCase() === PERMISSIONS?.SUPER_ADMIN
    )
    return !!isSuperAdmin
}

export const getUTCOffset = (timezone) => {
    console.log("moment.zone", moment().utcOffset())
    return !timezone ? moment().utcOffset() : timezone?.offset

    //  if(timeDifference < 0){
    //     return Math
    //  }
}

export const sortArray = (inputArray) => {
    const modifiedArray = [...inputArray]
    modifiedArray.sort((a, b) => {
        if (a?.position < b?.position) {
            return -1
        } else if (a?.position > b?.position) {
            return 1
        }
        return 0
    })
    return modifiedArray
}

export const getBrowserType = () => {
    var sBrowser,
        sUsrAg = navigator.userAgent
    if (sUsrAg.indexOf("Firefox") > -1) {
        sBrowser = "Mozilla Firefox"
    } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
        sBrowser = "Samsung Internet"
    } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
        sBrowser = "Opera"
    } else if (sUsrAg.indexOf("Trident") > -1) {
        sBrowser = "Microsoft Internet Explorer"
    } else if (sUsrAg.indexOf("Edge") > -1) {
        sBrowser = "Microsoft Edge"
    } else if (sUsrAg.indexOf("Chrome") > -1) {
        sBrowser = "Google Chrome or Chromium"
    } else if (sUsrAg.indexOf("Safari") > -1) {
        sBrowser = "Apple Safari"
    } else {
        sBrowser = "unknown"
    }

    return sBrowser
}

export const base64EncodeUint8Array = function (a) {
    for (
        var d,
            e,
            f,
            g,
            h,
            i,
            j,
            b = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
            c = "",
            k = 0;
        k < a.length;

    ) {
        d = a[k++]
        e = k < a.length ? a[k++] : Number.NaN
        f = k < a.length ? a[k++] : Number.NaN
        g = d >> 2
        h = ((3 & d) << 4) | (e >> 4)
        i = ((15 & e) << 2) | (f >> 6)
        j = 63 & f
        isNaN(e) ? (i = j = 64) : isNaN(f) && (j = 64)
        c += b.charAt(g) + b.charAt(h) + b.charAt(i) + b.charAt(j)
    }
    return c
}

export const getLanguages = () => {
    return JSON.parse(localStorage.getItem("languages"))
}

export const base64DecodeUint8Array = function (a) {
    var b = window.atob(a),
        c = b.length,
        d = new Uint8Array(new ArrayBuffer(c))
    for (let i = 0; i < c; i++) d[i] = b.charCodeAt(i)
    return d
}

export const arrayToString = function (a) {
    var b = new Uint16Array(a.buffer)
    return String.fromCharCode.apply(null, b)
}

// Custom function to check if an element is outside another element
// Using ClassList to check elements -> used for Tables, as noraml comparison is not working
export const isClickedOutside = (target, reference) => {
    if (!target || !reference) {
        return false
    }
    let element = target

    while (element) {
        if (element?.classList?.contains(reference?.className)) {
            return false
        }
        element = element.parentNode
    }

    return true
}

// TO generate unique Ids
export const getUniqueId = () => {
    return Math.random().toString(36).substring(2) + Date.now().toString(36)
}

export const getImageRecommendedText = (deviceSizeName) => {
    deviceSizeName = deviceSizeName?.toLowerCase()?.replace(/\s/g, "")
    switch (deviceSizeName) {
        case "mobile_banner_image":
        case "mobilebannerimage":
            return "Recommended (420 x 560 px)"
        case "web_banner_image":
        case "webbannerimage":
        case "bannerimage":
            return "Recommended (1920 x 1080 px)"
        case "coverimage":
        case "cover_image":
        case "episodecoverimage":
            return "Recommended (512 x 288 px)"
        case "poster_image":
        case "episodeposterimage":
        case "seasonposterimage":
        case "posterimage":
            return "Recommended (420 x 560 px)"
        case "icon":
            return "Recommended (50 x 50 px)"
        default:
            return "Recommended (1200 x 1778 px)"
    }
}

export const isWebSeriesTitle = (contentTypeName = "") => {
    return contentTypeName?.replace(/\s/g, "")?.replace("_", "")?.toLowerCase() === "webseries"
}

export const timeToSeconds = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(":").map(Number)
    return hours * 3600 + minutes * 60 + seconds
}

export const getCurrentUserTimezone = () => {
    // Get the user's current timezone offset in minutes
    const userOffset = moment().utcOffset()

    // Find the user's timezone based on the offset
    let userTimezone = moment.tz.names().find((timezone) => {
        return moment().tz(timezone).utcOffset() === userOffset
    })

    if (userTimezone?.includes("Asia/Calcutta")) {
        userTimezone = userTimezone?.replace("Asia/Calcutta", "Asia/Kolkata")
    }

    // Get the current timezone abbreviation (e.g., EST, GMT)
    const timezoneAbbreviation = new Date().toTimeString()?.split(" ")?.[1]

    // Return the user's timezone or a default value
    return userTimezone ? `${userTimezone}(${timezoneAbbreviation})` : "Asia/Kolkata (GMT+05:30)"
}

// Convert the time and returns UTC string
export const getUTCTime = (timeStamp) => {
    const dateObj = new Date(timeStamp)
    const hour = dateObj.getUTCHours().toString().padStart(2, "0")
    const minutes = dateObj.getUTCMinutes().toString().padStart(2, "0")
    const timeUTCString = [hour, minutes].join(":")
    return timeUTCString
}

// Convert the time and give time based on user system time
export const getTime = (timeStamp) => {
    const dateObj = new Date(timeStamp)
    const hour = dateObj.getHours().toString().padStart(2, "0")
    const minutes = dateObj.getMinutes().toString().padStart(2, "0")
    const timeUTCString = [hour, minutes].join(":")
    return timeUTCString
}

// Converts the time and return time based on timezone provided
export const getTimeBasedOnTimezoneString = (timeStamp, timezone = "Asia/Kolkata") => {
    // Converting string as date obj only identifies Asia/Kolkata
    if (timezone?.includes("Asia/Calcutta")) {
        timezone = timezone?.replace("Asia/Calcutta", "Asia/Kolkata")
    }
    timezone = getGMTTimezone(timezone, 0)?.trim()
    let dateObj = new Date(timeStamp).toLocaleString("en-US", {
        timeZone: timezone,
        hour: "2-digit",
        minute: "2-digit",
        hour12: false
    })
    dateObj = dateObj?.split(":")
    const hour = dateObj?.[0] === "24" ? "00" : dateObj?.[0]
    return [hour, dateObj?.[1]].join(":")
}

export const getCurrentUTCTimestamp = () => {
    const utcTimestamp = new Date(Date.now()).getTime()
    return utcTimestamp
}

export const getCurrentTimestamp = () => {
    const utcTimestamp = new Date(Date.now() - new Date().getTimezoneOffset() * 60 * 1000).getTime()
    return utcTimestamp
}

/**
 * Returns the timezone from given string
 * Input String : "Africa/Johannesburg (GMT+02:00)"
 * output: TimezoneString = 1 ->  GMT+02:00
 *         TimezoneString = 0 ->  Africa/Johannesburg
 */
export const getGMTTimezone = (inputString, timezoneString = 1) => {
    // let pattern = /\((.*?)\)/;
    inputString = inputString?.split("(")
    if (timezoneString === 1) {
        let GMTString = inputString?.[timezoneString]
        return GMTString?.split(")")?.[0]
    }
    return inputString?.[timezoneString]
    // Use match() method to find the first match of the pattern in the given string
    // let matches = inputString?.match(pattern);
    // return matches ? matches?.[timezoneString] : "GMT+00:00"
}

/**
 * Returns the date from given timezone
 * Input String : "Africa/Johannesburg (GMT+02:00)"
 * output: Returns date YYYY/MM/DD
 */
export const getTimezoneBasedDate = (timezone) => {
    const dateString = getTimezoneBasedDateAndTime(timezone)
    const datePart = dateString?.split(",")?.[0]
    const fullDate = datePart?.split("/")

    const month = fullDate?.[0]?.padStart(2, "0")
    const date = fullDate?.[1]?.padStart(2, "0")
    const year = fullDate?.[2]
    return `${year}/${month}/${date}`
}

/**
 * Returns full date and time from given timezone
 * Input String : "Africa/Johannesburg (GMT+02:00)"
 *
 */
export const getTimezoneBasedDateAndTime = (timezone) => {
    let timezoneString = timezone
    if (!timezoneString) {
        timezoneString = getCurrentUserTimezone()
    }
    timezoneString = timezoneString?.split("(")?.[0]?.trim()
    return new Date().toLocaleString("en-US", { timeZone: timezoneString })
}

export const joinUrl = (url) => {
    let updatedPath = imagePathPrefix
    if(!imagePathPrefix.endsWith('/')){
        updatedPath = `${imagePathPrefix}/`
    }
    return url.includes("https") ? url : updatedPath + url
}

export const getEPGImagePath = (imageData) => {
    let logo = imageData?.find(item => item?.type?.toLowerCase() === "poster");
    if(!logo){
        logo = imageData?.find(item => item?.type?.toLowerCase() === "logo");
    }
    return joinUrl(logo?.url) 
}

// Return Date Month year
export const getCurrentDate = () => {
    const date = new Date();
    return new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }).format(date);

}