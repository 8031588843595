import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import {
    CONTENTENRICHMENT_MODAL_TABLE_HEADING,
    CONTENTENRICHMENT_TABLE_HEADING,
    ModalTabList,
    PaginationOptions
} from "./api/constants"
import Pagination from "../common/Pagination"
import CustomModal from "../common/Modal"
import eyeIcon from "../../assets/images/eyeIcon.svg"
import Loader from "../../assets/images/loader.gif"

import "./style.scss"
import { getMoviesAndEpisodeData, getMoviesAndEpisodeCount } from "./api/action"
import { dateTime } from "../../utils/common"
import { imgPathPrefix } from "./api/constants"
import { TEXT_CONSTANTS } from "../../utils/constants"

export default function ContentEnrichment() {
    const [ce_tableData, setCe_tableData] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [selectedRow, setSelectedRow] = useState()
    const [selectedRowData, setSelectedRowData] = useState({
        selectedTab: "MOVIES",
        moviesData: [],
        episodeData: []
    })

    const dispatch = useDispatch()
    const isLoading = useSelector((state) => state.loader.isLoading)

    useEffect(() => {
        // Fetching the movies and episode count
        async function fetchData() {
            const response = await dispatch(getMoviesAndEpisodeCount())
            setCe_tableData([
                {
                    id: 1,
                    name: TEXT_CONSTANTS?.FINGER_PRINTING,
                    moviesCount: response?.fingerprinting?.MOVIES || 0,
                    episodeCount: response?.fingerprinting?.EPISODE || 0,
                    key: "fingerprinting"
                },
                {
                    id: 2,
                    name: TEXT_CONSTANTS?.BINGE_MARKER_IDENTIFICATION,
                    moviesCount: response?.bingeMarkerIdentification?.MOVIES || 0,
                    episodeCount: response?.bingeMarkerIdentification?.EPISODE || 0,
                    key: "bingeMarkerIdentification"
                },
                {
                    id: 3,
                    name: TEXT_CONSTANTS?.AD_BREAK_IDENTIFICATION,
                    moviesCount: response?.adBreaksIdentification?.MOVIES || 0,
                    episodeCount: response?.adBreaksIdentification?.EPISODE || 0,
                    key: "adBreaksIdentification"
                }
            ])
        }
        fetchData()
    }, [dispatch])

    const getSelectedIdResponse = (id, response) => {
        let data = {}
        if (id === "MOVIES") {
            data.moviesData = response
        } else {
            data.episodeData = response
        }
        return data
    }

    const actionHandler = async (selectedRow) => {
        setSelectedRow(selectedRow)
        setShowModal(true)
        // API call to fetch the movies and episode data
        const response = await dispatch(
            getMoviesAndEpisodeData(selectedRowData?.selectedTab, selectedRow?.key)
        )
        setSelectedRowData({
            ...selectedRowData,
            ...getSelectedIdResponse(selectedRowData?.selectedTab, response)
        })
    }

    const handleTabChange = async (item) => {
        setSelectedRowData({ ...selectedRowData, selectedTab: item?.id })
        // Fetch data only for the first time
        if (
            selectedRowData?.moviesData?.length === 0 ||
            selectedRowData?.episodeData?.length === 0
        ) {
            // API call to fetch the movies and episode data
            const response = await dispatch(getMoviesAndEpisodeData(item?.id, selectedRow?.key))
            setSelectedRowData((prevState) => {
                return {
                    ...prevState,
                    ...getSelectedIdResponse(item?.id, response)
                }
            })
        }
    }

    const modalSubmitHandler = (selectedRow) => {
        setShowModal(false)
    }

    const modalCancelHandler = (selectedRow) => {
        setShowModal(false)
        // Reset data on close
        setSelectedRowData({
            selectedTab: "MOVIES",
            moviesData: [],
            episodeData: []
        })
    }

    const getRowData = () => {
        return selectedRowData?.selectedTab === ModalTabList?.[0]?.id
            ? selectedRowData?.moviesData
            : selectedRowData?.episodeData
    }

    const displayLoader = () => {
        return (
            <div className="loader">
                <img src={Loader} alt="loader" width={"100px"} />
            </div>
        )
    }

    return (
        <div className="vd-content-enrichment-container">
            {renderPageTitle()}
            {isLoading && displayLoader()}
            <div className="vr-content-holder">
                <div className="vr-cont-head">
                    <div className="vr-cont-title">
                        <h2 className="inner-count-title">{TEXT_CONSTANTS?.LIST?.toUpperCase()}</h2>
                    </div>
                </div>
                {renderEnrichmentTableData({ actionHandler, ce_tableData })}
            </div>
            <CustomModal
                showModal={showModal}
                submitHandler={modalSubmitHandler}
                cancelHandler={modalCancelHandler}
                closeOnSubmit={false}
                showCancelButton={false}
                showSubmitButton={false}
                submitButtonLabel={"Save"}
            >
                {isLoading && displayLoader()}
                <h3 className="ce-modal-heading">{selectedRow?.name}</h3>
                <TabHeader
                    handleTabChange={handleTabChange}
                    selectedTab={selectedRowData?.selectedTab}
                ></TabHeader>
                <div className="ce-table-container">
                    {renderModalTableData({ rowData: getRowData() })}
                    {!isLoading && getRowData()?.length === 0 && (
                        <p className="no-data">{TEXT_CONSTANTS?.NO_DATA}</p>
                    )}
                </div>
            </CustomModal>
            <Pagination
                changeHandler={() => {}}
                options={PaginationOptions}
                pageCount={1}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={() => {}}
            />
        </div>
    )
}

const renderPageTitle = () => {
    return (
        <div className="vr-cont-head mb">
            {/* TODO: Replace below with the reusable pageTitleComponent */}
            <div className="vr-cont-title">
                <span className="vr-breadcrum">
                    {TEXT_CONSTANTS?.EDITORIAL} <i className="icon-arrow-right1" />
                    <span> {TEXT_CONSTANTS?.CONTENT_ENRICHMENT?.toLowerCase()}</span>
                </span>
                <h2 className="breadcrum-header">{TEXT_CONSTANTS?.CONTENT_ENRICHMENT}</h2>
            </div>
        </div>
    )
}

const getTableData = ({ classStyle, tableHeading, tableRows }) => {
    return (
        <table className={`table user-role-table ${classStyle}`}>
            <thead>
                <tr>{tableHeading}</tr>
            </thead>
            <tbody>{tableRows}</tbody>
        </table>
    )
}

const renderEnrichmentTableData = ({ actionHandler, ce_tableData }) => {
    const tableHeading = CONTENTENRICHMENT_TABLE_HEADING.map((item, index) => {
        return (
            <th key={index} className="custom_thead">
                {item?.header}
            </th>
        )
    })

    const tableRows = ce_tableData.map((row) => {
        return (
            <tr id={row?.id} key={row?.id} className="custom_tr">
                <td>{row?.id}</td>
                <td>{row?.name}</td>
                <td>{row?.moviesCount}</td>
                <td>{row?.episodeCount}</td>

                <td className="btn-popupContainer">
                    <img src={eyeIcon} alt="info-icon" onClick={() => actionHandler(row)} />
                </td>
            </tr>
        )
    })

    return getTableData({ classStyle: "ce", tableHeading, tableRows })
}

const renderModalTableData = ({ rowData }) => {
    const tableHeading = CONTENTENRICHMENT_MODAL_TABLE_HEADING.map((item, index) => {
        return (
            <th key={index} className="">
                {item?.header}
            </th>
        )
    })

    const tableRows = rowData?.map((row, index) => {
        return (
            <tr id={row?.contentId} key={row?.contentId} className="">
                <td>{index + 1}</td>
                <td>
                    {row?.contentImage ? (
                        <img
                            src={`${imgPathPrefix}${row?.contentImage}`}
                            alt="thumbnail"
                            className="movieThumbnail"
                            width={"150px"}
                            height={"80px"}
                        />
                    ) : (
                        <p>{TEXT_CONSTANTS?.NO_IMAGE}</p>
                    )}
                </td>
                <td>{row?.contentTitle || "-"}</td>
                <td>{dateTime(row?.contentIngestionDate)?.date}</td>
            </tr>
        )
    })

    return getTableData({
        classStyle: "ce-modal-table",
        tableHeading,
        tableRows
    })
}

function TabHeader(props) {
    const { selectedTab, handleTabChange } = props

    return (
        <div className="platform">
            {ModalTabList?.map((item, index) => {
                return (
                    <i
                        className={`icon-${item?.name.toLowerCase()} ${
                            selectedTab === item?.id ? "active" : ""
                        }`}
                        id={item.id}
                        onClick={() => handleTabChange(item)}
                    >
                        {item.name}
                    </i>
                )
            })}
        </div>
    )
}
