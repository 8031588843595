export const WebSeriesDropdownOptions = ["Brand", "Season", "Episode"]

export const PaginationOptions = [
    { id: 10, name: "10" },
    { id: 20, name: "20" },
    { id: 30, name: "30" },
    { id: 40, name: "40" },
    { id: 50, name: "50" }
]

export const FILTER_INITIAL_DATA = {
    length: 10,
    start: 0,
    searchTag: null,
    sortBy: "",
    sortOrder: "desc"
}

export const PAGINATION_INITIAL_DATA = {
    pageCount: 1,
    currentPage: 0,
    selectedOption: PaginationOptions[0],
    start: 0
}

export const FILE_STATUS_TAB = {
    SUCCESS_TAB: 0,
    FAILURE_TAB: 1
}

export const METADATA_INGESTION_CONTENT_HEADER = [
    {
        header: "Id",
        accessor: "id",
        type: "text",
        isSortEnabled: false
    },
    {
        header: "Email ID",
        accessor: "emailId",
        type: "text",
        isSortEnabled: false
    },
    {
        header: "Creation Date",
        accessor: "dateCreated",
        type: "text",
        isSortEnabled: false
    },
    {
        header: "Status",
        accessor: "status",
        type: "text",
        isSortEnabled: false,
        value: "status"
    },
    {
        header: "Platform",
        accessor: "platform",
        type: "text",
        isSortEnabled: false,
        value: "platform"
    },
    {
        header: "targetAppVersion",
        accessor: "targetAppVersion",
        type: "text",
        isSortEnabled: false,
        value: "targetAppVersion"
    },
    {
        header: "Update Type",
        accessor: "updateType",
        type: "text",
        isSortEnabled: false,
        value: "updateType"
    },
    {
        header: "action",
        accessor: "action",
        type: "text",
        isSortEnabled: false
    },
    { header: "", accessor: "copy", type: "text", isSortEnabled: false },
    { header: "", accessor: "view", type: "text", isSortEnabled: false }
]

export const SUCCESS_FAILURE_CONTENT_HEADER = [
    {
        header: "Title",
        accessor: "title",
        type: "text",
        isSortEnabled: false
    },
    {
        header: "Uploaded On",
        accessor: "uploadDate",
        type: "text",
        isSortEnabled: false,
        value: "uploadDate"
    },
    {
        header: "Uploaded By",
        accessor: "userId",
        type: "text",
        isSortEnabled: false,
        value: "userId"
    },
    {
        header: "Reason of Failure",
        accessor: "failureStatus",
        type: "text",
        isSortEnabled: false,
        value: "failureStatus"
    }
]
