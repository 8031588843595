import React from "react"
import Input from "../common/Input"
import { useState } from "react"
import { isEmpty } from "lodash"
import TextArea from "../common/TextArea"
import Checkbox from "../common/Checkbox"
import { toast } from "react-toastify"
import "./extendTrailForm.scss"
import CustomModal from "../common/Modal"
import { useEffect } from "react"
import { REGEX, TEXT_CONSTANTS } from "../../utils/constants"

const getTermsLabel = () => {
    return (
        <label htmlFor="checkbox-1" className="terms-text">
            {TEXT_CONSTANTS?.PRIVACY_POLICY_TXT}{" "}
            <a
                href="https://www.tothenew.com/privacy-policy"
                target="_blank"
                className={"privacyPolicy"}
                rel="noopener noreferrer"
            >
                {TEXT_CONSTANTS?.PRIVACY_POLICY}
            </a>{" "}
            {TEXT_CONSTANTS?.TERMS}
        </label>
    )
}

const formLabel = {
    formHeading: "Extend my trial by 1 week",
    formDescription:
        "Please share your details, our solutions expert will reach out to you shortly.",
    firstNameLabel: "First Name",
    lastNameLabel: "Last Name",
    organizationLabel: "Organization",
    phoneLabel: "Phone Number",
    emailLabel: "Business Email",
    messageLabel: "Message",
    termsConditionLabel: getTermsLabel(),
    buttonLabel: "Talk to our solutions expert"
}

export const validateEmail = (value) => {
    let email = value
    const emailRegex = REGEX.EMAIL_REGEX
    if (isEmpty(value) || !emailRegex.test(email)) {
        return "Please enter valid Email"
    }
    return ""
}

export const validatePassword = (value) => {
    if (isEmpty(value)) {
        return "Please enter your password"
    } else if (value.length < 6) {
        return "Password length should be minimum 6"
    }
    return ""
}

export const validateName = (value) => {
    return isEmpty(value) ? "Please enter value" : ""
}

export function ExtendTrailForm({
    isModalOpen,
    extendTrailHandler,
    tenantAdminName = "",
    tenantAdminContact = ""
}) {
    const [formData, setFormData] = useState({
        firstName: tenantAdminName?.split(" ")[0],
        lastName: tenantAdminName?.split(" ")[1],
        email: tenantAdminContact
    })
    const [error, setError] = useState({})
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        setShowModal(true)
    }, [isModalOpen])

    const validateField = (name, value) => {
        let errorObj = {}
        const setErrorVal = (val) => {
            errorObj[name] = val
        }

        switch (name) {
            case "email":
                setErrorVal(validateEmail(value))
                break
            case "password":
                setErrorVal(validatePassword(value))
                break
            default:
                setErrorVal(validateName(value))
                break
        }
        setError((prevState) => {
            return { ...prevState, ...errorObj }
        })
        return errorObj
    }
    const handleChange = (name, value) => {
        validateField(name, value)
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const isRequiredFieldsFilled = () => {
        const requiedFields = ["firstName", "lastName", "organization", "phoneNumber", "email"]
        let isError = false
        for (let i = 0; i < requiedFields?.length; i++) {
            const retObj = validateField(requiedFields[i], formData?.[requiedFields[i]]) //To show error msg if fields are not filled
            if (!isEmpty(retObj[requiedFields[i]])) {
                isError = true
            }
        }
        return isError
    }

    const onSubmit = async () => {
        if (isRequiredFieldsFilled()) {
            return
        } else if (!formData?.termsCondition) {
            toast.error("Please accept our terms and conditions")
            return
        }
        const res = await extendTrailHandler(formData)
        if (res.status) {
            setShowModal(false)
        }
    }

    const modalCancelHandler = () => {
        setShowModal(false)
    }

    const renderExtendTrailFormData = () => {
        return (
            <div className=" extend-trail">
                <div className="form-content">
                    <h3 className="form-head">{formLabel.formHeading}</h3>
                    <p className="form-des">{formLabel.formDescription}</p>
                    <div className="form-fields">
                        <Input
                            name="firstName"
                            placeholder={formLabel?.firstNameLabel}
                            changeHandler={(name, value) => handleChange(name, value)}
                            onBlur={(event) => validateField(event.target.name, event.target.value)}
                            errMsg={error.firstName}
                            required={true}
                            maxLength={60}
                            type={"text"}
                            labelText={formLabel?.firstNameLabel}
                            value={formData?.firstName}
                        />
                        <Input
                            name="lastName"
                            placeholder={formLabel?.lastNameLabel}
                            changeHandler={(name, value) => handleChange(name, value)}
                            onBlur={(event) => validateField(event.target.name, event.target.value)}
                            errMsg={error.lastName}
                            required={true}
                            maxLength={60}
                            type={"text"}
                            labelText={formLabel?.lastNameLabel}
                            value={formData?.lastName}
                        />
                    </div>
                    <div className="form-fields">
                        <Input
                            name="organization"
                            placeholder={formLabel?.organizationLabel}
                            changeHandler={(name, value) => handleChange(name, value)}
                            onBlur={(event) => validateField(event.target.name, event.target.value)}
                            errMsg={error.organization}
                            required={true}
                            maxLength={60}
                            type={"text"}
                            labelText={formLabel?.organizationLabel}
                            value={formData?.organization}
                        />
                        <Input
                            name="phoneNumber"
                            placeholder={formLabel?.phoneLabel}
                            changeHandler={(name, value) => handleChange(name, value)}
                            onBlur={(event) => validateField(event.target.name, event.target.value)}
                            errMsg={error.phoneNumber}
                            required={true}
                            maxLength={20}
                            type={"text"}
                            inputType={"Integer"}
                            labelText={formLabel?.phoneLabel}
                            value={formData?.phoneNumber}
                        />
                    </div>
                    <div className="form-fields">
                        <Input
                            name="email"
                            placeholder={formLabel?.emailLabel}
                            changeHandler={(name, value) => handleChange(name, value)}
                            onBlur={(event) => validateField(event.target.name, event.target.value)}
                            errMsg={error.email}
                            required={true}
                            maxLength={60}
                            type={"text"}
                            labelText={formLabel?.emailLabel}
                            value={formData?.email}
                        />
                    </div>
                    <div className="form-fields">
                        <TextArea
                            row={100}
                            col={10}
                            changeHandler={(name, val) => handleChange(name, val)}
                            name="description"
                            required={false}
                            value={formData?.description}
                            labelText={formLabel?.messageLabel}
                            placeholder={"Message"}
                        />
                    </div>
                    <div className="terms-condition-container">
                        <Checkbox
                            cName="terms-checkbox"
                            id={"checkbox-1"}
                            name={"termsCondition"}
                            changeHandler={(name, val) => handleChange(name, val)}
                            isChecked={formData?.termsCondition}
                        />
                        {formLabel?.termsConditionLabel}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="vr-dark-mode extend-form-section">
            <CustomModal
                showModal={showModal}
                submitHandler={onSubmit}
                cancelHandler={modalCancelHandler}
                closeOnSubmit={false}
                submitButtonLabel={formLabel?.buttonLabel}
                showCancelButton={false}
                cancelOnOutsideClick={false}
                showCloseIcon={false}
                isSubmitDisabled={!formData?.termsCondition}
            >
                {renderExtendTrailFormData()}
            </CustomModal>
        </div>
    )
}
