import { contentType } from "../../Category/api/constant"
import { ACTION } from "./constants"
import ContentServiceInstance from "./service"
import { toast } from "react-toastify"

export const fetchFormElement = (params) => {
    return (dispatch) => {
        return ContentServiceInstance.fetchFormElement(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_FORM_ELEMENT,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting form json ", error)
            })
    }
}

export const createContent = (params) => {
    return (dispatch) => {
        return ContentServiceInstance.createContent(params)
            .then((response) => {
                dispatch({
                    type: ACTION.CREATE_CONTENT,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting form json ", error)
            })
    }
}

export const updateContent = (params) => {
    return (dispatch) => {
        return ContentServiceInstance.updateContent(params)
            .then((response) => {
                dispatch({
                    type: ACTION.UPDATE_CONTENT,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting form json ", error)
            })
    }
}

//action to fetch content
export const fetchContent = (params) => {
    return (dispatch) => {
        return ContentServiceInstance.fetchContent(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_CONTENT,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting form json ", error)
            })
    }
}

export const fetchEntityValueMasterData = () => {
    return (dispatch) => {
        return ContentServiceInstance.fetchEntityValueMasterData()
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_ENTITY_VALUE_MASTER_DATA,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting form json ", error)
            })
    }
}

export const fetchContentListing = (params) => {
    return (dispatch) => {
        return ContentServiceInstance.fetchContentListing(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_CONTENT_LISTING,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting form json ", error)
            })
    }
}

export const uploadImage = (params) => {
    return (dispatch) => {
        return ContentServiceInstance.uploadImage(params)
            .then((response) => {
                dispatch({
                    type: ACTION.UPLOAD_IMAGE,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in upload image ", error)
            })
    }
}

export const deleteImage = (id) => {
    return (dispatch) => {
        return ContentServiceInstance.deleteImage(id)
            .then((response) => {
                dispatch({
                    type: ACTION.DELETE_IMAGE,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in upload image ", error)
            })
    }
}

export const deleteContent = (ids) => {
    return (dispatch) => {
        return ContentServiceInstance.deleteContent(ids)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in delete content", error)
            })
    }
}

export const fetchTaggedContentInRail = (ids) => {
    return (dispatch) => {
        return ContentServiceInstance.fetchTaggedContentInRail(ids)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in delete content", error)
            })
    }
}

export const togglePublish = (ids) => {
    return (dispatch) => {
        return ContentServiceInstance.togglePublish(ids)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in delete content", error)
            })
    }
}
export const saveContentNav = (data, id) => {
    return (dispatch) => {
        return ContentServiceInstance.saveContentNav(data, id)
            .then((response) => {
                if (response && response.status) {
                    toast.success(response.data.message)
                    return response.data
                } else {
                    toast.error(response.data.message)
                }
            })
            .catch((error) => {
                toast.error(error.message)
            })
    }
}
export const fetchContentList = (data, id) => {
    return (dispatch) => {
        return ContentServiceInstance.fetchContentList(data, id)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in delete content", error)
            })
    }
}
export const deleteContentList = (data) => {
    return (dispatch) => {
        return ContentServiceInstance.deleteContent(data)
            .then((response) => {
                if (response && response.status) {
                    toast.success(response.data.message)
                    return response.data
                } else {
                    toast.error(response.data.message)
                }
            })
            .catch((error) => {
                console.log("Got error in delete content", error)
            })
    }
}
export const getRightsManagement = (params) => {
    return (dispatch) => {
        return ContentServiceInstance.getRightsManagement(params)
            .then((response) => {
                dispatch({
                    type: ACTION.RIGHTS,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Rights management error", error)
                return error
                // return error;
            })
    }
}

export const restartStreaming = () => {
    return (dispatch) => {
        return ContentServiceInstance.restartStream()
            .then((response) => {
                dispatch({
                    type: ACTION.RESTART_STREAM,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in restart streaming ", error)
            })
    }
}
export const saveReels = (data, id) => {
    return (dispatch) => {
        return ContentServiceInstance.saveReels(data, id)
            .then((response) => {
                dispatch({
                    type: ACTION.SAVE_REELS,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in restart streaming ", error)
                return error
            })
    }
}

export const searchEventReels = (data) => {
    return () => {
        return ContentServiceInstance.searchReels(data)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in delete content", error)
            })
    }
}
export const getEnrichmentData = (data, id, contentType) => {
    return (dispatch) => {
        return ContentServiceInstance.getEnrichmentData(data, id, contentType)
            .then((response) => {
                if (response && response.status) {
                    toast.success("Successfully updated")
                    return response?.data
                } else {
                    toast.error(response?.data)
                }
                return response?.data
            })
            .catch((error) => {
                toast.error(error?.message)
                console.log("Got error in enrichment data ", error)
            })
    }
}
export const getContentEnrichment = (data) => {
    return (dispatch) => {
        return ContentServiceInstance.getContentEnrichment(data)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in restart streaming ", error)
            })
    }
}
export const fetchAllVideoMarkerData = (id, contentType) => {
    return (dispatch) => {
        return ContentServiceInstance.fetchAllVideoMarkerData(id, contentType)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in restart streaming ", error)
            })
    }
}

export const fetchGenre = () => {
    return (dispatch) => {
        return ContentServiceInstance.fetchGenre()
            .then((response) => {
                window.genres = response?.data?.data
                dispatch({
                    type: ACTION.FETCH_GENRE,
                    apiResponse: response?.data?.data
                })
                return response?.data?.data
            })
            .catch((error) => {
                console.log("Got error in restart streaming ", error)
                return error
            })
    }
}

export const fetchCertification = () => {
    console.log("fetch certification")
    return (dispatch) => {
        return ContentServiceInstance.fetchCertification()
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_CERTIFICATION,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Fetch certification error", error)
                return error
                // return error;
            })
    }
}

export const getCredits = () => {
    return () => {
        return ContentServiceInstance.getCredits()
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting credits ", error)
                return error
            })
    }
}

export const getCategory = () => {
    return () => {
        return ContentServiceInstance.getCategory()
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting category ", error)
                return error
            })
    }
}


export const saveV3NewContentNav = (data, url) => {
    return (dispatch) => {
        return ContentServiceInstance.saveV3NewContentNav(data, url)
            .then((response) => {
                return response?.data
            })
            .catch((error) => {
                console.log("Got error in getting saveV3NewContentNav ", error)
                return error
            })
    }
}

export const editV3NewContentNav = (data, url) => {
    return (dispatch) => {
        return ContentServiceInstance.editV3NewContentNav(data, url)
            .then((response) => {
                return response?.data
            
            })
            .catch((error) => {
                console.log("Got error in getting saveV3NewContentNav ", error)
                return error
            })
    }
}

export const deleteV3Content = (url) => {
    return (dispatch) => {
        return ContentServiceInstance.deleteV3Content(url)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in delete content", error)
            })
    }
}

export const toggleV3Publish = (ids) => {
    return (dispatch) => {
        return ContentServiceInstance.toggleV3Publish(ids)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in delete content", error)
            })
    }
}