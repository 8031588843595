import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import Chart from "react-apexcharts"

import "./styles.scss"

/* admin work */

class PieChart extends Component {
    constructor(props) {
        super(props)
        this.state = {
            series: props?.plotValues,

            options: {
                chart: {
                    width: 380,
                    type: "pie",
                    zoom: {
                        enabled: true,
                        type: "x",
                        autoScaleYaxis: true,
                        zoomedArea: {
                            fill: {
                                color: "#90CAF9",
                                opacity: 0.4
                            },
                            stroke: {
                                color: "#0D47A1",
                                opacity: 0.4,
                                width: 1
                            }
                        }
                    }
                },
                tooltip: {
                    enabled: true,
                    enabledOnSeries: undefined,
                    shared: true,
                    style: {
                        fontSize: "12px",
                        fontColor: "#000000",
                        backgroundColor: "black"
                    },
                    fillSeriesColor: true,
                    onDatasetHover: {
                        highlightDataSeries: true
                    }
                },
                labels: props?.xAxisData,
                dataLabels: {
                    enabled: false
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 300
                            },
                            legend: {
                                position: "bottom"
                            }
                        }
                    }
                ]
            }
        }
    }

    // componentWillReceiveProps(nextProps) {
    //   if (this.props !== nextProps) {
    //     let { series, options } = this.state;
    //     series = nextProps?.plotValues;
    //     options.labels = nextProps?.xAxisData;
    //     this.setState({
    //       series,
    //       options,
    //     });
    //   }
    // }

    componentDidMount() {}

    render() {
        return (
            <Chart options={this.state.options} series={this.state.series} type="pie" width={200} />
        )
    }
}
const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({}, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PieChart)
