import React from "react"
import "./styles.scss"

export const ProgressBar = (props) => {
    return (
        <div className="demo-progress">
            <div
                className={`progress ${
                    props?.completed || props?.failed ? "" : "progress-striped"
                } active`}
            >
                <div
                    // role="progressbar progress-striped"
                    style={{ width: props.progress ? props.progress + "%" : "0%" }}
                    className={`progress-bar ${
                        props.failed ? "progress-bar-danger" : "progress-bar-success"
                    }`}
                    id={props.index}
                ></div>
            </div>
            {/*   <div class="progress progress-striped active">
          <div
            role="progressbar "
            style="width: 90%;"
            class="progress-bar progress-bar-secondary"
          >
            <span>Secondary</span>
          </div>
        </div>
        <div class="progress progress-striped active">
          <div
            role="progressbar "
            style="width: 80%;"
            class="progress-bar progress-bar-default"
          >
            <span>Default</span>
          </div>
        </div>
        <div class="progress progress-striped active">
          <div
            role="progressbar "
            style="width: 70%;"
            class="progress-bar progress-bar-success"
          >
            <span>Success</span>
          </div>
        </div>
        <div class="progress progress-striped active">
          <div
            role="progressbar "
            style="width: 60%;"
            class="progress-bar progress-bar-info"
          >
            <span>Info</span>
          </div>
        </div>
        <div class="progress progress-striped active">
          <div
            role="progressbar "
            style="width: 50%;"
            class="progress-bar progress-bar-warni ng"
          >
            <span>Warning</span>
          </div>
        </div>
        <div class="progress progress-striped active">
          <div
            role="progressbar"
            style="width: 40%;"
            class="progress-bar progress-bar-danger"
          >
            <span>Danger</span>
          </div>
        </div> */}
        </div>
    )
}
