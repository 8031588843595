import { ACTION } from "./constants"
import { EnvironmentService } from "./service"

export const getAllEnv = (platformId) => {
    return (dispatch) => {
        return EnvironmentService.getAllEnv(platformId)
            .then((response) => {
                dispatch({
                    type: ACTION.GET_ALL_ENV,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting user list ", error)
            })
    }
}

export const addNewEnv = (params) => {
    return (dispatch) => {
        return EnvironmentService.addNewEnv(params)
            .then((response) => {
                dispatch({
                    type: ACTION.ADD_NEW_ENV,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting user list ", error)
            })
    }
}

export const deleteEnv = (id) => {
    return (dispatch) => {
        return EnvironmentService.deleteEnv(id)
            .then((response) => {
                dispatch({
                    type: ACTION.DELETE_ENV,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting user list ", error)
            })
    }
}
