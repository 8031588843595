import { SPORTS_ACTION as ACTION, PaginationOptions, SPORTS_SETUP_FILTER_KEYS } from "./constant"

let initialState = {
    sportsCategory: null,
    sportsSetup: {
        selectedTab: null,
        sportsData: [],
        filterData: {
            [SPORTS_SETUP_FILTER_KEYS.SPORT_SEARCH]: "",
            [SPORTS_SETUP_FILTER_KEYS.SPORTS_CATEGORY]: "",
            [SPORTS_SETUP_FILTER_KEYS.PAGE_STATUS]: "",
            [SPORTS_SETUP_FILTER_KEYS.LEAGUE]: ""
        },
        paginationData: {
            length: 10,
            start: 0,
            pageCount: 1,
            currentPage: 0,
            selectedOption: PaginationOptions[0]
        },
        sportsDropdownFilterList: null
    }
}

const getDefaultState = () => {
    return initialState
}

export default function SportsReducer(state = getDefaultState(), action) {
    switch (action.type) {
        case ACTION.SET_SPORTS_CATEGORY:
            return {
                ...state,
                sportsCategory: action.payload
            }

        case ACTION.SPORTS_SETUP_SELECTED_TAB:
            return {
                ...state,
                sportsSetup: {
                    ...state.sportsSetup,
                    filterData: {
                        ...initialState.sportsSetup?.filterData
                    },
                    paginationData: {
                        ...initialState.sportsSetup?.paginationData
                    },
                    sportsData: [],
                    selectedTab: action.payload
                }
            }

        case ACTION.SPORTS_SETUP_SET_PAGINATION_DATA:
            return {
                ...state,
                sportsSetup: {
                    ...state.sportsSetup,
                    paginationData: action.payload
                }
            }

        case ACTION.SPORTS_SETUP_SET_FILTER_DATA:
            return {
                ...state,
                sportsSetup: {
                    ...state.sportsSetup,
                    filterData: action.payload
                }
            }

        case ACTION.SPORTS_SETUP_CATEGORY_ID: {
            return {
                ...state,
                sportsSetup: {
                    ...state.sportsSetup,
                    sportsCategoryId: action.payload
                }
            }
        }

        case ACTION.FETCH_SELECTED_SPORTS_LIST: {
            return {
                ...state,
                sportsSetup: {
                    ...state.sportsSetup,
                    sportsData: action.payload
                }
            }
        }

        case ACTION.FETCH_SELECTED_SPORTS_FILTER: {
            return {
                ...state,
                sportsSetup: {
                    ...state.sportsSetup,
                    sportsDropdownFilterList: action.payload
                }
            }
        }

        default:
            return state
    }
}
