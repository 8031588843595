import React from "react"
import PropTypes from "prop-types"
import Button from "../Button"

import "./customModalFooter.scss"
import { BUTTON_CONSTANTS } from "../../../utils/constants"

export default function CustomModalFooter({
    cancelBtnHandler,
    cancelButtonLabel,
    submitBtnHandler,
    showCancelButton = false,
    showSubmitButton = true,
    isSubmitDisabled,
    submitBtnClass = "",
    cancelBtnClass = "",
    submitButtonLabel = ""
}) {
    return (
        <div className="custom-modal-footer">
            {showSubmitButton && (
                <Button
                    cName={`cm-btn cm-submit-btn ${submitBtnClass}`}
                    bType={"submit"}
                    bValue={submitButtonLabel || BUTTON_CONSTANTS?.SUBMIT}
                    disabled={isSubmitDisabled}
                    clickHandler={submitBtnHandler}
                ></Button>
            )}

            {showCancelButton && (
                <Button
                    cName={`cm-btn cm-cancel-btn ${cancelBtnClass}`}
                    bType={"button"}
                    bValue={cancelButtonLabel || BUTTON_CONSTANTS?.CANCEL}
                    clickHandler={cancelBtnHandler}
                ></Button>
            )}
        </div>
    )
}

CustomModalFooter.propTypes = {
    showCancelButton: PropTypes.bool,
    submitButtonLabel: PropTypes.string,
    cancelButtonLabel: PropTypes.string,
    submitBtnHandler: PropTypes.func,
    cancelBtnHandler: PropTypes.func
}
