import { combineReducers } from "redux"

import ThemeReducer from "../components/common/ThemeMode/APIs/reducer"
import userManagementReducer from "../components/UserManagement/APIs/reducer"
import loginReducer from "../components/LoginPanel/APIs/reducer"
import ContentReducer from "../components/Movies/api/reducer"
import UserProfileReducer from "../components/UserPanel/api/reducer"
import CustomPageReducer from "../components/CustomPages/APIs/reducer"
import productPlacementReducer from "../components/Monetize/APIs/reducer"
import HomeReducer from "../containers/Home/APIs/reducer"
import AdminHomeReducer from "../containers/AdminHome/APIs/reducer"
import AdminPageReducer from "../components/AdminComponent/Tenant/APIs/reducer"
import contentNavReducer from "../components/ContentNav/api/reducer"
import LoaderReducer from "../components/common/Loader/api/reducer"
import formBuilderReducer from "../components/formBuilder/api/reducer"
import categoryRail from "../components/Category/api/reducer"
import TimezoneReducer from "../components/Timezone/API/reducer"
import SportsReducer from "../components/Sports/APIs/reducer"

const reducer = combineReducers({
    theme: ThemeReducer,
    userManagement: userManagementReducer,
    auth: loginReducer,
    content: ContentReducer,
    userProfile: UserProfileReducer,
    customPage: CustomPageReducer,
    productPlacement: productPlacementReducer,
    HomeReducer: HomeReducer,
    AdminPageReducer: AdminPageReducer,
    AdminHomeReducer: AdminHomeReducer,
    contentNavReducer: contentNavReducer,
    loader: LoaderReducer,
    formBuilderReducer: formBuilderReducer,
    categoryRail: categoryRail,
    timezone: TimezoneReducer,
    sportsReducer: SportsReducer
})

export default reducer
