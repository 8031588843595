import React, { useRef, useState } from "react"
import "./style.scss"
import { useEffect } from "react"

function AccordionItem({
    title,
    content,
    showSideArrows,
    isAccordionOpen = false,
    accordionExpandHandler,
    reCenterAccordionPosition = false
}) {
    const [isOpen, setIsOpen] = useState(false)
    const accordionHeaderRef = useRef(null)
    const accordionContentRef = useRef(null)

    const toggleAccordion = () => {
        setIsOpen(!isOpen)
        accordionExpandHandler && accordionExpandHandler(!isOpen)
    }

    useEffect(() => {
        setIsOpen(isAccordionOpen)
    }, [isAccordionOpen])

    useEffect(() => {
        // Function to handle opening and positioning of the div
        const openDiv = () => {
            // Get button position relative to the viewport
            accordionContentRef.current.style.display = "block"
            const buttonRect =
                accordionHeaderRef.current.getBoundingClientRect()
            const buttonTop = buttonRect.top

            // Get the window height
            const windowHeight = window.innerHeight

            // Check if the div goes out of the window
            if (
                buttonTop + accordionContentRef.current.offsetHeight >
                windowHeight
            ) {
                // Update the div position
                accordionContentRef.current.style.bottom = `${
                    buttonRect.height + 10
                }px` // Adjust 10 for a gap between the button and the div
            }
        }
        reCenterAccordionPosition && isOpen && openDiv()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    // Intially hide accordion content in case of dynamically calculating the position other wise it will be displayed in UI
    const getStyles = () => {
        return reCenterAccordionPosition ? { display: "none" } : {}
    }

    return (
        <div className="accordion-item-container">
            <div
                className={`accordion-header-container ${
                    isOpen ? "open-border-color" : ""
                }`}
                onClick={toggleAccordion}
                ref={accordionHeaderRef}>
                {title}
                {showSideArrows &&
                    (isOpen ? (
                        <i className="icon-arrow-up" />
                    ) : (
                        <i className="icon-arrow-down1" />
                    ))}
            </div>
            {isOpen && (
                <>
                    <p className="horizontal-line" />
                    <div
                        className="accordion-content-container"
                        ref={accordionContentRef}
                        style={getStyles()}>
                        {content && content()}
                    </div>
                </>
            )}
        </div>
    )
}

export default function Accordion({
    items,
    accordionContainerClass,
    showSideArrows = true,
    isAccordionOpen,
    accordionExpandHandler,
    reCenterAccordionPosition
}) {
    return (
        <div className={`accordion-web-container ${accordionContainerClass}`}>
            {items.map((item, index) => (
                <AccordionItem
                    key={index}
                    title={item.title}
                    content={item.content}
                    showSideArrows={showSideArrows}
                    isAccordionOpen={isAccordionOpen}
                    accordionExpandHandler={accordionExpandHandler}
                    reCenterAccordionPosition={reCenterAccordionPosition}
                />
            ))}
        </div>
    )
}
