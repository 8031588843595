import { ACTION } from "./constants"

let initialState = {}

export default function contentNavReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION.FETCH_GENRE:
            return { ...state, genres: action.apiResponse }
        default:
            return state
    }
}
