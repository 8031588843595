import React from "react"
import Select from "react-select"
import "./style.scss"

// const ValueContainer = (props) => {
//   const selected = props.getValue();
//   const content = ((length) => {
//     switch (length) {
//       case 0:
//         return props.children;
//       case 1:
//         return selected[0].name;
//       default:
//         return `${selected.length} Selected`;
//     }
//   })(selected.length);

//   return (
//     <components.ValueContainer {...props}>{content}</components.ValueContainer>
//   );
// };

// const Option = (props) => {
//   return (
//     <components.Option {...props}>
//       <span className="checkboxContainer">
//         <span
//           className={
//             props.isSelected ? "checkboxStyleActive" : "checkboxStyleDisable"
//           }
//           id={`${props.isSelected ? "tue" : "fal"}`}
//         />{" "}
//       </span>
//       <label>{props.label}</label>
//     </components.Option>
//   );
// };

export default class CustomSelect extends React.Component {
    handleChange = (selectedOptions, action) => {
        const { changeHandler, id } = this.props
        if (selectedOptions) {
            changeHandler(id, selectedOptions)
        } else {
            changeHandler(id, [])
        }
    }

    render() {
        const {
            options,
            label,
            required,
            id,
            placeholder,
            selectedOptions,
            selectedValues,
            errMsg
        } = this.props
        const selectStyles = {
            menu: (styles) => ({ ...styles, zIndex: 9999, overflow: "scroll" })
        }
        /*   const options1 = [
      { value: "chocolate", label: "Chocolate" },
      { value: "strawberry", label: "Strawberry" },
      { value: "vanilla", label: "Vanilla" },
    ]; */
        return (
            <div className={`multiSelect-language-container ${errMsg ? `inputErr` : ""}`}>
                {label && (
                    <label htmlFor={id}>
                        {label}
                        <span className={required ? "required" : "notRequired"}>*</span>
                    </label>
                )}
                <Select
                    options={options}
                    placeholder={placeholder}
                    defaultValue={selectedOptions}
                    value={selectedValues}
                    styles={selectStyles}
                    onChange={this.handleChange}
                    // components={
                    //   isOptionCheckbox
                    //     ? {
                    //         ValueContainer: ValueContainer,
                    //         Option: isOptionCheckbox ? Option : null,
                    //         // DropdownIndicator: () => null,
                    //         // IndicatorSeparator: () => null,
                    //       }
                    //     : {
                    //         ValueContainer: ValueContainer,
                    //       }
                    // }
                    id={id}
                    // menuIsOpen={true}
                    openMenuOnClick={true}
                    hideSelectedOptions={false}
                    isSearchable={false}
                    className={"multiSelect-container"}
                    classNamePrefix={"multiSelect"}
                    getOptionLabel={(options) => options.name}
                    getOptionValue={(options) => options.id}
                />

                {errMsg && <span className="err">{errMsg}</span>}
            </div>
        )
    }
}
