import ApiService from "../../../utils/apiService"
import { API_TENANT_ROOT_PATH } from "../../../config/apiPath"

class StatsService {
    fetchAdminDashboard(id) {
        const options = {
            method: "GET",
            url: `${API_TENANT_ROOT_PATH}/multitenancy/v1/dashboard`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
}

const StatsServiceInstance = new StatsService()
export default StatsServiceInstance
