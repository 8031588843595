import { connect } from "react-redux"
import { compose } from "redux"
import { withRouter } from "react-router"
import { EditorState } from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import React, { Component } from "react"
import draftToHtml from "draftjs-to-html"
import { convertFromHTML, ContentState } from "draft-js"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import "./style.scss"

class EditorComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            editorState: EditorState.createEmpty(),
            content: "",
            convertHtmlToBodyContent: this.convertHtmlToBodyContent.bind(this)
        }
    }

    static getDerivedStateFromProps(nextProps, state) {
        if (nextProps.content && state.content !== nextProps.content) {
            state.convertHtmlToBodyContent(nextProps.content)
        }
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState
        })
    }
    onContentStateChange = (contentState) => {}

    onFocus = (event) => {}

    onBlur = () => {
        const { editorContent } = this.state
        const { getContent } = this.props
        editorContent.blocks.forEach((element) => {
            if (element.type === "code-block") {
                element.type = "code"
            }
        })
        var value = draftToHtml(editorContent)
        var formattedText = draftToHtml(editorContent)
        value = value.split("<pre>").join("")
        value = value.split("</pre>").join("")
        getContent(value, formattedText)
    }

    onChange = (editorContent) => {
        this.setState({ editorContent })
    }

    convertHtmlToBodyContent = (bodyContent) => {
        const blocksFromHTML = convertFromHTML(bodyContent)
        const state = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
        )
        this.setState({
            editorState: EditorState.createWithContent(state),
            content: bodyContent
        })
    }
    uploadImageCallBack = () => {}

    disableToolbarOptions = () => {
        let toolbarOptions = {
            options: ["blockType", "inline", "list", "image", "link"],

            inline: {
                options: ["bold", "italic"]
            },
            link: {
                options: ["link"]
            },
            list: {
                inDropdown: false,
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
                options: ["unordered", "ordered"]
            }
        }
        if (this.props?.isDisabled) {
            // Removing options when disabled
            toolbarOptions = {
                options: ["blockType"],

                inline: {
                    options: []
                },
                link: {
                    options: []
                },
                list: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: []
                }
            }
        }
        return toolbarOptions
    }

    render() {
        const { editorState } = this.state
        return (
            <div className="main_editor">
                <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editor-container"
                    onEditorStateChange={this.onEditorStateChange}
                    onContentStateChange={this.onContentStateChange}
                    onChange={this.onChange}
                    onFocus={(event) => this.onFocus()}
                    onBlur={(event, editorState) => this.onBlur(editorState)}
                    toolbar={this.disableToolbarOptions()}
                    readOnly={this.props?.isDisabled}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {}
}

const mapDispatchToProps = (dispatch) => ({})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(EditorComponent)
