import React, { useState } from "react"

import NoImgAvailable from "../../../../assets/images/no_img_available.png"

import "./style.scss"
import { joinUrl } from "../../../../utils/common"

function BannerCard(props) {
    const [highResImageLoaded, setHighResImageLoaded] = useState(false)

    const { banner, title, published, isPortrait } = props
    return (
        <div
            className={`featured-banner ${isPortrait ? "portrait-banner" : ""} ${
                !published ? "unpublished" : ""
            }`}>
            <img
                onLoad={() => setHighResImageLoaded(true)}
                src={banner ? joinUrl(banner) : `${NoImgAvailable}`}
                alt={title}
                onError={(e) => {
                    e.target.onerror = null
                    e.target.src = `${NoImgAvailable}`
                    e.target.className = `no-img-available`
                }}
            />
            <img
                {...(highResImageLoaded && { style: { opacity: "0" } })}
                alt=""
                className="overlayStyles"
                src={banner ? joinUrl(banner) : `${NoImgAvailable}`}
            />
        </div>
    )
}

export default BannerCard
