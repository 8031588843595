import { ACTION } from "./constant"

export const setLoader = () => {
    return {
        type: ACTION.SET_LOADER
    }
}

export const removeLoader = () => {
    return {
        type: ACTION.REMOVE_LOADER
    }
}
