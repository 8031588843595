import React, { useCallback, useMemo, useState } from "react"
import Select from "../../common/SelectBox"
import Checkbox from "../../common/Checkbox"
import ImageUpload from "../../common/imageUpload"
import CustomRadioButton from "../../common/customRadioBtn"
import { TEXT_CONSTANTS } from "../../../utils/constants"
import MultiSelectBox from "../../common/MultiSelect"
import Input from "../../common/Input"
import isEmpty from "lodash/isEmpty"
import { CONTENT_TYPE_CONST, RAIL_CATEGORY, RAIL_TYPE_CONSTANT } from "../api/constant"

const ContentType = (props) => {
    const {
        findDefaultOption,
        contentLevelOption,
        handleSelectChange,
        contentType,
        subType,
        mediaContentSearch,
        setMediaContentSearch,
        contentTypeList,
        subTypeList,
        mediaContentList,
        languageList,
        genreList,
        handleRadioChange,
        contentLevel,
        railType,
        checkboxHandleChange,
        themeRailCheck,
        imageUpload,
        handlePromoImageUpload,
        genre,
        setGenre,
        language,
        setLanguage,
        railCategory,
        favContent,
        handleRemoveImage,
        sportsSubTypeList,
        selectedSportsSubType
    } = props

    const handleGenre = useCallback(
        (value) => {
            const names = value.map((item) => item.name)
            setGenre([...names])
        },
        [genre]
    )

    const handleLanguage = useCallback(
        (value) => {
            const names = value.map((item) => item.name)
            setLanguage(names)
        },
        [language]
    )

    const handleSearchContent = useCallback((name, value) => {
        setMediaContentSearch(value)
    }, [])

    const filterContentList = useMemo(() => {
        return contentTypeList?.map((item) => {
            return {
                ...item,
                name: item.name.replace("_", " ")
            }
        })
    }, [contentTypeList])

    return (
        <div className="railGroup">
            {railType.id === RAIL_TYPE_CONSTANT.RAIL && (
                <Checkbox
                    cName="featured-checkBox"
                    labelText={"Themed Rail"}
                    id={"themeRailCheck"}
                    name={"themeRailCheck"}
                    isChecked={themeRailCheck}
                    changeHandler={checkboxHandleChange}
                />
            )}
            {/*-------------Themed Rail Image Container----------*/}
            {themeRailCheck && (
                <div className="image-container">
                    <ImageUpload
                        key={"banner"}
                        name={"Small Screen"}
                        id={"regularThemeImage"}
                        label={"Add Image for Small Device"}
                        required={true}
                        value={
                            imageUpload?.regularThemeImage
                                ? imageUpload?.regularThemeImage.path
                                : null
                        }
                        cName={"landscape"}
                        uploadImage={handlePromoImageUpload}
                        removeImage={handleRemoveImage}
                    />
                    <ImageUpload
                        key={"banner2"}
                        name={"Large Screen"}
                        id={"largeThemeImage"}
                        label={"Add Image for Large Screen"}
                        required={true}
                        value={
                            imageUpload?.largeThemeImage ? imageUpload?.largeThemeImage.path : null
                        }
                        cName={"landscape"}
                        uploadImage={handlePromoImageUpload}
                        removeImage={handleRemoveImage}
                    />
                </div>
            )}
            {/*---------------------------------------------*/}
            {railType.id !== RAIL_TYPE_CONSTANT.CHANNEL_RAIL && (
                <Select
                    value={
                        railType.id === RAIL_TYPE_CONSTANT.FAVOURITE
                            ? favContent
                            : findDefaultOption(contentType?.id, filterContentList)
                    }
                    options={filterContentList}
                    isOptionDisabled={(option) => option.isdisabled}
                    cName={"content-type"}
                    name={"contentType"}
                    label={"Content Type"}
                    required
                    placeholder={"Select"}
                    isMulti={railType.id === RAIL_TYPE_CONSTANT.FAVOURITE}
                    changeHandler={handleSelectChange}
                    isAutoFocus={false}
                />
            )}

            {contentType?.name === CONTENT_TYPE_CONST.LIVE_TV &&
                railType.id !== RAIL_TYPE_CONSTANT.CHANNEL_RAIL && (
                    <Select
                        value={findDefaultOption(subType, subTypeList)}
                        options={subTypeList}
                        isOptionDisabled={(option) => option.isdisabled}
                        cName={"content-type"}
                        name={"subType"}
                        label={"Sub Type"}
                        required
                        placeholder={"Select"}
                        changeHandler={handleSelectChange}
                    />
                )}

            {contentType?.name === CONTENT_TYPE_CONST.SPORTS && (
                <Select
                    value={findDefaultOption(selectedSportsSubType?.id, sportsSubTypeList)}
                    options={sportsSubTypeList}
                    isOptionDisabled={(option) => option.isdisabled}
                    cName={"content-type"}
                    name={"sports-subType"}
                    label={"Sub Type"}
                    placeholder={"Select"}
                    changeHandler={handleSelectChange}
                />
            )}

            {contentType?.name === CONTENT_TYPE_CONST.WEB_SERIES && (
                <div className="radio-btn-container">
                    <div className="radio-btn">
                        <CustomRadioButton
                            key={"Brand"}
                            id={"protocol"}
                            name={"contentLevel"}
                            handleRadioChange={handleRadioChange}
                            options={[
                                { id: 0, name: "Brand" },
                                {
                                    id: 1,
                                    name: "Season"
                                },
                                {
                                    id: 2,
                                    name: "Episode"
                                }
                            ]}
                            label={"Choose content level"}
                            value={contentLevel}
                        />
                    </div>
                </div>
            )}
            {railCategory === RAIL_CATEGORY.NEW && (
                <div className="search-box-container">
                    <div className="searchBox-header">
                        <label htmlFor="searchBar" className="label">
                            {TEXT_CONSTANTS?.ADD_CONTENT}
                            <span className="required"> *</span>
                        </label>
                    </div>
                    <div className="searchBar">
                        <div className="icon-search"></div>
                        <Input
                            id="searchBar"
                            cName={"movie-search-bar"}
                            placeholder="Search by Name"
                            value={mediaContentSearch}
                            changeHandler={handleSearchContent}
                        />
                    </div>
                    <ul className={`searchList-container`}>
                        {!isEmpty(mediaContentList) ? (
                            mediaContentList.map((item) => {
                                return contentLevelOption(item)
                            })
                        ) : (
                            <p className="no-data-found">
                                {mediaContentSearch
                                    ? "No Data Found"
                                    : "Please select content type"}
                            </p>
                        )}
                    </ul>
                </div>
            )}
        </div>
    )
}

export default ContentType
