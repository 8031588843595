import { ACTION } from "./constants"
import AdminComponentService from "./service"

export const createTenant = (params) => {
    return (dispatch) => {
        return AdminComponentService.createTenant(params)
            .then((response) => {
                dispatch({
                    type: ACTION.CREATE_TENANT,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in creating text page ", error)
                return error
            })
    }
}
export const getTenants = (params) => {
    return (dispatch) => {
        return AdminComponentService.getPaginatedTenants(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_TENANT,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in creating text page ", error)
            })
    }
}

export const getTenantsById = (params) => {
    return (dispatch) => {
        return AdminComponentService.getTenantsById(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_TENANT,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in creating text page ", error)
            })
    }
}

export const isExists = (params) => {
    return (dispatch) => {
        let response = {
            data: true,
            message: "string",
            status: true
        }

        return AdminComponentService.isExists(params)
            .then((response) => {
                dispatch({
                    type: ACTION.IS_EXISTS,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                dispatch({
                    type: ACTION.IS_EXISTS,
                    apiResponse: response.data
                })
                console.log("Got error in creating text page ", error)
                return response
            })
    }
}
export const uploadLogo = (params) => {
    return (dispatch) => {
        return AdminComponentService.uploadLogo(params)
            .then((response) => {
                dispatch({
                    type: ACTION.UPLOAD_LOGO,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in creating text page ", error)
            })
    }
}

export const deactivateTenant = (params) => {
    return (dispatch) => {
        return AdminComponentService.deactivateTenant(params)
            .then((response) => {
                dispatch({
                    type: ACTION.DEACTIVATE_TENANT,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in creating text page ", error)
            })
    }
}

export const isExistSubdomain = (params) => {
    return (dispatch) => {
        return AdminComponentService.isExistSubdomain(params)
            .then((response) => {
                dispatch({
                    type: ACTION.EXIST_DOMAIN,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in creating text page ", error)
            })
    }
}
export const isExistEmailMobile = (params) => {
    return (dispatch) => {
        return AdminComponentService.isExistEmailMobile(params)
            .then((response) => {
                dispatch({
                    type: ACTION.EXIST_EMAIL_MOBILE,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in creating text page ", error)
            })
    }
}
