import React, { useCallback, useEffect, useState } from "react"
import Loader from "../../assets/images/loader.gif"
import Checkbox from "../common/Checkbox"
import Button from "../common/Button"
import CustomModal from "../common/Modal"
import { multilingualLabels } from "./api/constant"
import { setLanguageInLocalStorage } from "../common/LanguageCardList"
import MultiSelect from "../common/CustomMultiselect"
import { toast } from "react-toastify"
import { DEFAULT_SELECTED_LANGUAGE_CODE } from "../ContentNav/api/constants"

import { useDispatch } from "react-redux"
import {
    getLanguageListData,
    setLanguageEnableStatus,
    setLanguagePrimaryStatus
} from "./api/action"

import "./style.scss"
import { ERROR_MESSAGES, PERMISSIONS } from "../../utils/constants"
import { isEqual } from "lodash"
import { useMemo } from "react"
import { checkIfUserHasAccess } from "../../utils/users"

export default function Multilingual() {
    const [showModal, setShowModal] = useState(false)
    const [isConfirmationModalActive, setIsConfirmationModalActive] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [languageListData, setLanguageListData] = useState([])
    const [tempSelectedLangugae, setTempSelectedLangugae] = useState({})
    const [selectedDeSelectedLanguageList, setSelectedDeSelectedLanguageList] = useState({
        selectedLanguageList: [],
        deselectedLanguageList: []
    })
    const dispatch = useDispatch()

    const canUpdateStatus = useMemo(
        () => checkIfUserHasAccess([PERMISSIONS?.SETTING_MULTILINGUAL_EDIT]),
        []
    )

    const fetchLanguageListData = useCallback(async () => {
        setIsLoading(true)
        const res = await dispatch(getLanguageListData())
        setLanguageListData(res?.data)
        setIsLoading(false)
    }, [dispatch])

    useEffect(() => {
        fetchLanguageListData()
    }, [fetchLanguageListData])

    const handleCheckboxChange = (name, val, id) => {
        if (!val) {
            setTempSelectedLangugae({ name, id, value: val })
            setModalState(true)
            setIsConfirmationModalActive(true)
        } else {
            setLanguageState(id, val)
        }
    }

    const newLanguageAddHandler = (name, code) => {
        setTempSelectedLangugae((prevState) => {
            return [...code]
        })
    }

    const saveHandler = async () => {
        const { selectedLanguageList, deselectedLanguageList } = selectedDeSelectedLanguageList
        if (selectedLanguageList?.length > 0 || deselectedLanguageList?.length > 0) {
            let res
            setIsLoading(true)
            if (selectedLanguageList?.length > 0) {
                res = await dispatch(
                    setLanguageEnableStatus({
                        enable: true,
                        ids: selectedLanguageList
                    })
                )
            }
            if (deselectedLanguageList?.length > 0) {
                res = await dispatch(
                    setLanguageEnableStatus({
                        enable: false,
                        ids: deselectedLanguageList
                    })
                )
            }
            setIsLoading(false)
            if (res?.status) {
                setLanguageInLocalStorage(languageListData)
                toast.success("Changes saved successfully!")
            } else {
                toast.error(ERROR_MESSAGES.SOMETHING_WENT_WRONG)
            }
            setSelectedDeSelectedLanguageList({
                selectedLanguageList: [],
                deselectedLanguageList: []
            })
            fetchLanguageListData()
        }
    }

    const addNewLanguageHandler = () => {
        setModalState(true)
        setIsConfirmationModalActive(false)
        setTempSelectedLangugae([])
    }

    const modifyLanguageState = (selectedList, deSelectedList, languageId) => {
        const temp = [...selectedList]
        temp.push(languageId)
        const index = deSelectedList?.indexOf(languageId)
        const deSelectTemp = [...deSelectedList]
        if (index !== -1) {
            deSelectTemp.splice(index, 1)
        }
        return { temp, deSelectTemp }
    }

    const setLanguageState = (languageId, languageValue) => {
        const modLang = languageListData?.map((item) => {
            if (item?.id === languageId) {
                return {
                    ...item,
                    enable: languageValue
                }
            }
            return item
        })
        setLanguageListData(modLang)
        if (languageValue) {
            const { temp, deSelectTemp } = modifyLanguageState(
                selectedDeSelectedLanguageList?.selectedLanguageList,
                selectedDeSelectedLanguageList?.deselectedLanguageList,
                languageId
            )
            setSelectedDeSelectedLanguageList((prevState) => {
                return {
                    ...prevState,
                    selectedLanguageList: temp,
                    deselectedLanguageList: deSelectTemp
                }
            })
        } else {
            const { temp, deSelectTemp } = modifyLanguageState(
                selectedDeSelectedLanguageList?.deselectedLanguageList,
                selectedDeSelectedLanguageList?.selectedLanguageList,
                languageId
            )
            setSelectedDeSelectedLanguageList((prevState) => {
                return {
                    ...prevState,
                    deselectedLanguageList: temp,
                    selectedLanguageList: deSelectTemp
                }
            })
        }
    }

    const modalSubmitHandler = () => {
        if (isConfirmationModalActive) {
            setLanguageState(tempSelectedLangugae?.id, tempSelectedLangugae?.value)
        } else {
            if (tempSelectedLangugae?.length > 0) {
                const modLang = tempSelectedLangugae?.map((item) => {
                    return {
                        id: item?.id,
                        languageName: item?.name,
                        languageCode: item?.value,
                        enable: false,
                        primary: true,
                        webAppLanguageName: item?.webAppLanguageName,
                        positionIndex: item?.positionIndex
                    }
                })
                updatePrimaryLanguage(modLang)
            }
        }

        setModalState(false)
    }

    const updatePrimaryLanguage = async (modLang) => {
        setIsLoading(true)
        await dispatch(setLanguagePrimaryStatus(modLang))
        fetchLanguageListData()
    }

    const setModalState = (val) => {
        setShowModal(val)
    }

    const displayLoader = () => {
        return (
            <div className="loader">
                <img src={Loader} alt="loader" width={"100px"} />
            </div>
        )
    }

    const checkIsButtonDisabled = () => {
        const { selectedLanguageList, deselectedLanguageList } = selectedDeSelectedLanguageList

        return (
            isEqual(selectedLanguageList?.length, 0) && isEqual(deselectedLanguageList?.length, 0)
        )
    }

    return (
        <div className="vr-content-holder vr-multilingual-language-container">
            {isLoading && displayLoader()}
            {displayLanguagesAndHeader({
                languageListData,
                handleCheckboxChange,
                canUpdateStatus
            })}
            {displayNewLanguages({ addNewLanguageHandler, canUpdateStatus })}
            {showModal && (
                <CustomModal
                    showModal={showModal}
                    submitHandler={modalSubmitHandler}
                    cancelHandler={() => {
                        setModalState(false)
                    }}
                    closeOnSubmit={false}
                    submitButtonLabel={
                        isConfirmationModalActive
                            ? multilingualLabels?.confirmTxt
                            : multilingualLabels?.saveTxt
                    }
                    showCancelButton={true}
                    cancelOnOutsideClick={false}
                    showCloseIcon={false}
                    submitBtnClass={isConfirmationModalActive ? "confirmation-submit-btn" : ""}
                    cancelBtnClass={isConfirmationModalActive ? "confirmation-cancel-btn" : ""}
                >
                    {isConfirmationModalActive
                        ? showConfirmationModal()
                        : showAddNewLanguageModal({
                              newLanguageAddHandler,
                              languageListData
                          })}
                </CustomModal>
            )}

            <div className="save-container">
                <Button
                    cName={"btn primary-btn"}
                    bType={"button"}
                    bValue={multilingualLabels?.saveTxt}
                    clickHandler={saveHandler}
                    disabled={checkIsButtonDisabled() || !canUpdateStatus}
                />
            </div>
        </div>
    )
}

const showConfirmationModal = () => {
    return (
        <div className="deselect-confirmation-language-modal">
            <h2>{multilingualLabels?.confirmationTxtHeading}</h2>
            <p>{multilingualLabels?.confirmationTxtDes}</p>
        </div>
    )
}

const showAddNewLanguageModal = ({ newLanguageAddHandler, languageListData }) => {
    return (
        <div className="add-new-language-modal">
            <h2>{multilingualLabels?.newLanguageTxt}</h2>

            <MultiSelect
                options={
                    languageListData &&
                    languageListData
                        ?.filter((item) => !item?.primary)
                        .map((ele) => {
                            return {
                                id: ele?.id,
                                name: ele?.languageName,
                                value: ele?.languageCode,
                                webAppLanguageName: ele?.webAppLanguageName,
                                positionIndex: ele?.positionIndex
                            }
                        })
                }
                placeholder={"-select-"}
                name={"newLanguageDropdown"}
                changeHandler={(name, value) => newLanguageAddHandler(name, value)}
            />
        </div>
    )
}

const displayNewLanguages = ({ addNewLanguageHandler, canUpdateStatus }) => {
    return (
        <div className="new-lang-container">
            <p className="prefered-lang-txt">{multilingualLabels?.preferedLanguageTxt}</p>
            <Button
                cName={"btn border-btn "}
                bType={"button"}
                bValue={multilingualLabels?.addNowBtnTxt}
                clickHandler={addNewLanguageHandler}
                disabled={!canUpdateStatus}
            />
        </div>
    )
}

const displayLanguagesAndHeader = ({ languageListData, handleCheckboxChange, canUpdateStatus }) => {
    return (
        <>
            <h2>{multilingualLabels?.heading}</h2>
            <p className="multilingual-subheading">{multilingualLabels?.subheading}</p>
            <div className="language-list-container">
                {languageListData
                    ?.filter((item) => item?.primary)
                    ?.map((item) => {
                        return (
                            <Checkbox
                                key={item?.id}
                                cName="terms-checkbox"
                                id={item?.id}
                                name={item?.languageName}
                                changeHandler={(name, val, id) =>
                                    handleCheckboxChange(name, val, id)
                                }
                                labelText={item?.languageName}
                                isChecked={item?.enable}
                                disabled={
                                    item?.languageCode === DEFAULT_SELECTED_LANGUAGE_CODE ||
                                    !canUpdateStatus
                                }
                            />
                        )
                    })}
            </div>
        </>
    )
}
