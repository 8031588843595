import React from "react"

import Input from "../../../common/Input/index"
import Select from "../../../common/SelectBox/index"
import Button from "../../../common/Button/index"
import ImageUpload from "../../../MenuDesign/addEditMenu/imageUplode"
import { imgPathPrefix } from "../../api/constant"
import { isMultiLingual } from "../../../../utils/common"
import { isEmpty } from "lodash"
import { BUTTON_CONSTANTS, TEXT_CONSTANTS } from "../../../../utils/constants"

function SubMenuList(props) {
    const {
        delBtn,
        findLinkTo,
        element,
        subMenu,
        cmsOptions,
        editorialOptions,
        provided,
        handleDeleteClickSubMenu,
        idToDeleteSubMenu,
        isDeleteSubMenu,
        handleDeleteSubMenu,
        handleSubMenuChange,
        addNewSubMenu,
        selectedLanguage,
        error,
        imageUpload,
        imageRemove
    } = props
    return (
        <li
            id={element.uniqueId}
            className={`tabList`}
            ref={provided.innerRef}
            {...provided.draggableProps}>
            <div className="drag" {...provided.dragHandleProps}>
                <span className="menu-icon"></span>
                <span className="menu-icon"></span>
                <span className="menu-icon"></span>
            </div>
            <div className="form-group">
                <ImageUpload
                    key={element.id ? element.id : element.uniqueId}
                    id={element.id ? element.id : element.uniqueId}
                    isSubMenu={true}
                    value={element.menuIcon ? `${imgPathPrefix}${element.menuIcon}` : null}
                    uploadImage={imageUpload}
                    removeImg={(name, id, mainMenuId) => imageRemove(name, id, mainMenuId)}
                    errMsg={error && error.menuIcon ? error.menuIcon : null}
                />
            </div>
            <div className="form-group">
                <Input
                    type="text"
                    placeholder="Enter Tab Name"
                    cName={`name`}
                    name="tabName"
                    value={
                        !isMultiLingual()
                            ? element?.tabName
                            : !isEmpty(element?.tabName) && element?.tabName[selectedLanguage?.code]
                            ? element?.tabName[selectedLanguage?.code]
                            : ""
                    }
                    changeHandler={handleSubMenuChange}
                    errMsg={error && error.name ? error.name : null}
                />
            </div>

            <div className="form-group">
                <Select
                    value={element.type ? { id: element.type, name: element.type } : null}
                    placeholder={"Select"}
                    options={subMenu
                        .filter((item) => {
                            if (item !== "Sub-Menu") {
                                return item
                            }
                            return false
                        })
                        .map((item) => ({ id: item, name: item }))}
                    cName={"type"}
                    name={"type"}
                    changeHandler={handleSubMenuChange}
                    errMsg={error && error.type ? error.type : null}
                />
            </div>
            <div className="form-group">
                <Select
                    value={
                        element.linkToPage
                            ? findLinkTo(
                                  element.linkToPage,
                                  element.type === "TAB"
                                      ? editorialOptions
                                      : element.type === "CMS"
                                      ? cmsOptions
                                      : null
                              )
                            : null
                    }
                    placeholder={"Select"}
                    options={
                        element.type === "TAB"
                            ? editorialOptions
                            : element.type === "CMS"
                            ? cmsOptions
                            : null
                    }
                    isDisabled={element.type === "Sub-Menu" ? true : false}
                    cName={"linkToPage"}
                    name={"linkToPage"}
                    changeHandler={handleSubMenuChange}
                    errMsg={error && error.linkToPage ? error.linkToPage : null}
                />
            </div>
            <div className="actions">
                {delBtn ? (
                    <i
                        className="icon-del"
                        onClick={() =>
                            handleDeleteClickSubMenu(element.id ? element.id : element.uniqueId)
                        }></i>
                ) : (
                    <>
                        <i
                            className="icon-del"
                            onClick={() =>
                                handleDeleteClickSubMenu(element.id ? element.id : element.uniqueId)
                            }></i>
                        <i className="icon-plus" onClick={addNewSubMenu}></i>
                    </>
                )}
            </div>
            {(element.id === idToDeleteSubMenu || element.uniqueId === idToDeleteSubMenu) && (
                <div
                    className={`deletePopup ${
                        isDeleteSubMenu ? "showPopUp slide-right" : "hidePopUp slide-left"
                    }`}>
                    <p>
                        {TEXT_CONSTANTS?.DO_YOU_WANT_TO_REMOVE} {element.name} {TEXT_CONSTANTS?.TAB}
                        ?
                    </p>
                    <Button
                        cName="btn popup-del-btn"
                        bValue={BUTTON_CONSTANTS?.YES_DELETE}
                        bType="button"
                        clickHandler={() => handleDeleteSubMenu(element)}
                    />
                    <Button
                        cName="btn cancel-btn"
                        bValue={BUTTON_CONSTANTS?.CANCEL}
                        bType="button"
                        clickHandler={() =>
                            handleDeleteClickSubMenu(element.id ? element.id : element.uniqueId)
                        }
                    />
                </div>
            )}
        </li>
    )
}

export default SubMenuList
