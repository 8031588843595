import React, { useEffect, useMemo, useState } from "react"

import "./style.scss"
import { useDispatch, useSelector } from "react-redux"
import Loader from "../common/Loader"
import { PERMISSIONS, TEXT_CONSTANTS } from "../../utils/constants"
import { SearchableList } from "../common/SearchableList"
import { getAllTimezone, saveTimezone } from "./API/action"
import { toast } from "react-toastify"
import { checkIfUserHasAccess } from "../../utils/users"

export default function Timezone() {
    const [timezoneDataList, setTimezoneDataList] = useState([])
    const isLoading = useSelector((state) => state.loader.isLoading)
    const selectedTimezoneLabel = useSelector((state) => state?.timezone?.selectedTimezone)
    const dispatch = useDispatch()

    const canUpdateStatus = useMemo(() => {
        return checkIfUserHasAccess([PERMISSIONS?.TIMEZONE_EDIT])
    }, [])

    useEffect(() => {
        getTimezoneData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getTimezoneData = async () => {
        const response = await dispatch(getAllTimezone())
        let timezoneData = []
        if (response?.status) {
            timezoneData = response?.data?.map((item) => {
                return {
                    id: item,
                    name: item,
                    label: item
                }
            })
        }

        setTimezoneDataList(timezoneData)
    }

    const saveSelectedTimezone = async (selectedItem) => {
        if (selectedItem?.id !== selectedTimezoneLabel?.timezone) {
            const timezoneString = selectedItem?.id
            const response = await dispatch(saveTimezone(timezoneString))
            if (response?.status) {
                toast.success(response?.message)
            } else {
                toast.error(response?.message)
            }
        }
    }

    return (
        <div className="timezone-component-wrapper">
            {isLoading && <Loader></Loader>}
            {displayComponentHeader()}
            <div className="vr-content-holder timezone-container">
                {displaySelectedTimezone(selectedTimezoneLabel?.timeZone)}
                <div className="timezone-list">
                    <SearchableList
                        searchableListHeading={TEXT_CONSTANTS?.TIMEZONE_LIST}
                        searchableListItems={timezoneDataList}
                        selectedItemChangeHandler={saveSelectedTimezone}
                        defaultSelectedItem={selectedTimezoneLabel?.timeZone}
                        isSearchableListDisabled={!canUpdateStatus}
                    ></SearchableList>
                </div>
            </div>
        </div>
    )
}

const displayComponentHeader = () => {
    return (
        <div className="vr-cont-head mb">
            {/* TODO: Replace below with the reusable pageTitleComponent */}
            <div className="vr-cont-title">
                <span className="vr-breadcrum">
                    {TEXT_CONSTANTS?.CONFIGURATION}
                    <i className="icon-arrow-right1" />
                    <span>{TEXT_CONSTANTS?.TIMEZONE_LABEL}</span>
                </span>
                <h2 className="breadcrum-header">
                    {TEXT_CONSTANTS?.TIMEZONE_LABEL?.toUpperCase()}
                </h2>
            </div>
        </div>
    )
}

const displaySelectedTimezone = (selectedTimezone) => {
    return (
        <div>
            <h2>
                {TEXT_CONSTANTS?.SELECTED_TIMEZONE_LABEL} : {selectedTimezone}
            </h2>
        </div>
    )
}
