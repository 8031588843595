import { isEmpty } from "lodash"
import React, { Component } from "react"

import "./style.scss"
import { TEXT_CONSTANTS } from "../../../../../utils/constants"

class FingerPrinting extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fingerPrintingData: {}
        }
    }

    // modd_tag
    componentDidMount() {
        const { contentMarkers } = this.props
        //toenable for api response
        let response = {
            mood: [],
            genre: contentMarkers?.genre,
            keyword: contentMarkers?.keyword,
            mood_tag: contentMarkers?.mood_tag
        }

        this.setState({
            fingerPrintingData: response
        })
    }

    renderFingerPrintingInfo = (fingerPrintingData) => {
        const data = fingerPrintingData
        let ar = []
        for (let key in data) {
            if (data[key]?.length > 0) {
                ar.push(
                    <div key={data[key]} className="fp-property">
                        <p className="fp-property-key">{key?.replace("_", " ")}</p>
                        <span className="fp-property-colon">:</span>
                        <div className="fp-values">
                            {data[key]?.map((item) => (
                                <p className="fp-values-item">{item}</p>
                            ))}
                        </div>
                    </div>
                )
            }
        }
        return isEmpty(ar) ? <h2 className="no-data-available">{TEXT_CONSTANTS?.NO_DATA}!</h2> : ar
    }

    render() {
        const { fingerPrintingData } = this.state

        return (
            <div className="finger-print-container">
                {this.renderFingerPrintingInfo(fingerPrintingData)}
            </div>
        )
    }
}

export default FingerPrinting
