import moment from "moment"
import ScheduleRail from "./ScheduleRail"
import React from "react"

const SchedulePublishRail = (props) => {
    const { closeScheduleModal, start, end, handleSchedulePublish } = props
    let startTime = "",
        endTime = ""
    if (start && end) {
        startTime = moment(start).utc()
        endTime = moment(end).utc()
    }
    return (
        <div className="railSchedulePreview">
            <div className="empty-wrapper" onClick={closeScheduleModal} />
            <ScheduleRail
                handleOnClose={closeScheduleModal}
                handleSchedulePublish={handleSchedulePublish}
                startDate={startTime ? new Date(startTime?.format("YYYY/MM/DD")) : ""}
                endDate={endTime ? new Date(endTime?.format("YYYY/MM/DD")) : ""}
                inputStartDate={startTime ? startTime?.format("DD/MM/YYYY") : ""}
                inputEndDate={endTime ? endTime?.format("DD/MM/YYYY") : ""}
                inputStartTime={startTime ? startTime?.format("HH:mm") : ""}
                inputEndTime={endTime ? endTime?.format("HH:mm") : ""}
            />
        </div>
    )
}

export default SchedulePublishRail
