import { isEmpty } from "lodash"
import {
    FORM_FIELDS_DATA,
    FORM_FIELDS_NAME,
    SELECT_ALL_OPTION,
    SETUP_SPROTS_CONSTANTS,
    SPORTS_SETUP_FILTER_KEYS,
    SPORTS_TAB,
    SPORTS_TABLE_HEADING
} from "./constant"
import { ERROR_MESSAGES } from "../../../utils/constants"

export const getDataBasedOnSelectedTab = ({
    selectedTab,
    sportCategoryData,
    playersData,
    leaguesData,
    matchData // Returing matchData as defualt
}) => {
    switch (selectedTab) {
        case SPORTS_TAB?.SPORTS_CATEGORY:
            return sportCategoryData
        case SPORTS_TAB?.PLAYERS:
            return playersData
        case SPORTS_TAB?.LEAGUES:
            return leaguesData
        default:
            return matchData
    }
}

export const getFormData = (selectedTab) => {
    const removeNonRequiredFields = (fields) => {
        return FORM_FIELDS_DATA.filter((item) => !fields.includes(item))
    }

    return getDataBasedOnSelectedTab({
        selectedTab,
        sportCategoryData: {
            requriedFields: removeNonRequiredFields([
                FORM_FIELDS_NAME.bannerImage,
                FORM_FIELDS_NAME.league,
                FORM_FIELDS_NAME.category
            ]),
            formTitle: SETUP_SPROTS_CONSTANTS?.sportCategoryFormHeader,
            inputFieldTitle: SETUP_SPROTS_CONSTANTS?.sportCategoryNameLabel,
            placeholderTitle: SETUP_SPROTS_CONSTANTS?.sportCategoryPlaceholder
        },
        playersData: {
            requriedFields: removeNonRequiredFields([FORM_FIELDS_NAME.league]),
            formTitle: SETUP_SPROTS_CONSTANTS?.createNewPlayer,
            inputFieldTitle: SETUP_SPROTS_CONSTANTS?.playerNameLabel,
            placeholderTitle: SETUP_SPROTS_CONSTANTS?.playerNamePlaceholder,
        },
        leaguesData: {
            requriedFields: removeNonRequiredFields([FORM_FIELDS_NAME.league]),
            formTitle: SETUP_SPROTS_CONSTANTS?.createNewLeague,
            inputFieldTitle: SETUP_SPROTS_CONSTANTS?.leagueNameLabel,
            placeholderTitle: SETUP_SPROTS_CONSTANTS?.leagueNamePlaceholder,
        },
        matchData: {
            requriedFields: FORM_FIELDS_DATA,
            formTitle: SETUP_SPROTS_CONSTANTS?.createNewMatch,
            inputFieldTitle: SETUP_SPROTS_CONSTANTS?.teamNameLabel,
            placeholderTitle: SETUP_SPROTS_CONSTANTS?.teamNamePlaceholder,
        }
    })
}

const getValue = (data = []) => {
    return data?.map((item) => {
        return item?.value
    })
}

const setFilterValue = (payload, value, identifier) => {
    if (value?.toLowerCase() !== SELECT_ALL_OPTION?.id) {
        payload.restFilters += `${identifier}${value}`
    }
}

export const createFilterDataListPayload = (paginationData, selectedTab, updatedFilterData) => {
    let payload = {
        length: paginationData?.length,
        start: paginationData?.start,
        pageEntityId: selectedTab?.pageEntityId,
        restFilters: ""
    }
    // TODO: Update param string based on api
    for (let key in updatedFilterData) {
        let value = updatedFilterData[key]
        if (!isEmpty(value)) {
            if (Array.isArray(value)) {
                value = getValue(value)?.join(",")
            } else if (typeof value === "object") {
                value = value?.id
            }
            switch (key) {
                case SPORTS_SETUP_FILTER_KEYS.SPORT_SEARCH: {
                    payload.restFilters += `&searchTag=${value}`
                    break
                }
                case SPORTS_SETUP_FILTER_KEYS.SPORTS_CATEGORY: {
                    setFilterValue(payload, value, `&parent.0=`)
                    break
                }
                case SPORTS_SETUP_FILTER_KEYS.PAGE_STATUS: {
                    setFilterValue(payload, value, `&published=`)
                    break
                }
                case SPORTS_SETUP_FILTER_KEYS.LEAGUE: {
                    setFilterValue(payload, value, `&parent.1=`)
                    break
                }
                default:
                    break
            }
        }
    }
    return payload
}

export const findTabBasedOnId = (id, sportsCategory) => {
    for (let key in sportsCategory) {
        let categoryId = sportsCategory?.[key]?.pageEntityId
        if (categoryId === id) {
            return { name: key }
        }
    }
}

export const createDropdownData = (dataList) => {
    if (dataList) {
        let dropdownList = dataList?.map((item) => {
            return {
                id: item?.id,
                name: item?.name,
                value: item?.id
            }
        })
        return [SELECT_ALL_OPTION, ...dropdownList]
    }
    return []
}

export const categoryLeagueDropdownData = (sportsDropdownFilterList, sportsCategory) => {
    let dropdownData = {
        sportsCategoryList: [],
        leaguesList: []
    }
    if (sportsCategory && sportsDropdownFilterList) {
        for (let key in sportsDropdownFilterList) {
            let obj = findTabBasedOnId(key, sportsCategory)
            obj = obj?.name?.replace(" ", "_")?.toLowerCase()
            if (obj === SPORTS_TAB.SPORTS_CATEGORY) {
                // Create sportsCategoryList Data
                dropdownData.sportsCategoryList = createDropdownData(sportsDropdownFilterList[key])
            } else if (obj === SPORTS_TAB.LEAGUES) {
                // Create leaguesList Data
                dropdownData.leaguesList = createDropdownData(sportsDropdownFilterList[key])
            }
        }
    }
    return dropdownData
}

export const disableTableFields = (disableKeys, headerName) => {
    let removedFields = []
    SPORTS_TABLE_HEADING.forEach((item) => {
        let modifiedVal = { ...item }
        if (item?.accessor === "name") {
            modifiedVal.header = headerName
        }
        if (!disableKeys.includes(item?.accessor)) {
            removedFields.push(modifiedVal)
        }
    })
    return removedFields
}

export const getFilterId = (sportsCategory) => {
    let filterDataId = []
    for (let key in sportsCategory) {
        let name = key?.replace(" ", "_")?.toLowerCase()
        if (name === SPORTS_TAB.SPORTS_CATEGORY || name === SPORTS_TAB.LEAGUES) {
            filterDataId.push(sportsCategory?.[key]?.pageEntityId)
        }
    }
    return filterDataId
}

export const getEmptyErrorMessage = (selectedTab) => {
    return getDataBasedOnSelectedTab({
        selectedTab,
        sportCategoryData: ERROR_MESSAGES?.NO_CATEGORY_PRESENT,
        playersData: ERROR_MESSAGES?.NO_PLAYER_PRESENT,
        leaguesData: ERROR_MESSAGES?.NO_LEAGUE_PRESENT,
        matchData: ERROR_MESSAGES?.NO_MATCH_PRESENT
    })
}

export const getSelectedIdName = (parent, code) => {
    return {
        [code]: { id: parent?.id, name: parent?.meta?.name?.values?.[code], value: parent?.id }
    }
}

export const createSportsTab = (sportsCategoryList, showCount = true, labelKeyName = "label") => {
    let tabs = []
    if (sportsCategoryList) {
        for (let key in sportsCategoryList) {
            let tempObj = {
                id: SPORTS_TAB?.[key?.replaceAll(" ", "_")?.toUpperCase()],
                [labelKeyName]: showCount
                    ? `${key} (${sportsCategoryList?.[key]?.count})`
                    : `${key}`,
                pageEntityId: sportsCategoryList?.[key]?.pageEntityId,
                position: sportsCategoryList?.[key]?.position
            }
            tabs.push(tempObj)
        }
        tabs.sort((a, b) => a?.position - b?.position) //sorting in increasing order of position
    }
    return tabs
}
