export const REGEX = {
    VIDEO_URL:
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
    EMAIL_REGEX: /\S+@\S+\.\S+/,
    SUBDOMAIN_REGEX: /^[a-zA-Z0-9.-]+$/,
    PASSWORD_VALIDATION:
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}\\[\]:;<>,.?~])[A-Za-z0-9!@#$%^&*()_+{}\\[\]:;<>,.?~]{8,}$/,
    TIMEZONE_REGEX: /\((.*?)\)/, // To get string inside the ()
    DURATION_REGEX: /^(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d$/,
    FILE_NAME: /[^a-zA-Z.0-9_-]/g,
    REMOVE_SPACES_START_END: /^\s+|\s+$/g
}

export const RUPEES_SYMBOL_UNICODE = "\u20B9"
export const EURO_SYMBOL_UNICODE = "\u20AC"
export const CHECK_MARK = "\u2714"

export const MASTER_TENANT_IDENTIFIER_NAME = "master"

export const ERROR_MESSAGES = {
    TENANT_NICKNAME_EXISTS: "Tenant nick name already exists!",
    TENANT_EMAIL_EXISTS: "Tenant email already exists!",
    ENTER_VALID_MOBILE: "Please enter valid mobile no.",
    TENANT_MOBILE_EXISTS: "Tenant mobile already exists!",
    TENANT_SUBDOMAIN_EXISTS: "Tenant subdomain already exists!",
    SOME_ISSUE_API: "Some issue in api",
    ENTER_DATA_IN_ENGLISH: "Please enter data in english language",
    PROFILE_UPDATED_SUCCESS: "Your profile updated successfully",
    PROFILE_UPDATE_FAILURE: "Your profile is not updated",
    SOMETHING_WENT_WRONG: "Something went wrong! Please try again.",
    ENTER_VALID_DATA: "Please enter valid data",
    EMAIL_ALREADY_EXISTS: "User with this email already exist.",
    ENTER_VALID_EMAIL: "Please enter valid email",
    UNIQUE_TENANT_NAME: "Please enter unique tenant name.",
    RAIL_NAME_ERROR: "Rail name should be longer than 2 letters",
    ENTER_VALID_SUBDOMAIN: "Please enter valid subdomain",
    UPLOADED_CONTENT_ERROR: "There is no uploaded content available for transcoding.",
    TRANSCODING_ERROR: "There is no transcoded content available.",
    UPLOADING_NAVIGATION_ERROR:
        "If you leave this screen, the file uploading process will be interrupted, and you’ll need to start the process again to upload your files.",
    DASH_ERROR_SAFARI: "Please use chrome/firefox or any other browser to play this content.",
    HLS_ERROR: "Please use safari browser to play this content.",
    OFFLINE_ERROR_MESSAGE:
        "The current system is offline, which may affect the proper functioning of the file uploading feature. To resume uploading, please ensure a stable internet connection is established.",
    EMPTY_METADATA_INGESTION: "There is no metadata content available.",
    TIME_FORMAT1_ERROR: "Invalid duration format",
    TIME_FORMAT2_ERROR: "Please use hh:mm:ss",
    REQUIRED_FIELD_ERROR: "Please fill required fields!",
    CONTENT_URL_MISSING: "There is no play URL associated with this content.",
    IMAGE_UPLOAD_FAILED: "Image upload unsuccessful. Please retry.",
    SYSTEM_GENERATED_ERROR: "System generated rail already exists!",
    NO_CATEGORY_PRESENT: "There is no sports category available.",
    NO_PLAYER_PRESENT: "There is no player available.",
    NO_MATCH_PRESENT: "There is no match available.",
    NO_LEAGUE_PRESENT: "There is no league available."
}

export const TEXT_CONSTANTS = {
    ADDED_SUCCESSFULLY: "Added successfully",
    CREATE_SUPERADMIN: "Create Super Admin",
    FILL_BELOW_DETAILS: "Fill below Details to create super admin",
    SIGN_IN: "Sign In",
    CUSTOM_PROFILE: "Custom Profile",
    INPUT_SOURCE: "Input Source",
    OUTPUT: "Output",
    SELECT_SUBTITLE_LANGUAGE: "Select subtitle language",
    EMAIL: "EMAIL",
    PASSWORD: "PASSWORD",
    SIGN_IN_CAPS: "SIGN IN",
    FORGOT_PASSWORD: "FORGOT PASSWORD ?",
    FORGOT_PASSWORD_1: "FORGOT PASSWORD",
    RESET_LINK: "RESET LINK",
    BACK_TO_LOGIN: "BACK TO LOGIN",
    SEND_LINK_REGISTER_EMAIL:
        "Enter your register email address and we will send you a link to reset password",
    REQUEST_SUBMITTED: "Request Submitted!",
    THANK_YOU_MSG: "Thank you for filling up the details",
    REACH_SHORTLY: "We will reach out to you shortly",
    BACK_TO_DASHBOARD: "Back to Dashboard",
    ADD_NEW_TENANT: "Add New Tenant",
    ADD_SUBTITLE: "Add Subtitle",
    ADD_NEW_COUPON: "Add New Coupon",
    FILL_TENANT_DETAILS: "Fill below Details to create tenant",
    TENANT_BRAND_DETAILS: "Tenant Brand Details",
    BRAND_THEME: "Brand Theme",
    TENANT_PERSONAL_DETAILS: "Tenant Personal Details",
    TENANT_UPADTED_SUCCESS: "Tenant updated successfully",
    TENANT_ADDED_SUCCESS: "Tenant added successfully",
    LOGO_DIMENSION: "Logo dimension : Any x 30",
    TENANTS: "TENANTS",
    MANAGE_COUPONS: "Manage Coupons",
    CHANGE_IMAGE: "Change Image",
    UPLOAD_NEW: "Upload New",
    RECOMMENDED_SIZE: "Recommended Size",
    PIXEL_SIZE: "720 X 1280 PIXEL",
    SELECT_FROM_LIBRARY: "Select from Library",
    PORTRAIT: "PORTRAIT",
    LANDSCAPE: "LANDSCAPE",
    EDIT_CONTENT: "Edit Content",
    AUTO_SHUFFLE: "Auto-shuffle",
    ON: "ON",
    AUTO_UPDATE: "Auto Update",
    OFF: "OFF",
    CONTENT_TYPE: "Content Type",
    LANGUAGE: "LANGUAGE",
    ARTIST: "ARTIST",
    SCHEDULE_RAIL_PUBLISH: "schedule rail publish",
    PICK_DATE: "Pick Date",
    START_DATE_TIME: "Start date and time",
    END_DATE_TIME: "End date and time",
    SELECT_SEASON: "Select Season",
    NO_RECORDS_FOUND: "No Records Found",
    EDIT_RAIL: "EDIT RAIL",
    ADD_NEW_RAIL: "ADD NEW RAIL",
    UNDER: "Under",
    TAB: "Tab",
    RAIL_CATEGORY: "Rail Category",
    CREATE_NEW_RAIL: "CREATE NEW RAIL",
    SYSTEM_GENERATED: "SYSTEM GENERATED",
    RAIL_NAME_TYPE: "Rail Type & name",
    FEATURED_IN: "Featured In",
    ADD_CONTENT: "Add Content",
    CONTENT_ADDED: "Content Added",
    SCHEDULE_RULE: "Selected Rule",
    ADD_EDIT_TAB: "ADD / EDIT TAB",
    SELECT_PLATFORM: "Select Platform and enter Tab Name",
    DO_YOU_WANT_TO_REMOVE: "Do you want to remove",
    ADD_NEW_TAB: "add new tab",
    WISH_TO: "Are you sure you wish to",
    PUBLISH: "Publish",
    UNPUBLISH: "Unpublish",
    THIS_RAIL: "this rail?",
    DELETE_RAIL_MSG: "Deleting this rail will delete it from all the platforms. Do you still wish to continue?",
    DUPLICATE_RAIL: "Duplicate Rail",
    DUPLICATE: "Duplicate",
    STAGE: "Stage",
    SYSTEM_GENERATED_RAIL: "Hi, This is a system generated rail",
    SYSTEM_GENERATED_RAIL_MSG:
        "Any material that comes on it is visible only on the respective platforms.",
    PAGE_VISIBILITY: "Page Visibility",
    RAIL_NAME_PLATFORM: "rail name and platform",
    STATUS: "Status",
    FROM: "From",
    TO: "To",
    ACTION: "Action",
    DELETE_RAIL: "Delete this Rail ?",
    COPY_TO: "Copy To",
    SAVE_CHANGES: "Save Changes ?",
    DRAG_TO_CHANGE: "drag to change sequence",
    UX_MANAGER: "UX Manager",
    CATEGORY: "Category",
    FINGER_PRINTING: "Finger Printing",
    BINGE_MARKER_IDENTIFICATION: "Binge Marker Identification",
    AD_BREAK_IDENTIFICATION: "Ad Breaks Identification",
    LIST: "List",
    NO_DATA: "No Data Available",
    CONTENT_ENRICHMENT: "Content Enrichment",
    EDITORIAL: "Editorial",
    NO_IMAGE: "No Image Available",
    LIVE_TV: "Live Tv",
    CONTENT: "Content",
    SELECTED: "Selected",
    SEASON: "Season",
    EPISODE: "Episode",
    PLAY_URL: "Play URL",
    DURATION: "Duration",
    START: "Start",
    SECS: "Secs",
    END: "End",
    U_A: "U/A",
    GENRE: "Genre",
    YEAR: "Year",
    CAST: "Cast",
    DIRECTOR: "Director",
    BINGE_MARKER: "Binge Marker",
    AD_BREAK: "Ad Break",
    FIGER_PRINTING: "Finger Printing",
    PLEASE_WAIT_MOMENT: "Please wait a moment...",
    FETCHING_IMDB_DATA: "We are fetching the data from IMDB",
    BACK_TO_MOVIE: "Back to Movie",
    ALERT: "Alert",
    THIS_CONTENT_PUBLISH:
        "This content is published on mentioned sections and will be unpublish automatically",
    PAGES: "Pages",
    SECTIONS: "Sections",
    DELETE_MOVIE: "Delete this Movie?",
    NO_RESULT_FOUND: "No Result Found",
    WISH_TO_DELETE_MOVIE: "Are you sure you wish to Delete this movie?",
    SETTINGS: "Settings",
    CUSTOMER_BRANDING: "Customer Branding",
    YOUR_LOGO: "Your Logo",
    LIGHT: "Light",
    DARK: "Dark",
    DEFAULT_MODE: "Default Mode",
    FAQ: "FAQ",
    PAGE_NAME: "Page Name",
    QUESTION: "Question",
    ANSWER: "Answer",
    CREATE_FORM: "Create Form",
    FORM_DETAILS_SENDS: "FORM DETAILS SEND ON",
    CREATE_TEXT_PAGE: "CREATE TEXT PAGE",
    DESCRIPTION: "Description",
    CUSTOM_PAGES: "Custom Pages",
    CREATE_NEW_PAGES: "CREATE NEW PAGES",
    SELECT_PAGE_TYPE: "Select page type",
    TEXT_PAGE: "Text Page",
    ABOUT_US_PRIVACY: "About us, Privacy Policy etc.",
    FORM: "Form",
    CONTACT_FEEDBACK: "Contact, Feedback etc.",
    ANALYTICS_DASHBOARD: "ANALYTICS DASHBOARD",
    CMS_DASHBOARD: "CMS DASHBOARD",
    RECENTLY_ADDED_CONTENT: "Recently Added Content",
    STREAM: "Stream",
    EPG: "EPG",
    ADD_SHOW: "Add Show",
    SHOW_TITLE: "SHOW TITLE",
    SELECT_CHANNEL: "SELECT CHANNEL",
    AIRED_DATE: "AIRED DATE",
    START_TIME: "START TIME",
    END_TIME: " END TIME",
    RATING: "Rating",
    CATCHUP_AVAILABLE: "CATCHUP AVAILABLE",
    CREDIT: "Credit",
    STARRING: "Starring",
    PRODUCER: "Producer",
    TV_SHOWS: "TV SHOWS",
    SHOW_NOT_AVAILABLE: "Show not available ",
    NO_SHOW_AVAIALBLE: "No Show Available",
    CATCH_UP: "Catchup",
    TIME: "Time",
    DESIGN_YOUR_FORM: "Design Your Form",
    ADD_NEW_FORM_FIELDS: "Add new fields from form elements",
    FORM_ELEMENTS: "Form Elements",
    ADD_NEW_FIELDS: "Add New Field",
    SIZE: "Size",
    GENERAL_SETTINGS: "General Settings",
    TEXT_TO_LOAD: "Show Text In Load",
    NONE: "None",
    PLACEHOLDER: "Placeholder",
    ITEM_FROM_DROPDOWN: "Item from dropdown",
    VLAUE: "Value",
    RESET_LINK_TEXT: "We have sent a link to reset your password. Check your mail inbox",
    MENU_ICON: "Menu Icon",
    TAB_NAME: "Tab Name",
    TYPE: "Type",
    LINK_TO_PAGE: "Link To Page",
    PLACEMENT: "Placement",
    MENU_DESIGN: "Menu Design",
    TIME_FRAMES: "Time Frames",
    OBJECT_PLACES: "Objects and places in the clip identified by AI",
    TAGS: "Tags",
    SELECTED_TARGETS: "Selected Targets",
    WHERE_WILL_AIRED: "Where ad will be aired",
    ORIGINAL_VIDEO: "Original Video",
    SELECT_CLIPS_PLACEHOLDER: "Select clip/s to add on video's placeholder",
    CLIPS_WITH_ADS: "Clips with ads",
    APPLY_CLIPS_VIDEO: "Apply clip/s to add on video’s placeholder",
    LOCATION: "Location",
    AGE_GROUP: "Age Group",
    GENDER: "Gender",
    PRODUCT_LIST: "Products List",
    PRODUCT_PLACEMENT: "Product-Placement",
    NEW_TASK: "New Task",
    SELECT_CONTENT_TO_ADD: "Select content to add the placement",
    LIST_OF_PERMISSION: "List of permission",
    ADD_NEW_ROLE: "ADD NEW ROLE",
    USER_MANAGEMENT: "user-management",
    ROLES: "Roles",
    ADD_NEW_USER: "ADD NEW USER",
    NO: "No",
    YES: "Yes",
    DELETE_THIS_USER: "Delete this User ?",
    USERS: "Users",
    MY_PROFILE: "My Profile",
    SIGN_OUT: "Sign Out",
    CHANGE_PASSWORD: "Change Password",
    YOUR_AVTAR: "YOUR AVTAR",
    PRIVACY_POLICY_TXT: "By submitting this form you acknowledge that you have read TO THE NEW's",
    PRIVACY_POLICY: "Privacy Policy",
    TERMS: "and agree to its terms.",
    WEBSERIES: "WebSeries",
    WEB_SERIES: "Web Series",
    STEP_1: "Step 1",
    BRAND: "Brand",
    STEP_2: "Step 2",
    EPISODES: "Episodes",
    FORM_PREVIEW: "Form Preview",
    TIMEZONE: "Add Timezone",
    SEARCH_RESULTS: "Search Results",
    CONTENT_SELECTED: "Content Selected",
    SELECTED_PLAN_TEXT:
        "Choose the plan(s) that will incorporate the selected content as a part of it.",
    FILTER_MOVIE_MESSAGE: "Please filter the movies first",
    EMPTY_CONTAINER_MESSAGE: "Apply the filters or search first to find the content",
    MOVIES_SUCCESS_ADDED_MESSAGE: " movies have been added to the ",
    WEBSERIES_SUCCESS_ADDED_MESSAGE: " series have been added to the ",
    CONTEN_Product_MANAGEMENT: "Content-Product Mapping",
    ADD_FILTER: "Add Filter",
    FILTER_DES: "Create the selected options as a filter",
    FILTER_NAME: "Filter Name",
    SELECT_SAVED_FILTERS: "Select Saved Filters",
    MAP_MOVIES_TO_PLANS: "Map the selected content with subscription plans",
    SUBSCRIPTION_PLANS: "Subscription Plans",
    SUBSCRIPTION_PLANS_DESC:
        "Select the plan(s) that will incorporate the selected content as a part of it.",
    DELETE_SAVE_FILTER: "Delete Saved Filter",
    DELETE_CONFIRMATION_MSG: "Are you sure, you want to delete the saved filter?",
    SORT: "Sort",
    SELECT_ALL: "Select All",
    FIRST_FREE_EPISODE: "First Episode Free For All Seasons",
    MANAGE_PLANS: "Manage Plans",
    DRM_NOT_OPTED: "You have not opted for DRM.",
    DRM_UNDER_PROCESS: "Your stack creation is under process. Please check after sometime.",
    BASIC_SETTINGS_SUCCESS: "Successfully updated",
    TRANSCODING: "Transcoding",
    DRM: "DRM",
    SELECT_INPUT_SOURCE: "Select Input Source",
    FILE_UPLOAD: "File Upload",
    FILE_UPLOADING: "Files Uploading...",
    VIDEO_PIPELINE: "Video Pipeline",
    VIDEO_PIPELINE_MSG: "Add or manage your transcoding content",
    TRANSCODED_CONTENT: "Transcoded Content",
    UPLOADED_CONTENT: "Uploaded Content",
    CONTENTS_SELECTED: "Contents selected",
    TRANSCODING_MSG: "You can start transcoding after selecting the content/s",
    DELETE_TRANSCODED_CONTENT: "Are you sure you wish to delete this",
    CANCEL_TRANSCODED_CONTENT: "Are you sure you wish to cancel this",
    CONFIRM_POPUP_CONTENT_LEVEL:
        "Are you sure you wish to change content level ? The selection for other contents will be deleted.",
    DATE_RANGE: "Date Range",
    MONETIZE: "Monetize",
    DRM_NOT_CONFIGURED:
        "Your DRM is not yet configured, to configure your DRM please contact administrator.",
    COPY_TO_CLIPBOARD: "Copy to clipboard",
    DELETE_EPG_MSG: "Are you sure you wish to delete this?",
    SCHEDULE: "Schedule",
    METADATA_INGESTION: "Metadata Ingestion",
    SELECT_CONTENT_TYPE: "Select Content Type",
    UPLOAD_CSV_FILE: "Upload CSV File",
    DOWNLOAD_TEMPLATE: "Download Template",
    DROP_FILES_HERE: "Drop the file here...",
    DRAG_DROP_FILES: "Drag & Drop file to upload OR",
    BROWSE: "Browse",
    SUCCESS: "Success",
    FAILURE: "Failure",
    FILE_STATUS_TEXT: "File Status",
    AD_DURATION_MESSAGE: "Setting it to -1 implies automatic ads duration.",
    SERIES: "Web Series",
    CONFIGURATION: "Configuration",
    TIMEZONE_LABEL: "Timezone",
    SELECTED_TIMEZONE_LABEL: "Selected Timezone",
    TIMEZONE_LIST: "List of Timezone",
    CONFIG_UPDATE_MESSAGE:
        "Configs already exist for this Platform. Previous configs will be deactivate and new will be activated. Are you sure you want to continue? ",
    CREATE_MANAGE_PAGE: "Create/Manage Pages",
    SPORTS_HOME: "Sports Home",
    AUDIT_TRAIL: "Audit Trail",
    ADD_NEW_CATEGORY_LABEL: "Want to add new Category?",
    SETUP_SPORTS_LABEL: "Add from Setup Sports",
    UNPUBLISH_CATEGORY: "Unpublishing Category",
    UNPUBLISH_CATEGORY_DESC:
        "Ensure that end users will no longer have visibility of this category across all platforms before unpublishing it.",
    UNPUBLISH_CATEGORY_CONFIRMATION: "Are you sure you want to unpublish this?",
    SPORTS: "Sports",
    SETUP_SPORTS: "Setup Sports",
    DELETE_CUSTOM_IMAGES: "Are you sure you want to delete the custom images?",
    UNPUBLISHING_RAIL_MESSAGE: "Unpublishing this rail will unpublish it from all the platforms. Do you still wish to continue?",
    PUBLISHING_RAIL_MESSAGE: "Publishing this rail will publish it to all the platforms. Do you still wish to continue?",
    BULK_UPLOAD: "Bulk Upload",
    ADD_TRANSLATION: "Add Translation In"

}

export const BUTTON_CONSTANTS = {
    PREVIOUS: "Previous",
    NEXT: "Next",
    UPDATE: "Update",
    CREATE: "Create",
    REMOVE: "Remove",
    CHANGE: "Change",
    EDIT: "Edit",
    DONE: "Done",
    ACTIVATE: "Activate",
    DELETE: "Delete",
    SELECT: "Select",
    SAVE: "Save",
    CANCEL: "Cancel",
    APPLY: "Apply",
    SCHEDULE_NOW: "schedule now",
    ADD: "Add",
    SUBMIT: "Submit",
    ADD_CUSTOM_PROFILE: "Add custom profile",
    BACK: "Back",
    CLOSE: "Close",
    PLAY_REEL: "Play Reel",
    RESET: "Reset",
    REFRESH_RAIL: "Refresh Rails",
    ADD_IN_LIST: "Add in list",
    SCHEDULE_PUBLISH: "Schedule Publish",
    YES_DELETE: "Yes, Delete",
    PLUS: "+",
    YES: "Yes",
    YES_SAVE: "Yes, SAVE",
    PUBLISH_CHANGES: "Publish Changes",
    VIEW: "View",
    START_LIVE_STREAMING: "Start Live Streaming",
    RESTART_STREAMING: "Restart Streaming",
    ADD_NEW: "Add New",
    METADATA_UPDATE: "Metadata update(Imdb)",
    CONTENT_ENRICHMENT: "Content Enrichment",
    MOVIE_INSIGHTS: "Movie Insights",
    PREVIOUS_STEP: "Previous Step",
    SAVE_NEXT_STEP: "Save and Next Step",
    SAVE_CHANGES: "Save Changes",
    LINK_FORM: "Link Form",
    LINK: "Link",
    GO_TO_LOGIN: "Go to Login",
    ADD_EDIT_MENU: "Add / Edit Menu",
    SELECT_VIDEO: "Select Video",
    SELECTED_TARGETS: "Select Target",
    PROCEED: "Proceed",
    VIEW_ALL: "View All",
    ADD_NEW_PLAN: "Add New Plan",
    ADD_PLAN: "Add Plan",
    ADD_ROLE: "Add Role",
    ADD_USER: "Add User",
    ADD_MORE_SEASON: "Add More Season",
    SAVE_FINISH: "Save And Finish",
    ADD_EPISODE: "Add Episode",
    CUSTOMISE: "Customise",
    FILTER_OPTIONS: "Filter Options",
    SAVE_FILTER: "Save Filter",
    EDIT_SAVED_FILTER: "Edit Saved Filter",
    MAP_TO_PLAN: "Map to Plan/s",
    OK: "Ok",
    START_NOW: "Start Now",
    PROCEED_TO_TRANSCODE: "Proceed to Transcode",
    FILTERS: "Filters",
    CLEAR_ALL: "Clear All",
    PLAY_URL: "Play URL",
    VIEW_SETTINGS: "View Settings",
    CONFIRM: "Confirm",
    VIEW_RIGHTS: "View Rights",
    DOWNLOAD_EVENT: "Download Event",
    NOT_NOW: "Not Now",
    PUBLISH_SCHEDULED: "Publish Scheduled",
}

export const PERMISSIONS = {
    SUPER_ADMIN: "superadmin",
    ADMIN: "admin",
    USER_MANAGEMENT_READ: "USER_MANAGEMENT_READ",
    USER_MANAGEMENT_EDIT: "USER_MANAGEMENT_EDIT",
    CONTENT_VIEW: "CONTENT_VIEW",
    CONTENT_EDIT: "CONTENT_EDIT",
    EDITORIAL_VIEW: "EDITORIAL_VIEW",
    // EDITORIAL_EDIT: "EDITORIAL_EDIT",
    CUSTOMER_BRANDING_VIEW: "CUSTOMER_BRANDING_VIEW",
    CUSTOMER_BRANDING_EDIT: "CUSTOMER_BRANDING_EDIT",
    CUSTOM_PAGES_READ: "CUSTOM_PAGES_READ",
    CUSTOM_PAGES_EDIT: "CUSTOM_PAGES_EDIT",
    MENU_DESIGN_READ: "MENU_DESIGN_READ",
    MENU_DESIGN_EDIT: "MENU_DESIGN_EDIT",
    DASHBOARD_VIEW: "DASHBOARD_VIEW",
    DASHBOARD_EDIT: "DASHBOARD_EDIT",
    STREAM_VIEW: "STREAM_VIEW",
    EPG_VIEW: "EPG_VIEW",
    EPG_EDIT: "EPG_EDIT",
    UXMANAGER_VIEW: "UXMANAGER_VIEW",
    UXMANAGER_EDIT: "UXMANAGER_EDIT",
    CONTENT_ENRICHMENT_VIEW: "CONTENT_ENRICHMENT_VIEW",
    CONTENT_ENRICHMENT_EDIT: "CONTENT_ENRICHMENT_EDIT",
    MONITIZE_VIEW: "MONITIZE_VIEW",
    MONETIZE_EDIT: "MONETIZE_EDIT",
    TENANT_LIST_VIEW: "TENANT_LIST_VIEW",
    TENANT_LIST_EDIT: "TENANT_LIST_EDIT",
    ADD_TENANT_VIEW: "ADD_TENANT_VIEW",
    ADD_TENANT_EDIT: "ADD_TENANT_EDIT",
    SETTING_VIEW: "SETTING_VIEW",
    SETTING_EDIT: "SETTING_EDIT",
    MONETIZE_ROI_MAXIMISER_EDIT: "MONETIZE_ROI_MAXIMISER_EDIT",
    MONETIZE_ROI_MAXIMISER_VIEW: "MONETIZE_ROI_MAXIMISER_VIEW",
    SETTING_USER_MANAGEMENT_VIEW: "SETTING_USER_MANAGEMENT_VIEW",
    SETTING_USER_MANAGEMENT_EDIT: "SETTING_USER_MANAGEMENT_EDIT",
    SETTING_CUSTOMER_VIEW: "SETTING_CUSTOMER_VIEW",
    SETTING_CUSTOMER_EDIT: "SETTING_CUSTOMER_EDIT",
    SETTING_FORM_BUILDER_VIEW: "SETTING_FORM_BUILDER_VIEW",
    SETTING_FORM_BUILDER_EDIT: "SETTING_FORM_BUILDER_EDIT",
    SETTING_MULTILINGUAL_VIEW: "SETTING_MULTILINGUAL_VIEW",
    SETTING_MULTILINGUAL_EDIT: "SETTING_MULTILINGUAL_EDIT",
    SUBSCRIPTION_VIEW: "SUBSCRIPTION_VIEW",
    SUBSCRIPTION_EDIT: "SUBSCRIPTION_EDIT",
    SUBSCRIPTION_ADD_PRODUCT_VIEW: "SUBSCRIPTION_ADD_PRODUCT_VIEW",
    SUBSCRIPTION_ADD_PRODUCT_EDIT: "SUBSCRIPTION_ADD_PRODUCT_EDIT",
    SUBSCRIPTION_COUPONS_VIEW: "SUBSCRIPTION_COUPONS_VIEW",
    SUBSCRIPTION_COUPONS_EDIT: "SUBSCRIPTION_COUPONS_EDIT",
    SUBSCRIPTION_PROMOTION_VIEW: "SUBSCRIPTION_PROMOTION_VIEW",
    SUBSCRIPTION_PROMOTION_EDIT: "SUBSCRIPTION_PROMOTION_EDIT",
    SUBSCRIPTION_ACTIVE_CUSTOMER_VIEW: "SUBSCRIPTION_ACTIVE_CUSTOMER_VIEW",
    SUBSCRIPTION_ACTIVE_CUSTOMER_EDIT: "SUBSCRIPTION_ACTIVE_CUSTOMER_EDIT",
    SUBSCRIPTION_VIEW_REPORT_VIEW: "SUBSCRIPTION_VIEW_REPORT_VIEW",
    SUBSCRIPTION_VIEW_REPORT_EDIT: "SUBSCRIPTION_VIEW_REPORT_EDIT",
    SUBSCRIPTION_DASHBOARD_VIEW: "SUBSCRIPTION_DASHBOARD_VIEW",
    SUBSCRIPTION_DASHBOARD_EDIT: "SUBSCRIPTION_DASHBOARD_EDIT",
    SETTING_USER_MANAGEMENT_USERS_VIEW: "SETTING_USER_MANAGEMENT_USERS_VIEW",
    SETTING_USER_MANAGEMENT_USERS_EDIT: "SETTING_USER_MANAGEMENT_USERS_EDIT",
    SETTING_USER_MANAGEMENT_ROLES_VIEW: "SETTING_USER_MANAGEMENT_ROLES_VIEW",
    SETTING_USER_MANAGEMENT_ROLES_EDIT: "SETTING_USER_MANAGEMENT_ROLES_EDIT",
    ENGAGE_EDIT: "ENGAGE_EDIT",
    ENGAGE_VIEW: "ENGAGE_VIEW",
    ENGAGE_UX_MANAGER_CATEGORY_VIEW: "ENGAGE_UX_MANAGER_CATEGORY_VIEW",
    ENGAGE_UX_MANAGER_CATEGORY_EDIT: "ENGAGE_UX_MANAGER_CATEGORY_EDIT",
    ENGAGE_UX_MANAGER_CUSTOM_PAGES_VIEW: "ENGAGE_UX_MANAGER_CUSTOM_PAGES_VIEW",
    ENGAGE_UX_MANAGER_CUSTOM_PAGES_EDIT: "ENGAGE_UX_MANAGER_CUSTOM_PAGES_EDIT",
    ENGAGE_UX_MANAGER_MENU_DESIGN_VIEW: "ENGAGE_UX_MANAGER_MENU_DESIGN_VIEW",
    ENGAGE_UX_MANAGER_MENU_DESIGN_EDIT: "ENGAGE_UX_MANAGER_MENU_DESIGN_EDIT",
    ENGAGE_UX_MANAGER_CONTENT_ENRICHMENT_VIEW: "ENGAGE_UX_MANAGER_CONTENT_ENRICHMENT_VIEW",
    ENGAGE_UX_MANAGER_CONTENT_ENRICHMENT_EDIT: "ENGAGE_UX_MANAGER_CONTENT_ENRICHMENT_EDIT",
    STREAM_CONTENT_REELS_VIEW: "STREAM_CONTENT_REELS_VIEW",
    STREAM_CONTENT_REELS_EDIT: "STREAM_CONTENT_REELS_EDIT",
    STREAM_CONTENT_MOVIE_VIEW: "STREAM_CONTENT_MOVIE_VIEW",
    STREAM_CONTENT_MOVIE_EDIT: "STREAM_CONTENT_MOVIE_EDIT",
    STREAM_CONTENT_WEB_SERIES_VIEW: "STREAM_CONTENT_WEB_SERIES_VIEW",
    STREAM_CONTENT_WEB_SERIES_EDIT: "STREAM_CONTENT_WEB_SERIES_EDIT",
    MONETIZE_PRODUCT_PLACEMENT_VIEW: "MONETIZE_PRODUCT_PLACEMENT_VIEW",
    MONETIZE_PRODUCT_PLACEMENT_EDIT: "MONETIZE_PRODUCT_PLACEMENT_EDIT",
    DRM_VIEW: "DRM_VIEW",
    DRM_EDIT: "DRM_EDIT",
    VIDEO_PIPELINE_EDIT: "VIDEOPIPELINE_EDIT",
    VIDEO_PIPELINE_VIEW: "VIDEOPIPELINE_VIEW",
    BASIC_CONFIGURATION_EDIT: "BASIC_CONFIGURATION_EDIT",
    BASIC_CONFIGURATION_VIEW: "BASIC_CONFIGURATION_VIEW",
    BULK_UPLOAD_EDIT: "BULK_UPLOAD_EDIT",
    BULK_UPLOAD_VIEW: "BULK_UPLOAD_VIEW",
    TIMEZONE_VIEW: "TIMEZONE_VIEW",
    TIMEZONE_EDIT: "TIMEZONE_EDIT",
    TRANSLATION_EDIT: "TRANSLATION_EDIT",
    TRANSLATION_VIEW: "TRANSLATION_VIEW",
}

export const TIMEZONE_OPTIONS = [
    { name: "GMT-12:00", id: "GMT-12:00", offset: -720 },
    { name: "GMT-11:00", id: "GMT-11:00", offset: -660 },
    { name: "GMT-10:00", id: "GMT-10:00", offset: -600 },
    { name: "GMT-09:30", id: "GMT-09:30", offset: -570 },
    { name: "GMT-09:00", id: "GMT-09:00", offset: -540 },
    { name: "GMT-08:00", id: "GMT-08:00", offset: -480 },
    { name: "GMT-07:00", id: "GMT-07:00", offset: -420 },
    { name: "GMT-06:00", id: "GMT-06:00", offset: -360 },
    { name: "GMT-05:00", id: "GMT-05:00", offset: -300 },
    { name: "GMT-04:30", id: "GMT-04:30", offset: -270 },

    { name: "GMT-4:00", id: "GMT-4:00", offset: -240 },
    { name: "GMT-03:30", id: "GMT-03:30", offset: -210 },
    { name: "GMT-03:00", id: "GMT-03:00", offset: -180 },
    { name: "GMT-02:00", id: "GMT-02:00", offset: -120 },
    { name: "GMT-01:00", id: "GMT-01:00", offset: -60 },
    { name: "GMT+00:00", id: "GMT+00:00", offset: 0 },
    { name: "GMT+01:00", id: "GMT+01:00", offset: 60 },
    { name: "GMT+02:00", id: "GMT+02:00", offset: 120 },
    { name: "GMT+03:00", id: "GMT+03:00", offset: 180 },
    { name: "GMT+03:30", id: "GMT+03:30", offset: 210 },

    { name: "GMT+04:00", id: "GMT+04:00", offset: 240 },
    { name: "GMT+04:30", id: "GMT+04:30", offset: 270 },
    { name: "GMT+05:00", id: "GMT+05:00", offset: 300 },
    { name: "GMT+05:30", id: "GMT+05:30", offset: 330 },
    { name: "GMT+05:45", id: "GMT+05:45", offset: 345 },
    { name: "GMT+06:00", id: "GMT+06:00", offset: 360 },
    { name: "GMT+06:30", id: "GMT+06:30", offset: 390 },
    { name: "GMT+07:00", id: "GMT+07:00", offset: 420 },
    { name: "GMT+08:00", id: "GMT+08:00", offset: 480 },
    { name: "GMT+08:45", id: "GMT+08:45", offset: 525 },

    { name: "GMT+09:00", id: "GMT+09:00", offset: 540 },
    { name: "GMT+09:30", id: "GMT+09:30", offset: 570 },
    { name: "GMT+10:00", id: "GMT+10:00", offset: 600 },
    { name: "GMT+10:30", id: "GMT+10:30", offset: 630 },
    { name: "GMT+11:00", id: "GMT+11:00", offset: 660 },
    { name: "GMT+11:30", id: "GMT+11:30", offset: 690 },
    { name: "GMT+12:00", id: "GMT+12:00", offset: 720 },
    { name: "GMT+12:45", id: "GMT+12:45", offset: 765 },
    { name: "GMT+13:00", id: "GMT+13:00", offset: 780 },
    { name: "GMT+14:00", id: "GMT+14:00", offset: 840 }
]
