import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import isEmpty from "lodash/isEmpty"

import { URL } from "../../utils/routeConstants"
import Input from "../common/Input"
import Button from "../common/Button"
import BrandImage from "../../assets/images/brand.svg"
import LogoImage from "../../assets/images/VideoReady_White.svg"
import { userLogin, clearLoginState, forgotPassword, extendTrail } from "./APIs/action"
import CreatePassword from "./CreatePassword"

import "./style.scss"
import { ExtendTrailForm } from "./ExtendTrailForm"
import { ERROR_MESSAGES, REGEX, TEXT_CONSTANTS } from "../../utils/constants"

class LoginPanel extends Component {
    constructor(props) {
        super(props)
        let { auth, history } = props
        this.changePasswordResetToken =
            props?.history?.location?.search && props?.history?.location?.search.split("=")[1]
        if (auth != null && !isEmpty(auth)) {
            //clear for unmount
            history.push(`/${URL.DASHBOARD}`)
        } else {
            if (this.changePasswordResetToken) {
            } else history.push(`/`)
        }

        this.state = {
            email: "",
            password: "",
            isAuth: false,
            isForgotPasswod: false,
            isSecreteLink: false,
            resetToken: this.changePasswordResetToken,
            error: {},
            showExtendTrailMsg: false
        }
        this.error = {}
    }

    componentDidMount() {}

    resetLoginInitialState = () => {
        this.setState({
            email: "",
            password: ""
        })
    }

    handleChange = (name, value) => {
        let isValidated = this.validate(name, value)
        this.setState({
            [name]: value,
            isValidated
        })
    }

    handleToggleLink = () => {
        this.setState({
            isForgotPasswod: !this.state.isForgotPasswod,
            error: {}
        })
        this.error = {}
    }

    onSubmit = async (e) => {
        e.preventDefault()
        const { email, password } = this.state
        const { userLogin } = this.props
        const payload = {
            email: email?.trim(),
            password: password?.trim()
        }
        this.setState({
            error: { isEmail: "", isPass: "" }
        })
        const response = await userLogin(payload)
        if (response && response.status) {
            this.props.handleAfterLogin()
        } else {
            toast.error(response.message)
        }
    }

    handleForgotPassword = async (e) => {
        e.preventDefault()
        const { email } = this.state
        const { forgotPassword } = this.props
        const payload = email
        let validated = this.validateEmail(payload)
        if (!validated) {
            return null
        }
        this.setState({ error: { isEmail: "" } })
        const response = await forgotPassword(payload)
        if (response && response.status) {
            this.setState({
                isSecreteLink: true
            })
        } else {
            toast.error(
                response?.message ? response.message : "Subscriber with this email does not exist"
            )
        }
    }

    validateEmail = (value) => {
        let email = value
        const emailRegex = REGEX.EMAIL_REGEX
        if (isEmpty(value)) {
            this.error.isEmail = "Please enter valid Email"
            return false
        } else if (!emailRegex.test(email)) {
            this.error.isEmail = "Please enter valid Email"
            return false
        } else {
            delete this.error.isEmail
            return true
        }
    }

    validatePassword = (value) => {
        if (isEmpty(value)) {
            this.error.isPass = "Please enter your password"
        } else if (value.length < 6) {
            this.error.isPass = "Password length should be minimum 6"
        } else {
            delete this.error.isPass
        }
    }

    validate = (name, value) => {
        let isValidated = false
        switch (name) {
            case "email":
                this.validateEmail(value)
                break
            case "password":
                this.validatePassword(value)
                break
            default:
                return
        }
        isValidated = isEmpty(this.error)
        this.setState({ error: this.error })
        return isValidated
    }

    extendTrailHandler = async (payload) => {
        const data = {
            ...payload,
            phone: payload?.phoneNumber,
            message: payload?.description || "",
            org: payload?.organization
        }
        let response = await extendTrail(data)
        if (response?.status) {
            this.setState({ showExtendTrailMsg: true })
            toast.success(response?.message)
        } else {
            toast.error(ERROR_MESSAGES.SOMETHING_WENT_WRONG)
        }
        return response
    }

    showExtendTrailSuccess = () => {
        return (
            <div className="form-container extend-trail-success">
                <h2>{TEXT_CONSTANTS.REQUEST_SUBMITTED}</h2>
                <p>{TEXT_CONSTANTS.THANK_YOU_MSG}</p>
                <p>{TEXT_CONSTANTS.REACH_SHORTLY}</p>
            </div>
        )
    }

    refreshLogin = () => {
        this.changePasswordResetToken = false
        this.setState({
            isSecreteLink: false,
            isForgotPasswod: false
        })
    }

    showLoginPanel = () => {
        const { isForgotPasswod, isSecreteLink, error, isValidated, email, password } = this.state
        let submitEnabled = !isForgotPasswod
            ? isValidated && !isEmpty(email) && !isEmpty(password)
            : isValidated && !isEmpty(email)
        return (
            <div className="form-container">
                {!isSecreteLink && !this.changePasswordResetToken ? (
                    <form className={isForgotPasswod ? "forgot-password" : "login-form"}>
                        <h2>
                            {!isForgotPasswod
                                ? TEXT_CONSTANTS.SIGN_IN
                                : TEXT_CONSTANTS.FORGOT_PASSWORD_1}
                        </h2>
                        {isForgotPasswod && <p>{TEXT_CONSTANTS.SEND_LINK_REGISTER_EMAIL}</p>}
                        <Input
                            name="email"
                            placeholder={TEXT_CONSTANTS.EMAIL}
                            changeHandler={this.handleChange}
                            errMsg={error.isEmail}
                            required={true}
                            maxLength={60}
                        />
                        {!isForgotPasswod && (
                            <Input
                                name="password"
                                type="password"
                                placeholder={TEXT_CONSTANTS.PASSWORD}
                                changeHandler={this.handleChange}
                                errMsg={error.isPass}
                                maxLength={60}
                            />
                        )}
                        <Button
                            name="signin"
                            cName="btn primary-btn"
                            type="submit"
                            bValue={
                                !isForgotPasswod
                                    ? TEXT_CONSTANTS.SIGN_IN
                                    : TEXT_CONSTANTS.RESET_LINK
                            }
                            disabled={!submitEnabled}
                            clickHandler={
                                isForgotPasswod ? this.handleForgotPassword : this.onSubmit
                            }
                        />
                        <span className="forgot-password-link" onClick={this.handleToggleLink}>
                            {!isForgotPasswod
                                ? TEXT_CONSTANTS.FORGOT_PASSWORD
                                : TEXT_CONSTANTS.BACK_TO_LOGIN}
                        </span>
                    </form>
                ) : (
                    <CreatePassword
                        token={this.changePasswordResetToken}
                        history={this.props?.history}
                        refreshLogin={this.refreshLogin}
                    />
                )}
                <div className="form-img">
                    <div>
                        <img src={BrandImage} alt="brand" />
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { showExtendTrailMsg } = this.state
        const { tenantData } = this.props
        const isTenantExpired = tenantData?.isTenantExpired || tenantData?.auxData?.isTenantExpired
        return (
            <div className="login-panel">
                <div className="login-logo">
                    <img src={LogoImage} alt="logo" />
                </div>
                {showExtendTrailMsg ? this.showExtendTrailSuccess() : this.showLoginPanel()}
                {(tenantData?.tenant_deleted || isTenantExpired) &&
                    !this.state.showExtendTrailMsg && (
                        <ExtendTrailForm
                            extendTrailHandler={this.extendTrailHandler}
                            tenantAdminName={tenantData?.tenantAdminName}
                            tenantAdminContact={tenantData?.tenantAdminContact}
                        ></ExtendTrailForm>
                    )}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        tenantData: state?.AdminHomeReducer?.tenantInfo?.data
    }
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(
            {
                userLogin,
                clearLoginState,
                forgotPassword
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPanel)
