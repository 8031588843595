import React from 'react'

/**
 * Reusable component for setting the page title
 * 
 */
export default function PageTitleComponent({ pageTitle, menuTitle }) {
    return (
        <div className="vr-cont-title">
            {menuTitle && <span className="vr-breadcrum">
                {menuTitle}
                <i className="icon-arrow-right1" />
                <span>{pageTitle}</span>
            </span>}
            {pageTitle && <h2 className="breadcrum-header">
                {pageTitle?.toUpperCase()}
            </h2>}
        </div>
    )
}
