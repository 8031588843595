import { isEmpty } from "lodash"
import React, { Component } from "react"
import VideoPlayerJS from "../../../../common/VideoJsMarkerPlayer"

import "./style.scss"
import { TEXT_CONSTANTS } from "../../../../../utils/constants"

class AdBreak extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isChecked: null,
            selectedTooltipLabel: "Break1"
        }
    }

    setSelectedMarker = (markerName) => {
        this.setState({
            selectedTooltipLabel: markerName
        })
    }

    componentDidMount() {
        let timeFrames = []
        let timeFrame = {}
        const { contentMarkers } = this.props
        let response = {
            adbreak: contentMarkers && contentMarkers?.adbreak
        }
        if (response && response.adbreak) {
            for (let index = 0; index < response?.adbreak.length; index++) {
                timeFrame = {}
                timeFrame.start = Math.round(response.adbreak[index])
                timeFrame.toolTip = `Break${index + 1}`
                timeFrame.type = "ad-break"

                timeFrames.push(timeFrame)
            }
        }
        this.setState({
            timeFrames
        })
    }

    render() {
        let { contentMarkers, src } = this.props
        const { timeFrames, selectedTooltipLabel } = this.state

        return (
            <div className="ad-marker-container">
                <>
                    <div className="marker-left-container">
                        {!isEmpty(timeFrames) && (
                            <p className="start-end-label">
                                <span className="start">
                                    {TEXT_CONSTANTS?.START}
                                    <span className="second-label">
                                        ({TEXT_CONSTANTS?.SECS?.toLowerCase()})
                                    </span>
                                </span>{" "}
                            </p>
                        )}
                        {!isEmpty(timeFrames) ? (
                            timeFrames.map((value, index) => {
                                return (
                                    <p
                                        onClick={() => this.setSelectedMarker(value.toolTip)}
                                        className={`marker-type ${
                                            value.toolTip === selectedTooltipLabel ? "active" : ""
                                        }`}
                                    >
                                        <span className="field1"> {`Break${index + 1}`}</span>
                                        <span className="field2"> {value.start}</span>
                                    </p>
                                )
                            })
                        ) : (
                            <h3 className="no-data-available">{TEXT_CONSTANTS?.NO_DATA}!</h3>
                        )}
                    </div>
                    <div className="marker-right-container">
                        {(!isEmpty(timeFrames) || isEmpty(contentMarkers?.adbreak)) && (
                            <VideoPlayerJS
                                timeFrames={timeFrames}
                                selectedTooltipLabel={selectedTooltipLabel}
                                src={src}
                            />
                        )}
                    </div>
                </>
            </div>
        )
    }
}

export default AdBreak
