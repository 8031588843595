import React, { Component } from "react"
import Button from "../../common/Button"
import Input from "../../common/Input"
import moment from "moment"
import NoImageAvailable from "../../../assets/images/no_img_available.png"

import "./style.scss"
import { PERMISSIONS, TEXT_CONSTANTS } from "../../../utils/constants"
import { AuthWrapper } from "../../common/AuthWrapper"
import { getEPGImagePath } from "../../../utils/common"

class ChannelDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            addNewEpg: false
        }
    }

    showMoretoggle = () => {
        this.setState({ isOpen: !this.state.isOpen })
    }

    showMore = () => {
        let { isOpen } = this.state
        let { channelDesc } = this.props
        if (channelDesc === null) {
            channelDesc = ""
        }

        if (isOpen) {
            return channelDesc
        }
        if (channelDesc.length > 187) {
            return channelDesc.slice(0, 187) + "..."
        } else {
            return channelDesc.slice(0, 187)
        }
    }

    getCommaSeperatedList = (data) => {
        let list = []
        data && data.map((item) => list.push(item.name))
        return list.join(", ")
    }

    render() {
        const { todaysDate, date, epgLiveTVdata, handleAddClick, addShow, handleDateFieldList } =
            this.props
        let beforeDate = moment(todaysDate, "YYYY/MM/DD").isBefore(
            moment(new Date()).format("YYYY/MM/DD")
        )
        return (
            <div className="channelDetails">
                <div className="showHeader">
                    <span>{TEXT_CONSTANTS?.TV_SHOWS}</span>
                    <Input
                        className={addShow ? "activeDate" : "inactiveDate"}
                        type="date"
                        changeHandler={(name, value) => {
                            handleDateFieldList(name, value)
                        }}
                        name={`date`}
                        disabled={!addShow}
                        value={todaysDate}
                    />
                </div>
                <div className="channelImg">
                    {!addShow ? (
                        <img src={`${NoImageAvailable}`} alt="" />
                    ) : (
                        <img src={` ${getEPGImagePath(epgLiveTVdata?.image)}`} alt="" />
                    )}
                </div>
                <div className="details-wrapper">
                    {!addShow ? "" : <h4>{epgLiveTVdata.name}</h4>}
                </div>
                {
                    <AuthWrapper permissions={[PERMISSIONS?.EPG_EDIT]}>
                        <Button
                            clickHandler={() => handleAddClick(epgLiveTVdata, date)}
                            cName="btn primary-btn"
                            bValue={TEXT_CONSTANTS?.ADD_SHOW?.toUpperCase()}
                            disabled={beforeDate ? true : false}
                        />
                    </AuthWrapper>
                }
            </div>
        )
    }
}

export default ChannelDetails
