import { decryptData } from "../../../utils/EncryptDecrypt"
import { ACTION } from "./constant"

let initialState = {}

const getDefaultState = () => {
    const authUserData = localStorage.getItem("auth")
    if (authUserData) {
        const res = decryptData(authUserData, process.env.REACT_APP_LOCAL_STORAGE_SECRETE_KEY)
        return res?.data || {}
    }
    return initialState
}

export default function loginReducer(state = getDefaultState(), action) {
    switch (action.type) {
        case ACTION.LOGIN:
            if (action.apiResponse.data) {
                return { ...state, ...action.apiResponse.data }
            }
            return { ...state, ...action.apiResponse }

        case ACTION.LOGOUT:
            return { ...initialState }

        case ACTION.CLEAR_LOGIN_STATE:
            return { ...initialState }

        case ACTION.ADDITIONAL_DETAILS:
            return {
                ...initialState,
                additionalDetailsResponse: action.apiResponse.data
            }

        case ACTION.FORGOT_PASSWORD:
            return { ...state, secretLink: action.apiResponse.data }

        case ACTION.RESET_PASSWORD:
            return { ...state, ...action.apiResponse.data }

        default:
            return state
    }
}
