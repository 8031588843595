import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import get from "lodash/get"
import { toast } from "react-toastify"

import Button from "../../common/Button"
import Input from "../../common/Input"
import { uploadImage, deleteImage, updateUserDetails, viewProfile } from "../api/action"
import NoImgAvailable from "../../../assets/images/no_image.jpg"

import "./style.scss"
import { imagePathPrefix } from "../../../config/apiPath"
import { BUTTON_CONSTANTS, ERROR_MESSAGES, TEXT_CONSTANTS } from "../../../utils/constants"
import Loader from "../../common/Loader"

class UserProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            email: "",
            roles: "",
            image: {
                id: null,
                path: ""
            },
            isLoading: true,
            isSubmitLoading: false,
            highResImageLoaded: false
        }
    }

    componentDidMount() {
        this.loadhandler()
    }

    loadhandler = async () => {
        const response = await this.props.viewProfile()
        if (response && response.status) {
            const { name, email, roles, profilePicture } = response.data
            let userRoles = []
            roles.forEach((item) => {
                userRoles.push(item.name)
            })
            let profileImg = profilePicture
                ? { id: profilePicture.id, path: profilePicture.path }
                : this.state.image
            this.setState({
                name,
                email,
                roles: userRoles.join(","),
                image: profileImg,
                isLoading: false
            })
        }
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    fileUpload = async (e) => {
        const { files } = e.target
        let data = new FormData()
        data.append("image", files[0])

        const response = await this.props.uploadImage(data, "USER_PROFILE_PICTURE")
        if (response && response.status) {
            this.setState({
                image: response.data
            })
        }
    }

    handleUpdateProfile = async () => {
        const userDetail = await this.props.viewProfile()
        const { name, image } = this.state
        let payload = { ...userDetail.data }
        payload.name = name
        payload.profilePicture = { id: image.id }
        this.setState({
            isSubmitLoading: true
        })
        const response = await this.props.updateUserDetails(payload)
        this.setState({
            isSubmitLoading: false
        })
        if (response && response.status) {
            toast.success(ERROR_MESSAGES.PROFILE_UPDATED_SUCCESS)
            this.loadhandler()
        } else {
            toast.error(ERROR_MESSAGES.PROFILE_UPDATE_FAILURE)
        }
    }

    myProfileLoader = () => {
        return (
            <>
                <div className="profileLoader">
                    <div className="img-loader"></div>
                    <div className="profile-rt-loader">
                        <span className="profilebarLoader title-loader"></span>
                        <span className="profilebarLoader"></span>
                    </div>
                </div>
                <div className="userDetail-loader">
                    <span className="profilebarLoader"></span>
                    <span className="profilebarLoader"></span>
                    <span className="profilebarLoader"></span>
                    <span className="profilebarLoader btnLoader"></span>
                </div>
            </>
        )
    }

    render() {
        // const { auth } = this.props;
        const { name, email, roles, image, isLoading, isSubmitLoading, highResImageLoaded } =
            this.state
        return (
            <div className="userProfile innerContainer">
                {isSubmitLoading && (
                    <div className="submit-loader">
                        {" "}
                        <Loader />{" "}
                    </div>
                )}
                {isLoading ? (
                    this.myProfileLoader()
                ) : (
                    <>
                        <div className="profile">
                            <img
                                onLoad={() => {
                                    this.setState({ highResImageLoaded: true })
                                }}
                                className="profileImg"
                                src={`${imagePathPrefix}${image.path}`}
                                alt=""
                                onError={(e) => {
                                    e.target.onerror = null
                                    e.target.src = `${NoImgAvailable}`
                                }}
                            />
                            <img
                                {...(highResImageLoaded && { style: { opacity: "0" } })}
                                alt=""
                                className="profileImg overlayStyles"
                                src=""
                            />
                            <div className="profile-rt">
                                <span>{TEXT_CONSTANTS?.YOUR_AVTAR}</span>
                                <label className="profileBtn" htmlFor="imgUpload">
                                    {BUTTON_CONSTANTS?.UPDATE?.toUpperCase()}
                                    <input
                                        type="file"
                                        id="imgUpload"
                                        name="image"
                                        onChange={this.fileUpload}
                                        accept="image/png, image/jpeg"
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="userDetail">
                            <Input
                                type="text"
                                name="name"
                                id="userName"
                                labelText="NAME"
                                value={name}
                                changeHandler={this.handleChange}
                            />
                            <Input
                                type="email"
                                name="email"
                                id="userEmail"
                                labelText="EMAIL"
                                value={email}
                                disabled={true}
                                cName={"textDisable"}
                            />
                            <Input
                                type="text"
                                name="roles"
                                id="userRoles"
                                labelText="ROLES"
                                value={roles}
                                disabled={true}
                                cName={"textDisable"}
                            />
                            <div className="actionBtn">
                                <Button
                                    cName="btn primary-btn submitBtn"
                                    bValue={BUTTON_CONSTANTS?.UPDATE?.toUpperCase()}
                                    clickHandler={this.handleUpdateProfile}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userProfile: get(state.userProfile, "userDetails", [])
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                uploadImage,
                deleteImage,
                updateUserDetails,
                viewProfile
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile)
// export default UserProfile
