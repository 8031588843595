import ApiService from "../../../utils/apiService"
import { API_TENANT_ROOT_PATH } from "../../../config/apiPath"

class MagnaQuestService {
    getPlansList(data) {
        const options = {
            method: "GET",
            url: `${API_TENANT_ROOT_PATH}/subscriber-gateway/subscription/getPlans`,
            isAuthRequired: true,
            data: data
        }
        return ApiService(options)
    }
    getCouponsList(data) {
        const options = {
            method: "GET",
            url: `${API_TENANT_ROOT_PATH}/subscriber-gateway/coupon/getAllCoupons`,
            isAuthRequired: true,
            data: data
        }
        return ApiService(options)
    }
}

const MagnaQuestInstance = new MagnaQuestService()
export default MagnaQuestInstance
