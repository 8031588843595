import React, { useCallback, useEffect, useState } from "react"
import { TEXT_CONSTANTS } from "../../utils/constants"
import CustomModal from "../common/Modal"
import CustomRadioButton from "../common/customRadioBtn"
import Select1 from "../common/SelectBox"
import { options } from "../ContentNav/api/constants"
import Pagination from "../common/Pagination"
import "./style.scss"
import DateCalendar from "../ContentPlan/DateCalendar"
import Button from "../common/Button"
import { useDispatch } from "react-redux"
import { fetchDetails, getAuditDropdowns, searchAuditList } from "./api/action"
import moment from "moment-timezone"
import { isEmpty } from "lodash"
import { toast } from "react-toastify"

const DEFAULT_DROPDOWN_STATE = {
    name: "ALL",
    id: "ALL"
}

const USER_MANAGEMENT_EVENTS = ["ALL", "CREATE", "UPDATE", "DELETE"]

const AUDIT_TRAIL_GROUP = [
    {
        id: "OTHERS",
        name: "Other"
    },
    {
        id: "CONTENT_MANAGER",
        name: "Media Manager Tracker"
    }
]

const AuditTrail = () => {
    const dispatch = useDispatch()

    const [openModal, setOpenModal] = useState(false)
    const [toggle, setToggle] = useState(true)

    const [userId, setUserId] = useState(DEFAULT_DROPDOWN_STATE)
    const [userIDList, setUserIDList] = useState([])

    const [eventType, setEventType] = useState(DEFAULT_DROPDOWN_STATE)
    const [eventTypeList, setEventTypeList] = useState([])

    const [auditType, setAuditType] = useState(AUDIT_TRAIL_GROUP[0].id)
    const [data, setData] = useState({})

    const [subEventType, setSubEventType] = useState(DEFAULT_DROPDOWN_STATE)
    const [userAuditInfo, setUserAuditInfo] = useState({})
    const [active, setActive] = useState(USER_MANAGEMENT_EVENTS[0])

    const [date, setDate] = useState({
        startDate: null,
        endDate: null
    })

    const [currentPage, setCurrentPage] = useState(0)
    const [selectedPage, setSelectedPage] = useState(options[0])

    const handleOpenModal = useCallback(
        async (id) => {
            let details = id ? await dispatch(fetchDetails(id)) : {}
            if (!isEmpty(details)) {
                const date = new Date(parseInt(details?.eventDate))
                details.eventDate = `${date.toLocaleDateString()} | ${date.toLocaleTimeString()}`
                try {
                    details.newData = JSON.parse(details.newData)
                    details.oldData = JSON.parse(details.oldData)
                } catch (e) {
                    console.log("Invalid JSON found in response. ", e)
                }
            }
            setUserAuditInfo(details)
            setOpenModal(!openModal)
            setToggle(true)
        },
        [openModal]
    )

    const handleRadioChange = useCallback(
        (event) => {
            setAuditType(event.target.value)
            setEventType(DEFAULT_DROPDOWN_STATE)
            setUserId(DEFAULT_DROPDOWN_STATE)
            setDate({
                endDate: "",
                startDate: ""
            })
            currentPage && setCurrentPage(0)
        },
        [currentPage]
    )

    const toggleData = useCallback((value) => {
        setToggle(value)
    }, [])

    const changeHandler = useCallback(
        (name, value, options) => {
            if (name === "user") setUserId(options)
            else setEventType(options)
            currentPage && setCurrentPage(0)
        },
        [currentPage]
    )

    const fetchAuditTrailList = async (type) => {
        const payload = {
            startDate: date.startDate,
            endDate: date.endDate,
            eventName: eventType.id,
            groupType: auditType,
            pageNumber: currentPage + 1,
            size: selectedPage.id,
            eventType: type || subEventType.id,
            userId: userId.id
        }
        const response = await dispatch(searchAuditList(payload))
        setData(response)
    }

    const handleDate = useCallback(
        (name, value) => {
            setDate(value)
            currentPage && setCurrentPage(0)
        },
        [currentPage]
    )

    const getEventDropdownList = useCallback(async () => {
        try {
            let userIDList = await dispatch(getAuditDropdowns("USER_ID"))
            userIDList = userIDList?.map((item) => ({ id: item, name: item }))
            setUserIDList(userIDList)
    
            let eventTypeList = await dispatch(getAuditDropdowns("EVENT_TYPE"))
            eventTypeList = eventTypeList?.map((item) => ({ id: item, name: item }))
            setEventTypeList(eventTypeList)
        } catch(err) {
            console.log('Error in getEventDropdownList', err)
        }
    }, [])

    const handleTag = (tag) => {
        setActive(tag)
        fetchAuditTrailList(tag)
    }

    const onPageChange = useCallback((e) => {
        const selectedPage = e.selected
        setCurrentPage(selectedPage)
    }, [])

    const showPerPageChange = useCallback((name, value) => {
        const defaultOption = options.filter((item) => {
            if (value === item.id) {
                return item
            }
            return false
        })
        setSelectedPage(defaultOption[0])
        setCurrentPage(0)
    }, [])

    const copyText = useCallback(() => {
        navigator.clipboard
            .writeText(JSON.stringify(toggle ? userAuditInfo.newData : userAuditInfo.oldData))
            .then(() => {
                toast.success("Text copied to clipboard.")
            })
            .catch(() => {
                toast.error("Something went wrong")
            })
    }, [toggle, userAuditInfo])

    useEffect(() => {
        getEventDropdownList()
    }, [])

    useEffect(() => {
        fetchAuditTrailList()
    }, [eventType, userId, currentPage, selectedPage, date])

    return (
        <div className="basic-container">
            {/* TODO: Replace below with the reusable pageTitleComponent */}
            <div className="vr-cont-head mb">
                <div className="vr-cont-title">
                    <h2 className="breadcrum-header">{TEXT_CONSTANTS.AUDIT_TRAIL}</h2>
                </div>
            </div>
            <div className="vr-content-holder ">
                <div className="cta-audit">
                    <CustomRadioButton
                        name={"auditType"}
                        handleRadioChange={handleRadioChange}
                        options={AUDIT_TRAIL_GROUP}
                        cRadioBtn={""}
                        value={auditType}
                    />
                    <div className="cta-dropdowns">
                        {auditType === AUDIT_TRAIL_GROUP[0].id ? (
                            <>
                                <Select1
                                    placeholder="Select"
                                    cName={"event"}
                                    options={eventTypeList}
                                    label={"Event Name"}
                                    required={true}
                                    name={"eventType"}
                                    changeHandler={changeHandler}
                                    value={eventType}
                                />
                                <Select1
                                    placeholder="Select"
                                    cName={"user"}
                                    options={userIDList}
                                    label={"User Type"}
                                    required={true}
                                    name={"user"}
                                    changeHandler={changeHandler}
                                    value={userId}
                                />
                            </>
                        ) : (
                            <></>
                        )}
                        <div className="select-box date-range">
                            <label className="selectBox">Date Range ( From - To )</label>
                            <DateCalendar
                                startDate={date?.startDate}
                                endDate={date?.endDate}
                                dateSubmitHandler={handleDate}
                                name={"Date"}
                                direction="vertical"
                                staticRanges={["", ""]}
                            />
                        </div>
                    </div>
                </div>
                {eventType.id === "USER_MANAGEMENT" && (
                    <div className="tags">
                        <p>User Management :</p>
                        {USER_MANAGEMENT_EVENTS.map((item) => {
                            return (
                                <button
                                    onClick={() => handleTag(item)}
                                    className={active === item ? "active" : ""}
                                >
                                    {item}
                                </button>
                            )
                        })}
                    </div>
                )}
                {data.auditList?.length ? (
                    <table className={`table metadata-ingestion-table`}>
                        <thead>
                            <tr>
                                <th>Date Created</th>
                                <th>Event Name</th>
                                <th>Event Type</th>
                                <th>User</th>
                                <th>Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.auditList.map((item) => {
                                const date = new Date(parseInt(item.eventDate))
                                return (
                                    <tr>
                                        <td>{`${date.toLocaleDateString()} | ${date.toLocaleTimeString()}`}</td>
                                        <td>{item.eventName}</td>
                                        <td>{item.eventType}</td>
                                        <td>{item.userId}</td>
                                        <td>
                                            <button
                                                onClick={() => handleOpenModal(item.recordId)}
                                                className="view-details"
                                            >
                                                View Details
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                ) : (
                    <div className="no-data">No Data Available</div>
                )}
                {openModal && (
                    <CustomModal
                        showModal={openModal}
                        cancelHandler={handleOpenModal}
                        showCancelButton={false}
                        showSubmitButton={false}
                        showCloseIcon={true}
                        showFooter={false}
                    >
                        <div className="view-details-modal">
                            <ul>
                                <li>
                                    <span className="title">Property:</span>
                                    <span className="value">Data</span>
                                </li>
                                <li>
                                    <span className="title">Created on:</span>
                                    <span className="value">{userAuditInfo?.eventDate}</span>
                                </li>
                                <li>
                                    <span className="title">Event Type:</span>
                                    <span className="value">{userAuditInfo.eventType}</span>
                                </li>
                                <li>
                                    <span className="title">Entity ID:</span>
                                    <span className="value">{userAuditInfo.eventId}</span>
                                </li>
                                <li>
                                    <span className="title">User:</span>
                                    <span className="value">{userAuditInfo.userId}</span>
                                </li>
                            </ul>
                        </div>
                        <div className="detail-response">
                            <Button
                                clickHandler={() => toggleData(true)}
                                bValue="New Data"
                                cName={`cta ${toggle}`}
                            />
                            <Button
                                clickHandler={() => toggleData(false)}
                                bValue="Old Data"
                                cName={`cta ${!toggle}`}
                            />

                            <pre className="map-data">
                                {JSON.stringify(
                                    toggle ? userAuditInfo.newData : userAuditInfo.oldData,
                                    null,
                                    4
                                )}
                            </pre>

                            <Button
                                clickHandler={copyText}
                                bValue="Copy"
                                cName="btn-copy icon-duplicate"
                            />
                        </div>
                    </CustomModal>
                )}
            </div>
           
            {data.auditList?.length ? (
                <Pagination
                    options={options}
                    pageCount={Math.ceil(data?.size / selectedPage.id)}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={5}
                    selectedOption={selectedPage}
                    onPageChange={onPageChange}
                    changeHandler={showPerPageChange}
                    currentPage={currentPage}
                    isAutoFocus={false}
                />
            ) : (
                <></>
            )}
        </div>
    )
}

export default AuditTrail
