export const ACTION = {
    GET_ALL_FOOTER_ITEMS: "GET_ALL_FOOTER_ITEMS",
    CREATE_FOOTER_ITEM: "CREATE_FOOTER_ITEM",
    DELETE_FOOTER_ITEM: "DELETE_FOOTER_ITEM",
    PUBLISH_FOOTER_ITEM: "PUBLISH_FOOTER_ITEM",
    UPDATE_FOOTER_ITEM: "UPDATE_FOOTER_ITEM",
    BULK_UPDATE_FOOTER: "BULK_UPDATE_FOOTER"
}

export const SOCIAL_LINKS = [
    { name: "FACEBOOK", id: 1 },
    { name: "INSTAGRAM", id: 2 },
    { name: "TWITTER", id: 3 },
    { name: "SNAPCHAT", id: 4 },
    { name: "LINKED IN", id: 5 },
    { name: "YOUTUBE", id: 6 },
    { name: "TIKTOK", id: 7 }
]

export const APP_LINKS = [
    { name: "PLAY STORE", id: 1 },
    { name: "APP STORE", id: 2 },
    { name: "APP GALLERY", id: 3 }
]

export const LINK_TYPES = [
    { name: "INTERNAL", id: 0 },
    { name: "EXTERNAL", id: 1 }
]

export const FOOTER_TABS_TITLE = {
    LINK: "LINK",
    SOCIAL: "SOCIAL MEDIA",
    APPS: "APPS"
}

export const FOOTER_BASE_URL = "/editorial/v1/footers"

export const FOOTER_TABS = [
    {
        name: FOOTER_TABS_TITLE.LINK,
        id: 1
    },
    {
        name: FOOTER_TABS_TITLE.SOCIAL,
        id: 2
    },
    {
        name: FOOTER_TABS_TITLE.APPS,
        id: 3
    }
]
