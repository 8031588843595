import LoginServiceInstance from "./service"
import { ACTION } from "./constant"
import { removeUserCookie, saveUserCookie } from "../../../utils/common"

export const userLogin = (params) => {
    return (dispatch) => {
        return LoginServiceInstance.login(params)
            .then(async (response) => {
                await saveUserCookie(response.data, "/")
                dispatch({
                    type: ACTION.LOGIN,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got Error with login " + error.message)
                return error
            })
    }
}

export const logoutUser = (isReloadRequired = true) => {
    removeUserCookie()
    // removeLocalStorage();
    // isReloadRequired && window.location.reload();
    return { type: ACTION.LOGOUT }
}

export const removeLocalStorage = () => {
    sessionStorage.removeItem("tenantInfo")
}

export function clearLoginState() {
    return { type: ACTION.CLEAR_LOGIN_STATE }
}

export const forgotPassword = (params) => {
    return (dispatch) => {
        return LoginServiceInstance.forgotPassword(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FORGOT_PASSWORD,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got Error with user forgot password: " + error)
                return error
            })
    }
}

export const resetPassword = (params) => {
    return (dispatch) => {
        return LoginServiceInstance.resetPassword(params)
            .then((response) => {
                dispatch({
                    type: ACTION.RESET_PASSWORD,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got Error with user reset password: " + error)
            })
    }
}

export const validateToken = (params) => {
    return (dispatch) => {
        return LoginServiceInstance.validateToken(params)
            .then((response) => {
                dispatch({
                    type: ACTION.VALIDATE_TOKEN,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got Error with user reset password: " + error)
            })
    }
}

export const extendTrail = async (params) => {
    return LoginServiceInstance.extendTrail(params)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            console.log("Got Error with user extend trail: " + error)
        })
}

export const sendLogoutToken = () => {
    // vapt logout api implementation requirement
    return (dispatch) => {
        return LoginServiceInstance.sendLogoutToken()
            .then((response) => {
                // console.log('response===', response);
            })
            .catch((error) => {
                console.log("Got Error with sendLogoutToken: " + error)
            })
    }
}
