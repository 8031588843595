import { ACTION } from "./constant"
import PipelineServiceInstance from "./service"
import { base64DecodeUint8Array } from "../../../utils/common"

export const initializeMultipartUpload = (params, callback, context) => {
    return PipelineServiceInstance.initializeMultipartUpload(params)
        .then((response) => {
            callback(response.data, context)
            //  return response.data;
        })
        .catch((error) => {
            console.log("Got error in initialize multipart", error)
            callback(error)
        })
}

export const getMultipartPreSignedUrls = (params, callback, context) => {
    return PipelineServiceInstance.getMultipartPreSignedUrls(params)
        .then((response) => {
            callback(response.data, context)
        })
        .catch((error) => {
            callback(error)
            console.log("Got error in getMultipartPreSignedUrls ", error)
        })
}

export const complete = (params, callback, context) => {
    return PipelineServiceInstance.complete(params)
        .then((response) => {
            callback(response.data, context)
        })
        .catch((error) => {
            callback(error)
            console.log("Got error in complete", error)
        })
}

export const abort = (params, callback, index) => {
    return PipelineServiceInstance.abort(params)
        .then((response) => {
            callback && callback(response, index)
            return response
        })
        .catch((error) => {
            callback(error)
            console.log("Got error in abort", error)
        })
}

export const saveCustomProfile = (params) => {
    return (dispatch) => {
        return PipelineServiceInstance.saveCustomProfile(params)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in custom profile", error)
                return error
            })
    }
}

export const saveChooseProtocol = (params) => {
    return (dispatch) => {
        return PipelineServiceInstance.saveChooseProtocol(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_TENANT,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in choose protocol", error)
                return error
            })
    }
}

export const getUploadedContentList = (params) => {
    return (dispatch) => {
        return PipelineServiceInstance.getUploadedContent(params)
            .then((response) => {
                dispatch({
                    type: ACTION.INITIALIZE_MULTIPART,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in get Uploaded Content List ", error)
            })
    }
}

export const getTranscodedContentList = (params) => {
    return (dispatch) => {
        return PipelineServiceInstance.getTranscodedContent(params)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in get Transcoded Content List ", error)
            })
    }
    // return {
    //   status: true,
    //   data:[{
    // 	"jobId": "1",
    // 	"fileName": "sample1.mp4",
    // 	"uploadedDate": "01-01-2023",
    // 	"totalSize": "456 bytes",
    // 	"duration": "1:30",
    // 	"status": "MEDIA_CONVERT_JOB_IN_PROGRESS_EVENT",
    // 	"statusForUI": "Received",
    // 	"progressPercentage": 70,
    // 	"isDRMEnabled": false,
    // 	"playURL": "https://example.com/sample1.mpd"
    // },]}
}

export const deleteUploadedContent = (id) => {
    return (dispatch) => {
        return PipelineServiceInstance.deleteUploadedContent(id)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in get Transcoded Content List ", error)
                return error
            })
    }
}

export const deleteTranscodedContent = (id) => {
    return (dispatch) => {
        return PipelineServiceInstance.deleteTranscodedContent(id)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in get deleting Transcoded Content List ", error)
                return error
            })
    }
}

export const startTranscoding = (params, callback) => {
    return (dispatch) => {
        return PipelineServiceInstance.startTranscoding(params, callback)
            .then((response) => {
                callback(response?.data)
            })
            .catch((error) => {
                console.log("Got error in transcoding ", error)
                callback(error)
            })
    }
}

export const cancelTranscoding = (id) => {
    return (dispatch) => {
        return PipelineServiceInstance.cancelTranscodingContent(id)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in get Cancel Transcoded Content List ", error)
                return error
            })
    }
}

export const getStatusFilterData = () => {
    return (dispatch) => {
        return PipelineServiceInstance.fitlerData()
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getStatusFilterData ", error)
            })
    }
}

export const getBuyDrmToken = (params) => {
    return (dispatch) => {
        return PipelineServiceInstance.getDRMToken(params)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getBuyDrmToken ", error)
            })
    }
}

export const getPipelineSettings = (params) => {
    return (dispatch) => {
        return PipelineServiceInstance.getPipelineSettings(params)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getPipelineSettings ", error)
            })
    }
}

export const fetchLicenseDetails = (serverLicense, body, headers, cb) => {
    // return (dispatch) => {
    //   return PipelineServiceInstance.fetchLicenseDetails(
    //     serverLicense,
    //     body,
    //     headers
    //   )
    //     .then((response) => {
    //       cb(null, base64DecodeUint8Array(response));
    //     })
    //     .catch((error) => {
    //       console.log("Got error in fetchLicenseDetails ", error);
    //     });
    // };

    fetch(serverLicense, {
        method: "POST",
        body,
        headers: {
            "Content-type": "application/x-www-form-urlencoded",
            ...headers
        }
    })
        .then((res) => {
            return res.text()
        })
        .then((res) => {
            cb(null, base64DecodeUint8Array(res))
        })
        .catch((err) => {})
}
