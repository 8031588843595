import ApiService from "../../../utils/apiService"
import { API_ROOT_PATH } from "../../../config/apiPath"

class MenuService {
    fetchMenuOptions(id) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/editorial/v1/menu/menu-types`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
    fetchPlatformList(id) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/core/user-platform/`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
    createMenu(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/editorial/v1/menu/bulk`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }
    fetchMenuList(id) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/editorial/v1/menu/platformId?platformId=${id}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
    deleteMenu(id) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/core/menu/delete-menu?menuId=${id}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
    uploadImage(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/core/document/upload`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }
    deleteTab(id) {
        const options = {
            method: "DELETE",
            url: `${API_ROOT_PATH}/editorial/v1/menu/${id}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
}
const MenuServiceInstance = new MenuService()
export default MenuServiceInstance
