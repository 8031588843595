import { toast } from "react-toastify"
import { removeLoader, setLoader } from "../../common/Loader/api/action"
import { ACTION } from "./constant"
import ContentServiceInstance from "./service"
import { ERROR_MESSAGES } from "../../../utils/constants"

//action to fetch form element
export const fetchFormElement = (params) => {
    return (dispatch) => {
        return ContentServiceInstance.fetchFormElement(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_FORM_ELEMENT,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting form json ", error)
            })
    }
}

//action to create content
export const createContent = (params, cb) => {
    return (dispatch) => {
        return ContentServiceInstance.createContent(params)
            .then((response) => {
                dispatch({
                    type: ACTION.CREATE_CONTENT,
                    apiResponse: response.data
                })
                if (cb) {
                    cb(response.data)
                }
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting form json ", error)
            })
    }
}

// export const updateContent = (params) => {
//   return dispatch => {
//     return ContentServiceInstance.updateContent(params).then((response)=>{
//         dispatch({
//             type: ACTION.UPDATE_CONTENT,
//             apiResponse: response.data,
//         });
//         return response.data;
//     }).catch((error) => {
//         console.log("Got error in getting form json ", error);
//     });
//   }
// }

//action to fetch content data
export const fetchContent = (params) => {
    console.log(params)
    return (dispatch) => {
        return ContentServiceInstance.fetchContent(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_CONTENT,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting form json ", error)
            })
    }
}

export const fetchEntityValueMasterData = () => {
    return (dispatch) => {
        return ContentServiceInstance.fetchEntityValueMasterData()
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_ENTITY_VALUE_MASTER_DATA,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting form json ", error)
            })
    }
}

export const fetchContentListing = (params) => {
    return (dispatch) => {
        return ContentServiceInstance.fetchContentListing(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_CONTENT_LISTING,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting form json ", error)
            })
    }
}

export const uploadImage = (params) => {
    return (dispatch) => {
        dispatch(setLoader())
        return ContentServiceInstance.uploadImage(params)
            .then((response) => {
                dispatch({
                    type: ACTION.UPLOAD_IMAGE,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                toast.error(error?.message || ERROR_MESSAGES?.IMAGE_UPLOAD_FAILED)
                console.log("Got error in upload image ", error)
            })
            .finally(() => {
                dispatch(removeLoader())
            })
    }
}

export const deleteImage = (id) => {
    return (dispatch) => {
        return ContentServiceInstance.deleteImage(id)
            .then((response) => {
                dispatch({
                    type: ACTION.DELETE_IMAGE,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in upload image ", error)
            })
    }
}

export const deleteContent = (ids) => {
    return (dispatch) => {
        return ContentServiceInstance.deleteContent(ids)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in delete content", error)
            })
    }
}

export const fetchTaggedContentInRail = (ids) => {
    return (dispatch) => {
        return ContentServiceInstance.fetchTaggedContentInRail(ids)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in delete content", error)
            })
    }
}

export const togglePublish = (ids) => {
    return (dispatch) => {
        return ContentServiceInstance.togglePublish(ids)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in delete content", error)
            })
    }
}
