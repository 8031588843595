export const CONTENTENRICHMENT_TABLE_HEADING = [
    { header: "Sr", accessor: "sr", type: "text" },
    { header: "name", accessor: "name", type: "text" },
    { header: "movies", accessor: "movies", type: "text" },
    { header: "episodes", accessor: "episodes", type: "text" },
    { header: "action", accessor: "action", type: "text" }
]

export const CONTENTENRICHMENT_MODAL_TABLE_HEADING = [
    { header: "Sr", accessor: "sr", type: "text" },
    { header: "image", accessor: "image", type: "image" },
    { header: "name", accessor: "name", type: "text" },
    { header: "date", accessor: "date", type: "text" }
]

export const CONTENTENRICHMENT_MODAL_TABLE_DATA = [
    {
        contentId: "Episode_005",
        contentType: "EPISODE",
        contentImage: "VOD/lionsgate/webseries/It'sASin_16x9.jpg",
        contentTitle: "It's A Sin S1 E1",
        contentIngestionDate: 1676270950063
    },
    {
        contentId: "Episode_005",
        contentType: "EPISODE",
        contentImage: "VOD/lionsgate/webseries/It'sASin_16x9.jpg",
        contentTitle: "It's A Sin S1 E1",
        contentIngestionDate: 1676270950063
    }
]

export const PaginationOptions = [
    { id: 10, name: "10" },
    { id: 20, name: "20" },
    { id: 30, name: "30" },
    { id: 40, name: "40" },
    { id: 50, name: "50" }
]

export const ModalTabList = [
    {
        id: "MOVIES",
        name: "Movies"
    },
    {
        id: "EPISODE",
        name: "Episodes"
    }
]

export const imgPathPrefix = "https://d341j4pc22502j.cloudfront.net/"
//   export const imagePathPrefix = "https://d341j4pc22502j.cloudfront.net/";
