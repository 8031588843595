import React, { Component } from "react"

import SideMenu from "./sideMenu"
import PreviewCanvas from "./preview"
import Options from "../options"
import "./style.scss"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import { checkIfUserHasAccess } from "../../../utils/users"
import { PERMISSIONS } from "../../../utils/constants"

class BuilderCanvas extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedElement: {},
            selectedIndex: null,
            isChild: null,
            actionButton: false
        }
        this.canUpdateStatus = checkIfUserHasAccess([PERMISSIONS.SETTING_FORM_BUILDER_EDIT])
    }

    getId = () => {
        return Math.random().toString(36).substring(2) + Date.now().toString(36)
    }

    reorder = (startIndex, endIndex) => {
        const result = [...this.props.elementsArr]
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)
        return result
    }
    getData = (draggableId) => {
        const arrIndex = parseInt(draggableId.split("")[1])
        const fieldIndex = parseInt(draggableId.split("")[0])
        return Options[arrIndex] && Options[arrIndex].fields[fieldIndex - 1]
    }
    addChildren = (combine, source) => {
        const { draggableId } = combine

        const { elementsArr } = this.props
        if (
            (typeof draggableId === "string" && draggableId.includes("group")) ||
            isNaN(source.index)
        ) {
            const data = { ...this.getData(`${source.index}`) }
            const id = draggableId.split(" ")[1]
            elementsArr.map((ele, i) => {
                ele.elementsArr &&
                    ele.elementsArr.length &&
                    ele.elementsArr.map((d, j) => {
                        if (d.id === id) {
                            if (!elementsArr[i].elementsArr[j].children) {
                                elementsArr[i].elementsArr[j].children = []
                            }
                            if (
                                (elementsArr[i].elementsArr[j].size === "M" &&
                                    elementsArr[i].elementsArr[j].children.length < 2) ||
                                (elementsArr[i].elementsArr[j].size === "S" &&
                                    elementsArr[i].elementsArr[j].children.length < 3)
                            ) {
                                if (data) {
                                    data.title = "Title"
                                    data.id = `${this.getId()}`
                                }
                                elementsArr[i].elementsArr[j].children.push(data)
                                this.props.setElementArray(elementsArr)
                            }
                        }
                        return d
                    })
                return ele
            })
        } else {
            const index = elementsArr.findIndex((i) => i.id === draggableId)
            const data = { ...this.getData(`${source.index}`) }
            if (data) {
                if (elementsArr[index] && elementsArr[index].isGroup) {
                    data.title = "Title"
                    data.id = `${this.getId()}`
                    data.isGroupElement = true
                    data.parentId = draggableId
                    elementsArr[index].elementsArr.push(data)
                    this.props.setElementArray(elementsArr)
                } else if (elementsArr[index] && elementsArr[index].size) {
                    if (!elementsArr[index].children) {
                        elementsArr[index].children = []
                    }
                    if (
                        (elementsArr[index].size === "M" && elementsArr[index].children < 2) ||
                        (elementsArr[index].size === "S" && elementsArr[index].children < 3)
                    ) {
                        data.title = "Title"
                        data.id = `${this.getId()}`
                        elementsArr[index].children.push(data)
                        this.props.setElementArray(elementsArr)
                    }
                }
            }
        }
    }
    addStepper = (draggableId, source, destinationIndex) => {
        const elementsArr = [...this.props.elementsArr]
        const data = { ...this.getData(`${source.index}`) }
        if (data.htmlElement && data.htmlElement === "group") {
            data.elementsArr = []
            data.groupTitle = "Group Title"
            data.isGroup = true
            data.id = `${this.getId()}`
        } else {
            data.title = "Title"
            data.id = `${this.getId()}`
        }
        data.stepperId = elementsArr[parseInt(destinationIndex)].id
        elementsArr.push(data)
        this.props.setElementArray(elementsArr)
    }

    onDragEnd = (result) => {
        const { source, destination, draggableId, combine } = result
        if (combine) {
            this.addChildren(combine, source)
        } else {
            if (
                source &&
                source.droppableId === "droppable1" &&
                source.droppableId === destination.droppableId
            ) {
                const items = this.reorder(source.index, destination.index)
                this.props.setElementArray(items)
            } else if (
                source &&
                source.droppableId === "droppable" &&
                destination &&
                destination.droppableId === "droppable1" &&
                !draggableId.includes("list")
            ) {
                const index =
                    typeof destination.index === "string"
                        ? parseInt(destination.index[destination.index.length - 1])
                        : destination.index
                if (
                    destination &&
                    destination.index !== "" &&
                    this.props.elementsArr[index] &&
                    this.props.elementsArr[index].isStepper
                ) {
                    this.addStepper(parseInt(draggableId), source, index)
                } else {
                    const elementsArr = [...this.props.elementsArr]
                    const data = { ...this.getData(`${source.index}`) }
                    if (data.htmlElement && data.htmlElement === "group") {
                        data.elementsArr = []
                        data.groupTitle = "Group Title"
                        data.isGroup = true
                        data.id = `${this.getId()}`
                    } else {
                        data.title = "Title"
                        data.id = `${this.getId()}`
                    }
                    elementsArr.splice(destination.index, 0, data)
                    this.props.setElementArray(elementsArr)
                }
            }
        }
    }
    onClick = (e, item, index, isChild) => {
        e.preventDefault()
        e.stopPropagation()
        if (!this.canUpdateStatus) {
            return
        }
        if (item && item.htmlElement === "button") {
            this.setState({
                actionButton: false
            })
        } else if (item && item.htmlElement !== "button") {
            this.setState({
                actionButton: true
            })
        }
        let i =
            this.props.elementsArr &&
            this.props.elementsArr.length &&
            this.props.elementsArr.findIndex((e) => e.id === item.id)
        if (i === -1) {
            i = 0
        }
        let child = isChild
        this.props.elementsArr &&
            this.props.elementsArr.length &&
            this.props.elementsArr.forEach((e, p) => {
                e.children &&
                    e.children.forEach((j, k) => {
                        if (j.id === item.id) {
                            i = p
                            child = k
                        }
                    })
            })
        if (item && item.isGroupElement && item.htmlElement === "select") {
            child = index
            i =
                this.props.elementsArr &&
                this.props.elementsArr.length &&
                this.props.elementsArr.findIndex((ele) => ele.id === item.parentId)
        }
        this.setState({
            selectedIndex: i || index || 0,
            isChild: child !== "" ? child : "",
            selectedElement: item
        })
    }
    handleBack = () => {
        this.setState({ selectedIndex: null })
        this.setState({ selectedElement: {} })
    }
    deleteElement = (item, index) => {
        let { elementsArr } = this.props
        if (item.isGroup) {
            elementsArr = elementsArr.filter((value, index, arr) => {
                return value.id !== item.id
            })
        }
        if (
            elementsArr[index] &&
            elementsArr[index].children &&
            elementsArr[index].children.length
        ) {
            elementsArr[index].children = elementsArr[index].children.filter((i) => {
                if (i.id !== item.id) {
                    return i
                }
                return false
            })
        }
        elementsArr = elementsArr.filter((i) => {
            if (i.isStepper) {
                if (i.id !== item.id) {
                    return i
                }
            } else if (i.stepperId && i.stepperId !== item.id) {
                return i
            } else if (i.isGroup) {
                return elementsArr.map((ele) => {
                    ele.elementsArr =
                        ele.elementsArr &&
                        ele.elementsArr.filter((j) => {
                            if (j.id !== item.id) {
                                return j
                            }
                            return false
                        })
                    return ele
                })
            }
            if (i.id !== item.id) {
                return i
            }
            return false
        })
        this.setState({
            selectedIndex: null,
            selectedElement: {}
        })
        this.props.setElementArray(elementsArr)
    }
    render() {
        const { selectedElement, selectedIndex, isChild, actionButton } = this.state
        const { handleBack, deleteElement } = this
        const {
            setPayloadName,
            elementsArr,
            setElementArray,
            apiPayload,
            addSearch,
            findChecked,
            handleAddClick
        } = this.props
        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <div className="vd-form-builder-canvas">
                    <div className="preview-canvas">
                        <Droppable droppableId="droppable1" type="app" isCombineEnabled>
                            {(provided) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    style={{ height: "100%" }}
                                >
                                    <PreviewCanvas
                                        elementsArr={elementsArr}
                                        onClick={this.onClick}
                                        selectedElement={selectedElement}
                                        setElementArray={setElementArray}
                                        setPayloadName={setPayloadName}
                                        apiPayload={apiPayload}
                                        handleAddClick={handleAddClick}
                                    />
                                </div>
                            )}
                        </Droppable>
                    </div>
                    <div className="sideMenu">
                        <Droppable droppableId="droppable" type="app" isCombineEnabled>
                            {(provided) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    style={{ height: "100%" }}
                                >
                                    <SideMenu
                                        elementsArr={elementsArr}
                                        selectedElement={selectedElement}
                                        setElementArray={setElementArray}
                                        selectedIndex={selectedIndex}
                                        handleBack={handleBack}
                                        deleteElement={deleteElement}
                                        isChild={isChild}
                                        addSearch={addSearch}
                                        findChecked={findChecked}
                                        actionButton={actionButton}
                                    />
                                </div>
                            )}
                        </Droppable>
                    </div>
                </div>
            </DragDropContext>
        )
    }
}

export default BuilderCanvas
