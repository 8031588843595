import { ACTION } from "./constant"
let initialState = {
    filters: {}
}

export default function categoryRail(state = initialState, action) {
    switch (action.type) {
        case ACTION.FETCH_CONTENT_TYPE:
            const content =
                Array.isArray(action.apiResponse.data) && action.apiResponse.data.includes("VOD")
                    ? action.apiResponse.data.map((item) => ({ id: item, name: item }))
                    : action.apiResponse.data
            return { ...state, contentTypeList: content }
        case ACTION.FETCH_PLATFORM:
            return { ...state, platformList: action.apiResponse.data }
        case ACTION.FETCH_RAIL_TYPE:
            const railType = action.apiResponse.data
                .map((item) => ({
                    id: item.name,
                    name: item.name.replace("_", " "),
                    railCategory: item.railCategory,
                    isdisabled: !item.isEnabled
                }))
                .filter((value, index) => {
                    return (
                        value.id !== "LATEST" &&
                        value.id !== "POPULAR" &&
                        value.id !== "GENRE" &&
                        value.id !== "LANGUAGE" &&
                        value.id !== "PICK_FOR_YOU"
                    )
                })
            return { ...state, railTypeList: railType }
        case ACTION.CONTENT_SEARCH:
            return { ...state, mediaContentList: action.apiResponse }
        case ACTION.FETCH_FILTERS:
            return { ...state, filters: { ...state.filters, ...action.apiResponse } }
        case ACTION.FETCH_ALL_CATEGORY:
            return { ...state, categoryTabList: action.apiResponse.data }
        case ACTION.GET_ALL_ENV:
            return { ...state, envList: action.apiResponse.data }
        default:
            return state
    }
}
