import { getMultipartPreSignedUrls, initializeMultipartUpload, complete } from "../api/action"

// initializing axios
// const api = axios.create({
//   baseURL: "http://localhost:3000",
// });

class FileUploader {
    constructor(options) {
        //  50MB would be 1024k x 1024k x 50.
        //1 megabyte equals 1024 kilobytes, which equals 1024 bytes.

        // this.chunkSize = options.chunkSize || 1024 * 1024 * 5;
        this.chunkSize = options.chunkSize || 1024 * 1024 * 10
        this.file = options.file
        this.fileName = options?.file?.name.replace(/[^a-zA-Z.0-9_-]/g, "")
        this.aborted = false
        this.uploadedSize = 0
        this.progressCache = {}
        this.activeConnections = {}
        this.parts = []
        this.uploadedParts = []
        this.fileId = null
        this.filePath = null
        // this.onProgressFn = () => {};
        this.handleProgress = this.handleProgress.bind(this)
        this.sendChunk = this.sendChunk.bind(this)
        this.upload = this.upload.bind(this)
        this.onErrorFn = () => {}
        this.setPresignedUrls = this.setPresignedUrls.bind(this)
    }
    // starting the multipart upload request
    start() {
        this.initialize()
    }

    setInitializeData(response, _this) {
        try {
            if (response && response?.data) {
                _this.fileId = response?.data?.uploadId
                _this.filePath = response?.data?.filePath
                _this.onInitialize({
                    fileId: _this.fileId,
                    filePath: _this.filePath
                })
                // retrieving the pre-signed URLs
                const numberOfparts = Math.ceil(_this.file.size / _this.chunkSize)
                const AWSMultipartFileDataInput = {
                    uploadId: _this.fileId,
                    filePath: _this.filePath,
                    numberOfPart: numberOfparts,
                    fileName: _this.fileName
                }

                getMultipartPreSignedUrls(AWSMultipartFileDataInput, _this.setPresignedUrls, _this)
            }
        } catch (error) {
            this.complete(error, "initialized")
        }
    }

    setPresignedUrls = (urlsResponse, _this) => {
        //After this send each part
        try {
            console.log("this", this)
            const newParts = urlsResponse.data?.preSignedUrl
            this.parts.push(...newParts)
            this.sendNext()
        } catch (error) {
            this.complete(error, "preSigned")
        }
    }

    async initialize() {
        try {
            // adding the the file extension (if present) to fileName
            let fileName = this.fileName
            // initializing the multipart request
            const videoInitializationUploadInput = fileName

            await initializeMultipartUpload(
                videoInitializationUploadInput,
                this.setInitializeData,
                this
            )
        } catch (error) {
            await this.complete(error, "initialized")
        }
    }

    sendNext() {
        const activeConnections = Object.keys(this.activeConnections).length

        /*   if (activeConnections >= this.threadsQuantity) {
      return
    } */

        if (!this.parts.length) {
            if (!activeConnections) {
                this.complete()
            }

            return
        }

        const part = this.parts.pop()
        if (this.file && part) {
            /* to get next chunk */
            const sentSize = (part.partNumber - 1) * this.chunkSize
            const chunk = this.file.slice(sentSize, sentSize + this.chunkSize)

            const sendChunkStarted = () => {
                this.sendNext()
            }

            this.sendChunk(chunk, part, sendChunkStarted)
                .then(() => {
                    this.sendNext()
                })
                .catch((error) => {
                    this.parts.push(part)
                    this.complete(error, error?.message)
                })
        }
    }

    // terminating the multipart upload request on success or failure
    async complete(error, type) {
        if (error && !this.aborted) {
            this.onErrorFn(error, type)
            return
        }

        if (error) {
            this.onErrorFn(error, type)
            return
        }

        try {
            await this.sendCompleteRequest()
        } catch (error) {
            this.onErrorFn(error, "complete")
        }
    }

    requestCompleted = (response) => {
        console.log("response", response)
        this.completeResponse({ status: true })
    }

    // finalizing the multipart upload request on success by calling
    // the finalization API
    async sendCompleteRequest() {
        if (this.fileId && this.filePath) {
            const videoFinalizationMultiPartInput = {
                uploadId: this.fileId,
                filePath: this.filePath,
                partETags: this.uploadedParts,
                fileName: this.fileName,
                totalSize: this.file.size
            }
            await complete(videoFinalizationMultiPartInput, this.requestCompleted)
        }
    }

    sendChunk(chunk, part, sendChunkStarted) {
        return new Promise((resolve, reject) => {
            this.upload(chunk, part, sendChunkStarted)
                .then((status) => {
                    if (status !== 200) {
                        reject(new Error("Failed chunk upload"))
                        return
                    }
                    resolve()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    // calculating the current progress of the multipart upload request
    handleProgress(part, event) {
        if (this.file) {
            if (event.type === "progress" || event.type === "error" || event.type === "abort") {
                this.progressCache[part] = event.loaded
            }

            if (event.type === "uploaded") {
                this.uploadedSize += this.progressCache[part] || 0
                delete this.progressCache[part]
            }

            const inProgress = Object.keys(this.progressCache)
                .map(Number)
                .reduce((memo, id) => (memo += this.progressCache[id]), 0)

            const sent = Math.min(this.uploadedSize + inProgress, this.file.size)

            const total = this.file.size

            const percentage = Math.round((sent / total) * 100)
            this.onProgressFn({
                sent: sent,
                total: total,
                percentage: percentage,
                file: this.file
            })
        }
    }

    // uploading a part through its pre-signed URL
    async upload(file, part, sendChunkStarted) {
        // uploading each part with its pre-signed URL
        return new Promise(async (resolve, reject) => {
            if (this.fileId && this.filePath) {
                // - 1 because partNumber is an index starting from 1 and not 0
                const xhr = (this.activeConnections[part.partNumber - 1] = new XMLHttpRequest())

                // sendChunkStarted();

                const progressListener = this.handleProgress.bind(this, part.partNumber - 1)
                /*   const response = await axios.get(
        part.url,
      ).then((resp)=>{
        console.log(resp)
      }); */

                xhr.upload.addEventListener("progress", progressListener)

                xhr.addEventListener("error", progressListener)
                xhr.addEventListener("abort", progressListener)
                xhr.addEventListener("loadend", progressListener)

                xhr.open("PUT", part.url)
                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4 && xhr.status === 200) {
                        // retrieving the ETag parameter from the HTTP headers
                        const ETag = xhr.getResponseHeader("ETag")
                        // let ETag = "";
                        // ETag = "adff"; //to change
                        if (ETag) {
                            const uploadedPart = {
                                partNumber: part.partNumber,
                                // removing the " enclosing carachters from
                                // the raw ETag
                                etag: ETag.replaceAll('"', "")
                            }

                            this.uploadedParts.push(uploadedPart)

                            resolve(xhr.status)
                            delete this.activeConnections[part.partNumber - 1]
                        }
                    }
                }

                xhr.onerror = (error) => {
                    reject(error)
                    delete this.activeConnections[part.partNumber - 1]
                }

                xhr.onabort = () => {
                    reject(new Error("cancelled"))
                    delete this.activeConnections[part.partNumber - 1]
                }

                xhr.send(file)
            }
        })
    }

    onProgress(onProgress) {
        this.onProgressFn = onProgress
        return this
    }

    onComplete(onComplete) {
        this.completeResponse = onComplete
        return this
    }

    onInitialize(onInitialize) {
        this.onInitialize = onInitialize
        return this
    }

    onError(onError) {
        this.onErrorFn = onError
        return this
    }

    abort() {
        Object.keys(this.activeConnections)
            .map(Number)
            .forEach((id) => {
                this.activeConnections[id].abort()
            })
        this.aborted = true
    }

    onResume() {
        this.sendNext()
    }
}

export default FileUploader
