import React, { Component } from "react"
import get from "lodash/get"
import { TABLE_LISTING_HEADING } from "../../api/constants"
import "./style.scss"
import Checkbox from "../../../common/Checkbox"
import Button from "../../../common/Button"
import NoImgAvailable from "../../../../assets/images/no_img_available.png"
import { dateTime, getContentData } from "../../../../utils/common"
import { BUTTON_CONSTANTS, PERMISSIONS, TEXT_CONSTANTS } from "../../../../utils/constants"
import { AuthWrapper } from "../../../common/AuthWrapper"
import { imagePathPrefix } from "../../../../config/apiPath"
import { isClickedOutside } from "../../../../utils/common"
import { getRightsManagement } from "../../api/action"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { hideHeader } from "../../contentNavCommon"

class WebSeriesList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isShowIcons: false,
            idToDelete: null,
            isDelete: false,
            highResImageLoaded: false,
            expandedRowId: null,
            season_index: 0,
            show_episode_action: "",
            active_tab: 0
        }
    }
    actionItemRef = React.createRef(null)
    componentDidMount() {
        const handleClickOutside = (event) => {
            this.state.show_episode_action &&
                !this.props.showModal &&
                isClickedOutside(event?.target, this.actionItemRef?.current) &&
                this.setState({ show_episode_action: false })
        }
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }
    // function to show icons menu
    showIconsMenu = () => {
        const { isShowIcons } = this.state
        this.setState({
            isShowIcons: !isShowIcons
        })
    }

    getGenreList = (data) => {
        let list = []
        data &&
            data.forEach((item) => {
                let genreName = item.name
                list.push(`#${genreName}`)
            })
        return list.join(" ")
    }

    handleDelete = (id) => {
        if (id) {
            this.setState({
                idToDelete: id,
                isDelete: true
            })
        } else {
            this.setState({
                idToDelete: null,
                isDelete: false
            })
        }
    }

    handleUnpublish = (id) => {
        if (id) {
            this.setState({
                idToUnpublish: id,
                isUnpublish: true
            })
        } else {
            this.setState({
                idToUnpublish: null,
                isUnpublish: false
            })
        }
    }
    toggleAccordion = (id) => {
        this.setState((prevState) => ({
            expandedRowId: prevState.expandedRowId === id ? null : id
        }))
    }

    handleSeason = (season_index) => {
        this.setState({ season_index: season_index, active_tab: season_index })
    }
    actionItemHandler = (selectedItem) => {
        const { showActionItem } = this.state
        if (selectedItem === showActionItem) {
            this.setState({ show_episode_action: "" })
            return
        }
        this.setState({ show_episode_action: selectedItem })
    }

    // initiateModal = async (val) => {
    //   const { getRightsManagement } = this.props;
    //   let response = await getRightsManagement(val.asset_id);
    //   if (response && response?.status) {
    //     this.props.setViewRightsId(response?.data?.rights);
    //     this.props.manageModal();
    //   } else {
    //     toast.error("No rights found");
    //   }

    // };

    getTableHeading = () => {
        // Add Action item header if content is editable
        if(hideHeader(this.props?.contentType?.toLowerCase())){
            return TABLE_LISTING_HEADING
        }
        return [
            ...TABLE_LISTING_HEADING,
            { key: "action", value: "actions" }
        ]
    }

    render() {
        const {
            movieList,
            markSelected,
            handleEdit,
            selectedCards,
            handleUnpublishClick,
            handleDeleteContent,
            imageKey,
            activeCardId,
            showEditOptions = true,
            handleViewClick
        } = this.props
        const {
            idToDelete,
            isDelete,
            idToUnpublish,
            isUnpublish,
            highResImageLoaded,
            expandedRowId,
            season_index
        } = this.state
        const tableHeadings = this.getTableHeading().map((item, index) => {
            return <th key={index}>{item.value}</th>
        })

        const tableRows = movieList.map((row, index) => {
            const { date, time } = dateTime(row.lastUpdated)
            return (
                <>
                    <tr
                        id={row.id}
                        key={row.id}
                        className={`movie-list-item ${row.active ? "" : "greyScale"}`} //${row.active ? "" : "greyScale"}
                        onClick={() => handleViewClick(row)}>
                        <td className="title-detail">
                            <div
                                className={`selectCheckBox ${
                                    selectedCards && selectedCards.length ? "showMe" : ""
                                } ${
                                    isDelete || isUnpublish || selectedCards.includes(row.id)
                                        ? "selected"
                                        : ""
                                }`}>
                                {/* {showEditOptions && (
                                    <Checkbox
                                        cName="select-checkBox"
                                        name={row.content?.title}
                                        id={row.id}
                                        changeHandler={markSelected}
                                        activeCardId={activeCardId}
                                        isChecked={
                                            selectedCards && selectedCards.includes(row.id) && true
                                        }
                                        activeIndex={row.active}
                                    />
                                )} */}
                            </div>
                            <div className={`movieBanner logo`}>
                                <img
                                    onLoad={() => {
                                        this.setState({ highResImageLoaded: true })
                                    }}
                                    src={
                                        row.images
                                            ? getContentData(
                                                  row.images.find(
                                                      (item) => item.type === "thumbnail"
                                                  ).url
                                              )
                                            : `${NoImgAvailable}`
                                    }
                                    alt="banner"
                                    onError={(e) => {
                                        e.target.onerror = null
                                        e.target.src = `${NoImgAvailable}`
                                        e.target.className = `no-img-available`
                                    }}
                                />
                                <img
                                    {...(highResImageLoaded && { style: { opacity: "0" } })}
                                    alt=""
                                    className="overlayStyles"
                                    src=""
                                />
                            </div>
                            <span>{get(row, "moviesName")}</span>
                        </td>
                        <td className="published-detail">
                            {row.title.shortTitle ? row.title.shortTitle.eng : row.title.short.eng}
                        </td>
                        <td className="published-detail">
                            {row.active ? (
                                <>
                                    <span>{date}</span>
                                    <span> &middot; </span>
                                    <span>{time}</span>
                                </>
                            ) : (
                                <span> — </span>
                            )}
                        </td>
                        {showEditOptions && (
                            <td className="btn-popupContainer">
                                <div className="icons-menu">
                                    <div className={"action-icons-list"}>
                                        <AuthWrapper permissions={[PERMISSIONS.CONTENT_EDIT]}>
                                            <div
                                                className={`toolsBtn ${
                                                    row.active ? "icon-unpublish" : "icon-published"
                                                }`}
                                                onClick={(e) => {e.stopPropagation(); this.handleUnpublish(row.id)}}>
                                                <span class="tooltiptext">
                                                    {row.active ? "Unpublish" : "Publish"}
                                                </span>
                                            </div>
                                        </AuthWrapper>
                                        <div
                                            className="toolsBtn icon-edit"
                                            onClick={(e) => {e.stopPropagation(); handleEdit(row)}}>
                                            <span class="tooltiptext">
                                                {BUTTON_CONSTANTS?.EDIT}
                                            </span>
                                        </div>
                                        <AuthWrapper permissions={[PERMISSIONS.CONTENT_EDIT]}>
                                            <div
                                                className="toolsBtn icon-del"
                                                onClick={(e) => {e.stopPropagation(); this.handleDelete(row.id)}}>
                                                <span class="tooltiptext">
                                                    {BUTTON_CONSTANTS?.DELETE}
                                                </span>
                                            </div>
                                        </AuthWrapper>
                                    </div>
                                    <i className={"icon-dot-3"}></i>
                                </div>
                                {idToDelete === row.id && (
                                    <div
                                        className={`deletePopup ${
                                            isDelete ? "showPopUp" : "hidePopUp"
                                        }`}>
                                        <p>{TEXT_CONSTANTS?.DELETE_MOVIE}</p>
                                        <Button
                                            cName="btn popup-del-btn"
                                            bValue={BUTTON_CONSTANTS?.YES_DELETE}
                                            bType="button"
                                            clickHandler={(e) => {  e.stopPropagation(); handleDeleteContent(row.id)}}
                                        />
                                        <Button
                                            cName="btn cancel-btn"
                                            bValue={BUTTON_CONSTANTS?.CANCEL}
                                            bType="button"
                                            clickHandler={(e) => { e.stopPropagation(); this.handleDelete()}}
                                        />
                                    </div>
                                )}
                                {idToUnpublish === row.id && (
                                    <div
                                        className={`unpublishPopup ${
                                            isUnpublish ? "showPopUp" : "hidePopUp"
                                        }`}>
                                        <p>{row.active ? "Unpublish" : "Publish"} this content?</p>
                                        <Button
                                            cName="btn popup-del-btn"
                                            bValue={BUTTON_CONSTANTS?.YES}
                                            bType="button"
                                            clickHandler={(e) => {
                                                e.stopPropagation();
                                                handleUnpublishClick(row.id, row.active)
                                            }}
                                        />
                                        <Button
                                            cName="btn cancel-btn"
                                            bValue={BUTTON_CONSTANTS?.CANCEL}
                                            bType="button"
                                            clickHandler={(e) => {e.stopPropagation(); this.handleUnpublish()}}
                                        />
                                    </div>
                                )}
                            </td>
                        )}
                    </tr>
                </>
            )
        })
        return (
            <div className="movie-list-view">
                {movieList.length ? (
                    <table className="table movie-list-table">
                        <thead>
                            <tr>{tableHeadings}</tr>
                        </thead>
                        <tbody>{tableRows}</tbody>
                    </table>
                ) : (
                    <div>{TEXT_CONSTANTS?.NO_RESULT_FOUND}</div>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                getRightsManagement
            },
            dispatch
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(WebSeriesList)
