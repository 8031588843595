import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { toast } from "react-toastify"

import Input from "../common/Input"
import Button from "../common/Button"
import { resetPassword, validateToken } from "./APIs/action"
import { BUTTON_CONSTANTS, REGEX, TEXT_CONSTANTS } from "../../utils/constants"
import { isEmpty } from "lodash"

class CreatePassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isChnaged: true,
            isSecreteLink: props.token ? false : true,
            newPassword: "",
            error: {}
        }
    }

    componentDidMount() {
        this.loadHandler()
    }

    loadHandler = async () => {
        const { validateToken, token } = this.props
        if (!isEmpty(token)) {
            let isTokenValidated = await validateToken(token)
            if (!isTokenValidated?.status) {
                this.handleGoToLogin()
            } else {
                this.setState({
                    isTokenValidated: true
                })
            }
            console.log("isTokenValidated", isTokenValidated)
        }
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    onSubmit = async () => {
        const { newPassword } = this.state
        const { token } = this.props
        const payload = {
            newPassword,
            token
        }
        const response = await this.props.resetPassword(payload)

        if (response && response.status) {
            toast.success(response.message)
            this.handleGoToLogin()
        } else {
            toast.error(response.message)
        }
    }

    handleGoToLogin = () => {
        const { refreshLogin } = this.props
        this.setState({
            isSecreteLink: !this.state.isSecreteLink
        })
        refreshLogin()
    }

    passwordValidation = () => {
        const { newPassword, confirmPassword } = this.state
        let error = {}
        let passRegex = REGEX.PASSWORD_VALIDATION
        if (!passRegex.test(newPassword) && newPassword) {
            error.isPassValid =
                "Password should be mix of cap letters, numbers & symbols of min 8 characters"
        }
        if (newPassword && confirmPassword && newPassword !== confirmPassword) {
            error.isPasswordMatch = "Password does not match"
        } else {
            error.isPasswordMatch = ""
        }
        return error
    }

    validatePassword = () => {
        let error = this.passwordValidation()
        this.setState({ error })
    }

    // Make change btn disabled till user enters valid password
    changeBtnDisabled = () => {
        const { isSecreteLink } = this.state
        if (!isSecreteLink) {
            const err = this.passwordValidation()
            if (
                err.isPasswordMatch ||
                err.isPassValid ||
                !this.state.confirmPassword ||
                !this.state.newPassword
            ) {
                return true
            }
        }
        return false
    }

    render() {
        const { isSecreteLink, error, isTokenValidated } = this.state
        const { token } = this.props
        return (
            <div className="create-password">
                <h2>{isSecreteLink ? "Successfully Sent" : "Create Password"}</h2>
                {isSecreteLink && <p>{TEXT_CONSTANTS?.RESET_LINK_TEXT}</p>}
                {!isSecreteLink && token && isTokenValidated && (
                    <React.Fragment>
                        <Input
                            name="newPassword"
                            type="password"
                            placeholder="new password"
                            onBlur={this.validatePassword}
                            changeHandler={this.handleChange}
                            errMsg={error.isPassValid}
                        />
                        <Input
                            name="confirmPassword"
                            type="password"
                            placeholder="confirm password"
                            changeHandler={this.handleChange}
                            onBlur={this.validatePassword}
                            errMsg={error.isPasswordMatch}
                        />
                    </React.Fragment>
                )}
                {isSecreteLink && (
                    <Button
                        name="signin"
                        cName="btn primary-btn"
                        bValue={
                            isSecreteLink ? BUTTON_CONSTANTS?.GO_TO_LOGIN : BUTTON_CONSTANTS?.CHANGE
                        }
                        clickHandler={isSecreteLink ? this.handleGoToLogin : this.onSubmit}
                    />
                )}
                {!isSecreteLink && token && isTokenValidated && (
                    <Button
                        name="signin"
                        cName="btn primary-btn"
                        bValue={
                            isSecreteLink ? BUTTON_CONSTANTS?.GO_TO_LOGIN : BUTTON_CONSTANTS?.CHANGE
                        }
                        clickHandler={this.onSubmit}
                        disabled={this.changeBtnDisabled()}
                    />
                )}
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        secretLink: state.auth.secretLink
    }
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(
            {
                resetPassword,
                validateToken
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePassword)
