import React from "react"
import Button from "../../../common/Button/index"
import alertImg from "../../../../assets/images/alert.png"
import "./style.scss"
import { BUTTON_CONSTANTS, TEXT_CONSTANTS } from "../../../../utils/constants"

const MovieAlert = (props) => {
    const {
        movieSrc,
        hashTag,
        title,
        created_At,
        created_Time,
        tableRow,
        handleTogglePublish,
        handleOnClose,
        contentId,
        published
    } = props
    return (
        <div className="content-alert">
            <header className="">
                {TEXT_CONSTANTS?.ALERT}
                <i className="icon-close" onClick={handleOnClose}></i>
            </header>
            <section className="alert-card">
                <div className="card-lf">
                    <div className="card-poster">
                        <img src={movieSrc} alt="" />
                    </div>
                    <div className="card-detail">
                        <span>{hashTag}</span>
                        <span>{title}</span>
                        <span>
                            {created_At} · {created_Time}
                        </span>
                    </div>
                </div>
                <img src={alertImg} alt="" />
            </section>
            <p>{TEXT_CONSTANTS?.THIS_CONTENT_PUBLISH}</p>
            {tableRow.length > 0 && (
                <section className="alert-table">
                    <table className="tbl">
                        <thead className="table-head">
                            <th>{TEXT_CONSTANTS?.PAGES}</th>
                            <th>{TEXT_CONSTANTS?.SECTIONS}</th>
                        </thead>
                        <tbody>
                            {tableRow.map((item) => {
                                return (
                                    <tr>
                                        <td>{item.categoryName}</td>
                                        <td className="red">{item.railName}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </section>
            )}
            <section className="alertBtn">
                <Button
                    cName="btn primary-btn"
                    bValue={TEXT_CONSTANTS?.UNPUBLISH}
                    clickHandler={() => handleTogglePublish(contentId, published)}
                />
                <Button
                    cName="btn cancel-btn"
                    bValue={BUTTON_CONSTANTS?.CANCEL}
                    clickHandler={handleOnClose}
                />
            </section>
        </div>
    )
}
export default MovieAlert
