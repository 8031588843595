let initialState = {
    logo: ""
}

export default function HomeReducer(state = initialState, action) {
    switch (action.type) {
        case "HEADER_LOGO_CHANGE":
            return { ...state, ...action }
        case "SHOW_NAVBAR":
            return { ...state, ...action }
        case "FETCH_TENANT_DOMAIN":
            return { ...state, ...action }
        default:
            return state
    }
}
