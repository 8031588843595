import React, { useCallback } from "react"
import PropTypes from "prop-types"

import CustomModalFooter from "./CustomModalFooter"
import close from "../../../assets/images/close.svg"

import "./style.scss"

const CustomModal = (props) => {
    const {
        showModal,
        children,
        cancelHandler = () => {},
        submitHandler = () => {},
        submitButtonLabel,
        cancelButtonLabel,
        showCancelButton = false,
        showSubmitButton = true,
        isSubmitDisabled = false,
        cancelOnOutsideClick = true,
        showCloseIcon = true,
        submitBtnClass = "",
        cancelBtnClass = "",
        modalContainerClass = "",
        showFooter = true
    } = props

    const onClickCancelBtn = useCallback(() => {
        if (cancelHandler) {
            cancelHandler()
        }
    }, [cancelHandler])

    const onClickSubmitBtn = useCallback(() => {
        if (submitHandler) {
            submitHandler()
        }
    }, [submitHandler])

    return (
        <>
            {showModal ? (
                <div
                    className={`modalContainer ${modalContainerClass}`}
                    onClick={cancelOnOutsideClick ? onClickCancelBtn : null}>
                    <div className="modal" onClick={(e) => e.stopPropagation()}>
                        <div className="close">
                            {showCloseIcon && (
                                <button onClick={onClickCancelBtn}>
                                    <img src={close} alt="close" />
                                </button>
                            )}
                        </div>
                        {children}
                        {showFooter && (
                            <footer className="modal_footer">
                                <CustomModalFooter
                                    cancelBtnHandler={onClickCancelBtn}
                                    submitBtnHandler={onClickSubmitBtn}
                                    showCancelButton={showCancelButton}
                                    cancelButtonLabel={cancelButtonLabel}
                                    submitButtonLabel={submitButtonLabel}
                                    showSubmitButton={showSubmitButton}
                                    isSubmitDisabled={isSubmitDisabled}
                                    submitBtnClass={submitBtnClass}
                                    cancelBtnClass={cancelBtnClass}
                                />
                            </footer>
                        )}
                    </div>
                </div>
            ) : null}
        </>
    )
}

CustomModal.propTypes = {
    submitHandler: PropTypes.func,
    cancelHandler: PropTypes.func,
    submitButtonLabel: PropTypes.string,
    cancelButtonLabel: PropTypes.string,
    showCancelButton: PropTypes.bool
}

export default CustomModal
