import { imagePathPrefix } from "../../../config/apiPath"

export const ACTION = {
    FETCH_FORM_ELEMENT: "FETCH_FORM_ELEMENT",
    CREATE_CONTENT: "CREATE_CONTENT",
    UPDATE_CONTENT: "UPDATE_CONTENT",
    FETCH_CONTENT: "FETCH_CONTENT",
    FETCH_ENTITY_VALUE_MASTER_DATA: "FETCH_ENTITY_VALUE_MASTER_DATA",
    FETCH_CONTENT_LISTING: "FETCH_CONTENT_LISTING",
    UPLOAD_IMAGE: "UPLOAD_IMAGE",
    DELETE_IMAGE: "DELETE_IMAGE",
    DELETE_CONTENT: "DELETE_CONTENT"
}

export const type = [
    { id: true, name: "Publish" },
    { id: false, name: "Unpublish" }
]
export const imgPathPrefix = imagePathPrefix

export const options = [
    { id: 10, name: "10" },
    { id: 20, name: "20" },
    { id: 30, name: "30" },
    { id: 50, name: "50" }
]
