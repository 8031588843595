import React, { useState } from "react"
import "./style.scss"

const Tab = ({ label, isActive, onClick }) => {
    return (
        <div onClick={onClick} className={isActive ? "tab currently-active-tab" : "tab"}>
            {label}
        </div>
    )
}

const MultipleTab = ({ tabs, className, callBack, selectedSportsTab }) => {
    const [activeTab, setActiveTab] = useState(selectedSportsTab || 0)

    const handleTabClick = (index, tab) => {
        setActiveTab(index)
        callBack && callBack(tab)
    }

    return (
        <>
            <div className={`multiple-tabs-container ${className}`}>
                {tabs?.length > 0 &&
                    tabs?.map((tab, index) => (
                        <Tab
                            key={index}
                            label={tab.label}
                            isActive={activeTab === index}
                            onClick={() => handleTabClick(index, tab)}
                        />
                    ))}
            </div>
            <div>{tabs?.length > 0 && tabs?.[activeTab]?.component}</div>
        </>
    )
}

export default MultipleTab
