import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import InvoiceDetail from "./InvoiceDetail"
import get from "lodash/get"
import moment from "moment"
import { SUBSCRIPTION_PAGE_TABLE_HEADING } from "../APIs/constants"
import { fetchInvoiceDetail } from "../APIs/action"

import "./style.scss"

class Invoice extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showInvoiceDetail: false,
            invoiceList: [],
            invoiceDetail: []
        }
    }

    componentDidMount() {
        this.loadHandler()
    }

    loadHandler = async () => {
        const { fetchInvoiceDetail, viewData } = this.props
        const response = await fetchInvoiceDetail(viewData.accountId)
        if (response && response.status) {
            this.setState({
                invoiceList: response.data
            })
        }
    }

    handleViewClick = () => {
        const { showInvoiceDetail } = this.state
        this.setState({
            showInvoiceDetail: !showInvoiceDetail
        })
    }
    closeInvoiceDetail = () => {
        this.setState({
            showInvoiceDetail: false
        })
    }
    ShowInvoiceDetail = (item) => {
        this.setState({
            showInvoiceDetail: true,
            invoiceDetail: item
        })
    }

    render() {
        const { showInvoiceDetail, invoiceList, invoiceDetail } = this.state
        const { handleClose, viewData } = this.props

        const tableHeading = SUBSCRIPTION_PAGE_TABLE_HEADING.map((item, index) => {
            return <th key={index}>{item}</th>
        })
        const list = invoiceList[invoiceList.length - 1]
        const tableRows = invoiceList.map((row, index) => {
            return row.invoiceItemList.map((element, idx) => {
                return (
                    <tr id={row.id} key={index} className="custom_tr">
                        <td>{index + 1}</td>
                        <td>{element.invoiceId}</td>
                        <td> {moment(element.startDate).format(" DD MMMM, YYYY")}</td>
                        <td>{element.planName}</td>
                        <td>{element.amount}</td>

                        <td>{row.status}</td>

                        <td className="btn-popupContainer">
                            {/* <i
                                    className="icon-published"

                                /> */}
                            <i
                                className="icon-selection"
                                onClick={() => this.ShowInvoiceDetail(row)}
                            />
                        </td>
                    </tr>
                )
            })
        })

        return (
            <div className="vd-custom-management">
                <div className="vr-cont-head mb">
                    <div className="vr-cont-title">
                        <span className="vr-breadcrum">
                            user-management <i className="icon-arrow-right1" />
                            <span>users</span>
                        </span>
                        <h2 className="breadcrum-header">Subscriber Details</h2>
                    </div>
                    <button className="btn border-btn">
                        <i className="btn-icon-left icon-arrow-left1" />
                        <span className="button-text" onClick={handleClose}>
                            Back
                        </span>
                    </button>
                </div>

                <div className="subscriber-detail">
                    <p className="name">{viewData.name}</p>
                    <p className="email">{viewData.email}</p>
                    <div className="list">
                        <ul>
                            {list &&
                                list.invoiceItemList.map((element, index) => {
                                    return (
                                        <>
                                            <li>
                                                Joining Date :{" "}
                                                {moment(element.startDate).format(" DD MMMM, YYYY")}
                                            </li>
                                            <li>Current Plan : {element.planName}</li>
                                            <li>
                                                Expire on :{" "}
                                                {moment(element.targetDate).format(
                                                    " DD MMMM, YYYY"
                                                )}
                                            </li>
                                            <li>{list.status}</li>
                                        </>
                                    )
                                })}
                        </ul>
                    </div>
                </div>

                <div className="vr-content-holder">
                    <div className="vr-cont-head">
                        <div className="vr-cont-title">
                            <h2 className="inner-count-title">INVOICE</h2>
                        </div>
                        {/* <div className="vr-cont-action">
                            <Search
                                name="search"
                                // changeHandler={this.handleSearch}
                                placeholder="Search Here"
                            />
                        </div> */}
                    </div>
                    <div>
                        <table className="table invoice-table">
                            <thead>
                                <tr>{tableHeading}</tr>
                            </thead>
                            <tbody>{tableRows}</tbody>
                        </table>
                    </div>
                </div>

                {/* {showInvoiceDetail ? <InvoiceDetail/> : ""} */}
                {showInvoiceDetail && (
                    <div className="showInvoiceDetail">
                        <div className="empty-wrapper" onClick={this.handleViewClick} />
                        <InvoiceDetail
                            handleclose={this.handleViewClick}
                            invoiceDetail={invoiceDetail}
                        />
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        invoiceList: get(state.invoiceList, "invoiceList", [])
        // customTextCount: get(state.customPage, 'customText.count', []),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                fetchInvoiceDetail
                // filterTextpage,
                // softDeleteTextpage,
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Invoice)
