import { removeLoader, setLoader } from "../../common/Loader/api/action"
import { ACTION } from "./constant"
import TimezoneSerivceInstance from "./service"

export const getAllTimezone = () => {
    return (dispatch) => {
        dispatch(setLoader())
        return TimezoneSerivceInstance.getAllTimezone()
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getAllTimezone ", error)
            })
            .finally(() => {
                dispatch(removeLoader())
            })
    }
}

export const saveTimezone = (selectedTimezone) => {
    return (dispatch) => {
        dispatch(setLoader())
        return TimezoneSerivceInstance.saveTimezoneData(selectedTimezone)
            .then((response) => {
                dispatch({
                    type: ACTION?.SAVE_TIMEZONE,
                    data: response?.data?.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in saveTimezone ", error)
                return error
            })
            .finally(() => {
                dispatch(removeLoader())
            })
    }
}

export const getSavedTimezone = () => {
    return (dispatch) => {
        dispatch(setLoader())
        return TimezoneSerivceInstance.getSavedTimezoneData()
            .then((response) => {
                dispatch({
                    type: ACTION?.SAVE_TIMEZONE,
                    data: response?.data?.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in saveTimezone ", error)
                return error
            })
            .finally(() => {
                dispatch(removeLoader())
            })
    }
}
