import DrmServiceInstance from "./service"
import { ACTION } from "./constant"

export const saveDrmConfig = (params) => {
    return (dispatch) => {
        return DrmServiceInstance.saveDrmConfig(params)
            .then(async (response) => {
                dispatch({
                    type: ACTION.DRM_CONFIG,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Drm config error " + error?.message)
                return error
            })
    }
}

export const getDrmConfig = (params) => {
    return (dispatch) => {
        return DrmServiceInstance.getDrmConfig(params)
            .then(async (response) => {
                dispatch({
                    type: ACTION.GET_DRM_CONFIG,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Get Drm config error " + error?.message)
                return error
            })
    }
}

export const getIsDrmConfig = (params) => {
    return (dispatch) => {
        return DrmServiceInstance.getIsDrmConfig(params)
            .then(async (response) => {
                dispatch({
                    type: ACTION.GET_IS_DRM_CONFIG,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Get Drm config error " + error?.message)
                return error
            })
    }
}

export const getCountries = (params) => {
    return (dispatch) => {
        return DrmServiceInstance.getCountries(params)
            .then(async (response) => {
                dispatch({
                    type: ACTION.GET_COUNTRIES,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Drm config error " + error?.message)
                return error
            })
    }
}

export const getLicenseDer = (params) => {
    return (dispatch) => {
        return DrmServiceInstance.getLicenseDer(params)
            .then(async (response) => {
                dispatch({
                    type: ACTION.GET_LICENSE,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Drm config error " + error?.message)
                return error
            })
    }
}
