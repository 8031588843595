import React from "react"

function PlatformTabHeader(props) {
    const { platFormList, currentPlatform, handlePlatformChange, isEdit } =
        props
    return (
        <div className="platform">
            {platFormList.map((item, index) => {
                return (
                    <i
                        className={`icon-${item.name.toLowerCase()} ${
                            currentPlatform === item.id ? "active" : ""
                        }`}
                        style={
                            isEdit
                                ? {
                                      cursor: "not-allowed"
                                  }
                                : null
                        }
                        id={item.id}
                        onClick={
                            !isEdit ? () => handlePlatformChange(item) : null
                        }>
                        <span>{item.name?.replace(/[_-]/g, " ")}</span>
                    </i>
                )
            })}
        </div>
    )
}

// const platformLoader = () => {
//   return (
//     <div className="platform-loader">
//       <div className="platform-block">
//         <div className="platform-img"></div>
//         <div className="platform-title"></div>
//       </div>
//     </div>
//   );
// };
export default PlatformTabHeader
