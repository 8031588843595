import React, { Component } from "react"
import videojs from "video.js"
import "video.js/dist/video-js.css"
import "./style.scss"
import { toHHMMSS } from "./util"
import playIcon from "../../../assets/images/play.svg"
import pauseIcon from "../../../assets/images/pause.svg"
import muteIcon from "../../../assets/images/mute.svg"
import unmuteIcon from "../../../assets/images/unmute.svg"
export default class VideoJsMarkerPlayer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            src: props.src,
            timeFrames: props.timeFrames
                ? props.timeFrames
                : [
                      // {
                      //   start: 10,
                      //   end: 20,
                      // },
                      // {
                      //   start: 20,
                      //   end: 50,
                      // },
                  ],
            isMuted: true,
            isPaused: true,
            contentId: "data"
        }
        this.valueHover = 0
        this.videoRef = React.createRef()
        // this.updateVideoSrc = this.updateVideoSrc.bind(this);
        this.isPaused = this.isPaused.bind(this)
        this.play = this.play.bind(this)
        this.pause = this.pause.bind(this)
        this.mute = this.mute.bind(this)
        this.unMute = this.unMute.bind(this)
        this.onPlay = this.onPlay.bind(this)
        this.onPause = this.onPause.bind(this)
        this.onVolume = this.onVolume.bind(this)
        // this.onError = this.onError.bind(this);
        this.onTimeupdate = this.onTimeupdate.bind(this)
        this.onReady = this.onReady.bind(this)
    }
    componentDidMount() {
        const self = this
        const { timeFrames } = this.state
        const params = {
            controls: true,
            autoplay: true,
            preload: "auto",
            volume: 1,
            //   poster: src.videoPoster,
            sources: [
                {
                    src: this.state?.src
                }
            ],
            inactivityTimeout: 0, // show controls always
            errorDisplay: false,
            liveui: true,
            fluid: true
        }
        this.videoRef.current.onerror = this.onError
        this.player = videojs(this.videoRef.current, params, function onPlayerReady() {
            const player = this
            // Button State
            this.setState({ isPaused: player.paused() })
            this.setState({ isMuted: player.muted() })

            // Events
            !player.hasOwnProperty("error_") && player.muted(true)
            player.on("play", self.onPlay)
            player.on("pause", self.onPause)
            player.on("error", self.onError)
            player.on("ready", self.onReady)
            player.on("timeupdate", self.onTimeupdate)
            player.on("volumechange", self.onVolume)
        })
        if (timeFrames && timeFrames != null) {
            this.addTimeSlot()
        }
        const seekBall = document.getElementById(`custom-seekbar_${this.state.contentId}`)
        seekBall.value = 0

        // seekBall.addEventListener("mousemove", (e) => {
        //   this.valueHover = calcSliderPos(e).toFixed(2);
        //   let tooltip = document.getElementById(
        //     `durtimeText_${this.state.contentId}`
        //   );
        //   if (tooltip) {
        //     tooltip.style.left = this.valueHover + "%";
        //     tooltip.innerText = toHHMMSS(
        //       (this.player.duration() * this.valueHover) / 100
        //     );
        //   }
        // });

        seekBall.addEventListener("click", (e) => {
            e.stopPropagation()
            let seekTime = this.player.duration() * (seekBall.value / 1000)
            this.player.currentTime(seekTime)
        })

        const playerEle = document.querySelectorAll(`#videojs_${this.state.contentId} video`)
        const controlBar = document.getElementById(`custom-controlBar_${this.state.contentId}`)
        let timeOut = null
        playerEle[0].addEventListener("mousemove", () => {
            clearTimeout(timeOut)
            // if (!controlBar.classList.contains("show")) {
            //   controlBar.classList.add("show");
            // }
            timeOut = setTimeout(() => {
                controlBar.classList.remove("show")
            }, 100000)
        })
    }

    componentWillUnmount() {
        if (this.videoRef.current) {
            this.videoRef.current.onerror = undefined
        }
        if (this.player) {
            this.player.dispose()
            this.player = undefined
        }
    }

    componentDidUpdate(prevProps) {
        if (
            this.props &&
            prevProps &&
            this.props.selectedTooltipLabel &&
            prevProps.selectedTooltipLabel &&
            this.props.selectedTooltipLabel !== prevProps.selectedTooltipLabel
        ) {
            this.addTimeSlot()
            // let toolTipCommonClass = document.getElementsByClassName("toottip");
            // if (toolTipCommonClass && toolTipCommonClass[0]) {
            //   toolTipCommonClass[0].style.visibility = "hidden";
            //   toolTipCommonClass[0].style.opacity = 0;
            // }
            // let toolTip = document.getElementsByClassName(
            //   nextProps.selectedTooltipLabel
            // );
            // setTimeout(() => {
            //   if (toolTip && toolTip[0]) {
            //     toolTip[0].style.visibility = "visible";
            //     toolTip[0].style.opacity = 1;
            //   }
            // }, 500);
        }
    }

    onReady(e) {
        let playBtn = document.getElementById(`play-btn_${this.state.contentId}`)
        let muteBtn = document.getElementById(`custom-mute_${this.state.contentId}`)

        playBtn.addEventListener("click", (e) => {
            e.stopPropagation()
            if (this.player.paused()) {
                this.player.play()
            } else {
                this.player.pause()
            }
        })

        muteBtn.addEventListener("click", (e) => {
            e.stopPropagation()
            if (this.player.muted()) {
                this.unMute(false)
            } else {
                this.mute()
            }
        })
    }

    play() {
        if (this.player) {
            this.player.play()
        }
    }

    pause() {
        if (this.player) {
            this.player.pause()
        }
    }
    onVolume() {
        this.setState({ isMuted: this.player.muted() })
    }
    isPaused() {
        if (this.player) {
            return this.player.paused()
        }
        return false
    }

    onPause() {
        this.setState({
            isPaused: this.player.paused(),
            isMuted: this.player.muted()
        })
        let playBtn = document.getElementById(`play-btn_${this.state.contentId}`)
        playBtn.firstChild.src = playIcon
    }

    onPlay() {
        this.setState({
            error: undefined,
            isPaused: this.player.paused(),
            isMuted: this.player.muted()
        })
        const controlBar = document.getElementById(`custom-controlBar_${this.state.contentId}`)
        if (!controlBar.classList.contains("show")) {
            controlBar.classList.add("show")
        }
        let playBtn = document.getElementById(`play-btn_${this.state.contentId}`)
        playBtn.firstChild.src = pauseIcon
    }

    mute() {
        if (this.player) {
            let muteBtn = document.getElementById(`custom-mute_${this.state.contentId}`)
            muteBtn.firstChild.src = muteIcon
            this.player.muted(true)
        }
    }

    unMute() {
        if (this.player) {
            let muteBtn = document.getElementById(`custom-mute_${this.state.contentId}`)
            muteBtn.firstChild.src = unmuteIcon
            this.player.muted(false)
        }
    }

    onTimeupdate() {
        if (this.player) {
            let seekBall = document.getElementById(`custom-seekbar_${this.state.contentId}`)
            let progressBar = document.getElementById(`progressBar_${this.state.contentId}`)
            let seekPos = this.player.currentTime() * (100 / this.player.duration())
            progressBar.style.width = seekPos + "%"
            seekBall.value = seekPos * 10
            document.getElementById(`custom-timer_${this.state.contentId}`).innerText = toHHMMSS(
                this.player.duration() - this.player.currentTime()
            )
            let tooltip = document.getElementById(`durtimeText_${this.state.contentId}`)
            if (tooltip) {
                // tooltip.style.left = this.valueHover + "%";
                tooltip.innerText = Math.round(this.player.currentTime()) + " secs"
            }
        }
        // this.valueHover = calcSliderPos(e).toFixed(2);
    }

    addTimeSlot() {
        if (this.player?.duration()) {
            const { timeFrames } = this.state
            const { selectedTooltipLabel } = this.props
            const seekBar = document.getElementById(`custom-seekbar_${this.state.contentId}`)

            timeFrames.map((value, key) => {
                let eleTemp = document.getElementById(`timeslot_${key}`)
                eleTemp && eleTemp.remove()
                let property = this.updateSlotPosition(value)
                let ele = document.createElement("div")
                ele.id = `timeslot_${key}`
                ele.className = "timeSlot"
                ele.style.left = property.left
                ele.style.width = property.width
                seekBar.parentNode.insertBefore(ele, seekBar)
                let toolTip = document.createElement("span")
                toolTip.className = `toottip ${value.toolTip}`
                // toolTip.style.left = "42%";
                if (value.type === "ad-break") {
                    ele.style.background = "#f96b55"
                }
                if (value.toolTip === selectedTooltipLabel && value?.start !== "NA") {
                    toolTip.style.visibility = "visible"
                    toolTip.style.opacity = 1
                } else {
                    toolTip.style.visibility = "hidden"
                    toolTip.style.opacity = 0
                }
                toolTip.innerHTML = value.end
                    ? `<div class="bold">${value.toolTip}</div> <div>${value?.start} : ${value.end}</div>`
                    : `<div class="bold">${value.toolTip}</div> <div>${value?.start}</div>`
                ele.appendChild(toolTip)

                return value
            })
        } else {
            setTimeout(() => {
                this.addTimeSlot()
            }, 1000)
        }
    }

    updateSlotPosition = (timeframe) => {
        let startSec = timeframe?.start
        let endSec = timeframe?.end
        return {
            left: startSec * (100 / this.player.duration()) + "%",
            width: endSec ? (endSec - startSec) * (100 / this.player.duration()) + "%" : 2 + "px"
        }
    }

    onError(e) {
        const err = this?.player?.error()
        console.log("Error in video js player", e, err)
    }

    render() {
        const { contentId } = this.state
        return (
            <div
                data-vjs-player
                style={{
                    backgroundColor: "#000",
                    border: "solid 1px #000",
                    position: "relative"
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        top: 8,
                        left: "25%",
                        width: "50%",
                        zIndex: 1
                    }}
                >
                    {/*{this.renderAlert()}*/}
                </div>
                <video
                    ref={this.videoRef}
                    id={`videojs_${contentId}`}
                    className="video-js video-js-container vjs-default-skin vjs-big-play-centered"
                ></video>
                <div id={`custom-controlBar_${contentId}`} className="custom-controlBar">
                    <div id={`play-btn_${contentId}`} className="play-btn">
                        <img src={playIcon} alt="" />
                    </div>
                    <div className="custom-seekbar-container">
                        <span
                            id={`durtimeText_${contentId}`}
                            className="toottip durtimeText"
                        ></span>
                        <div id={`progressBar_${contentId}`} className="progressBar"></div>
                        <input
                            type="range"
                            className="custom-seekbar"
                            id={`custom-seekbar_${contentId}`}
                            name="points"
                            min="0"
                            max="1000"
                        />
                    </div>
                    <div id={`custom-timer_${contentId}`} className="custom-timer"></div>
                    <div id={`custom-mute_${contentId}`} className="custom-mute">
                        <img src={muteIcon} alt="" />
                    </div>
                </div>
            </div>
        )
    }
}
