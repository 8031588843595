import React from "react"
import Category from "../../Category"
import {
    SPORTS_HOME_PAGE_DETAILS,
    SPORTS_HOME_TABS,
    VOD_SPORTS_TAB
} from "../../Category/api/constant"

export default function SportsHome() {
    return (
        <Category
            tabs={SPORTS_HOME_TABS}
            parent={VOD_SPORTS_TAB.SPORTS_HOME}
            pageDetails={SPORTS_HOME_PAGE_DETAILS}
        />
    )
}
