import { imagePathPrefix } from "../../../config/apiPath"
import { joinUrl } from "../../../utils/common"
import { SELECTED_LANGUAGE, SPORTS_TAB } from "../../Sports/APIs/constant"
import { CONTENT_TYPE_CONST, SPORTS_CONTENT_TYPE, SPORTS_ID } from "./constant"

export const getSportsCategoryList = (sportsCategoryList) => {
    let tabs = []
    for (let key in sportsCategoryList) {
        let tempObj = {
            id: SPORTS_TAB?.[key?.replaceAll(" ", "_")?.toUpperCase()],
            name: `${key}`,
            pageEntityId: sportsCategoryList?.[key]?.pageEntityId,
            position: sportsCategoryList?.[key]?.position
        }
        tabs.push(tempObj)
    }
    tabs.sort((a, b) => a?.position - b?.position) //sorting in increasing order of position
    return tabs
}

export const formatContentType = (list = [], replacedChar, replacedString) => {
    return list?.map((item) => item?.replace(replacedChar, replacedString))
}

export const formatSportsData = (sportsData = [], titleKey = "title") => {
    const code = SELECTED_LANGUAGE?.code
    return sportsData.map((item) => {
        const posterImageUrl = item?.meta?.posterImage?.values?.[code]
        return {
            ...item,
            [titleKey]: item?.meta?.name?.values?.[code],
            posterImage: joinUrl(posterImageUrl),
            active: item?.published,
            editorialType: "PAGE",
            images: [{type: "poster", url: joinUrl(posterImageUrl)}]
        }
    })
}

export const appendSportsContentType = (contentTypeList = []) => {
    let contentList = []
    if (contentTypeList) {
        const moviesItem = contentTypeList?.find((item) => item?.name === CONTENT_TYPE_CONST?.MOVIES)
        contentList = [...contentTypeList]
        contentList.push({
            contentEntityName: { hn: "चलचित्र", vn: "Phim", en: "SPORTS", fr_en: "FILMS" },
            genreKey: "genre",
            imageKey: "poster_image",
            languageKey: "language",
            localeContentEntityName: "SPORTS",
            name: SPORTS_CONTENT_TYPE?.label,
            nameKey: "movie_title",
            id: `${moviesItem?.id}${SPORTS_ID}`
        })
    }   
    return contentList
}
