import { FooterServices } from "./service"
import { ACTION } from "./constant"

export const getAllFooterItems = (platformId) => {
    return (dispatch) => {
        return FooterServices.getAllFooterItems(platformId)
            .then((response) => {
                dispatch({
                    type: ACTION.GET_ALL_FOOTER_ITEMS,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting user list ", error)
            })
    }
}

export const createFooterItem = (payload) => {
    return (dispatch) => {
        return FooterServices.createFooterItem(payload)
            .then((response) => {
                dispatch({
                    type: ACTION.CREATE_FOOTER_ITEM,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting user list ", error)
            })
    }
}

export const updateFooterItem = (payload) => {
    return (dispatch) => {
        return FooterServices.updateFooterItem(payload)
            .then((response) => {
                dispatch({
                    type: ACTION.UPDATE_FOOTER_ITEM,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting update list ", error)
            })
    }
}

export const bulkUpdateFooter = (payload) => {
    return (dispatch) => {
        return FooterServices.bulkUpdateFooter(payload)
            .then((response) => {
                dispatch({
                    type: ACTION.UPDATE_FOOTER_ITEM,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting update list ", error)
            })
    }
}

export const deleteFooterItem = (id) => {
    return (dispatch) => {
        return FooterServices.deleteFooterItem(id)
            .then((response) => {
                dispatch({
                    type: ACTION.DELETE_FOOTER_ITEM,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting user list ", error)
            })
    }
}

export const publishFooterItem = (id, status) => {
    return (dispatch) => {
        return FooterServices.publishFooterItem(id, status)
            .then((response) => {
                dispatch({
                    type: ACTION.PUBLISH_FOOTER_ITEM,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting user list ", error)
            })
    }
}
