import ApiService from "../../../utils/apiService"
import { API_TENANT_ROOT_PATH } from "../../../config/apiPath"

class AdminService {
    findTenantByDomain() {
        // !localStorage.getItem("domain") &&
        //   localStorage.setItem("domain", "admin.cms.demo.videoready.tv");
        //   let pathName = localStorage.getItem("domain");

        let pathName = window?.location?.hostname
        if (pathName === "localhost") {
            pathName = process.env.REACT_APP_LOCAL_PATH_NAME
        }
        const options = {
            method: "GET",
            url: `${API_TENANT_ROOT_PATH}/multitenancy/v1/tenant/findByUrl?url=${pathName}&platform=CMS`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
}

const AdminServiceInstance = new AdminService()
export default AdminServiceInstance
