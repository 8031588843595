import React, { Component } from "react"
import "videojs-contrib-eme"
import videojs from "video.js"
import "video.js/dist/video-js.css"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { toHHMMSS } from "../../../common/VideoJsMarkerPlayer/util"
import playIcon from "../../../../assets/images/play.svg"
import pauseIcon from "../../../../assets/images/pause.svg"
import muteIcon from "../../../../assets/images/mute.svg"
import unmuteIcon from "../../../../assets/images/unmute.svg"

import { base64EncodeUint8Array, getBrowserType } from "../../../../utils/common"
import { getBuyDrmToken, fetchLicenseDetails } from "../../api/action"

import "./style.scss"

let player = null
class DRMTrancodedVideo extends Component {
    constructor(props) {
        super(props)

        this.state = {
            src: props.src,
            timeFrames: props.timeFrames ? props.timeFrames : [],
            isMuted: true,
            isPaused: true,
            contentId: "data",
            selectedObj: props?.selectedObj
        }
        this.valueHover = 0
        this.videoRef = React.createRef()
        this.isPaused = this.isPaused.bind(this)
        this.play = this.play.bind(this)
        this.pause = this.pause.bind(this)
        this.mute = this.mute.bind(this)
        this.unMute = this.unMute.bind(this)
        this.onPlay = this.onPlay.bind(this)
        this.onPause = this.onPause.bind(this)
        this.onVolume = this.onVolume.bind(this)
        this.onTimeupdate = this.onTimeupdate.bind(this)
        this.onReady = this.onReady.bind(this)
    }

    instantiateVideo = async (drmResponse) => {
        const self = this

        const drm = this.state.selectedObj?.isDRMEnabled
        const playURL = this.state.src
        const VideoParams = {
            controls: true,
            autoplay: true,
            preload: "auto",
            volume: 1,
            sources: [
                {
                    src: this.state?.src
                }
            ],
            inactivityTimeout: 0, // show controls always
            errorDisplay: false,
            liveui: true,
            fluid: true
        }

        if (getBrowserType() === "Apple Safari") {
            player = videojs(this.videoRef.current, VideoParams, function onPlayerReady() {
                const player1 = this

                this.setState({ isPaused: player1.paused() })
                this.setState({ isMuted: player1.muted() })

                // Events
                !player1.hasOwnProperty("error_") && player1.muted(true)
                player1.on("play", self.onPlay)
                player1.on("pause", self.onPause)
                player1.on("error", self.onError)
                player1.on("ready", self.onReady)
                player1.on("timeupdate", self.onTimeupdate)
                player1.on("volumechange", self.onVolume)
            })
            let drmToken = drmResponse?.authXmlToken
            player.eme({
                emeHeaders: {
                    customdata: drmToken
                }
            })
            player.on("ready", function () {
                var fairplayHlsSrc = {
                    src: playURL,
                    type: "application/x-mpegURL",
                    keySystems:
                        (drm && {
                            "com.apple.fps.1_0": {
                                certificateUri: drmResponse?.certificateURL,
                                getContentId: function (emeOptions, initData) {
                                    if (initData.indexOf("skd://") > -1) {
                                        return initData.split("skd://")[1].substr(0, 32)
                                    }
                                    throw new Error(
                                        "Invalid Content ID format. The format of the Content ID must be the following: skd://xxx where xxx is the Key ID in hex format."
                                    )
                                },
                                getLicense: function (emeOptions, contentId, keyMessage, callback) {
                                    var data =
                                        "spc=" +
                                        base64EncodeUint8Array(keyMessage) +
                                        "&assetId=" +
                                        contentId
                                    const headers = { customdata: drmToken }
                                    fetchLicenseDetails(
                                        drmResponse?.fairPlayLicenseServer,
                                        data,
                                        headers,
                                        callback
                                    )
                                }
                            }
                        }) ||
                        null
                }
                player.src(fairplayHlsSrc)
            })
        } else {
            let videoParameters = {}

            const videoJsOptions = {
                autoplay: true,
                controls: true,
                preload: "auto",
                volume: 1,
                errorDisplay: false,
                liveui: true,
                fluid: true,
                sources: [
                    {
                        src: playURL,
                        keySystems: {
                            "com.widevine.alpha": drmResponse?.widevineLicenseServer,
                            "com.microsoft.playready": drmResponse?.playReadyLicenseServer
                        },
                        ...videoParameters
                    }
                ],
                crossorigin: "anonymous",
                html5: {
                    vhs: {
                        overrideNative: true
                    },
                    nativeTextTracks: false,
                    nativeAudioTracks: false,
                    nativeVideoTracks: false
                }
            }
            player = videojs(this.videoRef.current, videoJsOptions, function onPlayerReady() {
                const player1 = this
                this.setState({ isPaused: player1.paused() })
                this.setState({ isMuted: player1.muted() })

                // Events
                !player1.hasOwnProperty("error_") && player.muted(true)
                player1.on("play", self.onPlay)
                player1.on("pause", self.onPause)
                player1.on("error", self.onError)
                player1.on("ready", self.onReady)
                player1.on("timeupdate", self.onTimeupdate)
                player1.on("volumechange", self.onVolume)
            })

            if (drm) {
                let drmToken = drmResponse?.authXmlToken
                player.eme({
                    emeHeaders: {
                        customdata: drmToken
                    }
                })
            }
        }
    }

    async componentDidMount() {
        const selectedObj = this.state.selectedObj
        if (selectedObj?.playURL) {
            if (selectedObj?.isDRMEnabled) {
                const res = await this.props.getBuyDrmToken({
                    contentId: "movie_1234",
                    contentType: "MOVIES",
                    deviceType: getBrowserType() === "Apple Safari" ? "iOS" : "Android",
                    offlineDownload: false
                })
                this.instantiateVideo(res?.data)
            } else {
                this.instantiateVideo()
            }
        }

        this.videoRef.current.onerror = this.onError
        const seekBall = document.getElementById(`custom-seekbar_${this.state.contentId}`)
        seekBall.value = 0

        seekBall.addEventListener("click", (e) => {
            e.stopPropagation()
            let seekTime = player.duration() * (seekBall.value / 1000)
            player.currentTime(seekTime)
        })

        const playerEle = document.querySelectorAll(`#videojs_${this.state.contentId} video`)
        const controlBar = document.getElementById(`custom-controlBar_${this.state.contentId}`)
        let timeOut = null
        if (playerEle?.length > 0) {
            playerEle[0].addEventListener("mousemove", () => {
                clearTimeout(timeOut)
                timeOut = setTimeout(() => {
                    controlBar.classList.remove("show")
                }, 100000)
            })
            playerEle[0].addEventListener("play", () => {
                console.log("play")
            })
            playerEle[0].addEventListener("pause", () => {
                console.log("pause")
            })
        }
    }

    componentWillUnmount() {
        if (this.videoRef.current) {
            this.videoRef.current.onerror = undefined
        }
        if (player) {
            player.dispose()
            player = undefined
        }
    }

    onReady(e) {
        let playBtn = document.getElementById(`play-btn_${this.state.contentId}`)
        let muteBtn = document.getElementById(`custom-mute_${this.state.contentId}`)

        playBtn.addEventListener("click", (e) => {
            e.stopPropagation()
            if (player.paused()) {
                player.play()
            } else {
                player.pause()
            }
        })

        muteBtn.addEventListener("click", (e) => {
            e.stopPropagation()
            if (player.muted()) {
                this.unMute(false)
            } else {
                this.mute()
            }
        })
    }

    play() {
        if (player) {
            player.play()
        }
    }

    pause() {
        if (player) {
            player.pause()
        }
    }
    onVolume() {
        this.setState({ isMuted: player.muted() })
    }
    isPaused() {
        if (player) {
            return player.paused()
        }
        return false
    }

    onPause() {
        this.setState({
            isPaused: player.paused(),
            isMuted: player.muted()
        })
        let playBtn = document.getElementById(`play-btn_${this.state.contentId}`)
        playBtn.firstChild.src = playIcon
    }

    onPlay() {
        this.setState({
            error: undefined,
            isPaused: player.paused(),
            isMuted: player.muted()
        })
        const controlBar = document.getElementById(`custom-controlBar_${this.state.contentId}`)
        if (!controlBar.classList.contains("show")) {
            controlBar.classList.add("show")
        }
        let playBtn = document.getElementById(`play-btn_${this.state.contentId}`)
        playBtn.firstChild.src = pauseIcon
    }

    mute() {
        if (player) {
            let muteBtn = document.getElementById(`custom-mute_${this.state.contentId}`)
            muteBtn.firstChild.src = muteIcon
            player.muted(true)
        }
    }

    unMute() {
        if (player) {
            let muteBtn = document.getElementById(`custom-mute_${this.state.contentId}`)
            muteBtn.firstChild.src = unmuteIcon
            player.muted(false)
        }
    }

    onTimeupdate() {
        if (player) {
            let seekBall = document.getElementById(`custom-seekbar_${this.state.contentId}`)
            let progressBar = document.getElementById(`progressBar_${this.state.contentId}`)
            let seekPos = player.currentTime() * (100 / player.duration())
            progressBar.style.width = seekPos + "%"
            seekBall.value = seekPos * 10
            document.getElementById(`custom-timer_${this.state.contentId}`).innerText = toHHMMSS(
                player.duration() - player.currentTime()
            )
            let tooltip = document.getElementById(`durtimeText_${this.state.contentId}`)
            if (tooltip) {
                tooltip.innerText = Math.round(player.currentTime()) + " secs"
            }
        }
    }

    onError(e) {
        // const err = this?.player.error();
        console.log("Error in video js player", e)
    }

    render() {
        const { contentId } = this.state
        return (
            <div
                data-vjs-player
                style={{
                    backgroundColor: "#000",
                    border: "solid 1px #000",
                    position: "relative"
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        top: 8,
                        left: "25%",
                        width: "50%",
                        zIndex: 1
                    }}
                >
                    {/*{this.renderAlert()}*/}
                </div>
                <video
                    ref={this.videoRef}
                    id={`videojs_${contentId}`}
                    className="video-js video-js-container vjs-default-skin vjs-big-play-centered"
                ></video>
                <div
                    id={`custom-controlBar_${contentId}`}
                    className="custom-controlBar custom-seekbar-hide"
                >
                    <div id={`play-btn_${contentId}`} className="play-btn">
                        <img src={playIcon} alt="" />
                    </div>
                    <div className="custom-seekbar-container">
                        <div id={`progressBar_${contentId}`} className="progressBar"></div>
                        <input
                            type="range"
                            className="custom-seekbar"
                            id={`custom-seekbar_${contentId}`}
                            name="points"
                            min="0"
                            max="1000"
                        />
                    </div>
                    <div id={`custom-timer_${contentId}`} className="custom-timer"></div>
                    <div id={`custom-mute_${contentId}`} className="custom-mute">
                        <img src={muteIcon} alt="" />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                getBuyDrmToken,
                fetchLicenseDetails
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DRMTrancodedVideo)
