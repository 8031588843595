import React, { useMemo } from "react"
import { Draggable } from "react-beautiful-dnd"
import { checkIfUserHasAccess } from "../../../../utils/users"
import { PERMISSIONS } from "../../../../utils/constants"

const SideMenuList = (props) => {
    const [show, setShow] = React.useState(false)

    const canUpdateStatus = useMemo(
        () => checkIfUserHasAccess([PERMISSIONS?.SETTING_FORM_BUILDER_EDIT]),
        []
    )

    const toggleShow = () => {
        setShow(!show)
    }
    const getItemStyle = (isDragging, draggableStyle) => ({
        ...draggableStyle,
        userSelect: "none",
        position: "fixed",
        background: isDragging ? "#d3e7c9" : ""
    })
    return (
        <div className="option-container">
            <span onClick={toggleShow}>
                <i className={`icon-${props.field.icon}`}></i>
                {props.field.name}
            </span>
            <ul className={show ? "show" : "hide"}>
                {props.field.fields.map((item) => {
                    return (
                        <Draggable
                            key={parseInt("" + item.id + "" + props.index)}
                            draggableId={"" + item.id + "" + props.index}
                            index={parseInt(item.id + "" + props.index)}
                            isDragDisabled={!canUpdateStatus}
                        >
                            {(provided, snapshot) => {
                                return (
                                    <div>
                                        <li
                                            ref={provided.innerRef}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className={canUpdateStatus ? "" : "element-options"}
                                        >
                                            {item.name}
                                        </li>
                                        {provided.placeholder}
                                    </div>
                                )
                            }}
                        </Draggable>
                    )
                })}
            </ul>
        </div>
    )
}
export default SideMenuList
