import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom"
import "./style.scss"
import MultilingualDarkIcon from "../../../assets/images/multilingual-dark.svg"
import MultilingualLightIcon from "../../../assets/images/multilingual-light-icon.svg"
import MultilingualActiveIcon from "../../../assets/images/multilingual-active-icon.svg"
import { PERMISSIONS } from "../../../utils/constants"
import { checkIfUserHasAccess } from "../../../utils/users"

const navMenuItems = [
    {
        labelName: "dashboard",
        linkName: "dashboard",
        iconName: "icon-dashboard-3",
        permissions: [PERMISSIONS?.DASHBOARD_VIEW, PERMISSIONS?.DASHBOARD_EDIT]
    },
    {
        labelName: "stream",
        linkName: "stream",
        iconName: "icon-stream",
        permissions: [PERMISSIONS?.STREAM_VIEW],
        children: [
            {
                labelName: "content",
                linkName: "content",
                iconName: "icon-content",
                children: [],
                permissions: [PERMISSIONS?.CONTENT_EDIT, PERMISSIONS?.CONTENT_VIEW]
            },
            {
                labelName: "Epg",
                linkName: "epg",
                iconName: "icon-epg",
                permissions: [PERMISSIONS?.EPG_EDIT, PERMISSIONS?.EPG_VIEW]
            },
            {
                labelName: "Video Pipeline",
                linkName: "videoPipeline",
                iconName: "icon-dashboard-3",
                permissions: [PERMISSIONS?.VIDEO_PIPELINE_EDIT, PERMISSIONS?.VIDEO_PIPELINE_VIEW]
            }
        ]
    },
    {
        labelName: "engage",
        linkName: "engage",
        iconName: "icon-experience",
        permissions: [PERMISSIONS?.ENGAGE_EDIT, PERMISSIONS?.ENGAGE_VIEW],
        children: [
            {
                labelName: "UX Manager",
                linkName: "ux-manager",
                iconName: "icon-editorial",
                permissions: [
                    PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_VIEW,
                    PERMISSIONS?.UXMANAGER_VIEW
                ],
                children: [
                    {
                        labelName: "category",
                        linkName: "category",
                        permissions: [
                            PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_VIEW,
                            PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_EDIT
                        ]
                    }
                ]
            },
            {
                labelName: "Sports",
                linkName: "sports",
                iconName: "icon-editorial",
                permissions: [
                    PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_VIEW,
                    PERMISSIONS?.UXMANAGER_VIEW
                ],
                children: [
                    {
                        labelName: "setup sports",
                        linkName: "setup-sports",
                        permissions: [
                            PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_VIEW,
                            PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_EDIT
                        ]
                    },
                    {
                        labelName: "create/manage pages",
                        linkName: "create-pages",
                        permissions: [
                            PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_VIEW,
                            PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_EDIT
                        ]
                    },
                    {
                        labelName: "sports home page",
                        linkName: "sports-home",
                        permissions: [
                            PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_VIEW,
                            PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_EDIT
                        ]
                    }
                ]
            },
            {
                labelName: "Custom Pages",
                linkName: "custompages",
                iconName: "icon-customepage",
                permissions: [
                    PERMISSIONS?.ENGAGE_UX_MANAGER_CUSTOM_PAGES_VIEW,
                    PERMISSIONS?.ENGAGE_UX_MANAGER_CUSTOM_PAGES_EDIT
                ]
            },
            {
                labelName: "menu design",
                linkName: "menudesign",
                iconName: "icon-menudesign",
                permissions: [
                    PERMISSIONS?.ENGAGE_UX_MANAGER_MENU_DESIGN_EDIT,
                    PERMISSIONS?.ENGAGE_UX_MANAGER_MENU_DESIGN_VIEW
                ]
            }
            // {
            //   labelName: "content enrichment",
            //   linkName: "contentenrichment",
            //   permissions: [
            //     PERMISSIONS?.ENGAGE_UX_MANAGER_CONTENT_ENRICHMENT_EDIT,
            //     PERMISSIONS?.ENGAGE_UX_MANAGER_CONTENT_ENRICHMENT_VIEW,
            //   ],
            // },
        ]
    },
    /*   {
    labelName: "subscription",
    linkName: "subscription",
    iconName: "icon-texonomy",
    permissions: [
      PERMISSIONS?.SUBSCRIPTION_EDIT,
      PERMISSIONS?.SUBSCRIPTION_VIEW,
    ],
    children: [
      {
        labelName: "Add Product",
        linkName: "product",
        iconName: "icon-add_product",
        permissions: [PERMISSIONS?.SUBSCRIPTION_ADD_PRODUCT_VIEW],
      },
      {
        labelName: "Content Product Mapping",
        linkName: "contentproduct",
        iconName: "icon-monetize",
        permissions: [],
      },
      {
        labelName: "Coupons",
        linkName: "coupons",
        iconName: "icon-coupons",
        permissions: [PERMISSIONS?.SUBSCRIPTION_COUPONS_VIEW],
      },
      {
        labelName: "Dashboard",
        linkName: "dashboard",
        iconName: "icon-dashboard1",
        permissions: [PERMISSIONS?.SUBSCRIPTION_DASHBOARD_VIEW],
      },
      {
        labelName: "Reports",
        linkName: "reports",
        iconName: "icon-monetize",
        permissions: [PERMISSIONS?.SUBSCRIPTION_VIEW_REPORT_VIEW],
      },
    ],
  }, */
    {
        labelName: "Monetize",
        linkName: "monetize",
        iconName: "icon-monetize",
        permissions: [PERMISSIONS?.MONETIZE_EDIT, PERMISSIONS?.MONITIZE_VIEW],
        children: [
            {
                labelName: "product placement",
                linkName: "placement",
                iconName: "icon-productplacement",
                permissions: [
                    PERMISSIONS?.MONETIZE_PRODUCT_PLACEMENT_EDIT,
                    PERMISSIONS?.MONETIZE_PRODUCT_PLACEMENT_VIEW
                ]
            }
            /*  {
        labelName: "roi maximiser",
        linkName: "maximiser",
        iconName: "icon-roimaximiser",
        permissions: [PERMISSIONS?.MONETIZE_ROI_MAXIMISER_EDIT, PERMISSIONS?.MONETIZE_ROI_MAXIMISER_VIEW],
      }, */
            /*     {
        labelName: "subscription",
        linkName: "subscription",
        iconName: "icon-texonomy",
        permissions: [PERMISSIONS?.SUBSCRIPTION_EDIT, PERMISSIONS?.SUBSCRIPTION_VIEW],
        children: [
          {
            labelName: "create plan",
            linkName: "createplan",
          },
          {
            labelName: "subscribers",
            linkName: "subscribers",
            permissions: [PERMISSIONS?.SUBSCRIPTION_EDIT, PERMISSIONS?.SUBSCRIPTION_VIEW]
          },
        ],
      }, */
        ]
    },
    {
        labelName: "settings",
        linkName: "settings",
        iconName: "icon-settings",
        permissions: [PERMISSIONS?.SETTING_VIEW, PERMISSIONS?.SETTING_EDIT],
        children: [
            {
                labelName: "Configuration",
                linkName: "config",
                // assetsIcon: {
                //   darkMode: MultilingualDarkIcon,
                //   lightMode: MultilingualLightIcon,
                //   activeIcon: MultilingualActiveIcon,
                // },
                // permissions: [
                //   PERMISSIONS?.BASIC_CONFIGURATION_EDIT,
                //   PERMISSIONS?.BASIC_CONFIGURATION_VIEW,
                // ],
                iconName: "icon-settings",
                children: [
                    {
                        labelName: "Basic Configuration",
                        linkName: "basic-config",
                        assetsIcon: {
                            darkMode: MultilingualDarkIcon,
                            lightMode: MultilingualLightIcon,
                            activeIcon: MultilingualActiveIcon
                        },
                        permissions: [
                            PERMISSIONS?.BASIC_CONFIGURATION_VIEW,
                            PERMISSIONS?.BASIC_CONFIGURATION_EDIT
                        ]
                    },
                    {
                        labelName: "Timezone",
                        linkName: "timezone",
                        assetsIcon: {
                            darkMode: MultilingualDarkIcon,
                            lightMode: MultilingualLightIcon,
                            activeIcon: MultilingualActiveIcon
                        },
                        permissions: [PERMISSIONS?.TIMEZONE_VIEW, PERMISSIONS?.TIMEZONE_EDIT]
                    }
                ]
            },
            {
                labelName: "user management",
                linkName: "management",
                iconName: "icon-user-management",
                permissions: [
                    PERMISSIONS?.SETTING_USER_MANAGEMENT_EDIT,
                    PERMISSIONS?.SETTING_USER_MANAGEMENT_VIEW
                ],
                children: [
                    {
                        labelName: "users",
                        linkName: "users",
                        permissions: [
                            PERMISSIONS?.SETTING_USER_MANAGEMENT_USERS_EDIT,
                            PERMISSIONS?.SETTING_USER_MANAGEMENT_USERS_VIEW
                        ]
                    },
                    {
                        labelName: "roles",
                        linkName: "roles",
                        permissions: [
                            PERMISSIONS?.SETTING_USER_MANAGEMENT_ROLES_EDIT,
                            PERMISSIONS?.SETTING_USER_MANAGEMENT_ROLES_VIEW
                        ]
                    }
                ]
            },
            {
                labelName: "Customer",
                linkName: "customerbranding",
                iconName: "icon-customepage",
                permissions: [
                    PERMISSIONS?.SETTING_CUSTOMER_EDIT,
                    PERMISSIONS?.SETTING_CUSTOMER_VIEW
                ]
            },
            // {
            //   labelName: "form builder",
            //   linkName: "formbuilder",
            //   iconName: "icon-formbuilder",
            //   permissions: [PERMISSIONS?.SETTING_FORM_BUILDER_EDIT, PERMISSIONS?.SETTING_FORM_BUILDER_VIEW],
            // },
            {
                labelName: "multilingual",
                linkName: "multilingual",
                assetsIcon: {
                    darkMode: MultilingualDarkIcon,
                    lightMode: MultilingualLightIcon,
                    activeIcon: MultilingualActiveIcon
                },
                permissions: [
                    PERMISSIONS?.SETTING_MULTILINGUAL_EDIT,
                    PERMISSIONS?.SETTING_MULTILINGUAL_VIEW
                ]
            },
            {
                labelName: "Drm Configuration",
                linkName: "drm-config",
                assetsIcon: {
                    darkMode: MultilingualDarkIcon,
                    lightMode: MultilingualLightIcon,
                    activeIcon: MultilingualActiveIcon
                },
                permissions: [PERMISSIONS?.DRM_EDIT, PERMISSIONS?.DRM_VIEW]
            }
        ]
    }
]

class SideNave extends Component {
    state = {
        activeTab: "dashboard",
        activeTabIndex: null
    }

    handleToggleTab = (name, index) => {
        this.setState((prevState) => ({
            activeTab: prevState.activeTab !== name ? name : "",
            activeTabIndex: index
        }))
    }
    handleSubmenu = (name) => {
        this.setState({
            activeSubMenu: name
        })
    }
    openLink = (url) => {
        window.open(url)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.contentList !== "" &&
            this.props.contentList !== "" &&
            this.props.contentList !== undefined &&
            this.props.contentList.length > 0
        ) {
            const parentIndex =
                navMenuItems && navMenuItems.findIndex((item) => item.labelName === "stream")
            const childIndex =
                parentIndex > -1 &&
                navMenuItems &&
                navMenuItems[parentIndex] &&
                navMenuItems[parentIndex].children.findIndex((item) => item.labelName === "content")

            const additionalContentTypeItems = [
                {
                    labelName: "Metadata Ingestion",
                    linkName: "metadata-ingestion",
                    id: `metadata-ingestion-1`,
                    name: "metadata-ingestion",
                    permissions: [PERMISSIONS.BULK_UPLOAD_EDIT, PERMISSIONS.BULK_UPLOAD_VIEW]
                }
            ]

            if (this.props.contentList && this.props.contentList.length) {
                if (
                    navMenuItems &&
                    navMenuItems[parentIndex] &&
                    navMenuItems[parentIndex].children &&
                    navMenuItems[parentIndex].children[childIndex] &&
                    navMenuItems[parentIndex].children[childIndex].children.length !==
                        this.props.contentList.length + additionalContentTypeItems?.length
                ) {
                    this.props.contentList.map((content, i) => {
                        if (content) {
                            navMenuItems &&
                                navMenuItems[parentIndex] &&
                                navMenuItems[parentIndex].children &&
                                navMenuItems[parentIndex].children[childIndex] &&
                                navMenuItems[parentIndex].children[childIndex].children.push({
                                    labelName: content,
                                    linkName: content.split(" ").join(""),
                                    id: i,
                                    name: content.split(" ").join("")
                                })
                        }
                        return content
                    })
                    // Add metadata ingestion tab for the first time
                    navMenuItems &&
                        navMenuItems?.[parentIndex] &&
                        navMenuItems?.[parentIndex]?.children &&
                        navMenuItems[parentIndex].children[childIndex] &&
                        navMenuItems[parentIndex].children[childIndex].children.push(
                            ...additionalContentTypeItems
                        )
                }
            }
        }
    }

    componentDidMount() {
        const {
            history: {
                location: { pathname }
            }
        } = this.props
        navMenuItems &&
            navMenuItems.forEach((nav, index) => {
                if (pathname === nav.linkName || pathname.indexOf(nav.linkName) > -1) {
                    this.setState({
                        activeTab: nav.linkName,
                        activeTabIndex: index
                    })
                }
            })
    }

    handleClick = (e, array) => {
        if (Array.isArray(array) && array.length) {
            e.preventDefault()
        }
    }

    adminItems = () => {
        return (
            <>
                <li
                    key={"linkname"}
                    className={`dropdown-menu`}
                    onClick={() => this.handleToggleTab("", 2)}
                >
                    <a
                        // onClick={() =>
                        //   this.openLink("https://console.firebase.google.com/?pli=1")
                        // }
                        href="https://console.firebase.google.com/?pli=1"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {"Feature Access"}
                    </a>
                </li>
                <li
                    key={"linkname"}
                    className={`dropdown-menu`}
                    onClick={() => this.handleToggleTab("", 2)}
                >
                    <a
                        // onClick={() =>
                        //   this.openLink("https://console.firebase.google.com/?pli=1")
                        // }
                        href="https://console.firebase.google.com/?pli=1"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {"Superadmin Management"}
                    </a>
                </li>
            </>
        )
    }

    renderIcon = (child, isSubActive) => {
        const { isDarkMode } = this.props
        const { activeSubMenu } = this.state

        let imageIcon
        if (activeSubMenu === child?.linkName || isSubActive) {
            imageIcon = child?.assetsIcon?.activeIcon
        } else {
            imageIcon = isDarkMode ? child?.assetsIcon?.darkMode : child?.assetsIcon?.lightMode
        }

        return (
            <div className="iconimg-container">
                <img className="icon-img" src={imageIcon} alt="icon" />
                {child?.labelName}{" "}
            </div>
        )
    }

    checkMenuItemPermission = (menuItem) => {
        return checkIfUserHasAccess(menuItem?.permissions)
    }

    // To render Menu Items which do not have any children
    renderSingleMenuItem = ({ menuItem, index, isActive }) => {
        if (!this.checkMenuItemPermission(menuItem)) {
            return null
        }

        return (
            <li
                key={menuItem.linkName}
                className={`dropdown-menu ${isActive ? "active" : ""}`}
                onClick={() => this.handleToggleTab(menuItem.linkName, index)}
            >
                {menuItem.type !== "webUrl" ? (
                    <Link to={`/${menuItem.linkName}`}>
                        <i className={`${menuItem.iconName}`}></i>
                        {menuItem.labelName}
                    </Link>
                ) : (
                    <a
                        // onClick={() =>
                        //   this.openLink("https://console.firebase.google.com/?pli=1")
                        // }
                        href="https://console.firebase.google.com/?pli=1"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i className={`${menuItem.iconName}`}></i>
                        {menuItem.labelName}
                    </a>
                )}
            </li>
        )
    }

    renderMenuItemsWithChildren = ({ menuItem, index, isActive }) => {
        const {
            history: { location }
        } = this.props
        const { activeTab } = this.state

        let pathname = location.pathname

        if (!this.checkMenuItemPermission(menuItem)) {
            return null
        }

        return (
            <Fragment key={menuItem.linkName}>
                <li
                    className={`dropdown-menu ${
                        activeTab === menuItem.linkName || isActive ? "active" : ""
                    }`}
                    key={index}
                    onClick={() => this.handleToggleTab(menuItem.linkName, index)}
                    on={() => {
                        this.setState({ activeTab: null })
                    }}
                    onMouseLeave={() => {
                        this.setState({ activeSubMenu: null })
                    }}
                >
                    <Link
                        to={{
                            pathname: ``,
                            contentId: {
                                id: menuItem.children[0] ? menuItem.children[0].id : 0
                            }
                        }}
                    >
                        <i className={`${menuItem.iconName}`}></i>
                        {menuItem.labelName}
                        {menuItem.children && (
                            <i
                                className={
                                    activeTab === menuItem.linkName
                                        ? "icon-arrow-up"
                                        : "icon-arrow-down1"
                                }
                                style={{ float: "right" }}
                            />
                        )}
                    </Link>
                    <ul
                        className="menu-dropdown-list"
                        onClick={(event) => {
                            event.stopPropagation()
                        }}
                    >
                        {activeTab === menuItem.linkName &&
                            menuItem.children &&
                            menuItem.children.map((child, index) => {
                                return this.renderSubMenuItems({ menuItem, child, pathname })
                            })}
                    </ul>
                </li>
            </Fragment>
        )
    }

    // To render menuItems which contains sub routes
    renderSubMenuItems = ({ menuItem, child, pathname }) => {
        const { activeSubMenu } = this.state

        if (!this.checkMenuItemPermission(child)) {
            return null
        }
        let isSubActive =
            child.linkName.replace(/\s+/g, "-").toLowerCase() ===
            (pathname.split("/")[2] && pathname.split("/")[2].toLowerCase())

        return (
            <li
                key={`${menuItem.linkName}-${child.linkName}`}
                className={isSubActive ? "active" : ""}
                onMouseEnter={(e) => this.handleSubmenu(child.linkName)}
            >
                {child.labelName === "roi maximiser" ? (
                    <a
                        // onClick={() =>
                        //   this.openLink("https://portal.vionlabs.com")
                        // }
                        href="https://portal.vionlabs.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i className={`${menuItem.iconName}`}></i>
                        {child.labelName}
                    </a>
                ) : (
                    <Link
                        to={{
                            pathname: child.children
                                ? ""
                                : `/${menuItem.linkName}/${child.linkName}`,
                            contentType: { linkName: child.linkName }
                        }}
                        onClick={(e) => this.handleClick(e, child.children)}
                    >
                        {child?.assetsIcon ? (
                            this.renderIcon(child)
                        ) : (
                            <>
                                <i className={child.iconName ? child.iconName : "vr-icon-circle"} />
                                {child.labelName}
                            </>
                        )}
                        {child.children && (
                            <i className={"icon-arrow-right1"} style={{ float: "right" }} />
                        )}
                    </Link>
                )}
                {child.children && (
                    <ul
                        className={`submenu-dropdown-list ${
                            activeSubMenu === child.linkName && "show"
                        }`}
                        onClick={(event) => {
                            event.stopPropagation()
                        }}
                    >
                        {child.children.map((child1, index) => {
                            return this.renderMenuItemsGrandChild({
                                menuItem,
                                child1,
                                child,
                                pathname
                            })
                        })}
                    </ul>
                )}
            </li>
        )
    }

    renderMenuItemsGrandChild = ({ menuItem, child1, child, pathname }) => {
        if (!this.checkMenuItemPermission(child1)) {
            return null
        }

        let isSubActive =
            child1.linkName.replace(/\s+/g, "-").toLowerCase() ===
            (pathname.split("/")[3] && pathname.split("/")[3].toLowerCase())
        return (
            <li
                key={`${menuItem.linkName}-${child1.linkName}`}
                className={`${isSubActive ? "active" : ""} ${
                    child1.linkName === "metadata-ingestion" ? "metadata-ingestion" : ""
                }`}
            >
                <Link
                    to={{
                        pathname: `/${menuItem.linkName}/${child.linkName}/${child1.linkName}`,
                        contentType: {
                            linkName: child1.linkName
                        }
                    }}
                >
                    <i className={"vr-icon-circle"} /> {child1.labelName}
                </Link>
            </li>
        )
    }

    render() {
        const {
            history: { location },
            isSubscriptionEnabled
        } = this.props

        if (!isSubscriptionEnabled) {
            const index =
                navMenuItems && navMenuItems.findIndex((item) => item?.labelName === "subscription")
            if (index !== -1) {
                navMenuItems.splice(index, 1)
            }
        }

        const menuItems =
            navMenuItems &&
            navMenuItems.map((menuItem, index) => {
                let isActive = false
                let pathname = location.pathname
                if (
                    (pathname.split("/")[1] &&
                        pathname.split("/")[1] ===
                            menuItem.linkName.replace(/\s+/g, "-").toLowerCase()) ||
                    (pathname === "/" && menuItem.linkName === "dashboard")
                ) {
                    isActive = true
                }
                return menuItem.children
                    ? this.renderMenuItemsWithChildren({ menuItem, index, isActive })
                    : this.renderSingleMenuItem({ menuItem, index, isActive })
            })
        return (
            <nav>
                <ul className="vr-nav">
                    {/*    {this.adminItems()} */} {/* admin work */}
                    {menuItems}
                </ul>
            </nav>
        )
    }
}

export default SideNave
