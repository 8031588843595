import React, { useEffect } from "react"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"

import store from "./store"
import AdminHome from "./containers/AdminHome"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./styles/main.scss"

function App() {
    useEffect(() => {
        console.log("env&&", process.env)
        // Accessing scss variable "--background-color"
        // and "--text-color" using plain JavaScript
        // and changing the same according to the state of "darkTheme"
        const root = document.documentElement
        root.style.setProperty("--main-primary-color", "#00A3FA")
    }, [])

    return (
        <Provider store={store}>
            <BrowserRouter>{<AdminHome />}</BrowserRouter>
        </Provider>
    )
}

export default App
