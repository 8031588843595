import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import TotalTenants from "./TotalTenants"
import TenantSubscribers from "./TenantSubscribers"

import "./style.scss"
import UsersByDevices from "./UsersByDevices"
import TenantAcrossWorld from "./TenantAcrossWorld"
import { fetchAdminDashboard } from "./api/action"

/* admin work */
class StatsAdminDashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isSuperAdmin: true,
            selectedTab: 0
        }
    }

    componentDidMount() {
        this.loadHandler()
    }

    setTheme = (props) => {
        this.setState({
            isDarkMode: props?.isDarkMode
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            this.setTheme(nextProps)
        }
    }

    loadHandler = async () => {
        const { fetchAdminDashboard } = this.props
        let response = await fetchAdminDashboard()
        this.setState({
            statsData: response?.data
        })
    }

    render() {
        const { statsData, selectedTab, isDarkMode } = this.state

        return (
            <>
                <div className="stats-dashboard">
                    <div className="dashboard-header">
                        <h2
                            onClick={() => this.setState({ selectedTab: 0 })}
                            className={selectedTab === 0 && "active"}
                        >
                            ANALYTICS DASHBOARD
                        </h2>
                        <h2
                            onClick={() => this.setState({ selectedTab: 1 })}
                            className={selectedTab === 1 && "active"}
                        >
                            CMS DASHBOARD
                        </h2>
                    </div>
                    {selectedTab === 1 && (
                        <div className="stats-dashboard-container">
                            {/*   <p className="dashboard-heading">DASHBOARD</p> */}
                            <div className="first-container">
                                <div className="tenant-info-container">
                                    <div className="box-shadow first-box">
                                        <TotalTenants
                                            data={statsData && statsData["allTenant"]}
                                            history={this.props.history}
                                        />
                                    </div>
                                    <div className="box-shadow second-box">
                                        <TenantSubscribers
                                            data={statsData && statsData["all_tenant_subscriber"]}
                                        />
                                    </div>
                                </div>

                                {/*   <div className="box-shadow third-box">
              <TenantSession
                data={statsData && statsData["session_by_tenant"]}
              />
            </div> */}
                                <div className="box-shadow fourth-box">
                                    <TenantAcrossWorld
                                        data={statsData && statsData["tenant_across_world"]}
                                        selectedTab={selectedTab}
                                        isDarkMode={isDarkMode}
                                    />
                                </div>
                                <div className="box-shadow fifth-box">
                                    {statsData && statsData["user_by_device"] && (
                                        <UsersByDevices
                                            isDarkMode={isDarkMode}
                                            data={statsData["user_by_device"]}
                                        />
                                    )}
                                </div>
                                {/* <div className="box-shadow fifth-box">
              {statsData && statsData["upcomming_activity"] && (
                <UpcomingActivities data={statsData["upcomming_activity"]} />
              )}
            </div>
            <div className="box-shadow fifth-box">
              <OnlineQuery />
            </div> */}
                            </div>
                        </div>
                    )}
                    {selectedTab === 0 && (
                        <div className={"iframe-container"}>
                            <iframe
                                src="https://suite.npaw.com/"
                                frameborder="0"
                                title="npaw dashboard"
                            ></iframe>
                            <a
                                href={"https://suite.npaw.com/"}
                                target={"_blank"}
                                className="iframe-overlay"
                                rel="noopener noreferrer"
                            >
                                {" "}
                            </a>
                        </div>
                    )}
                </div>
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isDarkMode: state?.theme.darkMode
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ fetchAdminDashboard }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StatsAdminDashboard)
