import { ACTION } from "./constant"
import UserManagementServiceInstance from "./service"

export const addUserDetails = (params) => {
    return (dispatch) => {
        return UserManagementServiceInstance.addUserDetails(params)
            .then((response) => {
                dispatch({
                    type: ACTION.ADD_USER_DETAILS,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting role list ", error)
                return error
            })
    }
}

export const fetchAllRoles = (params) => {
    return (dispatch) => {
        return UserManagementServiceInstance.fetchAllRoles(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_ALL_ROLES,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting user list ", error)
            })
    }
}

export const fetchRolesList = (params) => {
    return (dispatch) => {
        return UserManagementServiceInstance.fetchRolesList(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_ROLES_LIST,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting user list ", error)
            })
    }
}

export const fetchPermissionsList = (params) => {
    return (dispatch) => {
        return UserManagementServiceInstance.fetchPermissionsList(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_PERMISSIONS_LIST,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting user list ", error)
            })
    }
}

export const AddRole = (params) => {
    return (dispatch) => {
        return UserManagementServiceInstance.AddRole(params)
            .then((response) => {
                dispatch({
                    type: ACTION.ADD_ROLE,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting user list ", error)
                return error
            })
    }
}

export const softDeleteRole = (params) => {
    return (dispatch) => {
        return UserManagementServiceInstance.softDeleteRole(params)
            .then((response) => {
                dispatch({
                    type: ACTION.SOFT_DELETE_ROLE,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting user list ", error)
            })
    }
}

export const fetchUsersList = (params) => {
    return (dispatch) => {
        return UserManagementServiceInstance.fetchUsersList(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_USERS_LIST,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting user list ", error)
            })
    }
}

// export const fetchAllUsersList = () => {
//   return dispatch => {
//     return UserManagementServiceInstance.fetchAllUsersList().then((response)=>{
//       dispatch({
//         type: ACTION.FETCH_ALL_USERS_LIST,
//         apiResponse: response.data,
//       });
//       return response.data;
//     }).catch((error) => {
//       console.log("Got error in getting user list ",error);
//     });
//   }
// }

export const changeUserState = (params) => {
    return (dispatch) => {
        return UserManagementServiceInstance.changeUserState(params)
            .then((response) => {
                dispatch({
                    type: ACTION.CHANGE_USER_STATE,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in changing user state ", error)
            })
    }
}

export const softDeleteUser = (params) => {
    return (dispatch) => {
        return UserManagementServiceInstance.softDeleteUser(params)
            .then((response) => {
                dispatch({
                    type: ACTION.SOFT_DELETE_USER,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting user list ", error)
            })
    }
}
