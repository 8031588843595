import React, { Component } from "react"
import VideoPlayerJS from "../../../VideoJsPlayer"
import "./style.scss"
import get from "lodash/get"
import { bindActionCreators } from "redux"
import { fetchAddClipList, fetchAdsPreviewUrl, savePreviewUrl } from "../../APIs/action"
import { connect } from "react-redux"
import { imgPathPrefix } from "../../APIs/constant"
import ClipAccordion from "./ClipAccordion"
import Button from "../../../common/Button"
import { toast } from "react-toastify"
import { BUTTON_CONSTANTS, PERMISSIONS, TEXT_CONSTANTS } from "../../../../utils/constants"
import { AuthWrapper } from "../../../common/AuthWrapper"

class AdsClips extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedAds: {},
            previewUrl: this.props.selectedProduct.content.play_url,
            useOriginal: false,
            selectedAcc: null
        }
    }

    componentDidMount() {
        this.props.fetchAddClipList()
    }
    handleAccordion = (timeFrame) => {
        this.setState({
            selectedAcc: this.state.selectedAcc === timeFrame ? "" : timeFrame
        })
    }
    showAddClips = () => {
        let accordionList = []
        for (const [key, value] of Object.entries(this.props.addClipList)) {
            let ele = (
                <ClipAccordion
                    selectedAcc={this.state.selectedAcc}
                    handleAccordion={this.handleAccordion}
                    selectedClip={this.state.selectedAds[key] || null}
                    handleSelectClip={this.handleSelectClip}
                    selectedAdsPreviewUrl={this.selectedAdsPreviewUrl}
                    timeFrame={key}
                    data={value}
                />
            )
            accordionList.push(ele)
        }
        return accordionList
    }
    selectedAdsPreviewUrl = () => {
        let arr = []
        for (const [key, value] of Object.entries(this.state.selectedAds)) {
            if (value) {
                let obj = {
                    contentId: value,
                    timeFrame: key
                }
                arr.push(obj)
            }
        }
        if (arr.length) {
            this.setState({
                useOriginal: false
            })
            this.props.fetchAdsPreviewUrl({ ads: arr })
        } else {
            this.setState({
                previewUrl: this.props.selectedProduct.content.play_url,
                useOriginal: true
            })
        }
    }
    handleSelectClip = (id, timeFrame) => {
        if (this.state.selectedAds[timeFrame] && this.state.selectedAds[timeFrame] === id) {
            let obj = this.state.selectedAds
            delete obj[timeFrame]
            this.setState(
                {
                    selectedAds: {
                        ...obj
                    }
                },
                () => this.selectedAdsPreviewUrl()
            )
        } else {
            this.setState(
                {
                    selectedAds: {
                        ...this.state.selectedAds,
                        [timeFrame]: id
                    }
                },
                () => this.selectedAdsPreviewUrl()
            )
        }
    }
    static getDerivedStateFromProps(nextProps, state) {
        let obj = {}
        if (
            !state.useOriginal &&
            nextProps.adsPreviewUrl &&
            state.previewUrl !== nextProps.adsPreviewUrl
        ) {
            obj = {
                ...obj,
                previewUrl: nextProps.adsPreviewUrl
            }
        }
        return obj
    }
    handleSave = () => {
        let obj = this.props.selectedProduct.content
        obj.id = this.props.selectedProduct.id
        obj.play_url = this.state.previewUrl
        this.props.savePreviewUrl(obj, this.props.selectedProduct.contentEntityId).then((data) => {
            toast.success(get(data, "message"))
            this.props.handleCancelBtn()
        })
    }
    render() {
        const { addClipList } = this.props
        const { selectedAds, selectedAcc } = this.state
        return (
            <>
                <div className="vr-content-holder">
                    <div className="ads-clip">
                        <div className="vr-cont-player">
                            <div className="vr-cont-player-title">
                                <h2>{TEXT_CONSTANTS?.ORIGINAL_VIDEO}</h2>
                                <p>{TEXT_CONSTANTS?.SELECT_CLIPS_PLACEHOLDER}</p>
                            </div>
                            <div className="video-frame-wrapper">
                                {addClipList && (
                                    <VideoPlayerJS
                                        src={{
                                            videoUrl: this.state.previewUrl,
                                            videoPoster: this.props.selectedProduct.content
                                                ? `${imgPathPrefix}${this.props.selectedProduct.content.cover_image}`
                                                : "https://dn8wbd49q0doa.cloudfront.net/content/1600430166525-2.1.png"
                                        }}
                                        timeFrames={addClipList}
                                        id="preview"
                                        selectedAcc={selectedAcc}
                                        selectedAds={selectedAds}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="vr-cont-clips">
                            <div className="vr-cont-clips-title">
                                <h2>{TEXT_CONSTANTS?.CLIPS_WITH_ADS}</h2>
                                <p>{TEXT_CONSTANTS?.APPLY_CLIPS_VIDEO}</p>
                            </div>
                            <ul className="accordion-container">
                                {addClipList && this.showAddClips()}
                            </ul>
                        </div>
                    </div>
                    <div className="save-and-next">
                        <AuthWrapper permissions={[PERMISSIONS?.MONETIZE_PRODUCT_PLACEMENT_EDIT]}>
                            <Button
                                cName={"btn primary-btn"}
                                bType={"submit"}
                                bValue={BUTTON_CONSTANTS?.SUBMIT?.toUpperCase()}
                                bIcon={"icon-left-arrow"}
                                clickHandler={this.handleSave}
                                disabled={
                                    this.state.previewUrl ===
                                    this.props.selectedProduct.content.play_url
                                }
                            />
                        </AuthWrapper>
                        <Button
                            cName={"btn cancel-btn"}
                            bType={"button"}
                            bValue={BUTTON_CONSTANTS?.CANCEL?.toUpperCase()}
                            clickHandler={this.props.handleCancelBtn}
                        />
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        addClipList: get(state.productPlacement, "addClipList", null),
        adsPreviewUrl: get(state.productPlacement, "adsPreviewUrl", null)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                fetchAddClipList,
                fetchAdsPreviewUrl,
                savePreviewUrl
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdsClips)
