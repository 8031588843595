import React, { useCallback } from "react"
import { imagePathPrefix } from "../../../config/apiPath"

import "./style.scss"
import { getImageRecommendedText } from "../../../utils/common"

function ImageUpload(props) {
    const {
        cName,
        name,
        id,
        listRef,
        value,
        label,
        size,
        uploadImage,
        disabled,
        required,
        removeImage,
        isEdit,
        isOverlay,
        showPathPrefix = true
    } = props

    const imageChangeHandler = (e) => {
        const { files } = e.target

        const modifiedName = files?.[0]?.name?.replace(/[^a-zA-Z.0-9_-]/g, "")
        const modifiedFile = new File([files[0]], modifiedName, {
            type: files[0]?.type
        })

        let data = new FormData()
        data.append("image", modifiedFile)
        uploadImage(data, id, name)
    }

    const removeImageHandle = useCallback(() => {
        if (removeImage) return removeImage(id)
    }, [removeImage])

    return (
        <div className={cName + " imageUpload"}>
            <span className="img-header-label">
                {name} {required && <span className="required"> *</span>}
            </span>
            <div>
                <div
                    className={`addPoster ${
                        disabled ? "no-cursor-allowed" : "show-pointer-cursor"
                    }`}
                >
                    {value ? (
                        <>
                            <img src={showPathPrefix ? `${imagePathPrefix}${value}`: value} alt="" id="showImg" />
                            {!disabled && (
                                <div className="overlay-btn">
                                    {/* btn primary-btn */}
                                    <label htmlFor={name} className="btn ">
                                        Change
                                    </label>
                                    <button className="btn" onClick={removeImageHandle}>
                                        Remove
                                    </button>
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            <label htmlFor={name} className="show-pointer-cursor upload-cta">
                                <i className="icon-plus"></i>
                                <span>{label}</span>
                            </label>
                        </>
                    )}
                </div>
            </div>
            <input
                type={"file"}
                data-id={id}
                id={name}
                name={name}
                ref={listRef}
                onChange={imageChangeHandler}
                disabled={disabled}
            />
            {size && <span className="img-size">{getImageRecommendedText(name)}</span>}
        </div>
    )
}

export default ImageUpload
