import React from "react"
import "./style.scss"
import accessDeniedDMImg from "../../../assets/images/accessDenied-DM.svg"
import accessDeniedLMImg from "../../../assets/images/accessDenied-LM.svg"

import { useSelector } from "react-redux"

export default function NotFound(props) {
    const TEXT_LABEL = {
        errorCode: "404",
        errorMsg: "Oops! Page Not Found",
        errorDesc1: "Sorry! The page you are looking for is not available ",
        errorDesc2: "Try another page or go back to previous screen "
    }

    const isDarkMode = useSelector((state) => state?.theme?.darkMode)

    return (
        <div className="vr-content-holder not-found-conatiner">
            <img
                src={isDarkMode ? accessDeniedDMImg : accessDeniedLMImg}
                alt="Access Denied"
                className="access-denied-image"
            ></img>
            <h2 className="not-found-access-heading not-found-access-heading-color">
                {TEXT_LABEL?.errorCode}
            </h2>
            <h3 className="not-found-access-heading-color">{TEXT_LABEL?.errorMsg}</h3>
            <p className="desc-color">{TEXT_LABEL?.errorDesc1}</p>
            <p className="desc-color">{TEXT_LABEL?.errorDesc2}</p>
        </div>
    )
}
