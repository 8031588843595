import React, { Component } from "react"
import ReactPlayer from "react-player"
import "./style.scss"
import { BUTTON_CONSTANTS } from "../../../utils/constants"

export default class VideoModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            src: "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
            poster: "https://raw.githubusercontent.com/waskito/react-modal-videojs/master/example/public/preview.png",
            show: false
        }
    }

    showModal = () => {
        this.setState({ show: true })
    }

    hideModal = () => {
        this.setState({ show: false })
    }

    render() {
        const { url, onCloseClick } = this.props
        return (
            <>
                <div className="modal"></div>
                <div className="modal-content">
                    {" "}
                    <div onClick={() => onCloseClick()} className="close-btn">
                        {BUTTON_CONSTANTS?.CLOSE?.toUpperCase()}
                    </div>
                    <div className="video-frame-wrapper">
                        <ReactPlayer url={url} controls autoplay width={"100%"} height={"auto"} />
                    </div>
                </div>
            </>
        )
    }
}
