import React, { useCallback, useMemo, useState } from "react"
import "./style.scss"
import { useDispatch } from "react-redux"
import Input from "../common/Input"
import ImageUpload from "../common/imageUpload"
import { BUTTON_CONSTANTS } from "../../utils/constants"
import Button from "../common/Button"
import { ManageSEOInitialState, REQUIRED_FIELDS } from "./api/constant"
import { isEmpty } from "lodash"
import { uploadImage } from "../ContentNav/api/action"

const ManageSEO = (props) => {
    const { data, updateData, onCancel } = props
    const dispatch = useDispatch()

    const [inputValue, setInputValue] = useState("")
    const [formData, setFormData] = useState(
        isEmpty(data.seoTags) ? ManageSEOInitialState : data.seoTags
    )

    const handleChange = useCallback(
        (name, value) => {
            setFormData({ ...formData, [name]: value })
        },
        [formData]
    )

    const handleOnSave = useCallback(() => {
        updateData(data.id, "seoTags", formData)
    }, [formData])

    const handlePromoImageUpload = async (data, id, name) => {
        // To save the image location
        data.append("type", "CONTENT")
        const response = await dispatch(uploadImage(data))
        if (response && response?.status) {
            handleChange(name, response?.data?.path)
        }
    }

    const handleRemoveImage = useCallback(
        (id) => {
            handleChange(id, null)
        },
        [formData]
    )

    const onReset = useCallback(() => {
        setFormData(ManageSEOInitialState)
    }, [])

    const checkButtonState = useMemo(() => {
        // If any required field is empty disable the button
        let isDisabled = false
        REQUIRED_FIELDS.forEach((item) => {
            if (!formData?.[item]) {
                isDisabled = true
            }
        })
        return isDisabled
    }, [formData])

    const handleKeyDown = (event) => {
        if (event.key === " " || event.key === "Enter") {
            event.preventDefault()
            if (inputValue.trim() !== "") {
                setFormData({ ...formData, keywords: [...formData.keywords, inputValue.trim()] })
                setInputValue("")
            }
        } else if (event.key === "Backspace" && inputValue === "") {
            event.preventDefault()
            if (formData.keywords.length > 0) {
                setFormData({ ...formData, keywords: formData?.keywords.slice(0, -1) })
            }
        }
    }

    const handleRemoveWord = (wordToRemove) => {
        setFormData({
            ...formData,
            keywords: formData.keywords.filter((word) => word !== wordToRemove)
        })
    }

    return (
        <div className="vd-menuDesign manage-seo">
            <div className="vr-content-holder basic-container">
                <div className="seo-form">
                    <h2>Metadata for ({data.tabName.en})</h2>
                    <div className="vr-content-holder">
                        <Input
                            name="title"
                            placeholder="Title"
                            value={formData?.title}
                            labelText="Title"
                            required
                            changeHandler={handleChange}
                        />
                        <Input
                            name="description"
                            placeholder="Description"
                            value={formData?.description}
                            labelText="Description"
                            required
                            changeHandler={handleChange}
                        />

                        <div className="meta-keywords">
                            <label>Keywords</label>
                            <div className="input-container">
                                {formData?.keywords.map((word, index) => (
                                    <WordTile key={index} word={word} onRemove={handleRemoveWord} />
                                ))}
                                <input
                                    tabIndex={"1"}
                                    type="text"
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                    placeholder="Type a keyword and press space or enter"
                                    className="input-field"
                                />
                            </div>
                        </div>

                        <Input
                            name="imageAltText"
                            placeholder="Twitter img alt"
                            value={formData?.imageAltText}
                            labelText="Twitter img alt"
                            required
                            changeHandler={handleChange}
                        />
                        <ImageUpload
                            key={"og-img"}
                            name={"ogImage"}
                            id={"ogImage"}
                            label={"OG Image"}
                            value={formData?.ogImage ? formData?.ogImage : null}
                            cName={"landscape"}
                            uploadImage={handlePromoImageUpload}
                            removeImage={handleRemoveImage}
                        />
                    </div>
                    <div className="buttons">
                        <Button
                            cName={"btn secondary-btn"}
                            bValue={BUTTON_CONSTANTS.SAVE}
                            clickHandler={handleOnSave}
                            disabled={checkButtonState}
                        />
                        <Button
                            cName={"btn cancel-btn"}
                            bValue={BUTTON_CONSTANTS.CANCEL}
                            clickHandler={onCancel}
                        />
                        <Button
                            cName={"btn cancel-btn"}
                            bValue={BUTTON_CONSTANTS.RESET}
                            clickHandler={onReset}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManageSEO

const WordTile = ({ word, onRemove }) => {
    return (
        <span className="word-tile">
            {word}
            <i className="icon-close" onClick={() => onRemove(word)}></i>
        </span>
    )
}
