import React, { useState } from "react"
import { BUTTON_CONSTANTS, TEXT_CONSTANTS } from "../../../utils/constants"
import Button from "../../common/Button"
import UploadingCSVFile from "../../common/DropzoneFileComponent"
import { WebSeriesDropdownOptions } from "../api/constants"
import Select1 from "../../common/SelectBox"
import { useDispatch, useSelector } from "react-redux"
import fileDownload from "../../../assets/images/file-download.svg"
import { toast } from "react-toastify"
import { submitContentType } from "../api/action"

import "./style.scss"
import Loader from "../../common/Loader"
import { getGMTTimezone, isWebSeriesTitle } from "../../../utils/common"

export default function UploadMetaDataContent({ addNewBackHandler }) {
    const [formData, setFormData] = useState({
        selectedFile: null,
        contentType: null,
        contentSubType: "Brand"
        // timezone: "",
    })

    // const [timezoneData, setTimezoneData] = useState([]);

    const isLoading = useSelector((state) => state?.loader?.isLoading)
    const selectedTimezoneData = useSelector((state) => state?.timezone?.selectedTimezone)
    // useEffect(() => {
    // getTimezoneData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    // const getTimezoneData = async () => {
    //   const response = await dispatch(getAllTimezone());
    //   if (response?.status) {
    //     setTimezoneData(response?.data);
    //     setFormData((prevState) => {
    //       return {
    //         ...prevState,
    //         timezone: response?.data[0],
    //       };
    //     });
    //   }
    // };

    const updateContentType = (state) => {
        const contentTypeValues = state?.formBuilderReducer?.contentType
        return contentTypeValues
            ?.filter(
                (items) =>
                    items?.toLowerCase()?.replace(/\s/g, "") !== "reels" &&
                    items?.toLowerCase()?.replace(/\s/g, "") !== "ads"
            )
            ?.map((contentType) => {
                if (contentType?.toLowerCase()?.replace(/\s/g, "") === "livetv") {
                    return "EPG"
                }
                return contentType
            })
    }

    const contentTypeData = useSelector((state) => updateContentType(state) || [])
    const dispatch = useDispatch()

    const setFormDataHandler = (name, value) => {
        let updatedValue = {
            [name]: value
        }
        if (name !== "selectedFile") {
            updatedValue = {
                [name]: {
                    name: value,
                    id: value
                }
            }
        }
        setFormData((prevState) => {
            return {
                ...prevState,
                ...updatedValue
            }
        })
    }

    const getCurrentContentInformation = (isDownloadTemplate = false) => {
        const { contentType, contentSubType } = formData
        let payload = {
            contentType: contentType?.name.toUpperCase()
        }
        if (formData.contentType?.name?.toLowerCase()?.replace(/\s/g, "") === "webseries") {
                payload.subContentType = contentSubType.name
                ?.replace(/\s/g, "")
                ?.toUpperCase()
        }
        return payload
    }

    const handleSubmit = async () => {
        const { selectedFile } = formData

        const fileData = new FormData()
        fileData.append("file", selectedFile)

        const payload = getCurrentContentInformation()
        Object.keys(payload).forEach(key => {
                fileData.append(key, payload[key]);
        });

        // const extractedString = timezone.match(REGEX?.TIMEZONE_REGEX)?.[1];
        try {
            const response = await dispatch(
                submitContentType(
                    fileData,
                    getGMTTimezone(selectedTimezoneData?.timeZone)
                )
            )

            if (response?.status) {
                toast.success("File uploaded successfully.")
                // Handle success
                addNewBackHandler()
            } else {
                toast.error("File upload failed.")
            }
        } catch (error) {
            console.error("Error:", error)
            // Handle network or other errors
        }
    }

    const onDropHandler = (acceptedFiles) => {
        // Do something with the files
        if (acceptedFiles.length === 0) {
            toast.error("Only CSV files are allowed!")
        }
        if (acceptedFiles.length > 0) {
            setFormDataHandler("selectedFile", acceptedFiles[0])
        }
    }

    const onFileRemoveHandler = (event) => {
        event.preventDefault()
        event.stopPropagation()
        setFormDataHandler("selectedFile", null)
    }

    const isSubmitButtonDisabled = () => {
        return !formData.contentType?.name || !formData?.selectedFile
    }

    // const downloadTemplateHandler = async () => {
    //   const response = await dispatch(downloadTemplateFile(getCurrentContentInformation(true)));

    // };

    let downloadTemplateName = formData.contentType?.name?.toUpperCase()
    if (formData.contentType?.name?.toLowerCase()?.replace(/\s/g, "") === "webseries") {
        downloadTemplateName = formData.contentSubType?.name?.toUpperCase()
    }

    return (
        <div className="upload-metadata-content-wrapper">
            {isLoading && <Loader></Loader>}
            <div className="metadata-content">
                {/* Heading */}
                <h2 className="content-heading">{TEXT_CONSTANTS?.SELECT_CONTENT_TYPE}</h2>

                {/* Dropdown options */}
                <div className="content-type-dropdown-container">
                    {renderContentTypeDropdown({
                        formData,
                        setFormDataHandler,
                        contentTypeData
                        // timezoneData,
                    })}
                </div>

                {/* upload file options */}
                {formData.contentType?.name && (
                    <div className="uploading-file-container">
                        <div className="upload-file-header">
                            <p>{TEXT_CONSTANTS?.UPLOAD_CSV_FILE}</p>
                            <div
                                className="download-btn"
                                // onClick={downloadTemplateHandler}
                                role="button"
                            >
                                <a
                                    href={`https://d1wchz14fblo18.cloudfront.net/templates/v2/${downloadTemplateName}.xlsx`}
                                    className="download-btn"
                                >
                                    <p>{TEXT_CONSTANTS?.DOWNLOAD_TEMPLATE}</p>{" "}
                                    <img src={fileDownload} alt="file-download"></img>
                                </a>
                            </div>
                        </div>
                        <UploadingCSVFile
                            selectedFile={formData?.selectedFile}
                            acceptedFilesType={{
                                "application/vnd.ms-excel": [],
                                "text/csv": [],
                                csv: [],
                                "application/csv": [],
                                "text/x-csv": [],
                                "application/xml": [],
                                "text/xml": [],
                                "application/x-csv": [],
                                "text/comma-separated-values": [],
                                "text/x-comma-separated-values": [],
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                                    [],
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                                    []
                            }}
                            onDropHandler={onDropHandler}
                            onFileRemoveHandler={onFileRemoveHandler}
                        ></UploadingCSVFile>
                    </div>
                )}
            </div>

            {/* Action Button */}
            <div className="btn-wrapper">
                <Button
                    bValue={BUTTON_CONSTANTS?.SUBMIT}
                    clickHandler={handleSubmit}
                    cName="btn primary-btn"
                    disabled={isSubmitButtonDisabled()}
                />
            </div>
        </div>
    )
}

const renderContentTypeDropdown = ({
    formData,
    setFormDataHandler,
    contentTypeData
    // timezoneData,
}) => {
    return (
        <>
            <Select1
                key={"content-type-001"}
                placeholder="-select-"
                options={
                    contentTypeData &&
                    contentTypeData.map((ele) => {
                        return {
                            id: ele,
                            name: isWebSeriesTitle(ele)
                                ? TEXT_CONSTANTS?.SERIES?.toUpperCase()
                                : ele,
                            value: ele
                        }
                    })
                }
                defaultOption={
                    formData.contentType?.name
                        ? {
                              id: formData.contentType?.name,
                              name: isWebSeriesTitle(formData.contentType?.name)
                                  ? TEXT_CONSTANTS?.SERIES
                                  : formData.contentType?.name,
                              value: formData.contentType?.name
                          }
                        : []
                }
                // label={'Content Type'}
                value={formData.contentType}
                required={true}
                name={"contentType"}
                changeHandler={(name, value) => setFormDataHandler(name, value)}
                cName={"contentTypeStyling"}
            />
            {formData.contentType?.name?.toLowerCase()?.replace(/\s/g, "") === "webseries" && (
                <Select1
                    key={"content-subType-001"}
                    placeholder="-select-"
                    options={
                        WebSeriesDropdownOptions &&
                        WebSeriesDropdownOptions.map((ele) => {
                            return {
                                id: ele,
                                name: ele,
                                value: ele
                            }
                        })
                    }
                    defaultOption={
                        formData.contentSubType
                            ? {
                                  id: formData.contentSubType.id,
                                  name: formData.contentSubType.name,
                                  value: formData.contentSubType.value
                              }
                            : []
                    }
                    value={formData.contentSubType}
                    required={true}
                    name={"contentSubType"}
                    changeHandler={(name, value) => setFormDataHandler(name, value)}
                    cName={"contentTypeStyling"}
                />
            )}
            {/* {formData?.contentType?.toLowerCase()?.replace(/\s/g, "") === "epg" && (
        <Select1
          key={"timezone"}
          placeholder="-select-"
          options={
            timezoneData &&
            timezoneData.map((ele) => {
              return {
                id: ele,
                name: ele,
                value: ele,
              };
            })
          }
          defaultOption={
            timezoneData
              ? {
                  id: timezoneData[0],
                  name: timezoneData[0],
                  value: timezoneData[0],
                }
              : []
          }
          value={formData?.timezone}
          name={"timezone"}
          changeHandler={(name, value) => setFormDataHandler(name, value)}
          cName={"contentTypeStyling"}
          isSearchable={true}
        />
      )} */}
        </>
    )
}
