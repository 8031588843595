import React from "react"
import "./style.scss"
import accessDeniedDMImg from "../../../assets/images/accessDenied-DM.svg"
import accessDeniedLMImg from "../../../assets/images/accessDenied-LM.svg"

import { useSelector } from "react-redux"

export default function Unauthorized({ isErrorCodeRequired = true, isErrorMsgRequired = true }) {
    const TEXT_LABEL = {
        errorCode: "403",
        errorMsg: "Ooh! Access Denied",
        errorDesc1: "You don’t have permission to access this page. ",
        errorDesc2: "Ask your system administrator for the access or go back to previous screen"
    }

    const isDarkMode = useSelector((state) => state?.theme?.darkMode)

    return (
        <div className="vr-content-holder unauthorized-conatiner">
            <img
                src={isDarkMode ? accessDeniedDMImg : accessDeniedLMImg}
                alt="Access Denied"
                className="access-denied-image"
            ></img>
            {isErrorCodeRequired && (
                <h2 className="unauthorized-access-heading unauthorized-access-heading-color">
                    {TEXT_LABEL?.errorCode}
                </h2>
            )}
            {isErrorMsgRequired && (
                <h3 className="unauthorized-access-heading-color">{TEXT_LABEL?.errorMsg}</h3>
            )}
            <p className="desc-color">{TEXT_LABEL?.errorDesc1}</p>
            <p className="desc-color">{TEXT_LABEL?.errorDesc2}</p>
        </div>
    )
}
