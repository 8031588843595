import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import get from "lodash/get"

import Input from "../../../common/Input"
import "./style.scss"
import Checkbox from "../../../common/Checkbox"
import { fetchPermissionsList } from "../../APIs/action"
import { TEXT_CONSTANTS } from "../../../../utils/constants"
import { decryptData } from "../../../../utils/EncryptDecrypt"

const MASTER_PERMISSIONS = [
    "TENANT_LIST_VIEW",
    "TENANT_LIST_EDIT",
    "ADD_TENANT_VIEW",
    "ADD_TENANT_EDIT"
]

class PermissionList extends Component {
    state = {
        permissionsList: [],
        finalsearchList: [],
        searchPermission: ""
    }

    componentDidMount() {
        this.loadHandler()
    }

    loadHandler = async () => {
        const { fetchPermissionsList } = this.props
        await fetchPermissionsList()
        const { permissions } = this.props
        this.setState({
            permissionsList: permissions,
            finalsearchList: permissions
        })
    }

    handleSearch = (name, value) => {
        const { permissionsList } = this.state
        let searchList = []

        permissionsList.map((item) => {
            if (value === "" || value.length === 0) {
                searchList = this.props.permissions
            } else if (item.name.toLowerCase().includes(value.toLowerCase())) {
                searchList.push(item)
            }
            return item
        })

        this.setState({
            finalsearchList: searchList
        })
    }

    findChecked = (name) => {
        const { taggedPermissions } = this.props
        let isChecked = false
        taggedPermissions.map((item) => {
            if (item.name === name) {
                isChecked = true
                return isChecked
            }
            return item
        })
        return isChecked
    }

    // For master -> Show all permissions
    // For Tenant -> Show limited permissions which are visible in side menu
    filterPermissionsBasedOnTenant = () => {
        const { finalsearchList } = this.state

        let tenantInfo = decryptData(
            sessionStorage.getItem("tenantInfo"),
            process.env.REACT_APP_LOCAL_STORAGE_SECRETE_KEY
        )
        // if (tenantInfo) {
        //   tenantInfo = JSON.parse(tenantInfo);
        // }
        if (tenantInfo?.data?.tenantIdentifier === "master") {
            return finalsearchList
        }
        // Remove MASTER_PERMISSIONS from tenant permissions
        return finalsearchList?.filter((item) => {
            return MASTER_PERMISSIONS?.includes(item?.name) ? false : true
        })
    }

    render() {
        const { checkboxHandleChange, isReset } = this.props
        // const { finalsearchList } = this.state;
        if (isReset) {
            this.loadHandler()
        }
        return (
            <div className="pl-container">
                <label htmlFor="pl-searchBox" className="label-text">
                    {TEXT_CONSTANTS?.LIST_OF_PERMISSION}
                </label>
                <div className="pl-search">
                    <span className="icon-search"></span>
                    <Input
                        type="text"
                        changeHandler={this.handleSearch}
                        name="searchPermission"
                        cName="searchInput"
                    />
                </div>
                <div className="pl-options">
                    <ul>
                        {this?.filterPermissionsBasedOnTenant()?.map((item, index) => {
                            return (
                                <li key={index}>
                                    <Checkbox
                                        cName="checklist"
                                        labelText={item.name}
                                        id={item.id}
                                        name={item.name}
                                        isChecked={this.findChecked(item.name)}
                                        changeHandler={checkboxHandleChange}
                                    />
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        permissions: get(state.userManagement, "permission", [])
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                fetchPermissionsList
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PermissionList)
