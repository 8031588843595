import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { toast } from "react-toastify"

import { fetchPlatformList } from "../Category/api/action"
import AddEditMenu from "./addEditMenu"
import MenuListing from "./menuListing"
import Button from "../common/Button"

import "./style.scss"
import { scrollPage } from "../../utils/common"
import { BUTTON_CONSTANTS, PERMISSIONS, TEXT_CONSTANTS } from "../../utils/constants"
import { AuthWrapper } from "../common/AuthWrapper"
import EnvDropdown from "../common/EnvDropdown"

class MenuDesign extends Component {
    constructor(props) {
        super(props)

        this.state = {
            platFormList: [],
            isEdit: false,
            currentPlatform: 0,
            platformName: ""
        }
    }
    componentDidMount() {
        scrollPage()
        this.loadHandler()
    }

    loadHandler = async () => {
        let { fetchPlatformList } = this.props
        let { currentPlatform, platformName } = this.state

        let response = await fetchPlatformList()
        if (response && response.status) {
            if (response.data) {
                currentPlatform = response.data[0].id
                platformName = response.data[0].name
            }
            this.setState({
                platFormList: response.data,
                currentPlatform,
                platformName
            })
        } else {
            toast.error("Opps!! Something went wrong!!")
        }
    }

    handleEditClick = () => {
        this.setState({
            isEdit: !this.state.isEdit
        })
    }
    handlePlatformChange = (item) => {
        this.setState({
            currentPlatform: item.id,
            platformName: item.name
        })
    }
    render() {
        const { platFormList, isEdit, currentPlatform } = this.state
        return (
            <>
                <EnvDropdown refreshCall={this.loadHandler} />
                <div className="vd-menuDesign">
                    <div className="vr-cont-head mb">
                        {/* TODO: Replace below with the reusable pageTitleComponent */}
                        <div className="vr-cont-title">
                            <span className="vr-breadcrum">
                                {TEXT_CONSTANTS?.EDITORIAL} <i className="icon-arrow-right1" />
                                <span>{TEXT_CONSTANTS?.MENU_DESIGN}</span>
                            </span>
                            <h2 className="breadcrum-header">{TEXT_CONSTANTS?.MENU_DESIGN}</h2>
                        </div>
                        <AuthWrapper
                            permissions={[PERMISSIONS?.ENGAGE_UX_MANAGER_MENU_DESIGN_EDIT]}>
                            <div className="vr-cont-action">
                                <Button
                                    cName={`btn ${!isEdit ? "primary-btn" : "border-btn"}`}
                                    bValue={`${
                                        !isEdit
                                            ? BUTTON_CONSTANTS?.ADD_EDIT_MENU
                                            : BUTTON_CONSTANTS?.BACK
                                    }`}
                                    leftIcon={isEdit && "icon-arrow-left1"}
                                    clickHandler={this.handleEditClick}
                                />
                            </div>
                        </AuthWrapper>
                    </div>
                    <div className="vr-content-holder">
                        {isEdit ? (
                            <AddEditMenu
                                isEdit={isEdit}
                                platFormList={platFormList}
                                currentPlatform={currentPlatform}
                                handlePlatformChange={this.handlePlatformChange}
                                handleEditClick={this.handleEditClick}
                            />
                        ) : platFormList?.length > 0 ? (
                            <MenuListing
                                platFormList={platFormList}
                                currentPlatform={currentPlatform}
                                handlePlatformChange={this.handlePlatformChange}
                            />
                        ) : null}
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                fetchPlatformList
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuDesign)
