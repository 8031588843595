import React, { Component } from "react"
import Button from "../common/Button"
import AddSeason from "./addWebSeries/AddSeason"
import { BUTTON_CONSTANTS, PERMISSIONS } from "../../utils/constants"
import { checkIfUserHasAccess } from "../../utils/users"
import { isEmpty } from "lodash"
import { connect } from "react-redux"

class AddSeasonWrapper extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentDataSource: "web_series",
            data: props.preFillData,
            allFilled: false,
            seasonDataCopy: JSON.parse(JSON.stringify(props?.selectedData?.season || [])),
            seasonNumbers: [],
            deletedSeasonsIndex: []
        }
        this.screenName = "addSeason"

        this.newSeasonData = {
            contentType: "SEASON",
            active: true,

            seasonPosterImage: "",
            coverImage: "",
            seasonNumber: "",
            seasonTitle: "",
            description: "",
            trailerUrl: "",
            playDuration: "",
            seasonYear: "",
            rating: "",
            videoFormat: [],
            addEpisode: {
                episode: []
            }
        }
        this.canUpdateStatus = checkIfUserHasAccess([PERMISSIONS.CONTENT_EDIT])
    }
    componentDidMount() {
        const { season } = this.state.data
        const { seasonNumbers } = this.state
        if (!isEmpty(season)) {
            season.forEach((item, index) => {
                !isEmpty(item?.seasonNumber) && seasonNumbers.push(item?.seasonNumber)
            })

            this.setState({
                seasonNumbers: this.state.seasonNumbers
            })
        }
    }

    handleSave = (index, data) => {
        let seasons = JSON.parse(JSON.stringify(this.state.data))
        seasons.season[index] = data
        this.setState(
            {
                data: seasons
            },
            () => {
                //this.props.handleSave("addBrand","addSeason",this.state.data.addSeason);
            }
        )
    }
    saveAndFinish = (isPublish) => {
        this.props.handleSave("addSeason", "addSeason", this.state.data, true, isPublish)
    }
    saveAndFinish = (isPublish) => {
        this.props.handleSave("addSeason", "addSeason", this.state.data, true, isPublish)
    }
    isAllFilled = (value) => {
        this.setState({
            allFilled: value
        })
    }

    getSelectedData = (selectedIndex) => {
        return this.state.seasonDataCopy?.[selectedIndex]
    }

    renderSeasonForms = () => {
        if (this.state.data?.season?.length <= 0) {
            this.state.data.season.push(JSON.parse(JSON.stringify(this.newSeasonData)))
        }

        return this.state?.data?.season?.map((item, index) => {
            return (
                <AddSeason
                    key={"add-season" + index}
                    index={index}
                    preFillData={item}
                    data={this.props.data.entity}
                    seasonNumbers={this.state?.seasonNumbers}
                    formMetaData={this.props.formMetaData}
                    handleSave={this.handleSave}
                    isAllFilled={this.isAllFilled}
                    deleteSeason={this.deleteSeason}
                    isEdit={this.props.isEdit}
                    isModalOpen={this.props.isModalOpen}
                    ceDataLoadingState={this.props.ceDataLoadingState}
                    isModalViewOpen={this.props.isModalViewOpen}
                    episodeIndex={this.props.episodeIndex}
                    isImdbSuccessModal={this.props.isImdbSuccessModal}
                    imdbLoadingState={this.props.imdbLoadingState}
                    imdbData={this.props.imdbData}
                    contentEnrichmentBtnHandler={this.props.contentEnrichmentBtnHandler}
                    submitModalHandler={this.props.submitModalHandler}
                    fetchVideoMarkers={this.props.fetchVideoMarkers}
                    contentEnrichmentViewBtnHandler={this.props.contentEnrichmentViewBtnHandler}
                    contentMarkers={this.props.contentMarkers}
                    openImdbModal={this.props.openImdbModal}
                    getContentEnrichment={this.props.getContentEnrichment}
                    viewData={this.props.viewData}
                    webseriesData={this.props.webseriesData}
                    contentType={this.props.contentType}
                    selectedLanguage={this.props?.selectedLanguage}
                    multilingualKeys={this.props?.multilingualKeys}
                    certifications={this.props?.certifications}
                    drmResponse={this.props.drmResponse}
                    selectedData={this.getSelectedData(index)}
                ></AddSeason>
            )
        })
    }
    addSeasons = () => {
        let seasons = JSON.parse(JSON.stringify(this.state?.data))
        if (!seasons?.hasOwnProperty("season")) {
            seasons.season = []
        }
        seasons.season.push(JSON.parse(JSON.stringify(this.newSeasonData)))
        this.setState({
            data: seasons
        })
        // $("html, body").animate({
        //   scrollTop: 0,
        // });
    }
    deleteSeason = (index) => {
        let seasons = this.state.data
        seasons.season.splice(index, 1)
        this.setState({
            data: seasons
        })

        let selectedCopyModification = this.state.seasonDataCopy
        seasons.season.splice(index, 1)
        selectedCopyModification.splice(index, 1)
        this.setState({
            data: seasons,
            seasonDataCopy: selectedCopyModification
        })
    }
    render() {
        let className =
            this.props.currentScreen === this.screenName
                ? "add-step-container active"
                : "add-step-container"
        return (
            <div className={className}>
                <div className="add-season-container">{this.renderSeasonForms()}</div>
                {this.canUpdateStatus && (
                    <div className="add-season-button">
                        <Button
                            cName={"btn border-btn"}
                            bValue={BUTTON_CONSTANTS?.ADD_MORE_SEASON?.toUpperCase()}
                            leftIcon={"icon-plus"}
                            // disabled={!this.state.allFilled}
                            clickHandler={() => {
                                this.addSeasons()
                            }}
                        />
                    </div>
                )}

                <div className="save-and-next">
                    <Button
                        cName={"btn cancel-btn"}
                        bValue={BUTTON_CONSTANTS?.PREVIOUS_STEP}
                        leftIcon={"icon-arrow-left1"}
                        // disabled={!this.state.allFilled}
                        clickHandler={() => {
                            this.props.handleSave("addBrand", "addSeason", this.state.data)
                        }}
                    />
                    {this.canUpdateStatus && (
                        <Button
                            cName={"btn primary-btn"}
                            bType={"submit"}
                            bValue={BUTTON_CONSTANTS?.SAVE_FINISH}
                            rightIcon={"icon-arrow-right1"}
                            disabled={this.props.save || this.props?.isUploadingImage}
                            clickHandler={() => {
                                this.saveAndFinish()
                            }}
                        />
                    )}
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isUploadingImage: state?.loader?.isLoading //Disable the submit button whenever user is uploading images
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(AddSeasonWrapper)
