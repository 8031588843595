import { ACTION } from "./constant"
import MenuServiceInstance from "./service"

export const fetchMenuOptions = (params) => {
    return (dispatch) => {
        return MenuServiceInstance.fetchMenuOptions(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_MENU_OPTIONS,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting form json ", error)
            })
    }
}

export const uploadImage = (params) => {
    return (dispatch) => {
        return MenuServiceInstance.uploadImage(params)
            .then((response) => {
                dispatch({
                    type: ACTION.UPLOAD_IMAGE,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in upload image ", error)
            })
    }
}

export const fetchPlatformList = (params) => {
    return (dispatch) => {
        return MenuServiceInstance.fetchPlatformList(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_PLATFORM,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting form json ", error)
            })
    }
}

export const createMenu = (params) => {
    return (dispatch) => {
        return MenuServiceInstance.createMenu(params)
            .then((response) => {
                dispatch({
                    type: ACTION.CREATE_MENU,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting form json ", error)
            })
    }
}

export const fetchMenuList = (params) => {
    return (dispatch) => {
        return MenuServiceInstance.fetchMenuList(params)
            .then((response) => {
                dispatch({
                    type: ACTION.FETCH_MENU,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting form json ", error)
            })
    }
}

export const deleteMenu = (params) => {
    return (dispatch) => {
        return MenuServiceInstance.deleteMenu(params)
            .then((response) => {
                dispatch({
                    type: ACTION.DELETE_MENU,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting form json ", error)
            })
    }
}

export const deleteTab = (params) => {
    return (dispatch) => {
        return MenuServiceInstance.deleteTab(params)
            .then((response) => {
                dispatch({
                    type: ACTION.DELETE_TAB,
                    apiResponse: response.data
                })
                return response.data
            })
            .catch((error) => {
                console.log("Got error in getting form json ", error)
            })
    }
}
