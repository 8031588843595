import { API_ROOT_PATH } from "../../../config/apiPath"
import ApiService from "../../../utils/apiService"

const SportsService = () => {
    const getSportsCategory = () => {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/editorial/v1/page-categories/tabs?key=pageEntityId`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    const uploadImage = (params) => {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/core/document/upload`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }

    const saveSelectedSportsCategory = (params) => {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/editorial/v1/page-categories`,
            data: params,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    const getSelectedSportsCategoryData = (params) => {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/editorial/v1/page-categories?length=${params?.length}&start=${
                params?.start
            }&pageEntityId=${params?.pageEntityId}${encodeURI(params?.restFilters ?? "")}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    const deleteSelectedData = (selectedId) => {
        const options = {
            method: "DELETE",
            url: `${API_ROOT_PATH}/editorial/v1/page-categories/${selectedId}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    const fetchSportsFilterData = (params) => {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/editorial/v1/page-categories/names?pageEntityId=${params}`,
            isAuthRequired: true
        }
        return ApiService(options)
    }

    return {
        getSportsCategory,
        uploadImage,
        saveSelectedSportsCategory,
        getSelectedSportsCategoryData,
        deleteSelectedData,
        fetchSportsFilterData
    }
}

export default SportsService()
