export const webSeriesData = {
    id: 90,
    active: true,
    name: "WEB_SERIES",
    description: "webSeries",
    masterData: false,
    published: true,
    attributes: [
        {
            id: 327,
            active: true,
            name: "addBrand",
            type: "OBJECT",
            required: true,
            repeats: false,
            entity: {
                id: 91,
                active: true,
                name: "addBrand",
                description: "addBrand",
                masterData: false,
                published: false,
                attributes: [
                    {
                        id: 303,
                        active: true,
                        name: "posterImage",
                        type: "STRING",
                        required: true,
                        repeats: false,
                        entity: null,
                        htmlElement: {
                            id: 244,
                            active: true,
                            elementType: "INPUT",
                            elementSubType: "FILE",
                            elementSize: "SMALL",
                            elementObjectType: "POSTER",
                            placeholder: "POSTER IMAGE",
                            label: "ADD POSTER IMAGE",
                            horizontalPosition: null
                        },
                        position: 1,
                        requiredOnForm: false,
                        filterable: false,
                        searchable: false,
                        primary: false
                    },
                    {
                        id: 304,
                        active: true,
                        name: "coverImage",
                        type: "STRING",
                        required: true,
                        repeats: false,
                        entity: null,
                        htmlElement: {
                            id: 245,
                            active: true,
                            elementType: "INPUT",
                            elementSubType: "FILE",
                            elementSize: "MEDIUM",
                            elementObjectType: "POSTER",
                            placeholder: "COVER IMAGE",
                            label: "ADD COVER IMAGE",
                            horizontalPosition: null
                        },
                        position: 2,
                        requiredOnForm: false,
                        filterable: false,
                        searchable: false,
                        primary: false
                    },
                    {
                        id: 305,
                        active: true,
                        name: "brandTitle",
                        type: "STRING",
                        required: true,
                        repeats: false,
                        entity: null,
                        htmlElement: {
                            id: 246,
                            active: true,
                            elementType: "INPUT",
                            elementSubType: "TEXT",
                            elementSize: "LARGE",
                            elementObjectType: null,
                            placeholder: "",
                            label: "BRAND TITLE",
                            horizontalPosition: null
                        },
                        position: 3,
                        requiredOnForm: false,
                        filterable: false,
                        searchable: false,
                        primary: false
                    },
                    {
                        id: 306,
                        active: true,
                        name: "trailerUrl",
                        type: "STRING",
                        required: true,
                        repeats: false,
                        entity: null,
                        htmlElement: {
                            id: 247,
                            active: true,
                            elementType: "INPUT",
                            elementSubType: "URL",
                            elementSize: "MEDIUM",
                            elementObjectType: null,
                            placeholder: "http://",
                            label: "TRAILER URL",
                            horizontalPosition: null
                        },
                        position: 4,
                        requiredOnForm: false,
                        filterable: false,
                        searchable: false,
                        primary: false
                    },
                    {
                        id: 307,
                        active: true,
                        name: "playDuration",
                        type: "NUMBER",
                        required: true,
                        repeats: false,
                        entity: null,
                        htmlElement: {
                            id: 248,
                            active: true,
                            elementType: "INPUT",
                            elementSubType: "TEXT",
                            elementSize: "SMALL",
                            elementObjectType: null,
                            placeholder: "HH/MM/SS",
                            label: "DURATION",
                            horizontalPosition: null
                        },
                        position: 5,
                        requiredOnForm: false,
                        filterable: false,
                        searchable: false,
                        primary: false
                    },
                    {
                        id: 319,
                        active: true,
                        name: "brandDescription",
                        type: "OBJECT",
                        required: true,
                        repeats: false,
                        entity: {
                            id: 92,
                            active: true,
                            name: "brandDescription",
                            description: "brandDescription",
                            masterData: false,
                            published: false,
                            attributes: [
                                {
                                    id: 308,
                                    active: true,
                                    name: "description",
                                    type: "STRING",
                                    required: true,
                                    repeats: false,
                                    entity: null,
                                    htmlElement: {
                                        id: 249,
                                        active: true,
                                        elementType: "INPUT",
                                        elementSubType: "TEXTAREA",
                                        elementSize: "LARGE",
                                        elementObjectType: null,
                                        placeholder: "",
                                        label: "DESCRIPTION",
                                        horizontalPosition: null
                                    },
                                    position: 1,
                                    requiredOnForm: false,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                },
                                {
                                    id: 310,
                                    active: true,
                                    name: "genre",
                                    type: "OBJECT",
                                    required: true,
                                    repeats: true,
                                    entity: {
                                        id: 4,
                                        active: true,
                                        name: "genre",
                                        description: "genre",
                                        masterData: true,
                                        published: false,
                                        attributes: [
                                            {
                                                id: 7,
                                                active: true,
                                                name: "id",
                                                type: "NUMBER",
                                                required: true,
                                                repeats: false,
                                                entity: null,
                                                htmlElement: null,
                                                position: 0,
                                                requiredOnForm: true,
                                                filterable: false,
                                                searchable: false,
                                                primary: false
                                            },
                                            {
                                                id: 8,
                                                active: true,
                                                name: "name",
                                                type: "STRING",
                                                required: true,
                                                repeats: false,
                                                entity: null,
                                                htmlElement: null,
                                                position: 0,
                                                requiredOnForm: true,
                                                filterable: false,
                                                searchable: false,
                                                primary: false
                                            }
                                        ],
                                        contentType: "GENRE",
                                        mappingFile: null,
                                        exposedEntity: null
                                    },
                                    htmlElement: {
                                        id: 250,
                                        active: true,
                                        elementType: "SELECT",
                                        elementSubType: "MULTI_SELECT",
                                        elementSize: "HALF",
                                        elementObjectType: null,
                                        placeholder: "",
                                        label: "GENRE",
                                        horizontalPosition: null
                                    },
                                    position: 2,
                                    requiredOnForm: false,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                },
                                {
                                    id: 312,
                                    active: true,
                                    name: "language",
                                    type: "OBJECT",
                                    required: true,
                                    repeats: true,
                                    entity: {
                                        id: 6,
                                        active: true,
                                        name: "language",
                                        description: "language",
                                        masterData: true,
                                        published: false,
                                        attributes: [
                                            {
                                                id: 12,
                                                active: true,
                                                name: "name",
                                                type: "STRING",
                                                required: true,
                                                repeats: false,
                                                entity: null,
                                                htmlElement: null,
                                                position: 0,
                                                requiredOnForm: true,
                                                filterable: false,
                                                searchable: false,
                                                primary: false
                                            },
                                            {
                                                id: 13,
                                                active: true,
                                                name: "id",
                                                type: "NUMBER",
                                                required: true,
                                                repeats: false,
                                                entity: null,
                                                htmlElement: null,
                                                position: 0,
                                                requiredOnForm: true,
                                                filterable: false,
                                                searchable: false,
                                                primary: false
                                            }
                                        ],
                                        contentType: "LANGUAGE",
                                        mappingFile: null,
                                        exposedEntity: null
                                    },
                                    htmlElement: {
                                        id: 251,
                                        active: true,
                                        elementType: "SELECT",
                                        elementSubType: "MULTI_SELECT",
                                        elementSize: "HALF",
                                        elementObjectType: null,
                                        placeholder: null,
                                        label: "LANGUAGE",
                                        horizontalPosition: null
                                    },
                                    position: 3,
                                    requiredOnForm: false,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                },
                                {
                                    id: 314,
                                    active: true,
                                    name: "year",
                                    type: "OBJECT",
                                    required: true,
                                    repeats: false,
                                    entity: {
                                        id: 30,
                                        active: true,
                                        name: "year",
                                        description: "year",
                                        masterData: true,
                                        published: false,
                                        attributes: [
                                            {
                                                id: 77,
                                                active: true,
                                                name: "id",
                                                type: "NUMBER",
                                                required: true,
                                                repeats: false,
                                                entity: null,
                                                htmlElement: null,
                                                position: 0,
                                                requiredOnForm: true,
                                                filterable: false,
                                                searchable: false,
                                                primary: false
                                            },
                                            {
                                                id: 78,
                                                active: true,
                                                name: "name",
                                                type: "STRING",
                                                required: true,
                                                repeats: false,
                                                entity: null,
                                                htmlElement: null,
                                                position: 0,
                                                requiredOnForm: true,
                                                filterable: false,
                                                searchable: false,
                                                primary: false
                                            }
                                        ],
                                        contentType: "OTHER",
                                        mappingFile: null,
                                        exposedEntity: null
                                    },
                                    htmlElement: {
                                        id: 253,
                                        active: true,
                                        elementType: "SELECT",
                                        elementSubType: "SELECT",
                                        elementSize: "EXTRA_SMALL",
                                        elementObjectType: null,
                                        placeholder: null,
                                        label: "YEAR",
                                        horizontalPosition: null
                                    },
                                    position: 4,
                                    requiredOnForm: false,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                },
                                {
                                    id: 316,
                                    active: true,
                                    name: "rating",
                                    type: "OBJECT",
                                    required: true,
                                    repeats: false,
                                    entity: {
                                        id: 31,
                                        active: true,
                                        name: "rating",
                                        description: "rating",
                                        masterData: true,
                                        published: false,
                                        attributes: [
                                            {
                                                id: 79,
                                                active: true,
                                                name: "id",
                                                type: "NUMBER",
                                                required: true,
                                                repeats: false,
                                                entity: null,
                                                htmlElement: null,
                                                position: 0,
                                                requiredOnForm: true,
                                                filterable: false,
                                                searchable: false,
                                                primary: false
                                            },
                                            {
                                                id: 80,
                                                active: true,
                                                name: "name",
                                                type: "NUMBER",
                                                required: true,
                                                repeats: false,
                                                entity: null,
                                                htmlElement: null,
                                                position: 0,
                                                requiredOnForm: true,
                                                filterable: false,
                                                searchable: false,
                                                primary: false
                                            }
                                        ],
                                        contentType: "OTHER",
                                        mappingFile: null,
                                        exposedEntity: null
                                    },
                                    htmlElement: {
                                        id: 254,
                                        active: true,
                                        elementType: "SELECT",
                                        elementSubType: "SELECT",
                                        elementSize: "EXTRA_SMALL",
                                        elementObjectType: null,
                                        placeholder: null,
                                        label: "RATING",
                                        horizontalPosition: null
                                    },
                                    position: 5,
                                    requiredOnForm: false,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                },
                                {
                                    id: 318,
                                    active: true,
                                    name: "videoFormat",
                                    type: "OBJECT",
                                    required: true,
                                    repeats: true,
                                    entity: {
                                        id: 7,
                                        active: true,
                                        name: "videoFormat",
                                        description: "videoFormat",
                                        masterData: true,
                                        published: false,
                                        attributes: [
                                            {
                                                id: 16,
                                                active: true,
                                                name: "name",
                                                type: "STRING",
                                                required: true,
                                                repeats: false,
                                                entity: null,
                                                htmlElement: null,
                                                position: 0,
                                                requiredOnForm: true,
                                                filterable: false,
                                                searchable: false,
                                                primary: false
                                            },
                                            {
                                                id: 17,
                                                active: true,
                                                name: "id",
                                                type: "NUMBER",
                                                required: true,
                                                repeats: false,
                                                entity: null,
                                                htmlElement: null,
                                                position: 0,
                                                requiredOnForm: true,
                                                filterable: false,
                                                searchable: false,
                                                primary: false
                                            }
                                        ],
                                        contentType: "OTHER",
                                        mappingFile: null,
                                        exposedEntity: null
                                    },
                                    htmlElement: {
                                        id: 255,
                                        active: true,
                                        elementType: "INPUT",
                                        elementSubType: "CHECKBOX",
                                        elementSize: "HALF",
                                        elementObjectType: null,
                                        placeholder: null,
                                        label: "VIDEO FORMAT",
                                        horizontalPosition: null
                                    },
                                    position: 6,
                                    requiredOnForm: false,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                }
                            ],
                            contentType: "OTHER",
                            mappingFile: null,
                            exposedEntity: null
                        },
                        htmlElement: {
                            id: 256,
                            active: true,
                            elementType: null,
                            elementSubType: null,
                            elementSize: null,
                            elementObjectType: null,
                            placeholder: null,
                            label: "DESCRIPTION",
                            horizontalPosition: null
                        },
                        position: 0,
                        requiredOnForm: false,
                        filterable: false,
                        searchable: false,
                        primary: false
                    },
                    {
                        id: 326,
                        active: true,
                        name: "credit",
                        type: "OBJECT",
                        required: true,
                        repeats: false,
                        entity: {
                            id: 99,
                            active: true,
                            name: "credit",
                            description: "credit",
                            masterData: false,
                            published: false,
                            attributes: [
                                {
                                    id: 321,
                                    active: true,
                                    name: "director",
                                    type: "OBJECT",
                                    required: true,
                                    repeats: true,
                                    entity: {
                                        id: 10,
                                        active: true,
                                        name: "director",
                                        description: "director",
                                        masterData: true,
                                        published: false,
                                        attributes: [
                                            {
                                                id: 20,
                                                active: true,
                                                name: "name",
                                                type: "STRING",
                                                required: true,
                                                repeats: false,
                                                entity: null,
                                                htmlElement: null,
                                                position: 0,
                                                requiredOnForm: true,
                                                filterable: false,
                                                searchable: false,
                                                primary: false
                                            },
                                            {
                                                id: 23,
                                                active: true,
                                                name: "id",
                                                type: "STRING",
                                                required: true,
                                                repeats: false,
                                                entity: null,
                                                htmlElement: null,
                                                position: 0,
                                                requiredOnForm: true,
                                                filterable: false,
                                                searchable: false,
                                                primary: false
                                            },
                                            {
                                                id: 301,
                                                active: true,
                                                name: "url",
                                                type: "STRING",
                                                required: true,
                                                repeats: false,
                                                entity: null,
                                                htmlElement: null,
                                                position: 0,
                                                requiredOnForm: true,
                                                filterable: false,
                                                searchable: false,
                                                primary: false
                                            }
                                        ],
                                        contentType: "OTHER",
                                        mappingFile: null,
                                        exposedEntity: null
                                    },
                                    htmlElement: {
                                        id: 259,
                                        active: true,
                                        elementType: "SELECT",
                                        elementSubType: "MULTI_SELECT",
                                        elementSize: "HALF",
                                        elementObjectType: null,
                                        placeholder: null,
                                        label: "DIRECTOR",
                                        horizontalPosition: null
                                    },
                                    position: 1,
                                    requiredOnForm: false,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                },
                                {
                                    id: 323,
                                    active: true,
                                    name: "producer",
                                    type: "OBJECT",
                                    required: true,
                                    repeats: true,
                                    entity: {
                                        id: 11,
                                        active: true,
                                        name: "producer",
                                        description: "producer",
                                        masterData: true,
                                        published: false,
                                        attributes: [
                                            {
                                                id: 21,
                                                active: true,
                                                name: "name",
                                                type: "STRING",
                                                required: true,
                                                repeats: false,
                                                entity: null,
                                                htmlElement: null,
                                                position: 0,
                                                requiredOnForm: true,
                                                filterable: false,
                                                searchable: false,
                                                primary: false
                                            },
                                            {
                                                id: 22,
                                                active: true,
                                                name: "id",
                                                type: "STRING",
                                                required: true,
                                                repeats: false,
                                                entity: null,
                                                htmlElement: null,
                                                position: 0,
                                                requiredOnForm: true,
                                                filterable: false,
                                                searchable: false,
                                                primary: false
                                            },
                                            {
                                                id: 302,
                                                active: true,
                                                name: "url",
                                                type: "STRING",
                                                required: true,
                                                repeats: false,
                                                entity: null,
                                                htmlElement: null,
                                                position: 0,
                                                requiredOnForm: true,
                                                filterable: false,
                                                searchable: false,
                                                primary: false
                                            }
                                        ],
                                        contentType: "OTHER",
                                        mappingFile: null,
                                        exposedEntity: null
                                    },
                                    htmlElement: {
                                        id: 260,
                                        active: true,
                                        elementType: "SELECT",
                                        elementSubType: "MULTI_SELECT",
                                        elementSize: "HALF",
                                        elementObjectType: null,
                                        placeholder: null,
                                        label: "PRODUCER",
                                        horizontalPosition: null
                                    },
                                    position: 0,
                                    requiredOnForm: false,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                },
                                {
                                    id: 325,
                                    active: true,
                                    name: "actor",
                                    type: "OBJECT",
                                    required: true,
                                    repeats: true,
                                    entity: {
                                        id: 9,
                                        active: true,
                                        name: "actor",
                                        description: "actor",
                                        masterData: true,
                                        published: false,
                                        attributes: [
                                            {
                                                id: 19,
                                                active: true,
                                                name: "name",
                                                type: "STRING",
                                                required: true,
                                                repeats: false,
                                                entity: null,
                                                htmlElement: null,
                                                position: 0,
                                                requiredOnForm: true,
                                                filterable: false,
                                                searchable: false,
                                                primary: false
                                            },
                                            {
                                                id: 24,
                                                active: true,
                                                name: "id",
                                                type: "STRING",
                                                required: true,
                                                repeats: false,
                                                entity: null,
                                                htmlElement: null,
                                                position: 0,
                                                requiredOnForm: true,
                                                filterable: false,
                                                searchable: false,
                                                primary: false
                                            },
                                            {
                                                id: 300,
                                                active: true,
                                                name: "url",
                                                type: "STRING",
                                                required: true,
                                                repeats: false,
                                                entity: null,
                                                htmlElement: null,
                                                position: 0,
                                                requiredOnForm: true,
                                                filterable: false,
                                                searchable: false,
                                                primary: false
                                            }
                                        ],
                                        contentType: "ACTOR",
                                        mappingFile: null,
                                        exposedEntity: null
                                    },
                                    htmlElement: {
                                        id: 261,
                                        active: true,
                                        elementType: "SEARCHABLE_LIST",
                                        elementSubType: null,
                                        elementSize: "LARGE",
                                        elementObjectType: null,
                                        placeholder: null,
                                        label: "STARRING",
                                        horizontalPosition: null
                                    },
                                    position: 0,
                                    requiredOnForm: false,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                }
                            ],
                            contentType: "OTHER",
                            mappingFile: null,
                            exposedEntity: null
                        },
                        htmlElement: {
                            id: 257,
                            active: true,
                            elementType: null,
                            elementSubType: null,
                            elementSize: null,
                            elementObjectType: null,
                            placeholder: "",
                            label: "CREDIT",
                            horizontalPosition: null
                        },
                        position: 0,
                        requiredOnForm: false,
                        filterable: false,
                        searchable: false,
                        primary: false
                    }
                ],
                contentType: "OTHER",
                mappingFile: null,
                exposedEntity: null
            },
            htmlElement: {
                id: 262,
                active: true,
                elementType: "FORM",
                elementSubType: null,
                elementSize: null,
                elementObjectType: null,
                placeholder: "",
                label: "Add Brand",
                horizontalPosition: 1
            },
            position: 0,
            requiredOnForm: false,
            filterable: false,
            searchable: false,
            primary: false
        },
        {
            id: 349,
            active: true,
            name: "addSeason",
            type: "OBJECT",
            required: true,
            repeats: false,
            entity: {
                id: 109,
                active: true,
                name: "AddSeason",
                description: "addSeason",
                masterData: false,
                published: false,
                attributes: [
                    {
                        id: 348,
                        active: true,
                        name: "season",
                        type: "OBJECT",
                        required: true,
                        repeats: true,
                        entity: {
                            id: 102,
                            active: true,
                            name: "SEASON",
                            description: "season",
                            masterData: false,
                            published: false,
                            attributes: [
                                {
                                    id: 328,
                                    active: true,
                                    name: "seasonPosterImage",
                                    type: "FILE",
                                    required: true,
                                    repeats: false,
                                    entity: null,
                                    htmlElement: {
                                        id: 265,
                                        active: true,
                                        elementType: "INPUT",
                                        elementSubType: "FILE",
                                        elementSize: "SMALL",
                                        elementObjectType: "POSTER",
                                        placeholder: "POSTER IMAGE",
                                        label: "ADD POSTER IMAGE",
                                        horizontalPosition: null
                                    },
                                    position: 1,
                                    requiredOnForm: false,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                },
                                {
                                    id: 329,
                                    active: true,
                                    name: "coverImage",
                                    type: "STRING",
                                    required: true,
                                    repeats: false,
                                    entity: null,
                                    htmlElement: {
                                        id: 266,
                                        active: true,
                                        elementType: "INPUT",
                                        elementSubType: "FILE",
                                        elementSize: "MEDIUM",
                                        elementObjectType: "POSTER",
                                        placeholder: "COVER IMAGE",
                                        label: "ADD COVER IMAGE",
                                        horizontalPosition: null
                                    },
                                    position: 2,
                                    requiredOnForm: false,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                },
                                {
                                    id: 330,
                                    active: true,
                                    name: "seasonTitle",
                                    type: "STRING",
                                    required: true,
                                    repeats: false,
                                    entity: null,
                                    htmlElement: {
                                        id: 268,
                                        active: true,
                                        elementType: "INPUT",
                                        elementSubType: "TEXT",
                                        elementSize: "LARGE",
                                        elementObjectType: null,
                                        placeholder: "SEASON TITLE",
                                        label: "SEASON TITLE",
                                        horizontalPosition: null
                                    },
                                    position: 1,
                                    requiredOnForm: false,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                },
                                {
                                    id: 331,
                                    active: true,
                                    name: "description",
                                    type: "STRING",
                                    required: true,
                                    repeats: false,
                                    entity: null,
                                    htmlElement: {
                                        id: 269,
                                        active: true,
                                        elementType: "INPUT",
                                        elementSubType: "TEXTAREA",
                                        elementSize: "LARGE",
                                        elementObjectType: null,
                                        placeholder: "",
                                        label: "DESCRIPTION",
                                        horizontalPosition: null
                                    },
                                    position: 1,
                                    requiredOnForm: false,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                },
                                {
                                    id: 332,
                                    active: true,
                                    name: "trailerUrl",
                                    type: "STRING",
                                    required: true,
                                    repeats: false,
                                    entity: null,
                                    htmlElement: {
                                        id: 270,
                                        active: true,
                                        elementType: "INPUT",
                                        elementSubType: "URL",
                                        elementSize: "MEDIUM",
                                        elementObjectType: null,
                                        placeholder: "TRAILER URL",
                                        label: "TRAILER URL",
                                        horizontalPosition: null
                                    },
                                    position: 2,
                                    requiredOnForm: false,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                },
                                {
                                    id: 333,
                                    active: true,
                                    name: "playDuration",
                                    type: "NUMBER",
                                    required: true,
                                    repeats: false,
                                    entity: null,
                                    htmlElement: {
                                        id: 271,
                                        active: true,
                                        elementType: "INPUT",
                                        elementSubType: "TEXT",
                                        elementSize: "SMALL",
                                        elementObjectType: null,
                                        placeholder: "HH/MM/SS",
                                        label: "DURATION",
                                        horizontalPosition: null
                                    },
                                    position: 3,
                                    requiredOnForm: false,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                },
                                {
                                    id: 335,
                                    active: true,
                                    name: "seasonYear",
                                    type: "OBJECT",
                                    required: true,
                                    repeats: false,
                                    entity: {
                                        id: 30,
                                        active: true,
                                        name: "year",
                                        description: "year",
                                        masterData: true,
                                        published: false,
                                        attributes: [
                                            {
                                                id: 77,
                                                active: true,
                                                name: "id",
                                                type: "NUMBER",
                                                required: true,
                                                repeats: false,
                                                entity: null,
                                                htmlElement: null,
                                                position: 0,
                                                requiredOnForm: true,
                                                filterable: false,
                                                searchable: false,
                                                primary: false
                                            },
                                            {
                                                id: 78,
                                                active: true,
                                                name: "name",
                                                type: "STRING",
                                                required: true,
                                                repeats: false,
                                                entity: null,
                                                htmlElement: null,
                                                position: 0,
                                                requiredOnForm: true,
                                                filterable: false,
                                                searchable: false,
                                                primary: false
                                            }
                                        ],
                                        contentType: "OTHER",
                                        mappingFile: null,
                                        exposedEntity: null
                                    },
                                    htmlElement: {
                                        id: 272,
                                        active: true,
                                        elementType: "SELECT",
                                        elementSubType: "SELECT",
                                        elementSize: "EXTRA_SMALL",
                                        elementObjectType: null,
                                        placeholder: null,
                                        label: "YEAR",
                                        horizontalPosition: null
                                    },
                                    position: 4,
                                    requiredOnForm: false,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                },
                                {
                                    id: 337,
                                    active: true,
                                    name: "rating",
                                    type: "OBJECT",
                                    required: true,
                                    repeats: false,
                                    entity: {
                                        id: 31,
                                        active: true,
                                        name: "rating",
                                        description: "rating",
                                        masterData: true,
                                        published: false,
                                        attributes: [
                                            {
                                                id: 79,
                                                active: true,
                                                name: "id",
                                                type: "NUMBER",
                                                required: true,
                                                repeats: false,
                                                entity: null,
                                                htmlElement: null,
                                                position: 0,
                                                requiredOnForm: true,
                                                filterable: false,
                                                searchable: false,
                                                primary: false
                                            },
                                            {
                                                id: 80,
                                                active: true,
                                                name: "name",
                                                type: "NUMBER",
                                                required: true,
                                                repeats: false,
                                                entity: null,
                                                htmlElement: null,
                                                position: 0,
                                                requiredOnForm: true,
                                                filterable: false,
                                                searchable: false,
                                                primary: false
                                            }
                                        ],
                                        contentType: "OTHER",
                                        mappingFile: null,
                                        exposedEntity: null
                                    },
                                    htmlElement: {
                                        id: 273,
                                        active: true,
                                        elementType: "SELECT",
                                        elementSubType: "SELECT",
                                        elementSize: "EXTRA_SMALL",
                                        elementObjectType: null,
                                        placeholder: null,
                                        label: "RATING",
                                        horizontalPosition: null
                                    },
                                    position: 5,
                                    requiredOnForm: false,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                },
                                {
                                    id: 339,
                                    active: true,
                                    name: "videoFormat",
                                    type: "OBJECT",
                                    required: true,
                                    repeats: true,
                                    entity: {
                                        id: 7,
                                        active: true,
                                        name: "videoFormat",
                                        description: "videoFormat",
                                        masterData: true,
                                        published: false,
                                        attributes: [
                                            {
                                                id: 16,
                                                active: true,
                                                name: "name",
                                                type: "STRING",
                                                required: true,
                                                repeats: false,
                                                entity: null,
                                                htmlElement: null,
                                                position: 0,
                                                requiredOnForm: true,
                                                filterable: false,
                                                searchable: false,
                                                primary: false
                                            },
                                            {
                                                id: 17,
                                                active: true,
                                                name: "id",
                                                type: "NUMBER",
                                                required: true,
                                                repeats: false,
                                                entity: null,
                                                htmlElement: null,
                                                position: 0,
                                                requiredOnForm: true,
                                                filterable: false,
                                                searchable: false,
                                                primary: false
                                            }
                                        ],
                                        contentType: "OTHER",
                                        mappingFile: null,
                                        exposedEntity: null
                                    },
                                    htmlElement: {
                                        id: 274,
                                        active: true,
                                        elementType: "INPUT",
                                        elementSubType: "CHECKBOX",
                                        elementSize: "HALF",
                                        elementObjectType: null,
                                        placeholder: null,
                                        label: "VIDEO FORMAT",
                                        horizontalPosition: null
                                    },
                                    position: 6,
                                    requiredOnForm: false,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                },
                                {
                                    id: 347,
                                    active: true,
                                    name: "addEpisode",
                                    type: "OBJECT",
                                    required: true,
                                    repeats: false,
                                    entity: {
                                        id: 108,
                                        active: true,
                                        name: "AddEpisode",
                                        description: "addEpisode",
                                        masterData: false,
                                        published: false,
                                        attributes: [
                                            {
                                                id: 346,
                                                active: true,
                                                name: "episode",
                                                type: "OBJECT",
                                                required: true,
                                                repeats: true,
                                                entity: {
                                                    id: 106,
                                                    active: true,
                                                    name: "EPISODE",
                                                    description: "episode",
                                                    masterData: false,
                                                    published: false,
                                                    attributes: [
                                                        {
                                                            id: 340,
                                                            active: true,
                                                            name: "episodeTitle",
                                                            type: "STRING",
                                                            required: true,
                                                            repeats: false,
                                                            entity: null,
                                                            htmlElement: {
                                                                id: 276,
                                                                active: true,
                                                                elementType:
                                                                    "INPUT",
                                                                elementSubType:
                                                                    "TEXT",
                                                                elementSize:
                                                                    "LARGE",
                                                                elementObjectType:
                                                                    null,
                                                                placeholder:
                                                                    null,
                                                                label: "EPISODE TITLE",
                                                                horizontalPosition:
                                                                    null
                                                            },
                                                            position: 1,
                                                            requiredOnForm: false,
                                                            filterable: false,
                                                            searchable: false,
                                                            primary: false
                                                        },
                                                        {
                                                            id: 341,
                                                            active: true,
                                                            name: "playUrl",
                                                            type: "STRING",
                                                            required: true,
                                                            repeats: false,
                                                            entity: null,
                                                            htmlElement: {
                                                                id: 277,
                                                                active: true,
                                                                elementType:
                                                                    "INPUT",
                                                                elementSubType:
                                                                    "URL",
                                                                elementSize:
                                                                    "MEDIUM",
                                                                elementObjectType:
                                                                    null,
                                                                placeholder:
                                                                    "http://",
                                                                label: "PLAY URL",
                                                                horizontalPosition:
                                                                    null
                                                            },
                                                            position: 2,
                                                            requiredOnForm: false,
                                                            filterable: false,
                                                            searchable: false,
                                                            primary: false
                                                        },
                                                        {
                                                            id: 342,
                                                            active: true,
                                                            name: "playDuration",
                                                            type: "NUMBER",
                                                            required: true,
                                                            repeats: false,
                                                            entity: null,
                                                            htmlElement: {
                                                                id: 278,
                                                                active: true,
                                                                elementType:
                                                                    "INPUT",
                                                                elementSubType:
                                                                    "TEXT",
                                                                elementSize:
                                                                    "SMALL",
                                                                elementObjectType:
                                                                    null,
                                                                placeholder:
                                                                    "HH/MM/SS",
                                                                label: "DURATION",
                                                                horizontalPosition:
                                                                    null
                                                            },
                                                            position: 3,
                                                            requiredOnForm: false,
                                                            filterable: false,
                                                            searchable: false,
                                                            primary: false
                                                        },
                                                        {
                                                            id: 343,
                                                            active: true,
                                                            name: "description",
                                                            type: "STRING",
                                                            required: true,
                                                            repeats: false,
                                                            entity: null,
                                                            htmlElement: {
                                                                id: 279,
                                                                active: true,
                                                                elementType:
                                                                    "INPUT",
                                                                elementSubType:
                                                                    "TEXTAREA",
                                                                elementSize:
                                                                    "LARGE",
                                                                elementObjectType:
                                                                    null,
                                                                placeholder: "",
                                                                label: "DESCRIPTION",
                                                                horizontalPosition:
                                                                    null
                                                            },
                                                            position: 4,
                                                            requiredOnForm: false,
                                                            filterable: false,
                                                            searchable: false,
                                                            primary: false
                                                        },
                                                        {
                                                            id: 345,
                                                            active: true,
                                                            name: "videoFormat",
                                                            type: "OBJECT",
                                                            required: true,
                                                            repeats: true,
                                                            entity: {
                                                                id: 7,
                                                                active: true,
                                                                name: "videoFormat",
                                                                description:
                                                                    "videoFormat",
                                                                masterData: true,
                                                                published: false,
                                                                attributes: [
                                                                    {
                                                                        id: 16,
                                                                        active: true,
                                                                        name: "name",
                                                                        type: "STRING",
                                                                        required: true,
                                                                        repeats: false,
                                                                        entity: null,
                                                                        htmlElement:
                                                                            null,
                                                                        position: 0,
                                                                        requiredOnForm: true,
                                                                        filterable: false,
                                                                        searchable: false,
                                                                        primary: false
                                                                    },
                                                                    {
                                                                        id: 17,
                                                                        active: true,
                                                                        name: "id",
                                                                        type: "NUMBER",
                                                                        required: true,
                                                                        repeats: false,
                                                                        entity: null,
                                                                        htmlElement:
                                                                            null,
                                                                        position: 0,
                                                                        requiredOnForm: true,
                                                                        filterable: false,
                                                                        searchable: false,
                                                                        primary: false
                                                                    }
                                                                ],
                                                                contentType:
                                                                    "OTHER",
                                                                mappingFile:
                                                                    null,
                                                                exposedEntity:
                                                                    null
                                                            },
                                                            htmlElement: {
                                                                id: 280,
                                                                active: true,
                                                                elementType:
                                                                    "INPUT",
                                                                elementSubType:
                                                                    "CHECKBOX",
                                                                elementSize:
                                                                    "HALF",
                                                                elementObjectType:
                                                                    null,
                                                                placeholder:
                                                                    null,
                                                                label: "VIDEO FORMAT",
                                                                horizontalPosition:
                                                                    null
                                                            },
                                                            position: 5,
                                                            requiredOnForm: false,
                                                            filterable: false,
                                                            searchable: false,
                                                            primary: false
                                                        },
                                                        {
                                                            id: 356,
                                                            active: true,
                                                            name: "published",
                                                            type: "BOOLEAN",
                                                            required: true,
                                                            repeats: false,
                                                            entity: null,
                                                            htmlElement: null,
                                                            position: 1,
                                                            requiredOnForm: true,
                                                            filterable: false,
                                                            searchable: false,
                                                            primary: false
                                                        }
                                                    ],
                                                    contentType: "EPISODE",
                                                    mappingFile: "episode.json",
                                                    exposedEntity: "CONTENT"
                                                },
                                                htmlElement: {
                                                    id: 294,
                                                    active: true,
                                                    elementType: "FORM",
                                                    elementSubType: null,
                                                    elementSize: null,
                                                    elementObjectType: null,
                                                    placeholder: "",
                                                    label: "EPISODE",
                                                    horizontalPosition: null
                                                },
                                                position: 0,
                                                requiredOnForm: false,
                                                filterable: false,
                                                searchable: false,
                                                primary: false
                                            }
                                        ],
                                        contentType: "OTHER",
                                        mappingFile: null,
                                        exposedEntity: null
                                    },
                                    htmlElement: {
                                        id: 275,
                                        active: true,
                                        elementType: null,
                                        elementSubType: null,
                                        elementSize: null,
                                        elementObjectType: null,
                                        placeholder: null,
                                        label: "ADD EPISODE",
                                        horizontalPosition: null
                                    },
                                    position: 7,
                                    requiredOnForm: false,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                },
                                {
                                    id: 355,
                                    active: true,
                                    name: "published",
                                    type: "BOOLEAN",
                                    required: true,
                                    repeats: false,
                                    entity: null,
                                    htmlElement: null,
                                    position: 1,
                                    requiredOnForm: true,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                }
                            ],
                            contentType: "SEASON",
                            mappingFile: "season.json",
                            exposedEntity: "CONTENT"
                        },
                        htmlElement: null,
                        position: 0,
                        requiredOnForm: false,
                        filterable: false,
                        searchable: false,
                        primary: false
                    },
                    {
                        id: 357,
                        active: true,
                        name: "published",
                        type: "BOOLEAN",
                        required: true,
                        repeats: false,
                        entity: null,
                        htmlElement: null,
                        position: 1,
                        requiredOnForm: true,
                        filterable: false,
                        searchable: false,
                        primary: false
                    }
                ],
                contentType: "OTHER",
                mappingFile: null,
                exposedEntity: null
            },
            htmlElement: {
                id: 264,
                active: true,
                elementType: "FORM",
                elementSubType: null,
                elementSize: null,
                elementObjectType: null,
                placeholder: "",
                label: "Add Season",
                horizontalPosition: 2
            },
            position: 0,
            requiredOnForm: false,
            filterable: false,
            searchable: false,
            primary: false
        }
    ],
    contentType: "WEB_SERIES",
    mappingFile: "web_series.json",
    exposedEntity: "CONTENT"
}

export const liveTvData = {
    id: 120,
    active: true,
    name: "LIVE_TV",
    description: "LIVE_TV",
    masterData: false,
    published: false,
    attributes: [
        {
            id: 362,
            active: true,
            name: "addNewChannel",
            type: "OBJECT",
            required: false,
            repeats: false,
            entity: {
                id: 121,
                active: true,
                name: "addNewChannel",
                description: "addNewChannel",
                masterData: false,
                published: false,
                attributes: [
                    {
                        id: 359,
                        active: true,
                        name: "channelLogo",
                        type: "STRING",
                        required: true,
                        repeats: false,
                        entity: null,
                        htmlElement: {
                            id: 310,
                            active: true,
                            elementType: "INPUT",
                            elementSubType: "FILE",
                            elementSize: "SMALL",
                            elementObjectType: "POSTER",
                            placeholder: "CHANNEL LOGO",
                            label: "CHANNEL LOGO",
                            horizontalPosition: null
                        },
                        position: 0,
                        requiredOnForm: false,
                        filterable: false,
                        searchable: false,
                        primary: false
                    },
                    {
                        id: 360,
                        active: true,
                        name: "title",
                        type: "STRING",
                        required: true,
                        repeats: false,
                        entity: null,
                        htmlElement: {
                            id: 311,
                            active: true,
                            elementType: "INPUT",
                            elementSubType: "TEXT",
                            elementSize: "LARGE",
                            elementObjectType: null,
                            placeholder: "",
                            label: "TITLE",
                            horizontalPosition: null
                        },
                        position: 0,
                        requiredOnForm: false,
                        filterable: false,
                        searchable: false,
                        primary: false
                    },
                    {
                        id: 361,
                        active: true,
                        name: "playUrl",
                        type: "STRING",
                        required: true,
                        repeats: false,
                        entity: null,
                        htmlElement: {
                            id: 312,
                            active: true,
                            elementType: "INPUT",
                            elementSubType: "URL",
                            elementSize: "MEDIUM",
                            elementObjectType: null,
                            placeholder: "http://",
                            label: "PLAY URL",
                            horizontalPosition: null
                        },
                        position: 0,
                        requiredOnForm: false,
                        filterable: false,
                        searchable: false,
                        primary: false
                    }
                ],
                contentType: "OTHER",
                mappingFile: null,
                exposedEntity: "INTERNAL"
            },
            htmlElement: {
                id: 309,
                active: true,
                elementType: null,
                elementSubType: null,
                elementSize: null,
                elementObjectType: null,
                placeholder: null,
                label: "ADD NEW CHANNEL",
                horizontalPosition: 0,
                showTitle: true
            },
            position: 0,
            requiredOnForm: false,
            filterable: false,
            searchable: false,
            primary: false
        },
        {
            id: 369,
            active: true,
            name: "description",
            type: "OBJECT",
            required: true,
            repeats: false,
            entity: {
                id: 122,
                active: true,
                name: "description",
                description: "description",
                masterData: false,
                published: false,
                attributes: [
                    {
                        id: 363,
                        active: true,
                        name: "description",
                        type: "STRING",
                        required: true,
                        repeats: false,
                        entity: null,
                        htmlElement: {
                            id: 314,
                            active: true,
                            elementType: "INPUT",
                            elementSubType: "TEXT",
                            elementSize: "LARGE",
                            elementObjectType: null,
                            placeholder: "CHANNEL DESCRIPTION",
                            label: "DESCRIPTION",
                            horizontalPosition: null
                        },
                        position: 0,
                        requiredOnForm: false,
                        filterable: false,
                        searchable: false,
                        primary: false
                    },
                    {
                        id: 365,
                        active: true,
                        name: "tvGenre",
                        type: "OBJECT",
                        required: true,
                        repeats: true,
                        entity: {
                            id: 123,
                            active: true,
                            name: "tvGenre",
                            description: "tvGenre",
                            masterData: true,
                            published: false,
                            attributes: [
                                {
                                    id: 364,
                                    active: true,
                                    name: "id",
                                    type: "NUMBER",
                                    required: true,
                                    repeats: false,
                                    entity: null,
                                    htmlElement: null,
                                    position: 0,
                                    requiredOnForm: false,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                },
                                {
                                    id: 370,
                                    active: true,
                                    name: "name",
                                    type: "STRING",
                                    required: true,
                                    repeats: false,
                                    entity: null,
                                    htmlElement: null,
                                    position: 0,
                                    requiredOnForm: false,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                }
                            ],
                            contentType: "TV_GENRE",
                            mappingFile: null,
                            exposedEntity: "INTERNAL"
                        },
                        htmlElement: {
                            id: 315,
                            active: true,
                            elementType: "SELECT",
                            elementSubType: "MULTI_SELECT",
                            elementSize: "HALF",
                            elementObjectType: null,
                            placeholder: "",
                            label: "GENRE",
                            horizontalPosition: null
                        },
                        position: 0,
                        requiredOnForm: false,
                        filterable: false,
                        searchable: false,
                        primary: false
                    },
                    {
                        id: 367,
                        active: true,
                        name: "language",
                        type: "OBJECT",
                        required: true,
                        repeats: true,
                        entity: {
                            id: 124,
                            active: true,
                            name: "language",
                            description: "language",
                            masterData: true,
                            published: false,
                            attributes: [
                                {
                                    id: 366,
                                    active: true,
                                    name: "id",
                                    type: "NUMBER",
                                    required: true,
                                    repeats: false,
                                    entity: null,
                                    htmlElement: null,
                                    position: 0,
                                    requiredOnForm: false,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                }
                            ],
                            contentType: "OTHER",
                            mappingFile: null,
                            exposedEntity: "INTERNAL"
                        },
                        htmlElement: {
                            id: 316,
                            active: true,
                            elementType: "SELECT",
                            elementSubType: "MULTI_SELECT",
                            elementSize: "HALF",
                            elementObjectType: null,
                            placeholder: null,
                            label: "LANGUAGE",
                            horizontalPosition: null
                        },
                        position: 0,
                        requiredOnForm: false,
                        filterable: false,
                        searchable: false,
                        primary: false
                    },
                    {
                        id: 368,
                        active: true,
                        name: "catchUpAvailable",
                        type: "STRING",
                        required: true,
                        repeats: false,
                        entity: null,
                        htmlElement: {
                            id: 317,
                            active: true,
                            elementType: "INPUT",
                            elementSubType: "RADIO",
                            elementSize: "HALF",
                            elementObjectType: null,
                            placeholder: null,
                            label: "CATCH UP",
                            horizontalPosition: null
                        },
                        position: 0,
                        requiredOnForm: false,
                        filterable: false,
                        searchable: false,
                        primary: false
                    }
                ],
                contentType: "OTHER",
                mappingFile: null,
                exposedEntity: "INTERNAL"
            },
            htmlElement: {
                id: 313,
                active: true,
                elementType: null,
                elementSubType: null,
                elementSize: null,
                elementObjectType: null,
                placeholder: null,
                label: "DESCRIPTION",
                horizontalPosition: 0,
                showTitle: true
            },
            position: 0,
            requiredOnForm: false,
            filterable: false,
            searchable: false,
            primary: false
        }
    ],
    contentType: "OTHER",
    mappingFile: null,
    exposedEntity: "CONTENT"
}

export const moviesData = {
    id: 15,
    active: true,
    name: "MOVIES",
    description: "movies",
    masterData: false,
    published: true,
    attributes: [
        {
            id: 31,
            active: true,
            name: "addNewMovie",
            type: "OBJECT",
            required: true,
            repeats: false,
            entity: {
                id: 1,
                active: true,
                name: "addNewMovie",
                description: "Add New Movie",
                masterData: false,
                published: false,
                attributes: [
                    {
                        id: 2,
                        active: true,
                        name: "posterImage",
                        type: "FILE",
                        required: true,
                        repeats: false,
                        entity: null,
                        htmlElement: {
                            id: 2,
                            active: true,
                            elementType: "INPUT",
                            elementSubType: "FILE",
                            elementSize: "SMALL",
                            elementObjectType: "POSTER",
                            placeholder: "POSTER IMAGE",
                            label: "ADD POSTER IMAGE",
                            horizontalPosition: null
                        },
                        position: 1,
                        requiredOnForm: false,
                        filterable: false,
                        searchable: false,
                        primary: false
                    },
                    {
                        id: 3,
                        active: true,
                        name: "coverImage",
                        type: "FILE",
                        required: true,
                        repeats: false,
                        entity: null,
                        htmlElement: {
                            id: 3,
                            active: true,
                            elementType: "INPUT",
                            elementSubType: "FILE",
                            elementSize: "LARGE",
                            elementObjectType: "POSTER",
                            placeholder: "COVER IMAGE",
                            label: "ADD COVER IMAGE",
                            horizontalPosition: null
                        },
                        position: 2,
                        requiredOnForm: false,
                        filterable: false,
                        searchable: false,
                        primary: false
                    },
                    {
                        id: 4,
                        active: true,
                        name: "playUrl",
                        type: "STRING",
                        required: true,
                        repeats: false,
                        entity: null,
                        htmlElement: {
                            id: 4,
                            active: true,
                            elementType: "INPUT",
                            elementSubType: "URL",
                            elementSize: "MEDIUM",
                            elementObjectType: null,
                            placeholder: "http://",
                            label: "PLAY URL",
                            horizontalPosition: null
                        },
                        position: 4,
                        requiredOnForm: false,
                        filterable: false,
                        searchable: false,
                        primary: false
                    },
                    {
                        id: 5,
                        active: true,
                        name: "trailerUrl",
                        type: "STRING",
                        required: true,
                        repeats: false,
                        entity: null,
                        htmlElement: {
                            id: 5,
                            active: true,
                            elementType: "INPUT",
                            elementSubType: "URL",
                            elementSize: "MEDIUM",
                            elementObjectType: null,
                            placeholder: "http://",
                            label: "TRAILER URL",
                            horizontalPosition: null
                        },
                        position: 5,
                        requiredOnForm: false,
                        filterable: false,
                        searchable: false,
                        primary: false
                    },
                    {
                        id: 6,
                        active: true,
                        name: "playDuration",
                        type: "NUMBER",
                        required: true,
                        repeats: false,
                        entity: null,
                        htmlElement: {
                            id: 6,
                            active: true,
                            elementType: "INPUT",
                            elementSubType: "TEXT",
                            elementSize: "SMALL",
                            elementObjectType: null,
                            placeholder: "HH/MM/SS",
                            label: "DURATION",
                            horizontalPosition: null
                        },
                        position: 6,
                        requiredOnForm: true,
                        filterable: false,
                        searchable: false,
                        primary: false
                    }
                ],
                contentType: null,
                mappingFile: null,
                exposedEntity: null
            },
            htmlElement: {
                id: 17,
                active: true,
                elementType: null,
                elementSubType: null,
                elementSize: null,
                elementObjectType: null,
                placeholder: null,
                label: "ADD NEW MOVIE",
                horizontalPosition: null,
                showTitle: true
            },
            position: 1,
            requiredOnForm: true,
            filterable: false,
            searchable: false,
            primary: false
        },
        {
            id: 32,
            active: true,
            name: "movieDescription",
            type: "OBJECT",
            required: true,
            repeats: false,
            entity: {
                id: 3,
                active: true,
                name: "MOVIE DESCRIPTION",
                description: "MOVIE DESC",
                masterData: false,
                published: false,
                attributes: [
                    {
                        id: 1,
                        active: true,
                        name: "movieTitle",
                        type: "STRING",
                        required: true,
                        repeats: false,
                        entity: null,
                        htmlElement: {
                            id: 1,
                            active: true,
                            elementType: "INPUT",
                            elementSubType: "TEXT",
                            elementSize: "LARGE",
                            elementObjectType: null,
                            placeholder: "",
                            label: "MOVIE TITLE",
                            horizontalPosition: null
                        },
                        position: 3,
                        requiredOnForm: true,
                        filterable: false,
                        searchable: false,
                        primary: false
                    },
                    {
                        id: 9,
                        active: true,
                        name: "description",
                        type: "STRING",
                        required: true,
                        repeats: false,
                        entity: null,
                        htmlElement: {
                            id: 7,
                            active: true,
                            elementType: "INPUT",
                            elementSubType: "TEXTAREA",
                            elementSize: "LARGE",
                            elementObjectType: null,
                            placeholder: "",
                            label: "MOVIE DESCRIPTION",
                            horizontalPosition: null
                        },
                        position: 0,
                        requiredOnForm: true,
                        filterable: false,
                        searchable: false,
                        primary: false
                    },
                    {
                        id: 10,
                        active: true,
                        name: "genre",
                        type: "OBJECT",
                        required: true,
                        repeats: true,
                        entity: {
                            id: 4,
                            active: true,
                            name: "genre",
                            description: "genre",
                            masterData: true,
                            published: false,
                            attributes: [
                                {
                                    id: 7,
                                    active: true,
                                    name: "id",
                                    type: "NUMBER",
                                    required: true,
                                    repeats: false,
                                    entity: null,
                                    htmlElement: null,
                                    position: 0,
                                    requiredOnForm: true,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                },
                                {
                                    id: 8,
                                    active: true,
                                    name: "name",
                                    type: "STRING",
                                    required: true,
                                    repeats: false,
                                    entity: null,
                                    htmlElement: null,
                                    position: 0,
                                    requiredOnForm: true,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                }
                            ],
                            contentType: "GENRE",
                            mappingFile: null,
                            exposedEntity: null
                        },
                        htmlElement: {
                            id: 8,
                            active: true,
                            elementType: "SELECT",
                            elementSubType: "MULTI_SELECT",
                            elementSize: "HALF",
                            elementObjectType: null,
                            placeholder: "",
                            label: "GENRE",
                            horizontalPosition: null
                        },
                        position: 0,
                        requiredOnForm: true,
                        filterable: false,
                        searchable: false,
                        primary: false
                    },
                    {
                        id: 11,
                        active: true,
                        name: "language",
                        type: "OBJECT",
                        required: true,
                        repeats: true,
                        entity: {
                            id: 6,
                            active: true,
                            name: "language",
                            description: "language",
                            masterData: true,
                            published: false,
                            attributes: [
                                {
                                    id: 12,
                                    active: true,
                                    name: "name",
                                    type: "STRING",
                                    required: true,
                                    repeats: false,
                                    entity: null,
                                    htmlElement: null,
                                    position: 0,
                                    requiredOnForm: true,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                },
                                {
                                    id: 13,
                                    active: true,
                                    name: "id",
                                    type: "NUMBER",
                                    required: true,
                                    repeats: false,
                                    entity: null,
                                    htmlElement: null,
                                    position: 0,
                                    requiredOnForm: true,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                }
                            ],
                            contentType: "LANGUAGE",
                            mappingFile: null,
                            exposedEntity: null
                        },
                        htmlElement: {
                            id: 9,
                            active: true,
                            elementType: "SELECT",
                            elementSubType: "MULTI_SELECT",
                            elementSize: "HALF",
                            elementObjectType: null,
                            placeholder: null,
                            label: "LANGUAGE",
                            horizontalPosition: null
                        },
                        position: 0,
                        requiredOnForm: true,
                        filterable: false,
                        searchable: false,
                        primary: false
                    },
                    {
                        id: 14,
                        active: true,
                        name: "movieYear",
                        type: "OBJECT",
                        required: true,
                        repeats: false,
                        entity: {
                            id: 30,
                            active: true,
                            name: "year",
                            description: "year",
                            masterData: true,
                            published: false,
                            attributes: [
                                {
                                    id: 77,
                                    active: true,
                                    name: "id",
                                    type: "NUMBER",
                                    required: true,
                                    repeats: false,
                                    entity: null,
                                    htmlElement: null,
                                    position: 0,
                                    requiredOnForm: true,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                },
                                {
                                    id: 78,
                                    active: true,
                                    name: "name",
                                    type: "STRING",
                                    required: true,
                                    repeats: false,
                                    entity: null,
                                    htmlElement: null,
                                    position: 0,
                                    requiredOnForm: true,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                }
                            ],
                            contentType: "OTHER",
                            mappingFile: null,
                            exposedEntity: null
                        },
                        htmlElement: {
                            id: 10,
                            active: true,
                            elementType: "SELECT",
                            elementSubType: "SELECT",
                            elementSize: "EXTRA_SMALL",
                            elementObjectType: null,
                            placeholder: null,
                            label: "MOVIE YEAR",
                            horizontalPosition: null
                        },
                        position: 0,
                        requiredOnForm: true,
                        filterable: false,
                        searchable: false,
                        primary: false
                    },
                    {
                        id: 15,
                        active: true,
                        name: "rating",
                        type: "OBJECT",
                        required: true,
                        repeats: false,
                        entity: {
                            id: 31,
                            active: true,
                            name: "rating",
                            description: "rating",
                            masterData: true,
                            published: false,
                            attributes: [
                                {
                                    id: 79,
                                    active: true,
                                    name: "id",
                                    type: "NUMBER",
                                    required: true,
                                    repeats: false,
                                    entity: null,
                                    htmlElement: null,
                                    position: 0,
                                    requiredOnForm: true,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                },
                                {
                                    id: 80,
                                    active: true,
                                    name: "name",
                                    type: "NUMBER",
                                    required: true,
                                    repeats: false,
                                    entity: null,
                                    htmlElement: null,
                                    position: 0,
                                    requiredOnForm: true,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                }
                            ],
                            contentType: "OTHER",
                            mappingFile: null,
                            exposedEntity: null
                        },
                        htmlElement: {
                            id: 11,
                            active: true,
                            elementType: "SELECT",
                            elementSubType: "SELECT",
                            elementSize: "EXTRA_SMALL",
                            elementObjectType: null,
                            placeholder: null,
                            label: "RATING",
                            horizontalPosition: null
                        },
                        position: 0,
                        requiredOnForm: true,
                        filterable: false,
                        searchable: false,
                        primary: false
                    },
                    {
                        id: 18,
                        active: true,
                        name: "videoFormat",
                        type: "OBJECT",
                        required: true,
                        repeats: true,
                        entity: {
                            id: 7,
                            active: true,
                            name: "videoFormat",
                            description: "videoFormat",
                            masterData: true,
                            published: false,
                            attributes: [
                                {
                                    id: 16,
                                    active: true,
                                    name: "name",
                                    type: "STRING",
                                    required: true,
                                    repeats: false,
                                    entity: null,
                                    htmlElement: null,
                                    position: 0,
                                    requiredOnForm: true,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                },
                                {
                                    id: 17,
                                    active: true,
                                    name: "id",
                                    type: "NUMBER",
                                    required: true,
                                    repeats: false,
                                    entity: null,
                                    htmlElement: null,
                                    position: 0,
                                    requiredOnForm: true,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                }
                            ],
                            contentType: "OTHER",
                            mappingFile: null,
                            exposedEntity: null
                        },
                        htmlElement: {
                            id: 12,
                            active: true,
                            elementType: "INPUT",
                            elementSubType: "CHECKBOX",
                            elementSize: "HALF",
                            elementObjectType: null,
                            placeholder: null,
                            label: "VIDEO FORMAT",
                            horizontalPosition: null
                        },
                        position: 0,
                        requiredOnForm: true,
                        filterable: false,
                        searchable: false,
                        primary: false
                    }
                ],
                contentType: null,
                mappingFile: null,
                exposedEntity: null
            },
            htmlElement: {
                id: 18,
                active: true,
                elementType: null,
                elementSubType: null,
                elementSize: null,
                elementObjectType: null,
                placeholder: null,
                label: "DESCRIPTION",
                horizontalPosition: null,
                showTitle: true
            },
            position: 2,
            requiredOnForm: true,
            filterable: false,
            searchable: false,
            primary: false
        },
        {
            id: 33,
            active: true,
            name: "movieCredit",
            type: "OBJECT",
            required: true,
            repeats: false,
            entity: {
                id: 8,
                active: true,
                name: "credit",
                description: "CREDIT",
                masterData: false,
                published: false,
                attributes: [
                    {
                        id: 26,
                        active: true,
                        name: "director",
                        type: "OBJECT",
                        required: true,
                        repeats: true,
                        entity: {
                            id: 10,
                            active: true,
                            name: "director",
                            description: "director",
                            masterData: true,
                            published: false,
                            attributes: [
                                {
                                    id: 20,
                                    active: true,
                                    name: "name",
                                    type: "STRING",
                                    required: true,
                                    repeats: false,
                                    entity: null,
                                    htmlElement: null,
                                    position: 0,
                                    requiredOnForm: true,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                },
                                {
                                    id: 23,
                                    active: true,
                                    name: "id",
                                    type: "STRING",
                                    required: true,
                                    repeats: false,
                                    entity: null,
                                    htmlElement: null,
                                    position: 0,
                                    requiredOnForm: true,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                },
                                {
                                    id: 301,
                                    active: true,
                                    name: "url",
                                    type: "STRING",
                                    required: true,
                                    repeats: false,
                                    entity: null,
                                    htmlElement: null,
                                    position: 0,
                                    requiredOnForm: true,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                }
                            ],
                            contentType: "OTHER",
                            mappingFile: null,
                            exposedEntity: null
                        },
                        htmlElement: {
                            id: 14,
                            active: true,
                            elementType: "SELECT",
                            elementSubType: "MULTI_SELECT",
                            elementSize: "HALF",
                            elementObjectType: null,
                            placeholder: null,
                            label: "DIRECTOR",
                            horizontalPosition: null
                        },
                        position: 2,
                        requiredOnForm: true,
                        filterable: false,
                        searchable: false,
                        primary: false
                    },
                    {
                        id: 27,
                        active: true,
                        name: "producer",
                        type: "OBJECT",
                        required: true,
                        repeats: true,
                        entity: {
                            id: 11,
                            active: true,
                            name: "producer",
                            description: "producer",
                            masterData: true,
                            published: false,
                            attributes: [
                                {
                                    id: 21,
                                    active: true,
                                    name: "name",
                                    type: "STRING",
                                    required: true,
                                    repeats: false,
                                    entity: null,
                                    htmlElement: null,
                                    position: 0,
                                    requiredOnForm: true,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                },
                                {
                                    id: 22,
                                    active: true,
                                    name: "id",
                                    type: "STRING",
                                    required: true,
                                    repeats: false,
                                    entity: null,
                                    htmlElement: null,
                                    position: 0,
                                    requiredOnForm: true,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                },
                                {
                                    id: 302,
                                    active: true,
                                    name: "url",
                                    type: "STRING",
                                    required: true,
                                    repeats: false,
                                    entity: null,
                                    htmlElement: null,
                                    position: 0,
                                    requiredOnForm: true,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                }
                            ],
                            contentType: "OTHER",
                            mappingFile: null,
                            exposedEntity: null
                        },
                        htmlElement: {
                            id: 15,
                            active: true,
                            elementType: "SELECT",
                            elementSubType: "MULTI_SELECT",
                            elementSize: "HALF",
                            elementObjectType: null,
                            placeholder: null,
                            label: "PRODUCER",
                            horizontalPosition: null
                        },
                        position: 3,
                        requiredOnForm: true,
                        filterable: false,
                        searchable: false,
                        primary: false
                    },
                    {
                        id: 36,
                        active: true,
                        name: "actor",
                        type: "OBJECT",
                        required: true,
                        repeats: true,
                        entity: {
                            id: 9,
                            active: true,
                            name: "actor",
                            description: "actor",
                            masterData: true,
                            published: false,
                            attributes: [
                                {
                                    id: 19,
                                    active: true,
                                    name: "name",
                                    type: "STRING",
                                    required: true,
                                    repeats: false,
                                    entity: null,
                                    htmlElement: null,
                                    position: 0,
                                    requiredOnForm: true,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                },
                                {
                                    id: 24,
                                    active: true,
                                    name: "id",
                                    type: "STRING",
                                    required: true,
                                    repeats: false,
                                    entity: null,
                                    htmlElement: null,
                                    position: 0,
                                    requiredOnForm: true,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                },
                                {
                                    id: 300,
                                    active: true,
                                    name: "url",
                                    type: "STRING",
                                    required: true,
                                    repeats: false,
                                    entity: null,
                                    htmlElement: null,
                                    position: 0,
                                    requiredOnForm: true,
                                    filterable: false,
                                    searchable: false,
                                    primary: false
                                }
                            ],
                            contentType: "OTHER",
                            mappingFile: null,
                            exposedEntity: null
                        },
                        htmlElement: {
                            id: 13,
                            active: true,
                            elementType: "SEARCHABLE_LIST",
                            elementSubType: null,
                            elementSize: "LARGE",
                            elementObjectType: null,
                            placeholder: null,
                            label: "STARRING",
                            horizontalPosition: null
                        },
                        position: 1,
                        requiredOnForm: true,
                        filterable: false,
                        searchable: false,
                        primary: false
                    }
                ],
                contentType: null,
                mappingFile: null,
                exposedEntity: null
            },
            htmlElement: {
                id: 19,
                active: true,
                elementType: null,
                elementSubType: null,
                elementSize: null,
                elementObjectType: null,
                placeholder: null,
                label: "CREDIT",
                horizontalPosition: null
            },
            position: 3,
            requiredOnForm: true,
            filterable: false,
            searchable: false,
            primary: false
        }
    ],
    contentType: "MOVIES",
    mappingFile: "movies_local.json",
    exposedEntity: "CONTENT"
}

export const createWebSeries = {
    entityAttributeId: 90,
    data: [
        {
            addBrand: {
                posterImage: "content/1591194632849-p7892603_b_v8_ad.jpg",
                coverImage: "content/1591194632849-p7892603_b_v8_ad.jpg",
                brandTitle: "F.R.I.E.N.D.S.",
                trailerUrl: "https://www.youtube.com/watch?v=q-9kPks0IfE",
                playDuration: 11120,
                brandDescription: {
                    description:
                        "Follow the lives of six reckless adults living in Manhattan, as they indulge in adventures which make their lives both troublesome and happening.",
                    genre: [
                        {
                            id: 2
                        }
                    ],
                    language: [
                        {
                            id: 2
                        }
                    ],
                    year: {
                        id: 3
                    },
                    rating: {
                        id: 5
                    },
                    videoFormat: [
                        {
                            id: 2
                        }
                    ]
                }
            },
            addSeason: {
                season: [
                    {
                        seasonPosterImage:
                            "content/1591194632849-p7892603_b_v8_ad.jpg",
                        coverImage:
                            "content/1591194632849-p7892603_b_v8_ad.jpg",
                        seasonTitle: "Season-1",
                        description:
                            "An introduction to the gang. After Rachel leaves her Mr Potato Head look-alike fiancé Barry at the altar, she moves in with Monica and discovers that independence sucks when you don't have Daddy's credit cards to rely on",
                        trailerUrl:
                            "https://www.youtube.com/watch?v=q-9kPks0IfE",
                        playDuration: 1120,
                        seasonYear: {
                            id: 3
                        },
                        rating: {
                            id: 5
                        },
                        videoFormat: [
                            {
                                id: 2
                            }
                        ],
                        addEpisode: {
                            episode: [
                                {
                                    episodeTitle: "Episode-1",
                                    playUrl:
                                        "https://www.youtube.com/watch?v=q-9kPks0IfE",
                                    playDuration: 1140,
                                    description: "Pilot as first ",
                                    videoFormat: [
                                        {
                                            id: 2
                                        }
                                    ]
                                },
                                {
                                    episodeTitle: "Episode-2",
                                    playUrl:
                                        "https://www.youtube.com/watch?v=q-9kPks0IfE",
                                    playDuration: 1140,
                                    description: "Pilot as second ",
                                    videoFormat: [
                                        {
                                            id: 2
                                        }
                                    ]
                                }
                            ]
                        }
                    },
                    {
                        seasonPosterImage:
                            "content/1591194632849-p7892603_b_v8_ad.jpg",
                        coverImage:
                            "content/1591194632849-p7892603_b_v8_ad.jpg",
                        seasonTitle: "Season-2",
                        description:
                            "An introduction to the gang. After Rachel leaves her Mr Potato Head look-alike fiancé Barry at the altar, she moves in with Monica and discovers that independence sucks when you don't have Daddy's credit cards to rely on",
                        trailerUrl:
                            "https://www.youtube.com/watch?v=q-9kPks0IfE",
                        playDuration: 1120,
                        seasonYear: {
                            id: 3
                        },
                        rating: {
                            id: 5
                        },
                        videoFormat: [
                            {
                                id: 2
                            }
                        ],
                        addEpisode: {
                            episode: [
                                {
                                    episodeTitle: "Season-2 Episode-1 updated",
                                    playUrl:
                                        "https://www.youtube.com/watch?v=q-9kPks0IfE",
                                    playDuration: 1140,
                                    description: "Pilot as first ",
                                    videoFormat: [
                                        {
                                            id: 2
                                        }
                                    ]
                                },
                                {
                                    episodeTitle: "Season-2 Episode-2 updated",
                                    playUrl:
                                        "https://www.youtube.com/watch?v=q-9kPks0IfE",
                                    playDuration: 1140,
                                    description: "Pilot as second ",
                                    videoFormat: [
                                        {
                                            id: 2
                                        }
                                    ]
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
}
// const array1 = [];
// let arr = [];
// const getSeries = (array, outerEntity = {}) => {
//   array.forEach((element, index) => {
//     if (element.entity) {

//       //to skip the attributes as the data is populated by masterdata such as multi select, searchable
//       if (element.entity.masterData) {
//         // debugger;
//         element.entity.active &&
//           array1.push({
//             refId: `${outerEntity && outerEntity.id}-${element && element.id}-${
//               element && element.htmlElement && element.htmlElement.id
//               }`,
//             entityId: outerEntity && outerEntity.id,
//             attributeId: element && element.id,
//             htmlElement: element && element.htmlElement,
//           });
//       } else {
//         element.entity.active &&
//           array1.push({
//             refId: `${element.entity && element.entity.id}-${
//               element && element.id
//               }-${element && element.htmlElement && element.htmlElement.id}`,
//             // entityId: outerEntity && outerEntity.id,
//             entityId: element.entity.id,
//             attributeId: element && element.id,
//             htmlElement: element && element.htmlElement,
//             position: element && element.position
//           });
//         return getSeries(element.entity.attributes, element.entity);
//       }

//       // if (element.htmlElement && element.htmlElement.elementType === "SELECT") {
//       //   // debugger;
//       // } else {
//       //   return getSeries(element.entity.attributes);
//       // }
//     } else {
//       element.active &&
//         array1.push({
//           refId: `${outerEntity && outerEntity.id}-${element && element.id}-${
//             element && element.htmlElement && element.htmlElement.id
//             }`,
//           entityId: outerEntity && outerEntity.id,
//           attributeId: element && element.id,
//           htmlElement: element && element.htmlElement,
//           position: element && element.position
//         });
//       return array1;
//     }
//   });
// };
// data.attributes.map((item, index) => {
//   // debugger;
//   getSeries(item.entity.attributes, item.entity);
//   arr[index] = !index ? [...array1] : array1.slice(arr[index - 1].length)

// });
// // getSeries(data1.attributes, data1.entity);

// export const formattedData = data;
// //export const data= data;
