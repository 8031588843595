import { imagePathPrefix } from "../../../config/apiPath"

export const ACTION = {
    FETCH_FORM_ELEMENT: "FETCH_FORM_ELEMENT",
    CREATE_CONTENT: "CREATE_CONTENT",
    UPDATE_CONTENT: "UPDATE_CONTENT",
    FETCH_CONTENT: "FETCH_CONTENT",
    FETCH_ENTITY_VALUE_MASTER_DATA: "FETCH_ENTITY_VALUE_MASTER_DATA",
    FETCH_CONTENT_LISTING: "FETCH_CONTENT_LISTING",
    UPLOAD_IMAGE: "UPLOAD_IMAGE",
    DELETE_IMAGE: "DELETE_IMAGE",
    DELETE_CONTENT: "DELETE_CONTENT",
    RESTART_STREAM: "RESTART_STREAM",
    SAVE_REELS: "SAVE_REELS",
    FETCH_GENRE: "FETCH_GENRE",
    FETCH_CERTIFICATION: "FETCH_CERTIFICATION"
}

export const TABLE_HEADING = [
    { key: "content", value: "content" },
    { key: "title", value: "title" },
    { key: "genre", value: "genre" },
    { key: "publishedOn", value: "published on" },
    { key: "action", value: "actions" }
]
export const TABLE_LISTING_HEADING = [
    { key: "content", value: "content" },
    { key: "title", value: "title" },
    { key: "publishedOn", value: "published on" }
]

export const EPISODE_HEADING = [
    { key: "episode", value: "episode" },
    { key: "name", value: "name" },
    { key: "episode_id", value: "episode id" },
    { key: "air_date", value: "air date" }
    // { key: "action", value: "action" },
]
export const RIGHTS_HEADING = [
    { key: "packagename", value: "package name" },
    { key: "starttime", value: "start time" },
    { key: "endtime", value: "end time" },
    { key: "timezone", value: "timezone" }
]

export const imgPathPrefix = imagePathPrefix

export const options = [
    { id: 10, name: "10" },
    { id: 20, name: "20" },
    { id: 30, name: "30" },
    { id: 50, name: "50" }
]

export const DEFAULT_SELECTED_LANGUAGE_CODE = "en"
export const DEFAULT_SELECTED_LANGUAGE_CODE_UPDATED = "eng"
export const DEFAULT_SELECTED_LANGUAGE_OBJ = { en: "" }
