import { removeLoader, setLoader } from "../../common/Loader/api/action"
import ForceUpgradeServiceInstance from "./service"

export const setConfig = (params) => {
    return (dispatch) => {
        dispatch(setLoader())
        return ForceUpgradeServiceInstance.setConfig(params)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in submitContentType ", error)
                return error
            })
            .finally(() => {
                dispatch(removeLoader())
            })
    }
}

export const getConfig = (params, status) => {
    return (dispatch) => {
        dispatch(setLoader())
        return ForceUpgradeServiceInstance.getConfig(params, status)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in submitContentType ", error)
            })
            .finally(() => {
                dispatch(removeLoader())
            })
    }
}

export const toggleState = (params, id) => {
    return (dispatch) => {
        dispatch(setLoader())
        return ForceUpgradeServiceInstance.toggleState(params, id)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in toggleState ", error)
            })
            .finally(() => {
                dispatch(removeLoader())
            })
    }
}

export const fetchLanguageList = (params, status) => {
    return (dispatch) => {
        dispatch(setLoader())
        return ForceUpgradeServiceInstance.fetchLanguageList(params, status)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in toggleState ", error)
            })
            .finally(() => {
                dispatch(removeLoader())
            })
    }
}

export const validateForceUpgrade = (params) => {
    return (dispatch) => {
        dispatch(setLoader())
        return ForceUpgradeServiceInstance.validateForceUpgrade(params)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log("Got error in validateForceUpgrade ", error)
            })
            .finally(() => {
                dispatch(removeLoader())
            })
    }
}
