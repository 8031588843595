import React from "react"
import "./style.scss"

const SelectedButtons = (props) => {
    const { list, label, changeHandler, errMsg } = props
    return (
        <>
            <div className="selected-btn-container">
                {label && (
                    <label htmlFor="selected-lists" className="labelText">
                        {`${label} ${
                            list.length > 0 ? `(${list.length})` : ""
                        }`}
                    </label>
                )}
                <div
                    className={
                        errMsg ? "selected-list tagInputErr" : "selected-list"
                    }>
                    {list.map((value, index) => {
                        return (
                            <div key={index} className="label">
                                {value.title}
                                <span
                                    onClick={(e) =>
                                        changeHandler(
                                            value.title,
                                            false,
                                            value.language
                                        )
                                    }>
                                    x
                                </span>
                            </div>
                        )
                    })}
                </div>
            </div>
            {errMsg && <span className="err">{errMsg}</span>}
        </>
    )
}

export default SelectedButtons
