import ApiService from "../../../utils/apiService"
import { API_ROOT_PATH } from "../../../config/apiPath"

class FormBuilderMngService {
    fetchContentList() {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/content-entity/contentTypes`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
    fetchContentEntityAll() {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/content-entity/all`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
    saveForm(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/content/content-entity`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }
    deleteForm(params) {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/content/content-entity/delete/${params.id}`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    }
}

const FormBuilderMangementService = new FormBuilderMngService()
export default FormBuilderMangementService
