import React from "react"
import PropTypes from "prop-types"
import "./style.scss"

const Checkbox = (props) => {
    const {
        id,
        checked,
        disabled,
        name,
        changeHandler,
        cName,
        labelText,
        image
    } = props
    return (
        <div className="checkBox">
            <input
                type="checkbox"
                disabled={disabled}
                name={name}
                checked={checked}
                id={id}
                onChange={changeHandler}
                className={cName}
            />
            <div className="actor-image">
                <img src={image} alt="actor" className={"imgIcon"} />
            </div>
            <label className="checkbox-text">{labelText}</label>
        </div>
    )
}

Checkbox.propTypes = {
    labelText: PropTypes.string,
    changeHandler: PropTypes.func,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    cName: PropTypes.string,
    id: PropTypes.string
}

export default Checkbox
