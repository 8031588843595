import React, { Component } from "react"
import Slider from "react-slick"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import { toast } from "react-toastify"

import MovieCardGrid from "../../common/MovieCardGrid"
import Checkbox from "../../common/Checkbox"
import BannerCard from "./FeaturedBannerCard"
import { createRail, getLatestPosition, fetchCategory } from "../api/action"
import System from "../../../assets/images/system.png"

import "./style.scss"
import {
    BUTTON_CONSTANTS,
    ERROR_MESSAGES,
    PERMISSIONS,
    TEXT_CONSTANTS
} from "../../../utils/constants"
import { AuthWrapper } from "../../common/AuthWrapper"
import { DEFAULT_SELECTED_LANGUAGE_CODE } from "../../ContentNav/api/constants"
import {
    CONTENT_TYPE_CONST,
    PUBLISH_UNPULISH_GROUP,
    RAIL_TYPE_CONSTANT,
    RESPONSIVE_1360_BREAKPOINT,
    RESPONSIVE_1680_BREAKPOINT,
    SPORTS_HOME_PAGE_TAB
} from "../api/constant"
import { getGMTTimezone } from "../../../utils/common"
import DuplicateRailModal from "./DuplicateRailModal"

class CategoryDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
            railToDuplicate: null,
            platformList: [],
            categoryList: [],
            platform: null,
            category: null,
            railStage: null,
            unpublishPopup: false,
            deletePopup: false,
            idToDelete: null,
            showPlayer: false
        }
        this.videoPlayerRef = ""
    }
    settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [RESPONSIVE_1360_BREAKPOINT, RESPONSIVE_1680_BREAKPOINT]
    }
    bannerSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [RESPONSIVE_1360_BREAKPOINT]
    }

    componentDidMount() {
        this.loadHandler()
    }

    componentWillUnmount() {
        this.removeOutsideClickEventlistner()
    }

    loadHandler = async () => {
        const { categoryList, platformList } = this.props
        this.setState({
            categoryList,
            platformList
        })
    }

    handleDuplicate = (item) => {
        if (item) {
            this.setState({
                railToDuplicate: { ...item }
            })
        } else {
            this.setState({
                railToDuplicate: null
            })
        }
    }

    handleRailNameChange = (name, value) => {
        let { railToDuplicate } = this.state
        railToDuplicate.name = value
        // Translated Names is used to store the duplicate rail data
        // Clear out name in all the other language
        const translatedNames = railToDuplicate?.translatedNames
        for (let key in translatedNames) {
            translatedNames[key] = ""
        }
        translatedNames[DEFAULT_SELECTED_LANGUAGE_CODE] = value
        this.setState({
            railToDuplicate
        })
    }

    handleSelectChange = async (name, value) => {
        let categoryList = [...this.state.categoryList],
            updatedObj = { [name]: value }
        if (name === "platform") {
            const resp = await this.props.fetchCategory(value)
            if (resp?.status) {
                categoryList = resp.data
            }
        } else if (name === "category") {
            const categoryObj = categoryList.find((item) => item.id === value)
            updatedObj = {
                [name]: categoryObj
            }
        }
        this.setState({
            ...updatedObj,
            categoryList
        })
    }

    handleRailCategory = (e) => {
        this.setState({ railStage: e.target.value })
    }

    playChannel = (channel) => {
        this.setState(
            {
                showPlayer: !this.state.showPlayer,
                playUrl: channel?.playBackUrl
            },
            () => {
                this.addOutsideClickEventlistner()
            }
        )
    }

    handleDuplicateSubmit = async (duplicateRailData) => {
        let { railToDuplicate } = this.state

        const { getLatestPosition, createRail, selectedTimezoneLabel } = this.props

        const { railName, platform, category, stage, sportsCategory, sportsSubType } =
            duplicateRailData

        let newRailToDuplicate = railToDuplicate
        newRailToDuplicate.title = railName

        // const translatedNames = newRailToDuplicate?.translatedNames
        // for (let key in translatedNames) {
        //     translatedNames[key] = ""
        // }
        // translatedNames[DEFAULT_SELECTED_LANGUAGE_CODE] = railName

        newRailToDuplicate.platform = [platform.id]
        newRailToDuplicate.category = category

        newRailToDuplicate.active = stage === PUBLISH_UNPULISH_GROUP?.[0]?.id ? true : false
        let contentEditorial = newRailToDuplicate?.contentEditorial
        contentEditorial = contentEditorial?.map((item) => {
            delete item.content
            delete item.id
            return {
                ...item
            }
        })
        newRailToDuplicate.contentEditorial = contentEditorial
        delete newRailToDuplicate.id

        if (category?.hasPageCategory) {
            newRailToDuplicate.pageCategoryId = sportsSubType?.id // page Category ID ex cricket, tennis or virat
            newRailToDuplicate.pageCategoryRail =
                sportsCategory?.id !== SPORTS_HOME_PAGE_TAB?.id ? true : false // False for sports home page
        } else {
            delete newRailToDuplicate?.pageCategoryId
            delete newRailToDuplicate?.pageCategoryRail
        }

        let resp = await getLatestPosition()
        if (resp && resp.status) {
            newRailToDuplicate.position = resp?.data + 1
        }
        let response = await createRail(
            newRailToDuplicate,
            getGMTTimezone(selectedTimezoneLabel?.timeZone)
        )
        if (response && response?.status) {
            toast.success("Duplicate rail created")
            this.handleDuplicate()
            this.props.refresh()
        } else {
            toast.error(response?.message || ERROR_MESSAGES.SOMETHING_WENT_WRONG)
        }
    }

    toggleConfirmationPopup = (e, popupKey, id) => {
        e.stopPropagation()
        this.setState((prevState) => {
            return {
                unpublishPopup: false,
                deletePopup: false,
                idToDelete: id,
                [popupKey]: id !== prevState.idToDelete ? true : !prevState[popupKey]
            }
        })
    }

    handleOutsideClick = (e) => {
        // ignore clicks on the component itself
        if (this.videoPlayerRef && this.videoPlayerRef.contains(e.target)) {
            return
        } else {
            this.setState(
                {
                    showPlayer: !this.state.showPlayer
                },
                () => {
                    this.removeOutsideClickEventlistner()
                }
            )
        }
    }

    addOutsideClickEventlistner = () => {
        return
        // document.addEventListener("click", this.handleOutsideClick, false);
    }

    removeOutsideClickEventlistner = () => {
        return
        // document.removeEventListener("click", this.handleOutsideClick, false);
    }

    getFastChannel = () => {
        const { fastChannel } = this.props
        return (
            <div className="main-slider">
                <div className="railcardDetail">
                    <p className="railName">
                        {" Fast Channels "}
                        <span>( {fastChannel && fastChannel.length} )</span>
                    </p>
                </div>
                <Slider {...this.bannerSettings}>
                    {fastChannel &&
                        fastChannel.map((element) => {
                            return (
                                <div
                                    className="channel-card"
                                    onClick={() => this.playChannel(element)}>
                                    <MovieCardGrid
                                        thumbnail={
                                            get(element, "channelLogo") &&
                                            get(element, "channelLogo")
                                        }
                                        title={get(element, "title")}
                                        cardType={"LandScape"}
                                        imageType={"external"}
                                    />
                                </div>
                            )
                        })}
                </Slider>
            </div>
        )
    }

    getValue = (content, title) => {
        const value = get(content, title)
        return value && Array.isArray(value) && value?.map((item) => item?.title)?.join()
    }

    renderContentTitle = (railType, content) => {
        if (railType?.isEpisodeRail) {
            return get(content?.content, "episodeTitle")
        } else if (railType?.isSeasonRail) {
            return get(content?.content, "seasonTitle")
        }

        return content?.content ? get(content, "title") : get(content, "title")
    }

    render() {
        const {
            rails,
            handleClick,
            handleDelete,
            togglePublishRail,
            selectedRailsList,
            selectRail,
            isSelected,
            platformList
        } = this.props
        const {
            railToDuplicate,
            categoryList,
            unpublishPopup,
            deletePopup,
            showPlayer,
            idToDelete
        } = this.state
        const { toggleConfirmationPopup } = this
        return (
            <div className="cd-container">
                {rails.map((item, index) => {
                    return (
                        <div className={`rail ${!item.active && "unPublish"}`}>
                            <div className="header">
                                <div className="header-left">
                                    <div
                                        className={`selectCheckBox ${
                                            selectedRailsList.length > 0 ? "show" : ""
                                        }`}>
                                        {isSelected(item.id) ? (
                                            <Checkbox
                                                cName="select-checkBox"
                                                name={item.title}
                                                id={item.id}
                                                isChecked={true}
                                                changeHandler={selectRail}
                                            />
                                        ) : (
                                            <Checkbox
                                                cName="select-checkBox"
                                                name={item.title}
                                                id={item.id}
                                                changeHandler={selectRail}
                                            />
                                        )}
                                    </div>
                                    <div className="railcardDetail">
                                        <p className="platformName">
                                            {platformList
                                                .filter((obj) => item.platform.includes(obj.id))
                                                .map((list) => (
                                                    <span>
                                                        {list.platformEnum} <i>,</i>{" "}
                                                    </span>
                                                ))}
                                        </p>
                                        <p className="railName">
                                            {item.title}
                                            <span>
                                                ({" "}
                                                {item.contentList.length < 10
                                                    ? `0${item.contentList.length}`
                                                    : item.contentList.length}{" "}
                                                )
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className="buttons">
                                    <AuthWrapper
                                        permissions={[
                                            PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_EDIT
                                        ]}>
                                        <div className="icons">
                                            <div
                                                className={
                                                    unpublishPopup ? "toolsBtn show" : "toolsBtn"
                                                }
                                                onClick={(e) =>
                                                    toggleConfirmationPopup(
                                                        e,
                                                        "unpublishPopup",
                                                        item.id
                                                    )
                                                }>
                                                <i
                                                    className={`toolsBtn ${
                                                        !item.active
                                                            ? "icon-published"
                                                            : "icon-unpublish"
                                                    }`}
                                                />
                                                <span className="tooltiptext">
                                                    {!item.active ? "Publish" : "Unpublish"}
                                                </span>
                                                {idToDelete === item.id && (
                                                    <div className="confirmation-popup">
                                                        <div className="message">
                                                            {!item.active ? (
                                                                <span className="type">
                                                                    {
                                                                        TEXT_CONSTANTS?.PUBLISHING_RAIL_MESSAGE
                                                                    }
                                                                </span>
                                                            ) : (
                                                                <span className="type">
                                                                    {
                                                                        TEXT_CONSTANTS?.UNPUBLISHING_RAIL_MESSAGE
                                                                    }
                                                                </span>
                                                            )}
                                                        </div>
                                                        <button
                                                            type="button"
                                                            className="btn cancel-btn"
                                                            onClick={
                                                                item.active
                                                                    ? () =>
                                                                          togglePublishRail(
                                                                              item.id,
                                                                              false
                                                                          )
                                                                    : () =>
                                                                          togglePublishRail(
                                                                              item.id,
                                                                              true
                                                                          )
                                                            }>
                                                            {!item.active ? (
                                                                <>{TEXT_CONSTANTS?.PUBLISH}</>
                                                            ) : (
                                                                <>{TEXT_CONSTANTS?.UNPUBLISH}</>
                                                            )}
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn primary-btn"
                                                            onClick={(e) =>
                                                                toggleConfirmationPopup(
                                                                    e,
                                                                    "unpublishPopup",
                                                                    item.id
                                                                )
                                                            }>
                                                            {BUTTON_CONSTANTS?.CANCEL}
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                            <div
                                                className={
                                                    deletePopup
                                                        ? "toolsBtn icon-del show"
                                                        : "toolsBtn icon-del"
                                                }
                                                onClick={(e) =>
                                                    toggleConfirmationPopup(
                                                        e,
                                                        "deletePopup",
                                                        item.id
                                                    )
                                                }>
                                                <span className="tooltiptext">
                                                    {BUTTON_CONSTANTS?.DELETE}
                                                </span>
                                                {idToDelete === item.id && (
                                                    <div className="confirmation-popup">
                                                        <div className="message">
                                                            {TEXT_CONSTANTS?.DELETE_RAIL_MSG}
                                                        </div>
                                                        <button
                                                            type="button"
                                                            className="btn cancel-btn"
                                                            onClick={() => handleDelete(item.id)}>
                                                            {BUTTON_CONSTANTS?.DELETE}
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn primary-btn"
                                                            onClick={(e) =>
                                                                toggleConfirmationPopup(
                                                                    e,
                                                                    "deletePopup",
                                                                    item.id
                                                                )
                                                            }>
                                                            {BUTTON_CONSTANTS?.CANCEL}
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                            <div
                                                className={`toolsBtn icon-duplicate`}
                                                onClick={() => this.handleDuplicate(item)}>
                                                <span className="tooltiptext">
                                                    {TEXT_CONSTANTS?.DUPLICATE_RAIL}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="toolsBtn">
                                            <i
                                                className="addNewBtn icon-edit"
                                                onClick={() => handleClick(item.id)}>
                                                {" "}
                                            </i>
                                            <span className="tooltiptext">
                                                {BUTTON_CONSTANTS?.EDIT}
                                            </span>
                                        </div>
                                    </AuthWrapper>
                                </div>
                            </div>
                            <AuthWrapper
                                permissions={[PERMISSIONS?.ENGAGE_UX_MANAGER_CATEGORY_EDIT]}>
                                {railToDuplicate?.id === item?.id && (
                                    <DuplicateRailModal
                                        handleDuplicate={this.handleDuplicate}
                                        handleDuplicateSubmit={this.handleDuplicateSubmit}
                                        platformList={platformList}
                                        railToDuplicate={railToDuplicate}
                                        item={item}
                                        category={this.state.category}
                                        categoryList={categoryList}></DuplicateRailModal>
                                )}
                            </AuthWrapper>
                            {item.railCategory !== "SYSTEM_GENERATED" &&
                            item.railType !== RAIL_TYPE_CONSTANT.PROMOTION_BANNER ? (
                                <div className="main-slider">
                                    {item.railType === "HERO_BANNER" ? (
                                        <Slider {...this.bannerSettings}>
                                            {item.contentList &&
                                                item.contentList.map((element) => {
                                                    if (
                                                        element.contentType ===
                                                        CONTENT_TYPE_CONST.CHANNEL
                                                    ) {
                                                        return (
                                                            <div className="project">
                                                                {
                                                                    <BannerCard
                                                                        banner={get(
                                                                            element,
                                                                            "thumbnailImage"
                                                                        )}
                                                                        title={get(
                                                                            element,
                                                                            "title"
                                                                        )}
                                                                        published={get(
                                                                            item,
                                                                            "active"
                                                                        )}
                                                                    />
                                                                }
                                                            </div>
                                                        )
                                                    } else {
                                                        return (
                                                            <div className="project">
                                                                {item.orientation !== "PORTRAIT" ? (
                                                                    <BannerCard
                                                                        banner={
                                                                            element.thumbnailImage
                                                                        }
                                                                        title={get(
                                                                            element,
                                                                            "title"
                                                                        )}
                                                                        published={get(
                                                                            item,
                                                                            "active"
                                                                        )}
                                                                    />
                                                                ) : (
                                                                    <BannerCard
                                                                        banner={
                                                                            element.thumbnailImage
                                                                        }
                                                                        isPortrait={true}
                                                                        title={get(
                                                                            element,
                                                                            "title"
                                                                        )}
                                                                        published={get(
                                                                            item,
                                                                            "active"
                                                                        )}
                                                                    />
                                                                )}
                                                            </div>
                                                        )
                                                    }
                                                })}
                                        </Slider>
                                    ) : item.orientation === "PORTRAIT" ? (
                                        <Slider {...this.settings}>
                                            {item.contentList &&
                                                item.contentList.map((element) => {
                                                    if (
                                                        element.contentType ===
                                                        CONTENT_TYPE_CONST.CHANNEL
                                                    ) {
                                                        return (
                                                            <div className="project">
                                                                <MovieCardGrid
                                                                    thumbnail={get(
                                                                        element,
                                                                        "thumbnailImage"
                                                                    )}
                                                                    title={get(element, "title")}
                                                                    category={this.getValue(
                                                                        element.content,
                                                                        "tvGenre"
                                                                    )}
                                                                    isButton={false}
                                                                    cardType={
                                                                        element.contentType ===
                                                                        CONTENT_TYPE_CONST.CHANNEL
                                                                            ? "small"
                                                                            : ""
                                                                    }
                                                                    published={item?.active}
                                                                    orientation={item.orientation}
                                                                    cardSize={item.cardSize}
                                                                />
                                                            </div>
                                                        )
                                                    } else
                                                        return (
                                                            <div className="project">
                                                                <MovieCardGrid
                                                                    thumbnail={
                                                                        !isEmpty(
                                                                            element.thumbnailImage
                                                                        )
                                                                            ? element.thumbnailImage
                                                                            : get(
                                                                                  element.content,
                                                                                  "posterImage"
                                                                              )
                                                                    }
                                                                    title={this.renderContentTitle(
                                                                        item,
                                                                        element
                                                                    )}
                                                                    category={
                                                                        element.content
                                                                            ? get(
                                                                                  element.content,
                                                                                  "genre"
                                                                              ) &&
                                                                              Array.isArray(
                                                                                  get(
                                                                                      element.content,
                                                                                      "genre"
                                                                                  )
                                                                              ) &&
                                                                              get(
                                                                                  element.content,
                                                                                  "genre"
                                                                              )
                                                                                  .map(
                                                                                      (item) =>
                                                                                          item.title
                                                                                  )
                                                                                  .join()
                                                                            : get(
                                                                                  element.content,
                                                                                  "genre"
                                                                              )
                                                                            ? get(
                                                                                  element.content,
                                                                                  "genre"
                                                                              )
                                                                            : get(
                                                                                  element.customContent,
                                                                                  "subTitle"
                                                                              )
                                                                    }
                                                                    isButton={false}
                                                                    cardType={
                                                                        element.contentType ===
                                                                            "Live Tv" && "small"
                                                                    }
                                                                    published={item.active}
                                                                    orientation={item.orientation}
                                                                    cardSize={item.cardSize}
                                                                />
                                                            </div>
                                                        )
                                                })}
                                        </Slider>
                                    ) : (
                                        // ********
                                        <Slider {...this.bannerSettings}>
                                            {item.contentList &&
                                                item.contentList.map((element) => {
                                                    if (
                                                        element.contentType ===
                                                        CONTENT_TYPE_CONST.CHANNEL
                                                    ) {
                                                        return (
                                                            <div className="project">
                                                                <MovieCardGrid
                                                                    thumbnail={get(
                                                                        element,
                                                                        "thumbnailImage"
                                                                    )}
                                                                    title={get(element, "title")}
                                                                    category={this.getValue(
                                                                        element.content,
                                                                        "tvGenre"
                                                                    )}
                                                                    isButton={false}
                                                                    cardType={
                                                                        element.contentType ===
                                                                        CONTENT_TYPE_CONST.CHANNEL
                                                                            ? "small"
                                                                            : ""
                                                                    }
                                                                    published={item?.active}
                                                                    orientation={item.orientation}
                                                                    cardSize={item.cardSize}
                                                                />
                                                            </div>
                                                        )
                                                    } else
                                                        return (
                                                            <div className="project">
                                                                <MovieCardGrid
                                                                    thumbnail={
                                                                        element.thumbnailImage
                                                                            ? element.thumbnailImage
                                                                            : get(
                                                                                  element.content,
                                                                                  "coverImage"
                                                                              )
                                                                    }
                                                                    title={this.renderContentTitle(
                                                                        item,
                                                                        element
                                                                    )}
                                                                    category={this.getValue(
                                                                        element.content,
                                                                        "genre"
                                                                    )}
                                                                    isButton={false}
                                                                    cardType={"LandScape"}
                                                                    published={
                                                                        item.active
                                                                        // .published
                                                                    }
                                                                    orientation={item.orientation}
                                                                    cardSize={item.cardSize}
                                                                />
                                                            </div>
                                                        )
                                                })}
                                        </Slider>
                                    )}
                                </div>
                            ) : item.railType === RAIL_TYPE_CONSTANT.PROMOTION_BANNER ? (
                                <div className="project 1">
                                    <MovieCardGrid
                                        thumbnail={item.largeScreenImageUrl}
                                        isButton={false}
                                        cardType={"LandScape"}
                                        published={item.active}
                                        railType={item.railType}
                                    />
                                </div>
                            ) : (
                                <div className="sys-rail">
                                    <div className="sys-container">
                                        <div className="sys-img icon-system">
                                            <img src={System} alt={"system-icon"} />
                                        </div>
                                        <div className="sys-description">
                                            <h5>{TEXT_CONSTANTS?.SYSTEM_GENERATED_RAIL}</h5>
                                            <p>{TEXT_CONSTANTS?.SYSTEM_GENERATED_RAIL_MSG}</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )
                })}
                {/*<div className={`rail fast-channel-rail`}>*/}
                {/*  {this.getFastChannel()}*/}
                {/*  {showPlayer && (*/}
                {/*    <div*/}
                {/*      className="video-frame-wrapper popup"*/}
                {/*      ref={(ref) => {*/}
                {/*        this.videoPlayerRef = ref;*/}
                {/*      }}*/}
                {/*    >*/}
                {/*      <ReactPlayer*/}
                {/*        url={this.state.playUrl}*/}
                {/*        controls*/}
                {/*        width={385}*/}
                {/*        height={212}*/}
                {/*      />*/}

                {/*      <i onClick={() => this.playChannel()} className="icon-close" />*/}
                {/*    </div>*/}
                {/*  )}*/}
                {/*</div>*/}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        selectedTimezoneLabel: state?.timezone?.selectedTimezone
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                createRail,
                getLatestPosition,
                fetchCategory
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryDetails)
