import { API_ROOT_PATH } from "../../../../config/apiPath"
import ApiService from "../../../../utils/apiService"

export const EnvironmentService = {
    getAllEnv() {
        const options = {
            method: "GET",
            url: `${API_ROOT_PATH}/editorial/environment-theme/`,
            isAuthRequired: true
        }
        return ApiService(options)
    },
    addNewEnv(params) {
        const options = {
            method: "POST",
            url: `${API_ROOT_PATH}/editorial/environment-theme/`,
            isAuthRequired: true,
            data: params
        }
        return ApiService(options)
    },
    deleteEnv(id) {
        const options = {
            method: "DELETE",
            url: `${API_ROOT_PATH}/editorial/environment-theme/${id}/`,
            isAuthRequired: true
        }
        return ApiService(options)
    }
}
