import React from "react"
import Select, { components } from "react-select"
import "./style.scss"

const ValueContainer = (props) => {
    const selected = props.getValue()
    const content = ((length) => {
        switch (length) {
            case 0:
                return props.children
            case 1:
                return selected[0].name
            default:
                return `${selected.length} Selected`
        }
    })(selected.length)

    return <components.ValueContainer {...props}>{content}</components.ValueContainer>
}

const Option = (props) => {
    return (
        <components.Option {...props}>
            <span className="checkboxContainer">
                <span
                    className={props.isSelected ? "checkboxStyleActive" : "checkboxStyleDisable"}
                    id={`${props.isSelected ? "tue" : "fal"}`}
                />{" "}
            </span>
            <label className={`option-label ${props.isSelected && "selectedOptionLabel"}`}>
                {props.label}
            </label>
        </components.Option>
    )
}

export default class CustomSelect extends React.Component {
    handleChange = (selectedOptions, action) => {
        const { changeHandler, id } = this.props
        if (selectedOptions) {
            changeHandler(id, selectedOptions)
        } else {
            changeHandler(id, [])
        }
    }

    render() {
        const {
            options,
            label,
            required,
            id,
            placeholder,
            selectedOptions,
            selectedValues,
            errMsg,
            isOptionCheckbox,
            showValueOnSelect
        } = this.props

        return (
            <div className={`multi-select-country-container ${errMsg ? `inputErr` : ""}`}>
                {label && (
                    <label htmlFor={id}>
                        {label}
                        <span className={required ? "required" : "notRequired"}>*</span>
                    </label>
                )}
                <Select
                    options={options}
                    placeholder={placeholder}
                    defaultValue={selectedOptions}
                    value={selectedValues && selectedValues.length ? selectedValues : []}
                    onChange={this.handleChange}
                    isMulti
                    /* menuIsOpen={true} */
                    components={
                        isOptionCheckbox
                            ? showValueOnSelect
                                ? {
                                      Option: isOptionCheckbox ? Option : null
                                      // DropdownIndicator: () => null,
                                      // IndicatorSeparator: () => null,
                                  }
                                : {
                                      ValueContainer: ValueContainer,
                                      Option: isOptionCheckbox ? Option : null
                                      // DropdownIndicator: () => null,
                                      // IndicatorSeparator: () => null,
                                  }
                            : {
                                  ValueContainer: ValueContainer
                              }
                    }
                    id={id}
                    // openMenuOnClick={true}
                    hideSelectedOptions={false}
                    isSearchable={true}
                    className={"multiSelect-container"}
                    classNamePrefix={"multiSelect"}
                    getOptionLabel={(options) => options.name}
                    getOptionValue={(options) => options.code}
                />
                {errMsg && <span className="err">{errMsg}</span>}
            </div>
        )
    }
}
