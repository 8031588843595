import React, { Component } from "react"
import get from "lodash/get"
import sortBy from "lodash/sortBy"

import Input from "../common/Input"
import ImageUplode from "./imageUplode/index"
import TextArea from "../common/TextArea"
import MultiSelect from "../common/CustomMultiselect"
import Select from "../common/SelectBox"
import VideoSelector from "./addWebSeries/VideoSelector"
import SearchBox from "./addWebSeries/SearchBox"
import { imgPathPrefix } from "./api/constant"
import CustomRadioButton from "../common/customRadioBtn"
import {
    currentFormattedDate,
    getMultiLingualValue,
    isMultiLingual
} from "../../../src/utils/common"
import { DEFAULT_SELECTED_LANGUAGE_CODE } from "../ContentNav/api/constants"
import "./style.scss"
import { checkIfUserHasAccess } from "../../utils/users"
import { PERMISSIONS } from "../../utils/constants"
import CustomCreateAbleSelect from "../common/CustomCreateAbleSelect"
class ContentEntity extends Component {
    constructor(props) {
        super(props)
        this.state = {
            expanded: true,
            urlError: null
        }
        this.setMasterData()
        this.canUpdateStatus = checkIfUserHasAccess([PERMISSIONS.CONTENT_EDIT])
    }

    setMasterData = () => {
        const { metaData, certifications } = this.props

        if (metaData) {
            this.setState({ certifications })
            metaData.forEach((item) => {
                this.setState({
                    [item.entityId]: item.values
                })
            })
        }
    }

    getOptions = (title) => {
        if (title === "age_rating" || title === "episode_rating") {
            let options = this?.props.certifications
            let list = options
                ? options.map((ele) => {
                      return { id: ele, name: ele }
                  })
                : null
            return list
        }
        let list
        let season = "season"
        let episode = "episode"
        if (title === "brand_genre") {
            let options = window.genres[DEFAULT_SELECTED_LANGUAGE_CODE]
            list = options
                ? options.map((ele) => {
                      return { id: ele, name: ele }
                  })
                : null
            return list
        }

        if (title && title.includes(season)) {
            const { formMetaData } = this.props
            let options =
                season in formMetaData &&
                formMetaData[season].items &&
                formMetaData[season].items.properties &&
                title in formMetaData[season].items.properties &&
                formMetaData[season].items.properties[title].items?.enum?.length > 0
                    ? formMetaData[season].items.properties[title]?.items?.enum
                    : null

            list = options
                ? options.map((ele) => {
                      return { id: ele, name: ele }
                  })
                : null
        } else if (title && title.includes(episode)) {
            const { formMetaData } = this.props
            let options =
                season in formMetaData &&
                formMetaData[season].items &&
                formMetaData[season].items.properties &&
                episode in formMetaData[season].items.properties &&
                formMetaData?.[season]?.items?.properties?.episode?.items?.properties &&
                formMetaData?.[season]?.items?.properties?.episode?.items?.properties?.[title] &&
                formMetaData[season].items.properties.episode?.items?.properties?.[title]?.items
                    ?.enum?.length > 0
                    ? formMetaData[season].items.properties?.episode?.items?.properties?.[title]
                          ?.items?.enum
                    : null

            list = options
                ? options.map((ele) => {
                      return { id: ele, name: ele }
                  })
                : null
        } else {
            const { formMetaData } = this.props
            let options =
                title in formMetaData &&
                formMetaData[title].items &&
                formMetaData[title].items?.enum?.length > 0
                    ? formMetaData[title].items?.enum
                    : null

            list = options
                ? options.map((ele) => {
                      return { id: ele, name: ele }
                  })
                : null
        }

        return list
    }
    /*   getDefaultOptions = (options, selected) => {
    if (options && selected) {
      let selectedOptions = [];
      selected.forEach((item, index) => {
        options.forEach((item1, index) => {
          if (item.id.toString() === item1.id.toString()) {
            selectedOptions.push(item1);
          }
        });
      });
      return selectedOptions;
    }
  }; */

    getDefaultOptions = (options, selected, title) => {
        if (isMultiLingual) {
            selected = selected?.values?.[this.props?.selectedLanguage?.code]
            let selectedOptions = this.getMultiLingualDefaultOptions(options, selected)
            return selectedOptions
        }
        if (options && selected && Array.isArray(selected)) {
            let selectedOptions = []
            selected.forEach((item, index) => {
                options.forEach((item1, index) => {
                    if (item.id.toString() === item1.id.toString()) {
                        selectedOptions.push(item1)
                    }
                })
            })
            return selectedOptions
        }
    }

    getDefaultCredits = (options, selected) => {
        let optionsArray = []
        if (options && selected) {
            options.forEach((item, index) => {
                optionsArray.push({
                    id: item.name,
                    name: item.name
                })
            })
        }
        if (isMultiLingual) {
            selected = selected?.values?.[this.props?.selectedLanguage?.code]
            let selectedOptions = this.getMultiLingualDefaultOptions(optionsArray, selected, true)
            let convertedSelectedOptions = []
            selectedOptions &&
                selectedOptions.forEach((item, index) => {
                    convertedSelectedOptions.push({
                        ...item,
                        label: item.name,
                        value: item.name
                    })
                })
            return convertedSelectedOptions
        }
    }

    getMultiLingualDefaultOptions = (options, selected, isCustomMultiselect) => {
        if (options && selected && Array.isArray(selected)) {
            let selectedOptions = []
            if (isCustomMultiselect) {
                selected.forEach((item, index) => {
                    if (!item.id) {
                        selectedOptions.push({ name: item, id: item })
                    } else {
                        selectedOptions.push(item)
                    }
                })

                return selectedOptions
            }
            selected.forEach((item, index) => {
                options.forEach((item1, index) => {
                    if (!item.id) {
                        if (item === item1.id.toString()) {
                            selectedOptions.push(item1)
                        }
                    } else {
                        if (item?.id.toString() === item1?.id.toString()) {
                            selectedOptions.push(item1)
                        }
                    }
                })
            })
            return selectedOptions
        }
        return null
    }
    isChecked = (optionId, name) => {
        let checked = false
        if (this.props.preFillData && this.props.preFillData[name]) {
            this.props.preFillData[name].forEach((item, index) => {
                if (item.id.toString() === optionId.toString()) {
                    checked = true
                }
            })
        }

        return checked
    }

    handleClick = () => {}
    renderAttributes = (data) => {
        let formData = null
        let attributeJSx = data.attributes.map((item, index) => {
            if (item.htmlElement && item.htmlElement.elementType === "FORM") {
                formData = item.entity
                return null
            }
            let entityJsx = ""
            if (item.entity) {
                let isshowLabel =
                    this.props.preFillData[item.name] &&
                    !Array.isArray(this.props.preFillData[item.name]) &&
                    typeof this.props.preFillData[item.name] === "object" &&
                    item.htmlElement.elementType === null
                entityJsx = (
                    <>
                        <ContentEntity
                            loadEpisodeForm={this.props.loadEpisodeForm}
                            isForm={item.htmlElement && item.htmlElement.elementType === "FORM"}
                            updateRequiredFields={this.props.updateRequiredFields}
                            formTitle={isshowLabel ? item.htmlElement.label : null}
                            key={"content-" + item.id}
                            data={item.entity}
                            formMetaData={this.props.formMetaData}
                            handleChange={this.props.handleChange}
                            uploadImage={this.props.uploadImage}
                            removeImg={this.props.removeImg}
                            preFillData={
                                this.props.preFillData
                                    ? this.props.preFillData[item.name]
                                        ? this.props.preFillData[item.name]
                                        : this.props.preFillData
                                    : null
                            }
                            searchedText={this.props.searchedText}
                            handleSearchBox={this.props.handleSearchBox}
                            handleCheckBoxChange={this.props.handleCheckBoxChange}
                            handleDeleteChange={this.props.handleDeleteChange}
                            newActorList={this.props.newActorList}
                            selectedLanguage={this.props?.selectedLanguage}
                            certifications={this.props?.certifications}
                            selectedData={this?.props?.selectedData}
                            drmResponse={this.props?.drmResponse}
                            isEdit={this.props?.isEdit}
                            actorOptions={this.props.actorOptions}
                            directorOptions={this.props.directorOptions}
                            producerOptions={this.props.producerOptions}
                        />
                    </>
                )
            }
            return (
                <>
                    {/* {attributeLabel} */}
                    {entityJsx}
                    {this.renderFormItems(item)}
                </>
            )
        })

        if (this.props.loadEpisodeForm) {
            this.props.loadEpisodeForm(formData)
        }
        return attributeJSx
    }

    findSelectedValues = (arr, id) => {
        let newArr = []
        if (Array.isArray(arr) && id) {
            arr.forEach((item) => {
                id.forEach((ele) => {
                    if (item.id === ele) {
                        newArr.push(item)
                    }
                })
            })
        }

        return newArr
    }

    getMinDate = () => {
        return currentFormattedDate()
    }
    validateUrl = (err, name) => {
        if (err) {
            this.setState({
                urlError: { ...this.state.urlError, [name]: "Please enter valid url" }
            })
        } else {
            let copyObj = { ...this.state.urlError }
            if (copyObj.hasOwnProperty(name)) {
                delete copyObj[name]
            }
            this.setState({
                urlError: copyObj
            })
        }
    }

    handleRadioChange = (e) => {
        this.props.handleChange(e.target?.name?.split("-")?.[0], e?.target.value)
        // const { payload } = this.state;
        // payload[e?.target.name] = e?.target.value == "1" ? true : false;
        // this.setState({
        //   payload,
        // });
    }

    renderFormItems = (element) => {
        const { urlError } = this.state
        let elementType = `${get(element, "htmlElement.elementType", "")}-${get(
            element,
            "htmlElement.elementSubType",
            ""
        )}`
        let elementLabel = get(element, "htmlElement.label", "")
        this.props.updateRequiredFields &&
            this.props.updateRequiredFields(element.name, element.required)
        if (elementLabel === "GENRE") {
            elementType = "SELECT-MULTI_SELECT"
        }

        const globalDRMResponse = this.props?.drmResponse?.isDRMEnabled
        const isDRMEnabled =
            this.props?.preFillData?.["drm"] && this.props?.drmResponse?.isDRMEnabled
        let isDisableRadio = false

        if (isDRMEnabled) {
            if (element?.title === "play_url" || element?.title === "episode_play_url") return null
        } else {
            if (
                element?.title === "hls_play_url" ||
                element?.title === "dash_play_url" ||
                element?.title === "offlineDownload"
            )
                return null
        }

        // Disable asset_id and drm_asset_id once value is added
        if (
            (element?.title === "asset_id" &&
                this.props.selectedData?.hasOwnProperty("asset_id") &&
                this.props.selectedData?.asset_id !== "") ||
            (element?.title === "drm_asset_id" &&
                this.props.selectedData?.hasOwnProperty("drm_asset_id") &&
                this.props.selectedData?.drm_asset_id !== "") ||
            (element?.title === "season_id" &&
                this.props.selectedData?.hasOwnProperty("season_id") &&
                this.props.selectedData?.season_id !== "") ||
            (element?.title === "episode_id" &&
                this.props.selectedData?.hasOwnProperty("episode_id") &&
                this.props.selectedData?.episode_id !== "")
        ) {
            isDisableRadio = true
        }

        const selectedLangCode = this.props.selectedLanguage?.code
        const isMulti = isMultiLingual()
        if (
            element?.htmlElement?.label === "DIRECTOR" ||
            element?.htmlElement?.label === "ACTOR" ||
            element?.htmlElement?.label === "PRODUCER"
        ) {
            elementType = "SELECT-MULTI_SELECT"
        }
        switch (elementType) {
            case "INPUT-FILE":
                const imgURL = getMultiLingualValue(
                    isMulti,
                    this.props.preFillData[element.name],
                    selectedLangCode
                )
                return (
                    <div className="image-Container">
                        <div
                            className={`col-${
                                element.htmlElement.elementSize &&
                                element.htmlElement.elementSize.toLowerCase()
                            }`}
                        >
                            <ImageUplode
                                listRef={(ref) => {
                                    this[element.refId] = ref
                                }}
                                key={element.name + "-" + element.id}
                                name={element.name}
                                id={element.name}
                                placeholder={element.htmlElement.placeholder}
                                label={element.htmlElement.label}
                                required={true}
                                value={
                                    this.props.preFillData &&
                                    this.props.preFillData[element.name] &&
                                    imgURL
                                        ? `${imgPathPrefix}${imgURL}`
                                        : null
                                }
                                uploadImage={this.props.uploadImage}
                                removeImg={this.props.removeImg}
                                disabled={!this.canUpdateStatus}
                            />
                        </div>
                    </div>
                )

            case "INPUT-DATE":
                return (
                    <div className={element.htmlElement.label === "AIR DATE" ? "col-half" : ""}>
                        <Input
                            key={element.name + "-" + element.id}
                            labelText={elementLabel}
                            type={element.htmlElement.elementSubType}
                            placeholder={element.htmlElement.placeholder}
                            value={this.props.preFillData && this.props.preFillData[element.name]}
                            required={element.required}
                            listRef={(ref) => {
                                this[element.refId] = ref
                            }}
                            minValue={this.getMinDate()}
                            name={element.name}
                            changeHandler={this.props.handleChange}
                            disabled={!this.canUpdateStatus || isDisableRadio}
                        />
                    </div>
                )
            case "INPUT-TEXT":
                return (
                    <div
                        className={
                            element.htmlElement.title === "brand_title" ||
                            element.htmlElement.title === "season_title" ||
                            element.htmlElement.title === "season_number"
                                ? "col-large brand-title"
                                : element.htmlElement.title === "brand_trailer_duration"
                                ? "duration"
                                : element.htmlElement.title === "season_year"
                                ? "season"
                                : "season"
                        }
                    >
                        <Input
                            key={element.name + "-" + element.id}
                            labelText={elementLabel}
                            type={element.htmlElement.elementSubType}
                            placeholder={element.htmlElement.placeholder}
                            value={
                                this.props.preFillData &&
                                getMultiLingualValue(
                                    isMulti,
                                    this.props.preFillData[element.name],
                                    selectedLangCode
                                )
                            }
                            required={element.required}
                            listRef={(ref) => {
                                this[element.refId] = ref
                            }}
                            name={element.name}
                            changeHandler={this.props.handleChange}
                            disabled={!this.canUpdateStatus || isDisableRadio}
                        />
                    </div>
                )
            case "INPUT-URL":
                return (
                    <div
                        className={
                            element.htmlElement.title === "brand_trailer_duration"
                                ? ""
                                : "trailer-url"
                        }
                    >
                        <Input
                            labelText={elementLabel}
                            type={element.htmlElement.elementSubType}
                            placeholder={element.htmlElement.placeholder}
                            required={element.required}
                            key={element.name + "-" + element.id}
                            value={this.props.preFillData && this.props.preFillData[element.name]}
                            listRef={(ref) => {
                                this[element.refId] = ref
                            }}
                            isUrl={true}
                            errMsg={urlError?.[element.name] ? urlError?.[element.name] : null}
                            validateUrl={this.validateUrl}
                            name={element.name}
                            changeHandler={this.props.handleChange}
                            disabled={!this.canUpdateStatus || isDisableRadio}
                        />
                    </div>
                )
            case "INPUT-TEXTAREA":
                return (
                    <div
                        className={`col-${
                            element.htmlElement.elementSize &&
                            element.htmlElement.elementSize.toLowerCase()
                        }`}
                    >
                        <TextArea
                            row={5}
                            col={5}
                            labelText={elementLabel}
                            placeholder={element.htmlElement.placeholder}
                            required={element.required}
                            key={element.name + "-" + element.id}
                            value={
                                this.props.preFillData &&
                                getMultiLingualValue(
                                    isMulti,
                                    this.props.preFillData[element.name],
                                    selectedLangCode
                                )
                            }
                            listRef={(ref) => {
                                this[element.refId] = ref
                            }}
                            name={element.name}
                            changeHandler={this.props.handleChange}
                            disabled={!this.canUpdateStatus || isDisableRadio}
                        />
                    </div>
                )
            case "SELECT-MULTI_SELECT":
                const selectOptions = this.getOptions(element.entity.title)
                return (
                    <div
                        className={
                            element.htmlElement.label === "DIRECTOR" ||
                            element.htmlElement.label === "PRODUCER"
                                ? "name-field"
                                : element.htmlElement.label === "ACTOR"
                                ? "actor col-half"
                                : "col-half"
                        }
                    >
                        {element.htmlElement.label === "DIRECTOR" ||
                        element.htmlElement.label === "PRODUCER" ||
                        element.htmlElement.label === "ACTOR" ? (
                            <CustomCreateAbleSelect
                                key={element.name + "-" + element.id}
                                options={
                                    element.htmlElement.label === "ACTOR"
                                        ? this.props.actorOptions
                                        : element.htmlElement.label === "DIRECTOR"
                                        ? this.props.directorOptions
                                        : this.props.producerOptions
                                }
                                required={element.required}
                                placeholder={"-select-"}
                                label={element.entity.title.toUpperCase().replace("_", " ")}
                                id={element.entity.name}
                                name={element.entity.name}
                                selectedOptions={this.getDefaultCredits(
                                    element.htmlElement.label === "ACTOR"
                                        ? this.props.actorOptions
                                        : element.htmlElement.label === "DIRECTOR"
                                        ? this.props.directorOptions
                                        : this.props.producerOptions,
                                    this.props.preFillData && this.props.preFillData[element.name]
                                )}
                                changeHandler={(name, selectedOptions, id) => {
                                    this.props.handleChange(
                                        name,
                                        selectedOptions,
                                        id,
                                        selectOptions
                                    )
                                }}
                            />
                        ) : (
                            <MultiSelect
                                key={element.name + "-" + element.id}
                                options={this.getOptions(element.entity.title)}
                                required={element.required}
                                placeholder={"-select-"}
                                label={elementLabel}
                                id={elementLabel}
                                name={element.name}
                                selectedOptions={this.getDefaultOptions(
                                    this.getOptions(element?.entity?.title),
                                    this.props.preFillData && this.props?.preFillData[element.name],
                                    element?.entity?.title
                                )}
                                listRef={(ref) => {
                                    this[element.refId] = ref
                                }}
                                changeHandler={this.props.handleChange}
                                isDisabled={!this.canUpdateStatus}
                            />
                        )}
                    </div>
                )
            case "SELECT-SELECT":
                return (
                    <div className={element.htmlElement.label === "RATING" ? "rating" : ""}>
                        <Select
                            key={element.name + "-" + element.id}
                            options={sortBy(this.getOptions(element.entity.title), "name")}
                            defaultOption={
                                this.props.preFillData[element.name] &&
                                this.props.preFillData[element.name]?.id?.length > 0
                                    ? this.props.preFillData[element.name]
                                    : null
                            }
                            placeholder="-select-"
                            label={elementLabel}
                            required={element.required}
                            name={element.name}
                            listRef={(ref) => {
                                this[element.refId] = ref
                            }}
                            changeHandler={this.props.handleChange}
                            isDisabled={!this.canUpdateStatus}
                        />
                    </div>
                )
            case "INPUT-CHECKBOX":
                return (
                    <div className={element.htmlElement.label === "VIDEO FORMAT" ? "season" : ""}>
                        <VideoSelector
                            key={element.name + "-" + element.id}
                            options={this.getOptions(element.entity.title)}
                            label={elementLabel}
                            required={element.required}
                            name={element.name}
                            isChecked={this.isChecked}
                            changeHandler={this.props.handleChange}
                            disabled={!this.canUpdateStatus}
                        />
                    </div>
                )
            case "SEARCHABLE_LIST-null":
                return (
                    <div
                        className={`col-${
                            element.htmlElement.elementSize &&
                            element.htmlElement.elementSize.toLowerCase()
                        }`}
                    >
                        <SearchBox
                            key={element.name + "-" + element.entity.id}
                            options={
                                this.props.newActorList.length
                                    ? this.props.newActorList
                                    : this.getOptions(element.entity.title)
                            }
                            selectedValues={
                                this.props.preFillData && this.props.preFillData[element.name]
                                    ? this.props.preFillData[element.name]
                                    : []
                            }
                            isChecked={this.isChecked}
                            id={element.name}
                            label={elementLabel}
                            required={element.required}
                            searchedText={this.props.searchedText}
                            handleSearchBox={this.props.handleSearchBox}
                            handleCheckBoxChange={(e, optionId) =>
                                this.props.handleCheckBoxChange(
                                    e,
                                    element.entity.id,
                                    element.name,
                                    optionId
                                )
                            }
                            handleDeleteChange={this.props.handleDeleteChange}
                        />
                    </div>
                )
            case "INPUT-RADIO":
            case "RADIO-RADIO":
                let radioValue = this.props?.preFillData[element.name]
                radioValue = radioValue === true ? "YES" : "NO"
                if (!globalDRMResponse || this.props.isEdit) {
                    if (element?.name === "drm" || element?.name === "offlineDownload") {
                        isDisableRadio = true
                    }
                }
                return (
                    <div className="season radio-field video-format-field">
                        <div>
                            <CustomRadioButton
                                // options={this.getOptions(element?.entity.title)}
                                options={[
                                    { id: 1, name: "YES" },
                                    { id: 2, name: "NO" }
                                ]}
                                label={elementLabel}
                                required={element.required}
                                disabled={isDisableRadio}
                                name={`${element.name}-${Math.random() * 100}`}
                                cRadioBtn={`radioBtnContainer ${
                                    isDisableRadio ? "disableRadio" : null
                                }`}
                                value={radioValue === "YES" ? "1" : "2"}
                                handleRadioChange={this.handleRadioChange}
                            />
                        </div>
                    </div>
                )
            default:
            // if (elementLabel)
            //   return <div class="col-large">{elementLabel}</div>;
            // else
            //   return null;
        }
    }
    toggelExpand = () => {
        this.setState({
            expanded: !this.state.expanded
        })
    }
    render() {
        let className = this.props.isForm
            ? "content-entity-container new-form-container"
            : "content-entity-container"
        if (!this.state.expanded) {
            className += " closed"
        }

        return (
            <>
                {this.props.formTitle ? (
                    <>
                        <div
                            className="col-large content-form-title form-title"
                            onClick={this.toggelExpand}
                        >
                            <span className="icon-plus"></span>
                            {this.props.formTitle}
                        </div>
                    </>
                ) : null}
                <div className={className}>{this.renderAttributes(this.props.data)}</div>
            </>
        )
    }
}

export default ContentEntity
