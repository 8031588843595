import React, { Component } from "react"

import Button from "../Button"
import Checkbox from "../Checkbox/index"
import NoImgAvailable from "../../../assets/images/no_img_available.png"
import "./style.scss"
import { imagePathPrefix } from "../../../config/apiPath"
import { BUTTON_CONSTANTS, PERMISSIONS } from "../../../utils/constants"
import { AuthWrapper } from "../AuthWrapper"
import {
    CARD_SIZE_LIST,
    ORIENTATION_MENU,
    orientationsOptions,
    RAIL_TYPE_CONSTANT
} from "../../Category/api/constant"
import { joinUrl } from "../../../utils/common"

class index extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isDelete: false,
            idToDelete: -1,
            isUnpublish: false,
            idToUnpublish: -1,
            highResImageLoaded: false
        }
    }
    handleDeleteView = (id) => {
        const { isDelete } = this.state
        if (isDelete) {
            this.setState({
                isDelete: !isDelete,
                idToDelete: -1
            })
        } else {
            this.setState({
                isDelete: !isDelete,
                idToDelete: id
            })
        }
    }

    handleUnpublishView = (id) => {
        const { isUnpublish } = this.state
        if (isUnpublish) {
            this.setState({
                isUnpublish: !isUnpublish,
                idToUnpublish: -1
            })
        } else {
            this.setState({
                isUnpublish: !isUnpublish,
                idToUnpublish: id
            })
        }
    }

    handleUnpublish = () => {
        this.props.handleUnpublishClick()
    }

    render() {
        const { isDelete, isUnpublish, highResImageLoaded } = this.state
        let {
            date,
            time,
            thumbnail,
            title,
            handleViewClick,
            id,
            markSelected,
            isButton,
            contentId,
            handleEdit,
            selected,
            cardType,
            published,
            handleDeleteContent,
            contentType,
            selectedCards,
            railType,
            orientation,
            cardSize,
            hideOverlayHeader = false
        } = this.props

        if (contentType) {
            contentType = contentType.replace("_", " ").toLowerCase()
        }

        let image = thumbnail
        if (!image) {
            image = NoImgAvailable
        } else if (!image.includes("http")) {
            image = joinUrl(image)
        }

        return (
            <div
                className={`movieCard ${
                    cardType === "small"
                        ? "smallCard"
                        : cardType === "LandScape"
                        ? "landscape"
                        : contentType === "reels"
                        ? "promotional-banner-card reels"
                        : cardType === "livetvcard"
                        ? "livetvcard"
                        : ""
                } ${!published ? "greyScale" : ""}  ${selectedCards ? "selected" : ""}
                 ${railType === RAIL_TYPE_CONSTANT.PROMOTION_BANNER ? "promo-banner-width" : ""}`}
                id={id}>
                <div className={date ? "cardHeader" : "hideHeader"}>
                    <span>{date}</span>
                    {/*<span>&middot;</span>*/}
                    <span>{time}</span>
                </div>
                <div
                    className={`cardImage ${!thumbnail ? "no-img-available" : ""} ${
                        orientation === orientationsOptions[ORIENTATION_MENU.CIRCULAR].name
                            ? "no-bg"
                            : ""
                    }`}>
                    <div
                        className={`img-overlay ${
                            orientation === orientationsOptions[ORIENTATION_MENU.CIRCULAR].name
                                ? "circular-image"
                                : ""
                        } ${cardSize === CARD_SIZE_LIST[1].id ? "large" : ""}`}>
                        <img
                            onLoad={() => {
                                this.setState({ highResImageLoaded: true })
                            }}
                            // ref={img => {
                            //   this.highResImage = img;
                            // }}
                            src={image}
                            alt="thumbnail"
                            className="movieThumbnail"
                            onError={(e) => {
                                e.target.onerror = null
                                e.target.src = `${NoImgAvailable}`
                                e.target.className = `no-img-available`
                            }}
                        />
                        <img
                            {...(highResImageLoaded && { style: { opacity: "0" } })}
                            alt=""
                            className="movieThumbnail overlayStyles"
                            src={image}
                        />
                    </div>
                    <div
                        className={`buttons ${!isButton ? "hideMe" : ""} ${
                            selected || isDelete || isUnpublish || selectedCards ? "selected" : ""
                        }
            `}>
                        {!hideOverlayHeader && (
                            <div className="buttons-header">
                                <div className="checkBoxBtn">
                                    {/* <Checkbox
                                        cName="check-box"
                                        id={id}
                                        name={title}
                                        isChecked={selectedCards && true}
                                        changeHandler={markSelected}
                                    /> */}
                                    {/* <div className="icon-menu" onClick={()=>markSelected(id)}></div> */}
                                </div>
                                <div className="icons-btn">
                                    <AuthWrapper permissions={[PERMISSIONS.CONTENT_EDIT]}>
                                        <div
                                            className={`toolsBtn ${
                                                published ? "icon-unpublish" : "icon-published"
                                            }`}
                                            onClick={this.handleUnpublishView}>
                                            <span className="tooltiptext">
                                                {published ? "Unpublish" : "Publish"}
                                            </span>
                                        </div>
                                    </AuthWrapper>

                                    <div
                                        className="toolsBtn icon-edit"
                                        onClick={() => handleEdit(contentId)}>
                                        <span className="tooltiptext">Edit</span>
                                    </div>
                                    <AuthWrapper permissions={[PERMISSIONS.CONTENT_EDIT]}>
                                        <div
                                            className="toolsBtn icon-del"
                                            onClick={() => this.handleDeleteView(id)}>
                                            <span className="tooltiptext">Delete</span>
                                        </div>
                                    </AuthWrapper>
                                </div>
                            </div>
                        )}
                        <div className="view-button">
                            <Button
                                clickHandler={handleViewClick}
                                cName="btn viewBtn"
                                bValue={BUTTON_CONSTANTS?.VIEW}
                                bType="button"
                            />
                        </div>
                        <div className={`deleteOptions ${isDelete ? "showPopUp" : ""}`}>
                            <div className="del-text">
                                <p>Delete this {contentType ? contentType : "content"} ?</p>
                                <small>This cannot be undo.</small>
                            </div>
                            <div className="del-btn">
                                <Button
                                    clickHandler={() => {
                                        handleDeleteContent(id)
                                        this.handleDeleteView()
                                    }}
                                    cName="btn popup-del-btn"
                                    bValue={BUTTON_CONSTANTS?.YES_DELETE}
                                    bType="button"
                                />
                                <Button
                                    clickHandler={this.handleDeleteView}
                                    cName="btn cancel-btn"
                                    bValue={BUTTON_CONSTANTS?.CANCEL}
                                    bType="button"
                                />
                            </div>
                        </div>
                        <div className={`unpublishOptions ${isUnpublish ? "showPopUp" : ""}`}>
                            <div className="unpublish-text">
                                <p>
                                    {published
                                        ? `Unpublish this ${
                                              contentType ? contentType : "content"
                                          } ?`
                                        : `Publish this ${contentType ? contentType : "content"} ?`}
                                </p>
                            </div>
                            <div className="unpublish-btn">
                                <Button
                                    clickHandler={this.handleUnpublish}
                                    cName="btn popup-btn"
                                    bValue={BUTTON_CONSTANTS?.YES}
                                    bType="button"
                                />
                                <Button
                                    clickHandler={this.handleUnpublishView}
                                    cName="btn cancel-btn"
                                    bValue={BUTTON_CONSTANTS?.CANCEL}
                                    bType="button"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="headings">
                    {/*<div className="subHeading">
            <p>{`hello ${category}`}</p>
          </div>*/}
                    <div className="mainHeading">
                        <h6>{title}</h6>
                    </div>
                </div>
            </div>
        )
    }
}

export default index
